package com.catbit.opinionpoll.core.ui.composables.base_components

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.extensions.bestPlacementOf
import com.catbit.opinionpoll.core.extensions.withNotNull
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.icons.MaterialIcon
import com.catbit.opinionpoll.core.ui.modifiers.*
import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextOverflow
import com.varabyte.kobweb.compose.css.UserSelect
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.silk.components.overlay.*
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

class PopUpMenuState {

    internal val openClosePopupStrategy = OpenClosePopupStrategy.manual()
    internal val keepPopupOpenStrategy = KeepPopupOpenStrategy.manual()

    fun show() {
        openClosePopupStrategy.isOpen = true
        keepPopupOpenStrategy.shouldKeepOpen = true
    }

    fun dismiss() {
        openClosePopupStrategy.isOpen = false
        keepPopupOpenStrategy.shouldKeepOpen = false
    }

    fun toggle() {
        openClosePopupStrategy.isOpen = openClosePopupStrategy.isOpen.not()
        keepPopupOpenStrategy.shouldKeepOpen = keepPopupOpenStrategy.shouldKeepOpen.not()
    }
}

@Composable
fun rememberPopUpMenuState() = remember { PopUpMenuState() }

@Composable
fun PopUpMenu(
    modifier: Modifier = Modifier,
    state: PopUpMenuState = rememberPopUpMenuState(),
    options: List<PopUpMenuEntry>,
    enabled: Boolean = true,
    onOptionSelected: (String) -> Unit,
    onOptionClick: (() -> Unit)? = null,
    onShowOptions: (() -> Unit)? = null,
    onHideOptions: (() -> Unit)? = null,
    content: @Composable () -> Unit
) {

    var containerYPosition by remember { mutableStateOf(0.0) }
    var containerXPosition by remember { mutableStateOf(0.0) }
    var containerWidth by remember { mutableStateOf(0.0) }
    val id by remember { mutableStateOf(UUID.stringUUID()) }

    var searchQuery by remember { mutableStateOf("") }
    val checkedOptionsList = mutableStateListOf<String>()

    Row(
        modifier = modifier
            .id(id)
            .cursor(if (enabled) Cursor.Pointer else Cursor.NotAllowed)
            .onClick {
                if (enabled) {
                    onOptionClick?.invoke()
                    withNotNull(document.getElementById(id)?.getBoundingClientRect()) {
                        containerYPosition = y
                        containerXPosition = x
                        containerWidth = width
                    }
                    state.toggle()

                    if (state.openClosePopupStrategy.isOpen) {
                        onShowOptions?.invoke()
                    } else {
                        onHideOptions?.invoke()
                        checkedOptionsList.clear()
                    }
                }
            },
        verticalAlignment = Alignment.CenterVertically
    ) {
        content()
    }

    AdvancedPopover(
        ElementTarget.PreviousSibling,
        placementStrategy = window.bestPlacementOf(
            xPos = containerXPosition,
            yPos = containerYPosition,
            width = containerWidth
        ),
        openCloseStrategy = state.openClosePopupStrategy,
        keepOpenStrategy = state.keepPopupOpenStrategy
    ) {

        Column(
            modifier = Modifier
                .overflow(Overflow.Hidden)
                .minWidth(containerWidth.px)
                .clip(Rect(8.px))
                .border(
                    color = sitePalette().primary,
                    width = 2.px,
                    style = LineStyle.Solid
                )
                .background(sitePalette().surfaceVariant)
                .borderRadius(8.px)
                .padding(vertical = 8.px)
        ) {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .maxHeight(300.px)
                    .overflow {
                        y(Overflow.Auto)
                        x(Overflow.Hidden)
                    },
            ) {

                options.forEach { option ->
                    Row(
                        modifier = Modifier
                            .userSelect(UserSelect.None)
                            .padding(horizontal = 16.px, vertical = 8.px)
                            .cursor(Cursor.Pointer)
                            .hoverBackground(
                                onMouseEnterBackgroundColor = sitePalette().primary,
                                onMouseExitBackgroundColor = sitePalette().surfaceVariant,
                            )
                            .hoverColor(
                                onMouseEnterColor = sitePalette().onPrimary,
                                onMouseExitColor = sitePalette().onSurfaceVariant,
                            )
                            .onClick {
                                onOptionSelected(option.id)
                                state.dismiss()
                                onHideOptions?.invoke()
                                searchQuery = ""
                            }
                            .fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        option.icon?.let {
                            MaterialIcon(
                                modifier = Modifier.margin(right = 8.px),
                                icon = it
                            )
                        }
                        Text(
                            modifier = Modifier
                                .textOverflow(TextOverflow.Ellipsis)
                                .labelLarge(),
                            text = option.text
                        )
                    }
                }
            }
        }
    }
}

data class PopUpMenuEntry(
    val id: String,
    val text: String,
    val icon: MaterialIcon? = null
)