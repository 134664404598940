package com.catbit.opinionpoll.inputs.requests.requests

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class OptionRequest(
    @SerialName("title")
    val title: String,
    @SerialName("identifier")
    val identifier: String
)