package com.catbit.opinionpoll.domain.user

import com.catbit.opinionpoll.core.domain.UseCase
import com.catbit.opinionpoll.data.repositories.OpinionPollRepository

class UpdateUserUseCase(
    private val repository: OpinionPollRepository
) : UseCase<Unit, UpdateUserUseCase.Params>() {

    override suspend fun execute(params: Params) = repository.updateUser(
        userIdentifier = params.userIdentifier,
        phoneNumber = params.phoneNumber,
        role = params.role,
        name = params.name,
        email = params.email
    )

    data class Params(
        val userIdentifier: String,
        val phoneNumber: String,
        val role: String,
        val name: String,
        val email: String,
    )
}