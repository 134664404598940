package com.catbit.opinionpoll.ui.screens.login

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.extensions.changeAlpha
import com.catbit.opinionpoll.core.extensions.getStateHolder
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.catbit.opinionpoll.core.sailor.navigation_controller.NavigationController
import com.catbit.opinionpoll.core.sailor.overlays.dialog.DialogState
import com.catbit.opinionpoll.core.sailor.providers.LocalDialogStateProvider
import com.catbit.opinionpoll.core.sailor.providers.LocalSnackbarState
import com.catbit.opinionpoll.core.ui.composables.CircularProgress
import com.catbit.opinionpoll.core.ui.composables.HoverableContent
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.composables.base_components.FilledButton
import com.catbit.opinionpoll.core.ui.composables.base_components.Text
import com.catbit.opinionpoll.core.ui.composables.base_components.TextField
import com.catbit.opinionpoll.core.ui.composables.effects.SingleEffect
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.*
import com.catbit.opinionpoll.res.Strings
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.px
import kotlin.time.Duration.Companion.seconds

@Composable
fun LoginScreen(
    stackEntryId: String,
    navigationController: NavigationController,
    onNavigate: () -> Unit,
    stateHolder: LoginStateHolder = getStateHolder(
        stackEntryId = stackEntryId
    ),
) {
    SingleEffect { onNavigate() }

    val uiState = stateHolder.uiState.collectAsState().value
    val snackBarState = LocalSnackbarState.current
    val coroutineScope = rememberCoroutineScope()
    val dialogState = LocalDialogStateProvider.current

    SingleEffect {
        stateHolder.effects.collect {
            when (it) {
                is LoginUIContract.Effect.OnLoginFailure -> {
                    snackBarState.show(
                        message = it.message,
                        coroutineScope = coroutineScope,
                        duration = 5.seconds
                    )
                }

                LoginUIContract.Effect.OnLoginSuccess -> {
                    navigationController.navigate(
                        routeName = "home", popUpTo = "login", popUpToInclusive = true
                    )
                }

                LoginUIContract.Effect.OnSendRecoveringPasswordStart -> {
                    dialogState.show {
                        Column(
                            modifier = Modifier.fillMaxWidth(),
                            horizontalAlignment = Alignment.CenterHorizontally
                        ) {
                            CircularProgress(50)
                            Text(
                                modifier = Modifier
                                    .margin(top = 8.px)
                                    .textAlign(TextAlign.Center),
                                text = "Enviando email de recuperação de senha"
                            )
                        }
                    }
                }

                LoginUIContract.Effect.OnSendRecoveringPasswordFailure -> {
                    dialogState.dismiss()
                    snackBarState.show(
                        coroutineScope = coroutineScope,
                        message = "Houve um erro ao enviar o email de recuperação, tente novamente."
                    )
                }

                LoginUIContract.Effect.OnSendRecoveringPasswordSuccess -> {
                    dialogState.dismiss()
                    snackBarState.show(
                        coroutineScope = coroutineScope,
                        message = "Email de recuperação de senha enviado! Verifique sua caixa de entrada e siga a instruções!"
                    )
                }

                LoginUIContract.Effect.OnDisplayRecoveringPassword -> {
                    showRecoveryPasswordDialog(
                        dialogState = dialogState,
                        onEvent = {
                            stateHolder.onEvent(it)
                        }
                    )
                }
            }
        }
    }

    when (uiState) {
        is LoginUIContract.State.CheckingIfAlreadyLogged -> {
            Column(
                modifier = Modifier.fillMaxSize(),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                CircularProgress(50)
                Text("Carregando")
            }
        }

        is LoginUIContract.State.Displaying -> {
            Column(
                modifier = Modifier.fillMaxSize(),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                LoginForm(
                    enabled = true,
                    email = uiState.email,
                    password = uiState.password,
                    showPassword = uiState.showPassword,
                    onEvent = { stateHolder.onEvent((it)) }
                )
            }
        }

        is LoginUIContract.State.Logging -> {
            Column(
                modifier = Modifier.fillMaxSize(),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                LoginForm(
                    enabled = false,
                    email = uiState.email,
                    password = uiState.password,
                    showPassword = uiState.showPassword,
                    onEvent = { stateHolder.onEvent((it)) }
                )
            }
        }
    }
}

fun showRecoveryPasswordDialog(
    dialogState: DialogState,
    onEvent: (
        LoginUIContract.Event
    ) -> Unit
) {
    dialogState.show {
        var enableSendButton by remember { mutableStateOf(false) }
        var email by remember { mutableStateOf("") }

        Column(
            modifier = Modifier.fillMaxSize()
        ) {
            Text(
                text = "Recuperar senha",
                modifier = Modifier
                    .fillMaxWidth()
                    .headlineSmall()
            )
            Text(
                text = "Insira o email para o qual o link de recuperação de senha será enviado",
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth()
                    .bodyMedium()
            )

            TextField(
                modifier = Modifier
                    .margin(top = 16.px)
                    .fillMaxWidth(),
                text = email,
                onTextChanged = {
                    email = it
                    enableSendButton = email.isNotBlank()
                }
            )

            Row(
                modifier = Modifier
                    .margin(top = 24.px)
                    .fillMaxWidth(),
                horizontalArrangement = Arrangement.End
            ) {
                HoverableContent(
                    modifier = Modifier.padding(horizontal = 16.px, vertical = 8.px),
                    onHoverEndColor = sitePalette().surface,
                    onClick = { dialogState.dismiss() }
                ) {
                    Text(
                        text = Strings.cancel,
                        modifier = Modifier.bodyLarge()
                    )
                }
                HoverableContent(
                    modifier = Modifier
                        .padding(horizontal = 16.px, vertical = 8.px),
                    onHoverStartColor = if (enableSendButton)
                        sitePalette().secondaryContainer.changeAlpha(0.8f)
                    else
                        sitePalette().surface,
                    onHoverEndColor = sitePalette().surface,
                    cursor = if (enableSendButton) Cursor.Pointer else Cursor.NotAllowed,
                    onClick = {
                        if (enableSendButton) {
                            onEvent(LoginUIContract.Event.OnConfirmRecoverEmail(email))
                            dialogState.dismiss()
                        }
                    }
                ) {
                    Text(
                        text = Strings.recoverPassword,
                        modifier = Modifier
                            .color(
                                if (enableSendButton)
                                    sitePalette().onSurface
                                else
                                    sitePalette().onSurface.changeAlpha(0.5f)
                            )
                            .bodyLarge()
                    )
                }
            }
        }
    }
}

@Composable
private fun LoginForm(
    enabled: Boolean,
    email: String,
    showPassword: Boolean,
    password: String,
    onEvent: (LoginUIContract.Event) -> Unit
) {
    Image(
        src = "/opinion_poll.png", width = 150, height = 150
    )
    Text(
        modifier = Modifier
            .titleLarge()
            .margin(top = 8.px),
        text = "OpinionPro"
    )
    TextField(
        modifier = Modifier
            .margin(top = 24.px)
            .width(300.px),
        text = email,
        hint = "Email",
        onTextChanged = { onEvent(LoginUIContract.Event.OnEmailChange(it)) },
        enabled = enabled
    )
    TextField(
        modifier = Modifier
            .margin(top = 8.px)
            .width(300.px),
        text = password,
        hint = "Senha",
        isPassword = !showPassword,
        onEnterPress = { onEvent(LoginUIContract.Event.OnLoginClick) },
        onTextChanged = { onEvent(LoginUIContract.Event.OnPasswordChange(it)) },
        enabled = enabled,
        trailingIcon = {
            if (!showPassword) {
                MaterialIcon(
                    modifier = Modifier
                        .cursor(Cursor.Pointer)
                        .onClick {
                            onEvent(LoginUIContract.Event.OnTogglePasswordVisibility)
                        },
                    icon = MaterialIcons.Round.Visibility
                )
            } else {
                MaterialIcon(
                    modifier = Modifier
                        .cursor(Cursor.Pointer)
                        .onClick {
                            onEvent(LoginUIContract.Event.OnTogglePasswordVisibility)
                        },
                    icon = MaterialIcons.Round.VisibilityOff
                )
            }
        }
    )
    FilledButton(
        modifier = Modifier
            .width(300.px)
            .margin(top = 16.px),
        onClick = { onEvent(LoginUIContract.Event.OnLoginClick) },
        enabled = enabled,
        loading = !enabled,
        text = "Login",
        icon = null
    )
    Text(
        modifier = Modifier
            .bodyMedium()
            .padding(top = 24.px)
            .color(sitePalette().primary)
            .cursor(Cursor.Pointer)
            .onClick { onEvent(LoginUIContract.Event.OnForgetPasswordClick) },
        text = Strings.forgetPassword
    )
}