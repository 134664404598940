package com.catbit.opinionpoll.inputs.inputs

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.extensions.withNotNull
import com.catbit.opinionpoll.core.sailor.providers.LocalDialogStateProvider
import com.catbit.opinionpoll.core.ui.composables.HoverableContent
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.composables.base_components.*
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.*
import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.inputs.InputType
import com.catbit.opinionpoll.inputs.InputUI
import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.composables.DefaultFormContainer
import com.catbit.opinionpoll.inputs.composables.DefaultFormPreview
import com.catbit.opinionpoll.inputs.events.InputUIEvent
import com.catbit.opinionpoll.inputs.events.SingleChoiceInputEventData
import com.catbit.opinionpoll.res.Strings
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.document
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.px
import org.w3c.dom.Element

class SingleChoiceInput : InputUI<SingleChoiceInputUIState> {
    override fun match(uiState: InputUIState) = uiState is SingleChoiceInputUIState

    @Composable
    override fun Compose(uiState: SingleChoiceInputUIState, onEvent: (InputUIEvent) -> Unit) {

        val dialogState = LocalDialogStateProvider.current
        var textAreaValue by remember { mutableStateOf("") }
        var hadUserInteraction by remember { mutableStateOf(false) }

        LaunchedEffect(uiState.options.lastOrNull()?.actualIdentifier) {
            withNotNull(uiState.options.lastOrNull()?.actualIdentifier) {
                if (hadUserInteraction) {
                    document.getElementById(this)?.focus()
                }
            }
        }

        DefaultFormContainer(
            modifier = Modifier.fillMaxWidth(),
            inputUIState = uiState,
            onEvent = onEvent,
            preview = { SingleChoiceInputPreview(uiState) }
        ) {
            Column(
                modifier = Modifier.fillMaxWidth()
            ) {
                Row(
                    modifier = Modifier.margin(top = 16.px),
                    verticalAlignment = Alignment.Bottom
                ) {
                    Checkbox(
                        modifier = Modifier.margin(right = 8.px),
                        checked = uiState.showSearchBar,
                        onCheck = {
                            onEvent(
                                InputUIEvent(
                                    formIdentifier = uiState.actualIdentifier,
                                    data = SingleChoiceInputEventData.OnShowSearchBarToggle(it)
                                )
                            )
                        }
                    )
                    Text(
                        modifier = Modifier
                            .titleMedium()
                            .margin(right = 8.px)
                            .fontWeight(FontWeight.Bold),
                        text = Strings.displaySearchBar
                    )

                    MaterialIcon(MaterialIcons.Round.Help)
                    Tooltip(
                        target = ElementTarget.PreviousSibling,
                        text = Strings.displaySearchBarTip,
                        placement = PopupPlacement.BottomLeft
                    )
                }
                Text(
                    modifier = Modifier
                        .titleMedium()
                        .fontWeight(FontWeight.Bold)
                        .margin(top = 16.px),
                    text = Strings.style
                )
                OutlinedDropdownList(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(top = 8.px),
                    options = SingleChoiceInputUIState.SingleChoiceStyle.entries.map { it.name },
                    onOptionsSelected = {
                        onEvent(
                            InputUIEvent(
                                formIdentifier = uiState.actualIdentifier,
                                data = SingleChoiceInputEventData.OnStyleChange(
                                    SingleChoiceInputUIState.SingleChoiceStyle.fromString(it)
                                )
                            )
                        )
                    },
                    value = uiState.style.name
                )
                Text(
                    modifier = Modifier
                        .titleMedium()
                        .fontWeight(FontWeight.Bold)
                        .margin(top = 16.px),
                    text = Strings.options
                )
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(top = 8.px)
                        .display(DisplayStyle.Flex)
                        .gap(8.px)
                ) {
                    uiState.options.forEach { option ->
                        Row(
                            modifier = Modifier.fillMaxWidth(),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            TextField(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .margin(right = 8.px),
                                text = option.title,
                                enabled = uiState.isEnabled,
                                inputIdentifier = option.actualIdentifier,
                                hint = Strings.option,
                                onTextChanged = {
                                    onEvent(
                                        InputUIEvent(
                                            formIdentifier = uiState.actualIdentifier,
                                            data = SingleChoiceInputEventData.OnOptionTitleChange(
                                                actualIdentifier = option.actualIdentifier,
                                                newTitle = it
                                            )
                                        )
                                    )
                                },
                                onEnterPress = {
                                    hadUserInteraction = true
                                    onEvent(
                                        InputUIEvent(
                                            formIdentifier = uiState.actualIdentifier,
                                            data = SingleChoiceInputEventData.OnAddOption
                                        )
                                    )
                                }
                            )
                            IconButton(
                                onClick = {
                                    onEvent(
                                        InputUIEvent(
                                            formIdentifier = uiState.actualIdentifier,
                                            data = SingleChoiceInputEventData.OnMoveOptionUp(
                                                actualIdentifier = option.actualIdentifier
                                            )
                                        )
                                    )
                                }
                            ) {
                                MaterialIcon(MaterialIcons.Round.ArrowCircleUp)
                            }
                            Tooltip(
                                target = ElementTarget.PreviousSibling,
                                text = Strings.moveOptionUp
                            )
                            IconButton(
                                onClick = {
                                    onEvent(
                                        InputUIEvent(
                                            formIdentifier = uiState.actualIdentifier,
                                            data = SingleChoiceInputEventData.OnMoveOptionDown(
                                                actualIdentifier = option.actualIdentifier
                                            )
                                        )
                                    )
                                }
                            ) {
                                MaterialIcon(MaterialIcons.Round.ArrowCircleDown)
                            }
                            Tooltip(
                                target = ElementTarget.PreviousSibling,
                                text = Strings.moveOptionDown
                            )

                            IconButton(
                                onClick = {
                                    onEvent(
                                        InputUIEvent(
                                            formIdentifier = uiState.actualIdentifier,
                                            data = SingleChoiceInputEventData.OnOptionRemove(
                                                actualIdentifier = option.actualIdentifier
                                            )
                                        )
                                    )
                                }
                            ) {
                                MaterialIcon(MaterialIcons.Round.HighlightOff)
                            }

                            Tooltip(
                                target = ElementTarget.PreviousSibling,
                                text = Strings.removeOption
                            )
                        }
                    }
                }
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(top = 8.px),
                    horizontalArrangement = Arrangement.Center
                ) {
                    IconButton(
                        onClick = {
                            hadUserInteraction = true
                            onEvent(
                                InputUIEvent(
                                    formIdentifier = uiState.actualIdentifier,
                                    data = SingleChoiceInputEventData.OnAddOption
                                )
                            )
                        }
                    ) {
                        MaterialIcon(MaterialIcons.Round.AddCircleOutline)
                    }

                    Tooltip(
                        target = ElementTarget.PreviousSibling,
                        text = Strings.addOption
                    )

                    IconButton(
                        onClick = {
                            hadUserInteraction = true
                            dialogState.show {
                                Column(
                                    modifier = Modifier.fillMaxSize()
                                ) {
                                    Text(
                                        text = "Adicionar lista de opções",
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .headlineSmall()
                                    )
                                    Text(
                                        text = "Adicione valores separados por quebra de linha ou por \";\"",
                                        modifier = Modifier
                                            .margin(top = 16.px)
                                            .fillMaxWidth()
                                            .bodyMedium()
                                    )
                                    TextArea(
                                        modifier = Modifier
                                            .fillMaxSize()
                                            .margin(top = 16.px),
                                        text = textAreaValue,
                                        lines = 10,
                                        onTextChanged = { textAreaValue = it }
                                    )
                                    Row(
                                        modifier = Modifier
                                            .margin(top = 24.px)
                                            .fillMaxWidth(),
                                        horizontalArrangement = Arrangement.End
                                    ) {
                                        HoverableContent(
                                            modifier = Modifier.padding(horizontal = 16.px, vertical = 8.px),
                                            onHoverEndColor = sitePalette().surface,
                                            onClick = {
                                                dialogState.dismiss()
                                                textAreaValue = ""
                                            }
                                        ) {
                                            Text(
                                                text = Strings.cancel,
                                                modifier = Modifier.bodyLarge()
                                            )
                                        }
                                        HoverableContent(
                                            modifier = Modifier.padding(horizontal = 16.px, vertical = 8.px),
                                            onHoverEndColor = sitePalette().surface,
                                            onClick = {
                                                onEvent(
                                                    InputUIEvent(
                                                        formIdentifier = uiState.actualIdentifier,
                                                        data = SingleChoiceInputEventData.OnAddBatchOptions(rawText = textAreaValue)
                                                    )
                                                )
                                                dialogState.dismiss()
                                                textAreaValue = ""
                                            }
                                        ) {
                                            Text(
                                                text = Strings.add,
                                                modifier = Modifier.bodyLarge()
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    ) {
                        MaterialIcon(MaterialIcons.Round.PostAdd)
                    }

                    Tooltip(
                        target = ElementTarget.PreviousSibling,
                        text = Strings.addOptionsInBatch
                    )
                }

                Row(
                    modifier = Modifier
                        .margin(top = 8.px),
                    verticalAlignment = Alignment.Bottom
                ) {
                    Checkbox(
                        modifier = Modifier.margin(right = 8.px),
                        checked = uiState.hasOtherOption,
                        onCheck = {
                            onEvent(
                                InputUIEvent(
                                    formIdentifier = uiState.actualIdentifier,
                                    data = SingleChoiceInputEventData.OnToggleHasOtherOption(it)
                                )
                            )
                        }
                    )
                    Text(
                        modifier = Modifier
                            .titleMedium()
                            .fontWeight(FontWeight.Bold),
                        text = Strings.displayOthersOption
                    )
                }

                if (uiState.hasOtherOption) {
                    Text(
                        modifier = Modifier
                            .margin(top = 8.px)
                            .fontWeight(FontWeight.Bold)
                            .titleMedium(),
                        text = Strings.textMaxLength
                    )
                    TextField(
                        modifier = Modifier
                            .margin(top = 8.px)
                            .fillMaxWidth(),
                        text = uiState.maxOtherOptionTextLength.toString(),
                        enabled = uiState.isEnabled,
                        onTextChanged = {

                            val newValue = it.takeIf { it.isNotBlank() } ?: "0"

                            onEvent(
                                InputUIEvent(
                                    formIdentifier = uiState.actualIdentifier,
                                    data = SingleChoiceInputEventData.OnOtherOptionTextLengthChange(newValue.toInt())
                                )
                            )
                        }
                    )
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .margin(top = 8.px)
                    ) {
                        TextField(
                            modifier = Modifier
                                .fillMaxWidth()
                                .margin(right = 8.px),
                            text = uiState.otherOptionTitle,
                            enabled = uiState.isEnabled,
                            hint = Strings.textToBeDisplayed,
                            onTextChanged = {
                                onEvent(
                                    InputUIEvent(
                                        formIdentifier = uiState.actualIdentifier,
                                        data = SingleChoiceInputEventData.OnOtherOptionTitleChange(it)
                                    )
                                )
                            }
                        )
                        TextField(
                            modifier = Modifier.fillMaxWidth(),
                            text = uiState.otherOptionPlaceholderText,
                            enabled = uiState.isEnabled,
                            hint = Strings.tip,
                            onTextChanged = {
                                onEvent(
                                    InputUIEvent(
                                        formIdentifier = uiState.actualIdentifier,
                                        data = SingleChoiceInputEventData.OnOtherPlaceholderTextTitleChange(it)
                                    )
                                )
                            }
                        )
                    }
                }
            }
        }
    }
}

@Composable
fun SingleChoiceInputPreview(
    uiState: SingleChoiceInputUIState
) {
    Column(
        modifier = Modifier.fillMaxWidth()
    ) {
        var selectedRadio by remember { mutableStateOf<String?>(null) }
        var placeholderText by remember { mutableStateOf("") }

        with(uiState) {
            DefaultFormPreview(
                index = index,
                title = title,
                subtitle = subtitle
            ) {
                when (uiState.style) {
                    SingleChoiceInputUIState.SingleChoiceStyle.Radio -> {
                        Column(
                            modifier = Modifier
                                .fillMaxWidth()
                                .display(DisplayStyle.Flex)
                                .gap(8.px)
                        ) {
                            uiState.options.forEach { option ->
                                Row(
                                    verticalAlignment = Alignment.CenterVertically
                                ) {
                                    RadioButton(
                                        modifier = Modifier.margin(right = 8.px),
                                        checked = selectedRadio == option.title
                                    ) {
                                        selectedRadio = option.title
                                    }
                                    Text(
                                        modifier = Modifier.bodyLarge(),
                                        text = option.title
                                    )
                                }
                            }

                            if (uiState.hasOtherOption) {
                                Row(
                                    verticalAlignment = Alignment.CenterVertically
                                ) {
                                    RadioButton(
                                        modifier = Modifier.margin(right = 8.px),
                                        checked = selectedRadio == uiState.otherOptionTitle
                                    ) {
                                        selectedRadio = uiState.otherOptionTitle
                                    }
                                    Text(
                                        modifier = Modifier.bodyLarge(),
                                        text = uiState.otherOptionTitle
                                    )
                                }

                                TextField(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .margin(top = 8.px),
                                    text = placeholderText,
                                    hint = uiState.otherOptionPlaceholderText,
                                    onTextChanged = { placeholderText = it }
                                )
                            }
                        }
                    }

                    SingleChoiceInputUIState.SingleChoiceStyle.Dropdown -> {
                        Column(
                            modifier = Modifier.fillMaxWidth()
                        ) {
                            OutlinedDropdownList(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .margin(top = 8.px),
                                options = uiState.options.map { it.title }.toMutableList().apply {
                                    if (uiState.hasOtherOption) {
                                        add(uiState.otherOptionTitle)
                                    }
                                },
                                displaySearchBar = uiState.showSearchBar,
                                value = selectedRadio.orEmpty(),
                                onOptionsSelected = { selectedRadio = it },
                            )

                            if (uiState.hasOtherOption && selectedRadio == uiState.otherOptionTitle) {
                                TextField(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .margin(top = 8.px),
                                    text = placeholderText,
                                    hint = uiState.otherOptionPlaceholderText,
                                    onTextChanged = { placeholderText = it }
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}

data class SingleChoiceInputUIState(
    override val index: Int,
    override val isEnabled: Boolean,
    override val actualIdentifier: String,
    override val identifier: String,
    override val title: String,
    override val subtitle: String,
    override val isObligatory: Boolean,
    override val type: InputType,
    override val errorMessage: String?,
    val options: List<OptionInput>,
    val hasOtherOption: Boolean,
    val otherOptionTitle: String,
    val otherOptionPlaceholderText: String,
    val maxOtherOptionTextLength: Int,
    val style: SingleChoiceStyle,
    val showSearchBar: Boolean
) : InputUIState {

    companion object {
        fun default(
            index: Int
        ) = SingleChoiceInputUIState(
            index = index,
            isEnabled = true,
            actualIdentifier = UUID.stringUUID(),
            identifier = "",
            title = "",
            subtitle = "",
            isObligatory = false,
            type = InputType.SingleChoice,
            errorMessage = null,
            options = listOf(),
            hasOtherOption = false,
            otherOptionTitle = "",
            otherOptionPlaceholderText = "",
            maxOtherOptionTextLength = 50,
            style = SingleChoiceStyle.Radio,
            showSearchBar = false,
        )
    }

    enum class SingleChoiceStyle {
        Radio, Dropdown;

        companion object {
            fun fromString(type: String) = entries.firstOrNull { it.name == type } ?: Radio
        }
    }

    override val internalIdentifiers = listOf(identifier)
    override fun knowsIdentifier(identifier: String) = identifier == this.identifier
    override fun updateIndex(newIndex: Int) = copy(index = newIndex)
    override fun copy(
        index: Int?,
        isEnabled: Boolean?,
        actualIdentifier: String?,
        identifier: String?,
        title: String?,
        subtitle: String?,
        isObligatory: Boolean?,
        type: InputType?,
        error: String?
    ) = copy(
        index = index ?: this.index,
        isEnabled = isEnabled ?: this.isEnabled,
        actualIdentifier = actualIdentifier ?: this.actualIdentifier,
        identifier = identifier ?: this.identifier,
        title = title ?: this.title,
        subtitle = subtitle ?: this.subtitle,
        isObligatory = isObligatory ?: this.isObligatory,
        type = type ?: this.type,
        errorMessage = error ?: this.errorMessage,
    )
}

//TODO Mover
fun Element.focus() {
    val dynElement: dynamic = this
    // Blur is a bad name - it means, here, remove focus
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/blur
    dynElement.focus()
}