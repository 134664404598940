package com.catbit.opinionpoll.inputs.requests.producers

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.inputs.MaskedTextAreaInputUIState
import com.catbit.opinionpoll.inputs.requests.requests.MaskedTextAreaInputRequest

class MaskedTextAreaInputRequestProducer : InputRequestProducer<MaskedTextAreaInputUIState> {
    override fun match(inputUIState: InputUIState) = inputUIState is MaskedTextAreaInputUIState

    override fun produce(inputUIState: MaskedTextAreaInputUIState) = MaskedTextAreaInputRequest(
        identifier = inputUIState.identifier,
        index = inputUIState.index,
        title = inputUIState.title,
        subtitle = inputUIState.subtitle,
        isObligatory = inputUIState.isObligatory,
        mask = inputUIState.mask,
        replaceableChar = inputUIState.replaceableChar.firstOrNull(),
        inputType = inputUIState.inputType.name.lowercase()
    )
}