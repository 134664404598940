package com.catbit.opinionpoll.core.extensions

import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.PopupPlacementStrategy
import org.w3c.dom.Window

fun Window.bestPlacementOf(
    width: Double,
    xPos: Double,
    yPos: Double
) = PopupPlacementStrategy.of(
    if (yPos >= innerHeight * 0.3 && yPos <= innerHeight * 0.6) {
        if (xPos + width >= innerWidth / 2) {
            PopupPlacement.Left
        } else {
            PopupPlacement.Right
        }
    } else if (yPos >= innerHeight / 2) {
        if (xPos + width >= innerWidth / 2) {
            PopupPlacement.TopRight
        } else {
            PopupPlacement.TopLeft
        }
    } else {
        if (xPos + width >= innerWidth / 2) {
            PopupPlacement.BottomRight
        } else {
            PopupPlacement.BottomLeft
        }
    }
)