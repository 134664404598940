package com.catbit.opinionpoll.inputs.responses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Time")
data class TimeInputResponse(
    override val identifier: String,
    override val title: String,
    override val subtitle: String?,
    override val isObligatory: Boolean,
    override val index: Int,
    @SerialName("minTime")
    val minTime: String?,
    @SerialName("maxTime")
    val maxTime: String?
) : InputResponse