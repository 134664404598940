package com.catbit.opinionpoll.ui.states

import com.catbit.opinionpoll.inputs.state.InputGraphUIState

sealed interface StatsUIState {
    data object Loading : StatsUIState
    data object Failure : StatsUIState
    data class Loaded(
        val totalAnswersByFormChart: InputGraphUIState,
        val totalAnswers: Int,
        val totalForms: Int,
        val totalUsers: Int,
    ) : StatsUIState
}
