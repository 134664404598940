package com.catbit.opinionpoll.core.remember

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.events.WindowOnUnloadEventHandler
import kotlinx.browser.sessionStorage
import org.w3c.dom.get

@Composable
inline fun <reified T> rememberOnSessionStorage(
    key: String,
    translator: SessionDataTranslator<T>,
    crossinline initBlock: @DisallowComposableCalls () -> T
): MutableState<T> {

    val value = remember {
        val storedValue = sessionStorage[key]?.let { translator.restore(it) }
        mutableStateOf(storedValue ?: initBlock())
    }

    LaunchedEffect(Unit) {
        WindowOnUnloadEventHandler.addOnWindowUnloadListener {
            sessionStorage.setItem(key, translator.translate(value.value))
        }
    }

    return value
}

interface SessionDataTranslator<out T> {
    fun translate(value: @UnsafeVariance T): String
    fun restore(restoreRawValue: String): T
}

val StringSessionDataTranslator = object : SessionDataTranslator<String> {
    override fun translate(value: String) = value
    override fun restore(restoreRawValue: String) = restoreRawValue
}

@Composable
fun rememberString(
    key: String,
    initialValue: String
) = rememberOnSessionStorage(
    key = key,
    translator = StringSessionDataTranslator
) {
    initialValue
}