package com.catbit.opinionpoll.core.strings

import kotlin.properties.ReadOnlyProperty
import kotlin.reflect.KProperty

class StringResource<T>(
    vararg translations: Pair<StringLocale, String>
) : ReadOnlyProperty<T, String> {

    private var translations: List<Pair<StringLocale, String>>

    init {
        require(translations.isNotEmpty()) {
            "No translation provided!"
        }
        this.translations = translations.toList()
    }

    override fun getValue(thisRef: T, property: KProperty<*>) = with(getSystemLocale()) {
        translations.firstOrNull { it.first.languageCode == this }
            ?.second ?: translations.first().second
    }
}
