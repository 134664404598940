package com.catbit.opinionpoll.inputs

import androidx.compose.runtime.Composable
import com.catbit.opinionpoll.inputs.events.InputUIEvent

class InputComposer(
    private val formsUIState: List<InputUI<*>>
) {
    @Composable
    fun Compose(
        uiState: InputUIState,
        onEvent: (InputUIEvent) -> Unit
    ) {
        formsUIState
            .firstOrNull { it.match(uiState) }?.Compose(
                uiState = uiState,
                onEvent = onEvent
            )
    }
}