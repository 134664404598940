package com.catbit.opinionpoll.inputs.requests.requests

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("MultipleChoicesOptions")
data class MultipleChoicesOptionsInputRequest(
    override val identifier: String,
    override val title: String,
    override val subtitle: String?,
    override val isObligatory: Boolean,
    override val index: Int,
    @SerialName("options")
    val options: List<String>,
    @SerialName("style")
    val style: String,
    @SerialName("minSelection")
    val minSelection: Int,
    @SerialName("maxSelection")
    val maxSelection: Int,
    @SerialName("enableOtherOptions")
    val enableOtherOptions: Boolean?,
    @SerialName("otherOptionPlaceholderText")
    val otherOptionPlaceholderText: String?,
    @SerialName("maxOtherOptions")
    val maxOtherOptions: Int?,
    @SerialName("maxOtherOptionTextLength")
    val maxOtherOptionTextLength: Int?
) : InputRequest
