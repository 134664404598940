package com.catbit.opinionpoll.core.ui.helpers

import com.catbit.opinionpoll.core.extensions.withNotNull
import kotlinx.browser.document
import org.w3c.dom.AUTO
import org.w3c.dom.INSTANT
import org.w3c.dom.ScrollBehavior
import org.w3c.dom.ScrollToOptions

fun scrollToPosition(
    elementId: String,
    containerId: String,
    scrollBehavior: ScrollBehavior = ScrollBehavior.INSTANT
) {
    withNotNull(document.getElementById(elementId)) {
        scrollContent(
            elementId = containerId,
            mode = ScrollMode.Position(
                left = 0.0,
                top = scrollTop
            ),
            scrollBehavior = scrollBehavior
        )
    }
}

fun scrollContent(
    elementId: String,
    mode: ScrollMode,
    scrollBehavior: ScrollBehavior = ScrollBehavior.AUTO
) {
    withNotNull(document.getElementById(elementId)) {

        val (top, left) = when (mode) {
            ScrollMode.Bottom -> scrollHeight.toDouble() to undefined
            ScrollMode.End -> undefined to scrollWidth.toDouble()
            is ScrollMode.Position -> mode.top to mode.left
            ScrollMode.Start -> undefined to 0.0
            ScrollMode.Top -> 0.0 to undefined
        }

        scroll(options = ScrollToOptions(top = top, left = left, behavior = scrollBehavior))
    }
}

sealed interface ScrollMode {
    data object Top : ScrollMode
    data object Bottom : ScrollMode
    data object Start : ScrollMode
    data object End : ScrollMode
    data class Position(
        val top: Double? = undefined,
        val left: Double? = undefined,
    ) : ScrollMode
}