package com.catbit.opinionpoll.core.ui.modifiers

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier

@Composable
fun <T> Modifier.thenIfNotNull(
    receiver: T?,
    block: @Composable Modifier.(T) -> Modifier
): Modifier {
    return receiver?.let {
        then(block(receiver))
    } ?: then(this)
}