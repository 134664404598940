package com.catbit.opinionpoll.data.models

import com.catbit.opinionpoll.inputs.models.InputModel
import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class FormModel(
    val identifier: String,
    val version: Long,
    val tips: String?,
    val title: String,
    val creationDate: LocalDateTime,
    val expirationDate: LocalDateTime,
    val sendGeoCoordinates: Boolean,
    val inputs: List<InputModel>,
    val assignedTo: List<String>,
    val filters: List<FormFilterModel>?
)
