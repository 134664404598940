package com.catbit.opinionpoll.core.state_holder

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.cancel

open class StateHolder {

    lateinit var stateHolderScope: CoroutineScope
        private set

    /*** Call the onStarted method, so resources can be started ***/
    fun onStart() {
        stateHolderScope = CoroutineScope(Dispatchers.Main)
        onStarted()
    }

    /*** Called when the StateHolder is first created ***/
    open fun onStarted() = Unit

    /*** Call the onRecover method. Use this method like onResume from the Android lib ***/
    fun onRecover() {
        if (stateHolderScope.coroutineContext[Job]?.isCancelled == true) {
            stateHolderScope = CoroutineScope(Dispatchers.Main)
        }
        onRecovered()
    }

    /*** Called when the StateHolder is recovered from the factory ***/
    open fun onRecovered() = Unit

    /*** Call the onDispose method, so resources can be cleared, like connections, and cancel the coroutine scope ***/
    fun onDispose() {
        onDisposed()
        stateHolderScope.cancel()
    }

    /*** Called when the Composition where the StateHolder was being used was disposed ***/
    open fun onDisposed() = Unit
}
