package com.catbit.opinionpoll.core.ui.helpers

import androidx.compose.runtime.*
import kotlinx.browser.document

internal val rootMobileModeState by lazy { mutableStateOf((document.documentElement?.clientWidth ?: 0) < 600) }

val LocalMobileMode = compositionLocalOf { rootMobileModeState }
val isMobileMode @Composable get() = LocalMobileMode.current.value

val mobileModeStateValue get() = rootMobileModeState.value

fun setMobileMode(isMobileMode: Boolean) {
    var mm by rootMobileModeState
    mm = isMobileMode
}