package com.catbit.opinionpoll.core.sailor.overlays.dialog

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.ui.animations.AnimationHolder
import com.catbit.opinionpoll.core.ui.animations.FadeInAnimation
import com.catbit.opinionpoll.core.ui.animations.FadeOutAnimation
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.catbit.opinionpoll.core.ui.helpers.isMobileMode
import com.catbit.opinionpoll.core.ui.helpers.mobileModeStateValue
import com.varabyte.kobweb.compose.css.Visibility
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import org.jetbrains.compose.web.css.CSSNumeric
import org.jetbrains.compose.web.css.minus
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

class DialogState(initialState: Visibility) {
    var visibility by mutableStateOf(initialState)
        private set

    var isCancellable by mutableStateOf(false)

    var currentContent: @Composable () -> Unit = {}
        private set

    private var onDismissCallback: (() -> Unit)? = null

    var enterTransition: AnimationHolder = FadeInAnimation()
        private set

    var exitTransition: AnimationHolder = FadeOutAnimation()
        private set

    fun dismiss() {
        onDismissCallback?.invoke()
        visibility = Visibility.Hidden
        currentContent = {}
    }

    fun show(
        usesDefaultDialogContainer: Boolean = true,
        width: CSSNumeric = if(mobileModeStateValue) 100.percent.minus(48.px) else 400.px,
        cancellable: Boolean = false,
        onDismiss: (() -> Unit)? = null,
        content: @Composable () -> Unit
    ) {
        isCancellable = cancellable
        onDismissCallback = onDismiss
        currentContent = if (usesDefaultDialogContainer) {
            @Composable {
                Box(
                    modifier = Modifier
                        .width(width)
                        .clip(Rect(16.px))
                        .padding(24.px)
                        .background(
                            color = sitePalette().surface,
                        )
                        .padding(16.px)
                ) {
                    content()
                }
            }
        } else content
        visibility = Visibility.Visible
    }
}

@Composable
fun rememberDialogState() = remember { DialogState(Visibility.Hidden) }