package com.catbit.opinionpoll.core.ui.modifiers

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.styleModifier

fun Modifier.displayLarge(): Modifier {
    return then(this).styleModifier {
        property("font-size", "57px")
        property("line-height", "64px")
        property("letter-spacing", "-0.25px")
        property("font-style", "normal")
        property("font-weight", "900")
    }
}

fun Modifier.displayMedium(): Modifier {
    return then(this).styleModifier {
        property("font-size", "45px")
        property("line-height", "52px")
        property("letter-spacing", "0px")
        property("font-style", "normal")
        property("font-weight", "900")
    }
}

fun Modifier.displaySmall(): Modifier {
    return then(this).styleModifier {
        property("font-size", "36px")
        property("line-height", "44px")
        property("letter-spacing", "0px")
        property("font-style", "normal")
        property("font-weight", "900")
    }
}

fun Modifier.headlineLarge(): Modifier {
    return then(this).styleModifier {
        property("font-size", "32px")
        property("line-height", "40px")
        property("letter-spacing", "0px")
        property("font-style", "normal")
        property("font-weight", "bold")
    }
}

fun Modifier.headlineMedium(): Modifier {
    return then(this).styleModifier {
        property("font-size", "28px")
        property("line-height", "36px")
        property("letter-spacing", "0px")
        property("font-style", "normal")
        property("font-weight", "bold")
    }
}

fun Modifier.headlineSmall(): Modifier {
    return then(this).styleModifier {
        property("font-size", "24px")
        property("line-height", "32px")
        property("letter-spacing", "0px")
        property("font-style", "normal")
        property("font-weight", "bold")
    }
}

fun Modifier.titleLarge(): Modifier {
    return then(this).styleModifier {
        property("font-size", "22px")
        property("line-height", "28px")
        property("letter-spacing", "0px")
        property("font-style", "normal")
        property("font-weight", "500")
    }
}

fun Modifier.titleMedium(): Modifier {
    return then(this).styleModifier {
        property("font-size", "16px")
        property("line-height", "24px")
        property("letter-spacing", "0.15px")
        property("font-style", "normal")
        property("font-weight", "500")
    }
}

fun Modifier.titleSmall(): Modifier {
    return then(this).styleModifier {
        property("font-size", "14px")
        property("line-height", "20px")
        property("letter-spacing", "0.1px")
        property("font-style", "normal")
        property("font-weight", "500")
    }
}

fun Modifier.bodyLarge(): Modifier {
    return then(this).styleModifier {
        property("font-size", "16px")
        property("line-height", "24px")
        property("letter-spacing", "0.5px")
        property("font-style", "normal")
        property("font-weight", "normal")
    }
}

fun Modifier.bodyMedium(): Modifier {
    return then(this).styleModifier {
        property("font-size", "14px")
        property("line-height", "20px")
        property("letter-spacing", "0.25px")
        property("font-style", "normal")
        property("font-weight", "normal")
    }
}

fun Modifier.bodySmall(): Modifier {
    return then(this).styleModifier {
        property("font-size", "12px")
        property("line-height", "16px")
        property("letter-spacing", "0.4px")
        property("font-style", "normal")
        property("font-weight", "normal")
    }
}

fun Modifier.labelLarge(): Modifier {
    return then(this).styleModifier {
        property("font-size", "14px")
        property("line-height", "20px")
        property("letter-spacing", "0.1px")
        property("font-style", "normal")
        property("font-weight", "300")
    }
}

fun Modifier.labelMedium(): Modifier {
    return then(this).styleModifier {
        property("font-size", "12px")
        property("line-height", "16px")
        property("letter-spacing", "0.5px")
        property("font-style", "normal")
        property("font-weight", "300")
    }
}

fun Modifier.labelSmall(): Modifier {
    return then(this).styleModifier {
        property("font-size", "11px")
        property("line-height", "16px")
        property("letter-spacing", "0.5px")
        property("font-style", "normal")
        property("font-weight", "300")
    }
}