package com.catbit.opinionpoll.ui.screens.user_maker

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.rememberCoroutineScope
import com.catbit.opinionpoll.core.extensions.getStateHolder
import com.catbit.opinionpoll.core.sailor.navigation_controller.NavigationController
import com.catbit.opinionpoll.core.sailor.providers.LocalDialogStateProvider
import com.catbit.opinionpoll.core.sailor.providers.LocalSnackbarState
import com.catbit.opinionpoll.core.ui.composables.CircularProgress
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.composables.base_components.*
import com.catbit.opinionpoll.core.ui.composables.effects.SingleEffect
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.noWrap
import com.catbit.opinionpoll.core.ui.modifiers.titleMedium
import com.catbit.opinionpoll.data.models.UserModel
import com.catbit.opinionpoll.ui.screens.user_maker.UserMakerUIContract.Event
import com.catbit.opinionpoll.ui.screens.user_maker.UserMakerUIContract.State
import com.catbit.opinionpoll.res.Strings
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.catbit.opinionpoll.ui.states.UserUIState
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.coroutines.flow.collectLatest
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.koin.core.parameter.parametersOf

@Composable
fun UserMakerScreen(
    stackEntryId: String,
    navigationController: NavigationController,
    userModel: UserModel?,
    stateHolder: UserMakerStateHolder = getStateHolder(
        stackEntryId = stackEntryId,
        parameters = parametersOf(userModel)
    )
) {
    val uiState = stateHolder.uiState.collectAsState().value
    val dialogState = LocalDialogStateProvider.current
    val coroutineScope = rememberCoroutineScope()
    val snackBarState = LocalSnackbarState.current

    SingleEffect {
        stateHolder.effects.collectLatest {
            when (it) {
                UserMakerUIContract.Effect.OnStarSubmitting -> {
                    dialogState.show {
                        Column(
                            modifier = Modifier.fillMaxWidth(),
                            horizontalAlignment = Alignment.CenterHorizontally
                        ) {
                            CircularProgress(50)
                            Text(
                                modifier = Modifier.margin(top = 8.px),
                                text = "Enviando alterações"
                            )
                        }
                    }
                }

                is UserMakerUIContract.Effect.OnSubmitFailure -> {
                    dialogState.dismiss()
                    snackBarState.show(
                        message = it.message,
                        coroutineScope = coroutineScope
                    )
                }

                UserMakerUIContract.Effect.OnSubmitSuccess -> {
                    dialogState.dismiss()
                    snackBarState.show(
                        message = "Alterações enviadas com sucesso!",
                        coroutineScope = coroutineScope,
                        onDismiss = {
                            navigationController.goBack()
                        }
                    )
                }
            }
        }
    }

    Column(
        modifier = Modifier
            .fillMaxSize()
            .overflow(Overflow.Hidden)
    ) {
        Toolbar(
            title = uiState.toolbarTitle,
            onLeftIconClick = { navigationController.goBack() },
            trailingContent = {
                if (uiState is State.Displaying) {
                    IconButton(
                        onClick = { stateHolder.onEvent(Event.OnSubmit) }
                    ) {
                        MaterialIcon(icon = MaterialIcons.Round.CloudUpload)
                    }
                    Tooltip(
                        target = ElementTarget.PreviousSibling,
                        text = "Salvar membro de equipe",
                        placement = PopupPlacement.BottomRight
                    )
                }
            }
        )

        when (uiState) {
            is State.Displaying -> DisplayingState(
                name = uiState.name,
                surname = uiState.surname,
                email = uiState.email,
                phoneNumber = uiState.phoneNumber,
                role = uiState.role,
                availableRoles = uiState.availableRoles,
                onEvent = {
                    stateHolder.onEvent(it)
                }
            )

            is State.Loading -> LoadingState()
            is State.Failure -> FailureState(
                onEvent = {
                    stateHolder.onEvent(it)
                }
            )
        }
    }
}

@Composable
private fun LoadingState() {
    Column(
        modifier = Modifier.fillMaxSize(),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        CircularProgress(50)
        Text("Carregando")
    }
}

@Composable
private fun FailureState(
    onEvent: (Event) -> Unit
) {
    Column(
        modifier = Modifier.fillMaxSize(),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        MaterialIcon(MaterialIcons.Round.Error)
        Text("Houve uma falha ao carregar os dados!")
        FilledButton(
            modifier = Modifier
                .margin(top = 8.px),
            text = "Tentar novamente",
            icon = null,
            onClick = {
                onEvent(Event.TryAgain)
            }
        )
    }
}

@Composable
private fun DisplayingState(
    name: String,
    surname: String,
    email: String,
    phoneNumber: String,
    role: UserUIState.Role,
    availableRoles: List<UserUIState.Role>,
    onEvent: (Event) -> Unit
) {
    Column(
        modifier = Modifier
            .fillMaxSize()
            .padding {
                left(10.percent)
                right(10.percent)
                bottom(24.px)
            }
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .borderRadius(16.px)
                .clip(Rect(16.px))
                .padding(all = 16.px)
                .background(sitePalette().surface)
        ) {
            Text(
                modifier = Modifier
                    .margin(top = 16.px)
                    .fillMaxWidth()
                    .noWrap()
                    .titleMedium()
                    .fontWeight(FontWeight.Bold),
                text = Strings.name
            )
            TextField(
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                text = name,
                onTextChanged = {
                    onEvent(
                        Event.OnNameChange(it)
                    )
                },
                trailingIcon = {
                    MaterialIcon(MaterialIcons.Round.Person)
                }
            )
            Text(
                modifier = Modifier
                    .margin(top = 16.px)
                    .fillMaxWidth()
                    .noWrap()
                    .titleMedium()
                    .fontWeight(FontWeight.Bold),
                text = Strings.surname
            )
            TextField(
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                text = surname,
                onTextChanged = {
                    onEvent(
                        Event.OnSurnameChange(it)
                    )
                },
                trailingIcon = {
                    MaterialIcon(MaterialIcons.Round.Group)
                }
            )
            Text(
                modifier = Modifier
                    .margin(top = 16.px)
                    .fillMaxWidth()
                    .noWrap()
                    .titleMedium()
                    .fontWeight(FontWeight.Bold),
                text = Strings.email
            )
            TextField(
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                text = email,
                onTextChanged = {
                    onEvent(
                        Event.OnEmailChange(it)
                    )
                },
                trailingIcon = {
                    MaterialIcon(MaterialIcons.Round.Mail)
                }
            )
            Text(
                modifier = Modifier
                    .margin(top = 16.px)
                    .fillMaxWidth()
                    .noWrap()
                    .titleMedium()
                    .fontWeight(FontWeight.Bold),
                text = Strings.phoneNumber
            )
            TextField(
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                text = phoneNumber,
                onTextChanged = {},
                onKeyDown = {
                    onEvent(
                        Event.OnPhoneNumberChange(it)
                    )
                },
                trailingIcon = {
                    MaterialIcon(MaterialIcons.Round.Phone)
                }
            )
            Text(
                modifier = Modifier
                    .margin(top = 16.px)
                    .fillMaxWidth()
                    .noWrap()
                    .titleMedium()
                    .fontWeight(FontWeight.Bold),
                text = Strings.role
            )
            OutlinedDropdownList(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                value = role.role,
                options = availableRoles.map { it.role },
                onOptionsSelected = {
                    onEvent(
                        Event.OnRoleChange(it)
                    )
                }
            )
        }
    }
}