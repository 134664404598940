package com.catbit.opinionpoll.inputs.inputs

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.extensions.joda
import com.catbit.opinionpoll.core.ui.composables.base_components.DatePicker
import com.catbit.opinionpoll.core.ui.composables.base_components.Text
import com.catbit.opinionpoll.core.ui.modifiers.titleMedium
import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.inputs.InputType
import com.catbit.opinionpoll.inputs.InputUI
import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.composables.DefaultFormContainer
import com.catbit.opinionpoll.inputs.composables.DefaultFormPreview
import com.catbit.opinionpoll.inputs.events.DateInputData
import com.catbit.opinionpoll.inputs.events.InputUIEvent
import com.catbit.opinionpoll.res.Strings
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import kotlinx.datetime.LocalDate
import org.jetbrains.compose.web.css.px

class DateFormInput : InputUI<DateInputUIState> {
    override fun match(uiState: InputUIState) = uiState is DateInputUIState

    @Composable
    override fun Compose(uiState: DateInputUIState, onEvent: (InputUIEvent) -> Unit) {
        DefaultFormContainer(
            modifier = Modifier.fillMaxWidth(),
            inputUIState = uiState,
            onEvent = onEvent,
            preview = { DateInputPreview(uiState) }
        ) {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(top = 16.px)
            ) {
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(right = 16.px)
                ) {
                    Text(
                        modifier = Modifier
                            .titleMedium()
                            .fontWeight(FontWeight.Bold),
                        text = Strings.minDate
                    )
                    DatePicker(
                        modifier = Modifier
                            .fillMaxWidth()
                            .margin(top = 8.px),
                        value = uiState.minDate?.joda(),
                        onDatePick = {
                            onEvent(
                                InputUIEvent(
                                    formIdentifier = uiState.actualIdentifier,
                                    data = DateInputData.OnMinDateChange(it)
                                )
                            )
                        }
                    )
                }
                Column(
                    modifier = Modifier.fillMaxWidth()
                ) {
                    Text(
                        modifier = Modifier
                            .titleMedium()
                            .fontWeight(FontWeight.Bold),
                        text = Strings.maxDate
                    )
                    DatePicker(
                        modifier = Modifier
                            .fillMaxWidth()
                            .margin(top = 8.px),
                        value = uiState.maxDate?.joda(),
                        onDatePick = {
                            onEvent(
                                InputUIEvent(
                                    formIdentifier = uiState.actualIdentifier,
                                    data = DateInputData.OnMaxDateChange(it)
                                )
                            )
                        }
                    )
                }
            }
        }
    }

    @Composable
    private fun DateInputPreview(uiState: DateInputUIState) {
        var selectedDate by remember {
            mutableStateOf<LocalDate?>(null)
        }

        with(uiState) {
            DefaultFormPreview(
                index = index,
                title = title,
                subtitle = subtitle
            ) {
                DatePicker(
                    modifier = Modifier.fillMaxWidth(),
                    value = selectedDate?.joda(),
                    onDatePick = { selectedDate = it }
                )
            }
        }
    }
}

data class DateInputUIState(
    override val index: Int,
    override val isEnabled: Boolean,
    override val actualIdentifier: String,
    override val identifier: String,
    override val title: String,
    override val subtitle: String,
    override val isObligatory: Boolean,
    override val type: InputType,
    override val errorMessage: String?,
    val minDate: LocalDate?,
    val maxDate: LocalDate?,
) : InputUIState {

    companion object {
        fun default(
            index: Int
        ) = DateInputUIState(
            index = index,
            isEnabled = true,
            actualIdentifier = UUID.stringUUID(),
            identifier = "",
            title = "",
            subtitle = "",
            isObligatory = false,
            errorMessage = null,
            type = InputType.Date,
            minDate = null,
            maxDate = null
        )
    }

    override val internalIdentifiers = listOf(identifier)
    override fun knowsIdentifier(identifier: String) = identifier == this.identifier
    override fun updateIndex(newIndex: Int) = copy(index = newIndex)
    override fun copy(
        index: Int?,
        isEnabled: Boolean?,
        actualIdentifier: String?,
        identifier: String?,
        title: String?,
        subtitle: String?,
        isObligatory: Boolean?,
        type: InputType?,
        error: String?
    ) = copy(
        index = index ?: this.index,
        isEnabled = isEnabled ?: this.isEnabled,
        actualIdentifier = actualIdentifier ?: this.actualIdentifier,
        identifier = identifier ?: this.identifier,
        title = title ?: this.title,
        subtitle = subtitle ?: this.subtitle,
        isObligatory = isObligatory ?: this.isObligatory,
        type = type ?: this.type,
        errorMessage = error ?: this.errorMessage,
    )
}