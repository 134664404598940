package com.catbit.opinionpoll.inputs.events_handler

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.events.DateInputData
import com.catbit.opinionpoll.inputs.events.InputUIEventDefaultData
import com.catbit.opinionpoll.inputs.inputs.DateInputUIState

class DateInputUIEventHandler : InputUIEventHandler<DateInputUIState> {

    override fun match(formIdentifier: String, uiState: InputUIState) =
        uiState is DateInputUIState && formIdentifier == uiState.actualIdentifier

    override fun handle(uiState: DateInputUIState, eventData: Any) = when (eventData) {
        is DateInputData.OnMinDateChange -> uiState.copy(minDate = eventData.newMinDate)
        is DateInputData.OnMaxDateChange -> uiState.copy(maxDate = eventData.newMaxDate)
        is InputUIEventDefaultData.IdentifierChange -> uiState.copy(identifier = eventData.newIdentifier)
        is InputUIEventDefaultData.TitleChange -> uiState.copy(title = eventData.newTitle)
        is InputUIEventDefaultData.SubtitleChange -> uiState.copy(subtitle = eventData.newSubtitle)
        is InputUIEventDefaultData.ObligatoryToggle -> uiState.copy(isObligatory = eventData.isObligatory)
        else -> uiState
    }
}