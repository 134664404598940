package com.catbit.opinionpoll.data.models

import kotlinx.serialization.Serializable

@Serializable
data class FormFilterModel(
    val identifier: String,
    val title: String,
    val groupingField: String,
    val subgroups: List<FormFilterSubgroupFieldModel>,
    val fieldsToDisplay: List<String>
) {
    @Serializable
    data class FormFilterSubgroupFieldModel(
        val identifier: String,
        val title: String,
        val values: List<String>
    )
}