package com.catbit.opinionpoll.core.ui.composables.base_components

import androidx.compose.runtime.Composable
import com.catbit.opinionpoll.core.ui.composables.HoverableContent
import com.catbit.opinionpoll.core.ui.modifiers.bodyLarge
import com.catbit.opinionpoll.core.ui.modifiers.bodyMedium
import com.catbit.opinionpoll.core.ui.modifiers.headlineSmall
import com.catbit.opinionpoll.core.ui.modifiers.padding
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.px

@Composable
fun Dialog(
    modifier: Modifier = Modifier,
    title: String,
    body: String,
    positiveText: String,
    onPositiveClick: () -> Unit,
    negativeText: String,
    onNegativeClick: () -> Unit,
) {
    Column(
        modifier = modifier
    ) {
        Text(
            text = title,
            modifier = Modifier
                .fillMaxWidth()
                .headlineSmall()
        )
        Text(
            text = body,
            modifier = Modifier
                .margin(top = 16.px)
                .fillMaxWidth()
                .bodyMedium()
        )
        Row(
            modifier = Modifier
                .margin(top = 24.px)
                .fillMaxWidth(),
            horizontalArrangement = Arrangement.End
        ) {
            HoverableContent(
                modifier = Modifier.padding(horizontal = 16.px, vertical = 8.px),
                onHoverEndColor = sitePalette().surface,
                onClick = onNegativeClick
            ) {
                Text(
                    text = negativeText,
                    modifier = Modifier.bodyLarge()
                )
            }
            HoverableContent(
                modifier = Modifier.padding(horizontal = 16.px, vertical = 8.px),
                onHoverEndColor = sitePalette().surface,
                onClick = onPositiveClick
            ) {
                Text(
                    text = positiveText,
                    modifier = Modifier.bodyLarge()
                )
            }
        }
    }
}