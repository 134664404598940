package com.catbit.opinionpoll.inputs.events_handler

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.events.InputUIEventDefaultData
import com.catbit.opinionpoll.inputs.events.MaskedTextAreaInputEventData
import com.catbit.opinionpoll.inputs.inputs.MaskedTextAreaInputUIState

class MaskedTextAreaInputUIEventHandler : InputUIEventHandler<MaskedTextAreaInputUIState> {

    override fun match(formIdentifier: String, uiState: InputUIState) =
        uiState is MaskedTextAreaInputUIState && formIdentifier == uiState.actualIdentifier

    override fun handle(uiState: MaskedTextAreaInputUIState, eventData: Any) = when (eventData) {
        is MaskedTextAreaInputEventData.OnMaskChange -> uiState.copy(mask = eventData.newMask)
        is MaskedTextAreaInputEventData.OnReplaceableCharChange -> uiState.copy(replaceableChar = eventData.newReplaceableChar)
        is MaskedTextAreaInputEventData.OnInputTypeChange -> uiState.copy(inputType = eventData.newType)
        is InputUIEventDefaultData.IdentifierChange -> uiState.copy(identifier = eventData.newIdentifier)
        is InputUIEventDefaultData.TitleChange -> uiState.copy(title = eventData.newTitle)
        is InputUIEventDefaultData.SubtitleChange -> uiState.copy(subtitle = eventData.newSubtitle)
        is InputUIEventDefaultData.ObligatoryToggle -> uiState.copy(isObligatory = eventData.isObligatory)
        else -> uiState
    }
}