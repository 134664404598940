package com.catbit.opinionpoll.core.extensions

import com.catbit.opinionpoll.core.data.models.LatLong
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.LocalTime

private val dateTimeRegex = "\\d{2}/\\d{2}/\\d{4} \\d{2}:\\d{2}".toRegex()
private val dateRegex = "\\d{2}/\\d{2}/\\d{4}".toRegex()
private val timeRegex = "\\d{2}:\\d{2}".toRegex()
private val emailValidationRegex = "^[\\w.-]+@([\\w-]+\\.)+[\\w]{2,4}$".toRegex()

fun String.toBrazilianPhoneFormat(): String {
    require("\\+[0-9]{13}".toRegex().matches(this)) {
        "Phone number must be in the complete form (+<Country Code><9th Digit><8 Phone numbers>)"
    }
    val cleanedPhoneNumber = replace("[^0-9]".toRegex(), "").drop(2)
    require(cleanedPhoneNumber.length == 11)

    return cleanedPhoneNumber.applyMask('#', "(##) # ####-####")
}

fun String.applyMask(replaceableChar: Char, mask: String): String {
    require(mask.contains(replaceableChar)) {
        "Mask \"$mask\" must contains at least one character \"$replaceableChar\""
    }
    require(this.length == mask.count { it == replaceableChar }) {
        "The replaceable part of the mask \"$mask\" should have the same length of \"$this\""
    }
    var replacementIndex = 0
    return buildString {
        mask.forEach { char ->
            append(
                if (char == replaceableChar) {
                    this@applyMask[replacementIndex++]
                } else {
                    char
                }
            )
        }
    }
}

fun String.applyDynamicMask(replaceableChar: Char, mask: String): String {
    require(mask.contains(replaceableChar)) {
        "Mask \"$mask\" must contains at least one character \"$replaceableChar\""
    }
    var replacementIndex = 0
    return buildString {
        mask.forEach { char ->
            if (replacementIndex > this@applyDynamicMask.length - 1) return@forEach
            append(
                if (char == replaceableChar) {
                    this@applyDynamicMask[replacementIndex++]
                } else {
                    char
                }
            )
        }
    }
}

fun String.toLatLong(): LatLong {
    val (strLat, strLong) = split(",")
    return LatLong(strLat.toDouble(), strLong.toDouble())
}

fun String.toLocalDate() = with(split("/")) {
    LocalDate(this[0].toInt(), this[1].toInt(), this[2].toInt())
}

fun String.toLocalTime() = with(split(":")) {
    LocalTime(this[0].toInt(), this[1].toInt())
}

fun String.toLocalDateTime() = with(split(" ")) {
    val (day, month, year) = this[0].split("/")
    val (hour, minute) = this[1].split(":")
    LocalDateTime(year.toInt(), month.toInt(), day.toInt(), hour.toInt(), minute.toInt())
}

fun String.capitalize() = replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }

fun String.constrainIn(
    size: Int,
    trailingSize: Int = 20,
    middleReplacement: String = " ... "
): String {

    if (length <= size) return this

    return take(size - middleReplacement.length) + middleReplacement + takeLast(trailingSize - middleReplacement.length)
}

fun String.isANumber() = try {
    this.toDouble()
    true
} catch (e: Throwable) {
    false
}

fun String.isADate() = dateRegex.matches(this)

fun String.isATime() = timeRegex.matches(this)

fun String.isADateTime() = dateTimeRegex.matches(this)

fun String.isAValidEmail() = emailValidationRegex.matches(this)
