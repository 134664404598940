package com.catbit.opinionpoll.data.models

import com.catbit.opinionpoll.ui.states.FormUIState
import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SimplifiedFormModel(
    @SerialName("identifier")
    val identifier: String,
    @SerialName("title")
    val title: String,
    @SerialName("creationDate")
    val creationDate: LocalDateTime,
    @SerialName("expirationDate")
    val expirationDate: LocalDateTime,
    @SerialName("createdBy")
    val createdBy: String
) {
    fun toFormUIState() = FormUIState(
        identifier = identifier,
        title = title,
        creationDate = creationDate,
        expirationDate = expirationDate,
        createdBy = createdBy,
        isSelectedForDeletion = false
    )
}
