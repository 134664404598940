package com.catbit.opinionpoll.ui.screens.support

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.rememberCoroutineScope
import com.catbit.opinionpoll.core.extensions.getStateHolder
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.catbit.opinionpoll.core.sailor.navigation_controller.NavigationController
import com.catbit.opinionpoll.core.sailor.overlays.snackbar.rememberSnackBarState
import com.catbit.opinionpoll.core.sailor.providers.LocalDialogStateProvider
import com.catbit.opinionpoll.core.sailor.providers.LocalSnackbarState
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.composables.base_components.*
import com.catbit.opinionpoll.core.ui.composables.effects.SingleEffect
import com.catbit.opinionpoll.core.ui.helpers.isMobileMode
import com.catbit.opinionpoll.core.ui.helpers.mobileModeStateValue
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.*
import com.varabyte.kobweb.compose.css.AlignSelf
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import kotlinx.coroutines.flow.collectLatest
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.minus
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun SupportScreen(
    stackEntryId: String,
    navigationController: NavigationController,
    stateHolder: SupportStateHolder = getStateHolder(stackEntryId)
) {

    val uiState = stateHolder.uiState.collectAsState().value
    val coroutineScope = rememberCoroutineScope()
    val snackBarState = LocalSnackbarState.current

    SingleEffect {
        stateHolder.effects.collectLatest {
            when(it){
                SupportUIStateContract.Effect.OnMessageSentSuccess -> {
                    snackBarState.show(
                        message = "Mensagem enviada com sucesso",
                        coroutineScope = coroutineScope
                    )
                }
                SupportUIStateContract.Effect.OnMessageSentFailure -> {
                    snackBarState.show(
                        message = "Houve um erro ao enviar a mensagem, tente novamente em alguns instantes.",
                        coroutineScope = coroutineScope
                    )
                }

                is SupportUIStateContract.Effect.OnInvalidField -> {
                    snackBarState.show(
                        message = it.message,
                        coroutineScope = coroutineScope
                    )
                }
            }
        }
    }

    when(uiState) {
        is SupportUIStateContract.State.Displaying -> {
            if(isMobileMode) {
                Column(
                    modifier = Modifier
                        .fillMaxSize()
                        .padding(horizontal = 24.px, vertical = 16.px)
                        .display(DisplayStyle.Flex)
                        .overflow {
                            y(Overflow.Auto)
                            x(Overflow.Hidden)
                        }
                        .gap(16.px)
                ) {
                    FAQ()
                    TermsAndConditions()
                    SendAMail(
                        uiState = uiState,
                        onEvent = stateHolder::onEvent
                    )
                }
            } else {
                Row(
                    modifier = Modifier
                        .fillMaxSize()
                        .display(DisplayStyle.Flex)
                        .gap(16.px)
                        .padding(horizontal = 24.px, vertical = 16.px)
                ) {
                    Column(
                        modifier = Modifier
                            .fillMaxSize()
                            .display(DisplayStyle.Flex)
                            .gap(16.px)
                    ) {
                        FAQ()
                        TermsAndConditions()
                    }
                    SendAMail(
                        uiState = uiState,
                        onEvent = stateHolder::onEvent
                    )
                }
            }
        }
    }
}

@Composable
private fun SendAMail(
    onEvent: (SupportUIStateContract.Event) -> Unit,
    uiState: SupportUIStateContract.State.Displaying
) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .background(sitePalette().surface)
            .borderRadius(16.px)
            .padding(all = 16.px),
        verticalArrangement = Arrangement.SpaceBetween
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
        ) {
            Text(
                modifier = Modifier.headlineMedium(),
                text = "Entre em contato"
            )
            Text(
                modifier = Modifier.titleLarge(),
                text = "Obtenha suporte ou envie dúvidas/sugestões sobre o sistema"
            )
            Text(
                modifier = Modifier
                    .titleMedium()
                    .margin(top = 16.px),
                text = "Assunto"
            )
            OutlinedDropdownList(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(top = 8.px),
                value = uiState.selectedTopic,
                options = uiState.topics,
                onOptionsSelected = {
                    onEvent(SupportUIStateContract.Event.OnSelectTopic(it))
                }
            )
            Text(
                modifier = Modifier
                    .titleMedium()
                    .margin(top = 16.px),
                text = "Assunto"
            )
            TextField(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(top = 8.px),
                text = uiState.subject,
                onTextChanged = {
                    onEvent(SupportUIStateContract.Event.OnSubjectChange(it))
                }
            )
            Row(
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                horizontalArrangement = Arrangement.End
            ) {
                Text(
                    modifier = Modifier.titleSmall(),
                    text = uiState.subjectLengthCounter
                )
            }
            Text(
                modifier = Modifier
                    .margin(top = 16.px)
                    .titleMedium(),
                text = "Mensagem"
            )
            TextArea(
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                lines = 5,
                text = uiState.message,
                onTextChanged = {
                    onEvent(SupportUIStateContract.Event.OnMessageChange(it))
                }
            )
            Row(
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                horizontalArrangement = Arrangement.End
            ) {
                Text(
                    modifier = Modifier.titleSmall(),
                    text = uiState.messageLengthCounter
                )
            }
        }

        FilledButton(
            modifier = Modifier
                .margin(top = 16.px)
                .fillMaxWidth(),
            text = "Enviar",
            icon = null,
            loading = uiState.isSubmitting,
            enabled = !uiState.isSubmitting,
            onClick = {
                onEvent(SupportUIStateContract.Event.OnSubmitClick)
            }
        )
    }
}

@Composable
private fun FAQ() {
    Column(
        modifier = Modifier
            .fillMaxSize()
            .background(sitePalette().surface)
            .borderRadius(16.px)
            .padding(all = 16.px)
    ) {
        Text(
            modifier = Modifier.headlineMedium(),
            text = "Dúvidas frequentes"
        )
        Text(
            modifier = Modifier.titleLarge(),
            text = "Encontre aqui dúvidas frequentes e tutoriais de uso do sistema"
        )
        Column(
            modifier = Modifier
                .fillMaxSize(),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {
            MaterialIcon(
                icon = MaterialIcons.Round.HourglassTop,
                size = 48.px
            )
            Text(
                modifier = Modifier.titleLarge(),
                text = "Em construção"
            )
        }
    }
}

@Composable
private fun TermsAndConditions() {

    val dialogState = LocalDialogStateProvider.current

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .background(sitePalette().surface)
            .borderRadius(16.px)
            .padding(all = 16.px)
    ) {
        Text(
            modifier = Modifier.headlineMedium(),
            text = "Termos e condições"
        )
        FilledButton(
            modifier = Modifier
                .margin(top = 8.px)
                .alignSelf(AlignSelf.End)
                .fillMaxWidth(),
            text = "Exibir termos e condições",
            icon = null,
            onClick = {
                dialogState.show(
                    width = if(mobileModeStateValue) 100.percent.minus(48.px) else 600.px
                ) {
                    Column(
                        Modifier
                            .fillMaxWidth()
                            .height(400.px)
                            .overflow(Overflow.Hidden)
                    ) {
                        Row(
                            modifier = Modifier
                                .fillMaxWidth(),
                            horizontalArrangement = Arrangement.End
                        ) {
                            IconButton(
                                onClick = {
                                    dialogState.dismiss()
                                }
                            ) {
                                MaterialIcon(MaterialIcons.Round.Close)
                            }
                        }
                        Column(
                            modifier = Modifier
                                .fillMaxSize()
                                .overflow { y(Overflow.Scroll) }
                        ) {

                            Column(
                                modifier = Modifier.fillMaxWidth(),
                                horizontalAlignment = Alignment.CenterHorizontally
                            ) {
                                Image(
                                    src = "/opinion_poll.png",
                                    width = 80,
                                    height = 80
                                )
                                Text(
                                    modifier = Modifier.titleLarge(),
                                    text = "Termos e condições"
                                )
                            }

                            Text(
                                modifier = Modifier
                                    .margin(top = 16.px)
                                    .fillMaxWidth()
                                    .fontWeight(FontWeight.Bold),
                                text = "1. Aceitação dos Termos"
                            )

                            Text(
                                modifier = Modifier
                                    .fillMaxWidth(),
                                text = "1.1. Ao utilizar o aplicativo OpinionPro, você concorda com estes Termos e Condições de Uso."
                            )

                            Text(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .margin(top = 8.px)
                                    .fontWeight(FontWeight.Bold),
                                text = "2. Princípios de Proteção de Dados"
                            )

                            Text(
                                modifier = Modifier
                                    .fillMaxWidth(),
                                text = "2.1. O OpinionPro compromete-se a observar os princípios de proteção de dados previstos na Lei Geral de Proteção de Dados (LGPD), Lei n. 13.709, de 14 de agosto de 2018, incluindo os princípios de finalidade, adequação, necessidade, livre acesso, qualidade dos dados, transparência, segurança, prevenção, não discriminação e responsabilização e prestação de contas."
                            )

                            Text(
                                modifier = Modifier
                                    .fillMaxWidth(),
                                text = "2.2. A coleta, armazenamento, utilização e divulgação dos dados processados pelo OpinionPro são de inteira responsabilidade dos usuários e do pessoal cadastrado pelo contratante."
                            )

                            Text(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .margin(top = 8.px)
                                    .fontWeight(FontWeight.Bold),
                                text = "3. Responsabilidade dos Usuários"
                            )

                            Text(
                                modifier = Modifier.fillMaxWidth(),
                                text = "3.1. Os usuários são responsáveis por manter a confidencialidade de suas senhas e informações de conta, bem como por todas as atividades que ocorrerem em sua conta."
                            )

                            Text(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .margin(top = 8.px)
                                    .fontWeight(FontWeight.Bold),
                                text = "4. Limitação de Responsabilidade"
                            )

                            Text(
                                modifier = Modifier.fillMaxWidth(),
                                text = "4.1. O OpinionPro não se responsabiliza por quaisquer danos diretos, indiretos, incidentais, especiais ou consequenciais decorrentes do uso ou da incapacidade de utilizar o aplicativo."
                            )


                            Text(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .margin(top = 8.px)
                                    .fontWeight(FontWeight.Bold),
                                text = "5. Disposições Gerais"
                            )

                            Text(
                                modifier = Modifier.fillMaxWidth(),
                                text = "5.1. Estes Termos e Condições de Uso constituem o acordo integral entre o usuário e o OpinionPro e regem o uso do aplicativo, substituindo quaisquer acordos anteriores entre as partes."
                            )

                            Text(
                                modifier = Modifier.fillMaxWidth(),
                                text = "5.2. O OpinionPro reserva-se o direito de modificar estes Termos e Condições de Uso a qualquer momento, mediante aviso prévio aos usuários."
                            )
                        }
                    }
                }
            }
        )
    }
}