package com.catbit.opinionpoll.domain.login

import com.catbit.opinionpoll.core.domain.UseCase
import com.catbit.opinionpoll.data.repositories.OpinionPollRepository

class RecoverPasswordUseCase(
    private val repository: OpinionPollRepository
) : UseCase<Unit, RecoverPasswordUseCase.Params>() {

    override suspend fun execute(params: Params) = repository.recoverPassword(
        email = params.email,
    )

    data class Params(
        val email: String,
    )
}