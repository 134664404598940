package com.catbit.opinionpoll.data.data_sources.networking.requests

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FormCopyRequest(
    @SerialName("newTitle")
    val newTitle: String,
    @SerialName("formIdentifier")
    val formIdentifier: String,
)