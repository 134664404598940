package com.catbit.opinionpoll.domain.login

import com.catbit.opinionpoll.core.domain.UseCase
import com.catbit.opinionpoll.data.repositories.OpinionPollRepository

class LoginUseCase(
    private val repository: OpinionPollRepository
) : UseCase<Unit, LoginUseCase.Params>() {

    override suspend fun execute(params: Params) = repository.login(
        email = params.email,
        password = params.password,
    )

    data class Params(
        val email: String,
        val password: String
    )
}