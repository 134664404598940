package com.catbit.opinionpoll.inputs.events

import kotlinx.datetime.LocalDateTime

sealed interface DateTimeInputEventData {

    data class OnMinDateTimeChange(
        val newMinDateTime: LocalDateTime?
    ) : DateTimeInputEventData

    data class OnMaxDateTimeChange(
        val newMaxDateTime: LocalDateTime?
    ) : DateTimeInputEventData

}