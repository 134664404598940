package com.catbit.opinionpoll.ui.screens.support

import com.catbit.opinionpoll.core.extensions.like
import com.catbit.opinionpoll.core.extensions.updateAs
import com.catbit.opinionpoll.domain.support.AskForSupportUseCase
import com.catbit.opinionpoll.ui.base.ScreenStateHolder
import com.catbit.opinionpoll.ui.screens.support.SupportUIStateContract.*
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.launch

class SupportStateHolder(
    private val askForSupportUseCase: AskForSupportUseCase
) : ScreenStateHolder<State, Event, Effect>() {

    override val internalUIState = MutableStateFlow<State>(State.Displaying())

    override fun onEvent(event: Event) {
        when (event) {
            is Event.OnMessageChange -> onMessageChange(event.message)
            is Event.OnSelectTopic -> onSelectTopic(event.topic)
            is Event.OnSubjectChange -> onSubjectChange(event.subject)
            Event.OnSubmitClick -> onSubmitClick()
        }
    }

    private fun onSubmitClick() {
        with(internalUIState.like<State.Displaying>()) {
            if (subject.isEmpty()) {
                internalEffects.dispatch(Effect.OnInvalidField("Informe o assunto antes de continuar"))
            } else if (message.isEmpty()) {
                internalEffects.dispatch(Effect.OnInvalidField("Escreva uma mensagem antes de continuar"))
            } else {
                internalUIState.updateAs<State.Displaying> {
                    copy(isSubmitting = true)
                }
                askForSupport()
            }
        }
    }

    private fun askForSupport() {
        stateHolderScope.launch {
            with(internalUIState.like<State.Displaying>()) {
                askForSupportUseCase(
                    AskForSupportUseCase.Params(
                        topic = selectedTopic,
                        subject = subject,
                        message = message,
                    )
                )
                    .onSuccess {
                        internalUIState.updateAs<State.Displaying> {
                            copy(
                                isSubmitting = false,
                                subject = "",
                                subjectLengthCounter = "0/100",
                                message = "",
                                messageLengthCounter = "0/2000"
                            )
                        }
                        internalEffects.dispatch(Effect.OnMessageSentSuccess)
                    }
                    .onFailure {
                        internalUIState.updateAs<State.Displaying> {
                            copy(isSubmitting = false)
                        }
                        internalEffects.dispatch(Effect.OnMessageSentFailure)
                    }
            }
        }
    }

    private fun onSubjectChange(subject: String) {
        subject.takeIf { it.length <= 100 }?.let {
            internalUIState.updateAs<State.Displaying> {
                copy(
                    subject = subject,
                    subjectLengthCounter = "${subject.length}/100"
                )
            }
        }
    }

    private fun onSelectTopic(topic: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(selectedTopic = topic)
        }
    }

    private fun onMessageChange(message: String) {
        message.takeIf { it.length <= 2000 }?.let {
            internalUIState.updateAs<State.Displaying> {
                copy(
                    message = message,
                    messageLengthCounter = "${message.length}/2000"
                )
            }
        }
    }
}