package com.catbit.opinionpoll.pages

import com.catbit.opinionpoll.core.exceptions.HttpResponseException
import com.catbit.opinionpoll.core.extensions.applyDynamicMask
import com.catbit.opinionpoll.core.extensions.like
import com.catbit.opinionpoll.core.extensions.updateAs
import com.catbit.opinionpoll.domain.environment.CreateEnvironmentUseCase
import com.catbit.opinionpoll.domain.support.GetInTouchUseCase
import com.catbit.opinionpoll.pages.IndexContracts.*
import com.catbit.opinionpoll.ui.base.ScreenStateHolder
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.launch

class IndexStateHolder(
    private val createEnvironmentUseCase: CreateEnvironmentUseCase,
    private val getInTouchUseCase: GetInTouchUseCase
) : ScreenStateHolder<State, Event, Effect>() {

    override val internalUIState = MutableStateFlow<State>(State.Displaying.default())

    override fun onEvent(event: Event) {
        when (event) {
            is Event.OnCompanyNameChange -> onCompanyNameChange(event.newCompanyName)
            is Event.OnEmailChange -> onEmailChange(event.newEmail)
            is Event.OnFullNameChange -> onFullNameChange(event.newFullName)
            is Event.OnPasswordChange -> onPasswordChange(event.newPassword)
            is Event.OnPhoneNumberChange -> onPhoneNumberChange(event.newPhoneNumber)
            Event.OnTogglePasswordVisibility -> onTogglePasswordVisibility()
            Event.OnTryFreeTrialClick -> onTryFreeTrialClick()
            is Event.OnMessageChange -> onMessageChange(event.message)
            is Event.OnSelectTopic -> onSelectTopic(event.topic)
            is Event.OnSubjectChange -> onSubjectChange(event.subject)
            is Event.OnSupportEmailChange -> onSupportEmailChange(event.email)
            is Event.OnSupportNameChange -> onSupportNameChange(event.name)
            Event.OnSubmitClick -> onSubmitClick()
        }
    }

    private fun onTryFreeTrialClick() {
        internalUIState.updateAs<State.Displaying> {
            copy(sending = true)
        }

        // TODO Validar campos antes de enviar

        with(internalUIState.like<State.Displaying>()) {
            stateHolderScope.launch {
                createEnvironmentUseCase(
                    CreateEnvironmentUseCase.Params(
                        email = email,
                        fullName = fullName,
                        password = password,
                        phoneNumber = "+55$phoneNumberBuffer",
                        companyName = companyName
                    )
                )
                    .onSuccess {
                        internalUIState.updateAs<State.Displaying> {
                            copy(sending = false)
                        }
                        internalEffects.dispatch(Effect.OnRequestFreeTrialSuccess)
                    }
                    .onFailure { failure ->
                        internalUIState.updateAs<State.Displaying> {
                            copy(sending = false)
                        }
                        val message = if (failure is HttpResponseException && failure.statusCode.value == 415)
                            "Você já possui uma conta no OpinionPro"
                        else "Ocorreu um erro ao solicitar teste grátis, tente novamente!"

                        internalEffects.dispatch(Effect.OnRequestFreeTrialFailure(message))
                    }
            }
        }
    }

    private fun onCompanyNameChange(newCompanyName: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(companyName = newCompanyName)
        }
    }

    private fun onEmailChange(newEmail: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(email = newEmail)
        }
    }

    private fun onFullNameChange(newFullName: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(fullName = newFullName)
        }
    }

    private fun onPasswordChange(newPassword: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(password = newPassword)
        }
    }

    private fun onPhoneNumberChange(pressedKey: String) {
        internalUIState.updateAs<State.Displaying> {
            val newPhoneNumberBuffer = if (pressedKey == "Backspace" || pressedKey == "Delete") {
                phoneNumberBuffer.dropLast(1)
            } else {
                if (phoneNumberBuffer.length == 11) {
                    phoneNumberBuffer
                } else phoneNumberBuffer + pressedKey
            }
            copy(
                phoneNumberBuffer = newPhoneNumberBuffer,
                phoneNumber = newPhoneNumberBuffer
                    .applyDynamicMask(
                        replaceableChar = '#',
                        mask = "(##) # ####-####"
                    )
            )
        }
    }

    private fun onTogglePasswordVisibility() {
        internalUIState.updateAs<State.Displaying> {
            copy(showPassword = showPassword.not())
        }
    }

    private fun onSubjectChange(subject: String) {
        subject.takeIf { it.length <= 100 }?.let {
            internalUIState.updateAs<State.Displaying> {
                copy(
                    subject = subject,
                    subjectLengthCounter = "${subject.length}/100"
                )
            }
        }
    }

    private fun onSelectTopic(topic: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(selectedTopic = topic)
        }
    }

    private fun onMessageChange(message: String) {
        message.takeIf { it.length <= 2000 }?.let {
            internalUIState.updateAs<State.Displaying> {
                copy(
                    message = message,
                    messageLengthCounter = "${message.length}/2000"
                )
            }
        }
    }

    private fun onSupportEmailChange(email: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(supportEmail = email)
        }
    }

    private fun onSupportNameChange(name: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(supportName = name)
        }
    }

    private fun onSubmitClick() {
        with(internalUIState.like<State.Displaying>()) {
            if (supportName.isEmpty()) {
                internalEffects.dispatch(Effect.OnInvalidField("Informe seu nome antes de continuar"))
            } else if (supportEmail.isEmpty()) {
                internalEffects.dispatch(Effect.OnInvalidField("Informe seu email antes de continuar"))
            } else if (subject.isEmpty()) {
                internalEffects.dispatch(Effect.OnInvalidField("Informe o assunto antes de continuar"))
            } else if (message.isEmpty()) {
                internalEffects.dispatch(Effect.OnInvalidField("Escreva uma mensagem antes de continuar"))
            } else {
                internalUIState.updateAs<State.Displaying> {
                    copy(isSubmitting = true)
                }
                getInTouch()
            }
        }
    }

    private fun getInTouch() {
        stateHolderScope.launch {
            with(internalUIState.like<State.Displaying>()) {
                getInTouchUseCase(
                    GetInTouchUseCase.Params(
                        name = supportName,
                        email = supportEmail,
                        topic = selectedTopic,
                        subject = subject,
                        message = message,
                    )
                )
                    .onSuccess {
                        internalUIState.updateAs<State.Displaying> {
                            copy(
                                isSubmitting = false,
                                supportEmail = "",
                                supportName = "",
                                subject = "",
                                subjectLengthCounter = "0/100",
                                message = "",
                                messageLengthCounter = "0/2000"
                            )
                        }
                        internalEffects.dispatch(Effect.OnMessageSentSuccess)
                    }
                    .onFailure {
                        internalUIState.updateAs<State.Displaying> {
                            copy(isSubmitting = false)
                        }
                        internalEffects.dispatch(Effect.OnMessageSentFailure)
                    }
            }
        }
    }
}