package com.catbit.opinionpoll.core.ui.composables.base_components

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.bodyLarge
import com.catbit.opinionpoll.core.ui.modifiers.padding
import com.catbit.opinionpoll.core.ui.modifiers.thenIf
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.UserSelect
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

@Composable
fun OutlinedDropdownList(
    modifier: Modifier = Modifier,
    state: DropdownListState = rememberDropdownListState(),
    value: String,
    options: List<String>,
    checkedOptions: List<String>? = null,
    enabled: Boolean = true,
    onCheckOptionsChange: ((List<String>) -> Unit)? = null,
    onOptionsSelected: (String) -> Unit,
    displaySearchBar: Boolean = false,
    constrainDialogOnParentWidth: Boolean = false,
) {
    var hasFocus by remember { mutableStateOf(false) }

    DropdownList(
        modifier = modifier,
        state = state,
        options = options,
        enabled = enabled,
        checkedOptions = checkedOptions,
        onCheckOptionsChange = onCheckOptionsChange,
        onOptionsSelected = onOptionsSelected,
        displaySearchBar = displaySearchBar,
        onShowOptions = {
            hasFocus = true
        },
        onHideOptions = {
            hasFocus = false
        },
        constrainDialogOnParentWidth = constrainDialogOnParentWidth
    ) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(vertical = 12.px, horizontal = 16.px)
                .minHeight(48.px)
                .borderRadius(8.px)
                .backgroundColor(sitePalette().surfaceVariant)
                .thenIf(hasFocus) {
                    border(
                        color = sitePalette().primary,
                        style = LineStyle.Solid,
                        width = 2.px
                    )
                },
            verticalAlignment = Alignment.CenterVertically
        ) {
            Text(
                modifier = Modifier
                    .userSelect(UserSelect.None)
                    .bodyLarge()
                    .color(sitePalette().onSurfaceVariant)
                    .fillMaxWidth(),
                text = if (checkedOptions != null) {
                    when (checkedOptions.size) {
                        0 -> "Nenhum selecionado"
                        1 -> "1 selecionado"
                        else -> "${checkedOptions.size} selecionados"
                    }
                } else value
            )
            MaterialIcon(
                icon = if (hasFocus)
                    MaterialIcons.Round.ArrowDropUp
                else
                    MaterialIcons.Round.ArrowDropDown,
                tint = sitePalette().onSurfaceVariant
            )
        }
    }
}