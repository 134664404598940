package com.catbit.opinionpoll.inputs.requests.requests

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("MaskedTextArea")
data class MaskedTextAreaInputRequest(
    override val identifier: String,
    override val title: String,
    override val subtitle: String?,
    override val isObligatory: Boolean,
    override val index: Int,
    @SerialName("mask")
    val mask: String,
    @SerialName("replaceableChar")
    val replaceableChar: Char?,
    @SerialName("inputType")
    val inputType: String?,
) : InputRequest