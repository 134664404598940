package com.catbit.opinionpoll.data.data_sources.networking.requests

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UpdateAnswersRequest(
    @SerialName("answersToAdd")
    val answersToAdd: List<FormAnswerRequest>,
    @SerialName("answersToUpdate")
    val answersToUpdate: List<SimplifiedFormAnswerRequest>,
    @SerialName("answersToDelete")
    val answersToDelete: List<String>
)
