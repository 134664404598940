package com.catbit.opinionpoll.inputs.composables

import androidx.compose.runtime.Composable
import com.catbit.opinionpoll.core.extensions.withNotNull
import com.catbit.opinionpoll.core.ui.composables.base_components.Text
import com.catbit.opinionpoll.core.ui.modifiers.titleMedium
import com.catbit.opinionpoll.core.ui.modifiers.titleSmall
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.jetbrains.compose.web.css.px

@Composable
fun DefaultFormPreview(
    index: Int,
    title: String,
    subtitle: String,
    content: @Composable () -> Unit
) {
    Column(
        modifier = Modifier.fillMaxWidth()
    ) {
        Row {
            Text(
                modifier = Modifier
                    .titleMedium()
                    .fontWeight(FontWeight.Bold)
                    .margin(right = 16.px),
                text = "$index."
            )
            Text(
                modifier = Modifier
                    .titleMedium()
                    .fontWeight(FontWeight.Bold),
                text = title
            )
        }
        withNotNull(subtitle.takeIf { it.isNotBlank() }) {
            Text(
                modifier = Modifier.titleSmall(),
                text = this
            )
        }
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .padding(top = 16.px)
        ) {
            content()
        }
    }
}