package com.catbit.opinionpoll.data.mappers

import com.catbit.opinionpoll.core.extensions.toLatLong
import com.catbit.opinionpoll.core.mapper.mapTo
import com.catbit.opinionpoll.core.mapper.mapper
import com.catbit.opinionpoll.data.data_sources.networking.requests.FormAnswerRequest
import com.catbit.opinionpoll.data.data_sources.networking.requests.FormFilterRequest
import com.catbit.opinionpoll.data.data_sources.networking.responses.*
import com.catbit.opinionpoll.data.models.*
import com.catbit.opinionpoll.inputs.models.*
import com.catbit.opinionpoll.inputs.responses.*
import kotlinx.datetime.LocalTime
import kotlinx.datetime.toLocalDate
import kotlinx.datetime.toLocalDateTime
import kotlinx.datetime.toLocalTime
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

object OpinionPollMappers {

    @OptIn(ExperimentalSerializationApi::class)
    private val json = Json {
        ignoreUnknownKeys = true
        explicitNulls = false
    }

    val mappers = listOf(
        mapper<FormFilterResponse.FormFilterSubgroupFieldResponse, FormFilterModel.FormFilterSubgroupFieldModel> {
            FormFilterModel.FormFilterSubgroupFieldModel(
                identifier = identifier,
                title = title,
                values = values
            )
        },
        mapper<StatsResponse, StatsModel> {
            StatsModel(
                totalForms = totalForms,
                totalUsers = totalUsers,
                formsTotalAnswers = formsTotalAnswers
            )
        },
        mapper<FormFilterResponse, FormFilterModel> { mappers ->
            FormFilterModel(
                identifier = identifier,
                title = title,
                groupingField = groupingField,
                subgroups = subgroups.mapTo(mappers),
                fieldsToDisplay = fieldsToDisplay
            )
        },
        mapper<FormFilterModel.FormFilterSubgroupFieldModel, FormFilterRequest.FormFilterSubgroupFieldRequest> {
            FormFilterRequest.FormFilterSubgroupFieldRequest(
                identifier = identifier,
                title = title,
                values = values
            )
        },
        mapper<FormFilterModel, FormFilterRequest> { mappers ->
            FormFilterRequest(
                identifier = identifier,
                title = title,
                groupingField = groupingField,
                subgroups = subgroups.mapTo(mappers),
                fieldsToDisplay = fieldsToDisplay
            )
        },
        mapper<FormResponse, FormModel> { mappers ->
            FormModel(
                identifier = identifier,
                version = version,
                tips = tips,
                title = title,
                creationDate = creationDate.toLocalDateTime(),
                expirationDate = expirationDate.toLocalDateTime(),
                sendGeoCoordinates = sendGeoCoordinates,
                inputs = template.mapTo(mappers),
                assignedTo = assignedTo,
                filters = filters?.mapTo(mappers)
            )
        },
        mapper<SimplifiedFormResponse, SimplifiedFormModel> {
            SimplifiedFormModel(
                identifier = identifier,
                title = title,
                creationDate = creationDate.toLocalDateTime(),
                expirationDate = expirationDate.toLocalDateTime(),
                createdBy = createdBy
            )
        },
        mapper<UserResponse, UserModel> {
            UserModel(
                uid = uid,
                environment = environment,
                phoneNumber = phoneNumber,
                role = UserModel.UserRole.getRoleFromString(role),
                name = name,
                email = email
            )
        },
        mapper<FormAnswerResponse, FormAnswerModel> {
            FormAnswerModel(
                identifier = identifier,
                submittedBy = submittedBy,
                submittedAt = submittedAt.toLocalDateTime(),
                geoCoordinates = geoCoordinates?.toLatLong(),
                answer = json.decodeFromString(answer)
            )
        },
        mapper<TimeInputResponse, TimeInputModel> {
            TimeInputModel(
                identifier = identifier,
                title = title,
                subtitle = subtitle,
                isObligatory = isObligatory,
                index = index,
                minTime = minTime?.toLocalTime() ?: LocalTime(0, 0),
                maxTime = maxTime?.toLocalTime() ?: LocalTime(23, 59)
            )
        },
        mapper<DateInputResponse, DateInputModel> {
            DateInputModel(
                identifier = identifier,
                title = title,
                subtitle = subtitle,
                isObligatory = isObligatory,
                index = index,
                minDate = minDate?.toLocalDate(),
                maxDate = maxDate?.toLocalDate()
            )
        },
        mapper<DateTimeInputResponse, DateTimeInputModel> {
            DateTimeInputModel(
                identifier = identifier,
                title = title,
                subtitle = subtitle,
                isObligatory = isObligatory,
                index = index,
                minDateTime = minDateTime?.toLocalDateTime(),
                maxDateTime = maxDateTime?.toLocalDateTime()
            )
        },
        mapper<MaskedTextAreaInputResponse, MaskedTextAreaInputModel> {
            MaskedTextAreaInputModel(
                identifier = identifier,
                title = title,
                subtitle = subtitle,
                isObligatory = isObligatory,
                index = index,
                mask = mask,
                replaceableChar = replaceableChar ?: '#',
                inputType = MaskedTextAreaInputModel.Type.getFromString(inputType)
            )
        },
        mapper<MultipleChoicesOptionsInputResponse, MultipleChoicesOptionsInputModel> {
            MultipleChoicesOptionsInputModel(
                identifier = identifier,
                title = title,
                subtitle = subtitle,
                isObligatory = isObligatory,
                index = index,
                options = options,
                style = MultipleChoicesOptionsInputModel.Style.getFromString(style),
                minSelection = minSelection,
                maxSelection = maxSelection,
                enableOtherOptions = enableOtherOptions ?: false,
                maxOtherOptions = maxOtherOptions ?: Int.MAX_VALUE,
                otherOptionPlaceholderText = otherOptionPlaceholderText ?: "Nova opção",
                maxOtherOptionTextLength = maxOtherOptionTextLength ?: 30,
            )
        },
        mapper<NumericAreaInputResponse, NumericAreaInputModel> {
            NumericAreaInputModel(
                identifier = identifier,
                title = title,
                subtitle = subtitle,
                isObligatory = isObligatory,
                index = index,
                minValue = minValue,
                maxValue = maxValue,
                symbol = symbol,
                symbolPosition = NumericAreaInputModel.SymbolPosition.getFromString(
                    symbolPosition
                ),
                decimalPoints = decimalPoints ?: 0
            )
        },
        mapper<OptionResponse, OptionModel> {
            OptionModel(
                title = title,
                identifier = identifier
            )
        },
        mapper<SingleChoiceOptionsInputResponse, SingleChoiceOptionsInputModel> { mappers ->
            SingleChoiceOptionsInputModel(
                identifier = identifier,
                title = title,
                subtitle = subtitle,
                isObligatory = isObligatory,
                index = index,
                options = options.mapTo(mappers),
                otherOption = otherOption?.mapTo(mappers),
                otherOptionPlaceholderText = otherOptionPlaceholderText ?: "Nova opção",
                maxOtherOptionTextLength = maxOtherOptionTextLength ?: 30,
                style = SingleChoiceOptionsInputModel.Style.getFromString(style),
                showSearchBar = showSearchBar ?: false,
            )
        },
        mapper<TextAreaInputResponse, TextAreaInputModel> {
            TextAreaInputModel(
                identifier = identifier,
                title = title,
                subtitle = subtitle,
                isObligatory = isObligatory,
                index = index,
                maxLength = maxLength
            )
        },
        mapper<EvaluationInputResponse, EvaluationInputModel> {
            EvaluationInputModel(
                identifier = identifier,
                title = title,
                subtitle = subtitle,
                isObligatory = isObligatory,
                index = index,
                minValue = minValue,
                maxValue = maxValue
            )
        },
        mapper<CompactSingleChoiceOptionsInputResponse, CompactSingleChoiceOptionsInputModel> { mappers ->
            CompactSingleChoiceOptionsInputModel(
                identifier = identifier,
                title = title,
                subtitle = subtitle,
                isObligatory = isObligatory,
                index = index,
                options = options.mapTo(mappers),
                questions = questions.mapTo(mappers),
                showSearchBar = showSearchBar ?: false,
            )
        },
        mapper<FormAnswerModel, FormAnswerRequest> {
            FormAnswerRequest(
                identifier = identifier,
                submittedBy = submittedBy,
                submittedAt = submittedAt.toString(),
                geoCoordinates = geoCoordinates?.toString(),
                answer = json.encodeToString(answer)
            )
        }
    )
}