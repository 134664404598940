package com.catbit.opinionpoll.domain.user

import com.catbit.opinionpoll.core.domain.UseCase
import com.catbit.opinionpoll.data.models.UserModel
import com.catbit.opinionpoll.data.repositories.OpinionPollRepository

class GetUsersUseCase(
    private val repository: OpinionPollRepository
) : UseCase<List<UserModel>, Unit>() {

    override suspend fun execute(params: Unit) = repository.getUsers()

}