package com.catbit.opinionpoll.core.ui.composables.base_components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text as ComposeText

@Composable
fun Text(
    text: String,
    modifier: Modifier = Modifier,
) {
    Span(
        attrs = modifier
            .fontFamily("Montserrat")
            .toAttrs()
    ) {
        ComposeText(text)
    }
}