package com.catbit.opinionpoll.data.data_sources.networking.responses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RefreshTokenResponse(
    @SerialName("idToken")
    val idToken: String,
    @SerialName("refreshToken")
    val refreshToken: String,
)
