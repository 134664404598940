package com.catbit.opinionpoll.ui.screens.login

import com.catbit.opinionpoll.core.domain.invoke
import com.catbit.opinionpoll.core.exceptions.HttpResponseException
import com.catbit.opinionpoll.core.extensions.updateAs
import com.catbit.opinionpoll.core.extensions.withNotNull
import com.catbit.opinionpoll.domain.login.LoginUseCase
import com.catbit.opinionpoll.domain.login.RecoverPasswordUseCase
import com.catbit.opinionpoll.domain.user.GetCurrentUserIdUseCase
import com.catbit.opinionpoll.res.Strings
import com.catbit.opinionpoll.ui.base.ScreenStateHolder
import com.catbit.opinionpoll.ui.screens.login.LoginUIContract.*
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch

class LoginStateHolder(
    private val loginUseCase: LoginUseCase,
    private val getCurrentUserIdUseCase: GetCurrentUserIdUseCase,
    private val recoverPasswordUseCase: RecoverPasswordUseCase
) : ScreenStateHolder<State, Event, Effect>() {

    override val internalUIState = MutableStateFlow<State>(State.CheckingIfAlreadyLogged)

    override fun onStarted() {
        stateHolderScope.launch {
            getCurrentUserIdUseCase()
                .onSuccess {
                    internalEffects.dispatch(Effect.OnLoginSuccess)
                }
                .onFailure {
                    internalUIState.update {
                        State.default()
                    }
                }
        }
    }

    override fun onEvent(event: Event) {
        when (event) {
            is Event.OnEmailChange -> onEmailChange(event.newValue)
            is Event.OnPasswordChange -> onPasswordChange(event.newValue)
            Event.OnLoginClick -> login()
            Event.OnTogglePasswordVisibility -> onTogglePasswordVisibility()
            is Event.OnConfirmRecoverEmail -> onConfirmRecoverEmail(event.email)
            Event.OnForgetPasswordClick -> onForgetPasswordClick()
        }
    }

    private fun onForgetPasswordClick() {
        internalEffects.dispatch(Effect.OnDisplayRecoveringPassword)
    }

    private fun onConfirmRecoverEmail(email: String) {
        internalEffects.dispatch(Effect.OnSendRecoveringPasswordStart)

        stateHolderScope.launch {
            recoverPasswordUseCase(
                RecoverPasswordUseCase.Params(email)
            )
                .onSuccess {
                    internalEffects.dispatch(Effect.OnSendRecoveringPasswordSuccess)
                }
                .onFailure {
                    internalEffects.dispatch(Effect.OnSendRecoveringPasswordFailure)
                }
        }
    }

    private fun onEmailChange(newValue: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(email = newValue)
        }
    }

    private fun onPasswordChange(newValue: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(password = newValue)
        }
    }

    private fun onTogglePasswordVisibility() {
        internalUIState.updateAs<State.Displaying> {
            copy(showPassword = !showPassword)
        }
    }

    private fun login() {
        withNotNull(internalUIState.value as? State.Displaying) {
            internalUIState.update {
                State.Logging(
                    email = email,
                    password = password,
                    showPassword = showPassword
                )
            }

            stateHolderScope.launch {
                loginUseCase(
                    LoginUseCase.Params(
                        email = email,
                        password = password
                    )
                )
                    .onSuccess {
                        internalUIState.update {
                            State.Displaying(
                                email = email,
                                password = password,
                                showPassword = showPassword,
                            )
                        }
                        internalEffects.dispatch(Effect.OnLoginSuccess)
                    }
                    .onFailure { failure ->
                        internalUIState.update {
                            State.Displaying(
                                email = email,
                                password = password,
                                showPassword = showPassword,
                            )
                        }
                        if (failure is HttpResponseException && failure.statusCode.value == 455) {
                            internalEffects.dispatch(
                                Effect.OnLoginFailure(Strings.notVerifiedEmailMessage)
                            )
                        } else {
                            internalEffects.dispatch(
                                Effect.OnLoginFailure(Strings.thereWasAProblemAtLogin)
                            )
                        }
                    }
            }
        }
    }
}