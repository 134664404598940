package com.catbit.opinionpoll.ui.composables

import androidx.compose.runtime.Composable
import com.catbit.opinionpoll.core.extensions.changeAlpha
import com.catbit.opinionpoll.core.extensions.toBrazilianPhoneFormat
import com.catbit.opinionpoll.core.ui.composables.CardWithIcon
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.composables.base_components.Text
import com.catbit.opinionpoll.core.ui.icons.MaterialIcon
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.bodyMedium
import com.catbit.opinionpoll.core.ui.modifiers.bodySmall
import com.catbit.opinionpoll.core.ui.modifiers.titleMedium
import com.catbit.opinionpoll.core.ui.modifiers.titleSmall
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.px

@Composable
fun User(
    modifier: Modifier = Modifier,
    uid: String,
    phoneNumber: String,
    roleIcon: MaterialIcon,
    name: String,
    email: String,
    backgroundColor: Color = sitePalette().surface,
    textColor: Color = sitePalette().onSurface,
    iconColor: Color = sitePalette().primary
) {
    CardWithIcon(
        modifier = modifier,
        icon = roleIcon,
        backgroundColor = backgroundColor,
        iconColor = iconColor
    ) {
        Column(
            modifier = Modifier.fillMaxWidth()
        ) {
            Text(
                modifier = Modifier
                    .titleMedium()
                    .color(textColor),
                text = name
            )
            Row(
                verticalAlignment = Alignment.CenterVertically
            ) {
                MaterialIcon(
                    modifier = Modifier.margin(right = 8.px),
                    icon = MaterialIcons.Round.Mail,
                    size = 18.px,
                    tint = textColor.changeAlpha(0.8f)
                )
                Text(
                    modifier = Modifier
                        .titleSmall()
                        .color(textColor.changeAlpha(0.8f)),
                    text = email
                )
            }

            Row(
                verticalAlignment = Alignment.CenterVertically
            ) {
                MaterialIcon(
                    modifier = Modifier.margin(right = 8.px),
                    icon = MaterialIcons.Round.Phone,
                    size = 18.px,
                    tint = textColor.changeAlpha(0.8f)
                )
                Text(
                    modifier = Modifier
                        .titleSmall()
                        .color(textColor.changeAlpha(0.8f)),
                    text = phoneNumber.toBrazilianPhoneFormat()
                )
            }

            Row(
                verticalAlignment = Alignment.CenterVertically
            ) {
                MaterialIcon(
                    modifier = Modifier.margin(right = 8.px),
                    icon = MaterialIcons.Round.Fingerprint,
                    size = 18.px,
                    tint = textColor.changeAlpha(0.8f)
                )
                Text(
                    modifier = Modifier
                        .fillMaxWidth()
                        .titleSmall()
                        .color(textColor.changeAlpha(0.8f)),
                    text = "Identificador: $uid"
                )
            }
        }
    }
}