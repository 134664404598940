package com.catbit.opinionpoll.ui.screens.forms

import com.catbit.opinionpoll.ui.states.FormUIState

interface FormsUIContract {
    sealed interface State {
        data class Displaying(
            val forms: List<FormUIState>,
            val isOnFormDeletionMode: Boolean = false,
            val enableDeleteFormsButton: Boolean = false,
            val selectedMenu: String = "",
            val searchSorting: String = "Título",
            val searchSortingOptions: List<String> = listOf("Título", "Data de criação"),
            val searchQuery: String = ""
        ) : State

        data object Loading : State

        data class Failure(
            val failure: Throwable
        ) : State
    }

    sealed interface Effect {
        data object OnConfirmFormsDeletion : Effect
        data object OnStartFormsDeletion : Effect
        data object OnFormsDeletionSuccess : Effect
        data object OnFormsDeletionFailure : Effect
        data object OnStartFormCopy : Effect
        data object OnFormCopySuccess : Effect
        data object OnFormCopyFailure : Effect
    }

    sealed interface Event {
        data object OnMenuDeleteFormClick : Event
        data object OnDeleteFormClick : Event
        data object OnSelectAllFormsForDeletion : Event
        data object OnCancelDeletingForms : Event
        data object OnConfirmFormsDeletion : Event
        data object OnRefreshForms : Event
        data class OnFormSelectedForDeletion(val formId: String) : Event
        data class OnFilterChange(val newFilter: String) : Event
        data class OnSearchQueryChange(val newQuery: String) : Event
        data object OnClearSearchQuery : Event
        data class OnCopyFormClick(val formId: String, val newTitle: String) : Event
    }
}