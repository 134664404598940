package com.catbit.opinionpoll.ui.screens.support

interface SupportUIStateContract {
    sealed interface State {
        data class Displaying(
            val selectedTopic: String = "Suporte",
            val topics: List<String> = listOf(
                "Suporte",
                "Financeiro",
                "Sugestão",
                "Demais assuntos"
            ),
            val subject: String = "",
            val subjectLengthCounter: String = "0/100",
            val message: String = "",
            val messageLengthCounter: String = "0/2000",
            val isSubmitting: Boolean = false
        ): State
    }
    sealed interface Event {
        data class OnSelectTopic(val topic: String) : Event
        data class OnSubjectChange(val subject: String) : Event
        data class OnMessageChange(val message: String) : Event
        data object OnSubmitClick : Event
    }
    sealed interface Effect {
        data class OnInvalidField(val message: String): Effect
        data object OnMessageSentSuccess : Effect
        data object OnMessageSentFailure : Effect
    }
}