package com.catbit.opinionpoll.core.ui.styles

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.silk.components.style.ComponentStyle

val SliderStyle = ComponentStyle("slider") {

}

val SliderStyleThumbColor = ComponentStyle("slider::-webkit-slider-thumb") {
    base {
        Modifier.background(Color.rgb(0xff0000))
    }
}