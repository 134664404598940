package com.catbit.opinionpoll.di

import com.catbit.opinionpoll.core.extensions.stateHolder
import com.catbit.opinionpoll.core.mapper.Mappers
import com.catbit.opinionpoll.data.data_sources.local_storage.OpinionPollLocalStorage
import com.catbit.opinionpoll.data.data_sources.local_storage.OpinionPollLocalStorageImpl
import com.catbit.opinionpoll.data.data_sources.networking.OpinionPollNetwork
import com.catbit.opinionpoll.data.data_sources.networking.OpinionPollNetworkImpl
import com.catbit.opinionpoll.data.mappers.OpinionPollMappers
import com.catbit.opinionpoll.data.models.UserModel
import com.catbit.opinionpoll.data.repositories.OpinionPollRepository
import com.catbit.opinionpoll.data.repositories.OpinionPollRepositoryImpl
import com.catbit.opinionpoll.domain.environment.CreateEnvironmentUseCase
import com.catbit.opinionpoll.domain.environment.GetEnvironmentInfoUseCase
import com.catbit.opinionpoll.domain.form_dashboard.*
import com.catbit.opinionpoll.domain.form_users_linker.GetFormUsersLinkerUseCase
import com.catbit.opinionpoll.domain.form_users_linker.LinkFormToUsersUseCase
import com.catbit.opinionpoll.domain.forms.*
import com.catbit.opinionpoll.domain.home.GetHomeUseCase
import com.catbit.opinionpoll.domain.home.GetStatsUseCase
import com.catbit.opinionpoll.domain.login.LoginUseCase
import com.catbit.opinionpoll.domain.login.LogoutUseCase
import com.catbit.opinionpoll.domain.login.RecoverPasswordUseCase
import com.catbit.opinionpoll.domain.support.AskForSupportUseCase
import com.catbit.opinionpoll.domain.support.GetInTouchUseCase
import com.catbit.opinionpoll.domain.user.*
import com.catbit.opinionpoll.inputs.InputComposer
import com.catbit.opinionpoll.inputs.InputGraphDataProducer
import com.catbit.opinionpoll.inputs.InputUIStateValidator
import com.catbit.opinionpoll.inputs.InputUpdater
import com.catbit.opinionpoll.inputs.events_handler.*
import com.catbit.opinionpoll.inputs.graph.*
import com.catbit.opinionpoll.inputs.inputs.*
import com.catbit.opinionpoll.inputs.requests.InputRequestProductionHandler
import com.catbit.opinionpoll.inputs.requests.producers.*
import com.catbit.opinionpoll.inputs.validators.*
import com.catbit.opinionpoll.pages.IndexStateHolder
import com.catbit.opinionpoll.pages.app.AppStateHolder
import com.catbit.opinionpoll.ui.screens.form_dashboard.FormDashboardStateHolder
import com.catbit.opinionpoll.ui.screens.form_maker.FormMakerStateHolder
import com.catbit.opinionpoll.ui.screens.form_users_linker.FormUsersLinkerStateHolder
import com.catbit.opinionpoll.ui.screens.forms.FormsStateHolder
import com.catbit.opinionpoll.ui.screens.home.HomeStateHolder
import com.catbit.opinionpoll.ui.screens.login.LoginStateHolder
import com.catbit.opinionpoll.ui.screens.support.SupportStateHolder
import com.catbit.opinionpoll.ui.screens.user_maker.UserMakerStateHolder
import com.catbit.opinionpoll.ui.screens.users.UsersStateHolder
import io.ktor.client.*
import io.ktor.client.engine.js.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.browser.localStorage
import org.koin.dsl.module
import kotlin.time.Duration.Companion.seconds

object AppModules {
    val modules by lazy {
        listOf(
            coreModule,
            stateHoldersModule,
            useCasesModules,
            inputsModule
        )
    }

    private val coreModule = module {
        single<HttpClient> {
            HttpClient(Js) {
                defaultRequest {
                    url {
                        protocol = URLProtocol.HTTPS
                        host = "us-central1-opinionpollsystem.cloudfunctions.net"
                        encodedPath = "/api/v1/"
                    }
                }
                install(HttpTimeout) {
                    requestTimeoutMillis = 35.seconds.inWholeMilliseconds
                }
                install(ContentNegotiation) {
                    json()
                }
            }
        }

        single<Mappers> {
            Mappers(OpinionPollMappers.mappers)
        }

        single<OpinionPollNetwork> {
            OpinionPollNetworkImpl(
                httpClient = get()
            )
        }

        single<OpinionPollLocalStorage> {
            OpinionPollLocalStorageImpl(localStorage)
        }

        single<OpinionPollRepository> {
            OpinionPollRepositoryImpl(
                network = get(),
                localStorage = get(),
                mappers = get()
            )
        }
    }

    private val stateHoldersModule = module {
        stateHolder {
            IndexStateHolder(
                createEnvironmentUseCase = get(),
                getInTouchUseCase = get()
            )
        }
        stateHolder {
            AppStateHolder(
                getEnvironmentInfoUseCase = get(),
                logoutUseCase = get()
            )
        }
        stateHolder {
            LoginStateHolder(
                loginUseCase = get(),
                getCurrentUserIdUseCase = get(),
                recoverPasswordUseCase = get()
            )
        }
        stateHolder {
            HomeStateHolder(
                getHomeUseCase = get(),
                getStatsUseCase = get()
            )
        }
        stateHolder {
            SupportStateHolder(
                askForSupportUseCase = get()
            )
        }
        stateHolder {
            FormsStateHolder(
                getSimplifiedFormsUseCase = get(),
                deleteFormsUseCase = get(),
                copyFormUseCase = get()
            )
        }
        stateHolder {
            UsersStateHolder(
                getUsersUseCase = get(),
                deleteUsersUseCase = get()
            )
        }
        stateHolder { (formIdentifier: String?) ->
            FormMakerStateHolder(
                formIdentifier = formIdentifier,
                inputUpdater = get(),
                requestProducer = get(),
                inputUIStateValidator = get(),
                createFormUseCase = get(),
                updateFormUseCase = get(),
                getFormUseCase = get(),
            )
        }
        stateHolder { (formIdentifier: String) ->
            FormUsersLinkerStateHolder(
                formIdentifier = formIdentifier,
                getFormUsersLinkerUseCase = get(),
                linkFormToUsersUseCase = get()
            )
        }
        stateHolder { (formIdentifier: String, formTitle: String) ->
            FormDashboardStateHolder(
                formIdentifier = formIdentifier,
                formTitle = formTitle,
                inputGraphDataProducer = get(),
                getFormDashboardUseCase = get(),
                getAnswersCSVUseCase = get(),
                getAnswersXLSXUseCase = get(),
                saveFormFiltersUseCase = get(),
                updateAnswersUseCase = get()
            )
        }
        stateHolder { (userModel: UserModel?) ->
            UserMakerStateHolder(
                userModel = userModel,
                getCurrentUserRole = get(),
                createUserUseCase = get(),
                updateUserUseCase = get(),
            )
        }
    }

    private val useCasesModules = module {
        factory {
            GetEnvironmentInfoUseCase(
                repository = get()
            )
        }
        factory {
            CreateEnvironmentUseCase(
                repository = get()
            )
        }
        factory {
            GetStatsUseCase(
                repository = get()
            )
        }
        factory {
            LoginUseCase(
                repository = get()
            )
        }
        factory {
            GetCurrentUserIdUseCase(
                repository = get()
            )
        }
        factory {
            GetSimplifiedFormsUseCase(
                repository = get()
            )
        }
        factory {
            GetHomeUseCase(
                repository = get()
            )
        }
        factory {
            LogoutUseCase(
                repository = get()
            )
        }
        factory {
            CreateFormUseCase(
                repository = get()
            )
        }
        factory {
            UpdateFormUseCase(
                repository = get()
            )
        }
        factory {
            DeleteFormsUseCase(
                repository = get()
            )
        }
        factory {
            GetFormUseCase(
                repository = get()
            )
        }
        factory {
            GetFormDashboardUseCase(
                repository = get()
            )
        }
        factory {
            SaveFormFiltersUseCase(
                repository = get()
            )
        }
        factory {
            UpdateAnswersUseCase(
                repository = get()
            )
        }
        factory {
            GetUsersUseCase(
                repository = get()
            )
        }

        factory {
            CreateFormUseCase(
                repository = get()
            )
        }

        factory {
            CopyFormUseCase(
                repository = get()
            )
        }

        factory {
            DeleteUsersUseCase(
                repository = get()
            )
        }

        factory {
            GetCurrentUserRoleUseCase(
                repository = get()
            )
        }
        factory {
            CreateUserUseCase(
                repository = get()
            )
        }
        factory {
            UpdateUserUseCase(
                repository = get()
            )
        }

        factory {
            GetFormUsersLinkerUseCase(
                repository = get()
            )
        }
        factory {
            LinkFormToUsersUseCase(
                repository = get()
            )
        }
        factory {
            GetAnswersCSVUseCase()
        }
        factory {
            GetAnswersXLSXUseCase()
        }
        factory {
            RecoverPasswordUseCase(
                repository = get()
            )
        }
        factory {
            AskForSupportUseCase(
                repository = get()
            )
        }
        factory {
            GetInTouchUseCase(
                repository = get()
            )
        }
    }

    private val inputsModule = module {
        single {
            InputUpdater(
                listOf(
                    TextAreaInputUIEventHandler(),
                    SingleChoiceInputUIEventHandler(),
                    MultipleChoicesInputUIEventHandler(),
                    CompactSingleChoiceInputUIEventHandler(),
                    DateInputUIEventHandler(),
                    TimeInputUIEventHandler(),
                    DateTimeInputUIEventHandler(),
                    MaskedTextAreaInputUIEventHandler(),
                    NumericAreaInputUIEventHandler(),
                    EvaluationInputUIEventHandler()
                )
            )
        }

        single {
            InputComposer(
                listOf(
                    TextAreaInput(),
                    SingleChoiceInput(),
                    MultipleChoicesInput(),
                    CompactSingleChoiceInput(),
                    DateFormInput(),
                    TimeInput(),
                    DateTimeInput(),
                    MaskedTextAreaInput(),
                    NumericAreaInput(),
                    EvaluationInput()
                )
            )
        }

        single {
            InputRequestProductionHandler(
                listOf(
                    TextAreaInputRequestProducer(),
                    SingleChoiceInputRequestProducer(),
                    MultipleChoicesInputRequestProducer(),
                    CompactSingleChoiceInputRequestProducer(),
                    DateInputRequestProducer(),
                    TimeInputRequestProducer(),
                    DateTimeInputRequestProducer(),
                    MaskedTextAreaInputRequestProducer(),
                    NumericAreaInputRequestProducer(),
                    EvaluationInputRequestProducer()
                )
            )
        }

        single {
            InputUIStateValidator(
                listOf(
                    TextAreaInputUIValidator(),
                    SingleChoiceInputUIValidator(),
                    MultipleChoicesInputUIValidator(),
                    CompactSingleChoiceInputUIValidator(),
                    DateInputUIValidator(),
                    TimeInputUIValidator(),
                    DateTimeInputUIValidator(),
                    MaskedTextAreaInputUIValidator(),
                    NumericAreaInputUIValidator(),
                    EvaluationInputUIValidator(),
                )
            )
        }

        single {
            InputGraphDataProducer(
                listOf(
                    CompactSingleChoiceInputGraphHandler(),
                    DateInputGraphHandler(),
                    DateTimeInputGraphHandler(),
                    EvaluationInputGraphHandler(),
                    MultipleChoicesInputGraphHandler(),
                    NumericAreaInputGraphHandler(),
                    SingleChoiceInputGraphHandler(),
                    TimeInputGraphHandler(),
                )
            )
        }
    }
}