package com.catbit.opinionpoll.inputs.responses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("CompactSingleChoiceOptions")
data class CompactSingleChoiceOptionsInputResponse(
    override val identifier: String,
    override val index: Int,
    override val title: String,
    override val subtitle: String?,
    override val isObligatory: Boolean,
    @SerialName("options")
    val options: List<OptionResponse>,
    @SerialName("questions")
    val questions: List<OptionResponse>,
    @SerialName("showSearchBar")
    val showSearchBar: Boolean?
) : InputResponse
