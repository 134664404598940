package com.catbit.opinionpoll.ui.screens.user_maker

import com.catbit.opinionpoll.ui.states.UserUIState

sealed interface UserMakerUIContract {
    sealed interface State : UserMakerUIContract {

        val toolbarTitle: String

        data class Displaying(
            override val toolbarTitle: String,
            val name: String,
            val surname: String,
            val email: String,
            val phoneNumber: String,
            val phoneNumberBuffer: String,
            val role: UserUIState.Role,
            val availableRoles: List<UserUIState.Role>
        ) : State

        data class Loading(
            override val toolbarTitle: String,
        ) : State

        data class Failure(
            override val toolbarTitle: String,
            val message: String
        ) : State

        companion object {
            fun default(
                toolbarTitle: String,
                availableRoles: List<UserUIState.Role>
            ) = Displaying(
                toolbarTitle = toolbarTitle,
                name = "",
                surname = "",
                email = "",
                phoneNumber = "",
                phoneNumberBuffer = "",
                role = UserUIState.Role.ProductUser,
                availableRoles = availableRoles,
            )
        }
    }

    sealed interface Event : UserMakerUIContract {
        data class OnNameChange(val name: String) : Event
        data class OnSurnameChange(val surname: String) : Event
        data class OnEmailChange(val email: String) : Event
        data class OnPhoneNumberChange(val phoneNumber: String) : Event
        data class OnRoleChange(val role: String) : Event
        data object OnSubmit : Event
        data object TryAgain : Event
    }

    sealed interface Effect : UserMakerUIContract {
        data object OnStarSubmitting : Effect
        data object OnSubmitSuccess : Effect
        data class OnSubmitFailure(
            val message: String
        ) : Effect
    }
}