package com.catbit.opinionpoll.ui.screens.form_maker

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.rememberCoroutineScope
import com.catbit.opinionpoll.core.extensions.changeAlpha
import com.catbit.opinionpoll.core.extensions.getStateHolder
import com.catbit.opinionpoll.core.extensions.joda
import com.catbit.opinionpoll.core.sailor.navigation_controller.NavigationController
import com.catbit.opinionpoll.core.sailor.providers.LocalDialogStateProvider
import com.catbit.opinionpoll.core.sailor.providers.LocalSnackbarState
import com.catbit.opinionpoll.core.ui.composables.CircularProgress
import com.catbit.opinionpoll.core.ui.composables.HoverableContent
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.composables.SearchBar
import com.catbit.opinionpoll.core.ui.composables.base_components.*
import com.catbit.opinionpoll.core.ui.composables.effects.SingleEffect
import com.catbit.opinionpoll.core.ui.helpers.ScrollMode
import com.catbit.opinionpoll.core.ui.helpers.scrollContent
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.*
import com.catbit.opinionpoll.inputs.InputComposer
import com.catbit.opinionpoll.inputs.InputType
import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.providers.LocalInputComposerProvider
import com.catbit.opinionpoll.res.Strings
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.coroutines.flow.collectLatest
import kotlinx.datetime.LocalDateTime
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.koin.core.parameter.parametersOf
import kotlin.time.Duration.Companion.seconds

@Composable
fun FormMakerScreen(
    stackEntryId: String,
    formIdentifier: String? = null,
    navigationController: NavigationController,
    stateHolder: FormMakerStateHolder = getStateHolder(
        stackEntryId = stackEntryId,
        parameters = parametersOf(formIdentifier)
    )
) {
    val uiState = stateHolder.uiState.collectAsState().value
    val coroutineScope = rememberCoroutineScope()
    val formComposer = LocalInputComposerProvider.current
    val snackBarState = LocalSnackbarState.current
    val dialogState = LocalDialogStateProvider.current

    SingleEffect {
        stateHolder.effects.collectLatest {
            when (it) {
                FormMakerUIContract.Effect.OnStartSubmittingForm -> {
                    dialogState.show {
                        Column(
                            modifier = Modifier.fillMaxWidth(),
                            horizontalAlignment = Alignment.CenterHorizontally
                        ) {
                            CircularProgress(50)
                            Text(
                                modifier = Modifier.margin(top = 8.px),
                                text = "Enviando formulário"
                            )
                        }
                    }
                }

                FormMakerUIContract.Effect.OnScrollToBottomEffect -> {
                    scrollContent(
                        elementId = "forms-container",
                        mode = ScrollMode.Bottom
                    )
                }

                is FormMakerUIContract.Effect.OnFormSubmissionSuccess -> {
                    dialogState.dismiss()
                    snackBarState.show(
                        message = it.message,
                        coroutineScope = coroutineScope,
                        onDismiss = {
                            navigationController.goBack()
                        }
                    )
                }

                is FormMakerUIContract.Effect.OnFormSubmissionFailure -> {
                    dialogState.dismiss()
                    snackBarState.show(
                        message = it.message,
                        duration = 5.seconds,
                        coroutineScope = coroutineScope
                    )
                }
            }
        }
    }

    Column(
        modifier = Modifier
            .overflow(Overflow.Hidden)
            .fillMaxSize()
    ) {
        Toolbar(
            title = uiState.toolbarTitle,
            onLeftIconClick = { navigationController.goBack() },
            trailingContent = {
                if (uiState is FormMakerUIContract.State.Displaying) {
                    IconButton(
                        onClick = {
                            stateHolder.onEvent(
                                FormMakerUIContract.Event.OnSendFormClick
                            )
                        }
                    ) {
                        MaterialIcon(icon = MaterialIcons.Round.CloudUpload)
                    }
                    Tooltip(
                        target = ElementTarget.PreviousSibling,
                        text = Strings.send,
                        placement = PopupPlacement.BottomRight
                    )
                }
            }
        )

        when (uiState) {
            is FormMakerUIContract.State.Displaying -> {
                Column(
                    modifier = Modifier
                        .id("form-maker")
                        .overflow(Overflow.Hidden)
                        .fillMaxSize()
                ) {
                    Row(
                        modifier = Modifier
                            .fillMaxSize()
                            .overflow(Overflow.Hidden)
                    ) {
                        FormControls(
                            title = uiState.title,
                            sendGeoCoordinates = uiState.sendGeoCoordinates,
                            expirationDate = uiState.expirationDate,
                            searchQuery = uiState.searchQuery,
                            onEvent = { event ->
                                stateHolder.onEvent(event)
                            }
                        )
                        InputsList(
                            inputs = uiState.inputs,
                            searchQuery = uiState.searchQuery,
                            inputComposer = formComposer,
                            onEvent = { event ->
                                stateHolder.onEvent(event)
                            }
                        )
                    }
                }
            }

            is FormMakerUIContract.State.Failure -> {
                Column(
                    modifier = Modifier.fillMaxSize(),
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Text("Erro")
                }
            }

            is FormMakerUIContract.State.Loading -> {
                Column(
                    modifier = Modifier.fillMaxSize(),
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    CircularProgress(50)
                    Text("Carregando")
                }
            }
        }
    }
}

@Composable
fun FormControls(
    title: String,
    searchQuery: String,
    expirationDate: LocalDateTime,
    sendGeoCoordinates: Boolean,
    onEvent: (FormMakerUIContract.Event) -> Unit
) {
    Column(
        modifier = Modifier
            .fillMaxHeight()
            .width(30.percent)
            .padding(left = 24.px, bottom = 24.px)
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .borderRadius(16.px)
                .clip(Rect(16.px))
                .padding(all = 16.px)
                .background(sitePalette().surface)
        ) {

            Text(
                modifier = Modifier
                    .fillMaxWidth()
                    .noWrap()
                    .titleMedium()
                    .fontWeight(FontWeight.Bold),
                text = Strings.formTitle
            )
            TextField(
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                text = title,
                onTextChanged = {
                    onEvent(
                        FormMakerUIContract.Event.OnTitleChange(it)
                    )
                }
            )

            Text(
                modifier = Modifier
                    .noWrap()
                    .margin(top = 16.px)
                    .titleMedium()
                    .fontWeight(FontWeight.Bold)
                    .fillMaxWidth(),
                text = Strings.expirationDate
            )
            DateTimePicker(
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                value = expirationDate.joda(),
                showClearButton = false,
                onDateTimePick = {
                    it?.let {
                        onEvent(
                            FormMakerUIContract.Event.OnExpirationDateChange(it)
                        )
                    }
                }
            )

            Row(
                modifier = Modifier
                    .margin(top = 16.px)
                    .fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Checkbox(
                    modifier = Modifier.margin(right = 8.px),
                    checked = sendGeoCoordinates,
                    onCheck = {
                        onEvent(
                            FormMakerUIContract.Event.OnSendGeoCoordinatesToggle(it)
                        )
                    }
                )
                Text(
                    modifier = Modifier
                        .noWrap()
                        .titleMedium()
                        .fontWeight(FontWeight.Bold),
                    text = Strings.sendCoordinates
                )
            }

            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .display(DisplayStyle.Flex)
                    .flexWrap(FlexWrap.Wrap)
                    .margin(top = 16.px)
                    .borderRadius(16.px)
                    .clip(Rect(16.px))
                    .background(sitePalette().surfaceVariant)
                    .padding(vertical = 8.px, horizontal = 24.px),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                InputType.all.forEach { type ->
                    IconButton(
                        onClick = {
                            onEvent(
                                FormMakerUIContract.Event.OnAddFormClick(type)
                            )
                        }
                    ) {
                        type.Icon(size = 24.px, Modifier)
                    }
                }
            }

            SearchBar(
                modifier = Modifier
                    .margin(top = 16.px)
                    .fillMaxWidth(),
                query = searchQuery,
                hint = Strings.formMakerSearchHint,
                backgroundColor = sitePalette().surfaceVariant,
                onSearch = {
                    onEvent(
                        FormMakerUIContract.Event.OnSearch(it)
                    )
                },
                onClear = {
                    onEvent(
                        FormMakerUIContract.Event.OnClearSearch
                    )
                }
            )

            HoverableContent(
                modifier = Modifier
                    .height(48.px)
                    .margin(top = 16.px)
                    .fillMaxWidth(),
                onHoverEndColor = sitePalette().surfaceVariant,
                onClick = {
                    onEvent(FormMakerUIContract.Event.OnClearAllForms)
                }
            ) {
                Row(
                    modifier = Modifier.fillMaxSize(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.Center
                ) {
                    MaterialIcon(
                        modifier = Modifier.margin(right = 8.px),
                        icon = MaterialIcons.Round.DeleteForever
                    )
                    Text(
                        modifier = Modifier
                            .bodyLarge(),
                        text = Strings.clearAllFields
                    )
                }
            }
        }
    }
}

@Composable
fun InputsList(
    inputs: List<InputUIState>,
    searchQuery: String,
    inputComposer: InputComposer,
    onEvent: (FormMakerUIContract.Event) -> Unit
) {
    Column(
        modifier = Modifier
            .id("forms-container")
            .fillMaxSize()
            .display(DisplayStyle.Flex)
            .padding(bottom = 24.px, left = 24.px, right = 24.px)
            .gap(32.px)
            .overflow(Overflow.Auto)
            .scrollBehavior(ScrollBehavior.Smooth)
    ) {

        val filteredInputs = inputs.filter { input ->
            input.title.contains(searchQuery, ignoreCase = true)
                    || input.subtitle.contains(searchQuery, ignoreCase = true)
                    || input.identifier.contains(searchQuery, ignoreCase = true)
        }

        if (filteredInputs.isEmpty()) {
            Column(
                modifier = Modifier.fillMaxSize(),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center
            ) {
                if (searchQuery.isNotBlank()) {
                    MaterialIcon(
                        icon = MaterialIcons.Round.SearchOff,
                        size = 100.px
                    )
                    Text(
                        modifier = Modifier
                            .margin(top = 32.px)
                            .headlineMedium(),
                        text = "Nenhum resultado encontrado"
                    )
                    Text(
                        modifier = Modifier
                            .headlineSmall()
                            .textAlign(TextAlign.Center)
                            .color(sitePalette().onBackground.changeAlpha(0.8f)),
                        text = "Busque por título, subtítulo ou identificador de um campo"
                    )
                } else {
                    Row(
                        modifier = Modifier
                            .width(200.px)
                            .height(80.px)
                            .displayLarge()
                            .display(DisplayStyle.Flex)
                            .flexWrap(FlexWrap.Wrap)
                    ) {
                        InputType.all.forEach { type ->
                            type.Icon(
                                size = 40.px,
                                modifier = Modifier.width(20.percent)
                            )
                        }
                    }

                    Text(
                        modifier = Modifier
                            .margin(top = 32.px)
                            .headlineMedium(),
                        text = "Adicione campos!"
                    )
                    Text(
                        modifier = Modifier
                            .headlineSmall()
                            .textAlign(TextAlign.Center)
                            .color(sitePalette().onBackground.changeAlpha(0.8f)),
                        text = "Escolha um tipo de campo no menu lateral e começe a editar!"
                    )
                }
            }
        } else {
            filteredInputs
                .forEach { formUIState ->
                    Row(
                        modifier = Modifier
                            .id("${formUIState.actualIdentifier}_of_${formUIState.type::class}")
                            .fillMaxWidth()
                    ) {
                        Column(
                            modifier = Modifier.margin(right = 8.px),
                            horizontalAlignment = Alignment.CenterHorizontally
                        ) {
                            Box(
                                modifier = Modifier
                                    .size(32.px)
                                    .margin(bottom = 16.px),
                                contentAlignment = Alignment.Center
                            ) {
                                formUIState.type.Icon(size = 24.px, Modifier)
                            }
                            Text(
                                modifier = Modifier.titleMedium(),
                                text = formUIState.index.toString()
                            )
                            IconButton(
                                onClick = {
                                    onEvent(
                                        FormMakerUIContract.Event.OnMoveFormUp(formUIState.actualIdentifier)
                                    )
                                }
                            ) {
                                MaterialIcon(MaterialIcons.Round.ArrowCircleUp)
                            }
                            Tooltip(
                                target = ElementTarget.PreviousSibling,
                                placement = PopupPlacement.Right,
                                text = "Mover item para cima"
                            )
                            IconButton(
                                onClick = {
                                    onEvent(
                                        FormMakerUIContract.Event.OnMoveFormDown(formUIState.actualIdentifier)
                                    )
                                }
                            ) {
                                MaterialIcon(MaterialIcons.Round.ArrowCircleDown)
                            }
                            Tooltip(
                                target = ElementTarget.PreviousSibling,
                                placement = PopupPlacement.Right,
                                text = "Mover item para baixo"
                            )
                            IconButton(
                                onClick = {
                                    onEvent(
                                        FormMakerUIContract.Event.OnDuplicateFormUp(formUIState.actualIdentifier)
                                    )
                                }
                            ) {
                                MaterialIcon(MaterialIcons.Round.Upload)
                            }
                            Tooltip(
                                target = ElementTarget.PreviousSibling,
                                placement = PopupPlacement.Right,
                                text = "Copiar item para cima"
                            )
                            IconButton(
                                onClick = {
                                    onEvent(
                                        FormMakerUIContract.Event.OnDuplicateFormDown(formUIState.actualIdentifier)
                                    )
                                }
                            ) {
                                MaterialIcon(MaterialIcons.Round.Download)
                            }
                            Tooltip(
                                target = ElementTarget.PreviousSibling,
                                placement = PopupPlacement.Right,
                                text = "Copiar item para baixo"
                            )
                            IconButton(
                                onClick = {
                                    onEvent(
                                        FormMakerUIContract.Event.OnRemoveFormClick(formUIState.actualIdentifier)
                                    )
                                }
                            ) {
                                MaterialIcon(MaterialIcons.Round.DeleteOutline)
                            }
                            Tooltip(
                                target = ElementTarget.PreviousSibling,
                                placement = PopupPlacement.Right,
                                text = "Apagar item"
                            )
                        }

                        inputComposer.Compose(
                            uiState = formUIState,
                            onEvent = { formUIEvent ->
                                onEvent(
                                    FormMakerUIContract.Event.OnFormUIEvent(formUIEvent)
                                )
                            }
                        )
                    }
                }
        }
    }
}