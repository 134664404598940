package com.catbit.opinionpoll.inputs.events

sealed interface MultipleChoicesInputEventData {
    data object OnAddOption : MultipleChoicesInputEventData

    data class OnOptionTitleChange(
        val actualIdentifier: String,
        val newTitle: String
    ) : MultipleChoicesInputEventData

    data class OnOptionRemove(
        val actualIdentifier: String
    ) : MultipleChoicesInputEventData

    data class OnMinSelectionChange(
        val minSelection: Int
    ) : MultipleChoicesInputEventData

    data class OnMaxSelectionChange(
        val maxSelection: Int
    ) : MultipleChoicesInputEventData

    data class OnToggleHasOtherOption(
        val hasOtherOption: Boolean
    ) : MultipleChoicesInputEventData

    data class OnOtherPlaceholderTextTitleChange(
        val newPlaceholder: String
    ) : MultipleChoicesInputEventData

    data class OnMaxOtherOptionsChange(
        val maxOtherOptions: Int
    ) : MultipleChoicesInputEventData

    data class OnOtherOptionTextLengthChange(
        val newLength: Int
    ) : MultipleChoicesInputEventData

    class OnAddBatchOptions(
        val rawText: String
    ) : MultipleChoicesInputEventData

    class OnMoveOptionUp(
        val actualIdentifier: String
    ) : MultipleChoicesInputEventData

    class OnMoveOptionDown(
        val actualIdentifier: String
    ) : MultipleChoicesInputEventData
}