package com.catbit.opinionpoll.inputs.models

import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.inputs.InputType
import com.catbit.opinionpoll.inputs.inputs.MaskedTextAreaInputUIState
import kotlinx.serialization.Serializable

@Serializable
data class MaskedTextAreaInputModel(
    override val identifier: String,
    override val title: String,
    override val subtitle: String?,
    override val isObligatory: Boolean,
    override val index: Int,
    val mask: String,
    val replaceableChar: Char,
    val inputType: Type,
) : InputModel {

    enum class Type(val type: String) {
        Text("text"), Number("number");

        companion object {
            fun getFromString(type: String?) = entries.firstOrNull { it.type == type } ?: Text
        }
    }

    override fun toUIState() = MaskedTextAreaInputUIState(
        actualIdentifier = UUID.stringUUID(),
        type = InputType.MaskedTextArea,
        identifier = identifier,
        title = title,
        subtitle = subtitle.orEmpty(),
        isObligatory = isObligatory,
        index = index,
        errorMessage = null,
        isEnabled = true,
        mask = mask,
        replaceableChar = replaceableChar.toString(),
        inputType = when (inputType) {
            Type.Text -> MaskedTextAreaInputUIState.Type.Text
            Type.Number -> MaskedTextAreaInputUIState.Type.Number
        }
    )

    override fun knowsIdentifier(identifier: String) = identifier == this.identifier
    override val identifiers = listOf(identifier)
    override val titleToIdentifierMapping = listOf(title to identifier)
}