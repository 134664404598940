package com.catbit.opinionpoll.inputs

import com.catbit.opinionpoll.inputs.validators.InputUIValidator

class InputUIStateValidator(
    private val validators: List<InputUIValidator<*>>
) {
    fun validate(
        inputUIState: InputUIState,
        allIdentifiers: List<Pair<String, String>>
    ): InputUIState =
        validators
            .first { it.match(inputUIState) }
            .validate(
                inputUIState = inputUIState,
                allIdentifiers = allIdentifiers
                    .filterNot { it.first == inputUIState.actualIdentifier }
                    .unzip()
                    .second
            )
}