package com.catbit.opinionpoll.inputs.models

import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.inputs.InputType
import com.catbit.opinionpoll.inputs.inputs.OptionInput
import com.catbit.opinionpoll.inputs.inputs.SingleChoiceInputUIState
import kotlinx.serialization.Serializable

@Serializable
data class SingleChoiceOptionsInputModel(
    override val identifier: String,
    override val title: String,
    override val subtitle: String?,
    override val isObligatory: Boolean,
    override val index: Int,
    val options: List<OptionModel>,
    val otherOptionPlaceholderText: String,
    val maxOtherOptionTextLength: Int,
    val otherOption: OptionModel?,
    val style: Style,
    val showSearchBar: Boolean
) : InputModel {

    enum class Style(val style: String) {
        DropDown("dropdown"), Radio("radio");

        companion object {
            fun getFromString(style: String) =
                entries.firstOrNull { it.style == style } ?: Radio
        }
    }

    override fun toUIState() = SingleChoiceInputUIState(
        actualIdentifier = UUID.stringUUID(),
        type = InputType.SingleChoice,
        identifier = identifier,
        title = title,
        subtitle = subtitle.orEmpty(),
        isObligatory = isObligatory,
        index = index,
        errorMessage = null,
        isEnabled = true,
        options = options.map { option ->
            OptionInput(
                actualIdentifier = option.identifier,
                title = option.title
            )
        },
        style = when (style) {
            Style.DropDown -> SingleChoiceInputUIState.SingleChoiceStyle.Dropdown
            Style.Radio -> SingleChoiceInputUIState.SingleChoiceStyle.Radio
        },
        showSearchBar = showSearchBar,
        otherOptionPlaceholderText = otherOptionPlaceholderText,
        maxOtherOptionTextLength = maxOtherOptionTextLength,
        hasOtherOption = otherOption != null,
        otherOptionTitle = otherOption?.title.orEmpty()
    )

    override fun knowsIdentifier(identifier: String) = identifier == this.identifier
    override val identifiers = listOf(identifier)
    override val titleToIdentifierMapping = listOf(title to identifier)
}