package com.catbit.opinionpoll.inputs.requests.requests

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("TextArea")
data class TextAreaInputRequest(
    override val identifier: String,
    override val title: String,
    override val subtitle: String?,
    override val isObligatory: Boolean,
    override val index: Int,
    @SerialName("maxLength")
    val maxLength: Int
) : InputRequest