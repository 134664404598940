package com.catbit.opinionpoll.core.extensions

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.remember
import com.catbit.opinionpoll.core.sailor.providers.LocalNavigationController
import com.catbit.opinionpoll.core.state_holder.CreationContext
import com.catbit.opinionpoll.core.state_holder.StateHolder
import com.catbit.opinionpoll.core.state_holder.StateHolderFactory
import org.koin.compose.getKoin
import org.koin.core.annotation.KoinInternalApi
import org.koin.core.parameter.ParametersHolder
import org.koin.core.parameter.parametersOf
import org.koin.core.qualifier.Qualifier
import org.koin.core.qualifier.StringQualifier
import org.koin.core.scope.Scope

@OptIn(KoinInternalApi::class)
@Composable
inline fun <reified T : StateHolder> getStandaloneStateHolder(
    qualifier: Qualifier? = null,
    parameters: ParametersHolder = parametersOf()
): T {

    val key = T::class.simpleName.orEmpty()
    val newQualifier = StringQualifier("$key${qualifier?.toString().orEmpty()}")
    val builderBlock = getKoin().get<Scope.(ParametersHolder) -> T>(newQualifier)
    val scope = getKoin().scopeRegistry.rootScope

    val stateHolderCreationContext = remember(key) {
        StateHolderFactory.getStateHolder(
            key = key,
            builderBlock = { builderBlock(scope, parameters) }
        )
    }

    LaunchedEffect(key) {
        when (stateHolderCreationContext.context) {
            CreationContext.Created -> stateHolderCreationContext.stateHolder.onStart()
            CreationContext.Recovered -> stateHolderCreationContext.stateHolder.onRecover()
        }
    }

    DisposableEffect(key) {
        onDispose {
            stateHolderCreationContext.stateHolder.onDispose()
        }
    }

    return stateHolderCreationContext.stateHolder
}

@OptIn(KoinInternalApi::class)
@Composable
inline fun <reified T : StateHolder> getStateHolder(
    stackEntryId: String,
    qualifier: Qualifier? = null,
    parameters: ParametersHolder = parametersOf()
): T {

    val key = T::class.simpleName
    val newQualifier = StringQualifier("$key${qualifier?.toString().orEmpty()}")
    val builderBlock = getKoin().get<Scope.(ParametersHolder) -> T>(newQualifier)
    val scope = getKoin().scopeRegistry.rootScope
    val navigationController = LocalNavigationController.current
    val stateHolderKey = "$key$stackEntryId"

    val stateHolderCreationContext = remember(stateHolderKey) {
        StateHolderFactory.getStateHolder(
            key = stateHolderKey,
            builderBlock = { builderBlock(scope, parameters) }
        ).also {
            navigationController.addStackEntryDestructionObserver(stackEntryId) {
                StateHolderFactory.removeStateHolder(stateHolderKey)
            }
        }
    }

    LaunchedEffect(stateHolderKey) {
        when (stateHolderCreationContext.context) {
            CreationContext.Created -> stateHolderCreationContext.stateHolder.onStart()
            CreationContext.Recovered -> stateHolderCreationContext.stateHolder.onRecover()
        }
    }

    DisposableEffect(stateHolderKey) {
        onDispose {
            stateHolderCreationContext.stateHolder.onDispose()
        }
    }

    return stateHolderCreationContext.stateHolder
}