package com.catbit.opinionpoll.inputs.events_handler

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.events.InputUIEventDefaultData
import com.catbit.opinionpoll.inputs.events.TextAreaInputEventData
import com.catbit.opinionpoll.inputs.inputs.TextAreaInputUIState

class TextAreaInputUIEventHandler : InputUIEventHandler<TextAreaInputUIState> {

    override fun match(formIdentifier: String, uiState: InputUIState) =
        uiState is TextAreaInputUIState && formIdentifier == uiState.actualIdentifier

    override fun handle(uiState: TextAreaInputUIState, eventData: Any) = when (eventData) {
        is TextAreaInputEventData.OnMaxLengthChange -> uiState.copy(maxLength = eventData.newMaxLength)
        is InputUIEventDefaultData.IdentifierChange -> uiState.copy(identifier = eventData.newIdentifier)
        is InputUIEventDefaultData.TitleChange -> uiState.copy(title = eventData.newTitle)
        is InputUIEventDefaultData.SubtitleChange -> uiState.copy(subtitle = eventData.newSubtitle)
        is InputUIEventDefaultData.ObligatoryToggle -> uiState.copy(isObligatory = eventData.isObligatory)
        else -> uiState
    }
}