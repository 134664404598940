package com.catbit.opinionpoll.domain.form_dashboard

import com.catbit.opinionpoll.core.domain.UseCase
import com.catbit.opinionpoll.data.models.FormFilterModel
import com.catbit.opinionpoll.data.repositories.OpinionPollRepository

class SaveFormFiltersUseCase(
    private val repository: OpinionPollRepository
) : UseCase<Unit, SaveFormFiltersUseCase.Params>() {

    data class Params(
        val formId: String,
        val filters: List<FormFilterModel>
    )

    override suspend fun execute(params: Params) = repository.saveFormFilters(
        formId = params.formId,
        filters = params.filters
    )
}