package com.catbit.opinionpoll.data.repositories

import com.catbit.opinionpoll.core.exceptions.HttpResponseException
import com.catbit.opinionpoll.core.extensions.*
import com.catbit.opinionpoll.core.mapper.Mappers
import com.catbit.opinionpoll.core.mapper.mapTo
import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.data.data_sources.local_storage.OpinionPollLocalStorage
import com.catbit.opinionpoll.data.data_sources.networking.OpinionPollNetwork
import com.catbit.opinionpoll.data.data_sources.networking.requests.AskForSupportRequest
import com.catbit.opinionpoll.data.data_sources.networking.requests.GetInTouchRequest
import com.catbit.opinionpoll.data.data_sources.networking.requests.SimplifiedFormAnswerRequest
import com.catbit.opinionpoll.data.data_sources.networking.requests.UpdateAnswersRequest
import com.catbit.opinionpoll.data.data_sources.networking.responses.FormResponse
import com.catbit.opinionpoll.data.data_sources.networking.responses.SimplifiedFormResponse
import com.catbit.opinionpoll.data.data_sources.networking.responses.UserResponse
import com.catbit.opinionpoll.data.models.*
import com.catbit.opinionpoll.inputs.requests.requests.InputRequest
import kotlinx.coroutines.async
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.internal.JSJoda.LocalTime
import kotlinx.datetime.internal.JSJoda.ZoneId
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

class OpinionPollRepositoryImpl(
    private val network: OpinionPollNetwork,
    private val localStorage: OpinionPollLocalStorage,
    private val mappers: Mappers
) : OpinionPollRepository {

    @OptIn(ExperimentalSerializationApi::class)
    private val json = Json {
        ignoreUnknownKeys = true
        explicitNulls = false
    }

    override suspend fun login(email: String, password: String) = safeResult {
        with(
            network.login(
                email = email,
                password = password,
                deviceUUID = UUID.stringUUID()
            )
        ) {
            localStorage.saveLoginCredentials(
                kind = kind,
                localId = localId,
                email = email,
                role = role,
                displayName = displayName,
                idToken = idToken,
                registered = registered,
                environment = environment,
                environmentPlan = environmentPlan,
                environmentPlanCreatedIn = environmentPlanCreatedIn,
                environmentPlanExpiresIn = environmentPlanExpiresIn,
                environmentImage = environmentImage,
                environmentName = environmentName,
                refreshToken = refreshToken,
                expiresIn = expiresIn
            )
        }
    }
        .notNull()

    override suspend fun getCurrentUserId() = safeResult {
        localStorage.getCredentialLocalId()
    }.notNull()

    override suspend fun getForms() = safeResult {
        handleIdTokenExpired {
            val idToken = localStorage.getCredentialIdToken()
            network.getForms(idToken.orEmpty())
        }
    }
        .notNull()
        .map {
            it.mapTo<SimplifiedFormResponse, SimplifiedFormModel>(mappers)
        }

    override suspend fun logout() = safeResult {
        localStorage.cleanLoginCredentials()
    }

    override suspend fun createForm(
        version: Long,
        title: String,
        tips: String?,
        expirationDate: LocalDateTime,
        sendGeoCoordinates: Boolean,
        template: List<InputRequest>,
    ) = safeResult {
        handleIdTokenExpired {
            val idToken = localStorage.getCredentialIdToken().orEmpty()
            val currentUser = localStorage.getCredentialLocalId().orEmpty()

            network.createForm(
                idToken = idToken,
                currentUser = currentUser,
                formIdentifier = UUID.stringUUID(),
                version = version,
                title = title,
                tips = tips,
                expirationDate = expirationDate,
                sendGeoCoordinates = sendGeoCoordinates,
                template = template,
            )
        }
    }

    override suspend fun updateForm(
        formIdentifier: String,
        version: Long,
        title: String,
        tips: String?,
        expirationDate: LocalDateTime,
        sendGeoCoordinates: Boolean,
        template: List<InputRequest>
    ) = safeResult {
        handleIdTokenExpired {
            val idToken = localStorage.getCredentialIdToken().orEmpty()

            network.updateForm(
                formIdentifier = formIdentifier,
                version = version,
                idToken = idToken,
                title = title,
                tips = tips,
                expirationDate = expirationDate,
                sendGeoCoordinates = sendGeoCoordinates,
                template = template,
            )
        }
    }

    override suspend fun copyForm(
        newTitle: String,
        formIdentifier: String
    ) = safeResult {
        handleIdTokenExpired {
            val idToken = localStorage.getCredentialIdToken().orEmpty()

            network.copyForm(
                idToken = idToken,
                newTitle = newTitle,
                formIdentifier = formIdentifier,
            )
        }
    }

    override suspend fun deleteForms(
        formIdentifiers: List<String>
    ) = safeResult {
        handleIdTokenExpired {
            val idToken = localStorage.getCredentialIdToken().orEmpty()

            network.deleteForms(
                idToken = idToken,
                formIdentifiers = formIdentifiers
            )
        }
    }
        .notNull()

    override suspend fun getUsers() = safeResult {
        handleIdTokenExpired {
            val idToken = localStorage.getCredentialIdToken().orEmpty()
            val currentUserRole = UserModel.UserRole.getRoleFromString(localStorage.getCredentialRole().orEmpty())

            network.getUsers(
                idToken = idToken
            ).filter { user ->
                UserModel.UserRole.getRoleFromString(user.role) < currentUserRole
            }
        }
    }
        .notNull()
        .map { it.mapTo<UserResponse, UserModel>(mappers) }

    override suspend fun getFormUsersLinker(
        formIdentifier: String
    ) = safeResult {
        handleIdTokenExpired {
            coroutineScope {
                val idToken = localStorage.getCredentialIdToken().orEmpty()
                val users = async { network.getUsers(idToken) }
                val form = async {
                    network.getForm(
                        idToken = idToken,
                        formIdentifier = formIdentifier
                    )
                }

                val usersResponse = users.await()
                val formResponse = form.await()

                FormUsersLinkerModel(
                    users = usersResponse.mapTo(mappers),
                    form = formResponse.mapTo(mappers)
                )
            }
        }
    }

    override suspend fun linkFormToUsers(
        userIdentifiers: List<String>,
        formId: String
    ) = safeResult {
        handleIdTokenExpired {
            val idToken = localStorage.getCredentialIdToken().orEmpty()
            network.linkFormToUsers(
                idToken = idToken,
                formId = formId,
                userIdentifiers = userIdentifiers
            )
        }
    }
        .notNull()

    override suspend fun getForm(
        formIdentifier: String
    ) = safeResult {
        handleIdTokenExpired {
            val idToken = localStorage.getCredentialIdToken().orEmpty()

            network.getForm(
                idToken = idToken,
                formIdentifier = formIdentifier
            )
        }
    }
        .notNull()
        .map { it.mapTo<FormResponse, FormModel>(mappers) }

    override suspend fun deleteUsers(
        userIdentifiers: List<String>
    ) = safeResult {
        handleIdTokenExpired {
            val idToken = localStorage.getCredentialIdToken().orEmpty()

            network.deleteUsers(
                idToken = idToken,
                userIdentifiers = userIdentifiers
            )
        }
    }

    override suspend fun updateUser(
        userIdentifier: String,
        phoneNumber: String,
        role: String,
        name: String,
        email: String
    ) = safeResult {
        handleIdTokenExpired {
            val idToken = localStorage.getCredentialIdToken().orEmpty()

            network.updateUser(
                idToken = idToken,
                userIdentifier = userIdentifier,
                phoneNumber = phoneNumber,
                role = role,
                name = name,
                email = email
            )
        }
    }

    override suspend fun getHome() = safeResult {
        handleIdTokenExpired {
            coroutineScope {
                val idToken = localStorage.getCredentialIdToken().orEmpty()
                val forms = async { network.getForms(idToken) }
                val currentTime = LocalTime.now(ZoneId.SYSTEM).kotlinLocalTime()
                val userName = localStorage.getCredentialDisplayName().orEmpty().split(" ").first()

                val greetings = if (currentTime.isAfternoon()) {
                    "Boa tarde, $userName!"
                } else if ((currentTime.isMorning())) {
                    "Bom dia, $userName!"
                } else "Boa noite, $userName!"

                HomeModel(
                    greetings = greetings,
                    forms = forms.await().mapTo(mappers)
                )
            }
        }
    }

    override suspend fun getFormDashboard(
        formIdentifier: String
    ) = safeResult {
        handleIdTokenExpired {
            coroutineScope {
                val idToken = localStorage.getCredentialIdToken().orEmpty()
                val form = async { network.getForm(idToken, formIdentifier) }
                val users = async { network.getUsers(idToken) }
                val answers = async { network.getFormsAnswers(idToken, formIdentifier) }

                FormDashboardModel(
                    form = form.await().mapTo(mappers),
                    users = users.await().mapTo(mappers),
                    answers = answers.await().mapTo(mappers)
                )
            }
        }
    }

    override suspend fun createUser(
        phoneNumber: String,
        role: String,
        name: String,
        email: String
    ) = safeResult {
        handleIdTokenExpired {
            val idToken = localStorage.getCredentialIdToken().orEmpty()

            network.createUser(
                idToken = idToken,
                phoneNumber = phoneNumber,
                role = role,
                name = name,
                email = email,
            )
        }
    }

    override suspend fun getCurrentUserRole() = safeResult {
        UserModel.UserRole.getRoleFromString(localStorage.getCredentialRole().orEmpty())
    }.notNull()

    override suspend fun recoverPassword(email: String) = safeResult {
        network.resetPassword(email = email)
    }.notNull()

    override suspend fun createEnvironment(
        email: String,
        fullName: String,
        password: String,
        phoneNumber: String,
        companyName: String
    ) = safeResult {
        network.createEnvironment(
            email = email,
            fullName = fullName,
            password = password,
            phoneNumber = phoneNumber,
            companyName = companyName
        )
    }

    override suspend fun getEnvironmentInfo() = safeResult {
        EnvironmentInfoModel(
            environmentId = localStorage.getCredentialEnvironment().orEmpty(),
            environmentName = localStorage.getCredentialEnvironmentName().orEmpty(),
            environmentPlan = localStorage.getCredentialEnvironmentPlan().orEmpty(),
            planExpirationDate = try {
                localStorage.getCredentialEnvironmentPlanExpiresIn().orEmpty().toLocalDateTime()
            } catch (e: Throwable) {
                null
            },
            environmentImage = localStorage.getCredentialEnvironmentImage().orEmpty(),
        )
    }

    override suspend fun saveFormFilters(
        formId: String,
        filters: List<FormFilterModel>
    ) = safeResult {
        handleIdTokenExpired {
            val idToken = localStorage.getCredentialIdToken().orEmpty()

            network.saveFormFilters(
                idToken = idToken,
                formId = formId,
                filters = filters.mapTo(mappers)
            )
        }
    }

    override fun getStats(): Flow<StatsModel> = flow {

        val idToken = localStorage.getCredentialIdToken().orEmpty()

        handleIdTokenExpired {
            emit(network.getStats(idToken).mapTo(mappers))
        }
    }

    override suspend fun updateAnswers(
        formIdentifier: String,
        answersToAdd: List<FormAnswerModel>,
        answersToUpdate: List<Pair<String, Map<String, String>>>,
        answersToDelete: List<String>
    ) = safeResult {
        handleIdTokenExpired {
            val idToken = localStorage.getCredentialIdToken().orEmpty()

            network.updateAnswers(
                idToken = idToken,
                formIdentifier = formIdentifier,
                answerUpdateRequest = UpdateAnswersRequest(
                    answersToAdd = answersToAdd.mapTo(mappers),
                    answersToUpdate = answersToUpdate.map { (identifier, answers) ->
                        SimplifiedFormAnswerRequest(
                            identifier = identifier,
                            answer = json.encodeToString(answers)
                        )
                    },
                    answersToDelete = answersToDelete
                )
            )
        }
    }

    override suspend fun askForSupport(
        topic: String,
        subject: String,
        message: String
    ) = safeResult {
        handleIdTokenExpired {
            val idToken = localStorage.getCredentialIdToken().orEmpty()

            network.askForSupport(
                idToken = idToken,
                askForSupportRequest = AskForSupportRequest(
                    topic = topic,
                    subject = subject,
                    message = message
                )
            )
        }
    }

    override suspend fun getInTouch(
        name: String,
        email: String,
        topic: String,
        subject: String,
        message: String
    ) = safeResult {
        network.getInTouch(
            getInTouchRequest = GetInTouchRequest(
                name = name,
                email = email,
                topic = topic,
                subject = subject,
                message = message
            )
        )
    }

    private suspend fun <T> handleIdTokenExpired(
        block: suspend () -> T
    ): T {
        return try {
            block()
        } catch (e: Throwable) {
            if (e is HttpResponseException && e.statusCode.value == 498) {
                val response = network.refreshToken(
                    refreshToken = localStorage.getCredentialRefreshToken()!!
                )
                localStorage.updateCredentialIdToken(response.idToken)
                localStorage.updateCredentialRefreshToken(response.refreshToken)
                block()
            } else throw e
        }
    }
}