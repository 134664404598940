package com.catbit.opinionpoll.inputs.events

import com.catbit.opinionpoll.inputs.inputs.MaskedTextAreaInputUIState

sealed interface MaskedTextAreaInputEventData {

    data class OnMaskChange(
        val newMask: String
    ) : MaskedTextAreaInputEventData

    data class OnReplaceableCharChange(
        val newReplaceableChar: String
    ) : MaskedTextAreaInputEventData

    data class OnInputTypeChange(
        val newType: MaskedTextAreaInputUIState.Type
    ) : MaskedTextAreaInputEventData

}