package com.catbit.opinionpoll.inputs.responses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed interface InputResponse {
    @SerialName("identifier")
    val identifier: String

    @SerialName("index")
    val index: Int

    @SerialName("title")
    val title: String

    @SerialName("subtitle")
    val subtitle: String?

    @SerialName("isObligatory")
    val isObligatory: Boolean
}