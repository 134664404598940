package com.catbit.opinionpoll.core.ui.composables.base_components

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.extensions.*
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.bodyLarge
import com.catbit.opinionpoll.core.ui.modifiers.noWrap
import com.catbit.opinionpoll.core.ui.modifiers.thenIf
import com.catbit.opinionpoll.core.uuid.UUID
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.overlay.*
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.flow.map
import kotlinx.datetime.internal.JSJoda.*
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import kotlinx.datetime.LocalDateTime as KotlinLocalDateTime

@Composable
fun DateTimePicker(
    modifier: Modifier = Modifier,
    value: LocalDateTime?,
    enabled: Boolean = true,
    backgroundColor: Color = sitePalette().surfaceVariant,
    showClearButton: Boolean = true,
    onDateTimePick: (KotlinLocalDateTime?) -> Unit
) {
    val mainPopCloseStrategyControl = remember { OpenClosePopupStrategy.manual() }
    val mainPopKeepOpenStrategyControl = remember { KeepPopupOpenStrategy.manual() }
    val monthPopCloseStrategyControl = remember { OpenClosePopupStrategy.manual() }
    val monthPopKeepOpenStrategyControl = remember { KeepPopupOpenStrategy.manual() }
    val yearPopCloseStrategyControl = remember { OpenClosePopupStrategy.manual() }
    val yearPopKeepOpenStrategyControl = remember { KeepPopupOpenStrategy.manual() }
    val hourPopCloseStrategyControl = remember { OpenClosePopupStrategy.manual() }
    val hourPopKeepOpenStrategyControl = remember { KeepPopupOpenStrategy.manual() }
    val minutePopCloseStrategyControl = remember { OpenClosePopupStrategy.manual() }
    val minutePopKeepOpenStrategyControl = remember { KeepPopupOpenStrategy.manual() }

    val hasFocus by mainPopCloseStrategyControl
        .requestFlow
        .map { it == OpenClose.OPEN }
        .collectAsState(false)

    val enableMonthPicker by yearPopCloseStrategyControl
        .requestFlow
        .map { it != OpenClose.OPEN }
        .collectAsState(true)

    val enableYearPicker by monthPopCloseStrategyControl
        .requestFlow
        .map { it != OpenClose.OPEN }
        .collectAsState(true)

    val enableHourPicker by minutePopCloseStrategyControl
        .requestFlow
        .map { it != OpenClose.OPEN }
        .collectAsState(true)

    val isHourSelected by hourPopCloseStrategyControl
        .requestFlow
        .map { it == OpenClose.OPEN }
        .collectAsState(true)

    val enableMinutePicker by hourPopCloseStrategyControl
        .requestFlow
        .map { it != OpenClose.OPEN }
        .collectAsState(true)

    val isMinuteSelected by minutePopCloseStrategyControl
        .requestFlow
        .map { it == OpenClose.OPEN }
        .collectAsState(true)

    var containerYPosition by remember { mutableStateOf(0.0) }
    var containerXPosition by remember { mutableStateOf(0.0) }
    var containerWidth by remember { mutableStateOf(0.0) }
    val id by remember { mutableStateOf(UUID.stringUUID()) }

    val today = remember { LocalDate.now(clockOrZone = ZoneId.SYSTEM) }
    val currentTime = remember { LocalTime.now(ZoneId.SYSTEM) }
    var selectedDate by remember {
        mutableStateOf(
            LocalDate.of(
                year = value?.year() ?: today.year(),
                month = value?.month() ?: today.month(),
                dayOfMonth = value?.dayOfMonth() ?: today.dayOfMonth()
            )
        )
    }
    var calendar by remember { mutableStateOf(buildCalendar(selectedDate)) }
    var selectedHour by remember { mutableStateOf(value?.hour() ?: currentTime.hour()) }
    var selectedMinute by remember { mutableStateOf(value?.minute() ?: currentTime.minute()) }

    Row(
        modifier = modifier
            .id(id)
            .cursor(Cursor.Pointer)
            .padding(all = 16.px)
            .height(48.px)
            .borderRadius(8.px)
            .backgroundColor(backgroundColor)
            .thenIf(hasFocus) {
                border(
                    color = sitePalette().primary,
                    style = LineStyle.Solid,
                    width = 2.px
                )
            }
            .onClick {
                withNotNull(document.getElementById(id)?.getBoundingClientRect()) {
                    containerYPosition = y
                    containerXPosition = x
                    containerWidth = width
                }

                if (mainPopCloseStrategyControl.isOpen) {
                    monthPopCloseStrategyControl.isOpen = false
                    monthPopKeepOpenStrategyControl.shouldKeepOpen = false
                    yearPopCloseStrategyControl.isOpen = false
                    yearPopKeepOpenStrategyControl.shouldKeepOpen = false
                    hourPopCloseStrategyControl.isOpen = false
                    hourPopKeepOpenStrategyControl.shouldKeepOpen = false
                    minutePopCloseStrategyControl.isOpen = false
                    minutePopKeepOpenStrategyControl.shouldKeepOpen = false
                }

                selectedDate = LocalDate.of(
                    year = value?.year() ?: today.year(),
                    month = value?.month() ?: today.month(),
                    dayOfMonth = value?.dayOfMonth() ?: today.dayOfMonth()
                )
                calendar = buildCalendar(selectedDate)
                selectedHour = value?.hour() ?: currentTime.hour()
                selectedMinute = value?.minute() ?: currentTime.minute()

                mainPopCloseStrategyControl.isOpen = mainPopCloseStrategyControl.isOpen.not()
                mainPopKeepOpenStrategyControl.shouldKeepOpen = mainPopKeepOpenStrategyControl.shouldKeepOpen.not()
            },
        verticalAlignment = Alignment.CenterVertically
    ) {
        Text(
            modifier = Modifier
                .noWrap()
                .bodyLarge()
                .color(sitePalette().onSurfaceVariant)
                .fillMaxWidth(),
            text = value?.kotlinLocalDateTime()?.toBrazilianDateTimePattern().orEmpty()
        )
        MaterialIcon(MaterialIcons.Round.CalendarToday)
    }

    AdvancedPopover(
        ElementTarget.PreviousSibling,
        placementStrategy = window.bestPlacementOf(
            xPos = containerXPosition,
            yPos = containerYPosition,
            width = containerWidth
        ),
        openCloseStrategy = mainPopCloseStrategyControl,
        keepOpenStrategy = mainPopKeepOpenStrategyControl
    ) {
        Column(
            modifier = Modifier
                .clip(Rect(16.px))
                .background(sitePalette().surfaceVariant)
                .border(
                    color = sitePalette().primary,
                    width = 2.px,
                    style = LineStyle.Solid
                )
                .borderRadius(16.px)
                .padding(all = 12.px)
        ) {
            Row {
                DatePickerControl(
                    modifier = Modifier
                        .width(328.px),
                    selectedDate = selectedDate,
                    todayDate = today,
                    calendar = calendar,
                    onDayClick = { day ->
                        val tmpSelectedDate = selectedDate
                        selectedDate = day
                        if (day.year() != tmpSelectedDate.year() || day.month() != tmpSelectedDate.month()) {
                            calendar = buildCalendar(day)
                        }
                    },
                    enableMonthPicker = enableMonthPicker,
                    onMonthSelect = { month ->
                        selectedDate = selectedDate.plusMonths(
                            selectedDate.month().compareTo(month).toInt().unaryMinus()
                        )
                        calendar = buildCalendar(selectedDate)
                        monthPopCloseStrategyControl.isOpen = false
                        monthPopKeepOpenStrategyControl.shouldKeepOpen = false
                    },
                    onMonthTitleClick = {
                        monthPopCloseStrategyControl.isOpen = true
                        monthPopKeepOpenStrategyControl.shouldKeepOpen = true
                    },
                    onMonthLeftControlClick = {
                        val newDate = selectedDate.minusMonths(1)
                        calendar = buildCalendar(newDate)
                        selectedDate = newDate
                    },
                    onMonthRightControlClick = {
                        val newDate = selectedDate.plusMonths(1)
                        calendar = buildCalendar(newDate)
                        selectedDate = newDate
                    },
                    monthPopCloseStrategyControl = monthPopCloseStrategyControl,
                    monthPopKeepOpenStrategyControl = monthPopKeepOpenStrategyControl,
                    enableYearPicker = enableYearPicker,
                    onYearSelect = { year ->
                        selectedDate = selectedDate.plusYears(
                            (selectedDate.year().toInt() - year).unaryMinus()
                        )
                        calendar = buildCalendar(selectedDate)
                        yearPopCloseStrategyControl.isOpen = false
                        yearPopKeepOpenStrategyControl.shouldKeepOpen = false
                    },
                    onYearTitleClick = {
                        yearPopCloseStrategyControl.isOpen = true
                        yearPopKeepOpenStrategyControl.shouldKeepOpen = true
                    },
                    onYearLeftControlClick = {
                        val newDate = selectedDate.minusYears(1)
                        calendar = buildCalendar(newDate)
                        selectedDate = newDate
                    },
                    onYearRightControlClick = {
                        val newDate = selectedDate.plusYears(1)
                        calendar = buildCalendar(newDate)
                        selectedDate = newDate
                    },
                    yearPopCloseStrategyControl = yearPopCloseStrategyControl,
                    yearPopKeepOpenStrategyControl = yearPopKeepOpenStrategyControl,
                    dialogTopOffset = 16,
                    dialogLeftOffset = 0
                )
                Divider(
                    modifier = Modifier
                        .background(sitePalette().onSurfaceVariant)
                        .fillMaxHeight()
                        .width(1.px)
                        .margin(all = 16.px)
                )
                Column {
                    TimePickerControl(
                        modifier = Modifier.fillMaxWidth(),
                        dialogModifier = Modifier
                            .width(242.px)
                            .height(287.px),
                        dialogLeftOffset = -24,
                        selectedHour = selectedHour,
                        isHourSelected = isHourSelected,
                        enableHourPicker = enableHourPicker,
                        onHourClick = {
                            hourPopCloseStrategyControl.isOpen = true
                            hourPopKeepOpenStrategyControl.shouldKeepOpen = true
                        },
                        onHourSelect = {
                            selectedHour = it
                            hourPopCloseStrategyControl.isOpen = false
                            hourPopKeepOpenStrategyControl.shouldKeepOpen = false
                        },
                        hourPopCloseStrategyControl = hourPopCloseStrategyControl,
                        hourPopKeepOpenStrategyControl = hourPopKeepOpenStrategyControl,
                        isMinuteSelected = isMinuteSelected,
                        selectedMinute = selectedMinute,
                        enableMinutePicker = enableMinutePicker,
                        minutePopCloseStrategyControl = minutePopCloseStrategyControl,
                        minutePopKeepOpenStrategyControl = minutePopKeepOpenStrategyControl,
                        onMinuteClick = {
                            minutePopCloseStrategyControl.isOpen = true
                            minutePopKeepOpenStrategyControl.shouldKeepOpen = true
                        },
                        onMinuteSelect = {
                            selectedMinute = it
                            minutePopCloseStrategyControl.isOpen = false
                            minutePopKeepOpenStrategyControl.shouldKeepOpen = false
                        }
                    )
                }
            }

            PickerDialogControl(
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                showClearButton = showClearButton,
                onClearClick = {
                    onDateTimePick(null)
                    monthPopCloseStrategyControl.isOpen = false
                    monthPopKeepOpenStrategyControl.shouldKeepOpen = false
                    yearPopCloseStrategyControl.isOpen = false
                    yearPopKeepOpenStrategyControl.shouldKeepOpen = false
                    hourPopCloseStrategyControl.isOpen = false
                    hourPopKeepOpenStrategyControl.shouldKeepOpen = false
                    minutePopCloseStrategyControl.isOpen = false
                    minutePopKeepOpenStrategyControl.shouldKeepOpen = false
                    mainPopCloseStrategyControl.isOpen = false
                    mainPopKeepOpenStrategyControl.shouldKeepOpen = false
                },
                onCancelClick = {
                    monthPopCloseStrategyControl.isOpen = false
                    monthPopKeepOpenStrategyControl.shouldKeepOpen = false
                    yearPopCloseStrategyControl.isOpen = false
                    yearPopKeepOpenStrategyControl.shouldKeepOpen = false
                    hourPopCloseStrategyControl.isOpen = false
                    hourPopKeepOpenStrategyControl.shouldKeepOpen = false
                    minutePopCloseStrategyControl.isOpen = false
                    minutePopKeepOpenStrategyControl.shouldKeepOpen = false
                    mainPopCloseStrategyControl.isOpen = false
                    mainPopKeepOpenStrategyControl.shouldKeepOpen = false
                },
                onSelectClick = {
                    onDateTimePick(
                        LocalDateTime.of(
                            year = selectedDate.year(),
                            month = selectedDate.month(),
                            dayOfMonth = selectedDate.dayOfMonth(),
                            hour = selectedHour,
                            minute = selectedMinute
                        ).kotlinLocalDateTime()
                    )
                    mainPopCloseStrategyControl.isOpen = false
                    mainPopKeepOpenStrategyControl.shouldKeepOpen = false
                }
            )
        }
    }
}