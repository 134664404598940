package com.catbit.opinionpoll.ui.screens.form_maker

import com.catbit.opinionpoll.core.exceptions.HttpResponseException
import com.catbit.opinionpoll.core.extensions.kotlinLocalDateTime
import com.catbit.opinionpoll.core.extensions.swap
import com.catbit.opinionpoll.core.extensions.updateAs
import com.catbit.opinionpoll.core.extensions.withNotNull
import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.domain.forms.CreateFormUseCase
import com.catbit.opinionpoll.domain.forms.GetFormUseCase
import com.catbit.opinionpoll.domain.forms.UpdateFormUseCase
import com.catbit.opinionpoll.inputs.InputType
import com.catbit.opinionpoll.inputs.InputUIStateValidator
import com.catbit.opinionpoll.inputs.InputUpdater
import com.catbit.opinionpoll.inputs.events.InputUIEvent
import com.catbit.opinionpoll.inputs.inputs.*
import com.catbit.opinionpoll.inputs.requests.InputRequestProductionHandler
import com.catbit.opinionpoll.ui.base.ScreenStateHolder
import com.catbit.opinionpoll.ui.screens.form_maker.FormMakerUIContract.*
import io.ktor.http.*
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.internal.JSJoda.ZoneId
import kotlinx.datetime.internal.JSJoda.LocalDateTime as JodaLocalDateTime

class FormMakerStateHolder(
    private val formIdentifier: String?,
    private val inputUpdater: InputUpdater,
    private val requestProducer: InputRequestProductionHandler,
    private val inputUIStateValidator: InputUIStateValidator,
    private val createFormUseCase: CreateFormUseCase,
    private val updateFormUseCase: UpdateFormUseCase,
    private val getFormUseCase: GetFormUseCase,
) : ScreenStateHolder<State, Event, Effect>() {

    override val internalUIState = MutableStateFlow<State>(
        State.Loading(
            toolbarTitle = if (formIdentifier != null) "Editar formulário" else "Adicionar formulário"
        )
    )

    override fun onStarted() {
        formIdentifier?.let {
            getForm(it)
        } ?: run {
            internalUIState.value = State.Displaying.default()
        }
    }

    private fun getForm(formIdentifier: String) {
        stateHolderScope.launch {
            getFormUseCase(
                GetFormUseCase.Params(formIdentifier)
            )
                .onSuccess { formModel ->
                    with(formModel) {
                        internalUIState.value = State.Displaying(
                            toolbarTitle = "Editar formulário",
                            title = title,
                            identifier = identifier,
                            expirationDate = expirationDate,
                            sendGeoCoordinates = sendGeoCoordinates,
                            inputs = inputs.map { it.toUIState() },
                            version = version,
                            tips = null,
                            searchQuery = "",
                        )
                    }
                }
                .onFailure {
                    internalUIState.value = State.Failure("Editar formulário")
                }
        }
    }

    override fun onEvent(event: Event) {
        when (event) {
            is Event.OnAddFormClick -> onAddFormClick(event.inputType)
            is Event.OnMoveFormDown -> onMoveFormDown(event.identifier)
            is Event.OnMoveFormUp -> onMoveFormUp(event.identifier)
            is Event.OnRemoveFormClick -> onRemoveForm(event.identifier)
            is Event.OnSendGeoCoordinatesToggle -> onSendGeoCoordinatesToggle(event.newValue)
            is Event.OnTitleChange -> onTitleChange(event.newValue)
            is Event.OnExpirationDateChange -> onExpirationDateChange(event.newValue)
            is Event.OnFormUIEvent -> onFormUIEvent(event.inputUIEvent)
            Event.OnClearAllForms -> onClearAllForms()
            Event.OnSendFormClick -> onSendFormClick()
            Event.OnClearSearch -> onClearSearch()
            is Event.OnSearch -> onSearch(event.newQuery)
            is Event.OnDuplicateFormDown -> onDuplicateFormDown(event.identifier)
            is Event.OnDuplicateFormUp -> onDuplicateFormUp(event.identifier)
        }
    }

    private fun onDuplicateFormUp(identifier: String) {
        internalUIState.updateAs<State.Displaying> {
            val input = inputs.first { it.actualIdentifier == identifier }.copy(
                actualIdentifier = UUID.stringUUID(),
                identifier = ""
            )
            val index = inputs.indexOfFirst { it.actualIdentifier == identifier }
            val mutableInputs = inputs.toMutableList()

            if (index == 0) {
                mutableInputs.add(0, input)
            } else {
                mutableInputs.add(index - 1, input)
            }

            copy(
                inputs = mutableInputs.mapIndexed { idx, inputUIState ->
                    inputUIState.updateIndex(idx + 1)
                }
            )
        }
    }

    private fun onDuplicateFormDown(identifier: String) {
        internalUIState.updateAs<State.Displaying> {
            val input = inputs.first { it.actualIdentifier == identifier }.copy(
                actualIdentifier = UUID.stringUUID(),
                identifier = ""
            )
            val index = inputs.indexOfFirst { it.actualIdentifier == identifier }
            val mutableInputs = inputs.toMutableList()

            if (index == inputs.lastIndex) {
                mutableInputs.add(input)
            } else {
                mutableInputs.add(index + 1, input)
            }

            copy(
                inputs = mutableInputs.mapIndexed { idx, inputUIState ->
                    inputUIState.updateIndex(idx + 1)
                }
            )
        }
    }

    private fun onSearch(newQuery: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(searchQuery = newQuery)
        }
    }

    private fun onClearSearch() {
        internalUIState.updateAs<State.Displaying> {
            copy(searchQuery = "")
        }
    }

    private fun onExpirationDateChange(newValue: LocalDateTime) {
        internalUIState.updateAs<State.Displaying> {
            copy(expirationDate = newValue)
        }
    }

    private fun onSendGeoCoordinatesToggle(newValue: Boolean) {
        internalUIState.updateAs<State.Displaying> {
            copy(sendGeoCoordinates = newValue)
        }
    }

    private fun onClearAllForms() {
        internalUIState.updateAs<State.Displaying> {
            copy(inputs = listOf())
        }
    }

    private fun onAddFormClick(inputType: InputType) {
        internalUIState.updateAs<State.Displaying> {
            copy(
                inputs = inputs.toMutableList().apply {
                    add(
                        when (inputType) {
                            InputType.CompactSingleChoice -> CompactSingleChoiceInputUIState.default(inputs.size + 1)
                            InputType.SingleChoice -> SingleChoiceInputUIState.default(inputs.size + 1)
                            InputType.MultipleChoices -> MultipleChoicesInputUIState.default(inputs.size + 1)
                            InputType.Date -> DateInputUIState.default(inputs.size + 1)
                            InputType.Time -> TimeInputUIState.default(inputs.size + 1)
                            InputType.DateTime -> DateTimeInputUIState.default(inputs.size + 1)
                            InputType.Evaluation -> EvaluationInputUIState.default(inputs.size + 1)
                            InputType.MaskedTextArea -> MaskedTextAreaInputUIState.default(inputs.size + 1)
                            InputType.NumericArea -> NumericAreaInputUIState.default(inputs.size + 1)
                            InputType.TextArea -> TextAreaInputUIState.default(inputs.size + 1)
                        }
                    )
                }
            )
        }
        stateHolderScope.launch {
            delay(100)
            internalEffects.dispatch(Effect.OnScrollToBottomEffect)
        }
    }

    private fun onFormUIEvent(event: InputUIEvent) {
        internalUIState.updateAs<State.Displaying> {
            copy(
                inputs = inputs.map { uiState ->
                    inputUpdater.update(
                        uiEvent = event, uiState = uiState
                    )
                }
            )
        }
    }

    private fun onTitleChange(newValue: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(title = newValue)
        }
    }

    private fun onMoveFormUp(formIdentifier: String) {
        internalUIState.updateAs<State.Displaying> {
            if (inputs.size == 1) return
            val currentIndex = inputs.indexOfFirst { it.actualIdentifier == formIdentifier }
            if ((currentIndex - 1) == -1) return

            val newForms = inputs.toMutableList().apply {
                swap(currentIndex, currentIndex - 1)
            }.mapIndexed { index, inputUIState -> inputUIState.updateIndex(index + 1) }

            copy(inputs = newForms)
        }
    }

    private fun onMoveFormDown(formIdentifier: String) {
        internalUIState.updateAs<State.Displaying> {
            if (inputs.size == 1) return
            val currentIndex = inputs.indexOfFirst { it.actualIdentifier == formIdentifier }
            if (currentIndex == inputs.lastIndex) return

            val newForms = inputs.toMutableList().apply {
                swap(currentIndex, currentIndex + 1)
            }.mapIndexed { index, inputUIState -> inputUIState.updateIndex(index + 1) }

            copy(inputs = newForms)
        }
    }

    private fun onRemoveForm(formIdentifier: String) {
        internalUIState.updateAs<State.Displaying> {
            val newForms = inputs.toMutableList().apply {
                removeAt(inputs.indexOfFirst { it.actualIdentifier == formIdentifier })
            }.mapIndexed { index, inputUIState -> inputUIState.updateIndex(index + 1) }

            copy(inputs = newForms)
        }
    }

    private fun onSendFormClick() {
        withNotNull(internalUIState.value as? State.Displaying) {

            internalEffects.dispatch(Effect.OnStartSubmittingForm)

            val allIdentifiers = inputs.map { input ->
                input.internalIdentifiers.map { identifier ->
                    input.actualIdentifier to identifier
                }
            }.flatten()

            val validatedInputs = inputs.map { input ->
                inputUIStateValidator.validate(
                    inputUIState = input,
                    allIdentifiers = allIdentifiers
                )
            }

            val currentLocalDateTime = JodaLocalDateTime.now(ZoneId.SYSTEM)

            if (title.isBlank()) {
                internalEffects.dispatch(
                    Effect.OnFormSubmissionFailure("O título não pode ser vazio")
                )
            } else if (expirationDate < currentLocalDateTime.kotlinLocalDateTime()) {
                internalEffects.dispatch(
                    Effect.OnFormSubmissionFailure("Data de expiração não pode ser menor que a data atual")
                )
            } else if (inputs.isEmpty()) {
                internalEffects.dispatch(
                    Effect.OnFormSubmissionFailure("É necessário inserir ao menos um campo")
                )
            } else if (validatedInputs.any { it.errorMessage != null }) {
                internalUIState.update {
                    copy(inputs = validatedInputs)
                }
                internalEffects.dispatch(
                    Effect.OnFormSubmissionFailure("Existem alguns campos inválidos")
                )
            } else {
                internalUIState.update {
                    copy(inputs = validatedInputs)
                }
                stateHolderScope.launch {
                    val template = inputs.map { requestProducer.produce(it) }

                    val result = formIdentifier?.let { formIdentifier ->
                        updateFormUseCase(
                            UpdateFormUseCase.Params(
                                formIdentifier = formIdentifier,
                                version = version + 1,
                                title = title,
                                tips = tips,
                                expirationDate = expirationDate,
                                sendGeoCoordinates = sendGeoCoordinates,
                                template = template
                            )
                        )
                    } ?: run {
                        createFormUseCase(
                            CreateFormUseCase.Params(
                                title = title,
                                version = version,
                                tips = null,
                                expirationDate = expirationDate,
                                sendGeoCoordinates = sendGeoCoordinates,
                                template = template
                            )
                        )
                    }

                    result
                        .onSuccess {
                            internalEffects.dispatch(
                                Effect.OnFormSubmissionSuccess(
                                    "Formulário enviado com sucesso!"
                                )
                            )
                        }.onFailure {
                            val message = withNotNull(it as? HttpResponseException) {
                                if (statusCode == HttpStatusCode.Conflict) {
                                    "Já existe um formulário com esse título! Altere o título e tente novamente."
                                } else "Houve um erro ao enviar o formulário!"
                            } ?: "Houve um erro ao enviar o formulário!"

                            internalEffects.dispatch(
                                Effect.OnFormSubmissionFailure(message)
                            )
                        }
                }
            }
        }
    }
}