package com.catbit.opinionpoll.ui.states

data class FormFilterUIState(
    val identifier: String,
    val title: String,
    val groupingField: String,
    val subgroups: List<FormFilterSubgroupFieldUIState>,
    val fieldsToDisplay: List<String>
) {
    data class FormFilterSubgroupFieldUIState(
        val identifier: String,
        val title: String,
        val values: List<String>
    )
}