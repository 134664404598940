package com.catbit.opinionpoll

import com.varabyte.kobweb.compose.ui.graphics.Color


val md_theme_light_primary = Color.argb(0xFF006688)
val md_theme_light_onPrimary = Color.argb(0xFFFFFFFF)
val md_theme_light_primaryContainer = Color.argb(0xFFC2E8FF)
val md_theme_light_onPrimaryContainer = Color.argb(0xFF001E2B)
val md_theme_light_secondary = Color.argb(0xFF006687)
val md_theme_light_onSecondary = Color.argb(0xFFFFFFFF)
val md_theme_light_secondaryContainer = Color.argb(0xFFC1E8FF)
val md_theme_light_onSecondaryContainer = Color.argb(0xFF001E2B)
val md_theme_light_tertiary = Color.argb(0xFF006687)
val md_theme_light_onTertiary = Color.argb(0xFFFFFFFF)
val md_theme_light_tertiaryContainer = Color.argb(0xFFC1E8FF)
val md_theme_light_onTertiaryContainer = Color.argb(0xFF001E2B)
val md_theme_light_error = Color.argb(0xFFBA1A1A)
val md_theme_light_errorContainer = Color.argb(0xFFFFDAD6)
val md_theme_light_onError = Color.argb(0xFFFFFFFF)
val md_theme_light_onErrorContainer = Color.argb(0xFF410002)
val md_theme_light_background = Color.argb(0xFFFBFCFE)
val md_theme_light_onBackground = Color.argb(0xFF191C1E)
val md_theme_light_surface = Color.argb(0xFFECECEC)
val md_theme_light_onSurface = Color.argb(0xFF191C1E)
val md_theme_light_surfaceVariant = Color.argb(0xFFDCE3E9)
val md_theme_light_onSurfaceVariant = Color.argb(0xFF40484D)
val md_theme_light_outline = Color.argb(0xFF71787D)
val md_theme_light_inverseOnSurface = Color.argb(0xFFF0F1F3)
val md_theme_light_inverseSurface = Color.argb(0xFF2E3133)
val md_theme_light_inversePrimary = Color.argb(0xFF75D1FF)
val md_theme_light_shadow = Color.argb(0xFF000000)
val md_theme_light_surfaceTint = Color.argb(0xFF006688)
val md_theme_light_outlineVariant = Color.argb(0xFFC0C7CD)
val md_theme_light_scrim = Color.argb(0xFF000000)

val md_theme_dark_primary = Color.argb(0xFF75D1FF)
val md_theme_dark_onPrimary = Color.argb(0xFF003548)
val md_theme_dark_primaryContainer = Color.argb(0xFF004D67)
val md_theme_dark_onPrimaryContainer = Color.argb(0xFFC2E8FF)
val md_theme_dark_secondary = Color.argb(0xFF72D2FF)
val md_theme_dark_onSecondary = Color.argb(0xFF003548)
val md_theme_dark_secondaryContainer = Color.argb(0xFF004D66)
val md_theme_dark_onSecondaryContainer = Color.argb(0xFFC1E8FF)
val md_theme_dark_tertiary = Color.argb(0xFF72D2FF)
val md_theme_dark_onTertiary = Color.argb(0xFF003548)
val md_theme_dark_tertiaryContainer = Color.argb(0xFF004D66)
val md_theme_dark_onTertiaryContainer = Color.argb(0xFFC1E8FF)
val md_theme_dark_error = Color.argb(0xFFFFB4AB)
val md_theme_dark_errorContainer = Color.argb(0xFF93000A)
val md_theme_dark_onError = Color.argb(0xFF690005)
val md_theme_dark_onErrorContainer = Color.argb(0xFFFFDAD6)
val md_theme_dark_background = Color.argb(0xFF191C1E)
val md_theme_dark_onBackground = Color.argb(0xFFE1E2E5)
val md_theme_dark_surface = Color.argb(0xFF28303A)
val md_theme_dark_onSurface = Color.argb(0xFFE1E2E5)
val md_theme_dark_surfaceVariant = Color.argb(0xFF40484D)
val md_theme_dark_onSurfaceVariant = Color.argb(0xFFC0C7CD)
val md_theme_dark_outline = Color.argb(0xFF8A9297)
val md_theme_dark_inverseOnSurface = Color.argb(0xFF191C1E)
val md_theme_dark_inverseSurface = Color.argb(0xFFE1E2E5)
val md_theme_dark_inversePrimary = Color.argb(0xFF006688)
val md_theme_dark_shadow = Color.argb(0xFF000000)
val md_theme_dark_surfaceTint = Color.argb(0xFF75D1FF)
val md_theme_dark_outlineVariant = Color.argb(0xFF40484D)
val md_theme_dark_scrim = Color.argb(0xFF000000)
