package com.catbit.opinionpoll.data.data_sources.networking.requests

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FormAnswerRequest(
    @SerialName("identifier")
    val identifier: String,
    @SerialName("submittedBy")
    val submittedBy: String,
    @SerialName("submittedAt")
    val submittedAt: String,
    @SerialName("geoCoordinates")
    val geoCoordinates: String?,
    @SerialName("answer")
    val answer: String
)
