package com.catbit.opinionpoll.inputs.validators

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.inputs.TextAreaInputUIState

class TextAreaInputUIValidator : InputUIValidator<TextAreaInputUIState> {
    override fun match(inputUIState: InputUIState) = inputUIState is TextAreaInputUIState

    override fun validate(
        inputUIState: TextAreaInputUIState,
        allIdentifiers: List<String>
    ): InputUIState {

        val errorMessage = with(inputUIState) {
            if (identifier.isEmpty()) {
                "O identificador não pode ser vazio"
            } else if (title.isEmpty()) {
                "O título não pode ser vazio"
            } else if (maxLength <= 0) {
                "O tamanho do texto precisa ser maior que zero"
            } else if (identifier in allIdentifiers) {
                "O identificador precisa ser único"
            } else null
        }

        return inputUIState.copy(errorMessage = errorMessage)
    }
}