package com.catbit.opinionpoll.core.ui.composables

import androidx.compose.runtime.Composable
import com.catbit.opinionpoll.core.ui.animations.AnimationHolder
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.dom.svg.Circle
import com.varabyte.kobweb.compose.dom.svg.SVGFillType
import com.varabyte.kobweb.compose.dom.svg.Svg
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.*

@Composable
fun CircularProgress(
    size: Int,
    strokeWidth: Int = 3,
    strokeColor: Color = sitePalette().primary
) {
    Svg(
        attrs = Modifier
            .height(size.px)
            .width(size.px)
            .position(Position.Relative)
            .animation(CircleProgressRotateAnimation().toAnimation())
            .toAttrs()
    ) {
        Circle(
            attrs = Modifier
                .animation(CircleProgressDashAnimation().toAnimation())
                .styleModifier {
                    property("stroke-dasharray", "1,200")
                    property("stroke-dashoffset", "0")
                    property("stroke-linecap", "round")
                    property("stroke", strokeColor)
                }.toAttrs {
                    cx(size / 2)
                    cy(size / 2)
                    r((size / 2) - strokeWidth * 2)
                    fill(SVGFillType.None)
                    this.strokeWidth(strokeWidth)
                    strokeMiterLimit(10)
                }
        )
    }
}

class CircleProgressDashAnimation : AnimationHolder {
    @Composable
    override fun toAnimation() = CircleProgressDashAnimationKeyFrames.toAnimation(
        duration = 1.5.s,
        timingFunction = AnimationTimingFunction.EaseInOut,
        iterationCount = AnimationIterationCount.Infinite
    )
}

class CircleProgressRotateAnimation : AnimationHolder {
    @Composable
    override fun toAnimation() = CircleProgressRotateAnimationKeyFrames.toAnimation(
        duration = 2.s,
        timingFunction = AnimationTimingFunction.Linear,
        iterationCount = AnimationIterationCount.Infinite
    )
}

val CircleProgressDashAnimationKeyFrames by Keyframes {
    from {
        Modifier.styleModifier {
            property("stroke-dasharray", "1,200")
            property("stroke-dashoffset", "0")
        }
    }
    each(50.percent) {
        Modifier.styleModifier {
            property("stroke-dasharray", "89,200")
            property("stroke-dashoffset", "-35")
        }
    }
    to {
        Modifier.styleModifier {
            property("stroke-dasharray", "89,200")
            property("stroke-dashoffset", "-124")
        }
    }
}

val CircleProgressRotateAnimationKeyFrames by Keyframes {
    to {
        Modifier.rotate(360.deg)
    }
}