package com.catbit.opinionpoll.domain.form_users_linker

import com.catbit.opinionpoll.core.domain.UseCase
import com.catbit.opinionpoll.data.repositories.OpinionPollRepository

class LinkFormToUsersUseCase(
    private val repository: OpinionPollRepository
) : UseCase<Unit, LinkFormToUsersUseCase.Params>() {

    override suspend fun execute(params: Params) =
        repository.linkFormToUsers(
            formId = params.formId,
            userIdentifiers = params.userIdentifiers
        )

    data class Params(
        val formId: String,
        val userIdentifiers: List<String>
    )
}