package com.catbit.opinionpoll.core.ui.composables.base_components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.icons.MaterialIcon
import com.catbit.opinionpoll.core.ui.modifiers.roundedCornerClip
import com.catbit.opinionpoll.core.ui.modifiers.thenIf
import com.catbit.opinionpoll.core.ui.modifiers.titleSmall
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.UserSelect
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import org.jetbrains.compose.web.css.px

@Composable
fun TabContainer(
    modifier: Modifier = Modifier,
    tabItems: List<TabItem>,
    selectedTabId: String,
    unselectedTabColor: Color = sitePalette().onSurfaceVariant,
    selectedTabColor: Color = sitePalette().primary
) {
    Row(
        modifier = modifier
    ) {
        tabItems.forEach { tabItem ->
            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .cursor(Cursor.Pointer)
                    .userSelect(UserSelect.None)
                    .onClick { tabItem.onClick(tabItem.id) },
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Column(
                    modifier = Modifier.fillMaxHeight(),
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.SpaceBetween
                ) {
                    Column(
                        modifier = Modifier.margin(top = 8.px),
                        horizontalAlignment = Alignment.CenterHorizontally,
                    ) {
                        MaterialIcon(
                            modifier = Modifier
                                .color(if (tabItem.id == selectedTabId) selectedTabColor else unselectedTabColor),
                            icon = tabItem.icon
                        )
                        Text(
                            modifier = Modifier
                                .titleSmall()
                                .color(if (tabItem.id == selectedTabId) selectedTabColor else unselectedTabColor),
                            text = tabItem.text
                        )
                    }
                    Box(
                        modifier = Modifier
                            .fillMaxWidth()
                            .height(3.px)
                            .margin(top = 8.px)
                            .roundedCornerClip(topLeft = 8.px, topRight = 8.px)
                            .thenIf(tabItem.id == selectedTabId) {
                                backgroundColor(selectedTabColor)
                            }
                    )
                }
            }
        }
    }
}

@Immutable
data class TabItem(
    val id: String,
    val text: String,
    val icon: MaterialIcon,
    val onClick: (String) -> Unit
)