package com.catbit.opinionpoll.core.ui.modifiers

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.jetbrains.compose.web.css.CSSNumeric
import org.jetbrains.compose.web.css.px

fun Modifier.margin(
    horizontal: CSSNumeric = 0.px,
    vertical: CSSNumeric = 0.px
): Modifier {
    return then(this).margin(
        top = vertical,
        right = horizontal,
        bottom = vertical,
        left = horizontal
    )
}

fun Modifier.padding(
    horizontal: CSSNumeric = 0.px,
    vertical: CSSNumeric = 0.px
): Modifier {
    return then(this)
        .padding(
            top = vertical,
            right = horizontal,
            bottom = vertical,
            left = horizontal
        )
}