package com.catbit.opinionpoll.core.ui.animations

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.translateX
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.components.animation.toAnimation
import org.jetbrains.compose.web.css.*

class SlideInFromLeftAnimation : AnimationHolder {
    @Composable
    override fun toAnimation() = SlideInFromLeftKeyFrames.toAnimation(
        duration = 250.ms,
        timingFunction = AnimationTimingFunction.EaseIn,
        fillMode = AnimationFillMode.Forwards
    )
}

val SlideInFromLeftKeyFrames by Keyframes {
    from { Modifier.translateX(-(100.percent)) }
    to { Modifier }
}