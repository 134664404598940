package com.catbit.opinionpoll.data.data_sources.networking.responses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class StatsResponse(
    @SerialName("totalForms")
    val totalForms: Int,
    @SerialName("totalUsers")
    val totalUsers: Int,
    @SerialName("formsTotalAnswers")
    val formsTotalAnswers: Map<String, Int>
)
