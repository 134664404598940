package com.catbit.opinionpoll.inputs.validators

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.inputs.CompactSingleChoiceInputUIState

class CompactSingleChoiceInputUIValidator : InputUIValidator<CompactSingleChoiceInputUIState> {
    override fun match(inputUIState: InputUIState) = inputUIState is CompactSingleChoiceInputUIState

    override fun validate(
        inputUIState: CompactSingleChoiceInputUIState,
        allIdentifiers: List<String>
    ): InputUIState {

        val errorMessage = with(inputUIState) {
            if (identifier.isEmpty()) {
                "O identificador não pode ser vazio"
            } else if (title.isEmpty()) {
                "O título não pode ser vazio"
            } else if (options.size <= 1) {
                "É necessário adicionar ao menos duas opções"
            } else if (questions.size <= 1) {
                "É necessário adicionar ao menos duas questões"
            } else if (options.any { it.title.isEmpty() }) {
                "Todas as opções precisam ter um título"
            } else if (questions.any { it.title.isEmpty() }) {
                "Todas as questões precisam ter um título"
            } else if (questions.map { it.identifier }.groupingBy { it }.eachCount().any { it.value != 1 }) {
                "O identificador precisa ser único"
            } else if (questions.map { it.identifier }.any { it in allIdentifiers } || identifier in allIdentifiers) {
                "O identificador precisa ser único"
            } else null
        }

        return inputUIState.copy(errorMessage = errorMessage)
    }
}