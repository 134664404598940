package com.catbit.opinionpoll.core.domain

import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.withContext

abstract class FlowUseCase<ReturnType, Params> {
    abstract suspend fun execute(params: Params): Flow<ReturnType>

    suspend operator fun invoke(params: Params) = withContext(Dispatchers.Default) {
        execute(params)
    }
}

suspend operator fun <ReturnType> FlowUseCase<ReturnType, Unit>.invoke() = invoke(Unit)