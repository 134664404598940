package com.catbit.opinionpoll.core.sailor.navigation_controller

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.catbit.opinionpoll.core.extensions.withNotNull
import com.catbit.opinionpoll.core.remember.SessionDataTranslator
import com.catbit.opinionpoll.core.remember.rememberOnSessionStorage
import com.catbit.opinionpoll.core.uuid.UUID
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

class NavigationController internal constructor() {
    private val _routes: MutableMap<String, Route> = mutableMapOf()
    val routes: List<Route> get() = _routes.values.toList()

    private var _stack = ArrayDeque<StackEntry>()
    val stack: List<StackEntry> get() = _stack.toList()

    internal var stackTopEntry by mutableStateOf<StackEntry?>(null)

    private val stackEntryObservers: MutableMap<String, () -> Unit> = mutableMapOf()

    internal var currentNavigationType: NavigationType = NavigationType.Initial

    private var restoredStack: List<Pair<String, Map<String, Any?>?>>? = null

    // TODO Método que analisa o endereço atual ao carregar e remonta a pilha a partir disso

    internal constructor(
        stack: List<Pair<String, Map<String, Any?>?>>,
    ) : this() {
        restoredStack = stack
    }

    internal fun init(
        startingRoute: String,
        startingArguments: ArgumentsBundle?,
    ) {

        withNotNull(restoredStack) {
            restoredStack?.forEach { (routeName, arguments) ->
                _stack.add(
                    StackEntry(
                        id = UUID.stringUUID(),
                        route = _routes.getValue(routeName),
                        arguments = ArgumentsBundle(arguments.orEmpty())
                    )
                )
            }
            restoredStack = null
        } ?: run {
            _stack.add(
                StackEntry(
                    id = UUID.stringUUID(),
                    route = _routes.getValue(startingRoute),
                    arguments = startingArguments
                )
            )
        }

        updateCurrentStackEntry()
    }

    fun navigate(
        routeName: String,
        arguments: ArgumentsBundle? = null,
        popUpTo: String? = null,
        popUpToInclusive: Boolean = false
    ) {
        require(_routes.containsKey(routeName)) {
            "Route not defined!"
        }

        val route = _routes.getValue(routeName)
        val newArguments = mutableMapOf<String, Any?>()

        currentNavigationType = NavigationType.Navigate

        route.arguments.forEach { argument ->
            val newArgument = if (!argument.nullable) {
                require(arguments?.contains(argument.id) != false) {
                    "Argument \"${argument.id}\" from route \"${route.name}\" was not provided!"
                }
                arguments?.getObject<Any>(argument.id)
            } else arguments?.getObject<Any>(argument.id) ?: argument.defaultValue

            newArguments[argument.id] = newArgument
        }

        val newStackEntry = StackEntry(
            id = UUID.stringUUID(),
            route = route,
            arguments = ArgumentsBundle(newArguments)
        )

        if (popUpTo != null) {
            val popUpToIndex = _stack
                .indexOfFirst { it.route.name == popUpTo }
                .takeIf { it != -1 }
                ?: _stack.lastIndex

            val newStackTopIndex = if (popUpToInclusive) popUpToIndex else popUpToIndex + 1

            for (i in _stack.lastIndex downTo newStackTopIndex) {
                executeStackEntryDestruction(_stack[i].id)
                _stack.removeAt(i)
            }

        }

        _stack.add(newStackEntry)

        updateCurrentStackEntry()
    }

    fun goBack() {
        if (_stack.size > 1) {
            currentNavigationType = NavigationType.Pop
            executeStackEntryDestruction(_stack.last().id)
            _stack.removeLast()
            updateCurrentStackEntry()
        }
    }

    fun addStackEntryDestructionObserver(
        stackEntryId: String,
        action: () -> Unit
    ) {
        require(_stack.any { it.id == stackEntryId }) {
            "There's no entry in the stack with the id $stackEntryId"
        }
        stackEntryObservers[stackEntryId] = action
    }

    private fun executeStackEntryDestruction(
        stackEntryId: String
    ) {
        stackEntryObservers[stackEntryId]?.invoke()
        stackEntryObservers.remove(stackEntryId)
    }

    private fun updateCurrentStackEntry() {
        stackTopEntry = _stack.last()
    }

    data class StackEntry(
        val id: String,
        val route: Route,
        val arguments: ArgumentsBundle?
    )

    data class Route(
        val name: String,
        val arguments: List<Argument>,
        val content: @Composable (StackEntry) -> Unit
    )

    data class Argument(
        val id: String, val nullable: Boolean = false, val defaultValue: Any? = null
    )

    class ArgumentsBundle(
        val arguments: Map<String, Any?>,
    ) {
        fun contains(id: String) = arguments.containsKey(id)

        fun getString(id: String) = arguments.getValue(id) as String
        fun getNullableString(id: String) = arguments[id] as? String

        fun getInt(id: String) = arguments.getValue(id) as Int
        fun getNullableInt(id: String) = arguments[id] as? Int

        fun getBoolean(id: String) = arguments.getValue(id) as Boolean
        fun getNullableBoolean(id: String) = arguments[id] as? Boolean

        fun getLong(id: String) = arguments.getValue(id) as Long
        fun getNullableLong(id: String) = arguments[id] as? Long

        fun getFloat(id: String) = arguments.getValue(id) as Float
        fun getNullableFloat(id: String) = arguments[id] as? Float

        fun getDouble(id: String) = arguments.getValue(id) as Double
        fun getNullableDouble(id: String) = arguments[id] as? Double

        internal fun <T> getObject(id: String) = arguments.getValue(id) as T
        internal fun <T> getNullableObject(id: String) = arguments[id] as? T

        inline fun <reified T> getSerializedObject(id: String) =
            Json.decodeFromString<T>(arguments.getValue(id) as String)

        inline fun <reified T> getNullableSerializedObject(id: String) = try {
            Json.decodeFromString<T>(arguments.getValue(id) as String)
        } catch (e: Throwable) {
            null
        }
    }

    inner class Scope {
        fun route(
            name: String,
            arguments: List<Argument> = listOf(),
            content: @Composable (StackEntry) -> Unit
        ) {
            _routes[name] = Route(
                name = name,
                arguments = arguments,
                content = content
            )
        }
    }

    sealed interface NavigationType {
        data object Initial : NavigationType
        data object Navigate : NavigationType
        data object Pop : NavigationType
    }
}

@Composable
fun rememberNavigationController(key: String) = rememberOnSessionStorage(
    key = key,
    translator = NavigationControllerSessionDataTranslator
) {
    NavigationController()
}

val NavigationControllerSessionDataTranslator = object : SessionDataTranslator<NavigationController> {

    @OptIn(ExperimentalSerializationApi::class)
    private val json = Json {
        ignoreUnknownKeys = true
        explicitNulls = false
    }

    override fun translate(value: NavigationController): String {
        val mappedStack = value.stack.associate { entry ->
            entry.route.name to entry.arguments?.arguments.orEmpty().mapValues { (_, value) ->
                when (value) {
                    null -> "null"
                    else -> {
                        val type = when (value) {
                            is Int -> "int"
                            is Boolean -> "bol"
                            is Double -> "dbl"
                            is Long -> "lng"
                            is Float -> "flt"
                            else -> "str"
                        }
                        "$type::$value"
                    }
                }
            }
        }
        return json.encodeToString(mappedStack)
    }

    override fun restore(restoreRawValue: String): NavigationController {
        val restoredMap = json.decodeFromString<Map<String, Map<String, String>>>(restoreRawValue)

        val stackEntries = restoredMap.entries.map { (key, value) ->
            key to value.mapValues { (_, mapValue) ->
                when (mapValue) {
                    "null" -> null
                    else -> {
                        val (type, rawValue) = mapValue.split("::")
                        when (type) {
                            "int" -> rawValue.toInt()
                            "bol" -> rawValue.toBoolean()
                            "dbl" -> rawValue.toDouble()
                            "lng" -> rawValue.toLong()
                            "flt" -> rawValue.toFloat()
                            else -> rawValue
                        }
                    }
                }
            }
        }

        return NavigationController(stackEntries as List<Pair<String, Map<String, Any?>?>>)
    }
}

fun argument(
    id: String, nullable: Boolean = false, defaultValue: Any? = null
) = NavigationController.Argument(
    id = id, nullable = nullable, defaultValue = defaultValue
)

fun argumentsBundleOf(vararg arguments: Pair<String, Any?>) =
    NavigationController.ArgumentsBundle(arguments.toMap())
