package com.catbit.opinionpoll.domain.forms

import com.catbit.opinionpoll.core.domain.UseCase
import com.catbit.opinionpoll.data.repositories.OpinionPollRepository

class CopyFormUseCase(
    private val repository: OpinionPollRepository
) : UseCase<Unit, CopyFormUseCase.Params>() {

    override suspend fun execute(params: Params) =
        repository.copyForm(
            newTitle = params.newTitle,
            formIdentifier = params.formIdentifier,
        )

    data class Params(
        val newTitle: String,
        val formIdentifier: String
    )
}