package com.catbit.opinionpoll.inputs.responses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("DateTime")
data class DateTimeInputResponse(
    override val identifier: String,
    override val title: String,
    override val subtitle: String?,
    override val isObligatory: Boolean,
    override val index: Int,
    @SerialName("minDateTime")
    val minDateTime: String?,
    @SerialName("maxDateTime")
    val maxDateTime: String?
) : InputResponse