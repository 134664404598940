package com.catbit.opinionpoll.core.ui.composables.base_components

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.ui.modifiers.bodyLarge
import com.catbit.opinionpoll.core.ui.modifiers.noBorder
import com.catbit.opinionpoll.core.ui.modifiers.noOutline
import com.catbit.opinionpoll.core.ui.modifiers.thenIf
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.Resize
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.attributes.maxLength
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.attributes.rows
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.TextArea

@Composable
fun TextArea(
    modifier: Modifier = Modifier,
    text: String,
    lines: Int = 1,
    onTextChanged: (String) -> Unit,
    inputIdentifier: String? = null,
    hint: String? = null,
    enabled: Boolean = true,
    maxLength: Int? = null,
    backgroundColor: Color = sitePalette().surfaceVariant,
) {

    var hasFocus by remember { mutableStateOf(false) }

    TextArea(
        value = text,
        attrs = modifier
            .padding(all = 16.px)
            .borderRadius(8.px)
            .thenIf(hasFocus) {
                border(
                    color = sitePalette().primary,
                    style = LineStyle.Solid,
                    width = 2.px
                )
            }
            .thenIf(!hasFocus) {
                noBorder()
            }
            .backgroundColor(backgroundColor)
            .color(sitePalette().onSurfaceVariant)
            .caretColor(sitePalette().primary)
            .background(backgroundColor)
            .bodyLarge()
            .ariaDisabled(enabled.not())
            .fillMaxWidth()
            .onFocusIn { hasFocus = true }
            .onFocusOut { hasFocus = false }
            .resize(Resize.None)
            .noOutline()
            .toAttrs {
                inputIdentifier?.let(::id)
                maxLength?.let(::maxLength)
                rows(lines)
                hint?.let(::placeholder)
                onInput { evt ->
                    onTextChanged(evt.value)
                }
            }
    )

//    Row(
//        modifier = modifier
//            .padding(all = 16.px)
//            .borderRadius(8.px)
//            .thenIf(hasFocus) {
//                border(
//                    color = sitePalette().primary,
//                    style = LineStyle.Solid,
//                    width = 2.px
//                )
//            }
//            .backgroundColor(backgroundColor),
//        verticalAlignment = Alignment.CenterVertically
//    ) {
//
//        TextArea(
//            value = text,
//            attrs = Modifier
//                .padding(all = 16.px)
//                .borderRadius(8.px)
//                .thenIf(hasFocus) {
//                    border(
//                        color = sitePalette().primary,
//                        style = LineStyle.Solid,
//                        width = 2.px
//                    )
//                }
//                .backgroundColor(backgroundColor)
//                .color(sitePalette().onSurfaceVariant)
//                .caretColor(sitePalette().primary)
//                .background(backgroundColor)
//                .bodyLarge()
//                .ariaDisabled(enabled.not())
//                .fillMaxWidth()
//                .onFocusIn { hasFocus = true }
//                .onFocusOut { hasFocus = false }
//                .noBorder()
//                .resize(Resize.None)
//                .noOutline()
//                .toAttrs {
//                    inputIdentifier?.let(::id)
//                    maxLength?.let(::maxLength)
//                    rows(lines)
//                    hint?.let(::placeholder)
//                    onInput { evt ->
//                        onTextChanged(evt.value)
//                    }
//                }
//        )
//    }
}