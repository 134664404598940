package com.catbit.opinionpoll.core.charts.ui.charts

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.charts.data.ChartData
import com.catbit.opinionpoll.core.ui.composables.base_components.SegmentedButton
import com.catbit.opinionpoll.core.ui.composables.base_components.SegmentedButtonItem
import com.catbit.opinionpoll.core.ui.composables.base_components.Text
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.bodyLarge
import com.catbit.opinionpoll.core.ui.modifiers.noWrap
import com.catbit.opinionpoll.core.ui.modifiers.thenIf
import com.catbit.opinionpoll.core.ui.modifiers.titleLarge
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import org.jetbrains.compose.web.css.CSSNumeric
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun BarChart(
    modifier: Modifier = Modifier,
    barsMaxWidth: CSSNumeric,
    data: ChartData,
    verticalMode: Boolean = false
) {
    val total = remember(data.dataSet) { data.dataSet.sumOf { it.value.toDouble() } }
    val maxValue = remember(data.dataSet) { data.dataSet.maxBy { it.value.toDouble() }.value.toDouble() }
    val sortedData = remember(data.dataSet) { data.dataSet.sortedByDescending { it.value.toDouble() } }

    Column(
        modifier = modifier
    ) {

        if(data.title.isNotBlank()) {
            Text(
                modifier = Modifier
                    .titleLarge()
                    .fontWeight(FontWeight.Bold)
                    .textAlign(TextAlign.Center)
                    .fillMaxWidth(),
                text = data.title
            )
        }

        if(verticalMode) {
            Column(
                modifier = Modifier
                    .thenIf(data.title.isNotBlank()) {
                        margin(top = 24.px)
                    }
                    .width(barsMaxWidth)
            ) {

                var dataValueDisplay by remember { mutableStateOf("percent") }

                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(bottom = 16.px)
                    ,
                    horizontalArrangement = Arrangement.Center
                ) {
                    SegmentedButton(
                        selectedItemId = dataValueDisplay,
                        items = listOf(
                            SegmentedButtonItem(
                                id = "percent",
                                text = "",
                                icon = MaterialIcons.Round.Percent
                            ) {
                                dataValueDisplay = "percent"
                            },
                            SegmentedButtonItem(
                                id = "amount",
                                text = "",
                                icon = MaterialIcons.Round.Pin
                            ) {
                                dataValueDisplay = "amount"
                            }
                        )
                    )
                }

                sortedData.forEachIndexed { index, chartData ->

                    val barWidth = (chartData.value.toDouble() / maxValue * 100).percent
                    val percentage = if(chartData.value == 0) "0.00" else (chartData.value.toDouble() / total * 100.0).asDynamic().toFixed(2) as String

                    Column(
                        modifier = Modifier
                            .fillMaxWidth()
                            .overflow { x(Overflow.Hidden) }
                            .thenIf(index != 0) {
                                margin(top = 16.px)
                            },
                    ) {

                        val textToDisplay = buildString {
                            append("[")
                            if (dataValueDisplay == "amount") {
                                append(chartData.value)
                            } else {
                                append("$percentage%")
                            }
                            append("] ")
                            append(chartData.label)
                        }

                        Text(
                            modifier = Modifier
                                .fillMaxWidth()
                                .whiteSpace(WhiteSpace.NoWrap)
                                .textOverflow(TextOverflow.Ellipsis)
                                .overflow { x(Overflow.Hidden) }
                                .bodyLarge(),
                            text = textToDisplay
                        )

                        Box(
                            modifier = Modifier
                                .width(barWidth)
                                .margin(right = 16.px)
                                .height(24.px)
                                .background(chartData.color)
                        )
                    }
                }
            }
        } else {
            Row(
                modifier = Modifier
                    .thenIf(data.title.isNotBlank()) {
                        margin(top = 24.px)
                    }
                    .width(barsMaxWidth)
            ) {
                Column(
                    modifier = Modifier.margin(right = 16.px)
                ) {
                    sortedData.forEachIndexed { index, chartData ->
                        Row(
                            modifier = Modifier
                                .height(24.px)
                                .thenIf(index != 0) {
                                    margin(top = 4.px)
                                },
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Text(
                                modifier = Modifier
                                    .bodyLarge()
                                    .noWrap(),
                                text = chartData.label
                            )
                        }
                    }
                }
                Column(
                    modifier = Modifier.fillMaxWidth()
                ) {
                    sortedData.forEachIndexed { index, chartData ->
                        val barWidth = (chartData.value.toDouble() / maxValue * 100).percent
                        val percentage = if(chartData.value == 0) "0.00" else (chartData.value.toDouble() / total * 100.0).asDynamic().toFixed(2) as String

                        Row(
                            modifier = Modifier
                                .fillMaxWidth()
                                .thenIf(index != 0) {
                                    margin(top = 4.px)
                                },
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Row(
                                modifier = Modifier
                                    .fillMaxWidth()
                            ) {
                                Box(
                                    modifier = Modifier.width(barWidth)
                                        .margin(right = 16.px)
                                        .height(24.px)
                                        .background(chartData.color)
                                )
                            }
                            Text(
                                modifier = Modifier
                                    .width(70.px)
                                    .bodyLarge()
                                    .noWrap(),
                                text = "$percentage%"
                            )
                        }
                    }
                }
            }
        }
    }
}