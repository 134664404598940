package com.catbit.opinionpoll.core.extensions

suspend fun <T> safeResult(
    riskyBlock: suspend () -> T
) = try {
    Result.success(riskyBlock())
} catch (e: Throwable) {
    Result.failure(e)
}

fun <T : Any> Result<T?>.notNull(): Result<T> =
    mapCatching { it ?: throw IllegalArgumentException("Value is null") }

