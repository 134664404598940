import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobalsLocal
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.components.animation.registerKeyframes
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { com.catbit.opinionpoll.pages.Index() }
        ctx.router.register("/app") { com.catbit.opinionpoll.pages.app.AppPage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerComponentStyle(com.catbit.opinionpoll.HeadlineTextStyle)
        ctx.theme.registerComponentStyle(com.catbit.opinionpoll.SubheadlineTextStyle)
        ctx.theme.registerComponentStyle(com.catbit.opinionpoll.core.ui.styles.SliderStyle)
        ctx.theme.registerComponentStyle(com.catbit.opinionpoll.core.ui.styles.SliderStyleThumbColor)
        ctx.theme.registerComponentVariants(com.catbit.opinionpoll.CircleButtonVariant)
        ctx.theme.registerComponentVariants(com.catbit.opinionpoll.RoundedRectangleButtonVariant)
        ctx.theme.registerComponentVariants(com.catbit.opinionpoll.UncoloredButtonVariant)
        ctx.stylesheet.registerKeyframes(com.catbit.opinionpoll.core.ui.animations.FadeInKeyFrames)
        ctx.stylesheet.registerKeyframes(com.catbit.opinionpoll.core.ui.animations.FadeOutKeyFrames)
        ctx.stylesheet.registerKeyframes(com.catbit.opinionpoll.core.ui.animations.InfiniteSpinningKeyFrames)
        ctx.stylesheet.registerKeyframes(com.catbit.opinionpoll.core.ui.animations.SlideInFromLeftKeyFrames)
        ctx.stylesheet.registerKeyframes(com.catbit.opinionpoll.core.ui.composables.CircleProgressDashAnimationKeyFrames)
        ctx.stylesheet.registerKeyframes(com.catbit.opinionpoll.core.ui.composables.CircleProgressRotateAnimationKeyFrames)
        com.catbit.opinionpoll.initColorMode(ctx)
        com.catbit.opinionpoll.initSiteStyles(ctx)
        com.catbit.opinionpoll.initTheme(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    renderComposable(rootElementId = "root") {
        CompositionLocalProvider(AppGlobalsLocal provides mapOf("title" to "OpinionPro")) {
            com.catbit.opinionpoll.AppEntry {
                router.renderActivePage { renderWithDeferred { it() } }
            }
        }
    }
}
