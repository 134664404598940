package com.catbit.opinionpoll.inputs.requests.producers

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.inputs.CompactSingleChoiceInputUIState
import com.catbit.opinionpoll.inputs.requests.requests.CompactSingleChoiceOptionsInputRequest
import com.catbit.opinionpoll.inputs.requests.requests.OptionRequest

class CompactSingleChoiceInputRequestProducer : InputRequestProducer<CompactSingleChoiceInputUIState> {
    override fun match(inputUIState: InputUIState) = inputUIState is CompactSingleChoiceInputUIState

    override fun produce(inputUIState: CompactSingleChoiceInputUIState) = CompactSingleChoiceOptionsInputRequest(
        identifier = inputUIState.identifier,
        index = inputUIState.index,
        title = inputUIState.title,
        subtitle = inputUIState.subtitle,
        isObligatory = inputUIState.isObligatory,
        options = inputUIState.options.map { option ->
            OptionRequest(
                title = option.title,
                identifier = option.actualIdentifier
            )
        },
        questions = inputUIState.questions.map { option ->
            OptionRequest(
                title = option.title,
                identifier = option.identifier
            )
        },
        showSearchBar = inputUIState.showSearchBar,
    )
}