package com.catbit.opinionpoll.pages.app

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.extensions.*
import com.catbit.opinionpoll.core.remember.rememberString
import com.catbit.opinionpoll.core.sailor.NavigationHost
import com.catbit.opinionpoll.core.sailor.navigation_controller.NavigationController
import com.catbit.opinionpoll.core.sailor.navigation_controller.argument
import com.catbit.opinionpoll.core.sailor.navigation_controller.rememberNavigationController
import com.catbit.opinionpoll.core.sailor.overlays.dialog.DialogState
import com.catbit.opinionpoll.core.sailor.overlays.dialog.rememberDialogState
import com.catbit.opinionpoll.core.sailor.overlays.snackbar.SnackBarState
import com.catbit.opinionpoll.core.sailor.overlays.snackbar.rememberSnackBarState
import com.catbit.opinionpoll.core.ui.composables.CircularProgress
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.composables.NavRailButton
import com.catbit.opinionpoll.core.ui.composables.base_components.FilledButton
import com.catbit.opinionpoll.core.ui.composables.base_components.IconButton
import com.catbit.opinionpoll.core.ui.composables.base_components.Spacer
import com.catbit.opinionpoll.core.ui.composables.base_components.Text
import com.catbit.opinionpoll.core.ui.composables.effects.SingleEffect
import com.catbit.opinionpoll.core.ui.helpers.isMobileMode
import com.catbit.opinionpoll.core.ui.icons.MaterialIcon
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.*
import com.catbit.opinionpoll.toggleColorMode
import com.catbit.opinionpoll.ui.screens.form_dashboard.FormDashboardScreen
import com.catbit.opinionpoll.ui.screens.form_maker.FormMakerScreen
import com.catbit.opinionpoll.ui.screens.form_users_linker.FormUsersLinkerScreen
import com.catbit.opinionpoll.ui.screens.forms.FormsScreen
import com.catbit.opinionpoll.ui.screens.home.HomeScreen
import com.catbit.opinionpoll.ui.screens.login.LoginScreen
import com.catbit.opinionpoll.ui.screens.support.SupportScreen
import com.catbit.opinionpoll.ui.screens.user_maker.UserMakerScreen
import com.catbit.opinionpoll.ui.screens.users.UsersScreen
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.UserSelect
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.coroutines.flow.collectLatest
import kotlinx.datetime.LocalDateTime
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.minus
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Page("/app")
@Composable
fun AppPage() {

    // TODO Verificar todos os cenários de erro (tentar novamente, falha)
    // TODO Mudar ícones de envio (usuário, forms, e afins), desabilitar quando não houver alterações
    // TODO Ajustar componente de busca (separador)

    val navigationController by rememberNavigationController("defaultNavigationController")
    var showNavRail by remember { mutableStateOf(false) }
    var selectMenuOption by rememberString("OPCurrentMenu", "home")

    val dialogState = rememberDialogState()
    val snackBarState = rememberSnackBarState()

    val stateHolder: AppStateHolder = getStandaloneStateHolder()
    val uiState = stateHolder.uiState.collectAsState().value

    SingleEffect {
        stateHolder.effects.collectLatest {
            when (it) {
                AppUIContract.Effect.OnLogout -> {
                    navigationController.navigate(
                        routeName = "login",
                        popUpTo = "login",
                        popUpToInclusive = true
                    )
                }
            }
        }
    }

    when (uiState) {
        AppUIContract.State.Loading -> {
            Column(
                modifier = Modifier.fillMaxSize(),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                CircularProgress(50)
                Text("Carregando")
            }
        }

        is AppUIContract.State.Displaying -> {
            if (isMobileMode) {
                Column(
                    modifier = Modifier
                        .fillMaxSize()
                        .id("index-row")
                ) {
                    MainContent(
                        navigationController = navigationController,
                        dialogState = dialogState,
                        snackBarState = snackBarState,
                        onShowNavRailChange = { showNavRail = it },
                        onSelectedMenuChange = { selectMenuOption = it }
                    )

                    if (showNavRail) {
                        NavRail(
                            selectMenuOption = selectMenuOption,
                            entries = stateHolder.menuEntries,
                            onMenuOptionSelected = { route ->
                                selectMenuOption = route
                            },
                            onEvent = { event -> stateHolder.onEvent(event) },
                            navigationController = navigationController,
                            dialogState = dialogState,
                            environmentId = uiState.environmentId,
                            environmentName = uiState.environmentName,
                            environmentPlan = uiState.environmentPlan,
                            planExpirationDate = uiState.planExpirationDate,
                            environmentImage = uiState.environmentImage
                        )
                    }
                }
            } else {
                Row(
                    modifier = Modifier
                        .fillMaxSize()
                        .id("index-row")
                ) {
                    if (showNavRail) {
                        NavRail(
                            selectMenuOption = selectMenuOption,
                            entries = stateHolder.menuEntries,
                            onEvent = { event -> stateHolder.onEvent(event) },
                            onMenuOptionSelected = { route ->
                                selectMenuOption = route
                            },
                            navigationController = navigationController,
                            dialogState = dialogState,
                            environmentId = uiState.environmentId,
                            environmentName = uiState.environmentName,
                            environmentPlan = uiState.environmentPlan,
                            planExpirationDate = uiState.planExpirationDate,
                            environmentImage = uiState.environmentImage
                        )
                    }

                    MainContent(
                        navigationController = navigationController,
                        dialogState = dialogState,
                        snackBarState = snackBarState,
                        onShowNavRailChange = { showNavRail = it },
                        onSelectedMenuChange = { selectMenuOption = it }
                    )
                }
            }
        }
    }
}

@Composable
fun MainContent(
    navigationController: NavigationController,
    dialogState: DialogState,
    snackBarState: SnackBarState,
    onShowNavRailChange: (Boolean) -> Unit,
    onSelectedMenuChange: (String) -> Unit
) {
    NavigationHost(
        modifier = Modifier
            .id("nav-host")
            .thenIf(
                condition = isMobileMode,
                ifTrue = {
                    width(100.percent).height(100.percent.minus(76.px))
                },
                ifFalse = {
                    fillMaxSize()
                }
            ),
        navigationController = navigationController,
        dialogState = dialogState,
        snackBarState = snackBarState,
        startingRoute = "login"
    ) {
        route("login") { stackEntry ->
            onShowNavRailChange(false)
            LoginScreen(
                stackEntryId = stackEntry.id,
                navigationController = navigationController,
                onNavigate = { onSelectedMenuChange("home") }
            )
        }
        route("home") { stackEntry ->
            onShowNavRailChange(true)
            HomeScreen(
                stackEntryId = stackEntry.id,
                navigationController = navigationController
            )
        }
        route("forms") { stackEntry ->
            onShowNavRailChange(true)
            FormsScreen(
                stackEntryId = stackEntry.id,
                navigationController = navigationController
            )
        }
        route(
            name = "form_maker",
            arguments = listOf(
                argument(id = "formIdentifier")
            )
        ) { stackEntry ->
            withNotNull(stackEntry.arguments) {
                onShowNavRailChange(false)
                FormMakerScreen(
                    stackEntryId = stackEntry.id,
                    formIdentifier = getNullableString("formIdentifier"),
                    navigationController = navigationController
                )
            }
        }
        route(
            name = "form_users_linker",
            arguments = listOf(
                argument(id = "formIdentifier"),
                argument(id = "formTitle")
            )
        ) { stackEntry ->
            withNotNull(stackEntry.arguments) {
                onShowNavRailChange(false)
                FormUsersLinkerScreen(
                    stackEntryId = stackEntry.id,
                    navigationController = navigationController,
                    formIdentifier = getString("formIdentifier"),
                    formTitle = getString("formTitle")
                )
            }
        }

        route("users") { stackEntry ->
            onShowNavRailChange(true)
            UsersScreen(
                stackEntryId = stackEntry.id,
                navigationController = navigationController
            )
        }

        route(
            name = "form_dashboard",
            arguments = listOf(
                argument("formIdentifier"),
                argument("formTitle"),
            )
        ) { stackEntry ->
            onShowNavRailChange(false)
            withNotNull(stackEntry.arguments) {
                FormDashboardScreen(
                    stackEntryId = stackEntry.id,
                    navigationController = navigationController,
                    formIdentifier = getString("formIdentifier"),
                    formTitle = getString("formTitle")
                )
            }
        }

        route(
            name = "user_maker",
            arguments = listOf(
                argument("userModel")
            )
        ) { stackEntry ->
            onShowNavRailChange(false)
            withNotNull(stackEntry.arguments) {
                UserMakerScreen(
                    stackEntryId = stackEntry.id,
                    navigationController = navigationController,
                    userModel = getNullableSerializedObject("userModel")
                )
            }
        }

        route("support") { stackEntry ->
            onShowNavRailChange(true)
            SupportScreen(
                stackEntryId = stackEntry.id,
                navigationController = navigationController,
            )
        }
    }
}

@Composable
fun UserHeader(
    dialogState: DialogState,
    environmentId: String,
    environmentName: String,
    environmentPlan: String,
    planExpirationDate: LocalDateTime?,
    environmentImage: String?
) {
    Box(
        modifier = Modifier
            .background(sitePalette().onSurface)
            .padding(8.px)
            .clip(Circle())
            .margin(bottom = 32.px)
            .onClick {
                dialogState.show(
                    width = 500.px
                ) {
                    Column(
                        modifier = Modifier.fillMaxSize()
                    ) {
                        Row(
                            modifier = Modifier.fillMaxWidth()
                        ) {
                            Column(
                                modifier = Modifier.margin(right = 24.px),
                                horizontalAlignment = Alignment.CenterHorizontally
                            ) {
                                Image(
                                    src = "/opinion_poll.png",
                                    width = 130,
                                    height = 130
                                )
                                Text(
                                    modifier = Modifier
                                        .titleLarge()
                                        .margin(top = 8.px),
                                    text = "OpinionPro"
                                )
                            }
                            Column(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .margin(right = 24.px),
                            ) {
                                Text(
                                    modifier = Modifier
                                        .bodyMedium()
                                        .margin(top = 8.px),
                                    text = "Disponibilizado para:"
                                )
                                Text(
                                    modifier = Modifier
                                        .titleSmall()
                                        .fontWeight(FontWeight.Bold),
                                    text = environmentName
                                )
                                Text(
                                    modifier = Modifier
                                        .bodyMedium()
                                        .margin(top = 8.px),
                                    text = "Plano:"
                                )
                                Text(
                                    modifier = Modifier
                                        .titleSmall()
                                        .fontWeight(FontWeight.Bold),
                                    text = environmentPlan
                                )
                                withNotNull(planExpirationDate) {
                                    Text(
                                        modifier = Modifier
                                            .bodyMedium()
                                            .margin(top = 8.px),
                                        text = "Plano expira em:"
                                    )
                                    Text(
                                        modifier = Modifier
                                            .titleSmall()
                                            .fontWeight(FontWeight.Bold),
                                        text = this.toBrazilianDatePattern()
                                    )
                                }
                                Text(
                                    modifier = Modifier
                                        .bodySmall()
                                        .color(sitePalette().onSurface.changeAlpha(0.5f))
                                        .margin(top = 8.px),
                                    text = "Identificador: $environmentId"
                                )
                            }
                            IconButton(
                                onClick = {
                                    dialogState.dismiss()
                                }
                            ) {
                                MaterialIcon(MaterialIcons.Round.Close)
                            }
                        }
                    }
                }
            }
            .cursor(Cursor.Pointer),
        contentAlignment = Alignment.Center
    ) {
        if (environmentImage != null) {
            Image(
                src = environmentImage,
                width = 48,
                height = 48,
            )
        } else {
            MaterialIcon(
                size = 48.px,
                icon = MaterialIcons.Round.Person,
                tint = sitePalette().surface
            )
        }
    }
}

@Composable
private fun NavRail(
    selectMenuOption: String,
    entries: List<NavRailEntry>,
    onMenuOptionSelected: (String) -> Unit,
    onEvent: (AppUIContract.Event) -> Unit,
    navigationController: NavigationController,
    dialogState: DialogState,
    environmentId: String,
    environmentName: String,
    environmentPlan: String,
    planExpirationDate: LocalDateTime?,
    environmentImage: String?
) {
    Box(
        modifier = Modifier
            .userSelect(UserSelect.None)
            .height(if (isMobileMode) 76.px else 100.percent)
            .width(if (isMobileMode) 100.percent else 100.px)
    ) {
        if (isMobileMode) {
            Row(
                modifier = Modifier
                    .id("nav-rail")
                    .fillMaxSize()
                    .backgroundColor(sitePalette().surface),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceEvenly
            ) {
                entries
                    .forEach { entry ->
                        with(entry) {
                            NavRailButton(
                                icon = icon,
                                label = label,
                                selected = route == selectMenuOption
                            ) {
                                onMenuOptionSelected(route)
                                navigationController.navigate(
                                    routeName = route,
                                    popUpTo = route,
                                    popUpToInclusive = true
                                )
                            }
                        }
                    }

                Box(
                    modifier = Modifier
                        .size(48.px)
                        .clip(Rect(48.px))
                        .background(sitePalette().onSurface)
                        .cursor(Cursor.Pointer)
                        .borderRadius(40.px)
                        .onClick {
                            dialogState.show(
                                width = 300.px
                            ) {
                                Column(
                                    modifier = Modifier
                                        .display(DisplayStyle.Flex)
                                        .gap(8.px)
                                        .fillMaxWidth(),
                                    horizontalAlignment = Alignment.CenterHorizontally
                                ) {
                                    Row(
                                        modifier = Modifier.fillMaxWidth(),
                                        horizontalArrangement = Arrangement.End
                                    ) {
                                        IconButton(
                                            onClick = {
                                                dialogState.dismiss()
                                            }
                                        ) {
                                            MaterialIcon(MaterialIcons.Round.Close)
                                        }
                                    }

                                    Image(
                                        src = "/opinion_poll.png",
                                        width = 80,
                                        height = 80
                                    )
                                    Text(
                                        modifier = Modifier
                                            .titleLarge()
                                            .margin(top = 8.px),
                                        text = "OpinionPro"
                                    )

                                    Text(
                                        modifier = Modifier
                                            .bodyMedium()
                                            .margin(top = 8.px),
                                        text = "Disponibilizado para:"
                                    )
                                    Text(
                                        modifier = Modifier
                                            .titleSmall()
                                            .fontWeight(FontWeight.Bold),
                                        text = environmentName
                                    )
                                    Text(
                                        modifier = Modifier
                                            .bodyMedium()
                                            .margin(top = 8.px),
                                        text = "Plano:"
                                    )
                                    Text(
                                        modifier = Modifier
                                            .titleSmall()
                                            .fontWeight(FontWeight.Bold),
                                        text = environmentPlan
                                    )
                                    withNotNull(planExpirationDate) {
                                        Text(
                                            modifier = com.varabyte.kobweb.compose.ui.Modifier
                                                .bodyMedium()
                                                .margin(top = 8.px),
                                            text = "Plano expira em:"
                                        )
                                        Text(
                                            modifier = com.varabyte.kobweb.compose.ui.Modifier
                                                .titleSmall()
                                                .fontWeight(com.varabyte.kobweb.compose.css.FontWeight.Bold),
                                            text = this.toBrazilianDatePattern()
                                        )
                                    }
                                    Text(
                                        modifier = Modifier
                                            .bodySmall()
                                            .color(sitePalette().onSurface.changeAlpha(0.5f))
                                            .margin(top = 8.px),
                                        text = "Identificador: $environmentId"
                                    )

                                    var colorMode by ColorMode.currentState

                                    FilledButton(
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .padding(horizontal = 16.px),
                                        text = "Mudar tema",
                                        icon = if (colorMode.isLight)
                                            MaterialIcons.Round.DarkMode
                                        else
                                            MaterialIcons.Round.LightMode,
                                        onClick = {
                                            dialogState.dismiss()
                                            colorMode = colorMode.opposite
                                            toggleColorMode(colorMode)
                                        }
                                    )

                                    FilledButton(
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .padding(horizontal = 16.px),
                                        text = "Sair",
                                        icon = MaterialIcons.Round.Logout,
                                        onClick = {
                                            dialogState.dismiss()
                                            onEvent(
                                                AppUIContract.Event.OnLogout
                                            )
                                        }
                                    )
                                }
                            }
                        },
                    contentAlignment = Alignment.Center
                ) {
                    if (environmentImage != null) {
                        Image(
                            src = environmentImage,
                            width = 32,
                            height = 32,
                        )
                    } else {
                        MaterialIcon(
                            size = 32.px,
                            icon = MaterialIcons.Round.Person,
                            tint = sitePalette().surface
                        )
                    }
                }
            }
        } else {
            Column(
                modifier = Modifier
                    .id("nav-rail")
                    .fillMaxSize()
                    .backgroundColor(sitePalette().surface)
                    .padding(top = 20.px, bottom = 20.px),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {

                UserHeader(
                    dialogState,
                    environmentId,
                    environmentName,
                    environmentPlan,
                    planExpirationDate,
                    environmentImage
                )

                entries.forEach { entry ->
                    with(entry) {
                        NavRailButton(
                            modifier = Modifier
                                .margin(top = 14.px, right = 12.px, left = 12.px)
                                .fillMaxWidth(),
                            icon = icon,
                            label = label,
                            selected = route == selectMenuOption
                        ) {
                            onMenuOptionSelected(route)
                            navigationController.navigate(
                                routeName = route,
                                popUpTo = route,
                                popUpToInclusive = true
                            )
                        }
                    }

                }
                Spacer(modifier = Modifier.fillMaxHeight())

                var colorMode by ColorMode.currentState

                NavRailButton(
                    modifier = Modifier
                        .margin(right = 12.px, left = 12.px)
                        .fillMaxWidth(),
                    icon = if (colorMode.isLight) MaterialIcons.Round.DarkMode else MaterialIcons.Round.LightMode,
                    label = "Tema",
                    selected = false
                ) {
                    colorMode = colorMode.opposite
                    toggleColorMode(colorMode)
                }

                NavRailButton(
                    modifier = Modifier
                        .margin(top = 14.px, right = 12.px, left = 12.px)
                        .fillMaxWidth(),
                    icon = MaterialIcons.Round.Logout,
                    label = "Sair",
                    selected = false
                ) {
                    onEvent(
                        AppUIContract.Event.OnLogout
                    )
                }
            }
        }

        Spacer(
            modifier = Modifier
                .visibility(dialogState.visibility)
                .zIndex(102)
                .fillMaxSize()
                .backgroundColor(sitePalette().background.darkened().changeAlpha(0.8f))
        )
    }
}

data class NavRailEntry(
    val label: String,
    val route: String,
    val icon: MaterialIcon
)