package com.catbit.opinionpoll.inputs.validators

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.inputs.NumericAreaInputUIState

class NumericAreaInputUIValidator : InputUIValidator<NumericAreaInputUIState> {
    override fun match(inputUIState: InputUIState) = inputUIState is NumericAreaInputUIState

    override fun validate(
        inputUIState: NumericAreaInputUIState,
        allIdentifiers: List<String>
    ): InputUIState {
        val errorMessage = with(inputUIState) {
            if (identifier.isEmpty()) {
                "O identificador não pode ser vazio"
            } else if (title.isEmpty()) {
                "O título não pode ser vazio"
            } else if (minValue >= maxValue) {
                "O valor máximo precisa ser maior que o valor mínimo"
            } else if (identifier in allIdentifiers) {
                "O identificador precisa ser único"
            } else null
        }
        return inputUIState.copy(errorMessage = errorMessage)
    }
}