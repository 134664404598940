package com.catbit.opinionpoll.ui.screens.users

import com.catbit.opinionpoll.ui.states.UserUIState

interface UsersUIContract {
    sealed interface State {
        data class Displaying(
            val users: List<UserUIState>,
            val isOnUserDeletionMode: Boolean = false,
            val enableDeleteUsersButton: Boolean = false,
            val selectedMenu: String = "",
            val searchSorting: String = "Nome",
            val searchSortingOptions: List<String> = listOf("Nome", "Email", "Telefone"),
            val searchQuery: String = ""
        ) : State

        data object Loading : State

        data class Failure(
            val failure: Throwable
        ) : State
    }

    sealed interface Effect {
        data object OnConfirmUsersDeletion : Effect
        data object OnStartUsersDeletion : Effect
        data object OnUsersDeletionSuccess : Effect
        data object OnUsersDeletionFailure : Effect
    }

    sealed interface Event {
        data object OnMenuDeleteUserClick : Event
        data object OnDeleteUserClick : Event
        data object OnSelectAllUsersForDeletion : Event
        data object OnCancelDeletingUsers : Event
        data object OnConfirmUsersDeletion : Event
        data object OnRefreshUsers : Event
        data class OnUserSelectedForDeletion(val userId: String) : Event
        data class OnFilterChange(val newFilter: String) : Event
        data class OnSearchQueryChange(val newQuery: String) : Event
        data object OnClearSearchQuery : Event
    }
}