package com.catbit.opinionpoll.ui.screens.user_maker

import com.catbit.opinionpoll.core.domain.invoke
import com.catbit.opinionpoll.core.extensions.*
import com.catbit.opinionpoll.data.models.UserModel
import com.catbit.opinionpoll.domain.user.CreateUserUseCase
import com.catbit.opinionpoll.domain.user.GetCurrentUserRoleUseCase
import com.catbit.opinionpoll.domain.user.UpdateUserUseCase
import com.catbit.opinionpoll.ui.screens.user_maker.UserMakerUIContract.*
import com.catbit.opinionpoll.ui.base.ScreenStateHolder
import com.catbit.opinionpoll.ui.states.UserUIState
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch

class UserMakerStateHolder(
    private val userModel: UserModel?,
    private val getCurrentUserRole: GetCurrentUserRoleUseCase,
    private val createUserUseCase: CreateUserUseCase,
    private val updateUserUseCase: UpdateUserUseCase,
) : ScreenStateHolder<State, Event, Effect>() {

    override val internalUIState = MutableStateFlow<State>(
        State.Loading(
            toolbarTitle = if (userModel != null) "Editar membro da equipe" else "Adicionar membro"
        )
    )

    private lateinit var currentUserRole: UserModel.UserRole

    override fun onStarted() {
        loadData()
    }

    private fun loadData() {
        stateHolderScope.launch {
            getCurrentUserRole()
                .onSuccess {
                    currentUserRole = it

                    withNotNull(userModel) {
                        internalUIState.update {
                            with(this) {
                                State.Displaying(
                                    toolbarTitle = "Editar membro da equipe",
                                    name = name.split(" ").first(),
                                    surname = name.split(" ").drop(1).joinToString(" "),
                                    email = email,
                                    phoneNumber = phoneNumber.toBrazilianPhoneFormat(),
                                    phoneNumberBuffer = phoneNumber.drop(3),
                                    role = UserUIState.Role.fromRoleModel(role),
                                    availableRoles = UserUIState.Role.getAvailableRoles(currentUserRole)
                                )
                            }
                        }
                    } ?: run {
                        internalUIState.update {
                            State.default(
                                toolbarTitle = "Adicionar membro",
                                availableRoles = UserUIState.Role.getAvailableRoles(currentUserRole)
                            )
                        }
                    }
                }
                .onFailure {
                    internalUIState.update {
                        State.Failure(
                            toolbarTitle = if (userModel != null) "Editar membro da equipe" else "Adicionar membro",
                            message = "Houve um erro ao carregar os dados!"
                        )
                    }
                }
        }
    }

    override fun onEvent(event: Event) {
        when (event) {
            is Event.OnEmailChange -> onEmailChange(event.email)
            is Event.OnNameChange -> onNameChange(event.name)
            is Event.OnRoleChange -> onRoleChange(event.role)
            is Event.OnSurnameChange -> onSurnameChange(event.surname)
            is Event.OnPhoneNumberChange -> onPhoneNumberChange(event.phoneNumber)
            Event.OnSubmit -> onSubmit()
            Event.TryAgain -> loadData()
        }
    }

    private fun onSurnameChange(surname: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(surname = surname)
        }
    }

    private fun onRoleChange(role: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(role = UserUIState.Role.fromString(role))
        }
    }

    private fun onNameChange(name: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(name = name)
        }
    }

    private fun onEmailChange(email: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(email = email)
        }
    }

    private fun onPhoneNumberChange(pressedKey: String) {
        internalUIState.updateAs<State.Displaying> {
            val newPhoneNumberBuffer = if (pressedKey == "Backspace" || pressedKey == "Delete") {
                phoneNumberBuffer.dropLast(1)
            } else {
                if (phoneNumberBuffer.length == 11) {
                    phoneNumberBuffer
                } else phoneNumberBuffer + pressedKey
            }
            copy(
                phoneNumberBuffer = newPhoneNumberBuffer,
                phoneNumber = newPhoneNumberBuffer
                    .applyDynamicMask(
                        replaceableChar = '#',
                        mask = "(##) # ####-####"
                    )
            )
        }
    }

    private fun onSubmit() {
        withNotNull(internalUIState.value as? State.Displaying) {
            if (name.isBlank()) {
                internalEffects.dispatch(
                    Effect.OnSubmitFailure(
                        message = "O campo Nome não pode ser vazio"
                    )
                )
            } else if (surname.isBlank()) {
                internalEffects.dispatch(
                    Effect.OnSubmitFailure(
                        message = "O campo Sobrenome não pode ser vazio"
                    )
                )
            } else if (email.isBlank()) {
                internalEffects.dispatch(
                    Effect.OnSubmitFailure(
                        message = "O campo Email não pode ser vazio"
                    )
                )
            } else if (!email.isAValidEmail()) {
                internalEffects.dispatch(
                    Effect.OnSubmitFailure(
                        message = "O campo Email precisa conter um email válido"
                    )
                )
            } else if (phoneNumberBuffer.isBlank()) {
                internalEffects.dispatch(
                    Effect.OnSubmitFailure(
                        message = "O campo Telefone não pode ser vazio"
                    )
                )
            } else if (phoneNumberBuffer.length != 11) {
                internalEffects.dispatch(
                    Effect.OnSubmitFailure(
                        message = "Campo telefone deve estar no padrão (##) # ####-####"
                    )
                )
            } else {
                internalEffects.dispatch(Effect.OnStarSubmitting)

                stateHolderScope.launch {

                    val result = if (userModel != null)
                        updateUserUseCase(
                            UpdateUserUseCase.Params(
                                userIdentifier = userModel.uid,
                                phoneNumber = "+55$phoneNumberBuffer",
                                role = role.name,
                                name = prepareName(name, surname),
                                email = email,
                            )
                        )
                    else
                        createUserUseCase(
                            CreateUserUseCase.Params(
                                phoneNumber = "+55$phoneNumberBuffer",
                                role = role.name,
                                name = prepareName(name, surname),
                                email = email
                            )
                        )

                    result
                        .onSuccess {
                            internalEffects.dispatch(Effect.OnSubmitSuccess)
                        }
                        .onFailure {
                            internalEffects.dispatch(Effect.OnSubmitFailure("Houve uma falha ao salvar!"))
                        }

                }
            }
        }
    }

    private fun prepareName(
        name: String,
        surname: String
    ): String {
        val firstName = name.split(" ").first().capitalize()
        val completeSurname = surname.split(" ").map {
            it.capitalize()
        }

        return "$firstName ${completeSurname.joinToString(" ")}"
    }
}