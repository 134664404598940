package com.catbit.opinionpoll.inputs.events

data class InputUIEvent (
    val formIdentifier: String,
    val data: Any
)

sealed interface InputUIEventDefaultData {
    data class IdentifierChange(val newIdentifier: String)
    data class TitleChange(val newTitle: String)
    data class SubtitleChange(val newSubtitle: String)
    data class ObligatoryToggle(val isObligatory: Boolean)
}