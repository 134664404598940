package com.catbit.opinionpoll.inputs.requests.requests

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Date")
data class DateInputRequest(
    override val identifier: String,
    override val title: String,
    override val subtitle: String?,
    override val isObligatory: Boolean,
    override val index: Int,
    @SerialName("minDate")
    val minDate: String?,
    @SerialName("maxDate")
    val maxDate: String?
) : InputRequest