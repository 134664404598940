package com.catbit.opinionpoll.ui.screens.form_users_linker

import com.catbit.opinionpoll.ui.states.UserUIState

interface FormUsersLinkerUIContract {
    sealed interface State {
        data class Displaying(
            val title: String,
            val assignedUsers: List<UserUIState>,
            val assignedUsersQuery: String,
            val assignedUsersSorting: String = "Nome",
            val nonAssignedUsers: List<UserUIState>,
            val nonAssignedUsersQuery: String,
            val nonAssignedUsersSorting: String = "Nome",
            val sortingOptions: List<String> = listOf("Nome", "Email", "Telefone"),
        ) : State

        data object Loading : State

        data class Failure(
            val failure: Throwable,
        ) : State
    }

    sealed interface Effect {
        data object OnStartSavingChanges : Effect
        data object OnSaveChangesSuccess : Effect
        data class OnSaveChangesFailure(val message: String) : Effect
    }

    sealed interface Event {
        data class OnUserChecked(val uid: String) : Event
        data object OnMoveUsersToAssigned : Event
        data object OnMoveAllUsersToAssigned : Event
        data object OnMoveUsersToNonAssigned : Event
        data object OnMoveAllUsersToNonAssigned : Event
        data class OnAssignedUsersSearch(val query: String) : Event
        data object OnAssignedClearSearch : Event
        data class OnAssignedUsersSortingChange(val sorting: String) : Event
        data class OnNonAssignedUsersSearch(val query: String) : Event
        data object OnNonAssignedClearSearch : Event
        data class OnNonAssignedUsersSortingChange(val sorting: String) : Event
        data object OnSaveChanges : Event
    }
}