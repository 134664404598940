package com.catbit.opinionpoll.inputs.requests.requests

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("SingleChoiceOptions")
data class SingleChoiceOptionsInputRequest(
    override val identifier: String,
    override val title: String,
    override val subtitle: String?,
    override val isObligatory: Boolean,
    override val index: Int,
    @SerialName("options")
    val options: List<OptionRequest>,
    @SerialName("otherOption")
    val otherOption: OptionRequest?,
    @SerialName("otherOptionPlaceholderText")
    val otherOptionPlaceholderText: String?,
    @SerialName("maxOtherOptionTextLength")
    val maxOtherOptionTextLength: Int?,
    @SerialName("style")
    val style: String,
    @SerialName("showSearchBar")
    val showSearchBar: Boolean?
) : InputRequest
