package com.catbit.opinionpoll.inputs.events

sealed interface CompactSingleChoiceInputEventData {
    data object OnAddOption : CompactSingleChoiceInputEventData

    data class OnOptionTitleChange(
        val actualIdentifier: String,
        val newTitle: String
    ) : CompactSingleChoiceInputEventData

    data class OnOptionRemove(
        val actualIdentifier: String
    ) : CompactSingleChoiceInputEventData

    data object OnAddQuestion : CompactSingleChoiceInputEventData

    data class OnQuestionActualIdentifierChange(
        val actualIdentifier: String,
        val newIdentifier: String
    ) : CompactSingleChoiceInputEventData

    data class OnQuestionTitleChange(
        val actualIdentifier: String,
        val newTitle: String
    ) : CompactSingleChoiceInputEventData

    data class OnQuestionRemove(
        val actualIdentifier: String
    ) : CompactSingleChoiceInputEventData

    data class OnShowSearchBarToggle(
        val showSearchBar: Boolean
    ) : CompactSingleChoiceInputEventData

    class OnAddBatchOptions(
        val rawText: String
    ) : CompactSingleChoiceInputEventData

    class OnAddBatchQuestions(
        val rawText: String
    ) : CompactSingleChoiceInputEventData

    data class OnMoveOptionUp(
        val actualIdentifier: String
    ) : CompactSingleChoiceInputEventData

    data class OnMoveOptionDown(
        val actualIdentifier: String
    ) : CompactSingleChoiceInputEventData

    data class OnMoveQuestionUp(
        val actualIdentifier: String
    ) : CompactSingleChoiceInputEventData

    data class OnMoveQuestionDown(
        val actualIdentifier: String
    ) : CompactSingleChoiceInputEventData
}