package com.catbit.opinionpoll.ui.base

import com.catbit.opinionpoll.core.state_holder.StateHolder
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asSharedFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch

abstract class ScreenStateHolder<State, Event, Effect> : StateHolder() {

    protected abstract val internalUIState: MutableStateFlow<State>
    val uiState get() = internalUIState.asStateFlow()

    protected val internalEffects = MutableSharedFlow<Effect>()
    val effects get() = internalEffects.asSharedFlow()

    abstract fun onEvent(event: Event)

    fun MutableSharedFlow<Effect>.dispatch(effect: Effect) {
        stateHolderScope.launch {
            emit(effect)
        }
    }
}