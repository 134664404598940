package com.catbit.opinionpoll.core.ui.composables

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.extensions.changeAlpha
import com.catbit.opinionpoll.core.ui.composables.base_components.Text
import com.catbit.opinionpoll.core.ui.icons.MaterialIcon
import com.catbit.opinionpoll.core.ui.modifiers.labelMedium
import com.catbit.opinionpoll.core.ui.modifiers.padding
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.lightened
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import org.jetbrains.compose.web.css.px

@Composable
fun NavDrawerButton(
    modifier: Modifier = Modifier,
    icon: MaterialIcon,
    label: String,
    selected: Boolean,
    onClick: () -> Unit
) {

    val selectedBackgroundColor = sitePalette().secondaryContainer
    val selectedTextColor = sitePalette().onSecondaryContainer
    val selectedIconColor = sitePalette().onSecondaryContainer

    val unselectedBackgroundColor = sitePalette().surfaceVariant
    val unselectedTextColor = sitePalette().onSurfaceVariant
    val unselectedIconColor = sitePalette().onSurfaceVariant

    val hoveredBackgroundColor = sitePalette().secondaryContainer.changeAlpha(0.8f)
    val hoveredTextColor = sitePalette().onSecondaryContainer
    val hoveredIconColor = sitePalette().onSecondaryContainer

    var backgroundColor by remember(selected) {
        mutableStateOf(
            if (selected) selectedBackgroundColor else unselectedBackgroundColor
        )
    }

    var textColor by remember(selected) {
        mutableStateOf(
            if (selected) selectedTextColor else unselectedTextColor
        )
    }

    var iconColor by remember(selected) {
        mutableStateOf(
            if (selected) selectedIconColor else unselectedIconColor
        )
    }

    Row(
        modifier = modifier
            .height(48.px)
            .fillMaxWidth()
            .cursor(Cursor.Pointer)
            .backgroundColor(backgroundColor)
            .padding(horizontal = 16.px)
            .clip(Rect(48.px))
            .onMouseEnter {
                if (!selected) {
                    backgroundColor = hoveredBackgroundColor
                    textColor = hoveredTextColor
                    iconColor = hoveredIconColor
                }
            }
            .onMouseLeave {
                backgroundColor = if (selected) selectedBackgroundColor else unselectedBackgroundColor
                textColor = if (selected) selectedTextColor else unselectedTextColor
                iconColor = if (selected) selectedIconColor else unselectedIconColor
            }
            .onClick { onClick() },
        verticalAlignment = Alignment.CenterVertically
    ) {
        MaterialIcon(
            modifier = Modifier.margin(right = 12.px),
            icon = icon,
            tint = iconColor
        )
        Text(
            modifier = Modifier
                .color(textColor)
                .labelMedium(),
            text = label
        )
    }
}