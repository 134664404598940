package com.catbit.opinionpoll.core.ui.composables.base_components

import androidx.compose.runtime.Composable
import com.catbit.opinionpoll.core.extensions.withNotNull
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.icons.MaterialIcon
import com.catbit.opinionpoll.core.ui.modifiers.labelLarge
import com.catbit.opinionpoll.core.ui.modifiers.padding
import com.catbit.opinionpoll.core.ui.modifiers.roundedCornerClip
import com.catbit.opinionpoll.core.ui.modifiers.thenIf
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

@Composable
fun SegmentedButton(
    modifier: Modifier = Modifier,
    selectedItemId: String,
    items: List<SegmentedButtonItem>,
    backgroundColor: Color = sitePalette().surface,
    textColor: Color = sitePalette().onSurface,
) {
    Row(
        modifier = modifier
            .height(40.px)
            .borderRadius(40.px)
            .clip(Rect(40.px))
            .border(
                width = 1.px,
                color = sitePalette().outline,
                style = LineStyle.Solid
            )
    ) {
        items.forEachIndexed { index, item ->
            Row(
                modifier = Modifier
                    .fillMaxSize()
                    .height(38.px)
                    .padding(horizontal = 16.px)
                    .cursor(Cursor.Pointer)
                    .thenIf(index != items.lastIndex) {
                        borderRight(
                            width = 1.px,
                            color = sitePalette().outline,
                            style = LineStyle.Solid
                        )
                    }
                    .thenIf(index == 0) {
                        roundedCornerClip(
                            topLeft = 38.px,
                            bottomLeft = 38.px
                        )
                    }
                    .thenIf(index == items.lastIndex) {
                        roundedCornerClip(
                            topRight = 38.px,
                            bottomRight = 38.px
                        )
                    }
                    .background(
                        if (item.id == selectedItemId)
                            sitePalette().secondaryContainer
                        else
                            backgroundColor
                    )
                    .onClick { item.onClick() },
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                withNotNull(item.icon) {
                    MaterialIcon(
                        modifier = Modifier.margin(right = 8.px),
                        tint = if (item.id == selectedItemId)
                            sitePalette().onSecondaryContainer
                        else
                            textColor,
                        icon = this
                    )
                }
                Text(
                    modifier = Modifier
                        .color(
                            if (item.id == selectedItemId)
                                sitePalette().onSecondaryContainer
                            else
                                sitePalette().onSurface
                        )
                        .labelLarge(),
                    text = item.text
                )
            }
        }
    }
}

data class SegmentedButtonItem(
    val id: String,
    val text: String,
    val icon: MaterialIcon? = null,
    val onClick: () -> Unit
)