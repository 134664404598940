package com.catbit.opinionpoll.inputs.validators

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.inputs.DateInputUIState

class DateInputUIValidator : InputUIValidator<DateInputUIState> {
    override fun match(inputUIState: InputUIState) = inputUIState is DateInputUIState

    override fun validate(
        inputUIState: DateInputUIState,
        allIdentifiers: List<String>
        ): InputUIState {

        val errorMessage = with(inputUIState) {
            if (identifier.isEmpty()) {
                "O identificador não pode ser vazio"
            } else if (title.isEmpty()) {
                "O título não pode ser vazio"
            } else if (minDate != null && maxDate != null && minDate >= maxDate) {
                "Data mínima precisa ser maior que a data máxima"
            } else if (identifier in allIdentifiers) {
                "O identificador precisa ser único"
            } else null
        }

        return inputUIState.copy(errorMessage = errorMessage)
    }
}