package com.catbit.opinionpoll.data.data_sources.networking.requests

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SimplifiedFormAnswerRequest(
    @SerialName("identifier")
    val identifier: String,
    @SerialName("answer")
    val answer: String
)
