package com.catbit.opinionpoll.ui.states

import kotlinx.datetime.LocalDateTime

data class FormUIState(
    val identifier: String,
    val title: String,
    val creationDate: LocalDateTime,
    val expirationDate: LocalDateTime,
    val createdBy: String,
    val isSelectedForDeletion: Boolean
)
