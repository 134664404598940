package com.catbit.opinionpoll.inputs.events_handler

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.events.DateTimeInputEventData
import com.catbit.opinionpoll.inputs.events.InputUIEventDefaultData
import com.catbit.opinionpoll.inputs.inputs.DateTimeInputUIState

class DateTimeInputUIEventHandler : InputUIEventHandler<DateTimeInputUIState> {

    override fun match(formIdentifier: String, uiState: InputUIState) =
        uiState is DateTimeInputUIState && formIdentifier == uiState.actualIdentifier

    override fun handle(uiState: DateTimeInputUIState, eventData: Any) = when (eventData) {
        is DateTimeInputEventData.OnMinDateTimeChange -> uiState.copy(minDateTime = eventData.newMinDateTime)
        is DateTimeInputEventData.OnMaxDateTimeChange -> uiState.copy(maxDateTime = eventData.newMaxDateTime)
        is InputUIEventDefaultData.IdentifierChange -> uiState.copy(identifier = eventData.newIdentifier)
        is InputUIEventDefaultData.TitleChange -> uiState.copy(title = eventData.newTitle)
        is InputUIEventDefaultData.SubtitleChange -> uiState.copy(subtitle = eventData.newSubtitle)
        is InputUIEventDefaultData.ObligatoryToggle -> uiState.copy(isObligatory = eventData.isObligatory)
        else -> uiState
    }
}