package com.catbit.opinionpoll.inputs.models

import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.inputs.InputType
import com.catbit.opinionpoll.inputs.inputs.CompactSingleChoiceInputUIState
import com.catbit.opinionpoll.inputs.inputs.OptionInput
import com.catbit.opinionpoll.inputs.inputs.QuestionInput
import kotlinx.serialization.Serializable

@Serializable
data class CompactSingleChoiceOptionsInputModel(
    override val identifier: String,
    override val title: String,
    override val subtitle: String?,
    override val isObligatory: Boolean,
    override val index: Int,
    val options: List<OptionModel>,
    val questions: List<OptionModel>,
    val showSearchBar: Boolean,
) : InputModel {

    override fun toUIState() = CompactSingleChoiceInputUIState(
        actualIdentifier = UUID.stringUUID(),
        type = InputType.CompactSingleChoice,
        identifier = identifier,
        title = title,
        subtitle = subtitle.orEmpty(),
        isObligatory = isObligatory,
        index = index,
        errorMessage = null,
        isEnabled = true,
        options = options.map {
            OptionInput(
                actualIdentifier = it.identifier,
                title = it.title
            )
        },
        questions = questions.map {
            QuestionInput(
                actualIdentifier = UUID.stringUUID(),
                identifier = it.identifier,
                title = it.title
            )
        },
        showSearchBar = showSearchBar
    )

    override fun knowsIdentifier(identifier: String) = questions.any { it.identifier == identifier }
    override val identifiers = questions.map { it.identifier }
    override val titleToIdentifierMapping = questions.map {"$title  ${it.title}" to it.identifier }
}
