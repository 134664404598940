package com.catbit.opinionpoll.core.ui.modifiers

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.onMouseEnter
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave

@Composable
fun Modifier.hoverColor(
    onMouseExitColor: Color,
    onMouseEnterColor: Color
): Modifier {

    var hasMouseEnter by remember { mutableStateOf(false) }

    return then(this)
        .color(if (hasMouseEnter) onMouseEnterColor else onMouseExitColor)
        .onMouseEnter { hasMouseEnter = true }
        .onMouseLeave { hasMouseEnter = false }
}