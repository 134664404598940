package com.catbit.opinionpoll.core.ui.composables.base_components

import androidx.compose.runtime.Composable
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.icons.MaterialIcon
import com.catbit.opinionpoll.core.ui.modifiers.labelLarge
import com.catbit.opinionpoll.core.ui.modifiers.padding
import com.catbit.opinionpoll.core.ui.modifiers.thenIf
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

@Composable
fun Chip(
    modifier: Modifier = Modifier,
    text: String,
    selected: Boolean,
    enabled: Boolean,
    icon: MaterialIcon? = null,
    onClick: () -> Unit
) {
    val borderColor = sitePalette().outline

    Row(
        modifier = modifier
            .padding(horizontal = 16.px)
            .clip(Rect(8.px))
            .borderRadius(8.px)
            .onClick {
                if (enabled) {
                    onClick()
                }
            }
            .cursor(if (enabled) Cursor.Pointer else Cursor.NotAllowed)
            .background(
                if (selected)
                    sitePalette().secondaryContainer
                else
                    sitePalette().surface
            )
            .border(
                width = if (selected) null else 1.px,
                style = if (selected) null else LineStyle.Solid,
                color = if (selected) null else borderColor
            )
            .height(32.px),
        verticalAlignment = Alignment.CenterVertically
    ) {
        icon?.let {
            MaterialIcon(
                modifier = Modifier.margin(right = 8.px),
                size = 18.px,
                icon = it,
                tint = if (selected)
                    sitePalette().onSecondaryContainer
                else
                    sitePalette().onSurface
            )
        }

        Text(
            modifier = Modifier
                .labelLarge()
                .color(
                    if (selected)
                        sitePalette().onSecondaryContainer
                    else
                        sitePalette().onSurface
                ),
            text = text
        )
    }
}