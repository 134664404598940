package com.catbit.opinionpoll.data.data_sources.networking.responses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UserResponse(
    @SerialName("uid")
    val uid: String,
    @SerialName("environment")
    val environment: String,
    @SerialName("phoneNumber")
    val phoneNumber: String,
    @SerialName("role")
    val role: String,
    @SerialName("name")
    val name: String,
    @SerialName("email")
    val email: String,
)
