package com.catbit.opinionpoll.domain.user

import com.catbit.opinionpoll.core.domain.UseCase
import com.catbit.opinionpoll.data.repositories.OpinionPollRepository

class GetCurrentUserIdUseCase(
    private val repository: OpinionPollRepository
) : UseCase<String, Unit>() {

    override suspend fun execute(params: Unit) = repository.getCurrentUserId()
}