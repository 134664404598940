package com.catbit.opinionpoll.core.domain

import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext

abstract class UseCase<ReturnType, Params> {
    abstract suspend fun execute(params: Params): Result<ReturnType>

    suspend operator fun invoke(params: Params) = withContext(Dispatchers.Default) {
        execute(params)
    }
}

suspend operator fun <ReturnType> UseCase<ReturnType, Unit>.invoke() = invoke(Unit)