package com.catbit.opinionpoll.domain.form_dashboard

import com.catbit.opinionpoll.core.domain.UseCase
import com.catbit.opinionpoll.data.models.FormDashboardModel
import com.catbit.opinionpoll.data.repositories.OpinionPollRepository

class GetFormDashboardUseCase(
    private val repository: OpinionPollRepository
) : UseCase<FormDashboardModel, GetFormDashboardUseCase.Params>() {

    override suspend fun execute(params: Params) =
        repository.getFormDashboard(formIdentifier = params.formIdentifier)

    data class Params(
        val formIdentifier: String
    )
}