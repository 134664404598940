package com.catbit.opinionpoll.inputs.requests.producers

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.inputs.DateInputUIState
import com.catbit.opinionpoll.inputs.requests.requests.DateInputRequest

class DateInputRequestProducer : InputRequestProducer<DateInputUIState> {
    override fun match(inputUIState: InputUIState) = inputUIState is DateInputUIState

    override fun produce(inputUIState: DateInputUIState) = DateInputRequest(
        identifier = inputUIState.identifier,
        index = inputUIState.index,
        title = inputUIState.title,
        subtitle = inputUIState.subtitle,
        isObligatory = inputUIState.isObligatory,
        minDate = inputUIState.minDate?.toString(),
        maxDate = inputUIState.maxDate?.toString()
    )
}