package com.catbit.opinionpoll.inputs.responses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class OptionResponse(
    @SerialName("title")
    val title: String,
    @SerialName("identifier")
    val identifier: String
)