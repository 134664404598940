package com.catbit.opinionpoll.core.ui.composables

import androidx.compose.runtime.Composable
import com.catbit.opinionpoll.core.ui.icons.MaterialIcon
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.UserSelect
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.userSelect
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.CSSNumeric
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun MaterialIcon(
    icon: MaterialIcon,
    modifier: Modifier = Modifier,
    tint: Color = sitePalette().onBackground,
    size: CSSNumeric = 24.px
) {
    Span(
        attrs = modifier
            .fontSize(size)
            .userSelect(UserSelect.None)
            .color(tint)
            .toAttrs {
                val iconClass = icon.style.suffix
                val classPrefix = if (iconClass.isEmpty()) "" else "-"
                classes("material-icons$classPrefix$iconClass")
            }
    ) {
        Text(icon.name)
    }
}

@Composable
fun MaterialIcon(
    icon: MaterialIcon,
    modifier: Modifier = Modifier,
    size: CSSNumeric = 24.px
) {
    Span(
        attrs = modifier
            .fontSize(size)
            .userSelect(UserSelect.None)
            .toAttrs {
                val iconClass = icon.style.suffix
                val classPrefix = if (iconClass.isEmpty()) "" else "-"
                classes("material-icons$classPrefix$iconClass")
            }
    ) {
        Text(icon.name)
    }
}