package com.catbit.opinionpoll.inputs.events

import com.catbit.opinionpoll.inputs.inputs.SingleChoiceInputUIState

sealed interface SingleChoiceInputEventData {
    data object OnAddOption : SingleChoiceInputEventData

    data class OnOptionTitleChange(
        val actualIdentifier: String,
        val newTitle: String
    ) : SingleChoiceInputEventData

    data class OnOptionRemove(
        val actualIdentifier: String
    ) : SingleChoiceInputEventData

    data class OnToggleHasOtherOption(
        val hasOtherOption: Boolean
    ) : SingleChoiceInputEventData

    data class OnOtherOptionTitleChange(
        val newTitle: String
    ) : SingleChoiceInputEventData

    data class OnOtherPlaceholderTextTitleChange(
        val newPlaceholder: String
    ) : SingleChoiceInputEventData

    data class OnOtherOptionTextLengthChange(
        val newLength: Int
    ) : SingleChoiceInputEventData

    data class OnStyleChange(
        val newStyle: SingleChoiceInputUIState.SingleChoiceStyle
    ) : SingleChoiceInputEventData

    data class OnShowSearchBarToggle(
        val showSearchBar: Boolean
    ) : SingleChoiceInputEventData

    class OnAddBatchOptions(
        val rawText: String
    ) : SingleChoiceInputEventData

    class OnMoveOptionUp(
        val actualIdentifier: String
    ) : SingleChoiceInputEventData

    class OnMoveOptionDown(
        val actualIdentifier: String
    ) : SingleChoiceInputEventData
}