package com.catbit.opinionpoll.core.extensions

import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.LocalTime
import kotlinx.datetime.internal.JSJoda.LocalTime as JodaLocalTime

fun LocalTime.isMorning() =
    this in LocalTime(6, 0, 0)..LocalTime(11, 59, 0)

fun LocalTime.isAfternoon() =
    this in LocalTime(12, 0, 0)..LocalTime(17, 59, 0)

fun LocalTime.joda() = JodaLocalTime.of(hour, minute, second, nanosecond)
fun JodaLocalTime.kotlinLocalTime() = LocalTime(
    hour().toInt(),
    minute().toInt(),
    second().toInt(),
    nano().toInt()
)

val LocalTime.Companion.comparator get() = Comparator<LocalTime> { a, b ->
    a.compareTo(b)
}