package com.catbit.opinionpoll.domain.environment

import com.catbit.opinionpoll.core.domain.UseCase
import com.catbit.opinionpoll.data.repositories.OpinionPollRepository

class CreateEnvironmentUseCase(
    private val repository: OpinionPollRepository
) : UseCase<Unit, CreateEnvironmentUseCase.Params>() {

    override suspend fun execute(params: Params) = repository.createEnvironment(
        email = params.email,
        fullName = params.fullName,
        password = params.password,
        phoneNumber = params.phoneNumber,
        companyName = params.companyName,
    )

    data class Params(
        val email: String,
        val fullName: String,
        val password: String,
        val phoneNumber: String,
        val companyName: String,
    )
}