package com.catbit.opinionpoll.inputs.inputs

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.ui.composables.base_components.OutlinedDropdownList
import com.catbit.opinionpoll.core.ui.composables.base_components.Text
import com.catbit.opinionpoll.core.ui.composables.base_components.TextField
import com.catbit.opinionpoll.core.ui.modifiers.bodyLarge
import com.catbit.opinionpoll.core.ui.modifiers.titleMedium
import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.inputs.InputType
import com.catbit.opinionpoll.inputs.InputUI
import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.composables.DefaultFormContainer
import com.catbit.opinionpoll.inputs.composables.DefaultFormPreview
import com.catbit.opinionpoll.inputs.events.InputUIEvent
import com.catbit.opinionpoll.inputs.events.NumericAreaInputEventData
import com.catbit.opinionpoll.res.Strings
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import org.jetbrains.compose.web.css.px

class NumericAreaInput : InputUI<NumericAreaInputUIState> {
    override fun match(uiState: InputUIState) = uiState is NumericAreaInputUIState

    @Composable
    override fun Compose(uiState: NumericAreaInputUIState, onEvent: (InputUIEvent) -> Unit) {

        // TODO Aplicar máscara

        DefaultFormContainer(
            modifier = Modifier.fillMaxWidth(),
            inputUIState = uiState,
            onEvent = onEvent,
            preview = { NumericAreaFormPreview(uiState) }
        ) {
            Column(
                modifier = Modifier
                    .margin(top = 16.px)
                    .fillMaxWidth()
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth()
                ) {
                    Column(
                        modifier = Modifier
                            .fillMaxWidth()
                            .margin(right = 16.px)
                    ) {
                        Text(
                            modifier = Modifier
                                .titleMedium()
                                .fontWeight(FontWeight.Bold),
                            text = Strings.minValue
                        )
                        TextField(
                            modifier = Modifier
                                .margin(top = 8.px)
                                .fillMaxWidth(),
                            text = uiState.minValue.toString(),
                            enabled = uiState.isEnabled,
                            onTextChanged = {
                                val newValue = it.takeIf { it.isNotBlank() } ?: "0"
                                onEvent(
                                    InputUIEvent(
                                        formIdentifier = uiState.actualIdentifier,
                                        data = NumericAreaInputEventData.OnMinValueChange(newValue.toLong())
                                    )
                                )
                            }
                        )
                    }
                    Column(
                        modifier = Modifier.fillMaxWidth()
                    ) {
                        Text(
                            modifier = Modifier
                                .titleMedium()
                                .fontWeight(FontWeight.Bold),
                            text = Strings.maxValue
                        )
                        TextField(
                            modifier = Modifier
                                .margin(top = 8.px)
                                .fillMaxWidth(),
                            text = uiState.maxValue.toString(),
                            enabled = uiState.isEnabled,
                            onTextChanged = {
                                val newValue = it.takeIf { it.isNotBlank() } ?: "0"
                                onEvent(
                                    InputUIEvent(
                                        formIdentifier = uiState.actualIdentifier,
                                        data = NumericAreaInputEventData.OnMaxValueChange(newValue.toLong())
                                    )
                                )
                            }
                        )
                    }
                }
                Row(
                    modifier = Modifier
                        .margin(top = 16.px)
                        .fillMaxWidth()
                ) {
                    Column(
                        modifier = Modifier
                            .fillMaxWidth()
                            .margin(right = 16.px)
                    ) {
                        Text(
                            modifier = Modifier
                                .titleMedium()
                                .fontWeight(FontWeight.Bold),
                            text = Strings.decimalPoints
                        )
                        TextField(
                            modifier = Modifier
                                .margin(top = 8.px)
                                .fillMaxWidth(),
                            text = uiState.decimalPoints.toString(),
                            enabled = uiState.isEnabled,
                            onTextChanged = {
                                val newValue = it.takeIf { it.isNotBlank() } ?: "0"
                                onEvent(
                                    InputUIEvent(
                                        formIdentifier = uiState.actualIdentifier,
                                        data = NumericAreaInputEventData.OnDecimalPointsChange(newValue.toInt())
                                    )
                                )
                            }
                        )
                    }
                    Column(
                        modifier = Modifier.fillMaxWidth()
                    ) {
                        Text(
                            modifier = Modifier
                                .titleMedium()
                                .fontWeight(FontWeight.Bold),
                            text = Strings.symbol
                        )
                        TextField(
                            modifier = Modifier
                                .margin(top = 8.px)
                                .fillMaxWidth(),
                            text = uiState.symbol,
                            enabled = uiState.isEnabled,
                            onTextChanged = {
                                onEvent(
                                    InputUIEvent(
                                        formIdentifier = uiState.actualIdentifier,
                                        data = NumericAreaInputEventData.OnSymbolChange(it)
                                    )
                                )
                            }
                        )
                    }
                }
            }
            Text(
                modifier = Modifier
                    .titleMedium()
                    .fontWeight(FontWeight.Bold)
                    .margin(top = 16.px),
                text = Strings.symbolPosition
            )
            OutlinedDropdownList(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(top = 8.px),
                options = NumericAreaInputUIState.SymbolPosition.entries.map { it.translatedName },
                onOptionsSelected = {
                    onEvent(
                        InputUIEvent(
                            formIdentifier = uiState.actualIdentifier,
                            data = NumericAreaInputEventData.OnSymbolPositionChange(
                                NumericAreaInputUIState.SymbolPosition.fromString(it)
                            )
                        )
                    )
                },
                value = uiState.symbolPosition.translatedName
            )
        }
    }

    @Composable
    private fun NumericAreaFormPreview(uiState: NumericAreaInputUIState) {

        var value by remember { mutableStateOf("") }

        with(uiState) {
            DefaultFormPreview(
                index = index,
                title = title,
                subtitle = subtitle
            ) {
                TextField(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(top = 8.px),
                    enabled = uiState.isEnabled,
                    text = value,
                    onTextChanged = {
                        if (!it.contains("\\D".toRegex())) {
                            value = it
                        }
                    },
                    leadingIcon = uiState.takeIf { symbolPosition == NumericAreaInputUIState.SymbolPosition.Before && uiState.symbol.isNotBlank() }
                        ?.let {
                            @Composable {
                                Text(
                                    modifier = Modifier.bodyLarge(),
                                    text = uiState.symbol
                                )
                            }
                        },
                    trailingIcon = uiState.takeIf { symbolPosition == NumericAreaInputUIState.SymbolPosition.After && uiState.symbol.isNotBlank() }
                        ?.let {
                            @Composable {
                                Text(
                                    modifier = Modifier.bodyLarge(),
                                    text = uiState.symbol
                                )
                            }
                        }
                )
            }
        }
    }
}

data class NumericAreaInputUIState(
    override val index: Int,
    override val isEnabled: Boolean,
    override val actualIdentifier: String,
    override val identifier: String,
    override val title: String,
    override val subtitle: String,
    override val isObligatory: Boolean,
    override val type: InputType,
    override val errorMessage: String?,
    val minValue: Long,
    val maxValue: Long,
    val symbol: String,
    val symbolPosition: SymbolPosition,
    val decimalPoints: Int
) : InputUIState {

    enum class SymbolPosition(
        val translatedName: String
    ) {
        Before("Antes"), After("Depois");

        companion object {
            fun fromString(type: String) =
                entries.firstOrNull { it.name == type || it.translatedName == type } ?: Before
        }
    }

    companion object {
        fun default(
            index: Int
        ) = NumericAreaInputUIState(
            index = index,
            isEnabled = true,
            actualIdentifier = UUID.stringUUID(),
            identifier = "",
            title = "",
            subtitle = "",
            isObligatory = false,
            type = InputType.NumericArea,
            errorMessage = null,
            minValue = 0L,
            maxValue = 0L,
            symbol = "",
            symbolPosition = SymbolPosition.Before,
            decimalPoints = 0
        )
    }

    override val internalIdentifiers = listOf(identifier)
    override fun knowsIdentifier(identifier: String) = identifier == this.identifier
    override fun updateIndex(newIndex: Int) = copy(index = newIndex)
    override fun copy(
        index: Int?,
        isEnabled: Boolean?,
        actualIdentifier: String?,
        identifier: String?,
        title: String?,
        subtitle: String?,
        isObligatory: Boolean?,
        type: InputType?,
        error: String?
    ) = copy(
        index = index ?: this.index,
        isEnabled = isEnabled ?: this.isEnabled,
        actualIdentifier = actualIdentifier ?: this.actualIdentifier,
        identifier = identifier ?: this.identifier,
        title = title ?: this.title,
        subtitle = subtitle ?: this.subtitle,
        isObligatory = isObligatory ?: this.isObligatory,
        type = type ?: this.type,
        errorMessage = error ?: this.errorMessage,
    )
}