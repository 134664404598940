package com.catbit.opinionpoll.core.extensions

import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.internal.JSJoda.LocalDate as JodaLocalDate

fun LocalDate.toBrazilianPattern() = "${if (dayOfMonth < 10) "0" else ""}$dayOfMonth/${if (monthNumber < 10) "0" else ""}$monthNumber/$year"

fun LocalDate.joda() = JodaLocalDate.of(year, monthNumber, dayOfMonth)

fun JodaLocalDate.kotlinLocalDate() = LocalDate(
    year().toInt(),
    monthValue().toInt(),
    dayOfMonth().toInt()
)

val LocalDate.Companion.comparator get() = Comparator<LocalDate> { a, b ->
    a.compareTo(b)
}