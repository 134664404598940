package com.catbit.opinionpoll.pages

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.extensions.changeAlpha
import com.catbit.opinionpoll.core.extensions.getStandaloneStateHolder
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.catbit.opinionpoll.core.sailor.NavigationHost
import com.catbit.opinionpoll.core.sailor.navigation_controller.rememberNavigationController
import com.catbit.opinionpoll.core.sailor.overlays.dialog.DialogState
import com.catbit.opinionpoll.core.sailor.overlays.dialog.rememberDialogState
import com.catbit.opinionpoll.core.sailor.overlays.snackbar.rememberSnackBarState
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.composables.base_components.*
import com.catbit.opinionpoll.core.ui.composables.effects.SingleEffect
import com.catbit.opinionpoll.core.ui.helpers.isMobileMode
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.*
import com.catbit.opinionpoll.res.Strings
import com.catbit.opinionpoll.toggleColorMode
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.flow.collectLatest
import kotlinx.datetime.internal.JSJoda.LocalDateTime
import kotlinx.datetime.internal.JSJoda.ZoneId
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.w3c.dom.HTMLAnchorElement
import org.jetbrains.compose.web.dom.Text as ComposeText

var fullRollout by mutableStateOf(false)
val pressedKeys = mutableStateListOf<String>()

@Page
@Composable
fun Index() {
    val coroutineScope = rememberCoroutineScope()
    val navigationController by rememberNavigationController("indexNavigationController")
    val dialogState = rememberDialogState()
    val snackBarState = rememberSnackBarState()

    NavigationHost(
        modifier = Modifier
            .id("nav-host")
            .fillMaxSize(),
        dialogState = dialogState,
        snackBarState = snackBarState,
        navigationController = navigationController,
        startingRoute = "index"
    ) {
        route("index") {
            Column(
                modifier = Modifier
                    .background(sitePalette().background)
                    .overflow(Overflow.Hidden)
                    .fillMaxSize(),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                val stateHolder = getStandaloneStateHolder<IndexStateHolder>()
                val uiState = stateHolder.uiState.collectAsState().value

                SingleEffect {
                    stateHolder.effects.collectLatest {
                        when (it) {
                            is IndexContracts.Effect.OnRequestFreeTrialFailure -> {
                                snackBarState.show(
                                    message = it.message,
                                    coroutineScope = coroutineScope
                                )
                            }

                            IndexContracts.Effect.OnRequestFreeTrialSuccess -> {
                                window.location.replace("https://opinionpro.com.br/app")
                            }

                            IndexContracts.Effect.OnMessageSentSuccess -> {
                                snackBarState.show(
                                    message = "Mensagem enviada com sucesso",
                                    coroutineScope = coroutineScope
                                )
                            }

                            IndexContracts.Effect.OnMessageSentFailure -> {
                                snackBarState.show(
                                    message = "Houve um erro ao enviar a mensagem, tente novamente em alguns instantes.",
                                    coroutineScope = coroutineScope
                                )
                            }

                            is IndexContracts.Effect.OnInvalidField -> {
                                snackBarState.show(
                                    message = it.message,
                                    coroutineScope = coroutineScope
                                )
                            }
                        }
                    }
                }


                when (uiState) {
                    is IndexContracts.State.Displaying -> {
                        Header(
                            mobileMode = isMobileMode,
                            dialogState = dialogState
                        )
                        Column(
                            modifier = Modifier
                                .fillMaxSize()
                                .overflow {
                                    y(Overflow.Scroll)
                                    x(Overflow.Hidden)
                                }
                        ) {
                            Presentation(isMobileMode)
                            Functionalities(isMobileMode)
                            HowItWorks(isMobileMode)
                            if (fullRollout) {
                                Plans(
                                    onEvent = {
                                        stateHolder.onEvent(it)
                                    }
                                )
                                FreeTrial(
                                    email = uiState.email,
                                    fullName = uiState.fullName,
                                    password = uiState.password,
                                    phoneNumber = uiState.phoneNumber,
                                    companyName = uiState.companyName,
                                    showPassword = uiState.showPassword,
                                    sending = uiState.sending,
                                    onEvent = stateHolder::onEvent
                                )
                            }
                            Apps(isMobileMode)
                            GetInTouch(
                                mobileMode = isMobileMode,
                                uiState = uiState,
                                onEvent = stateHolder::onEvent
                            )
                            Footer(isMobileMode)
                        }
                    }
                }
            }
        }
    }
}

@Composable
private fun Header(
    mobileMode: Boolean,
    dialogState: DialogState
) {
    if (mobileMode) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(horizontal = 24.px, vertical = 12.px),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Row(
                modifier = Modifier
                    .cursor(Cursor.Pointer)
                    .margin(right = 24.px)
                    .userSelect(UserSelect.None)
                    .onClick {
                        navigateInternally("presentation")
                    },
                verticalAlignment = Alignment.CenterVertically
            ) {
                Image(
                    modifier = Modifier.margin(right = 8.px),
                    src = "/opinion_poll.png", width = 50, height = 50
                )
                Text(
                    modifier = Modifier
                        .color(sitePalette().onBackground)
                        .titleLarge()
                        .margin(vertical = 8.px),
                    text = "OpinionPro"
                )
            }

            Spacer(modifier = Modifier.weight(1f))

            Box(
                modifier = Modifier
                    .size(40.px)
                    .clip(Rect(40.px))
                    .hoverBackground(
                        onMouseEnterBackgroundColor = sitePalette().primary.changeAlpha(0.8f),
                        onMouseExitBackgroundColor = sitePalette().primary
                    )
                    .cursor(Cursor.Pointer)
                    .borderRadius(40.px)
                    .onClick {
                        dialogState.show(
                            width = 300.px
                        ) {
                            Column(
                                modifier = Modifier
                                    .display(DisplayStyle.Flex)
                                    .gap(8.px)
                                    .fillMaxWidth()
                            ) {
                                Row(
                                    modifier = Modifier
                                        .fillMaxWidth(),
                                    horizontalArrangement = Arrangement.End
                                ) {
                                    IconButton(
                                        onClick = { dialogState.dismiss() }
                                    ) {
                                        MaterialIcon(MaterialIcons.Round.Close)
                                    }
                                }
                                FilledButton(
                                    modifier = Modifier
                                        .padding(horizontal = 16.px)
                                        .fillMaxWidth(),
                                    text = "Entre em contato",
                                    leadingIcon = null,
                                    onClick = {
                                        openNewTab("https://wa.me/553199788314")
                                    }
                                )
                                FilledButton(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .padding(horizontal = 16.px),
                                    text = "Recursos",
                                    icon = MaterialIcons.Round.SettingsSuggest,
                                    onClick = {
                                        dialogState.dismiss()
                                        navigateInternally("functionalities")
                                    }
                                )
                                FilledButton(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .padding(horizontal = 16.px),
                                    text = "Crie sua campanha",
                                    icon = MaterialIcons.Round.Engineering,
                                    onClick = {
                                        dialogState.dismiss()
                                        navigateInternally("howItWorks")
                                    }
                                )

                                if (fullRollout) {
                                    FilledButton(
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .padding(horizontal = 16.px),
                                        text = "Planos",
                                        icon = MaterialIcons.Round.Login,
                                        onClick = {
                                            dialogState.dismiss()
                                            navigateInternally("plans")
                                        }
                                    )

                                    FilledButton(
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .padding(horizontal = 16.px),
                                        text = "Teste grátis",
                                        icon = MaterialIcons.Round.Login,
                                        onClick = {
                                            dialogState.dismiss()
                                            navigateInternally("tryFreeTrial")
                                        }
                                    )
                                }

                                var colorMode by ColorMode.currentState

                                FilledButton(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .padding(horizontal = 16.px),
                                    text = "Mudar tema",
                                    icon = if (colorMode.isLight)
                                        MaterialIcons.Round.DarkMode
                                    else
                                        MaterialIcons.Round.LightMode,
                                    onClick = {
                                        dialogState.dismiss()
                                        colorMode = colorMode.opposite
                                        toggleColorMode(colorMode)
                                    }
                                )

                                FilledButton(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .padding(horizontal = 16.px),
                                    text = "Login",
                                    icon = MaterialIcons.Round.Login,
                                    onClick = {
                                        dialogState.dismiss()
                                        openNewTab("https://opinionpro.com.br/app")
                                    }
                                )
                            }
                        }
                    },
                contentAlignment = Alignment.Center
            ) {
                MaterialIcon(
                    icon = MaterialIcons.Round.Menu,
                    tint = sitePalette().onPrimary
                )
            }
        }
    } else {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(horizontal = 24.px, vertical = 24.px),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Row(
                modifier = Modifier
                    .cursor(Cursor.Pointer)
                    .margin(right = 24.px)
                    .userSelect(UserSelect.None)
                    .onClick {
                        navigateInternally("presentation")
                    },
                verticalAlignment = Alignment.CenterVertically
            ) {
                Image(
                    modifier = Modifier.margin(right = 8.px),
                    src = "/opinion_poll.png", width = 50, height = 50
                )
                Text(
                    modifier = Modifier
                        .color(sitePalette().onBackground)
                        .titleLarge()
                        .margin(vertical = 8.px),
                    text = "OpinionPro"
                )
            }
            Text(
                modifier = Modifier
                    .userSelect(UserSelect.None)
                    .color(sitePalette().onBackground)
                    .margin(left = 32.px, right = 24.px, top = 4.px)
                    .titleMedium()
                    .cursor(Cursor.Pointer)
                    .onClick {
                        navigateInternally("functionalities")
                    },
                text = "Recursos"
            )
            Text(
                modifier = Modifier
                    .userSelect(UserSelect.None)
                    .color(sitePalette().onBackground)
                    .margin(right = 24.px, top = 4.px)
                    .titleMedium()
                    .cursor(Cursor.Pointer)
                    .onClick {
                        navigateInternally("howItWorks")
                    },
                text = "Crie sua campanha"
            )

            if (fullRollout) {
                Text(
                    modifier = Modifier
                        .userSelect(UserSelect.None)
                        .color(sitePalette().onBackground)
                        .margin(right = 24.px, top = 4.px)
                        .titleMedium()
                        .cursor(Cursor.Pointer)
                        .onClick {
                            navigateInternally("plans")
                        },
                    text = "Planos"
                )

                Text(
                    modifier = Modifier
                        .userSelect(UserSelect.None)
                        .color(sitePalette().onBackground)
                        .margin(right = 24.px, top = 4.px)
                        .titleMedium()
                        .cursor(Cursor.Pointer)
                        .onClick {
                            navigateInternally("tryFreeTrial")
                        },
                    text = "Teste grátis"
                )
            }

            FilledButton(
                modifier = Modifier
                    .padding(horizontal = 16.px)
                    .margin(right = 16.px),
                text = "Entre em contato",
                leadingIcon = null,
                onClick = {
                    openNewTab("https://wa.me/5531999788314")
                }
            )

            Spacer(modifier = Modifier.weight(1f))

            Row {
                FilledButton(
                    modifier = Modifier
                        .margin(right = 16.px)
                        .padding(horizontal = 16.px),
                    text = "Login",
                    icon = MaterialIcons.Round.Login,
                    onClick = {
                        openNewTab("https://opinionpro.com.br/app")
                    }
                )

                var colorMode by ColorMode.currentState

                Box(
                    modifier = Modifier
                        .size(40.px)
                        .clip(Rect(40.px))
                        .hoverBackground(
                            onMouseEnterBackgroundColor = sitePalette().primary.changeAlpha(0.8f),
                            onMouseExitBackgroundColor = sitePalette().primary
                        )
                        .cursor(Cursor.Pointer)
                        .borderRadius(40.px)
                        .onClick {
                            colorMode = colorMode.opposite
                            toggleColorMode(colorMode)
                        },
                    contentAlignment = Alignment.Center
                ) {
                    MaterialIcon(
                        icon = if (colorMode.isLight)
                            MaterialIcons.Round.DarkMode
                        else
                            MaterialIcons.Round.LightMode,
                        tint = sitePalette().onPrimary
                    )
                }
            }
        }
    }
}

@Composable
private fun Presentation(
    mobileMode: Boolean
) {

    if (mobileMode) {
        Column(
            modifier = Modifier
                .id("presentation")
                .padding(horizontal = 24.px)
                .fillMaxWidth(),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Column(
                modifier = Modifier
                    .color(sitePalette().onBackground)
                    .headlineSmall()
                    .fillMaxWidth()
            ) {
                P(
                    attrs = Modifier
                        .margin(0.px)
                        .fillMaxWidth()
                        .toAttrs()
                ) {
                    ComposeText("Potencialize sua ")
                    Span(
                        attrs = Modifier
                            .color(Color.rgb(31, 140, 173))
                            .toAttrs()
                    ) {
                        ComposeText("campanha eleitoral ")
                    }
                    ComposeText("com o OpinionPro, a ")
                    Span(
                        attrs = Modifier
                            .color(Color.rgb(31, 140, 173))
                            .toAttrs()
                    ) {
                        ComposeText("solução ")
                    }
                    ComposeText("definitiva para ")
                    Span(
                        attrs = Modifier
                            .color(Color.rgb(31, 140, 173))
                            .toAttrs()
                    ) {
                        ComposeText("coleta ")
                    }
                    ComposeText("e processamento de dados.")
                }

                P(
                    attrs = Modifier
                        .margin(0.px)
                        .fillMaxWidth()
                        .toAttrs()
                ) {
                    ComposeText("Descubra como analisar ")
                    Span(
                        attrs = Modifier
                            .color(Color.rgb(31, 140, 173))
                            .toAttrs()
                    ) {
                        ComposeText("informações ")
                    }
                    ComposeText("de forma ")
                    Span(
                        attrs = Modifier
                            .color(Color.rgb(31, 140, 173))
                            .toAttrs()
                    ) {
                        ComposeText("estratégica ")
                    }
                    ComposeText("e eficaz.")
                }
            }

            Image(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(top = 16.px),
                src = "/mac.webp"
            )

            Row(
                modifier = Modifier
                    .color(sitePalette().onBackground)
                    .margin(top = 16.px)
                    .titleLarge()
                    .fillMaxWidth(),
            ) {
                Text(
                    modifier = Modifier.margin(right = 8.px),
                    text = "● "
                )
                Text(text = "Coleta offline 100% funcional.")
            }
            Row(
                modifier = Modifier
                    .color(sitePalette().onBackground)
                    .titleLarge()
                    .fillMaxWidth(),
            ) {
                Text(
                    modifier = Modifier.margin(right = 8.px),
                    text = "● "
                )
                Text(text = "Dashboard poderoso para análise dos resultados de coletas.")
            }
            Row(
                modifier = Modifier
                    .color(sitePalette().onBackground)
                    .titleLarge()
                    .fillMaxWidth(),
            ) {
                Text(
                    modifier = Modifier.margin(right = 8.px),
                    text = "● "
                )
                Text(text = "Disponibilidade 24/7")
            }
            Row(
                modifier = Modifier
                    .color(sitePalette().onBackground)
                    .titleLarge()
                    .fillMaxWidth(),
            ) {
                Text(
                    modifier = Modifier.margin(right = 8.px),
                    text = "● "
                )
                Text(text = "Interface amigável de fácil utilização")
            }
        }
    } else {
        Column(
            modifier = Modifier
                .id("presentation")
                .fillMaxWidth(),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Row(
                modifier = Modifier.width(80.percent),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                Column(
                    modifier = Modifier
                        .fillMaxHeight()
                        .width(50.percent),
                    verticalArrangement = Arrangement.Center
                ) {
                    Column(
                        modifier = Modifier
                            .color(sitePalette().onBackground)
                            .headlineSmall()
                            .fillMaxWidth()
                    ) {
                        P(
                            attrs = Modifier
                                .margin(0.px)
                                .fillMaxWidth()
                                .toAttrs()
                        ) {
                            ComposeText("Potencialize sua ")
                            Span(
                                attrs = Modifier
                                    .color(Color.rgb(31, 140, 173))
                                    .toAttrs()
                            ) {
                                ComposeText("campanha eleitoral ")
                            }
                            ComposeText("com o OpinionPro, a ")
                            Span(
                                attrs = Modifier
                                    .color(Color.rgb(31, 140, 173))
                                    .toAttrs()
                            ) {
                                ComposeText("solução ")
                            }
                            ComposeText("definitiva para ")
                            Span(
                                attrs = Modifier
                                    .color(Color.rgb(31, 140, 173))
                                    .toAttrs()
                            ) {
                                ComposeText("coleta ")
                            }
                            ComposeText("e processamento de dados.")
                        }

                        P(
                            attrs = Modifier
                                .margin(0.px)
                                .fillMaxWidth()
                                .toAttrs()
                        ) {
                            ComposeText("Descubra como analisar ")
                            Span(
                                attrs = Modifier
                                    .color(Color.rgb(31, 140, 173))
                                    .toAttrs()
                            ) {
                                ComposeText("informações ")
                            }
                            ComposeText("de forma ")
                            Span(
                                attrs = Modifier
                                    .color(Color.rgb(31, 140, 173))
                                    .toAttrs()
                            ) {
                                ComposeText("estratégica ")
                            }
                            ComposeText("e eficaz.")
                        }
                    }

                    Row(
                        modifier = Modifier
                            .color(sitePalette().onBackground)
                            .margin(top = 16.px, left = 24.px)
                            .titleLarge()
                            .fillMaxWidth(),
                    ) {
                        Text(
                            modifier = Modifier.margin(right = 8.px),
                            text = "●"
                        )
                        Text(text = "Coleta offline 100% funcional.")
                    }

                    Row(
                        modifier = Modifier
                            .color(sitePalette().onBackground)
                            .margin(top = 8.px, left = 24.px)
                            .titleLarge()
                            .fillMaxWidth(),
                    ) {
                        Text(
                            modifier = Modifier.margin(right = 8.px),
                            text = "●"
                        )
                        Text(text = "Dashboard poderoso para análise dos resultados de coletas.")
                    }

                    Row(
                        modifier = Modifier
                            .color(sitePalette().onBackground)
                            .margin(top = 8.px, left = 24.px)
                            .titleLarge()
                            .fillMaxWidth(),
                    ) {
                        Text(
                            modifier = Modifier.margin(right = 8.px),
                            text = "●"
                        )
                        Text(text = "Disponibilidade 24/7")
                    }

                    Row(
                        modifier = Modifier
                            .color(sitePalette().onBackground)
                            .margin(top = 8.px, left = 24.px)
                            .titleLarge()
                            .fillMaxWidth(),
                    ) {
                        Text(
                            modifier = Modifier.margin(right = 8.px),
                            text = "●"
                        )
                        Text(text = "Interface amigável de fácil utilização")
                    }
                }
                Image(
                    modifier = Modifier
                        .width(50.percent)
                        .margin(right = 8.px),
                    src = "/mac.webp"
                )
            }
        }
    }
}

private fun openNewTab(link: String) {
    (document.createElement("a") as HTMLAnchorElement).apply {
        href = link
        target = "_blank"
    }.also {
        document.body?.appendChild(it)
        it.click()
        document.body?.removeChild(it)
    }
}

@Composable
fun Functionalities(
    mobileMode: Boolean
) {
    if (mobileMode) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .display(DisplayStyle.Flex)
                .gap(16.px)
                .margin(top = 48.px)
                .padding(horizontal = 24.px),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {

            Text(
                modifier = Modifier
                    .id("functionalities")
                    .fillMaxWidth()
                    .noWrap()
                    .headlineSmall(),
                text = Strings.functionalities
            )

            Column(
                modifier = Modifier
                    .background(sitePalette().surface)
                    .padding(16.px)
                    .clip(Rect(16.px))
                    .fillMaxWidth()
                    .height(250.px),
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                MaterialIcon(
                    icon = MaterialIcons.Round.WifiOff,
                    size = 80.px,
                    tint = sitePalette().secondary
                )
                Column(
                    modifier = Modifier
                        .margin(top = 16.px)
                        .fillMaxWidth(),
                    horizontalAlignment = Alignment.CenterHorizontally,
                ) {
                    Text(
                        modifier = Modifier
                            .titleLarge()
                            .textAlign(TextAlign.Center),
                        text = "Coleta 100% offline"
                    )
                    Text(
                        modifier = Modifier
                            .bodyMedium()
                            .textAlign(TextAlign.Center),
                        text = "Faça coletas em áreas rurais ou sem cobertura de sinal com segurança"
                    )
                }
            }
            Column(
                modifier = Modifier
                    .background(sitePalette().surface)
                    .padding(16.px)
                    .clip(Rect(16.px))
                    .fillMaxWidth()
                    .height(250.px),
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                MaterialIcon(
                    icon = MaterialIcons.Round.DonutLarge,
                    size = 80.px,
                    tint = sitePalette().secondary
                )
                Column(
                    modifier = Modifier
                        .margin(top = 16.px)
                        .fillMaxWidth(),
                    horizontalAlignment = Alignment.CenterHorizontally,
                ) {
                    Text(
                        modifier = Modifier
                            .titleLarge()
                            .textAlign(TextAlign.Center),
                        text = "Dashboard completo"
                    )
                    Text(
                        modifier = Modifier
                            .bodyMedium()
                            .textAlign(TextAlign.Center),
                        text = "Analise os dados diretamente na plataforma, através do BI, Gráficos, Planilha e Mapa"
                    )
                }
            }

            Column(
                modifier = Modifier
                    .background(sitePalette().surface)
                    .padding(16.px)
                    .clip(Rect(16.px))
                    .fillMaxWidth()
                    .height(250.px),
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                MaterialIcon(
                    icon = MaterialIcons.Round.ListAlt,
                    size = 80.px,
                    tint = sitePalette().secondary
                )
                Column(
                    modifier = Modifier
                        .margin(top = 16.px)
                        .fillMaxWidth(),
                    horizontalAlignment = Alignment.CenterHorizontally,
                ) {
                    Text(
                        modifier = Modifier
                            .titleLarge()
                            .textAlign(TextAlign.Center),
                        text = "Criador de formulários"
                    )
                    Text(
                        modifier = Modifier
                            .bodyMedium()
                            .textAlign(TextAlign.Center),
                        text = "Crie e disponibilize formulários para uma campanha com a nossa Super Ferramenta de criação de formulários"
                    )
                }
            }
            Column(
                modifier = Modifier
                    .background(sitePalette().surface)
                    .padding(16.px)
                    .clip(Rect(16.px))
                    .fillMaxWidth()
                    .height(250.px),
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                MaterialIcon(
                    icon = MaterialIcons.Round.Groups,
                    size = 80.px,
                    tint = sitePalette().secondary
                )
                Column(
                    modifier = Modifier
                        .margin(top = 16.px)
                        .fillMaxWidth(),
                    horizontalAlignment = Alignment.CenterHorizontally,
                ) {
                    Text(
                        modifier = Modifier
                            .titleLarge()
                            .textAlign(TextAlign.Center),
                        text = "Gerenciamento de equipe"
                    )
                    Text(
                        modifier = Modifier
                            .bodyMedium()
                            .textAlign(TextAlign.Center),
                        text = "Gerencie os membros da equipe e defina agentes de coleta de forma fácil"
                    )
                }

            }

            Column(
                modifier = Modifier
                    .background(sitePalette().surface)
                    .padding(16.px)
                    .clip(Rect(16.px))
                    .fillMaxWidth()
                    .height(250.px),
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                MaterialIcon(
                    icon = MaterialIcons.Round.VerifiedUser,
                    size = 80.px,
                    tint = sitePalette().secondary
                )
                Column(
                    modifier = Modifier
                        .margin(top = 16.px)
                        .fillMaxWidth(),
                    horizontalAlignment = Alignment.CenterHorizontally,
                ) {
                    Text(
                        modifier = Modifier
                            .titleLarge()
                            .textAlign(TextAlign.Center),
                        text = "Segurança e privacidade"
                    )
                    Text(
                        modifier = Modifier
                            .bodyMedium()
                            .textAlign(TextAlign.Center),
                        text = "Com o recurso de papéis e níveis de acesso você garante que apenas usuários desejados tenham acesso à pesquisa"
                    )
                }
            }
            Column(
                modifier = Modifier
                    .background(sitePalette().surface)
                    .padding(16.px)
                    .clip(Rect(16.px))
                    .fillMaxWidth()
                    .height(250.px),
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                MaterialIcon(
                    icon = MaterialIcons.Round.ScreenShare,
                    size = 80.px,
                    tint = sitePalette().secondary
                )
                Column(
                    modifier = Modifier
                        .margin(top = 16.px)
                        .fillMaxWidth(),
                    horizontalAlignment = Alignment.CenterHorizontally,
                ) {
                    Text(
                        modifier = Modifier
                            .titleLarge()
                            .textAlign(TextAlign.Center),
                        text = "Exportação de dados"
                    )
                    Text(
                        modifier = Modifier
                            .bodyMedium()
                            .textAlign(TextAlign.Center),
                        text = "Exporte os dados de uma pesquisa nos mais diversos formatos do mercado, como XLSX e CSV"
                    )
                }
            }

            Column(
                modifier = Modifier
                    .background(sitePalette().surface)
                    .padding(16.px)
                    .clip(Rect(16.px))
                    .fillMaxWidth()
                    .height(250.px),
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                MaterialIcon(
                    icon = MaterialIcons.Round.EventAvailable,
                    size = 80.px,
                    tint = sitePalette().secondary
                )
                Column(
                    modifier = Modifier
                        .margin(top = 16.px)
                        .fillMaxWidth(),
                    horizontalAlignment = Alignment.CenterHorizontally,
                ) {
                    Text(
                        modifier = Modifier
                            .titleLarge()
                            .textAlign(TextAlign.Center),
                        text = "Funcionamento 24/7"
                    )
                    Text(
                        modifier = Modifier
                            .bodyMedium()
                            .textAlign(TextAlign.Center),
                        text = "Desfrute da estabilidade através da plataforma Google Cloud, garantindo sempre a disponibilidade do sistema"
                    )
                }
            }
            Column(
                modifier = Modifier
                    .background(sitePalette().surface)
                    .padding(16.px)
                    .clip(Rect(16.px))
                    .fillMaxWidth()
                    .height(250.px),
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                MaterialIcon(
                    icon = MaterialIcons.Round.SupportAgent,
                    size = 80.px,
                    tint = sitePalette().secondary
                )
                Column(
                    modifier = Modifier
                        .margin(top = 16.px)
                        .fillMaxWidth(),
                    horizontalAlignment = Alignment.CenterHorizontally,
                ) {
                    Text(
                        modifier = Modifier
                            .titleLarge()
                            .textAlign(TextAlign.Center),
                        text = "Suporte total"
                    )
                    Text(
                        modifier = Modifier
                            .bodyMedium()
                            .textAlign(TextAlign.Center),
                        text = "Em caso de dúvidas ou problemas, nosso suporte especializado está disponível através do Email ou Whatsapp"
                    )
                }
            }
        }
    } else {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .margin(top = 48.px)
                .padding(bottom = 24.px),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {

            Text(
                modifier = Modifier
                    .id("functionalities")
                    .width(75.percent)
                    .noWrap()
                    .headlineSmall(),
                text = Strings.functionalities
            )

            Column(
                modifier = Modifier
                    .margin(top = 24.px)
                    .display(DisplayStyle.Flex)
                    .gap(16.px)
                    .width(75.percent)
            ) {
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .display(DisplayStyle.Flex)
                        .gap(16.px)
                ) {
                    Column(
                        modifier = Modifier
                            .background(sitePalette().surface)
                            .padding(16.px)
                            .clip(Rect(16.px))
                            .width(25.percent)
                            .height(300.px),
                        horizontalAlignment = Alignment.CenterHorizontally,
                    ) {
                        MaterialIcon(
                            icon = MaterialIcons.Round.WifiOff,
                            size = 80.px,
                            tint = sitePalette().secondary
                        )
                        Column(
                            modifier = Modifier
                                .margin(top = 16.px)
                                .fillMaxWidth(),
                            horizontalAlignment = Alignment.CenterHorizontally,
                        ) {
                            Text(
                                modifier = Modifier
                                    .titleLarge()
                                    .textAlign(TextAlign.Center),
                                text = "Coleta 100% offline"
                            )
                            Text(
                                modifier = Modifier
                                    .bodyMedium()
                                    .textAlign(TextAlign.Center),
                                text = "Faça coletas em áreas rurais ou sem cobertura de sinal com segurança"
                            )
                        }
                    }
                    Column(
                        modifier = Modifier
                            .background(sitePalette().surface)
                            .padding(16.px)
                            .clip(Rect(16.px))
                            .width(25.percent)
                            .height(300.px),
                        horizontalAlignment = Alignment.CenterHorizontally,
                    ) {
                        MaterialIcon(
                            icon = MaterialIcons.Round.DonutLarge,
                            size = 80.px,
                            tint = sitePalette().secondary
                        )
                        Column(
                            modifier = Modifier
                                .margin(top = 16.px)
                                .fillMaxWidth(),
                            horizontalAlignment = Alignment.CenterHorizontally,
                        ) {
                            Text(
                                modifier = Modifier
                                    .titleLarge()
                                    .textAlign(TextAlign.Center),
                                text = "Dashboard completo"
                            )
                            Text(
                                modifier = Modifier
                                    .bodyMedium()
                                    .textAlign(TextAlign.Center),
                                text = "Analise os dados diretamente na plataforma, através do BI, Gráficos, Planilha e Mapa"
                            )
                        }
                    }
                    Column(
                        modifier = Modifier
                            .background(sitePalette().surface)
                            .padding(16.px)
                            .clip(Rect(16.px))
                            .width(25.percent)
                            .height(300.px),
                        horizontalAlignment = Alignment.CenterHorizontally,
                    ) {
                        MaterialIcon(
                            icon = MaterialIcons.Round.ListAlt,
                            size = 80.px,
                            tint = sitePalette().secondary
                        )
                        Column(
                            modifier = Modifier
                                .margin(top = 16.px)
                                .fillMaxWidth(),
                            horizontalAlignment = Alignment.CenterHorizontally,
                        ) {
                            Text(
                                modifier = Modifier
                                    .titleLarge()
                                    .textAlign(TextAlign.Center),
                                text = "Criador de formulários"
                            )
                            Text(
                                modifier = Modifier
                                    .bodyMedium()
                                    .textAlign(TextAlign.Center),
                                text = "Crie e disponibilize formulários para uma campanha com a nossa Super Ferramenta de criação de formulários"
                            )
                        }
                    }
                    Column(
                        modifier = Modifier
                            .background(sitePalette().surface)
                            .padding(16.px)
                            .clip(Rect(16.px))
                            .width(25.percent)
                            .height(300.px),
                        horizontalAlignment = Alignment.CenterHorizontally,
                    ) {
                        MaterialIcon(
                            icon = MaterialIcons.Round.Groups,
                            size = 80.px,
                            tint = sitePalette().secondary
                        )
                        Column(
                            modifier = Modifier
                                .margin(top = 16.px)
                                .fillMaxWidth(),
                            horizontalAlignment = Alignment.CenterHorizontally,
                        ) {
                            Text(
                                modifier = Modifier
                                    .titleLarge()
                                    .textAlign(TextAlign.Center),
                                text = "Gerenciamento de equipe"
                            )
                            Text(
                                modifier = Modifier
                                    .bodyMedium()
                                    .textAlign(TextAlign.Center),
                                text = "Gerencie os membros da equipe e defina agentes de coleta de forma fácil"
                            )
                        }
                    }
                }

                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .display(DisplayStyle.Flex)
                        .gap(16.px)
                ) {
                    Column(
                        modifier = Modifier
                            .background(sitePalette().surface)
                            .padding(16.px)
                            .clip(Rect(16.px))
                            .width(25.percent)
                            .height(300.px),
                        horizontalAlignment = Alignment.CenterHorizontally,
                    ) {
                        MaterialIcon(
                            icon = MaterialIcons.Round.VerifiedUser,
                            size = 80.px,
                            tint = sitePalette().secondary
                        )
                        Column(
                            modifier = Modifier
                                .margin(top = 16.px)
                                .fillMaxWidth(),
                            horizontalAlignment = Alignment.CenterHorizontally,
                        ) {
                            Text(
                                modifier = Modifier
                                    .titleLarge()
                                    .textAlign(TextAlign.Center),
                                text = "Segurança e privacidade"
                            )
                            Text(
                                modifier = Modifier
                                    .bodyMedium()
                                    .textAlign(TextAlign.Center),
                                text = "Com o recurso de papéis e níveis de acesso você garante que apenas usuários desejados tenham acesso à pesquisa"
                            )
                        }
                    }
                    Column(
                        modifier = Modifier
                            .background(sitePalette().surface)
                            .padding(16.px)
                            .clip(Rect(16.px))
                            .width(25.percent)
                            .height(300.px),
                        horizontalAlignment = Alignment.CenterHorizontally,
                    ) {
                        MaterialIcon(
                            icon = MaterialIcons.Round.ScreenShare,
                            size = 80.px,
                            tint = sitePalette().secondary
                        )
                        Column(
                            modifier = Modifier
                                .margin(top = 16.px)
                                .fillMaxWidth(),
                            horizontalAlignment = Alignment.CenterHorizontally,
                        ) {
                            Text(
                                modifier = Modifier
                                    .titleLarge()
                                    .textAlign(TextAlign.Center),
                                text = "Exportação de dados"
                            )
                            Text(
                                modifier = Modifier
                                    .bodyMedium()
                                    .textAlign(TextAlign.Center),
                                text = "Exporte os dados de uma pesquisa nos mais diversos formatos do mercado, como XLSX e CSV"
                            )
                        }
                    }
                    Column(
                        modifier = Modifier
                            .background(sitePalette().surface)
                            .padding(16.px)
                            .clip(Rect(16.px))
                            .width(25.percent)
                            .height(300.px),
                        horizontalAlignment = Alignment.CenterHorizontally,
                    ) {
                        MaterialIcon(
                            icon = MaterialIcons.Round.EventAvailable,
                            size = 80.px,
                            tint = sitePalette().secondary
                        )
                        Column(
                            modifier = Modifier
                                .margin(top = 16.px)
                                .fillMaxWidth(),
                            horizontalAlignment = Alignment.CenterHorizontally,
                        ) {
                            Text(
                                modifier = Modifier
                                    .titleLarge()
                                    .textAlign(TextAlign.Center),
                                text = "Funcionamento 24/7"
                            )
                            Text(
                                modifier = Modifier
                                    .bodyMedium()
                                    .textAlign(TextAlign.Center),
                                text = "Desfrute da estabilidade através da plataforma Google Cloud, garantindo sempre a disponibilidade do sistema"
                            )
                        }
                    }
                    Column(
                        modifier = Modifier
                            .background(sitePalette().surface)
                            .padding(16.px)
                            .clip(Rect(16.px))
                            .width(25.percent)
                            .height(300.px),
                        horizontalAlignment = Alignment.CenterHorizontally,
                    ) {
                        MaterialIcon(
                            icon = MaterialIcons.Round.SupportAgent,
                            size = 80.px,
                            tint = sitePalette().secondary
                        )
                        Column(
                            modifier = Modifier
                                .margin(top = 16.px)
                                .fillMaxWidth(),
                            horizontalAlignment = Alignment.CenterHorizontally,
                        ) {
                            Text(
                                modifier = Modifier
                                    .titleLarge()
                                    .textAlign(TextAlign.Center),
                                text = "Suporte total"
                            )
                            Text(
                                modifier = Modifier
                                    .bodyMedium()
                                    .textAlign(TextAlign.Center),
                                text = "Em caso de dúvidas ou problemas, nosso suporte especializado está disponível através do Email ou Whatsapp"
                            )
                        }
                    }
                }
            }
        }
    }
}

@Composable
fun HowItWorks(
    mobileMode: Boolean
) {
    if (mobileMode) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .margin(top = 48.px)
                .padding(horizontal = 24.px),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            Text(
                modifier = Modifier
                    .id("howItWorks")
                    .fillMaxWidth()
                    .noWrap()
                    .headlineSmall(),
                text = Strings.howItWorks
            )

            Column(
                modifier = Modifier
                    .margin(top = 24.px)
                    .background(sitePalette().surface)
                    .padding(16.px)
                    .clip(Rect(16.px))
                    .display(DisplayStyle.Flex)
                    .gap(24.px)
                    .fillMaxWidth()
            ) {
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .display(DisplayStyle.Flex)
                        .gap(16.px)
                ) {

                    Text(
                        modifier = Modifier.displayMedium(),
                        text = "1."
                    )
                    Text(
                        modifier = Modifier.displaySmall(),
                        text = "Crie um formulário",
                    )

                    Image(
                        modifier = Modifier.fillMaxWidth(),
                        src = "/form_maker.webp"
                    )

                    Text(
                        modifier = Modifier.titleMedium(),
                        text = "A Super Ferramenta de criação de formulários disponibiliza diversos tipos de campos como múltipla escolha, área de texto, texto com formatação, dentre outros.",
                    )
                    Text(
                        modifier = Modifier.titleMedium(),
                        text = "Conforme um campo é adicionado, é possível testá-lo no menu de visualização incorporado ao layout do sistema.",
                    )
                    Text(
                        modifier = Modifier.titleMedium(),
                        text = "Escolha os campos para a sua pesquisa, configure-os para a sua necessidade e ao final salve o formulário.",
                    )
                }

                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .display(DisplayStyle.Flex)
                        .gap(16.px)
                ) {
                    Text(
                        modifier = Modifier.displayMedium(),
                        text = "2."
                    )
                    Text(
                        modifier = Modifier.displaySmall(),
                        text = "Vincule membros da equipe ao formulário",
                    )

                    Image(
                        modifier = Modifier
                            .clip(Rect(16.px))
                            .fillMaxWidth(),
                        src = "/link_users.webp"
                    )

                    Text(
                        modifier = Modifier.titleMedium(),
                        text = "Após a criação de um formulário, é necessário vinculá-lo aos agentes de campo.",
                    )
                    Text(
                        modifier = Modifier.titleMedium(),
                        text = "Ao ser vinculado à um formulário, o membro da equipe passa a ter acesso ao formulário através do app.",
                    )
                    Text(
                        modifier = Modifier.titleMedium(),
                        text = "A etapa de vinculação de membros e formulário é extremamente segura, pois só membros vinculados terão acesso ao formulário.",
                    )
                }

                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .display(DisplayStyle.Flex)
                        .gap(16.px)
                ) {

                    Text(
                        modifier = Modifier.displayMedium(),
                        text = "3."
                    )
                    Text(
                        modifier = Modifier.displaySmall(),
                        text = "Colete os dados",
                    )

                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalArrangement = Arrangement.Center
                    ) {
                        Image(
                            modifier = Modifier
                                .clip(Rect(16.px))
                                .margin(right = 24.px)
                                .height(250.px),
                            src = "/phone_home_screen.webp"
                        )
                        Image(
                            modifier = Modifier
                                .clip(Rect(16.px))
                                .height(250.px),
                            src = "/phone_form_screen.webp"
                        )
                    }

                    Text(
                        modifier = Modifier.titleMedium(),
                        text = "Ao abrir o app, os membros da equipe vinculados ao formulário conseguirão acessá-lo pelo menu principal.",
                    )
                    Text(
                        modifier = Modifier.titleMedium(),
                        text = "O processo de coleta é fácil e intuitivo. Ao final de uma coleta, o agente deverá clicar em enviar para que as respostas sejam recebidas pela plataforma.",
                    )
                    Text(
                        modifier = Modifier.titleMedium(),
                        text = "O agente de campo poderá ativar o modo offline caso deseje, podendo enviar a coleta ao final do dia.",
                    )
                }

                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .display(DisplayStyle.Flex)
                        .gap(16.px)
                ) {

                    Text(
                        modifier = Modifier.displayMedium(),
                        text = "4."
                    )
                    Text(
                        modifier = Modifier.displaySmall(),
                        text = "Faça a análise dos dados",
                    )

                    Image(
                        modifier = Modifier
                            .clip(Rect(16.px))
                            .fillMaxWidth(),
                        src = "/dashboard.webp"
                    )

                    Text(
                        modifier = Modifier.titleMedium(),
                        text = "Conforme os dados forem sendo recebidos, é possível acessá-los através do poderoso Dashboard, que oferece uma gama de ferramentas para a análise dos dados, como Gráficos, Tabela, Mapa e nosso B.I. integrado.",
                    )
                    Text(
                        modifier = Modifier.titleMedium(),
                        text = "Caso deseje, também é possível exportar os dados para outros formatos, como XLSX, CSV ou PDF.",
                    )
                }
            }
        }
    } else {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .margin(top = 48.px)
                .padding(bottom = 24.px),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {

            Text(
                modifier = Modifier
                    .id("howItWorks")
                    .width(75.percent)
                    .noWrap()
                    .headlineSmall(),
                text = Strings.howItWorks
            )

            Column(
                modifier = Modifier
                    .margin(top = 24.px)
                    .background(sitePalette().surface)
                    .padding(16.px)
                    .clip(Rect(16.px))
                    .display(DisplayStyle.Flex)
                    .gap(24.px)
                    .width(75.percent)
            ) {
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .display(DisplayStyle.Flex)
                        .gap(16.px)
                ) {
                    Column(
                        modifier = Modifier
                            .width(30.percent)
                    ) {
                        Text(
                            modifier = Modifier.displayMedium(),
                            text = "1."
                        )
                        Text(
                            modifier = Modifier.displaySmall(),
                            text = "Crie um formulário",
                        )
                        Text(
                            modifier = Modifier
                                .margin(top = 16.px)
                                .titleMedium(),
                            text = "A Super Ferramenta de criação de formulários disponibiliza diversos tipos de campos como múltipla escolha, área de texto, texto com formatação, dentre outros.",
                        )
                        Text(
                            modifier = Modifier
                                .margin(top = 16.px)
                                .titleMedium(),
                            text = "Conforme um campo é adicionado, é possível testá-lo no menu de visualização incorporado ao layout do sistema.",
                        )
                        Text(
                            modifier = Modifier
                                .margin(top = 16.px)
                                .titleMedium(),
                            text = "Escolha os campos para a sua pesquisa, configure-os para a sua necessidade e ao final salve o formulário.",
                        )
                    }
                    Image(
                        modifier = Modifier
                            .clip(Rect(16.px))
                            .width(70.percent),
                        src = "/form_maker.webp"
                    )
                }

                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .display(DisplayStyle.Flex)
                        .gap(16.px)
                ) {
                    Image(
                        modifier = Modifier
                            .clip(Rect(16.px))
                            .width(70.percent),
                        src = "/link_users.webp"
                    )
                    Column(
                        modifier = Modifier
                            .width(30.percent)
                    ) {
                        Text(
                            modifier = Modifier.displayMedium(),
                            text = "2."
                        )
                        Text(
                            modifier = Modifier.displaySmall(),
                            text = "Vincule membros da equipe ao formulário",
                        )
                        Text(
                            modifier = Modifier
                                .margin(top = 16.px)
                                .titleMedium(),
                            text = "Após a criação de um formulário, é necessário vinculá-lo aos agentes de campo.",
                        )
                        Text(
                            modifier = Modifier
                                .margin(top = 16.px)
                                .titleMedium(),
                            text = "Estando vinculado, o membro da equipe passa a ter acesso ao formulário através do app.",
                        )
                        Text(
                            modifier = Modifier
                                .margin(top = 16.px)
                                .titleMedium(),
                            text = "A etapa de vinculação de membros e formulário é extremamente segura, pois só membros vinculados terão acesso ao formulário.",
                        )
                    }
                }

                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .display(DisplayStyle.Flex)
                        .gap(16.px)
                ) {
                    Column(
                        modifier = Modifier.fillMaxWidth()
                    ) {
                        Text(
                            modifier = Modifier.displayMedium(),
                            text = "3."
                        )
                        Text(
                            modifier = Modifier.displaySmall(),
                            text = "Colete os dados",
                        )
                        Text(
                            modifier = Modifier
                                .margin(top = 16.px)
                                .titleMedium(),
                            text = "Ao abrir o app, os membros da equipe vinculados ao formulário conseguirão acessá-lo pelo menu principal.",
                        )
                        Text(
                            modifier = Modifier
                                .margin(top = 16.px)
                                .titleMedium(),
                            text = "O processo de coleta é fácil e intuitivo. Ao final de uma coleta, o agente deverá clicar em enviar para que as respostas sejam recebidas pela plataforma.",
                        )
                        Text(
                            modifier = Modifier
                                .margin(top = 16.px)
                                .titleMedium(),
                            text = "O agente de campo poderá ativar o modo offline caso deseje, podendo enviar a coleta ao final do dia.",
                        )
                    }
                    Row {
                        Image(
                            modifier = Modifier
                                .clip(Rect(16.px))
                                .margin(right = 24.px)
                                .height(500.px),
                            src = "/phone_home_screen.webp"
                        )
                        Image(
                            modifier = Modifier
                                .clip(Rect(16.px))
                                .height(500.px),
                            src = "/phone_form_screen.webp"
                        )
                    }
                }

                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .display(DisplayStyle.Flex)
                        .gap(16.px)
                ) {
                    Image(
                        modifier = Modifier
                            .clip(Rect(16.px))
                            .width(70.percent),
                        src = "/dashboard.webp"
                    )
                    Column(
                        modifier = Modifier
                            .width(30.percent)
                    ) {
                        Text(
                            modifier = Modifier.displayMedium(),
                            text = "4."
                        )
                        Text(
                            modifier = Modifier.displaySmall(),
                            text = "Faça a análise dos dados",
                        )
                        Text(
                            modifier = Modifier
                                .margin(top = 16.px)
                                .titleMedium(),
                            text = "Conforme os dados forem sendo recebidos, é possível acessá-los através do poderoso Dashboard, que oferece uma gama de ferramentas para a análise dos dados, como Gráficos, Tabela, Mapa e nosso B.I. integrado.",
                        )
                        Text(
                            modifier = Modifier
                                .margin(top = 16.px)
                                .titleMedium(),
                            text = "Caso deseje, também é possível exportar os dados para outros formatos, como XLSX ou CSV.",
                        )
                    }
                }
            }
        }
    }
}

@Composable
fun Plans(
    onEvent: (IndexContracts.Event) -> Unit
) {
    Column(
        modifier = Modifier
            .id("plans")
            .fillMaxWidth()
            .margin(top = 48.px)
            .padding(bottom = 24.px),
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {

        Text(
            modifier = Modifier
                .width(75.percent)
                .noWrap()
                .headlineSmall(),
            text = Strings.plans
        )

        Column(
            modifier = Modifier
                .margin(top = 24.px)
                .width(75.percent)
        ) {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .borderRadius(topRight = 16.px, topLeft = 16.px)
            ) {
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .borderRadius(topLeft = 16.px)
                        .background(sitePalette().secondary)
                        .color(sitePalette().onSecondary)
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "Plano"
                )
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .background(sitePalette().secondary.changeAlpha(0.8f))
                        .color(sitePalette().onSecondary)
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "Teste grátis"
                )
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .background(sitePalette().secondary.changeAlpha(0.8f))
                        .color(sitePalette().onSecondary)
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "Basic"
                )
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .background(sitePalette().secondary.changeAlpha(0.8f))
                        .color(sitePalette().onSecondary)
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "Intermediate"
                )
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .background(sitePalette().secondary.changeAlpha(0.8f))
                        .color(sitePalette().onSecondary)
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .borderRadius(topRight = 16.px)
                        .width(20.percent),
                    text = "Pro"
                )
            }
            Row(
                modifier = Modifier
                    .fillMaxWidth()
            ) {
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .background(sitePalette().secondary)
                        .color(sitePalette().onSecondary)
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "Membros"
                )
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "3"
                )
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "2"
                )
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "5"
                )
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "Ilimitado"
                )
            }
            Row(
                modifier = Modifier
                    .fillMaxWidth()
            ) {
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .background(sitePalette().secondary)
                        .color(sitePalette().onSecondary)
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "Formulários"
                )
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "2"
                )
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "4"
                )
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "10"
                )
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "Ilimitado"
                )
            }
            Row(
                modifier = Modifier
                    .fillMaxWidth()
            ) {
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .background(sitePalette().secondary)
                        .color(sitePalette().onSecondary)
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "Coletas por formulário"
                )
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "200"
                )
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "300"
                )
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "500"
                )
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "Ilimitado"
                )
            }
            Row(
                modifier = Modifier
                    .fillMaxWidth()
            ) {
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .background(sitePalette().secondary)
                        .color(sitePalette().onSecondary)
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "Suporte"
                )
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "Email"
                )
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "Email"
                )
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "Email"
                )
                Text(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent),
                    text = "Email e Whatsapp"
                )
            }
            Row(
                modifier = Modifier
                    .fillMaxWidth()
            ) {
                Box(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .borderRadius(bottomLeft = 16.px)
                        .width(20.percent)
                ) {
                    Box(modifier = Modifier.height(40.px))
                }
                Box(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .width(20.percent)
                ) {
                    FilledButton(
                        modifier = Modifier.fillMaxWidth(),
                        text = "Testar grátis!",
                        onClick = {
                            navigateInternally("tryFreeTrial")
                        },
                        icon = null
                    )
                }
                Box(
                    modifier = Modifier
                        .border(
                            color = sitePalette().onBackground,
                            style = LineStyle.Solid,
                            width = 1.px
                        )
                        .padding(horizontal = 16.px, vertical = 8.px)
                        .borderRadius(bottomRight = 16.px)
                        .width(60.percent)
                ) {
                    FilledButton(
                        modifier = Modifier.fillMaxWidth(),
                        text = "Entre em contato",
                        onClick = {
                            openNewTab("https://wa.me/553199788314")
                        },
                        icon = null
                    )
                }
            }
        }
    }
}

private fun navigateInternally(
    where: String
) {
    (document.createElement("a") as HTMLAnchorElement).apply {
        href = "#$where"
    }.also {
        document.body?.appendChild(it)
        it.click()
        document.body?.removeChild(it)
    }
}

@Composable
fun FreeTrial(
    email: String,
    fullName: String,
    password: String,
    phoneNumber: String,
    companyName: String,
    showPassword: Boolean,
    sending: Boolean,
    onEvent: (IndexContracts.Event) -> Unit
) {
    Column(
        modifier = Modifier
            .id("tryFreeTrial")
            .fillMaxWidth()
            .margin(top = 48.px)
            .padding(bottom = 24.px),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {

        Text(
            modifier = Modifier
                .width(75.percent)
                .noWrap()
                .headlineSmall(),
            text = Strings.tryForFree
        )

        Column(
            modifier = Modifier
                .background(sitePalette().surface)
                .margin(top = 24.px)
                .padding(16.px)
                .clip(Rect(16.px))
                .width(75.percent),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Text(
                modifier = Modifier
                    .fillMaxWidth()
                    .noWrap()
                    .titleMedium()
                    .fontWeight(FontWeight.Bold),
                text = Strings.name
            )
            TextField(
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                text = fullName,
                onTextChanged = {
                    onEvent(
                        IndexContracts.Event.OnFullNameChange(it)
                    )
                },
                trailingIcon = {
                    MaterialIcon(MaterialIcons.Round.Person)
                }
            )
            Text(
                modifier = Modifier
                    .margin(top = 16.px)
                    .fillMaxWidth()
                    .noWrap()
                    .titleMedium()
                    .fontWeight(FontWeight.Bold),
                text = Strings.password
            )
            TextField(
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                text = password,
                isPassword = !showPassword,
                onTextChanged = { onEvent(IndexContracts.Event.OnPasswordChange(it)) },
                enabled = sending.not(),
                trailingIcon = {
                    if (!showPassword) {
                        MaterialIcon(
                            modifier = Modifier
                                .cursor(Cursor.Pointer)
                                .onClick {
                                    onEvent(IndexContracts.Event.OnTogglePasswordVisibility)
                                },
                            icon = MaterialIcons.Round.Visibility
                        )
                    } else {
                        MaterialIcon(
                            modifier = Modifier
                                .cursor(Cursor.Pointer)
                                .onClick {
                                    onEvent(IndexContracts.Event.OnTogglePasswordVisibility)
                                },
                            icon = MaterialIcons.Round.VisibilityOff
                        )
                    }
                }
            )
            Text(
                modifier = Modifier
                    .margin(top = 16.px)
                    .fillMaxWidth()
                    .noWrap()
                    .titleMedium()
                    .fontWeight(FontWeight.Bold),
                text = Strings.email
            )
            TextField(
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                text = email,
                onTextChanged = {
                    onEvent(
                        IndexContracts.Event.OnEmailChange(it)
                    )
                },
                trailingIcon = {
                    MaterialIcon(MaterialIcons.Round.Mail)
                }
            )
            Text(
                modifier = Modifier
                    .margin(top = 16.px)
                    .fillMaxWidth()
                    .noWrap()
                    .titleMedium()
                    .fontWeight(FontWeight.Bold),
                text = Strings.phoneNumber
            )
            TextField(
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                text = phoneNumber,
                onTextChanged = {},
                onKeyDown = {
                    onEvent(
                        IndexContracts.Event.OnPhoneNumberChange(it)
                    )
                },
                trailingIcon = {
                    MaterialIcon(MaterialIcons.Round.Phone)
                }
            )
            Text(
                modifier = Modifier
                    .margin(top = 16.px)
                    .fillMaxWidth()
                    .noWrap()
                    .titleMedium()
                    .fontWeight(FontWeight.Bold),
                text = Strings.companyName
            )
            TextField(
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                text = companyName,
                onTextChanged = {
                    onEvent(
                        IndexContracts.Event.OnCompanyNameChange(it)
                    )
                },
                trailingIcon = {
                    MaterialIcon(MaterialIcons.Round.People)
                }
            )
            FilledButton(
                modifier = Modifier
                    .margin(top = 24.px)
                    .width(50.percent),
                text = "Testar grátis!",
                onClick = {
                    onEvent(
                        IndexContracts.Event.OnTryFreeTrialClick
                    )
                },
                enabled = !sending,
                loading = sending,
                icon = null
            )
        }
    }
}

@Composable
fun GetInTouch(
    mobileMode: Boolean,
    uiState: IndexContracts.State.Displaying,
    onEvent: (IndexContracts.Event) -> Unit
) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .margin(top = 48.px)
            .padding(horizontal = if (mobileMode) 24.px else 0.px),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {

        Text(
            modifier = Modifier
                .id("getInTouch")
                .width(if (mobileMode) 100.percent else 75.percent)
                .noWrap()
                .headlineSmall(),
            text = "Suporte"
        )

        Column(
            modifier = Modifier
                .width(if (mobileMode) 100.percent else 75.percent)
                .background(sitePalette().surface)
                .padding(16.px)
                .clip(Rect(16.px))
                .margin(top = 24.px)
                .display(DisplayStyle.Flex)
                .gap(16.px),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
            ) {
                Text(
                    modifier = Modifier
                        .titleMedium(),
                    text = "Tópico"
                )
                OutlinedDropdownList(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(top = 8.px),
                    value = uiState.selectedTopic,
                    options = uiState.topics,
                    onOptionsSelected = {
                        onEvent(IndexContracts.Event.OnSelectTopic(it))
                    }
                )
                Text(
                    modifier = Modifier
                        .titleMedium()
                        .margin(top = 16.px),
                    text = "Nome"
                )
                TextField(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(top = 8.px),
                    text = uiState.supportName,
                    onTextChanged = {
                        onEvent(IndexContracts.Event.OnSupportNameChange(it))
                    }
                )
                Text(
                    modifier = Modifier
                        .titleMedium()
                        .margin(top = 16.px),
                    text = "Email"
                )
                TextField(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(top = 8.px),
                    text = uiState.supportEmail,
                    onTextChanged = {
                        onEvent(IndexContracts.Event.OnSupportEmailChange(it))
                    }
                )
                Text(
                    modifier = Modifier
                        .titleMedium()
                        .margin(top = 16.px),
                    text = "Assunto"
                )
                TextField(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(top = 8.px),
                    text = uiState.subject,
                    onTextChanged = {
                        onEvent(IndexContracts.Event.OnSubjectChange(it))
                    }
                )
                Row(
                    modifier = Modifier
                        .margin(top = 8.px)
                        .fillMaxWidth(),
                    horizontalArrangement = Arrangement.End
                ) {
                    Text(
                        modifier = Modifier.titleSmall(),
                        text = uiState.subjectLengthCounter
                    )
                }
                Text(
                    modifier = Modifier
                        .margin(top = 16.px)
                        .titleMedium(),
                    text = "Mensagem"
                )
                TextArea(
                    modifier = Modifier
                        .margin(top = 8.px)
                        .fillMaxWidth(),
                    lines = 5,
                    text = uiState.message,
                    onTextChanged = {
                        onEvent(IndexContracts.Event.OnMessageChange(it))
                    }
                )
                Row(
                    modifier = Modifier
                        .margin(top = 8.px)
                        .fillMaxWidth(),
                    horizontalArrangement = Arrangement.End
                ) {
                    Text(
                        modifier = Modifier.titleSmall(),
                        text = uiState.messageLengthCounter
                    )
                }
            }
            FilledButton(
                modifier = Modifier
                    .alignSelf(com.varabyte.kobweb.compose.css.AlignSelf.End)
                    .fillMaxWidth(),
                text = "Enviar",
                icon = null,
                loading = uiState.isSubmitting,
                enabled = !uiState.isSubmitting,
                onClick = {
                    onEvent(IndexContracts.Event.OnSubmitClick)
                }
            )
        }
    }
}

@Composable
fun Apps(
    mobileMode: Boolean
) {
    if (mobileMode) {
        Column(
            modifier = Modifier
                .id("footer")
                .fillMaxWidth()
                .margin(top = 48.px)
                .padding(horizontal = 24.px),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .background(sitePalette().surface)
                    .padding(16.px)
                    .clip(Rect(16.px))
                    .display(DisplayStyle.Flex)
                    .gap(16.px),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Column(
                    modifier = Modifier
                        .cursor(Cursor.Pointer)
                        .onClick {
                            openNewTab("https://play.google.com/store/apps/details?id=com.catbit.opinionpoll")
                        },
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Text("Baixe agora para Android!")
                    Image(
                        src = "/google_play.png",
                        height = 100
                    )
                }
                Column(
                    modifier = Modifier
                        .cursor(Cursor.Pointer)
                        .onClick {
                            openNewTab("https://apps.apple.com/br/app/opinionpro/id6499178486")
                        },
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Text("Baixe agora para iOS!")
                    Image(
                        src = "/app_store.png",
                        height = 100
                    )
                }
            }
        }
    } else {
        Column(
            modifier = Modifier
                .id("footer")
                .fillMaxWidth()
                .margin(top = 48.px)
                .padding(bottom = 24.px),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Column(
                modifier = Modifier.width(75.percent),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .background(sitePalette().surface)
                        .padding(16.px)
                        .clip(Rect(16.px))
                        .display(DisplayStyle.Flex)
                        .gap(16.px),
                    horizontalArrangement = Arrangement.Center
                ) {
                    Column(
                        modifier = Modifier
                            .cursor(Cursor.Pointer)
                            .onClick {
                                openNewTab("https://play.google.com/store/apps/details?id=com.catbit.opinionpoll")
                            },
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {
                        Text("Baixe agora para Android!")
                        Image(
                            src = "/google_play.png",
                            height = 100
                        )
                    }
                    Column(
                        modifier = Modifier
                            .cursor(Cursor.Pointer)
                            .onClick {
                                openNewTab("https://apps.apple.com/br/app/opinionpro/id6499178486")
                            },
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {
                        Text("Baixe agora para iOS!")
                        Image(
                            src = "/app_store.png",
                            height = 100
                        )
                    }
                }
            }
        }
    }
}

@Composable
fun Footer(
    mobileMode: Boolean
) {
    if (mobileMode) {
        Column(
            modifier = Modifier
                .id("footer")
                .fillMaxWidth()
                .margin(top = 48.px, bottom = 24.px)
                .padding(horizontal = 24.px, vertical = 24.px),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            val currentDate = remember { LocalDateTime.now(ZoneId.SYSTEM) }

            Column(
                modifier = Modifier.fillMaxWidth(),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Text("OpinionPro - ${currentDate.year()}.")
                Text("© Todos os direitos reservados.")
            }
        }
    } else {
        Column(
            modifier = Modifier
                .id("footer")
                .fillMaxWidth()
                .margin(top = 48.px, bottom = 24.px)
                .padding(bottom = 24.px),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            val currentDate = remember { LocalDateTime.now(ZoneId.SYSTEM) }

            Column(
                modifier = Modifier.width(75.percent),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Text(
                    modifier = Modifier.onDoubleClick {
                        fullRollout = fullRollout.not()
                    },
                    text = "OpinionPro - ${currentDate.year()} - © Todos os direitos reservados."
                )
            }
        }
    }

}

