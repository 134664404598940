package com.catbit.opinionpoll.ui.screens.login

sealed interface LoginUIContract {
    sealed interface State {
        data class Displaying(
            val email: String,
            val password: String,
            val showPassword: Boolean
        ) : State

        data class Logging(
            val email: String,
            val password: String,
            val showPassword: Boolean
        ) : State

        data object CheckingIfAlreadyLogged : State

        companion object {
            fun default() = Displaying(
                email = "",
                password = "",
                showPassword = false
            )
        }
    }

    sealed interface Effect {
        data class OnLoginFailure(val message: String) : Effect
        data object OnLoginSuccess : Effect
        data object OnDisplayRecoveringPassword : Effect
        data object OnSendRecoveringPasswordStart : Effect
        data object OnSendRecoveringPasswordFailure : Effect
        data object OnSendRecoveringPasswordSuccess : Effect
    }

    sealed interface Event {
        data class OnEmailChange(val newValue: String) : Event
        data class OnPasswordChange(val newValue: String) : Event
        data object OnLoginClick : Event
        data object OnTogglePasswordVisibility : Event
        data object OnForgetPasswordClick : Event
        data class OnConfirmRecoverEmail(val email: String) : Event
    }
}