package com.catbit.opinionpoll.core.ui.composables.base_components

import androidx.compose.runtime.Composable
import com.catbit.opinionpoll.core.extensions.changeAlpha
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.catbit.opinionpoll.core.extensions.withNotNull
import com.catbit.opinionpoll.core.ui.composables.CircularProgress
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.icons.MaterialIcon
import com.catbit.opinionpoll.core.ui.modifiers.hoverBackground
import com.catbit.opinionpoll.core.ui.modifiers.labelLarge
import com.catbit.opinionpoll.core.ui.modifiers.noWrap
import com.catbit.opinionpoll.core.ui.modifiers.thenIf
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.UserSelect
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import org.jetbrains.compose.web.css.px

@Composable
fun FilledButton(
    modifier: Modifier = Modifier,
    text: String,
    enabled: Boolean = true,
    loading: Boolean = false,
    icon: MaterialIcon? = null,
    onClick: () -> Unit
) {
    FilledButton(
        modifier = modifier,
        text = text,
        enabled = enabled,
        loading = loading,
        leadingIcon = icon?.let {
            @Composable {
                MaterialIcon(
                    icon = it,
                    tint = sitePalette().onPrimary,
                    size = 18.px
                )
            }
        },
        onClick = onClick,
    )
}

@Composable
fun FilledButton(
    modifier: Modifier = Modifier,
    text: String,
    enabled: Boolean = true,
    loading: Boolean = false,
    leadingIcon: (@Composable () -> Unit)? = null,
    onClick: () -> Unit
) {
    Row(
        modifier = modifier
            .userSelect(UserSelect.None)
            .minHeight(40.px)
            .clip(Rect(40.px))
            .hoverBackground(
                onMouseEnterBackgroundColor = if (enabled)
                    sitePalette().primary.changeAlpha(0.8f)
                else
                    sitePalette().secondary.darkened(0.3f),
                onMouseExitBackgroundColor = if (enabled)
                    sitePalette().primary
                else
                    sitePalette().secondary.darkened(0.3f),

                )
            .cursor(if (enabled) Cursor.Pointer else Cursor.NotAllowed)
            .borderRadius(40.px)
            .thenIf(enabled) {
                onClick {
                    onClick()
                }
            },
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Center
    ) {
        if (loading) {
            CircularProgress(
                size = 24,
                strokeColor = sitePalette().onPrimary,
                strokeWidth = 2
            )
        } else {
            withNotNull(leadingIcon) {
                Box(
                    modifier = Modifier
                        .color(sitePalette().onPrimary)
                        .size(18.px)
                        .margin(right = 8.px)
                ) { this@withNotNull() }
            }
            Text(
                modifier = Modifier
                    .noWrap()
                    .color(sitePalette().onPrimary)
                    .labelLarge(),
                text = text
            )
        }
    }
}