package com.catbit.opinionpoll.core.ui.composables.base_components

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.extensions.*
import com.catbit.opinionpoll.core.ui.composables.HoverableContent
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.composables.effects.SingleEffect
import com.catbit.opinionpoll.core.ui.helpers.ScrollMode
import com.catbit.opinionpoll.core.ui.helpers.scrollContent
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.*
import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.res.Strings
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.UserSelect
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.overlay.*
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.flow.map
import kotlinx.datetime.internal.JSJoda.*
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import kotlinx.datetime.LocalDate as KotlinLocalDate

@Composable
fun DatePicker(
    modifier: Modifier = Modifier,
    value: LocalDate?,
    enabled: Boolean = true,
    backgroundColor: Color = sitePalette().surfaceVariant,
    showClearButton: Boolean = true,
    onDatePick: (KotlinLocalDate?) -> Unit
) {
    val mainPopCloseStrategyControl = remember { OpenClosePopupStrategy.manual() }
    val mainPopKeepOpenStrategyControl = remember { KeepPopupOpenStrategy.manual() }
    val monthPopCloseStrategyControl = remember { OpenClosePopupStrategy.manual() }
    val monthPopKeepOpenStrategyControl = remember { KeepPopupOpenStrategy.manual() }
    val yearPopCloseStrategyControl = remember { OpenClosePopupStrategy.manual() }
    val yearPopKeepOpenStrategyControl = remember { KeepPopupOpenStrategy.manual() }

    val hasFocus by mainPopCloseStrategyControl
        .requestFlow
        .map { it == OpenClose.OPEN }
        .collectAsState(false)

    val enableMonthPicker by yearPopCloseStrategyControl
        .requestFlow
        .map { it != OpenClose.OPEN }
        .collectAsState(true)

    val enableYearPicker by monthPopCloseStrategyControl
        .requestFlow
        .map { it != OpenClose.OPEN }
        .collectAsState(true)

    var containerYPosition by remember { mutableStateOf(0.0) }
    var containerXPosition by remember { mutableStateOf(0.0) }
    var containerWidth by remember { mutableStateOf(0.0) }

    val id by remember { mutableStateOf(UUID.stringUUID()) }

    val today = remember { LocalDate.now(clockOrZone = ZoneId.SYSTEM) }
    var selectedDate by remember {
        mutableStateOf(
            LocalDate.of(
                year = value?.year() ?: today.year(),
                month = value?.month() ?: today.month(),
                dayOfMonth = value?.dayOfMonth() ?: today.dayOfMonth()
            )
        )
    }
    var calendar by remember { mutableStateOf(buildCalendar(selectedDate)) }

    Row(
        modifier = modifier
            .id(id)
            .cursor(Cursor.Pointer)
            .padding(all = 16.px)
            .height(48.px)
            .borderRadius(8.px)
            .backgroundColor(backgroundColor)
            .thenIf(hasFocus) {
                border(
                    color = sitePalette().primary,
                    style = LineStyle.Solid,
                    width = 2.px
                )
            }
            .onClick {
                withNotNull(document.getElementById(id)?.getBoundingClientRect()) {
                    containerYPosition = y
                    containerXPosition = x
                    containerWidth = width
                }

                if (mainPopCloseStrategyControl.isOpen) {
                    monthPopCloseStrategyControl.isOpen = false
                    monthPopKeepOpenStrategyControl.shouldKeepOpen = false
                    yearPopCloseStrategyControl.isOpen = false
                    yearPopKeepOpenStrategyControl.shouldKeepOpen = false
                }

                selectedDate = LocalDate.of(
                    year = value?.year() ?: today.year(),
                    month = value?.month() ?: today.month(),
                    dayOfMonth = value?.dayOfMonth() ?: today.dayOfMonth()
                )
                calendar = buildCalendar(selectedDate)

                mainPopCloseStrategyControl.isOpen = mainPopCloseStrategyControl.isOpen.not()
                mainPopKeepOpenStrategyControl.shouldKeepOpen = mainPopKeepOpenStrategyControl.shouldKeepOpen.not()
            },
        verticalAlignment = Alignment.CenterVertically
    ) {
        Text(
            modifier = Modifier
                .noWrap()
                .bodyLarge()
                .color(sitePalette().onSurface)
                .fillMaxWidth(),
            text = value?.kotlinLocalDate()?.toBrazilianPattern().orEmpty()
        )
        MaterialIcon(MaterialIcons.Round.CalendarMonth)
    }

    AdvancedPopover(
        ElementTarget.PreviousSibling,
        placementStrategy = window.bestPlacementOf(
            xPos = containerXPosition,
            yPos = containerYPosition,
            width = containerWidth
        ),
        openCloseStrategy = mainPopCloseStrategyControl,
        keepOpenStrategy = mainPopKeepOpenStrategyControl
    ) {
        Column(
            modifier = Modifier
                .width(328.px)
                .clip(Rect(16.px))
                .border(
                    color = sitePalette().primary,
                    width = 2.px,
                    style = LineStyle.Solid
                )
                .background(sitePalette().surfaceVariant)
                .borderRadius(16.px)
                .padding(all = 12.px)
        ) {
            DatePickerControl(
                modifier = Modifier.fillMaxWidth(),
                selectedDate = selectedDate,
                todayDate = today,
                calendar = calendar,
                onDayClick = { day ->
                    val tmpSelectedDate = selectedDate
                    selectedDate = day
                    if (day.year() != tmpSelectedDate.year() || day.month() != tmpSelectedDate.month()) {
                        calendar = buildCalendar(day)
                    }
                },
                enableMonthPicker = enableMonthPicker,
                onMonthSelect = { month ->
                    selectedDate = selectedDate.plusMonths(
                        selectedDate.month().compareTo(month).toInt().unaryMinus()
                    )
                    calendar = buildCalendar(selectedDate)
                    monthPopCloseStrategyControl.isOpen = false
                    monthPopKeepOpenStrategyControl.shouldKeepOpen = false
                },
                onMonthTitleClick = {
                    monthPopCloseStrategyControl.isOpen = true
                    monthPopKeepOpenStrategyControl.shouldKeepOpen = true
                },
                onMonthLeftControlClick = {
                    val newDate = selectedDate.minusMonths(1)
                    calendar = buildCalendar(newDate)
                    selectedDate = newDate
                },
                onMonthRightControlClick = {
                    val newDate = selectedDate.plusMonths(1)
                    calendar = buildCalendar(newDate)
                    selectedDate = newDate
                },
                monthPopCloseStrategyControl = monthPopCloseStrategyControl,
                monthPopKeepOpenStrategyControl = monthPopKeepOpenStrategyControl,
                enableYearPicker = enableYearPicker,
                onYearSelect = { year ->
                    selectedDate = selectedDate.plusYears(
                        (selectedDate.year().toInt() - year).unaryMinus()
                    )
                    calendar = buildCalendar(selectedDate)
                    yearPopCloseStrategyControl.isOpen = false
                    yearPopKeepOpenStrategyControl.shouldKeepOpen = false
                },
                onYearTitleClick = {
                    yearPopCloseStrategyControl.isOpen = true
                    yearPopKeepOpenStrategyControl.shouldKeepOpen = true
                },
                onYearLeftControlClick = {
                    val newDate = selectedDate.minusYears(1)
                    calendar = buildCalendar(newDate)
                    selectedDate = newDate
                },
                onYearRightControlClick = {
                    val newDate = selectedDate.plusYears(1)
                    calendar = buildCalendar(newDate)
                    selectedDate = newDate
                },
                yearPopCloseStrategyControl = yearPopCloseStrategyControl,
                yearPopKeepOpenStrategyControl = yearPopKeepOpenStrategyControl,
                dialogTopOffset = 16,
                dialogLeftOffset = -12
            )

            PickerDialogControl(
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                showClearButton = showClearButton,
                onClearClick = {
                    onDatePick(null)
                    mainPopCloseStrategyControl.isOpen = false
                    mainPopKeepOpenStrategyControl.shouldKeepOpen = false
                },
                onCancelClick = {
                    mainPopCloseStrategyControl.isOpen = false
                    mainPopKeepOpenStrategyControl.shouldKeepOpen = false
                },
                onSelectClick = {
                    onDatePick(selectedDate.kotlinLocalDate())
                    mainPopCloseStrategyControl.isOpen = false
                    mainPopKeepOpenStrategyControl.shouldKeepOpen = false
                }
            )
        }
    }
}

@Composable
internal fun DatePickerControl(
    modifier: Modifier = Modifier,
    selectedDate: LocalDate,
    todayDate: LocalDate,
    calendar: List<List<LocalDate>>,
    onDayClick: (LocalDate) -> Unit,
    enableMonthPicker: Boolean,
    onMonthSelect: (Month) -> Unit,
    onMonthTitleClick: () -> Unit,
    onMonthLeftControlClick: () -> Unit,
    onMonthRightControlClick: () -> Unit,
    monthPopCloseStrategyControl: OpenClosePopupStrategy,
    monthPopKeepOpenStrategyControl: KeepPopupOpenStrategy,
    enableYearPicker: Boolean,
    onYearSelect: (Int) -> Unit,
    onYearTitleClick: () -> Unit,
    onYearLeftControlClick: () -> Unit,
    onYearRightControlClick: () -> Unit,
    yearPopCloseStrategyControl: OpenClosePopupStrategy,
    yearPopKeepOpenStrategyControl: KeepPopupOpenStrategy,
    dialogTopOffset: Int,
    dialogLeftOffset: Int,
) {
    Column(
        modifier = modifier
    ) {
        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            PeriodRange(
                title = selectedDate.month().displayText(),
                onTitleClick = onMonthTitleClick,
                onLeftControlClick = onMonthLeftControlClick,
                onRightControlClick = onMonthRightControlClick,
                enabled = enableMonthPicker
            )

            PeriodRange(
                title = selectedDate.year().toString(),
                onTitleClick = onYearTitleClick,
                onLeftControlClick = onYearLeftControlClick,
                onRightControlClick = onYearRightControlClick,
                enabled = enableYearPicker
            )
        }

        AdvancedPopover(
            ElementTarget.PreviousSibling,
            placementStrategy = PopupPlacementStrategy.of(
                PopupPlacement.BottomLeft,
                offsetPixels = dialogTopOffset
            ),
            openCloseStrategy = monthPopCloseStrategyControl,
            keepOpenStrategy = monthPopKeepOpenStrategyControl
        ) {
            MonthPicker(
                leftOffset = dialogLeftOffset,
                selectedDate = selectedDate,
                onMonthSelect = onMonthSelect
            )
        }

        // Âncora
        Row {}

        AdvancedPopover(
            ElementTarget.PreviousSibling,
            placementStrategy = PopupPlacementStrategy.of(
                PopupPlacement.BottomLeft,
                offsetPixels = dialogTopOffset
            ),
            openCloseStrategy = yearPopCloseStrategyControl,
            keepOpenStrategy = yearPopKeepOpenStrategyControl
        ) {
            YearPicker(
                leftOffset = dialogLeftOffset,
                selectedDate = selectedDate,
                onYearSelect = onYearSelect
            )
        }

        //Week Days Column
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .margin(top = 4.px),
            horizontalArrangement = Arrangement.SpaceEvenly
        ) {
            DayOfWeek.values().forEach { day ->
                Box(
                    modifier = Modifier.size(40.px),
                    contentAlignment = Alignment.Center
                ) {
                    Text(
                        modifier = Modifier
                            .userSelect(UserSelect.None)
                            .bodyLarge(),
                        text = day.dayLetter()
                    )
                }
            }
        }

        Column(
            modifier = Modifier
                .fillMaxWidth()
                .margin(top = 4.px)
        ) {
            calendar.forEach { daysRow ->
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceEvenly
                ) {
                    daysRow.forEach { day ->
                        Box(
                            modifier = Modifier
                                .cursor(Cursor.Pointer)
                                .size(40.px)
                                .clip(Rect(48.px))
                                .background(
                                    if (day == selectedDate) {
                                        sitePalette().primary
                                    } else {
                                        sitePalette().surfaceVariant
                                    }
                                ).thenIf(day == todayDate) {
                                    border(
                                        color = sitePalette().primary,
                                        style = LineStyle.Solid,
                                        width = 1.px
                                    ).borderRadius(48.px)
                                }
                                .onClick { onDayClick(day) },
                            contentAlignment = Alignment.Center
                        ) {
                            Text(
                                modifier = Modifier
                                    .userSelect(UserSelect.None)
                                    .color(
                                        if (day == selectedDate) {
                                            sitePalette().onPrimary
                                        } else if (day == todayDate) {
                                            sitePalette().primary
                                        } else if (day.month() == selectedDate.month()) {
                                            sitePalette().onSurface
                                        } else {
                                            sitePalette().onSurfaceVariant.changeAlpha(0.8f)
                                        }
                                    ),
                                text = day.dayOfMonth().toString()
                            )
                        }
                    }
                }
            }
        }
    }
}

@Composable
internal fun PeriodRange(
    title: String,
    enabled: Boolean,
    onTitleClick: () -> Unit,
    onLeftControlClick: () -> Unit,
    onRightControlClick: () -> Unit
) {
    Row {
        if (enabled) {
            MaterialIcon(
                modifier = Modifier
                    .cursor(Cursor.Pointer)
                    .onClick { onLeftControlClick() },
                icon = MaterialIcons.Round.ChevronLeft
            )
        } else {
            Spacer(modifier = Modifier.size(24.px))
        }
        Row(
            modifier = Modifier
                .cursor(if (enabled) Cursor.Pointer else Cursor.Default)
                .onClick {
                    if (enabled) {
                        onTitleClick()
                    }
                }
                .margin(horizontal = 16.px),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Text(
                modifier = Modifier
                    .labelLarge()
                    .color(
                        if (enabled)
                            sitePalette().onSurface
                        else
                            sitePalette().onSurface.changeAlpha(0.8f)
                    )
                    .userSelect(UserSelect.None)
                    .margin(right = 8.px),
                text = title
            )
            if (enabled) {
                MaterialIcon(MaterialIcons.Round.ArrowDropDown)
            } else {
                Spacer(modifier = Modifier.size(24.px))
            }
        }
        if (enabled) {
            MaterialIcon(
                modifier = Modifier
                    .cursor(Cursor.Pointer)
                    .onClick { onRightControlClick() },
                icon = MaterialIcons.Round.ChevronRight
            )
        } else {
            Spacer(modifier = Modifier.size(24.px))
        }
    }
}

@Composable
internal fun MonthPicker(
    leftOffset: Int,
    selectedDate: LocalDate,
    onMonthSelect: (Month) -> Unit
) {
    Column(
        modifier = Modifier
            .translateX(leftOffset.px)
            .width(328.px)
            .height(327.px)
            .roundedCornerClip(
                bottomRight = 16.px,
                bottomLeft = 16.px
            )
            .overflow(Overflow.Hidden)
            .background(sitePalette().surfaceVariant)
    ) {
        Divider(
            modifier = Modifier
                .height(1.px)
                .fillMaxWidth(),
            color = sitePalette().onSurfaceVariant
        )
        Column(
            modifier = Modifier
                .fillMaxSize()
                .id("MonthPickerId")
                .overflow {
                    y(Overflow.Auto)
                }
        ) {
            Month.values().forEach { month ->
                Row(
                    modifier = Modifier
                        .cursor(Cursor.Pointer)
                        .fillMaxWidth()
                        .padding(all = 16.px)
                        .hoverBackground(
                            onMouseExitBackgroundColor = sitePalette().surfaceVariant,
                            onMouseEnterBackgroundColor = sitePalette().surface
                        )
                        .onClick { onMonthSelect(month) }
                ) {
                    if (month == selectedDate.month()) {
                        MaterialIcon(MaterialIcons.Round.Check)
                    } else {
                        Box(modifier = Modifier.size(24.px))
                    }
                    Text(
                        modifier = Modifier
                            .bodyLarge()
                            .hoverColor(
                                onMouseExitColor = sitePalette().onSurfaceVariant,
                                onMouseEnterColor = sitePalette().onSurface
                            )
                            .margin(left = 16.px),
                        text = month.brazilianName()
                    )
                }
            }

            SingleEffect {
                val topOffset = Month.values().indexOfFirst { it == selectedDate.month() }
                scrollContent(
                    elementId = "MonthPickerId",
                    mode = ScrollMode.Position(top = topOffset * 56.0)
                )
            }
        }
    }
}

@Composable
internal fun YearPicker(
    leftOffset: Int,
    selectedDate: LocalDate,
    onYearSelect: (Int) -> Unit
) {

    val initialYear = remember { selectedDate.minusYears(50).year().toInt() }
    val endYear = remember { selectedDate.plusYears(50).year().toInt() }

    Column(
        modifier = Modifier
            .translateX(leftOffset.px)
            .width(328.px)
            .height(327.px)
            .roundedCornerClip(
                bottomRight = 16.px,
                bottomLeft = 16.px
            )
            .overflow(Overflow.Hidden)
            .background(sitePalette().surfaceVariant)
    ) {
        Divider(
            modifier = Modifier
                .height(1.px)
                .fillMaxWidth(),
            color = sitePalette().onSurfaceVariant
        )
        Column(
            modifier = Modifier
                .fillMaxSize()
                .id("YearPickerId")
                .overflow {
                    y(Overflow.Auto)
                }
        ) {
            (initialYear..endYear).forEach { year ->
                Row(
                    modifier = Modifier
                        .cursor(Cursor.Pointer)
                        .fillMaxWidth()
                        .padding(all = 16.px)
                        .hoverBackground(
                            onMouseExitBackgroundColor = sitePalette().surfaceVariant,
                            onMouseEnterBackgroundColor = sitePalette().surface
                        )
                        .onClick { onYearSelect(year) }
                ) {
                    if (year == selectedDate.year()) {
                        MaterialIcon(MaterialIcons.Round.Check)
                    } else {
                        Box(modifier = Modifier.size(24.px))
                    }
                    Text(
                        modifier = Modifier
                            .bodyLarge()
                            .hoverColor(
                                onMouseExitColor = sitePalette().onSurfaceVariant,
                                onMouseEnterColor = sitePalette().onSurface
                            )
                            .margin(left = 16.px),
                        text = year.toString()
                    )
                }
            }

            SingleEffect {
                val topOffset = (initialYear..endYear).indexOfFirst { it == selectedDate.year() }
                scrollContent(
                    elementId = "YearPickerId",
                    mode = ScrollMode.Position(top = topOffset * 56.0)
                )
            }
        }
    }
}

@Composable
fun PickerDialogControl(
    modifier: Modifier = Modifier,
    showClearButton: Boolean,
    onClearClick: () -> Unit,
    onCancelClick: () -> Unit,
    onSelectClick: () -> Unit,
) {
    Row(
        modifier = modifier,
        horizontalArrangement = Arrangement.End
    ) {
        if (showClearButton) {
            HoverableContent(
                modifier = Modifier.padding(horizontal = 8.px, vertical = 4.px),
                onHoverEndColor = sitePalette().surfaceVariant,
                onClick = onClearClick
            ) {
                Text(
                    text = Strings.clear,
                    modifier = Modifier.labelLarge()
                )
            }
        }
        HoverableContent(
            modifier = Modifier.padding(horizontal = 8.px, vertical = 4.px),
            onHoverEndColor = sitePalette().surfaceVariant,
            onClick = onCancelClick
        ) {
            Text(
                text = Strings.cancel,
                modifier = Modifier.labelLarge()
            )
        }
        HoverableContent(
            modifier = Modifier.padding(horizontal = 8.px, vertical = 4.px),
            onHoverEndColor = sitePalette().surfaceVariant,
            onClick = onSelectClick
        ) {
            Text(
                text = Strings.select,
                modifier = Modifier.labelLarge()
            )
        }
    }
}

internal fun buildCalendar(
    currentMonthDate: LocalDate
): List<List<LocalDate>> {

    val previousMonthDate = currentMonthDate.minusMonths(1)
    val nextMonthDate = currentMonthDate.plusMonths(1)
    val linearCalendar = mutableListOf<LocalDate>()

    val currentMonthDays = (1..currentMonthDate.lengthOfMonth().toInt()).map { day ->
        LocalDate.of(currentMonthDate.year(), currentMonthDate.month(), day)
    }

    val previousMonthDays = (1..previousMonthDate.lengthOfMonth().toInt()).map { day ->
        LocalDate.of(previousMonthDate.year(), previousMonthDate.month(), day)
    }

    val nextMonthDays = (1..nextMonthDate.lengthOfMonth().toInt()).map { day ->
        LocalDate.of(nextMonthDate.year(), nextMonthDate.month(), day)
    }

    val firstDayOfMonth = currentMonthDays.first()
    val shift = firstDayOfMonth.dayOfWeek().value().toInt() - DayOfWeek.MONDAY.value().toInt()
    val remainingDays = 42 - (shift + currentMonthDays.size)

    return with(linearCalendar) {
        addAll(previousMonthDays.takeLast(shift))
        addAll(currentMonthDays)
        addAll(nextMonthDays.take(remainingDays))
        chunked(7)
    }
}