package com.catbit.opinionpoll.inputs.validators

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.inputs.SingleChoiceInputUIState

class SingleChoiceInputUIValidator : InputUIValidator<SingleChoiceInputUIState> {
    override fun match(inputUIState: InputUIState) = inputUIState is SingleChoiceInputUIState

    override fun validate(
        inputUIState: SingleChoiceInputUIState,
        allIdentifiers: List<String>
    ): InputUIState {

        val errorMessage = with(inputUIState) {
            if (identifier.isEmpty()) {
                "O identificador não pode ser vazio"
            } else if (title.isEmpty()) {
                "O título não pode ser vazio"
            } else if (options.size <= 1 || (options.size == 1 && !hasOtherOption)) {
                "É necessário adicionar ao menos duas opções (Ou uma opção e habilitar \"Exibir opção Outros\")"
            } else if (options.any { it.title.isEmpty() }) {
                "Todas as opções precisam ter um título"
            } else if (hasOtherOption && otherOptionTitle.isEmpty()) {
                "O título da outra opção não pode ser vazio"
            } else if (hasOtherOption && maxOtherOptionTextLength == 0) {
                "O tamanho máximo da outra opção não pode ser zero"
            } else if (identifier in allIdentifiers) {
                "O identificador precisa ser único"
            } else null
        }

        return inputUIState.copy(errorMessage = errorMessage)
    }
}