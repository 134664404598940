package com.catbit.opinionpoll.core.extensions

import com.catbit.opinionpoll.core.state_holder.StateHolder
import org.koin.core.module.Module
import org.koin.core.parameter.ParametersHolder
import org.koin.core.qualifier.Qualifier
import org.koin.core.qualifier.StringQualifier
import org.koin.core.scope.Scope

inline fun <reified T : StateHolder> Module.stateHolder(
//    key: String, // It's a temporary solution. At this time, there's a bug in compose when using reified params
    qualifier: Qualifier? = null,
    noinline definition: Scope.(ParametersHolder) -> T,
) {
//    factory(StringQualifier("$key${qualifier?.toString().orEmpty()}")) { definition }
    factory(StringQualifier("${T::class.simpleName}${qualifier?.toString().orEmpty()}")) { definition }
}