package com.catbit.opinionpoll.inputs.validators

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.inputs.TimeInputUIState

class TimeInputUIValidator : InputUIValidator<TimeInputUIState> {
    override fun match(inputUIState: InputUIState) = inputUIState is TimeInputUIState

    override fun validate(
        inputUIState: TimeInputUIState,
        allIdentifiers: List<String>
    ): InputUIState {

        val errorMessage = with(inputUIState) {
            if (identifier.isEmpty()) {
                "O identificador não pode ser vazio"
            } else if (title.isEmpty()) {
                "O título não pode ser vazio"
            } else if (minTime != null && maxTime != null && minTime >= maxTime) {
                "Hora mínima precisa ser maior que a hora máxima"
            } else if (identifier in allIdentifiers) {
                "O identificador precisa ser único"
            } else null
        }

        return inputUIState.copy(errorMessage = errorMessage)
    }
}