package com.catbit.opinionpoll.inputs.requests.requests

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("NumericArea")
data class NumericAreaInputRequest(
    override val identifier: String,
    override val title: String,
    override val subtitle: String?,
    override val isObligatory: Boolean,
    override val index: Int,
    @SerialName("minValue")
    val minValue: Long,
    @SerialName("maxValue")
    val maxValue: Long,
    @SerialName("symbol")
    val symbol: String?,
    @SerialName("symbolPosition")
    val symbolPosition: String?,
    @SerialName("decimalPoints")
    val decimalPoints: Int?
) : InputRequest