package com.catbit.opinionpoll.core.charts.ui

import com.varabyte.kobweb.compose.ui.graphics.Color

class ChartsColorPallet {
    companion object {
        val lavenderBlush = Color.argb(0xFFFFE1F5)
        val bubbleGumPink = Color.argb(0xFFFF6E9B)
        val lemonMeringue = Color.argb(0xFFFFF3B0)
        val emeraldGlow = Color.argb(0xFF00E676)
        val sapphireDream = Color.argb(0xFF245DFF)
        val goldenSunrise = Color.argb(0xFFFFD700)
        val cherryBlossom = Color.argb(0xFFFFB7C5)
        val aquaMarine = Color.argb(0xFF7FFFD4)
        val flamingoPink = Color.argb(0xFFFC8EAC)
        val tangerineZest = Color.argb(0xFFFFA500)
        val mintyFresh = Color.argb(0xFF00FFC5)
        val lilacBreeze = Color.argb(0xFFB28DFF)
        val creamyMocha = Color.argb(0xFF8B4513)
        val radiantOrchid = Color.argb(0xFFB768A2)
        val springMeadow = Color.argb(0xFF77DD77)
        val midnightSky = Color.argb(0xFF2E3192)
        val candyAppleRed = Color.argb(0xFFFF0800)
        val oceanBlue = Color.argb(0xFF0077B5)
        val neonLime = Color.argb(0xFFBFFF00)
        val amethystPurple = Color.argb(0xFF9966CC)
        val sunsetOrange = Color.argb(0xFFFF4500)
        val seaFoamGreen = Color.argb(0xFF2E8B57)
        val hotPink = Color.argb(0xFFFF69B4)
        val caramelSwirl = Color.argb(0xFFFFD700)
        val royalVelvet = Color.argb(0xFF6A0DAD)
        val electricBlue = Color.argb(0xFF00F7FF)
        val peachParadise = Color.argb(0xFFFFDAB9)
        val forestGreen = Color.argb(0xFF228B22)
        val cherryRed = Color.argb(0xFFDE3163)
        val lemonZest = Color.argb(0xFFFFF44F)
        val turquoiseSea = Color.argb(0xFF00CED1)
        val lavenderFields = Color.argb(0xFFDCD0FF)
        val roseGold = Color.argb(0xFFFFC0CB)
        val citronYellow = Color.argb(0xFF9FA91F)
        val electricLime = Color.argb(0xFFCCFF00)
        val oceanBreeze = Color.argb(0xFF00A1E2)
        val chocolateSwirl = Color.argb(0xFF8B4513)
        val cherryBurst = Color.argb(0xFFFF4545)
        val deepEmerald = Color.argb(0xFF008080)
        val radiantRose = Color.argb(0xFFFF007F)
        val springMint = Color.argb(0xFF00FFC0)
        val lemonCustard = Color.argb(0xFFF4DA8B)
        val aquaSplash = Color.argb(0xFF32C7A9)
        val rosyRaspberry = Color.argb(0xFFE30B5C)
        val blazingSun = Color.argb(0xFFFFA500)
        val denimBlue = Color.argb(0xFF1560BD)
        val cottonCandyBlue = Color.argb(0xFF7CA7D2)
        val neonLilac = Color.argb(0xFFDE6FA1)
        val lushMeadow = Color.argb(0xFF4C9141)
        val indigoNight = Color.argb(0xFF191970)
        val cherryBomb = Color.argb(0xFFFF0000)
        val aquaSky = Color.argb(0xFF7FFFD4)
        val goldenHoney = Color.argb(0xFFFFD700)
        val electricCitrus = Color.argb(0xFFFDFF00)
        val azureBreeze = Color.argb(0xFF00AEEF)
        val chocolateTruffle = Color.argb(0xFF7B3F00)
        val emeraldEnvy = Color.argb(0xFF228B22)
        val coralCrush = Color.argb(0xFFFF6B6B)
        val lemonadeFizzle = Color.argb(0xFFFFF44F)
        val tangerineTango = Color.argb(0xFFF94D00)
        val radiantRuby = Color.argb(0xFF9B111E)
        val oceanWave = Color.argb(0xFF4CA2B0)
        val fieryOrange = Color.argb(0xFFFF5733)
        val emeraldDream = Color.argb(0xFF00B140)
        val cottonCandyClouds = Color.argb(0xFFFFD9E8)
        val lavenderLilac = Color.argb(0xFF9A8BC4)
        val candyFlossPink = Color.argb(0xFFFF5DA7)
        val zestyLemon = Color.argb(0xFFFFD300)
        val electricTurquoise = Color.argb(0xFF00FFEF)
        val sapphireSerenity = Color.argb(0xFF005F97)
        val honeyCrisp = Color.argb(0xFFFFA700)
        val cherryBlossomPink = Color.argb(0xFFFFA6C9)
        val sugarPlumPurple = Color.argb(0xFFA62FBB)
        val mintJulep = Color.argb(0xFF44D61E)
        val hotLavaRed = Color.argb(0xFFFF4500)
        val oceanicBlue = Color.argb(0xFF0055A4)
        val neonYellow = Color.argb(0xFFFFFF00)
        val amethystGem = Color.argb(0xFF884DA7)
        val tropicalSunset = Color.argb(0xFFFF6D31)
        val seaGlassGreen = Color.argb(0xFF3DC1A3)
        val babyBlueEyes = Color.argb(0xFFA1D9FF)
        val cinnamonSwirl = Color.argb(0xFFD2691E)
        val lemonLimeZest = Color.argb(0xFFBFFF00)
        val tranquilTurquoise = Color.argb(0xFF00CED1)
        val cherryRedDelight = Color.argb(0xFFFF3939)
        val marshmallowCream = Color.argb(0xFFEEE4B9)
        val emeraldOasis = Color.argb(0xFF00A86B)
        val rosyCheeks = Color.argb(0xFFFF6A80)
        val pinkParadise = Color.argb(0xFFFFC3A0)
        val citrusSqueeze = Color.argb(0xFFFFD700)
        val lavenderLace = Color.argb(0xFFDCD0FF)
        val electricChartreuse = Color.argb(0xFFDFFF00)
        val azureWaters = Color.argb(0xFF0077BE)
        val oceanMist = Color.argb(0xFF82C0CC)
        val radiantRaspberry = Color.argb(0xFFDB0A5B)
        val lushFoliage = Color.argb(0xFF4F7942)
        val cherryBerryBlast = Color.argb(0xFFFF0055)
        val cottonCandyPink = Color.argb(0xFFFFD3E0)
        val neonOrchid = Color.argb(0xFFDE2C87)
        val lemonZing = Color.argb(0xFFFFD34E)
        val deepAquaBlue = Color.argb(0xFF004B87)
        val lavenderLushness = Color.argb(0xFFDDD0FF)
        val roseQuartz = Color.argb(0xFFF7CAC9)
        val citrineYellow = Color.argb(0xFFE4D00A)
        val lavenderLeisure = Color.argb(0xFFE6E6FA)
        val electricOlive = Color.argb(0xFFB5CC39)
        val chocolateDelight = Color.argb(0xFF7B3F00)
        val cherrySensation = Color.argb(0xFFFF0033)
        val marshmallowClouds = Color.argb(0xFFF7E7CE)
        val emeraldElegance = Color.argb(0xFF00A572)
        val coralReef = Color.argb(0xFFFF6F61)
        val lemonLimeFizz = Color.argb(0xFFD4FF00)
        val lavenderLuxuryCream = Color.argb(0xFFEAEAFF)

        operator fun get(colorName: String): Color =
            colorsMap[colorName] ?: hotLavaRed

        operator fun get(colorIndex: Int): Color = if (colorIndex in 0..<colorsMap.size)
            colorsMap.values.elementAt(colorIndex)
        else colorsMap.values.first()

        val colorsMap = mapOf(
            "goldenSunrise" to goldenSunrise,
            "lemonMeringue" to lemonMeringue,
            "mintyFresh" to mintyFresh,
            "cherryBlossom" to cherryBlossom,
            "flamingoPink" to flamingoPink,
            "emeraldGlow" to emeraldGlow,
            "aquaMarine" to aquaMarine,
            "lilacBreeze" to lilacBreeze,
            "sapphireDream" to sapphireDream,
            "lavenderBlush" to lavenderBlush,
            "bubbleGumPink" to bubbleGumPink,
            "radiantOrchid" to radiantOrchid,
            "creamyMocha" to creamyMocha,
            "candyAppleRed" to candyAppleRed,
            "springMeadow" to springMeadow,
            "oceanBlue" to oceanBlue,
            "midnightSky" to midnightSky,
            "tangerineZest" to tangerineZest,
            "amethystPurple" to amethystPurple,
            "sunsetOrange" to sunsetOrange,
            "neonLime" to neonLime,
            "hotPink" to hotPink,
            "caramelSwirl" to caramelSwirl,
            "royalVelvet" to royalVelvet,
            "seaFoamGreen" to seaFoamGreen,
            "peachParadise" to peachParadise,
            "electricBlue" to electricBlue,
            "forestGreen" to forestGreen,
            "cherryRed" to cherryRed,
            "lemonZest" to lemonZest,
            "turquoiseSea" to turquoiseSea,
            "lavenderFields" to lavenderFields,
            "roseGold" to roseGold,
            "citronYellow" to citronYellow,
            "electricLime" to electricLime,
            "oceanBreeze" to oceanBreeze,
            "chocolateSwirl" to chocolateSwirl,
            "cherryBurst" to cherryBurst,
            "deepEmerald" to deepEmerald,
            "radiantRose" to radiantRose,
            "springMint" to springMint,
            "lemonCustard" to lemonCustard,
            "aquaSplash" to aquaSplash,
            "rosyRaspberry" to rosyRaspberry,
            "blazingSun" to blazingSun,
            "denimBlue" to denimBlue,
            "cottonCandyBlue" to cottonCandyBlue,
            "neonLilac" to neonLilac,
            "lushMeadow" to lushMeadow,
            "indigoNight" to indigoNight,
            "cherryBomb" to cherryBomb,
            "aquaSky" to aquaSky,
            "goldenHoney" to goldenHoney,
            "chocolateTruffle" to chocolateTruffle,
            "azureBreeze" to azureBreeze,
            "coralCrush" to coralCrush,
            "tangerineTango" to tangerineTango,
            "radiantRuby" to radiantRuby,
            "electricCitrus" to electricCitrus,
            "emeraldEnvy" to emeraldEnvy,
            "oceanWave" to oceanWave,
            "fieryOrange" to fieryOrange,
            "lemonadeFizzle" to lemonadeFizzle,
            "emeraldDream" to emeraldDream,
            "zestyLemon" to zestyLemon,
            "cottonCandyClouds" to cottonCandyClouds,
            "lavenderLilac" to lavenderLilac,
            "candyFlossPink" to candyFlossPink,
            "electricTurquoise" to electricTurquoise,
            "sapphireSerenity" to sapphireSerenity,
            "honeyCrisp" to honeyCrisp,
            "cherryBlossomPink" to cherryBlossomPink,
            "sugarPlumPurple" to sugarPlumPurple,
            "mintJulep" to mintJulep,
            "hotLavaRed" to hotLavaRed,
            "oceanicBlue" to oceanicBlue,
            "neonYellow" to neonYellow,
            "amethystGem" to amethystGem,
            "tropicalSunset" to tropicalSunset,
            "seaGlassGreen" to seaGlassGreen,
            "babyBlueEyes" to babyBlueEyes,
            "cinnamonSwirl" to cinnamonSwirl,
            "lemonLimeZest" to lemonLimeZest,
            "tranquilTurquoise" to tranquilTurquoise,
            "cherryRedDelight" to cherryRedDelight,
            "marshmallowCream" to marshmallowCream,
            "emeraldOasis" to emeraldOasis,
            "rosyCheeks" to rosyCheeks,
            "pinkParadise" to pinkParadise,
            "citrusSqueeze" to citrusSqueeze,
            "lavenderLace" to lavenderLace,
            "electricChartreuse" to electricChartreuse,
            "azureWaters" to azureWaters,
            "oceanMist" to oceanMist,
            "radiantRaspberry" to radiantRaspberry,
            "lushFoliage" to lushFoliage,
            "cherryBerryBlast" to cherryBerryBlast,
            "cottonCandyPink" to cottonCandyPink,
            "neonOrchid" to neonOrchid,
            "lemonZing" to lemonZing,
            "deepAquaBlue" to deepAquaBlue,
            "lavenderLushness" to lavenderLushness,
            "roseQuartz" to roseQuartz,
            "citrineYellow" to citrineYellow,
            "lavenderLeisure" to lavenderLeisure,
            "electricOlive" to electricOlive,
            "chocolateDelight" to chocolateDelight,
            "cherrySensation" to cherrySensation,
            "marshmallowClouds" to marshmallowClouds,
            "emeraldElegance" to emeraldElegance,
            "coralReef" to coralReef,
            "lemonLimeFizz" to lemonLimeFizz,
            "lavenderLuxuryCream" to lavenderLuxuryCream,
        )
    }
}