package com.catbit.opinionpoll.inputs.inputs

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.composables.base_components.OutlinedDropdownList
import com.catbit.opinionpoll.core.ui.composables.base_components.Text
import com.catbit.opinionpoll.core.ui.composables.base_components.TextField
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.titleMedium
import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.inputs.InputType
import com.catbit.opinionpoll.inputs.InputUI
import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.composables.DefaultFormContainer
import com.catbit.opinionpoll.inputs.composables.DefaultFormPreview
import com.catbit.opinionpoll.inputs.events.InputUIEvent
import com.catbit.opinionpoll.inputs.events.MaskedTextAreaInputEventData
import com.catbit.opinionpoll.res.Strings
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import org.jetbrains.compose.web.css.px

class MaskedTextAreaInput : InputUI<MaskedTextAreaInputUIState> {
    override fun match(uiState: InputUIState) = uiState is MaskedTextAreaInputUIState

    @Composable
    override fun Compose(uiState: MaskedTextAreaInputUIState, onEvent: (InputUIEvent) -> Unit) {

        // TODO Aplicar máscara

        DefaultFormContainer(
            modifier = Modifier.fillMaxWidth(),
            inputUIState = uiState,
            onEvent = onEvent,
            preview = { MaskedTextAreaFormPreview(uiState) }
        ) {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(top = 16.px)
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth()
                ) {
                    Column(
                        modifier = Modifier
                            .fillMaxWidth()
                            .margin(right = 16.px)
                    ) {
                        Text(
                            modifier = Modifier
                                .titleMedium()
                                .fontWeight(FontWeight.Bold),
                            text = Strings.mask
                        )
                        TextField(
                            modifier = Modifier
                                .fillMaxWidth()
                                .margin(top = 8.px),
                            text = uiState.mask,
                            enabled = uiState.isEnabled,
                            hint = Strings.maskExample,
                            onTextChanged = {
                                onEvent(
                                    InputUIEvent(
                                        formIdentifier = uiState.actualIdentifier,
                                        data = MaskedTextAreaInputEventData.OnMaskChange(it)
                                    )
                                )
                            }
                        )
                    }

                    Column(
                        modifier = Modifier.fillMaxWidth()
                    ) {
                        Text(
                            modifier = Modifier
                                .titleMedium()
                                .fontWeight(FontWeight.Bold),
                            text = Strings.characterToBeReplaced
                        )
                        TextField(
                            modifier = Modifier
                                .margin(top = 8.px)
                                .fillMaxWidth(),
                            text = uiState.replaceableChar,
                            enabled = uiState.isEnabled,
                            onTextChanged = {
                                if (it.length <= 1) {
                                    onEvent(
                                        InputUIEvent(
                                            formIdentifier = uiState.actualIdentifier,
                                            data = MaskedTextAreaInputEventData.OnReplaceableCharChange(it)
                                        )
                                    )
                                }
                            }
                        )
                    }
                }
            }

            Row(
                modifier = Modifier.margin(top = 16.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Text(
                    modifier = Modifier
                        .margin(right = 8.px)
                        .titleMedium()
                        .fontWeight(FontWeight.Bold),
                    text = Strings.fieldType
                )
                MaterialIcon(MaterialIcons.Round.Help)
                Tooltip(
                    text = Strings.fieldTypeTip,
                    target = ElementTarget.PreviousSibling,
                    placement = PopupPlacement.BottomLeft
                )
            }
            OutlinedDropdownList(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(top = 8.px),
                options = MaskedTextAreaInputUIState.Type.entries.map { it.translatedName },
                value = uiState.inputType.translatedName,
                onOptionsSelected = {
                    onEvent(
                        InputUIEvent(
                            formIdentifier = uiState.actualIdentifier,
                            data = MaskedTextAreaInputEventData.OnInputTypeChange(
                                MaskedTextAreaInputUIState.Type.fromString(it)
                            )
                        )
                    )
                }
            )
        }
    }

    @Composable
    private fun MaskedTextAreaFormPreview(uiState: MaskedTextAreaInputUIState) {

        var value by remember { mutableStateOf("") }

        with(uiState) {
            DefaultFormPreview(
                index = index,
                title = title,
                subtitle = subtitle
            ) {
                TextField(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(top = 8.px),
                    enabled = uiState.isEnabled,
                    text = value,
                    onTextChanged = { value = it }
                )
            }
        }
    }
}

data class MaskedTextAreaInputUIState(
    override val index: Int,
    override val isEnabled: Boolean,
    override val actualIdentifier: String,
    override val identifier: String,
    override val title: String,
    override val subtitle: String,
    override val isObligatory: Boolean,
    override val type: InputType,
    override val errorMessage: String?,
    val mask: String,
    val replaceableChar: String,
    val inputType: Type
) : InputUIState {

    enum class Type(
        val translatedName: String
    ) {
        Text("Texto"), Number("Número");

        companion object {
            fun fromString(type: String) = entries.firstOrNull { it.name == type } ?: Text
        }
    }

    companion object {
        fun default(
            index: Int
        ) = MaskedTextAreaInputUIState(
            index = index,
            isEnabled = true,
            actualIdentifier = UUID.stringUUID(),
            identifier = "",
            title = "",
            subtitle = "",
            isObligatory = false,
            errorMessage = null,
            type = InputType.MaskedTextArea,
            mask = "",
            replaceableChar = "#",
            inputType = Type.Text
        )
    }

    override val internalIdentifiers = listOf(identifier)
    override fun knowsIdentifier(identifier: String) = identifier == this.identifier
    override fun updateIndex(newIndex: Int) = copy(index = newIndex)
    override fun copy(
        index: Int?,
        isEnabled: Boolean?,
        actualIdentifier: String?,
        identifier: String?,
        title: String?,
        subtitle: String?,
        isObligatory: Boolean?,
        type: InputType?,
        error: String?
    ) = copy(
        index = index ?: this.index,
        isEnabled = isEnabled ?: this.isEnabled,
        actualIdentifier = actualIdentifier ?: this.actualIdentifier,
        identifier = identifier ?: this.identifier,
        title = title ?: this.title,
        subtitle = subtitle ?: this.subtitle,
        isObligatory = isObligatory ?: this.isObligatory,
        type = type ?: this.type,
        errorMessage = error ?: this.errorMessage,
    )
}