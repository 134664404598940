package com.catbit.opinionpoll.ui.screens.users

import com.catbit.opinionpoll.core.domain.invoke
import com.catbit.opinionpoll.core.extensions.updateAs
import com.catbit.opinionpoll.core.extensions.like
import com.catbit.opinionpoll.data.models.UserModel
import com.catbit.opinionpoll.domain.user.DeleteUsersUseCase
import com.catbit.opinionpoll.domain.user.GetUsersUseCase
import com.catbit.opinionpoll.ui.screens.users.UsersUIContract.*
import com.catbit.opinionpoll.ui.base.ScreenStateHolder
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

class UsersStateHolder(
    private val getUsersUseCase: GetUsersUseCase,
    private val deleteUsersUseCase: DeleteUsersUseCase
) : ScreenStateHolder<State, Event, Effect>() {

    override val internalUIState = MutableStateFlow<State>(State.Loading)
    private lateinit var usersMap: Map<String, UserModel>

    @OptIn(ExperimentalSerializationApi::class)
    private val json = Json {
        ignoreUnknownKeys = true
        explicitNulls = false
    }

    override fun onStarted() {
        getUsers()
    }

    override fun onRecovered() {
        getUsers()
    }

    private fun getUsers() {

        internalUIState.update {
            State.Loading
        }

        stateHolderScope.launch {
            getUsersUseCase()
                .onSuccess { users ->
                    usersMap = users.associateBy { it.uid }
                    internalUIState.update {
                        State.Displaying(users.map { it.toUIState() })
                    }
                }
                .onFailure { failure ->
                    internalUIState.update {
                        State.Failure(failure)
                    }
                }
        }
    }

    override fun onEvent(event: Event) {
        when (event) {
            Event.OnMenuDeleteUserClick -> onMenuDeleteUserClick()
            is Event.OnUserSelectedForDeletion -> onUserSelectForDeletion(event)
            Event.OnDeleteUserClick -> onDeleteUsersClick()
            Event.OnCancelDeletingUsers -> onCancelDeletingUsers()
            Event.OnConfirmUsersDeletion -> onConfirmUsersDeletion()
            Event.OnSelectAllUsersForDeletion -> onSelectAllUsersForDeletion()
            Event.OnRefreshUsers -> getUsers()
            is Event.OnFilterChange -> onFilterChange(event.newFilter)
            is Event.OnSearchQueryChange -> onSearchQueryChange(event.newQuery)
            Event.OnClearSearchQuery -> onClearSearchQuery()
        }
    }

    private fun onClearSearchQuery() {
        internalUIState.updateAs<State.Displaying> {
            copy(searchQuery = "")
        }
    }

    private fun onSearchQueryChange(
        newQuery: String
    ) {
        internalUIState.updateAs<State.Displaying> {
            copy(searchQuery = newQuery)
        }
    }

    private fun onFilterChange(newFilter: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(searchSorting = newFilter)
        }
    }

    private fun onDeleteUsersClick() {
        internalEffects.dispatch(Effect.OnConfirmUsersDeletion)
    }

    private fun onSelectAllUsersForDeletion() {
        internalUIState.updateAs<State.Displaying> {
            val allSelected = users.all { it.checked }
            val newUsers = users.map { user ->
                user.copy(checked = if (allSelected) false else true)
            }
            copy(
                users = newUsers,
                enableDeleteUsersButton = newUsers.any { it.checked }
            )
        }
    }

    private fun onCancelDeletingUsers() {
        internalUIState.updateAs<State.Displaying> {
            copy(
                isOnUserDeletionMode = false,
                enableDeleteUsersButton = false,
                selectedMenu = "none",
                users = users.map { user ->
                    user.copy(checked = false)
                }
            )
        }
    }

    private fun onConfirmUsersDeletion() {
        internalEffects.dispatch(Effect.OnStartUsersDeletion)

        stateHolderScope.launch {
            val userIdentifiers = internalUIState.like<State.Displaying>().users
                .filter { it.checked }
                .map { it.uid }

            deleteUsersUseCase(DeleteUsersUseCase.Params(userIdentifiers))
                .onSuccess {
                    internalEffects.dispatch(Effect.OnUsersDeletionSuccess)
                }
                .onFailure {
                    internalEffects.dispatch(Effect.OnUsersDeletionFailure)
                }
        }
    }

    private fun onUserSelectForDeletion(event: Event.OnUserSelectedForDeletion) {
        internalUIState.updateAs<State.Displaying> {
            val newUsers = users.map { user ->
                if (user.uid == event.userId) {
                    user.copy(checked = !user.checked)
                } else user
            }

            copy(
                users = newUsers,
                enableDeleteUsersButton = newUsers.any { it.checked }
            )
        }
    }

    private fun onMenuDeleteUserClick() {
        internalUIState.updateAs<State.Displaying> {
            copy(
                isOnUserDeletionMode = true,
                selectedMenu = "delete_users",
                users = users.map { user ->
                    user.copy(checked = false)
                }
            )
        }
    }

    fun getUserModel(uid: String) = json.encodeToString(usersMap.getValue(uid))
}