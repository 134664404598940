package com.catbit.opinionpoll.ui.screens.home

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.charts.data.ChartData
import com.catbit.opinionpoll.core.charts.ui.ChartConfiguration
import com.catbit.opinionpoll.core.charts.ui.ChartsColorPallet
import com.catbit.opinionpoll.core.charts.ui.charts.BarChart
import com.catbit.opinionpoll.core.charts.ui.charts.PieChart
import com.catbit.opinionpoll.core.extensions.changeAlpha
import com.catbit.opinionpoll.core.extensions.getStateHolder
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.catbit.opinionpoll.core.extensions.toBrazilianDatePattern
import com.catbit.opinionpoll.core.sailor.navigation_controller.NavigationController
import com.catbit.opinionpoll.core.sailor.navigation_controller.argumentsBundleOf
import com.catbit.opinionpoll.core.sailor.providers.LocalSnackbarState
import com.catbit.opinionpoll.core.ui.composables.*
import com.catbit.opinionpoll.core.ui.composables.base_components.*
import com.catbit.opinionpoll.core.ui.composables.effects.SingleEffect
import com.catbit.opinionpoll.core.ui.helpers.isMobileMode
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.*
import com.catbit.opinionpoll.data.models.SimplifiedFormModel
import com.catbit.opinionpoll.ui.screens.home.HomeUIStateContract.Effect
import com.catbit.opinionpoll.ui.screens.home.HomeUIStateContract.Event
import com.catbit.opinionpoll.ui.states.StatsUIState
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.AlignContent
import com.varabyte.kobweb.compose.css.AlignItems
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.coroutines.flow.collectLatest
import org.jetbrains.compose.web.css.*

@Composable
fun HomeScreen(
    stackEntryId: String,
    navigationController: NavigationController,
    stateHolder: HomeStateHolder = getStateHolder(stackEntryId)
) {
    val uiState = stateHolder.uiState.collectAsState().value
    val snackBarState = LocalSnackbarState.current
    val coroutineScope = rememberCoroutineScope()

    SingleEffect {
        stateHolder.effects.collectLatest {
            when (it) {
                Effect.Initial -> Unit
                Effect.OnDisplayMessageEffect -> {
                    snackBarState.show(
                        message = "Olá!",
                        coroutineScope = coroutineScope
                    )
                }
            }
        }
    }

    when (uiState) {
        is HomeUIStateContract.State.Displaying -> DisplayingState(
            navigationController = navigationController,
            greetings = uiState.greetings,
            groupedForms = uiState.forms,
            searchQuery = uiState.searchQuery,
            selectedSorting = uiState.selectedSorting,
            sortingOptions = uiState.sortingOptions,
            stats = uiState.stats,
            selectedTabId = uiState.selectedTabId,
            tabItems = uiState.tabItems,
            onEvent = {
                stateHolder.onEvent(it)
            }
        )

        HomeUIStateContract.State.Loading -> LoadingState()
        HomeUIStateContract.State.Failure -> FailureState()
    }
}

@Composable
private fun LoadingState() {
    Column(
        modifier = Modifier.fillMaxSize(),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        CircularProgress(50)
        Text("Carregando")
    }
}

@Composable
private fun FailureState() {
    Column(
        modifier = Modifier.fillMaxSize(),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        MaterialIcon(MaterialIcons.Round.Error)
        Text("Houve uma falha ao carregar os dados!")
    }
}

@Composable
private fun DisplayingState(
    navigationController: NavigationController,
    greetings: String,
    searchQuery: String,
    selectedSorting: String,
    sortingOptions: List<String>,
    stats: StatsUIState,
    groupedForms: Map<String, List<SimplifiedFormModel>>,
    selectedTabId: String,
    tabItems: List<HomeTabItemUIState>,
    onEvent: (Event) -> Unit
) {

    var formOverflow by remember { mutableStateOf(Overflow.Hidden) }
    var statsOverflow by remember { mutableStateOf(Overflow.Hidden) }

    if (isMobileMode) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .overflow(Overflow.Hidden)
        ) {
            Text(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(horizontal = 24.px, vertical = 16.px)
                    .headlineMedium(),
                text = greetings
            )

            TabContainer(
                modifier = Modifier
                    .padding(horizontal = 24.px)
                    .fillMaxWidth(),
                selectedTabId = selectedTabId,
                tabItems = tabItems.map { tabItem ->
                    TabItem(
                        id = tabItem.id,
                        text = tabItem.text,
                        icon = tabItem.icon
                    ) {
                        onEvent(Event.OnTabSelected(it))
                    }
                }
            )

            Divider(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(1.px)
            )

            if (selectedTabId == "polls") {
                SearchBar(
                    modifier = Modifier
                        .padding(top = 12.px, left = 24.px, right = 24.px, bottom = 16.px)
                        .fillMaxWidth(),
                    compactMode = true,
                    query = searchQuery,
                    onSearch = { onEvent(Event.OnFormSearch(query = it)) },
                    sorting = SearchBarSorting(
                        selected = selectedSorting,
                        options = sortingOptions,
                        onSort = {
                            onEvent(Event.OnSortingChange(it))
                        }
                    ),
                    onClear = { onEvent(Event.OnClearSearch) }
                )

                Column(
                    modifier = Modifier
                        .padding(vertical = 16.px, horizontal = 24.px)
                        .display(DisplayStyle.Flex)
                        .gap(16.px)
                        .overflow {
                            y(Overflow.Auto)
                            x(Overflow.Hidden)
                        }
                        .fillMaxSize()
                ) {
                    groupedForms.forEach { (title, forms) ->
                        if (forms.isNotEmpty()) {

                            Text(
                                modifier = Modifier.titleLarge(),
                                text = title
                            )

                            forms
                                .filter { it.title.contains(searchQuery, ignoreCase = true) }
                                .sortedWith { form1, form2 ->
                                    when (selectedSorting) {
                                        "Data de criação" -> if (form1.creationDate < form2.creationDate) {
                                            1
                                        } else if (form1.creationDate > form2.creationDate) {
                                            -1
                                        } else 0

                                        else -> form1.title.compareTo(form2.title)
                                    }
                                }
                                .forEach { form ->
                                    CardWithIcon(
                                        modifier = Modifier.fillMaxWidth(),
                                        icon = MaterialIcons.Round.Poll,
                                        alignment = CardWithIconAlignment.Top
                                    ) {
                                        Column(
                                            modifier = Modifier.fillMaxWidth()
                                        ) {
                                            Text(
                                                modifier = Modifier
                                                    .color(sitePalette().onSurface)
                                                    .titleMedium()
                                                    .overflow { x(Overflow.Hidden) }
                                                    .whiteSpace(WhiteSpace.NoWrap)
                                                    .textOverflow(TextOverflow.Ellipsis),
                                                text = form.title
                                            )
                                            Text(
                                                modifier = Modifier
                                                    .color(
                                                        sitePalette().onSurface.changeAlpha(
                                                            0.8f
                                                        )
                                                    )
                                                    .titleSmall()
                                                    .noWrap(),
                                                text = "Criado em ${form.creationDate.toBrazilianDatePattern()}"
                                            )
                                            Text(
                                                modifier = Modifier
                                                    .color(
                                                        sitePalette().onSurface.changeAlpha(
                                                            0.8f
                                                        )
                                                    )
                                                    .titleSmall()
                                                    .noWrap(),
                                                text = "Expira em ${form.expirationDate.toBrazilianDatePattern()}"
                                            )

                                            Row(
                                                modifier = Modifier
                                                    .margin(top = 8.px)
                                                    .fillMaxWidth(),
                                                horizontalArrangement = Arrangement.End
                                            ) {
                                                MaterialIcon(
                                                    modifier = Modifier
                                                        .padding(all = 8.px)
                                                        .cursor(Cursor.Pointer)
                                                        .margin(right = 8.px)
                                                        .clip(Circle())
                                                        .hoverBackground(
                                                            onMouseEnterBackgroundColor = sitePalette().secondaryContainer.changeAlpha(
                                                                0.8f
                                                            ),
                                                            onMouseExitBackgroundColor = sitePalette().surfaceVariant,
                                                        )
                                                        .onClick {
                                                            navigationController.navigate(
                                                                routeName = "form_dashboard",
                                                                arguments = argumentsBundleOf(
                                                                    "formIdentifier" to form.identifier,
                                                                    "formTitle" to form.title
                                                                )
                                                            )
                                                        },
                                                    icon = MaterialIcons.Round.Dashboard
                                                )

                                                Tooltip(
                                                    target = ElementTarget.PreviousSibling,
                                                    text = "Acessar Dashboard",
                                                    placement = PopupPlacement.Left
                                                )
                                            }

                                        }
                                    }
                                }
                        }
                    }
                }

            } else {
                when (stats) {
                    StatsUIState.Failure -> {
                        Column(
                            modifier = Modifier
                                .fillMaxSize()
                                .padding(horizontal = 24.px, vertical = 16.px),
                            verticalArrangement = Arrangement.Center,
                            horizontalAlignment = Alignment.CenterHorizontally
                        ) {
                            MaterialIcon(MaterialIcons.Round.Error)
                            Text("Houve uma falha ao carregar os dados!")
                            FilledButton(
                                modifier = Modifier
                                    .margin(top = 8.px)
                                    .padding(horizontal = 16.px),
                                text = "Tentar novamente",
                                onClick = {
                                    onEvent(Event.OnStatsTryAgainClick)
                                },
                                icon = null
                            )
                        }
                    }

                    StatsUIState.Loading -> {
                        Column(
                            modifier = Modifier
                                .fillMaxSize()
                                .padding(horizontal = 24.px, vertical = 16.px),
                            verticalArrangement = Arrangement.Center,
                            horizontalAlignment = Alignment.CenterHorizontally
                        ) {
                            CircularProgress(50)
                            Text("Carregando estatísticas")
                        }
                    }

                    is StatsUIState.Loaded -> {
                        Row(
                            modifier = Modifier
                                .fillMaxSize()
                                .padding(horizontal = 24.px, vertical = 16.px)
                                .gap(16.px)
                                .alignContent(AlignContent.FlexStart)
                                .alignItems(AlignItems.FlexStart)
                                .flexWrap(FlexWrap.Wrap)
                                .overflow {
                                    y(Overflow.Auto)
                                    x(Overflow.Hidden)
                                }
                        ) {
                            Column(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .borderRadius(16.px)
                                    .clip(Rect(16.px))
                                    .padding(all = 16.px)
                                    .background(sitePalette().surface),
                                horizontalAlignment = Alignment.CenterHorizontally
                            ) {
                                Text(
                                    modifier = Modifier.displayMedium(),
                                    text = stats.totalAnswers.toString()
                                )

                                Text(
                                    modifier = Modifier
                                        .headlineSmall()
                                        .margin(top = 8.px)
                                        .textAlign(TextAlign.Center),
                                    text = "Respostas coletadas"
                                )
                            }

                            Column(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .borderRadius(16.px)
                                    .clip(Rect(16.px))
                                    .padding(all = 16.px)
                                    .background(sitePalette().surface),
                                horizontalAlignment = Alignment.CenterHorizontally
                            ) {
                                Text(
                                    modifier = Modifier.displayMedium(),
                                    text = stats.totalForms.toString()
                                )

                                Text(
                                    modifier = Modifier
                                        .headlineSmall()
                                        .textAlign(TextAlign.Center)
                                        .margin(top = 8.px),
                                    text = "Formulários"
                                )
                            }

                            Column(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .borderRadius(16.px)
                                    .clip(Rect(16.px))
                                    .padding(all = 16.px)
                                    .background(sitePalette().surface),
                                horizontalAlignment = Alignment.CenterHorizontally
                            ) {
                                Text(
                                    modifier = Modifier.displayMedium(),
                                    text = stats.totalUsers.toString()
                                )

                                Text(
                                    modifier = Modifier
                                        .headlineSmall()
                                        .textAlign(TextAlign.Center)
                                        .margin(top = 8.px),
                                    text = "Membros na equipe"
                                )
                            }

                            if (stats.totalAnswersByFormChart.data.isNotEmpty()
                                && stats.totalAnswersByFormChart.data.any { it.value != 0 }
                            ) {
                                Row(
                                    modifier = Modifier.fillMaxWidth()
                                ) {
                                    Text(
                                        modifier = Modifier.titleLarge(),
                                        text = stats.totalAnswersByFormChart.title
                                    )
                                }

                                Column(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .borderRadius(16.px)
                                        .clip(Rect(16.px))
                                        .padding(all = 16.px)
                                        .background(sitePalette().surface),
                                    horizontalAlignment = Alignment.CenterHorizontally
                                ) {
                                    BarChart(
                                        modifier = Modifier.fillMaxWidth(),
                                        barsMaxWidth = 100.percent,
                                        data = ChartData(
                                            title = "",
                                            dataSet = stats.totalAnswersByFormChart.data.mapIndexed { index, data ->
                                                ChartData.Data(
                                                    value = data.value,
                                                    label = data.label,
                                                    color = ChartsColorPallet[index]
                                                )
                                            }
                                        ),
                                        verticalMode = true
                                    )
                                }

                                Column(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .clip(Rect(16.px))
                                        .padding(all = 16.px)
                                        .background(sitePalette().surface),
                                    horizontalAlignment = Alignment.CenterHorizontally,
                                    verticalArrangement = Arrangement.Center
                                ) {
                                    PieChart(
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .margin(top = 16.px),
                                        configuration = object : ChartConfiguration {
                                            override val height = 150
                                            override val width = 150
                                            override val labelsPosition = ChartConfiguration.LabelPosition.Bottom
                                        },
                                        data = ChartData(
                                            title = "",
                                            dataSet = stats.totalAnswersByFormChart.data.mapIndexed { index, data ->
                                                ChartData.Data(
                                                    value = data.value,
                                                    label = data.label,
                                                    color = ChartsColorPallet[index]
                                                )
                                            }
                                        ),
                                        wrapLegends = false
                                    )
                                }
                            } else {
                                Column(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .clip(Rect(16.px))
                                        .padding(all = 16.px)
                                        .background(sitePalette().surface),
                                    horizontalAlignment = Alignment.CenterHorizontally,
                                    verticalArrangement = Arrangement.Center
                                ) {
                                    MaterialIcon(
                                        icon = MaterialIcons.Round.TableChart,
                                        size = 48.px,
                                        tint = sitePalette().onSurface
                                    )
                                    Text(
                                        modifier = Modifier
                                            .margin(top = 16.px)
                                            .textAlign(TextAlign.Center)
                                            .color(sitePalette().onSurface)
                                            .titleLarge(),
                                        text = "Nenhum dado a ser exibido"
                                    )
                                    Text(
                                        modifier = Modifier
                                            .titleMedium()
                                            .textAlign(TextAlign.Center)
                                            .color(sitePalette().onSurface),
                                        text = "Conforme as pesquisas forem sendo realizadas, os dados coletados aparecerão aqui."
                                    )
                                }
                            }
                        }
                    }

                }
            }
        }
    } else {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(top = 24.px, left = 24.px, right = 24.px)
                .overflow(Overflow.Hidden)
        ) {

            Text(
                modifier = Modifier.headlineLarge(),
                text = greetings
            )

            Row(
                modifier = Modifier
                    .margin(top = 32.px)
                    .fillMaxSize()
                    .overflow(Overflow.Hidden)
            ) {
                Column(
                    modifier = Modifier
                        .width(50.percent)
                        .margin(right = 24.px)
                        .fillMaxHeight()
                ) {
                    SearchBar(
                        modifier = Modifier.fillMaxWidth(),
                        hint = "Buscar formulários",
                        query = searchQuery,
                        onSearch = { onEvent(Event.OnFormSearch(query = it)) },
                        sorting = SearchBarSorting(
                            selected = selectedSorting,
                            options = sortingOptions,
                            onSort = {
                                onEvent(Event.OnSortingChange(it))
                            }
                        ),
                        onClear = { onEvent(Event.OnClearSearch) }
                    )

                    Column(
                        modifier = Modifier
                            .margin(top = 24.px)
                            .padding(bottom = 24.px)
                            .display(DisplayStyle.Flex)
                            .gap(16.px)
                            .onMouseEnter { formOverflow = Overflow.Auto }
                            .onMouseLeave { formOverflow = Overflow.Hidden }
                            .overflow { y(formOverflow) }
                            .fillMaxSize()
                    ) {
                        groupedForms.forEach { (title, forms) ->
                            if (forms.isNotEmpty()) {

                                Text(
                                    modifier = Modifier.titleLarge(),
                                    text = title
                                )

                                forms
                                    .filter { it.title.contains(searchQuery, ignoreCase = true) }
                                    .sortedWith { form1, form2 ->
                                        when (selectedSorting) {
                                            "Data de criação" -> if (form1.creationDate < form2.creationDate) {
                                                1
                                            } else if (form1.creationDate > form2.creationDate) {
                                                -1
                                            } else 0

                                            else -> form1.title.compareTo(form2.title)
                                        }
                                    }
                                    .forEach { form ->
                                        CardWithIcon(
                                            modifier = Modifier.fillMaxWidth(),
                                            icon = MaterialIcons.Round.Poll
                                        ) {
                                            Row(
                                                modifier = Modifier.fillMaxWidth(),
                                                verticalAlignment = Alignment.Bottom
                                            ) {
                                                Column(
                                                    modifier = Modifier.fillMaxWidth()
                                                ) {
                                                    Text(
                                                        modifier = Modifier
                                                            .color(sitePalette().onSurface)
                                                            .titleMedium()
                                                            .noWrap(),
                                                        text = form.title
                                                    )
                                                    Text(
                                                        modifier = Modifier
                                                            .color(
                                                                sitePalette().onSurface.changeAlpha(
                                                                    0.8f
                                                                )
                                                            )
                                                            .titleSmall()
                                                            .noWrap(),
                                                        text = "Criado em ${form.creationDate.toBrazilianDatePattern()}"
                                                    )
                                                    Text(
                                                        modifier = Modifier
                                                            .color(
                                                                sitePalette().onSurface.changeAlpha(
                                                                    0.8f
                                                                )
                                                            )
                                                            .titleSmall()
                                                            .noWrap(),
                                                        text = "Expira em ${form.expirationDate.toBrazilianDatePattern()}"
                                                    )
                                                }

                                                MaterialIcon(
                                                    modifier = Modifier
                                                        .padding(all = 8.px)
                                                        .cursor(Cursor.Pointer)
                                                        .margin(right = 8.px)
                                                        .clip(Circle())
                                                        .hoverBackground(
                                                            onMouseEnterBackgroundColor = sitePalette().secondaryContainer.changeAlpha(
                                                                0.8f
                                                            ),
                                                            onMouseExitBackgroundColor = sitePalette().surfaceVariant,
                                                        )
                                                        .onClick {
                                                            navigationController.navigate(
                                                                routeName = "form_dashboard",
                                                                arguments = argumentsBundleOf(
                                                                    "formIdentifier" to form.identifier,
                                                                    "formTitle" to form.title
                                                                )
                                                            )
                                                        },
                                                    icon = MaterialIcons.Round.Dashboard
                                                )

                                                Tooltip(
                                                    target = ElementTarget.PreviousSibling,
                                                    text = "Acessar Dashboard",
                                                    placement = PopupPlacement.Left
                                                )
                                            }
                                        }
                                    }
                            }
                        }
                    }
                }

                Row(
                    modifier = Modifier
                        .width(50.percent)
                        .fillMaxHeight()
                        .padding(bottom = 24.px)
                        .overflow(Overflow.Hidden)
                ) {
                    when (stats) {
                        StatsUIState.Failure -> {
                            Column(
                                modifier = Modifier
                                    .fillMaxSize()
                                    .padding(horizontal = 24.px, vertical = 16.px),
                                verticalArrangement = Arrangement.Center,
                                horizontalAlignment = Alignment.CenterHorizontally
                            ) {
                                MaterialIcon(MaterialIcons.Round.Error)
                                Text("Houve uma falha ao carregar os dados!")
                                FilledButton(
                                    modifier = Modifier
                                        .margin(top = 8.px)
                                        .padding(horizontal = 16.px),
                                    text = "Tentar novamente",
                                    onClick = {
                                        onEvent(Event.OnStatsTryAgainClick)
                                    },
                                    icon = null
                                )
                            }
                        }

                        StatsUIState.Loading -> {
                            Column(
                                modifier = Modifier.fillMaxSize(),
                                verticalArrangement = Arrangement.Center,
                                horizontalAlignment = Alignment.CenterHorizontally
                            ) {
                                CircularProgress(50)
                                Text("Carregando estatísticas")
                            }
                        }

                        is StatsUIState.Loaded -> {
                            Row(
                                modifier = Modifier
                                    .fillMaxSize()
                                    .gap(16.px)
                                    .alignContent(AlignContent.FlexStart)
                                    .alignItems(AlignItems.FlexStart)
                                    .flexWrap(FlexWrap.Wrap)
                                    .onMouseEnter { statsOverflow = Overflow.Auto }
                                    .onMouseLeave { statsOverflow = Overflow.Hidden }
                                    .overflow {
                                        y(statsOverflow)
                                        x(Overflow.Hidden)
                                    }
                            ) {
                                Column(
                                    modifier = Modifier
                                        .width(33.percent.minus(8.px))
                                        .borderRadius(16.px)
                                        .clip(Rect(16.px))
                                        .padding(all = 16.px)
                                        .background(sitePalette().surface),
                                    horizontalAlignment = Alignment.CenterHorizontally
                                ) {
                                    Text(
                                        modifier = Modifier.displayMedium(),
                                        text = stats.totalAnswers.toString()
                                    )

                                    Text(
                                        modifier = Modifier
                                            .headlineSmall()
                                            .margin(top = 8.px)
                                            .textAlign(TextAlign.Center),
                                        text = "Respostas coletadas"
                                    )
                                }

                                Column(
                                    modifier = Modifier
                                        .width(33.percent.minus(8.px))
                                        .borderRadius(16.px)
                                        .clip(Rect(16.px))
                                        .padding(all = 16.px)
                                        .background(sitePalette().surface),
                                    horizontalAlignment = Alignment.CenterHorizontally
                                ) {
                                    Text(
                                        modifier = Modifier.displayMedium(),
                                        text = stats.totalForms.toString()
                                    )

                                    Text(
                                        modifier = Modifier
                                            .headlineSmall()
                                            .textAlign(TextAlign.Center)
                                            .margin(top = 8.px),
                                        text = "Formulários"
                                    )
                                }

                                Column(
                                    modifier = Modifier
                                        .width(33.percent.minus(8.px))
                                        .borderRadius(16.px)
                                        .clip(Rect(16.px))
                                        .padding(all = 16.px)
                                        .background(sitePalette().surface),
                                    horizontalAlignment = Alignment.CenterHorizontally
                                ) {
                                    Text(
                                        modifier = Modifier.displayMedium(),
                                        text = stats.totalUsers.toString()
                                    )

                                    Text(
                                        modifier = Modifier
                                            .headlineSmall()
                                            .textAlign(TextAlign.Center)
                                            .margin(top = 8.px),
                                        text = "Membros na equipe"
                                    )
                                }

                                if (stats.totalAnswersByFormChart.data.isNotEmpty()
                                    && stats.totalAnswersByFormChart.data.any { it.value != 0 }
                                ) {
                                    Row(
                                        modifier = Modifier.fillMaxWidth()
                                    ) {
                                        Text(
                                            modifier = Modifier.titleLarge(),
                                            text = stats.totalAnswersByFormChart.title
                                        )
                                    }

                                    Column(
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .borderRadius(16.px)
                                            .clip(Rect(16.px))
                                            .padding(all = 16.px)
                                            .background(sitePalette().surface),
                                        horizontalAlignment = Alignment.CenterHorizontally
                                    ) {
                                        BarChart(
                                            modifier = Modifier
                                                .fillMaxWidth()
                                                .margin(top = 16.px),
                                            barsMaxWidth = 100.percent,
                                            data = ChartData(
                                                title = "",
                                                dataSet = stats.totalAnswersByFormChart.data.mapIndexed { index, data ->
                                                    ChartData.Data(
                                                        value = data.value,
                                                        label = data.label,
                                                        color = ChartsColorPallet[index]
                                                    )
                                                }
                                            )
                                        )
                                    }

                                    Column(
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .clip(Rect(16.px))
                                            .padding(all = 16.px)
                                            .background(sitePalette().surface),
                                        horizontalAlignment = Alignment.CenterHorizontally,
                                        verticalArrangement = Arrangement.Center
                                    ) {
                                        PieChart(
                                            modifier = Modifier
                                                .fillMaxWidth()
                                                .margin(top = 16.px),
                                            configuration = object : ChartConfiguration {
                                                override val height = 150
                                                override val width = 150
                                                override val labelsPosition = ChartConfiguration.LabelPosition.Right
                                            },
                                            data = ChartData(
                                                title = "",
                                                dataSet = stats.totalAnswersByFormChart.data.mapIndexed { index, data ->
                                                    ChartData.Data(
                                                        value = data.value,
                                                        label = data.label,
                                                        color = ChartsColorPallet[index]
                                                    )
                                                }
                                            )
                                        )
                                    }
                                } else {
                                    Column(
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .clip(Rect(16.px))
                                            .padding(all = 16.px)
                                            .background(sitePalette().surface),
                                        horizontalAlignment = Alignment.CenterHorizontally,
                                        verticalArrangement = Arrangement.Center
                                    ) {
                                        MaterialIcon(
                                            icon = MaterialIcons.Round.TableChart,
                                            size = 48.px,
                                            tint = sitePalette().onSurface
                                        )
                                        Text(
                                            modifier = Modifier
                                                .margin(top = 16.px)
                                                .textAlign(TextAlign.Center)
                                                .color(sitePalette().onSurface)
                                                .titleLarge(),
                                            text = "Nenhum dado a ser exibido"
                                        )
                                        Text(
                                            modifier = Modifier
                                                .titleMedium()
                                                .textAlign(TextAlign.Center)
                                                .color(sitePalette().onSurface),
                                            text = "Conforme as pesquisas forem sendo realizadas, os dados coletados aparecerão aqui."
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}