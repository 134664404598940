package com.catbit.opinionpoll.domain.home

import com.catbit.opinionpoll.core.domain.FlowUseCase
import com.catbit.opinionpoll.data.models.StatsModel
import com.catbit.opinionpoll.data.repositories.OpinionPollRepository
import kotlinx.coroutines.flow.Flow

class GetStatsUseCase(
    private val repository: OpinionPollRepository
) : FlowUseCase<StatsModel, Unit>() {
    override suspend fun execute(params: Unit) = repository.getStats()
}