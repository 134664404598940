package com.catbit.opinionpoll.core.ui.composables.base_components

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.extensions.changeAlpha
import com.catbit.opinionpoll.core.ui.composables.HoverableContent
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.composables.effects.SingleEffect
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.*
import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.res.Strings
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextOverflow
import com.varabyte.kobweb.compose.css.UserSelect
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.overlay.*
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

class DropdownListState {

    internal val openClosePopupStrategy = OpenClosePopupStrategy.manual()
    internal val keepPopupOpenStrategy = KeepPopupOpenStrategy.manual()

    fun show() {
        openClosePopupStrategy.isOpen = true
        keepPopupOpenStrategy.shouldKeepOpen = true
    }

    fun dismiss() {
        openClosePopupStrategy.isOpen = false
        keepPopupOpenStrategy.shouldKeepOpen = false
    }

    fun toggle() {
        openClosePopupStrategy.isOpen = openClosePopupStrategy.isOpen.not()
        keepPopupOpenStrategy.shouldKeepOpen = keepPopupOpenStrategy.shouldKeepOpen.not()
    }
}

@Composable
fun rememberDropdownListState() = remember { DropdownListState() }

@Composable
fun DropdownList(
    modifier: Modifier = Modifier,
    state: DropdownListState = rememberDropdownListState(),
    options: List<String>,
    displaySearchBar: Boolean = false,
    enabled: Boolean = true,
    checkedOptions: List<String>? = null,
    onCheckOptionsChange: ((List<String>) -> Unit)? = null,
    onOptionsSelected: (String) -> Unit,
    onShowOptions: (() -> Unit)? = null,
    onHideOptions: (() -> Unit)? = null,
    constrainDialogOnParentWidth: Boolean = false,
    value: @Composable () -> Unit
) {

    var containerPosition by remember { mutableStateOf(0.0) }
    var containerWidth by remember { mutableStateOf(0.px) }
    val id by remember { mutableStateOf(UUID.stringUUID()) }

    var searchQuery by remember { mutableStateOf("") }
    val checkedOptionsList = mutableStateListOf<String>()

    Row(
        modifier = modifier
            .id(id)
            .cursor(if (enabled) Cursor.Pointer else Cursor.NotAllowed)
            .onClick {
                if (enabled) {
                    containerPosition = document.getElementById(id)?.getBoundingClientRect()?.y ?: 0.0
                    containerWidth = (document.getElementById(id)?.getBoundingClientRect()?.width ?: 0.0).px
                    state.toggle()

                    if (state.openClosePopupStrategy.isOpen) {
                        onShowOptions?.invoke()
                    } else {
                        onHideOptions?.invoke()
                        checkedOptionsList.clear()
                    }
                }
            },
        verticalAlignment = Alignment.CenterVertically
    ) {
        value()
    }

    AdvancedPopover(
        ElementTarget.PreviousSibling,
        placementStrategy = PopupPlacementStrategy.of(
            if (containerPosition >= window.innerHeight / 2)
                PopupPlacement.TopRight
            else
                PopupPlacement.BottomRight
        ),
        openCloseStrategy = state.openClosePopupStrategy,
        keepOpenStrategy = state.keepPopupOpenStrategy
    ) {

        SingleEffect {
            checkedOptions?.let { checkedOptionsList.addAll(it) }
        }

        Column(
            modifier = Modifier
                .overflow(Overflow.Hidden)
                .thenIf(constrainDialogOnParentWidth) {
                    maxWidth(containerWidth)
                }
                .minWidth(containerWidth)
                .clip(Rect(8.px))
                .border(
                    color = sitePalette().primary,
                    width = 2.px,
                    style = LineStyle.Solid
                )
                .background(sitePalette().surfaceVariant)
                .borderRadius(8.px)
                .padding(vertical = 8.px)
        ) {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .maxHeight(300.px)
                    .overflow {
                        y(Overflow.Auto)
                        x(Overflow.Hidden)
                    },
            ) {
                if (displaySearchBar) {
                    Row(
                        modifier = Modifier
                            .margin(vertical = 8.px)
                            .padding(horizontal = 16.px)
                            .fillMaxWidth()
                    ) {
                        TextField(
                            modifier = Modifier
                                .fillMaxWidth()
                                .color(sitePalette().onSurface),
                            backgroundColor = sitePalette().surface,
                            text = searchQuery,
                            onTextChanged = {
                                searchQuery = it
                            },
                            trailingIcon = {
                                MaterialIcon(
                                    modifier = Modifier.onClick {
                                        searchQuery = ""
                                    },
                                    icon = MaterialIcons.Round.Close
                                )
                            }
                        )
                    }
                }

                options
                    .filter { it.contains(searchQuery, ignoreCase = true) }
                    .forEach { option ->
                        Row(
                            modifier = Modifier
                                .userSelect(UserSelect.None)
                                .padding(horizontal = 16.px, vertical = 8.px)
                                .cursor(Cursor.Pointer)
                                .hoverBackground(
                                    onMouseEnterBackgroundColor = sitePalette().primary,
                                    onMouseExitBackgroundColor = sitePalette().surfaceVariant,
                                )
                                .hoverColor(
                                    onMouseEnterColor = sitePalette().onPrimary,
                                    onMouseExitColor = sitePalette().onSurfaceVariant,
                                )
                                .onClick {
                                    if (checkedOptions != null) {
                                        if (option in checkedOptionsList) {
                                            checkedOptionsList.remove(option)
                                        } else {
                                            checkedOptionsList.add(option)
                                        }
                                    } else {
                                        onOptionsSelected(option)
                                        state.dismiss()
                                        onHideOptions?.invoke()
                                        searchQuery = ""
                                    }
                                }
                                .fillMaxWidth(),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            if (checkedOptions != null) {
                                if (option in checkedOptionsList) {
                                    MaterialIcon(
                                        modifier = Modifier.margin(right = 16.px).noWrap(),
                                        icon = MaterialIcons.Round.CheckBox,
                                    )
                                } else {
                                    MaterialIcon(
                                        modifier = Modifier.margin(right = 16.px).noWrap(),
                                        icon = MaterialIcons.Round.CheckBoxOutlineBlank,
                                    )
                                }
                            }
                            Text(
                                modifier = Modifier
                                    .textOverflow(TextOverflow.Ellipsis)
                                    .labelLarge(),
                                text = option
                            )
                        }
                    }
            }

            if (checkedOptions != null) {
                Row(
                    modifier = modifier,
                    horizontalArrangement = Arrangement.End
                ) {
                    HoverableContent(
                        modifier = Modifier.padding(horizontal = 8.px, vertical = 4.px),
                        onHoverEndColor = sitePalette().surfaceVariant,
                        onClick = {
                            state.dismiss()
                            onHideOptions?.invoke()
                            searchQuery = ""
                            checkedOptionsList.clear()
                        }
                    ) {
                        Text(
                            text = Strings.cancel,
                            modifier = Modifier.labelLarge()
                        )
                    }
                    HoverableContent(
                        modifier = Modifier.padding(horizontal = 8.px, vertical = 4.px),
                        onHoverEndColor = sitePalette().surfaceVariant,
                        onClick = {
                            state.dismiss()
                            onHideOptions?.invoke()
                            searchQuery = ""
                            onCheckOptionsChange?.invoke(checkedOptionsList.toList())
                            checkedOptionsList.clear()
                        }
                    ) {
                        Text(
                            text = Strings.select,
                            modifier = Modifier.labelLarge()
                        )
                    }
                }
            }
        }
    }
}