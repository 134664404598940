package com.catbit.opinionpoll.core.ui.composables

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.extensions.changeAlpha
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div

@Composable
fun HoverableContent(
    modifier: Modifier = Modifier,
    onHoverStartColor: Color = sitePalette().secondaryContainer.changeAlpha(0.8f),
    onHoverEndColor: Color = sitePalette().background,
    cursor: Cursor = Cursor.Pointer,
    onClick: () -> Unit,
    content: @Composable () -> Unit
) {

    var hasMouseEntered by remember { mutableStateOf(false) }
    val backgroundColor by rememberUpdatedState(
        if (hasMouseEntered)
            onHoverStartColor
        else
            onHoverEndColor
    )

    Div(
        attrs = modifier
            .cursor(cursor)
            .backgroundColor(backgroundColor)
            .clip(Rect(48.px))
            .onMouseEnter {
                hasMouseEntered = true
            }
            .onMouseLeave {
                hasMouseEntered = false
            }
            .onClick { onClick() }
            .toAttrs(),
    ) {
        content()
    }
}