package com.catbit.opinionpoll.ui.screens.form_dashboard

import com.catbit.opinionpoll.core.charts.data.LineChartData
import com.catbit.opinionpoll.core.js.google_maps.maps.LatLng
import com.catbit.opinionpoll.core.ui.icons.MaterialIcon
import com.catbit.opinionpoll.inputs.state.InputGraphUIState
import com.catbit.opinionpoll.ui.states.FormFilterUIState
import org.w3c.files.Blob

sealed interface FormDashboardUIContract {

    sealed interface State {

        val toolbarTitle: String
        val displayUpdateAnswersButton: Boolean

        data class Empty(
            override val toolbarTitle: String,
            override val displayUpdateAnswersButton: Boolean,
        ): State

        data class Displaying(
            override val toolbarTitle: String,
            val menuOptions: List<MenuItemUIState>,
            override val displayUpdateAnswersButton: Boolean,
            val selectedMenuId: String,
            val bi: BIUIState,
            val charts: ChartsUIState,
            val table: TableUIState,
            val map: MapUIState?
        ) : State {

            data class MenuItemUIState(
                val id: String,
                val text: String,
                val icon: MaterialIcon? = null
            )

            data class BIUIState(
                val biAnswers: FilterUIState?,
                val hasOriginalFilters: Boolean,
                val filters: List<FormFilterUIState>,
                val selectedFilter: String,
                val filterSubgroups: List<String>,
                val selectedFilterSubgroups: List<String>,
                val filterSubgroupValues: List<String>,
                val selectedFilterSubgroupValues: List<String>,
                val savingFilters: Boolean,
                val managingFilter: Boolean,
                val filterManagingMode: FilterManagingMode,
                val editFilterIdentifier: String,
                val editFilterTitle: String,
                val editAvailableGroupingFields: List<String>,
                val editSelectedGroupingField: String,
                val editSubgroups: List<FormFilterUIState.FormFilterSubgroupFieldUIState>,
                val editAvailableSubgroupsValues: List<String>,
                val editAvailableCrossingFields: List<String>,
                val editSelectedCrossingFields: List<String>,
            ) {
                enum class FilterManagingMode {
                    Add, Edit
                }

                data class FilterUIState(
                    val percentage: Double,
                    val total: Int,
                    val graphs: List<InputGraphUIState>
                )
            }

            data class ChartsUIState(
                val totalAnswers: Int,
                val graphAnswers: List<InputGraphUIState>,
                val usersChart: LineChartData,
            )

            data class TableUIState(
                val sorting: Sorting = Sorting.Ascending,
                val headers: List<String>,
                val answers: List<AnswerUIState>,
                val tableHeight: Int? = null,
                val currentPage: Int = 1,
                val pages: Int = 0,
                val rowsWindow: IntRange = 0..0,
                val rowsChunk: Int = 0,
                val displayGeoCoordinatesColumn: Boolean,
            ) {
                enum class Sorting {
                    Ascending, Descending;

                    fun invert(): Sorting = if (this == Ascending) Descending else Ascending
                }

                data class AnswerUIState(
                    val identifier: String,
                    val values: List<AnswerCellUIState>
                )

                data class AnswerCellUIState(
                    val identifier: String,
                    val value: String
                )
            }

            data class MapUIState(
                val markers: List<MapMarkerUIState>
            ) {
                data class MapMarkerUIState(
                    val latLong: LatLng,
                    val collectedAt: String,
                    val collectedBy: String,
                    val relatedAnswer: Map<String, String>
                )
            }
        }

        data class Loading(
            override val toolbarTitle: String,
            override val displayUpdateAnswersButton: Boolean = false,
        ) : State

        data class Failure(
            override val toolbarTitle: String,
            override val displayUpdateAnswersButton: Boolean = false,
        ) : State

    }

    sealed interface Event {
        data object OnGenerateCSVRequest : Event
        data object OnGenerateXLSXRequest : Event
        data object OnExportAnswersClick : Event
        data class OnMenuClick(val menuId: String) : Event
        data object TryAgain : Event
        data object OnAddBISubgroup : Event
        data object OnCancelAddingBIFilter : Event
        data object OnSaveBIFilter : Event
        data object OnRemoveFilter : Event
        data object OnEditFilter : Event
        data object OnSaveFilters : Event
        data object OnAddBIFilter : Event
        data object OnGoToFirstTablePageClick : Event
        data object OnGoToPreviousTablePageClick : Event
        data object OnGoToNextTablePageClick : Event
        data object OnGoToLastTablePageClick : Event
        data object OnUpdateAnswers : Event
        data class OnBITitleChange(val newTitle: String) : Event
        data class OnBIGroupingFieldSelected(val field: String) : Event
        data class OnBISubgroupTitleChange(val identifier: String, val newTitle: String) : Event
        data class OnBISubgroupValuesChange(val identifier: String, val values: List<String>) : Event
        data class OnBISubgroupRemove(val identifier: String) : Event
        data class OnBICrossingFieldsValuesChange(val values: List<String>) : Event
        data class OnSelectFilter(val filterIdentifier: String) : Event
        data class OnToggleFilterSubgroup(val filterSubgroupIdentifier: String) : Event
        data class OnToggleFilterSubgroupValue(val filterSubgroupValue: String) : Event
        data class OnChartTypeChange(val identifier: String, val newType: InputGraphUIState.CharType) : Event
        data class OnFilterChartTypeChange(val identifier: String, val newType: InputGraphUIState.CharType) : Event
        data class OnSortAnswerTable(val columnIndex: Int) : Event
        data class OnCopyAnswerTableColumn(val column: String) : Event
        data class OnCopyAnswerTableRows(val column: String, val startRow: Int, val endRow: Int) : Event
        data class OnReplaceAnswerTableRows(val column: String, val startRow: Int, val values: String) : Event
        data class OnRemoveAnswerTableRows(val startRow: Int, val endRow: Int) : Event
        data class OnEditTableRow(val cellIdentifier: String, val answerIdentifier: String, val newValue: String) :
            Event
        data class OnTableHeightSet(val clientHeight: Int) : Event
        data class OnRemoveAnswerTableRow(val rowIndex: Int) : Event
        data class OnDuplicateAnswerTableRow(val rowIndex: Int) : Event
    }

    sealed interface Effect {
        data object OnDisplayFileFormatPicker : Effect
        data object OnPreparingAnswersToDownload : Effect
        data object OnPreparingAnswersToDownloadFail : Effect
        data class OnDownloadReady(val file: Blob, val fileExtension: String) : Effect
        data object OnSaveFiltersSuccess : Effect
        data object OnSaveFiltersFailure : Effect
        data object OnUpdateAnswersStart : Effect
        data object OnUpdateAnswersSuccess : Effect
        data object OnUpdateAnswersFailure : Effect

        data class OnDisplayMessage(val message: String) : Effect
        data class OnColumnCopiedToClipboard(val columnData: String) : Effect
    }
}