package com.catbit.opinionpoll.data.data_sources.networking.requests

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class AskForSupportRequest(
    @SerialName("topic")
    val topic: String,
    @SerialName("subject")
    val subject: String,
    @SerialName("message")
    val message: String
)
