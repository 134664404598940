package com.catbit.opinionpoll.inputs.events

import kotlinx.datetime.LocalDate

sealed interface DateInputData {

    data class OnMinDateChange(
        val newMinDate: LocalDate?
    ) : DateInputData

    data class OnMaxDateChange(
        val newMaxDate: LocalDate?
    ) : DateInputData

}