package com.catbit.opinionpoll.core.ui.composables

import androidx.compose.runtime.Composable
import com.catbit.opinionpoll.core.extensions.changeAlpha
import com.catbit.opinionpoll.core.ui.composables.base_components.Text
import com.catbit.opinionpoll.core.ui.icons.MaterialIcon
import com.catbit.opinionpoll.core.ui.modifiers.noWrap
import com.catbit.opinionpoll.core.ui.modifiers.titleMedium
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.px

@Composable
fun ToolbarButton(
    modifier: Modifier = Modifier,
    text: String,
    icon: MaterialIcon,
    enabled: Boolean,
    onClick: () -> Unit,
) {
    HoverableContent(
        modifier = modifier,
        onClick = {
            if (enabled) {
                onClick()
            }
        },
        onHoverStartColor = if (enabled)
            sitePalette().secondaryContainer.changeAlpha(0.8f)
        else
            sitePalette().background
    ) {
        Row(
            modifier = Modifier.padding(all = 8.px),
            verticalAlignment = Alignment.CenterVertically
        ) {
            MaterialIcon(
                modifier = Modifier.margin(right = 8.px),
                icon = icon,
                tint = if (enabled)
                    sitePalette().primary
                else
                    sitePalette().outline
            )
            Text(
                modifier = Modifier
                    .color(
                        if (enabled)
                            sitePalette().primary
                        else
                            sitePalette().outline
                    )
                    .noWrap()
                    .titleMedium(),
                text = text
            )
        }
    }
}