package com.catbit.opinionpoll.data.data_sources.networking.requests

import com.catbit.opinionpoll.inputs.requests.requests.InputRequest
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FormCreationRequest(
    @SerialName("version")
    val version: Long,
    @SerialName("title")
    val title: String,
    @SerialName("tips")
    val tips: String?,
    @SerialName("creationDate")
    val creationDate: String,
    @SerialName("createdBy")
    val createdBy: String,
    @SerialName("expirationDate")
    val expirationDate: String,
    @SerialName("sendGeoCoordinates")
    val sendGeoCoordinates: Boolean,
    @SerialName("template")
    val template: List<InputRequest>
)