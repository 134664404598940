package com.catbit.opinionpoll.core.js.google_maps.maps

import org.w3c.dom.Element


fun googleMap(element: Element, zoom: Int, center: LatLng, init: MapOptions.() -> Unit = {}): Map {
    val options = createObject<MapOptions>().apply {
        this.zoom = zoom
        this.center = center
        init()
    }
    return Map(element, options)
}

fun marker(init: MarkerOptions.() -> Unit): Marker = Marker(createObject<MarkerOptions>().apply(init))

fun infoWindow(init: InfoWindowOptions.() -> Unit = {}) = InfoWindow(createObject<InfoWindowOptions>().apply(init))

private inline fun <reified T> createObject(): T = Any().unsafeCast<T>()
