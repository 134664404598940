package com.catbit.opinionpoll.inputs.models

import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.inputs.InputType
import com.catbit.opinionpoll.inputs.inputs.MultipleChoicesInputUIState
import com.catbit.opinionpoll.inputs.inputs.OptionInput
import kotlinx.serialization.Serializable

@Serializable
data class MultipleChoicesOptionsInputModel(
    override val identifier: String,
    override val title: String,
    override val subtitle: String?,
    override val isObligatory: Boolean,
    override val index: Int,
    val options: List<String>,
    val style: Style,
    val minSelection: Int,
    val maxSelection: Int,
    val enableOtherOptions: Boolean,
    val maxOtherOptions: Int,
    val otherOptionPlaceholderText: String,
    val maxOtherOptionTextLength: Int,
) : InputModel {

    enum class Style(val style: String) {
        CheckBox("checkbox");

        companion object {
            fun getFromString(style: String) =
                entries.firstOrNull { it.style == style } ?: CheckBox
        }
    }

    override fun toUIState() = MultipleChoicesInputUIState(
        actualIdentifier = UUID.stringUUID(),
        type = InputType.MultipleChoices,
        identifier = identifier,
        title = title,
        subtitle = subtitle.orEmpty(),
        isObligatory = isObligatory,
        index = index,
        errorMessage = null,
        isEnabled = true,
        options = options.map {
            OptionInput(
                actualIdentifier = UUID.stringUUID(),
                title = it
            )
        },
        style = when (style) {
            Style.CheckBox -> MultipleChoicesInputUIState.MultipleChoicesStyle.Checkbox
        },
        minSelection = minSelection,
        maxSelection = maxSelection,
        hasOtherOption = enableOtherOptions,
        maxOtherOptions = maxOtherOptions,
        otherOptionPlaceholderText = otherOptionPlaceholderText,
        maxOtherOptionTextLength = maxOtherOptionTextLength
    )

    override fun knowsIdentifier(identifier: String) = identifier == this.identifier
    override val identifiers = listOf(identifier)
    override val titleToIdentifierMapping = listOf(title to identifier)
}