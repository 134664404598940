package com.catbit.opinionpoll.inputs.models

import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.inputs.InputType
import com.catbit.opinionpoll.inputs.inputs.DateTimeInputUIState
import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class DateTimeInputModel(
    override val identifier: String,
    override val title: String,
    override val subtitle: String?,
    override val isObligatory: Boolean,
    override val index: Int,
    val minDateTime: LocalDateTime?,
    val maxDateTime: LocalDateTime?,
) : InputModel {

    override fun toUIState() = DateTimeInputUIState(
        actualIdentifier = UUID.stringUUID(),
        type = InputType.DateTime,
        identifier = identifier,
        title = title,
        subtitle = subtitle.orEmpty(),
        isObligatory = isObligatory,
        index = index,
        isEnabled = true,
        errorMessage = null,
        minDateTime = minDateTime,
        maxDateTime = maxDateTime
    )

    override fun knowsIdentifier(identifier: String) = identifier == this.identifier
    override val identifiers = listOf(identifier)
    override val titleToIdentifierMapping = listOf(title to identifier)
}