package com.catbit.opinionpoll.ui.screens.users

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.extensions.changeAlpha
import com.catbit.opinionpoll.core.extensions.getStateHolder
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.catbit.opinionpoll.core.extensions.toBrazilianPhoneFormat
import com.catbit.opinionpoll.core.sailor.navigation_controller.NavigationController
import com.catbit.opinionpoll.core.sailor.navigation_controller.argumentsBundleOf
import com.catbit.opinionpoll.core.sailor.providers.LocalDialogStateProvider
import com.catbit.opinionpoll.core.sailor.providers.LocalSnackbarState
import com.catbit.opinionpoll.core.ui.composables.*
import com.catbit.opinionpoll.core.ui.composables.base_components.Checkbox
import com.catbit.opinionpoll.core.ui.composables.base_components.Dialog
import com.catbit.opinionpoll.core.ui.composables.base_components.Spacer
import com.catbit.opinionpoll.core.ui.composables.base_components.Text
import com.catbit.opinionpoll.core.ui.composables.effects.SingleEffect
import com.catbit.opinionpoll.core.ui.helpers.isMobileMode
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.*
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.coroutines.flow.collectLatest
import org.jetbrains.compose.web.css.*

@Composable
fun UsersScreen(
    stackEntryId: String,
    navigationController: NavigationController,
    stateHolder: UsersStateHolder = getStateHolder(
        stackEntryId = stackEntryId
    )
) {
    val uiState = stateHolder.uiState.collectAsState().value
    val dialogState = LocalDialogStateProvider.current
    val coroutineScope = rememberCoroutineScope()
    val snackBarState = LocalSnackbarState.current

    SingleEffect {
        stateHolder.effects.collectLatest {
            when (it) {
                UsersUIContract.Effect.OnConfirmUsersDeletion -> {
                    dialogState.show {
                        Dialog(
                            modifier = Modifier.fillMaxWidth(),
                            title = "Apagar membros da equipe?",
                            body = "Esta operação não pode ser revertida.",
                            positiveText = "Sim",
                            onPositiveClick = {
                                stateHolder.onEvent(
                                    UsersUIContract.Event.OnConfirmUsersDeletion
                                )
                                dialogState.dismiss()
                            },
                            negativeText = "Não",
                            onNegativeClick = {
                                dialogState.dismiss()
                            })
                    }
                }

                UsersUIContract.Effect.OnStartUsersDeletion -> {
                    dialogState.show {
                        Column(
                            modifier = Modifier.fillMaxWidth(), horizontalAlignment = Alignment.CenterHorizontally
                        ) {
                            CircularProgress(50)
                            Text(
                                modifier = Modifier.margin(top = 8.px), text = "Apagando membros da equipe"
                            )
                        }
                    }
                }

                UsersUIContract.Effect.OnUsersDeletionFailure -> {
                    dialogState.dismiss()
                    snackBarState.show(
                        coroutineScope = coroutineScope,
                        message = "Houve um erro ao apagar os membros da equipe, tente novamente!"
                    )
                }

                UsersUIContract.Effect.OnUsersDeletionSuccess -> {
                    dialogState.dismiss()
                    snackBarState.show(
                        coroutineScope = coroutineScope, message = "Membros da equipe apagados com sucesso!"
                    )
                    stateHolder.onEvent(
                        UsersUIContract.Event.OnRefreshUsers
                    )
                }
            }
        }
    }

    Column(
        modifier = Modifier.fillMaxSize(),
    ) {
        when (uiState) {
            is UsersUIContract.State.Displaying -> {
                if (isMobileMode) {
                    var showNavDrawer by remember { mutableStateOf(false) }

                    Box(
                        modifier = Modifier.fillMaxSize()
                    ) {
                        if (uiState.users.isEmpty()) {
                            Column(
                                modifier = Modifier
                                    .fillMaxSize(),
                            ) {
                                Box(
                                    modifier = Modifier
                                        .margin(left = 24.px, top = 16.px)
                                        .minSize(40.px)
                                        .clip(Rect(40.px))
                                        .hoverBackground(
                                            onMouseEnterBackgroundColor = sitePalette().primary.changeAlpha(0.8f),
                                            onMouseExitBackgroundColor = sitePalette().primary
                                        )
                                        .cursor(Cursor.Pointer)
                                        .borderRadius(40.px)
                                        .onClick {
                                            showNavDrawer = true
                                        },
                                    contentAlignment = Alignment.Center
                                ) {
                                    MaterialIcon(
                                        icon = MaterialIcons.Round.Menu,
                                        tint = sitePalette().onPrimary
                                    )
                                }

                                Column(
                                    modifier = Modifier
                                        .padding(all = 24.px)
                                        .fillMaxSize(),
                                    horizontalAlignment = Alignment.CenterHorizontally,
                                    verticalArrangement = Arrangement.Center
                                ) {
                                    MaterialIcon(
                                        icon = MaterialIcons.Round.People,
                                        size = 48.px,
                                        tint = sitePalette().onSurface
                                    )
                                    Text(
                                        modifier = Modifier
                                            .margin(top = 16.px)
                                            .textAlign(TextAlign.Center)
                                            .color(sitePalette().onSurface)
                                            .titleLarge(),
                                        text = "Nenhum membro cadastrado"
                                    )
                                    Text(
                                        modifier = Modifier
                                            .titleMedium()
                                            .textAlign(TextAlign.Center)
                                            .color(sitePalette().onSurface),
                                        text = "Você pode adicionar um membro clicando ao lado em \"Adicionar membro\""
                                    )
                                }
                            }
                        } else {
                            Column(
                                modifier = Modifier.fillMaxSize()
                            ) {

                                Box(
                                    modifier = Modifier
                                        .margin(left = 24.px, top = 16.px)
                                        .minSize(40.px)
                                        .clip(Rect(40.px))
                                        .hoverBackground(
                                            onMouseEnterBackgroundColor = sitePalette().primary.changeAlpha(0.8f),
                                            onMouseExitBackgroundColor = sitePalette().primary
                                        )
                                        .cursor(Cursor.Pointer)
                                        .borderRadius(40.px)
                                        .onClick {
                                            showNavDrawer = true
                                        },
                                    contentAlignment = Alignment.Center
                                ) {
                                    MaterialIcon(
                                        icon = MaterialIcons.Round.Menu,
                                        tint = sitePalette().onPrimary
                                    )
                                }

                                SearchBar(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .padding(horizontal = 24.px)
                                        .margin(vertical = 16.px),
                                    sorting = SearchBarSorting(selected = uiState.searchSorting,
                                        options = uiState.searchSortingOptions,
                                        onSort = {
                                            stateHolder.onEvent(
                                                UsersUIContract.Event.OnFilterChange(it)
                                            )
                                        }),
                                    query = uiState.searchQuery,
                                    onClear = {
                                        stateHolder.onEvent(
                                            UsersUIContract.Event.OnClearSearchQuery
                                        )
                                    },
                                    compactMode = true
                                ) {
                                    stateHolder.onEvent(
                                        UsersUIContract.Event.OnSearchQueryChange(it)
                                    )
                                }

                                if (uiState.isOnUserDeletionMode) {
                                    Row(
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .padding(horizontal = 24.px)
                                            .display(DisplayStyle.Flex)
                                            .flexWrap(FlexWrap.Wrap)
                                            .margin(bottom = 16.px),
                                        verticalAlignment = Alignment.CenterVertically
                                    ) {
                                        ToolbarButton(
                                            text = "Apagar",
                                            enabled = uiState.enableDeleteUsersButton,
                                            icon = MaterialIcons.Round.DeleteOutline
                                        ) {
                                            stateHolder.onEvent(UsersUIContract.Event.OnDeleteUserClick)
                                        }
                                        ToolbarButton(
                                            text = "Marcar todos", enabled = true, icon = MaterialIcons.Round.SelectAll
                                        ) {
                                            stateHolder.onEvent(UsersUIContract.Event.OnSelectAllUsersForDeletion)
                                        }
                                        ToolbarButton(
                                            text = "Cancelar", enabled = true, icon = MaterialIcons.Round.Close
                                        ) {
                                            stateHolder.onEvent(UsersUIContract.Event.OnCancelDeletingUsers)
                                        }
                                    }
                                }

                                Column(
                                    modifier = Modifier
                                        .padding(right = 24.px, bottom = 24.px, left = 24.px)
                                        .overflow {
                                            y(Overflow.Auto)
                                            x(Overflow.Hidden)
                                        }
                                        .display(DisplayStyle.Flex)
                                        .gap(16.px)
                                        .fillMaxWidth()
                                ) {
                                    uiState.users.filter { user ->
                                        user.name.contains(
                                            uiState.searchQuery,
                                            ignoreCase = true
                                        ) || user.email.contains(
                                            uiState.searchQuery, ignoreCase = true
                                        ) || user.phoneNumber.contains(uiState.searchQuery, ignoreCase = true)
                                    }.sortedWith { user1, user2 ->
                                        when (uiState.searchSorting) {
                                            "Nome" -> user1.name.compareTo(user2.name)
                                            "Email" -> user1.email.compareTo(user2.email)
                                            else -> user1.phoneNumber.compareTo(user2.phoneNumber)
                                        }
                                    }.forEach { user ->
                                        Row(
                                            modifier = Modifier.fillMaxWidth(),
                                            verticalAlignment = Alignment.CenterVertically
                                        ) {
                                            if (uiState.isOnUserDeletionMode) {
                                                Checkbox(
                                                    modifier = Modifier
                                                        .margin(right = 16.px),
                                                    checked = user.checked,
                                                    onCheck = {
                                                        stateHolder.onEvent(
                                                            UsersUIContract.Event.OnUserSelectedForDeletion(user.uid)
                                                        )
                                                    }
                                                )
                                            }
                                            CardWithIcon(
                                                modifier = Modifier.width(
                                                    if (uiState.isOnUserDeletionMode) 100.percent.minus(
                                                        40.px
                                                    ) else 100.percent
                                                ),
                                                icon = user.roleIcon,
                                                alignment = CardWithIconAlignment.Center,
                                                content = {
                                                    Column(
                                                        modifier = Modifier
                                                            .fillMaxWidth()
                                                            .overflow { x(Overflow.Hidden) }
                                                    ) {
                                                        Text(
                                                            modifier = Modifier
                                                                .color(sitePalette().onSurface)
                                                                .titleMedium(),
                                                            text = user.name
                                                        )
                                                    }
                                                },
                                                bottomContent = {
                                                    Column(
                                                        modifier = Modifier
                                                            .margin(top = 16.px)
                                                            .fillMaxWidth()
                                                            .overflow { x(Overflow.Hidden) }
                                                    ) {
                                                        Row(
                                                            modifier = Modifier.fillMaxWidth(),
                                                            verticalAlignment = Alignment.CenterVertically
                                                        ) {
                                                            MaterialIcon(
                                                                modifier = Modifier.margin(right = 8.px),
                                                                icon = MaterialIcons.Round.Mail,
                                                                size = 18.px,
                                                                tint = sitePalette().onSurface
                                                                    .changeAlpha(0.8f)
                                                            )
                                                            Text(
                                                                modifier = Modifier
                                                                    .overflow { x(Overflow.Hidden) }
                                                                    .whiteSpace(WhiteSpace.NoWrap)
                                                                    .fillMaxWidth()
                                                                    .textOverflow(TextOverflow.Ellipsis)
                                                                    .bodySmall()
                                                                    .color(
                                                                        sitePalette().onSurface.changeAlpha(0.8f)
                                                                    ),
                                                                text = user.email
                                                            )
                                                        }

                                                        Row(
                                                            verticalAlignment = Alignment.CenterVertically
                                                        ) {
                                                            MaterialIcon(
                                                                modifier = Modifier.margin(right = 8.px),
                                                                icon = MaterialIcons.Round.Phone,
                                                                size = 18.px,
                                                                tint = sitePalette().onSurface
                                                                    .changeAlpha(0.8f)
                                                            )
                                                            Text(
                                                                modifier = Modifier
                                                                    .noWrap()
                                                                    .bodySmall()
                                                                    .color(
                                                                        sitePalette().onSurface.changeAlpha(
                                                                            0.8f
                                                                        )
                                                                    ),
                                                                text = user.phoneNumber.toBrazilianPhoneFormat()
                                                            )
                                                        }

                                                        Row(
                                                            verticalAlignment = Alignment.CenterVertically
                                                        ) {
                                                            MaterialIcon(
                                                                modifier = Modifier.margin(right = 8.px),
                                                                icon = MaterialIcons.Round.Fingerprint,
                                                                size = 18.px,
                                                                tint = sitePalette().onSurface.changeAlpha(
                                                                    0.8f
                                                                )
                                                            )
                                                            Text(
                                                                modifier = Modifier
                                                                    .fillMaxWidth()
                                                                    .overflow { x(Overflow.Hidden) }
                                                                    .whiteSpace(WhiteSpace.NoWrap)
                                                                    .fillMaxWidth()
                                                                    .textOverflow(TextOverflow.Ellipsis)
                                                                    .labelSmall()
                                                                    .color(
                                                                        sitePalette().onSurface.changeAlpha(
                                                                            0.8f
                                                                        )
                                                                    ), text = "Id: ${user.uid}"
                                                            )
                                                        }

                                                        Row(
                                                            modifier = Modifier
                                                                .fillMaxWidth()
                                                                .margin(8.px),
                                                            horizontalArrangement = Arrangement.End
                                                        ) {
                                                            if (!uiState.isOnUserDeletionMode) {
                                                                MaterialIcon(
                                                                    modifier = Modifier
                                                                        .cursor(Cursor.Pointer)
                                                                        .padding(all = 8.px)
                                                                        .margin(right = 8.px)
                                                                        .clip(Circle())
                                                                        .hoverBackground(
                                                                            onMouseEnterBackgroundColor = sitePalette().secondaryContainer.changeAlpha(
                                                                                0.8f
                                                                            ),
                                                                            onMouseExitBackgroundColor = sitePalette().surfaceVariant,
                                                                        )
                                                                        .onClick {
                                                                            navigationController.navigate(
                                                                                routeName = "user_maker",
                                                                                arguments = argumentsBundleOf(
                                                                                    "userModel" to stateHolder.getUserModel(
                                                                                        user.uid
                                                                                    )
                                                                                )
                                                                            )
                                                                        },
                                                                    icon = MaterialIcons.Round.Edit
                                                                )
                                                                Tooltip(
                                                                    target = ElementTarget.PreviousSibling,
                                                                    text = "Editar",
                                                                    placement = PopupPlacement.Left
                                                                )
                                                            }
                                                        }
                                                    }
                                                }
                                            )
                                        }
                                    }
                                }
                            }
                        }

                        if (showNavDrawer) {
                            Row(
                                modifier = Modifier
                                    .zIndex(90)
                                    .backgroundColor(sitePalette().background.darkened().changeAlpha(0.8f))
                                    .fillMaxSize()
                            ) {
                                NavDrawer(
                                    selectedMenu = uiState.selectedMenu,
                                    onEvent = { event -> stateHolder.onEvent(event) },
                                    navigationController = navigationController,
                                    navDrawerWidth = 100.percent.minus(80.px),
                                    onItemClick = { showNavDrawer = false }
                                )

                                Box(
                                    modifier = Modifier
                                        .size(40.px)
                                        .margin(top = 16.px, left = 16.px, right = 24.px)
                                        .clip(Rect(40.px))
                                        .hoverBackground(
                                            onMouseEnterBackgroundColor = sitePalette().primary.changeAlpha(0.8f),
                                            onMouseExitBackgroundColor = sitePalette().primary
                                        )
                                        .cursor(Cursor.Pointer)
                                        .borderRadius(40.px)
                                        .onClick {
                                            showNavDrawer = false
                                        },
                                    contentAlignment = Alignment.Center
                                ) {
                                    MaterialIcon(
                                        icon = MaterialIcons.Round.Menu,
                                        tint = sitePalette().onPrimary
                                    )
                                }
                            }
                        }

                    }
                } else {
                    Row(
                        modifier = Modifier.fillMaxSize()
                    ) {
                        NavDrawer(
                            selectedMenu = uiState.selectedMenu,
                            onEvent = { event -> stateHolder.onEvent(event) },
                            navigationController = navigationController,
                        )

                        if (uiState.users.isEmpty()) {
                            Column(
                                modifier = Modifier
                                    .padding(top = 24.px, right = 24.px)
                                    .fillMaxSize(),
                                horizontalAlignment = Alignment.CenterHorizontally,
                                verticalArrangement = Arrangement.Center
                            ) {
                                MaterialIcon(
                                    icon = MaterialIcons.Round.People,
                                    size = 48.px,
                                    tint = sitePalette().onSurface
                                )
                                Text(
                                    modifier = Modifier
                                        .margin(top = 16.px)
                                        .textAlign(TextAlign.Center)
                                        .color(sitePalette().onSurface)
                                        .titleLarge(),
                                    text = "Nenhum membro cadastrado"
                                )
                                Text(
                                    modifier = Modifier
                                        .titleMedium()
                                        .textAlign(TextAlign.Center)
                                        .color(sitePalette().onSurface),
                                    text = "Você pode adicionar um membro clicando ao lado em \"Adicionar membro\""
                                )
                            }
                        } else {

                            Column(
                                modifier = Modifier.padding(top = 24.px).fillMaxSize()
                            ) {
                                SearchBar(modifier = Modifier.fillMaxWidth().padding(horizontal = 24.px)
                                    .margin(bottom = 16.px),
                                    sorting = SearchBarSorting(selected = uiState.searchSorting,
                                        options = uiState.searchSortingOptions,
                                        onSort = {
                                            stateHolder.onEvent(
                                                UsersUIContract.Event.OnFilterChange(it)
                                            )
                                        }),
                                    hint = "Pesquise por nome, telefone ou email",
                                    query = uiState.searchQuery,
                                    onClear = {
                                        stateHolder.onEvent(
                                            UsersUIContract.Event.OnClearSearchQuery
                                        )
                                    }) {
                                    stateHolder.onEvent(
                                        UsersUIContract.Event.OnSearchQueryChange(it)
                                    )
                                }

                                if (uiState.isOnUserDeletionMode) {
                                    Row(
                                        modifier = Modifier.fillMaxWidth().padding(horizontal = 24.px)
                                            .margin(bottom = 16.px),
                                        verticalAlignment = Alignment.CenterVertically
                                    ) {
                                        Text(
                                            modifier = Modifier.color(sitePalette().primary).titleMedium()
                                                .noWrap(), text = "Apagar membros da equipe"
                                        )
                                        Spacer(Modifier.fillMaxWidth())
                                        ToolbarButton(
                                            text = "Apagar",
                                            enabled = uiState.enableDeleteUsersButton,
                                            icon = MaterialIcons.Round.DeleteOutline
                                        ) {
                                            stateHolder.onEvent(UsersUIContract.Event.OnDeleteUserClick)
                                        }
                                        ToolbarButton(
                                            text = "Marcar todos", enabled = true, icon = MaterialIcons.Round.SelectAll
                                        ) {
                                            stateHolder.onEvent(UsersUIContract.Event.OnSelectAllUsersForDeletion)
                                        }
                                        ToolbarButton(
                                            text = "Cancelar", enabled = true, icon = MaterialIcons.Round.Close
                                        ) {
                                            stateHolder.onEvent(UsersUIContract.Event.OnCancelDeletingUsers)
                                        }
                                    }
                                }

                                Column(
                                    modifier = Modifier.padding(right = 24.px, bottom = 24.px, left = 24.px)
                                        .overflow { y(Overflow.Auto) }.display(DisplayStyle.Flex).gap(16.px)
                                        .fillMaxWidth()
                                ) {
                                    uiState.users.filter { user ->
                                        user.name.contains(
                                            uiState.searchQuery,
                                            ignoreCase = true
                                        ) || user.email.contains(
                                            uiState.searchQuery, ignoreCase = true
                                        ) || user.phoneNumber.contains(uiState.searchQuery, ignoreCase = true)
                                    }.sortedWith { user1, user2 ->
                                        when (uiState.searchSorting) {
                                            "Nome" -> user1.name.compareTo(user2.name)
                                            "Email" -> user1.email.compareTo(user2.email)
                                            else -> user1.phoneNumber.compareTo(user2.phoneNumber)
                                        }
                                    }.forEach { user ->
                                        Row(
                                            modifier = Modifier.fillMaxWidth(),
                                            verticalAlignment = Alignment.CenterVertically
                                        ) {
                                            if (uiState.isOnUserDeletionMode) {
                                                Checkbox(
                                                    modifier = Modifier
                                                        .margin(right = 16.px),
                                                    checked = user.checked,
                                                    onCheck = {
                                                        stateHolder.onEvent(
                                                            UsersUIContract.Event.OnUserSelectedForDeletion(user.uid)
                                                        )
                                                    }
                                                )
                                            }
                                            CardWithIcon(
                                                modifier = Modifier.fillMaxWidth(),
                                                icon = user.roleIcon
                                            ) {
                                                Row(
                                                    modifier = Modifier.fillMaxWidth(),
                                                    verticalAlignment = Alignment.Bottom
                                                ) {
                                                    Column {
                                                        Text(
                                                            modifier = Modifier
                                                                .color(sitePalette().onSurface)
                                                                .titleMedium()
                                                                .noWrap(),
                                                            text = user.name
                                                        )
                                                        Row(
                                                            verticalAlignment = Alignment.CenterVertically
                                                        ) {
                                                            MaterialIcon(
                                                                modifier = Modifier.margin(right = 8.px),
                                                                icon = MaterialIcons.Round.Mail,
                                                                size = 18.px,
                                                                tint = sitePalette().onSurface
                                                                    .changeAlpha(0.8f)
                                                            )
                                                            Text(
                                                                modifier = Modifier.noWrap().titleSmall().color(
                                                                    sitePalette().onSurface
                                                                        .changeAlpha(0.8f)
                                                                ),
                                                                text = user.email
                                                            )
                                                        }

                                                        Row(
                                                            verticalAlignment = Alignment.CenterVertically
                                                        ) {
                                                            MaterialIcon(
                                                                modifier = Modifier.margin(right = 8.px),
                                                                icon = MaterialIcons.Round.Phone,
                                                                size = 18.px,
                                                                tint = sitePalette().onSurface
                                                                    .changeAlpha(0.8f)
                                                            )
                                                            Text(
                                                                modifier = Modifier
                                                                    .noWrap()
                                                                    .titleSmall()
                                                                    .color(
                                                                        sitePalette().onSurface.changeAlpha(
                                                                            0.8f
                                                                        )
                                                                    ),
                                                                text = user.phoneNumber.toBrazilianPhoneFormat()
                                                            )
                                                        }

                                                        Row(
                                                            verticalAlignment = Alignment.CenterVertically
                                                        ) {
                                                            MaterialIcon(
                                                                modifier = Modifier.margin(right = 8.px),
                                                                icon = MaterialIcons.Round.Fingerprint,
                                                                size = 18.px,
                                                                tint = sitePalette().onSurface.changeAlpha(
                                                                    0.8f
                                                                )
                                                            )
                                                            Text(
                                                                modifier = Modifier.fillMaxWidth().noWrap()
                                                                    .labelMedium()
                                                                    .color(
                                                                        sitePalette().onSurface.changeAlpha(
                                                                            0.8f
                                                                        )
                                                                    ), text = "Identificador: ${user.uid}"
                                                            )
                                                        }
                                                    }
                                                    if (!uiState.isOnUserDeletionMode) {
                                                        Spacer(modifier = Modifier.fillMaxWidth())
                                                        MaterialIcon(
                                                            modifier = Modifier
                                                                .cursor(Cursor.Pointer)
                                                                .padding(all = 8.px)
                                                                .margin(right = 8.px)
                                                                .clip(Circle())
                                                                .hoverBackground(
                                                                    onMouseEnterBackgroundColor = sitePalette().secondaryContainer.changeAlpha(
                                                                        0.8f
                                                                    ),
                                                                    onMouseExitBackgroundColor = sitePalette().surfaceVariant,
                                                                )
                                                                .onClick {
                                                                    navigationController.navigate(
                                                                        routeName = "user_maker",
                                                                        arguments = argumentsBundleOf(
                                                                            "userModel" to stateHolder.getUserModel(user.uid)
                                                                        )
                                                                    )
                                                                },
                                                            icon = MaterialIcons.Round.Edit
                                                        )
                                                        Tooltip(
                                                            target = ElementTarget.PreviousSibling,
                                                            text = "Editar",
                                                            placement = PopupPlacement.Left
                                                        )
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            is UsersUIContract.State.Failure -> FailureState()

            is UsersUIContract.State.Loading -> {
                Column(
                    modifier = Modifier.fillMaxSize(),
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    CircularProgress(50)
                    Text("Carregando")
                }
            }
        }
    }
}

@Composable
private fun FailureState() {
    Column(
        modifier = Modifier.fillMaxSize(),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        MaterialIcon(MaterialIcons.Round.Error)
        Text("Houve uma falha ao carregar os dados!")
    }
}

@Composable
private fun NavDrawer(
    selectedMenu: String,
    onEvent: (UsersUIContract.Event) -> Unit,
    navigationController: NavigationController,
    navDrawerWidth: CSSNumeric = 360.px,
    onItemClick: (String) -> Unit = {},
) {
    Column(
        modifier = Modifier
            .background(sitePalette().surfaceVariant)
            .padding(
                horizontal = 12.px,
                vertical = 24.px
            )
            .roundedCornerClip(
                topRight = 16.px,
                bottomRight = 16.px
            )
            .fillMaxHeight()
            .width(navDrawerWidth)
    ) {
        NavDrawerButton(
            icon = MaterialIcons.Round.AddCircleOutline,
            label = "Adicionar membro",
            selected = selectedMenu == "user_maker",
            onClick = {
                onItemClick("user_maker")
                navigationController.navigate("user_maker")
            }
        )
        NavDrawerButton(
            icon = MaterialIcons.Round.DeleteOutline,
            label = "Apagar membro",
            selected = selectedMenu == "delete_users",
            onClick = {
                onItemClick("delete_users")
                if (selectedMenu != "delete_users") {
                    onEvent(UsersUIContract.Event.OnMenuDeleteUserClick)
                }
            }
        )
    }
}