package com.catbit.opinionpoll.core.ui.composables.base_components

import androidx.compose.runtime.Composable
import com.catbit.opinionpoll.core.ui.modifiers.margin
import com.catbit.opinionpoll.core.ui.modifiers.titleMedium
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.classNames
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.max
import org.jetbrains.compose.web.attributes.min
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input

@Composable
fun Slider(
    modifier: Modifier = Modifier,
    value: Int,
    minValue: Int,
    maxValue: Int,
    onValueChange: (Int) -> Unit
) {
    Column(
        modifier = modifier
            .display(DisplayStyle.Flex)
            .gap(8.px)
    ) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .margin(horizontal = 4.px),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            (minValue..maxValue).forEach {
                Text(
                    modifier = Modifier.titleMedium(),
                    text = it.toString()
                )
            }
        }
        Input(
            type = InputType.Range,
            attrs = Modifier
                .classNames("slider")
                .fillMaxWidth()
                .toAttrs {
                    value(value)
                    min(minValue.toString())
                    max(maxValue.toString())
                    onInput { onValueChange(it.value?.toInt() ?: value) }
                }
        )
    }
}