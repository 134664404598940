package com.catbit.opinionpoll.domain.forms

import com.catbit.opinionpoll.core.domain.UseCase
import com.catbit.opinionpoll.data.repositories.OpinionPollRepository

class DeleteFormsUseCase(
    private val repository: OpinionPollRepository
) : UseCase<Unit, DeleteFormsUseCase.Params>() {

    override suspend fun execute(params: Params) =
        repository.deleteForms(formIdentifiers = params.formIdentifiers)

    data class Params(
        val formIdentifiers: List<String>
    )
}