package com.catbit.opinionpoll.inputs.events

import kotlinx.datetime.LocalTime

sealed interface TimeInputEventData {

    data class OnMinTimeChange(
        val newMinTime: LocalTime?
    ) : TimeInputEventData

    data class OnMaxTimeChange(
        val newMaxTime: LocalTime?
    ) : TimeInputEventData

}