package com.catbit.opinionpoll.ui.screens.form_maker

import com.catbit.opinionpoll.inputs.InputType
import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.events.InputUIEvent
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.internal.JSJoda.ZoneId
import kotlinx.datetime.internal.JSJoda.LocalDateTime as JodaLocalDateTime

// TODO Dialog ao sair sem enviar

interface FormMakerUIContract {
    sealed interface State {

        val toolbarTitle: String

        data class Displaying(
            override val toolbarTitle: String,
            val title: String,
            val identifier: String,
            val expirationDate: LocalDateTime,
            val sendGeoCoordinates: Boolean,
            val inputs: List<InputUIState>,
            val tips: String?,
            val version: Long,
            val searchQuery: String,
        ) : State {
            companion object {
                fun default() = Displaying(
                    toolbarTitle = "Adicionar formulário",
                    title = "",
                    identifier = "",
                    expirationDate = with(JodaLocalDateTime.now(ZoneId.SYSTEM).plusDays(30)) {
                        LocalDateTime.parse(this.toString())
                    },
                    sendGeoCoordinates = false,
                    inputs = listOf(),
                    version = 1,
                    tips = null,
                    searchQuery = "",
                )
            }
        }

        data class Loading(
            override val toolbarTitle: String,
        ) : State

        data class Failure(
            override val toolbarTitle: String,
        ) : State
    }

    sealed interface Effect {
        data object OnStartSubmittingForm : Effect
        data object OnScrollToBottomEffect : Effect
        data class OnFormSubmissionSuccess(
            val message: String
        ) : Effect

        data class OnFormSubmissionFailure(
            val message: String
        ) : Effect
    }

    sealed interface Event {
        data class OnFormUIEvent(
            val inputUIEvent: InputUIEvent
        ) : Event

        data class OnTitleChange(
            val newValue: String
        ) : Event

        data class OnSendGeoCoordinatesToggle(
            val newValue: Boolean
        ) : Event

        data class OnExpirationDateChange(
            val newValue: LocalDateTime
        ) : Event

        data class OnAddFormClick(
            val inputType: InputType
        ) : Event

        data class OnRemoveFormClick(
            val identifier: String
        ) : Event

        data class OnMoveFormUp(
            val identifier: String
        ) : Event

        data class OnMoveFormDown(
            val identifier: String
        ) : Event

        data class OnDuplicateFormUp(
            val identifier: String
        ) : Event

        data class OnDuplicateFormDown(
            val identifier: String
        ) : Event

        data class OnSearch(
            val newQuery: String
        ) : Event

        data object OnClearSearch : Event

        data object OnClearAllForms : Event

        data object OnSendFormClick : Event
    }
}