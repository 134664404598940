package com.catbit.opinionpoll.inputs.requests.producers

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.inputs.NumericAreaInputUIState
import com.catbit.opinionpoll.inputs.requests.requests.NumericAreaInputRequest

class NumericAreaInputRequestProducer : InputRequestProducer<NumericAreaInputUIState> {
    override fun match(inputUIState: InputUIState) = inputUIState is NumericAreaInputUIState

    override fun produce(inputUIState: NumericAreaInputUIState) = NumericAreaInputRequest(
        identifier = inputUIState.identifier,
        index = inputUIState.index,
        title = inputUIState.title,
        subtitle = inputUIState.subtitle,
        isObligatory = inputUIState.isObligatory,
        minValue = inputUIState.minValue,
        maxValue = inputUIState.maxValue,
        symbol = inputUIState.symbol,
        symbolPosition = inputUIState.symbolPosition.name.lowercase(),
        decimalPoints = inputUIState.decimalPoints,
    )
}