package com.catbit.opinionpoll.core.ui.composables.base_components

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.extensions.withNotNull
import com.catbit.opinionpoll.core.ui.modifiers.*
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.maxLength
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input

@Composable
fun TextField(
    modifier: Modifier = Modifier,
    text: String,
    onTextChanged: (String) -> Unit,
    onKeyDown: ((String) -> Unit)? = null,
    onEnterPress: (() -> Unit)? = null,
    isPassword: Boolean = false,
    inputIdentifier: String? = null,
    hint: String? = null,
    enabled: Boolean = true,
    maxLength: Int? = null,
    backgroundColor: Color = sitePalette().surfaceVariant,
    leadingIcon: (@Composable () -> Unit)? = null,
    trailingIcon: (@Composable () -> Unit)? = null
) {

    var hasFocus by remember { mutableStateOf(false) }

    Row(
        modifier = modifier
            .padding(all = 16.px)
            .height(48.px)
            .borderRadius(8.px)
            .thenIf(hasFocus) {
                border(
                    color = sitePalette().primary,
                    style = LineStyle.Solid,
                    width = 2.px
                )
            }
            .backgroundColor(backgroundColor),
        verticalAlignment = Alignment.CenterVertically
    ) {
        withNotNull(leadingIcon) {
            Box(
                modifier = Modifier
                    .noWrap()
                    .size(24.px)
                    .margin(right = 16.px)
            ) { this@withNotNull() }
        }

        Input(
            type = if (isPassword) InputType.Password else InputType.Text,
            attrs = Modifier
                .color(sitePalette().onSurfaceVariant)
                .caretColor(sitePalette().primary)
                .background(backgroundColor)
                .bodyLarge()
                .ariaDisabled(enabled.not())
                .fillMaxWidth()
                .onFocusIn { hasFocus = true }
                .onFocusOut { hasFocus = false }
                .noBorder()
                .noOutline()
                .toAttrs {
                    inputIdentifier?.let(::id)
                    value(text)
                    maxLength?.let(::maxLength)
                    hint?.let(::placeholder)
                    onInput { evt ->
                        onTextChanged(inputType.inputValue(evt.nativeEvent))
                    }
                    onKeyDown { evt ->
                        if (evt.key == "Enter") {
                            onEnterPress?.invoke()
                        } else {
                            onKeyDown?.invoke(evt.key)
                        }
                    }
                }
        )

        withNotNull(trailingIcon) {
            Box(
                modifier = Modifier
                    .size(24.px)
                    .margin(left = 16.px)
            ) { this@withNotNull() }
        }
    }
}