package com.catbit.opinionpoll.inputs.models

import com.catbit.opinionpoll.inputs.InputUIState
import kotlinx.serialization.Serializable

@Serializable
sealed interface InputModel {
    val identifier: String
    val title: String
    val subtitle: String?
    val isObligatory: Boolean
    val index: Int

    val titleToIdentifierMapping: List<Pair<String, String>>
    fun toUIState(): InputUIState
    fun knowsIdentifier(identifier: String): Boolean
    val identifiers: List<String>
}