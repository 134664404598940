package com.catbit.opinionpoll.inputs.requests.producers

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.inputs.DateTimeInputUIState
import com.catbit.opinionpoll.inputs.requests.requests.DateTimeInputRequest

class DateTimeInputRequestProducer : InputRequestProducer<DateTimeInputUIState> {
    override fun match(inputUIState: InputUIState) = inputUIState is DateTimeInputUIState

    override fun produce(inputUIState: DateTimeInputUIState) = DateTimeInputRequest(
        identifier = inputUIState.identifier,
        index = inputUIState.index,
        title = inputUIState.title,
        subtitle = inputUIState.subtitle,
        isObligatory = inputUIState.isObligatory,
        minDateTime = inputUIState.minDateTime?.toString(),
        maxDateTime = inputUIState.maxDateTime?.toString()
    )
}