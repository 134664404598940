package com.catbit.opinionpoll.core.charts.data

import com.varabyte.kobweb.compose.ui.graphics.Color

data class ChartData(
    val title: String,
    val dataSet: List<Data>
) {
    data class Data(
        val value: Number,
        val label: String,
        val color: Color
    )
}
