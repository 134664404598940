package com.catbit.opinionpoll.inputs.inputs

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.extensions.withNotNull
import com.catbit.opinionpoll.core.sailor.providers.LocalDialogStateProvider
import com.catbit.opinionpoll.core.ui.composables.HoverableContent
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.composables.base_components.*
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.*
import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.inputs.InputType
import com.catbit.opinionpoll.inputs.InputUI
import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.composables.DefaultFormContainer
import com.catbit.opinionpoll.inputs.composables.DefaultFormPreview
import com.catbit.opinionpoll.inputs.events.CompactSingleChoiceInputEventData
import com.catbit.opinionpoll.inputs.events.InputUIEvent
import com.catbit.opinionpoll.res.Strings
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.document
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.px

class CompactSingleChoiceInput : InputUI<CompactSingleChoiceInputUIState> {
    override fun match(uiState: InputUIState) = uiState is CompactSingleChoiceInputUIState

    @Composable
    override fun Compose(uiState: CompactSingleChoiceInputUIState, onEvent: (InputUIEvent) -> Unit) {

        val dialogState = LocalDialogStateProvider.current
        var textAreaValue by remember { mutableStateOf("") }
        var hadUserInteraction by remember { mutableStateOf(false) }

        LaunchedEffect(uiState.options.lastOrNull()?.actualIdentifier) {
            withNotNull(uiState.options.lastOrNull()?.actualIdentifier) {
                if (hadUserInteraction) {
                    document.getElementById(this)?.focus()
                }
            }
        }

        LaunchedEffect(uiState.questions.lastOrNull()?.actualIdentifier) {
            withNotNull(uiState.questions.lastOrNull()?.actualIdentifier) {
                if (hadUserInteraction) {
                    document.getElementById(this)?.focus()
                }
            }
        }

        DefaultFormContainer(
            modifier = Modifier.fillMaxWidth(),
            inputUIState = uiState,
            onEvent = onEvent,
            preview = { CompactSingleChoiceInputPreview(uiState) }
        ) {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(top = 16.px)
            ) {
                Row(
                    verticalAlignment = Alignment.Bottom
                ) {
                    Checkbox(
                        modifier = Modifier.margin(right = 8.px),
                        enabled = uiState.isEnabled,
                        checked = uiState.showSearchBar,
                        onCheck = {
                            onEvent(
                                InputUIEvent(
                                    formIdentifier = uiState.actualIdentifier,
                                    data = CompactSingleChoiceInputEventData.OnShowSearchBarToggle(it)
                                )
                            )
                        }
                    )
                    Text(
                        modifier = Modifier
                            .titleMedium()
                            .fontWeight(FontWeight.Bold),
                        text = Strings.displaySearchBar
                    )
                }
                Text(
                    modifier = Modifier
                        .titleMedium()
                        .fontWeight(FontWeight.Bold)
                        .margin(top = 16.px),
                    text = Strings.questions
                )
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(top = 8.px)
                        .display(DisplayStyle.Flex)
                        .gap(8.px)
                ) {
                    uiState.questions.forEach { question ->
                        Row(
                            modifier = Modifier.fillMaxWidth(),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            TextField(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .margin(right = 8.px),
                                text = question.identifier,
                                enabled = uiState.isEnabled,
                                hint = Strings.identifier,
                                onTextChanged = {
                                    onEvent(
                                        InputUIEvent(
                                            formIdentifier = uiState.actualIdentifier,
                                            data = CompactSingleChoiceInputEventData.OnQuestionActualIdentifierChange(
                                                actualIdentifier = question.actualIdentifier,
                                                newIdentifier = it
                                            )
                                        )
                                    )
                                },
                                trailingIcon = {
                                    MaterialIcon(MaterialIcons.Round.Help)
                                    Tooltip(
                                        target = ElementTarget.PreviousSibling,
                                        text = Strings.identifierTooltip,
                                        placement = PopupPlacement.BottomLeft
                                    )
                                }
                            )
                            TextField(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .margin(right = 8.px),
                                text = question.title,
                                enabled = uiState.isEnabled,
                                hint = Strings.question,
                                inputIdentifier = question.actualIdentifier,
                                onTextChanged = {
                                    onEvent(
                                        InputUIEvent(
                                            formIdentifier = uiState.actualIdentifier,
                                            data = CompactSingleChoiceInputEventData.OnQuestionTitleChange(
                                                actualIdentifier = question.actualIdentifier,
                                                newTitle = it
                                            )
                                        )
                                    )
                                },
                                onEnterPress = {
                                    hadUserInteraction = true
                                    onEvent(
                                        InputUIEvent(
                                            formIdentifier = uiState.actualIdentifier,
                                            data = CompactSingleChoiceInputEventData.OnAddQuestion
                                        )
                                    )
                                }
                            )
                            IconButton(
                                onClick = {
                                    onEvent(
                                        InputUIEvent(
                                            formIdentifier = uiState.actualIdentifier,
                                            data = CompactSingleChoiceInputEventData.OnMoveQuestionUp(
                                                actualIdentifier = question.actualIdentifier
                                            )
                                        )
                                    )
                                }
                            ) {
                                MaterialIcon(MaterialIcons.Round.ArrowCircleUp)
                            }
                            Tooltip(
                                target = ElementTarget.PreviousSibling,
                                text = Strings.moveQuestionUp
                            )
                            IconButton(
                                onClick = {
                                    onEvent(
                                        InputUIEvent(
                                            formIdentifier = uiState.actualIdentifier,
                                            data = CompactSingleChoiceInputEventData.OnMoveQuestionDown(
                                                actualIdentifier = question.actualIdentifier
                                            )
                                        )
                                    )
                                }
                            ) {
                                MaterialIcon(MaterialIcons.Round.ArrowCircleDown)
                            }
                            Tooltip(
                                target = ElementTarget.PreviousSibling,
                                text = Strings.moveQuestionDown
                            )
                            IconButton(
                                onClick = {
                                    onEvent(
                                        InputUIEvent(
                                            formIdentifier = uiState.actualIdentifier,
                                            data = CompactSingleChoiceInputEventData.OnQuestionRemove(
                                                actualIdentifier = question.actualIdentifier
                                            )
                                        )
                                    )
                                },
                                enabled = uiState.isEnabled
                            ) {
                                MaterialIcon(MaterialIcons.Round.DeleteOutline)
                            }
                            Tooltip(
                                target = ElementTarget.PreviousSibling,
                                text = Strings.removeQuestion
                            )
                        }
                    }
                }

                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(top = 8.px),
                    horizontalArrangement = Arrangement.Center
                ) {
                    IconButton(
                        onClick = {
                            hadUserInteraction = true
                            onEvent(
                                InputUIEvent(
                                    formIdentifier = uiState.actualIdentifier,
                                    data = CompactSingleChoiceInputEventData.OnAddQuestion
                                )
                            )
                        }
                    ) {
                        MaterialIcon(MaterialIcons.Round.AddCircleOutline)
                    }
                    Tooltip(
                        target = ElementTarget.PreviousSibling,
                        text = Strings.addQuestion
                    )

                    IconButton(
                        onClick = {
                            dialogState.show {
                                Column(
                                    modifier = Modifier.fillMaxSize()
                                ) {
                                    Text(
                                        text = "Adicionar lista de opções",
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .headlineSmall()
                                    )
                                    Text(
                                        text = "Adicione valores separados por quebra de linha ou por \";\"",
                                        modifier = Modifier
                                            .margin(top = 16.px)
                                            .fillMaxWidth()
                                            .bodyMedium()
                                    )
                                    TextArea(
                                        modifier = Modifier
                                            .fillMaxSize()
                                            .margin(top = 16.px),
                                        text = textAreaValue,
                                        lines = 10,
                                        onTextChanged = { textAreaValue = it }
                                    )
                                    Row(
                                        modifier = Modifier
                                            .margin(top = 24.px)
                                            .fillMaxWidth(),
                                        horizontalArrangement = Arrangement.End
                                    ) {
                                        HoverableContent(
                                            modifier = Modifier.padding(horizontal = 16.px, vertical = 8.px),
                                            onHoverEndColor = sitePalette().surface,
                                            onClick = {
                                                dialogState.dismiss()
                                                textAreaValue = ""
                                            }
                                        ) {
                                            Text(
                                                text = Strings.cancel,
                                                modifier = Modifier.bodyLarge()
                                            )
                                        }
                                        HoverableContent(
                                            modifier = Modifier.padding(horizontal = 16.px, vertical = 8.px),
                                            onHoverEndColor = sitePalette().surface,
                                            onClick = {
                                                hadUserInteraction = true
                                                onEvent(
                                                    InputUIEvent(
                                                        formIdentifier = uiState.actualIdentifier,
                                                        data = CompactSingleChoiceInputEventData.OnAddBatchQuestions(
                                                            rawText = textAreaValue
                                                        )
                                                    )
                                                )
                                                dialogState.dismiss()
                                                textAreaValue = ""
                                            }
                                        ) {
                                            Text(
                                                text = Strings.add,
                                                modifier = Modifier.bodyLarge()
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    ) {
                        MaterialIcon(MaterialIcons.Round.PostAdd)
                    }
                    Tooltip(
                        target = ElementTarget.PreviousSibling,
                        text = Strings.addQuestionsInBatch
                    )
                }

                Text(
                    modifier = Modifier
                        .margin(top = 16.px)
                        .titleMedium()
                        .fontWeight(FontWeight.Bold),
                    text = Strings.options
                )

                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(top = 8.px)
                        .display(DisplayStyle.Flex)
                        .gap(8.px)
                ) {
                    uiState.options.forEach { option ->
                        Row(
                            modifier = Modifier
                                .fillMaxWidth(),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            TextField(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .margin(right = 8.px),
                                text = option.title,
                                hint = Strings.option,
                                inputIdentifier = option.actualIdentifier,
                                onTextChanged = {
                                    onEvent(
                                        InputUIEvent(
                                            formIdentifier = uiState.actualIdentifier,
                                            data = CompactSingleChoiceInputEventData.OnOptionTitleChange(
                                                actualIdentifier = option.actualIdentifier,
                                                newTitle = it
                                            )
                                        )
                                    )
                                },
                                onEnterPress = {
                                    hadUserInteraction = true
                                    onEvent(
                                        InputUIEvent(
                                            formIdentifier = uiState.actualIdentifier,
                                            data = CompactSingleChoiceInputEventData.OnAddOption
                                        )
                                    )
                                }
                            )
                            IconButton(
                                onClick = {
                                    onEvent(
                                        InputUIEvent(
                                            formIdentifier = uiState.actualIdentifier,
                                            data = CompactSingleChoiceInputEventData.OnMoveOptionUp(
                                                actualIdentifier = option.actualIdentifier
                                            )
                                        )
                                    )
                                }
                            ) {
                                MaterialIcon(MaterialIcons.Round.ArrowCircleUp)
                            }
                            Tooltip(
                                target = ElementTarget.PreviousSibling,
                                text = Strings.moveOptionUp
                            )
                            IconButton(
                                onClick = {
                                    onEvent(
                                        InputUIEvent(
                                            formIdentifier = uiState.actualIdentifier,
                                            data = CompactSingleChoiceInputEventData.OnMoveOptionDown(
                                                actualIdentifier = option.actualIdentifier
                                            )
                                        )
                                    )
                                }
                            ) {
                                MaterialIcon(MaterialIcons.Round.ArrowCircleDown)
                            }
                            Tooltip(
                                target = ElementTarget.PreviousSibling,
                                text = Strings.moveOptionDown
                            )
                            IconButton(
                                onClick = {
                                    onEvent(
                                        InputUIEvent(
                                            formIdentifier = uiState.actualIdentifier,
                                            data = CompactSingleChoiceInputEventData.OnOptionRemove(
                                                actualIdentifier = option.actualIdentifier
                                            )
                                        )
                                    )
                                }
                            ) {
                                MaterialIcon(MaterialIcons.Round.DeleteOutline)
                            }
                            Tooltip(
                                target = ElementTarget.PreviousSibling,
                                text = Strings.removeOption
                            )
                        }
                    }
                }
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(top = 8.px),
                    horizontalArrangement = Arrangement.Center
                ) {
                    IconButton(
                        onClick = {
                            hadUserInteraction = true
                            onEvent(
                                InputUIEvent(
                                    formIdentifier = uiState.actualIdentifier,
                                    data = CompactSingleChoiceInputEventData.OnAddOption
                                )
                            )
                        }
                    ) {
                        MaterialIcon(MaterialIcons.Round.AddCircleOutline)
                    }
                    Tooltip(
                        target = ElementTarget.PreviousSibling,
                        text = Strings.addOption
                    )
                    IconButton(
                        onClick = {
                            dialogState.show {
                                Column(
                                    modifier = Modifier.fillMaxSize()
                                ) {
                                    Text(
                                        text = "Adicionar lista de opções",
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .headlineSmall()
                                    )
                                    Text(
                                        text = "Adicione valores separados por quebra de linha ou por \";\"",
                                        modifier = Modifier
                                            .margin(top = 16.px)
                                            .fillMaxWidth()
                                            .bodyMedium()
                                    )
                                    TextArea(
                                        modifier = Modifier
                                            .fillMaxSize()
                                            .margin(top = 16.px),
                                        text = textAreaValue,
                                        lines = 10,
                                        onTextChanged = { textAreaValue = it }
                                    )
                                    Row(
                                        modifier = Modifier
                                            .margin(top = 24.px)
                                            .fillMaxWidth(),
                                        horizontalArrangement = Arrangement.End
                                    ) {
                                        HoverableContent(
                                            modifier = Modifier.padding(horizontal = 16.px, vertical = 8.px),
                                            onHoverEndColor = sitePalette().surface,
                                            onClick = {
                                                dialogState.dismiss()
                                                textAreaValue = ""
                                            }
                                        ) {
                                            Text(
                                                text = Strings.cancel,
                                                modifier = Modifier.bodyLarge()
                                            )
                                        }
                                        HoverableContent(
                                            modifier = Modifier.padding(horizontal = 16.px, vertical = 8.px),
                                            onHoverEndColor = sitePalette().surface,
                                            onClick = {
                                                onEvent(
                                                    InputUIEvent(
                                                        formIdentifier = uiState.actualIdentifier,
                                                        data = CompactSingleChoiceInputEventData.OnAddBatchOptions(
                                                            rawText = textAreaValue
                                                        )
                                                    )
                                                )
                                                dialogState.dismiss()
                                                textAreaValue = ""
                                            }
                                        ) {
                                            Text(
                                                text = Strings.add,
                                                modifier = Modifier.bodyLarge()
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    ) {
                        MaterialIcon(MaterialIcons.Round.PostAdd)
                    }

                    Tooltip(
                        target = ElementTarget.PreviousSibling,
                        text = Strings.addOptionsInBatch
                    )
                }
            }
        }
    }
}

@Composable
fun CompactSingleChoiceInputPreview(
    uiState: CompactSingleChoiceInputUIState
) {
    Column(
        modifier = Modifier.fillMaxWidth()
    ) {
        val selectedOptions = remember { mutableStateMapOf<String, String>() }

        with(uiState) {
            DefaultFormPreview(
                index = index,
                title = title,
                subtitle = subtitle
            ) {
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .display(DisplayStyle.Flex)
                        .margin(top = 8.px)
                        .gap(16.px)
                ) {
                    questions.forEach { question ->
                        Column(
                            modifier = Modifier.fillMaxWidth()
                        ) {
                            Text(
                                modifier = Modifier.titleMedium(),
                                text = question.title
                            )
                            OutlinedDropdownList(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .margin(top = 8.px),
                                options = options.map { it.title },
                                value = selectedOptions[question.identifier].orEmpty(),
                                onOptionsSelected = {
                                    selectedOptions[question.identifier] = it
                                },
                            )
                        }
                    }
                }
            }
        }
    }
}

data class CompactSingleChoiceInputUIState(
    override val index: Int,
    override val isEnabled: Boolean,
    override val actualIdentifier: String,
    override val identifier: String,
    override val title: String,
    override val subtitle: String,
    override val isObligatory: Boolean,
    override val type: InputType,
    override val errorMessage: String?,
    val options: List<OptionInput>,
    val questions: List<QuestionInput>,
    val showSearchBar: Boolean
) : InputUIState {

    companion object {
        fun default(
            index: Int
        ) = CompactSingleChoiceInputUIState(
            index = index,
            isEnabled = true,
            actualIdentifier = UUID.stringUUID(),
            identifier = "",
            title = "",
            subtitle = "",
            isObligatory = false,
            type = InputType.CompactSingleChoice,
            errorMessage = null,
            options = listOf(),
            questions = listOf(),
            showSearchBar = false,
        )
    }

    override val internalIdentifiers = questions.map { it.identifier } + identifier
    override fun knowsIdentifier(identifier: String) =
        identifier == this.identifier || questions.any { it.identifier == identifier }

    override fun updateIndex(newIndex: Int) = copy(index = newIndex)
    override fun copy(
        index: Int?,
        isEnabled: Boolean?,
        actualIdentifier: String?,
        identifier: String?,
        title: String?,
        subtitle: String?,
        isObligatory: Boolean?,
        type: InputType?,
        error: String?
    ) = copy(
        index = index ?: this.index,
        isEnabled = isEnabled ?: this.isEnabled,
        actualIdentifier = actualIdentifier ?: this.actualIdentifier,
        identifier = identifier ?: this.identifier,
        title = title ?: this.title,
        subtitle = subtitle ?: this.subtitle,
        isObligatory = isObligatory ?: this.isObligatory,
        type = type ?: this.type,
        errorMessage = error ?: this.errorMessage,
    )
}