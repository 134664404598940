package com.catbit.opinionpoll.inputs.inputs

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.ui.composables.base_components.Text
import com.catbit.opinionpoll.core.ui.composables.base_components.TextField
import com.catbit.opinionpoll.core.ui.modifiers.titleMedium
import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.inputs.InputType
import com.catbit.opinionpoll.inputs.InputUI
import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.composables.DefaultFormContainer
import com.catbit.opinionpoll.inputs.composables.DefaultFormPreview
import com.catbit.opinionpoll.inputs.events.InputUIEvent
import com.catbit.opinionpoll.inputs.events.TextAreaInputEventData
import com.catbit.opinionpoll.res.Strings
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import org.jetbrains.compose.web.css.px

class TextAreaInput : InputUI<TextAreaInputUIState> {
    override fun match(uiState: InputUIState) = uiState is TextAreaInputUIState

    @Composable
    override fun Compose(uiState: TextAreaInputUIState, onEvent: (InputUIEvent) -> Unit) {
        DefaultFormContainer(
            modifier = Modifier.fillMaxWidth(),
            inputUIState = uiState,
            onEvent = onEvent,
            preview = { TextAreaInputPreview(uiState) }
        ) {
            Column(
                modifier = Modifier
                    .margin(top = 16.px)
                    .fillMaxWidth()
            ) {
                Text(
                    modifier = Modifier
                        .titleMedium()
                        .fontWeight(FontWeight.Bold),
                    text = Strings.maxLength
                )
                TextField(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(top = 8.px),
                    text = uiState.maxLength.toString(),
                    enabled = uiState.isEnabled,
                    onTextChanged = {
                        val newValue = it.takeIf { it.isNotBlank() } ?: "0"
                        onEvent(
                            InputUIEvent(
                                formIdentifier = uiState.actualIdentifier,
                                data = TextAreaInputEventData.OnMaxLengthChange(newValue.toInt())
                            )
                        )
                    }
                )
            }
        }
    }

    @Composable
    private fun TextAreaInputPreview(uiState: TextAreaInputUIState) {
        var previewText by remember { mutableStateOf("") }

        with(uiState) {
            DefaultFormPreview(
                index = index,
                title = title,
                subtitle = subtitle
            ) {
                TextField(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(top = 8.px),
                    maxLength = maxLength,
                    enabled = uiState.isEnabled,
                    text = previewText,
                    onTextChanged = { previewText = it }
                )
            }
        }
    }
}

data class TextAreaInputUIState(
    override val index: Int,
    override val isEnabled: Boolean,
    override val actualIdentifier: String,
    override val identifier: String,
    override val title: String,
    override val subtitle: String,
    override val isObligatory: Boolean,
    override val type: InputType,
    override val errorMessage: String?,
    val maxLength: Int
) : InputUIState {

    companion object {
        fun default(
            index: Int
        ) = TextAreaInputUIState(
            index = index,
            isEnabled = true,
            actualIdentifier = UUID.stringUUID(),
            identifier = "",
            title = "",
            subtitle = "",
            isObligatory = false,
            type = InputType.TextArea,
            errorMessage = null,
            maxLength = 50,
        )
    }

    override val internalIdentifiers = listOf(identifier)
    override fun knowsIdentifier(identifier: String) = identifier == this.identifier
    override fun updateIndex(newIndex: Int) = copy(index = newIndex)
    override fun copy(
        index: Int?,
        isEnabled: Boolean?,
        actualIdentifier: String?,
        identifier: String?,
        title: String?,
        subtitle: String?,
        isObligatory: Boolean?,
        type: InputType?,
        error: String?
    ) = copy(
        index = index ?: this.index,
        isEnabled = isEnabled ?: this.isEnabled,
        actualIdentifier = actualIdentifier ?: this.actualIdentifier,
        identifier = identifier ?: this.identifier,
        title = title ?: this.title,
        subtitle = subtitle ?: this.subtitle,
        isObligatory = isObligatory ?: this.isObligatory,
        type = type ?: this.type,
        errorMessage = error ?: this.errorMessage,
    )
}