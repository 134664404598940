package com.catbit.opinionpoll.core.ui.icons

data class MaterialIcon(
    val name: String,
    val style: Style
) {
    enum class Style(val suffix: String) {
        Normal(""),
        Outlined("outlined"),
        TwoTone("two-tone"),
        Round("round"),
        Sharp("sharp")
    }
}
