package com.catbit.opinionpoll.ui.screens.form_users_linker

import com.catbit.opinionpoll.core.exceptions.HttpResponseException
import com.catbit.opinionpoll.core.extensions.updateAs
import com.catbit.opinionpoll.core.extensions.withNotNull
import com.catbit.opinionpoll.domain.form_users_linker.GetFormUsersLinkerUseCase
import com.catbit.opinionpoll.domain.form_users_linker.LinkFormToUsersUseCase
import com.catbit.opinionpoll.ui.base.ScreenStateHolder
import com.catbit.opinionpoll.ui.screens.form_users_linker.FormUsersLinkerUIContract.*
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch

class FormUsersLinkerStateHolder(
    private val formIdentifier: String,
    private val getFormUsersLinkerUseCase: GetFormUsersLinkerUseCase,
    private val linkFormToUsersUseCase: LinkFormToUsersUseCase,
) : ScreenStateHolder<State, Event, Effect>() {

    override val internalUIState = MutableStateFlow<State>(State.Loading)

    override fun onStarted() {
        stateHolderScope.launch {
            getFormUsersLinkerUseCase(
                GetFormUsersLinkerUseCase.Params(formIdentifier)
            )
                .onSuccess { formUsersLink ->
                    internalUIState.update {

                        val usersUIState = formUsersLink.users.map { it.toUIState() }

                        State.Displaying(
                            title = "Vincular membros da equipe à ${formUsersLink.form.title}",
                            assignedUsers = usersUIState.filter { it.uid in formUsersLink.form.assignedTo },
                            nonAssignedUsers = usersUIState.filterNot { it.uid in formUsersLink.form.assignedTo },
                            assignedUsersQuery = "",
                            nonAssignedUsersQuery = ""
                        )
                    }
                }
                .onFailure { failure ->
                    internalUIState.update {
                        State.Failure(failure)
                    }
                }
        }
    }

    override fun onEvent(event: Event) {
        when (event) {
            Event.OnAssignedClearSearch -> onAssignedClearSearch()
            is Event.OnAssignedUsersSearch -> onAssignedUsersSearch(event.query)
            is Event.OnAssignedUsersSortingChange -> onAssignedUsersSortingChange(event.sorting)
            Event.OnMoveAllUsersToAssigned -> onMoveAllUsersToAssigned()
            Event.OnMoveAllUsersToNonAssigned -> onMoveAllUsersToNonAssigned()
            Event.OnMoveUsersToAssigned -> onMoveUsersToAssigned()
            Event.OnMoveUsersToNonAssigned -> onMoveUsersToNonAssigned()
            Event.OnNonAssignedClearSearch -> onNonAssignedClearSearch()
            is Event.OnNonAssignedUsersSearch -> onNonAssignedUsersSearch(event.query)
            is Event.OnNonAssignedUsersSortingChange -> onNonAssignedUsersSortingChange(event.sorting)
            is Event.OnUserChecked -> onUserChecked(event.uid)
            Event.OnSaveChanges -> onSaveChanges()
        }
    }

    private fun onSaveChanges() {

        internalEffects.dispatch(Effect.OnStartSavingChanges)

        withNotNull(internalUIState.value as? State.Displaying) {
            stateHolderScope.launch {
                linkFormToUsersUseCase(
                    LinkFormToUsersUseCase.Params(
                        formId = formIdentifier,
                        userIdentifiers = assignedUsers.map { it.uid }
                    )
                )
                    .onSuccess {
                        internalEffects.dispatch(Effect.OnSaveChangesSuccess)
                    }
                    .onFailure {
                        internalEffects.dispatch(
                            Effect.OnSaveChangesFailure(
                                message = if (it is HttpResponseException && it.statusCode.value == 477)
                                    "Limite de usuários por plano atingido!"
                                else "Houve um erro ao salvar a alteração, tente novamente!"
                            )
                        )
                    }
            }
        }
    }

    private fun onMoveUsersToAssigned() {
        internalUIState.updateAs<State.Displaying> {
            val usersToBeMoved = nonAssignedUsers.filter { it.checked }
            copy(
                nonAssignedUsers = (nonAssignedUsers - usersToBeMoved.toSet()).map { it.copy(checked = false) },
                assignedUsers = (assignedUsers + usersToBeMoved).map { it.copy(checked = false) }
            )
        }
    }

    private fun onMoveUsersToNonAssigned() {
        internalUIState.updateAs<State.Displaying> {
            val usersToBeMoved = assignedUsers.filter { it.checked }
            copy(
                nonAssignedUsers = (nonAssignedUsers + usersToBeMoved).map { it.copy(checked = false) },
                assignedUsers = (assignedUsers - usersToBeMoved.toSet()).map { it.copy(checked = false) }
            )
        }
    }

    private fun onNonAssignedUsersSearch(query: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(nonAssignedUsersQuery = query)
        }
    }

    private fun onNonAssignedClearSearch() {
        internalUIState.updateAs<State.Displaying> {
            copy(nonAssignedUsersQuery = "")
        }
    }

    private fun onNonAssignedUsersSortingChange(sorting: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(nonAssignedUsersSorting = sorting)
        }
    }

    private fun onUserChecked(uid: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(
                assignedUsers = if (assignedUsers.any { it.uid == uid }) {
                    assignedUsers.map {
                        if (it.uid == uid) it.copy(checked = !it.checked) else it
                    }
                } else assignedUsers,
                nonAssignedUsers = if (nonAssignedUsers.any { it.uid == uid }) {
                    nonAssignedUsers.map {
                        if (it.uid == uid) it.copy(checked = !it.checked) else it
                    }
                } else nonAssignedUsers,
            )
        }
    }

    private fun onMoveAllUsersToNonAssigned() {
        internalUIState.updateAs<State.Displaying> {
            copy(
                nonAssignedUsers = (nonAssignedUsers + assignedUsers).map { it.copy(checked = false) },
                assignedUsers = listOf()
            )
        }
    }

    private fun onMoveAllUsersToAssigned() {
        internalUIState.updateAs<State.Displaying> {
            copy(
                nonAssignedUsers = listOf(),
                assignedUsers = (nonAssignedUsers + assignedUsers).map { it.copy(checked = false) }
            )
        }
    }

    private fun onAssignedUsersSortingChange(sorting: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(assignedUsersSorting = sorting)
        }
    }

    private fun onAssignedUsersSearch(query: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(assignedUsersQuery = query)
        }
    }

    private fun onAssignedClearSearch() {
        internalUIState.updateAs<State.Displaying> {
            copy(assignedUsersQuery = "")
        }
    }
}