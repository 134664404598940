package com.catbit.opinionpoll.ui.states

import com.catbit.opinionpoll.core.ui.icons.MaterialIcon
import com.catbit.opinionpoll.data.models.UserModel

data class UserUIState(
    val uid: String,
    val phoneNumber: String,
    val roleIcon: MaterialIcon,
    val name: String,
    val email: String,
    val checked: Boolean
) {
    enum class Role(val role: String) {
        ProductUser("Usuário"),
        ProductManager("Gerente"),
        ProductOwner("Proprietário"),
        Admin("Administrador");

        companion object {
            fun fromString(role: String) = entries.first { it.role == role }

            fun fromRoleModel(role: UserModel.UserRole) = when(role) {
                UserModel.UserRole.ProductUser -> ProductUser
                UserModel.UserRole.ProductManager -> ProductManager
                UserModel.UserRole.ProductOwner -> ProductOwner
                UserModel.UserRole.Admin -> Admin
            }
            fun getAvailableRoles(currentRole: UserModel.UserRole) = when (currentRole) {
                UserModel.UserRole.ProductUser -> listOf()
                UserModel.UserRole.ProductManager -> listOf(ProductUser)
                UserModel.UserRole.ProductOwner -> listOf(ProductManager, ProductUser)
                UserModel.UserRole.Admin -> listOf(ProductOwner, ProductManager, ProductUser)
            }
        }
    }
}
