package com.catbit.opinionpoll.core.ui.composables

import androidx.compose.runtime.Composable
import com.catbit.opinionpoll.core.extensions.changeAlpha
import com.catbit.opinionpoll.core.extensions.withNotNull
import com.catbit.opinionpoll.core.ui.composables.base_components.Divider
import com.catbit.opinionpoll.core.ui.composables.base_components.DropdownList
import com.catbit.opinionpoll.core.ui.composables.base_components.IconButton
import com.catbit.opinionpoll.core.ui.composables.base_components.Text
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.*
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.TextInput

@Composable
fun SearchBar(
    modifier: Modifier = Modifier,
    hint: String? = null,
    sorting: SearchBarSorting? = null,
    query: String,
    onClear: () -> Unit,
    backgroundColor: Color = sitePalette().surface,
    textColor: Color = sitePalette().onSurface,
    clearIconColor: Color = sitePalette().primary,
    compactMode: Boolean = false,
    onSearch: (String) -> Unit
) {
    Box(
        modifier = modifier
    ) {
        Row(
            modifier = Modifier
                .height(48.px)
                .padding(left = 16.px, right = 4.px)
                .fillMaxWidth()
                .background(backgroundColor)
                .clip(Rect(48.px)),
            verticalAlignment = Alignment.CenterVertically
        ) {
            MaterialIcon(
                modifier = Modifier.margin(right = 8.px),
                icon = MaterialIcons.Round.Search,
                tint = textColor
            )
            TextInput(
                value = query,
                attrs = Modifier
                    .color(textColor)
                    .caretColor(textColor)
                    .background(backgroundColor)
                    .fillMaxWidth()
                    .fontSize(16.px)
                    .noBorder()
                    .noOutline()
                    .toAttrs {
                        withNotNull(hint) {
                            placeholder(this)
                        }
                        onInput { onSearch(it.value) }
                    }
            )
            if (query.isNotEmpty()) {
                IconButton(
                    onClick = onClear
                ) {
                    MaterialIcon(
                        icon = MaterialIcons.Round.Close,
                        tint = clearIconColor
                    )
                }
            }
            withNotNull(sorting) {
                Divider(
                    modifier = Modifier
                        .height(80.percent)
                        .margin(horizontal = 8.px)
                        .width(1.px),
                    color = sitePalette().outline.changeAlpha(0.8f)
                )
                if(compactMode) {
                    MaterialIcon(
                        modifier = Modifier
                            .margin(right = 8.px),
                        icon = MaterialIcons.Round.FilterList,
                        tint = textColor
                    )
                } else {
                    Text(
                        modifier = Modifier
                            .color(textColor)
                            .noWrap()
                            .margin(right = 8.px)
                            .bodyLarge(),
                        text = "Ordenar por:"
                    )
                }

                DropdownList(
                    modifier = Modifier.margin(right = 8.px),
                    options = options,
                    onOptionsSelected = onSort,
                    constrainDialogOnParentWidth = false
                ) {
                    Row(
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Text(
                            modifier = Modifier
                                .noWrap()
                                .margin(right = 8.px)
                                .color(textColor)
                                .bodyLarge(),
                            text = selected
                        )
                        MaterialIcon(MaterialIcons.Round.ArrowDropDown)
                    }
                }
            }
        }
    }
}

data class SearchBarSorting(
    val selected: String,
    val options: List<String>,
    val onSort: (String) -> Unit
)