package com.catbit.opinionpoll.core.charts.ui.charts

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.charts.data.ChartData
import com.catbit.opinionpoll.core.charts.ui.ChartConfiguration
import com.catbit.opinionpoll.core.ui.composables.base_components.SegmentedButton
import com.catbit.opinionpoll.core.ui.composables.base_components.SegmentedButtonItem
import com.catbit.opinionpoll.core.ui.composables.base_components.Text
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.bodyLarge
import com.catbit.opinionpoll.core.ui.modifiers.thenIf
import com.catbit.opinionpoll.core.ui.modifiers.titleLarge
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Canvas2d
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.px
import org.w3c.dom.HIGH
import org.w3c.dom.ImageSmoothingQuality
import kotlin.math.PI
import kotlin.math.min

@Composable
fun PieChart(
    modifier: Modifier = Modifier,
    configuration: ChartConfiguration,
    data: ChartData,
    wrapLegends: Boolean = true
) {
    val total = remember { data.dataSet.sumOf { it.value.toDouble() } }
    val sortedData = remember { data.dataSet.sortedByDescending { it.value.toDouble() } }
    var dataValueDisplay by remember { mutableStateOf("percent") }

    Column(
        modifier = modifier
    ) {

        if (data.title.isNotBlank()){
            Text(
                modifier = Modifier
                    .titleLarge()
                    .fontWeight(FontWeight.Bold)
                    .textAlign(TextAlign.Center)
                    .fillMaxWidth(),
                text = data.title
            )
        }

        when (configuration.labelsPosition) {
            ChartConfiguration.LabelPosition.Right -> {
                Row(
                    modifier = Modifier
                        .thenIf(data.title.isNotBlank()) {
                            margin(top = 24.px)
                        }
                        .display(DisplayStyle.Flex)
                        .gap(16.px)
                        .fillMaxWidth(),
                ) {
                    DrawChartAndControls(
                        configuration = configuration,
                        sortedData = sortedData,
                        total = total,
                        dataValueDisplay = dataValueDisplay
                    ) {
                        dataValueDisplay = it
                    }
                    DrawLegend(
                        sortedData = sortedData,
                        total = total,
                        dataValueDisplay = dataValueDisplay,
                        wrapLegends = wrapLegends
                    )
                }
            }

            ChartConfiguration.LabelPosition.Bottom -> {
                Column(
                    modifier = Modifier
                        .display(DisplayStyle.Flex)
                        .gap(16.px)
                        .fillMaxWidth(),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    DrawChartAndControls(
                        configuration = configuration,
                        sortedData = sortedData,
                        total = total,
                        dataValueDisplay = dataValueDisplay
                    ) {
                        dataValueDisplay = it
                    }
                    DrawLegend(
                        sortedData = sortedData,
                        total = total,
                        dataValueDisplay = dataValueDisplay,
                        wrapLegends = wrapLegends
                    )
                }
            }
        }
    }
}

@Composable
private fun DrawLegend(
    sortedData: List<ChartData.Data>,
    total: Double,
    dataValueDisplay: String,
    wrapLegends: Boolean
) {
    if (wrapLegends) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .display(DisplayStyle.Flex)
                .flexWrap(FlexWrap.Wrap)
                .overflow { x(Overflow.Hidden) }
                .gap(16.px)
        ) {
            DrawLabels(
                sortedData = sortedData,
                total = total,
                dataValueDisplay = dataValueDisplay
            )
        }
    } else {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .display(DisplayStyle.Flex)
                .flexWrap(FlexWrap.Wrap)
                .overflow { x(Overflow.Hidden) }
                .gap(16.px)
        ) {
            DrawLabels(
                modifier = Modifier
                    .fillMaxWidth()
                    .overflow { x(Overflow.Hidden) },
                sortedData = sortedData,
                total = total,
                dataValueDisplay = dataValueDisplay
            )
        }
    }
}

@Composable
private fun DrawChartAndControls(
    configuration: ChartConfiguration,
    sortedData: List<ChartData.Data>,
    total: Double,
    dataValueDisplay: String,
    onDataValueDisplayClick: (String) -> Unit
) {
    Column(
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Canvas2d(
            width = configuration.width,
            height = configuration.height
        ) {
            with(ctx) {
                imageSmoothingEnabled = true
                imageSmoothingQuality = ImageSmoothingQuality.HIGH

                var startAngle = 0.0

                sortedData.forEach { chartData ->
                    val sliceAngle = (chartData.value.toDouble() / total) * 2 * PI

                    beginPath()
                    moveTo(width.toDouble() / 2, height.toDouble() / 2)
                    arc(
                        width.toDouble() / 2,
                        height.toDouble() / 2,
                        min(width, height).toDouble() / 2,
                        startAngle,
                        startAngle + sliceAngle
                    )
                    lineTo(width.toDouble() / 2, height.toDouble() / 2)
                    fillStyle = chartData.color // Assuming data.color is a valid color string
                    fill()

                    startAngle += sliceAngle
                }
            }
        }

        SegmentedButton(
            modifier = Modifier.margin(top = 16.px),
            selectedItemId = dataValueDisplay,
            items = listOf(
                SegmentedButtonItem(
                    id = "percent",
                    text = "",
                    icon = MaterialIcons.Round.Percent
                ) {
                    onDataValueDisplayClick("percent")
                },
                SegmentedButtonItem(
                    id = "amount",
                    text = "",
                    icon = MaterialIcons.Round.Pin
                ) {
                    onDataValueDisplayClick("amount")
                }
            )
        )
    }
}

@Composable
private fun DrawLabels(
    modifier: Modifier = Modifier,
    sortedData: List<ChartData.Data>,
    total: Double,
    dataValueDisplay: String
) {
    sortedData
        .forEach { chartData ->
            Row(
                modifier = modifier
            ) {
                Box(
                    modifier = Modifier
                        .minSize(24.px)
                        .margin(right = 8.px)
                        .background(chartData.color)
                )

                val percentage = if (chartData.value == 0) "0.00" else
                    (chartData.value.toDouble() / total * 100.0).asDynamic()
                        .toFixed(2) as String

                val textToDisplay = buildString {
                    append("[")
                    if (dataValueDisplay == "amount") {
                        append(chartData.value)
                    } else {
                        append("$percentage%")
                    }
                    append("] ")
                    append(chartData.label)
                }

                Text(
                    modifier = Modifier
                        .whiteSpace(WhiteSpace.NoWrap)
                        .textOverflow(TextOverflow.Ellipsis)
                        .overflow { x(Overflow.Hidden) }
                        .bodyLarge(),
                    text = textToDisplay
                )
            }
        }
}