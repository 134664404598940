package com.catbit.opinionpoll.inputs.validators

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.inputs.MaskedTextAreaInputUIState

class MaskedTextAreaInputUIValidator : InputUIValidator<MaskedTextAreaInputUIState> {
    override fun match(inputUIState: InputUIState) = inputUIState is MaskedTextAreaInputUIState

    override fun validate(
        inputUIState: MaskedTextAreaInputUIState,
        allIdentifiers: List<String>
    ): InputUIState {

        val errorMessage = with(inputUIState) {
            if (identifier.isEmpty()) {
                "O identificador não pode ser vazio"
            } else if (title.isEmpty()) {
                "O título não pode ser vazio"
            } else if (replaceableChar.length != 1) {
                "O caracter de substituição precisa ser único"
            } else if (!mask.contains(replaceableChar)) {
                "A máscara precisa ter ao menos um caracter de substituição"
            } else if (mask.isEmpty()) {
                "A máscara não pode ser vazia"
            } else if (identifier in allIdentifiers) {
                "O identificador precisa ser único"
            } else null
        }

        return inputUIState.copy(errorMessage = errorMessage)
    }
}