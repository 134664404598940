package com.catbit.opinionpoll.domain.forms

import com.catbit.opinionpoll.core.domain.UseCase
import com.catbit.opinionpoll.data.repositories.OpinionPollRepository
import com.catbit.opinionpoll.inputs.requests.requests.InputRequest
import kotlinx.datetime.LocalDateTime

class CreateFormUseCase(
    private val repository: OpinionPollRepository
) : UseCase<Unit, CreateFormUseCase.Params>() {

    override suspend fun execute(params: Params) = repository.createForm(
        version = params.version,
        title = params.title,
        tips = params.tips,
        expirationDate = params.expirationDate,
        sendGeoCoordinates = params.sendGeoCoordinates,
        template = params.template,
    )

    data class Params(
        val version: Long,
        val title: String,
        val tips: String?,
        val expirationDate: LocalDateTime,
        val sendGeoCoordinates: Boolean,
        val template: List<InputRequest>,
    )
}