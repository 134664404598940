package com.catbit.opinionpoll.ui.screens.form_dashboard

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.charts.data.ChartData
import com.catbit.opinionpoll.core.charts.ui.ChartConfiguration
import com.catbit.opinionpoll.core.charts.ui.ChartsColorPallet
import com.catbit.opinionpoll.core.charts.ui.charts.BarChart
import com.catbit.opinionpoll.core.charts.ui.charts.PieChart
import com.catbit.opinionpoll.core.extensions.getStateHolder
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.catbit.opinionpoll.core.extensions.withNotNull
import com.catbit.opinionpoll.core.js.google_maps.maps.*
import com.catbit.opinionpoll.core.sailor.navigation_controller.NavigationController
import com.catbit.opinionpoll.core.sailor.overlays.dialog.DialogState
import com.catbit.opinionpoll.core.sailor.providers.LocalDialogStateProvider
import com.catbit.opinionpoll.core.sailor.providers.LocalSnackbarState
import com.catbit.opinionpoll.core.ui.composables.CircularProgress
import com.catbit.opinionpoll.core.ui.composables.HoverableContent
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.composables.base_components.*
import com.catbit.opinionpoll.core.ui.composables.base_components.Text
import com.catbit.opinionpoll.core.ui.composables.effects.SingleEffect
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.*
import com.catbit.opinionpoll.inputs.state.InputGraphUIState
import com.catbit.opinionpoll.res.Strings
import com.catbit.opinionpoll.ui.composables.MarkerIcon
import com.catbit.opinionpoll.ui.screens.form_dashboard.FormDashboardUIContract.Event
import com.catbit.opinionpoll.ui.screens.form_dashboard.FormDashboardUIContract.State
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaFileCsv
import com.varabyte.kobweb.silk.components.icons.fa.FaFileExcel
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.flow.collectLatest
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.dom.*
import org.koin.core.parameter.parametersOf
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.events.WheelEvent
import org.w3c.dom.url.URL
import org.w3c.files.Blob

@Composable
fun FormDashboardScreen(
    stackEntryId: String,
    navigationController: NavigationController,
    formIdentifier: String,
    formTitle: String,
    stateHolder: FormDashboardStateHolder = getStateHolder(
        stackEntryId = stackEntryId, parameters = parametersOf(formIdentifier, formTitle)
    )
) {
    val uiState = stateHolder.uiState.collectAsState().value
    val dialogState = LocalDialogStateProvider.current
    val coroutineScope = rememberCoroutineScope()
    val snackBarState = LocalSnackbarState.current

    // TODO Dashboard com gráfico de linha sobre dados da coleta, como coletas pelo dia, ranking de usuários e afins

    SingleEffect {
        stateHolder.effects.collectLatest {
            when (it) {
                FormDashboardUIContract.Effect.OnDisplayFileFormatPicker -> {
                    fileFormatSelector(dialogState, stateHolder)
                }

                FormDashboardUIContract.Effect.OnPreparingAnswersToDownload -> {
                    fileDownloadMessage(dialogState)
                }

                is FormDashboardUIContract.Effect.OnDownloadReady -> {
                    dialogState.dismiss()
                    downloadFile(
                        formTitle = formTitle, file = it.file, fileExtension = it.fileExtension
                    )
                }

                FormDashboardUIContract.Effect.OnPreparingAnswersToDownloadFail -> {
                    dialogState.dismiss()
                    snackBarState.show(
                        message = "Erro ao preparar o arquivo de download", coroutineScope = coroutineScope
                    )
                }

                FormDashboardUIContract.Effect.OnSaveFiltersFailure -> {
                    snackBarState.show(
                        message = "Falha ao salvar filtros", coroutineScope = coroutineScope
                    )
                }

                FormDashboardUIContract.Effect.OnSaveFiltersSuccess -> {
                    snackBarState.show(
                        message = "Filtros salvos com sucesso!", coroutineScope = coroutineScope
                    )
                }

                is FormDashboardUIContract.Effect.OnDisplayMessage -> {
                    snackBarState.show(
                        message = it.message, coroutineScope = coroutineScope
                    )
                }

                is FormDashboardUIContract.Effect.OnColumnCopiedToClipboard -> {
                    window.navigator.clipboard.writeText(it.columnData)

                    snackBarState.show(
                        message = "Coluna copiada para a área de transferência!", coroutineScope = coroutineScope
                    )
                }

                FormDashboardUIContract.Effect.OnUpdateAnswersStart -> {
                    dialogState.show {
                        Column(
                            modifier = Modifier.fillMaxWidth(),
                            horizontalAlignment = Alignment.CenterHorizontally
                        ) {
                            CircularProgress(50)
                            Text(
                                modifier = Modifier
                                    .margin(top = 8.px)
                                    .textAlign(TextAlign.Center),
                                text = "Atualizando respostas"
                            )
                        }
                    }
                }

                FormDashboardUIContract.Effect.OnUpdateAnswersSuccess -> {
                    dialogState.dismiss()
                    snackBarState.show(
                        message = "Respostas atualizadas com sucesso!",
                        coroutineScope = coroutineScope
                    )
                }

                FormDashboardUIContract.Effect.OnUpdateAnswersFailure -> {
                    dialogState.dismiss()
                    snackBarState.show(
                        message = "Erro ao atualizar respostas! Tente novamente em alguns instantes.",
                        coroutineScope = coroutineScope
                    )
                }
            }
        }
    }

    Column(
        modifier = Modifier.fillMaxSize().overflow(Overflow.Hidden)
    ) {
        Toolbar(
            title = uiState.toolbarTitle,
            onLeftIconClick = { navigationController.goBack() },
            trailingContent = {
                if (uiState is State.Displaying) {
                    Row {
                        IconButton(
                            onClick = {
                                stateHolder.onEvent(Event.OnExportAnswersClick)
                            }
                        ) {
                            MaterialIcon(icon = MaterialIcons.Round.FileDownload)
                        }
                        Tooltip(
                            target = ElementTarget.PreviousSibling,
                            text = Strings.exportAnswers,
                            placement = PopupPlacement.BottomRight
                        )

                        if (uiState.displayUpdateAnswersButton) {
                            IconButton(
                                onClick = {
                                    stateHolder.onEvent(Event.OnUpdateAnswers)
                                }
                            ) {
                                MaterialIcon(icon = MaterialIcons.Round.CloudUpload)
                            }
                            Tooltip(
                                target = ElementTarget.PreviousSibling,
                                text = Strings.updateAnswers,
                                placement = PopupPlacement.BottomRight
                            )
                        }
                    }
                }
            }
        )

        when (uiState) {
            is State.Displaying -> DisplayingState(
                uiState = uiState,
                dialogState = dialogState,
                onEvent = {
                    stateHolder.onEvent(it)
                })

            is State.Empty -> EmptyState()
            is State.Loading -> LoadingState()
            is State.Failure -> FailureState(
                onEvent = {
                    stateHolder.onEvent(it)
                }
            )

        }
    }
}

@Composable
private fun EmptyState() {
    Column(
        modifier = Modifier
            .fillMaxSize()
            .padding(horizontal = 48.px),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        MaterialIcon(
            icon = MaterialIcons.Round.TableChart,
            size = 48.px,
            tint = sitePalette().onSurface
        )
        Text(
            modifier = Modifier
                .margin(top = 16.px)
                .textAlign(TextAlign.Center)
                .color(sitePalette().onSurface)
                .titleLarge(),
            text = "Nenhum dado a ser exibido"
        )
        Text(
            modifier = Modifier
                .titleMedium()
                .textAlign(TextAlign.Center)
                .color(sitePalette().onSurface),
            text = "Conforme as pesquisas forem sendo realizadas, os dados coletados aparecerão aqui."
        )
    }
}

fun downloadFile(
    formTitle: String, file: Blob, fileExtension: String
) {
    val url = URL.createObjectURL(file)
    val downloadLink = document.createElement("a") as HTMLAnchorElement
    downloadLink.href = url
    downloadLink.download = "$formTitle.$fileExtension"

    document.body?.appendChild(downloadLink)

    downloadLink.click()

    URL.revokeObjectURL(url)
    document.body?.removeChild(downloadLink)
}

fun fileDownloadMessage(
    dialogState: DialogState,
) {
    dialogState.show {
        Column(
            modifier = Modifier.fillMaxWidth(), horizontalAlignment = Alignment.CenterHorizontally
        ) {
            CircularProgress(50)
            Text(
                modifier = Modifier.margin(top = 8.px), text = "Preparando respostas para download"
            )
        }
    }
}

private fun fileFormatSelector(
    dialogState: DialogState, stateHolder: FormDashboardStateHolder
) {
    dialogState.show {
        Column(
            modifier = Modifier.fillMaxSize()
        ) {

            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.End
            ) {
                IconButton(
                    onClick = {
                        dialogState.dismiss()
                    }
                ) {
                    MaterialIcon(MaterialIcons.Round.Close)
                }
            }

            Text(
                modifier = Modifier
                    .margin(top = 16.px)
                    .fillMaxWidth()
                    .headlineSmall(),
                text = "Em qual formato você deseja exportar as respostas?"
            )
            Text(
                modifier = Modifier
                    .margin(top = 16.px)
                    .fillMaxWidth()
                    .bodyMedium(),
                text = "CSV: Colunas separadas por \";\", aceito na maioria dos programas de planilha."
            )
            Text(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(top = 8.px)
                    .bodyMedium(),
                text = "XLSX: Formato proprietário do Microsoft Excel."
            )

            FilledButton(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(top = 16.px),
                text = "CSV",
                leadingIcon = {
                    FaFileCsv()
                },
                onClick = {
                    dialogState.dismiss()
                    stateHolder.onEvent(Event.OnGenerateCSVRequest)
                },
            )

            FilledButton(
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                text = "XLSX",
                leadingIcon = {
                    FaFileExcel()
                }, onClick = {
                    dialogState.dismiss()
                    stateHolder.onEvent(Event.OnGenerateXLSXRequest)
                }
            )
        }
    }
}

@Composable
private fun LoadingState() {
    Column(
        modifier = Modifier.fillMaxSize(),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        CircularProgress(50)
        Text("Carregando")
    }
}

@Composable
private fun FailureState(
    onEvent: (Event) -> Unit
) {
    Column(
        modifier = Modifier.fillMaxSize(),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        MaterialIcon(MaterialIcons.Round.Error)
        Text("Houve uma falha ao carregar os dados!")
        FilledButton(modifier = Modifier.margin(top = 8.px), text = "Tentar novamente", icon = null, onClick = {
            onEvent(Event.TryAgain)
        })
    }
}

@Composable
private fun DisplayingState(
    uiState: State.Displaying,
    dialogState: DialogState,
    onEvent: (Event) -> Unit,
) {
    Column(
        modifier = Modifier
            .fillMaxSize()
            .overflow(Overflow.Hidden)
            .padding {
                right(24.px)
                bottom(24.px)
                left(24.px)
            }
    ) {
        SegmentedButton(
            modifier = Modifier.height(40.px).fillMaxWidth(),
            items = uiState.menuOptions.map {
                SegmentedButtonItem(id = it.id, text = it.text, icon = it.icon, onClick = {
                    onEvent(Event.OnMenuClick(it.id))
                })
            },
            selectedItemId = uiState.selectedMenuId,
            backgroundColor = sitePalette().background,
            textColor = sitePalette().onBackground,
        )

        when (uiState.selectedMenuId) {
            "bi" -> {
                BI(
                    bi = uiState.bi,
                    onEvent = onEvent
                )
            }

            "charts" -> {
                Charts(
                    charts = uiState.charts,
                    onEvent = onEvent
                )
            }

            "table" -> {
                AnswersTable(
                    table = uiState.table,
                    dialogState = dialogState,
                    onEvent = onEvent
                )
            }

            "map" -> {
                uiState.map?.let {
                    Map(
                        map = it,
                        onEvent = onEvent
                    )
                }
            }
        }
    }
}

@Composable
private fun BI(
    bi: State.Displaying.BIUIState,
    onEvent: (Event) -> Unit,
) {
    Row(
        modifier = Modifier
            .fillMaxSize()
            .display(DisplayStyle.Flex)
            .gap(24.px)
            .margin(top = 16.px)
            .overflow(Overflow.Hidden)
    ) {
        Column(
            modifier = Modifier
                .width(20.percent)
                .borderRadius(16.px)
                .clip(Rect(16.px))
                .padding(all = 16.px)
                .background(sitePalette().surface)
                .fillMaxHeight()
        ) {
            if (bi.filters.isNotEmpty()) {
                Text(
                    modifier = Modifier
                        .fillMaxWidth()
                        .noWrap()
                        .titleMedium()
                        .fontWeight(FontWeight.Bold),
                    text = Strings.filter
                )
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(top = 8.px, bottom = 16.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    OutlinedDropdownList(
                        modifier = Modifier.fillMaxWidth(),
                        value = bi.selectedFilter,
                        enabled = bi.managingFilter.not() && bi.savingFilters.not(),
                        options = bi.filters.map { it.title },
                        onOptionsSelected = {
                            onEvent(Event.OnSelectFilter(it))
                        }
                    )

                    IconButton(
                        modifier = Modifier.margin(left = 8.px),
                        enabled = bi.managingFilter.not() && bi.savingFilters.not(),
                        onClick = {
                            onEvent(Event.OnEditFilter)
                        },
                    ) {
                        MaterialIcon(MaterialIcons.Round.Edit)
                    }

                    Tooltip(
                        target = ElementTarget.PreviousSibling,
                        text = Strings.editFilter
                    )

                    IconButton(
                        onClick = {
                            onEvent(Event.OnRemoveFilter)
                        },
                        enabled = bi.managingFilter.not() && bi.savingFilters.not(),
                    ) {
                        MaterialIcon(MaterialIcons.Round.HighlightOff)
                    }

                    Tooltip(
                        target = ElementTarget.PreviousSibling,
                        text = Strings.deleteFilter
                    )

                }

                Column(
                    modifier = Modifier
                        .overflow { y(Overflow.Auto) }
                ) {
                    Text(
                        modifier = Modifier
                            .fillMaxWidth()
                            .noWrap()
                            .titleMedium()
                            .fontWeight(FontWeight.Bold),
                        text = "Agrupador"
                    )

                    Row(
                        modifier = Modifier
                            .margin(top = 8.px)
                            .display(DisplayStyle.Flex)
                            .flexWrap(FlexWrap.Wrap)
                            .gap(8.px)
                            .fillMaxWidth()
                    ) {
                        bi.filterSubgroups.forEach { subgroup ->
                            Chip(
                                text = subgroup,
                                icon = if (subgroup in bi.selectedFilterSubgroups)
                                    MaterialIcons.Round.Check
                                else
                                    null,
                                selected = subgroup in bi.selectedFilterSubgroups,
                                enabled = bi.managingFilter.not() && bi.savingFilters.not(),
                                onClick = {
                                    onEvent(Event.OnToggleFilterSubgroup(subgroup))
                                }
                            )
                        }
                    }

                    Text(
                        modifier = Modifier
                            .margin(top = 16.px)
                            .fillMaxWidth()
                            .noWrap()
                            .titleMedium()
                            .fontWeight(FontWeight.Bold), text = "Subgrupo"
                    )

                    Row(
                        modifier = Modifier
                            .margin(top = 8.px)
                            .display(DisplayStyle.Flex)
                            .flexWrap(FlexWrap.Wrap)
                            .gap(8.px)
                            .fillMaxWidth()
                    ) {
                        bi.filterSubgroupValues.forEach { subgroupValue ->
                            Chip(
                                text = subgroupValue,
                                icon = if (subgroupValue in bi.selectedFilterSubgroupValues)
                                    MaterialIcons.Round.Check
                                else
                                    null,
                                selected = subgroupValue in bi.selectedFilterSubgroupValues,
                                enabled = bi.managingFilter.not() && bi.savingFilters.not(),
                                onClick = {
                                    onEvent(Event.OnToggleFilterSubgroupValue(subgroupValue))
                                }
                            )
                        }
                    }
                }
            } else {
                Column(
                    modifier = Modifier.fillMaxSize(),
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    MaterialIcon(
                        icon = MaterialIcons.Round.FilterAlt, size = 48.px
                    )
                    Text(
                        modifier = Modifier
                            .margin(top = 16.px)
                            .textAlign(TextAlign.Center)
                            .titleLarge(),
                        text = "Ainda não existem filtros"
                    )
                    Text(
                        modifier = Modifier
                            .margin(top = 8.px)
                            .textAlign(TextAlign.Center)
                            .titleSmall(),
                        text = "Você pode adicioná-los clicando em \"Adicionar filtro\" no botão abaixo"
                    )
                }
            }

            Column(
                modifier = Modifier
                    .margin(top = 16.px)
                    .fillMaxWidth()
                    .height(
                        if (bi.filters.isNotEmpty() || bi.hasOriginalFilters)
                            88.px else 40.px
                    )
            ) {
                FilledButton(
                    modifier = Modifier.fillMaxWidth(),
                    text = Strings.addFilter,
                    icon = MaterialIcons.Round.FilterAlt,
                    enabled = bi.managingFilter.not() && bi.savingFilters.not(),
                    onClick = {
                        onEvent(Event.OnAddBIFilter)
                    }
                )

                if (bi.filters.isNotEmpty() || bi.hasOriginalFilters) {
                    FilledButton(
                        modifier = Modifier.fillMaxWidth()
                            .margin(top = 8.px),
                        text = Strings.saveFilters,
                        icon = MaterialIcons.Round.FileUpload,
                        enabled = bi.managingFilter.not() && bi.savingFilters.not(),
                        loading = bi.savingFilters,
                        onClick = {
                            onEvent(Event.OnSaveFilters)
                        }
                    )
                }
            }
        }
        if (bi.managingFilter) {
            Column(
                modifier = Modifier
                    .width(60.percent)
                    .fillMaxHeight()
                    .borderRadius(16.px).clip(Rect(16.px))
                    .padding(all = 16.px)
                    .background(sitePalette().surface)
            ) {
                Column(
                    modifier = Modifier
                        .fillMaxSize()
                        .overflow { x(Overflow.Auto) }
                ) {
                    Text(
                        modifier = Modifier
                            .fillMaxWidth()
                            .noWrap().titleMedium()
                            .fontWeight(FontWeight.Bold),
                        text = Strings.title
                    )

                    TextField(
                        modifier = Modifier
                            .fillMaxWidth()
                            .margin(top = 8.px),
                        text = bi.editFilterTitle,
                        onTextChanged = {
                            onEvent(
                                Event.OnBITitleChange(newTitle = it)
                            )
                        }
                    )

                    Text(
                        modifier = Modifier
                            .margin(top = 16.px)
                            .fillMaxWidth()
                            .noWrap()
                            .titleMedium()
                            .fontWeight(FontWeight.Bold), text = Strings.groupingField
                    )
                    OutlinedDropdownList(
                        modifier = Modifier
                            .margin(top = 8.px)
                            .fillMaxWidth(),
                        constrainDialogOnParentWidth = true,
                        value = bi.editSelectedGroupingField,
                        options = bi.editAvailableGroupingFields,
                        onOptionsSelected = {
                            onEvent(Event.OnBIGroupingFieldSelected(field = it))
                        }
                    )

                    Text(
                        modifier = Modifier
                            .margin(top = 16.px)
                            .fillMaxWidth()
                            .noWrap()
                            .titleMedium()
                            .fontWeight(FontWeight.Bold),
                        text = Strings.subgroups
                    )
                    Column(
                        modifier = Modifier
                            .margin(top = 8.px)
                            .display(DisplayStyle.Flex)
                            .gap(8.px)
                            .fillMaxWidth()
                    ) {
                        bi.editSubgroups.forEach { subgroup ->
                            Row(
                                modifier = Modifier
                                    .display(DisplayStyle.Flex)
                                    .gap(8.px)
                                    .fillMaxWidth(),
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                TextField(modifier = Modifier.fillMaxWidth(),
                                    text = subgroup.title,
                                    hint = Strings.title,
                                    onTextChanged = {
                                        onEvent(
                                            Event.OnBISubgroupTitleChange(
                                                identifier = subgroup.identifier, newTitle = it
                                            )
                                        )
                                    })

                                OutlinedDropdownList(
                                    modifier = Modifier.fillMaxWidth(),
                                    value = "",
                                    options = (bi.editAvailableSubgroupsValues + subgroup.values).toSet().toList()
                                        .sorted(),
                                    checkedOptions = subgroup.values,
                                    constrainDialogOnParentWidth = true,
                                    onOptionsSelected = { },
                                    onCheckOptionsChange = {
                                        onEvent(
                                            Event.OnBISubgroupValuesChange(
                                                identifier = subgroup.identifier, values = it
                                            )
                                        )
                                    })

                                IconButton(onClick = {
                                    onEvent(
                                        Event.OnBISubgroupRemove(subgroup.identifier)
                                    )
                                }) {
                                    MaterialIcon(MaterialIcons.Round.HighlightOff)
                                }

                                Tooltip(
                                    target = ElementTarget.PreviousSibling, text = Strings.removeSubgroup
                                )
                            }
                        }
                    }

                    Row(
                        modifier = Modifier.fillMaxWidth().margin(top = 8.px),
                        horizontalArrangement = Arrangement.Center
                    ) {
                        IconButton(
                            onClick = {
                                onEvent(Event.OnAddBISubgroup)
                            }, enabled = bi.editAvailableSubgroupsValues.isNotEmpty()
                        ) {
                            MaterialIcon(MaterialIcons.Round.AddCircleOutline)
                        }

                        Tooltip(
                            target = ElementTarget.PreviousSibling,
                            text = Strings.addSubgroup
                        )
                    }

                    Text(
                        modifier = Modifier.margin(top = 16.px).fillMaxWidth().noWrap().titleMedium()
                            .fontWeight(FontWeight.Bold), text = Strings.crossingFields
                    )

                    OutlinedDropdownList(modifier = Modifier.fillMaxWidth().margin(top = 8.px),
                        value = "",
                        options = bi.editAvailableCrossingFields,
                        checkedOptions = bi.editSelectedCrossingFields,
                        onOptionsSelected = { },
                        constrainDialogOnParentWidth = true,
                        onCheckOptionsChange = {
                            onEvent(
                                Event.OnBICrossingFieldsValuesChange(values = it)
                            )
                        }
                    )
                }

                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .display(DisplayStyle.Flex).gap(8.px),
                    horizontalArrangement = Arrangement.End
                ) {

                    FilledButton(
                        modifier = Modifier
                            .padding(horizontal = 16.px),
                        text = Strings.cancel,
                        onClick = {
                            onEvent(Event.OnCancelAddingBIFilter)
                        },
                        icon = null
                    )

                    FilledButton(
                        modifier = Modifier
                            .padding(horizontal = 16.px),
                        text = if (bi.filterManagingMode == State.Displaying.BIUIState.FilterManagingMode.Add)
                            Strings.add
                        else
                            Strings.update,
                        onClick = {
                            onEvent(Event.OnSaveBIFilter)
                        },
                        icon = null
                    )
                }
            }

            Column(
                modifier = Modifier
                    .width(20.percent)
                    .fillMaxHeight()
                    .borderRadius(16.px)
                    .clip(Rect(16.px))
                    .padding(all = 16.px)
                    .background(sitePalette().surface)
            ) {

            }
        } else {
            Row(
                modifier = Modifier
                    .width(80.percent)
                    .display(DisplayStyle.Flex)
                    .flexWrap(FlexWrap.Wrap)
                    .alignContent(AlignContent.FlexStart)
                    .alignItems(AlignItems.FlexStart)
                    .gap(16.px)
                    .fillMaxHeight()
                    .overflow {
                        y(Overflow.Auto)
                    }
            ) {
                if (bi.biAnswers != null) {
                    Column(
                        modifier = Modifier
                            .borderRadius(16.px)
                            .clip(Rect(16.px))
                            .padding(all = 16.px)
                            .background(sitePalette().surfaceVariant),
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {
                        Text(
                            modifier = Modifier.displayMedium(),
                            text = bi.biAnswers.total.toString()
                        )

                        Text(
                            modifier = Modifier
                                .headlineMedium()
                                .margin(top = 8.px),
                            text = "Respostas"
                        )

                        Text(
                            modifier = Modifier
                                .titleMedium()
                                .margin(top = 8.px),
                            text = "(%${bi.biAnswers.percentage.asDynamic().toFixed(2) as String})"
                        )
                    }

                    bi.biAnswers.graphs.forEach { graphSpec ->
                        Column(
                            modifier = Modifier
                                .borderRadius(16.px)
                                .width(50.percent.minus(8.px))
                                .clip(Rect(16.px))
                                .padding(all = 16.px)
                                .background(sitePalette().surfaceVariant)
                        ) {

                            SegmentedButton(
                                items = InputGraphUIState.CharType.entries.map {
                                    SegmentedButtonItem(
                                        id = it.name,
                                        text = it.translatedName,
                                        icon = it.icon
                                    ) {
                                        onEvent(
                                            Event.OnFilterChartTypeChange(
                                                identifier = graphSpec.identifier,
                                                newType = it
                                            )
                                        )
                                    }
                                },
                                selectedItemId = graphSpec.chartType.name
                            )

                            when (graphSpec.chartType) {
                                InputGraphUIState.CharType.Pizza -> {
                                    PieChart(
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .margin(top = 16.px),
                                        configuration = object : ChartConfiguration {
                                            override val height = 300
                                            override val width = 300
                                            override val labelsPosition = ChartConfiguration.LabelPosition.Bottom
                                        },
                                        data = ChartData(
                                            title = graphSpec.title,
                                            dataSet = graphSpec.data.mapIndexed { index, data ->
                                                ChartData.Data(
                                                    value = data.value,
                                                    label = data.label,
                                                    color = ChartsColorPallet[index]
                                                )
                                            }
                                        )
                                    )
                                }

                                InputGraphUIState.CharType.Bar -> {
                                    BarChart(
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .margin(top = 16.px),
                                        barsMaxWidth = 100.percent,
                                        data = ChartData(
                                            title = graphSpec.title,
                                            dataSet = graphSpec.data.mapIndexed { index, data ->
                                                ChartData.Data(
                                                    value = data.value,
                                                    label = data.label,
                                                    color = ChartsColorPallet[index]
                                                )
                                            }
                                        )
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@Composable
private fun Charts(
    charts: State.Displaying.ChartsUIState,
    onEvent: (Event) -> Unit,
) {
    Row(
        modifier = Modifier
            .margin(top = 16.px)
            .fillMaxWidth()
            .display(DisplayStyle.Flex)
            .flexWrap(FlexWrap.Wrap)
            .alignContent(AlignContent.FlexStart)
            .alignItems(AlignItems.FlexStart)
            .gap(16.px)
            .fillMaxHeight()
            .overflow {
                y(Overflow.Auto)
            }
    ) {

        Column(
            modifier = Modifier
                .width(15.percent.minus(8.px))
                .borderRadius(16.px)
                .clip(Rect(16.px))
                .padding(all = 16.px)
                .background(sitePalette().surface),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Text(
                modifier = Modifier.displayMedium(),
                text = charts.totalAnswers.toString()
            )

            Text(
                modifier = Modifier
                    .headlineMedium()
                    .margin(top = 8.px),
                text = "Respostas"
            )
        }

//        LineChart(
//            modifier = Modifier
//                .borderRadius(16.px)
//                .clip(Rect(16.px))
//                .padding(all = 16.px)
//                .background(sitePalette().surface)
//                .width(85.percent.minus(8.px)),
//            configuration = object : ChartConfiguration {
//                override val height = 300
//                override val width = 800
//                override val labelsPosition = ChartConfiguration.LabelPosition.Bottom
//            },
//            data = usersChart
//        )

        charts.graphAnswers.forEach { graphSpec ->
            Column(
                modifier = Modifier
                    .borderRadius(16.px)
                    .width(50.percent.minus(8.px))
                    .clip(Rect(16.px))
                    .padding(all = 16.px)
                    .background(sitePalette().surface)
            ) {
                SegmentedButton(
                    items = InputGraphUIState.CharType.entries.map {
                        SegmentedButtonItem(
                            id = it.name,
                            text = it.translatedName,
                            icon = it.icon
                        ) {
                            onEvent(
                                Event.OnChartTypeChange(
                                    identifier = graphSpec.identifier,
                                    newType = it
                                )
                            )
                        }
                    },
                    selectedItemId = graphSpec.chartType.name
                )

                when (graphSpec.chartType) {
                    InputGraphUIState.CharType.Pizza -> {
                        PieChart(
                            modifier = Modifier
                                .margin(top = 16.px)
                                .fillMaxWidth(),
                            configuration = object : ChartConfiguration {
                                override val height = 300
                                override val width = 300
                                override val labelsPosition = ChartConfiguration.LabelPosition.Bottom
                            },
                            data = ChartData(
                                title = graphSpec.title,
                                dataSet = graphSpec.data.mapIndexed { index, data ->
                                    ChartData.Data(
                                        value = data.value,
                                        label = data.label,
                                        color = ChartsColorPallet[index]
                                    )
                                }
                            )
                        )
                    }

                    InputGraphUIState.CharType.Bar -> {
                        BarChart(
                            modifier = Modifier
                                .fillMaxWidth()
                                .margin(top = 16.px),
                            barsMaxWidth = 100.percent,
                            data = ChartData(
                                title = graphSpec.title,
                                dataSet = graphSpec.data.mapIndexed { index, data ->
                                    ChartData.Data(
                                        value = data.value,
                                        label = data.label,
                                        color = ChartsColorPallet[index]
                                    )
                                }
                            )
                        )
                    }
                }
            }
        }
    }
}

@Composable
private fun Map(
    map: State.Displaying.MapUIState,
    onEvent: (Event) -> Unit
) {
    Row(
        modifier = Modifier
            .fillMaxSize()
            .margin(top = 16.px)
            .display(DisplayStyle.Flex)
            .gap(24.px)
            .overflow(Overflow.Hidden)
    ) {

        var selectedMarker by remember { mutableStateOf<State.Displaying.MapUIState.MapMarkerUIState?>(null) }

        Div(
            attrs = Modifier
                .fillMaxHeight()
                .width(65.percent)
                .padding(16.px)
                .background(sitePalette().surface)
                .clip(Rect(16.px))
                .borderRadius(16.px)
                .toAttrs()
        ) {
            Div(
                attrs = Modifier
                    .id("map")
                    .fillMaxSize()
                    .clip(Rect(8.px))
                    .toAttrs()
            )
        }

        SingleEffect {
            val northPoint = map.markers.maxBy { it.latLong.lat() }
            val southPoint = map.markers.minBy { it.latLong.lat() }
            val eastPoint = map.markers.maxBy { it.latLong.lng() }
            val westPoint = map.markers.minBy { it.latLong.lng() }

            val bounds = LatLngBounds(
                sw = LatLng(
                    lat = southPoint.latLong.lat(),
                    lng = westPoint.latLong.lng()
                ),
                ne = LatLng(
                    lat = northPoint.latLong.lat(),
                    lng = eastPoint.latLong.lng()
                )
            )
            val googleMap = googleMap(
                element = document.getElementById("map")!!,
                zoom = 10,
                center = LatLng(
                    lat = (northPoint.latLong.lat() + southPoint.latLong.lat()) / 2,
                    lng = (eastPoint.latLong.lng() + westPoint.latLong.lng()) / 2
                )
            ) {
                minZoom = 3
                mapTypeControl = false
                overviewMapControl = false
            }

            map.markers.forEach { marker ->
                bounds.extend(marker.latLong)
                marker {
                    this.map = googleMap
                    position = marker.latLong
                    icon = "/marker.png"
                    clickable = true
                }.apply {
                    addListener("click") { _, _ ->
                        googleMap.panTo(marker.latLong)
                        selectedMarker = marker
                    }
                }
            }

            googleMap.fitBounds(bounds)
        }

        Column(
            modifier = Modifier
                .fillMaxHeight()
                .width(35.percent)
                .padding(16.px)
                .background(sitePalette().surface)
                .clip(Rect(16.px))
                .overflow(Overflow.Hidden)
                .borderRadius(16.px)
        ) {
            if (selectedMarker != null) {
                Text(
                    modifier = Modifier
                        .fillMaxWidth()
                        .noWrap()
                        .titleMedium()
                        .color(sitePalette().onSurface)
                        .fontWeight(FontWeight.Bold),
                    text = "Coletado por"
                )

                Text(
                    modifier = Modifier
                        .fillMaxWidth()
                        .noWrap()
                        .color(sitePalette().onSurface)
                        .titleMedium(),
                    text = selectedMarker?.collectedBy.orEmpty()
                )

                Text(
                    modifier = Modifier
                        .fillMaxWidth()
                        .noWrap()
                        .margin(top = 8.px)
                        .titleMedium()
                        .color(sitePalette().onSurface)
                        .fontWeight(FontWeight.Bold),
                    text = "Coletado em"
                )
                Text(
                    modifier = Modifier
                        .fillMaxWidth()
                        .noWrap()
                        .color(sitePalette().onSurface)
                        .titleMedium(),
                    text = selectedMarker?.collectedAt.orEmpty()
                )

                Text(
                    modifier = Modifier
                        .fillMaxWidth()
                        .noWrap()
                        .margin(top = 8.px)
                        .titleMedium()
                        .color(sitePalette().onSurface)
                        .fontWeight(FontWeight.Bold),
                    text = "Respostas"
                )

                Column(
                    modifier = Modifier
                        .margin(top = 4.px)
                        .fillMaxSize()
                        .overflow {
                            y(Overflow.Auto)
                        }
                ) {
                    Table(
                        attrs = Modifier
                            .fillMaxWidth()
                            .borderCollapse(BorderCollapse.Collapse)
                            .toAttrs()
                    ) {
                        Thead(
                            attrs = Modifier
                                .fillMaxWidth()
                                .background(sitePalette().secondaryContainer)
                                .color(sitePalette().onSecondaryContainer)
                                .toAttrs()
                        ) {
                            Tr(
                                attrs = Modifier
                                    .fillMaxWidth()
                                    .toAttrs()
                            ) {
                                listOf("#", "Pergunta", "Resposta").forEach { header ->
                                    Td(
                                        attrs = Modifier
                                            .border(
                                                width = 1.px,
                                                style = LineStyle.Solid,
                                                color = sitePalette().onSurface
                                            )
                                            .padding(vertical = 4.px, horizontal = 8.px)
                                            .toAttrs()
                                    ) {
                                        Text(
                                            modifier = Modifier
                                                .labelLarge()
                                                .fontWeight(FontWeight.Bold),
                                            text = header
                                        )
                                    }
                                }
                            }
                        }

                        Tbody(
                            attrs = Modifier
                                .fillMaxWidth()
                                .toAttrs()
                        ) {
                            selectedMarker?.relatedAnswer?.entries?.forEachIndexed { index, (question, answer) ->
                                Tr(
                                    attrs = Modifier
                                        .fillMaxWidth()
                                        .toAttrs()
                                ) {

                                    listOf(index.toString(), question, answer).forEach { cell ->
                                        Td(
                                            attrs = Modifier
                                                .border(
                                                    width = 1.px,
                                                    style = LineStyle.Solid,
                                                    color = sitePalette().onSurface
                                                )
                                                .padding(vertical = 4.px, horizontal = 8.px)
                                                .minHeight(36.px)
                                                .toAttrs()
                                        ) {
                                            Text(
                                                modifier = Modifier.labelLarge(),
                                                text = cell
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                Column(
                    modifier = Modifier.fillMaxSize(),
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    MarkerIcon(48.px)
                    Text(
                        modifier = Modifier
                            .margin(top = 16.px)
                            .titleLarge()
                            .textAlign(TextAlign.Center),
                        text = "Visualize aqui informações sobre uma coleta"
                    )
                    Text(
                        modifier = Modifier
                            .margin(top = 8.px)
                            .titleSmall()
                            .textAlign(TextAlign.Center),
                        text = "Clique em um marcador no mapa"
                    )
                }
            }
        }
    }
}

@Composable
private fun AnswersTable(
    table: State.Displaying.TableUIState,
    dialogState: DialogState,
    onEvent: (Event) -> Unit,
) {

    val popUpMenuStates = remember(table) {
        mutableMapOf<String, PopUpMenuState>()
    }

    // TODO melhorar tempo de renderização (PagedTable)
    // TODO Ordenar
    // TODO Editar
    // TODO Mover linha
    // TODO Excluir range

    Column(
        modifier = Modifier
            .fillMaxSize()
            .padding(16.px)
            .margin(top = 16.px)
            .background(sitePalette().surface).clip(Rect(16.px))
            .borderRadius(16.px),
    ) {
        Column(
            modifier = Modifier
                .id("tableContainer")
                .fillMaxSize()
                .overflow(Overflow.Auto)
        ) {
            if (table.tableHeight == null) {
                SingleEffect {
                    withNotNull(document.getElementById("tableContainer")) {
                        onEvent(Event.OnTableHeightSet(this.clientHeight))
                        addEventListener(
                            type = "wheel", callback = { event ->
                                (event as? WheelEvent)?.let { wheelEvent ->
                                    if (wheelEvent.deltaY > 0) {
                                        onEvent(Event.OnGoToNextTablePageClick)
                                    } else if (wheelEvent.deltaY < 0) {
                                        onEvent(Event.OnGoToPreviousTablePageClick)
                                    }
                                }
                            }
                        )
                    }
                }
            } else {
                Table(
                    attrs = Modifier
                        .fillMaxWidth()
                        .borderCollapse(BorderCollapse.Collapse)
                        .toAttrs()
                ) {
                    Thead(
                        attrs = Modifier
                            .fillMaxWidth()
                            .background(sitePalette().secondaryContainer)
                            .color(sitePalette().onSecondaryContainer)
                            .position(Position.Sticky)
                            .toAttrs()
                    ) {
                        Tr(
                            attrs = Modifier
                                .fillMaxWidth()
                                .toAttrs()
                        ) {

                            Td(
                                attrs = Modifier
                                    .border(
                                        width = 1.px,
                                        style = LineStyle.Solid,
                                        color = sitePalette().onSurface
                                    )
                                    .padding(vertical = 4.px, horizontal = 8.px)
                                    .toAttrs()
                            ) {
                                Text(
                                    modifier = Modifier
                                        .labelLarge()
                                        .fontWeight(FontWeight.Bold)
                                        .noWrap(),
                                    text = "#"
                                )
                            }

                            table.headers.forEachIndexed { index, header ->
                                Td(
                                    attrs = Modifier
                                        .border(
                                            width = 1.px,
                                            style = LineStyle.Solid,
                                            color = sitePalette().onSurface
                                        )
                                        .padding(vertical = 4.px, horizontal = 8.px)
                                        .toAttrs()
                                ) {
                                    Row(
                                        modifier = Modifier.fillMaxWidth(),
                                        verticalAlignment = Alignment.CenterVertically,
                                        horizontalArrangement = Arrangement.SpaceBetween
                                    ) {
                                        Text(
                                            modifier = Modifier
                                                .labelLarge()
                                                .fontWeight(FontWeight.Bold)
                                                .noWrap(),
                                            text = header
                                        )

                                        Row(
                                            verticalAlignment = Alignment.CenterVertically
                                        ) {
                                            Box(
                                                modifier = Modifier
                                                    .cursor(Cursor.Pointer)
                                                    .margin(left = 8.px)
                                                    .size(32.px)
                                                    .padding(4.px)
                                                    .clip(Rect(32.px))
                                                    .hoverBackground(
                                                        onMouseExitBackgroundColor = sitePalette().secondaryContainer,
                                                        onMouseEnterBackgroundColor = sitePalette().secondaryContainer.darkened(
                                                            0.3f
                                                        )
                                                    )
                                                    .onClick {
                                                        onEvent(Event.OnSortAnswerTable(columnIndex = index))
                                                    },
                                            ) {
                                                MaterialIcon(
                                                    icon = MaterialIcons.Round.SwapVert,
                                                    tint = sitePalette().onSecondaryContainer
                                                )
                                            }

                                            PopUpMenu(
                                                state = popUpMenuStates.getOrPut("col$header") {
                                                    PopUpMenuState()
                                                },
                                                onOptionClick = {
                                                    popUpMenuStates
                                                        .filter { it.key != "col$header" }
                                                        .values
                                                        .forEach { state -> state.dismiss() }
                                                },
                                                options = mutableListOf(
                                                    PopUpMenuEntry(
                                                        id = "copy_all",
                                                        text = "Copiar coluna",
                                                        icon = MaterialIcons.Round.CopyAll
                                                    ),
                                                    PopUpMenuEntry(
                                                        id = "copy_rows",
                                                        text = "Copiar intervalo",
                                                        icon = MaterialIcons.Round.FormatListNumbered
                                                    )
                                                ).apply {
                                                    if ((table.displayGeoCoordinatesColumn && index > 2) || (!table.displayGeoCoordinatesColumn && index > 1)) {
                                                        add(
                                                            PopUpMenuEntry(
                                                                id = "replace_rows",
                                                                text = "Substituir intervalo",
                                                                icon = MaterialIcons.Round.TableRows
                                                            )
                                                        )
                                                    }
                                                },
                                                onOptionSelected = { optionId ->
                                                    when (optionId) {
                                                        "copy_all" -> onEvent(Event.OnCopyAnswerTableColumn(column = header))
                                                        "copy_rows" -> copyRowsDialog(
                                                            dialogState,
                                                            table,
                                                            header,
                                                            onEvent
                                                        )

                                                        "replace_rows" -> replaceRowsDialog(
                                                            dialogState,
                                                            onEvent,
                                                            header
                                                        )
                                                    }
                                                }
                                            ) {
                                                Box(
                                                    modifier = Modifier
                                                        .size(32.px)
                                                        .margin(left = 4.px)
                                                        .padding(4.px)
                                                        .clip(Rect(32.px))
                                                        .hoverBackground(
                                                            onMouseExitBackgroundColor = sitePalette().secondaryContainer,
                                                            onMouseEnterBackgroundColor = sitePalette().secondaryContainer.darkened(
                                                                0.3f
                                                            )
                                                        ),
                                                ) {
                                                    MaterialIcon(
                                                        icon = MaterialIcons.Round.MoreVert,
                                                        tint = sitePalette().onSecondaryContainer
                                                    )
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    Tbody(
                        attrs = Modifier
                            .fillMaxSize()
                            .toAttrs()
                    ) {
                        for (rowIndex in table.rowsWindow) {
                            Tr(
                                attrs = Modifier
                                    .fillMaxWidth()
                                    .toAttrs()
                            ) {

                                Td(
                                    attrs = Modifier
                                        .border(
                                            width = 1.px,
                                            style = LineStyle.Solid,
                                            color = sitePalette().onSurface
                                        )
                                        .padding(vertical = 4.px, horizontal = 8.px)
                                        .toAttrs()
                                ) {
                                    Row(
                                        verticalAlignment = Alignment.CenterVertically,
                                        horizontalArrangement = Arrangement.SpaceBetween
                                    ) {

                                        Text(
                                            modifier = Modifier
                                                .labelLarge()
                                                .noWrap(),
                                            text = (rowIndex + 1).toString()
                                        )

                                        PopUpMenu(
                                            state = popUpMenuStates.getOrPut("row$rowIndex") {
                                                PopUpMenuState()
                                            },
                                            onOptionClick = {
                                                popUpMenuStates
                                                    .filter { it.key != "row$rowIndex" }
                                                    .values
                                                    .forEach { state -> state.dismiss() }
                                            },
                                            options = mutableListOf(
                                                PopUpMenuEntry(
                                                    id = "duplicate_row",
                                                    text = "Duplicar linha",
                                                    icon = MaterialIcons.Round.ContentCopy
                                                ),
                                                PopUpMenuEntry(
                                                    id = "remove_row",
                                                    text = "Apagar linha",
                                                    icon = MaterialIcons.Round.Delete
                                                ),
                                                PopUpMenuEntry(
                                                    id = "remove_rows",
                                                    text = "Apagar linhas",
                                                    icon = MaterialIcons.Round.DeleteSweep
                                                )
                                            ),
                                            onOptionSelected = { optionId ->
                                                when (optionId) {
                                                    "duplicate_row" -> onEvent(Event.OnDuplicateAnswerTableRow(rowIndex))
                                                    "remove_row" -> onEvent(Event.OnRemoveAnswerTableRow(rowIndex))
                                                    "remove_rows" -> deleteRowsDialog(dialogState, table, onEvent)
                                                }
                                            }
                                        ) {
                                            Box(
                                                modifier = Modifier
                                                    .size(32.px)
                                                    .margin(left = 4.px)
                                                    .padding(4.px)
                                                    .clip(Rect(32.px))
                                                    .hoverBackground(
                                                        onMouseExitBackgroundColor = sitePalette().surface,
                                                        onMouseEnterBackgroundColor = sitePalette().secondaryContainer
                                                    ),
                                            ) {
                                                MaterialIcon(
                                                    icon = MaterialIcons.Round.MoreVert,
                                                    tint = sitePalette().onSecondaryContainer
                                                )
                                            }
                                        }
                                    }
                                }

                                table.answers[rowIndex].values.forEachIndexed { columnIndex, answer ->
                                    Td(
                                        attrs = Modifier
                                            .border(
                                                width = 1.px,
                                                style = LineStyle.Solid,
                                                color = sitePalette().onSurface
                                            )
                                            .padding(vertical = 4.px, horizontal = 8.px)
                                            .toAttrs()
                                    ) {
                                        Row(
                                            modifier = Modifier.fillMaxWidth(),
                                            verticalAlignment = Alignment.CenterVertically
                                        ) {
                                            Text(
                                                modifier = Modifier
                                                    .labelLarge()
                                                    .noWrap(),
                                                text = answer.value
                                            )

                                            if ((table.displayGeoCoordinatesColumn && columnIndex > 2) || (!table.displayGeoCoordinatesColumn && columnIndex > 1)) {
                                                Box(
                                                    modifier = Modifier
                                                        .size(32.px)
                                                        .cursor(Cursor.Pointer)
                                                        .margin(left = 4.px)
                                                        .padding(4.px)
                                                        .hoverColor(
                                                            onMouseExitColor = sitePalette().surface,
                                                            onMouseEnterColor = sitePalette().onSurface
                                                        ).onClick {
                                                            editCellDialog(
                                                                dialogState,
                                                                answer,
                                                                onEvent,
                                                                table,
                                                                rowIndex
                                                            )
                                                        }
                                                ) {
                                                    MaterialIcon(
                                                        icon = MaterialIcons.Round.Edit
                                                    )
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        Row(
            modifier = Modifier
                .margin(top = 16.px)
                .fillMaxWidth()
                .display(DisplayStyle.Flex)
                .gap(8.px),
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically
        ) {
            IconButton(
                onClick = {
                    onEvent(Event.OnGoToFirstTablePageClick)
                }
            ) {
                MaterialIcon(MaterialIcons.Round.SkipPrevious)
            }
            IconButton(
                onClick = {
                    onEvent(Event.OnGoToPreviousTablePageClick)
                }
            ) {
                MaterialIcon(MaterialIcons.Round.KeyboardArrowLeft)
            }

            Text(
                modifier = Modifier
                    .userSelect(UserSelect.None)
                    .bodyMedium()
                    .color(sitePalette().onSurface),
                text = "${table.currentPage}/${table.pages}"
            )

            IconButton(
                onClick = {
                    onEvent(Event.OnGoToNextTablePageClick)
                }
            ) {
                MaterialIcon(MaterialIcons.Round.KeyboardArrowRight)
            }
            IconButton(
                onClick = {
                    onEvent(Event.OnGoToLastTablePageClick)
                }
            ) {
                MaterialIcon(MaterialIcons.Round.SkipNext)
            }
        }
    }
}

private fun replaceRowsDialog(
    dialogState: DialogState,
    onEvent: (Event) -> Unit,
    header: String
) {
    dialogState.show {
        var startRow by remember { mutableStateOf("1") }
        var values by remember { mutableStateOf("") }

        Column(
            modifier = Modifier.fillMaxWidth()
        ) {

            Text(
                text = "Substituir linhas",
                modifier = Modifier
                    .fillMaxWidth()
                    .headlineSmall()
            )

            Text(
                text = "Defina a linha inicial",
                modifier = Modifier
                    .margin(top = 16.px)
                    .fillMaxWidth()
                    .bodyMedium()
            )

            TextField(
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                text = startRow,
                onTextChanged = {
                    startRow = it
                }
            )

            Text(
                text = "Adicione valores separados por quebra de linha ou por \";\"",
                modifier = Modifier
                    .margin(top = 16.px)
                    .fillMaxWidth()
                    .bodyMedium()
            )

            TextArea(
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                text = values,
                lines = 5,
                onTextChanged = {
                    values = it
                }
            )

            Row(
                modifier = Modifier
                    .margin(top = 24.px)
                    .fillMaxWidth(),
                horizontalArrangement = Arrangement.End
            ) {
                HoverableContent(
                    modifier = Modifier.padding(
                        horizontal = 16.px,
                        vertical = 8.px
                    ),
                    onHoverEndColor = sitePalette().surface,
                    onClick = {
                        dialogState.dismiss()
                    }
                ) {
                    Text(
                        text = "Cancelar",
                        modifier = Modifier.bodyLarge()
                    )
                }
                HoverableContent(
                    modifier = Modifier.padding(
                        horizontal = 16.px,
                        vertical = 8.px
                    ),
                    onHoverEndColor = sitePalette().surface,
                    onClick = {
                        onEvent(
                            Event.OnReplaceAnswerTableRows(
                                column = header,
                                startRow = startRow.toInt(),
                                values = values
                            )
                        )
                        dialogState.dismiss()
                    }
                ) {
                    Text(
                        text = "Substituir",
                        modifier = Modifier.bodyLarge()
                    )
                }
            }
        }
    }
}

private fun copyRowsDialog(
    dialogState: DialogState,
    table: State.Displaying.TableUIState,
    header: String,
    onEvent: (Event) -> Unit
) {
    dialogState.show {

        var startRow by remember { mutableStateOf("1") }
        var endRow by remember { mutableStateOf(table.answers.size.toString()) }

        Column(
            modifier = Modifier.fillMaxWidth()
        ) {
            Text(
                text = "Copiar linhas",
                modifier = Modifier
                    .fillMaxWidth()
                    .headlineSmall()
            )

            Text(
                text = "Defina o intervalo de linhas da coluna $header serem copiadas",
                modifier = Modifier
                    .margin(top = 16.px)
                    .fillMaxWidth()
                    .bodyMedium()
            )
            Row(
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            ) {
                TextField(
                    modifier = Modifier
                        .margin(top = 8.px)
                        .fillMaxWidth(),
                    text = startRow,
                    onTextChanged = {
                        startRow = it
                    }
                )

                Text(
                    text = "a",
                    modifier = Modifier
                        .margin(horizontal = 8.px)
                        .titleMedium()
                )

                TextField(
                    modifier = Modifier
                        .margin(top = 8.px)
                        .fillMaxWidth(),
                    text = endRow,
                    onTextChanged = {
                        endRow = it
                    }
                )
            }
            Row(
                modifier = Modifier
                    .margin(top = 24.px)
                    .fillMaxWidth(),
                horizontalArrangement = Arrangement.End
            ) {
                HoverableContent(
                    modifier = Modifier.padding(
                        horizontal = 16.px,
                        vertical = 8.px
                    ),
                    onHoverEndColor = sitePalette().surface,
                    onClick = {
                        dialogState.dismiss()
                    }
                ) {
                    Text(
                        text = "Cancelar",
                        modifier = Modifier.bodyLarge()
                    )
                }
                HoverableContent(
                    modifier = Modifier.padding(
                        horizontal = 16.px,
                        vertical = 8.px
                    ),
                    onHoverEndColor = sitePalette().surface,
                    onClick = {
                        onEvent(
                            Event.OnCopyAnswerTableRows(
                                column = header,
                                startRow = startRow.toInt(),
                                endRow = endRow.toInt()
                            )
                        )
                        dialogState.dismiss()
                    }
                ) {
                    Text(
                        text = "Copiar",
                        modifier = Modifier.bodyLarge()
                    )
                }
            }
        }
    }
}

private fun deleteRowsDialog(
    dialogState: DialogState,
    table: State.Displaying.TableUIState,
    onEvent: (Event) -> Unit
) {
    dialogState.show {

        var startRow by remember { mutableStateOf("1") }
        var endRow by remember { mutableStateOf(table.answers.size.toString()) }

        Column(
            modifier = Modifier.fillMaxWidth()
        ) {
            Text(
                text = "Apagar linhas",
                modifier = Modifier
                    .fillMaxWidth()
                    .headlineSmall()
            )

            Text(
                text = "Defina o intervalo de linhas a serem apagadas",
                modifier = Modifier
                    .margin(top = 16.px)
                    .fillMaxWidth()
                    .bodyMedium()
            )
            Row(
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            ) {
                TextField(
                    modifier = Modifier
                        .margin(top = 8.px)
                        .fillMaxWidth(),
                    text = startRow,
                    onTextChanged = {
                        startRow = it
                    }
                )

                Text(
                    text = "a",
                    modifier = Modifier
                        .margin(horizontal = 8.px)
                        .titleMedium()
                )

                TextField(
                    modifier = Modifier
                        .margin(top = 8.px)
                        .fillMaxWidth(),
                    text = endRow,
                    onTextChanged = {
                        endRow = it
                    }
                )
            }
            Row(
                modifier = Modifier
                    .margin(top = 24.px)
                    .fillMaxWidth(),
                horizontalArrangement = Arrangement.End
            ) {
                HoverableContent(
                    modifier = Modifier.padding(
                        horizontal = 16.px,
                        vertical = 8.px
                    ),
                    onHoverEndColor = sitePalette().surface,
                    onClick = {
                        dialogState.dismiss()
                    }
                ) {
                    Text(
                        text = "Cancelar",
                        modifier = Modifier.bodyLarge()
                    )
                }
                HoverableContent(
                    modifier = Modifier.padding(
                        horizontal = 16.px,
                        vertical = 8.px
                    ),
                    onHoverEndColor = sitePalette().surface,
                    onClick = {
                        onEvent(
                            Event.OnRemoveAnswerTableRows(
                                startRow = startRow.toInt(),
                                endRow = endRow.toInt()
                            )
                        )
                        dialogState.dismiss()
                    }
                ) {
                    Text(
                        text = "Apagar",
                        modifier = Modifier.bodyLarge()
                    )
                }
            }
        }
    }
}

private fun editCellDialog(
    dialogState: DialogState,
    answer: State.Displaying.TableUIState.AnswerCellUIState,
    onEvent: (Event) -> Unit,
    table: State.Displaying.TableUIState,
    rowIndex: Int
) {
    dialogState.show {
        var value by remember { mutableStateOf(answer.value) }
        Column(
            modifier = Modifier.fillMaxWidth()
        ) {
            Text(
                text = "Insira um novo valor",
                modifier = Modifier
                    .fillMaxWidth()
                    .headlineSmall()
            )
            TextField(
                modifier = Modifier
                    .margin(top = 8.px)
                    .fillMaxWidth(),
                text = value,
                onTextChanged = {
                    value = it
                },
                onEnterPress = {
                    onEvent(
                        Event.OnEditTableRow(
                            cellIdentifier = answer.identifier,
                            answerIdentifier = table.answers[rowIndex].identifier,
                            newValue = value
                        )
                    )
                    dialogState.dismiss()
                }
            )
            Row(
                modifier = Modifier
                    .margin(top = 24.px)
                    .fillMaxWidth(),
                horizontalArrangement = Arrangement.End
            ) {
                HoverableContent(
                    modifier = Modifier.padding(
                        horizontal = 16.px,
                        vertical = 8.px
                    ),
                    onHoverEndColor = sitePalette().surface,
                    onClick = {
                        dialogState.dismiss()
                    }
                ) {
                    Text(
                        text = "Cancelar",
                        modifier = Modifier.bodyLarge()
                    )
                }
                HoverableContent(
                    modifier = Modifier.padding(
                        horizontal = 16.px,
                        vertical = 8.px
                    ),
                    onHoverEndColor = sitePalette().surface,
                    onClick = {
                        onEvent(
                            Event.OnEditTableRow(
                                cellIdentifier = answer.identifier,
                                answerIdentifier = table.answers[rowIndex].identifier,
                                newValue = value
                            )
                        )
                        dialogState.dismiss()
                    }
                ) {
                    Text(
                        text = "Confirmar",
                        modifier = Modifier.bodyLarge()
                    )
                }
            }
        }
    }
}