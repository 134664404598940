package com.catbit.opinionpoll.inputs.validators

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.inputs.DateTimeInputUIState

class DateTimeInputUIValidator : InputUIValidator<DateTimeInputUIState> {
    override fun match(inputUIState: InputUIState) = inputUIState is DateTimeInputUIState

    override fun validate(
        inputUIState: DateTimeInputUIState,
        allIdentifiers: List<String>
    ): InputUIState {

        val errorMessage = with(inputUIState) {
            if (identifier.isEmpty()) {
                "O identificador não pode ser vazio"
            } else if (title.isEmpty()) {
                "O título não pode ser vazio"
            } else if (minDateTime != null && maxDateTime != null && minDateTime >= maxDateTime) {
                "Data e hora mínimas não podem ser maiores que data e hora máximas"
            } else if (identifier in allIdentifiers) {
                "O identificador precisa ser único"
            } else null
        }

        return inputUIState.copy(errorMessage = errorMessage)
    }
}