package com.catbit.opinionpoll.inputs.responses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Evaluation")
data class EvaluationInputResponse(
    override val identifier: String,
    override val title: String,
    override val subtitle: String?,
    override val isObligatory: Boolean,
    override val index: Int,
    @SerialName("minValue")
    val minValue: Int,
    @SerialName("maxValue")
    val maxValue: Int
) : InputResponse