package com.catbit.opinionpoll.inputs

import androidx.compose.runtime.Composable
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import org.jetbrains.compose.web.css.CSSNumeric

sealed interface InputType {

    @Composable
    fun Icon(
        size: CSSNumeric,
        modifier: Modifier,
    )

    data object CompactSingleChoice : InputType {
        @Composable
        override fun Icon(
            size: CSSNumeric,
            modifier: Modifier
        ) {
            MaterialIcon(
                modifier = modifier,
                size = size,
                icon = MaterialIcons.Round.FormatListBulleted
            )
            Tooltip(
                target = ElementTarget.PreviousSibling,
                text = "Seleção única (modo compacto)",
                placement = PopupPlacement.BottomLeft
            )
        }
    }

    data object SingleChoice : InputType {
        @Composable
        override fun Icon(
            size: CSSNumeric,
            modifier: Modifier
        ) {
            MaterialIcon(
                modifier = modifier,
                size = size,
                icon = MaterialIcons.Round.RadioButtonChecked
            )
            Tooltip(
                target = ElementTarget.PreviousSibling,
                text = "Seleção única",
                placement = PopupPlacement.BottomLeft
            )
        }
    }

    data object MultipleChoices : InputType {
        @Composable
        override fun Icon(
            size: CSSNumeric,
            modifier: Modifier
        ) {
            MaterialIcon(
                modifier = modifier,
                size = size,
                icon = MaterialIcons.Round.CheckBox
            )
            Tooltip(
                target = ElementTarget.PreviousSibling,
                text = "Seleção múltipla",
                placement = PopupPlacement.BottomLeft
            )
        }
    }

    data object Date : InputType {
        @Composable
        override fun Icon(
            size: CSSNumeric,
            modifier: Modifier
        ) {
            MaterialIcon(
                modifier = modifier,
                size = size,
                icon = MaterialIcons.Round.CalendarMonth
            )
            Tooltip(
                target = ElementTarget.PreviousSibling,
                text = "Data",
                placement = PopupPlacement.BottomLeft
            )
        }
    }

    data object Time : InputType {
        @Composable
        override fun Icon(
            size: CSSNumeric,
            modifier: Modifier
        ) {
            MaterialIcon(
                modifier = modifier,
                size = size,
                icon = MaterialIcons.Round.Alarm
            )
            Tooltip(
                target = ElementTarget.PreviousSibling,
                text = "Hora",
                placement = PopupPlacement.BottomLeft
            )
        }
    }

    data object DateTime : InputType {
        @Composable
        override fun Icon(
            size: CSSNumeric,
            modifier: Modifier
        ) {
            MaterialIcon(
                modifier = modifier,
                size = size,
                icon = MaterialIcons.Round.CalendarToday
            )
            Tooltip(
                target = ElementTarget.PreviousSibling,
                text = "Data e hora",
                placement = PopupPlacement.BottomLeft
            )
        }
    }

    data object Evaluation : InputType {
        @Composable
        override fun Icon(
            size: CSSNumeric,
            modifier: Modifier
        ) {
            MaterialIcon(
                modifier = modifier,
                size = size,
                icon = MaterialIcons.Round.Star
            )
            Tooltip(
                target = ElementTarget.PreviousSibling,
                text = "Avaliação em escala",
                placement = PopupPlacement.BottomLeft
            )
        }
    }

    data object MaskedTextArea : InputType {
        @Composable
        override fun Icon(
            size: CSSNumeric,
            modifier: Modifier
        ) {
            MaterialIcon(
                modifier = modifier,
                size = size,
                icon = MaterialIcons.Round.Masks
            )
            Tooltip(
                target = ElementTarget.PreviousSibling,
                text = "Texto com máscara",
                placement = PopupPlacement.BottomLeft
            )
        }
    }

    data object NumericArea : InputType {
        @Composable
        override fun Icon(
            size: CSSNumeric,
            modifier: Modifier
        ) {
            MaterialIcon(
                modifier = modifier,
                size = size,
                icon = MaterialIcons.Round.AttachMoney
            )
            Tooltip(
                target = ElementTarget.PreviousSibling,
                text = "Campo numérico",
                placement = PopupPlacement.BottomLeft
            )
        }
    }

    data object TextArea : InputType {
        @Composable
        override fun Icon(
            size: CSSNumeric,
            modifier: Modifier
        ) {
            MaterialIcon(
                modifier = modifier,
                size = size,
                icon = MaterialIcons.Round.Title
            )
            Tooltip(
                target = ElementTarget.PreviousSibling,
                text = "Área de texto",
                placement = PopupPlacement.BottomLeft
            )
        }
    }

    companion object {
        val all
            get() = listOf(
                SingleChoice,
                MultipleChoices,
                CompactSingleChoice,
                TextArea,
                NumericArea,
                MaskedTextArea,
                Date,
                Time,
                DateTime,
                Evaluation
            )
    }
}