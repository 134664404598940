package com.catbit.opinionpoll.inputs.requests.producers

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.inputs.MultipleChoicesInputUIState
import com.catbit.opinionpoll.inputs.requests.requests.MultipleChoicesOptionsInputRequest

class MultipleChoicesInputRequestProducer : InputRequestProducer<MultipleChoicesInputUIState> {
    override fun match(inputUIState: InputUIState) = inputUIState is MultipleChoicesInputUIState

    override fun produce(inputUIState: MultipleChoicesInputUIState) = MultipleChoicesOptionsInputRequest(
        identifier = inputUIState.identifier,
        index = inputUIState.index,
        title = inputUIState.title,
        subtitle = inputUIState.subtitle,
        isObligatory = inputUIState.isObligatory,
        options = inputUIState.options.map { it.title },
        style = inputUIState.style.name.lowercase(),
        minSelection = inputUIState.minSelection,
        maxSelection = inputUIState.maxSelection,
        enableOtherOptions = inputUIState.hasOtherOption,
        otherOptionPlaceholderText = inputUIState.otherOptionPlaceholderText,
        maxOtherOptions = inputUIState.maxOtherOptions,
        maxOtherOptionTextLength = inputUIState.maxOtherOptionTextLength,
    )
}