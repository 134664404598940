package com.catbit.opinionpoll.domain.user

import com.catbit.opinionpoll.core.domain.UseCase
import com.catbit.opinionpoll.data.repositories.OpinionPollRepository

class DeleteUsersUseCase(
    private val repository: OpinionPollRepository
) : UseCase<Unit, DeleteUsersUseCase.Params>() {

    override suspend fun execute(params: Params) =
        repository.deleteUsers(userIdentifiers = params.userIdentifiers)

    data class Params(
        val userIdentifiers: List<String>
    )
}