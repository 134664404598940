package com.catbit.opinionpoll.ui.screens.form_users_linker

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.rememberCoroutineScope
import com.catbit.opinionpoll.core.extensions.getStateHolder
import com.catbit.opinionpoll.core.sailor.navigation_controller.NavigationController
import com.catbit.opinionpoll.core.sailor.providers.LocalDialogStateProvider
import com.catbit.opinionpoll.core.sailor.providers.LocalSnackbarState
import com.catbit.opinionpoll.core.ui.composables.*
import com.catbit.opinionpoll.core.ui.composables.base_components.*
import com.catbit.opinionpoll.core.ui.composables.effects.SingleEffect
import com.catbit.opinionpoll.core.ui.icons.MaterialIcon
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.*
import com.catbit.opinionpoll.res.Strings
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.catbit.opinionpoll.ui.composables.User
import com.catbit.opinionpoll.ui.states.UserUIState
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.coroutines.flow.collectLatest
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.px
import org.koin.core.parameter.parametersOf

@Composable
fun FormUsersLinkerScreen(
    stackEntryId: String,
    navigationController: NavigationController,
    formIdentifier: String,
    formTitle: String,
    stateHolder: FormUsersLinkerStateHolder = getStateHolder(
        stackEntryId = stackEntryId,
        parameters = parametersOf(formIdentifier)
    )
) {
    val uiState = stateHolder.uiState.collectAsState().value
    val dialogState = LocalDialogStateProvider.current
    val coroutineScope = rememberCoroutineScope()
    val snackBarState = LocalSnackbarState.current

    SingleEffect {
        stateHolder.effects.collectLatest {
            when (it) {
                is FormUsersLinkerUIContract.Effect.OnSaveChangesFailure -> {
                    dialogState.dismiss()
                    snackBarState.show(
                        coroutineScope = coroutineScope,
                        message = it.message
                    )
                }

                FormUsersLinkerUIContract.Effect.OnSaveChangesSuccess -> {
                    dialogState.dismiss()
                    snackBarState.show(
                        coroutineScope = coroutineScope,
                        message = "Alteração salva com sucesso!",
                        onDismiss = {
                            navigationController.goBack()
                        }
                    )
                }

                FormUsersLinkerUIContract.Effect.OnStartSavingChanges -> {
                    dialogState.show {
                        Column(
                            modifier = Modifier.fillMaxWidth(),
                            horizontalAlignment = Alignment.CenterHorizontally
                        ) {
                            CircularProgress(50)
                            Text(
                                modifier = Modifier.margin(top = 8.px),
                                text = "Salvando alterações"
                            )
                        }
                    }
                }
            }
        }
    }

    Column(
        modifier = Modifier.fillMaxSize(),
    ) {
        Toolbar(
            title = "Vincular $formTitle à membro da equipe",
            onLeftIconClick = { navigationController.goBack() },
            trailingContent = {
                if (uiState is FormUsersLinkerUIContract.State.Displaying) {
                    IconButton(
                        onClick = {
                            stateHolder.onEvent(
                                FormUsersLinkerUIContract.Event.OnSaveChanges
                            )
                        }
                    ) {
                        MaterialIcon(icon = MaterialIcons.Round.CloudUpload)
                    }
                    Tooltip(
                        target = ElementTarget.PreviousSibling,
                        text = Strings.send,
                        placement = PopupPlacement.BottomRight
                    )
                }
            }
        )
        when (uiState) {
            is FormUsersLinkerUIContract.State.Displaying -> {
                Column(
                    modifier = Modifier
                        .fillMaxSize()
                        .overflow { y(Overflow.Hidden) }
                ) {
                    Row(
                        modifier = Modifier
                            .padding(right = 24.px, left = 24.px, bottom = 24.px)
                            .fillMaxSize(),
                    ) {
                        UserList(
                            title = "Membros da equipe não vinculados",
                            linkTerm = "Desvincular",
                            users = uiState.nonAssignedUsers,
                            onUserSelect = {
                                stateHolder.onEvent(
                                    FormUsersLinkerUIContract.Event.OnUserChecked(it)
                                )
                            },
                            query = uiState.nonAssignedUsersQuery,
                            onSearch = {
                                stateHolder.onEvent(
                                    FormUsersLinkerUIContract.Event.OnNonAssignedUsersSearch(it)
                                )
                            },
                            onClearSearch = {
                                stateHolder.onEvent(
                                    FormUsersLinkerUIContract.Event.OnNonAssignedClearSearch
                                )
                            },
                            sorting = uiState.nonAssignedUsersSorting,
                            sortingOptions = uiState.sortingOptions,
                            onSort = {
                                stateHolder.onEvent(
                                    FormUsersLinkerUIContract.Event.OnNonAssignedUsersSortingChange(it)
                                )
                            }
                        )
                        Controls(
                            onEvent = { stateHolder.onEvent(it) }
                        )
                        UserList(
                            title = "Membros da equipe vinculados",
                            linkTerm = "Vincular",
                            users = uiState.assignedUsers,
                            onUserSelect = {
                                stateHolder.onEvent(
                                    FormUsersLinkerUIContract.Event.OnUserChecked(it)
                                )
                            },
                            query = uiState.assignedUsersQuery,
                            onSearch = {
                                stateHolder.onEvent(
                                    FormUsersLinkerUIContract.Event.OnAssignedUsersSearch(it)
                                )
                            },
                            onClearSearch = {
                                stateHolder.onEvent(
                                    FormUsersLinkerUIContract.Event.OnAssignedClearSearch
                                )
                            },
                            sorting = uiState.assignedUsersSorting,
                            sortingOptions = uiState.sortingOptions,
                            onSort = {
                                stateHolder.onEvent(
                                    FormUsersLinkerUIContract.Event.OnAssignedUsersSortingChange(it)
                                )
                            }
                        )
                    }
                }
            }

            is FormUsersLinkerUIContract.State.Failure -> {
                Column(
                    modifier = Modifier.fillMaxSize(),
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Text("Erro")
                }
            }

            is FormUsersLinkerUIContract.State.Loading -> {
                Column(
                    modifier = Modifier.fillMaxSize(),
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    CircularProgress(50)
                    Text("Carregando")
                }
            }
        }
    }
}

@Composable
private fun Controls(
    onEvent: (FormUsersLinkerUIContract.Event) -> Unit
) {
    Column(
        modifier = Modifier
            .fillMaxHeight()
            .margin(horizontal = 16.px),
        verticalArrangement = Arrangement.Center
    ) {
        Control(
            title = "Vincular todos",
            icon = MaterialIcons.Round.KeyboardDoubleArrowRight
        ) {
            onEvent(FormUsersLinkerUIContract.Event.OnMoveAllUsersToAssigned)
        }
        Spacer(modifier = Modifier.height(8.px))
        Control(
            title = "Vincular",
            icon = MaterialIcons.Round.KeyboardArrowRight
        ) {
            onEvent(FormUsersLinkerUIContract.Event.OnMoveUsersToAssigned)
        }
        Spacer(modifier = Modifier.height(8.px))
        Control(
            title = "Desvincular",
            icon = MaterialIcons.Round.KeyboardArrowLeft
        ) {
            onEvent(FormUsersLinkerUIContract.Event.OnMoveUsersToNonAssigned)
        }
        Spacer(modifier = Modifier.height(8.px))
        Control(
            title = "Desvincular todos",
            icon = MaterialIcons.Round.KeyboardDoubleArrowLeft
        ) {
            onEvent(FormUsersLinkerUIContract.Event.OnMoveAllUsersToNonAssigned)
        }
    }
}

@Composable
private fun Control(
    title: String,
    icon: MaterialIcon,
    onEvent: () -> Unit
) {
    HoverableContent(
        modifier = Modifier
            .fillMaxWidth()
            .padding(right = 16.px, left = 16.px, top = 8.px, bottom = 8.px),
        onHoverEndColor = sitePalette().surface,
        onClick = onEvent
    ) {
        Row {
            MaterialIcon(
                modifier = Modifier.margin(right = 8.px),
                icon = icon
            )
            Text(
                text = title,
                modifier = Modifier
                    .noWrap()
                    .bodyLarge()
            )
        }
    }
}

@Composable
private fun UserList(
    title: String,
    linkTerm: String,
    users: List<UserUIState>,
    onUserSelect: (String) -> Unit,
    query: String,
    onSearch: (String) -> Unit,
    onClearSearch: () -> Unit,
    sorting: String,
    sortingOptions: List<String>,
    onSort: (String) -> Unit,
) {
    Column(
        modifier = Modifier
            .fillMaxSize()
            .clip(Rect(24.px))
            .padding(all = 24.px)
            .background(sitePalette().surface)
    ) {
        Text(
            modifier = Modifier
                .color(sitePalette().onSurface)
                .titleMedium()
                .margin(bottom = 16.px)
                .fillMaxWidth(),
            text = title
        )
        SearchBar(
            modifier = Modifier
                .fillMaxWidth()
                .margin(bottom = 16.px),
            onClear = onClearSearch,
            hint = "Busque por nome, email ou telefone",
            sorting = SearchBarSorting(
                selected = sorting,
                options = sortingOptions,
                onSort = onSort
            ),
            query = query,
            onSearch = onSearch,
            backgroundColor = sitePalette().surfaceVariant,
            textColor = sitePalette().onSurfaceVariant
        )

        Column(
            modifier = Modifier
                .fillMaxSize()
                .display(DisplayStyle.Flex)
                .gap(16.px)
                .overflow { y(Overflow.Auto) }
        ) {

            if (users.isEmpty()) {
                Column(
                    modifier = Modifier
                        .fillMaxSize()
                        .padding(horizontal = 48.px),
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    MaterialIcon(
                        icon = MaterialIcons.Round.Group,
                        size = 48.px,
                        tint = sitePalette().onSurface
                    )
                    Text(
                        modifier = Modifier
                            .margin(top = 16.px)
                            .textAlign(TextAlign.Center)
                            .color(sitePalette().onSurface)
                            .titleLarge(),
                        text = "Nenhum membro da equipe nesta lista"
                    )
                    Text(
                        modifier = Modifier
                            .titleMedium()
                            .textAlign(TextAlign.Center)
                            .color(sitePalette().onSurface),
                        text = "Você pode selecionar alguns membros da equipe na lista ao e clicar em \"$linkTerm\", ou clicar em \"$linkTerm todos\"."
                    )
                }
            } else {
                users
                    .filter { user ->
                        user.name.contains(query, ignoreCase = true)
                                || user.email.contains(query, ignoreCase = true)
                                || user.phoneNumber.contains(query, ignoreCase = true)
                    }
                    .sortedWith { user1, user2 ->
                        when (sorting) {
                            "Nome" -> user1.name.compareTo(user2.name)
                            "Email" -> user1.email.compareTo(user2.email)
                            else -> user1.phoneNumber.compareTo(user2.phoneNumber)
                        }
                    }
                    .forEach { user ->
                        with(user) {
                            Row(
                                modifier = Modifier.fillMaxWidth(),
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Checkbox(
                                    modifier = Modifier.margin(right = 8.px),
                                    checked = checked
                                ) {
                                    onUserSelect(uid)
                                }
                                User(
                                    modifier = Modifier.fillMaxWidth(),
                                    uid = uid,
                                    name = name,
                                    email = email,
                                    roleIcon = roleIcon,
                                    phoneNumber = phoneNumber,
                                    backgroundColor = sitePalette().surfaceVariant,
                                    textColor = sitePalette().onSurface,
                                )
                            }
                        }
                    }
            }
        }
    }
}