package com.catbit.opinionpoll.inputs

import com.catbit.opinionpoll.inputs.graph.InputGraphDataHandler
import com.catbit.opinionpoll.inputs.models.InputModel

class InputGraphDataProducer(
    private val handlers: List<InputGraphDataHandler<*>>
) {
    fun produce(
        identifier: String,
        model: InputModel,
        rawData: List<String>
    ) = handlers.firstOrNull { it.handles(model) }?.handle(identifier, model, rawData)
}