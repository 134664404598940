package com.catbit.opinionpoll.core.state_holder

import androidx.compose.runtime.DisallowComposableCalls

object StateHolderFactory {

    private val stateHolders: MutableMap<String, StateHolder> = mutableMapOf()

    fun <T : StateHolder> getStateHolder(
        key: String,
        builderBlock: @DisallowComposableCalls () -> T
    ): StateHolderCreationContext<T> {

        val creationContext =
            if (stateHolders.containsKey(key)) CreationContext.Recovered else CreationContext.Created

        val stateHolder = stateHolders[key] ?: builderBlock().also {
            stateHolders[key] = it
        }

        return StateHolderCreationContext(stateHolder as T, creationContext)
    }

    fun removeStateHolder(
        key: String
    ) {
        stateHolders.remove(key)
    }
}

data class StateHolderCreationContext<T : StateHolder>(
    val stateHolder: T,
    val context: CreationContext
)

enum class CreationContext {
    Created, Recovered
}