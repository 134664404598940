package com.catbit.opinionpoll.inputs.events

sealed interface EvaluationInputEventData {

    data class OnMinValueChange(
        val newMinValue: Int
    ) : EvaluationInputEventData

    data class OnMaxValueChange(
        val newMaxValue: Int
    ) : EvaluationInputEventData
}