package com.catbit.opinionpoll.ui.screens.form_dashboard

import com.catbit.opinionpoll.core.charts.data.LineChartData
import com.catbit.opinionpoll.core.charts.ui.ChartsColorPallet
import com.catbit.opinionpoll.core.extensions.*
import com.catbit.opinionpoll.core.js.google_maps.maps.LatLng
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.data.models.FormAnswerModel
import com.catbit.opinionpoll.data.models.FormFilterModel
import com.catbit.opinionpoll.data.models.FormModel
import com.catbit.opinionpoll.data.models.UserModel
import com.catbit.opinionpoll.domain.form_dashboard.*
import com.catbit.opinionpoll.inputs.InputGraphDataProducer
import com.catbit.opinionpoll.inputs.state.InputGraphUIState
import com.catbit.opinionpoll.ui.base.ScreenStateHolder
import com.catbit.opinionpoll.ui.screens.form_dashboard.FormDashboardUIContract.*
import com.catbit.opinionpoll.ui.screens.form_dashboard.FormDashboardUIContract.State.Displaying.*
import com.catbit.opinionpoll.ui.states.FormFilterUIState
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch

class FormDashboardStateHolder(
    private val formIdentifier: String,
    private val formTitle: String,
    private val inputGraphDataProducer: InputGraphDataProducer,
    private val getFormDashboardUseCase: GetFormDashboardUseCase,
    private val getAnswersCSVUseCase: GetAnswersCSVUseCase,
    private val getAnswersXLSXUseCase: GetAnswersXLSXUseCase,
    private val saveFormFiltersUseCase: SaveFormFiltersUseCase,
    private val updateAnswersUseCase: UpdateAnswersUseCase
) : ScreenStateHolder<State, Event, Effect>() {

    override val internalUIState = MutableStateFlow<State>(
        State.Loading(
            toolbarTitle = "Dashboard de $formTitle"
        )
    )

    private lateinit var formModel: FormModel
    private lateinit var users: List<UserModel>
    private var answers: MutableMap<String, FormAnswerModel> = mutableMapOf()
    private val answersToAdd = mutableSetOf<String>()
    private val answersToUpdate = mutableSetOf<String>()
    private val answersToDelete = mutableSetOf<String>()

    private lateinit var titleToFieldIdentifiersMapping: Map<String, String>
    private lateinit var groupTitleToFieldIdentifiersMapping: Map<String, String>
    private lateinit var fieldIdentifiersToTitleMapping: Map<String, String>
    private lateinit var fieldIdentifiersToValuesMapping: Map<String, List<String>>

    override fun onStarted() {
        loadData()
    }

    private fun loadData() {

        internalUIState.update {
            State.Loading("Dashboard de $formTitle")
        }

        stateHolderScope.launch {
            getFormDashboardUseCase(
                GetFormDashboardUseCase.Params(formIdentifier)
            )
                .onSuccess { dashboardModel ->

                    if (dashboardModel.answers.isEmpty()) {
                        internalUIState.update {
                            State.Empty(
                                toolbarTitle = "Dashboard de $formTitle",
                                displayUpdateAnswersButton = false
                            )
                        }
                    } else {
                        formModel = dashboardModel.form
                        users = dashboardModel.users

                        normalizeAnswers(dashboardModel.answers)

                        val chartUIState = buildChartsUIState()

                        titleToFieldIdentifiersMapping = getAvailableGroupingFields().toMap()
                        groupTitleToFieldIdentifiersMapping = titleToFieldIdentifiersMapping.filter {
                            it.value in fieldIdentifiersToValuesMapping.keys
                        }
                        fieldIdentifiersToTitleMapping = titleToFieldIdentifiersMapping.swap()

                        internalUIState.update {
                            State.Displaying(
                                toolbarTitle = "Dashboard de $formTitle",
                                menuOptions = createMenuOptions(),
                                displayUpdateAnswersButton = false,
                                selectedMenuId = "bi",
                                charts = chartUIState,
                                bi = buildDashboardUIState(),
                                table = buildTableUIState(),
                                map = buildMapUIState()
                            )
                        }
                    }
                }
                .onFailure {
                    internalUIState.update {
                        State.Failure(
                            toolbarTitle = "Dashboard de $formTitle"
                        )
                    }
                }
        }
    }

    private fun normalizeAnswers(
        rawAnswers: List<FormAnswerModel>
    ) {
        val availableQuestionIdentifiers = formModel.inputs.map { it.identifiers }.flatten()
        rawAnswers.forEach { rawAnswer ->
            with(rawAnswer) {
                answers[identifier] = FormAnswerModel(
                    identifier = identifier,
                    submittedBy = submittedBy,
                    submittedAt = submittedAt,
                    geoCoordinates = geoCoordinates,
                    answer = availableQuestionIdentifiers.associateWith { identifier ->
                        (answer[identifier] ?: "\u200E ")
                    },
                )
            }
        }
    }

    private fun buildDashboardUIState(): BIUIState {
        val filters = formModel.filters?.mapNotNull { filterModel ->
            fieldIdentifiersToTitleMapping[filterModel.groupingField]?.let { translatedGroupingField ->
                with(filterModel) {
                    FormFilterUIState(
                        identifier = identifier,
                        title = title,
                        groupingField = translatedGroupingField,
                        subgroups = subgroups.map { subgroup ->
                            with(subgroup) {
                                FormFilterUIState.FormFilterSubgroupFieldUIState(
                                    identifier = identifier,
                                    title = title,
                                    values = values
                                )
                            }
                        },
                        fieldsToDisplay = fieldsToDisplay.mapNotNull { fieldIdentifiersToTitleMapping[it] },
                    )
                }
            }
        }.orEmpty()

        val subgroups = filters.firstOrNull()?.subgroups.orEmpty()

        return BIUIState(
            biAnswers = createBIAnswers(
                groupingField = filters.firstOrNull()?.groupingField,
                fieldsToDisplay = filters.firstOrNull()?.fieldsToDisplay.orEmpty(),
                values = filters.firstOrNull()?.subgroups?.map { it.values }?.flatten().orEmpty(),
                answers = answers.values
            ),
            hasOriginalFilters = formModel.filters.isNullOrEmpty().not(),
            filters = filters,
            selectedFilter = filters.firstOrNull()?.title.orEmpty(),
            filterSubgroups = subgroups.map { it.title },
            selectedFilterSubgroups = subgroups.map { it.title },
            filterSubgroupValues = subgroups.map { it.values }.flatten(),
            selectedFilterSubgroupValues = subgroups.map { it.values }.flatten(),
            editAvailableGroupingFields = groupTitleToFieldIdentifiersMapping.keys.toList(),
            editSelectedGroupingField = groupTitleToFieldIdentifiersMapping.keys.firstOrNull().orEmpty(),
            editSubgroups = listOf(),
            editFilterIdentifier = UUID.stringUUID(),
            editFilterTitle = "",
            editAvailableSubgroupsValues = mutableListOf<String>().apply {
                withNotNull(groupTitleToFieldIdentifiersMapping.values.firstOrNull()) {
                    fieldIdentifiersToValuesMapping[this]?.let {
                        addAll(it)
                    }
                }
            }.sorted(),
            editAvailableCrossingFields = groupTitleToFieldIdentifiersMapping.keys.toList().drop(1),
            editSelectedCrossingFields = listOf(),
            savingFilters = false,
            managingFilter = false,
            filterManagingMode = State.Displaying.BIUIState.FilterManagingMode.Add,
        )
    }

    private fun buildChartsUIState(): ChartsUIState {
        val graphAnswers = createAnswersGraphUIState(
            answers = answers.values,
            users = users
        )

        fieldIdentifiersToValuesMapping =
            graphAnswers.associate { it.identifier to it.data.map { data -> data.label } }


        return ChartsUIState(
            totalAnswers = answers.size,
            graphAnswers = graphAnswers,
            usersChart = createUsersChart(
                answers = answers.values,
                users = users
            )
        )
    }

    private fun buildTableUIState() = createAnswersTableUIState()

    private fun buildMapUIState(): MapUIState? = if (formModel.sendGeoCoordinates)
        MapUIState(
            createMapMarkers(answers = answers.values, users = users)
        ) else null


    override fun onEvent(event: Event) {
        when (event) {
            Event.OnGenerateCSVRequest -> onGenerateCSVRequest()
            Event.OnGenerateXLSXRequest -> onGenerateXLSXRequest()
            Event.OnExportAnswersClick -> onExportAnswersClick()
            Event.TryAgain -> loadData()
            is Event.OnMenuClick -> onMenuClick(event.menuId)
            Event.OnAddBIFilter -> onAddBIFilter()
            is Event.OnBITitleChange -> onDashboardTitleChange(event.newTitle)
            is Event.OnBIGroupingFieldSelected -> onBIGroupingFieldSelected(event.field)
            Event.OnAddBISubgroup -> onAddSubgroup()
            is Event.OnBISubgroupTitleChange -> onBISubgroupTitleChange(event.identifier, event.newTitle)
            is Event.OnBISubgroupValuesChange -> onBISubgroupValuesChange(event.identifier, event.values)
            is Event.OnBISubgroupRemove -> onBISubgroupRemove(event.identifier)
            is Event.OnBICrossingFieldsValuesChange -> onDashboardCrossingFieldsValuesChange(event.values)
            Event.OnCancelAddingBIFilter -> onCancelAddingBIFilter()
            Event.OnSaveBIFilter -> onSaveBIFilter()
            is Event.OnSelectFilter -> onSelectFilter(event.filterIdentifier)
            is Event.OnToggleFilterSubgroup -> onToggleFilterSubgroup(event.filterSubgroupIdentifier)
            is Event.OnToggleFilterSubgroupValue -> onToggleFilterSubgroupValue(event.filterSubgroupValue)
            Event.OnEditFilter -> onEditFilter()
            Event.OnRemoveFilter -> onRemoveFilter()
            Event.OnSaveFilters -> onSaveFilters()
            is Event.OnChartTypeChange -> onChartTypeChange(event.identifier, event.newType)
            is Event.OnFilterChartTypeChange -> onFilterChartTypeChange(event.identifier, event.newType)
            is Event.OnCopyAnswerTableColumn -> onCopyAnswerTableColumn(event.column)
            is Event.OnCopyAnswerTableRows -> onCopyAnswerTableRows(event.column, event.startRow - 1, event.endRow)
            is Event.OnRemoveAnswerTableRows -> onRemoveAnswerTableRows(event.startRow, event.endRow)
            is Event.OnReplaceAnswerTableRows -> onReplaceAnswerTableRows(event.column, event.startRow, event.values)
            is Event.OnSortAnswerTable -> onSortAnswerTable(event.columnIndex)
            is Event.OnEditTableRow -> onEditTableRow(event.cellIdentifier, event.answerIdentifier, event.newValue)
            is Event.OnRemoveAnswerTableRow -> onRemoveAnswerTableRow(event.rowIndex)
            is Event.OnTableHeightSet -> onTableHeightSet(event.clientHeight)
            is Event.OnDuplicateAnswerTableRow -> onDuplicateAnswerTableRow(event.rowIndex)
            Event.OnGoToFirstTablePageClick -> onGoToFirstTablePageClick()
            Event.OnGoToLastTablePageClick -> onGoToLastTablePageClick()
            Event.OnGoToNextTablePageClick -> onGoToNextTablePageClick()
            Event.OnGoToPreviousTablePageClick -> onGoToPreviousTablePageClick()
            Event.OnUpdateAnswers -> onUpdateAnswers()
        }
    }

    private fun onUpdateAnswers() {
        internalEffects.dispatch(Effect.OnUpdateAnswersStart)

        stateHolderScope.launch {
            updateAnswersUseCase(
                UpdateAnswersUseCase.Params(
                    formIdentifier = formIdentifier,
                    answersToAdd = answers.values.filter { it.identifier in answersToAdd },
                    answersToUpdate = answers.values
                        .filter { it.identifier in answersToUpdate }
                        .map { it.identifier to it.answer },
                    answersToDelete = answersToDelete.toList()
                )
            )
                .onSuccess {
                    answersToAdd.clear()
                    answersToUpdate.clear()
                    answersToDelete.clear()
                    internalEffects.dispatch(Effect.OnUpdateAnswersSuccess)
                }
                .onFailure {
                    internalEffects.dispatch(Effect.OnUpdateAnswersFailure)
                }
        }
    }

    private fun onRemoveAnswerTableRow(rowIndex: Int) {
        val table = internalUIState.like<State.Displaying>().table
        val answer = table.answers[rowIndex]

        answers.remove(answer.identifier)

        if (answer.identifier !in answersToAdd) {
            answersToDelete.add(answer.identifier)
        }

        answersToUpdate.remove(answer.identifier)
        answersToAdd.remove(answer.identifier)

        if (answers.isEmpty()) {
            internalUIState.update {
                State.Empty(
                    toolbarTitle = it.toolbarTitle,
                    displayUpdateAnswersButton = true
                )
            }
        } else {
            internalUIState.updateAs<State.Displaying> {

                val pages = answers.size / table.rowsChunk
                val normalizedPages = if (answers.size % table.rowsChunk == 0) pages else pages + 1
                val currentPage = table.currentPage.coerceIn(0, normalizedPages)

                val start = ((currentPage - 1) * table.rowsChunk)
                val end = (start + table.rowsChunk).coerceIn(0, answers.size)

                copy(
                    displayUpdateAnswersButton = true,
                    table = table.copy(
                        answers = table.answers.toMutableList().apply {
                            removeAt(rowIndex)
                        },
                        pages = normalizedPages,
                        currentPage = currentPage,
                        rowsWindow = start..<end
                    )
                )
            }

            stateHolderScope.launch {
                internalUIState.updateAs<State.Displaying> {
                    copy(
                        charts = buildChartsUIState(),
                        bi = buildDashboardUIState(),
                        map = buildMapUIState()
                    )
                }
            }
        }
    }

    private fun onDuplicateAnswerTableRow(rowIndex: Int) {
        val table = internalUIState.like<State.Displaying>().table
        val answerUIState = table.answers[rowIndex]
        val answer = answers.getValue(answerUIState.identifier)
        val newIdentifier = UUID.stringUUID(20)

        answers[newIdentifier] = answer.copy(
            identifier = newIdentifier
        )

        answersToAdd.add(newIdentifier)

        internalUIState.updateAs<State.Displaying> {

            val pages = answers.size / table.rowsChunk
            val normalizedPages = if (answers.size % table.rowsChunk == 0) pages else pages + 1
            val currentPage = table.currentPage.coerceIn(0, normalizedPages)

            val start = ((currentPage - 1) * table.rowsChunk)
            val end = (start + table.rowsChunk).coerceIn(0, answers.size)

            copy(
                displayUpdateAnswersButton = true,
                table = table.copy(
                    answers = table.answers.toMutableList().apply {
                        add(rowIndex, answerUIState.copy(identifier = newIdentifier))
                    },
                    pages = normalizedPages,
                    currentPage = currentPage,
                    rowsWindow = start..<end
                )
            )
        }

        stateHolderScope.launch {
            internalUIState.updateAs<State.Displaying> {
                copy(
                    charts = buildChartsUIState(),
                    bi = buildDashboardUIState(),
                    map = buildMapUIState()
                )
            }
        }
    }

    private fun onGoToFirstTablePageClick() {
        internalUIState.updateAs<State.Displaying> {
            copy(
                table = table.copy(
                    currentPage = 1,
                    rowsWindow = 0..<table.rowsChunk.coerceAtMost(answers.size)
                )
            )
        }
    }

    private fun onGoToPreviousTablePageClick() {
        internalUIState.updateAs<State.Displaying> {

            if (table.currentPage - 1 == 0) return
            val start = ((table.currentPage - 2) * table.rowsChunk)
            val end = (start + table.rowsChunk).coerceIn(0, answers.size)

            copy(
                table = table.copy(
                    currentPage = table.currentPage - 1,
                    rowsWindow = start..<end
                )
            )
        }
    }

    private fun onGoToNextTablePageClick() {
        internalUIState.updateAs<State.Displaying> {

            if (table.currentPage + 1 == table.pages.plus(1)) return
            val start = (table.currentPage * table.rowsChunk)
            val end = (start + table.rowsChunk).coerceIn(0, answers.size)

            copy(
                table = table.copy(
                    currentPage = table.currentPage + 1,
                    rowsWindow = start..<end
                )
            )
        }
    }

    private fun onGoToLastTablePageClick() {
        internalUIState.updateAs<State.Displaying> {
            copy(
                table = table.copy(
                    currentPage = table.pages,
                    rowsWindow = (table.pages - 1) * table.rowsChunk..<table.answers.size
                )
            )
        }
    }

    private fun onTableHeightSet(clientHeight: Int) {
        internalUIState.updateAs<State.Displaying> {
            val rowsChunk = (clientHeight - 41) / 41
            val pages = answers.size / rowsChunk
            copy(
                table = table.copy(
                    tableHeight = clientHeight,
                    pages = if (answers.size % rowsChunk == 0) pages else pages + 1,
                    rowsWindow = 0..<rowsChunk.coerceAtMost(answers.size),
                    rowsChunk = rowsChunk
                )
            )
        }
    }

    private fun onEditTableRow(
        cellIdentifier: String,
        answerIdentifier: String,
        newValue: String
    ) {
        if (answerIdentifier !in answersToAdd) {
            answersToUpdate.add(answerIdentifier)
        }

        val answer = answers.getValue(answerIdentifier)
        answers[answerIdentifier] = answer.copy(
            answer = answer.answer.toMutableMap().apply {
                this[cellIdentifier] = newValue
            }
        )
        internalUIState.updateAs<State.Displaying> {
            copy(
                displayUpdateAnswersButton = true,
                table = table.copy(
                    answers = table.answers.map { answerUIState ->
                        if (answerUIState.identifier == answerIdentifier) {
                            answerUIState.copy(
                                values = answerUIState.values.map { cell ->
                                    if (cell.identifier == cellIdentifier) {
                                        cell.copy(value = newValue)
                                    } else cell
                                }
                            )
                        } else answerUIState
                    }
                )
            )
        }

        stateHolderScope.launch {
            internalUIState.updateAs<State.Displaying> {
                copy(
                    charts = buildChartsUIState(),
                    bi = buildDashboardUIState(),
                    map = buildMapUIState()
                )
            }
        }
    }

    private fun createMapMarkers(
        answers: Collection<FormAnswerModel>,
        users: List<UserModel>
    ): List<MapUIState.MapMarkerUIState> {
        val usersMap = users.associateBy { it.uid }

        return answers.mapNotNull { answer ->
            answer.geoCoordinates?.let {
                MapUIState.MapMarkerUIState(
                    latLong = LatLng(answer.geoCoordinates.latitude, answer.geoCoordinates.longitude),
                    collectedAt = answer.submittedAt.toBrazilianDateTimePattern(),
                    collectedBy = usersMap[answer.submittedBy]?.name ?: "Usuário desconhecido",
                    relatedAnswer = answer.answer
                )
            }
        }
    }

    private fun createUsersChart(
        answers: Collection<FormAnswerModel>,
        users: List<UserModel>
    ): LineChartData {

        val usersSubmissionPerDate = HashMap<String, MutableList<Pair<String, Int>>>()
        val usersMap = users.associateBy { it.uid }

        answers
            .groupBy { it.submittedAt.toBrazilianDatePattern() }
            .entries
            .sortedBy { it.value.first().submittedAt }
            .forEach { (date, dateAnswers) ->
                dateAnswers
                    .groupBy { it.submittedBy }
                    .forEach { (userId, answers) ->
                        usersSubmissionPerDate.getOrPut(userId) { mutableListOf() }.add(date to answers.size)
                    }
            }

        val labels = answers.groupBy { it.submittedAt.toBrazilianDatePattern() }
            .entries
            .sortedBy { it.value.first().submittedAt }.map { it.key }

        return LineChartData(
            title = "Envios por usuário",
            labels = labels,
            dataSet = usersSubmissionPerDate.entries.mapIndexed { index, (userId, submissions) ->

                val submissionsMap = submissions.toMap()

                LineChartData.Data(
                    label = usersMap[userId]?.name ?: "Usuário desconhecido",
                    values = labels.map { label ->
                        submissionsMap[label] ?: 0
                    },
                    color = ChartsColorPallet[index]
                )
            }
        )
    }

    private fun createMenuOptions() = mutableListOf(
        MenuItemUIState(
            id = "bi",
            text = "BI",
            icon = MaterialIcons.Round.Dashboard
        ),
        MenuItemUIState(
            id = "charts",
            text = "Gráficos",
            icon = MaterialIcons.Round.Poll
        ),
        MenuItemUIState(
            id = "table",
            text = "Tabela",
            icon = MaterialIcons.Round.TableChart
        )
    ).apply {
        if (formModel.sendGeoCoordinates) {
            add(
                MenuItemUIState(
                    id = "map",
                    text = "Mapa",
                    icon = MaterialIcons.Round.Map
                )
            )
        }
    }

    private fun getAvailableGroupingFields() = formModel.inputs.map { it.titleToIdentifierMapping }.flatten()

    private fun createAnswersTableUIState(): TableUIState {
        if (answers.isEmpty()) return TableUIState(
            headers = listOf(),
            answers = listOf(),
            displayGeoCoordinatesColumn = formModel.sendGeoCoordinates
        )

        val headers = answers.values.maxBy { it.submittedAt }.answer.keys.toList()
        val usersMap = users.associateBy(
            keySelector = { it.uid },
            valueTransform = { it.name }
        )

        return TableUIState(
            headers = mutableListOf("Enviado em", "Enviado por").apply {
                if (formModel.sendGeoCoordinates) {
                    add("Geocoordenadas")
                }
            } + headers,
            answers = answers
                .values
                .sortedByDescending { it.submittedAt }
                .map { answer ->
                    val baseColumns = mutableListOf(
                        "date" to answer.submittedAt.toBrazilianDateTimePattern(),
                        "user" to (usersMap[answer.submittedBy] ?: "Usuário desconhecido"),
                    ).apply {
                        if (formModel.sendGeoCoordinates) {
                            add("coords" to (answer.geoCoordinates?.toString() ?: "\u200E "))
                        }
                    }

                    val answerColumns = answer.answer.entries.map { it.key to it.value }

                    TableUIState.AnswerUIState(
                        identifier = answer.identifier,
                        values = (baseColumns + answerColumns).map { cell ->
                            TableUIState.AnswerCellUIState(
                                identifier = cell.first,
                                value = cell.second
                            )
                        }
                    )
                },
            displayGeoCoordinatesColumn = formModel.sendGeoCoordinates
        )
    }

    private fun createBIAnswers(
        groupingField: String?,
        values: List<String>,
        answers: Collection<FormAnswerModel>,
        fieldsToDisplay: List<String>
    ): BIUIState.FilterUIState? {

        // TODO Filtrar opções de perguntas abertas

        if (groupingField == null) return null
        if (!titleToFieldIdentifiersMapping.containsKey(groupingField)) return null

        val groupingFieldIdentifier = titleToFieldIdentifiersMapping.getValue(groupingField)

        val graphAnswers = answers
            .filter {
                it.answer.containsKey(groupingFieldIdentifier) && it.answer[groupingFieldIdentifier] in values
            }
            .map { it.answer.entries }
            .flatten()
            .groupBy({ it.key }, { it.value })
            .filter { fieldIdentifiersToTitleMapping[it.key] in fieldsToDisplay }
            .mapValues { (identifier, values) ->
                formModel.inputs.firstOrNull { it.knowsIdentifier(identifier) }?.let {
                    inputGraphDataProducer.produce(
                        identifier = identifier,
                        model = it,
                        rawData = values
                    )?.copy(chartType = InputGraphUIState.CharType.Bar)
                }
            }
            .values
            .filterNotNull()

        val total = answers.filter {
            it.answer.containsKey(groupingFieldIdentifier)
                    && it.answer[groupingFieldIdentifier] in values
        }.size

        return BIUIState.FilterUIState(
            total = total,
            percentage = (total.toDouble() / answers.size) * 100.0,
            graphs = graphAnswers
        )
    }

    private fun createAnswersGraphUIState(
        answers: Collection<FormAnswerModel>,
        users: List<UserModel>
    ): List<InputGraphUIState> {

        // TODO Gráfico de usuário

        return answers
            .map { it.answer.entries }
            .flatten()
            .groupBy({ it.key }, { it.value })
            .mapValues { (identifier, values) ->
                formModel.inputs.firstOrNull { it.knowsIdentifier(identifier) }?.let {
                    inputGraphDataProducer.produce(
                        identifier = identifier,
                        model = it,
                        rawData = values
                    )
                }
            }
            .values
            .filterNotNull()
    }

    private fun onSortAnswerTable(columnIndex: Int) {
        internalUIState.updateAs<State.Displaying> {
            copy(
                table = table.copy(
                    answers = when (table.sorting) {
                        TableUIState.Sorting.Ascending -> {
                            table.answers.sortedWith(
                                compareBy {
                                    val value = it.values[columnIndex].value
                                    when {
                                        value.isANumber() -> value.toDouble()
                                        value.isADate() -> value.toLocalDate()
                                        value.isATime() -> value.toLocalTime()
                                        value.isADateTime() -> value.toLocalDateTime()
                                        else -> value
                                    }
                                }
                            )
                        }

                        TableUIState.Sorting.Descending -> {
                            table.answers.sortedWith(
                                compareByDescending {
                                    val value = it.values[columnIndex].value
                                    when {
                                        value.isANumber() -> value.toDouble()
                                        value.isADate() -> value.toLocalDate()
                                        value.isATime() -> value.toLocalTime()
                                        value.isADateTime() -> value.toLocalDateTime()
                                        else -> value
                                    }
                                }
                            )
                        }
                    },
                    sorting = table.sorting.invert()
                )
            )
        }
    }

    private fun onReplaceAnswerTableRows(
        column: String,
        startRow: Int,
        values: String
    ) {
        if (values.isBlank()) return
        val newValues = values.split(";", "\n")
        val answersChunk = internalUIState
            .like<State.Displaying>()
            .table
            .answers
            .subList(startRow - 1, (startRow - 1 + newValues.size).coerceIn(startRow - 1, answers.size))

        val identifierNewValueMap = answersChunk.mapIndexed { index, answerUIState ->
            answerUIState.identifier to newValues[index]
        }.toMap()

        answersToUpdate.addAll(
            identifierNewValueMap.keys.filter { it !in answersToAdd }
        )

        answers.toMutableMap().apply {
            identifierNewValueMap.forEach { (identifier, newValue) ->
                this[identifier] = getValue(identifier).copy(
                    answer = getValue(identifier)
                        .answer
                        .toMutableMap()
                        .apply {
                            this[column] = newValue
                        }
                )
            }
        }.also {
            answers = it
        }

        internalUIState.updateAs<State.Displaying> {
            copy(
                displayUpdateAnswersButton = true,
                table = table.copy(
                    answers = table.answers.map { answerUIState ->
                        if (answerUIState.identifier in identifierNewValueMap) {
                            answerUIState.copy(
                                values = answerUIState.values.map { cell ->
                                    if (cell.identifier == column) {
                                        cell.copy(
                                            value = identifierNewValueMap.getValue(answerUIState.identifier)
                                        )
                                    } else cell
                                }
                            )
                        } else answerUIState
                    }
                )
            )
        }

        stateHolderScope.launch {
            internalUIState.updateAs<State.Displaying> {
                copy(
                    charts = buildChartsUIState(),
                    bi = buildDashboardUIState(),
                    map = buildMapUIState()
                )
            }
        }
    }

    private fun onRemoveAnswerTableRows(startRow: Int, endRow: Int) {
        val answersChunk = internalUIState
            .like<State.Displaying>()
            .table
            .answers
            .subList(startRow - 1, endRow)
            .map { it.identifier }

        answersToDelete.addAll(answersChunk.filter { it !in answersToAdd })
        answersToUpdate.removeAll(answersChunk.toSet())
        answersToAdd.removeAll(answersChunk.toSet())

        answers.toMutableMap().apply {
            answersChunk.forEach { identifier ->
                remove(identifier)
            }
        }.also {
            answers = it
        }

        if (answers.isEmpty()) {
            internalUIState.update {
                State.Empty(
                    toolbarTitle = it.toolbarTitle,
                    displayUpdateAnswersButton = true
                )
            }
        } else {
            internalUIState.updateAs<State.Displaying> {
                val pages = answers.size / table.rowsChunk
                val normalizedPages = if (answers.size % table.rowsChunk == 0) pages else pages + 1
                val currentPage = table.currentPage.coerceIn(0, normalizedPages)

                val start = ((currentPage - 1) * table.rowsChunk)
                val end = (start + table.rowsChunk).coerceIn(0, answers.size)

                copy(
                    displayUpdateAnswersButton = true,
                    table = table.copy(
                        pages = normalizedPages,
                        currentPage = currentPage,
                        answers = table.answers.filter { it.identifier !in answersChunk },
                        rowsWindow = start..<end
                    )
                )
            }

            stateHolderScope.launch {
                internalUIState.updateAs<State.Displaying> {
                    copy(
                        charts = buildChartsUIState(),
                        bi = buildDashboardUIState(),
                        map = buildMapUIState()
                    )
                }
            }
        }
    }

    private fun onCopyAnswerTableRows(column: String, startRow: Int, endRow: Int) {
        val tableAnswers = internalUIState.like<State.Displaying>().table
        val columnIndex = tableAnswers.headers.indexOf(column).takeIf { it != -1 }
            ?: return

        internalEffects.dispatch(
            Effect.OnColumnCopiedToClipboard(
                columnData = tableAnswers.answers
                    .subList(startRow, endRow)
                    .joinToString("\n") {
                        it.values[columnIndex].value
                    }
            )
        )
    }

    private fun onCopyAnswerTableColumn(column: String) {
        val tableAnswers = internalUIState.like<State.Displaying>().table
        onCopyAnswerTableRows(column, startRow = 0, endRow = tableAnswers.answers.size)
    }

    private fun onFilterChartTypeChange(
        identifier: String,
        newType: InputGraphUIState.CharType
    ) {
        internalUIState.updateAs<State.Displaying> {
            copy(
                bi = bi.copy(
                    biAnswers = bi.biAnswers?.copy(
                        graphs = bi.biAnswers.graphs.map { graphSpec ->
                            if (graphSpec.identifier == identifier) {
                                graphSpec.copy(chartType = newType)
                            } else graphSpec
                        }
                    )
                )
            )

        }
    }

    private fun onChartTypeChange(
        identifier: String,
        newType: InputGraphUIState.CharType
    ) {
        internalUIState.updateAs<State.Displaying> {
            copy(
                charts = charts.copy(
                    graphAnswers = charts.graphAnswers.map { graphSpec ->
                        if (graphSpec.identifier == identifier) {
                            graphSpec.copy(chartType = newType)
                        } else graphSpec
                    }
                )
            )
        }
    }

    private fun onSaveFilters() {
        stateHolderScope.launch {
            internalUIState.updateAs<State.Displaying> {
                copy(
                    bi = bi.copy(
                        savingFilters = true
                    )
                )
            }
            saveFormFiltersUseCase(
                SaveFormFiltersUseCase.Params(
                    formId = formIdentifier,
                    filters = internalUIState.like<State.Displaying>().bi.filters.map { filter ->
                        with(filter) {
                            FormFilterModel(
                                identifier = identifier,
                                title = title,
                                groupingField = titleToFieldIdentifiersMapping.getValue(groupingField),
                                fieldsToDisplay = fieldsToDisplay.mapNotNull { titleToFieldIdentifiersMapping[it] },
                                subgroups = subgroups.map { subgroup ->
                                    with(subgroup) {
                                        FormFilterModel.FormFilterSubgroupFieldModel(
                                            identifier = identifier,
                                            title = title,
                                            values = values
                                        )
                                    }
                                }
                            )
                        }
                    }
                )
            )
                .onSuccess {
                    internalEffects.dispatch(Effect.OnSaveFiltersSuccess)
                    internalUIState.updateAs<State.Displaying> {
                        copy(
                            bi = bi.copy(
                                savingFilters = false
                            )
                        )
                    }
                }
                .onFailure {
                    internalEffects.dispatch(Effect.OnSaveFiltersFailure)
                    internalUIState.updateAs<State.Displaying> {
                        copy(
                            bi = bi.copy(
                                savingFilters = false
                            )
                        )
                    }
                }
        }
    }

    private fun onRemoveFilter() {
        internalUIState.updateAs<State.Displaying> {
            val currentFilterIndex = bi.filters.indexOfFirst { it.title == bi.selectedFilter }
            val filters = bi.filters.toMutableList().apply {
                removeAt(currentFilterIndex)
            }

            val selectedFilter = filters.firstOrNull()

            copy(
                bi = bi.copy(
                    filters = filters,
                    selectedFilter = selectedFilter?.title.orEmpty(),
                    filterSubgroups = selectedFilter?.subgroups?.map { it.title }.orEmpty(),
                    selectedFilterSubgroups = selectedFilter?.subgroups?.map { it.title }.orEmpty(),
                    filterSubgroupValues = selectedFilter?.subgroups?.map { it.values }?.flatten().orEmpty(),
                    selectedFilterSubgroupValues = selectedFilter?.subgroups?.map { it.values }?.flatten().orEmpty(),
                    biAnswers = createBIAnswers(
                        groupingField = filters.firstOrNull { it.title == selectedFilter?.title }?.groupingField,
                        values = selectedFilter?.subgroups?.map { it.values }?.flatten().orEmpty(),
                        answers = answers.values,
                        fieldsToDisplay = filters.firstOrNull { it.title == selectedFilter?.title }?.fieldsToDisplay.orEmpty()
                    )
                )
            )
        }
    }

    private fun onEditFilter() {
        internalUIState.updateAs<State.Displaying> {

            val currentFilter = bi.filters.first { it.title == bi.selectedFilter }
            val editAvailableSubgroupsValues = fieldIdentifiersToValuesMapping
                .getValue(titleToFieldIdentifiersMapping.getValue(currentFilter.groupingField))
                .minus(currentFilter.subgroups.map { it.values }.flatten().toSet())

            copy(
                bi = bi.copy(
                    managingFilter = true,
                    filterManagingMode = BIUIState.FilterManagingMode.Edit,
                    editAvailableGroupingFields = groupTitleToFieldIdentifiersMapping.keys.toList()
                        .minus(currentFilter.groupingField),
                    editSelectedGroupingField = currentFilter.groupingField,
                    editSubgroups = currentFilter.subgroups,
                    editFilterIdentifier = currentFilter.identifier,
                    editFilterTitle = currentFilter.title,
                    editAvailableSubgroupsValues = editAvailableSubgroupsValues.sorted(),
                    editAvailableCrossingFields = groupTitleToFieldIdentifiersMapping.keys.toList(),
                    editSelectedCrossingFields = currentFilter.fieldsToDisplay
                )
            )
        }
    }

    private fun onSelectFilter(filterTitle: String) {
        internalUIState.updateAs<State.Displaying> {

            val subgroups = bi.filters.firstOrNull { it.title == filterTitle }?.subgroups.orEmpty()

            copy(
                bi = bi.copy(
                    selectedFilter = filterTitle,
                    filterSubgroups = subgroups.map { it.title },
                    selectedFilterSubgroups = subgroups.map { it.title },
                    filterSubgroupValues = subgroups.map { it.values }.flatten(),
                    selectedFilterSubgroupValues = subgroups.map { it.values }.flatten(),
                    biAnswers = createBIAnswers(
                        groupingField = bi.filters.firstOrNull { it.title == filterTitle }?.groupingField,
                        values = subgroups.map { it.values }.flatten(),
                        answers = answers.values,
                        fieldsToDisplay = bi.filters.firstOrNull { it.title == filterTitle }?.fieldsToDisplay.orEmpty()
                    )
                )
            )
        }
    }

    private fun onToggleFilterSubgroup(filterSubgroupTitle: String) {
        internalUIState.updateAs<State.Displaying> {

            val allSubgroups = bi.filters
                .firstOrNull { it.title == bi.selectedFilter }
                ?.subgroups
                .orEmpty()
                .toMutableList()

            val selectedFilterSubgroups = bi.selectedFilterSubgroups.toMutableList().apply {
                if (filterSubgroupTitle in bi.selectedFilterSubgroups) {
                    remove(filterSubgroupTitle)
                } else {
                    add(filterSubgroupTitle)
                }
            }

            val filterSubgroupValues = allSubgroups
                .filter { it.title in selectedFilterSubgroups }
                .map { it.values }
                .flatten()

            val selectedFilterSubgroupValues = bi.selectedFilterSubgroupValues
                .filter { it in filterSubgroupValues }
                .toMutableList()
                .apply {
                    if (filterSubgroupTitle in selectedFilterSubgroups) {
                        allSubgroups.firstOrNull { it.title == filterSubgroupTitle }?.let {
                            addAll(it.values)
                        }
                    }
                }

            copy(
                bi = bi.copy(
                    selectedFilterSubgroups = selectedFilterSubgroups,
                    filterSubgroupValues = filterSubgroupValues,
                    selectedFilterSubgroupValues = selectedFilterSubgroupValues,
                    biAnswers = createBIAnswers(
                        groupingField = bi.filters.firstOrNull {
                            it.title == bi.selectedFilter
                        }?.groupingField,
                        values = selectedFilterSubgroupValues,
                        answers = answers.values,
                        fieldsToDisplay = bi.filters.firstOrNull {
                            it.title == bi.selectedFilter
                        }?.fieldsToDisplay.orEmpty()
                    )
                )
            )
        }
    }

    private fun onToggleFilterSubgroupValue(filterSubgroupValue: String) {
        internalUIState.updateAs<State.Displaying> {

            val selectedFilterSubgroupValues = bi.selectedFilterSubgroupValues
                .toMutableList()
                .apply {
                    if (filterSubgroupValue in bi.selectedFilterSubgroupValues) {
                        remove(filterSubgroupValue)
                    } else {
                        add(filterSubgroupValue)
                    }
                }

            copy(
                bi = bi.copy(
                    selectedFilterSubgroupValues = selectedFilterSubgroupValues,
                    biAnswers = createBIAnswers(
                        groupingField = bi.filters.firstOrNull {
                            it.title == bi.selectedFilter
                        }?.groupingField,
                        values = selectedFilterSubgroupValues,
                        answers = answers.values,
                        fieldsToDisplay = bi.filters.firstOrNull {
                            it.title == bi.selectedFilter
                        }?.fieldsToDisplay.orEmpty()
                    )
                ),
            )
        }
    }

    private fun onSaveBIFilter() {

        // TODO Mover para UseCase essa validação

        val currentFilters = internalUIState.like<State.Displaying>().bi.filters
        val currentUIState = internalUIState.like<State.Displaying>()

        if (currentUIState.bi.editFilterTitle.isEmpty()) {
            internalEffects.dispatch(Effect.OnDisplayMessage("O título não pode ser vazio"))
            return
        } else if (currentUIState.bi.editSubgroups.isEmpty()) {
            internalEffects.dispatch(Effect.OnDisplayMessage("Você precisa criar ao menos um subgrupo"))
            return
        } else if (currentUIState.bi.editSubgroups.map { it.title }
                .distinct().size != currentUIState.bi.editSubgroups.size) {
            internalEffects.dispatch(Effect.OnDisplayMessage("O nome de um subgrupo não pode se repetir"))
            return
        } else if (currentUIState.bi.editSubgroups.any { it.values.isEmpty() }) {
            internalEffects.dispatch(Effect.OnDisplayMessage("Todos os subgrupos precisam ter ao menos um valor"))
            return
        } else if (currentUIState.bi.editSelectedCrossingFields.isEmpty()) {
            internalEffects.dispatch(Effect.OnDisplayMessage("Você precisa selecionar ao menos um campo cruzado"))
            return
        }

        val filterTitleAlreadyExists = currentFilters.any {
            it.title == currentUIState.bi.editFilterTitle && it.identifier != currentUIState.bi.editFilterIdentifier
        }

        if (filterTitleAlreadyExists) {
            internalEffects.dispatch(Effect.OnDisplayMessage("O título do filtro não pode ser repetido"))
            return
        }

        internalUIState.updateAs<State.Displaying> {
            val filters = if (bi.filterManagingMode == BIUIState.FilterManagingMode.Add) {
                bi.filters.toMutableList().apply {
                    add(
                        FormFilterUIState(
                            identifier = bi.editFilterIdentifier,
                            title = bi.editFilterTitle,
                            groupingField = bi.editSelectedGroupingField,
                            subgroups = bi.editSubgroups,
                            fieldsToDisplay = bi.editSelectedCrossingFields
                        )
                    )
                }
            } else {
                bi.filters.map { filter ->
                    if (filter.identifier == bi.editFilterIdentifier) {
                        FormFilterUIState(
                            identifier = bi.editFilterIdentifier,
                            title = bi.editFilterTitle,
                            groupingField = bi.editSelectedGroupingField,
                            subgroups = bi.editSubgroups,
                            fieldsToDisplay = bi.editSelectedCrossingFields
                        )
                    } else filter
                }
            }

            val shouldUseOriginalParams = bi.filterManagingMode == BIUIState.FilterManagingMode.Add
                    && internalUIState.like<State.Displaying>().bi.filters.isNotEmpty()

            val currentFilter =
                if (shouldUseOriginalParams) filters.first { it.identifier == bi.editFilterIdentifier } else filters.last()

            copy(
                bi = bi.copy(
                    managingFilter = false,
                    filters = filters,
                    selectedFilter = if (shouldUseOriginalParams) bi.selectedFilter else bi.editFilterTitle,
                    filterSubgroups = if (shouldUseOriginalParams) bi.filterSubgroups else bi.editSubgroups.map { it.title },
                    selectedFilterSubgroups = if (shouldUseOriginalParams) bi.selectedFilterSubgroups else bi.editSubgroups.map { it.title },
                    filterSubgroupValues = if (shouldUseOriginalParams) bi.filterSubgroupValues else bi.editSubgroups.map { it.values }
                        .flatten(),
                    selectedFilterSubgroupValues = if (shouldUseOriginalParams) bi.selectedFilterSubgroupValues else bi.editSubgroups.map { it.values }
                        .flatten(),
                    editAvailableGroupingFields = groupTitleToFieldIdentifiersMapping.keys.toList(),
                    editSelectedGroupingField = groupTitleToFieldIdentifiersMapping.keys.firstOrNull().orEmpty(),
                    editSubgroups = listOf(),
                    editFilterIdentifier = UUID.stringUUID(),
                    editFilterTitle = "",
                    editAvailableSubgroupsValues = mutableListOf<String>().apply {
                        withNotNull(groupTitleToFieldIdentifiersMapping.values.firstOrNull()) {
                            fieldIdentifiersToValuesMapping[this]?.let {
                                addAll(it)
                            }
                        }
                    }.sorted(),
                    editAvailableCrossingFields = groupTitleToFieldIdentifiersMapping.keys.toList().drop(1),
                    editSelectedCrossingFields = listOf(),
                    biAnswers = createBIAnswers(
                        groupingField = currentFilter.groupingField,
                        values = if (shouldUseOriginalParams) bi.selectedFilterSubgroupValues else bi.editSubgroups.map { it.values }
                            .flatten(),
                        answers = answers.values,
                        fieldsToDisplay = currentFilter.fieldsToDisplay
                    )
                )
            )
        }
    }

    private fun onCancelAddingBIFilter() {
        internalUIState.updateAs<State.Displaying> {
            copy(
                bi = bi.copy(
                    managingFilter = false,
                    editAvailableGroupingFields = groupTitleToFieldIdentifiersMapping.keys.toList(),
                    editSelectedGroupingField = groupTitleToFieldIdentifiersMapping.keys.firstOrNull().orEmpty(),
                    editSubgroups = listOf(),
                    editFilterIdentifier = UUID.stringUUID(),
                    editFilterTitle = "",
                    editAvailableSubgroupsValues = mutableListOf<String>().apply {
                        withNotNull(groupTitleToFieldIdentifiersMapping.values.firstOrNull()) {
                            addAll(fieldIdentifiersToValuesMapping.getValue(this))
                        }
                    }.sorted(),
                    editAvailableCrossingFields = groupTitleToFieldIdentifiersMapping.keys.toList().drop(1),
                    editSelectedCrossingFields = listOf()
                )
            )
        }
    }

    private fun onDashboardCrossingFieldsValuesChange(values: List<String>) {
        internalUIState.updateAs<State.Displaying> {
            copy(
                bi = bi.copy(
                    editSelectedCrossingFields = values
                )
            )
        }
    }

    private fun onDashboardTitleChange(newTitle: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(
                bi = bi.copy(
                    editFilterTitle = newTitle
                )
            )
        }
    }

    private fun onBISubgroupRemove(identifier: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(
                bi = bi.copy(
                    editSubgroups = bi.editSubgroups.filterNot { it.identifier == identifier },
                    editAvailableSubgroupsValues = bi.editAvailableSubgroupsValues
                            + bi.editSubgroups.first { it.identifier == identifier }.values
                )
            )
        }
    }

    private fun onBISubgroupValuesChange(identifier: String, values: List<String>) {
        internalUIState.updateAs<State.Displaying> {
            val valuesKey = titleToFieldIdentifiersMapping[bi.editSelectedGroupingField].orEmpty()
            val allValues = fieldIdentifiersToValuesMapping[valuesKey]
            val subgroupsValues = bi.editSubgroups.mapNotNull { subgroup ->
                if (subgroup.identifier != identifier) {
                    subgroup.values
                } else null
            }.flatten()

            val availableSubgroupsValues = allValues?.filter { value ->
                value !in values && value !in subgroupsValues
            }

            copy(
                bi = bi.copy(
                    editSubgroups = bi.editSubgroups.map { subgroup ->
                        if (subgroup.identifier == identifier) {
                            subgroup.copy(values = values)
                        } else subgroup
                    },
                    editAvailableSubgroupsValues = availableSubgroupsValues.orEmpty().sorted()
                )
            )
        }
    }

    private fun onBISubgroupTitleChange(identifier: String, newTitle: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(
                bi = bi.copy(
                    editSubgroups = bi.editSubgroups.map { subgroup ->
                        if (subgroup.identifier == identifier) {
                            subgroup.copy(title = newTitle)
                        } else subgroup
                    }
                )
            )
        }
    }

    private fun onAddSubgroup() {
        internalUIState.updateAs<State.Displaying> {
            copy(
                bi = bi.copy(
                    editSubgroups = bi.editSubgroups.toMutableList().apply {
                        add(
                            FormFilterUIState.FormFilterSubgroupFieldUIState(
                                identifier = UUID.stringUUID(),
                                title = "",
                                values = listOf()
                            )
                        )
                    }
                )
            )
        }
    }

    private fun onBIGroupingFieldSelected(field: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(
                bi = bi.copy(
                    editSelectedGroupingField = field,
                    editAvailableSubgroupsValues = mutableListOf<String>().apply {
                        withNotNull(titleToFieldIdentifiersMapping[field]) {
                            addAll(fieldIdentifiersToValuesMapping.getValue(this))
                        }
                    },
                    editSubgroups = listOf(),
                    editAvailableCrossingFields = titleToFieldIdentifiersMapping.keys.filterNot { it == field },
                    editSelectedCrossingFields = listOf()
                )
            )
        }
    }

    private fun onAddBIFilter() {
        internalUIState.updateAs<State.Displaying> {
            copy(
                bi = bi.copy(
                    managingFilter = true,
                    filterManagingMode = BIUIState.FilterManagingMode.Add
                )
            )
        }
    }

    private fun onMenuClick(menuId: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(
                selectedMenuId = menuId,
                bi = bi.copy(
                    managingFilter = false,
                    editAvailableGroupingFields = groupTitleToFieldIdentifiersMapping.keys.toList(),
                    editSelectedGroupingField = groupTitleToFieldIdentifiersMapping.keys.firstOrNull().orEmpty(),
                    editSubgroups = listOf(),
                    editFilterIdentifier = UUID.stringUUID(),
                    editFilterTitle = "",
                    editAvailableSubgroupsValues = mutableListOf<String>().apply {
                        withNotNull(groupTitleToFieldIdentifiersMapping.values.firstOrNull()) {
                            fieldIdentifiersToValuesMapping[this]?.let {
                                addAll((it))
                            }
                        }
                    }.sorted(),
                    editAvailableCrossingFields = groupTitleToFieldIdentifiersMapping.keys.toList().drop(1),
                    editSelectedCrossingFields = listOf()
                )
            )
        }
    }

    private fun onExportAnswersClick() {
        internalEffects.dispatch(Effect.OnDisplayFileFormatPicker)
    }

    private fun onGenerateXLSXRequest() {
        internalEffects.dispatch(Effect.OnPreparingAnswersToDownload)
        stateHolderScope.launch {
            getAnswersXLSXUseCase(
                GetAnswersXLSXUseCase.Params(
                    sheetTitle = formTitle,
                    answers = answers.values,
                    users = users
                )
            )
                .onSuccess {
                    internalEffects.dispatch(
                        Effect.OnDownloadReady(
                            file = it,
                            fileExtension = "xlsx"
                        )
                    )
                }
                .onFailure {
                    internalEffects.dispatch(Effect.OnPreparingAnswersToDownloadFail)
                }
        }
    }

    private fun onGenerateCSVRequest() {
        internalEffects.dispatch(Effect.OnPreparingAnswersToDownload)
        stateHolderScope.launch {
            getAnswersCSVUseCase(
                GetAnswersCSVUseCase.Params(
                    answers = answers.values,
                    users = users
                )
            )
                .onSuccess {
                    internalEffects.dispatch(
                        Effect.OnDownloadReady(
                            file = it,
                            fileExtension = "csv"
                        )
                    )
                }
                .onFailure {
                    internalEffects.dispatch(Effect.OnPreparingAnswersToDownloadFail)
                }
        }
    }
}