package com.catbit.opinionpoll.domain.support

import com.catbit.opinionpoll.core.domain.UseCase
import com.catbit.opinionpoll.data.repositories.OpinionPollRepository

class AskForSupportUseCase(
    private val repository: OpinionPollRepository
) : UseCase<Unit, AskForSupportUseCase.Params>() {

    override suspend fun execute(params: Params) = repository.askForSupport(
        topic = params.topic,
        subject = params.subject,
        message = params.message
    )

    data class Params(
        val topic: String,
        val subject: String,
        val message: String
    )
}