package com.catbit.opinionpoll.domain.form_dashboard

import BorderPattern
import BordersPartial
import ColorPartial
import FillPattern
import Workbook
import com.catbit.opinionpoll.core.domain.UseCase
import com.catbit.opinionpoll.core.extensions.toBrazilianDateTimePattern
import com.catbit.opinionpoll.data.models.FormAnswerModel
import com.catbit.opinionpoll.data.models.UserModel
import kotlinx.coroutines.await
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag

class GetAnswersXLSXUseCase : UseCase<Blob, GetAnswersXLSXUseCase.Params>() {
    override suspend fun execute(params: Params): Result<Blob> {

        val workbook = Workbook().apply {
            creator = "OpinionPro"
        }
        val worksheet = workbook.addWorksheet()

        val fixedHeaders = arrayOf("Enviado em", "Enviado por", "Geocoordenadas")
        val headers = params.answers.maxBy { it.submittedAt }.answer.keys.toList()

        val usersMap = params.users.associateBy(
            keySelector = { it.uid },
            valueTransform = { it.name }
        )

        worksheet.addRow(fixedHeaders + headers)

        worksheet.getRow(1).eachCell { cell, _ ->
            cell.fill = object : FillPattern {
                override var type: String = "pattern"
                override var pattern: String = "solid"
                override var fgColor: ColorPartial? = object : ColorPartial {
                    override var argb: String? = "FFC1E8FF"
                }
            }
            cell.border = object : BordersPartial {
                override var top: BorderPattern? = object : BorderPattern {
                    override var style = "thin"
                    override var color: ColorPartial? = object : ColorPartial {
                        override var argb: String? = "FF000000"
                    }
                }
                override var left: BorderPattern? = object : BorderPattern {
                    override var style = "thin"
                    override var color: ColorPartial? = object : ColorPartial {
                        override var argb: String? = "FF000000"
                    }
                }
                override var bottom: BorderPattern? = object : BorderPattern {
                    override var style = "thin"
                    override var color: ColorPartial? = object : ColorPartial {
                        override var argb: String? = "FF000000"
                    }
                }
                override var right: BorderPattern? = object : BorderPattern {
                    override var style = "thin"
                    override var color: ColorPartial? = object : ColorPartial {
                        override var argb: String? = "FF000000"
                    }
                }
            }
        }

        params.answers
            .sortedByDescending { it.submittedAt }
            .forEach { answer ->
                val rowFixedValues = arrayOf(
                    answer.submittedAt.toBrazilianDateTimePattern(),
                    usersMap[answer.submittedBy] ?: "Usuário desconhecido",
                    answer.geoCoordinates?.toString().orEmpty(),
                )

                val row = headers.map { key ->
                    answer.answer[key] ?: ""
                }

                worksheet.addRow(rowFixedValues + row)
            }

        (fixedHeaders + headers).forEachIndexed { index, cell ->
            worksheet.getColumn(index + 1).width = cell.length
        }

        return Result.success(
            Blob(
                blobParts = arrayOf(workbook.xlsx.writeBuffer().await()),
                options = BlobPropertyBag("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            )
        )
    }

    data class Params(
        val sheetTitle: String,
        val answers: Collection<FormAnswerModel>,
        val users: List<UserModel>
    )
}