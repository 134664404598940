package com.catbit.opinionpoll.pages.app

import com.catbit.opinionpoll.core.domain.invoke
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.domain.environment.GetEnvironmentInfoUseCase
import com.catbit.opinionpoll.domain.login.LogoutUseCase
import com.catbit.opinionpoll.pages.app.AppUIContract.*
import com.catbit.opinionpoll.ui.base.ScreenStateHolder
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.launch

class AppStateHolder(
    private val getEnvironmentInfoUseCase: GetEnvironmentInfoUseCase,
    private val logoutUseCase: LogoutUseCase
) : ScreenStateHolder<State, Event, Effect>() {

    override val internalUIState = MutableStateFlow<State>(State.Loading)

    override fun onStarted() {
        stateHolderScope.launch {
            getEnvironmentInfoUseCase()
                .onSuccess { envInfo ->
                    with(envInfo) {
                        internalUIState.value = State.Displaying(
                            environmentId = environmentId,
                            environmentName = environmentName,
                            environmentPlan = environmentPlan,
                            planExpirationDate = planExpirationDate,
                            environmentImage = environmentImage
                        )
                    }
                }
        }
    }

    override fun onEvent(event: Event) {
        when (event) {
            Event.OnLogout -> {
                logout()
            }
        }
    }

    private fun logout() {
        stateHolderScope.launch {
            logoutUseCase()
                .onSuccess {
                    internalEffects.dispatch(Effect.OnLogout)
                }
        }
    }

    val menuEntries = listOf(
        NavRailEntry(
            label = "Home",
            route = "home",
            icon = MaterialIcons.Round.Home
        ),
        NavRailEntry(
            label = "Formulários",
            route = "forms",
            icon = MaterialIcons.Round.Ballot
        ),
        NavRailEntry(
            label = "Equipe",
            route = "users",
            icon = MaterialIcons.Round.People
        ),
        NavRailEntry(
            label = "Suporte",
            route = "support",
            icon = MaterialIcons.Round.SupportAgent
        )
    )
}