package com.catbit.opinionpoll.core.extensions

import kotlinx.datetime.internal.JSJoda.DayOfWeek

fun DayOfWeek.dayLetter() = when (this) {
    DayOfWeek.MONDAY -> "S"
    DayOfWeek.TUESDAY -> "T"
    DayOfWeek.WEDNESDAY -> "Q"
    DayOfWeek.THURSDAY -> "Q"
    DayOfWeek.FRIDAY -> "S"
    DayOfWeek.SATURDAY -> "S"
    else -> "D"
}