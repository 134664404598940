package com.catbit.opinionpoll.core.ui.composables

import androidx.compose.runtime.Composable
import com.catbit.opinionpoll.core.extensions.changeAlpha
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.catbit.opinionpoll.core.ui.icons.MaterialIcon
import com.catbit.opinionpoll.core.ui.modifiers.thenIfNotNull
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import org.jetbrains.compose.web.css.px

@Composable
fun CardWithIcon(
    modifier: Modifier = Modifier,
    icon: MaterialIcon,
    iconColor: Color = sitePalette().primary,
    iconBackgroundColor: Color = iconColor.changeAlpha(newAlpha = 0.3f),
    backgroundColor: Color = sitePalette().surface,
    alignment: CardWithIconAlignment = CardWithIconAlignment.Center,
    onClick: (() -> Unit)? = null,
    bottomContent: (@Composable () -> Unit)? = null,
    content: @Composable () -> Unit,
) {
    Column(
        modifier = modifier
            .background(backgroundColor)
            .clip(Rect(8.px))
            .thenIfNotNull(onClick) {
                onClick { it() }
                    .cursor(Cursor.Pointer)
            }
            .padding(all = 16.px)
    ) {
        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = when (alignment) {
                CardWithIconAlignment.Top -> Alignment.Top
                CardWithIconAlignment.Center -> Alignment.CenterVertically
                CardWithIconAlignment.Bottom -> Alignment.Bottom
            }
        ) {
            Box(
                modifier = Modifier
                    .minSize(48.px)
                    .margin(right = 16.px)
                    .background(iconBackgroundColor)
                    .clip(Circle()),
                contentAlignment = Alignment.Center
            ) {
                MaterialIcon(
                    icon = icon,
                    tint = iconColor
                )
            }

            content()
        }
        bottomContent?.invoke()
    }
}

enum class CardWithIconAlignment {
    Top, Center, Bottom
}