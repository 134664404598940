package com.catbit.opinionpoll.core.ui.modifiers

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.onMouseEnter
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave

@Composable
fun Modifier.hoverBackground(
    onMouseExitBackgroundColor: Color,
    onMouseEnterBackgroundColor: Color
): Modifier {

    var hasMouseEnter by remember { mutableStateOf(false) }

    return then(this)
        .background(if (hasMouseEnter) onMouseEnterBackgroundColor else onMouseExitBackgroundColor)
        .onMouseEnter { hasMouseEnter = true }
        .onMouseLeave { hasMouseEnter = false }
}