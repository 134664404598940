package com.catbit.opinionpoll.core.unit

import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnitRel
import org.jetbrains.compose.web.css.CSSUnitValueTyped

interface CustomCSSUnit {

    interface dvh : CSSUnitRel

    companion object {
        inline val dvh get() = "dvh".unsafeCast<dvh>()
    }
}

val Number.dvh
    get(): CSSSizeValue<CustomCSSUnit.dvh> = CSSUnitValueTyped(this.toFloat(), CustomCSSUnit.dvh)