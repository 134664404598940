package com.catbit.opinionpoll.core.extensions

import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.LocalTime
import kotlinx.datetime.internal.JSJoda.LocalDateTime as JodaLocalDateTime

fun LocalDateTime.toBrazilianDatePattern() = "${if (dayOfMonth < 10) "0" else ""}$dayOfMonth/${if (monthNumber < 10) "0" else ""}$monthNumber/$year"

fun LocalDateTime.toTimePattern() =
    "${if (hour < 10) "0" else ""}$hour:${if (minute < 10) "0" else ""}$minute"

fun LocalDateTime.toBrazilianDateTimePattern() = "${toBrazilianDatePattern()} ${toTimePattern()}"

fun LocalDateTime.joda() = JodaLocalDateTime.of(year, monthNumber, dayOfMonth, hour, minute, second, nanosecond)
fun JodaLocalDateTime.kotlinLocalDateTime() = LocalDateTime(
    year().toInt(),
    monthValue().toInt(),
    dayOfMonth().toInt(),
    hour().toInt(),
    minute().toInt(),
    second().toInt(),
    nano().toInt()
)

val LocalDateTime.Companion.comparator get() = Comparator<LocalDateTime> { a, b ->
    a.compareTo(b)
}
