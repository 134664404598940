package com.catbit.opinionpoll.data.data_sources.networking.responses

import com.catbit.opinionpoll.inputs.responses.InputResponse
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FormResponse(
    @SerialName("identifier")
    val identifier: String,
    @SerialName("version")
    val version: Long,
    @SerialName("title")
    val title: String,
    @SerialName("tips")
    val tips: String?,
    @SerialName("creationDate")
    val creationDate: String,
    @SerialName("expirationDate")
    val expirationDate: String,
    @SerialName("sendGeoCoordinates")
    val sendGeoCoordinates: Boolean,
    @SerialName("template")
    val template: List<InputResponse>,
    @SerialName("assignedTo")
    val assignedTo: List<String>,
    @SerialName("filters")
    val filters: List<FormFilterResponse>?
)