package com.catbit.opinionpoll.core.ui.composables.base_components

import androidx.compose.runtime.Composable
import com.catbit.opinionpoll.core.extensions.changeAlpha
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.silk.theme.colors.ColorMode

@Composable
fun RadioButton(
    modifier: Modifier = Modifier,
    checked: Boolean,
    enabled: Boolean = true,
    onCheck: (Boolean) -> Unit
) {
    Box(
        modifier = modifier
            .cursor(if (enabled) Cursor.Pointer else Cursor.NotAllowed)
            .onClick {
                if (enabled) {
                    onCheck(!checked)
                }
            }
    ) {
        if (checked) {
            MaterialIcon(
                icon = MaterialIcons.Round.RadioButtonChecked,
                tint = if (enabled)
                    sitePalette().primary
                else
                    sitePalette().onSurface.changeAlpha(0.38f)
            )
        } else {
            MaterialIcon(
                icon = MaterialIcons.Round.RadioButtonUnchecked,
                tint = if (enabled)
                    sitePalette().onSurface
                else
                    sitePalette().onSurface.changeAlpha(0.38f)
            )
        }
    }

}