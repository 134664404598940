package com.catbit.opinionpoll.core.charts.data

import com.varabyte.kobweb.compose.ui.graphics.Color

data class LineChartData(
    val title: String,
    val labels: List<String>,
    val dataSet: List<Data>
) {
    data class Data(
        val values: List<Number>,
        val label: String,
        val color: Color
    )
}
