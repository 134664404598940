package com.catbit.opinionpoll.data.data_sources.networking

import com.catbit.opinionpoll.core.exceptions.HttpResponseException
import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.data.data_sources.networking.requests.*
import com.catbit.opinionpoll.data.data_sources.networking.responses.*
import com.catbit.opinionpoll.inputs.requests.requests.InputRequest
import io.ktor.client.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.internal.JSJoda.ZoneId
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlinx.datetime.internal.JSJoda.LocalDateTime as JodaLocalDateTime

class OpinionPollNetworkImpl(
    private val httpClient: HttpClient
) : OpinionPollNetwork {

    @OptIn(ExperimentalSerializationApi::class)
    private val json = Json {
        ignoreUnknownKeys = true
        explicitNulls = false
    }

    override suspend fun login(
        email: String,
        password: String,
        deviceUUID: String,
    ) = networkCall<LoginResponse> {
        httpClient.post {
            url("login")
            setBody(
                json.encodeToString(
                    LoginRequest(
                        email = email,
                        password = password,
                        deviceUUID = deviceUUID
                    )
                )
            )
        }
    }

    override suspend fun resetPassword(
        email: String
    ) = networkCall<Unit> {
        httpClient.post {
            url("resetPassword")
            setBody(
                json.encodeToString(
                    RecoverPasswordRequest(
                        email = email
                    )
                )
            )
        }
    }

    override suspend fun createEnvironment(
        email: String,
        fullName: String,
        password: String,
        phoneNumber: String,
        companyName: String
    ) = networkCall<Unit> {
        httpClient.post {
            url("environments/add")
            setBody(
                json.encodeToString(
                    CreateEnvironmentRequest(
                        email = email,
                        fullName = fullName,
                        password = password,
                        phoneNumber = phoneNumber,
                        environmentName = companyName,
                    )
                )
            )
        }
    }

    override suspend fun saveFormFilters(
        idToken: String,
        formId: String,
        filters: List<FormFilterRequest>
    ) = networkCall<Unit> {
        httpClient.post {
            url("forms/filters/add/$formId")
            header("idToken", idToken)
            setBody(
                json.encodeToString(filters)
            )
        }
    }

    override suspend fun updateAnswers(
        idToken: String,
        formIdentifier: String,
        answerUpdateRequest: UpdateAnswersRequest
    ) = networkCall<Unit> {
        httpClient.put {
            url("forms/answers/$formIdentifier/update")
            header("idToken", idToken)
            setBody(
                json.encodeToString(answerUpdateRequest)
            )
        }
    }

    override suspend fun askForSupport(
        idToken: String,
        askForSupportRequest: AskForSupportRequest
    ) = networkCall<Unit> {
        httpClient.post {
            url("askForSupport")
            header("idToken", idToken)
            setBody(
                json.encodeToString(askForSupportRequest)
            )
        }
    }

    override suspend fun getInTouch(
        getInTouchRequest: GetInTouchRequest
    ) = networkCall<Unit> {
        httpClient.post {
            url("getInTouch")
            setBody(
                json.encodeToString(getInTouchRequest)
            )
        }
    }

    override suspend fun getForms(
        idToken: String
    ) = networkCall<List<SimplifiedFormResponse>> {
        httpClient.get {
            url("forms")
            header("idToken", idToken)
        }
    }

    override suspend fun getStats(
        idToken: String
    ) = networkCall<StatsResponse> {
        httpClient.get {
            url("stats")
            header("idToken", idToken)
        }
    }

    override suspend fun getFormsAnswers(
        idToken: String,
        formIdentifier: String
    ) = networkCall<List<FormAnswerResponse>> {
        httpClient.get {
            url("forms/answers/$formIdentifier")
            header("idToken", idToken)
        }
    }

    override suspend fun refreshToken(
        refreshToken: String
    ) = networkCall<RefreshTokenResponse> {
        httpClient.post {
            url("refreshToken")
            setBody(
                json.encodeToString(
                    RefreshTokenRequest(
                        refreshToken = refreshToken
                    )
                )
            )
        }
    }

    override suspend fun createForm(
        idToken: String,
        formIdentifier: String,
        version: Long,
        title: String,
        tips: String?,
        expirationDate: LocalDateTime,
        sendGeoCoordinates: Boolean,
        template: List<InputRequest>,
        currentUser: String
    ) = networkCall<Unit> {
        httpClient.post {
            url("forms/add/$formIdentifier")
            header("idToken", idToken)
            setBody(
                json.encodeToString(
                    FormCreationRequest(
                        version = version,
                        title = title,
                        tips = tips,
                        creationDate = JodaLocalDateTime.now(clockOrZone = ZoneId.SYSTEM).toString(),
                        expirationDate = expirationDate.toString(),
                        sendGeoCoordinates = sendGeoCoordinates,
                        createdBy = currentUser,
                        template = template
                    )
                )
            )
        }
    }

    override suspend fun updateForm(
        idToken: String,
        formIdentifier: String,
        version: Long,
        title: String,
        tips: String?,
        expirationDate: LocalDateTime,
        sendGeoCoordinates: Boolean,
        template: List<InputRequest>
    ) = networkCall<Unit> {
        httpClient.patch {
            url("forms/update/$formIdentifier")
            header("idToken", idToken)
            setBody(
                json.encodeToString(
                    FormUpdateRequest(
                        version = version,
                        title = title,
                        tips = tips,
                        expirationDate = expirationDate.toString(),
                        sendGeoCoordinates = sendGeoCoordinates,
                        template = template
                    )
                )
            )
        }
    }

    override suspend fun deleteForms(
        idToken: String,
        formIdentifiers: List<String>
    ) = networkCall<Unit> {
        httpClient.delete {
            url("forms/delete/${formIdentifiers.joinToString(",")}")
            header("idToken", idToken)
        }
    }

    override suspend fun getUsers(
        idToken: String
    ) = networkCall<List<UserResponse>> {
        httpClient.get {
            url("users")
            header("idToken", idToken)
        }
    }

    override suspend fun getForm(
        idToken: String,
        formIdentifier: String
    ) = networkCall<FormResponse> {
        httpClient.get {
            url("form/$formIdentifier")
            header("idToken", idToken)
        }
    }

    override suspend fun linkFormToUsers(
        idToken: String,
        formId: String,
        userIdentifiers: List<String>
    ) = networkCall<Unit> {
        httpClient.post {
            url("form/$formId/linkToUsers")
            header("idToken", idToken)
            setBody(
                json.encodeToString(
                    LinkFormToUsersRequest(
                        users = userIdentifiers
                    )
                )
            )
        }
    }

    override suspend fun deleteUsers(
        idToken: String,
        userIdentifiers: List<String>
    ) = networkCall<Unit> {
        httpClient.delete {
            url("users/delete/${userIdentifiers.joinToString(",")}")
            header("idToken", idToken)
        }
    }

    override suspend fun updateUser(
        idToken: String,
        userIdentifier: String,
        phoneNumber: String,
        role: String,
        name: String,
        email: String
    ) = networkCall<Unit> {
        httpClient.patch {
            url("users/update/$userIdentifier")
            header("idToken", idToken)
            setBody(
                json.encodeToString(
                    UserUpdateRequest(
                        phoneNumber = phoneNumber,
                        role = role,
                        displayName = name,
                        email = email
                    )
                )
            )
        }
    }

    override suspend fun createUser(
        idToken: String,
        phoneNumber: String,
        role: String,
        name: String,
        email: String
    ) = networkCall<Unit> {
        httpClient.post {
            url("users/add")
            header("idToken", idToken)
            setBody(
                json.encodeToString(
                    UserCreateRequest(
                        phoneNumber = phoneNumber,
                        role = role,
                        displayName = name,
                        email = email,
                        password = email
                    )
                )
            )
        }
    }

    override suspend fun copyForm(
        idToken: String,
        newTitle: String,
        formIdentifier: String
    ) = networkCall<Unit> {
        httpClient.post {
            url("forms/copy/$formIdentifier")
            header("idToken", idToken)
            setBody(
                json.encodeToString(
                    FormCopyRequest(
                        newTitle = newTitle,
                        formIdentifier = UUID.stringUUID()
                    )
                )
            )
        }
    }

    private suspend inline fun <reified T> networkCall(
        networkBlock: () -> HttpResponse
    ): T {
        val response = networkBlock()
        return if (response.status.isSuccess()) {
            if (T::class == Unit::class) {
                Unit as T
            } else json.decodeFromString<T>(response.bodyAsText())
        } else throw HttpResponseException(statusCode = response.status, message = response.bodyAsText())
    }
}