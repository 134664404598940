package com.catbit.opinionpoll.domain.forms

import com.catbit.opinionpoll.core.domain.UseCase
import com.catbit.opinionpoll.data.models.SimplifiedFormModel
import com.catbit.opinionpoll.data.repositories.OpinionPollRepository

class GetSimplifiedFormsUseCase(
    private val repository: OpinionPollRepository
) : UseCase<List<SimplifiedFormModel>, Unit>() {

    override suspend fun execute(params: Unit) = repository.getForms()
}