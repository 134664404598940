package com.catbit.opinionpoll.data.models

import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.ui.states.UserUIState
import kotlinx.serialization.Serializable

@Serializable
data class UserModel(
    val uid: String,
    val environment: String,
    val phoneNumber: String,
    val role: UserRole,
    val name: String,
    val email: String,
) {
    fun toUIState() = UserUIState(
        uid = uid,
        phoneNumber = phoneNumber,
        roleIcon = role.icon,
        name = name,
        email = email,
        checked = false
    )

    enum class UserRole(val role: String) {
        ProductUser("ProductUser"),
        ProductManager("ProductManager"),
        ProductOwner("ProductOwner"),
        Admin("Admin");

        fun checkRoleAtLeast(other: UserRole) = (this.ordinal - other.ordinal) >= 0

        val icon
            get() = when (this) {
                ProductUser -> MaterialIcons.Round.Person
                ProductManager -> MaterialIcons.Round.ManageAccounts
                ProductOwner -> MaterialIcons.Round.WorkOutline
                Admin -> MaterialIcons.Round.AdminPanelSettings
            }

        companion object {
            fun getRoleFromString(role: String) = when (role) {
                "Admin" -> Admin
                "ProductOwner" -> ProductOwner
                "ProductManager" -> ProductManager
                else -> ProductUser
            }
        }
    }
}
