package com.catbit.opinionpoll.core.extensions

import kotlinx.datetime.internal.JSJoda.Month

fun Month.displayText() = when (this) {
    Month.Companion.JANUARY -> "Jan"
    Month.Companion.FEBRUARY -> "Fev"
    Month.Companion.MARCH -> "Mar"
    Month.Companion.APRIL -> "Abr"
    Month.Companion.MAY -> "Mai"
    Month.Companion.JUNE -> "Jun"
    Month.Companion.JULY -> "Jul"
    Month.Companion.AUGUST -> "Ago"
    Month.Companion.SEPTEMBER -> "Set"
    Month.Companion.OCTOBER -> "Out"
    Month.Companion.NOVEMBER -> "Nov"
    else -> "Dec"
}

fun Month.brazilianName() = when (this) {
    Month.Companion.JANUARY -> "Janeiro"
    Month.Companion.FEBRUARY -> "Fevereiro"
    Month.Companion.MARCH -> "Março"
    Month.Companion.APRIL -> "Abril"
    Month.Companion.MAY -> "Maio"
    Month.Companion.JUNE -> "Junho"
    Month.Companion.JULY -> "Julho"
    Month.Companion.AUGUST -> "Agosto"
    Month.Companion.SEPTEMBER -> "Setembro"
    Month.Companion.OCTOBER -> "Outubro"
    Month.Companion.NOVEMBER -> "Novembro"
    else -> "Dezembro"
}