package com.catbit.opinionpoll.core.sailor.overlays.snackbar

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.ui.composables.base_components.Text
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.overlay.KeepPopupOpenStrategy
import com.varabyte.kobweb.silk.components.overlay.OpenClosePopupStrategy
import com.varabyte.kobweb.silk.components.overlay.manual
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.coroutines.*
import org.jetbrains.compose.web.css.CSSNumeric
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

class SnackBarState {

    var currentContent: @Composable () -> Unit = {}
        private set

    val closeStrategy = OpenClosePopupStrategy.manual()
    val keepOpenStrategy = KeepPopupOpenStrategy.manual()

    private var currentSnackBarDisplayJob: Job? = null
    private var onDismissCallback: (() -> Unit)? = null

    fun dismiss() {
        currentSnackBarDisplayJob?.cancel()
        defaultDismissOperations()
    }

    fun show(
        message: String,
        coroutineScope: CoroutineScope,
        duration: Duration = 3.seconds,
        onDismiss: (() -> Unit)? = null,
        bottomOffset: CSSNumeric = 24.px,
    ) {
        show(
            coroutineScope = coroutineScope,
            duration = duration,
            onDismiss = onDismiss,
            bottomOffset = bottomOffset,
            content = @Composable {

                val onMouseEnterColor = sitePalette().primary
                val onMouseLeaveColor = sitePalette().inversePrimary
                var actionTextColor by remember { mutableStateOf(onMouseLeaveColor) }

                Row(
                    modifier = Modifier
                        .fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Text(
                        modifier = Modifier
                            .fillMaxWidth()
                            .color(sitePalette().inverseOnSurface),
                        text = message
                    )
                    Text(
                        modifier = Modifier
                            .padding(left = 16.px)
                            .color(actionTextColor)
                            .onMouseEnter {
                                actionTextColor = onMouseEnterColor
                            }
                            .onMouseLeave {
                                actionTextColor = onMouseLeaveColor
                            }
                            .cursor(Cursor.Pointer)
                            .onClick { dismiss() },
                        text = "Ok"
                    )
                }
            }
        )
    }

    fun show(
        coroutineScope: CoroutineScope,
        duration: Duration = 3.seconds,
        onDismiss: (() -> Unit)? = null,
        bottomOffset: CSSNumeric = 24.px,
        content: @Composable () -> Unit
    ) {
        onDismissCallback = onDismiss

        currentContent = @Composable {
            Box(
                modifier = Modifier
                    .id("snackbar-wrapper")
                    .fillMaxWidth()
                    .clip(Rect(8.px))
                    .margin {
                        top(bottomOffset)
                        right(24.px)
                        bottom(bottomOffset)
                        left(24.px)
                    }
                    .padding {
                        top(16.px)
                        right(16.px)
                        bottom(16.px)
                        left(16.px)
                    }
                    .background(
                        color = sitePalette().inverseSurface,
                    )
            ) {
                content()
            }
        }

        closeStrategy.isOpen = true
        keepOpenStrategy.shouldKeepOpen = true

        coroutineScope.coroutineContext.job.invokeOnCompletion {
            defaultDismissOperations()
        }

        currentSnackBarDisplayJob = coroutineScope.launch {
            delay(duration)
            defaultDismissOperations()
        }
    }

    private fun defaultDismissOperations() {
        closeStrategy.isOpen = false
        keepOpenStrategy.shouldKeepOpen = false
        onDismissCallback?.invoke()
    }
}

@Composable
fun rememberSnackBarState() = remember { SnackBarState() }