package com.catbit.opinionpoll.core.ui.animations

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.rotate
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.components.animation.toAnimation
import org.jetbrains.compose.web.css.AnimationTimingFunction
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.s

class InfiniteSpinningAnimation : AnimationHolder {
    @Composable
    override fun toAnimation() = InfiniteSpinningKeyFrames.toAnimation(
        duration = 0.8.s,
        timingFunction = AnimationTimingFunction.Linear,
        iterationCount = AnimationIterationCount.Infinite
    )
}

val InfiniteSpinningKeyFrames by Keyframes {
    from { Modifier.rotate(0.deg) }
    to { Modifier.rotate(359.deg) }
}
