package com.catbit.opinionpoll.inputs.models

import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.inputs.InputType
import com.catbit.opinionpoll.inputs.inputs.NumericAreaInputUIState
import kotlinx.serialization.Serializable

@Serializable
data class NumericAreaInputModel(
    override val identifier: String,
    override val title: String,
    override val subtitle: String?,
    override val isObligatory: Boolean,
    override val index: Int,
    val minValue: Long,
    val maxValue: Long,
    val symbol: String?,
    val symbolPosition: SymbolPosition,
    val decimalPoints: Int
) : InputModel {

    enum class SymbolPosition(val position: String) {
        Before("before"), After("after");

        companion object {
            fun getFromString(position: String?) =
                entries.firstOrNull { it.position == position } ?: Before
        }
    }

    override fun toUIState() = NumericAreaInputUIState(
        actualIdentifier = UUID.stringUUID(),
        type = InputType.NumericArea,
        identifier = identifier,
        title = title,
        subtitle = subtitle.orEmpty(),
        isObligatory = isObligatory,
        index = index,
        errorMessage = null,
        isEnabled = true,
        minValue = minValue,
        maxValue = maxValue,
        symbol = symbol.orEmpty(),
        symbolPosition = when (symbolPosition) {
            SymbolPosition.Before -> NumericAreaInputUIState.SymbolPosition.Before
            SymbolPosition.After -> NumericAreaInputUIState.SymbolPosition.After
        },
        decimalPoints = decimalPoints
    )

    override fun knowsIdentifier(identifier: String) = identifier == this.identifier
    override val identifiers = listOf(identifier)
    override val titleToIdentifierMapping = listOf(title to identifier)
}