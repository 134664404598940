package com.catbit.opinionpoll.inputs.inputs

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.ui.composables.base_components.Slider
import com.catbit.opinionpoll.core.ui.composables.base_components.Text
import com.catbit.opinionpoll.core.ui.composables.base_components.TextField
import com.catbit.opinionpoll.core.ui.modifiers.titleMedium
import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.inputs.InputType
import com.catbit.opinionpoll.inputs.InputUI
import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.composables.DefaultFormContainer
import com.catbit.opinionpoll.inputs.composables.DefaultFormPreview
import com.catbit.opinionpoll.inputs.events.EvaluationInputEventData
import com.catbit.opinionpoll.inputs.events.InputUIEvent
import com.catbit.opinionpoll.res.Strings
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import org.jetbrains.compose.web.css.px

class EvaluationInput : InputUI<EvaluationInputUIState> {
    override fun match(uiState: InputUIState) = uiState is EvaluationInputUIState

    @Composable
    override fun Compose(uiState: EvaluationInputUIState, onEvent: (InputUIEvent) -> Unit) {
        DefaultFormContainer(
            modifier = Modifier.fillMaxWidth(),
            inputUIState = uiState,
            onEvent = onEvent,
            preview = { EvaluationInputPreview(uiState) }
        ) {

            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(top = 16.px)
            ) {
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(right = 16.px)
                ) {
                    Text(
                        modifier = Modifier
                            .titleMedium()
                            .fontWeight(FontWeight.Bold),
                        text = Strings.minValue
                    )
                    TextField(
                        modifier = Modifier
                            .fillMaxWidth()
                            .margin(top = 8.px),
                        text = uiState.minValue.toString(),
                        enabled = uiState.isEnabled,
                        onTextChanged = {
                            val newValue = it.takeIf { it.isNotBlank() } ?: "0"
                            onEvent(
                                InputUIEvent(
                                    formIdentifier = uiState.actualIdentifier,
                                    data = EvaluationInputEventData.OnMinValueChange(newValue.toInt())
                                )
                            )
                        }
                    )
                }
                Column(
                    modifier = Modifier.fillMaxWidth()
                ) {
                    Text(
                        modifier = Modifier
                            .titleMedium()
                            .fontWeight(FontWeight.Bold),
                        text = Strings.maxValue
                    )
                    TextField(
                        modifier = Modifier
                            .fillMaxWidth()
                            .margin(top = 8.px),
                        text = uiState.maxValue.toString(),
                        enabled = uiState.isEnabled,
                        onTextChanged = {
                            val newValue = it.takeIf { it.isNotBlank() } ?: "0"
                            onEvent(
                                InputUIEvent(
                                    formIdentifier = uiState.actualIdentifier,
                                    data = EvaluationInputEventData.OnMaxValueChange(newValue.toInt())
                                )
                            )
                        }
                    )
                }
            }
        }
    }

    @Composable
    private fun EvaluationInputPreview(uiState: EvaluationInputUIState) {

        var value by remember { mutableStateOf(0) }

        with(uiState) {
            DefaultFormPreview(
                index = index,
                title = title,
                subtitle = subtitle
            ) {
                Slider(
                    modifier = Modifier.fillMaxWidth(),
                    value = value,
                    minValue = minValue,
                    maxValue = maxValue
                ) {
                    value = it
                }
            }
        }
    }

}

data class EvaluationInputUIState(
    override val index: Int,
    override val isEnabled: Boolean,
    override val actualIdentifier: String,
    override val identifier: String,
    override val title: String,
    override val subtitle: String,
    override val isObligatory: Boolean,
    override val type: InputType,
    override val errorMessage: String?,
    val minValue: Int,
    val maxValue: Int,
) : InputUIState {

    companion object {
        fun default(
            index: Int
        ) = EvaluationInputUIState(
            index = index,
            isEnabled = true,
            actualIdentifier = UUID.stringUUID(),
            identifier = "",
            title = "",
            subtitle = "",
            isObligatory = false,
            type = InputType.Evaluation,
            errorMessage = null,
            minValue = 0,
            maxValue = 0,
        )
    }

    override val internalIdentifiers = listOf(identifier)
    override fun knowsIdentifier(identifier: String) = identifier == this.identifier
    override fun updateIndex(newIndex: Int) = copy(index = newIndex)
    override fun copy(
        index: Int?,
        isEnabled: Boolean?,
        actualIdentifier: String?,
        identifier: String?,
        title: String?,
        subtitle: String?,
        isObligatory: Boolean?,
        type: InputType?,
        error: String?
    ) = copy(
        index = index ?: this.index,
        isEnabled = isEnabled ?: this.isEnabled,
        actualIdentifier = actualIdentifier ?: this.actualIdentifier,
        identifier = identifier ?: this.identifier,
        title = title ?: this.title,
        subtitle = subtitle ?: this.subtitle,
        isObligatory = isObligatory ?: this.isObligatory,
        type = type ?: this.type,
        errorMessage = error ?: this.errorMessage,
    )
}