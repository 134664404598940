package com.catbit.opinionpoll.inputs.requests.producers

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.inputs.TextAreaInputUIState
import com.catbit.opinionpoll.inputs.requests.requests.TextAreaInputRequest

class TextAreaInputRequestProducer : InputRequestProducer<TextAreaInputUIState> {
    override fun match(inputUIState: InputUIState) = inputUIState is TextAreaInputUIState

    override fun produce(inputUIState: TextAreaInputUIState) = TextAreaInputRequest(
        identifier = inputUIState.identifier,
        index = inputUIState.index,
        title = inputUIState.title,
        subtitle = inputUIState.subtitle,
        isObligatory = inputUIState.isObligatory,
        maxLength = inputUIState.maxLength
    )
}