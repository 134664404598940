package com.catbit.opinionpoll.inputs.events_handler

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.events.InputUIEventDefaultData
import com.catbit.opinionpoll.inputs.events.TimeInputEventData
import com.catbit.opinionpoll.inputs.inputs.TimeInputUIState

class TimeInputUIEventHandler : InputUIEventHandler<TimeInputUIState> {

    override fun match(formIdentifier: String, uiState: InputUIState) =
        uiState is TimeInputUIState && formIdentifier == uiState.actualIdentifier

    override fun handle(uiState: TimeInputUIState, eventData: Any) = when (eventData) {
        is TimeInputEventData.OnMinTimeChange -> uiState.copy(minTime = eventData.newMinTime)
        is TimeInputEventData.OnMaxTimeChange -> uiState.copy(maxTime = eventData.newMaxTime)
        is InputUIEventDefaultData.IdentifierChange -> uiState.copy(identifier = eventData.newIdentifier)
        is InputUIEventDefaultData.TitleChange -> uiState.copy(title = eventData.newTitle)
        is InputUIEventDefaultData.SubtitleChange -> uiState.copy(subtitle = eventData.newSubtitle)
        is InputUIEventDefaultData.ObligatoryToggle -> uiState.copy(isObligatory = eventData.isObligatory)
        else -> uiState
    }
}