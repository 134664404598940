package com.catbit.opinionpoll.inputs.validators

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.inputs.MultipleChoicesInputUIState

class MultipleChoicesInputUIValidator : InputUIValidator<MultipleChoicesInputUIState> {
    override fun match(inputUIState: InputUIState) = inputUIState is MultipleChoicesInputUIState

    override fun validate(
        inputUIState: MultipleChoicesInputUIState,
        allIdentifiers: List<String>
    ): InputUIState {

        val errorMessage = with(inputUIState) {
            if (identifier.isEmpty()) {
                "O identificador não pode ser vazio"
            } else if (title.isEmpty()) {
                "O título não pode ser vazio"
            } else if (options.size <= 1 || (options.size == 1 && !hasOtherOption)) {
                "É necessário adicionar ao menos duas opções (Ou uma opção e habilitar \"Exibir opção Outros\")"
            } else if (options.any { it.title.isEmpty() }) {
                "Todas as opções precisam ter um título"
            } else if (minSelection >= maxSelection) {
                "A seleção máxima precisa ser maior que a seleção mínima"
            } else if (minSelection > options.size) {
                "A seleção mínima não pode ser maior que a quantidade de opções disponíveis"
            } else if (maxSelection > options.size) {
                "A seleção máxima não pode ser maior que a quantidade de opções disponíveis"
            } else if (hasOtherOption && maxOtherOptions == 0) {
                "O máximo de outras opções precisa ser maior que zero"
            }  else if (hasOtherOption && maxOtherOptionTextLength == 0) {
                "O tamanho máximo da outra opção não pode ser zero"
            } else if (identifier in allIdentifiers) {
                "O identificador precisa ser único"
            } else null
        }

        return inputUIState.copy(errorMessage = errorMessage)
    }
}