package com.catbit.opinionpoll.core.ui.composables.base_components

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.extensions.bestPlacementOf
import com.catbit.opinionpoll.core.extensions.kotlinLocalTime
import com.catbit.opinionpoll.core.extensions.withNotNull
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.composables.effects.SingleEffect
import com.catbit.opinionpoll.core.ui.helpers.ScrollMode
import com.catbit.opinionpoll.core.ui.helpers.scrollContent
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.*
import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.res.Strings
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.UserSelect
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.overlay.*
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.flow.map
import kotlinx.datetime.internal.JSJoda.LocalTime
import kotlinx.datetime.internal.JSJoda.ZoneId
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.unaryMinus
import kotlinx.datetime.LocalTime as KotlinLocalTime

@Composable
fun TimePicker(
    modifier: Modifier = Modifier,
    value: LocalTime?,
    enabled: Boolean = true,
    backgroundColor: Color = sitePalette().surfaceVariant,
    showClearButton: Boolean = true,
    onTimePick: (KotlinLocalTime?) -> Unit
) {
    val mainPopCloseStrategyControl = remember { OpenClosePopupStrategy.manual() }
    val mainPopKeepOpenStrategyControl = remember { KeepPopupOpenStrategy.manual() }
    val hourPopCloseStrategyControl = remember { OpenClosePopupStrategy.manual() }
    val hourPopKeepOpenStrategyControl = remember { KeepPopupOpenStrategy.manual() }
    val minutePopCloseStrategyControl = remember { OpenClosePopupStrategy.manual() }
    val minutePopKeepOpenStrategyControl = remember { KeepPopupOpenStrategy.manual() }

    val hasFocus by mainPopCloseStrategyControl
        .requestFlow
        .map { it == OpenClose.OPEN }
        .collectAsState(false)

    val enableHourPicker by minutePopCloseStrategyControl
        .requestFlow
        .map { it != OpenClose.OPEN }
        .collectAsState(true)

    val isHourSelected by hourPopCloseStrategyControl
        .requestFlow
        .map { it == OpenClose.OPEN }
        .collectAsState(true)

    val enableMinutePicker by hourPopCloseStrategyControl
        .requestFlow
        .map { it != OpenClose.OPEN }
        .collectAsState(true)

    val isMinuteSelected by minutePopCloseStrategyControl
        .requestFlow
        .map { it == OpenClose.OPEN }
        .collectAsState(true)

    var containerYPosition by remember { mutableStateOf(0.0) }
    var containerXPosition by remember { mutableStateOf(0.0) }
    var containerWidth by remember { mutableStateOf(0.0) }
    val id by remember { mutableStateOf(UUID.stringUUID()) }

    val currentTime = remember { LocalTime.now(ZoneId.SYSTEM) }
    var selectedHour by remember { mutableStateOf(value?.hour() ?: currentTime.hour()) }
    var selectedMinute by remember { mutableStateOf(value?.minute() ?: currentTime.minute()) }

    Row(
        modifier = modifier
            .id(id)
            .cursor(Cursor.Pointer)
            .padding(all = 16.px)
            .height(48.px)
            .borderRadius(8.px)
            .ariaDisabled(enabled.not())
            .backgroundColor(backgroundColor)
            .thenIf(hasFocus) {
                border(
                    color = sitePalette().primary,
                    style = LineStyle.Solid,
                    width = 2.px
                )
            }
            .onClick {
                withNotNull(document.getElementById(id)?.getBoundingClientRect()) {
                    containerYPosition = y
                    containerXPosition = x
                    containerWidth = width
                }

                if (mainPopCloseStrategyControl.isOpen) {
                    hourPopCloseStrategyControl.isOpen = false
                    hourPopKeepOpenStrategyControl.shouldKeepOpen = false
                    minutePopCloseStrategyControl.isOpen = false
                    minutePopKeepOpenStrategyControl.shouldKeepOpen = false
                }

                selectedHour = value?.hour() ?: currentTime.hour()
                selectedMinute = value?.minute() ?: currentTime.minute()

                mainPopCloseStrategyControl.isOpen = mainPopCloseStrategyControl.isOpen.not()
                mainPopKeepOpenStrategyControl.shouldKeepOpen = mainPopKeepOpenStrategyControl.shouldKeepOpen.not()
            },
        verticalAlignment = Alignment.CenterVertically
    ) {
        Text(
            modifier = Modifier.bodyLarge()
                .color(sitePalette().onSurface)
                .fillMaxWidth(),
            text = value?.toString().orEmpty()
        )
        MaterialIcon(MaterialIcons.Round.Alarm)
    }

    AdvancedPopover(
        ElementTarget.PreviousSibling,
        placementStrategy = window.bestPlacementOf(
            xPos = containerXPosition,
            yPos = containerYPosition,
            width = containerWidth
        ),
        openCloseStrategy = mainPopCloseStrategyControl,
        keepOpenStrategy = mainPopKeepOpenStrategyControl
    ) {
        Column(
            modifier = Modifier
                .clip(Rect(16.px))
                .width(290.px)
                .background(sitePalette().surfaceVariant)
                .border(
                    color = sitePalette().primary,
                    width = 2.px,
                    style = LineStyle.Solid
                )
                .borderRadius(16.px)
                .padding(all = 24.px)
        ) {
            TimePickerControl(
                modifier = Modifier.fillMaxWidth(),
                dialogModifier = Modifier
                    .translateX(-(24.px))
                    .width(290.px)
                    .height(130.px),
                dialogLeftOffset = -24,
                selectedHour = selectedHour,
                isHourSelected = isHourSelected,
                enableHourPicker = enableHourPicker,
                onHourClick = {
                    hourPopCloseStrategyControl.isOpen = true
                    hourPopKeepOpenStrategyControl.shouldKeepOpen = true
                },
                onHourSelect = {
                    selectedHour = it
                    hourPopCloseStrategyControl.isOpen = false
                    hourPopKeepOpenStrategyControl.shouldKeepOpen = false
                },
                hourPopCloseStrategyControl = hourPopCloseStrategyControl,
                hourPopKeepOpenStrategyControl = hourPopKeepOpenStrategyControl,
                isMinuteSelected = isMinuteSelected,
                selectedMinute = selectedMinute,
                enableMinutePicker = enableMinutePicker,
                minutePopCloseStrategyControl = minutePopCloseStrategyControl,
                minutePopKeepOpenStrategyControl = minutePopKeepOpenStrategyControl,
                onMinuteSelect = {
                    selectedMinute = it
                    minutePopCloseStrategyControl.isOpen = false
                    minutePopKeepOpenStrategyControl.shouldKeepOpen = false
                }
            ) {
                minutePopCloseStrategyControl.isOpen = true
                minutePopKeepOpenStrategyControl.shouldKeepOpen = true
            }

            PickerDialogControl(
                modifier = Modifier
                    .margin(top = 48.px)
                    .fillMaxWidth(),
                showClearButton = showClearButton,
                onClearClick = {
                    onTimePick(null)
                    mainPopCloseStrategyControl.isOpen = false
                    mainPopKeepOpenStrategyControl.shouldKeepOpen = false
                },
                onCancelClick = {
                    mainPopCloseStrategyControl.isOpen = false
                    mainPopKeepOpenStrategyControl.shouldKeepOpen = false
                },
                onSelectClick = {
                    onTimePick(LocalTime.of(selectedHour, selectedMinute).kotlinLocalTime())
                    mainPopCloseStrategyControl.isOpen = false
                    mainPopKeepOpenStrategyControl.shouldKeepOpen = false
                }
            )
        }
    }
}

@Composable
internal fun TimePickerControl(
    modifier: Modifier = Modifier,
    dialogModifier: Modifier = Modifier,
    dialogLeftOffset: Int,
    selectedHour: Number,
    isHourSelected: Boolean,
    enableHourPicker: Boolean,
    onHourClick: () -> Unit,
    onHourSelect: (Int) -> Unit,
    hourPopCloseStrategyControl: OpenClosePopupStrategy,
    hourPopKeepOpenStrategyControl: KeepPopupOpenStrategy,
    isMinuteSelected: Boolean,
    selectedMinute: Number,
    enableMinutePicker: Boolean,
    minutePopCloseStrategyControl: OpenClosePopupStrategy,
    minutePopKeepOpenStrategyControl: KeepPopupOpenStrategy,
    onMinuteSelect: (Int) -> Unit,
    onMinuteClick: () -> Unit,
) {
    Row(
        modifier = modifier,
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Column {
            TimeRange(
                title = selectedHour.toString(),
                enabled = enableHourPicker,
                selected = isHourSelected,
                onClick = onHourClick
            )
            Text(
                modifier = Modifier
                    .titleMedium()
                    .userSelect(UserSelect.None)
                    .margin(top = 8.px),
                text = Strings.hour
            )
        }
        Text(
            modifier = Modifier
                .displayLarge()
                .userSelect(UserSelect.None)
                .margin {
                    left(16.px)
                    right(16.px)
                    bottom(32.px)
                },
            text = ":"
        )
        Column {
            TimeRange(
                title = selectedMinute.toString(),
                onClick = onMinuteClick,
                selected = isMinuteSelected,
                enabled = enableMinutePicker
            )
            Text(
                modifier = Modifier
                    .titleMedium()
                    .userSelect(UserSelect.None)
                    .margin(top = 8.px),
                text = Strings.minute
            )
        }
    }
    AdvancedPopover(
        ElementTarget.PreviousSibling,
        placementStrategy = PopupPlacementStrategy.of(
            PopupPlacement.BottomLeft,
            offsetPixels = dialogLeftOffset
        ),
        openCloseStrategy = hourPopCloseStrategyControl,
        keepOpenStrategy = hourPopKeepOpenStrategyControl
    ) {
        TimePicker(
            modifier = dialogModifier,
            selectedTime = selectedHour.toInt(),
            onTimeSelect = onHourSelect,
            options = (0..23).toList(),
        )
    }

    // Âncora
    Row {}

    AdvancedPopover(
        ElementTarget.PreviousSibling,
        placementStrategy = PopupPlacementStrategy.of(
            PopupPlacement.BottomLeft,
            offsetPixels = dialogLeftOffset
        ),
        openCloseStrategy = minutePopCloseStrategyControl,
        keepOpenStrategy = minutePopKeepOpenStrategyControl
    ) {
        TimePicker(
            modifier = dialogModifier,
            selectedTime = selectedMinute.toInt(),
            onTimeSelect = onMinuteSelect,
            options = (0..59).toList(),
        )
    }
}

@Composable
private fun TimeRange(
    title: String,
    enabled: Boolean,
    selected: Boolean,
    onClick: () -> Unit
) {
    Row(
        modifier = Modifier
            .width(96.px)
            .height(72.px)
            .clip(Rect(8.px))
            .cursor(if (enabled) Cursor.Pointer else Cursor.Default)
            .background(
                if (!enabled) {
                    sitePalette().surfaceVariant
                } else if (selected) {
                    sitePalette().primaryContainer
                } else {
                    sitePalette().surface
                }
            )
            .onClick {
                if (enabled) {
                    onClick()
                }
            },
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Text(
            modifier = Modifier
                .displayLarge()
                .color(
                    if (!enabled) {
                        sitePalette().onSurfaceVariant
                    } else if (selected)
                        sitePalette().onPrimaryContainer
                    else
                        sitePalette().onSurface
                )
                .userSelect(UserSelect.None),
            text = title
        )
    }
}

@Composable
private fun TimePicker(
    modifier: Modifier = Modifier,
    selectedTime: Int,
    onTimeSelect: (Int) -> Unit,
    options: List<Int>
) {
    Column(
        modifier = modifier
            .roundedCornerClip(
                bottomRight = 16.px,
                bottomLeft = 16.px
            )
            .overflow(Overflow.Hidden)
            .background(sitePalette().surfaceVariant)
    ) {
        Divider(
            modifier = Modifier
                .height(1.px)
                .fillMaxWidth(),
            color = sitePalette().onSurfaceVariant
        )
        Column(
            modifier = Modifier
                .fillMaxSize()
                .id("TimePickerId")
                .overflow {
                    y(Overflow.Auto)
                }
        ) {
            options.forEach { timeOption ->
                Row(
                    modifier = Modifier
                        .cursor(Cursor.Pointer)
                        .fillMaxWidth()
                        .padding(all = 16.px)
                        .hoverBackground(
                            onMouseExitBackgroundColor = sitePalette().surfaceVariant,
                            onMouseEnterBackgroundColor = sitePalette().surface
                        )
                        .onClick { onTimeSelect(timeOption) }
                ) {
                    if (timeOption == selectedTime) {
                        MaterialIcon(MaterialIcons.Round.Check)
                    } else {
                        Box(modifier = Modifier.size(24.px))
                    }
                    Text(
                        modifier = Modifier
                            .bodyLarge()
                            .hoverColor(
                                onMouseExitColor = sitePalette().onSurfaceVariant,
                                onMouseEnterColor = sitePalette().onSurface
                            )
                            .margin(left = 16.px),
                        text = timeOption.toString()
                    )
                }
            }

            SingleEffect {
                val topOffset = options.indexOfFirst { it == selectedTime }
                scrollContent(
                    elementId = "TimePickerId",
                    mode = ScrollMode.Position(top = topOffset * 56.0)
                )
            }
        }
    }
}