package com.catbit.opinionpoll.inputs.composables

import androidx.compose.runtime.Composable
import com.catbit.opinionpoll.core.extensions.withNotNull
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.composables.base_components.Checkbox
import com.catbit.opinionpoll.core.ui.composables.base_components.Text
import com.catbit.opinionpoll.core.ui.composables.base_components.TextField
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.margin
import com.catbit.opinionpoll.core.ui.modifiers.padding
import com.catbit.opinionpoll.core.ui.modifiers.titleMedium
import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.events.InputUIEvent
import com.catbit.opinionpoll.inputs.events.InputUIEventDefaultData
import com.catbit.opinionpoll.res.Strings
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun DefaultFormContainer(
    modifier: Modifier = Modifier,
    inputUIState: InputUIState,
    onEvent: (InputUIEvent) -> Unit,
    preview: @Composable () -> Unit,
    content: @Composable () -> Unit,
) {
    Row(
        modifier = modifier
    ) {
        Column(
            modifier = Modifier
                .width(60.percent)
                .backgroundColor(sitePalette().surface)
                .clip(Rect(16.px))
                .padding(all = 16.px)
                .margin(right = 24.px)
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.Bottom
            ) {
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(right = 16.px)
                ) {
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Text(
                            modifier = Modifier
                                .margin(right = 8.px)
                                .titleMedium()
                                .fontWeight(FontWeight.Bold),
                            text = Strings.identifier
                        )
                        MaterialIcon(MaterialIcons.Round.Help)
                        Tooltip(
                            target = ElementTarget.PreviousSibling,
                            text = Strings.identifierTooltip,
                            placement = PopupPlacement.BottomLeft
                        )
                    }
                    TextField(
                        modifier = Modifier
                            .fillMaxWidth()
                            .margin(top = 8.px),
                        text = inputUIState.identifier,
                        enabled = inputUIState.isEnabled,
                        onTextChanged = {
                            onEvent(
                                InputUIEvent(
                                    formIdentifier = inputUIState.actualIdentifier,
                                    data = InputUIEventDefaultData.IdentifierChange(it)
                                )
                            )
                        }
                    )
                }

                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.Bottom
                ) {
                    Checkbox(
                        modifier = Modifier.margin(right = 8.px),
                        checked = inputUIState.isObligatory,
                        onCheck = {
                            onEvent(
                                InputUIEvent(
                                    formIdentifier = inputUIState.actualIdentifier,
                                    data = InputUIEventDefaultData.ObligatoryToggle(it)
                                )
                            )
                        }
                    )
                    Text(
                        text = Strings.obligatoryField,
                        modifier = Modifier
                            .titleMedium()
                            .fontWeight(FontWeight.Bold)
                    )
                }
            }

            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(top = 16.px)
            ) {
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(right = 16.px)
                ) {
                    Text(
                        text = Strings.title,
                        modifier = Modifier
                            .titleMedium()
                            .fontWeight(FontWeight.Bold)
                    )
                    TextField(
                        modifier = Modifier
                            .fillMaxWidth()
                            .margin(top = 8.px),
                        text = inputUIState.title,
                        enabled = inputUIState.isEnabled,
                        onTextChanged = {
                            onEvent(
                                InputUIEvent(
                                    formIdentifier = inputUIState.actualIdentifier,
                                    data = InputUIEventDefaultData.TitleChange(it)
                                )
                            )
                        }
                    )
                }

                Column(
                    modifier = Modifier.fillMaxWidth()
                ) {
                    Text(
                        text = Strings.subtitle,
                        modifier = Modifier
                            .titleMedium()
                            .fontWeight(FontWeight.Bold)
                    )
                    TextField(
                        modifier = Modifier
                            .fillMaxWidth()
                            .margin(top = 8.px),
                        text = inputUIState.subtitle,
                        enabled = inputUIState.isEnabled,
                        onTextChanged = {
                            onEvent(
                                InputUIEvent(
                                    formIdentifier = inputUIState.actualIdentifier,
                                    data = InputUIEventDefaultData.SubtitleChange(it)
                                )
                            )
                        }
                    )
                }
            }

            content()

            withNotNull(inputUIState.errorMessage) {
                Text(
                    modifier = Modifier
                        .margin(vertical = 8.px)
                        .titleMedium()
                        .padding(all = 16.px)
                        .fillMaxWidth()
                        .color(Color.white)
                        .backgroundColor(sitePalette().errorContainer)
                        .clip(Rect(8.px)),
                    text = this
                )
            }
        }

        Box(
            modifier = Modifier
                .width(40.percent)
                .backgroundColor(sitePalette().surface)
                .clip(Rect(16.px))
                .padding(all = 16.px)
        ) {
            preview()
        }
    }
}