package com.catbit.opinionpoll.core.ui.modifiers

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier

@Composable
fun Modifier.thenIf(
    condition: Boolean,
    ifFalse: (@Composable Modifier.() -> Modifier)? = null,
    ifTrue: @Composable Modifier.() -> Modifier,
): Modifier {
    return then(
        if (condition) {
            ifTrue()
        } else {
            ifFalse?.let {
                it()
            } ?: this
        }
    )
}