package com.catbit.opinionpoll.data.data_sources.networking.responses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class LoginResponse(
    @SerialName("kind")
    val kind: String,
    @SerialName("localId")
    val localId: String,
    @SerialName("email")
    val email: String,
    @SerialName("role")
    val role: String,
    @SerialName("displayName")
    val displayName: String,
    @SerialName("idToken")
    val idToken: String,
    @SerialName("registered")
    val registered: Boolean,
    @SerialName("environment")
    val environment: String,
    @SerialName("environmentImage")
    val environmentImage: String?,
    @SerialName("environmentName")
    val environmentName: String,
    @SerialName("environmentPlanCreatedIn")
    val environmentPlanCreatedIn: String,
    @SerialName("environmentPlanExpiresIn")
    val environmentPlanExpiresIn: String,
    @SerialName("environmentPlan")
    val environmentPlan: String,
    @SerialName("refreshToken")
    val refreshToken: String,
    @SerialName("expiresIn")
    val expiresIn: String,
)

