package com.catbit.opinionpoll.domain.form_dashboard

import com.catbit.opinionpoll.core.domain.UseCase
import com.catbit.opinionpoll.core.extensions.toBrazilianDateTimePattern
import com.catbit.opinionpoll.data.models.FormAnswerModel
import com.catbit.opinionpoll.data.models.UserModel
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag

class GetAnswersCSVUseCase : UseCase<Blob, GetAnswersCSVUseCase.Params>() {
    override suspend fun execute(params: Params): Result<Blob> {

        val stringBuilder = StringBuilder()
        val headers = params.answers.maxBy { it.submittedAt }.answer.keys.toList()
        val usersMap = params.users.associateBy(
            keySelector = { it.uid },
            valueTransform = { it.name }
        )

        with(stringBuilder) {
            append("Enviado em;")
            append("Enviado por;")
            append("Geocoordenadas;")
        }

        headers.forEachIndexed { index, header ->
            stringBuilder.append("$header${if (index != headers.lastIndex) ";" else "\n"}")
        }

        params.answers
            .sortedByDescending { it.submittedAt }
            .forEach { answer ->

                with(stringBuilder) {
                    append("${answer.submittedAt.toBrazilianDateTimePattern()};")
                    append("${usersMap[answer.submittedBy] ?: "Usuário desconhecido"};")
                    append("\"${answer.geoCoordinates?.toString().orEmpty()}\";")
                }

                headers.forEachIndexed { index, key ->
                    val content = answer.answer[key]

                    if (content != null) {
                        val formattedContent = if (content.contains(";")) "\"${content}\"" else content
                        stringBuilder.append("$formattedContent${if (index != headers.lastIndex) ";" else "\n"}")
                    } else {
                        stringBuilder.append(if (index != headers.lastIndex) ";" else "\n")
                    }
                }

            }

        return Result.success(
            Blob(
                blobParts = arrayOf(stringBuilder.toString()),
                options = BlobPropertyBag("text/csv;charset=utf-8")
            )
        )
    }

    data class Params(
        val answers: Collection<FormAnswerModel>,
        val users: List<UserModel>
    )
}