package com.catbit.opinionpoll.core.sailor

import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.LaunchedEffect
import com.catbit.opinionpoll.core.extensions.changeAlpha
import com.catbit.opinionpoll.core.sailor.navigation_controller.NavigationController
import com.catbit.opinionpoll.core.sailor.overlays.dialog.DialogState
import com.catbit.opinionpoll.core.sailor.overlays.dialog.rememberDialogState
import com.catbit.opinionpoll.core.sailor.overlays.snackbar.SnackBarState
import com.catbit.opinionpoll.core.sailor.overlays.snackbar.rememberSnackBarState
import com.catbit.opinionpoll.core.sailor.providers.LocalDialogStateProvider
import com.catbit.opinionpoll.core.sailor.providers.LocalNavigationController
import com.catbit.opinionpoll.core.sailor.providers.LocalSnackbarState
import com.catbit.opinionpoll.core.sailor.providers.LocalZIndexProvider
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.AlignSelf
import com.varabyte.kobweb.compose.css.JustifySelf
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.overlay.AdvancedPopover
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.PopupPlacementStrategy
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.percent

@Composable
fun NavigationHost(
    modifier: Modifier = Modifier,
    navigationController: NavigationController,
    dialogState: DialogState = rememberDialogState(),
    snackBarState: SnackBarState = rememberSnackBarState(),
    navigationRail: (@Composable () -> Unit)? = null,
    startingRoute: String,
    startingArguments: NavigationController.ArgumentsBundle? = null,
    scope: NavigationController.Scope.() -> Unit
) {
    LaunchedEffect(Unit) {
        scope(navigationController.Scope())
        navigationController.init(startingRoute, startingArguments)
    }

    CompositionLocalProvider(
        LocalNavigationController provides navigationController,
        LocalDialogStateProvider provides dialogState,
        LocalSnackbarState provides snackBarState,
        LocalZIndexProvider provides 0
    ) {
        Box(
            modifier = modifier
        ) {

            Box(
                modifier = Modifier
                    .id("main-overlay")
                    .fillMaxSize(),
                contentAlignment = Alignment.BottomCenter
            ) {
                /**
                 * Navigation frame
                 */

                navigationController.stackTopEntry?.let { stackEntry ->
                    Box(
                        modifier = Modifier
                            .fillMaxSize()
                    ) {
                        stackEntry.route.content(stackEntry)
                    }
                }

                /**
                 * Nav Rail
                 */

                navigationRail?.invoke()
            }

            /**
             * Snackbar Overlay
             */

            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .alignSelf(AlignSelf.End)
                    .justifySelf(JustifySelf.Center)
            ) {
                AdvancedPopover(
                    modifier = Modifier.width(50.percent),
                    target = ElementTarget.Parent,
                    placementStrategy = PopupPlacementStrategy.of(PopupPlacement.Top, 0),
                    openCloseStrategy = snackBarState.closeStrategy,
                    keepOpenStrategy = snackBarState.keepOpenStrategy,
                ) {
                    snackBarState.currentContent()
                }
            }

            /**
             * Dialog Overlay
             */
            Box(
                modifier = Modifier
                    .id("dialog-overlay")
                    .zIndex(102)
                    .fillMaxSize()
                    .backgroundColor(sitePalette().background.darkened().changeAlpha(0.8f))
                    .visibility(dialogState.visibility),
                contentAlignment = Alignment.Center,
            ) {
                CompositionLocalProvider(
                    LocalZIndexProvider provides 102
                ) {
                    dialogState.currentContent()
                }
            }
        }
    }
}