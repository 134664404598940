package com.catbit.opinionpoll.inputs.requests

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.requests.producers.InputRequestProducer

class InputRequestProductionHandler(
    private val requestProducers: List<InputRequestProducer<*>>
) {
    fun produce(inputUIState: InputUIState) =
        requestProducers.first { it.match(inputUIState) }.produce(inputUIState)
}