package com.catbit.opinionpoll.res

import com.catbit.opinionpoll.core.strings.StringLocale
import com.catbit.opinionpoll.core.strings.StringResource

// TODO StringResource precisa ser mutableState

object Strings {
    val update: String by StringResource(
        StringLocale.PT_BR to "Atualizar"
    )
    val saveFilters: String by StringResource(
        StringLocale.PT_BR to "Salvar filtros"
    )
    val editFilter: String by StringResource(
        StringLocale.PT_BR to "Editar filtro"
    )
    val deleteFilter: String by StringResource(
        StringLocale.PT_BR to "Remover filtro"
    )
    val howItWorks: String by StringResource(
        StringLocale.PT_BR to "Crie sua campanha"
    )
    val functionalities: String by StringResource(
        StringLocale.PT_BR to "Recursos"
    )
    val filter: String by StringResource(
        StringLocale.PT_BR to "Filtro"
    )
    val crossingFields: String by StringResource(
        StringLocale.PT_BR to "Campos cruzados"
    )
    val removeSubgroup: String by StringResource(
        StringLocale.PT_BR to "Remover subgrupo"
    )
    val values: String by StringResource(
        StringLocale.PT_BR to "Valores"
    )
    val addSubgroup: String by StringResource(
        StringLocale.PT_BR to "Adicionar subgrupo"
    )
    val subgroups: String by StringResource(
        StringLocale.PT_BR to "Subgrupos"
    )
    val groupingField: String by StringResource(
        StringLocale.PT_BR to "Campo agrupador"
    )
    val addFilter: String by StringResource(
        StringLocale.PT_BR to "Adicionar filtro"
    )
    val plans: String by StringResource(
        StringLocale.PT_BR to "Planos"
    )
    val tryForFree: String by StringResource(
        StringLocale.PT_BR to "Teste grátis por 7 dias!"
    )
    val password: String by StringResource(
        StringLocale.PT_BR to "Senha"
    )
    val companyName: String by StringResource(
        StringLocale.PT_BR to "Nome da equipe"
    )
    val notVerifiedEmailMessage by StringResource(
        StringLocale.PT_BR to "Email ainda não verificado. Um email foi enviado com instruções para continuar. Verifique sua caixa de entrada."
    )
    val thereWasAProblemAtLogin by StringResource(
        StringLocale.PT_BR to "Houve um problema ao realizar login, verifique email ou senha e tente novamente!"
    )
    val recoverPassword: String by StringResource(
        StringLocale.PT_BR to "Recuperar senha"
    )
    val forgetPassword: String by StringResource(
        StringLocale.PT_BR to "Esqueci minha senha"
    )
    val copy: String by StringResource(
        StringLocale.PT_BR to "Copiar"
    )
    val name: String by StringResource(
        StringLocale.PT_BR to "Nome"
    )
    val surname: String by StringResource(
        StringLocale.PT_BR to "Sobrenome"
    )
    val email: String by StringResource(
        StringLocale.PT_BR to "Email"
    )
    val phoneNumber: String by StringResource(
        StringLocale.PT_BR to "Telefone"
    )
    val role: String by StringResource(
        StringLocale.PT_BR to "Nível de acesso"
    )
    val exportAnswers: String by StringResource(
        StringLocale.PT_BR to "Exportar respostas"
    )
    val updateAnswers: String by StringResource(
        StringLocale.PT_BR to "Atualizar respostas"
    )
    val displaySearchBarTip: String by StringResource(
        StringLocale.PT_BR to "Quando o estilo for Dropdown, exibe uma barra de busca para que o usuário filtre as opções disponíveis"
    )
    val addOptionsInBatch: String by StringResource(
        StringLocale.PT_BR to "Adicionar opções em lote"
    )
    val addQuestionsInBatch: String by StringResource(
        StringLocale.PT_BR to "Adicionar questões em lote"
    )
    val add: String by StringResource(
        StringLocale.PT_BR to "Adicionar"
    )
    val send: String by StringResource(
        StringLocale.PT_BR to "Enviar"
    )
    val formMakerSearchHint: String by StringResource(
        StringLocale.PT_BR to "Buscar campo"
    )
    val clearAllFields: String by StringResource(
        StringLocale.PT_BR to "Limpar todos os campos"
    )
    val formTitle: String by StringResource(
        StringLocale.PT_BR to "Título do formulário"
    )
    val expirationDate: String by StringResource(
        StringLocale.PT_BR to "Data de expiração"
    )
    val sendCoordinates: String by StringResource(
        StringLocale.PT_BR to "Enviar coordenadas"
    )
    val hour: String by StringResource(
        StringLocale.PT_BR to "Hora"
    )
    val minute: String by StringResource(
        StringLocale.PT_BR to "Minutos"
    )
    val clear: String by StringResource(
        StringLocale.PT_BR to "Limpar"
    )
    val select: String by StringResource(
        StringLocale.PT_BR to "Selecionar"
    )
    val cancel: String by StringResource(
        StringLocale.PT_BR to "Cancelar"
    )
    val minDateTime: String by StringResource(
        StringLocale.PT_BR to "Data e hora mínima"
    )
    val maxDateTime: String by StringResource(
        StringLocale.PT_BR to "Data e hora máxima"
    )
    val minTime: String by StringResource(
        StringLocale.PT_BR to "Hora mínima"
    )
    val maxTime: String by StringResource(
        StringLocale.PT_BR to "Hora máxima"
    )
    val minDate: String by StringResource(
        StringLocale.PT_BR to "Data mínima"
    )
    val maxDate: String by StringResource(
        StringLocale.PT_BR to "Data máxima"
    )
    val fieldTypeTip: String by StringResource(
        StringLocale.PT_BR to "Indica qual tipo de teclado será exibido no app"
    )
    val fieldType: String by StringResource(
        StringLocale.PT_BR to "Tipo do campo"
    )
    val characterToBeReplaced: String by StringResource(
        StringLocale.PT_BR to "Caracter a ser substituído"
    )
    val mask: String by StringResource(
        StringLocale.PT_BR to "Máscara"
    )
    val maskExample: String by StringResource(
        StringLocale.PT_BR to "Por exemplo, CPF: ###.###.###-##"
    )
    val symbolPosition: String by StringResource(
        StringLocale.PT_BR to "Posição do símbolo"
    )
    val symbol: String by StringResource(
        StringLocale.PT_BR to "Símbolo"
    )
    val decimalPoints: String by StringResource(
        StringLocale.PT_BR to "Casas decimais"
    )
    val minValue: String by StringResource(
        StringLocale.PT_BR to "Valor mínimo"
    )
    val maxValue: String by StringResource(
        StringLocale.PT_BR to "Valor máximo"
    )
    val minSelection: String by StringResource(
        StringLocale.PT_BR to "Seleção mínima"
    )
    val maxSelection: String by StringResource(
        StringLocale.PT_BR to "Seleção máxima"
    )
    val maxOptions: String by StringResource(
        StringLocale.PT_BR to "Máximo de opções"
    )
    val maxOptionTextLength: String by StringResource(
        StringLocale.PT_BR to "Tamanho máximo do texto"
    )
    val questions: String by StringResource(
        StringLocale.PT_BR to "Questões"
    )
    val question: String by StringResource(
        StringLocale.PT_BR to "Questão"
    )
    val addQuestion: String by StringResource(
        StringLocale.PT_BR to "Adicionar questão"
    )
    val removeQuestion: String by StringResource(
        StringLocale.PT_BR to "Remover questão"
    )
    val moveQuestionUp: String by StringResource(
        StringLocale.PT_BR to "Mover questão para cima"
    )
    val moveQuestionDown: String by StringResource(
        StringLocale.PT_BR to "Mover questão para baixo"
    )
    val addOption: String by StringResource(
        StringLocale.PT_BR to "Adicionar opção"
    )
    val removeOption: String by StringResource(
        StringLocale.PT_BR to "Remover opção"
    )
    val moveOptionUp: String by StringResource(
        StringLocale.PT_BR to "Mover opção para cima"
    )
    val moveOptionDown: String by StringResource(
        StringLocale.PT_BR to "Mover opção para baixo"
    )
    val style: String by StringResource(
        StringLocale.PT_BR to "Estilo"
    )
    val tip: String by StringResource(
        StringLocale.PT_BR to "Dica de texto"
    )
    val textMaxLength: String by StringResource(
        StringLocale.PT_BR to "Tamanho máximo do texto"
    )
    val textToBeDisplayed: String by StringResource(
        StringLocale.PT_BR to "Texto a ser exibido (ex: Outro)"
    )
    val displayOthersOption: String by StringResource(
        StringLocale.PT_BR to "Exibir opção \"Outros\""
    )
    val options: String by StringResource(
        StringLocale.PT_BR to "Opções"
    )
    val option: String by StringResource(
        StringLocale.PT_BR to "Opção"
    )
    val displaySearchBar: String by StringResource(
        StringLocale.PT_BR to "Exibir barra de busca"
    )
    val maxLength: String by StringResource(
        StringLocale.PT_BR to "Tamanho máximo"
    )
    val title: String by StringResource(
        StringLocale.PT_BR to "Título"
    )
    val subtitle: String by StringResource(
        StringLocale.PT_BR to "Subtítulo"
    )
    val identifier by StringResource(
        StringLocale.PT_BR to "Identificador"
    )
    val obligatoryField by StringResource(
        StringLocale.PT_BR to "Campo obrigatório"
    )
    val identifierTooltip by StringResource(
        StringLocale.PT_BR to "Este campo define o nome da coluna a ser gerada no arquivo csv/xlsx. Deve ser único."
    )
}