package com.catbit.opinionpoll.inputs.events

import com.catbit.opinionpoll.inputs.inputs.NumericAreaInputUIState

sealed interface NumericAreaInputEventData {

    data class OnMinValueChange(
        val newMinValue: Long
    ) : NumericAreaInputEventData

    data class OnMaxValueChange(
        val newMaxValue: Long
    ) : NumericAreaInputEventData

    data class OnSymbolChange(
        val newSymbol: String
    ) : NumericAreaInputEventData

    data class OnSymbolPositionChange(
        val newSymbolPosition: NumericAreaInputUIState.SymbolPosition
    ) : NumericAreaInputEventData

    data class OnDecimalPointsChange(
        val newDecimalPoints: Int
    ) : NumericAreaInputEventData
}