package com.catbit.opinionpoll.data.models

import kotlinx.datetime.LocalDateTime

data class EnvironmentInfoModel(
    val environmentId: String,
    val environmentName: String,
    val environmentPlan: String,
    val planExpirationDate: LocalDateTime?,
    val environmentImage: String?
)
