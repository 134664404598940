package com.catbit.opinionpoll.inputs

interface InputUIState {

    // TODO Campos com aparição conficional

    val index: Int
    val isEnabled: Boolean
    val actualIdentifier: String
    val identifier: String
    val title: String
    val subtitle: String
    val isObligatory: Boolean
    val type: InputType
    val errorMessage: String?

    val internalIdentifiers: List<String>
    fun knowsIdentifier(identifier: String): Boolean
    fun updateIndex(newIndex: Int): InputUIState
    fun copy(
        index: Int? = null,
        isEnabled: Boolean? = null,
        actualIdentifier: String? = null,
        identifier: String? = null,
        title: String? = null,
        subtitle: String? = null,
        isObligatory: Boolean? = null,
        type: InputType? = null,
        error: String? = null
    ): InputUIState
}