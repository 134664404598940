package com.catbit.opinionpoll.inputs.events_handler

import com.catbit.opinionpoll.core.extensions.swap
import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.events.InputUIEventDefaultData
import com.catbit.opinionpoll.inputs.events.MultipleChoicesInputEventData.*
import com.catbit.opinionpoll.inputs.inputs.MultipleChoicesInputUIState
import com.catbit.opinionpoll.inputs.inputs.OptionInput

class MultipleChoicesInputUIEventHandler : InputUIEventHandler<MultipleChoicesInputUIState> {

    override fun match(formIdentifier: String, uiState: InputUIState) =
        uiState is MultipleChoicesInputUIState && formIdentifier == uiState.actualIdentifier

    override fun handle(uiState: MultipleChoicesInputUIState, eventData: Any) = when (eventData) {
        is OnAddOption -> uiState.copy(
            options = uiState.options.toMutableList().apply {
                add(
                    OptionInput(
                        actualIdentifier = UUID.stringUUID(),
                        title = ""
                    )
                )
            }
        )

        is OnOptionTitleChange -> {
            uiState.copy(
                options = uiState.options.map { option ->
                    if (option.actualIdentifier == eventData.actualIdentifier) {
                        option.copy(title = eventData.newTitle)
                    } else option
                }
            )
        }

        is OnOptionRemove -> {
            uiState.copy(
                options = uiState.options.filter { option ->
                    option.actualIdentifier != eventData.actualIdentifier
                }
            )
        }

        is OnToggleHasOtherOption -> {
            uiState.copy(hasOtherOption = eventData.hasOtherOption)
        }

        is OnMinSelectionChange -> {
            uiState.copy(minSelection = eventData.minSelection)
        }

        is OnMaxSelectionChange -> {
            uiState.copy(maxSelection = eventData.maxSelection)
        }

        is OnMaxOtherOptionsChange -> {
            uiState.copy(maxOtherOptions = eventData.maxOtherOptions)
        }

        is OnOtherPlaceholderTextTitleChange -> {
            uiState.copy(otherOptionPlaceholderText = eventData.newPlaceholder)
        }

        is OnOtherOptionTextLengthChange -> {
            uiState.copy(maxOtherOptionTextLength = eventData.newLength)
        }

        is OnAddBatchOptions -> {
            uiState.copy(
                options = uiState.options + eventData.rawText
                    .trim()
                    .replace(";", "\n")
                    .split("\n")
                    .map {
                        OptionInput(
                            actualIdentifier = UUID.stringUUID(),
                            title = it
                        )
                    }
            )
        }

        is OnMoveOptionUp -> {
            with(uiState) {
                if (options.size == 1) return uiState
                val currentIndex = options.indexOfFirst { it.actualIdentifier == eventData.actualIdentifier }
                if ((currentIndex - 1) == -1) return uiState

                val newOptions = options.toMutableList().apply {
                    swap(currentIndex, currentIndex - 1)
                }

                copy(options = newOptions)
            }
        }

        is OnMoveOptionDown -> {
            with(uiState) {
                if (options.size == 1) return uiState
                val currentIndex = options.indexOfFirst { it.actualIdentifier == eventData.actualIdentifier }
                if (currentIndex == options.lastIndex) return uiState

                val newOptions = options.toMutableList().apply {
                    swap(currentIndex, currentIndex + 1)
                }

                copy(options = newOptions)
            }
        }

        is InputUIEventDefaultData.IdentifierChange -> uiState.copy(identifier = eventData.newIdentifier)
        is InputUIEventDefaultData.TitleChange -> uiState.copy(title = eventData.newTitle)
        is InputUIEventDefaultData.SubtitleChange -> uiState.copy(subtitle = eventData.newSubtitle)
        is InputUIEventDefaultData.ObligatoryToggle -> uiState.copy(isObligatory = eventData.isObligatory)
        else -> uiState
    }
}