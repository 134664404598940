package com.catbit.opinionpoll.core.events

import androidx.compose.runtime.Composable
import kotlinx.browser.window

object WindowOnResizeEventHandler {

    private var eventObservers = mutableListOf<() -> Unit>()
    fun addOnWindowResizeListener(observer:() -> Unit) {
        eventObservers.add(observer)
    }

    fun removeOnWindowResizeListener(observer: () -> Unit) {
        eventObservers.remove(observer)
    }

    init {
        window.onresize = {
            eventObservers.forEach { it() }
        }
    }
}