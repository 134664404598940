package com.catbit.opinionpoll

import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.LaunchedEffect
import com.catbit.opinionpoll.core.events.WindowOnResizeEventHandler
import com.catbit.opinionpoll.core.ui.composables.effects.SingleEffect
import com.catbit.opinionpoll.core.ui.helpers.setMobileMode
import com.catbit.opinionpoll.core.unit.dvh
import com.catbit.opinionpoll.di.AppModules
import com.catbit.opinionpoll.inputs.providers.LocalInputComposerProvider
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.App
import com.varabyte.kobweb.silk.SilkApp
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.style.common.SmoothColorStyle
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.jetbrains.compose.web.css.vw
import org.koin.compose.KoinApplication
import org.koin.compose.getKoin
import org.w3c.dom.get

private const val COLOR_MODE_KEY = "opinionpoll:colorMode"

@InitSilk
fun initColorMode(ctx: InitSilkContext) {
    val theme = localStorage["OPTheme"]

    ctx.config.initialColorMode = theme?.theme() ?: if (window.matchMedia("(prefers-color-scheme: dark)").matches)
        ColorMode.DARK
    else
        ColorMode.LIGHT
}

private fun String?.theme() = if (this == "dark") ColorMode.DARK else ColorMode.LIGHT

fun toggleColorMode(colorMode: ColorMode) {
    localStorage.setItem("OPTheme", if (colorMode.isDark) "dark" else "light")
}

@App
@Composable
fun AppEntry(content: @Composable () -> Unit) {
    KoinApplication(
        application = {
            modules(AppModules.modules)
        }
    ) {

        SingleEffect {
            WindowOnResizeEventHandler.addOnWindowResizeListener {
                setMobileMode((document.documentElement?.clientWidth ?: 0) < 600)
            }
        }

        CompositionLocalProvider(
            LocalInputComposerProvider provides getKoin().get()
        ) {
            SilkApp {
                val colorMode = ColorMode.current
                LaunchedEffect(colorMode) {
                    localStorage.setItem(COLOR_MODE_KEY, colorMode.name)
                }
                Surface(
                    SmoothColorStyle
                        .toModifier()
                        .id("surface")
                        .height(100.dvh)
                        .width(100.vw)
                ) {
                    content()
                }
            }
        }
    }
}