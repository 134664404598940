package com.catbit.opinionpoll.inputs

import com.catbit.opinionpoll.inputs.events.InputUIEvent
import com.catbit.opinionpoll.inputs.events_handler.InputUIEventHandler

class InputUpdater(
    private val inputUIEventHandlers: List<InputUIEventHandler<*>>
) {
    fun update(
        uiEvent: InputUIEvent,
        uiState: InputUIState
    ) = inputUIEventHandlers
        .firstOrNull {
            it.match(
                uiState = uiState,
                formIdentifier = uiEvent.formIdentifier
            )
        }?.handle(
            uiState = uiState,
            eventData = uiEvent.data
        ) ?: uiState

}