package com.catbit.opinionpoll.ui.screens.forms

import com.catbit.opinionpoll.core.domain.invoke
import com.catbit.opinionpoll.core.extensions.updateAs
import com.catbit.opinionpoll.core.extensions.like
import com.catbit.opinionpoll.domain.forms.CopyFormUseCase
import com.catbit.opinionpoll.domain.forms.DeleteFormsUseCase
import com.catbit.opinionpoll.domain.forms.GetSimplifiedFormsUseCase
import com.catbit.opinionpoll.ui.base.ScreenStateHolder
import com.catbit.opinionpoll.ui.screens.forms.FormsUIContract.*
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch

class FormsStateHolder(
    private val getSimplifiedFormsUseCase: GetSimplifiedFormsUseCase,
    private val deleteFormsUseCase: DeleteFormsUseCase,
    private val copyFormUseCase: CopyFormUseCase
) : ScreenStateHolder<State, Event, Effect>() {

    override val internalUIState = MutableStateFlow<State>(State.Loading)

    override fun onStarted() {
        getForms()
    }

    override fun onRecovered() {
        getForms()
    }

    private fun getForms() {

        internalUIState.update {
            State.Loading
        }

        stateHolderScope.launch {
            getSimplifiedFormsUseCase()
                .onSuccess { forms ->
                    internalUIState.update {
                        State.Displaying(forms.map { it.toFormUIState() })
                    }
                }
                .onFailure { failure ->
                    internalUIState.update {
                        State.Failure(failure)
                    }
                }
        }
    }

    override fun onEvent(event: Event) {
        when (event) {
            Event.OnMenuDeleteFormClick -> onMenuDeleteFormClick()
            is Event.OnFormSelectedForDeletion -> onFormSelectForDeletion(event)
            Event.OnDeleteFormClick -> onDeleteFormsClick()
            Event.OnCancelDeletingForms -> onCancelDeletingForms()
            Event.OnConfirmFormsDeletion -> onConfirmFormsDeletion()
            Event.OnSelectAllFormsForDeletion -> onSelectAllFormsForDeletion()
            Event.OnRefreshForms -> getForms()
            is Event.OnFilterChange -> onFilterChange(event.newFilter)
            is Event.OnSearchQueryChange -> onSearchQueryChange(event.newQuery)
            Event.OnClearSearchQuery -> onClearSearchQuery()
            is Event.OnCopyFormClick -> onCopyFormClick(event.formId, event.newTitle)
        }
    }

    private fun onCopyFormClick(
        formId: String,
        newTitle: String
    ) {
        internalEffects.dispatch(Effect.OnStartFormCopy)
        stateHolderScope.launch {
            copyFormUseCase(
                CopyFormUseCase.Params(
                    newTitle = newTitle,
                    formIdentifier = formId
                )
            )
                .onSuccess {
                    internalEffects.dispatch(Effect.OnFormCopySuccess)
                    getForms()
                }
                .onFailure {
                    internalEffects.dispatch(Effect.OnFormCopyFailure)
                }
        }
    }

    private fun onClearSearchQuery() {
        internalUIState.updateAs<State.Displaying> {
            copy(searchQuery = "")
        }
    }

    private fun onSearchQueryChange(
        newQuery: String
    ) {
        internalUIState.updateAs<State.Displaying> {
            copy(searchQuery = newQuery)
        }
    }

    private fun onFilterChange(newFilter: String) {
        internalUIState.updateAs<State.Displaying> {
            copy(searchSorting = newFilter)
        }
    }

    private fun onDeleteFormsClick() {
        internalEffects.dispatch(Effect.OnConfirmFormsDeletion)
    }

    private fun onSelectAllFormsForDeletion() {
        internalUIState.updateAs<State.Displaying> {
            val allSelected = forms.all { it.isSelectedForDeletion }
            val newForms = forms.map { form ->
                form.copy(isSelectedForDeletion = if (allSelected) false else true)
            }
            copy(
                forms = newForms,
                enableDeleteFormsButton = newForms.any { it.isSelectedForDeletion }
            )
        }
    }

    private fun onCancelDeletingForms() {
        internalUIState.updateAs<State.Displaying> {
            copy(
                isOnFormDeletionMode = false,
                enableDeleteFormsButton = false,
                selectedMenu = "none",
                forms = forms.map { form ->
                    form.copy(isSelectedForDeletion = false)
                }
            )
        }
    }

    private fun onConfirmFormsDeletion() {

        internalEffects.dispatch(Effect.OnStartFormsDeletion)

        stateHolderScope.launch {
            val formsIdentifiers = internalUIState.like<State.Displaying>().forms
                .filter { it.isSelectedForDeletion }
                .map { it.identifier }

            deleteFormsUseCase(DeleteFormsUseCase.Params(formsIdentifiers))
                .onSuccess {
                    internalEffects.dispatch(Effect.OnFormsDeletionSuccess)
                }
                .onFailure {
                    internalEffects.dispatch(Effect.OnFormsDeletionFailure)
                }
        }
    }

    private fun onFormSelectForDeletion(event: Event.OnFormSelectedForDeletion) {
        internalUIState.updateAs<State.Displaying> {
            val newForms = forms.map { form ->
                if (form.identifier == event.formId) {
                    form.copy(isSelectedForDeletion = !form.isSelectedForDeletion)
                } else form
            }

            copy(
                forms = newForms,
                enableDeleteFormsButton = newForms.any { it.isSelectedForDeletion }
            )
        }
    }

    private fun onMenuDeleteFormClick() {
        internalUIState.updateAs<State.Displaying> {
            copy(
                isOnFormDeletionMode = true,
                selectedMenu = "delete_forms",
                forms = forms.map { form ->
                    form.copy(isSelectedForDeletion = false)
                }
            )
        }
    }
}