package com.catbit.opinionpoll.inputs.events_handler

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.events.EvaluationInputEventData
import com.catbit.opinionpoll.inputs.events.InputUIEventDefaultData
import com.catbit.opinionpoll.inputs.inputs.EvaluationInputUIState

class EvaluationInputUIEventHandler : InputUIEventHandler<EvaluationInputUIState> {

    override fun match(formIdentifier: String, uiState: InputUIState) =
        uiState is EvaluationInputUIState && formIdentifier == uiState.actualIdentifier

    override fun handle(uiState: EvaluationInputUIState, eventData: Any) = when (eventData) {
        is EvaluationInputEventData.OnMinValueChange -> uiState.copy(minValue = eventData.newMinValue)
        is EvaluationInputEventData.OnMaxValueChange -> uiState.copy(maxValue = eventData.newMaxValue)
        is InputUIEventDefaultData.IdentifierChange -> uiState.copy(identifier = eventData.newIdentifier)
        is InputUIEventDefaultData.TitleChange -> uiState.copy(title = eventData.newTitle)
        is InputUIEventDefaultData.SubtitleChange -> uiState.copy(subtitle = eventData.newSubtitle)
        is InputUIEventDefaultData.ObligatoryToggle -> uiState.copy(isObligatory = eventData.isObligatory)
        else -> uiState
    }
}