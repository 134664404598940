package com.catbit.opinionpoll.core.extensions

import androidx.compose.runtime.Composable
import com.catbit.opinionpoll.toSitePalette
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.silk.theme.colors.ColorMode

fun Color.changeAlpha(newAlpha: Float): Color {
    require(newAlpha in 0f..1f) {
        "Alpha must be in [0f, 1f]"
    }

    return toRgb().copyf(alpha = newAlpha)
}

@Composable
fun sitePalette() = ColorMode.current.toSitePalette()