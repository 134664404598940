package com.catbit.opinionpoll.inputs.requests.producers

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.inputs.TimeInputUIState
import com.catbit.opinionpoll.inputs.requests.requests.TimeInputRequest

class TimeInputRequestProducer : InputRequestProducer<TimeInputUIState> {
    override fun match(inputUIState: InputUIState) = inputUIState is TimeInputUIState

    override fun produce(inputUIState: TimeInputUIState) = TimeInputRequest(
        identifier = inputUIState.identifier,
        index = inputUIState.index,
        title = inputUIState.title,
        subtitle = inputUIState.subtitle,
        isObligatory = inputUIState.isObligatory,
        minTime = inputUIState.minTime?.toString(),
        maxTime = inputUIState.maxTime?.toString()
    )
}