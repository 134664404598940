package com.catbit.opinionpoll.inputs.graph

import com.catbit.opinionpoll.inputs.models.EvaluationInputModel
import com.catbit.opinionpoll.inputs.models.InputModel
import com.catbit.opinionpoll.inputs.state.InputGraphUIState

class EvaluationInputGraphHandler : InputGraphDataHandler<EvaluationInputModel> {
    override fun handles(
        model: InputModel,
    ) = model is EvaluationInputModel

    override fun handle(
        identifier: String,
        model: EvaluationInputModel,
        rawData: List<String>
    ) = with(model) {

        InputGraphUIState(
            identifier = identifier,
            title = "$index.  $title",
            chartType = InputGraphUIState.CharType.Pizza,
            data = rawData
                .filterNot { it.isBlank() || it.isEmpty() }
                .groupingBy { it }
                .eachCount()
                .map { (label, value) ->
                    InputGraphUIState.ChartData(label, value)
                }
        )
    }
}