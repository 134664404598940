package com.catbit.opinionpoll.pages

import com.catbit.opinionpoll.ui.screens.support.SupportUIStateContract

interface IndexContracts {
    sealed interface State {
        data class Displaying(
            val email: String,
            val fullName: String,
            val password: String,
            val phoneNumberBuffer: String,
            val phoneNumber: String,
            val companyName: String,
            val showPassword: Boolean,
            val sending: Boolean,
            val selectedTopic: String = "Suporte",
            val topics: List<String> = listOf(
                "Suporte",
                "Dúvidas",
                "Sugestão",
                "Demais assuntos"
            ),
            val subject: String = "",
            val subjectLengthCounter: String = "0/100",
            val message: String = "",
            val messageLengthCounter: String = "0/2000",
            val isSubmitting: Boolean = false,
            val supportName: String,
            val supportEmail: String,
        ) : State {
            companion object {
                fun default() = Displaying(
                    email = "",
                    fullName = "",
                    password = "",
                    phoneNumberBuffer = "",
                    phoneNumber = "",
                    companyName = "",
                    showPassword = false,
                    sending = false,
                    supportName = "",
                    supportEmail = ""
                )
            }
        }
    }

    sealed interface Event {
        data class OnEmailChange(val newEmail: String) : Event
        data class OnFullNameChange(val newFullName: String) : Event
        data class OnPasswordChange(val newPassword: String) : Event
        data class OnPhoneNumberChange(val newPhoneNumber: String) : Event
        data class OnCompanyNameChange(val newCompanyName: String) : Event
        data object OnTogglePasswordVisibility : Event
        data object OnTryFreeTrialClick : Event
        data class OnSelectTopic(val topic: String) : Event
        data class OnSupportNameChange(val name: String) : Event
        data class OnSupportEmailChange(val email: String) : Event
        data class OnSubjectChange(val subject: String) : Event
        data class OnMessageChange(val message: String) : Event
        data object OnSubmitClick : Event
    }

    sealed interface Effect {
        data object OnRequestFreeTrialSuccess : Effect
        data object OnMessageSentSuccess : Effect
        data object OnMessageSentFailure : Effect

        data class OnRequestFreeTrialFailure(val message: String) : Effect
        data class OnInvalidField(val message: String) : Effect
    }
}