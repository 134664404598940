package com.catbit.opinionpoll.domain.form_dashboard

import com.catbit.opinionpoll.core.domain.UseCase
import com.catbit.opinionpoll.data.models.FormAnswerModel
import com.catbit.opinionpoll.data.repositories.OpinionPollRepository

class UpdateAnswersUseCase(
    val repository: OpinionPollRepository
) : UseCase<Unit, UpdateAnswersUseCase.Params>() {

    override suspend fun execute(params: Params) = repository.updateAnswers(
        formIdentifier = params.formIdentifier,
        answersToAdd = params.answersToAdd,
        answersToUpdate = params.answersToUpdate,
        answersToDelete = params.answersToDelete,
    )

    data class Params(
        val formIdentifier: String,
        val answersToAdd: List<FormAnswerModel>,
        val answersToUpdate: List<Pair<String, Map<String, String>>>,
        val answersToDelete: List<String>
    )
}