package com.catbit.opinionpoll.core.ui.animations

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.components.animation.toAnimation
import org.jetbrains.compose.web.css.AnimationTimingFunction
import org.jetbrains.compose.web.css.ms

class FadeOutAnimation : AnimationHolder {
    @Composable
    override fun toAnimation() = FadeOutKeyFrames.toAnimation(
        duration = 250.ms,
        timingFunction = AnimationTimingFunction.EaseOut,
    )
}

val FadeOutKeyFrames by Keyframes {
    from { Modifier.opacity(1f) }
    to { Modifier.opacity(0f) }
}