package com.catbit.opinionpoll.domain.home

import com.catbit.opinionpoll.core.domain.UseCase
import com.catbit.opinionpoll.data.models.HomeModel
import com.catbit.opinionpoll.data.repositories.OpinionPollRepository

class GetHomeUseCase(
    private val repository: OpinionPollRepository,
) : UseCase<HomeModel, Unit>() {

    override suspend fun execute(params: Unit) = repository.getHome()
}