package com.catbit.opinionpoll.data.data_sources.networking.requests

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UserCreateRequest(
    @SerialName("phoneNumber")
    val phoneNumber: String,
    @SerialName("role")
    val role: String,
    @SerialName("displayName")
    val displayName: String,
    @SerialName("email")
    val email: String,
    @SerialName("password")
    val password: String
)
