package com.catbit.opinionpoll.core.ui.composables

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.extensions.changeAlpha
import com.catbit.opinionpoll.core.ui.composables.base_components.Text
import com.catbit.opinionpoll.core.ui.icons.MaterialIcon
import com.catbit.opinionpoll.core.ui.modifiers.labelMedium
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import org.jetbrains.compose.web.css.px

@Composable
fun NavRailButton(
    modifier: Modifier = Modifier,
    icon: MaterialIcon,
    label: String,
    selected: Boolean,
    onClick: () -> Unit
) {

    val selectedBackgroundColor = sitePalette().secondaryContainer
    val selectedTextColor = sitePalette().onSurface
    val selectedIconColor = sitePalette().onSecondaryContainer

    val unselectedBackgroundColor = sitePalette().surface
    val unselectedTextColor = sitePalette().onSurfaceVariant
    val unselectedIconColor = sitePalette().onSurfaceVariant

    val hoveredBackgroundColor = sitePalette().secondaryContainer.changeAlpha(0.8f)
    val hoveredTextColor = sitePalette().onSurface
    val hoveredIconColor = sitePalette().onSecondaryContainer

    var backgroundColor by remember(selected) {
        mutableStateOf(
            if (selected) selectedBackgroundColor else unselectedBackgroundColor
        )
    }

    var textColor by remember(selected) {
        mutableStateOf(
            if (selected) selectedTextColor else unselectedTextColor
        )
    }

    var iconColor by remember(selected) {
        mutableStateOf(
            if (selected) selectedIconColor else unselectedIconColor
        )
    }

    SideEffect {
        backgroundColor = if (selected) selectedBackgroundColor else unselectedBackgroundColor
        textColor = if (selected) selectedTextColor else unselectedTextColor
        iconColor = if (selected) selectedIconColor else unselectedIconColor
    }

    Column(
        modifier = modifier
            .cursor(Cursor.Pointer)
            .onMouseEnter {
                if (!selected) {
                    backgroundColor = hoveredBackgroundColor
                    textColor = hoveredTextColor
                    iconColor = hoveredIconColor
                }
            }
            .onMouseLeave {
                backgroundColor = if (selected) selectedBackgroundColor else unselectedBackgroundColor
                textColor = if (selected) selectedTextColor else unselectedTextColor
                iconColor = if (selected) selectedIconColor else unselectedIconColor
            }
            .onClick { onClick() },
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Box(
            modifier = Modifier
                .height(32.px)
                .width(56.px)
                .backgroundColor(backgroundColor)
                .clip(Rect(16.px)),
            contentAlignment = Alignment.Center
        ) {
            MaterialIcon(
                icon = icon,
                tint = iconColor
            )
        }
        Text(
            text = label,
            modifier = Modifier
                .color(textColor)
                .labelMedium()
                .margin(top = 4.px)
        )
    }
}