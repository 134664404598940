package com.catbit.opinionpoll.core.ui.icons

object MaterialIcons {
    object Normal {
        val _10k = MaterialIcon(
            name = "10k",
            style = MaterialIcon.Style.Normal
        )
        val _10mp = MaterialIcon(
            name = "10mp",
            style = MaterialIcon.Style.Normal
        )
        val _11mp = MaterialIcon(
            name = "11mp",
            style = MaterialIcon.Style.Normal
        )
        val _123 = MaterialIcon(
            name = "123",
            style = MaterialIcon.Style.Normal
        )
        val _12mp = MaterialIcon(
            name = "12mp",
            style = MaterialIcon.Style.Normal
        )
        val _13mp = MaterialIcon(
            name = "13mp",
            style = MaterialIcon.Style.Normal
        )
        val _14mp = MaterialIcon(
            name = "14mp",
            style = MaterialIcon.Style.Normal
        )
        val _15mp = MaterialIcon(
            name = "15mp",
            style = MaterialIcon.Style.Normal
        )
        val _16mp = MaterialIcon(
            name = "16mp",
            style = MaterialIcon.Style.Normal
        )
        val _17mp = MaterialIcon(
            name = "17mp",
            style = MaterialIcon.Style.Normal
        )
        val _18UpRating = MaterialIcon(
            name = "18_up_rating",
            style = MaterialIcon.Style.Normal
        )
        val _18mp = MaterialIcon(
            name = "18mp",
            style = MaterialIcon.Style.Normal
        )
        val _19mp = MaterialIcon(
            name = "19mp",
            style = MaterialIcon.Style.Normal
        )
        val _1k = MaterialIcon(
            name = "1k",
            style = MaterialIcon.Style.Normal
        )
        val _1kPlus = MaterialIcon(
            name = "1k_plus",
            style = MaterialIcon.Style.Normal
        )
        val _1xMobiledata = MaterialIcon(
            name = "1x_mobiledata",
            style = MaterialIcon.Style.Normal
        )
        val _20mp = MaterialIcon(
            name = "20mp",
            style = MaterialIcon.Style.Normal
        )
        val _21mp = MaterialIcon(
            name = "21mp",
            style = MaterialIcon.Style.Normal
        )
        val _22mp = MaterialIcon(
            name = "22mp",
            style = MaterialIcon.Style.Normal
        )
        val _23mp = MaterialIcon(
            name = "23mp",
            style = MaterialIcon.Style.Normal
        )
        val _24mp = MaterialIcon(
            name = "24mp",
            style = MaterialIcon.Style.Normal
        )
        val _2k = MaterialIcon(
            name = "2k",
            style = MaterialIcon.Style.Normal
        )
        val _2kPlus = MaterialIcon(
            name = "2k_plus",
            style = MaterialIcon.Style.Normal
        )
        val _2mp = MaterialIcon(
            name = "2mp",
            style = MaterialIcon.Style.Normal
        )
        val _30fps = MaterialIcon(
            name = "30fps",
            style = MaterialIcon.Style.Normal
        )
        val _30fpsSelect = MaterialIcon(
            name = "30fps_select",
            style = MaterialIcon.Style.Normal
        )
        val _360 = MaterialIcon(
            name = "360",
            style = MaterialIcon.Style.Normal
        )
        val _3dRotation = MaterialIcon(
            name = "3d_rotation",
            style = MaterialIcon.Style.Normal
        )
        val _3gMobiledata = MaterialIcon(
            name = "3g_mobiledata",
            style = MaterialIcon.Style.Normal
        )
        val _3k = MaterialIcon(
            name = "3k",
            style = MaterialIcon.Style.Normal
        )
        val _3kPlus = MaterialIcon(
            name = "3k_plus",
            style = MaterialIcon.Style.Normal
        )
        val _3mp = MaterialIcon(
            name = "3mp",
            style = MaterialIcon.Style.Normal
        )
        val _3p = MaterialIcon(
            name = "3p",
            style = MaterialIcon.Style.Normal
        )
        val _4gMobiledata = MaterialIcon(
            name = "4g_mobiledata",
            style = MaterialIcon.Style.Normal
        )
        val _4gPlusMobiledata = MaterialIcon(
            name = "4g_plus_mobiledata",
            style = MaterialIcon.Style.Normal
        )
        val _4k = MaterialIcon(
            name = "4k",
            style = MaterialIcon.Style.Normal
        )
        val _4kPlus = MaterialIcon(
            name = "4k_plus",
            style = MaterialIcon.Style.Normal
        )
        val _4mp = MaterialIcon(
            name = "4mp",
            style = MaterialIcon.Style.Normal
        )
        val _5g = MaterialIcon(
            name = "5g",
            style = MaterialIcon.Style.Normal
        )
        val _5k = MaterialIcon(
            name = "5k",
            style = MaterialIcon.Style.Normal
        )
        val _5kPlus = MaterialIcon(
            name = "5k_plus",
            style = MaterialIcon.Style.Normal
        )
        val _5mp = MaterialIcon(
            name = "5mp",
            style = MaterialIcon.Style.Normal
        )
        val _60fps = MaterialIcon(
            name = "60fps",
            style = MaterialIcon.Style.Normal
        )
        val _60fpsSelect = MaterialIcon(
            name = "60fps_select",
            style = MaterialIcon.Style.Normal
        )
        val _6FtApart = MaterialIcon(
            name = "6_ft_apart",
            style = MaterialIcon.Style.Normal
        )
        val _6k = MaterialIcon(
            name = "6k",
            style = MaterialIcon.Style.Normal
        )
        val _6kPlus = MaterialIcon(
            name = "6k_plus",
            style = MaterialIcon.Style.Normal
        )
        val _6mp = MaterialIcon(
            name = "6mp",
            style = MaterialIcon.Style.Normal
        )
        val _7k = MaterialIcon(
            name = "7k",
            style = MaterialIcon.Style.Normal
        )
        val _7kPlus = MaterialIcon(
            name = "7k_plus",
            style = MaterialIcon.Style.Normal
        )
        val _7mp = MaterialIcon(
            name = "7mp",
            style = MaterialIcon.Style.Normal
        )
        val _8k = MaterialIcon(
            name = "8k",
            style = MaterialIcon.Style.Normal
        )
        val _8kPlus = MaterialIcon(
            name = "8k_plus",
            style = MaterialIcon.Style.Normal
        )
        val _8mp = MaterialIcon(
            name = "8mp",
            style = MaterialIcon.Style.Normal
        )
        val _9k = MaterialIcon(
            name = "9k",
            style = MaterialIcon.Style.Normal
        )
        val _9kPlus = MaterialIcon(
            name = "9k_plus",
            style = MaterialIcon.Style.Normal
        )
        val _9mp = MaterialIcon(
            name = "9mp",
            style = MaterialIcon.Style.Normal
        )
        val Abc = MaterialIcon(
            name = "abc",
            style = MaterialIcon.Style.Normal
        )
        val AcUnit = MaterialIcon(
            name = "ac_unit",
            style = MaterialIcon.Style.Normal
        )
        val AccessAlarm = MaterialIcon(
            name = "access_alarm",
            style = MaterialIcon.Style.Normal
        )
        val AccessAlarms = MaterialIcon(
            name = "access_alarms",
            style = MaterialIcon.Style.Normal
        )
        val AccessTime = MaterialIcon(
            name = "access_time",
            style = MaterialIcon.Style.Normal
        )
        val AccessTimeFilled = MaterialIcon(
            name = "access_time_filled",
            style = MaterialIcon.Style.Normal
        )
        val Accessibility = MaterialIcon(
            name = "accessibility",
            style = MaterialIcon.Style.Normal
        )
        val AccessibilityNew = MaterialIcon(
            name = "accessibility_new",
            style = MaterialIcon.Style.Normal
        )
        val Accessible = MaterialIcon(
            name = "accessible",
            style = MaterialIcon.Style.Normal
        )
        val AccessibleForward = MaterialIcon(
            name = "accessible_forward",
            style = MaterialIcon.Style.Normal
        )
        val AccountBalance = MaterialIcon(
            name = "account_balance",
            style = MaterialIcon.Style.Normal
        )
        val AccountBalanceWallet = MaterialIcon(
            name = "account_balance_wallet",
            style = MaterialIcon.Style.Normal
        )
        val AccountBox = MaterialIcon(
            name = "account_box",
            style = MaterialIcon.Style.Normal
        )
        val AccountCircle = MaterialIcon(
            name = "account_circle",
            style = MaterialIcon.Style.Normal
        )
        val AccountTree = MaterialIcon(
            name = "account_tree",
            style = MaterialIcon.Style.Normal
        )
        val AdUnits = MaterialIcon(
            name = "ad_units",
            style = MaterialIcon.Style.Normal
        )
        val Adb = MaterialIcon(
            name = "adb",
            style = MaterialIcon.Style.Normal
        )
        val Add = MaterialIcon(
            name = "add",
            style = MaterialIcon.Style.Normal
        )
        val AddAPhoto = MaterialIcon(
            name = "add_a_photo",
            style = MaterialIcon.Style.Normal
        )
        val AddAlarm = MaterialIcon(
            name = "add_alarm",
            style = MaterialIcon.Style.Normal
        )
        val AddAlert = MaterialIcon(
            name = "add_alert",
            style = MaterialIcon.Style.Normal
        )
        val AddBox = MaterialIcon(
            name = "add_box",
            style = MaterialIcon.Style.Normal
        )
        val AddBusiness = MaterialIcon(
            name = "add_business",
            style = MaterialIcon.Style.Normal
        )
        val AddCard = MaterialIcon(
            name = "add_card",
            style = MaterialIcon.Style.Normal
        )
        val AddChart = MaterialIcon(
            name = "add_chart",
            style = MaterialIcon.Style.Normal
        )
        val AddCircle = MaterialIcon(
            name = "add_circle",
            style = MaterialIcon.Style.Normal
        )
        val AddCircleOutline = MaterialIcon(
            name = "add_circle_outline",
            style = MaterialIcon.Style.Normal
        )
        val AddComment = MaterialIcon(
            name = "add_comment",
            style = MaterialIcon.Style.Normal
        )
        val AddHome = MaterialIcon(
            name = "add_home",
            style = MaterialIcon.Style.Normal
        )
        val AddHomeWork = MaterialIcon(
            name = "add_home_work",
            style = MaterialIcon.Style.Normal
        )
        val AddIcCall = MaterialIcon(
            name = "add_ic_call",
            style = MaterialIcon.Style.Normal
        )
        val AddLink = MaterialIcon(
            name = "add_link",
            style = MaterialIcon.Style.Normal
        )
        val AddLocation = MaterialIcon(
            name = "add_location",
            style = MaterialIcon.Style.Normal
        )
        val AddLocationAlt = MaterialIcon(
            name = "add_location_alt",
            style = MaterialIcon.Style.Normal
        )
        val AddModerator = MaterialIcon(
            name = "add_moderator",
            style = MaterialIcon.Style.Normal
        )
        val AddPhotoAlternate = MaterialIcon(
            name = "add_photo_alternate",
            style = MaterialIcon.Style.Normal
        )
        val AddReaction = MaterialIcon(
            name = "add_reaction",
            style = MaterialIcon.Style.Normal
        )
        val AddRoad = MaterialIcon(
            name = "add_road",
            style = MaterialIcon.Style.Normal
        )
        val AddShoppingCart = MaterialIcon(
            name = "add_shopping_cart",
            style = MaterialIcon.Style.Normal
        )
        val AddTask = MaterialIcon(
            name = "add_task",
            style = MaterialIcon.Style.Normal
        )
        val AddToDrive = MaterialIcon(
            name = "add_to_drive",
            style = MaterialIcon.Style.Normal
        )
        val AddToHomeScreen = MaterialIcon(
            name = "add_to_home_screen",
            style = MaterialIcon.Style.Normal
        )
        val AddToPhotos = MaterialIcon(
            name = "add_to_photos",
            style = MaterialIcon.Style.Normal
        )
        val AddToQueue = MaterialIcon(
            name = "add_to_queue",
            style = MaterialIcon.Style.Normal
        )
        val Addchart = MaterialIcon(
            name = "addchart",
            style = MaterialIcon.Style.Normal
        )
        val AdfScanner = MaterialIcon(
            name = "adf_scanner",
            style = MaterialIcon.Style.Normal
        )
        val Adjust = MaterialIcon(
            name = "adjust",
            style = MaterialIcon.Style.Normal
        )
        val AdminPanelSettings = MaterialIcon(
            name = "admin_panel_settings",
            style = MaterialIcon.Style.Normal
        )
        val AdsClick = MaterialIcon(
            name = "ads_click",
            style = MaterialIcon.Style.Normal
        )
        val Agriculture = MaterialIcon(
            name = "agriculture",
            style = MaterialIcon.Style.Normal
        )
        val Air = MaterialIcon(
            name = "air",
            style = MaterialIcon.Style.Normal
        )
        val AirlineSeatFlat = MaterialIcon(
            name = "airline_seat_flat",
            style = MaterialIcon.Style.Normal
        )
        val AirlineSeatFlatAngled = MaterialIcon(
            name = "airline_seat_flat_angled",
            style = MaterialIcon.Style.Normal
        )
        val AirlineSeatIndividualSuite = MaterialIcon(
            name = "airline_seat_individual_suite",
            style = MaterialIcon.Style.Normal
        )
        val AirlineSeatLegroomExtra = MaterialIcon(
            name = "airline_seat_legroom_extra",
            style = MaterialIcon.Style.Normal
        )
        val AirlineSeatLegroomNormal = MaterialIcon(
            name = "airline_seat_legroom_normal",
            style = MaterialIcon.Style.Normal
        )
        val AirlineSeatLegroomReduced = MaterialIcon(
            name = "airline_seat_legroom_reduced",
            style = MaterialIcon.Style.Normal
        )
        val AirlineSeatReclineExtra = MaterialIcon(
            name = "airline_seat_recline_extra",
            style = MaterialIcon.Style.Normal
        )
        val AirlineSeatReclineNormal = MaterialIcon(
            name = "airline_seat_recline_normal",
            style = MaterialIcon.Style.Normal
        )
        val AirlineStops = MaterialIcon(
            name = "airline_stops",
            style = MaterialIcon.Style.Normal
        )
        val Airlines = MaterialIcon(
            name = "airlines",
            style = MaterialIcon.Style.Normal
        )
        val AirplaneTicket = MaterialIcon(
            name = "airplane_ticket",
            style = MaterialIcon.Style.Normal
        )
        val AirplanemodeActive = MaterialIcon(
            name = "airplanemode_active",
            style = MaterialIcon.Style.Normal
        )
        val AirplanemodeInactive = MaterialIcon(
            name = "airplanemode_inactive",
            style = MaterialIcon.Style.Normal
        )
        val Airplay = MaterialIcon(
            name = "airplay",
            style = MaterialIcon.Style.Normal
        )
        val AirportShuttle = MaterialIcon(
            name = "airport_shuttle",
            style = MaterialIcon.Style.Normal
        )
        val Alarm = MaterialIcon(
            name = "alarm",
            style = MaterialIcon.Style.Normal
        )
        val AlarmAdd = MaterialIcon(
            name = "alarm_add",
            style = MaterialIcon.Style.Normal
        )
        val AlarmOff = MaterialIcon(
            name = "alarm_off",
            style = MaterialIcon.Style.Normal
        )
        val AlarmOn = MaterialIcon(
            name = "alarm_on",
            style = MaterialIcon.Style.Normal
        )
        val Album = MaterialIcon(
            name = "album",
            style = MaterialIcon.Style.Normal
        )
        val AlignHorizontalCenter = MaterialIcon(
            name = "align_horizontal_center",
            style = MaterialIcon.Style.Normal
        )
        val AlignHorizontalLeft = MaterialIcon(
            name = "align_horizontal_left",
            style = MaterialIcon.Style.Normal
        )
        val AlignHorizontalRight = MaterialIcon(
            name = "align_horizontal_right",
            style = MaterialIcon.Style.Normal
        )
        val AlignVerticalBottom = MaterialIcon(
            name = "align_vertical_bottom",
            style = MaterialIcon.Style.Normal
        )
        val AlignVerticalCenter = MaterialIcon(
            name = "align_vertical_center",
            style = MaterialIcon.Style.Normal
        )
        val AlignVerticalTop = MaterialIcon(
            name = "align_vertical_top",
            style = MaterialIcon.Style.Normal
        )
        val AllInbox = MaterialIcon(
            name = "all_inbox",
            style = MaterialIcon.Style.Normal
        )
        val AllInclusive = MaterialIcon(
            name = "all_inclusive",
            style = MaterialIcon.Style.Normal
        )
        val AllOut = MaterialIcon(
            name = "all_out",
            style = MaterialIcon.Style.Normal
        )
        val AltRoute = MaterialIcon(
            name = "alt_route",
            style = MaterialIcon.Style.Normal
        )
        val AlternateEmail = MaterialIcon(
            name = "alternate_email",
            style = MaterialIcon.Style.Normal
        )
        val Analytics = MaterialIcon(
            name = "analytics",
            style = MaterialIcon.Style.Normal
        )
        val Anchor = MaterialIcon(
            name = "anchor",
            style = MaterialIcon.Style.Normal
        )
        val Android = MaterialIcon(
            name = "android",
            style = MaterialIcon.Style.Normal
        )
        val Animation = MaterialIcon(
            name = "animation",
            style = MaterialIcon.Style.Normal
        )
        val Announcement = MaterialIcon(
            name = "announcement",
            style = MaterialIcon.Style.Normal
        )
        val Aod = MaterialIcon(
            name = "aod",
            style = MaterialIcon.Style.Normal
        )
        val Apartment = MaterialIcon(
            name = "apartment",
            style = MaterialIcon.Style.Normal
        )
        val Api = MaterialIcon(
            name = "api",
            style = MaterialIcon.Style.Normal
        )
        val AppBlocking = MaterialIcon(
            name = "app_blocking",
            style = MaterialIcon.Style.Normal
        )
        val AppRegistration = MaterialIcon(
            name = "app_registration",
            style = MaterialIcon.Style.Normal
        )
        val AppSettingsAlt = MaterialIcon(
            name = "app_settings_alt",
            style = MaterialIcon.Style.Normal
        )
        val AppShortcut = MaterialIcon(
            name = "app_shortcut",
            style = MaterialIcon.Style.Normal
        )
        val Approval = MaterialIcon(
            name = "approval",
            style = MaterialIcon.Style.Normal
        )
        val Apps = MaterialIcon(
            name = "apps",
            style = MaterialIcon.Style.Normal
        )
        val AppsOutage = MaterialIcon(
            name = "apps_outage",
            style = MaterialIcon.Style.Normal
        )
        val Architecture = MaterialIcon(
            name = "architecture",
            style = MaterialIcon.Style.Normal
        )
        val Archive = MaterialIcon(
            name = "archive",
            style = MaterialIcon.Style.Normal
        )
        val AreaChart = MaterialIcon(
            name = "area_chart",
            style = MaterialIcon.Style.Normal
        )
        val ArrowBack = MaterialIcon(
            name = "arrow_back",
            style = MaterialIcon.Style.Normal
        )
        val ArrowBackIos = MaterialIcon(
            name = "arrow_back_ios",
            style = MaterialIcon.Style.Normal
        )
        val ArrowBackIosNew = MaterialIcon(
            name = "arrow_back_ios_new",
            style = MaterialIcon.Style.Normal
        )
        val ArrowCircleDown = MaterialIcon(
            name = "arrow_circle_down",
            style = MaterialIcon.Style.Normal
        )
        val ArrowCircleLeft = MaterialIcon(
            name = "arrow_circle_left",
            style = MaterialIcon.Style.Normal
        )
        val ArrowCircleRight = MaterialIcon(
            name = "arrow_circle_right",
            style = MaterialIcon.Style.Normal
        )
        val ArrowCircleUp = MaterialIcon(
            name = "arrow_circle_up",
            style = MaterialIcon.Style.Normal
        )
        val ArrowDownward = MaterialIcon(
            name = "arrow_downward",
            style = MaterialIcon.Style.Normal
        )
        val ArrowDropDown = MaterialIcon(
            name = "arrow_drop_down",
            style = MaterialIcon.Style.Normal
        )
        val ArrowDropDownCircle = MaterialIcon(
            name = "arrow_drop_down_circle",
            style = MaterialIcon.Style.Normal
        )
        val ArrowDropUp = MaterialIcon(
            name = "arrow_drop_up",
            style = MaterialIcon.Style.Normal
        )
        val ArrowForward = MaterialIcon(
            name = "arrow_forward",
            style = MaterialIcon.Style.Normal
        )
        val ArrowForwardIos = MaterialIcon(
            name = "arrow_forward_ios",
            style = MaterialIcon.Style.Normal
        )
        val ArrowLeft = MaterialIcon(
            name = "arrow_left",
            style = MaterialIcon.Style.Normal
        )
        val ArrowOutward = MaterialIcon(
            name = "arrow_outward",
            style = MaterialIcon.Style.Normal
        )
        val ArrowRight = MaterialIcon(
            name = "arrow_right",
            style = MaterialIcon.Style.Normal
        )
        val ArrowRightAlt = MaterialIcon(
            name = "arrow_right_alt",
            style = MaterialIcon.Style.Normal
        )
        val ArrowUpward = MaterialIcon(
            name = "arrow_upward",
            style = MaterialIcon.Style.Normal
        )
        val ArtTrack = MaterialIcon(
            name = "art_track",
            style = MaterialIcon.Style.Normal
        )
        val Article = MaterialIcon(
            name = "article",
            style = MaterialIcon.Style.Normal
        )
        val AspectRatio = MaterialIcon(
            name = "aspect_ratio",
            style = MaterialIcon.Style.Normal
        )
        val Assessment = MaterialIcon(
            name = "assessment",
            style = MaterialIcon.Style.Normal
        )
        val Assignment = MaterialIcon(
            name = "assignment",
            style = MaterialIcon.Style.Normal
        )
        val AssignmentInd = MaterialIcon(
            name = "assignment_ind",
            style = MaterialIcon.Style.Normal
        )
        val AssignmentLate = MaterialIcon(
            name = "assignment_late",
            style = MaterialIcon.Style.Normal
        )
        val AssignmentReturn = MaterialIcon(
            name = "assignment_return",
            style = MaterialIcon.Style.Normal
        )
        val AssignmentReturned = MaterialIcon(
            name = "assignment_returned",
            style = MaterialIcon.Style.Normal
        )
        val AssignmentTurnedIn = MaterialIcon(
            name = "assignment_turned_in",
            style = MaterialIcon.Style.Normal
        )
        val AssistWalker = MaterialIcon(
            name = "assist_walker",
            style = MaterialIcon.Style.Normal
        )
        val Assistant = MaterialIcon(
            name = "assistant",
            style = MaterialIcon.Style.Normal
        )
        val AssistantDirection = MaterialIcon(
            name = "assistant_direction",
            style = MaterialIcon.Style.Normal
        )
        val AssistantPhoto = MaterialIcon(
            name = "assistant_photo",
            style = MaterialIcon.Style.Normal
        )
        val AssuredWorkload = MaterialIcon(
            name = "assured_workload",
            style = MaterialIcon.Style.Normal
        )
        val Atm = MaterialIcon(
            name = "atm",
            style = MaterialIcon.Style.Normal
        )
        val AttachEmail = MaterialIcon(
            name = "attach_email",
            style = MaterialIcon.Style.Normal
        )
        val AttachFile = MaterialIcon(
            name = "attach_file",
            style = MaterialIcon.Style.Normal
        )
        val AttachMoney = MaterialIcon(
            name = "attach_money",
            style = MaterialIcon.Style.Normal
        )
        val Attachment = MaterialIcon(
            name = "attachment",
            style = MaterialIcon.Style.Normal
        )
        val Attractions = MaterialIcon(
            name = "attractions",
            style = MaterialIcon.Style.Normal
        )
        val Attribution = MaterialIcon(
            name = "attribution",
            style = MaterialIcon.Style.Normal
        )
        val AudioFile = MaterialIcon(
            name = "audio_file",
            style = MaterialIcon.Style.Normal
        )
        val Audiotrack = MaterialIcon(
            name = "audiotrack",
            style = MaterialIcon.Style.Normal
        )
        val AutoAwesome = MaterialIcon(
            name = "auto_awesome",
            style = MaterialIcon.Style.Normal
        )
        val AutoAwesomeMosaic = MaterialIcon(
            name = "auto_awesome_mosaic",
            style = MaterialIcon.Style.Normal
        )
        val AutoAwesomeMotion = MaterialIcon(
            name = "auto_awesome_motion",
            style = MaterialIcon.Style.Normal
        )
        val AutoDelete = MaterialIcon(
            name = "auto_delete",
            style = MaterialIcon.Style.Normal
        )
        val AutoFixHigh = MaterialIcon(
            name = "auto_fix_high",
            style = MaterialIcon.Style.Normal
        )
        val AutoFixNormal = MaterialIcon(
            name = "auto_fix_normal",
            style = MaterialIcon.Style.Normal
        )
        val AutoFixOff = MaterialIcon(
            name = "auto_fix_off",
            style = MaterialIcon.Style.Normal
        )
        val AutoGraph = MaterialIcon(
            name = "auto_graph",
            style = MaterialIcon.Style.Normal
        )
        val AutoMode = MaterialIcon(
            name = "auto_mode",
            style = MaterialIcon.Style.Normal
        )
        val AutoStories = MaterialIcon(
            name = "auto_stories",
            style = MaterialIcon.Style.Normal
        )
        val AutofpsSelect = MaterialIcon(
            name = "autofps_select",
            style = MaterialIcon.Style.Normal
        )
        val Autorenew = MaterialIcon(
            name = "autorenew",
            style = MaterialIcon.Style.Normal
        )
        val AvTimer = MaterialIcon(
            name = "av_timer",
            style = MaterialIcon.Style.Normal
        )
        val BabyChangingStation = MaterialIcon(
            name = "baby_changing_station",
            style = MaterialIcon.Style.Normal
        )
        val BackHand = MaterialIcon(
            name = "back_hand",
            style = MaterialIcon.Style.Normal
        )
        val Backpack = MaterialIcon(
            name = "backpack",
            style = MaterialIcon.Style.Normal
        )
        val Backspace = MaterialIcon(
            name = "backspace",
            style = MaterialIcon.Style.Normal
        )
        val Backup = MaterialIcon(
            name = "backup",
            style = MaterialIcon.Style.Normal
        )
        val BackupTable = MaterialIcon(
            name = "backup_table",
            style = MaterialIcon.Style.Normal
        )
        val Badge = MaterialIcon(
            name = "badge",
            style = MaterialIcon.Style.Normal
        )
        val BakeryDining = MaterialIcon(
            name = "bakery_dining",
            style = MaterialIcon.Style.Normal
        )
        val Balance = MaterialIcon(
            name = "balance",
            style = MaterialIcon.Style.Normal
        )
        val Balcony = MaterialIcon(
            name = "balcony",
            style = MaterialIcon.Style.Normal
        )
        val Ballot = MaterialIcon(
            name = "ballot",
            style = MaterialIcon.Style.Normal
        )
        val BarChart = MaterialIcon(
            name = "bar_chart",
            style = MaterialIcon.Style.Normal
        )
        val BatchPrediction = MaterialIcon(
            name = "batch_prediction",
            style = MaterialIcon.Style.Normal
        )
        val Bathroom = MaterialIcon(
            name = "bathroom",
            style = MaterialIcon.Style.Normal
        )
        val Bathtub = MaterialIcon(
            name = "bathtub",
            style = MaterialIcon.Style.Normal
        )
        val Battery0Bar = MaterialIcon(
            name = "battery_0_bar",
            style = MaterialIcon.Style.Normal
        )
        val Battery1Bar = MaterialIcon(
            name = "battery_1_bar",
            style = MaterialIcon.Style.Normal
        )
        val Battery2Bar = MaterialIcon(
            name = "battery_2_bar",
            style = MaterialIcon.Style.Normal
        )
        val Battery3Bar = MaterialIcon(
            name = "battery_3_bar",
            style = MaterialIcon.Style.Normal
        )
        val Battery4Bar = MaterialIcon(
            name = "battery_4_bar",
            style = MaterialIcon.Style.Normal
        )
        val Battery5Bar = MaterialIcon(
            name = "battery_5_bar",
            style = MaterialIcon.Style.Normal
        )
        val Battery6Bar = MaterialIcon(
            name = "battery_6_bar",
            style = MaterialIcon.Style.Normal
        )
        val BatteryAlert = MaterialIcon(
            name = "battery_alert",
            style = MaterialIcon.Style.Normal
        )
        val BatteryChargingFull = MaterialIcon(
            name = "battery_charging_full",
            style = MaterialIcon.Style.Normal
        )
        val BatteryFull = MaterialIcon(
            name = "battery_full",
            style = MaterialIcon.Style.Normal
        )
        val BatterySaver = MaterialIcon(
            name = "battery_saver",
            style = MaterialIcon.Style.Normal
        )
        val BatteryStd = MaterialIcon(
            name = "battery_std",
            style = MaterialIcon.Style.Normal
        )
        val BatteryUnknown = MaterialIcon(
            name = "battery_unknown",
            style = MaterialIcon.Style.Normal
        )
        val BeachAccess = MaterialIcon(
            name = "beach_access",
            style = MaterialIcon.Style.Normal
        )
        val Bed = MaterialIcon(
            name = "bed",
            style = MaterialIcon.Style.Normal
        )
        val BedroomBaby = MaterialIcon(
            name = "bedroom_baby",
            style = MaterialIcon.Style.Normal
        )
        val BedroomChild = MaterialIcon(
            name = "bedroom_child",
            style = MaterialIcon.Style.Normal
        )
        val BedroomParent = MaterialIcon(
            name = "bedroom_parent",
            style = MaterialIcon.Style.Normal
        )
        val Bedtime = MaterialIcon(
            name = "bedtime",
            style = MaterialIcon.Style.Normal
        )
        val BedtimeOff = MaterialIcon(
            name = "bedtime_off",
            style = MaterialIcon.Style.Normal
        )
        val Beenhere = MaterialIcon(
            name = "beenhere",
            style = MaterialIcon.Style.Normal
        )
        val Bento = MaterialIcon(
            name = "bento",
            style = MaterialIcon.Style.Normal
        )
        val BikeScooter = MaterialIcon(
            name = "bike_scooter",
            style = MaterialIcon.Style.Normal
        )
        val Biotech = MaterialIcon(
            name = "biotech",
            style = MaterialIcon.Style.Normal
        )
        val Blender = MaterialIcon(
            name = "blender",
            style = MaterialIcon.Style.Normal
        )
        val Blind = MaterialIcon(
            name = "blind",
            style = MaterialIcon.Style.Normal
        )
        val Blinds = MaterialIcon(
            name = "blinds",
            style = MaterialIcon.Style.Normal
        )
        val BlindsClosed = MaterialIcon(
            name = "blinds_closed",
            style = MaterialIcon.Style.Normal
        )
        val Block = MaterialIcon(
            name = "block",
            style = MaterialIcon.Style.Normal
        )
        val Bloodtype = MaterialIcon(
            name = "bloodtype",
            style = MaterialIcon.Style.Normal
        )
        val Bluetooth = MaterialIcon(
            name = "bluetooth",
            style = MaterialIcon.Style.Normal
        )
        val BluetoothAudio = MaterialIcon(
            name = "bluetooth_audio",
            style = MaterialIcon.Style.Normal
        )
        val BluetoothConnected = MaterialIcon(
            name = "bluetooth_connected",
            style = MaterialIcon.Style.Normal
        )
        val BluetoothDisabled = MaterialIcon(
            name = "bluetooth_disabled",
            style = MaterialIcon.Style.Normal
        )
        val BluetoothDrive = MaterialIcon(
            name = "bluetooth_drive",
            style = MaterialIcon.Style.Normal
        )
        val BluetoothSearching = MaterialIcon(
            name = "bluetooth_searching",
            style = MaterialIcon.Style.Normal
        )
        val BlurCircular = MaterialIcon(
            name = "blur_circular",
            style = MaterialIcon.Style.Normal
        )
        val BlurLinear = MaterialIcon(
            name = "blur_linear",
            style = MaterialIcon.Style.Normal
        )
        val BlurOff = MaterialIcon(
            name = "blur_off",
            style = MaterialIcon.Style.Normal
        )
        val BlurOn = MaterialIcon(
            name = "blur_on",
            style = MaterialIcon.Style.Normal
        )
        val Bolt = MaterialIcon(
            name = "bolt",
            style = MaterialIcon.Style.Normal
        )
        val Book = MaterialIcon(
            name = "book",
            style = MaterialIcon.Style.Normal
        )
        val BookOnline = MaterialIcon(
            name = "book_online",
            style = MaterialIcon.Style.Normal
        )
        val Bookmark = MaterialIcon(
            name = "bookmark",
            style = MaterialIcon.Style.Normal
        )
        val BookmarkAdd = MaterialIcon(
            name = "bookmark_add",
            style = MaterialIcon.Style.Normal
        )
        val BookmarkAdded = MaterialIcon(
            name = "bookmark_added",
            style = MaterialIcon.Style.Normal
        )
        val BookmarkBorder = MaterialIcon(
            name = "bookmark_border",
            style = MaterialIcon.Style.Normal
        )
        val BookmarkRemove = MaterialIcon(
            name = "bookmark_remove",
            style = MaterialIcon.Style.Normal
        )
        val Bookmarks = MaterialIcon(
            name = "bookmarks",
            style = MaterialIcon.Style.Normal
        )
        val BorderAll = MaterialIcon(
            name = "border_all",
            style = MaterialIcon.Style.Normal
        )
        val BorderBottom = MaterialIcon(
            name = "border_bottom",
            style = MaterialIcon.Style.Normal
        )
        val BorderClear = MaterialIcon(
            name = "border_clear",
            style = MaterialIcon.Style.Normal
        )
        val BorderColor = MaterialIcon(
            name = "border_color",
            style = MaterialIcon.Style.Normal
        )
        val BorderHorizontal = MaterialIcon(
            name = "border_horizontal",
            style = MaterialIcon.Style.Normal
        )
        val BorderInner = MaterialIcon(
            name = "border_inner",
            style = MaterialIcon.Style.Normal
        )
        val BorderLeft = MaterialIcon(
            name = "border_left",
            style = MaterialIcon.Style.Normal
        )
        val BorderOuter = MaterialIcon(
            name = "border_outer",
            style = MaterialIcon.Style.Normal
        )
        val BorderRight = MaterialIcon(
            name = "border_right",
            style = MaterialIcon.Style.Normal
        )
        val BorderStyle = MaterialIcon(
            name = "border_style",
            style = MaterialIcon.Style.Normal
        )
        val BorderTop = MaterialIcon(
            name = "border_top",
            style = MaterialIcon.Style.Normal
        )
        val BorderVertical = MaterialIcon(
            name = "border_vertical",
            style = MaterialIcon.Style.Normal
        )
        val Boy = MaterialIcon(
            name = "boy",
            style = MaterialIcon.Style.Normal
        )
        val BrandingWatermark = MaterialIcon(
            name = "branding_watermark",
            style = MaterialIcon.Style.Normal
        )
        val BreakfastDining = MaterialIcon(
            name = "breakfast_dining",
            style = MaterialIcon.Style.Normal
        )
        val Brightness1 = MaterialIcon(
            name = "brightness_1",
            style = MaterialIcon.Style.Normal
        )
        val Brightness2 = MaterialIcon(
            name = "brightness_2",
            style = MaterialIcon.Style.Normal
        )
        val Brightness3 = MaterialIcon(
            name = "brightness_3",
            style = MaterialIcon.Style.Normal
        )
        val Brightness4 = MaterialIcon(
            name = "brightness_4",
            style = MaterialIcon.Style.Normal
        )
        val Brightness5 = MaterialIcon(
            name = "brightness_5",
            style = MaterialIcon.Style.Normal
        )
        val Brightness6 = MaterialIcon(
            name = "brightness_6",
            style = MaterialIcon.Style.Normal
        )
        val Brightness7 = MaterialIcon(
            name = "brightness_7",
            style = MaterialIcon.Style.Normal
        )
        val BrightnessAuto = MaterialIcon(
            name = "brightness_auto",
            style = MaterialIcon.Style.Normal
        )
        val BrightnessHigh = MaterialIcon(
            name = "brightness_high",
            style = MaterialIcon.Style.Normal
        )
        val BrightnessLow = MaterialIcon(
            name = "brightness_low",
            style = MaterialIcon.Style.Normal
        )
        val BrightnessMedium = MaterialIcon(
            name = "brightness_medium",
            style = MaterialIcon.Style.Normal
        )
        val BroadcastOnHome = MaterialIcon(
            name = "broadcast_on_home",
            style = MaterialIcon.Style.Normal
        )
        val BroadcastOnPersonal = MaterialIcon(
            name = "broadcast_on_personal",
            style = MaterialIcon.Style.Normal
        )
        val BrokenImage = MaterialIcon(
            name = "broken_image",
            style = MaterialIcon.Style.Normal
        )
        val BrowseGallery = MaterialIcon(
            name = "browse_gallery",
            style = MaterialIcon.Style.Normal
        )
        val BrowserNotSupported = MaterialIcon(
            name = "browser_not_supported",
            style = MaterialIcon.Style.Normal
        )
        val BrowserUpdated = MaterialIcon(
            name = "browser_updated",
            style = MaterialIcon.Style.Normal
        )
        val BrunchDining = MaterialIcon(
            name = "brunch_dining",
            style = MaterialIcon.Style.Normal
        )
        val Brush = MaterialIcon(
            name = "brush",
            style = MaterialIcon.Style.Normal
        )
        val BubbleChart = MaterialIcon(
            name = "bubble_chart",
            style = MaterialIcon.Style.Normal
        )
        val BugReport = MaterialIcon(
            name = "bug_report",
            style = MaterialIcon.Style.Normal
        )
        val Build = MaterialIcon(
            name = "build",
            style = MaterialIcon.Style.Normal
        )
        val BuildCircle = MaterialIcon(
            name = "build_circle",
            style = MaterialIcon.Style.Normal
        )
        val Bungalow = MaterialIcon(
            name = "bungalow",
            style = MaterialIcon.Style.Normal
        )
        val BurstMode = MaterialIcon(
            name = "burst_mode",
            style = MaterialIcon.Style.Normal
        )
        val BusAlert = MaterialIcon(
            name = "bus_alert",
            style = MaterialIcon.Style.Normal
        )
        val Business = MaterialIcon(
            name = "business",
            style = MaterialIcon.Style.Normal
        )
        val BusinessCenter = MaterialIcon(
            name = "business_center",
            style = MaterialIcon.Style.Normal
        )
        val Cabin = MaterialIcon(
            name = "cabin",
            style = MaterialIcon.Style.Normal
        )
        val Cable = MaterialIcon(
            name = "cable",
            style = MaterialIcon.Style.Normal
        )
        val Cached = MaterialIcon(
            name = "cached",
            style = MaterialIcon.Style.Normal
        )
        val Cake = MaterialIcon(
            name = "cake",
            style = MaterialIcon.Style.Normal
        )
        val Calculate = MaterialIcon(
            name = "calculate",
            style = MaterialIcon.Style.Normal
        )
        val CalendarMonth = MaterialIcon(
            name = "calendar_month",
            style = MaterialIcon.Style.Normal
        )
        val CalendarToday = MaterialIcon(
            name = "calendar_today",
            style = MaterialIcon.Style.Normal
        )
        val CalendarViewDay = MaterialIcon(
            name = "calendar_view_day",
            style = MaterialIcon.Style.Normal
        )
        val CalendarViewMonth = MaterialIcon(
            name = "calendar_view_month",
            style = MaterialIcon.Style.Normal
        )
        val CalendarViewWeek = MaterialIcon(
            name = "calendar_view_week",
            style = MaterialIcon.Style.Normal
        )
        val Call = MaterialIcon(
            name = "call",
            style = MaterialIcon.Style.Normal
        )
        val CallEnd = MaterialIcon(
            name = "call_end",
            style = MaterialIcon.Style.Normal
        )
        val CallMade = MaterialIcon(
            name = "call_made",
            style = MaterialIcon.Style.Normal
        )
        val CallMerge = MaterialIcon(
            name = "call_merge",
            style = MaterialIcon.Style.Normal
        )
        val CallMissed = MaterialIcon(
            name = "call_missed",
            style = MaterialIcon.Style.Normal
        )
        val CallMissedOutgoing = MaterialIcon(
            name = "call_missed_outgoing",
            style = MaterialIcon.Style.Normal
        )
        val CallReceived = MaterialIcon(
            name = "call_received",
            style = MaterialIcon.Style.Normal
        )
        val CallSplit = MaterialIcon(
            name = "call_split",
            style = MaterialIcon.Style.Normal
        )
        val CallToAction = MaterialIcon(
            name = "call_to_action",
            style = MaterialIcon.Style.Normal
        )
        val Camera = MaterialIcon(
            name = "camera",
            style = MaterialIcon.Style.Normal
        )
        val CameraAlt = MaterialIcon(
            name = "camera_alt",
            style = MaterialIcon.Style.Normal
        )
        val CameraEnhance = MaterialIcon(
            name = "camera_enhance",
            style = MaterialIcon.Style.Normal
        )
        val CameraFront = MaterialIcon(
            name = "camera_front",
            style = MaterialIcon.Style.Normal
        )
        val CameraIndoor = MaterialIcon(
            name = "camera_indoor",
            style = MaterialIcon.Style.Normal
        )
        val CameraOutdoor = MaterialIcon(
            name = "camera_outdoor",
            style = MaterialIcon.Style.Normal
        )
        val CameraRear = MaterialIcon(
            name = "camera_rear",
            style = MaterialIcon.Style.Normal
        )
        val CameraRoll = MaterialIcon(
            name = "camera_roll",
            style = MaterialIcon.Style.Normal
        )
        val Cameraswitch = MaterialIcon(
            name = "cameraswitch",
            style = MaterialIcon.Style.Normal
        )
        val Campaign = MaterialIcon(
            name = "campaign",
            style = MaterialIcon.Style.Normal
        )
        val Cancel = MaterialIcon(
            name = "cancel",
            style = MaterialIcon.Style.Normal
        )
        val CancelPresentation = MaterialIcon(
            name = "cancel_presentation",
            style = MaterialIcon.Style.Normal
        )
        val CancelScheduleSend = MaterialIcon(
            name = "cancel_schedule_send",
            style = MaterialIcon.Style.Normal
        )
        val CandlestickChart = MaterialIcon(
            name = "candlestick_chart",
            style = MaterialIcon.Style.Normal
        )
        val CarCrash = MaterialIcon(
            name = "car_crash",
            style = MaterialIcon.Style.Normal
        )
        val CarRental = MaterialIcon(
            name = "car_rental",
            style = MaterialIcon.Style.Normal
        )
        val CarRepair = MaterialIcon(
            name = "car_repair",
            style = MaterialIcon.Style.Normal
        )
        val CardGiftcard = MaterialIcon(
            name = "card_giftcard",
            style = MaterialIcon.Style.Normal
        )
        val CardMembership = MaterialIcon(
            name = "card_membership",
            style = MaterialIcon.Style.Normal
        )
        val CardTravel = MaterialIcon(
            name = "card_travel",
            style = MaterialIcon.Style.Normal
        )
        val Carpenter = MaterialIcon(
            name = "carpenter",
            style = MaterialIcon.Style.Normal
        )
        val Cases = MaterialIcon(
            name = "cases",
            style = MaterialIcon.Style.Normal
        )
        val Casino = MaterialIcon(
            name = "casino",
            style = MaterialIcon.Style.Normal
        )
        val Cast = MaterialIcon(
            name = "cast",
            style = MaterialIcon.Style.Normal
        )
        val CastConnected = MaterialIcon(
            name = "cast_connected",
            style = MaterialIcon.Style.Normal
        )
        val CastForEducation = MaterialIcon(
            name = "cast_for_education",
            style = MaterialIcon.Style.Normal
        )
        val Castle = MaterialIcon(
            name = "castle",
            style = MaterialIcon.Style.Normal
        )
        val CatchingPokemon = MaterialIcon(
            name = "catching_pokemon",
            style = MaterialIcon.Style.Normal
        )
        val Category = MaterialIcon(
            name = "category",
            style = MaterialIcon.Style.Normal
        )
        val Celebration = MaterialIcon(
            name = "celebration",
            style = MaterialIcon.Style.Normal
        )
        val CellTower = MaterialIcon(
            name = "cell_tower",
            style = MaterialIcon.Style.Normal
        )
        val CellWifi = MaterialIcon(
            name = "cell_wifi",
            style = MaterialIcon.Style.Normal
        )
        val CenterFocusStrong = MaterialIcon(
            name = "center_focus_strong",
            style = MaterialIcon.Style.Normal
        )
        val CenterFocusWeak = MaterialIcon(
            name = "center_focus_weak",
            style = MaterialIcon.Style.Normal
        )
        val Chair = MaterialIcon(
            name = "chair",
            style = MaterialIcon.Style.Normal
        )
        val ChairAlt = MaterialIcon(
            name = "chair_alt",
            style = MaterialIcon.Style.Normal
        )
        val Chalet = MaterialIcon(
            name = "chalet",
            style = MaterialIcon.Style.Normal
        )
        val ChangeCircle = MaterialIcon(
            name = "change_circle",
            style = MaterialIcon.Style.Normal
        )
        val ChangeHistory = MaterialIcon(
            name = "change_history",
            style = MaterialIcon.Style.Normal
        )
        val ChargingStation = MaterialIcon(
            name = "charging_station",
            style = MaterialIcon.Style.Normal
        )
        val Chat = MaterialIcon(
            name = "chat",
            style = MaterialIcon.Style.Normal
        )
        val ChatBubble = MaterialIcon(
            name = "chat_bubble",
            style = MaterialIcon.Style.Normal
        )
        val ChatBubbleOutline = MaterialIcon(
            name = "chat_bubble_outline",
            style = MaterialIcon.Style.Normal
        )
        val Check = MaterialIcon(
            name = "check",
            style = MaterialIcon.Style.Normal
        )
        val CheckBox = MaterialIcon(
            name = "check_box",
            style = MaterialIcon.Style.Normal
        )
        val CheckBoxOutlineBlank = MaterialIcon(
            name = "check_box_outline_blank",
            style = MaterialIcon.Style.Normal
        )
        val CheckCircle = MaterialIcon(
            name = "check_circle",
            style = MaterialIcon.Style.Normal
        )
        val CheckCircleOutline = MaterialIcon(
            name = "check_circle_outline",
            style = MaterialIcon.Style.Normal
        )
        val Checklist = MaterialIcon(
            name = "checklist",
            style = MaterialIcon.Style.Normal
        )
        val ChecklistRtl = MaterialIcon(
            name = "checklist_rtl",
            style = MaterialIcon.Style.Normal
        )
        val Checkroom = MaterialIcon(
            name = "checkroom",
            style = MaterialIcon.Style.Normal
        )
        val ChevronLeft = MaterialIcon(
            name = "chevron_left",
            style = MaterialIcon.Style.Normal
        )
        val ChevronRight = MaterialIcon(
            name = "chevron_right",
            style = MaterialIcon.Style.Normal
        )
        val ChildCare = MaterialIcon(
            name = "child_care",
            style = MaterialIcon.Style.Normal
        )
        val ChildFriendly = MaterialIcon(
            name = "child_friendly",
            style = MaterialIcon.Style.Normal
        )
        val ChromeReaderMode = MaterialIcon(
            name = "chrome_reader_mode",
            style = MaterialIcon.Style.Normal
        )
        val Church = MaterialIcon(
            name = "church",
            style = MaterialIcon.Style.Normal
        )
        val Circle = MaterialIcon(
            name = "circle",
            style = MaterialIcon.Style.Normal
        )
        val CircleNotifications = MaterialIcon(
            name = "circle_notifications",
            style = MaterialIcon.Style.Normal
        )
        val Class = MaterialIcon(
            name = "class",
            style = MaterialIcon.Style.Normal
        )
        val CleanHands = MaterialIcon(
            name = "clean_hands",
            style = MaterialIcon.Style.Normal
        )
        val CleaningServices = MaterialIcon(
            name = "cleaning_services",
            style = MaterialIcon.Style.Normal
        )
        val Clear = MaterialIcon(
            name = "clear",
            style = MaterialIcon.Style.Normal
        )
        val ClearAll = MaterialIcon(
            name = "clear_all",
            style = MaterialIcon.Style.Normal
        )
        val Close = MaterialIcon(
            name = "close",
            style = MaterialIcon.Style.Normal
        )
        val CloseFullscreen = MaterialIcon(
            name = "close_fullscreen",
            style = MaterialIcon.Style.Normal
        )
        val ClosedCaption = MaterialIcon(
            name = "closed_caption",
            style = MaterialIcon.Style.Normal
        )
        val ClosedCaptionDisabled = MaterialIcon(
            name = "closed_caption_disabled",
            style = MaterialIcon.Style.Normal
        )
        val ClosedCaptionOff = MaterialIcon(
            name = "closed_caption_off",
            style = MaterialIcon.Style.Normal
        )
        val Cloud = MaterialIcon(
            name = "cloud",
            style = MaterialIcon.Style.Normal
        )
        val CloudCircle = MaterialIcon(
            name = "cloud_circle",
            style = MaterialIcon.Style.Normal
        )
        val CloudDone = MaterialIcon(
            name = "cloud_done",
            style = MaterialIcon.Style.Normal
        )
        val CloudDownload = MaterialIcon(
            name = "cloud_download",
            style = MaterialIcon.Style.Normal
        )
        val CloudOff = MaterialIcon(
            name = "cloud_off",
            style = MaterialIcon.Style.Normal
        )
        val CloudQueue = MaterialIcon(
            name = "cloud_queue",
            style = MaterialIcon.Style.Normal
        )
        val CloudSync = MaterialIcon(
            name = "cloud_sync",
            style = MaterialIcon.Style.Normal
        )
        val CloudUpload = MaterialIcon(
            name = "cloud_upload",
            style = MaterialIcon.Style.Normal
        )
        val Co2 = MaterialIcon(
            name = "co2",
            style = MaterialIcon.Style.Normal
        )
        val CoPresent = MaterialIcon(
            name = "co_present",
            style = MaterialIcon.Style.Normal
        )
        val Code = MaterialIcon(
            name = "code",
            style = MaterialIcon.Style.Normal
        )
        val CodeOff = MaterialIcon(
            name = "code_off",
            style = MaterialIcon.Style.Normal
        )
        val Coffee = MaterialIcon(
            name = "coffee",
            style = MaterialIcon.Style.Normal
        )
        val CoffeeMaker = MaterialIcon(
            name = "coffee_maker",
            style = MaterialIcon.Style.Normal
        )
        val Collections = MaterialIcon(
            name = "collections",
            style = MaterialIcon.Style.Normal
        )
        val CollectionsBookmark = MaterialIcon(
            name = "collections_bookmark",
            style = MaterialIcon.Style.Normal
        )
        val ColorLens = MaterialIcon(
            name = "color_lens",
            style = MaterialIcon.Style.Normal
        )
        val Colorize = MaterialIcon(
            name = "colorize",
            style = MaterialIcon.Style.Normal
        )
        val Comment = MaterialIcon(
            name = "comment",
            style = MaterialIcon.Style.Normal
        )
        val CommentBank = MaterialIcon(
            name = "comment_bank",
            style = MaterialIcon.Style.Normal
        )
        val CommentsDisabled = MaterialIcon(
            name = "comments_disabled",
            style = MaterialIcon.Style.Normal
        )
        val Commit = MaterialIcon(
            name = "commit",
            style = MaterialIcon.Style.Normal
        )
        val Commute = MaterialIcon(
            name = "commute",
            style = MaterialIcon.Style.Normal
        )
        val Compare = MaterialIcon(
            name = "compare",
            style = MaterialIcon.Style.Normal
        )
        val CompareArrows = MaterialIcon(
            name = "compare_arrows",
            style = MaterialIcon.Style.Normal
        )
        val CompassCalibration = MaterialIcon(
            name = "compass_calibration",
            style = MaterialIcon.Style.Normal
        )
        val Compost = MaterialIcon(
            name = "compost",
            style = MaterialIcon.Style.Normal
        )
        val Compress = MaterialIcon(
            name = "compress",
            style = MaterialIcon.Style.Normal
        )
        val Computer = MaterialIcon(
            name = "computer",
            style = MaterialIcon.Style.Normal
        )
        val ConfirmationNumber = MaterialIcon(
            name = "confirmation_number",
            style = MaterialIcon.Style.Normal
        )
        val ConnectWithoutContact = MaterialIcon(
            name = "connect_without_contact",
            style = MaterialIcon.Style.Normal
        )
        val ConnectedTv = MaterialIcon(
            name = "connected_tv",
            style = MaterialIcon.Style.Normal
        )
        val ConnectingAirports = MaterialIcon(
            name = "connecting_airports",
            style = MaterialIcon.Style.Normal
        )
        val Construction = MaterialIcon(
            name = "construction",
            style = MaterialIcon.Style.Normal
        )
        val ContactEmergency = MaterialIcon(
            name = "contact_emergency",
            style = MaterialIcon.Style.Normal
        )
        val ContactMail = MaterialIcon(
            name = "contact_mail",
            style = MaterialIcon.Style.Normal
        )
        val ContactPage = MaterialIcon(
            name = "contact_page",
            style = MaterialIcon.Style.Normal
        )
        val ContactPhone = MaterialIcon(
            name = "contact_phone",
            style = MaterialIcon.Style.Normal
        )
        val ContactSupport = MaterialIcon(
            name = "contact_support",
            style = MaterialIcon.Style.Normal
        )
        val Contactless = MaterialIcon(
            name = "contactless",
            style = MaterialIcon.Style.Normal
        )
        val Contacts = MaterialIcon(
            name = "contacts",
            style = MaterialIcon.Style.Normal
        )
        val ContentCopy = MaterialIcon(
            name = "content_copy",
            style = MaterialIcon.Style.Normal
        )
        val ContentCut = MaterialIcon(
            name = "content_cut",
            style = MaterialIcon.Style.Normal
        )
        val ContentPaste = MaterialIcon(
            name = "content_paste",
            style = MaterialIcon.Style.Normal
        )
        val ContentPasteGo = MaterialIcon(
            name = "content_paste_go",
            style = MaterialIcon.Style.Normal
        )
        val ContentPasteOff = MaterialIcon(
            name = "content_paste_off",
            style = MaterialIcon.Style.Normal
        )
        val ContentPasteSearch = MaterialIcon(
            name = "content_paste_search",
            style = MaterialIcon.Style.Normal
        )
        val Contrast = MaterialIcon(
            name = "contrast",
            style = MaterialIcon.Style.Normal
        )
        val ControlCamera = MaterialIcon(
            name = "control_camera",
            style = MaterialIcon.Style.Normal
        )
        val ControlPoint = MaterialIcon(
            name = "control_point",
            style = MaterialIcon.Style.Normal
        )
        val ControlPointDuplicate = MaterialIcon(
            name = "control_point_duplicate",
            style = MaterialIcon.Style.Normal
        )
        val Cookie = MaterialIcon(
            name = "cookie",
            style = MaterialIcon.Style.Normal
        )
        val CopyAll = MaterialIcon(
            name = "copy_all",
            style = MaterialIcon.Style.Normal
        )
        val Copyright = MaterialIcon(
            name = "copyright",
            style = MaterialIcon.Style.Normal
        )
        val Coronavirus = MaterialIcon(
            name = "coronavirus",
            style = MaterialIcon.Style.Normal
        )
        val CorporateFare = MaterialIcon(
            name = "corporate_fare",
            style = MaterialIcon.Style.Normal
        )
        val Cottage = MaterialIcon(
            name = "cottage",
            style = MaterialIcon.Style.Normal
        )
        val Countertops = MaterialIcon(
            name = "countertops",
            style = MaterialIcon.Style.Normal
        )
        val Create = MaterialIcon(
            name = "create",
            style = MaterialIcon.Style.Normal
        )
        val CreateNewFolder = MaterialIcon(
            name = "create_new_folder",
            style = MaterialIcon.Style.Normal
        )
        val CreditCard = MaterialIcon(
            name = "credit_card",
            style = MaterialIcon.Style.Normal
        )
        val CreditCardOff = MaterialIcon(
            name = "credit_card_off",
            style = MaterialIcon.Style.Normal
        )
        val CreditScore = MaterialIcon(
            name = "credit_score",
            style = MaterialIcon.Style.Normal
        )
        val Crib = MaterialIcon(
            name = "crib",
            style = MaterialIcon.Style.Normal
        )
        val CrisisAlert = MaterialIcon(
            name = "crisis_alert",
            style = MaterialIcon.Style.Normal
        )
        val Crop = MaterialIcon(
            name = "crop",
            style = MaterialIcon.Style.Normal
        )
        val Crop169 = MaterialIcon(
            name = "crop_16_9",
            style = MaterialIcon.Style.Normal
        )
        val Crop32 = MaterialIcon(
            name = "crop_3_2",
            style = MaterialIcon.Style.Normal
        )
        val Crop54 = MaterialIcon(
            name = "crop_5_4",
            style = MaterialIcon.Style.Normal
        )
        val Crop75 = MaterialIcon(
            name = "crop_7_5",
            style = MaterialIcon.Style.Normal
        )
        val CropDin = MaterialIcon(
            name = "crop_din",
            style = MaterialIcon.Style.Normal
        )
        val CropFree = MaterialIcon(
            name = "crop_free",
            style = MaterialIcon.Style.Normal
        )
        val CropLandscape = MaterialIcon(
            name = "crop_landscape",
            style = MaterialIcon.Style.Normal
        )
        val CropOriginal = MaterialIcon(
            name = "crop_original",
            style = MaterialIcon.Style.Normal
        )
        val CropPortrait = MaterialIcon(
            name = "crop_portrait",
            style = MaterialIcon.Style.Normal
        )
        val CropRotate = MaterialIcon(
            name = "crop_rotate",
            style = MaterialIcon.Style.Normal
        )
        val CropSquare = MaterialIcon(
            name = "crop_square",
            style = MaterialIcon.Style.Normal
        )
        val CrueltyFree = MaterialIcon(
            name = "cruelty_free",
            style = MaterialIcon.Style.Normal
        )
        val Css = MaterialIcon(
            name = "css",
            style = MaterialIcon.Style.Normal
        )
        val CurrencyBitcoin = MaterialIcon(
            name = "currency_bitcoin",
            style = MaterialIcon.Style.Normal
        )
        val CurrencyExchange = MaterialIcon(
            name = "currency_exchange",
            style = MaterialIcon.Style.Normal
        )
        val CurrencyFranc = MaterialIcon(
            name = "currency_franc",
            style = MaterialIcon.Style.Normal
        )
        val CurrencyLira = MaterialIcon(
            name = "currency_lira",
            style = MaterialIcon.Style.Normal
        )
        val CurrencyPound = MaterialIcon(
            name = "currency_pound",
            style = MaterialIcon.Style.Normal
        )
        val CurrencyRuble = MaterialIcon(
            name = "currency_ruble",
            style = MaterialIcon.Style.Normal
        )
        val CurrencyRupee = MaterialIcon(
            name = "currency_rupee",
            style = MaterialIcon.Style.Normal
        )
        val CurrencyYen = MaterialIcon(
            name = "currency_yen",
            style = MaterialIcon.Style.Normal
        )
        val CurrencyYuan = MaterialIcon(
            name = "currency_yuan",
            style = MaterialIcon.Style.Normal
        )
        val Curtains = MaterialIcon(
            name = "curtains",
            style = MaterialIcon.Style.Normal
        )
        val CurtainsClosed = MaterialIcon(
            name = "curtains_closed",
            style = MaterialIcon.Style.Normal
        )
        val Cyclone = MaterialIcon(
            name = "cyclone",
            style = MaterialIcon.Style.Normal
        )
        val Dangerous = MaterialIcon(
            name = "dangerous",
            style = MaterialIcon.Style.Normal
        )
        val DarkMode = MaterialIcon(
            name = "dark_mode",
            style = MaterialIcon.Style.Normal
        )
        val Dashboard = MaterialIcon(
            name = "dashboard",
            style = MaterialIcon.Style.Normal
        )
        val DashboardCustomize = MaterialIcon(
            name = "dashboard_customize",
            style = MaterialIcon.Style.Normal
        )
        val DataArray = MaterialIcon(
            name = "data_array",
            style = MaterialIcon.Style.Normal
        )
        val DataExploration = MaterialIcon(
            name = "data_exploration",
            style = MaterialIcon.Style.Normal
        )
        val DataObject = MaterialIcon(
            name = "data_object",
            style = MaterialIcon.Style.Normal
        )
        val DataSaverOff = MaterialIcon(
            name = "data_saver_off",
            style = MaterialIcon.Style.Normal
        )
        val DataSaverOn = MaterialIcon(
            name = "data_saver_on",
            style = MaterialIcon.Style.Normal
        )
        val DataThresholding = MaterialIcon(
            name = "data_thresholding",
            style = MaterialIcon.Style.Normal
        )
        val DataUsage = MaterialIcon(
            name = "data_usage",
            style = MaterialIcon.Style.Normal
        )
        val Dataset = MaterialIcon(
            name = "dataset",
            style = MaterialIcon.Style.Normal
        )
        val DatasetLinked = MaterialIcon(
            name = "dataset_linked",
            style = MaterialIcon.Style.Normal
        )
        val DateRange = MaterialIcon(
            name = "date_range",
            style = MaterialIcon.Style.Normal
        )
        val Deblur = MaterialIcon(
            name = "deblur",
            style = MaterialIcon.Style.Normal
        )
        val Deck = MaterialIcon(
            name = "deck",
            style = MaterialIcon.Style.Normal
        )
        val Dehaze = MaterialIcon(
            name = "dehaze",
            style = MaterialIcon.Style.Normal
        )
        val Delete = MaterialIcon(
            name = "delete",
            style = MaterialIcon.Style.Normal
        )
        val DeleteForever = MaterialIcon(
            name = "delete_forever",
            style = MaterialIcon.Style.Normal
        )
        val DeleteOutline = MaterialIcon(
            name = "delete_outline",
            style = MaterialIcon.Style.Normal
        )
        val DeleteSweep = MaterialIcon(
            name = "delete_sweep",
            style = MaterialIcon.Style.Normal
        )
        val DeliveryDining = MaterialIcon(
            name = "delivery_dining",
            style = MaterialIcon.Style.Normal
        )
        val DensityLarge = MaterialIcon(
            name = "density_large",
            style = MaterialIcon.Style.Normal
        )
        val DensityMedium = MaterialIcon(
            name = "density_medium",
            style = MaterialIcon.Style.Normal
        )
        val DensitySmall = MaterialIcon(
            name = "density_small",
            style = MaterialIcon.Style.Normal
        )
        val DepartureBoard = MaterialIcon(
            name = "departure_board",
            style = MaterialIcon.Style.Normal
        )
        val Description = MaterialIcon(
            name = "description",
            style = MaterialIcon.Style.Normal
        )
        val Deselect = MaterialIcon(
            name = "deselect",
            style = MaterialIcon.Style.Normal
        )
        val DesignServices = MaterialIcon(
            name = "design_services",
            style = MaterialIcon.Style.Normal
        )
        val Desk = MaterialIcon(
            name = "desk",
            style = MaterialIcon.Style.Normal
        )
        val DesktopAccessDisabled = MaterialIcon(
            name = "desktop_access_disabled",
            style = MaterialIcon.Style.Normal
        )
        val DesktopMac = MaterialIcon(
            name = "desktop_mac",
            style = MaterialIcon.Style.Normal
        )
        val DesktopWindows = MaterialIcon(
            name = "desktop_windows",
            style = MaterialIcon.Style.Normal
        )
        val Details = MaterialIcon(
            name = "details",
            style = MaterialIcon.Style.Normal
        )
        val DeveloperBoard = MaterialIcon(
            name = "developer_board",
            style = MaterialIcon.Style.Normal
        )
        val DeveloperBoardOff = MaterialIcon(
            name = "developer_board_off",
            style = MaterialIcon.Style.Normal
        )
        val DeveloperMode = MaterialIcon(
            name = "developer_mode",
            style = MaterialIcon.Style.Normal
        )
        val DeviceHub = MaterialIcon(
            name = "device_hub",
            style = MaterialIcon.Style.Normal
        )
        val DeviceThermostat = MaterialIcon(
            name = "device_thermostat",
            style = MaterialIcon.Style.Normal
        )
        val DeviceUnknown = MaterialIcon(
            name = "device_unknown",
            style = MaterialIcon.Style.Normal
        )
        val Devices = MaterialIcon(
            name = "devices",
            style = MaterialIcon.Style.Normal
        )
        val DevicesFold = MaterialIcon(
            name = "devices_fold",
            style = MaterialIcon.Style.Normal
        )
        val DevicesOther = MaterialIcon(
            name = "devices_other",
            style = MaterialIcon.Style.Normal
        )
        val DialerSip = MaterialIcon(
            name = "dialer_sip",
            style = MaterialIcon.Style.Normal
        )
        val Dialpad = MaterialIcon(
            name = "dialpad",
            style = MaterialIcon.Style.Normal
        )
        val Diamond = MaterialIcon(
            name = "diamond",
            style = MaterialIcon.Style.Normal
        )
        val Difference = MaterialIcon(
            name = "difference",
            style = MaterialIcon.Style.Normal
        )
        val Dining = MaterialIcon(
            name = "dining",
            style = MaterialIcon.Style.Normal
        )
        val DinnerDining = MaterialIcon(
            name = "dinner_dining",
            style = MaterialIcon.Style.Normal
        )
        val Directions = MaterialIcon(
            name = "directions",
            style = MaterialIcon.Style.Normal
        )
        val DirectionsBike = MaterialIcon(
            name = "directions_bike",
            style = MaterialIcon.Style.Normal
        )
        val DirectionsBoat = MaterialIcon(
            name = "directions_boat",
            style = MaterialIcon.Style.Normal
        )
        val DirectionsBoatFilled = MaterialIcon(
            name = "directions_boat_filled",
            style = MaterialIcon.Style.Normal
        )
        val DirectionsBus = MaterialIcon(
            name = "directions_bus",
            style = MaterialIcon.Style.Normal
        )
        val DirectionsBusFilled = MaterialIcon(
            name = "directions_bus_filled",
            style = MaterialIcon.Style.Normal
        )
        val DirectionsCar = MaterialIcon(
            name = "directions_car",
            style = MaterialIcon.Style.Normal
        )
        val DirectionsCarFilled = MaterialIcon(
            name = "directions_car_filled",
            style = MaterialIcon.Style.Normal
        )
        val DirectionsOff = MaterialIcon(
            name = "directions_off",
            style = MaterialIcon.Style.Normal
        )
        val DirectionsRailway = MaterialIcon(
            name = "directions_railway",
            style = MaterialIcon.Style.Normal
        )
        val DirectionsRailwayFilled = MaterialIcon(
            name = "directions_railway_filled",
            style = MaterialIcon.Style.Normal
        )
        val DirectionsRun = MaterialIcon(
            name = "directions_run",
            style = MaterialIcon.Style.Normal
        )
        val DirectionsSubway = MaterialIcon(
            name = "directions_subway",
            style = MaterialIcon.Style.Normal
        )
        val DirectionsSubwayFilled = MaterialIcon(
            name = "directions_subway_filled",
            style = MaterialIcon.Style.Normal
        )
        val DirectionsTransit = MaterialIcon(
            name = "directions_transit",
            style = MaterialIcon.Style.Normal
        )
        val DirectionsTransitFilled = MaterialIcon(
            name = "directions_transit_filled",
            style = MaterialIcon.Style.Normal
        )
        val DirectionsWalk = MaterialIcon(
            name = "directions_walk",
            style = MaterialIcon.Style.Normal
        )
        val DirtyLens = MaterialIcon(
            name = "dirty_lens",
            style = MaterialIcon.Style.Normal
        )
        val DisabledByDefault = MaterialIcon(
            name = "disabled_by_default",
            style = MaterialIcon.Style.Normal
        )
        val DisabledVisible = MaterialIcon(
            name = "disabled_visible",
            style = MaterialIcon.Style.Normal
        )
        val DiscFull = MaterialIcon(
            name = "disc_full",
            style = MaterialIcon.Style.Normal
        )
        val Discount = MaterialIcon(
            name = "discount",
            style = MaterialIcon.Style.Normal
        )
        val DisplaySettings = MaterialIcon(
            name = "display_settings",
            style = MaterialIcon.Style.Normal
        )
        val Diversity1 = MaterialIcon(
            name = "diversity_1",
            style = MaterialIcon.Style.Normal
        )
        val Diversity2 = MaterialIcon(
            name = "diversity_2",
            style = MaterialIcon.Style.Normal
        )
        val Diversity3 = MaterialIcon(
            name = "diversity_3",
            style = MaterialIcon.Style.Normal
        )
        val Dns = MaterialIcon(
            name = "dns",
            style = MaterialIcon.Style.Normal
        )
        val DoDisturb = MaterialIcon(
            name = "do_disturb",
            style = MaterialIcon.Style.Normal
        )
        val DoDisturbAlt = MaterialIcon(
            name = "do_disturb_alt",
            style = MaterialIcon.Style.Normal
        )
        val DoDisturbOff = MaterialIcon(
            name = "do_disturb_off",
            style = MaterialIcon.Style.Normal
        )
        val DoDisturbOn = MaterialIcon(
            name = "do_disturb_on",
            style = MaterialIcon.Style.Normal
        )
        val DoNotDisturb = MaterialIcon(
            name = "do_not_disturb",
            style = MaterialIcon.Style.Normal
        )
        val DoNotDisturbAlt = MaterialIcon(
            name = "do_not_disturb_alt",
            style = MaterialIcon.Style.Normal
        )
        val DoNotDisturbOff = MaterialIcon(
            name = "do_not_disturb_off",
            style = MaterialIcon.Style.Normal
        )
        val DoNotDisturbOn = MaterialIcon(
            name = "do_not_disturb_on",
            style = MaterialIcon.Style.Normal
        )
        val DoNotDisturbOnTotalSilence = MaterialIcon(
            name = "do_not_disturb_on_total_silence",
            style = MaterialIcon.Style.Normal
        )
        val DoNotStep = MaterialIcon(
            name = "do_not_step",
            style = MaterialIcon.Style.Normal
        )
        val DoNotTouch = MaterialIcon(
            name = "do_not_touch",
            style = MaterialIcon.Style.Normal
        )
        val Dock = MaterialIcon(
            name = "dock",
            style = MaterialIcon.Style.Normal
        )
        val DocumentScanner = MaterialIcon(
            name = "document_scanner",
            style = MaterialIcon.Style.Normal
        )
        val Domain = MaterialIcon(
            name = "domain",
            style = MaterialIcon.Style.Normal
        )
        val DomainAdd = MaterialIcon(
            name = "domain_add",
            style = MaterialIcon.Style.Normal
        )
        val DomainDisabled = MaterialIcon(
            name = "domain_disabled",
            style = MaterialIcon.Style.Normal
        )
        val DomainVerification = MaterialIcon(
            name = "domain_verification",
            style = MaterialIcon.Style.Normal
        )
        val Done = MaterialIcon(
            name = "done",
            style = MaterialIcon.Style.Normal
        )
        val DoneAll = MaterialIcon(
            name = "done_all",
            style = MaterialIcon.Style.Normal
        )
        val DoneOutline = MaterialIcon(
            name = "done_outline",
            style = MaterialIcon.Style.Normal
        )
        val DonutLarge = MaterialIcon(
            name = "donut_large",
            style = MaterialIcon.Style.Normal
        )
        val DonutSmall = MaterialIcon(
            name = "donut_small",
            style = MaterialIcon.Style.Normal
        )
        val DoorBack = MaterialIcon(
            name = "door_back",
            style = MaterialIcon.Style.Normal
        )
        val DoorFront = MaterialIcon(
            name = "door_front",
            style = MaterialIcon.Style.Normal
        )
        val DoorSliding = MaterialIcon(
            name = "door_sliding",
            style = MaterialIcon.Style.Normal
        )
        val Doorbell = MaterialIcon(
            name = "doorbell",
            style = MaterialIcon.Style.Normal
        )
        val DoubleArrow = MaterialIcon(
            name = "double_arrow",
            style = MaterialIcon.Style.Normal
        )
        val DownhillSkiing = MaterialIcon(
            name = "downhill_skiing",
            style = MaterialIcon.Style.Normal
        )
        val Download = MaterialIcon(
            name = "download",
            style = MaterialIcon.Style.Normal
        )
        val DownloadDone = MaterialIcon(
            name = "download_done",
            style = MaterialIcon.Style.Normal
        )
        val DownloadForOffline = MaterialIcon(
            name = "download_for_offline",
            style = MaterialIcon.Style.Normal
        )
        val Downloading = MaterialIcon(
            name = "downloading",
            style = MaterialIcon.Style.Normal
        )
        val Drafts = MaterialIcon(
            name = "drafts",
            style = MaterialIcon.Style.Normal
        )
        val DragHandle = MaterialIcon(
            name = "drag_handle",
            style = MaterialIcon.Style.Normal
        )
        val DragIndicator = MaterialIcon(
            name = "drag_indicator",
            style = MaterialIcon.Style.Normal
        )
        val Draw = MaterialIcon(
            name = "draw",
            style = MaterialIcon.Style.Normal
        )
        val DriveEta = MaterialIcon(
            name = "drive_eta",
            style = MaterialIcon.Style.Normal
        )
        val DriveFileMove = MaterialIcon(
            name = "drive_file_move",
            style = MaterialIcon.Style.Normal
        )
        val DriveFileMoveRtl = MaterialIcon(
            name = "drive_file_move_rtl",
            style = MaterialIcon.Style.Normal
        )
        val DriveFileRenameOutline = MaterialIcon(
            name = "drive_file_rename_outline",
            style = MaterialIcon.Style.Normal
        )
        val DriveFolderUpload = MaterialIcon(
            name = "drive_folder_upload",
            style = MaterialIcon.Style.Normal
        )
        val Dry = MaterialIcon(
            name = "dry",
            style = MaterialIcon.Style.Normal
        )
        val DryCleaning = MaterialIcon(
            name = "dry_cleaning",
            style = MaterialIcon.Style.Normal
        )
        val Duo = MaterialIcon(
            name = "duo",
            style = MaterialIcon.Style.Normal
        )
        val Dvr = MaterialIcon(
            name = "dvr",
            style = MaterialIcon.Style.Normal
        )
        val DynamicFeed = MaterialIcon(
            name = "dynamic_feed",
            style = MaterialIcon.Style.Normal
        )
        val DynamicForm = MaterialIcon(
            name = "dynamic_form",
            style = MaterialIcon.Style.Normal
        )
        val EMobiledata = MaterialIcon(
            name = "e_mobiledata",
            style = MaterialIcon.Style.Normal
        )
        val Earbuds = MaterialIcon(
            name = "earbuds",
            style = MaterialIcon.Style.Normal
        )
        val EarbudsBattery = MaterialIcon(
            name = "earbuds_battery",
            style = MaterialIcon.Style.Normal
        )
        val East = MaterialIcon(
            name = "east",
            style = MaterialIcon.Style.Normal
        )
        val EdgesensorHigh = MaterialIcon(
            name = "edgesensor_high",
            style = MaterialIcon.Style.Normal
        )
        val EdgesensorLow = MaterialIcon(
            name = "edgesensor_low",
            style = MaterialIcon.Style.Normal
        )
        val Edit = MaterialIcon(
            name = "edit",
            style = MaterialIcon.Style.Normal
        )
        val EditAttributes = MaterialIcon(
            name = "edit_attributes",
            style = MaterialIcon.Style.Normal
        )
        val EditCalendar = MaterialIcon(
            name = "edit_calendar",
            style = MaterialIcon.Style.Normal
        )
        val EditLocation = MaterialIcon(
            name = "edit_location",
            style = MaterialIcon.Style.Normal
        )
        val EditLocationAlt = MaterialIcon(
            name = "edit_location_alt",
            style = MaterialIcon.Style.Normal
        )
        val EditNote = MaterialIcon(
            name = "edit_note",
            style = MaterialIcon.Style.Normal
        )
        val EditNotifications = MaterialIcon(
            name = "edit_notifications",
            style = MaterialIcon.Style.Normal
        )
        val EditOff = MaterialIcon(
            name = "edit_off",
            style = MaterialIcon.Style.Normal
        )
        val EditRoad = MaterialIcon(
            name = "edit_road",
            style = MaterialIcon.Style.Normal
        )
        val Egg = MaterialIcon(
            name = "egg",
            style = MaterialIcon.Style.Normal
        )
        val EggAlt = MaterialIcon(
            name = "egg_alt",
            style = MaterialIcon.Style.Normal
        )
        val Eject = MaterialIcon(
            name = "eject",
            style = MaterialIcon.Style.Normal
        )
        val Elderly = MaterialIcon(
            name = "elderly",
            style = MaterialIcon.Style.Normal
        )
        val ElderlyWoman = MaterialIcon(
            name = "elderly_woman",
            style = MaterialIcon.Style.Normal
        )
        val ElectricBike = MaterialIcon(
            name = "electric_bike",
            style = MaterialIcon.Style.Normal
        )
        val ElectricBolt = MaterialIcon(
            name = "electric_bolt",
            style = MaterialIcon.Style.Normal
        )
        val ElectricCar = MaterialIcon(
            name = "electric_car",
            style = MaterialIcon.Style.Normal
        )
        val ElectricMeter = MaterialIcon(
            name = "electric_meter",
            style = MaterialIcon.Style.Normal
        )
        val ElectricMoped = MaterialIcon(
            name = "electric_moped",
            style = MaterialIcon.Style.Normal
        )
        val ElectricRickshaw = MaterialIcon(
            name = "electric_rickshaw",
            style = MaterialIcon.Style.Normal
        )
        val ElectricScooter = MaterialIcon(
            name = "electric_scooter",
            style = MaterialIcon.Style.Normal
        )
        val ElectricalServices = MaterialIcon(
            name = "electrical_services",
            style = MaterialIcon.Style.Normal
        )
        val Elevator = MaterialIcon(
            name = "elevator",
            style = MaterialIcon.Style.Normal
        )
        val Email = MaterialIcon(
            name = "email",
            style = MaterialIcon.Style.Normal
        )
        val Emergency = MaterialIcon(
            name = "emergency",
            style = MaterialIcon.Style.Normal
        )
        val EmergencyRecording = MaterialIcon(
            name = "emergency_recording",
            style = MaterialIcon.Style.Normal
        )
        val EmergencyShare = MaterialIcon(
            name = "emergency_share",
            style = MaterialIcon.Style.Normal
        )
        val EmojiEmotions = MaterialIcon(
            name = "emoji_emotions",
            style = MaterialIcon.Style.Normal
        )
        val EmojiEvents = MaterialIcon(
            name = "emoji_events",
            style = MaterialIcon.Style.Normal
        )
        val EmojiFoodBeverage = MaterialIcon(
            name = "emoji_food_beverage",
            style = MaterialIcon.Style.Normal
        )
        val EmojiNature = MaterialIcon(
            name = "emoji_nature",
            style = MaterialIcon.Style.Normal
        )
        val EmojiObjects = MaterialIcon(
            name = "emoji_objects",
            style = MaterialIcon.Style.Normal
        )
        val EmojiPeople = MaterialIcon(
            name = "emoji_people",
            style = MaterialIcon.Style.Normal
        )
        val EmojiSymbols = MaterialIcon(
            name = "emoji_symbols",
            style = MaterialIcon.Style.Normal
        )
        val EmojiTransportation = MaterialIcon(
            name = "emoji_transportation",
            style = MaterialIcon.Style.Normal
        )
        val EnergySavingsLeaf = MaterialIcon(
            name = "energy_savings_leaf",
            style = MaterialIcon.Style.Normal
        )
        val Engineering = MaterialIcon(
            name = "engineering",
            style = MaterialIcon.Style.Normal
        )
        val EnhancedEncryption = MaterialIcon(
            name = "enhanced_encryption",
            style = MaterialIcon.Style.Normal
        )
        val Equalizer = MaterialIcon(
            name = "equalizer",
            style = MaterialIcon.Style.Normal
        )
        val Error = MaterialIcon(
            name = "error",
            style = MaterialIcon.Style.Normal
        )
        val ErrorOutline = MaterialIcon(
            name = "error_outline",
            style = MaterialIcon.Style.Normal
        )
        val Escalator = MaterialIcon(
            name = "escalator",
            style = MaterialIcon.Style.Normal
        )
        val EscalatorWarning = MaterialIcon(
            name = "escalator_warning",
            style = MaterialIcon.Style.Normal
        )
        val Euro = MaterialIcon(
            name = "euro",
            style = MaterialIcon.Style.Normal
        )
        val EuroSymbol = MaterialIcon(
            name = "euro_symbol",
            style = MaterialIcon.Style.Normal
        )
        val EvStation = MaterialIcon(
            name = "ev_station",
            style = MaterialIcon.Style.Normal
        )
        val Event = MaterialIcon(
            name = "event",
            style = MaterialIcon.Style.Normal
        )
        val EventAvailable = MaterialIcon(
            name = "event_available",
            style = MaterialIcon.Style.Normal
        )
        val EventBusy = MaterialIcon(
            name = "event_busy",
            style = MaterialIcon.Style.Normal
        )
        val EventNote = MaterialIcon(
            name = "event_note",
            style = MaterialIcon.Style.Normal
        )
        val EventRepeat = MaterialIcon(
            name = "event_repeat",
            style = MaterialIcon.Style.Normal
        )
        val EventSeat = MaterialIcon(
            name = "event_seat",
            style = MaterialIcon.Style.Normal
        )
        val ExitToApp = MaterialIcon(
            name = "exit_to_app",
            style = MaterialIcon.Style.Normal
        )
        val Expand = MaterialIcon(
            name = "expand",
            style = MaterialIcon.Style.Normal
        )
        val ExpandCircleDown = MaterialIcon(
            name = "expand_circle_down",
            style = MaterialIcon.Style.Normal
        )
        val ExpandLess = MaterialIcon(
            name = "expand_less",
            style = MaterialIcon.Style.Normal
        )
        val ExpandMore = MaterialIcon(
            name = "expand_more",
            style = MaterialIcon.Style.Normal
        )
        val Explicit = MaterialIcon(
            name = "explicit",
            style = MaterialIcon.Style.Normal
        )
        val Explore = MaterialIcon(
            name = "explore",
            style = MaterialIcon.Style.Normal
        )
        val ExploreOff = MaterialIcon(
            name = "explore_off",
            style = MaterialIcon.Style.Normal
        )
        val Exposure = MaterialIcon(
            name = "exposure",
            style = MaterialIcon.Style.Normal
        )
        val ExposureNeg1 = MaterialIcon(
            name = "exposure_neg_1",
            style = MaterialIcon.Style.Normal
        )
        val ExposureNeg2 = MaterialIcon(
            name = "exposure_neg_2",
            style = MaterialIcon.Style.Normal
        )
        val ExposurePlus1 = MaterialIcon(
            name = "exposure_plus_1",
            style = MaterialIcon.Style.Normal
        )
        val ExposurePlus2 = MaterialIcon(
            name = "exposure_plus_2",
            style = MaterialIcon.Style.Normal
        )
        val ExposureZero = MaterialIcon(
            name = "exposure_zero",
            style = MaterialIcon.Style.Normal
        )
        val Extension = MaterialIcon(
            name = "extension",
            style = MaterialIcon.Style.Normal
        )
        val ExtensionOff = MaterialIcon(
            name = "extension_off",
            style = MaterialIcon.Style.Normal
        )
        val Face = MaterialIcon(
            name = "face",
            style = MaterialIcon.Style.Normal
        )
        val Face2 = MaterialIcon(
            name = "face_2",
            style = MaterialIcon.Style.Normal
        )
        val Face3 = MaterialIcon(
            name = "face_3",
            style = MaterialIcon.Style.Normal
        )
        val Face4 = MaterialIcon(
            name = "face_4",
            style = MaterialIcon.Style.Normal
        )
        val Face5 = MaterialIcon(
            name = "face_5",
            style = MaterialIcon.Style.Normal
        )
        val Face6 = MaterialIcon(
            name = "face_6",
            style = MaterialIcon.Style.Normal
        )
        val FaceRetouchingNatural = MaterialIcon(
            name = "face_retouching_natural",
            style = MaterialIcon.Style.Normal
        )
        val FaceRetouchingOff = MaterialIcon(
            name = "face_retouching_off",
            style = MaterialIcon.Style.Normal
        )
        val FaceUnlock = MaterialIcon(
            name = "face_unlock",
            style = MaterialIcon.Style.Normal
        )
        val FactCheck = MaterialIcon(
            name = "fact_check",
            style = MaterialIcon.Style.Normal
        )
        val Factory = MaterialIcon(
            name = "factory",
            style = MaterialIcon.Style.Normal
        )
        val FamilyRestroom = MaterialIcon(
            name = "family_restroom",
            style = MaterialIcon.Style.Normal
        )
        val FastForward = MaterialIcon(
            name = "fast_forward",
            style = MaterialIcon.Style.Normal
        )
        val FastRewind = MaterialIcon(
            name = "fast_rewind",
            style = MaterialIcon.Style.Normal
        )
        val Fastfood = MaterialIcon(
            name = "fastfood",
            style = MaterialIcon.Style.Normal
        )
        val Favorite = MaterialIcon(
            name = "favorite",
            style = MaterialIcon.Style.Normal
        )
        val FavoriteBorder = MaterialIcon(
            name = "favorite_border",
            style = MaterialIcon.Style.Normal
        )
        val Fax = MaterialIcon(
            name = "fax",
            style = MaterialIcon.Style.Normal
        )
        val FeaturedPlayList = MaterialIcon(
            name = "featured_play_list",
            style = MaterialIcon.Style.Normal
        )
        val FeaturedVideo = MaterialIcon(
            name = "featured_video",
            style = MaterialIcon.Style.Normal
        )
        val Feed = MaterialIcon(
            name = "feed",
            style = MaterialIcon.Style.Normal
        )
        val Feedback = MaterialIcon(
            name = "feedback",
            style = MaterialIcon.Style.Normal
        )
        val Female = MaterialIcon(
            name = "female",
            style = MaterialIcon.Style.Normal
        )
        val Fence = MaterialIcon(
            name = "fence",
            style = MaterialIcon.Style.Normal
        )
        val Festival = MaterialIcon(
            name = "festival",
            style = MaterialIcon.Style.Normal
        )
        val FiberDvr = MaterialIcon(
            name = "fiber_dvr",
            style = MaterialIcon.Style.Normal
        )
        val FiberManualRecord = MaterialIcon(
            name = "fiber_manual_record",
            style = MaterialIcon.Style.Normal
        )
        val FiberNew = MaterialIcon(
            name = "fiber_new",
            style = MaterialIcon.Style.Normal
        )
        val FiberPin = MaterialIcon(
            name = "fiber_pin",
            style = MaterialIcon.Style.Normal
        )
        val FiberSmartRecord = MaterialIcon(
            name = "fiber_smart_record",
            style = MaterialIcon.Style.Normal
        )
        val FileCopy = MaterialIcon(
            name = "file_copy",
            style = MaterialIcon.Style.Normal
        )
        val FileDownload = MaterialIcon(
            name = "file_download",
            style = MaterialIcon.Style.Normal
        )
        val FileDownloadDone = MaterialIcon(
            name = "file_download_done",
            style = MaterialIcon.Style.Normal
        )
        val FileDownloadOff = MaterialIcon(
            name = "file_download_off",
            style = MaterialIcon.Style.Normal
        )
        val FileOpen = MaterialIcon(
            name = "file_open",
            style = MaterialIcon.Style.Normal
        )
        val FilePresent = MaterialIcon(
            name = "file_present",
            style = MaterialIcon.Style.Normal
        )
        val FileUpload = MaterialIcon(
            name = "file_upload",
            style = MaterialIcon.Style.Normal
        )
        val Filter = MaterialIcon(
            name = "filter",
            style = MaterialIcon.Style.Normal
        )
        val Filter1 = MaterialIcon(
            name = "filter_1",
            style = MaterialIcon.Style.Normal
        )
        val Filter2 = MaterialIcon(
            name = "filter_2",
            style = MaterialIcon.Style.Normal
        )
        val Filter3 = MaterialIcon(
            name = "filter_3",
            style = MaterialIcon.Style.Normal
        )
        val Filter4 = MaterialIcon(
            name = "filter_4",
            style = MaterialIcon.Style.Normal
        )
        val Filter5 = MaterialIcon(
            name = "filter_5",
            style = MaterialIcon.Style.Normal
        )
        val Filter6 = MaterialIcon(
            name = "filter_6",
            style = MaterialIcon.Style.Normal
        )
        val Filter7 = MaterialIcon(
            name = "filter_7",
            style = MaterialIcon.Style.Normal
        )
        val Filter8 = MaterialIcon(
            name = "filter_8",
            style = MaterialIcon.Style.Normal
        )
        val Filter9 = MaterialIcon(
            name = "filter_9",
            style = MaterialIcon.Style.Normal
        )
        val Filter9Plus = MaterialIcon(
            name = "filter_9_plus",
            style = MaterialIcon.Style.Normal
        )
        val FilterAlt = MaterialIcon(
            name = "filter_alt",
            style = MaterialIcon.Style.Normal
        )
        val FilterAltOff = MaterialIcon(
            name = "filter_alt_off",
            style = MaterialIcon.Style.Normal
        )
        val FilterBAndW = MaterialIcon(
            name = "filter_b_and_w",
            style = MaterialIcon.Style.Normal
        )
        val FilterCenterFocus = MaterialIcon(
            name = "filter_center_focus",
            style = MaterialIcon.Style.Normal
        )
        val FilterDrama = MaterialIcon(
            name = "filter_drama",
            style = MaterialIcon.Style.Normal
        )
        val FilterFrames = MaterialIcon(
            name = "filter_frames",
            style = MaterialIcon.Style.Normal
        )
        val FilterHdr = MaterialIcon(
            name = "filter_hdr",
            style = MaterialIcon.Style.Normal
        )
        val FilterList = MaterialIcon(
            name = "filter_list",
            style = MaterialIcon.Style.Normal
        )
        val FilterListOff = MaterialIcon(
            name = "filter_list_off",
            style = MaterialIcon.Style.Normal
        )
        val FilterNone = MaterialIcon(
            name = "filter_none",
            style = MaterialIcon.Style.Normal
        )
        val FilterTiltShift = MaterialIcon(
            name = "filter_tilt_shift",
            style = MaterialIcon.Style.Normal
        )
        val FilterVintage = MaterialIcon(
            name = "filter_vintage",
            style = MaterialIcon.Style.Normal
        )
        val FindInPage = MaterialIcon(
            name = "find_in_page",
            style = MaterialIcon.Style.Normal
        )
        val FindReplace = MaterialIcon(
            name = "find_replace",
            style = MaterialIcon.Style.Normal
        )
        val Fingerprint = MaterialIcon(
            name = "fingerprint",
            style = MaterialIcon.Style.Normal
        )
        val FireExtinguisher = MaterialIcon(
            name = "fire_extinguisher",
            style = MaterialIcon.Style.Normal
        )
        val FireHydrantAlt = MaterialIcon(
            name = "fire_hydrant_alt",
            style = MaterialIcon.Style.Normal
        )
        val FireTruck = MaterialIcon(
            name = "fire_truck",
            style = MaterialIcon.Style.Normal
        )
        val Fireplace = MaterialIcon(
            name = "fireplace",
            style = MaterialIcon.Style.Normal
        )
        val FirstPage = MaterialIcon(
            name = "first_page",
            style = MaterialIcon.Style.Normal
        )
        val FitScreen = MaterialIcon(
            name = "fit_screen",
            style = MaterialIcon.Style.Normal
        )
        val Fitbit = MaterialIcon(
            name = "fitbit",
            style = MaterialIcon.Style.Normal
        )
        val FitnessCenter = MaterialIcon(
            name = "fitness_center",
            style = MaterialIcon.Style.Normal
        )
        val Flag = MaterialIcon(
            name = "flag",
            style = MaterialIcon.Style.Normal
        )
        val FlagCircle = MaterialIcon(
            name = "flag_circle",
            style = MaterialIcon.Style.Normal
        )
        val Flaky = MaterialIcon(
            name = "flaky",
            style = MaterialIcon.Style.Normal
        )
        val Flare = MaterialIcon(
            name = "flare",
            style = MaterialIcon.Style.Normal
        )
        val FlashAuto = MaterialIcon(
            name = "flash_auto",
            style = MaterialIcon.Style.Normal
        )
        val FlashOff = MaterialIcon(
            name = "flash_off",
            style = MaterialIcon.Style.Normal
        )
        val FlashOn = MaterialIcon(
            name = "flash_on",
            style = MaterialIcon.Style.Normal
        )
        val FlashlightOff = MaterialIcon(
            name = "flashlight_off",
            style = MaterialIcon.Style.Normal
        )
        val FlashlightOn = MaterialIcon(
            name = "flashlight_on",
            style = MaterialIcon.Style.Normal
        )
        val Flatware = MaterialIcon(
            name = "flatware",
            style = MaterialIcon.Style.Normal
        )
        val Flight = MaterialIcon(
            name = "flight",
            style = MaterialIcon.Style.Normal
        )
        val FlightClass = MaterialIcon(
            name = "flight_class",
            style = MaterialIcon.Style.Normal
        )
        val FlightLand = MaterialIcon(
            name = "flight_land",
            style = MaterialIcon.Style.Normal
        )
        val FlightTakeoff = MaterialIcon(
            name = "flight_takeoff",
            style = MaterialIcon.Style.Normal
        )
        val Flip = MaterialIcon(
            name = "flip",
            style = MaterialIcon.Style.Normal
        )
        val FlipCameraAndroid = MaterialIcon(
            name = "flip_camera_android",
            style = MaterialIcon.Style.Normal
        )
        val FlipCameraIos = MaterialIcon(
            name = "flip_camera_ios",
            style = MaterialIcon.Style.Normal
        )
        val FlipToBack = MaterialIcon(
            name = "flip_to_back",
            style = MaterialIcon.Style.Normal
        )
        val FlipToFront = MaterialIcon(
            name = "flip_to_front",
            style = MaterialIcon.Style.Normal
        )
        val Flood = MaterialIcon(
            name = "flood",
            style = MaterialIcon.Style.Normal
        )
        val Fluorescent = MaterialIcon(
            name = "fluorescent",
            style = MaterialIcon.Style.Normal
        )
        val FlutterDash = MaterialIcon(
            name = "flutter_dash",
            style = MaterialIcon.Style.Normal
        )
        val FmdBad = MaterialIcon(
            name = "fmd_bad",
            style = MaterialIcon.Style.Normal
        )
        val FmdGood = MaterialIcon(
            name = "fmd_good",
            style = MaterialIcon.Style.Normal
        )
        val Folder = MaterialIcon(
            name = "folder",
            style = MaterialIcon.Style.Normal
        )
        val FolderCopy = MaterialIcon(
            name = "folder_copy",
            style = MaterialIcon.Style.Normal
        )
        val FolderDelete = MaterialIcon(
            name = "folder_delete",
            style = MaterialIcon.Style.Normal
        )
        val FolderOff = MaterialIcon(
            name = "folder_off",
            style = MaterialIcon.Style.Normal
        )
        val FolderOpen = MaterialIcon(
            name = "folder_open",
            style = MaterialIcon.Style.Normal
        )
        val FolderShared = MaterialIcon(
            name = "folder_shared",
            style = MaterialIcon.Style.Normal
        )
        val FolderSpecial = MaterialIcon(
            name = "folder_special",
            style = MaterialIcon.Style.Normal
        )
        val FolderZip = MaterialIcon(
            name = "folder_zip",
            style = MaterialIcon.Style.Normal
        )
        val FollowTheSigns = MaterialIcon(
            name = "follow_the_signs",
            style = MaterialIcon.Style.Normal
        )
        val FontDownload = MaterialIcon(
            name = "font_download",
            style = MaterialIcon.Style.Normal
        )
        val FontDownloadOff = MaterialIcon(
            name = "font_download_off",
            style = MaterialIcon.Style.Normal
        )
        val FoodBank = MaterialIcon(
            name = "food_bank",
            style = MaterialIcon.Style.Normal
        )
        val Forest = MaterialIcon(
            name = "forest",
            style = MaterialIcon.Style.Normal
        )
        val ForkLeft = MaterialIcon(
            name = "fork_left",
            style = MaterialIcon.Style.Normal
        )
        val ForkRight = MaterialIcon(
            name = "fork_right",
            style = MaterialIcon.Style.Normal
        )
        val FormatAlignCenter = MaterialIcon(
            name = "format_align_center",
            style = MaterialIcon.Style.Normal
        )
        val FormatAlignJustify = MaterialIcon(
            name = "format_align_justify",
            style = MaterialIcon.Style.Normal
        )
        val FormatAlignLeft = MaterialIcon(
            name = "format_align_left",
            style = MaterialIcon.Style.Normal
        )
        val FormatAlignRight = MaterialIcon(
            name = "format_align_right",
            style = MaterialIcon.Style.Normal
        )
        val FormatBold = MaterialIcon(
            name = "format_bold",
            style = MaterialIcon.Style.Normal
        )
        val FormatClear = MaterialIcon(
            name = "format_clear",
            style = MaterialIcon.Style.Normal
        )
        val FormatColorFill = MaterialIcon(
            name = "format_color_fill",
            style = MaterialIcon.Style.Normal
        )
        val FormatColorReset = MaterialIcon(
            name = "format_color_reset",
            style = MaterialIcon.Style.Normal
        )
        val FormatColorText = MaterialIcon(
            name = "format_color_text",
            style = MaterialIcon.Style.Normal
        )
        val FormatIndentDecrease = MaterialIcon(
            name = "format_indent_decrease",
            style = MaterialIcon.Style.Normal
        )
        val FormatIndentIncrease = MaterialIcon(
            name = "format_indent_increase",
            style = MaterialIcon.Style.Normal
        )
        val FormatItalic = MaterialIcon(
            name = "format_italic",
            style = MaterialIcon.Style.Normal
        )
        val FormatLineSpacing = MaterialIcon(
            name = "format_line_spacing",
            style = MaterialIcon.Style.Normal
        )
        val FormatListBulleted = MaterialIcon(
            name = "format_list_bulleted",
            style = MaterialIcon.Style.Normal
        )
        val FormatListNumbered = MaterialIcon(
            name = "format_list_numbered",
            style = MaterialIcon.Style.Normal
        )
        val FormatListNumberedRtl = MaterialIcon(
            name = "format_list_numbered_rtl",
            style = MaterialIcon.Style.Normal
        )
        val FormatOverline = MaterialIcon(
            name = "format_overline",
            style = MaterialIcon.Style.Normal
        )
        val FormatPaint = MaterialIcon(
            name = "format_paint",
            style = MaterialIcon.Style.Normal
        )
        val FormatQuote = MaterialIcon(
            name = "format_quote",
            style = MaterialIcon.Style.Normal
        )
        val FormatShapes = MaterialIcon(
            name = "format_shapes",
            style = MaterialIcon.Style.Normal
        )
        val FormatSize = MaterialIcon(
            name = "format_size",
            style = MaterialIcon.Style.Normal
        )
        val FormatStrikethrough = MaterialIcon(
            name = "format_strikethrough",
            style = MaterialIcon.Style.Normal
        )
        val FormatTextdirectionLToR = MaterialIcon(
            name = "format_textdirection_l_to_r",
            style = MaterialIcon.Style.Normal
        )
        val FormatTextdirectionRToL = MaterialIcon(
            name = "format_textdirection_r_to_l",
            style = MaterialIcon.Style.Normal
        )
        val FormatUnderlined = MaterialIcon(
            name = "format_underlined",
            style = MaterialIcon.Style.Normal
        )
        val Fort = MaterialIcon(
            name = "fort",
            style = MaterialIcon.Style.Normal
        )
        val Forum = MaterialIcon(
            name = "forum",
            style = MaterialIcon.Style.Normal
        )
        val Forward = MaterialIcon(
            name = "forward",
            style = MaterialIcon.Style.Normal
        )
        val Forward10 = MaterialIcon(
            name = "forward_10",
            style = MaterialIcon.Style.Normal
        )
        val Forward30 = MaterialIcon(
            name = "forward_30",
            style = MaterialIcon.Style.Normal
        )
        val Forward5 = MaterialIcon(
            name = "forward_5",
            style = MaterialIcon.Style.Normal
        )
        val ForwardToInbox = MaterialIcon(
            name = "forward_to_inbox",
            style = MaterialIcon.Style.Normal
        )
        val Foundation = MaterialIcon(
            name = "foundation",
            style = MaterialIcon.Style.Normal
        )
        val FreeBreakfast = MaterialIcon(
            name = "free_breakfast",
            style = MaterialIcon.Style.Normal
        )
        val FreeCancellation = MaterialIcon(
            name = "free_cancellation",
            style = MaterialIcon.Style.Normal
        )
        val FrontHand = MaterialIcon(
            name = "front_hand",
            style = MaterialIcon.Style.Normal
        )
        val Fullscreen = MaterialIcon(
            name = "fullscreen",
            style = MaterialIcon.Style.Normal
        )
        val FullscreenExit = MaterialIcon(
            name = "fullscreen_exit",
            style = MaterialIcon.Style.Normal
        )
        val Functions = MaterialIcon(
            name = "functions",
            style = MaterialIcon.Style.Normal
        )
        val GMobiledata = MaterialIcon(
            name = "g_mobiledata",
            style = MaterialIcon.Style.Normal
        )
        val GTranslate = MaterialIcon(
            name = "g_translate",
            style = MaterialIcon.Style.Normal
        )
        val Gamepad = MaterialIcon(
            name = "gamepad",
            style = MaterialIcon.Style.Normal
        )
        val Games = MaterialIcon(
            name = "games",
            style = MaterialIcon.Style.Normal
        )
        val Garage = MaterialIcon(
            name = "garage",
            style = MaterialIcon.Style.Normal
        )
        val GasMeter = MaterialIcon(
            name = "gas_meter",
            style = MaterialIcon.Style.Normal
        )
        val Gavel = MaterialIcon(
            name = "gavel",
            style = MaterialIcon.Style.Normal
        )
        val GeneratingTokens = MaterialIcon(
            name = "generating_tokens",
            style = MaterialIcon.Style.Normal
        )
        val Gesture = MaterialIcon(
            name = "gesture",
            style = MaterialIcon.Style.Normal
        )
        val GetApp = MaterialIcon(
            name = "get_app",
            style = MaterialIcon.Style.Normal
        )
        val Gif = MaterialIcon(
            name = "gif",
            style = MaterialIcon.Style.Normal
        )
        val GifBox = MaterialIcon(
            name = "gif_box",
            style = MaterialIcon.Style.Normal
        )
        val Girl = MaterialIcon(
            name = "girl",
            style = MaterialIcon.Style.Normal
        )
        val Gite = MaterialIcon(
            name = "gite",
            style = MaterialIcon.Style.Normal
        )
        val GolfCourse = MaterialIcon(
            name = "golf_course",
            style = MaterialIcon.Style.Normal
        )
        val GppBad = MaterialIcon(
            name = "gpp_bad",
            style = MaterialIcon.Style.Normal
        )
        val GppGood = MaterialIcon(
            name = "gpp_good",
            style = MaterialIcon.Style.Normal
        )
        val GppMaybe = MaterialIcon(
            name = "gpp_maybe",
            style = MaterialIcon.Style.Normal
        )
        val GpsFixed = MaterialIcon(
            name = "gps_fixed",
            style = MaterialIcon.Style.Normal
        )
        val GpsNotFixed = MaterialIcon(
            name = "gps_not_fixed",
            style = MaterialIcon.Style.Normal
        )
        val GpsOff = MaterialIcon(
            name = "gps_off",
            style = MaterialIcon.Style.Normal
        )
        val Grade = MaterialIcon(
            name = "grade",
            style = MaterialIcon.Style.Normal
        )
        val Gradient = MaterialIcon(
            name = "gradient",
            style = MaterialIcon.Style.Normal
        )
        val Grading = MaterialIcon(
            name = "grading",
            style = MaterialIcon.Style.Normal
        )
        val Grain = MaterialIcon(
            name = "grain",
            style = MaterialIcon.Style.Normal
        )
        val GraphicEq = MaterialIcon(
            name = "graphic_eq",
            style = MaterialIcon.Style.Normal
        )
        val Grass = MaterialIcon(
            name = "grass",
            style = MaterialIcon.Style.Normal
        )
        val Grid3x3 = MaterialIcon(
            name = "grid_3x3",
            style = MaterialIcon.Style.Normal
        )
        val Grid4x4 = MaterialIcon(
            name = "grid_4x4",
            style = MaterialIcon.Style.Normal
        )
        val GridGoldenratio = MaterialIcon(
            name = "grid_goldenratio",
            style = MaterialIcon.Style.Normal
        )
        val GridOff = MaterialIcon(
            name = "grid_off",
            style = MaterialIcon.Style.Normal
        )
        val GridOn = MaterialIcon(
            name = "grid_on",
            style = MaterialIcon.Style.Normal
        )
        val GridView = MaterialIcon(
            name = "grid_view",
            style = MaterialIcon.Style.Normal
        )
        val Group = MaterialIcon(
            name = "group",
            style = MaterialIcon.Style.Normal
        )
        val GroupAdd = MaterialIcon(
            name = "group_add",
            style = MaterialIcon.Style.Normal
        )
        val GroupOff = MaterialIcon(
            name = "group_off",
            style = MaterialIcon.Style.Normal
        )
        val GroupRemove = MaterialIcon(
            name = "group_remove",
            style = MaterialIcon.Style.Normal
        )
        val GroupWork = MaterialIcon(
            name = "group_work",
            style = MaterialIcon.Style.Normal
        )
        val Groups = MaterialIcon(
            name = "groups",
            style = MaterialIcon.Style.Normal
        )
        val Groups2 = MaterialIcon(
            name = "groups_2",
            style = MaterialIcon.Style.Normal
        )
        val Groups3 = MaterialIcon(
            name = "groups_3",
            style = MaterialIcon.Style.Normal
        )
        val HMobiledata = MaterialIcon(
            name = "h_mobiledata",
            style = MaterialIcon.Style.Normal
        )
        val HPlusMobiledata = MaterialIcon(
            name = "h_plus_mobiledata",
            style = MaterialIcon.Style.Normal
        )
        val Hail = MaterialIcon(
            name = "hail",
            style = MaterialIcon.Style.Normal
        )
        val Handshake = MaterialIcon(
            name = "handshake",
            style = MaterialIcon.Style.Normal
        )
        val Handyman = MaterialIcon(
            name = "handyman",
            style = MaterialIcon.Style.Normal
        )
        val Hardware = MaterialIcon(
            name = "hardware",
            style = MaterialIcon.Style.Normal
        )
        val Hd = MaterialIcon(
            name = "hd",
            style = MaterialIcon.Style.Normal
        )
        val HdrAuto = MaterialIcon(
            name = "hdr_auto",
            style = MaterialIcon.Style.Normal
        )
        val HdrAutoSelect = MaterialIcon(
            name = "hdr_auto_select",
            style = MaterialIcon.Style.Normal
        )
        val HdrEnhancedSelect = MaterialIcon(
            name = "hdr_enhanced_select",
            style = MaterialIcon.Style.Normal
        )
        val HdrOff = MaterialIcon(
            name = "hdr_off",
            style = MaterialIcon.Style.Normal
        )
        val HdrOffSelect = MaterialIcon(
            name = "hdr_off_select",
            style = MaterialIcon.Style.Normal
        )
        val HdrOn = MaterialIcon(
            name = "hdr_on",
            style = MaterialIcon.Style.Normal
        )
        val HdrOnSelect = MaterialIcon(
            name = "hdr_on_select",
            style = MaterialIcon.Style.Normal
        )
        val HdrPlus = MaterialIcon(
            name = "hdr_plus",
            style = MaterialIcon.Style.Normal
        )
        val HdrStrong = MaterialIcon(
            name = "hdr_strong",
            style = MaterialIcon.Style.Normal
        )
        val HdrWeak = MaterialIcon(
            name = "hdr_weak",
            style = MaterialIcon.Style.Normal
        )
        val Headphones = MaterialIcon(
            name = "headphones",
            style = MaterialIcon.Style.Normal
        )
        val HeadphonesBattery = MaterialIcon(
            name = "headphones_battery",
            style = MaterialIcon.Style.Normal
        )
        val Headset = MaterialIcon(
            name = "headset",
            style = MaterialIcon.Style.Normal
        )
        val HeadsetMic = MaterialIcon(
            name = "headset_mic",
            style = MaterialIcon.Style.Normal
        )
        val HeadsetOff = MaterialIcon(
            name = "headset_off",
            style = MaterialIcon.Style.Normal
        )
        val Healing = MaterialIcon(
            name = "healing",
            style = MaterialIcon.Style.Normal
        )
        val HealthAndSafety = MaterialIcon(
            name = "health_and_safety",
            style = MaterialIcon.Style.Normal
        )
        val Hearing = MaterialIcon(
            name = "hearing",
            style = MaterialIcon.Style.Normal
        )
        val HearingDisabled = MaterialIcon(
            name = "hearing_disabled",
            style = MaterialIcon.Style.Normal
        )
        val HeartBroken = MaterialIcon(
            name = "heart_broken",
            style = MaterialIcon.Style.Normal
        )
        val HeatPump = MaterialIcon(
            name = "heat_pump",
            style = MaterialIcon.Style.Normal
        )
        val Height = MaterialIcon(
            name = "height",
            style = MaterialIcon.Style.Normal
        )
        val Help = MaterialIcon(
            name = "help",
            style = MaterialIcon.Style.Normal
        )
        val HelpCenter = MaterialIcon(
            name = "help_center",
            style = MaterialIcon.Style.Normal
        )
        val HelpOutline = MaterialIcon(
            name = "help_outline",
            style = MaterialIcon.Style.Normal
        )
        val Hevc = MaterialIcon(
            name = "hevc",
            style = MaterialIcon.Style.Normal
        )
        val Hexagon = MaterialIcon(
            name = "hexagon",
            style = MaterialIcon.Style.Normal
        )
        val HideImage = MaterialIcon(
            name = "hide_image",
            style = MaterialIcon.Style.Normal
        )
        val HideSource = MaterialIcon(
            name = "hide_source",
            style = MaterialIcon.Style.Normal
        )
        val HighQuality = MaterialIcon(
            name = "high_quality",
            style = MaterialIcon.Style.Normal
        )
        val Highlight = MaterialIcon(
            name = "highlight",
            style = MaterialIcon.Style.Normal
        )
        val HighlightAlt = MaterialIcon(
            name = "highlight_alt",
            style = MaterialIcon.Style.Normal
        )
        val HighlightOff = MaterialIcon(
            name = "highlight_off",
            style = MaterialIcon.Style.Normal
        )
        val Hiking = MaterialIcon(
            name = "hiking",
            style = MaterialIcon.Style.Normal
        )
        val History = MaterialIcon(
            name = "history",
            style = MaterialIcon.Style.Normal
        )
        val HistoryEdu = MaterialIcon(
            name = "history_edu",
            style = MaterialIcon.Style.Normal
        )
        val HistoryToggleOff = MaterialIcon(
            name = "history_toggle_off",
            style = MaterialIcon.Style.Normal
        )
        val Hive = MaterialIcon(
            name = "hive",
            style = MaterialIcon.Style.Normal
        )
        val Hls = MaterialIcon(
            name = "hls",
            style = MaterialIcon.Style.Normal
        )
        val HlsOff = MaterialIcon(
            name = "hls_off",
            style = MaterialIcon.Style.Normal
        )
        val HolidayVillage = MaterialIcon(
            name = "holiday_village",
            style = MaterialIcon.Style.Normal
        )
        val Home = MaterialIcon(
            name = "home",
            style = MaterialIcon.Style.Normal
        )
        val HomeMax = MaterialIcon(
            name = "home_max",
            style = MaterialIcon.Style.Normal
        )
        val HomeMini = MaterialIcon(
            name = "home_mini",
            style = MaterialIcon.Style.Normal
        )
        val HomeRepairService = MaterialIcon(
            name = "home_repair_service",
            style = MaterialIcon.Style.Normal
        )
        val HomeWork = MaterialIcon(
            name = "home_work",
            style = MaterialIcon.Style.Normal
        )
        val HorizontalDistribute = MaterialIcon(
            name = "horizontal_distribute",
            style = MaterialIcon.Style.Normal
        )
        val HorizontalRule = MaterialIcon(
            name = "horizontal_rule",
            style = MaterialIcon.Style.Normal
        )
        val HorizontalSplit = MaterialIcon(
            name = "horizontal_split",
            style = MaterialIcon.Style.Normal
        )
        val HotTub = MaterialIcon(
            name = "hot_tub",
            style = MaterialIcon.Style.Normal
        )
        val Hotel = MaterialIcon(
            name = "hotel",
            style = MaterialIcon.Style.Normal
        )
        val HotelClass = MaterialIcon(
            name = "hotel_class",
            style = MaterialIcon.Style.Normal
        )
        val HourglassBottom = MaterialIcon(
            name = "hourglass_bottom",
            style = MaterialIcon.Style.Normal
        )
        val HourglassDisabled = MaterialIcon(
            name = "hourglass_disabled",
            style = MaterialIcon.Style.Normal
        )
        val HourglassEmpty = MaterialIcon(
            name = "hourglass_empty",
            style = MaterialIcon.Style.Normal
        )
        val HourglassFull = MaterialIcon(
            name = "hourglass_full",
            style = MaterialIcon.Style.Normal
        )
        val HourglassTop = MaterialIcon(
            name = "hourglass_top",
            style = MaterialIcon.Style.Normal
        )
        val House = MaterialIcon(
            name = "house",
            style = MaterialIcon.Style.Normal
        )
        val HouseSiding = MaterialIcon(
            name = "house_siding",
            style = MaterialIcon.Style.Normal
        )
        val Houseboat = MaterialIcon(
            name = "houseboat",
            style = MaterialIcon.Style.Normal
        )
        val HowToReg = MaterialIcon(
            name = "how_to_reg",
            style = MaterialIcon.Style.Normal
        )
        val HowToVote = MaterialIcon(
            name = "how_to_vote",
            style = MaterialIcon.Style.Normal
        )
        val Html = MaterialIcon(
            name = "html",
            style = MaterialIcon.Style.Normal
        )
        val Http = MaterialIcon(
            name = "http",
            style = MaterialIcon.Style.Normal
        )
        val Https = MaterialIcon(
            name = "https",
            style = MaterialIcon.Style.Normal
        )
        val Hub = MaterialIcon(
            name = "hub",
            style = MaterialIcon.Style.Normal
        )
        val Hvac = MaterialIcon(
            name = "hvac",
            style = MaterialIcon.Style.Normal
        )
        val IceSkating = MaterialIcon(
            name = "ice_skating",
            style = MaterialIcon.Style.Normal
        )
        val Icecream = MaterialIcon(
            name = "icecream",
            style = MaterialIcon.Style.Normal
        )
        val Image = MaterialIcon(
            name = "image",
            style = MaterialIcon.Style.Normal
        )
        val ImageAspectRatio = MaterialIcon(
            name = "image_aspect_ratio",
            style = MaterialIcon.Style.Normal
        )
        val ImageNotSupported = MaterialIcon(
            name = "image_not_supported",
            style = MaterialIcon.Style.Normal
        )
        val ImageSearch = MaterialIcon(
            name = "image_search",
            style = MaterialIcon.Style.Normal
        )
        val ImagesearchRoller = MaterialIcon(
            name = "imagesearch_roller",
            style = MaterialIcon.Style.Normal
        )
        val ImportContacts = MaterialIcon(
            name = "import_contacts",
            style = MaterialIcon.Style.Normal
        )
        val ImportExport = MaterialIcon(
            name = "import_export",
            style = MaterialIcon.Style.Normal
        )
        val ImportantDevices = MaterialIcon(
            name = "important_devices",
            style = MaterialIcon.Style.Normal
        )
        val Inbox = MaterialIcon(
            name = "inbox",
            style = MaterialIcon.Style.Normal
        )
        val IncompleteCircle = MaterialIcon(
            name = "incomplete_circle",
            style = MaterialIcon.Style.Normal
        )
        val IndeterminateCheckBox = MaterialIcon(
            name = "indeterminate_check_box",
            style = MaterialIcon.Style.Normal
        )
        val Info = MaterialIcon(
            name = "info",
            style = MaterialIcon.Style.Normal
        )
        val Input = MaterialIcon(
            name = "input",
            style = MaterialIcon.Style.Normal
        )
        val InsertChart = MaterialIcon(
            name = "insert_chart",
            style = MaterialIcon.Style.Normal
        )
        val InsertChartOutlined = MaterialIcon(
            name = "insert_chart_outlined",
            style = MaterialIcon.Style.Normal
        )
        val InsertComment = MaterialIcon(
            name = "insert_comment",
            style = MaterialIcon.Style.Normal
        )
        val InsertDriveFile = MaterialIcon(
            name = "insert_drive_file",
            style = MaterialIcon.Style.Normal
        )
        val InsertEmoticon = MaterialIcon(
            name = "insert_emoticon",
            style = MaterialIcon.Style.Normal
        )
        val InsertInvitation = MaterialIcon(
            name = "insert_invitation",
            style = MaterialIcon.Style.Normal
        )
        val InsertLink = MaterialIcon(
            name = "insert_link",
            style = MaterialIcon.Style.Normal
        )
        val InsertPageBreak = MaterialIcon(
            name = "insert_page_break",
            style = MaterialIcon.Style.Normal
        )
        val InsertPhoto = MaterialIcon(
            name = "insert_photo",
            style = MaterialIcon.Style.Normal
        )
        val Insights = MaterialIcon(
            name = "insights",
            style = MaterialIcon.Style.Normal
        )
        val InstallDesktop = MaterialIcon(
            name = "install_desktop",
            style = MaterialIcon.Style.Normal
        )
        val InstallMobile = MaterialIcon(
            name = "install_mobile",
            style = MaterialIcon.Style.Normal
        )
        val IntegrationInstructions = MaterialIcon(
            name = "integration_instructions",
            style = MaterialIcon.Style.Normal
        )
        val Interests = MaterialIcon(
            name = "interests",
            style = MaterialIcon.Style.Normal
        )
        val InterpreterMode = MaterialIcon(
            name = "interpreter_mode",
            style = MaterialIcon.Style.Normal
        )
        val Inventory = MaterialIcon(
            name = "inventory",
            style = MaterialIcon.Style.Normal
        )
        val Inventory2 = MaterialIcon(
            name = "inventory_2",
            style = MaterialIcon.Style.Normal
        )
        val InvertColors = MaterialIcon(
            name = "invert_colors",
            style = MaterialIcon.Style.Normal
        )
        val InvertColorsOff = MaterialIcon(
            name = "invert_colors_off",
            style = MaterialIcon.Style.Normal
        )
        val IosShare = MaterialIcon(
            name = "ios_share",
            style = MaterialIcon.Style.Normal
        )
        val Iron = MaterialIcon(
            name = "iron",
            style = MaterialIcon.Style.Normal
        )
        val Iso = MaterialIcon(
            name = "iso",
            style = MaterialIcon.Style.Normal
        )
        val Javascript = MaterialIcon(
            name = "javascript",
            style = MaterialIcon.Style.Normal
        )
        val JoinFull = MaterialIcon(
            name = "join_full",
            style = MaterialIcon.Style.Normal
        )
        val JoinInner = MaterialIcon(
            name = "join_inner",
            style = MaterialIcon.Style.Normal
        )
        val JoinLeft = MaterialIcon(
            name = "join_left",
            style = MaterialIcon.Style.Normal
        )
        val JoinRight = MaterialIcon(
            name = "join_right",
            style = MaterialIcon.Style.Normal
        )
        val Kayaking = MaterialIcon(
            name = "kayaking",
            style = MaterialIcon.Style.Normal
        )
        val KebabDining = MaterialIcon(
            name = "kebab_dining",
            style = MaterialIcon.Style.Normal
        )
        val Key = MaterialIcon(
            name = "key",
            style = MaterialIcon.Style.Normal
        )
        val KeyOff = MaterialIcon(
            name = "key_off",
            style = MaterialIcon.Style.Normal
        )
        val Keyboard = MaterialIcon(
            name = "keyboard",
            style = MaterialIcon.Style.Normal
        )
        val KeyboardAlt = MaterialIcon(
            name = "keyboard_alt",
            style = MaterialIcon.Style.Normal
        )
        val KeyboardArrowDown = MaterialIcon(
            name = "keyboard_arrow_down",
            style = MaterialIcon.Style.Normal
        )
        val KeyboardArrowLeft = MaterialIcon(
            name = "keyboard_arrow_left",
            style = MaterialIcon.Style.Normal
        )
        val KeyboardArrowRight = MaterialIcon(
            name = "keyboard_arrow_right",
            style = MaterialIcon.Style.Normal
        )
        val KeyboardArrowUp = MaterialIcon(
            name = "keyboard_arrow_up",
            style = MaterialIcon.Style.Normal
        )
        val KeyboardBackspace = MaterialIcon(
            name = "keyboard_backspace",
            style = MaterialIcon.Style.Normal
        )
        val KeyboardCapslock = MaterialIcon(
            name = "keyboard_capslock",
            style = MaterialIcon.Style.Normal
        )
        val KeyboardCommandKey = MaterialIcon(
            name = "keyboard_command_key",
            style = MaterialIcon.Style.Normal
        )
        val KeyboardControlKey = MaterialIcon(
            name = "keyboard_control_key",
            style = MaterialIcon.Style.Normal
        )
        val KeyboardDoubleArrowDown = MaterialIcon(
            name = "keyboard_double_arrow_down",
            style = MaterialIcon.Style.Normal
        )
        val KeyboardDoubleArrowLeft = MaterialIcon(
            name = "keyboard_double_arrow_left",
            style = MaterialIcon.Style.Normal
        )
        val KeyboardDoubleArrowRight = MaterialIcon(
            name = "keyboard_double_arrow_right",
            style = MaterialIcon.Style.Normal
        )
        val KeyboardDoubleArrowUp = MaterialIcon(
            name = "keyboard_double_arrow_up",
            style = MaterialIcon.Style.Normal
        )
        val KeyboardHide = MaterialIcon(
            name = "keyboard_hide",
            style = MaterialIcon.Style.Normal
        )
        val KeyboardOptionKey = MaterialIcon(
            name = "keyboard_option_key",
            style = MaterialIcon.Style.Normal
        )
        val KeyboardReturn = MaterialIcon(
            name = "keyboard_return",
            style = MaterialIcon.Style.Normal
        )
        val KeyboardTab = MaterialIcon(
            name = "keyboard_tab",
            style = MaterialIcon.Style.Normal
        )
        val KeyboardVoice = MaterialIcon(
            name = "keyboard_voice",
            style = MaterialIcon.Style.Normal
        )
        val KingBed = MaterialIcon(
            name = "king_bed",
            style = MaterialIcon.Style.Normal
        )
        val Kitchen = MaterialIcon(
            name = "kitchen",
            style = MaterialIcon.Style.Normal
        )
        val Kitesurfing = MaterialIcon(
            name = "kitesurfing",
            style = MaterialIcon.Style.Normal
        )
        val Label = MaterialIcon(
            name = "label",
            style = MaterialIcon.Style.Normal
        )
        val LabelImportant = MaterialIcon(
            name = "label_important",
            style = MaterialIcon.Style.Normal
        )
        val LabelOff = MaterialIcon(
            name = "label_off",
            style = MaterialIcon.Style.Normal
        )
        val Lan = MaterialIcon(
            name = "lan",
            style = MaterialIcon.Style.Normal
        )
        val Landscape = MaterialIcon(
            name = "landscape",
            style = MaterialIcon.Style.Normal
        )
        val Landslide = MaterialIcon(
            name = "landslide",
            style = MaterialIcon.Style.Normal
        )
        val Language = MaterialIcon(
            name = "language",
            style = MaterialIcon.Style.Normal
        )
        val Laptop = MaterialIcon(
            name = "laptop",
            style = MaterialIcon.Style.Normal
        )
        val LaptopChromebook = MaterialIcon(
            name = "laptop_chromebook",
            style = MaterialIcon.Style.Normal
        )
        val LaptopMac = MaterialIcon(
            name = "laptop_mac",
            style = MaterialIcon.Style.Normal
        )
        val LaptopWindows = MaterialIcon(
            name = "laptop_windows",
            style = MaterialIcon.Style.Normal
        )
        val LastPage = MaterialIcon(
            name = "last_page",
            style = MaterialIcon.Style.Normal
        )
        val Launch = MaterialIcon(
            name = "launch",
            style = MaterialIcon.Style.Normal
        )
        val Layers = MaterialIcon(
            name = "layers",
            style = MaterialIcon.Style.Normal
        )
        val LayersClear = MaterialIcon(
            name = "layers_clear",
            style = MaterialIcon.Style.Normal
        )
        val Leaderboard = MaterialIcon(
            name = "leaderboard",
            style = MaterialIcon.Style.Normal
        )
        val LeakAdd = MaterialIcon(
            name = "leak_add",
            style = MaterialIcon.Style.Normal
        )
        val LeakRemove = MaterialIcon(
            name = "leak_remove",
            style = MaterialIcon.Style.Normal
        )
        val LegendToggle = MaterialIcon(
            name = "legend_toggle",
            style = MaterialIcon.Style.Normal
        )
        val Lens = MaterialIcon(
            name = "lens",
            style = MaterialIcon.Style.Normal
        )
        val LensBlur = MaterialIcon(
            name = "lens_blur",
            style = MaterialIcon.Style.Normal
        )
        val LibraryAdd = MaterialIcon(
            name = "library_add",
            style = MaterialIcon.Style.Normal
        )
        val LibraryAddCheck = MaterialIcon(
            name = "library_add_check",
            style = MaterialIcon.Style.Normal
        )
        val LibraryBooks = MaterialIcon(
            name = "library_books",
            style = MaterialIcon.Style.Normal
        )
        val LibraryMusic = MaterialIcon(
            name = "library_music",
            style = MaterialIcon.Style.Normal
        )
        val Light = MaterialIcon(
            name = "light",
            style = MaterialIcon.Style.Normal
        )
        val LightMode = MaterialIcon(
            name = "light_mode",
            style = MaterialIcon.Style.Normal
        )
        val Lightbulb = MaterialIcon(
            name = "lightbulb",
            style = MaterialIcon.Style.Normal
        )
        val LightbulbCircle = MaterialIcon(
            name = "lightbulb_circle",
            style = MaterialIcon.Style.Normal
        )
        val LineAxis = MaterialIcon(
            name = "line_axis",
            style = MaterialIcon.Style.Normal
        )
        val LineStyle = MaterialIcon(
            name = "line_style",
            style = MaterialIcon.Style.Normal
        )
        val LineWeight = MaterialIcon(
            name = "line_weight",
            style = MaterialIcon.Style.Normal
        )
        val LinearScale = MaterialIcon(
            name = "linear_scale",
            style = MaterialIcon.Style.Normal
        )
        val Link = MaterialIcon(
            name = "link",
            style = MaterialIcon.Style.Normal
        )
        val LinkOff = MaterialIcon(
            name = "link_off",
            style = MaterialIcon.Style.Normal
        )
        val LinkedCamera = MaterialIcon(
            name = "linked_camera",
            style = MaterialIcon.Style.Normal
        )
        val Liquor = MaterialIcon(
            name = "liquor",
            style = MaterialIcon.Style.Normal
        )
        val List = MaterialIcon(
            name = "list",
            style = MaterialIcon.Style.Normal
        )
        val ListAlt = MaterialIcon(
            name = "list_alt",
            style = MaterialIcon.Style.Normal
        )
        val LiveHelp = MaterialIcon(
            name = "live_help",
            style = MaterialIcon.Style.Normal
        )
        val LiveTv = MaterialIcon(
            name = "live_tv",
            style = MaterialIcon.Style.Normal
        )
        val Living = MaterialIcon(
            name = "living",
            style = MaterialIcon.Style.Normal
        )
        val LocalActivity = MaterialIcon(
            name = "local_activity",
            style = MaterialIcon.Style.Normal
        )
        val LocalAirport = MaterialIcon(
            name = "local_airport",
            style = MaterialIcon.Style.Normal
        )
        val LocalAtm = MaterialIcon(
            name = "local_atm",
            style = MaterialIcon.Style.Normal
        )
        val LocalBar = MaterialIcon(
            name = "local_bar",
            style = MaterialIcon.Style.Normal
        )
        val LocalCafe = MaterialIcon(
            name = "local_cafe",
            style = MaterialIcon.Style.Normal
        )
        val LocalCarWash = MaterialIcon(
            name = "local_car_wash",
            style = MaterialIcon.Style.Normal
        )
        val LocalConvenienceStore = MaterialIcon(
            name = "local_convenience_store",
            style = MaterialIcon.Style.Normal
        )
        val LocalDining = MaterialIcon(
            name = "local_dining",
            style = MaterialIcon.Style.Normal
        )
        val LocalDrink = MaterialIcon(
            name = "local_drink",
            style = MaterialIcon.Style.Normal
        )
        val LocalFireDepartment = MaterialIcon(
            name = "local_fire_department",
            style = MaterialIcon.Style.Normal
        )
        val LocalFlorist = MaterialIcon(
            name = "local_florist",
            style = MaterialIcon.Style.Normal
        )
        val LocalGasStation = MaterialIcon(
            name = "local_gas_station",
            style = MaterialIcon.Style.Normal
        )
        val LocalGroceryStore = MaterialIcon(
            name = "local_grocery_store",
            style = MaterialIcon.Style.Normal
        )
        val LocalHospital = MaterialIcon(
            name = "local_hospital",
            style = MaterialIcon.Style.Normal
        )
        val LocalHotel = MaterialIcon(
            name = "local_hotel",
            style = MaterialIcon.Style.Normal
        )
        val LocalLaundryService = MaterialIcon(
            name = "local_laundry_service",
            style = MaterialIcon.Style.Normal
        )
        val LocalLibrary = MaterialIcon(
            name = "local_library",
            style = MaterialIcon.Style.Normal
        )
        val LocalMall = MaterialIcon(
            name = "local_mall",
            style = MaterialIcon.Style.Normal
        )
        val LocalMovies = MaterialIcon(
            name = "local_movies",
            style = MaterialIcon.Style.Normal
        )
        val LocalOffer = MaterialIcon(
            name = "local_offer",
            style = MaterialIcon.Style.Normal
        )
        val LocalParking = MaterialIcon(
            name = "local_parking",
            style = MaterialIcon.Style.Normal
        )
        val LocalPharmacy = MaterialIcon(
            name = "local_pharmacy",
            style = MaterialIcon.Style.Normal
        )
        val LocalPhone = MaterialIcon(
            name = "local_phone",
            style = MaterialIcon.Style.Normal
        )
        val LocalPizza = MaterialIcon(
            name = "local_pizza",
            style = MaterialIcon.Style.Normal
        )
        val LocalPlay = MaterialIcon(
            name = "local_play",
            style = MaterialIcon.Style.Normal
        )
        val LocalPolice = MaterialIcon(
            name = "local_police",
            style = MaterialIcon.Style.Normal
        )
        val LocalPostOffice = MaterialIcon(
            name = "local_post_office",
            style = MaterialIcon.Style.Normal
        )
        val LocalPrintshop = MaterialIcon(
            name = "local_printshop",
            style = MaterialIcon.Style.Normal
        )
        val LocalSee = MaterialIcon(
            name = "local_see",
            style = MaterialIcon.Style.Normal
        )
        val LocalShipping = MaterialIcon(
            name = "local_shipping",
            style = MaterialIcon.Style.Normal
        )
        val LocalTaxi = MaterialIcon(
            name = "local_taxi",
            style = MaterialIcon.Style.Normal
        )
        val LocationCity = MaterialIcon(
            name = "location_city",
            style = MaterialIcon.Style.Normal
        )
        val LocationDisabled = MaterialIcon(
            name = "location_disabled",
            style = MaterialIcon.Style.Normal
        )
        val LocationOff = MaterialIcon(
            name = "location_off",
            style = MaterialIcon.Style.Normal
        )
        val LocationOn = MaterialIcon(
            name = "location_on",
            style = MaterialIcon.Style.Normal
        )
        val LocationSearching = MaterialIcon(
            name = "location_searching",
            style = MaterialIcon.Style.Normal
        )
        val Lock = MaterialIcon(
            name = "lock",
            style = MaterialIcon.Style.Normal
        )
        val LockClock = MaterialIcon(
            name = "lock_clock",
            style = MaterialIcon.Style.Normal
        )
        val LockOpen = MaterialIcon(
            name = "lock_open",
            style = MaterialIcon.Style.Normal
        )
        val LockPerson = MaterialIcon(
            name = "lock_person",
            style = MaterialIcon.Style.Normal
        )
        val LockReset = MaterialIcon(
            name = "lock_reset",
            style = MaterialIcon.Style.Normal
        )
        val Login = MaterialIcon(
            name = "login",
            style = MaterialIcon.Style.Normal
        )
        val LogoDev = MaterialIcon(
            name = "logo_dev",
            style = MaterialIcon.Style.Normal
        )
        val Logout = MaterialIcon(
            name = "logout",
            style = MaterialIcon.Style.Normal
        )
        val Looks = MaterialIcon(
            name = "looks",
            style = MaterialIcon.Style.Normal
        )
        val Looks3 = MaterialIcon(
            name = "looks_3",
            style = MaterialIcon.Style.Normal
        )
        val Looks4 = MaterialIcon(
            name = "looks_4",
            style = MaterialIcon.Style.Normal
        )
        val Looks5 = MaterialIcon(
            name = "looks_5",
            style = MaterialIcon.Style.Normal
        )
        val Looks6 = MaterialIcon(
            name = "looks_6",
            style = MaterialIcon.Style.Normal
        )
        val LooksOne = MaterialIcon(
            name = "looks_one",
            style = MaterialIcon.Style.Normal
        )
        val LooksTwo = MaterialIcon(
            name = "looks_two",
            style = MaterialIcon.Style.Normal
        )
        val Loop = MaterialIcon(
            name = "loop",
            style = MaterialIcon.Style.Normal
        )
        val Loupe = MaterialIcon(
            name = "loupe",
            style = MaterialIcon.Style.Normal
        )
        val LowPriority = MaterialIcon(
            name = "low_priority",
            style = MaterialIcon.Style.Normal
        )
        val Loyalty = MaterialIcon(
            name = "loyalty",
            style = MaterialIcon.Style.Normal
        )
        val LteMobiledata = MaterialIcon(
            name = "lte_mobiledata",
            style = MaterialIcon.Style.Normal
        )
        val LtePlusMobiledata = MaterialIcon(
            name = "lte_plus_mobiledata",
            style = MaterialIcon.Style.Normal
        )
        val Luggage = MaterialIcon(
            name = "luggage",
            style = MaterialIcon.Style.Normal
        )
        val LunchDining = MaterialIcon(
            name = "lunch_dining",
            style = MaterialIcon.Style.Normal
        )
        val Lyrics = MaterialIcon(
            name = "lyrics",
            style = MaterialIcon.Style.Normal
        )
        val MacroOff = MaterialIcon(
            name = "macro_off",
            style = MaterialIcon.Style.Normal
        )
        val Mail = MaterialIcon(
            name = "mail",
            style = MaterialIcon.Style.Normal
        )
        val MailLock = MaterialIcon(
            name = "mail_lock",
            style = MaterialIcon.Style.Normal
        )
        val MailOutline = MaterialIcon(
            name = "mail_outline",
            style = MaterialIcon.Style.Normal
        )
        val Male = MaterialIcon(
            name = "male",
            style = MaterialIcon.Style.Normal
        )
        val Man = MaterialIcon(
            name = "man",
            style = MaterialIcon.Style.Normal
        )
        val Man2 = MaterialIcon(
            name = "man_2",
            style = MaterialIcon.Style.Normal
        )
        val Man3 = MaterialIcon(
            name = "man_3",
            style = MaterialIcon.Style.Normal
        )
        val Man4 = MaterialIcon(
            name = "man_4",
            style = MaterialIcon.Style.Normal
        )
        val ManageAccounts = MaterialIcon(
            name = "manage_accounts",
            style = MaterialIcon.Style.Normal
        )
        val ManageHistory = MaterialIcon(
            name = "manage_history",
            style = MaterialIcon.Style.Normal
        )
        val ManageSearch = MaterialIcon(
            name = "manage_search",
            style = MaterialIcon.Style.Normal
        )
        val Map = MaterialIcon(
            name = "map",
            style = MaterialIcon.Style.Normal
        )
        val MapsHomeWork = MaterialIcon(
            name = "maps_home_work",
            style = MaterialIcon.Style.Normal
        )
        val MapsUgc = MaterialIcon(
            name = "maps_ugc",
            style = MaterialIcon.Style.Normal
        )
        val Margin = MaterialIcon(
            name = "margin",
            style = MaterialIcon.Style.Normal
        )
        val MarkAsUnread = MaterialIcon(
            name = "mark_as_unread",
            style = MaterialIcon.Style.Normal
        )
        val MarkChatRead = MaterialIcon(
            name = "mark_chat_read",
            style = MaterialIcon.Style.Normal
        )
        val MarkChatUnread = MaterialIcon(
            name = "mark_chat_unread",
            style = MaterialIcon.Style.Normal
        )
        val MarkEmailRead = MaterialIcon(
            name = "mark_email_read",
            style = MaterialIcon.Style.Normal
        )
        val MarkEmailUnread = MaterialIcon(
            name = "mark_email_unread",
            style = MaterialIcon.Style.Normal
        )
        val MarkUnreadChatAlt = MaterialIcon(
            name = "mark_unread_chat_alt",
            style = MaterialIcon.Style.Normal
        )
        val Markunread = MaterialIcon(
            name = "markunread",
            style = MaterialIcon.Style.Normal
        )
        val MarkunreadMailbox = MaterialIcon(
            name = "markunread_mailbox",
            style = MaterialIcon.Style.Normal
        )
        val Masks = MaterialIcon(
            name = "masks",
            style = MaterialIcon.Style.Normal
        )
        val Maximize = MaterialIcon(
            name = "maximize",
            style = MaterialIcon.Style.Normal
        )
        val MediaBluetoothOff = MaterialIcon(
            name = "media_bluetooth_off",
            style = MaterialIcon.Style.Normal
        )
        val MediaBluetoothOn = MaterialIcon(
            name = "media_bluetooth_on",
            style = MaterialIcon.Style.Normal
        )
        val Mediation = MaterialIcon(
            name = "mediation",
            style = MaterialIcon.Style.Normal
        )
        val MedicalInformation = MaterialIcon(
            name = "medical_information",
            style = MaterialIcon.Style.Normal
        )
        val MedicalServices = MaterialIcon(
            name = "medical_services",
            style = MaterialIcon.Style.Normal
        )
        val Medication = MaterialIcon(
            name = "medication",
            style = MaterialIcon.Style.Normal
        )
        val MedicationLiquid = MaterialIcon(
            name = "medication_liquid",
            style = MaterialIcon.Style.Normal
        )
        val MeetingRoom = MaterialIcon(
            name = "meeting_room",
            style = MaterialIcon.Style.Normal
        )
        val Memory = MaterialIcon(
            name = "memory",
            style = MaterialIcon.Style.Normal
        )
        val Menu = MaterialIcon(
            name = "menu",
            style = MaterialIcon.Style.Normal
        )
        val MenuBook = MaterialIcon(
            name = "menu_book",
            style = MaterialIcon.Style.Normal
        )
        val MenuOpen = MaterialIcon(
            name = "menu_open",
            style = MaterialIcon.Style.Normal
        )
        val Merge = MaterialIcon(
            name = "merge",
            style = MaterialIcon.Style.Normal
        )
        val MergeType = MaterialIcon(
            name = "merge_type",
            style = MaterialIcon.Style.Normal
        )
        val Message = MaterialIcon(
            name = "message",
            style = MaterialIcon.Style.Normal
        )
        val Mic = MaterialIcon(
            name = "mic",
            style = MaterialIcon.Style.Normal
        )
        val MicExternalOff = MaterialIcon(
            name = "mic_external_off",
            style = MaterialIcon.Style.Normal
        )
        val MicExternalOn = MaterialIcon(
            name = "mic_external_on",
            style = MaterialIcon.Style.Normal
        )
        val MicNone = MaterialIcon(
            name = "mic_none",
            style = MaterialIcon.Style.Normal
        )
        val MicOff = MaterialIcon(
            name = "mic_off",
            style = MaterialIcon.Style.Normal
        )
        val Microwave = MaterialIcon(
            name = "microwave",
            style = MaterialIcon.Style.Normal
        )
        val MilitaryTech = MaterialIcon(
            name = "military_tech",
            style = MaterialIcon.Style.Normal
        )
        val Minimize = MaterialIcon(
            name = "minimize",
            style = MaterialIcon.Style.Normal
        )
        val MinorCrash = MaterialIcon(
            name = "minor_crash",
            style = MaterialIcon.Style.Normal
        )
        val MiscellaneousServices = MaterialIcon(
            name = "miscellaneous_services",
            style = MaterialIcon.Style.Normal
        )
        val MissedVideoCall = MaterialIcon(
            name = "missed_video_call",
            style = MaterialIcon.Style.Normal
        )
        val Mms = MaterialIcon(
            name = "mms",
            style = MaterialIcon.Style.Normal
        )
        val MobileFriendly = MaterialIcon(
            name = "mobile_friendly",
            style = MaterialIcon.Style.Normal
        )
        val MobileOff = MaterialIcon(
            name = "mobile_off",
            style = MaterialIcon.Style.Normal
        )
        val MobileScreenShare = MaterialIcon(
            name = "mobile_screen_share",
            style = MaterialIcon.Style.Normal
        )
        val MobiledataOff = MaterialIcon(
            name = "mobiledata_off",
            style = MaterialIcon.Style.Normal
        )
        val Mode = MaterialIcon(
            name = "mode",
            style = MaterialIcon.Style.Normal
        )
        val ModeComment = MaterialIcon(
            name = "mode_comment",
            style = MaterialIcon.Style.Normal
        )
        val ModeEdit = MaterialIcon(
            name = "mode_edit",
            style = MaterialIcon.Style.Normal
        )
        val ModeEditOutline = MaterialIcon(
            name = "mode_edit_outline",
            style = MaterialIcon.Style.Normal
        )
        val ModeFanOff = MaterialIcon(
            name = "mode_fan_off",
            style = MaterialIcon.Style.Normal
        )
        val ModeNight = MaterialIcon(
            name = "mode_night",
            style = MaterialIcon.Style.Normal
        )
        val ModeOfTravel = MaterialIcon(
            name = "mode_of_travel",
            style = MaterialIcon.Style.Normal
        )
        val ModeStandby = MaterialIcon(
            name = "mode_standby",
            style = MaterialIcon.Style.Normal
        )
        val ModelTraining = MaterialIcon(
            name = "model_training",
            style = MaterialIcon.Style.Normal
        )
        val MonetizationOn = MaterialIcon(
            name = "monetization_on",
            style = MaterialIcon.Style.Normal
        )
        val Money = MaterialIcon(
            name = "money",
            style = MaterialIcon.Style.Normal
        )
        val MoneyOff = MaterialIcon(
            name = "money_off",
            style = MaterialIcon.Style.Normal
        )
        val MoneyOffCsred = MaterialIcon(
            name = "money_off_csred",
            style = MaterialIcon.Style.Normal
        )
        val Monitor = MaterialIcon(
            name = "monitor",
            style = MaterialIcon.Style.Normal
        )
        val MonitorHeart = MaterialIcon(
            name = "monitor_heart",
            style = MaterialIcon.Style.Normal
        )
        val MonitorWeight = MaterialIcon(
            name = "monitor_weight",
            style = MaterialIcon.Style.Normal
        )
        val MonochromePhotos = MaterialIcon(
            name = "monochrome_photos",
            style = MaterialIcon.Style.Normal
        )
        val Mood = MaterialIcon(
            name = "mood",
            style = MaterialIcon.Style.Normal
        )
        val MoodBad = MaterialIcon(
            name = "mood_bad",
            style = MaterialIcon.Style.Normal
        )
        val Moped = MaterialIcon(
            name = "moped",
            style = MaterialIcon.Style.Normal
        )
        val More = MaterialIcon(
            name = "more",
            style = MaterialIcon.Style.Normal
        )
        val MoreHoriz = MaterialIcon(
            name = "more_horiz",
            style = MaterialIcon.Style.Normal
        )
        val MoreTime = MaterialIcon(
            name = "more_time",
            style = MaterialIcon.Style.Normal
        )
        val MoreVert = MaterialIcon(
            name = "more_vert",
            style = MaterialIcon.Style.Normal
        )
        val Mosque = MaterialIcon(
            name = "mosque",
            style = MaterialIcon.Style.Normal
        )
        val MotionPhotosAuto = MaterialIcon(
            name = "motion_photos_auto",
            style = MaterialIcon.Style.Normal
        )
        val MotionPhotosOff = MaterialIcon(
            name = "motion_photos_off",
            style = MaterialIcon.Style.Normal
        )
        val MotionPhotosOn = MaterialIcon(
            name = "motion_photos_on",
            style = MaterialIcon.Style.Normal
        )
        val MotionPhotosPause = MaterialIcon(
            name = "motion_photos_pause",
            style = MaterialIcon.Style.Normal
        )
        val MotionPhotosPaused = MaterialIcon(
            name = "motion_photos_paused",
            style = MaterialIcon.Style.Normal
        )
        val Mouse = MaterialIcon(
            name = "mouse",
            style = MaterialIcon.Style.Normal
        )
        val MoveDown = MaterialIcon(
            name = "move_down",
            style = MaterialIcon.Style.Normal
        )
        val MoveToInbox = MaterialIcon(
            name = "move_to_inbox",
            style = MaterialIcon.Style.Normal
        )
        val MoveUp = MaterialIcon(
            name = "move_up",
            style = MaterialIcon.Style.Normal
        )
        val Movie = MaterialIcon(
            name = "movie",
            style = MaterialIcon.Style.Normal
        )
        val MovieCreation = MaterialIcon(
            name = "movie_creation",
            style = MaterialIcon.Style.Normal
        )
        val MovieFilter = MaterialIcon(
            name = "movie_filter",
            style = MaterialIcon.Style.Normal
        )
        val Moving = MaterialIcon(
            name = "moving",
            style = MaterialIcon.Style.Normal
        )
        val Mp = MaterialIcon(
            name = "mp",
            style = MaterialIcon.Style.Normal
        )
        val MultilineChart = MaterialIcon(
            name = "multiline_chart",
            style = MaterialIcon.Style.Normal
        )
        val MultipleStop = MaterialIcon(
            name = "multiple_stop",
            style = MaterialIcon.Style.Normal
        )
        val Museum = MaterialIcon(
            name = "museum",
            style = MaterialIcon.Style.Normal
        )
        val MusicNote = MaterialIcon(
            name = "music_note",
            style = MaterialIcon.Style.Normal
        )
        val MusicOff = MaterialIcon(
            name = "music_off",
            style = MaterialIcon.Style.Normal
        )
        val MusicVideo = MaterialIcon(
            name = "music_video",
            style = MaterialIcon.Style.Normal
        )
        val MyLocation = MaterialIcon(
            name = "my_location",
            style = MaterialIcon.Style.Normal
        )
        val Nat = MaterialIcon(
            name = "nat",
            style = MaterialIcon.Style.Normal
        )
        val Nature = MaterialIcon(
            name = "nature",
            style = MaterialIcon.Style.Normal
        )
        val NaturePeople = MaterialIcon(
            name = "nature_people",
            style = MaterialIcon.Style.Normal
        )
        val NavigateBefore = MaterialIcon(
            name = "navigate_before",
            style = MaterialIcon.Style.Normal
        )
        val NavigateNext = MaterialIcon(
            name = "navigate_next",
            style = MaterialIcon.Style.Normal
        )
        val Navigation = MaterialIcon(
            name = "navigation",
            style = MaterialIcon.Style.Normal
        )
        val NearMe = MaterialIcon(
            name = "near_me",
            style = MaterialIcon.Style.Normal
        )
        val NearMeDisabled = MaterialIcon(
            name = "near_me_disabled",
            style = MaterialIcon.Style.Normal
        )
        val NearbyError = MaterialIcon(
            name = "nearby_error",
            style = MaterialIcon.Style.Normal
        )
        val NearbyOff = MaterialIcon(
            name = "nearby_off",
            style = MaterialIcon.Style.Normal
        )
        val NestCamWiredStand = MaterialIcon(
            name = "nest_cam_wired_stand",
            style = MaterialIcon.Style.Normal
        )
        val NetworkCell = MaterialIcon(
            name = "network_cell",
            style = MaterialIcon.Style.Normal
        )
        val NetworkCheck = MaterialIcon(
            name = "network_check",
            style = MaterialIcon.Style.Normal
        )
        val NetworkLocked = MaterialIcon(
            name = "network_locked",
            style = MaterialIcon.Style.Normal
        )
        val NetworkPing = MaterialIcon(
            name = "network_ping",
            style = MaterialIcon.Style.Normal
        )
        val NetworkWifi = MaterialIcon(
            name = "network_wifi",
            style = MaterialIcon.Style.Normal
        )
        val NetworkWifi1Bar = MaterialIcon(
            name = "network_wifi_1_bar",
            style = MaterialIcon.Style.Normal
        )
        val NetworkWifi2Bar = MaterialIcon(
            name = "network_wifi_2_bar",
            style = MaterialIcon.Style.Normal
        )
        val NetworkWifi3Bar = MaterialIcon(
            name = "network_wifi_3_bar",
            style = MaterialIcon.Style.Normal
        )
        val NewLabel = MaterialIcon(
            name = "new_label",
            style = MaterialIcon.Style.Normal
        )
        val NewReleases = MaterialIcon(
            name = "new_releases",
            style = MaterialIcon.Style.Normal
        )
        val Newspaper = MaterialIcon(
            name = "newspaper",
            style = MaterialIcon.Style.Normal
        )
        val NextPlan = MaterialIcon(
            name = "next_plan",
            style = MaterialIcon.Style.Normal
        )
        val NextWeek = MaterialIcon(
            name = "next_week",
            style = MaterialIcon.Style.Normal
        )
        val Nfc = MaterialIcon(
            name = "nfc",
            style = MaterialIcon.Style.Normal
        )
        val NightShelter = MaterialIcon(
            name = "night_shelter",
            style = MaterialIcon.Style.Normal
        )
        val Nightlife = MaterialIcon(
            name = "nightlife",
            style = MaterialIcon.Style.Normal
        )
        val Nightlight = MaterialIcon(
            name = "nightlight",
            style = MaterialIcon.Style.Normal
        )
        val NightlightRound = MaterialIcon(
            name = "nightlight_round",
            style = MaterialIcon.Style.Normal
        )
        val NightsStay = MaterialIcon(
            name = "nights_stay",
            style = MaterialIcon.Style.Normal
        )
        val NoAccounts = MaterialIcon(
            name = "no_accounts",
            style = MaterialIcon.Style.Normal
        )
        val NoAdultContent = MaterialIcon(
            name = "no_adult_content",
            style = MaterialIcon.Style.Normal
        )
        val NoBackpack = MaterialIcon(
            name = "no_backpack",
            style = MaterialIcon.Style.Normal
        )
        val NoCell = MaterialIcon(
            name = "no_cell",
            style = MaterialIcon.Style.Normal
        )
        val NoCrash = MaterialIcon(
            name = "no_crash",
            style = MaterialIcon.Style.Normal
        )
        val NoDrinks = MaterialIcon(
            name = "no_drinks",
            style = MaterialIcon.Style.Normal
        )
        val NoEncryption = MaterialIcon(
            name = "no_encryption",
            style = MaterialIcon.Style.Normal
        )
        val NoEncryptionGmailerrorred = MaterialIcon(
            name = "no_encryption_gmailerrorred",
            style = MaterialIcon.Style.Normal
        )
        val NoFlash = MaterialIcon(
            name = "no_flash",
            style = MaterialIcon.Style.Normal
        )
        val NoFood = MaterialIcon(
            name = "no_food",
            style = MaterialIcon.Style.Normal
        )
        val NoLuggage = MaterialIcon(
            name = "no_luggage",
            style = MaterialIcon.Style.Normal
        )
        val NoMeals = MaterialIcon(
            name = "no_meals",
            style = MaterialIcon.Style.Normal
        )
        val NoMeetingRoom = MaterialIcon(
            name = "no_meeting_room",
            style = MaterialIcon.Style.Normal
        )
        val NoPhotography = MaterialIcon(
            name = "no_photography",
            style = MaterialIcon.Style.Normal
        )
        val NoSim = MaterialIcon(
            name = "no_sim",
            style = MaterialIcon.Style.Normal
        )
        val NoStroller = MaterialIcon(
            name = "no_stroller",
            style = MaterialIcon.Style.Normal
        )
        val NoTransfer = MaterialIcon(
            name = "no_transfer",
            style = MaterialIcon.Style.Normal
        )
        val NoiseAware = MaterialIcon(
            name = "noise_aware",
            style = MaterialIcon.Style.Normal
        )
        val NoiseControlOff = MaterialIcon(
            name = "noise_control_off",
            style = MaterialIcon.Style.Normal
        )
        val NordicWalking = MaterialIcon(
            name = "nordic_walking",
            style = MaterialIcon.Style.Normal
        )
        val North = MaterialIcon(
            name = "north",
            style = MaterialIcon.Style.Normal
        )
        val NorthEast = MaterialIcon(
            name = "north_east",
            style = MaterialIcon.Style.Normal
        )
        val NorthWest = MaterialIcon(
            name = "north_west",
            style = MaterialIcon.Style.Normal
        )
        val NotAccessible = MaterialIcon(
            name = "not_accessible",
            style = MaterialIcon.Style.Normal
        )
        val NotInterested = MaterialIcon(
            name = "not_interested",
            style = MaterialIcon.Style.Normal
        )
        val NotListedLocation = MaterialIcon(
            name = "not_listed_location",
            style = MaterialIcon.Style.Normal
        )
        val NotStarted = MaterialIcon(
            name = "not_started",
            style = MaterialIcon.Style.Normal
        )
        val Note = MaterialIcon(
            name = "note",
            style = MaterialIcon.Style.Normal
        )
        val NoteAdd = MaterialIcon(
            name = "note_add",
            style = MaterialIcon.Style.Normal
        )
        val NoteAlt = MaterialIcon(
            name = "note_alt",
            style = MaterialIcon.Style.Normal
        )
        val Notes = MaterialIcon(
            name = "notes",
            style = MaterialIcon.Style.Normal
        )
        val NotificationAdd = MaterialIcon(
            name = "notification_add",
            style = MaterialIcon.Style.Normal
        )
        val NotificationImportant = MaterialIcon(
            name = "notification_important",
            style = MaterialIcon.Style.Normal
        )
        val Notifications = MaterialIcon(
            name = "notifications",
            style = MaterialIcon.Style.Normal
        )
        val NotificationsActive = MaterialIcon(
            name = "notifications_active",
            style = MaterialIcon.Style.Normal
        )
        val NotificationsNone = MaterialIcon(
            name = "notifications_none",
            style = MaterialIcon.Style.Normal
        )
        val NotificationsOff = MaterialIcon(
            name = "notifications_off",
            style = MaterialIcon.Style.Normal
        )
        val NotificationsPaused = MaterialIcon(
            name = "notifications_paused",
            style = MaterialIcon.Style.Normal
        )
        val Numbers = MaterialIcon(
            name = "numbers",
            style = MaterialIcon.Style.Normal
        )
        val OfflineBolt = MaterialIcon(
            name = "offline_bolt",
            style = MaterialIcon.Style.Normal
        )
        val OfflinePin = MaterialIcon(
            name = "offline_pin",
            style = MaterialIcon.Style.Normal
        )
        val OfflineShare = MaterialIcon(
            name = "offline_share",
            style = MaterialIcon.Style.Normal
        )
        val OilBarrel = MaterialIcon(
            name = "oil_barrel",
            style = MaterialIcon.Style.Normal
        )
        val OnDeviceTraining = MaterialIcon(
            name = "on_device_training",
            style = MaterialIcon.Style.Normal
        )
        val OndemandVideo = MaterialIcon(
            name = "ondemand_video",
            style = MaterialIcon.Style.Normal
        )
        val OnlinePrediction = MaterialIcon(
            name = "online_prediction",
            style = MaterialIcon.Style.Normal
        )
        val Opacity = MaterialIcon(
            name = "opacity",
            style = MaterialIcon.Style.Normal
        )
        val OpenInBrowser = MaterialIcon(
            name = "open_in_browser",
            style = MaterialIcon.Style.Normal
        )
        val OpenInFull = MaterialIcon(
            name = "open_in_full",
            style = MaterialIcon.Style.Normal
        )
        val OpenInNew = MaterialIcon(
            name = "open_in_new",
            style = MaterialIcon.Style.Normal
        )
        val OpenInNewOff = MaterialIcon(
            name = "open_in_new_off",
            style = MaterialIcon.Style.Normal
        )
        val OpenWith = MaterialIcon(
            name = "open_with",
            style = MaterialIcon.Style.Normal
        )
        val OtherHouses = MaterialIcon(
            name = "other_houses",
            style = MaterialIcon.Style.Normal
        )
        val Outbound = MaterialIcon(
            name = "outbound",
            style = MaterialIcon.Style.Normal
        )
        val Outbox = MaterialIcon(
            name = "outbox",
            style = MaterialIcon.Style.Normal
        )
        val OutdoorGrill = MaterialIcon(
            name = "outdoor_grill",
            style = MaterialIcon.Style.Normal
        )
        val Outlet = MaterialIcon(
            name = "outlet",
            style = MaterialIcon.Style.Normal
        )
        val OutlinedFlag = MaterialIcon(
            name = "outlined_flag",
            style = MaterialIcon.Style.Normal
        )
        val Output = MaterialIcon(
            name = "output",
            style = MaterialIcon.Style.Normal
        )
        val Padding = MaterialIcon(
            name = "padding",
            style = MaterialIcon.Style.Normal
        )
        val Pages = MaterialIcon(
            name = "pages",
            style = MaterialIcon.Style.Normal
        )
        val Pageview = MaterialIcon(
            name = "pageview",
            style = MaterialIcon.Style.Normal
        )
        val Paid = MaterialIcon(
            name = "paid",
            style = MaterialIcon.Style.Normal
        )
        val Palette = MaterialIcon(
            name = "palette",
            style = MaterialIcon.Style.Normal
        )
        val PanTool = MaterialIcon(
            name = "pan_tool",
            style = MaterialIcon.Style.Normal
        )
        val PanToolAlt = MaterialIcon(
            name = "pan_tool_alt",
            style = MaterialIcon.Style.Normal
        )
        val Panorama = MaterialIcon(
            name = "panorama",
            style = MaterialIcon.Style.Normal
        )
        val PanoramaFishEye = MaterialIcon(
            name = "panorama_fish_eye",
            style = MaterialIcon.Style.Normal
        )
        val PanoramaHorizontal = MaterialIcon(
            name = "panorama_horizontal",
            style = MaterialIcon.Style.Normal
        )
        val PanoramaHorizontalSelect = MaterialIcon(
            name = "panorama_horizontal_select",
            style = MaterialIcon.Style.Normal
        )
        val PanoramaPhotosphere = MaterialIcon(
            name = "panorama_photosphere",
            style = MaterialIcon.Style.Normal
        )
        val PanoramaPhotosphereSelect = MaterialIcon(
            name = "panorama_photosphere_select",
            style = MaterialIcon.Style.Normal
        )
        val PanoramaVertical = MaterialIcon(
            name = "panorama_vertical",
            style = MaterialIcon.Style.Normal
        )
        val PanoramaVerticalSelect = MaterialIcon(
            name = "panorama_vertical_select",
            style = MaterialIcon.Style.Normal
        )
        val PanoramaWideAngle = MaterialIcon(
            name = "panorama_wide_angle",
            style = MaterialIcon.Style.Normal
        )
        val PanoramaWideAngleSelect = MaterialIcon(
            name = "panorama_wide_angle_select",
            style = MaterialIcon.Style.Normal
        )
        val Paragliding = MaterialIcon(
            name = "paragliding",
            style = MaterialIcon.Style.Normal
        )
        val Park = MaterialIcon(
            name = "park",
            style = MaterialIcon.Style.Normal
        )
        val PartyMode = MaterialIcon(
            name = "party_mode",
            style = MaterialIcon.Style.Normal
        )
        val Password = MaterialIcon(
            name = "password",
            style = MaterialIcon.Style.Normal
        )
        val Pattern = MaterialIcon(
            name = "pattern",
            style = MaterialIcon.Style.Normal
        )
        val Pause = MaterialIcon(
            name = "pause",
            style = MaterialIcon.Style.Normal
        )
        val PauseCircle = MaterialIcon(
            name = "pause_circle",
            style = MaterialIcon.Style.Normal
        )
        val PauseCircleFilled = MaterialIcon(
            name = "pause_circle_filled",
            style = MaterialIcon.Style.Normal
        )
        val PauseCircleOutline = MaterialIcon(
            name = "pause_circle_outline",
            style = MaterialIcon.Style.Normal
        )
        val PausePresentation = MaterialIcon(
            name = "pause_presentation",
            style = MaterialIcon.Style.Normal
        )
        val Payment = MaterialIcon(
            name = "payment",
            style = MaterialIcon.Style.Normal
        )
        val Payments = MaterialIcon(
            name = "payments",
            style = MaterialIcon.Style.Normal
        )
        val PedalBike = MaterialIcon(
            name = "pedal_bike",
            style = MaterialIcon.Style.Normal
        )
        val Pending = MaterialIcon(
            name = "pending",
            style = MaterialIcon.Style.Normal
        )
        val PendingActions = MaterialIcon(
            name = "pending_actions",
            style = MaterialIcon.Style.Normal
        )
        val Pentagon = MaterialIcon(
            name = "pentagon",
            style = MaterialIcon.Style.Normal
        )
        val People = MaterialIcon(
            name = "people",
            style = MaterialIcon.Style.Normal
        )
        val PeopleAlt = MaterialIcon(
            name = "people_alt",
            style = MaterialIcon.Style.Normal
        )
        val PeopleOutline = MaterialIcon(
            name = "people_outline",
            style = MaterialIcon.Style.Normal
        )
        val Percent = MaterialIcon(
            name = "percent",
            style = MaterialIcon.Style.Normal
        )
        val PermCameraMic = MaterialIcon(
            name = "perm_camera_mic",
            style = MaterialIcon.Style.Normal
        )
        val PermContactCalendar = MaterialIcon(
            name = "perm_contact_calendar",
            style = MaterialIcon.Style.Normal
        )
        val PermDataSetting = MaterialIcon(
            name = "perm_data_setting",
            style = MaterialIcon.Style.Normal
        )
        val PermDeviceInformation = MaterialIcon(
            name = "perm_device_information",
            style = MaterialIcon.Style.Normal
        )
        val PermIdentity = MaterialIcon(
            name = "perm_identity",
            style = MaterialIcon.Style.Normal
        )
        val PermMedia = MaterialIcon(
            name = "perm_media",
            style = MaterialIcon.Style.Normal
        )
        val PermPhoneMsg = MaterialIcon(
            name = "perm_phone_msg",
            style = MaterialIcon.Style.Normal
        )
        val PermScanWifi = MaterialIcon(
            name = "perm_scan_wifi",
            style = MaterialIcon.Style.Normal
        )
        val Person = MaterialIcon(
            name = "person",
            style = MaterialIcon.Style.Normal
        )
        val Person2 = MaterialIcon(
            name = "person_2",
            style = MaterialIcon.Style.Normal
        )
        val Person3 = MaterialIcon(
            name = "person_3",
            style = MaterialIcon.Style.Normal
        )
        val Person4 = MaterialIcon(
            name = "person_4",
            style = MaterialIcon.Style.Normal
        )
        val PersonAdd = MaterialIcon(
            name = "person_add",
            style = MaterialIcon.Style.Normal
        )
        val PersonAddAlt = MaterialIcon(
            name = "person_add_alt",
            style = MaterialIcon.Style.Normal
        )
        val PersonAddAlt1 = MaterialIcon(
            name = "person_add_alt_1",
            style = MaterialIcon.Style.Normal
        )
        val PersonAddDisabled = MaterialIcon(
            name = "person_add_disabled",
            style = MaterialIcon.Style.Normal
        )
        val PersonOff = MaterialIcon(
            name = "person_off",
            style = MaterialIcon.Style.Normal
        )
        val PersonOutline = MaterialIcon(
            name = "person_outline",
            style = MaterialIcon.Style.Normal
        )
        val PersonPin = MaterialIcon(
            name = "person_pin",
            style = MaterialIcon.Style.Normal
        )
        val PersonPinCircle = MaterialIcon(
            name = "person_pin_circle",
            style = MaterialIcon.Style.Normal
        )
        val PersonRemove = MaterialIcon(
            name = "person_remove",
            style = MaterialIcon.Style.Normal
        )
        val PersonRemoveAlt1 = MaterialIcon(
            name = "person_remove_alt_1",
            style = MaterialIcon.Style.Normal
        )
        val PersonSearch = MaterialIcon(
            name = "person_search",
            style = MaterialIcon.Style.Normal
        )
        val PersonalInjury = MaterialIcon(
            name = "personal_injury",
            style = MaterialIcon.Style.Normal
        )
        val PersonalVideo = MaterialIcon(
            name = "personal_video",
            style = MaterialIcon.Style.Normal
        )
        val PestControl = MaterialIcon(
            name = "pest_control",
            style = MaterialIcon.Style.Normal
        )
        val PestControlRodent = MaterialIcon(
            name = "pest_control_rodent",
            style = MaterialIcon.Style.Normal
        )
        val Pets = MaterialIcon(
            name = "pets",
            style = MaterialIcon.Style.Normal
        )
        val Phishing = MaterialIcon(
            name = "phishing",
            style = MaterialIcon.Style.Normal
        )
        val Phone = MaterialIcon(
            name = "phone",
            style = MaterialIcon.Style.Normal
        )
        val PhoneAndroid = MaterialIcon(
            name = "phone_android",
            style = MaterialIcon.Style.Normal
        )
        val PhoneBluetoothSpeaker = MaterialIcon(
            name = "phone_bluetooth_speaker",
            style = MaterialIcon.Style.Normal
        )
        val PhoneCallback = MaterialIcon(
            name = "phone_callback",
            style = MaterialIcon.Style.Normal
        )
        val PhoneDisabled = MaterialIcon(
            name = "phone_disabled",
            style = MaterialIcon.Style.Normal
        )
        val PhoneEnabled = MaterialIcon(
            name = "phone_enabled",
            style = MaterialIcon.Style.Normal
        )
        val PhoneForwarded = MaterialIcon(
            name = "phone_forwarded",
            style = MaterialIcon.Style.Normal
        )
        val PhoneIphone = MaterialIcon(
            name = "phone_iphone",
            style = MaterialIcon.Style.Normal
        )
        val PhoneLocked = MaterialIcon(
            name = "phone_locked",
            style = MaterialIcon.Style.Normal
        )
        val PhoneMissed = MaterialIcon(
            name = "phone_missed",
            style = MaterialIcon.Style.Normal
        )
        val PhonePaused = MaterialIcon(
            name = "phone_paused",
            style = MaterialIcon.Style.Normal
        )
        val Phonelink = MaterialIcon(
            name = "phonelink",
            style = MaterialIcon.Style.Normal
        )
        val PhonelinkErase = MaterialIcon(
            name = "phonelink_erase",
            style = MaterialIcon.Style.Normal
        )
        val PhonelinkLock = MaterialIcon(
            name = "phonelink_lock",
            style = MaterialIcon.Style.Normal
        )
        val PhonelinkOff = MaterialIcon(
            name = "phonelink_off",
            style = MaterialIcon.Style.Normal
        )
        val PhonelinkRing = MaterialIcon(
            name = "phonelink_ring",
            style = MaterialIcon.Style.Normal
        )
        val PhonelinkSetup = MaterialIcon(
            name = "phonelink_setup",
            style = MaterialIcon.Style.Normal
        )
        val Photo = MaterialIcon(
            name = "photo",
            style = MaterialIcon.Style.Normal
        )
        val PhotoAlbum = MaterialIcon(
            name = "photo_album",
            style = MaterialIcon.Style.Normal
        )
        val PhotoCamera = MaterialIcon(
            name = "photo_camera",
            style = MaterialIcon.Style.Normal
        )
        val PhotoCameraBack = MaterialIcon(
            name = "photo_camera_back",
            style = MaterialIcon.Style.Normal
        )
        val PhotoCameraFront = MaterialIcon(
            name = "photo_camera_front",
            style = MaterialIcon.Style.Normal
        )
        val PhotoFilter = MaterialIcon(
            name = "photo_filter",
            style = MaterialIcon.Style.Normal
        )
        val PhotoLibrary = MaterialIcon(
            name = "photo_library",
            style = MaterialIcon.Style.Normal
        )
        val PhotoSizeSelectActual = MaterialIcon(
            name = "photo_size_select_actual",
            style = MaterialIcon.Style.Normal
        )
        val PhotoSizeSelectLarge = MaterialIcon(
            name = "photo_size_select_large",
            style = MaterialIcon.Style.Normal
        )
        val PhotoSizeSelectSmall = MaterialIcon(
            name = "photo_size_select_small",
            style = MaterialIcon.Style.Normal
        )
        val Php = MaterialIcon(
            name = "php",
            style = MaterialIcon.Style.Normal
        )
        val Piano = MaterialIcon(
            name = "piano",
            style = MaterialIcon.Style.Normal
        )
        val PianoOff = MaterialIcon(
            name = "piano_off",
            style = MaterialIcon.Style.Normal
        )
        val PictureAsPdf = MaterialIcon(
            name = "picture_as_pdf",
            style = MaterialIcon.Style.Normal
        )
        val PictureInPicture = MaterialIcon(
            name = "picture_in_picture",
            style = MaterialIcon.Style.Normal
        )
        val PictureInPictureAlt = MaterialIcon(
            name = "picture_in_picture_alt",
            style = MaterialIcon.Style.Normal
        )
        val PieChart = MaterialIcon(
            name = "pie_chart",
            style = MaterialIcon.Style.Normal
        )
        val PieChartOutline = MaterialIcon(
            name = "pie_chart_outline",
            style = MaterialIcon.Style.Normal
        )
        val Pin = MaterialIcon(
            name = "pin",
            style = MaterialIcon.Style.Normal
        )
        val PinDrop = MaterialIcon(
            name = "pin_drop",
            style = MaterialIcon.Style.Normal
        )
        val PinEnd = MaterialIcon(
            name = "pin_end",
            style = MaterialIcon.Style.Normal
        )
        val PinInvoke = MaterialIcon(
            name = "pin_invoke",
            style = MaterialIcon.Style.Normal
        )
        val Pinch = MaterialIcon(
            name = "pinch",
            style = MaterialIcon.Style.Normal
        )
        val PivotTableChart = MaterialIcon(
            name = "pivot_table_chart",
            style = MaterialIcon.Style.Normal
        )
        val Pix = MaterialIcon(
            name = "pix",
            style = MaterialIcon.Style.Normal
        )
        val Place = MaterialIcon(
            name = "place",
            style = MaterialIcon.Style.Normal
        )
        val Plagiarism = MaterialIcon(
            name = "plagiarism",
            style = MaterialIcon.Style.Normal
        )
        val PlayArrow = MaterialIcon(
            name = "play_arrow",
            style = MaterialIcon.Style.Normal
        )
        val PlayCircle = MaterialIcon(
            name = "play_circle",
            style = MaterialIcon.Style.Normal
        )
        val PlayCircleFilled = MaterialIcon(
            name = "play_circle_filled",
            style = MaterialIcon.Style.Normal
        )
        val PlayCircleOutline = MaterialIcon(
            name = "play_circle_outline",
            style = MaterialIcon.Style.Normal
        )
        val PlayDisabled = MaterialIcon(
            name = "play_disabled",
            style = MaterialIcon.Style.Normal
        )
        val PlayForWork = MaterialIcon(
            name = "play_for_work",
            style = MaterialIcon.Style.Normal
        )
        val PlayLesson = MaterialIcon(
            name = "play_lesson",
            style = MaterialIcon.Style.Normal
        )
        val PlaylistAdd = MaterialIcon(
            name = "playlist_add",
            style = MaterialIcon.Style.Normal
        )
        val PlaylistAddCheck = MaterialIcon(
            name = "playlist_add_check",
            style = MaterialIcon.Style.Normal
        )
        val PlaylistAddCheckCircle = MaterialIcon(
            name = "playlist_add_check_circle",
            style = MaterialIcon.Style.Normal
        )
        val PlaylistAddCircle = MaterialIcon(
            name = "playlist_add_circle",
            style = MaterialIcon.Style.Normal
        )
        val PlaylistPlay = MaterialIcon(
            name = "playlist_play",
            style = MaterialIcon.Style.Normal
        )
        val PlaylistRemove = MaterialIcon(
            name = "playlist_remove",
            style = MaterialIcon.Style.Normal
        )
        val Plumbing = MaterialIcon(
            name = "plumbing",
            style = MaterialIcon.Style.Normal
        )
        val PlusOne = MaterialIcon(
            name = "plus_one",
            style = MaterialIcon.Style.Normal
        )
        val Podcasts = MaterialIcon(
            name = "podcasts",
            style = MaterialIcon.Style.Normal
        )
        val PointOfSale = MaterialIcon(
            name = "point_of_sale",
            style = MaterialIcon.Style.Normal
        )
        val Policy = MaterialIcon(
            name = "policy",
            style = MaterialIcon.Style.Normal
        )
        val Poll = MaterialIcon(
            name = "poll",
            style = MaterialIcon.Style.Normal
        )
        val Polyline = MaterialIcon(
            name = "polyline",
            style = MaterialIcon.Style.Normal
        )
        val Polymer = MaterialIcon(
            name = "polymer",
            style = MaterialIcon.Style.Normal
        )
        val Pool = MaterialIcon(
            name = "pool",
            style = MaterialIcon.Style.Normal
        )
        val PortableWifiOff = MaterialIcon(
            name = "portable_wifi_off",
            style = MaterialIcon.Style.Normal
        )
        val Portrait = MaterialIcon(
            name = "portrait",
            style = MaterialIcon.Style.Normal
        )
        val PostAdd = MaterialIcon(
            name = "post_add",
            style = MaterialIcon.Style.Normal
        )
        val Power = MaterialIcon(
            name = "power",
            style = MaterialIcon.Style.Normal
        )
        val PowerInput = MaterialIcon(
            name = "power_input",
            style = MaterialIcon.Style.Normal
        )
        val PowerOff = MaterialIcon(
            name = "power_off",
            style = MaterialIcon.Style.Normal
        )
        val PowerSettingsNew = MaterialIcon(
            name = "power_settings_new",
            style = MaterialIcon.Style.Normal
        )
        val PrecisionManufacturing = MaterialIcon(
            name = "precision_manufacturing",
            style = MaterialIcon.Style.Normal
        )
        val PregnantWoman = MaterialIcon(
            name = "pregnant_woman",
            style = MaterialIcon.Style.Normal
        )
        val PresentToAll = MaterialIcon(
            name = "present_to_all",
            style = MaterialIcon.Style.Normal
        )
        val Preview = MaterialIcon(
            name = "preview",
            style = MaterialIcon.Style.Normal
        )
        val PriceChange = MaterialIcon(
            name = "price_change",
            style = MaterialIcon.Style.Normal
        )
        val PriceCheck = MaterialIcon(
            name = "price_check",
            style = MaterialIcon.Style.Normal
        )
        val Print = MaterialIcon(
            name = "print",
            style = MaterialIcon.Style.Normal
        )
        val PrintDisabled = MaterialIcon(
            name = "print_disabled",
            style = MaterialIcon.Style.Normal
        )
        val PriorityHigh = MaterialIcon(
            name = "priority_high",
            style = MaterialIcon.Style.Normal
        )
        val PrivacyTip = MaterialIcon(
            name = "privacy_tip",
            style = MaterialIcon.Style.Normal
        )
        val PrivateConnectivity = MaterialIcon(
            name = "private_connectivity",
            style = MaterialIcon.Style.Normal
        )
        val ProductionQuantityLimits = MaterialIcon(
            name = "production_quantity_limits",
            style = MaterialIcon.Style.Normal
        )
        val Propane = MaterialIcon(
            name = "propane",
            style = MaterialIcon.Style.Normal
        )
        val PropaneTank = MaterialIcon(
            name = "propane_tank",
            style = MaterialIcon.Style.Normal
        )
        val Psychology = MaterialIcon(
            name = "psychology",
            style = MaterialIcon.Style.Normal
        )
        val PsychologyAlt = MaterialIcon(
            name = "psychology_alt",
            style = MaterialIcon.Style.Normal
        )
        val Public = MaterialIcon(
            name = "public",
            style = MaterialIcon.Style.Normal
        )
        val PublicOff = MaterialIcon(
            name = "public_off",
            style = MaterialIcon.Style.Normal
        )
        val Publish = MaterialIcon(
            name = "publish",
            style = MaterialIcon.Style.Normal
        )
        val PublishedWithChanges = MaterialIcon(
            name = "published_with_changes",
            style = MaterialIcon.Style.Normal
        )
        val PunchClock = MaterialIcon(
            name = "punch_clock",
            style = MaterialIcon.Style.Normal
        )
        val PushPin = MaterialIcon(
            name = "push_pin",
            style = MaterialIcon.Style.Normal
        )
        val QrCode = MaterialIcon(
            name = "qr_code",
            style = MaterialIcon.Style.Normal
        )
        val QrCode2 = MaterialIcon(
            name = "qr_code_2",
            style = MaterialIcon.Style.Normal
        )
        val QrCodeScanner = MaterialIcon(
            name = "qr_code_scanner",
            style = MaterialIcon.Style.Normal
        )
        val QueryBuilder = MaterialIcon(
            name = "query_builder",
            style = MaterialIcon.Style.Normal
        )
        val QueryStats = MaterialIcon(
            name = "query_stats",
            style = MaterialIcon.Style.Normal
        )
        val QuestionAnswer = MaterialIcon(
            name = "question_answer",
            style = MaterialIcon.Style.Normal
        )
        val QuestionMark = MaterialIcon(
            name = "question_mark",
            style = MaterialIcon.Style.Normal
        )
        val Queue = MaterialIcon(
            name = "queue",
            style = MaterialIcon.Style.Normal
        )
        val QueueMusic = MaterialIcon(
            name = "queue_music",
            style = MaterialIcon.Style.Normal
        )
        val QueuePlayNext = MaterialIcon(
            name = "queue_play_next",
            style = MaterialIcon.Style.Normal
        )
        val Quickreply = MaterialIcon(
            name = "quickreply",
            style = MaterialIcon.Style.Normal
        )
        val Quiz = MaterialIcon(
            name = "quiz",
            style = MaterialIcon.Style.Normal
        )
        val RMobiledata = MaterialIcon(
            name = "r_mobiledata",
            style = MaterialIcon.Style.Normal
        )
        val Radar = MaterialIcon(
            name = "radar",
            style = MaterialIcon.Style.Normal
        )
        val Radio = MaterialIcon(
            name = "radio",
            style = MaterialIcon.Style.Normal
        )
        val RadioButtonChecked = MaterialIcon(
            name = "radio_button_checked",
            style = MaterialIcon.Style.Normal
        )
        val RadioButtonUnchecked = MaterialIcon(
            name = "radio_button_unchecked",
            style = MaterialIcon.Style.Normal
        )
        val RailwayAlert = MaterialIcon(
            name = "railway_alert",
            style = MaterialIcon.Style.Normal
        )
        val RamenDining = MaterialIcon(
            name = "ramen_dining",
            style = MaterialIcon.Style.Normal
        )
        val RampLeft = MaterialIcon(
            name = "ramp_left",
            style = MaterialIcon.Style.Normal
        )
        val RampRight = MaterialIcon(
            name = "ramp_right",
            style = MaterialIcon.Style.Normal
        )
        val RateReview = MaterialIcon(
            name = "rate_review",
            style = MaterialIcon.Style.Normal
        )
        val RawOff = MaterialIcon(
            name = "raw_off",
            style = MaterialIcon.Style.Normal
        )
        val RawOn = MaterialIcon(
            name = "raw_on",
            style = MaterialIcon.Style.Normal
        )
        val ReadMore = MaterialIcon(
            name = "read_more",
            style = MaterialIcon.Style.Normal
        )
        val RealEstateAgent = MaterialIcon(
            name = "real_estate_agent",
            style = MaterialIcon.Style.Normal
        )
        val Receipt = MaterialIcon(
            name = "receipt",
            style = MaterialIcon.Style.Normal
        )
        val ReceiptLong = MaterialIcon(
            name = "receipt_long",
            style = MaterialIcon.Style.Normal
        )
        val RecentActors = MaterialIcon(
            name = "recent_actors",
            style = MaterialIcon.Style.Normal
        )
        val Recommend = MaterialIcon(
            name = "recommend",
            style = MaterialIcon.Style.Normal
        )
        val RecordVoiceOver = MaterialIcon(
            name = "record_voice_over",
            style = MaterialIcon.Style.Normal
        )
        val Rectangle = MaterialIcon(
            name = "rectangle",
            style = MaterialIcon.Style.Normal
        )
        val Recycling = MaterialIcon(
            name = "recycling",
            style = MaterialIcon.Style.Normal
        )
        val Redeem = MaterialIcon(
            name = "redeem",
            style = MaterialIcon.Style.Normal
        )
        val Redo = MaterialIcon(
            name = "redo",
            style = MaterialIcon.Style.Normal
        )
        val ReduceCapacity = MaterialIcon(
            name = "reduce_capacity",
            style = MaterialIcon.Style.Normal
        )
        val Refresh = MaterialIcon(
            name = "refresh",
            style = MaterialIcon.Style.Normal
        )
        val RememberMe = MaterialIcon(
            name = "remember_me",
            style = MaterialIcon.Style.Normal
        )
        val Remove = MaterialIcon(
            name = "remove",
            style = MaterialIcon.Style.Normal
        )
        val RemoveCircle = MaterialIcon(
            name = "remove_circle",
            style = MaterialIcon.Style.Normal
        )
        val RemoveCircleOutline = MaterialIcon(
            name = "remove_circle_outline",
            style = MaterialIcon.Style.Normal
        )
        val RemoveDone = MaterialIcon(
            name = "remove_done",
            style = MaterialIcon.Style.Normal
        )
        val RemoveFromQueue = MaterialIcon(
            name = "remove_from_queue",
            style = MaterialIcon.Style.Normal
        )
        val RemoveModerator = MaterialIcon(
            name = "remove_moderator",
            style = MaterialIcon.Style.Normal
        )
        val RemoveRedEye = MaterialIcon(
            name = "remove_red_eye",
            style = MaterialIcon.Style.Normal
        )
        val RemoveRoad = MaterialIcon(
            name = "remove_road",
            style = MaterialIcon.Style.Normal
        )
        val RemoveShoppingCart = MaterialIcon(
            name = "remove_shopping_cart",
            style = MaterialIcon.Style.Normal
        )
        val Reorder = MaterialIcon(
            name = "reorder",
            style = MaterialIcon.Style.Normal
        )
        val Repartition = MaterialIcon(
            name = "repartition",
            style = MaterialIcon.Style.Normal
        )
        val Repeat = MaterialIcon(
            name = "repeat",
            style = MaterialIcon.Style.Normal
        )
        val RepeatOn = MaterialIcon(
            name = "repeat_on",
            style = MaterialIcon.Style.Normal
        )
        val RepeatOne = MaterialIcon(
            name = "repeat_one",
            style = MaterialIcon.Style.Normal
        )
        val RepeatOneOn = MaterialIcon(
            name = "repeat_one_on",
            style = MaterialIcon.Style.Normal
        )
        val Replay = MaterialIcon(
            name = "replay",
            style = MaterialIcon.Style.Normal
        )
        val Replay10 = MaterialIcon(
            name = "replay_10",
            style = MaterialIcon.Style.Normal
        )
        val Replay30 = MaterialIcon(
            name = "replay_30",
            style = MaterialIcon.Style.Normal
        )
        val Replay5 = MaterialIcon(
            name = "replay_5",
            style = MaterialIcon.Style.Normal
        )
        val ReplayCircleFilled = MaterialIcon(
            name = "replay_circle_filled",
            style = MaterialIcon.Style.Normal
        )
        val Reply = MaterialIcon(
            name = "reply",
            style = MaterialIcon.Style.Normal
        )
        val ReplyAll = MaterialIcon(
            name = "reply_all",
            style = MaterialIcon.Style.Normal
        )
        val Report = MaterialIcon(
            name = "report",
            style = MaterialIcon.Style.Normal
        )
        val ReportGmailerrorred = MaterialIcon(
            name = "report_gmailerrorred",
            style = MaterialIcon.Style.Normal
        )
        val ReportOff = MaterialIcon(
            name = "report_off",
            style = MaterialIcon.Style.Normal
        )
        val ReportProblem = MaterialIcon(
            name = "report_problem",
            style = MaterialIcon.Style.Normal
        )
        val RequestPage = MaterialIcon(
            name = "request_page",
            style = MaterialIcon.Style.Normal
        )
        val RequestQuote = MaterialIcon(
            name = "request_quote",
            style = MaterialIcon.Style.Normal
        )
        val ResetTv = MaterialIcon(
            name = "reset_tv",
            style = MaterialIcon.Style.Normal
        )
        val RestartAlt = MaterialIcon(
            name = "restart_alt",
            style = MaterialIcon.Style.Normal
        )
        val Restaurant = MaterialIcon(
            name = "restaurant",
            style = MaterialIcon.Style.Normal
        )
        val RestaurantMenu = MaterialIcon(
            name = "restaurant_menu",
            style = MaterialIcon.Style.Normal
        )
        val Restore = MaterialIcon(
            name = "restore",
            style = MaterialIcon.Style.Normal
        )
        val RestoreFromTrash = MaterialIcon(
            name = "restore_from_trash",
            style = MaterialIcon.Style.Normal
        )
        val RestorePage = MaterialIcon(
            name = "restore_page",
            style = MaterialIcon.Style.Normal
        )
        val Reviews = MaterialIcon(
            name = "reviews",
            style = MaterialIcon.Style.Normal
        )
        val RiceBowl = MaterialIcon(
            name = "rice_bowl",
            style = MaterialIcon.Style.Normal
        )
        val RingVolume = MaterialIcon(
            name = "ring_volume",
            style = MaterialIcon.Style.Normal
        )
        val Rocket = MaterialIcon(
            name = "rocket",
            style = MaterialIcon.Style.Normal
        )
        val RocketLaunch = MaterialIcon(
            name = "rocket_launch",
            style = MaterialIcon.Style.Normal
        )
        val RollerShades = MaterialIcon(
            name = "roller_shades",
            style = MaterialIcon.Style.Normal
        )
        val RollerShadesClosed = MaterialIcon(
            name = "roller_shades_closed",
            style = MaterialIcon.Style.Normal
        )
        val RollerSkating = MaterialIcon(
            name = "roller_skating",
            style = MaterialIcon.Style.Normal
        )
        val Roofing = MaterialIcon(
            name = "roofing",
            style = MaterialIcon.Style.Normal
        )
        val Room = MaterialIcon(
            name = "room",
            style = MaterialIcon.Style.Normal
        )
        val RoomPreferences = MaterialIcon(
            name = "room_preferences",
            style = MaterialIcon.Style.Normal
        )
        val RoomService = MaterialIcon(
            name = "room_service",
            style = MaterialIcon.Style.Normal
        )
        val Rotate90DegreesCcw = MaterialIcon(
            name = "rotate_90_degrees_ccw",
            style = MaterialIcon.Style.Normal
        )
        val Rotate90DegreesCw = MaterialIcon(
            name = "rotate_90_degrees_cw",
            style = MaterialIcon.Style.Normal
        )
        val RotateLeft = MaterialIcon(
            name = "rotate_left",
            style = MaterialIcon.Style.Normal
        )
        val RotateRight = MaterialIcon(
            name = "rotate_right",
            style = MaterialIcon.Style.Normal
        )
        val RoundaboutLeft = MaterialIcon(
            name = "roundabout_left",
            style = MaterialIcon.Style.Normal
        )
        val RoundaboutRight = MaterialIcon(
            name = "roundabout_right",
            style = MaterialIcon.Style.Normal
        )
        val RoundedCorner = MaterialIcon(
            name = "rounded_corner",
            style = MaterialIcon.Style.Normal
        )
        val Route = MaterialIcon(
            name = "route",
            style = MaterialIcon.Style.Normal
        )
        val Router = MaterialIcon(
            name = "router",
            style = MaterialIcon.Style.Normal
        )
        val Rowing = MaterialIcon(
            name = "rowing",
            style = MaterialIcon.Style.Normal
        )
        val RssFeed = MaterialIcon(
            name = "rss_feed",
            style = MaterialIcon.Style.Normal
        )
        val Rsvp = MaterialIcon(
            name = "rsvp",
            style = MaterialIcon.Style.Normal
        )
        val Rtt = MaterialIcon(
            name = "rtt",
            style = MaterialIcon.Style.Normal
        )
        val Rule = MaterialIcon(
            name = "rule",
            style = MaterialIcon.Style.Normal
        )
        val RuleFolder = MaterialIcon(
            name = "rule_folder",
            style = MaterialIcon.Style.Normal
        )
        val RunCircle = MaterialIcon(
            name = "run_circle",
            style = MaterialIcon.Style.Normal
        )
        val RunningWithErrors = MaterialIcon(
            name = "running_with_errors",
            style = MaterialIcon.Style.Normal
        )
        val RvHookup = MaterialIcon(
            name = "rv_hookup",
            style = MaterialIcon.Style.Normal
        )
        val SafetyCheck = MaterialIcon(
            name = "safety_check",
            style = MaterialIcon.Style.Normal
        )
        val SafetyDivider = MaterialIcon(
            name = "safety_divider",
            style = MaterialIcon.Style.Normal
        )
        val Sailing = MaterialIcon(
            name = "sailing",
            style = MaterialIcon.Style.Normal
        )
        val Sanitizer = MaterialIcon(
            name = "sanitizer",
            style = MaterialIcon.Style.Normal
        )
        val Satellite = MaterialIcon(
            name = "satellite",
            style = MaterialIcon.Style.Normal
        )
        val SatelliteAlt = MaterialIcon(
            name = "satellite_alt",
            style = MaterialIcon.Style.Normal
        )
        val Save = MaterialIcon(
            name = "save",
            style = MaterialIcon.Style.Normal
        )
        val SaveAlt = MaterialIcon(
            name = "save_alt",
            style = MaterialIcon.Style.Normal
        )
        val SaveAs = MaterialIcon(
            name = "save_as",
            style = MaterialIcon.Style.Normal
        )
        val SavedSearch = MaterialIcon(
            name = "saved_search",
            style = MaterialIcon.Style.Normal
        )
        val Savings = MaterialIcon(
            name = "savings",
            style = MaterialIcon.Style.Normal
        )
        val Scale = MaterialIcon(
            name = "scale",
            style = MaterialIcon.Style.Normal
        )
        val Scanner = MaterialIcon(
            name = "scanner",
            style = MaterialIcon.Style.Normal
        )
        val ScatterPlot = MaterialIcon(
            name = "scatter_plot",
            style = MaterialIcon.Style.Normal
        )
        val Schedule = MaterialIcon(
            name = "schedule",
            style = MaterialIcon.Style.Normal
        )
        val ScheduleSend = MaterialIcon(
            name = "schedule_send",
            style = MaterialIcon.Style.Normal
        )
        val Schema = MaterialIcon(
            name = "schema",
            style = MaterialIcon.Style.Normal
        )
        val School = MaterialIcon(
            name = "school",
            style = MaterialIcon.Style.Normal
        )
        val Science = MaterialIcon(
            name = "science",
            style = MaterialIcon.Style.Normal
        )
        val Score = MaterialIcon(
            name = "score",
            style = MaterialIcon.Style.Normal
        )
        val Scoreboard = MaterialIcon(
            name = "scoreboard",
            style = MaterialIcon.Style.Normal
        )
        val ScreenLockLandscape = MaterialIcon(
            name = "screen_lock_landscape",
            style = MaterialIcon.Style.Normal
        )
        val ScreenLockPortrait = MaterialIcon(
            name = "screen_lock_portrait",
            style = MaterialIcon.Style.Normal
        )
        val ScreenLockRotation = MaterialIcon(
            name = "screen_lock_rotation",
            style = MaterialIcon.Style.Normal
        )
        val ScreenRotation = MaterialIcon(
            name = "screen_rotation",
            style = MaterialIcon.Style.Normal
        )
        val ScreenRotationAlt = MaterialIcon(
            name = "screen_rotation_alt",
            style = MaterialIcon.Style.Normal
        )
        val ScreenSearchDesktop = MaterialIcon(
            name = "screen_search_desktop",
            style = MaterialIcon.Style.Normal
        )
        val ScreenShare = MaterialIcon(
            name = "screen_share",
            style = MaterialIcon.Style.Normal
        )
        val Screenshot = MaterialIcon(
            name = "screenshot",
            style = MaterialIcon.Style.Normal
        )
        val ScreenshotMonitor = MaterialIcon(
            name = "screenshot_monitor",
            style = MaterialIcon.Style.Normal
        )
        val ScubaDiving = MaterialIcon(
            name = "scuba_diving",
            style = MaterialIcon.Style.Normal
        )
        val Sd = MaterialIcon(
            name = "sd",
            style = MaterialIcon.Style.Normal
        )
        val SdCard = MaterialIcon(
            name = "sd_card",
            style = MaterialIcon.Style.Normal
        )
        val SdCardAlert = MaterialIcon(
            name = "sd_card_alert",
            style = MaterialIcon.Style.Normal
        )
        val SdStorage = MaterialIcon(
            name = "sd_storage",
            style = MaterialIcon.Style.Normal
        )
        val Search = MaterialIcon(
            name = "search",
            style = MaterialIcon.Style.Normal
        )
        val SearchOff = MaterialIcon(
            name = "search_off",
            style = MaterialIcon.Style.Normal
        )
        val Security = MaterialIcon(
            name = "security",
            style = MaterialIcon.Style.Normal
        )
        val SecurityUpdate = MaterialIcon(
            name = "security_update",
            style = MaterialIcon.Style.Normal
        )
        val SecurityUpdateGood = MaterialIcon(
            name = "security_update_good",
            style = MaterialIcon.Style.Normal
        )
        val SecurityUpdateWarning = MaterialIcon(
            name = "security_update_warning",
            style = MaterialIcon.Style.Normal
        )
        val Segment = MaterialIcon(
            name = "segment",
            style = MaterialIcon.Style.Normal
        )
        val SelectAll = MaterialIcon(
            name = "select_all",
            style = MaterialIcon.Style.Normal
        )
        val SelfImprovement = MaterialIcon(
            name = "self_improvement",
            style = MaterialIcon.Style.Normal
        )
        val Sell = MaterialIcon(
            name = "sell",
            style = MaterialIcon.Style.Normal
        )
        val Send = MaterialIcon(
            name = "send",
            style = MaterialIcon.Style.Normal
        )
        val SendAndArchive = MaterialIcon(
            name = "send_and_archive",
            style = MaterialIcon.Style.Normal
        )
        val SendTimeExtension = MaterialIcon(
            name = "send_time_extension",
            style = MaterialIcon.Style.Normal
        )
        val SendToMobile = MaterialIcon(
            name = "send_to_mobile",
            style = MaterialIcon.Style.Normal
        )
        val SensorDoor = MaterialIcon(
            name = "sensor_door",
            style = MaterialIcon.Style.Normal
        )
        val SensorOccupied = MaterialIcon(
            name = "sensor_occupied",
            style = MaterialIcon.Style.Normal
        )
        val SensorWindow = MaterialIcon(
            name = "sensor_window",
            style = MaterialIcon.Style.Normal
        )
        val Sensors = MaterialIcon(
            name = "sensors",
            style = MaterialIcon.Style.Normal
        )
        val SensorsOff = MaterialIcon(
            name = "sensors_off",
            style = MaterialIcon.Style.Normal
        )
        val SentimentDissatisfied = MaterialIcon(
            name = "sentiment_dissatisfied",
            style = MaterialIcon.Style.Normal
        )
        val SentimentNeutral = MaterialIcon(
            name = "sentiment_neutral",
            style = MaterialIcon.Style.Normal
        )
        val SentimentSatisfied = MaterialIcon(
            name = "sentiment_satisfied",
            style = MaterialIcon.Style.Normal
        )
        val SentimentSatisfiedAlt = MaterialIcon(
            name = "sentiment_satisfied_alt",
            style = MaterialIcon.Style.Normal
        )
        val SentimentVeryDissatisfied = MaterialIcon(
            name = "sentiment_very_dissatisfied",
            style = MaterialIcon.Style.Normal
        )
        val SentimentVerySatisfied = MaterialIcon(
            name = "sentiment_very_satisfied",
            style = MaterialIcon.Style.Normal
        )
        val SetMeal = MaterialIcon(
            name = "set_meal",
            style = MaterialIcon.Style.Normal
        )
        val Settings = MaterialIcon(
            name = "settings",
            style = MaterialIcon.Style.Normal
        )
        val SettingsAccessibility = MaterialIcon(
            name = "settings_accessibility",
            style = MaterialIcon.Style.Normal
        )
        val SettingsApplications = MaterialIcon(
            name = "settings_applications",
            style = MaterialIcon.Style.Normal
        )
        val SettingsBackupRestore = MaterialIcon(
            name = "settings_backup_restore",
            style = MaterialIcon.Style.Normal
        )
        val SettingsBluetooth = MaterialIcon(
            name = "settings_bluetooth",
            style = MaterialIcon.Style.Normal
        )
        val SettingsBrightness = MaterialIcon(
            name = "settings_brightness",
            style = MaterialIcon.Style.Normal
        )
        val SettingsCell = MaterialIcon(
            name = "settings_cell",
            style = MaterialIcon.Style.Normal
        )
        val SettingsEthernet = MaterialIcon(
            name = "settings_ethernet",
            style = MaterialIcon.Style.Normal
        )
        val SettingsInputAntenna = MaterialIcon(
            name = "settings_input_antenna",
            style = MaterialIcon.Style.Normal
        )
        val SettingsInputComponent = MaterialIcon(
            name = "settings_input_component",
            style = MaterialIcon.Style.Normal
        )
        val SettingsInputComposite = MaterialIcon(
            name = "settings_input_composite",
            style = MaterialIcon.Style.Normal
        )
        val SettingsInputHdmi = MaterialIcon(
            name = "settings_input_hdmi",
            style = MaterialIcon.Style.Normal
        )
        val SettingsInputSvideo = MaterialIcon(
            name = "settings_input_svideo",
            style = MaterialIcon.Style.Normal
        )
        val SettingsOverscan = MaterialIcon(
            name = "settings_overscan",
            style = MaterialIcon.Style.Normal
        )
        val SettingsPhone = MaterialIcon(
            name = "settings_phone",
            style = MaterialIcon.Style.Normal
        )
        val SettingsPower = MaterialIcon(
            name = "settings_power",
            style = MaterialIcon.Style.Normal
        )
        val SettingsRemote = MaterialIcon(
            name = "settings_remote",
            style = MaterialIcon.Style.Normal
        )
        val SettingsSuggest = MaterialIcon(
            name = "settings_suggest",
            style = MaterialIcon.Style.Normal
        )
        val SettingsSystemDaydream = MaterialIcon(
            name = "settings_system_daydream",
            style = MaterialIcon.Style.Normal
        )
        val SettingsVoice = MaterialIcon(
            name = "settings_voice",
            style = MaterialIcon.Style.Normal
        )
        val SevereCold = MaterialIcon(
            name = "severe_cold",
            style = MaterialIcon.Style.Normal
        )
        val ShapeLine = MaterialIcon(
            name = "shape_line",
            style = MaterialIcon.Style.Normal
        )
        val Share = MaterialIcon(
            name = "share",
            style = MaterialIcon.Style.Normal
        )
        val ShareLocation = MaterialIcon(
            name = "share_location",
            style = MaterialIcon.Style.Normal
        )
        val Shield = MaterialIcon(
            name = "shield",
            style = MaterialIcon.Style.Normal
        )
        val ShieldMoon = MaterialIcon(
            name = "shield_moon",
            style = MaterialIcon.Style.Normal
        )
        val Shop = MaterialIcon(
            name = "shop",
            style = MaterialIcon.Style.Normal
        )
        val Shop2 = MaterialIcon(
            name = "shop_2",
            style = MaterialIcon.Style.Normal
        )
        val ShopTwo = MaterialIcon(
            name = "shop_two",
            style = MaterialIcon.Style.Normal
        )
        val ShoppingBag = MaterialIcon(
            name = "shopping_bag",
            style = MaterialIcon.Style.Normal
        )
        val ShoppingBasket = MaterialIcon(
            name = "shopping_basket",
            style = MaterialIcon.Style.Normal
        )
        val ShoppingCart = MaterialIcon(
            name = "shopping_cart",
            style = MaterialIcon.Style.Normal
        )
        val ShoppingCartCheckout = MaterialIcon(
            name = "shopping_cart_checkout",
            style = MaterialIcon.Style.Normal
        )
        val ShortText = MaterialIcon(
            name = "short_text",
            style = MaterialIcon.Style.Normal
        )
        val Shortcut = MaterialIcon(
            name = "shortcut",
            style = MaterialIcon.Style.Normal
        )
        val ShowChart = MaterialIcon(
            name = "show_chart",
            style = MaterialIcon.Style.Normal
        )
        val Shower = MaterialIcon(
            name = "shower",
            style = MaterialIcon.Style.Normal
        )
        val Shuffle = MaterialIcon(
            name = "shuffle",
            style = MaterialIcon.Style.Normal
        )
        val ShuffleOn = MaterialIcon(
            name = "shuffle_on",
            style = MaterialIcon.Style.Normal
        )
        val ShutterSpeed = MaterialIcon(
            name = "shutter_speed",
            style = MaterialIcon.Style.Normal
        )
        val Sick = MaterialIcon(
            name = "sick",
            style = MaterialIcon.Style.Normal
        )
        val SignLanguage = MaterialIcon(
            name = "sign_language",
            style = MaterialIcon.Style.Normal
        )
        val SignalCellular0Bar = MaterialIcon(
            name = "signal_cellular_0_bar",
            style = MaterialIcon.Style.Normal
        )
        val SignalCellular4Bar = MaterialIcon(
            name = "signal_cellular_4_bar",
            style = MaterialIcon.Style.Normal
        )
        val SignalCellularAlt = MaterialIcon(
            name = "signal_cellular_alt",
            style = MaterialIcon.Style.Normal
        )
        val SignalCellularAlt1Bar = MaterialIcon(
            name = "signal_cellular_alt_1_bar",
            style = MaterialIcon.Style.Normal
        )
        val SignalCellularAlt2Bar = MaterialIcon(
            name = "signal_cellular_alt_2_bar",
            style = MaterialIcon.Style.Normal
        )
        val SignalCellularConnectedNoInternet0Bar = MaterialIcon(
            name = "signal_cellular_connected_no_internet_0_bar",
            style = MaterialIcon.Style.Normal
        )
        val SignalCellularConnectedNoInternet4Bar = MaterialIcon(
            name = "signal_cellular_connected_no_internet_4_bar",
            style = MaterialIcon.Style.Normal
        )
        val SignalCellularNoSim = MaterialIcon(
            name = "signal_cellular_no_sim",
            style = MaterialIcon.Style.Normal
        )
        val SignalCellularNodata = MaterialIcon(
            name = "signal_cellular_nodata",
            style = MaterialIcon.Style.Normal
        )
        val SignalCellularNull = MaterialIcon(
            name = "signal_cellular_null",
            style = MaterialIcon.Style.Normal
        )
        val SignalCellularOff = MaterialIcon(
            name = "signal_cellular_off",
            style = MaterialIcon.Style.Normal
        )
        val SignalWifi0Bar = MaterialIcon(
            name = "signal_wifi_0_bar",
            style = MaterialIcon.Style.Normal
        )
        val SignalWifi4Bar = MaterialIcon(
            name = "signal_wifi_4_bar",
            style = MaterialIcon.Style.Normal
        )
        val SignalWifi4BarLock = MaterialIcon(
            name = "signal_wifi_4_bar_lock",
            style = MaterialIcon.Style.Normal
        )
        val SignalWifiBad = MaterialIcon(
            name = "signal_wifi_bad",
            style = MaterialIcon.Style.Normal
        )
        val SignalWifiConnectedNoInternet4 = MaterialIcon(
            name = "signal_wifi_connected_no_internet_4",
            style = MaterialIcon.Style.Normal
        )
        val SignalWifiOff = MaterialIcon(
            name = "signal_wifi_off",
            style = MaterialIcon.Style.Normal
        )
        val SignalWifiStatusbar4Bar = MaterialIcon(
            name = "signal_wifi_statusbar_4_bar",
            style = MaterialIcon.Style.Normal
        )
        val SignalWifiStatusbarConnectedNoInternet4 = MaterialIcon(
            name = "signal_wifi_statusbar_connected_no_internet_4",
            style = MaterialIcon.Style.Normal
        )
        val SignalWifiStatusbarNull = MaterialIcon(
            name = "signal_wifi_statusbar_null",
            style = MaterialIcon.Style.Normal
        )
        val Signpost = MaterialIcon(
            name = "signpost",
            style = MaterialIcon.Style.Normal
        )
        val SimCard = MaterialIcon(
            name = "sim_card",
            style = MaterialIcon.Style.Normal
        )
        val SimCardAlert = MaterialIcon(
            name = "sim_card_alert",
            style = MaterialIcon.Style.Normal
        )
        val SimCardDownload = MaterialIcon(
            name = "sim_card_download",
            style = MaterialIcon.Style.Normal
        )
        val SingleBed = MaterialIcon(
            name = "single_bed",
            style = MaterialIcon.Style.Normal
        )
        val Sip = MaterialIcon(
            name = "sip",
            style = MaterialIcon.Style.Normal
        )
        val Skateboarding = MaterialIcon(
            name = "skateboarding",
            style = MaterialIcon.Style.Normal
        )
        val SkipNext = MaterialIcon(
            name = "skip_next",
            style = MaterialIcon.Style.Normal
        )
        val SkipPrevious = MaterialIcon(
            name = "skip_previous",
            style = MaterialIcon.Style.Normal
        )
        val Sledding = MaterialIcon(
            name = "sledding",
            style = MaterialIcon.Style.Normal
        )
        val Slideshow = MaterialIcon(
            name = "slideshow",
            style = MaterialIcon.Style.Normal
        )
        val SlowMotionVideo = MaterialIcon(
            name = "slow_motion_video",
            style = MaterialIcon.Style.Normal
        )
        val SmartButton = MaterialIcon(
            name = "smart_button",
            style = MaterialIcon.Style.Normal
        )
        val SmartDisplay = MaterialIcon(
            name = "smart_display",
            style = MaterialIcon.Style.Normal
        )
        val SmartScreen = MaterialIcon(
            name = "smart_screen",
            style = MaterialIcon.Style.Normal
        )
        val SmartToy = MaterialIcon(
            name = "smart_toy",
            style = MaterialIcon.Style.Normal
        )
        val Smartphone = MaterialIcon(
            name = "smartphone",
            style = MaterialIcon.Style.Normal
        )
        val SmokeFree = MaterialIcon(
            name = "smoke_free",
            style = MaterialIcon.Style.Normal
        )
        val SmokingRooms = MaterialIcon(
            name = "smoking_rooms",
            style = MaterialIcon.Style.Normal
        )
        val Sms = MaterialIcon(
            name = "sms",
            style = MaterialIcon.Style.Normal
        )
        val SmsFailed = MaterialIcon(
            name = "sms_failed",
            style = MaterialIcon.Style.Normal
        )
        val SnippetFolder = MaterialIcon(
            name = "snippet_folder",
            style = MaterialIcon.Style.Normal
        )
        val Snooze = MaterialIcon(
            name = "snooze",
            style = MaterialIcon.Style.Normal
        )
        val Snowboarding = MaterialIcon(
            name = "snowboarding",
            style = MaterialIcon.Style.Normal
        )
        val Snowmobile = MaterialIcon(
            name = "snowmobile",
            style = MaterialIcon.Style.Normal
        )
        val Snowshoeing = MaterialIcon(
            name = "snowshoeing",
            style = MaterialIcon.Style.Normal
        )
        val Soap = MaterialIcon(
            name = "soap",
            style = MaterialIcon.Style.Normal
        )
        val SocialDistance = MaterialIcon(
            name = "social_distance",
            style = MaterialIcon.Style.Normal
        )
        val SolarPower = MaterialIcon(
            name = "solar_power",
            style = MaterialIcon.Style.Normal
        )
        val Sort = MaterialIcon(
            name = "sort",
            style = MaterialIcon.Style.Normal
        )
        val SortByAlpha = MaterialIcon(
            name = "sort_by_alpha",
            style = MaterialIcon.Style.Normal
        )
        val Sos = MaterialIcon(
            name = "sos",
            style = MaterialIcon.Style.Normal
        )
        val SoupKitchen = MaterialIcon(
            name = "soup_kitchen",
            style = MaterialIcon.Style.Normal
        )
        val Source = MaterialIcon(
            name = "source",
            style = MaterialIcon.Style.Normal
        )
        val South = MaterialIcon(
            name = "south",
            style = MaterialIcon.Style.Normal
        )
        val SouthAmerica = MaterialIcon(
            name = "south_america",
            style = MaterialIcon.Style.Normal
        )
        val SouthEast = MaterialIcon(
            name = "south_east",
            style = MaterialIcon.Style.Normal
        )
        val SouthWest = MaterialIcon(
            name = "south_west",
            style = MaterialIcon.Style.Normal
        )
        val Spa = MaterialIcon(
            name = "spa",
            style = MaterialIcon.Style.Normal
        )
        val SpaceBar = MaterialIcon(
            name = "space_bar",
            style = MaterialIcon.Style.Normal
        )
        val SpaceDashboard = MaterialIcon(
            name = "space_dashboard",
            style = MaterialIcon.Style.Normal
        )
        val SpatialAudio = MaterialIcon(
            name = "spatial_audio",
            style = MaterialIcon.Style.Normal
        )
        val SpatialAudioOff = MaterialIcon(
            name = "spatial_audio_off",
            style = MaterialIcon.Style.Normal
        )
        val SpatialTracking = MaterialIcon(
            name = "spatial_tracking",
            style = MaterialIcon.Style.Normal
        )
        val Speaker = MaterialIcon(
            name = "speaker",
            style = MaterialIcon.Style.Normal
        )
        val SpeakerGroup = MaterialIcon(
            name = "speaker_group",
            style = MaterialIcon.Style.Normal
        )
        val SpeakerNotes = MaterialIcon(
            name = "speaker_notes",
            style = MaterialIcon.Style.Normal
        )
        val SpeakerNotesOff = MaterialIcon(
            name = "speaker_notes_off",
            style = MaterialIcon.Style.Normal
        )
        val SpeakerPhone = MaterialIcon(
            name = "speaker_phone",
            style = MaterialIcon.Style.Normal
        )
        val Speed = MaterialIcon(
            name = "speed",
            style = MaterialIcon.Style.Normal
        )
        val Spellcheck = MaterialIcon(
            name = "spellcheck",
            style = MaterialIcon.Style.Normal
        )
        val Splitscreen = MaterialIcon(
            name = "splitscreen",
            style = MaterialIcon.Style.Normal
        )
        val Spoke = MaterialIcon(
            name = "spoke",
            style = MaterialIcon.Style.Normal
        )
        val Sports = MaterialIcon(
            name = "sports",
            style = MaterialIcon.Style.Normal
        )
        val SportsBar = MaterialIcon(
            name = "sports_bar",
            style = MaterialIcon.Style.Normal
        )
        val SportsBaseball = MaterialIcon(
            name = "sports_baseball",
            style = MaterialIcon.Style.Normal
        )
        val SportsBasketball = MaterialIcon(
            name = "sports_basketball",
            style = MaterialIcon.Style.Normal
        )
        val SportsCricket = MaterialIcon(
            name = "sports_cricket",
            style = MaterialIcon.Style.Normal
        )
        val SportsEsports = MaterialIcon(
            name = "sports_esports",
            style = MaterialIcon.Style.Normal
        )
        val SportsFootball = MaterialIcon(
            name = "sports_football",
            style = MaterialIcon.Style.Normal
        )
        val SportsGolf = MaterialIcon(
            name = "sports_golf",
            style = MaterialIcon.Style.Normal
        )
        val SportsGymnastics = MaterialIcon(
            name = "sports_gymnastics",
            style = MaterialIcon.Style.Normal
        )
        val SportsHandball = MaterialIcon(
            name = "sports_handball",
            style = MaterialIcon.Style.Normal
        )
        val SportsHockey = MaterialIcon(
            name = "sports_hockey",
            style = MaterialIcon.Style.Normal
        )
        val SportsKabaddi = MaterialIcon(
            name = "sports_kabaddi",
            style = MaterialIcon.Style.Normal
        )
        val SportsMartialArts = MaterialIcon(
            name = "sports_martial_arts",
            style = MaterialIcon.Style.Normal
        )
        val SportsMma = MaterialIcon(
            name = "sports_mma",
            style = MaterialIcon.Style.Normal
        )
        val SportsMotorsports = MaterialIcon(
            name = "sports_motorsports",
            style = MaterialIcon.Style.Normal
        )
        val SportsRugby = MaterialIcon(
            name = "sports_rugby",
            style = MaterialIcon.Style.Normal
        )
        val SportsScore = MaterialIcon(
            name = "sports_score",
            style = MaterialIcon.Style.Normal
        )
        val SportsSoccer = MaterialIcon(
            name = "sports_soccer",
            style = MaterialIcon.Style.Normal
        )
        val SportsTennis = MaterialIcon(
            name = "sports_tennis",
            style = MaterialIcon.Style.Normal
        )
        val SportsVolleyball = MaterialIcon(
            name = "sports_volleyball",
            style = MaterialIcon.Style.Normal
        )
        val Square = MaterialIcon(
            name = "square",
            style = MaterialIcon.Style.Normal
        )
        val SquareFoot = MaterialIcon(
            name = "square_foot",
            style = MaterialIcon.Style.Normal
        )
        val SsidChart = MaterialIcon(
            name = "ssid_chart",
            style = MaterialIcon.Style.Normal
        )
        val StackedBarChart = MaterialIcon(
            name = "stacked_bar_chart",
            style = MaterialIcon.Style.Normal
        )
        val StackedLineChart = MaterialIcon(
            name = "stacked_line_chart",
            style = MaterialIcon.Style.Normal
        )
        val Stadium = MaterialIcon(
            name = "stadium",
            style = MaterialIcon.Style.Normal
        )
        val Stairs = MaterialIcon(
            name = "stairs",
            style = MaterialIcon.Style.Normal
        )
        val Star = MaterialIcon(
            name = "star",
            style = MaterialIcon.Style.Normal
        )
        val StarBorder = MaterialIcon(
            name = "star_border",
            style = MaterialIcon.Style.Normal
        )
        val StarBorderPurple500 = MaterialIcon(
            name = "star_border_purple500",
            style = MaterialIcon.Style.Normal
        )
        val StarHalf = MaterialIcon(
            name = "star_half",
            style = MaterialIcon.Style.Normal
        )
        val StarOutline = MaterialIcon(
            name = "star_outline",
            style = MaterialIcon.Style.Normal
        )
        val StarPurple500 = MaterialIcon(
            name = "star_purple500",
            style = MaterialIcon.Style.Normal
        )
        val StarRate = MaterialIcon(
            name = "star_rate",
            style = MaterialIcon.Style.Normal
        )
        val Stars = MaterialIcon(
            name = "stars",
            style = MaterialIcon.Style.Normal
        )
        val Start = MaterialIcon(
            name = "start",
            style = MaterialIcon.Style.Normal
        )
        val StayCurrentLandscape = MaterialIcon(
            name = "stay_current_landscape",
            style = MaterialIcon.Style.Normal
        )
        val StayCurrentPortrait = MaterialIcon(
            name = "stay_current_portrait",
            style = MaterialIcon.Style.Normal
        )
        val StayPrimaryLandscape = MaterialIcon(
            name = "stay_primary_landscape",
            style = MaterialIcon.Style.Normal
        )
        val StayPrimaryPortrait = MaterialIcon(
            name = "stay_primary_portrait",
            style = MaterialIcon.Style.Normal
        )
        val StickyNote2 = MaterialIcon(
            name = "sticky_note_2",
            style = MaterialIcon.Style.Normal
        )
        val Stop = MaterialIcon(
            name = "stop",
            style = MaterialIcon.Style.Normal
        )
        val StopCircle = MaterialIcon(
            name = "stop_circle",
            style = MaterialIcon.Style.Normal
        )
        val StopScreenShare = MaterialIcon(
            name = "stop_screen_share",
            style = MaterialIcon.Style.Normal
        )
        val Storage = MaterialIcon(
            name = "storage",
            style = MaterialIcon.Style.Normal
        )
        val Store = MaterialIcon(
            name = "store",
            style = MaterialIcon.Style.Normal
        )
        val StoreMallDirectory = MaterialIcon(
            name = "store_mall_directory",
            style = MaterialIcon.Style.Normal
        )
        val Storefront = MaterialIcon(
            name = "storefront",
            style = MaterialIcon.Style.Normal
        )
        val Storm = MaterialIcon(
            name = "storm",
            style = MaterialIcon.Style.Normal
        )
        val Straight = MaterialIcon(
            name = "straight",
            style = MaterialIcon.Style.Normal
        )
        val Straighten = MaterialIcon(
            name = "straighten",
            style = MaterialIcon.Style.Normal
        )
        val Stream = MaterialIcon(
            name = "stream",
            style = MaterialIcon.Style.Normal
        )
        val Streetview = MaterialIcon(
            name = "streetview",
            style = MaterialIcon.Style.Normal
        )
        val StrikethroughS = MaterialIcon(
            name = "strikethrough_s",
            style = MaterialIcon.Style.Normal
        )
        val Stroller = MaterialIcon(
            name = "stroller",
            style = MaterialIcon.Style.Normal
        )
        val Style = MaterialIcon(
            name = "style",
            style = MaterialIcon.Style.Normal
        )
        val SubdirectoryArrowLeft = MaterialIcon(
            name = "subdirectory_arrow_left",
            style = MaterialIcon.Style.Normal
        )
        val SubdirectoryArrowRight = MaterialIcon(
            name = "subdirectory_arrow_right",
            style = MaterialIcon.Style.Normal
        )
        val Subject = MaterialIcon(
            name = "subject",
            style = MaterialIcon.Style.Normal
        )
        val Subscript = MaterialIcon(
            name = "subscript",
            style = MaterialIcon.Style.Normal
        )
        val Subscriptions = MaterialIcon(
            name = "subscriptions",
            style = MaterialIcon.Style.Normal
        )
        val Subtitles = MaterialIcon(
            name = "subtitles",
            style = MaterialIcon.Style.Normal
        )
        val SubtitlesOff = MaterialIcon(
            name = "subtitles_off",
            style = MaterialIcon.Style.Normal
        )
        val Subway = MaterialIcon(
            name = "subway",
            style = MaterialIcon.Style.Normal
        )
        val Summarize = MaterialIcon(
            name = "summarize",
            style = MaterialIcon.Style.Normal
        )
        val Superscript = MaterialIcon(
            name = "superscript",
            style = MaterialIcon.Style.Normal
        )
        val SupervisedUserCircle = MaterialIcon(
            name = "supervised_user_circle",
            style = MaterialIcon.Style.Normal
        )
        val SupervisorAccount = MaterialIcon(
            name = "supervisor_account",
            style = MaterialIcon.Style.Normal
        )
        val Support = MaterialIcon(
            name = "support",
            style = MaterialIcon.Style.Normal
        )
        val SupportAgent = MaterialIcon(
            name = "support_agent",
            style = MaterialIcon.Style.Normal
        )
        val Surfing = MaterialIcon(
            name = "surfing",
            style = MaterialIcon.Style.Normal
        )
        val SurroundSound = MaterialIcon(
            name = "surround_sound",
            style = MaterialIcon.Style.Normal
        )
        val SwapCalls = MaterialIcon(
            name = "swap_calls",
            style = MaterialIcon.Style.Normal
        )
        val SwapHoriz = MaterialIcon(
            name = "swap_horiz",
            style = MaterialIcon.Style.Normal
        )
        val SwapHorizontalCircle = MaterialIcon(
            name = "swap_horizontal_circle",
            style = MaterialIcon.Style.Normal
        )
        val SwapVert = MaterialIcon(
            name = "swap_vert",
            style = MaterialIcon.Style.Normal
        )
        val SwapVerticalCircle = MaterialIcon(
            name = "swap_vertical_circle",
            style = MaterialIcon.Style.Normal
        )
        val Swipe = MaterialIcon(
            name = "swipe",
            style = MaterialIcon.Style.Normal
        )
        val SwipeDown = MaterialIcon(
            name = "swipe_down",
            style = MaterialIcon.Style.Normal
        )
        val SwipeDownAlt = MaterialIcon(
            name = "swipe_down_alt",
            style = MaterialIcon.Style.Normal
        )
        val SwipeLeft = MaterialIcon(
            name = "swipe_left",
            style = MaterialIcon.Style.Normal
        )
        val SwipeLeftAlt = MaterialIcon(
            name = "swipe_left_alt",
            style = MaterialIcon.Style.Normal
        )
        val SwipeRight = MaterialIcon(
            name = "swipe_right",
            style = MaterialIcon.Style.Normal
        )
        val SwipeRightAlt = MaterialIcon(
            name = "swipe_right_alt",
            style = MaterialIcon.Style.Normal
        )
        val SwipeUp = MaterialIcon(
            name = "swipe_up",
            style = MaterialIcon.Style.Normal
        )
        val SwipeUpAlt = MaterialIcon(
            name = "swipe_up_alt",
            style = MaterialIcon.Style.Normal
        )
        val SwipeVertical = MaterialIcon(
            name = "swipe_vertical",
            style = MaterialIcon.Style.Normal
        )
        val SwitchAccessShortcut = MaterialIcon(
            name = "switch_access_shortcut",
            style = MaterialIcon.Style.Normal
        )
        val SwitchAccessShortcutAdd = MaterialIcon(
            name = "switch_access_shortcut_add",
            style = MaterialIcon.Style.Normal
        )
        val SwitchAccount = MaterialIcon(
            name = "switch_account",
            style = MaterialIcon.Style.Normal
        )
        val SwitchCamera = MaterialIcon(
            name = "switch_camera",
            style = MaterialIcon.Style.Normal
        )
        val SwitchLeft = MaterialIcon(
            name = "switch_left",
            style = MaterialIcon.Style.Normal
        )
        val SwitchRight = MaterialIcon(
            name = "switch_right",
            style = MaterialIcon.Style.Normal
        )
        val SwitchVideo = MaterialIcon(
            name = "switch_video",
            style = MaterialIcon.Style.Normal
        )
        val Synagogue = MaterialIcon(
            name = "synagogue",
            style = MaterialIcon.Style.Normal
        )
        val Sync = MaterialIcon(
            name = "sync",
            style = MaterialIcon.Style.Normal
        )
        val SyncAlt = MaterialIcon(
            name = "sync_alt",
            style = MaterialIcon.Style.Normal
        )
        val SyncDisabled = MaterialIcon(
            name = "sync_disabled",
            style = MaterialIcon.Style.Normal
        )
        val SyncLock = MaterialIcon(
            name = "sync_lock",
            style = MaterialIcon.Style.Normal
        )
        val SyncProblem = MaterialIcon(
            name = "sync_problem",
            style = MaterialIcon.Style.Normal
        )
        val SystemSecurityUpdate = MaterialIcon(
            name = "system_security_update",
            style = MaterialIcon.Style.Normal
        )
        val SystemSecurityUpdateGood = MaterialIcon(
            name = "system_security_update_good",
            style = MaterialIcon.Style.Normal
        )
        val SystemSecurityUpdateWarning = MaterialIcon(
            name = "system_security_update_warning",
            style = MaterialIcon.Style.Normal
        )
        val SystemUpdate = MaterialIcon(
            name = "system_update",
            style = MaterialIcon.Style.Normal
        )
        val SystemUpdateAlt = MaterialIcon(
            name = "system_update_alt",
            style = MaterialIcon.Style.Normal
        )
        val Tab = MaterialIcon(
            name = "tab",
            style = MaterialIcon.Style.Normal
        )
        val TabUnselected = MaterialIcon(
            name = "tab_unselected",
            style = MaterialIcon.Style.Normal
        )
        val TableBar = MaterialIcon(
            name = "table_bar",
            style = MaterialIcon.Style.Normal
        )
        val TableChart = MaterialIcon(
            name = "table_chart",
            style = MaterialIcon.Style.Normal
        )
        val TableRestaurant = MaterialIcon(
            name = "table_restaurant",
            style = MaterialIcon.Style.Normal
        )
        val TableRows = MaterialIcon(
            name = "table_rows",
            style = MaterialIcon.Style.Normal
        )
        val TableView = MaterialIcon(
            name = "table_view",
            style = MaterialIcon.Style.Normal
        )
        val Tablet = MaterialIcon(
            name = "tablet",
            style = MaterialIcon.Style.Normal
        )
        val TabletAndroid = MaterialIcon(
            name = "tablet_android",
            style = MaterialIcon.Style.Normal
        )
        val TabletMac = MaterialIcon(
            name = "tablet_mac",
            style = MaterialIcon.Style.Normal
        )
        val Tag = MaterialIcon(
            name = "tag",
            style = MaterialIcon.Style.Normal
        )
        val TagFaces = MaterialIcon(
            name = "tag_faces",
            style = MaterialIcon.Style.Normal
        )
        val TakeoutDining = MaterialIcon(
            name = "takeout_dining",
            style = MaterialIcon.Style.Normal
        )
        val TapAndPlay = MaterialIcon(
            name = "tap_and_play",
            style = MaterialIcon.Style.Normal
        )
        val Tapas = MaterialIcon(
            name = "tapas",
            style = MaterialIcon.Style.Normal
        )
        val Task = MaterialIcon(
            name = "task",
            style = MaterialIcon.Style.Normal
        )
        val TaskAlt = MaterialIcon(
            name = "task_alt",
            style = MaterialIcon.Style.Normal
        )
        val TaxiAlert = MaterialIcon(
            name = "taxi_alert",
            style = MaterialIcon.Style.Normal
        )
        val TempleBuddhist = MaterialIcon(
            name = "temple_buddhist",
            style = MaterialIcon.Style.Normal
        )
        val TempleHindu = MaterialIcon(
            name = "temple_hindu",
            style = MaterialIcon.Style.Normal
        )
        val Terminal = MaterialIcon(
            name = "terminal",
            style = MaterialIcon.Style.Normal
        )
        val Terrain = MaterialIcon(
            name = "terrain",
            style = MaterialIcon.Style.Normal
        )
        val TextDecrease = MaterialIcon(
            name = "text_decrease",
            style = MaterialIcon.Style.Normal
        )
        val TextFields = MaterialIcon(
            name = "text_fields",
            style = MaterialIcon.Style.Normal
        )
        val TextFormat = MaterialIcon(
            name = "text_format",
            style = MaterialIcon.Style.Normal
        )
        val TextIncrease = MaterialIcon(
            name = "text_increase",
            style = MaterialIcon.Style.Normal
        )
        val TextRotateUp = MaterialIcon(
            name = "text_rotate_up",
            style = MaterialIcon.Style.Normal
        )
        val TextRotateVertical = MaterialIcon(
            name = "text_rotate_vertical",
            style = MaterialIcon.Style.Normal
        )
        val TextRotationAngledown = MaterialIcon(
            name = "text_rotation_angledown",
            style = MaterialIcon.Style.Normal
        )
        val TextRotationAngleup = MaterialIcon(
            name = "text_rotation_angleup",
            style = MaterialIcon.Style.Normal
        )
        val TextRotationDown = MaterialIcon(
            name = "text_rotation_down",
            style = MaterialIcon.Style.Normal
        )
        val TextRotationNone = MaterialIcon(
            name = "text_rotation_none",
            style = MaterialIcon.Style.Normal
        )
        val TextSnippet = MaterialIcon(
            name = "text_snippet",
            style = MaterialIcon.Style.Normal
        )
        val Textsms = MaterialIcon(
            name = "textsms",
            style = MaterialIcon.Style.Normal
        )
        val Texture = MaterialIcon(
            name = "texture",
            style = MaterialIcon.Style.Normal
        )
        val TheaterComedy = MaterialIcon(
            name = "theater_comedy",
            style = MaterialIcon.Style.Normal
        )
        val Theaters = MaterialIcon(
            name = "theaters",
            style = MaterialIcon.Style.Normal
        )
        val Thermostat = MaterialIcon(
            name = "thermostat",
            style = MaterialIcon.Style.Normal
        )
        val ThermostatAuto = MaterialIcon(
            name = "thermostat_auto",
            style = MaterialIcon.Style.Normal
        )
        val ThumbDown = MaterialIcon(
            name = "thumb_down",
            style = MaterialIcon.Style.Normal
        )
        val ThumbDownAlt = MaterialIcon(
            name = "thumb_down_alt",
            style = MaterialIcon.Style.Normal
        )
        val ThumbDownOffAlt = MaterialIcon(
            name = "thumb_down_off_alt",
            style = MaterialIcon.Style.Normal
        )
        val ThumbUp = MaterialIcon(
            name = "thumb_up",
            style = MaterialIcon.Style.Normal
        )
        val ThumbUpAlt = MaterialIcon(
            name = "thumb_up_alt",
            style = MaterialIcon.Style.Normal
        )
        val ThumbUpOffAlt = MaterialIcon(
            name = "thumb_up_off_alt",
            style = MaterialIcon.Style.Normal
        )
        val ThumbsUpDown = MaterialIcon(
            name = "thumbs_up_down",
            style = MaterialIcon.Style.Normal
        )
        val Thunderstorm = MaterialIcon(
            name = "thunderstorm",
            style = MaterialIcon.Style.Normal
        )
        val TimeToLeave = MaterialIcon(
            name = "time_to_leave",
            style = MaterialIcon.Style.Normal
        )
        val Timelapse = MaterialIcon(
            name = "timelapse",
            style = MaterialIcon.Style.Normal
        )
        val Timeline = MaterialIcon(
            name = "timeline",
            style = MaterialIcon.Style.Normal
        )
        val Timer = MaterialIcon(
            name = "timer",
            style = MaterialIcon.Style.Normal
        )
        val Timer10 = MaterialIcon(
            name = "timer_10",
            style = MaterialIcon.Style.Normal
        )
        val Timer10Select = MaterialIcon(
            name = "timer_10_select",
            style = MaterialIcon.Style.Normal
        )
        val Timer3 = MaterialIcon(
            name = "timer_3",
            style = MaterialIcon.Style.Normal
        )
        val Timer3Select = MaterialIcon(
            name = "timer_3_select",
            style = MaterialIcon.Style.Normal
        )
        val TimerOff = MaterialIcon(
            name = "timer_off",
            style = MaterialIcon.Style.Normal
        )
        val TipsAndUpdates = MaterialIcon(
            name = "tips_and_updates",
            style = MaterialIcon.Style.Normal
        )
        val TireRepair = MaterialIcon(
            name = "tire_repair",
            style = MaterialIcon.Style.Normal
        )
        val Title = MaterialIcon(
            name = "title",
            style = MaterialIcon.Style.Normal
        )
        val Toc = MaterialIcon(
            name = "toc",
            style = MaterialIcon.Style.Normal
        )
        val Today = MaterialIcon(
            name = "today",
            style = MaterialIcon.Style.Normal
        )
        val ToggleOff = MaterialIcon(
            name = "toggle_off",
            style = MaterialIcon.Style.Normal
        )
        val ToggleOn = MaterialIcon(
            name = "toggle_on",
            style = MaterialIcon.Style.Normal
        )
        val Token = MaterialIcon(
            name = "token",
            style = MaterialIcon.Style.Normal
        )
        val Toll = MaterialIcon(
            name = "toll",
            style = MaterialIcon.Style.Normal
        )
        val Tonality = MaterialIcon(
            name = "tonality",
            style = MaterialIcon.Style.Normal
        )
        val Topic = MaterialIcon(
            name = "topic",
            style = MaterialIcon.Style.Normal
        )
        val Tornado = MaterialIcon(
            name = "tornado",
            style = MaterialIcon.Style.Normal
        )
        val TouchApp = MaterialIcon(
            name = "touch_app",
            style = MaterialIcon.Style.Normal
        )
        val Tour = MaterialIcon(
            name = "tour",
            style = MaterialIcon.Style.Normal
        )
        val Toys = MaterialIcon(
            name = "toys",
            style = MaterialIcon.Style.Normal
        )
        val TrackChanges = MaterialIcon(
            name = "track_changes",
            style = MaterialIcon.Style.Normal
        )
        val Traffic = MaterialIcon(
            name = "traffic",
            style = MaterialIcon.Style.Normal
        )
        val Train = MaterialIcon(
            name = "train",
            style = MaterialIcon.Style.Normal
        )
        val Tram = MaterialIcon(
            name = "tram",
            style = MaterialIcon.Style.Normal
        )
        val Transcribe = MaterialIcon(
            name = "transcribe",
            style = MaterialIcon.Style.Normal
        )
        val TransferWithinAStation = MaterialIcon(
            name = "transfer_within_a_station",
            style = MaterialIcon.Style.Normal
        )
        val Transform = MaterialIcon(
            name = "transform",
            style = MaterialIcon.Style.Normal
        )
        val Transgender = MaterialIcon(
            name = "transgender",
            style = MaterialIcon.Style.Normal
        )
        val TransitEnterexit = MaterialIcon(
            name = "transit_enterexit",
            style = MaterialIcon.Style.Normal
        )
        val Translate = MaterialIcon(
            name = "translate",
            style = MaterialIcon.Style.Normal
        )
        val TravelExplore = MaterialIcon(
            name = "travel_explore",
            style = MaterialIcon.Style.Normal
        )
        val TrendingDown = MaterialIcon(
            name = "trending_down",
            style = MaterialIcon.Style.Normal
        )
        val TrendingFlat = MaterialIcon(
            name = "trending_flat",
            style = MaterialIcon.Style.Normal
        )
        val TrendingUp = MaterialIcon(
            name = "trending_up",
            style = MaterialIcon.Style.Normal
        )
        val TripOrigin = MaterialIcon(
            name = "trip_origin",
            style = MaterialIcon.Style.Normal
        )
        val Troubleshoot = MaterialIcon(
            name = "troubleshoot",
            style = MaterialIcon.Style.Normal
        )
        val Try = MaterialIcon(
            name = "try",
            style = MaterialIcon.Style.Normal
        )
        val Tsunami = MaterialIcon(
            name = "tsunami",
            style = MaterialIcon.Style.Normal
        )
        val Tty = MaterialIcon(
            name = "tty",
            style = MaterialIcon.Style.Normal
        )
        val Tune = MaterialIcon(
            name = "tune",
            style = MaterialIcon.Style.Normal
        )
        val Tungsten = MaterialIcon(
            name = "tungsten",
            style = MaterialIcon.Style.Normal
        )
        val TurnLeft = MaterialIcon(
            name = "turn_left",
            style = MaterialIcon.Style.Normal
        )
        val TurnRight = MaterialIcon(
            name = "turn_right",
            style = MaterialIcon.Style.Normal
        )
        val TurnSharpLeft = MaterialIcon(
            name = "turn_sharp_left",
            style = MaterialIcon.Style.Normal
        )
        val TurnSharpRight = MaterialIcon(
            name = "turn_sharp_right",
            style = MaterialIcon.Style.Normal
        )
        val TurnSlightLeft = MaterialIcon(
            name = "turn_slight_left",
            style = MaterialIcon.Style.Normal
        )
        val TurnSlightRight = MaterialIcon(
            name = "turn_slight_right",
            style = MaterialIcon.Style.Normal
        )
        val TurnedIn = MaterialIcon(
            name = "turned_in",
            style = MaterialIcon.Style.Normal
        )
        val TurnedInNot = MaterialIcon(
            name = "turned_in_not",
            style = MaterialIcon.Style.Normal
        )
        val Tv = MaterialIcon(
            name = "tv",
            style = MaterialIcon.Style.Normal
        )
        val TvOff = MaterialIcon(
            name = "tv_off",
            style = MaterialIcon.Style.Normal
        )
        val TwoWheeler = MaterialIcon(
            name = "two_wheeler",
            style = MaterialIcon.Style.Normal
        )
        val TypeSpecimen = MaterialIcon(
            name = "type_specimen",
            style = MaterialIcon.Style.Normal
        )
        val UTurnLeft = MaterialIcon(
            name = "u_turn_left",
            style = MaterialIcon.Style.Normal
        )
        val UTurnRight = MaterialIcon(
            name = "u_turn_right",
            style = MaterialIcon.Style.Normal
        )
        val Umbrella = MaterialIcon(
            name = "umbrella",
            style = MaterialIcon.Style.Normal
        )
        val Unarchive = MaterialIcon(
            name = "unarchive",
            style = MaterialIcon.Style.Normal
        )
        val Undo = MaterialIcon(
            name = "undo",
            style = MaterialIcon.Style.Normal
        )
        val UnfoldLess = MaterialIcon(
            name = "unfold_less",
            style = MaterialIcon.Style.Normal
        )
        val UnfoldLessDouble = MaterialIcon(
            name = "unfold_less_double",
            style = MaterialIcon.Style.Normal
        )
        val UnfoldMore = MaterialIcon(
            name = "unfold_more",
            style = MaterialIcon.Style.Normal
        )
        val UnfoldMoreDouble = MaterialIcon(
            name = "unfold_more_double",
            style = MaterialIcon.Style.Normal
        )
        val Unpublished = MaterialIcon(
            name = "unpublished",
            style = MaterialIcon.Style.Normal
        )
        val Unsubscribe = MaterialIcon(
            name = "unsubscribe",
            style = MaterialIcon.Style.Normal
        )
        val Upcoming = MaterialIcon(
            name = "upcoming",
            style = MaterialIcon.Style.Normal
        )
        val Update = MaterialIcon(
            name = "update",
            style = MaterialIcon.Style.Normal
        )
        val UpdateDisabled = MaterialIcon(
            name = "update_disabled",
            style = MaterialIcon.Style.Normal
        )
        val Upgrade = MaterialIcon(
            name = "upgrade",
            style = MaterialIcon.Style.Normal
        )
        val Upload = MaterialIcon(
            name = "upload",
            style = MaterialIcon.Style.Normal
        )
        val UploadFile = MaterialIcon(
            name = "upload_file",
            style = MaterialIcon.Style.Normal
        )
        val Usb = MaterialIcon(
            name = "usb",
            style = MaterialIcon.Style.Normal
        )
        val UsbOff = MaterialIcon(
            name = "usb_off",
            style = MaterialIcon.Style.Normal
        )
        val Vaccines = MaterialIcon(
            name = "vaccines",
            style = MaterialIcon.Style.Normal
        )
        val VapeFree = MaterialIcon(
            name = "vape_free",
            style = MaterialIcon.Style.Normal
        )
        val VapingRooms = MaterialIcon(
            name = "vaping_rooms",
            style = MaterialIcon.Style.Normal
        )
        val Verified = MaterialIcon(
            name = "verified",
            style = MaterialIcon.Style.Normal
        )
        val VerifiedUser = MaterialIcon(
            name = "verified_user",
            style = MaterialIcon.Style.Normal
        )
        val VerticalAlignBottom = MaterialIcon(
            name = "vertical_align_bottom",
            style = MaterialIcon.Style.Normal
        )
        val VerticalAlignCenter = MaterialIcon(
            name = "vertical_align_center",
            style = MaterialIcon.Style.Normal
        )
        val VerticalAlignTop = MaterialIcon(
            name = "vertical_align_top",
            style = MaterialIcon.Style.Normal
        )
        val VerticalDistribute = MaterialIcon(
            name = "vertical_distribute",
            style = MaterialIcon.Style.Normal
        )
        val VerticalShades = MaterialIcon(
            name = "vertical_shades",
            style = MaterialIcon.Style.Normal
        )
        val VerticalShadesClosed = MaterialIcon(
            name = "vertical_shades_closed",
            style = MaterialIcon.Style.Normal
        )
        val VerticalSplit = MaterialIcon(
            name = "vertical_split",
            style = MaterialIcon.Style.Normal
        )
        val Vibration = MaterialIcon(
            name = "vibration",
            style = MaterialIcon.Style.Normal
        )
        val VideoCall = MaterialIcon(
            name = "video_call",
            style = MaterialIcon.Style.Normal
        )
        val VideoCameraBack = MaterialIcon(
            name = "video_camera_back",
            style = MaterialIcon.Style.Normal
        )
        val VideoCameraFront = MaterialIcon(
            name = "video_camera_front",
            style = MaterialIcon.Style.Normal
        )
        val VideoChat = MaterialIcon(
            name = "video_chat",
            style = MaterialIcon.Style.Normal
        )
        val VideoFile = MaterialIcon(
            name = "video_file",
            style = MaterialIcon.Style.Normal
        )
        val VideoLabel = MaterialIcon(
            name = "video_label",
            style = MaterialIcon.Style.Normal
        )
        val VideoLibrary = MaterialIcon(
            name = "video_library",
            style = MaterialIcon.Style.Normal
        )
        val VideoSettings = MaterialIcon(
            name = "video_settings",
            style = MaterialIcon.Style.Normal
        )
        val VideoStable = MaterialIcon(
            name = "video_stable",
            style = MaterialIcon.Style.Normal
        )
        val Videocam = MaterialIcon(
            name = "videocam",
            style = MaterialIcon.Style.Normal
        )
        val VideocamOff = MaterialIcon(
            name = "videocam_off",
            style = MaterialIcon.Style.Normal
        )
        val VideogameAsset = MaterialIcon(
            name = "videogame_asset",
            style = MaterialIcon.Style.Normal
        )
        val VideogameAssetOff = MaterialIcon(
            name = "videogame_asset_off",
            style = MaterialIcon.Style.Normal
        )
        val ViewAgenda = MaterialIcon(
            name = "view_agenda",
            style = MaterialIcon.Style.Normal
        )
        val ViewArray = MaterialIcon(
            name = "view_array",
            style = MaterialIcon.Style.Normal
        )
        val ViewCarousel = MaterialIcon(
            name = "view_carousel",
            style = MaterialIcon.Style.Normal
        )
        val ViewColumn = MaterialIcon(
            name = "view_column",
            style = MaterialIcon.Style.Normal
        )
        val ViewComfy = MaterialIcon(
            name = "view_comfy",
            style = MaterialIcon.Style.Normal
        )
        val ViewComfyAlt = MaterialIcon(
            name = "view_comfy_alt",
            style = MaterialIcon.Style.Normal
        )
        val ViewCompact = MaterialIcon(
            name = "view_compact",
            style = MaterialIcon.Style.Normal
        )
        val ViewCompactAlt = MaterialIcon(
            name = "view_compact_alt",
            style = MaterialIcon.Style.Normal
        )
        val ViewCozy = MaterialIcon(
            name = "view_cozy",
            style = MaterialIcon.Style.Normal
        )
        val ViewDay = MaterialIcon(
            name = "view_day",
            style = MaterialIcon.Style.Normal
        )
        val ViewHeadline = MaterialIcon(
            name = "view_headline",
            style = MaterialIcon.Style.Normal
        )
        val ViewInAr = MaterialIcon(
            name = "view_in_ar",
            style = MaterialIcon.Style.Normal
        )
        val ViewKanban = MaterialIcon(
            name = "view_kanban",
            style = MaterialIcon.Style.Normal
        )
        val ViewList = MaterialIcon(
            name = "view_list",
            style = MaterialIcon.Style.Normal
        )
        val ViewModule = MaterialIcon(
            name = "view_module",
            style = MaterialIcon.Style.Normal
        )
        val ViewQuilt = MaterialIcon(
            name = "view_quilt",
            style = MaterialIcon.Style.Normal
        )
        val ViewSidebar = MaterialIcon(
            name = "view_sidebar",
            style = MaterialIcon.Style.Normal
        )
        val ViewStream = MaterialIcon(
            name = "view_stream",
            style = MaterialIcon.Style.Normal
        )
        val ViewTimeline = MaterialIcon(
            name = "view_timeline",
            style = MaterialIcon.Style.Normal
        )
        val ViewWeek = MaterialIcon(
            name = "view_week",
            style = MaterialIcon.Style.Normal
        )
        val Vignette = MaterialIcon(
            name = "vignette",
            style = MaterialIcon.Style.Normal
        )
        val Villa = MaterialIcon(
            name = "villa",
            style = MaterialIcon.Style.Normal
        )
        val Visibility = MaterialIcon(
            name = "visibility",
            style = MaterialIcon.Style.Normal
        )
        val VisibilityOff = MaterialIcon(
            name = "visibility_off",
            style = MaterialIcon.Style.Normal
        )
        val VoiceChat = MaterialIcon(
            name = "voice_chat",
            style = MaterialIcon.Style.Normal
        )
        val VoiceOverOff = MaterialIcon(
            name = "voice_over_off",
            style = MaterialIcon.Style.Normal
        )
        val Voicemail = MaterialIcon(
            name = "voicemail",
            style = MaterialIcon.Style.Normal
        )
        val Volcano = MaterialIcon(
            name = "volcano",
            style = MaterialIcon.Style.Normal
        )
        val VolumeDown = MaterialIcon(
            name = "volume_down",
            style = MaterialIcon.Style.Normal
        )
        val VolumeMute = MaterialIcon(
            name = "volume_mute",
            style = MaterialIcon.Style.Normal
        )
        val VolumeOff = MaterialIcon(
            name = "volume_off",
            style = MaterialIcon.Style.Normal
        )
        val VolumeUp = MaterialIcon(
            name = "volume_up",
            style = MaterialIcon.Style.Normal
        )
        val VolunteerActivism = MaterialIcon(
            name = "volunteer_activism",
            style = MaterialIcon.Style.Normal
        )
        val VpnKey = MaterialIcon(
            name = "vpn_key",
            style = MaterialIcon.Style.Normal
        )
        val VpnKeyOff = MaterialIcon(
            name = "vpn_key_off",
            style = MaterialIcon.Style.Normal
        )
        val VpnLock = MaterialIcon(
            name = "vpn_lock",
            style = MaterialIcon.Style.Normal
        )
        val Vrpano = MaterialIcon(
            name = "vrpano",
            style = MaterialIcon.Style.Normal
        )
        val Wallet = MaterialIcon(
            name = "wallet",
            style = MaterialIcon.Style.Normal
        )
        val Wallpaper = MaterialIcon(
            name = "wallpaper",
            style = MaterialIcon.Style.Normal
        )
        val Warehouse = MaterialIcon(
            name = "warehouse",
            style = MaterialIcon.Style.Normal
        )
        val Warning = MaterialIcon(
            name = "warning",
            style = MaterialIcon.Style.Normal
        )
        val WarningAmber = MaterialIcon(
            name = "warning_amber",
            style = MaterialIcon.Style.Normal
        )
        val Wash = MaterialIcon(
            name = "wash",
            style = MaterialIcon.Style.Normal
        )
        val Watch = MaterialIcon(
            name = "watch",
            style = MaterialIcon.Style.Normal
        )
        val WatchLater = MaterialIcon(
            name = "watch_later",
            style = MaterialIcon.Style.Normal
        )
        val WatchOff = MaterialIcon(
            name = "watch_off",
            style = MaterialIcon.Style.Normal
        )
        val Water = MaterialIcon(
            name = "water",
            style = MaterialIcon.Style.Normal
        )
        val WaterDamage = MaterialIcon(
            name = "water_damage",
            style = MaterialIcon.Style.Normal
        )
        val WaterDrop = MaterialIcon(
            name = "water_drop",
            style = MaterialIcon.Style.Normal
        )
        val WaterfallChart = MaterialIcon(
            name = "waterfall_chart",
            style = MaterialIcon.Style.Normal
        )
        val Waves = MaterialIcon(
            name = "waves",
            style = MaterialIcon.Style.Normal
        )
        val WavingHand = MaterialIcon(
            name = "waving_hand",
            style = MaterialIcon.Style.Normal
        )
        val WbAuto = MaterialIcon(
            name = "wb_auto",
            style = MaterialIcon.Style.Normal
        )
        val WbCloudy = MaterialIcon(
            name = "wb_cloudy",
            style = MaterialIcon.Style.Normal
        )
        val WbIncandescent = MaterialIcon(
            name = "wb_incandescent",
            style = MaterialIcon.Style.Normal
        )
        val WbIridescent = MaterialIcon(
            name = "wb_iridescent",
            style = MaterialIcon.Style.Normal
        )
        val WbShade = MaterialIcon(
            name = "wb_shade",
            style = MaterialIcon.Style.Normal
        )
        val WbSunny = MaterialIcon(
            name = "wb_sunny",
            style = MaterialIcon.Style.Normal
        )
        val WbTwilight = MaterialIcon(
            name = "wb_twilight",
            style = MaterialIcon.Style.Normal
        )
        val Wc = MaterialIcon(
            name = "wc",
            style = MaterialIcon.Style.Normal
        )
        val Web = MaterialIcon(
            name = "web",
            style = MaterialIcon.Style.Normal
        )
        val WebAsset = MaterialIcon(
            name = "web_asset",
            style = MaterialIcon.Style.Normal
        )
        val WebAssetOff = MaterialIcon(
            name = "web_asset_off",
            style = MaterialIcon.Style.Normal
        )
        val WebStories = MaterialIcon(
            name = "web_stories",
            style = MaterialIcon.Style.Normal
        )
        val Webhook = MaterialIcon(
            name = "webhook",
            style = MaterialIcon.Style.Normal
        )
        val Weekend = MaterialIcon(
            name = "weekend",
            style = MaterialIcon.Style.Normal
        )
        val West = MaterialIcon(
            name = "west",
            style = MaterialIcon.Style.Normal
        )
        val Whatshot = MaterialIcon(
            name = "whatshot",
            style = MaterialIcon.Style.Normal
        )
        val WheelchairPickup = MaterialIcon(
            name = "wheelchair_pickup",
            style = MaterialIcon.Style.Normal
        )
        val WhereToVote = MaterialIcon(
            name = "where_to_vote",
            style = MaterialIcon.Style.Normal
        )
        val Widgets = MaterialIcon(
            name = "widgets",
            style = MaterialIcon.Style.Normal
        )
        val WidthFull = MaterialIcon(
            name = "width_full",
            style = MaterialIcon.Style.Normal
        )
        val WidthNormal = MaterialIcon(
            name = "width_normal",
            style = MaterialIcon.Style.Normal
        )
        val WidthWide = MaterialIcon(
            name = "width_wide",
            style = MaterialIcon.Style.Normal
        )
        val Wifi = MaterialIcon(
            name = "wifi",
            style = MaterialIcon.Style.Normal
        )
        val Wifi1Bar = MaterialIcon(
            name = "wifi_1_bar",
            style = MaterialIcon.Style.Normal
        )
        val Wifi2Bar = MaterialIcon(
            name = "wifi_2_bar",
            style = MaterialIcon.Style.Normal
        )
        val WifiCalling = MaterialIcon(
            name = "wifi_calling",
            style = MaterialIcon.Style.Normal
        )
        val WifiCalling3 = MaterialIcon(
            name = "wifi_calling_3",
            style = MaterialIcon.Style.Normal
        )
        val WifiChannel = MaterialIcon(
            name = "wifi_channel",
            style = MaterialIcon.Style.Normal
        )
        val WifiFind = MaterialIcon(
            name = "wifi_find",
            style = MaterialIcon.Style.Normal
        )
        val WifiLock = MaterialIcon(
            name = "wifi_lock",
            style = MaterialIcon.Style.Normal
        )
        val WifiOff = MaterialIcon(
            name = "wifi_off",
            style = MaterialIcon.Style.Normal
        )
        val WifiPassword = MaterialIcon(
            name = "wifi_password",
            style = MaterialIcon.Style.Normal
        )
        val WifiProtectedSetup = MaterialIcon(
            name = "wifi_protected_setup",
            style = MaterialIcon.Style.Normal
        )
        val WifiTethering = MaterialIcon(
            name = "wifi_tethering",
            style = MaterialIcon.Style.Normal
        )
        val WifiTetheringError = MaterialIcon(
            name = "wifi_tethering_error",
            style = MaterialIcon.Style.Normal
        )
        val WifiTetheringOff = MaterialIcon(
            name = "wifi_tethering_off",
            style = MaterialIcon.Style.Normal
        )
        val WindPower = MaterialIcon(
            name = "wind_power",
            style = MaterialIcon.Style.Normal
        )
        val Window = MaterialIcon(
            name = "window",
            style = MaterialIcon.Style.Normal
        )
        val WineBar = MaterialIcon(
            name = "wine_bar",
            style = MaterialIcon.Style.Normal
        )
        val Woman = MaterialIcon(
            name = "woman",
            style = MaterialIcon.Style.Normal
        )
        val Woman2 = MaterialIcon(
            name = "woman_2",
            style = MaterialIcon.Style.Normal
        )
        val Work = MaterialIcon(
            name = "work",
            style = MaterialIcon.Style.Normal
        )
        val WorkHistory = MaterialIcon(
            name = "work_history",
            style = MaterialIcon.Style.Normal
        )
        val WorkOff = MaterialIcon(
            name = "work_off",
            style = MaterialIcon.Style.Normal
        )
        val WorkOutline = MaterialIcon(
            name = "work_outline",
            style = MaterialIcon.Style.Normal
        )
        val WorkspacePremium = MaterialIcon(
            name = "workspace_premium",
            style = MaterialIcon.Style.Normal
        )
        val Workspaces = MaterialIcon(
            name = "workspaces",
            style = MaterialIcon.Style.Normal
        )
        val WrapText = MaterialIcon(
            name = "wrap_text",
            style = MaterialIcon.Style.Normal
        )
        val WrongLocation = MaterialIcon(
            name = "wrong_location",
            style = MaterialIcon.Style.Normal
        )
        val Wysiwyg = MaterialIcon(
            name = "wysiwyg",
            style = MaterialIcon.Style.Normal
        )
        val Yard = MaterialIcon(
            name = "yard",
            style = MaterialIcon.Style.Normal
        )
        val YoutubeSearchedFor = MaterialIcon(
            name = "youtube_searched_for",
            style = MaterialIcon.Style.Normal
        )
        val ZoomIn = MaterialIcon(
            name = "zoom_in",
            style = MaterialIcon.Style.Normal
        )
        val ZoomInMap = MaterialIcon(
            name = "zoom_in_map",
            style = MaterialIcon.Style.Normal
        )
        val ZoomOut = MaterialIcon(
            name = "zoom_out",
            style = MaterialIcon.Style.Normal
        )
        val ZoomOutMap = MaterialIcon(
            name = "zoom_out_map",
            style = MaterialIcon.Style.Normal
        )
    }
    object Outlined {
        val _10k = MaterialIcon(
            name = "10k",
            style = MaterialIcon.Style.Outlined
        )
        val _10mp = MaterialIcon(
            name = "10mp",
            style = MaterialIcon.Style.Outlined
        )
        val _11mp = MaterialIcon(
            name = "11mp",
            style = MaterialIcon.Style.Outlined
        )
        val _123 = MaterialIcon(
            name = "123",
            style = MaterialIcon.Style.Outlined
        )
        val _12mp = MaterialIcon(
            name = "12mp",
            style = MaterialIcon.Style.Outlined
        )
        val _13mp = MaterialIcon(
            name = "13mp",
            style = MaterialIcon.Style.Outlined
        )
        val _14mp = MaterialIcon(
            name = "14mp",
            style = MaterialIcon.Style.Outlined
        )
        val _15mp = MaterialIcon(
            name = "15mp",
            style = MaterialIcon.Style.Outlined
        )
        val _16mp = MaterialIcon(
            name = "16mp",
            style = MaterialIcon.Style.Outlined
        )
        val _17mp = MaterialIcon(
            name = "17mp",
            style = MaterialIcon.Style.Outlined
        )
        val _18UpRating = MaterialIcon(
            name = "18_up_rating",
            style = MaterialIcon.Style.Outlined
        )
        val _18mp = MaterialIcon(
            name = "18mp",
            style = MaterialIcon.Style.Outlined
        )
        val _19mp = MaterialIcon(
            name = "19mp",
            style = MaterialIcon.Style.Outlined
        )
        val _1k = MaterialIcon(
            name = "1k",
            style = MaterialIcon.Style.Outlined
        )
        val _1kPlus = MaterialIcon(
            name = "1k_plus",
            style = MaterialIcon.Style.Outlined
        )
        val _1xMobiledata = MaterialIcon(
            name = "1x_mobiledata",
            style = MaterialIcon.Style.Outlined
        )
        val _20mp = MaterialIcon(
            name = "20mp",
            style = MaterialIcon.Style.Outlined
        )
        val _21mp = MaterialIcon(
            name = "21mp",
            style = MaterialIcon.Style.Outlined
        )
        val _22mp = MaterialIcon(
            name = "22mp",
            style = MaterialIcon.Style.Outlined
        )
        val _23mp = MaterialIcon(
            name = "23mp",
            style = MaterialIcon.Style.Outlined
        )
        val _24mp = MaterialIcon(
            name = "24mp",
            style = MaterialIcon.Style.Outlined
        )
        val _2k = MaterialIcon(
            name = "2k",
            style = MaterialIcon.Style.Outlined
        )
        val _2kPlus = MaterialIcon(
            name = "2k_plus",
            style = MaterialIcon.Style.Outlined
        )
        val _2mp = MaterialIcon(
            name = "2mp",
            style = MaterialIcon.Style.Outlined
        )
        val _30fps = MaterialIcon(
            name = "30fps",
            style = MaterialIcon.Style.Outlined
        )
        val _30fpsSelect = MaterialIcon(
            name = "30fps_select",
            style = MaterialIcon.Style.Outlined
        )
        val _360 = MaterialIcon(
            name = "360",
            style = MaterialIcon.Style.Outlined
        )
        val _3dRotation = MaterialIcon(
            name = "3d_rotation",
            style = MaterialIcon.Style.Outlined
        )
        val _3gMobiledata = MaterialIcon(
            name = "3g_mobiledata",
            style = MaterialIcon.Style.Outlined
        )
        val _3k = MaterialIcon(
            name = "3k",
            style = MaterialIcon.Style.Outlined
        )
        val _3kPlus = MaterialIcon(
            name = "3k_plus",
            style = MaterialIcon.Style.Outlined
        )
        val _3mp = MaterialIcon(
            name = "3mp",
            style = MaterialIcon.Style.Outlined
        )
        val _3p = MaterialIcon(
            name = "3p",
            style = MaterialIcon.Style.Outlined
        )
        val _4gMobiledata = MaterialIcon(
            name = "4g_mobiledata",
            style = MaterialIcon.Style.Outlined
        )
        val _4gPlusMobiledata = MaterialIcon(
            name = "4g_plus_mobiledata",
            style = MaterialIcon.Style.Outlined
        )
        val _4k = MaterialIcon(
            name = "4k",
            style = MaterialIcon.Style.Outlined
        )
        val _4kPlus = MaterialIcon(
            name = "4k_plus",
            style = MaterialIcon.Style.Outlined
        )
        val _4mp = MaterialIcon(
            name = "4mp",
            style = MaterialIcon.Style.Outlined
        )
        val _5g = MaterialIcon(
            name = "5g",
            style = MaterialIcon.Style.Outlined
        )
        val _5k = MaterialIcon(
            name = "5k",
            style = MaterialIcon.Style.Outlined
        )
        val _5kPlus = MaterialIcon(
            name = "5k_plus",
            style = MaterialIcon.Style.Outlined
        )
        val _5mp = MaterialIcon(
            name = "5mp",
            style = MaterialIcon.Style.Outlined
        )
        val _60fps = MaterialIcon(
            name = "60fps",
            style = MaterialIcon.Style.Outlined
        )
        val _60fpsSelect = MaterialIcon(
            name = "60fps_select",
            style = MaterialIcon.Style.Outlined
        )
        val _6FtApart = MaterialIcon(
            name = "6_ft_apart",
            style = MaterialIcon.Style.Outlined
        )
        val _6k = MaterialIcon(
            name = "6k",
            style = MaterialIcon.Style.Outlined
        )
        val _6kPlus = MaterialIcon(
            name = "6k_plus",
            style = MaterialIcon.Style.Outlined
        )
        val _6mp = MaterialIcon(
            name = "6mp",
            style = MaterialIcon.Style.Outlined
        )
        val _7k = MaterialIcon(
            name = "7k",
            style = MaterialIcon.Style.Outlined
        )
        val _7kPlus = MaterialIcon(
            name = "7k_plus",
            style = MaterialIcon.Style.Outlined
        )
        val _7mp = MaterialIcon(
            name = "7mp",
            style = MaterialIcon.Style.Outlined
        )
        val _8k = MaterialIcon(
            name = "8k",
            style = MaterialIcon.Style.Outlined
        )
        val _8kPlus = MaterialIcon(
            name = "8k_plus",
            style = MaterialIcon.Style.Outlined
        )
        val _8mp = MaterialIcon(
            name = "8mp",
            style = MaterialIcon.Style.Outlined
        )
        val _9k = MaterialIcon(
            name = "9k",
            style = MaterialIcon.Style.Outlined
        )
        val _9kPlus = MaterialIcon(
            name = "9k_plus",
            style = MaterialIcon.Style.Outlined
        )
        val _9mp = MaterialIcon(
            name = "9mp",
            style = MaterialIcon.Style.Outlined
        )
        val Abc = MaterialIcon(
            name = "abc",
            style = MaterialIcon.Style.Outlined
        )
        val AcUnit = MaterialIcon(
            name = "ac_unit",
            style = MaterialIcon.Style.Outlined
        )
        val AccessAlarm = MaterialIcon(
            name = "access_alarm",
            style = MaterialIcon.Style.Outlined
        )
        val AccessAlarms = MaterialIcon(
            name = "access_alarms",
            style = MaterialIcon.Style.Outlined
        )
        val AccessTime = MaterialIcon(
            name = "access_time",
            style = MaterialIcon.Style.Outlined
        )
        val AccessTimeFilled = MaterialIcon(
            name = "access_time_filled",
            style = MaterialIcon.Style.Outlined
        )
        val Accessibility = MaterialIcon(
            name = "accessibility",
            style = MaterialIcon.Style.Outlined
        )
        val AccessibilityNew = MaterialIcon(
            name = "accessibility_new",
            style = MaterialIcon.Style.Outlined
        )
        val Accessible = MaterialIcon(
            name = "accessible",
            style = MaterialIcon.Style.Outlined
        )
        val AccessibleForward = MaterialIcon(
            name = "accessible_forward",
            style = MaterialIcon.Style.Outlined
        )
        val AccountBalance = MaterialIcon(
            name = "account_balance",
            style = MaterialIcon.Style.Outlined
        )
        val AccountBalanceWallet = MaterialIcon(
            name = "account_balance_wallet",
            style = MaterialIcon.Style.Outlined
        )
        val AccountBox = MaterialIcon(
            name = "account_box",
            style = MaterialIcon.Style.Outlined
        )
        val AccountCircle = MaterialIcon(
            name = "account_circle",
            style = MaterialIcon.Style.Outlined
        )
        val AccountTree = MaterialIcon(
            name = "account_tree",
            style = MaterialIcon.Style.Outlined
        )
        val AdUnits = MaterialIcon(
            name = "ad_units",
            style = MaterialIcon.Style.Outlined
        )
        val Adb = MaterialIcon(
            name = "adb",
            style = MaterialIcon.Style.Outlined
        )
        val Add = MaterialIcon(
            name = "add",
            style = MaterialIcon.Style.Outlined
        )
        val AddAPhoto = MaterialIcon(
            name = "add_a_photo",
            style = MaterialIcon.Style.Outlined
        )
        val AddAlarm = MaterialIcon(
            name = "add_alarm",
            style = MaterialIcon.Style.Outlined
        )
        val AddAlert = MaterialIcon(
            name = "add_alert",
            style = MaterialIcon.Style.Outlined
        )
        val AddBox = MaterialIcon(
            name = "add_box",
            style = MaterialIcon.Style.Outlined
        )
        val AddBusiness = MaterialIcon(
            name = "add_business",
            style = MaterialIcon.Style.Outlined
        )
        val AddCard = MaterialIcon(
            name = "add_card",
            style = MaterialIcon.Style.Outlined
        )
        val AddChart = MaterialIcon(
            name = "add_chart",
            style = MaterialIcon.Style.Outlined
        )
        val AddCircle = MaterialIcon(
            name = "add_circle",
            style = MaterialIcon.Style.Outlined
        )
        val AddCircleOutline = MaterialIcon(
            name = "add_circle_outline",
            style = MaterialIcon.Style.Outlined
        )
        val AddComment = MaterialIcon(
            name = "add_comment",
            style = MaterialIcon.Style.Outlined
        )
        val AddHome = MaterialIcon(
            name = "add_home",
            style = MaterialIcon.Style.Outlined
        )
        val AddHomeWork = MaterialIcon(
            name = "add_home_work",
            style = MaterialIcon.Style.Outlined
        )
        val AddIcCall = MaterialIcon(
            name = "add_ic_call",
            style = MaterialIcon.Style.Outlined
        )
        val AddLink = MaterialIcon(
            name = "add_link",
            style = MaterialIcon.Style.Outlined
        )
        val AddLocation = MaterialIcon(
            name = "add_location",
            style = MaterialIcon.Style.Outlined
        )
        val AddLocationAlt = MaterialIcon(
            name = "add_location_alt",
            style = MaterialIcon.Style.Outlined
        )
        val AddModerator = MaterialIcon(
            name = "add_moderator",
            style = MaterialIcon.Style.Outlined
        )
        val AddPhotoAlternate = MaterialIcon(
            name = "add_photo_alternate",
            style = MaterialIcon.Style.Outlined
        )
        val AddReaction = MaterialIcon(
            name = "add_reaction",
            style = MaterialIcon.Style.Outlined
        )
        val AddRoad = MaterialIcon(
            name = "add_road",
            style = MaterialIcon.Style.Outlined
        )
        val AddShoppingCart = MaterialIcon(
            name = "add_shopping_cart",
            style = MaterialIcon.Style.Outlined
        )
        val AddTask = MaterialIcon(
            name = "add_task",
            style = MaterialIcon.Style.Outlined
        )
        val AddToDrive = MaterialIcon(
            name = "add_to_drive",
            style = MaterialIcon.Style.Outlined
        )
        val AddToHomeScreen = MaterialIcon(
            name = "add_to_home_screen",
            style = MaterialIcon.Style.Outlined
        )
        val AddToPhotos = MaterialIcon(
            name = "add_to_photos",
            style = MaterialIcon.Style.Outlined
        )
        val AddToQueue = MaterialIcon(
            name = "add_to_queue",
            style = MaterialIcon.Style.Outlined
        )
        val Addchart = MaterialIcon(
            name = "addchart",
            style = MaterialIcon.Style.Outlined
        )
        val AdfScanner = MaterialIcon(
            name = "adf_scanner",
            style = MaterialIcon.Style.Outlined
        )
        val Adjust = MaterialIcon(
            name = "adjust",
            style = MaterialIcon.Style.Outlined
        )
        val AdminPanelSettings = MaterialIcon(
            name = "admin_panel_settings",
            style = MaterialIcon.Style.Outlined
        )
        val AdsClick = MaterialIcon(
            name = "ads_click",
            style = MaterialIcon.Style.Outlined
        )
        val Agriculture = MaterialIcon(
            name = "agriculture",
            style = MaterialIcon.Style.Outlined
        )
        val Air = MaterialIcon(
            name = "air",
            style = MaterialIcon.Style.Outlined
        )
        val AirlineSeatFlat = MaterialIcon(
            name = "airline_seat_flat",
            style = MaterialIcon.Style.Outlined
        )
        val AirlineSeatFlatAngled = MaterialIcon(
            name = "airline_seat_flat_angled",
            style = MaterialIcon.Style.Outlined
        )
        val AirlineSeatIndividualSuite = MaterialIcon(
            name = "airline_seat_individual_suite",
            style = MaterialIcon.Style.Outlined
        )
        val AirlineSeatLegroomExtra = MaterialIcon(
            name = "airline_seat_legroom_extra",
            style = MaterialIcon.Style.Outlined
        )
        val AirlineSeatLegroomNormal = MaterialIcon(
            name = "airline_seat_legroom_normal",
            style = MaterialIcon.Style.Outlined
        )
        val AirlineSeatLegroomReduced = MaterialIcon(
            name = "airline_seat_legroom_reduced",
            style = MaterialIcon.Style.Outlined
        )
        val AirlineSeatReclineExtra = MaterialIcon(
            name = "airline_seat_recline_extra",
            style = MaterialIcon.Style.Outlined
        )
        val AirlineSeatReclineNormal = MaterialIcon(
            name = "airline_seat_recline_normal",
            style = MaterialIcon.Style.Outlined
        )
        val AirlineStops = MaterialIcon(
            name = "airline_stops",
            style = MaterialIcon.Style.Outlined
        )
        val Airlines = MaterialIcon(
            name = "airlines",
            style = MaterialIcon.Style.Outlined
        )
        val AirplaneTicket = MaterialIcon(
            name = "airplane_ticket",
            style = MaterialIcon.Style.Outlined
        )
        val AirplanemodeActive = MaterialIcon(
            name = "airplanemode_active",
            style = MaterialIcon.Style.Outlined
        )
        val AirplanemodeInactive = MaterialIcon(
            name = "airplanemode_inactive",
            style = MaterialIcon.Style.Outlined
        )
        val Airplay = MaterialIcon(
            name = "airplay",
            style = MaterialIcon.Style.Outlined
        )
        val AirportShuttle = MaterialIcon(
            name = "airport_shuttle",
            style = MaterialIcon.Style.Outlined
        )
        val Alarm = MaterialIcon(
            name = "alarm",
            style = MaterialIcon.Style.Outlined
        )
        val AlarmAdd = MaterialIcon(
            name = "alarm_add",
            style = MaterialIcon.Style.Outlined
        )
        val AlarmOff = MaterialIcon(
            name = "alarm_off",
            style = MaterialIcon.Style.Outlined
        )
        val AlarmOn = MaterialIcon(
            name = "alarm_on",
            style = MaterialIcon.Style.Outlined
        )
        val Album = MaterialIcon(
            name = "album",
            style = MaterialIcon.Style.Outlined
        )
        val AlignHorizontalCenter = MaterialIcon(
            name = "align_horizontal_center",
            style = MaterialIcon.Style.Outlined
        )
        val AlignHorizontalLeft = MaterialIcon(
            name = "align_horizontal_left",
            style = MaterialIcon.Style.Outlined
        )
        val AlignHorizontalRight = MaterialIcon(
            name = "align_horizontal_right",
            style = MaterialIcon.Style.Outlined
        )
        val AlignVerticalBottom = MaterialIcon(
            name = "align_vertical_bottom",
            style = MaterialIcon.Style.Outlined
        )
        val AlignVerticalCenter = MaterialIcon(
            name = "align_vertical_center",
            style = MaterialIcon.Style.Outlined
        )
        val AlignVerticalTop = MaterialIcon(
            name = "align_vertical_top",
            style = MaterialIcon.Style.Outlined
        )
        val AllInbox = MaterialIcon(
            name = "all_inbox",
            style = MaterialIcon.Style.Outlined
        )
        val AllInclusive = MaterialIcon(
            name = "all_inclusive",
            style = MaterialIcon.Style.Outlined
        )
        val AllOut = MaterialIcon(
            name = "all_out",
            style = MaterialIcon.Style.Outlined
        )
        val AltRoute = MaterialIcon(
            name = "alt_route",
            style = MaterialIcon.Style.Outlined
        )
        val AlternateEmail = MaterialIcon(
            name = "alternate_email",
            style = MaterialIcon.Style.Outlined
        )
        val Analytics = MaterialIcon(
            name = "analytics",
            style = MaterialIcon.Style.Outlined
        )
        val Anchor = MaterialIcon(
            name = "anchor",
            style = MaterialIcon.Style.Outlined
        )
        val Android = MaterialIcon(
            name = "android",
            style = MaterialIcon.Style.Outlined
        )
        val Animation = MaterialIcon(
            name = "animation",
            style = MaterialIcon.Style.Outlined
        )
        val Announcement = MaterialIcon(
            name = "announcement",
            style = MaterialIcon.Style.Outlined
        )
        val Aod = MaterialIcon(
            name = "aod",
            style = MaterialIcon.Style.Outlined
        )
        val Apartment = MaterialIcon(
            name = "apartment",
            style = MaterialIcon.Style.Outlined
        )
        val Api = MaterialIcon(
            name = "api",
            style = MaterialIcon.Style.Outlined
        )
        val AppBlocking = MaterialIcon(
            name = "app_blocking",
            style = MaterialIcon.Style.Outlined
        )
        val AppRegistration = MaterialIcon(
            name = "app_registration",
            style = MaterialIcon.Style.Outlined
        )
        val AppSettingsAlt = MaterialIcon(
            name = "app_settings_alt",
            style = MaterialIcon.Style.Outlined
        )
        val AppShortcut = MaterialIcon(
            name = "app_shortcut",
            style = MaterialIcon.Style.Outlined
        )
        val Approval = MaterialIcon(
            name = "approval",
            style = MaterialIcon.Style.Outlined
        )
        val Apps = MaterialIcon(
            name = "apps",
            style = MaterialIcon.Style.Outlined
        )
        val AppsOutage = MaterialIcon(
            name = "apps_outage",
            style = MaterialIcon.Style.Outlined
        )
        val Architecture = MaterialIcon(
            name = "architecture",
            style = MaterialIcon.Style.Outlined
        )
        val Archive = MaterialIcon(
            name = "archive",
            style = MaterialIcon.Style.Outlined
        )
        val AreaChart = MaterialIcon(
            name = "area_chart",
            style = MaterialIcon.Style.Outlined
        )
        val ArrowBack = MaterialIcon(
            name = "arrow_back",
            style = MaterialIcon.Style.Outlined
        )
        val ArrowBackIos = MaterialIcon(
            name = "arrow_back_ios",
            style = MaterialIcon.Style.Outlined
        )
        val ArrowBackIosNew = MaterialIcon(
            name = "arrow_back_ios_new",
            style = MaterialIcon.Style.Outlined
        )
        val ArrowCircleDown = MaterialIcon(
            name = "arrow_circle_down",
            style = MaterialIcon.Style.Outlined
        )
        val ArrowCircleLeft = MaterialIcon(
            name = "arrow_circle_left",
            style = MaterialIcon.Style.Outlined
        )
        val ArrowCircleRight = MaterialIcon(
            name = "arrow_circle_right",
            style = MaterialIcon.Style.Outlined
        )
        val ArrowCircleUp = MaterialIcon(
            name = "arrow_circle_up",
            style = MaterialIcon.Style.Outlined
        )
        val ArrowDownward = MaterialIcon(
            name = "arrow_downward",
            style = MaterialIcon.Style.Outlined
        )
        val ArrowDropDown = MaterialIcon(
            name = "arrow_drop_down",
            style = MaterialIcon.Style.Outlined
        )
        val ArrowDropDownCircle = MaterialIcon(
            name = "arrow_drop_down_circle",
            style = MaterialIcon.Style.Outlined
        )
        val ArrowDropUp = MaterialIcon(
            name = "arrow_drop_up",
            style = MaterialIcon.Style.Outlined
        )
        val ArrowForward = MaterialIcon(
            name = "arrow_forward",
            style = MaterialIcon.Style.Outlined
        )
        val ArrowForwardIos = MaterialIcon(
            name = "arrow_forward_ios",
            style = MaterialIcon.Style.Outlined
        )
        val ArrowLeft = MaterialIcon(
            name = "arrow_left",
            style = MaterialIcon.Style.Outlined
        )
        val ArrowOutward = MaterialIcon(
            name = "arrow_outward",
            style = MaterialIcon.Style.Outlined
        )
        val ArrowRight = MaterialIcon(
            name = "arrow_right",
            style = MaterialIcon.Style.Outlined
        )
        val ArrowRightAlt = MaterialIcon(
            name = "arrow_right_alt",
            style = MaterialIcon.Style.Outlined
        )
        val ArrowUpward = MaterialIcon(
            name = "arrow_upward",
            style = MaterialIcon.Style.Outlined
        )
        val ArtTrack = MaterialIcon(
            name = "art_track",
            style = MaterialIcon.Style.Outlined
        )
        val Article = MaterialIcon(
            name = "article",
            style = MaterialIcon.Style.Outlined
        )
        val AspectRatio = MaterialIcon(
            name = "aspect_ratio",
            style = MaterialIcon.Style.Outlined
        )
        val Assessment = MaterialIcon(
            name = "assessment",
            style = MaterialIcon.Style.Outlined
        )
        val Assignment = MaterialIcon(
            name = "assignment",
            style = MaterialIcon.Style.Outlined
        )
        val AssignmentInd = MaterialIcon(
            name = "assignment_ind",
            style = MaterialIcon.Style.Outlined
        )
        val AssignmentLate = MaterialIcon(
            name = "assignment_late",
            style = MaterialIcon.Style.Outlined
        )
        val AssignmentReturn = MaterialIcon(
            name = "assignment_return",
            style = MaterialIcon.Style.Outlined
        )
        val AssignmentReturned = MaterialIcon(
            name = "assignment_returned",
            style = MaterialIcon.Style.Outlined
        )
        val AssignmentTurnedIn = MaterialIcon(
            name = "assignment_turned_in",
            style = MaterialIcon.Style.Outlined
        )
        val AssistWalker = MaterialIcon(
            name = "assist_walker",
            style = MaterialIcon.Style.Outlined
        )
        val Assistant = MaterialIcon(
            name = "assistant",
            style = MaterialIcon.Style.Outlined
        )
        val AssistantDirection = MaterialIcon(
            name = "assistant_direction",
            style = MaterialIcon.Style.Outlined
        )
        val AssistantPhoto = MaterialIcon(
            name = "assistant_photo",
            style = MaterialIcon.Style.Outlined
        )
        val AssuredWorkload = MaterialIcon(
            name = "assured_workload",
            style = MaterialIcon.Style.Outlined
        )
        val Atm = MaterialIcon(
            name = "atm",
            style = MaterialIcon.Style.Outlined
        )
        val AttachEmail = MaterialIcon(
            name = "attach_email",
            style = MaterialIcon.Style.Outlined
        )
        val AttachFile = MaterialIcon(
            name = "attach_file",
            style = MaterialIcon.Style.Outlined
        )
        val AttachMoney = MaterialIcon(
            name = "attach_money",
            style = MaterialIcon.Style.Outlined
        )
        val Attachment = MaterialIcon(
            name = "attachment",
            style = MaterialIcon.Style.Outlined
        )
        val Attractions = MaterialIcon(
            name = "attractions",
            style = MaterialIcon.Style.Outlined
        )
        val Attribution = MaterialIcon(
            name = "attribution",
            style = MaterialIcon.Style.Outlined
        )
        val AudioFile = MaterialIcon(
            name = "audio_file",
            style = MaterialIcon.Style.Outlined
        )
        val Audiotrack = MaterialIcon(
            name = "audiotrack",
            style = MaterialIcon.Style.Outlined
        )
        val AutoAwesome = MaterialIcon(
            name = "auto_awesome",
            style = MaterialIcon.Style.Outlined
        )
        val AutoAwesomeMosaic = MaterialIcon(
            name = "auto_awesome_mosaic",
            style = MaterialIcon.Style.Outlined
        )
        val AutoAwesomeMotion = MaterialIcon(
            name = "auto_awesome_motion",
            style = MaterialIcon.Style.Outlined
        )
        val AutoDelete = MaterialIcon(
            name = "auto_delete",
            style = MaterialIcon.Style.Outlined
        )
        val AutoFixHigh = MaterialIcon(
            name = "auto_fix_high",
            style = MaterialIcon.Style.Outlined
        )
        val AutoFixNormal = MaterialIcon(
            name = "auto_fix_normal",
            style = MaterialIcon.Style.Outlined
        )
        val AutoFixOff = MaterialIcon(
            name = "auto_fix_off",
            style = MaterialIcon.Style.Outlined
        )
        val AutoGraph = MaterialIcon(
            name = "auto_graph",
            style = MaterialIcon.Style.Outlined
        )
        val AutoMode = MaterialIcon(
            name = "auto_mode",
            style = MaterialIcon.Style.Outlined
        )
        val AutoStories = MaterialIcon(
            name = "auto_stories",
            style = MaterialIcon.Style.Outlined
        )
        val AutofpsSelect = MaterialIcon(
            name = "autofps_select",
            style = MaterialIcon.Style.Outlined
        )
        val Autorenew = MaterialIcon(
            name = "autorenew",
            style = MaterialIcon.Style.Outlined
        )
        val AvTimer = MaterialIcon(
            name = "av_timer",
            style = MaterialIcon.Style.Outlined
        )
        val BabyChangingStation = MaterialIcon(
            name = "baby_changing_station",
            style = MaterialIcon.Style.Outlined
        )
        val BackHand = MaterialIcon(
            name = "back_hand",
            style = MaterialIcon.Style.Outlined
        )
        val Backpack = MaterialIcon(
            name = "backpack",
            style = MaterialIcon.Style.Outlined
        )
        val Backspace = MaterialIcon(
            name = "backspace",
            style = MaterialIcon.Style.Outlined
        )
        val Backup = MaterialIcon(
            name = "backup",
            style = MaterialIcon.Style.Outlined
        )
        val BackupTable = MaterialIcon(
            name = "backup_table",
            style = MaterialIcon.Style.Outlined
        )
        val Badge = MaterialIcon(
            name = "badge",
            style = MaterialIcon.Style.Outlined
        )
        val BakeryDining = MaterialIcon(
            name = "bakery_dining",
            style = MaterialIcon.Style.Outlined
        )
        val Balance = MaterialIcon(
            name = "balance",
            style = MaterialIcon.Style.Outlined
        )
        val Balcony = MaterialIcon(
            name = "balcony",
            style = MaterialIcon.Style.Outlined
        )
        val Ballot = MaterialIcon(
            name = "ballot",
            style = MaterialIcon.Style.Outlined
        )
        val BarChart = MaterialIcon(
            name = "bar_chart",
            style = MaterialIcon.Style.Outlined
        )
        val BatchPrediction = MaterialIcon(
            name = "batch_prediction",
            style = MaterialIcon.Style.Outlined
        )
        val Bathroom = MaterialIcon(
            name = "bathroom",
            style = MaterialIcon.Style.Outlined
        )
        val Bathtub = MaterialIcon(
            name = "bathtub",
            style = MaterialIcon.Style.Outlined
        )
        val Battery0Bar = MaterialIcon(
            name = "battery_0_bar",
            style = MaterialIcon.Style.Outlined
        )
        val Battery1Bar = MaterialIcon(
            name = "battery_1_bar",
            style = MaterialIcon.Style.Outlined
        )
        val Battery2Bar = MaterialIcon(
            name = "battery_2_bar",
            style = MaterialIcon.Style.Outlined
        )
        val Battery3Bar = MaterialIcon(
            name = "battery_3_bar",
            style = MaterialIcon.Style.Outlined
        )
        val Battery4Bar = MaterialIcon(
            name = "battery_4_bar",
            style = MaterialIcon.Style.Outlined
        )
        val Battery5Bar = MaterialIcon(
            name = "battery_5_bar",
            style = MaterialIcon.Style.Outlined
        )
        val Battery6Bar = MaterialIcon(
            name = "battery_6_bar",
            style = MaterialIcon.Style.Outlined
        )
        val BatteryAlert = MaterialIcon(
            name = "battery_alert",
            style = MaterialIcon.Style.Outlined
        )
        val BatteryChargingFull = MaterialIcon(
            name = "battery_charging_full",
            style = MaterialIcon.Style.Outlined
        )
        val BatteryFull = MaterialIcon(
            name = "battery_full",
            style = MaterialIcon.Style.Outlined
        )
        val BatterySaver = MaterialIcon(
            name = "battery_saver",
            style = MaterialIcon.Style.Outlined
        )
        val BatteryStd = MaterialIcon(
            name = "battery_std",
            style = MaterialIcon.Style.Outlined
        )
        val BatteryUnknown = MaterialIcon(
            name = "battery_unknown",
            style = MaterialIcon.Style.Outlined
        )
        val BeachAccess = MaterialIcon(
            name = "beach_access",
            style = MaterialIcon.Style.Outlined
        )
        val Bed = MaterialIcon(
            name = "bed",
            style = MaterialIcon.Style.Outlined
        )
        val BedroomBaby = MaterialIcon(
            name = "bedroom_baby",
            style = MaterialIcon.Style.Outlined
        )
        val BedroomChild = MaterialIcon(
            name = "bedroom_child",
            style = MaterialIcon.Style.Outlined
        )
        val BedroomParent = MaterialIcon(
            name = "bedroom_parent",
            style = MaterialIcon.Style.Outlined
        )
        val Bedtime = MaterialIcon(
            name = "bedtime",
            style = MaterialIcon.Style.Outlined
        )
        val BedtimeOff = MaterialIcon(
            name = "bedtime_off",
            style = MaterialIcon.Style.Outlined
        )
        val Beenhere = MaterialIcon(
            name = "beenhere",
            style = MaterialIcon.Style.Outlined
        )
        val Bento = MaterialIcon(
            name = "bento",
            style = MaterialIcon.Style.Outlined
        )
        val BikeScooter = MaterialIcon(
            name = "bike_scooter",
            style = MaterialIcon.Style.Outlined
        )
        val Biotech = MaterialIcon(
            name = "biotech",
            style = MaterialIcon.Style.Outlined
        )
        val Blender = MaterialIcon(
            name = "blender",
            style = MaterialIcon.Style.Outlined
        )
        val Blind = MaterialIcon(
            name = "blind",
            style = MaterialIcon.Style.Outlined
        )
        val Blinds = MaterialIcon(
            name = "blinds",
            style = MaterialIcon.Style.Outlined
        )
        val BlindsClosed = MaterialIcon(
            name = "blinds_closed",
            style = MaterialIcon.Style.Outlined
        )
        val Block = MaterialIcon(
            name = "block",
            style = MaterialIcon.Style.Outlined
        )
        val Bloodtype = MaterialIcon(
            name = "bloodtype",
            style = MaterialIcon.Style.Outlined
        )
        val Bluetooth = MaterialIcon(
            name = "bluetooth",
            style = MaterialIcon.Style.Outlined
        )
        val BluetoothAudio = MaterialIcon(
            name = "bluetooth_audio",
            style = MaterialIcon.Style.Outlined
        )
        val BluetoothConnected = MaterialIcon(
            name = "bluetooth_connected",
            style = MaterialIcon.Style.Outlined
        )
        val BluetoothDisabled = MaterialIcon(
            name = "bluetooth_disabled",
            style = MaterialIcon.Style.Outlined
        )
        val BluetoothDrive = MaterialIcon(
            name = "bluetooth_drive",
            style = MaterialIcon.Style.Outlined
        )
        val BluetoothSearching = MaterialIcon(
            name = "bluetooth_searching",
            style = MaterialIcon.Style.Outlined
        )
        val BlurCircular = MaterialIcon(
            name = "blur_circular",
            style = MaterialIcon.Style.Outlined
        )
        val BlurLinear = MaterialIcon(
            name = "blur_linear",
            style = MaterialIcon.Style.Outlined
        )
        val BlurOff = MaterialIcon(
            name = "blur_off",
            style = MaterialIcon.Style.Outlined
        )
        val BlurOn = MaterialIcon(
            name = "blur_on",
            style = MaterialIcon.Style.Outlined
        )
        val Bolt = MaterialIcon(
            name = "bolt",
            style = MaterialIcon.Style.Outlined
        )
        val Book = MaterialIcon(
            name = "book",
            style = MaterialIcon.Style.Outlined
        )
        val BookOnline = MaterialIcon(
            name = "book_online",
            style = MaterialIcon.Style.Outlined
        )
        val Bookmark = MaterialIcon(
            name = "bookmark",
            style = MaterialIcon.Style.Outlined
        )
        val BookmarkAdd = MaterialIcon(
            name = "bookmark_add",
            style = MaterialIcon.Style.Outlined
        )
        val BookmarkAdded = MaterialIcon(
            name = "bookmark_added",
            style = MaterialIcon.Style.Outlined
        )
        val BookmarkBorder = MaterialIcon(
            name = "bookmark_border",
            style = MaterialIcon.Style.Outlined
        )
        val BookmarkRemove = MaterialIcon(
            name = "bookmark_remove",
            style = MaterialIcon.Style.Outlined
        )
        val Bookmarks = MaterialIcon(
            name = "bookmarks",
            style = MaterialIcon.Style.Outlined
        )
        val BorderAll = MaterialIcon(
            name = "border_all",
            style = MaterialIcon.Style.Outlined
        )
        val BorderBottom = MaterialIcon(
            name = "border_bottom",
            style = MaterialIcon.Style.Outlined
        )
        val BorderClear = MaterialIcon(
            name = "border_clear",
            style = MaterialIcon.Style.Outlined
        )
        val BorderColor = MaterialIcon(
            name = "border_color",
            style = MaterialIcon.Style.Outlined
        )
        val BorderHorizontal = MaterialIcon(
            name = "border_horizontal",
            style = MaterialIcon.Style.Outlined
        )
        val BorderInner = MaterialIcon(
            name = "border_inner",
            style = MaterialIcon.Style.Outlined
        )
        val BorderLeft = MaterialIcon(
            name = "border_left",
            style = MaterialIcon.Style.Outlined
        )
        val BorderOuter = MaterialIcon(
            name = "border_outer",
            style = MaterialIcon.Style.Outlined
        )
        val BorderRight = MaterialIcon(
            name = "border_right",
            style = MaterialIcon.Style.Outlined
        )
        val BorderStyle = MaterialIcon(
            name = "border_style",
            style = MaterialIcon.Style.Outlined
        )
        val BorderTop = MaterialIcon(
            name = "border_top",
            style = MaterialIcon.Style.Outlined
        )
        val BorderVertical = MaterialIcon(
            name = "border_vertical",
            style = MaterialIcon.Style.Outlined
        )
        val Boy = MaterialIcon(
            name = "boy",
            style = MaterialIcon.Style.Outlined
        )
        val BrandingWatermark = MaterialIcon(
            name = "branding_watermark",
            style = MaterialIcon.Style.Outlined
        )
        val BreakfastDining = MaterialIcon(
            name = "breakfast_dining",
            style = MaterialIcon.Style.Outlined
        )
        val Brightness1 = MaterialIcon(
            name = "brightness_1",
            style = MaterialIcon.Style.Outlined
        )
        val Brightness2 = MaterialIcon(
            name = "brightness_2",
            style = MaterialIcon.Style.Outlined
        )
        val Brightness3 = MaterialIcon(
            name = "brightness_3",
            style = MaterialIcon.Style.Outlined
        )
        val Brightness4 = MaterialIcon(
            name = "brightness_4",
            style = MaterialIcon.Style.Outlined
        )
        val Brightness5 = MaterialIcon(
            name = "brightness_5",
            style = MaterialIcon.Style.Outlined
        )
        val Brightness6 = MaterialIcon(
            name = "brightness_6",
            style = MaterialIcon.Style.Outlined
        )
        val Brightness7 = MaterialIcon(
            name = "brightness_7",
            style = MaterialIcon.Style.Outlined
        )
        val BrightnessAuto = MaterialIcon(
            name = "brightness_auto",
            style = MaterialIcon.Style.Outlined
        )
        val BrightnessHigh = MaterialIcon(
            name = "brightness_high",
            style = MaterialIcon.Style.Outlined
        )
        val BrightnessLow = MaterialIcon(
            name = "brightness_low",
            style = MaterialIcon.Style.Outlined
        )
        val BrightnessMedium = MaterialIcon(
            name = "brightness_medium",
            style = MaterialIcon.Style.Outlined
        )
        val BroadcastOnHome = MaterialIcon(
            name = "broadcast_on_home",
            style = MaterialIcon.Style.Outlined
        )
        val BroadcastOnPersonal = MaterialIcon(
            name = "broadcast_on_personal",
            style = MaterialIcon.Style.Outlined
        )
        val BrokenImage = MaterialIcon(
            name = "broken_image",
            style = MaterialIcon.Style.Outlined
        )
        val BrowseGallery = MaterialIcon(
            name = "browse_gallery",
            style = MaterialIcon.Style.Outlined
        )
        val BrowserNotSupported = MaterialIcon(
            name = "browser_not_supported",
            style = MaterialIcon.Style.Outlined
        )
        val BrowserUpdated = MaterialIcon(
            name = "browser_updated",
            style = MaterialIcon.Style.Outlined
        )
        val BrunchDining = MaterialIcon(
            name = "brunch_dining",
            style = MaterialIcon.Style.Outlined
        )
        val Brush = MaterialIcon(
            name = "brush",
            style = MaterialIcon.Style.Outlined
        )
        val BubbleChart = MaterialIcon(
            name = "bubble_chart",
            style = MaterialIcon.Style.Outlined
        )
        val BugReport = MaterialIcon(
            name = "bug_report",
            style = MaterialIcon.Style.Outlined
        )
        val Build = MaterialIcon(
            name = "build",
            style = MaterialIcon.Style.Outlined
        )
        val BuildCircle = MaterialIcon(
            name = "build_circle",
            style = MaterialIcon.Style.Outlined
        )
        val Bungalow = MaterialIcon(
            name = "bungalow",
            style = MaterialIcon.Style.Outlined
        )
        val BurstMode = MaterialIcon(
            name = "burst_mode",
            style = MaterialIcon.Style.Outlined
        )
        val BusAlert = MaterialIcon(
            name = "bus_alert",
            style = MaterialIcon.Style.Outlined
        )
        val Business = MaterialIcon(
            name = "business",
            style = MaterialIcon.Style.Outlined
        )
        val BusinessCenter = MaterialIcon(
            name = "business_center",
            style = MaterialIcon.Style.Outlined
        )
        val Cabin = MaterialIcon(
            name = "cabin",
            style = MaterialIcon.Style.Outlined
        )
        val Cable = MaterialIcon(
            name = "cable",
            style = MaterialIcon.Style.Outlined
        )
        val Cached = MaterialIcon(
            name = "cached",
            style = MaterialIcon.Style.Outlined
        )
        val Cake = MaterialIcon(
            name = "cake",
            style = MaterialIcon.Style.Outlined
        )
        val Calculate = MaterialIcon(
            name = "calculate",
            style = MaterialIcon.Style.Outlined
        )
        val CalendarMonth = MaterialIcon(
            name = "calendar_month",
            style = MaterialIcon.Style.Outlined
        )
        val CalendarToday = MaterialIcon(
            name = "calendar_today",
            style = MaterialIcon.Style.Outlined
        )
        val CalendarViewDay = MaterialIcon(
            name = "calendar_view_day",
            style = MaterialIcon.Style.Outlined
        )
        val CalendarViewMonth = MaterialIcon(
            name = "calendar_view_month",
            style = MaterialIcon.Style.Outlined
        )
        val CalendarViewWeek = MaterialIcon(
            name = "calendar_view_week",
            style = MaterialIcon.Style.Outlined
        )
        val Call = MaterialIcon(
            name = "call",
            style = MaterialIcon.Style.Outlined
        )
        val CallEnd = MaterialIcon(
            name = "call_end",
            style = MaterialIcon.Style.Outlined
        )
        val CallMade = MaterialIcon(
            name = "call_made",
            style = MaterialIcon.Style.Outlined
        )
        val CallMerge = MaterialIcon(
            name = "call_merge",
            style = MaterialIcon.Style.Outlined
        )
        val CallMissed = MaterialIcon(
            name = "call_missed",
            style = MaterialIcon.Style.Outlined
        )
        val CallMissedOutgoing = MaterialIcon(
            name = "call_missed_outgoing",
            style = MaterialIcon.Style.Outlined
        )
        val CallReceived = MaterialIcon(
            name = "call_received",
            style = MaterialIcon.Style.Outlined
        )
        val CallSplit = MaterialIcon(
            name = "call_split",
            style = MaterialIcon.Style.Outlined
        )
        val CallToAction = MaterialIcon(
            name = "call_to_action",
            style = MaterialIcon.Style.Outlined
        )
        val Camera = MaterialIcon(
            name = "camera",
            style = MaterialIcon.Style.Outlined
        )
        val CameraAlt = MaterialIcon(
            name = "camera_alt",
            style = MaterialIcon.Style.Outlined
        )
        val CameraEnhance = MaterialIcon(
            name = "camera_enhance",
            style = MaterialIcon.Style.Outlined
        )
        val CameraFront = MaterialIcon(
            name = "camera_front",
            style = MaterialIcon.Style.Outlined
        )
        val CameraIndoor = MaterialIcon(
            name = "camera_indoor",
            style = MaterialIcon.Style.Outlined
        )
        val CameraOutdoor = MaterialIcon(
            name = "camera_outdoor",
            style = MaterialIcon.Style.Outlined
        )
        val CameraRear = MaterialIcon(
            name = "camera_rear",
            style = MaterialIcon.Style.Outlined
        )
        val CameraRoll = MaterialIcon(
            name = "camera_roll",
            style = MaterialIcon.Style.Outlined
        )
        val Cameraswitch = MaterialIcon(
            name = "cameraswitch",
            style = MaterialIcon.Style.Outlined
        )
        val Campaign = MaterialIcon(
            name = "campaign",
            style = MaterialIcon.Style.Outlined
        )
        val Cancel = MaterialIcon(
            name = "cancel",
            style = MaterialIcon.Style.Outlined
        )
        val CancelPresentation = MaterialIcon(
            name = "cancel_presentation",
            style = MaterialIcon.Style.Outlined
        )
        val CancelScheduleSend = MaterialIcon(
            name = "cancel_schedule_send",
            style = MaterialIcon.Style.Outlined
        )
        val CandlestickChart = MaterialIcon(
            name = "candlestick_chart",
            style = MaterialIcon.Style.Outlined
        )
        val CarCrash = MaterialIcon(
            name = "car_crash",
            style = MaterialIcon.Style.Outlined
        )
        val CarRental = MaterialIcon(
            name = "car_rental",
            style = MaterialIcon.Style.Outlined
        )
        val CarRepair = MaterialIcon(
            name = "car_repair",
            style = MaterialIcon.Style.Outlined
        )
        val CardGiftcard = MaterialIcon(
            name = "card_giftcard",
            style = MaterialIcon.Style.Outlined
        )
        val CardMembership = MaterialIcon(
            name = "card_membership",
            style = MaterialIcon.Style.Outlined
        )
        val CardTravel = MaterialIcon(
            name = "card_travel",
            style = MaterialIcon.Style.Outlined
        )
        val Carpenter = MaterialIcon(
            name = "carpenter",
            style = MaterialIcon.Style.Outlined
        )
        val Cases = MaterialIcon(
            name = "cases",
            style = MaterialIcon.Style.Outlined
        )
        val Casino = MaterialIcon(
            name = "casino",
            style = MaterialIcon.Style.Outlined
        )
        val Cast = MaterialIcon(
            name = "cast",
            style = MaterialIcon.Style.Outlined
        )
        val CastConnected = MaterialIcon(
            name = "cast_connected",
            style = MaterialIcon.Style.Outlined
        )
        val CastForEducation = MaterialIcon(
            name = "cast_for_education",
            style = MaterialIcon.Style.Outlined
        )
        val Castle = MaterialIcon(
            name = "castle",
            style = MaterialIcon.Style.Outlined
        )
        val CatchingPokemon = MaterialIcon(
            name = "catching_pokemon",
            style = MaterialIcon.Style.Outlined
        )
        val Category = MaterialIcon(
            name = "category",
            style = MaterialIcon.Style.Outlined
        )
        val Celebration = MaterialIcon(
            name = "celebration",
            style = MaterialIcon.Style.Outlined
        )
        val CellTower = MaterialIcon(
            name = "cell_tower",
            style = MaterialIcon.Style.Outlined
        )
        val CellWifi = MaterialIcon(
            name = "cell_wifi",
            style = MaterialIcon.Style.Outlined
        )
        val CenterFocusStrong = MaterialIcon(
            name = "center_focus_strong",
            style = MaterialIcon.Style.Outlined
        )
        val CenterFocusWeak = MaterialIcon(
            name = "center_focus_weak",
            style = MaterialIcon.Style.Outlined
        )
        val Chair = MaterialIcon(
            name = "chair",
            style = MaterialIcon.Style.Outlined
        )
        val ChairAlt = MaterialIcon(
            name = "chair_alt",
            style = MaterialIcon.Style.Outlined
        )
        val Chalet = MaterialIcon(
            name = "chalet",
            style = MaterialIcon.Style.Outlined
        )
        val ChangeCircle = MaterialIcon(
            name = "change_circle",
            style = MaterialIcon.Style.Outlined
        )
        val ChangeHistory = MaterialIcon(
            name = "change_history",
            style = MaterialIcon.Style.Outlined
        )
        val ChargingStation = MaterialIcon(
            name = "charging_station",
            style = MaterialIcon.Style.Outlined
        )
        val Chat = MaterialIcon(
            name = "chat",
            style = MaterialIcon.Style.Outlined
        )
        val ChatBubble = MaterialIcon(
            name = "chat_bubble",
            style = MaterialIcon.Style.Outlined
        )
        val ChatBubbleOutline = MaterialIcon(
            name = "chat_bubble_outline",
            style = MaterialIcon.Style.Outlined
        )
        val Check = MaterialIcon(
            name = "check",
            style = MaterialIcon.Style.Outlined
        )
        val CheckBox = MaterialIcon(
            name = "check_box",
            style = MaterialIcon.Style.Outlined
        )
        val CheckBoxOutlineBlank = MaterialIcon(
            name = "check_box_outline_blank",
            style = MaterialIcon.Style.Outlined
        )
        val CheckCircle = MaterialIcon(
            name = "check_circle",
            style = MaterialIcon.Style.Outlined
        )
        val CheckCircleOutline = MaterialIcon(
            name = "check_circle_outline",
            style = MaterialIcon.Style.Outlined
        )
        val Checklist = MaterialIcon(
            name = "checklist",
            style = MaterialIcon.Style.Outlined
        )
        val ChecklistRtl = MaterialIcon(
            name = "checklist_rtl",
            style = MaterialIcon.Style.Outlined
        )
        val Checkroom = MaterialIcon(
            name = "checkroom",
            style = MaterialIcon.Style.Outlined
        )
        val ChevronLeft = MaterialIcon(
            name = "chevron_left",
            style = MaterialIcon.Style.Outlined
        )
        val ChevronRight = MaterialIcon(
            name = "chevron_right",
            style = MaterialIcon.Style.Outlined
        )
        val ChildCare = MaterialIcon(
            name = "child_care",
            style = MaterialIcon.Style.Outlined
        )
        val ChildFriendly = MaterialIcon(
            name = "child_friendly",
            style = MaterialIcon.Style.Outlined
        )
        val ChromeReaderMode = MaterialIcon(
            name = "chrome_reader_mode",
            style = MaterialIcon.Style.Outlined
        )
        val Church = MaterialIcon(
            name = "church",
            style = MaterialIcon.Style.Outlined
        )
        val Circle = MaterialIcon(
            name = "circle",
            style = MaterialIcon.Style.Outlined
        )
        val CircleNotifications = MaterialIcon(
            name = "circle_notifications",
            style = MaterialIcon.Style.Outlined
        )
        val Class = MaterialIcon(
            name = "class",
            style = MaterialIcon.Style.Outlined
        )
        val CleanHands = MaterialIcon(
            name = "clean_hands",
            style = MaterialIcon.Style.Outlined
        )
        val CleaningServices = MaterialIcon(
            name = "cleaning_services",
            style = MaterialIcon.Style.Outlined
        )
        val Clear = MaterialIcon(
            name = "clear",
            style = MaterialIcon.Style.Outlined
        )
        val ClearAll = MaterialIcon(
            name = "clear_all",
            style = MaterialIcon.Style.Outlined
        )
        val Close = MaterialIcon(
            name = "close",
            style = MaterialIcon.Style.Outlined
        )
        val CloseFullscreen = MaterialIcon(
            name = "close_fullscreen",
            style = MaterialIcon.Style.Outlined
        )
        val ClosedCaption = MaterialIcon(
            name = "closed_caption",
            style = MaterialIcon.Style.Outlined
        )
        val ClosedCaptionDisabled = MaterialIcon(
            name = "closed_caption_disabled",
            style = MaterialIcon.Style.Outlined
        )
        val ClosedCaptionOff = MaterialIcon(
            name = "closed_caption_off",
            style = MaterialIcon.Style.Outlined
        )
        val Cloud = MaterialIcon(
            name = "cloud",
            style = MaterialIcon.Style.Outlined
        )
        val CloudCircle = MaterialIcon(
            name = "cloud_circle",
            style = MaterialIcon.Style.Outlined
        )
        val CloudDone = MaterialIcon(
            name = "cloud_done",
            style = MaterialIcon.Style.Outlined
        )
        val CloudDownload = MaterialIcon(
            name = "cloud_download",
            style = MaterialIcon.Style.Outlined
        )
        val CloudOff = MaterialIcon(
            name = "cloud_off",
            style = MaterialIcon.Style.Outlined
        )
        val CloudQueue = MaterialIcon(
            name = "cloud_queue",
            style = MaterialIcon.Style.Outlined
        )
        val CloudSync = MaterialIcon(
            name = "cloud_sync",
            style = MaterialIcon.Style.Outlined
        )
        val CloudUpload = MaterialIcon(
            name = "cloud_upload",
            style = MaterialIcon.Style.Outlined
        )
        val Co2 = MaterialIcon(
            name = "co2",
            style = MaterialIcon.Style.Outlined
        )
        val CoPresent = MaterialIcon(
            name = "co_present",
            style = MaterialIcon.Style.Outlined
        )
        val Code = MaterialIcon(
            name = "code",
            style = MaterialIcon.Style.Outlined
        )
        val CodeOff = MaterialIcon(
            name = "code_off",
            style = MaterialIcon.Style.Outlined
        )
        val Coffee = MaterialIcon(
            name = "coffee",
            style = MaterialIcon.Style.Outlined
        )
        val CoffeeMaker = MaterialIcon(
            name = "coffee_maker",
            style = MaterialIcon.Style.Outlined
        )
        val Collections = MaterialIcon(
            name = "collections",
            style = MaterialIcon.Style.Outlined
        )
        val CollectionsBookmark = MaterialIcon(
            name = "collections_bookmark",
            style = MaterialIcon.Style.Outlined
        )
        val ColorLens = MaterialIcon(
            name = "color_lens",
            style = MaterialIcon.Style.Outlined
        )
        val Colorize = MaterialIcon(
            name = "colorize",
            style = MaterialIcon.Style.Outlined
        )
        val Comment = MaterialIcon(
            name = "comment",
            style = MaterialIcon.Style.Outlined
        )
        val CommentBank = MaterialIcon(
            name = "comment_bank",
            style = MaterialIcon.Style.Outlined
        )
        val CommentsDisabled = MaterialIcon(
            name = "comments_disabled",
            style = MaterialIcon.Style.Outlined
        )
        val Commit = MaterialIcon(
            name = "commit",
            style = MaterialIcon.Style.Outlined
        )
        val Commute = MaterialIcon(
            name = "commute",
            style = MaterialIcon.Style.Outlined
        )
        val Compare = MaterialIcon(
            name = "compare",
            style = MaterialIcon.Style.Outlined
        )
        val CompareArrows = MaterialIcon(
            name = "compare_arrows",
            style = MaterialIcon.Style.Outlined
        )
        val CompassCalibration = MaterialIcon(
            name = "compass_calibration",
            style = MaterialIcon.Style.Outlined
        )
        val Compost = MaterialIcon(
            name = "compost",
            style = MaterialIcon.Style.Outlined
        )
        val Compress = MaterialIcon(
            name = "compress",
            style = MaterialIcon.Style.Outlined
        )
        val Computer = MaterialIcon(
            name = "computer",
            style = MaterialIcon.Style.Outlined
        )
        val ConfirmationNumber = MaterialIcon(
            name = "confirmation_number",
            style = MaterialIcon.Style.Outlined
        )
        val ConnectWithoutContact = MaterialIcon(
            name = "connect_without_contact",
            style = MaterialIcon.Style.Outlined
        )
        val ConnectedTv = MaterialIcon(
            name = "connected_tv",
            style = MaterialIcon.Style.Outlined
        )
        val ConnectingAirports = MaterialIcon(
            name = "connecting_airports",
            style = MaterialIcon.Style.Outlined
        )
        val Construction = MaterialIcon(
            name = "construction",
            style = MaterialIcon.Style.Outlined
        )
        val ContactEmergency = MaterialIcon(
            name = "contact_emergency",
            style = MaterialIcon.Style.Outlined
        )
        val ContactMail = MaterialIcon(
            name = "contact_mail",
            style = MaterialIcon.Style.Outlined
        )
        val ContactPage = MaterialIcon(
            name = "contact_page",
            style = MaterialIcon.Style.Outlined
        )
        val ContactPhone = MaterialIcon(
            name = "contact_phone",
            style = MaterialIcon.Style.Outlined
        )
        val ContactSupport = MaterialIcon(
            name = "contact_support",
            style = MaterialIcon.Style.Outlined
        )
        val Contactless = MaterialIcon(
            name = "contactless",
            style = MaterialIcon.Style.Outlined
        )
        val Contacts = MaterialIcon(
            name = "contacts",
            style = MaterialIcon.Style.Outlined
        )
        val ContentCopy = MaterialIcon(
            name = "content_copy",
            style = MaterialIcon.Style.Outlined
        )
        val ContentCut = MaterialIcon(
            name = "content_cut",
            style = MaterialIcon.Style.Outlined
        )
        val ContentPaste = MaterialIcon(
            name = "content_paste",
            style = MaterialIcon.Style.Outlined
        )
        val ContentPasteGo = MaterialIcon(
            name = "content_paste_go",
            style = MaterialIcon.Style.Outlined
        )
        val ContentPasteOff = MaterialIcon(
            name = "content_paste_off",
            style = MaterialIcon.Style.Outlined
        )
        val ContentPasteSearch = MaterialIcon(
            name = "content_paste_search",
            style = MaterialIcon.Style.Outlined
        )
        val Contrast = MaterialIcon(
            name = "contrast",
            style = MaterialIcon.Style.Outlined
        )
        val ControlCamera = MaterialIcon(
            name = "control_camera",
            style = MaterialIcon.Style.Outlined
        )
        val ControlPoint = MaterialIcon(
            name = "control_point",
            style = MaterialIcon.Style.Outlined
        )
        val ControlPointDuplicate = MaterialIcon(
            name = "control_point_duplicate",
            style = MaterialIcon.Style.Outlined
        )
        val Cookie = MaterialIcon(
            name = "cookie",
            style = MaterialIcon.Style.Outlined
        )
        val CopyAll = MaterialIcon(
            name = "copy_all",
            style = MaterialIcon.Style.Outlined
        )
        val Copyright = MaterialIcon(
            name = "copyright",
            style = MaterialIcon.Style.Outlined
        )
        val Coronavirus = MaterialIcon(
            name = "coronavirus",
            style = MaterialIcon.Style.Outlined
        )
        val CorporateFare = MaterialIcon(
            name = "corporate_fare",
            style = MaterialIcon.Style.Outlined
        )
        val Cottage = MaterialIcon(
            name = "cottage",
            style = MaterialIcon.Style.Outlined
        )
        val Countertops = MaterialIcon(
            name = "countertops",
            style = MaterialIcon.Style.Outlined
        )
        val Create = MaterialIcon(
            name = "create",
            style = MaterialIcon.Style.Outlined
        )
        val CreateNewFolder = MaterialIcon(
            name = "create_new_folder",
            style = MaterialIcon.Style.Outlined
        )
        val CreditCard = MaterialIcon(
            name = "credit_card",
            style = MaterialIcon.Style.Outlined
        )
        val CreditCardOff = MaterialIcon(
            name = "credit_card_off",
            style = MaterialIcon.Style.Outlined
        )
        val CreditScore = MaterialIcon(
            name = "credit_score",
            style = MaterialIcon.Style.Outlined
        )
        val Crib = MaterialIcon(
            name = "crib",
            style = MaterialIcon.Style.Outlined
        )
        val CrisisAlert = MaterialIcon(
            name = "crisis_alert",
            style = MaterialIcon.Style.Outlined
        )
        val Crop = MaterialIcon(
            name = "crop",
            style = MaterialIcon.Style.Outlined
        )
        val Crop169 = MaterialIcon(
            name = "crop_16_9",
            style = MaterialIcon.Style.Outlined
        )
        val Crop32 = MaterialIcon(
            name = "crop_3_2",
            style = MaterialIcon.Style.Outlined
        )
        val Crop54 = MaterialIcon(
            name = "crop_5_4",
            style = MaterialIcon.Style.Outlined
        )
        val Crop75 = MaterialIcon(
            name = "crop_7_5",
            style = MaterialIcon.Style.Outlined
        )
        val CropDin = MaterialIcon(
            name = "crop_din",
            style = MaterialIcon.Style.Outlined
        )
        val CropFree = MaterialIcon(
            name = "crop_free",
            style = MaterialIcon.Style.Outlined
        )
        val CropLandscape = MaterialIcon(
            name = "crop_landscape",
            style = MaterialIcon.Style.Outlined
        )
        val CropOriginal = MaterialIcon(
            name = "crop_original",
            style = MaterialIcon.Style.Outlined
        )
        val CropPortrait = MaterialIcon(
            name = "crop_portrait",
            style = MaterialIcon.Style.Outlined
        )
        val CropRotate = MaterialIcon(
            name = "crop_rotate",
            style = MaterialIcon.Style.Outlined
        )
        val CropSquare = MaterialIcon(
            name = "crop_square",
            style = MaterialIcon.Style.Outlined
        )
        val CrueltyFree = MaterialIcon(
            name = "cruelty_free",
            style = MaterialIcon.Style.Outlined
        )
        val Css = MaterialIcon(
            name = "css",
            style = MaterialIcon.Style.Outlined
        )
        val CurrencyBitcoin = MaterialIcon(
            name = "currency_bitcoin",
            style = MaterialIcon.Style.Outlined
        )
        val CurrencyExchange = MaterialIcon(
            name = "currency_exchange",
            style = MaterialIcon.Style.Outlined
        )
        val CurrencyFranc = MaterialIcon(
            name = "currency_franc",
            style = MaterialIcon.Style.Outlined
        )
        val CurrencyLira = MaterialIcon(
            name = "currency_lira",
            style = MaterialIcon.Style.Outlined
        )
        val CurrencyPound = MaterialIcon(
            name = "currency_pound",
            style = MaterialIcon.Style.Outlined
        )
        val CurrencyRuble = MaterialIcon(
            name = "currency_ruble",
            style = MaterialIcon.Style.Outlined
        )
        val CurrencyRupee = MaterialIcon(
            name = "currency_rupee",
            style = MaterialIcon.Style.Outlined
        )
        val CurrencyYen = MaterialIcon(
            name = "currency_yen",
            style = MaterialIcon.Style.Outlined
        )
        val CurrencyYuan = MaterialIcon(
            name = "currency_yuan",
            style = MaterialIcon.Style.Outlined
        )
        val Curtains = MaterialIcon(
            name = "curtains",
            style = MaterialIcon.Style.Outlined
        )
        val CurtainsClosed = MaterialIcon(
            name = "curtains_closed",
            style = MaterialIcon.Style.Outlined
        )
        val Cyclone = MaterialIcon(
            name = "cyclone",
            style = MaterialIcon.Style.Outlined
        )
        val Dangerous = MaterialIcon(
            name = "dangerous",
            style = MaterialIcon.Style.Outlined
        )
        val DarkMode = MaterialIcon(
            name = "dark_mode",
            style = MaterialIcon.Style.Outlined
        )
        val Dashboard = MaterialIcon(
            name = "dashboard",
            style = MaterialIcon.Style.Outlined
        )
        val DashboardCustomize = MaterialIcon(
            name = "dashboard_customize",
            style = MaterialIcon.Style.Outlined
        )
        val DataArray = MaterialIcon(
            name = "data_array",
            style = MaterialIcon.Style.Outlined
        )
        val DataExploration = MaterialIcon(
            name = "data_exploration",
            style = MaterialIcon.Style.Outlined
        )
        val DataObject = MaterialIcon(
            name = "data_object",
            style = MaterialIcon.Style.Outlined
        )
        val DataSaverOff = MaterialIcon(
            name = "data_saver_off",
            style = MaterialIcon.Style.Outlined
        )
        val DataSaverOn = MaterialIcon(
            name = "data_saver_on",
            style = MaterialIcon.Style.Outlined
        )
        val DataThresholding = MaterialIcon(
            name = "data_thresholding",
            style = MaterialIcon.Style.Outlined
        )
        val DataUsage = MaterialIcon(
            name = "data_usage",
            style = MaterialIcon.Style.Outlined
        )
        val Dataset = MaterialIcon(
            name = "dataset",
            style = MaterialIcon.Style.Outlined
        )
        val DatasetLinked = MaterialIcon(
            name = "dataset_linked",
            style = MaterialIcon.Style.Outlined
        )
        val DateRange = MaterialIcon(
            name = "date_range",
            style = MaterialIcon.Style.Outlined
        )
        val Deblur = MaterialIcon(
            name = "deblur",
            style = MaterialIcon.Style.Outlined
        )
        val Deck = MaterialIcon(
            name = "deck",
            style = MaterialIcon.Style.Outlined
        )
        val Dehaze = MaterialIcon(
            name = "dehaze",
            style = MaterialIcon.Style.Outlined
        )
        val Delete = MaterialIcon(
            name = "delete",
            style = MaterialIcon.Style.Outlined
        )
        val DeleteForever = MaterialIcon(
            name = "delete_forever",
            style = MaterialIcon.Style.Outlined
        )
        val DeleteOutline = MaterialIcon(
            name = "delete_outline",
            style = MaterialIcon.Style.Outlined
        )
        val DeleteSweep = MaterialIcon(
            name = "delete_sweep",
            style = MaterialIcon.Style.Outlined
        )
        val DeliveryDining = MaterialIcon(
            name = "delivery_dining",
            style = MaterialIcon.Style.Outlined
        )
        val DensityLarge = MaterialIcon(
            name = "density_large",
            style = MaterialIcon.Style.Outlined
        )
        val DensityMedium = MaterialIcon(
            name = "density_medium",
            style = MaterialIcon.Style.Outlined
        )
        val DensitySmall = MaterialIcon(
            name = "density_small",
            style = MaterialIcon.Style.Outlined
        )
        val DepartureBoard = MaterialIcon(
            name = "departure_board",
            style = MaterialIcon.Style.Outlined
        )
        val Description = MaterialIcon(
            name = "description",
            style = MaterialIcon.Style.Outlined
        )
        val Deselect = MaterialIcon(
            name = "deselect",
            style = MaterialIcon.Style.Outlined
        )
        val DesignServices = MaterialIcon(
            name = "design_services",
            style = MaterialIcon.Style.Outlined
        )
        val Desk = MaterialIcon(
            name = "desk",
            style = MaterialIcon.Style.Outlined
        )
        val DesktopAccessDisabled = MaterialIcon(
            name = "desktop_access_disabled",
            style = MaterialIcon.Style.Outlined
        )
        val DesktopMac = MaterialIcon(
            name = "desktop_mac",
            style = MaterialIcon.Style.Outlined
        )
        val DesktopWindows = MaterialIcon(
            name = "desktop_windows",
            style = MaterialIcon.Style.Outlined
        )
        val Details = MaterialIcon(
            name = "details",
            style = MaterialIcon.Style.Outlined
        )
        val DeveloperBoard = MaterialIcon(
            name = "developer_board",
            style = MaterialIcon.Style.Outlined
        )
        val DeveloperBoardOff = MaterialIcon(
            name = "developer_board_off",
            style = MaterialIcon.Style.Outlined
        )
        val DeveloperMode = MaterialIcon(
            name = "developer_mode",
            style = MaterialIcon.Style.Outlined
        )
        val DeviceHub = MaterialIcon(
            name = "device_hub",
            style = MaterialIcon.Style.Outlined
        )
        val DeviceThermostat = MaterialIcon(
            name = "device_thermostat",
            style = MaterialIcon.Style.Outlined
        )
        val DeviceUnknown = MaterialIcon(
            name = "device_unknown",
            style = MaterialIcon.Style.Outlined
        )
        val Devices = MaterialIcon(
            name = "devices",
            style = MaterialIcon.Style.Outlined
        )
        val DevicesFold = MaterialIcon(
            name = "devices_fold",
            style = MaterialIcon.Style.Outlined
        )
        val DevicesOther = MaterialIcon(
            name = "devices_other",
            style = MaterialIcon.Style.Outlined
        )
        val DialerSip = MaterialIcon(
            name = "dialer_sip",
            style = MaterialIcon.Style.Outlined
        )
        val Dialpad = MaterialIcon(
            name = "dialpad",
            style = MaterialIcon.Style.Outlined
        )
        val Diamond = MaterialIcon(
            name = "diamond",
            style = MaterialIcon.Style.Outlined
        )
        val Difference = MaterialIcon(
            name = "difference",
            style = MaterialIcon.Style.Outlined
        )
        val Dining = MaterialIcon(
            name = "dining",
            style = MaterialIcon.Style.Outlined
        )
        val DinnerDining = MaterialIcon(
            name = "dinner_dining",
            style = MaterialIcon.Style.Outlined
        )
        val Directions = MaterialIcon(
            name = "directions",
            style = MaterialIcon.Style.Outlined
        )
        val DirectionsBike = MaterialIcon(
            name = "directions_bike",
            style = MaterialIcon.Style.Outlined
        )
        val DirectionsBoat = MaterialIcon(
            name = "directions_boat",
            style = MaterialIcon.Style.Outlined
        )
        val DirectionsBoatFilled = MaterialIcon(
            name = "directions_boat_filled",
            style = MaterialIcon.Style.Outlined
        )
        val DirectionsBus = MaterialIcon(
            name = "directions_bus",
            style = MaterialIcon.Style.Outlined
        )
        val DirectionsBusFilled = MaterialIcon(
            name = "directions_bus_filled",
            style = MaterialIcon.Style.Outlined
        )
        val DirectionsCar = MaterialIcon(
            name = "directions_car",
            style = MaterialIcon.Style.Outlined
        )
        val DirectionsCarFilled = MaterialIcon(
            name = "directions_car_filled",
            style = MaterialIcon.Style.Outlined
        )
        val DirectionsOff = MaterialIcon(
            name = "directions_off",
            style = MaterialIcon.Style.Outlined
        )
        val DirectionsRailway = MaterialIcon(
            name = "directions_railway",
            style = MaterialIcon.Style.Outlined
        )
        val DirectionsRailwayFilled = MaterialIcon(
            name = "directions_railway_filled",
            style = MaterialIcon.Style.Outlined
        )
        val DirectionsRun = MaterialIcon(
            name = "directions_run",
            style = MaterialIcon.Style.Outlined
        )
        val DirectionsSubway = MaterialIcon(
            name = "directions_subway",
            style = MaterialIcon.Style.Outlined
        )
        val DirectionsSubwayFilled = MaterialIcon(
            name = "directions_subway_filled",
            style = MaterialIcon.Style.Outlined
        )
        val DirectionsTransit = MaterialIcon(
            name = "directions_transit",
            style = MaterialIcon.Style.Outlined
        )
        val DirectionsTransitFilled = MaterialIcon(
            name = "directions_transit_filled",
            style = MaterialIcon.Style.Outlined
        )
        val DirectionsWalk = MaterialIcon(
            name = "directions_walk",
            style = MaterialIcon.Style.Outlined
        )
        val DirtyLens = MaterialIcon(
            name = "dirty_lens",
            style = MaterialIcon.Style.Outlined
        )
        val DisabledByDefault = MaterialIcon(
            name = "disabled_by_default",
            style = MaterialIcon.Style.Outlined
        )
        val DisabledVisible = MaterialIcon(
            name = "disabled_visible",
            style = MaterialIcon.Style.Outlined
        )
        val DiscFull = MaterialIcon(
            name = "disc_full",
            style = MaterialIcon.Style.Outlined
        )
        val Discount = MaterialIcon(
            name = "discount",
            style = MaterialIcon.Style.Outlined
        )
        val DisplaySettings = MaterialIcon(
            name = "display_settings",
            style = MaterialIcon.Style.Outlined
        )
        val Diversity1 = MaterialIcon(
            name = "diversity_1",
            style = MaterialIcon.Style.Outlined
        )
        val Diversity2 = MaterialIcon(
            name = "diversity_2",
            style = MaterialIcon.Style.Outlined
        )
        val Diversity3 = MaterialIcon(
            name = "diversity_3",
            style = MaterialIcon.Style.Outlined
        )
        val Dns = MaterialIcon(
            name = "dns",
            style = MaterialIcon.Style.Outlined
        )
        val DoDisturb = MaterialIcon(
            name = "do_disturb",
            style = MaterialIcon.Style.Outlined
        )
        val DoDisturbAlt = MaterialIcon(
            name = "do_disturb_alt",
            style = MaterialIcon.Style.Outlined
        )
        val DoDisturbOff = MaterialIcon(
            name = "do_disturb_off",
            style = MaterialIcon.Style.Outlined
        )
        val DoDisturbOn = MaterialIcon(
            name = "do_disturb_on",
            style = MaterialIcon.Style.Outlined
        )
        val DoNotDisturb = MaterialIcon(
            name = "do_not_disturb",
            style = MaterialIcon.Style.Outlined
        )
        val DoNotDisturbAlt = MaterialIcon(
            name = "do_not_disturb_alt",
            style = MaterialIcon.Style.Outlined
        )
        val DoNotDisturbOff = MaterialIcon(
            name = "do_not_disturb_off",
            style = MaterialIcon.Style.Outlined
        )
        val DoNotDisturbOn = MaterialIcon(
            name = "do_not_disturb_on",
            style = MaterialIcon.Style.Outlined
        )
        val DoNotDisturbOnTotalSilence = MaterialIcon(
            name = "do_not_disturb_on_total_silence",
            style = MaterialIcon.Style.Outlined
        )
        val DoNotStep = MaterialIcon(
            name = "do_not_step",
            style = MaterialIcon.Style.Outlined
        )
        val DoNotTouch = MaterialIcon(
            name = "do_not_touch",
            style = MaterialIcon.Style.Outlined
        )
        val Dock = MaterialIcon(
            name = "dock",
            style = MaterialIcon.Style.Outlined
        )
        val DocumentScanner = MaterialIcon(
            name = "document_scanner",
            style = MaterialIcon.Style.Outlined
        )
        val Domain = MaterialIcon(
            name = "domain",
            style = MaterialIcon.Style.Outlined
        )
        val DomainAdd = MaterialIcon(
            name = "domain_add",
            style = MaterialIcon.Style.Outlined
        )
        val DomainDisabled = MaterialIcon(
            name = "domain_disabled",
            style = MaterialIcon.Style.Outlined
        )
        val DomainVerification = MaterialIcon(
            name = "domain_verification",
            style = MaterialIcon.Style.Outlined
        )
        val Done = MaterialIcon(
            name = "done",
            style = MaterialIcon.Style.Outlined
        )
        val DoneAll = MaterialIcon(
            name = "done_all",
            style = MaterialIcon.Style.Outlined
        )
        val DoneOutline = MaterialIcon(
            name = "done_outline",
            style = MaterialIcon.Style.Outlined
        )
        val DonutLarge = MaterialIcon(
            name = "donut_large",
            style = MaterialIcon.Style.Outlined
        )
        val DonutSmall = MaterialIcon(
            name = "donut_small",
            style = MaterialIcon.Style.Outlined
        )
        val DoorBack = MaterialIcon(
            name = "door_back",
            style = MaterialIcon.Style.Outlined
        )
        val DoorFront = MaterialIcon(
            name = "door_front",
            style = MaterialIcon.Style.Outlined
        )
        val DoorSliding = MaterialIcon(
            name = "door_sliding",
            style = MaterialIcon.Style.Outlined
        )
        val Doorbell = MaterialIcon(
            name = "doorbell",
            style = MaterialIcon.Style.Outlined
        )
        val DoubleArrow = MaterialIcon(
            name = "double_arrow",
            style = MaterialIcon.Style.Outlined
        )
        val DownhillSkiing = MaterialIcon(
            name = "downhill_skiing",
            style = MaterialIcon.Style.Outlined
        )
        val Download = MaterialIcon(
            name = "download",
            style = MaterialIcon.Style.Outlined
        )
        val DownloadDone = MaterialIcon(
            name = "download_done",
            style = MaterialIcon.Style.Outlined
        )
        val DownloadForOffline = MaterialIcon(
            name = "download_for_offline",
            style = MaterialIcon.Style.Outlined
        )
        val Downloading = MaterialIcon(
            name = "downloading",
            style = MaterialIcon.Style.Outlined
        )
        val Drafts = MaterialIcon(
            name = "drafts",
            style = MaterialIcon.Style.Outlined
        )
        val DragHandle = MaterialIcon(
            name = "drag_handle",
            style = MaterialIcon.Style.Outlined
        )
        val DragIndicator = MaterialIcon(
            name = "drag_indicator",
            style = MaterialIcon.Style.Outlined
        )
        val Draw = MaterialIcon(
            name = "draw",
            style = MaterialIcon.Style.Outlined
        )
        val DriveEta = MaterialIcon(
            name = "drive_eta",
            style = MaterialIcon.Style.Outlined
        )
        val DriveFileMove = MaterialIcon(
            name = "drive_file_move",
            style = MaterialIcon.Style.Outlined
        )
        val DriveFileMoveRtl = MaterialIcon(
            name = "drive_file_move_rtl",
            style = MaterialIcon.Style.Outlined
        )
        val DriveFileRenameOutline = MaterialIcon(
            name = "drive_file_rename_outline",
            style = MaterialIcon.Style.Outlined
        )
        val DriveFolderUpload = MaterialIcon(
            name = "drive_folder_upload",
            style = MaterialIcon.Style.Outlined
        )
        val Dry = MaterialIcon(
            name = "dry",
            style = MaterialIcon.Style.Outlined
        )
        val DryCleaning = MaterialIcon(
            name = "dry_cleaning",
            style = MaterialIcon.Style.Outlined
        )
        val Duo = MaterialIcon(
            name = "duo",
            style = MaterialIcon.Style.Outlined
        )
        val Dvr = MaterialIcon(
            name = "dvr",
            style = MaterialIcon.Style.Outlined
        )
        val DynamicFeed = MaterialIcon(
            name = "dynamic_feed",
            style = MaterialIcon.Style.Outlined
        )
        val DynamicForm = MaterialIcon(
            name = "dynamic_form",
            style = MaterialIcon.Style.Outlined
        )
        val EMobiledata = MaterialIcon(
            name = "e_mobiledata",
            style = MaterialIcon.Style.Outlined
        )
        val Earbuds = MaterialIcon(
            name = "earbuds",
            style = MaterialIcon.Style.Outlined
        )
        val EarbudsBattery = MaterialIcon(
            name = "earbuds_battery",
            style = MaterialIcon.Style.Outlined
        )
        val East = MaterialIcon(
            name = "east",
            style = MaterialIcon.Style.Outlined
        )
        val EdgesensorHigh = MaterialIcon(
            name = "edgesensor_high",
            style = MaterialIcon.Style.Outlined
        )
        val EdgesensorLow = MaterialIcon(
            name = "edgesensor_low",
            style = MaterialIcon.Style.Outlined
        )
        val Edit = MaterialIcon(
            name = "edit",
            style = MaterialIcon.Style.Outlined
        )
        val EditAttributes = MaterialIcon(
            name = "edit_attributes",
            style = MaterialIcon.Style.Outlined
        )
        val EditCalendar = MaterialIcon(
            name = "edit_calendar",
            style = MaterialIcon.Style.Outlined
        )
        val EditLocation = MaterialIcon(
            name = "edit_location",
            style = MaterialIcon.Style.Outlined
        )
        val EditLocationAlt = MaterialIcon(
            name = "edit_location_alt",
            style = MaterialIcon.Style.Outlined
        )
        val EditNote = MaterialIcon(
            name = "edit_note",
            style = MaterialIcon.Style.Outlined
        )
        val EditNotifications = MaterialIcon(
            name = "edit_notifications",
            style = MaterialIcon.Style.Outlined
        )
        val EditOff = MaterialIcon(
            name = "edit_off",
            style = MaterialIcon.Style.Outlined
        )
        val EditRoad = MaterialIcon(
            name = "edit_road",
            style = MaterialIcon.Style.Outlined
        )
        val Egg = MaterialIcon(
            name = "egg",
            style = MaterialIcon.Style.Outlined
        )
        val EggAlt = MaterialIcon(
            name = "egg_alt",
            style = MaterialIcon.Style.Outlined
        )
        val Eject = MaterialIcon(
            name = "eject",
            style = MaterialIcon.Style.Outlined
        )
        val Elderly = MaterialIcon(
            name = "elderly",
            style = MaterialIcon.Style.Outlined
        )
        val ElderlyWoman = MaterialIcon(
            name = "elderly_woman",
            style = MaterialIcon.Style.Outlined
        )
        val ElectricBike = MaterialIcon(
            name = "electric_bike",
            style = MaterialIcon.Style.Outlined
        )
        val ElectricBolt = MaterialIcon(
            name = "electric_bolt",
            style = MaterialIcon.Style.Outlined
        )
        val ElectricCar = MaterialIcon(
            name = "electric_car",
            style = MaterialIcon.Style.Outlined
        )
        val ElectricMeter = MaterialIcon(
            name = "electric_meter",
            style = MaterialIcon.Style.Outlined
        )
        val ElectricMoped = MaterialIcon(
            name = "electric_moped",
            style = MaterialIcon.Style.Outlined
        )
        val ElectricRickshaw = MaterialIcon(
            name = "electric_rickshaw",
            style = MaterialIcon.Style.Outlined
        )
        val ElectricScooter = MaterialIcon(
            name = "electric_scooter",
            style = MaterialIcon.Style.Outlined
        )
        val ElectricalServices = MaterialIcon(
            name = "electrical_services",
            style = MaterialIcon.Style.Outlined
        )
        val Elevator = MaterialIcon(
            name = "elevator",
            style = MaterialIcon.Style.Outlined
        )
        val Email = MaterialIcon(
            name = "email",
            style = MaterialIcon.Style.Outlined
        )
        val Emergency = MaterialIcon(
            name = "emergency",
            style = MaterialIcon.Style.Outlined
        )
        val EmergencyRecording = MaterialIcon(
            name = "emergency_recording",
            style = MaterialIcon.Style.Outlined
        )
        val EmergencyShare = MaterialIcon(
            name = "emergency_share",
            style = MaterialIcon.Style.Outlined
        )
        val EmojiEmotions = MaterialIcon(
            name = "emoji_emotions",
            style = MaterialIcon.Style.Outlined
        )
        val EmojiEvents = MaterialIcon(
            name = "emoji_events",
            style = MaterialIcon.Style.Outlined
        )
        val EmojiFoodBeverage = MaterialIcon(
            name = "emoji_food_beverage",
            style = MaterialIcon.Style.Outlined
        )
        val EmojiNature = MaterialIcon(
            name = "emoji_nature",
            style = MaterialIcon.Style.Outlined
        )
        val EmojiObjects = MaterialIcon(
            name = "emoji_objects",
            style = MaterialIcon.Style.Outlined
        )
        val EmojiPeople = MaterialIcon(
            name = "emoji_people",
            style = MaterialIcon.Style.Outlined
        )
        val EmojiSymbols = MaterialIcon(
            name = "emoji_symbols",
            style = MaterialIcon.Style.Outlined
        )
        val EmojiTransportation = MaterialIcon(
            name = "emoji_transportation",
            style = MaterialIcon.Style.Outlined
        )
        val EnergySavingsLeaf = MaterialIcon(
            name = "energy_savings_leaf",
            style = MaterialIcon.Style.Outlined
        )
        val Engineering = MaterialIcon(
            name = "engineering",
            style = MaterialIcon.Style.Outlined
        )
        val EnhancedEncryption = MaterialIcon(
            name = "enhanced_encryption",
            style = MaterialIcon.Style.Outlined
        )
        val Equalizer = MaterialIcon(
            name = "equalizer",
            style = MaterialIcon.Style.Outlined
        )
        val Error = MaterialIcon(
            name = "error",
            style = MaterialIcon.Style.Outlined
        )
        val ErrorOutline = MaterialIcon(
            name = "error_outline",
            style = MaterialIcon.Style.Outlined
        )
        val Escalator = MaterialIcon(
            name = "escalator",
            style = MaterialIcon.Style.Outlined
        )
        val EscalatorWarning = MaterialIcon(
            name = "escalator_warning",
            style = MaterialIcon.Style.Outlined
        )
        val Euro = MaterialIcon(
            name = "euro",
            style = MaterialIcon.Style.Outlined
        )
        val EuroSymbol = MaterialIcon(
            name = "euro_symbol",
            style = MaterialIcon.Style.Outlined
        )
        val EvStation = MaterialIcon(
            name = "ev_station",
            style = MaterialIcon.Style.Outlined
        )
        val Event = MaterialIcon(
            name = "event",
            style = MaterialIcon.Style.Outlined
        )
        val EventAvailable = MaterialIcon(
            name = "event_available",
            style = MaterialIcon.Style.Outlined
        )
        val EventBusy = MaterialIcon(
            name = "event_busy",
            style = MaterialIcon.Style.Outlined
        )
        val EventNote = MaterialIcon(
            name = "event_note",
            style = MaterialIcon.Style.Outlined
        )
        val EventRepeat = MaterialIcon(
            name = "event_repeat",
            style = MaterialIcon.Style.Outlined
        )
        val EventSeat = MaterialIcon(
            name = "event_seat",
            style = MaterialIcon.Style.Outlined
        )
        val ExitToApp = MaterialIcon(
            name = "exit_to_app",
            style = MaterialIcon.Style.Outlined
        )
        val Expand = MaterialIcon(
            name = "expand",
            style = MaterialIcon.Style.Outlined
        )
        val ExpandCircleDown = MaterialIcon(
            name = "expand_circle_down",
            style = MaterialIcon.Style.Outlined
        )
        val ExpandLess = MaterialIcon(
            name = "expand_less",
            style = MaterialIcon.Style.Outlined
        )
        val ExpandMore = MaterialIcon(
            name = "expand_more",
            style = MaterialIcon.Style.Outlined
        )
        val Explicit = MaterialIcon(
            name = "explicit",
            style = MaterialIcon.Style.Outlined
        )
        val Explore = MaterialIcon(
            name = "explore",
            style = MaterialIcon.Style.Outlined
        )
        val ExploreOff = MaterialIcon(
            name = "explore_off",
            style = MaterialIcon.Style.Outlined
        )
        val Exposure = MaterialIcon(
            name = "exposure",
            style = MaterialIcon.Style.Outlined
        )
        val ExposureNeg1 = MaterialIcon(
            name = "exposure_neg_1",
            style = MaterialIcon.Style.Outlined
        )
        val ExposureNeg2 = MaterialIcon(
            name = "exposure_neg_2",
            style = MaterialIcon.Style.Outlined
        )
        val ExposurePlus1 = MaterialIcon(
            name = "exposure_plus_1",
            style = MaterialIcon.Style.Outlined
        )
        val ExposurePlus2 = MaterialIcon(
            name = "exposure_plus_2",
            style = MaterialIcon.Style.Outlined
        )
        val ExposureZero = MaterialIcon(
            name = "exposure_zero",
            style = MaterialIcon.Style.Outlined
        )
        val Extension = MaterialIcon(
            name = "extension",
            style = MaterialIcon.Style.Outlined
        )
        val ExtensionOff = MaterialIcon(
            name = "extension_off",
            style = MaterialIcon.Style.Outlined
        )
        val Face = MaterialIcon(
            name = "face",
            style = MaterialIcon.Style.Outlined
        )
        val Face2 = MaterialIcon(
            name = "face_2",
            style = MaterialIcon.Style.Outlined
        )
        val Face3 = MaterialIcon(
            name = "face_3",
            style = MaterialIcon.Style.Outlined
        )
        val Face4 = MaterialIcon(
            name = "face_4",
            style = MaterialIcon.Style.Outlined
        )
        val Face5 = MaterialIcon(
            name = "face_5",
            style = MaterialIcon.Style.Outlined
        )
        val Face6 = MaterialIcon(
            name = "face_6",
            style = MaterialIcon.Style.Outlined
        )
        val FaceRetouchingNatural = MaterialIcon(
            name = "face_retouching_natural",
            style = MaterialIcon.Style.Outlined
        )
        val FaceRetouchingOff = MaterialIcon(
            name = "face_retouching_off",
            style = MaterialIcon.Style.Outlined
        )
        val FaceUnlock = MaterialIcon(
            name = "face_unlock",
            style = MaterialIcon.Style.Outlined
        )
        val FactCheck = MaterialIcon(
            name = "fact_check",
            style = MaterialIcon.Style.Outlined
        )
        val Factory = MaterialIcon(
            name = "factory",
            style = MaterialIcon.Style.Outlined
        )
        val FamilyRestroom = MaterialIcon(
            name = "family_restroom",
            style = MaterialIcon.Style.Outlined
        )
        val FastForward = MaterialIcon(
            name = "fast_forward",
            style = MaterialIcon.Style.Outlined
        )
        val FastRewind = MaterialIcon(
            name = "fast_rewind",
            style = MaterialIcon.Style.Outlined
        )
        val Fastfood = MaterialIcon(
            name = "fastfood",
            style = MaterialIcon.Style.Outlined
        )
        val Favorite = MaterialIcon(
            name = "favorite",
            style = MaterialIcon.Style.Outlined
        )
        val FavoriteBorder = MaterialIcon(
            name = "favorite_border",
            style = MaterialIcon.Style.Outlined
        )
        val Fax = MaterialIcon(
            name = "fax",
            style = MaterialIcon.Style.Outlined
        )
        val FeaturedPlayList = MaterialIcon(
            name = "featured_play_list",
            style = MaterialIcon.Style.Outlined
        )
        val FeaturedVideo = MaterialIcon(
            name = "featured_video",
            style = MaterialIcon.Style.Outlined
        )
        val Feed = MaterialIcon(
            name = "feed",
            style = MaterialIcon.Style.Outlined
        )
        val Feedback = MaterialIcon(
            name = "feedback",
            style = MaterialIcon.Style.Outlined
        )
        val Female = MaterialIcon(
            name = "female",
            style = MaterialIcon.Style.Outlined
        )
        val Fence = MaterialIcon(
            name = "fence",
            style = MaterialIcon.Style.Outlined
        )
        val Festival = MaterialIcon(
            name = "festival",
            style = MaterialIcon.Style.Outlined
        )
        val FiberDvr = MaterialIcon(
            name = "fiber_dvr",
            style = MaterialIcon.Style.Outlined
        )
        val FiberManualRecord = MaterialIcon(
            name = "fiber_manual_record",
            style = MaterialIcon.Style.Outlined
        )
        val FiberNew = MaterialIcon(
            name = "fiber_new",
            style = MaterialIcon.Style.Outlined
        )
        val FiberPin = MaterialIcon(
            name = "fiber_pin",
            style = MaterialIcon.Style.Outlined
        )
        val FiberSmartRecord = MaterialIcon(
            name = "fiber_smart_record",
            style = MaterialIcon.Style.Outlined
        )
        val FileCopy = MaterialIcon(
            name = "file_copy",
            style = MaterialIcon.Style.Outlined
        )
        val FileDownload = MaterialIcon(
            name = "file_download",
            style = MaterialIcon.Style.Outlined
        )
        val FileDownloadDone = MaterialIcon(
            name = "file_download_done",
            style = MaterialIcon.Style.Outlined
        )
        val FileDownloadOff = MaterialIcon(
            name = "file_download_off",
            style = MaterialIcon.Style.Outlined
        )
        val FileOpen = MaterialIcon(
            name = "file_open",
            style = MaterialIcon.Style.Outlined
        )
        val FilePresent = MaterialIcon(
            name = "file_present",
            style = MaterialIcon.Style.Outlined
        )
        val FileUpload = MaterialIcon(
            name = "file_upload",
            style = MaterialIcon.Style.Outlined
        )
        val Filter = MaterialIcon(
            name = "filter",
            style = MaterialIcon.Style.Outlined
        )
        val Filter1 = MaterialIcon(
            name = "filter_1",
            style = MaterialIcon.Style.Outlined
        )
        val Filter2 = MaterialIcon(
            name = "filter_2",
            style = MaterialIcon.Style.Outlined
        )
        val Filter3 = MaterialIcon(
            name = "filter_3",
            style = MaterialIcon.Style.Outlined
        )
        val Filter4 = MaterialIcon(
            name = "filter_4",
            style = MaterialIcon.Style.Outlined
        )
        val Filter5 = MaterialIcon(
            name = "filter_5",
            style = MaterialIcon.Style.Outlined
        )
        val Filter6 = MaterialIcon(
            name = "filter_6",
            style = MaterialIcon.Style.Outlined
        )
        val Filter7 = MaterialIcon(
            name = "filter_7",
            style = MaterialIcon.Style.Outlined
        )
        val Filter8 = MaterialIcon(
            name = "filter_8",
            style = MaterialIcon.Style.Outlined
        )
        val Filter9 = MaterialIcon(
            name = "filter_9",
            style = MaterialIcon.Style.Outlined
        )
        val Filter9Plus = MaterialIcon(
            name = "filter_9_plus",
            style = MaterialIcon.Style.Outlined
        )
        val FilterAlt = MaterialIcon(
            name = "filter_alt",
            style = MaterialIcon.Style.Outlined
        )
        val FilterAltOff = MaterialIcon(
            name = "filter_alt_off",
            style = MaterialIcon.Style.Outlined
        )
        val FilterBAndW = MaterialIcon(
            name = "filter_b_and_w",
            style = MaterialIcon.Style.Outlined
        )
        val FilterCenterFocus = MaterialIcon(
            name = "filter_center_focus",
            style = MaterialIcon.Style.Outlined
        )
        val FilterDrama = MaterialIcon(
            name = "filter_drama",
            style = MaterialIcon.Style.Outlined
        )
        val FilterFrames = MaterialIcon(
            name = "filter_frames",
            style = MaterialIcon.Style.Outlined
        )
        val FilterHdr = MaterialIcon(
            name = "filter_hdr",
            style = MaterialIcon.Style.Outlined
        )
        val FilterList = MaterialIcon(
            name = "filter_list",
            style = MaterialIcon.Style.Outlined
        )
        val FilterListOff = MaterialIcon(
            name = "filter_list_off",
            style = MaterialIcon.Style.Outlined
        )
        val FilterNone = MaterialIcon(
            name = "filter_none",
            style = MaterialIcon.Style.Outlined
        )
        val FilterTiltShift = MaterialIcon(
            name = "filter_tilt_shift",
            style = MaterialIcon.Style.Outlined
        )
        val FilterVintage = MaterialIcon(
            name = "filter_vintage",
            style = MaterialIcon.Style.Outlined
        )
        val FindInPage = MaterialIcon(
            name = "find_in_page",
            style = MaterialIcon.Style.Outlined
        )
        val FindReplace = MaterialIcon(
            name = "find_replace",
            style = MaterialIcon.Style.Outlined
        )
        val Fingerprint = MaterialIcon(
            name = "fingerprint",
            style = MaterialIcon.Style.Outlined
        )
        val FireExtinguisher = MaterialIcon(
            name = "fire_extinguisher",
            style = MaterialIcon.Style.Outlined
        )
        val FireHydrantAlt = MaterialIcon(
            name = "fire_hydrant_alt",
            style = MaterialIcon.Style.Outlined
        )
        val FireTruck = MaterialIcon(
            name = "fire_truck",
            style = MaterialIcon.Style.Outlined
        )
        val Fireplace = MaterialIcon(
            name = "fireplace",
            style = MaterialIcon.Style.Outlined
        )
        val FirstPage = MaterialIcon(
            name = "first_page",
            style = MaterialIcon.Style.Outlined
        )
        val FitScreen = MaterialIcon(
            name = "fit_screen",
            style = MaterialIcon.Style.Outlined
        )
        val Fitbit = MaterialIcon(
            name = "fitbit",
            style = MaterialIcon.Style.Outlined
        )
        val FitnessCenter = MaterialIcon(
            name = "fitness_center",
            style = MaterialIcon.Style.Outlined
        )
        val Flag = MaterialIcon(
            name = "flag",
            style = MaterialIcon.Style.Outlined
        )
        val FlagCircle = MaterialIcon(
            name = "flag_circle",
            style = MaterialIcon.Style.Outlined
        )
        val Flaky = MaterialIcon(
            name = "flaky",
            style = MaterialIcon.Style.Outlined
        )
        val Flare = MaterialIcon(
            name = "flare",
            style = MaterialIcon.Style.Outlined
        )
        val FlashAuto = MaterialIcon(
            name = "flash_auto",
            style = MaterialIcon.Style.Outlined
        )
        val FlashOff = MaterialIcon(
            name = "flash_off",
            style = MaterialIcon.Style.Outlined
        )
        val FlashOn = MaterialIcon(
            name = "flash_on",
            style = MaterialIcon.Style.Outlined
        )
        val FlashlightOff = MaterialIcon(
            name = "flashlight_off",
            style = MaterialIcon.Style.Outlined
        )
        val FlashlightOn = MaterialIcon(
            name = "flashlight_on",
            style = MaterialIcon.Style.Outlined
        )
        val Flatware = MaterialIcon(
            name = "flatware",
            style = MaterialIcon.Style.Outlined
        )
        val Flight = MaterialIcon(
            name = "flight",
            style = MaterialIcon.Style.Outlined
        )
        val FlightClass = MaterialIcon(
            name = "flight_class",
            style = MaterialIcon.Style.Outlined
        )
        val FlightLand = MaterialIcon(
            name = "flight_land",
            style = MaterialIcon.Style.Outlined
        )
        val FlightTakeoff = MaterialIcon(
            name = "flight_takeoff",
            style = MaterialIcon.Style.Outlined
        )
        val Flip = MaterialIcon(
            name = "flip",
            style = MaterialIcon.Style.Outlined
        )
        val FlipCameraAndroid = MaterialIcon(
            name = "flip_camera_android",
            style = MaterialIcon.Style.Outlined
        )
        val FlipCameraIos = MaterialIcon(
            name = "flip_camera_ios",
            style = MaterialIcon.Style.Outlined
        )
        val FlipToBack = MaterialIcon(
            name = "flip_to_back",
            style = MaterialIcon.Style.Outlined
        )
        val FlipToFront = MaterialIcon(
            name = "flip_to_front",
            style = MaterialIcon.Style.Outlined
        )
        val Flood = MaterialIcon(
            name = "flood",
            style = MaterialIcon.Style.Outlined
        )
        val Fluorescent = MaterialIcon(
            name = "fluorescent",
            style = MaterialIcon.Style.Outlined
        )
        val FlutterDash = MaterialIcon(
            name = "flutter_dash",
            style = MaterialIcon.Style.Outlined
        )
        val FmdBad = MaterialIcon(
            name = "fmd_bad",
            style = MaterialIcon.Style.Outlined
        )
        val FmdGood = MaterialIcon(
            name = "fmd_good",
            style = MaterialIcon.Style.Outlined
        )
        val Folder = MaterialIcon(
            name = "folder",
            style = MaterialIcon.Style.Outlined
        )
        val FolderCopy = MaterialIcon(
            name = "folder_copy",
            style = MaterialIcon.Style.Outlined
        )
        val FolderDelete = MaterialIcon(
            name = "folder_delete",
            style = MaterialIcon.Style.Outlined
        )
        val FolderOff = MaterialIcon(
            name = "folder_off",
            style = MaterialIcon.Style.Outlined
        )
        val FolderOpen = MaterialIcon(
            name = "folder_open",
            style = MaterialIcon.Style.Outlined
        )
        val FolderShared = MaterialIcon(
            name = "folder_shared",
            style = MaterialIcon.Style.Outlined
        )
        val FolderSpecial = MaterialIcon(
            name = "folder_special",
            style = MaterialIcon.Style.Outlined
        )
        val FolderZip = MaterialIcon(
            name = "folder_zip",
            style = MaterialIcon.Style.Outlined
        )
        val FollowTheSigns = MaterialIcon(
            name = "follow_the_signs",
            style = MaterialIcon.Style.Outlined
        )
        val FontDownload = MaterialIcon(
            name = "font_download",
            style = MaterialIcon.Style.Outlined
        )
        val FontDownloadOff = MaterialIcon(
            name = "font_download_off",
            style = MaterialIcon.Style.Outlined
        )
        val FoodBank = MaterialIcon(
            name = "food_bank",
            style = MaterialIcon.Style.Outlined
        )
        val Forest = MaterialIcon(
            name = "forest",
            style = MaterialIcon.Style.Outlined
        )
        val ForkLeft = MaterialIcon(
            name = "fork_left",
            style = MaterialIcon.Style.Outlined
        )
        val ForkRight = MaterialIcon(
            name = "fork_right",
            style = MaterialIcon.Style.Outlined
        )
        val FormatAlignCenter = MaterialIcon(
            name = "format_align_center",
            style = MaterialIcon.Style.Outlined
        )
        val FormatAlignJustify = MaterialIcon(
            name = "format_align_justify",
            style = MaterialIcon.Style.Outlined
        )
        val FormatAlignLeft = MaterialIcon(
            name = "format_align_left",
            style = MaterialIcon.Style.Outlined
        )
        val FormatAlignRight = MaterialIcon(
            name = "format_align_right",
            style = MaterialIcon.Style.Outlined
        )
        val FormatBold = MaterialIcon(
            name = "format_bold",
            style = MaterialIcon.Style.Outlined
        )
        val FormatClear = MaterialIcon(
            name = "format_clear",
            style = MaterialIcon.Style.Outlined
        )
        val FormatColorFill = MaterialIcon(
            name = "format_color_fill",
            style = MaterialIcon.Style.Outlined
        )
        val FormatColorReset = MaterialIcon(
            name = "format_color_reset",
            style = MaterialIcon.Style.Outlined
        )
        val FormatColorText = MaterialIcon(
            name = "format_color_text",
            style = MaterialIcon.Style.Outlined
        )
        val FormatIndentDecrease = MaterialIcon(
            name = "format_indent_decrease",
            style = MaterialIcon.Style.Outlined
        )
        val FormatIndentIncrease = MaterialIcon(
            name = "format_indent_increase",
            style = MaterialIcon.Style.Outlined
        )
        val FormatItalic = MaterialIcon(
            name = "format_italic",
            style = MaterialIcon.Style.Outlined
        )
        val FormatLineSpacing = MaterialIcon(
            name = "format_line_spacing",
            style = MaterialIcon.Style.Outlined
        )
        val FormatListBulleted = MaterialIcon(
            name = "format_list_bulleted",
            style = MaterialIcon.Style.Outlined
        )
        val FormatListNumbered = MaterialIcon(
            name = "format_list_numbered",
            style = MaterialIcon.Style.Outlined
        )
        val FormatListNumberedRtl = MaterialIcon(
            name = "format_list_numbered_rtl",
            style = MaterialIcon.Style.Outlined
        )
        val FormatOverline = MaterialIcon(
            name = "format_overline",
            style = MaterialIcon.Style.Outlined
        )
        val FormatPaint = MaterialIcon(
            name = "format_paint",
            style = MaterialIcon.Style.Outlined
        )
        val FormatQuote = MaterialIcon(
            name = "format_quote",
            style = MaterialIcon.Style.Outlined
        )
        val FormatShapes = MaterialIcon(
            name = "format_shapes",
            style = MaterialIcon.Style.Outlined
        )
        val FormatSize = MaterialIcon(
            name = "format_size",
            style = MaterialIcon.Style.Outlined
        )
        val FormatStrikethrough = MaterialIcon(
            name = "format_strikethrough",
            style = MaterialIcon.Style.Outlined
        )
        val FormatTextdirectionLToR = MaterialIcon(
            name = "format_textdirection_l_to_r",
            style = MaterialIcon.Style.Outlined
        )
        val FormatTextdirectionRToL = MaterialIcon(
            name = "format_textdirection_r_to_l",
            style = MaterialIcon.Style.Outlined
        )
        val FormatUnderlined = MaterialIcon(
            name = "format_underlined",
            style = MaterialIcon.Style.Outlined
        )
        val Fort = MaterialIcon(
            name = "fort",
            style = MaterialIcon.Style.Outlined
        )
        val Forum = MaterialIcon(
            name = "forum",
            style = MaterialIcon.Style.Outlined
        )
        val Forward = MaterialIcon(
            name = "forward",
            style = MaterialIcon.Style.Outlined
        )
        val Forward10 = MaterialIcon(
            name = "forward_10",
            style = MaterialIcon.Style.Outlined
        )
        val Forward30 = MaterialIcon(
            name = "forward_30",
            style = MaterialIcon.Style.Outlined
        )
        val Forward5 = MaterialIcon(
            name = "forward_5",
            style = MaterialIcon.Style.Outlined
        )
        val ForwardToInbox = MaterialIcon(
            name = "forward_to_inbox",
            style = MaterialIcon.Style.Outlined
        )
        val Foundation = MaterialIcon(
            name = "foundation",
            style = MaterialIcon.Style.Outlined
        )
        val FreeBreakfast = MaterialIcon(
            name = "free_breakfast",
            style = MaterialIcon.Style.Outlined
        )
        val FreeCancellation = MaterialIcon(
            name = "free_cancellation",
            style = MaterialIcon.Style.Outlined
        )
        val FrontHand = MaterialIcon(
            name = "front_hand",
            style = MaterialIcon.Style.Outlined
        )
        val Fullscreen = MaterialIcon(
            name = "fullscreen",
            style = MaterialIcon.Style.Outlined
        )
        val FullscreenExit = MaterialIcon(
            name = "fullscreen_exit",
            style = MaterialIcon.Style.Outlined
        )
        val Functions = MaterialIcon(
            name = "functions",
            style = MaterialIcon.Style.Outlined
        )
        val GMobiledata = MaterialIcon(
            name = "g_mobiledata",
            style = MaterialIcon.Style.Outlined
        )
        val GTranslate = MaterialIcon(
            name = "g_translate",
            style = MaterialIcon.Style.Outlined
        )
        val Gamepad = MaterialIcon(
            name = "gamepad",
            style = MaterialIcon.Style.Outlined
        )
        val Games = MaterialIcon(
            name = "games",
            style = MaterialIcon.Style.Outlined
        )
        val Garage = MaterialIcon(
            name = "garage",
            style = MaterialIcon.Style.Outlined
        )
        val GasMeter = MaterialIcon(
            name = "gas_meter",
            style = MaterialIcon.Style.Outlined
        )
        val Gavel = MaterialIcon(
            name = "gavel",
            style = MaterialIcon.Style.Outlined
        )
        val GeneratingTokens = MaterialIcon(
            name = "generating_tokens",
            style = MaterialIcon.Style.Outlined
        )
        val Gesture = MaterialIcon(
            name = "gesture",
            style = MaterialIcon.Style.Outlined
        )
        val GetApp = MaterialIcon(
            name = "get_app",
            style = MaterialIcon.Style.Outlined
        )
        val Gif = MaterialIcon(
            name = "gif",
            style = MaterialIcon.Style.Outlined
        )
        val GifBox = MaterialIcon(
            name = "gif_box",
            style = MaterialIcon.Style.Outlined
        )
        val Girl = MaterialIcon(
            name = "girl",
            style = MaterialIcon.Style.Outlined
        )
        val Gite = MaterialIcon(
            name = "gite",
            style = MaterialIcon.Style.Outlined
        )
        val GolfCourse = MaterialIcon(
            name = "golf_course",
            style = MaterialIcon.Style.Outlined
        )
        val GppBad = MaterialIcon(
            name = "gpp_bad",
            style = MaterialIcon.Style.Outlined
        )
        val GppGood = MaterialIcon(
            name = "gpp_good",
            style = MaterialIcon.Style.Outlined
        )
        val GppMaybe = MaterialIcon(
            name = "gpp_maybe",
            style = MaterialIcon.Style.Outlined
        )
        val GpsFixed = MaterialIcon(
            name = "gps_fixed",
            style = MaterialIcon.Style.Outlined
        )
        val GpsNotFixed = MaterialIcon(
            name = "gps_not_fixed",
            style = MaterialIcon.Style.Outlined
        )
        val GpsOff = MaterialIcon(
            name = "gps_off",
            style = MaterialIcon.Style.Outlined
        )
        val Grade = MaterialIcon(
            name = "grade",
            style = MaterialIcon.Style.Outlined
        )
        val Gradient = MaterialIcon(
            name = "gradient",
            style = MaterialIcon.Style.Outlined
        )
        val Grading = MaterialIcon(
            name = "grading",
            style = MaterialIcon.Style.Outlined
        )
        val Grain = MaterialIcon(
            name = "grain",
            style = MaterialIcon.Style.Outlined
        )
        val GraphicEq = MaterialIcon(
            name = "graphic_eq",
            style = MaterialIcon.Style.Outlined
        )
        val Grass = MaterialIcon(
            name = "grass",
            style = MaterialIcon.Style.Outlined
        )
        val Grid3x3 = MaterialIcon(
            name = "grid_3x3",
            style = MaterialIcon.Style.Outlined
        )
        val Grid4x4 = MaterialIcon(
            name = "grid_4x4",
            style = MaterialIcon.Style.Outlined
        )
        val GridGoldenratio = MaterialIcon(
            name = "grid_goldenratio",
            style = MaterialIcon.Style.Outlined
        )
        val GridOff = MaterialIcon(
            name = "grid_off",
            style = MaterialIcon.Style.Outlined
        )
        val GridOn = MaterialIcon(
            name = "grid_on",
            style = MaterialIcon.Style.Outlined
        )
        val GridView = MaterialIcon(
            name = "grid_view",
            style = MaterialIcon.Style.Outlined
        )
        val Group = MaterialIcon(
            name = "group",
            style = MaterialIcon.Style.Outlined
        )
        val GroupAdd = MaterialIcon(
            name = "group_add",
            style = MaterialIcon.Style.Outlined
        )
        val GroupOff = MaterialIcon(
            name = "group_off",
            style = MaterialIcon.Style.Outlined
        )
        val GroupRemove = MaterialIcon(
            name = "group_remove",
            style = MaterialIcon.Style.Outlined
        )
        val GroupWork = MaterialIcon(
            name = "group_work",
            style = MaterialIcon.Style.Outlined
        )
        val Groups = MaterialIcon(
            name = "groups",
            style = MaterialIcon.Style.Outlined
        )
        val Groups2 = MaterialIcon(
            name = "groups_2",
            style = MaterialIcon.Style.Outlined
        )
        val Groups3 = MaterialIcon(
            name = "groups_3",
            style = MaterialIcon.Style.Outlined
        )
        val HMobiledata = MaterialIcon(
            name = "h_mobiledata",
            style = MaterialIcon.Style.Outlined
        )
        val HPlusMobiledata = MaterialIcon(
            name = "h_plus_mobiledata",
            style = MaterialIcon.Style.Outlined
        )
        val Hail = MaterialIcon(
            name = "hail",
            style = MaterialIcon.Style.Outlined
        )
        val Handshake = MaterialIcon(
            name = "handshake",
            style = MaterialIcon.Style.Outlined
        )
        val Handyman = MaterialIcon(
            name = "handyman",
            style = MaterialIcon.Style.Outlined
        )
        val Hardware = MaterialIcon(
            name = "hardware",
            style = MaterialIcon.Style.Outlined
        )
        val Hd = MaterialIcon(
            name = "hd",
            style = MaterialIcon.Style.Outlined
        )
        val HdrAuto = MaterialIcon(
            name = "hdr_auto",
            style = MaterialIcon.Style.Outlined
        )
        val HdrAutoSelect = MaterialIcon(
            name = "hdr_auto_select",
            style = MaterialIcon.Style.Outlined
        )
        val HdrEnhancedSelect = MaterialIcon(
            name = "hdr_enhanced_select",
            style = MaterialIcon.Style.Outlined
        )
        val HdrOff = MaterialIcon(
            name = "hdr_off",
            style = MaterialIcon.Style.Outlined
        )
        val HdrOffSelect = MaterialIcon(
            name = "hdr_off_select",
            style = MaterialIcon.Style.Outlined
        )
        val HdrOn = MaterialIcon(
            name = "hdr_on",
            style = MaterialIcon.Style.Outlined
        )
        val HdrOnSelect = MaterialIcon(
            name = "hdr_on_select",
            style = MaterialIcon.Style.Outlined
        )
        val HdrPlus = MaterialIcon(
            name = "hdr_plus",
            style = MaterialIcon.Style.Outlined
        )
        val HdrStrong = MaterialIcon(
            name = "hdr_strong",
            style = MaterialIcon.Style.Outlined
        )
        val HdrWeak = MaterialIcon(
            name = "hdr_weak",
            style = MaterialIcon.Style.Outlined
        )
        val Headphones = MaterialIcon(
            name = "headphones",
            style = MaterialIcon.Style.Outlined
        )
        val HeadphonesBattery = MaterialIcon(
            name = "headphones_battery",
            style = MaterialIcon.Style.Outlined
        )
        val Headset = MaterialIcon(
            name = "headset",
            style = MaterialIcon.Style.Outlined
        )
        val HeadsetMic = MaterialIcon(
            name = "headset_mic",
            style = MaterialIcon.Style.Outlined
        )
        val HeadsetOff = MaterialIcon(
            name = "headset_off",
            style = MaterialIcon.Style.Outlined
        )
        val Healing = MaterialIcon(
            name = "healing",
            style = MaterialIcon.Style.Outlined
        )
        val HealthAndSafety = MaterialIcon(
            name = "health_and_safety",
            style = MaterialIcon.Style.Outlined
        )
        val Hearing = MaterialIcon(
            name = "hearing",
            style = MaterialIcon.Style.Outlined
        )
        val HearingDisabled = MaterialIcon(
            name = "hearing_disabled",
            style = MaterialIcon.Style.Outlined
        )
        val HeartBroken = MaterialIcon(
            name = "heart_broken",
            style = MaterialIcon.Style.Outlined
        )
        val HeatPump = MaterialIcon(
            name = "heat_pump",
            style = MaterialIcon.Style.Outlined
        )
        val Height = MaterialIcon(
            name = "height",
            style = MaterialIcon.Style.Outlined
        )
        val Help = MaterialIcon(
            name = "help",
            style = MaterialIcon.Style.Outlined
        )
        val HelpCenter = MaterialIcon(
            name = "help_center",
            style = MaterialIcon.Style.Outlined
        )
        val HelpOutline = MaterialIcon(
            name = "help_outline",
            style = MaterialIcon.Style.Outlined
        )
        val Hevc = MaterialIcon(
            name = "hevc",
            style = MaterialIcon.Style.Outlined
        )
        val Hexagon = MaterialIcon(
            name = "hexagon",
            style = MaterialIcon.Style.Outlined
        )
        val HideImage = MaterialIcon(
            name = "hide_image",
            style = MaterialIcon.Style.Outlined
        )
        val HideSource = MaterialIcon(
            name = "hide_source",
            style = MaterialIcon.Style.Outlined
        )
        val HighQuality = MaterialIcon(
            name = "high_quality",
            style = MaterialIcon.Style.Outlined
        )
        val Highlight = MaterialIcon(
            name = "highlight",
            style = MaterialIcon.Style.Outlined
        )
        val HighlightAlt = MaterialIcon(
            name = "highlight_alt",
            style = MaterialIcon.Style.Outlined
        )
        val HighlightOff = MaterialIcon(
            name = "highlight_off",
            style = MaterialIcon.Style.Outlined
        )
        val Hiking = MaterialIcon(
            name = "hiking",
            style = MaterialIcon.Style.Outlined
        )
        val History = MaterialIcon(
            name = "history",
            style = MaterialIcon.Style.Outlined
        )
        val HistoryEdu = MaterialIcon(
            name = "history_edu",
            style = MaterialIcon.Style.Outlined
        )
        val HistoryToggleOff = MaterialIcon(
            name = "history_toggle_off",
            style = MaterialIcon.Style.Outlined
        )
        val Hive = MaterialIcon(
            name = "hive",
            style = MaterialIcon.Style.Outlined
        )
        val Hls = MaterialIcon(
            name = "hls",
            style = MaterialIcon.Style.Outlined
        )
        val HlsOff = MaterialIcon(
            name = "hls_off",
            style = MaterialIcon.Style.Outlined
        )
        val HolidayVillage = MaterialIcon(
            name = "holiday_village",
            style = MaterialIcon.Style.Outlined
        )
        val Home = MaterialIcon(
            name = "home",
            style = MaterialIcon.Style.Outlined
        )
        val HomeMax = MaterialIcon(
            name = "home_max",
            style = MaterialIcon.Style.Outlined
        )
        val HomeMini = MaterialIcon(
            name = "home_mini",
            style = MaterialIcon.Style.Outlined
        )
        val HomeRepairService = MaterialIcon(
            name = "home_repair_service",
            style = MaterialIcon.Style.Outlined
        )
        val HomeWork = MaterialIcon(
            name = "home_work",
            style = MaterialIcon.Style.Outlined
        )
        val HorizontalDistribute = MaterialIcon(
            name = "horizontal_distribute",
            style = MaterialIcon.Style.Outlined
        )
        val HorizontalRule = MaterialIcon(
            name = "horizontal_rule",
            style = MaterialIcon.Style.Outlined
        )
        val HorizontalSplit = MaterialIcon(
            name = "horizontal_split",
            style = MaterialIcon.Style.Outlined
        )
        val HotTub = MaterialIcon(
            name = "hot_tub",
            style = MaterialIcon.Style.Outlined
        )
        val Hotel = MaterialIcon(
            name = "hotel",
            style = MaterialIcon.Style.Outlined
        )
        val HotelClass = MaterialIcon(
            name = "hotel_class",
            style = MaterialIcon.Style.Outlined
        )
        val HourglassBottom = MaterialIcon(
            name = "hourglass_bottom",
            style = MaterialIcon.Style.Outlined
        )
        val HourglassDisabled = MaterialIcon(
            name = "hourglass_disabled",
            style = MaterialIcon.Style.Outlined
        )
        val HourglassEmpty = MaterialIcon(
            name = "hourglass_empty",
            style = MaterialIcon.Style.Outlined
        )
        val HourglassFull = MaterialIcon(
            name = "hourglass_full",
            style = MaterialIcon.Style.Outlined
        )
        val HourglassTop = MaterialIcon(
            name = "hourglass_top",
            style = MaterialIcon.Style.Outlined
        )
        val House = MaterialIcon(
            name = "house",
            style = MaterialIcon.Style.Outlined
        )
        val HouseSiding = MaterialIcon(
            name = "house_siding",
            style = MaterialIcon.Style.Outlined
        )
        val Houseboat = MaterialIcon(
            name = "houseboat",
            style = MaterialIcon.Style.Outlined
        )
        val HowToReg = MaterialIcon(
            name = "how_to_reg",
            style = MaterialIcon.Style.Outlined
        )
        val HowToVote = MaterialIcon(
            name = "how_to_vote",
            style = MaterialIcon.Style.Outlined
        )
        val Html = MaterialIcon(
            name = "html",
            style = MaterialIcon.Style.Outlined
        )
        val Http = MaterialIcon(
            name = "http",
            style = MaterialIcon.Style.Outlined
        )
        val Https = MaterialIcon(
            name = "https",
            style = MaterialIcon.Style.Outlined
        )
        val Hub = MaterialIcon(
            name = "hub",
            style = MaterialIcon.Style.Outlined
        )
        val Hvac = MaterialIcon(
            name = "hvac",
            style = MaterialIcon.Style.Outlined
        )
        val IceSkating = MaterialIcon(
            name = "ice_skating",
            style = MaterialIcon.Style.Outlined
        )
        val Icecream = MaterialIcon(
            name = "icecream",
            style = MaterialIcon.Style.Outlined
        )
        val Image = MaterialIcon(
            name = "image",
            style = MaterialIcon.Style.Outlined
        )
        val ImageAspectRatio = MaterialIcon(
            name = "image_aspect_ratio",
            style = MaterialIcon.Style.Outlined
        )
        val ImageNotSupported = MaterialIcon(
            name = "image_not_supported",
            style = MaterialIcon.Style.Outlined
        )
        val ImageSearch = MaterialIcon(
            name = "image_search",
            style = MaterialIcon.Style.Outlined
        )
        val ImagesearchRoller = MaterialIcon(
            name = "imagesearch_roller",
            style = MaterialIcon.Style.Outlined
        )
        val ImportContacts = MaterialIcon(
            name = "import_contacts",
            style = MaterialIcon.Style.Outlined
        )
        val ImportExport = MaterialIcon(
            name = "import_export",
            style = MaterialIcon.Style.Outlined
        )
        val ImportantDevices = MaterialIcon(
            name = "important_devices",
            style = MaterialIcon.Style.Outlined
        )
        val Inbox = MaterialIcon(
            name = "inbox",
            style = MaterialIcon.Style.Outlined
        )
        val IncompleteCircle = MaterialIcon(
            name = "incomplete_circle",
            style = MaterialIcon.Style.Outlined
        )
        val IndeterminateCheckBox = MaterialIcon(
            name = "indeterminate_check_box",
            style = MaterialIcon.Style.Outlined
        )
        val Info = MaterialIcon(
            name = "info",
            style = MaterialIcon.Style.Outlined
        )
        val Input = MaterialIcon(
            name = "input",
            style = MaterialIcon.Style.Outlined
        )
        val InsertChart = MaterialIcon(
            name = "insert_chart",
            style = MaterialIcon.Style.Outlined
        )
        val InsertChartOutlined = MaterialIcon(
            name = "insert_chart_outlined",
            style = MaterialIcon.Style.Outlined
        )
        val InsertComment = MaterialIcon(
            name = "insert_comment",
            style = MaterialIcon.Style.Outlined
        )
        val InsertDriveFile = MaterialIcon(
            name = "insert_drive_file",
            style = MaterialIcon.Style.Outlined
        )
        val InsertEmoticon = MaterialIcon(
            name = "insert_emoticon",
            style = MaterialIcon.Style.Outlined
        )
        val InsertInvitation = MaterialIcon(
            name = "insert_invitation",
            style = MaterialIcon.Style.Outlined
        )
        val InsertLink = MaterialIcon(
            name = "insert_link",
            style = MaterialIcon.Style.Outlined
        )
        val InsertPageBreak = MaterialIcon(
            name = "insert_page_break",
            style = MaterialIcon.Style.Outlined
        )
        val InsertPhoto = MaterialIcon(
            name = "insert_photo",
            style = MaterialIcon.Style.Outlined
        )
        val Insights = MaterialIcon(
            name = "insights",
            style = MaterialIcon.Style.Outlined
        )
        val InstallDesktop = MaterialIcon(
            name = "install_desktop",
            style = MaterialIcon.Style.Outlined
        )
        val InstallMobile = MaterialIcon(
            name = "install_mobile",
            style = MaterialIcon.Style.Outlined
        )
        val IntegrationInstructions = MaterialIcon(
            name = "integration_instructions",
            style = MaterialIcon.Style.Outlined
        )
        val Interests = MaterialIcon(
            name = "interests",
            style = MaterialIcon.Style.Outlined
        )
        val InterpreterMode = MaterialIcon(
            name = "interpreter_mode",
            style = MaterialIcon.Style.Outlined
        )
        val Inventory = MaterialIcon(
            name = "inventory",
            style = MaterialIcon.Style.Outlined
        )
        val Inventory2 = MaterialIcon(
            name = "inventory_2",
            style = MaterialIcon.Style.Outlined
        )
        val InvertColors = MaterialIcon(
            name = "invert_colors",
            style = MaterialIcon.Style.Outlined
        )
        val InvertColorsOff = MaterialIcon(
            name = "invert_colors_off",
            style = MaterialIcon.Style.Outlined
        )
        val IosShare = MaterialIcon(
            name = "ios_share",
            style = MaterialIcon.Style.Outlined
        )
        val Iron = MaterialIcon(
            name = "iron",
            style = MaterialIcon.Style.Outlined
        )
        val Iso = MaterialIcon(
            name = "iso",
            style = MaterialIcon.Style.Outlined
        )
        val Javascript = MaterialIcon(
            name = "javascript",
            style = MaterialIcon.Style.Outlined
        )
        val JoinFull = MaterialIcon(
            name = "join_full",
            style = MaterialIcon.Style.Outlined
        )
        val JoinInner = MaterialIcon(
            name = "join_inner",
            style = MaterialIcon.Style.Outlined
        )
        val JoinLeft = MaterialIcon(
            name = "join_left",
            style = MaterialIcon.Style.Outlined
        )
        val JoinRight = MaterialIcon(
            name = "join_right",
            style = MaterialIcon.Style.Outlined
        )
        val Kayaking = MaterialIcon(
            name = "kayaking",
            style = MaterialIcon.Style.Outlined
        )
        val KebabDining = MaterialIcon(
            name = "kebab_dining",
            style = MaterialIcon.Style.Outlined
        )
        val Key = MaterialIcon(
            name = "key",
            style = MaterialIcon.Style.Outlined
        )
        val KeyOff = MaterialIcon(
            name = "key_off",
            style = MaterialIcon.Style.Outlined
        )
        val Keyboard = MaterialIcon(
            name = "keyboard",
            style = MaterialIcon.Style.Outlined
        )
        val KeyboardAlt = MaterialIcon(
            name = "keyboard_alt",
            style = MaterialIcon.Style.Outlined
        )
        val KeyboardArrowDown = MaterialIcon(
            name = "keyboard_arrow_down",
            style = MaterialIcon.Style.Outlined
        )
        val KeyboardArrowLeft = MaterialIcon(
            name = "keyboard_arrow_left",
            style = MaterialIcon.Style.Outlined
        )
        val KeyboardArrowRight = MaterialIcon(
            name = "keyboard_arrow_right",
            style = MaterialIcon.Style.Outlined
        )
        val KeyboardArrowUp = MaterialIcon(
            name = "keyboard_arrow_up",
            style = MaterialIcon.Style.Outlined
        )
        val KeyboardBackspace = MaterialIcon(
            name = "keyboard_backspace",
            style = MaterialIcon.Style.Outlined
        )
        val KeyboardCapslock = MaterialIcon(
            name = "keyboard_capslock",
            style = MaterialIcon.Style.Outlined
        )
        val KeyboardCommandKey = MaterialIcon(
            name = "keyboard_command_key",
            style = MaterialIcon.Style.Outlined
        )
        val KeyboardControlKey = MaterialIcon(
            name = "keyboard_control_key",
            style = MaterialIcon.Style.Outlined
        )
        val KeyboardDoubleArrowDown = MaterialIcon(
            name = "keyboard_double_arrow_down",
            style = MaterialIcon.Style.Outlined
        )
        val KeyboardDoubleArrowLeft = MaterialIcon(
            name = "keyboard_double_arrow_left",
            style = MaterialIcon.Style.Outlined
        )
        val KeyboardDoubleArrowRight = MaterialIcon(
            name = "keyboard_double_arrow_right",
            style = MaterialIcon.Style.Outlined
        )
        val KeyboardDoubleArrowUp = MaterialIcon(
            name = "keyboard_double_arrow_up",
            style = MaterialIcon.Style.Outlined
        )
        val KeyboardHide = MaterialIcon(
            name = "keyboard_hide",
            style = MaterialIcon.Style.Outlined
        )
        val KeyboardOptionKey = MaterialIcon(
            name = "keyboard_option_key",
            style = MaterialIcon.Style.Outlined
        )
        val KeyboardReturn = MaterialIcon(
            name = "keyboard_return",
            style = MaterialIcon.Style.Outlined
        )
        val KeyboardTab = MaterialIcon(
            name = "keyboard_tab",
            style = MaterialIcon.Style.Outlined
        )
        val KeyboardVoice = MaterialIcon(
            name = "keyboard_voice",
            style = MaterialIcon.Style.Outlined
        )
        val KingBed = MaterialIcon(
            name = "king_bed",
            style = MaterialIcon.Style.Outlined
        )
        val Kitchen = MaterialIcon(
            name = "kitchen",
            style = MaterialIcon.Style.Outlined
        )
        val Kitesurfing = MaterialIcon(
            name = "kitesurfing",
            style = MaterialIcon.Style.Outlined
        )
        val Label = MaterialIcon(
            name = "label",
            style = MaterialIcon.Style.Outlined
        )
        val LabelImportant = MaterialIcon(
            name = "label_important",
            style = MaterialIcon.Style.Outlined
        )
        val LabelOff = MaterialIcon(
            name = "label_off",
            style = MaterialIcon.Style.Outlined
        )
        val Lan = MaterialIcon(
            name = "lan",
            style = MaterialIcon.Style.Outlined
        )
        val Landscape = MaterialIcon(
            name = "landscape",
            style = MaterialIcon.Style.Outlined
        )
        val Landslide = MaterialIcon(
            name = "landslide",
            style = MaterialIcon.Style.Outlined
        )
        val Language = MaterialIcon(
            name = "language",
            style = MaterialIcon.Style.Outlined
        )
        val Laptop = MaterialIcon(
            name = "laptop",
            style = MaterialIcon.Style.Outlined
        )
        val LaptopChromebook = MaterialIcon(
            name = "laptop_chromebook",
            style = MaterialIcon.Style.Outlined
        )
        val LaptopMac = MaterialIcon(
            name = "laptop_mac",
            style = MaterialIcon.Style.Outlined
        )
        val LaptopWindows = MaterialIcon(
            name = "laptop_windows",
            style = MaterialIcon.Style.Outlined
        )
        val LastPage = MaterialIcon(
            name = "last_page",
            style = MaterialIcon.Style.Outlined
        )
        val Launch = MaterialIcon(
            name = "launch",
            style = MaterialIcon.Style.Outlined
        )
        val Layers = MaterialIcon(
            name = "layers",
            style = MaterialIcon.Style.Outlined
        )
        val LayersClear = MaterialIcon(
            name = "layers_clear",
            style = MaterialIcon.Style.Outlined
        )
        val Leaderboard = MaterialIcon(
            name = "leaderboard",
            style = MaterialIcon.Style.Outlined
        )
        val LeakAdd = MaterialIcon(
            name = "leak_add",
            style = MaterialIcon.Style.Outlined
        )
        val LeakRemove = MaterialIcon(
            name = "leak_remove",
            style = MaterialIcon.Style.Outlined
        )
        val LegendToggle = MaterialIcon(
            name = "legend_toggle",
            style = MaterialIcon.Style.Outlined
        )
        val Lens = MaterialIcon(
            name = "lens",
            style = MaterialIcon.Style.Outlined
        )
        val LensBlur = MaterialIcon(
            name = "lens_blur",
            style = MaterialIcon.Style.Outlined
        )
        val LibraryAdd = MaterialIcon(
            name = "library_add",
            style = MaterialIcon.Style.Outlined
        )
        val LibraryAddCheck = MaterialIcon(
            name = "library_add_check",
            style = MaterialIcon.Style.Outlined
        )
        val LibraryBooks = MaterialIcon(
            name = "library_books",
            style = MaterialIcon.Style.Outlined
        )
        val LibraryMusic = MaterialIcon(
            name = "library_music",
            style = MaterialIcon.Style.Outlined
        )
        val Light = MaterialIcon(
            name = "light",
            style = MaterialIcon.Style.Outlined
        )
        val LightMode = MaterialIcon(
            name = "light_mode",
            style = MaterialIcon.Style.Outlined
        )
        val Lightbulb = MaterialIcon(
            name = "lightbulb",
            style = MaterialIcon.Style.Outlined
        )
        val LightbulbCircle = MaterialIcon(
            name = "lightbulb_circle",
            style = MaterialIcon.Style.Outlined
        )
        val LineAxis = MaterialIcon(
            name = "line_axis",
            style = MaterialIcon.Style.Outlined
        )
        val LineStyle = MaterialIcon(
            name = "line_style",
            style = MaterialIcon.Style.Outlined
        )
        val LineWeight = MaterialIcon(
            name = "line_weight",
            style = MaterialIcon.Style.Outlined
        )
        val LinearScale = MaterialIcon(
            name = "linear_scale",
            style = MaterialIcon.Style.Outlined
        )
        val Link = MaterialIcon(
            name = "link",
            style = MaterialIcon.Style.Outlined
        )
        val LinkOff = MaterialIcon(
            name = "link_off",
            style = MaterialIcon.Style.Outlined
        )
        val LinkedCamera = MaterialIcon(
            name = "linked_camera",
            style = MaterialIcon.Style.Outlined
        )
        val Liquor = MaterialIcon(
            name = "liquor",
            style = MaterialIcon.Style.Outlined
        )
        val List = MaterialIcon(
            name = "list",
            style = MaterialIcon.Style.Outlined
        )
        val ListAlt = MaterialIcon(
            name = "list_alt",
            style = MaterialIcon.Style.Outlined
        )
        val LiveHelp = MaterialIcon(
            name = "live_help",
            style = MaterialIcon.Style.Outlined
        )
        val LiveTv = MaterialIcon(
            name = "live_tv",
            style = MaterialIcon.Style.Outlined
        )
        val Living = MaterialIcon(
            name = "living",
            style = MaterialIcon.Style.Outlined
        )
        val LocalActivity = MaterialIcon(
            name = "local_activity",
            style = MaterialIcon.Style.Outlined
        )
        val LocalAirport = MaterialIcon(
            name = "local_airport",
            style = MaterialIcon.Style.Outlined
        )
        val LocalAtm = MaterialIcon(
            name = "local_atm",
            style = MaterialIcon.Style.Outlined
        )
        val LocalBar = MaterialIcon(
            name = "local_bar",
            style = MaterialIcon.Style.Outlined
        )
        val LocalCafe = MaterialIcon(
            name = "local_cafe",
            style = MaterialIcon.Style.Outlined
        )
        val LocalCarWash = MaterialIcon(
            name = "local_car_wash",
            style = MaterialIcon.Style.Outlined
        )
        val LocalConvenienceStore = MaterialIcon(
            name = "local_convenience_store",
            style = MaterialIcon.Style.Outlined
        )
        val LocalDining = MaterialIcon(
            name = "local_dining",
            style = MaterialIcon.Style.Outlined
        )
        val LocalDrink = MaterialIcon(
            name = "local_drink",
            style = MaterialIcon.Style.Outlined
        )
        val LocalFireDepartment = MaterialIcon(
            name = "local_fire_department",
            style = MaterialIcon.Style.Outlined
        )
        val LocalFlorist = MaterialIcon(
            name = "local_florist",
            style = MaterialIcon.Style.Outlined
        )
        val LocalGasStation = MaterialIcon(
            name = "local_gas_station",
            style = MaterialIcon.Style.Outlined
        )
        val LocalGroceryStore = MaterialIcon(
            name = "local_grocery_store",
            style = MaterialIcon.Style.Outlined
        )
        val LocalHospital = MaterialIcon(
            name = "local_hospital",
            style = MaterialIcon.Style.Outlined
        )
        val LocalHotel = MaterialIcon(
            name = "local_hotel",
            style = MaterialIcon.Style.Outlined
        )
        val LocalLaundryService = MaterialIcon(
            name = "local_laundry_service",
            style = MaterialIcon.Style.Outlined
        )
        val LocalLibrary = MaterialIcon(
            name = "local_library",
            style = MaterialIcon.Style.Outlined
        )
        val LocalMall = MaterialIcon(
            name = "local_mall",
            style = MaterialIcon.Style.Outlined
        )
        val LocalMovies = MaterialIcon(
            name = "local_movies",
            style = MaterialIcon.Style.Outlined
        )
        val LocalOffer = MaterialIcon(
            name = "local_offer",
            style = MaterialIcon.Style.Outlined
        )
        val LocalParking = MaterialIcon(
            name = "local_parking",
            style = MaterialIcon.Style.Outlined
        )
        val LocalPharmacy = MaterialIcon(
            name = "local_pharmacy",
            style = MaterialIcon.Style.Outlined
        )
        val LocalPhone = MaterialIcon(
            name = "local_phone",
            style = MaterialIcon.Style.Outlined
        )
        val LocalPizza = MaterialIcon(
            name = "local_pizza",
            style = MaterialIcon.Style.Outlined
        )
        val LocalPlay = MaterialIcon(
            name = "local_play",
            style = MaterialIcon.Style.Outlined
        )
        val LocalPolice = MaterialIcon(
            name = "local_police",
            style = MaterialIcon.Style.Outlined
        )
        val LocalPostOffice = MaterialIcon(
            name = "local_post_office",
            style = MaterialIcon.Style.Outlined
        )
        val LocalPrintshop = MaterialIcon(
            name = "local_printshop",
            style = MaterialIcon.Style.Outlined
        )
        val LocalSee = MaterialIcon(
            name = "local_see",
            style = MaterialIcon.Style.Outlined
        )
        val LocalShipping = MaterialIcon(
            name = "local_shipping",
            style = MaterialIcon.Style.Outlined
        )
        val LocalTaxi = MaterialIcon(
            name = "local_taxi",
            style = MaterialIcon.Style.Outlined
        )
        val LocationCity = MaterialIcon(
            name = "location_city",
            style = MaterialIcon.Style.Outlined
        )
        val LocationDisabled = MaterialIcon(
            name = "location_disabled",
            style = MaterialIcon.Style.Outlined
        )
        val LocationOff = MaterialIcon(
            name = "location_off",
            style = MaterialIcon.Style.Outlined
        )
        val LocationOn = MaterialIcon(
            name = "location_on",
            style = MaterialIcon.Style.Outlined
        )
        val LocationSearching = MaterialIcon(
            name = "location_searching",
            style = MaterialIcon.Style.Outlined
        )
        val Lock = MaterialIcon(
            name = "lock",
            style = MaterialIcon.Style.Outlined
        )
        val LockClock = MaterialIcon(
            name = "lock_clock",
            style = MaterialIcon.Style.Outlined
        )
        val LockOpen = MaterialIcon(
            name = "lock_open",
            style = MaterialIcon.Style.Outlined
        )
        val LockPerson = MaterialIcon(
            name = "lock_person",
            style = MaterialIcon.Style.Outlined
        )
        val LockReset = MaterialIcon(
            name = "lock_reset",
            style = MaterialIcon.Style.Outlined
        )
        val Login = MaterialIcon(
            name = "login",
            style = MaterialIcon.Style.Outlined
        )
        val LogoDev = MaterialIcon(
            name = "logo_dev",
            style = MaterialIcon.Style.Outlined
        )
        val Logout = MaterialIcon(
            name = "logout",
            style = MaterialIcon.Style.Outlined
        )
        val Looks = MaterialIcon(
            name = "looks",
            style = MaterialIcon.Style.Outlined
        )
        val Looks3 = MaterialIcon(
            name = "looks_3",
            style = MaterialIcon.Style.Outlined
        )
        val Looks4 = MaterialIcon(
            name = "looks_4",
            style = MaterialIcon.Style.Outlined
        )
        val Looks5 = MaterialIcon(
            name = "looks_5",
            style = MaterialIcon.Style.Outlined
        )
        val Looks6 = MaterialIcon(
            name = "looks_6",
            style = MaterialIcon.Style.Outlined
        )
        val LooksOne = MaterialIcon(
            name = "looks_one",
            style = MaterialIcon.Style.Outlined
        )
        val LooksTwo = MaterialIcon(
            name = "looks_two",
            style = MaterialIcon.Style.Outlined
        )
        val Loop = MaterialIcon(
            name = "loop",
            style = MaterialIcon.Style.Outlined
        )
        val Loupe = MaterialIcon(
            name = "loupe",
            style = MaterialIcon.Style.Outlined
        )
        val LowPriority = MaterialIcon(
            name = "low_priority",
            style = MaterialIcon.Style.Outlined
        )
        val Loyalty = MaterialIcon(
            name = "loyalty",
            style = MaterialIcon.Style.Outlined
        )
        val LteMobiledata = MaterialIcon(
            name = "lte_mobiledata",
            style = MaterialIcon.Style.Outlined
        )
        val LtePlusMobiledata = MaterialIcon(
            name = "lte_plus_mobiledata",
            style = MaterialIcon.Style.Outlined
        )
        val Luggage = MaterialIcon(
            name = "luggage",
            style = MaterialIcon.Style.Outlined
        )
        val LunchDining = MaterialIcon(
            name = "lunch_dining",
            style = MaterialIcon.Style.Outlined
        )
        val Lyrics = MaterialIcon(
            name = "lyrics",
            style = MaterialIcon.Style.Outlined
        )
        val MacroOff = MaterialIcon(
            name = "macro_off",
            style = MaterialIcon.Style.Outlined
        )
        val Mail = MaterialIcon(
            name = "mail",
            style = MaterialIcon.Style.Outlined
        )
        val MailLock = MaterialIcon(
            name = "mail_lock",
            style = MaterialIcon.Style.Outlined
        )
        val MailOutline = MaterialIcon(
            name = "mail_outline",
            style = MaterialIcon.Style.Outlined
        )
        val Male = MaterialIcon(
            name = "male",
            style = MaterialIcon.Style.Outlined
        )
        val Man = MaterialIcon(
            name = "man",
            style = MaterialIcon.Style.Outlined
        )
        val Man2 = MaterialIcon(
            name = "man_2",
            style = MaterialIcon.Style.Outlined
        )
        val Man3 = MaterialIcon(
            name = "man_3",
            style = MaterialIcon.Style.Outlined
        )
        val Man4 = MaterialIcon(
            name = "man_4",
            style = MaterialIcon.Style.Outlined
        )
        val ManageAccounts = MaterialIcon(
            name = "manage_accounts",
            style = MaterialIcon.Style.Outlined
        )
        val ManageHistory = MaterialIcon(
            name = "manage_history",
            style = MaterialIcon.Style.Outlined
        )
        val ManageSearch = MaterialIcon(
            name = "manage_search",
            style = MaterialIcon.Style.Outlined
        )
        val Map = MaterialIcon(
            name = "map",
            style = MaterialIcon.Style.Outlined
        )
        val MapsHomeWork = MaterialIcon(
            name = "maps_home_work",
            style = MaterialIcon.Style.Outlined
        )
        val MapsUgc = MaterialIcon(
            name = "maps_ugc",
            style = MaterialIcon.Style.Outlined
        )
        val Margin = MaterialIcon(
            name = "margin",
            style = MaterialIcon.Style.Outlined
        )
        val MarkAsUnread = MaterialIcon(
            name = "mark_as_unread",
            style = MaterialIcon.Style.Outlined
        )
        val MarkChatRead = MaterialIcon(
            name = "mark_chat_read",
            style = MaterialIcon.Style.Outlined
        )
        val MarkChatUnread = MaterialIcon(
            name = "mark_chat_unread",
            style = MaterialIcon.Style.Outlined
        )
        val MarkEmailRead = MaterialIcon(
            name = "mark_email_read",
            style = MaterialIcon.Style.Outlined
        )
        val MarkEmailUnread = MaterialIcon(
            name = "mark_email_unread",
            style = MaterialIcon.Style.Outlined
        )
        val MarkUnreadChatAlt = MaterialIcon(
            name = "mark_unread_chat_alt",
            style = MaterialIcon.Style.Outlined
        )
        val Markunread = MaterialIcon(
            name = "markunread",
            style = MaterialIcon.Style.Outlined
        )
        val MarkunreadMailbox = MaterialIcon(
            name = "markunread_mailbox",
            style = MaterialIcon.Style.Outlined
        )
        val Masks = MaterialIcon(
            name = "masks",
            style = MaterialIcon.Style.Outlined
        )
        val Maximize = MaterialIcon(
            name = "maximize",
            style = MaterialIcon.Style.Outlined
        )
        val MediaBluetoothOff = MaterialIcon(
            name = "media_bluetooth_off",
            style = MaterialIcon.Style.Outlined
        )
        val MediaBluetoothOn = MaterialIcon(
            name = "media_bluetooth_on",
            style = MaterialIcon.Style.Outlined
        )
        val Mediation = MaterialIcon(
            name = "mediation",
            style = MaterialIcon.Style.Outlined
        )
        val MedicalInformation = MaterialIcon(
            name = "medical_information",
            style = MaterialIcon.Style.Outlined
        )
        val MedicalServices = MaterialIcon(
            name = "medical_services",
            style = MaterialIcon.Style.Outlined
        )
        val Medication = MaterialIcon(
            name = "medication",
            style = MaterialIcon.Style.Outlined
        )
        val MedicationLiquid = MaterialIcon(
            name = "medication_liquid",
            style = MaterialIcon.Style.Outlined
        )
        val MeetingRoom = MaterialIcon(
            name = "meeting_room",
            style = MaterialIcon.Style.Outlined
        )
        val Memory = MaterialIcon(
            name = "memory",
            style = MaterialIcon.Style.Outlined
        )
        val Menu = MaterialIcon(
            name = "menu",
            style = MaterialIcon.Style.Outlined
        )
        val MenuBook = MaterialIcon(
            name = "menu_book",
            style = MaterialIcon.Style.Outlined
        )
        val MenuOpen = MaterialIcon(
            name = "menu_open",
            style = MaterialIcon.Style.Outlined
        )
        val Merge = MaterialIcon(
            name = "merge",
            style = MaterialIcon.Style.Outlined
        )
        val MergeType = MaterialIcon(
            name = "merge_type",
            style = MaterialIcon.Style.Outlined
        )
        val Message = MaterialIcon(
            name = "message",
            style = MaterialIcon.Style.Outlined
        )
        val Mic = MaterialIcon(
            name = "mic",
            style = MaterialIcon.Style.Outlined
        )
        val MicExternalOff = MaterialIcon(
            name = "mic_external_off",
            style = MaterialIcon.Style.Outlined
        )
        val MicExternalOn = MaterialIcon(
            name = "mic_external_on",
            style = MaterialIcon.Style.Outlined
        )
        val MicNone = MaterialIcon(
            name = "mic_none",
            style = MaterialIcon.Style.Outlined
        )
        val MicOff = MaterialIcon(
            name = "mic_off",
            style = MaterialIcon.Style.Outlined
        )
        val Microwave = MaterialIcon(
            name = "microwave",
            style = MaterialIcon.Style.Outlined
        )
        val MilitaryTech = MaterialIcon(
            name = "military_tech",
            style = MaterialIcon.Style.Outlined
        )
        val Minimize = MaterialIcon(
            name = "minimize",
            style = MaterialIcon.Style.Outlined
        )
        val MinorCrash = MaterialIcon(
            name = "minor_crash",
            style = MaterialIcon.Style.Outlined
        )
        val MiscellaneousServices = MaterialIcon(
            name = "miscellaneous_services",
            style = MaterialIcon.Style.Outlined
        )
        val MissedVideoCall = MaterialIcon(
            name = "missed_video_call",
            style = MaterialIcon.Style.Outlined
        )
        val Mms = MaterialIcon(
            name = "mms",
            style = MaterialIcon.Style.Outlined
        )
        val MobileFriendly = MaterialIcon(
            name = "mobile_friendly",
            style = MaterialIcon.Style.Outlined
        )
        val MobileOff = MaterialIcon(
            name = "mobile_off",
            style = MaterialIcon.Style.Outlined
        )
        val MobileScreenShare = MaterialIcon(
            name = "mobile_screen_share",
            style = MaterialIcon.Style.Outlined
        )
        val MobiledataOff = MaterialIcon(
            name = "mobiledata_off",
            style = MaterialIcon.Style.Outlined
        )
        val Mode = MaterialIcon(
            name = "mode",
            style = MaterialIcon.Style.Outlined
        )
        val ModeComment = MaterialIcon(
            name = "mode_comment",
            style = MaterialIcon.Style.Outlined
        )
        val ModeEdit = MaterialIcon(
            name = "mode_edit",
            style = MaterialIcon.Style.Outlined
        )
        val ModeEditOutline = MaterialIcon(
            name = "mode_edit_outline",
            style = MaterialIcon.Style.Outlined
        )
        val ModeFanOff = MaterialIcon(
            name = "mode_fan_off",
            style = MaterialIcon.Style.Outlined
        )
        val ModeNight = MaterialIcon(
            name = "mode_night",
            style = MaterialIcon.Style.Outlined
        )
        val ModeOfTravel = MaterialIcon(
            name = "mode_of_travel",
            style = MaterialIcon.Style.Outlined
        )
        val ModeStandby = MaterialIcon(
            name = "mode_standby",
            style = MaterialIcon.Style.Outlined
        )
        val ModelTraining = MaterialIcon(
            name = "model_training",
            style = MaterialIcon.Style.Outlined
        )
        val MonetizationOn = MaterialIcon(
            name = "monetization_on",
            style = MaterialIcon.Style.Outlined
        )
        val Money = MaterialIcon(
            name = "money",
            style = MaterialIcon.Style.Outlined
        )
        val MoneyOff = MaterialIcon(
            name = "money_off",
            style = MaterialIcon.Style.Outlined
        )
        val MoneyOffCsred = MaterialIcon(
            name = "money_off_csred",
            style = MaterialIcon.Style.Outlined
        )
        val Monitor = MaterialIcon(
            name = "monitor",
            style = MaterialIcon.Style.Outlined
        )
        val MonitorHeart = MaterialIcon(
            name = "monitor_heart",
            style = MaterialIcon.Style.Outlined
        )
        val MonitorWeight = MaterialIcon(
            name = "monitor_weight",
            style = MaterialIcon.Style.Outlined
        )
        val MonochromePhotos = MaterialIcon(
            name = "monochrome_photos",
            style = MaterialIcon.Style.Outlined
        )
        val Mood = MaterialIcon(
            name = "mood",
            style = MaterialIcon.Style.Outlined
        )
        val MoodBad = MaterialIcon(
            name = "mood_bad",
            style = MaterialIcon.Style.Outlined
        )
        val Moped = MaterialIcon(
            name = "moped",
            style = MaterialIcon.Style.Outlined
        )
        val More = MaterialIcon(
            name = "more",
            style = MaterialIcon.Style.Outlined
        )
        val MoreHoriz = MaterialIcon(
            name = "more_horiz",
            style = MaterialIcon.Style.Outlined
        )
        val MoreTime = MaterialIcon(
            name = "more_time",
            style = MaterialIcon.Style.Outlined
        )
        val MoreVert = MaterialIcon(
            name = "more_vert",
            style = MaterialIcon.Style.Outlined
        )
        val Mosque = MaterialIcon(
            name = "mosque",
            style = MaterialIcon.Style.Outlined
        )
        val MotionPhotosAuto = MaterialIcon(
            name = "motion_photos_auto",
            style = MaterialIcon.Style.Outlined
        )
        val MotionPhotosOff = MaterialIcon(
            name = "motion_photos_off",
            style = MaterialIcon.Style.Outlined
        )
        val MotionPhotosOn = MaterialIcon(
            name = "motion_photos_on",
            style = MaterialIcon.Style.Outlined
        )
        val MotionPhotosPause = MaterialIcon(
            name = "motion_photos_pause",
            style = MaterialIcon.Style.Outlined
        )
        val MotionPhotosPaused = MaterialIcon(
            name = "motion_photos_paused",
            style = MaterialIcon.Style.Outlined
        )
        val Mouse = MaterialIcon(
            name = "mouse",
            style = MaterialIcon.Style.Outlined
        )
        val MoveDown = MaterialIcon(
            name = "move_down",
            style = MaterialIcon.Style.Outlined
        )
        val MoveToInbox = MaterialIcon(
            name = "move_to_inbox",
            style = MaterialIcon.Style.Outlined
        )
        val MoveUp = MaterialIcon(
            name = "move_up",
            style = MaterialIcon.Style.Outlined
        )
        val Movie = MaterialIcon(
            name = "movie",
            style = MaterialIcon.Style.Outlined
        )
        val MovieCreation = MaterialIcon(
            name = "movie_creation",
            style = MaterialIcon.Style.Outlined
        )
        val MovieFilter = MaterialIcon(
            name = "movie_filter",
            style = MaterialIcon.Style.Outlined
        )
        val Moving = MaterialIcon(
            name = "moving",
            style = MaterialIcon.Style.Outlined
        )
        val Mp = MaterialIcon(
            name = "mp",
            style = MaterialIcon.Style.Outlined
        )
        val MultilineChart = MaterialIcon(
            name = "multiline_chart",
            style = MaterialIcon.Style.Outlined
        )
        val MultipleStop = MaterialIcon(
            name = "multiple_stop",
            style = MaterialIcon.Style.Outlined
        )
        val Museum = MaterialIcon(
            name = "museum",
            style = MaterialIcon.Style.Outlined
        )
        val MusicNote = MaterialIcon(
            name = "music_note",
            style = MaterialIcon.Style.Outlined
        )
        val MusicOff = MaterialIcon(
            name = "music_off",
            style = MaterialIcon.Style.Outlined
        )
        val MusicVideo = MaterialIcon(
            name = "music_video",
            style = MaterialIcon.Style.Outlined
        )
        val MyLocation = MaterialIcon(
            name = "my_location",
            style = MaterialIcon.Style.Outlined
        )
        val Nat = MaterialIcon(
            name = "nat",
            style = MaterialIcon.Style.Outlined
        )
        val Nature = MaterialIcon(
            name = "nature",
            style = MaterialIcon.Style.Outlined
        )
        val NaturePeople = MaterialIcon(
            name = "nature_people",
            style = MaterialIcon.Style.Outlined
        )
        val NavigateBefore = MaterialIcon(
            name = "navigate_before",
            style = MaterialIcon.Style.Outlined
        )
        val NavigateNext = MaterialIcon(
            name = "navigate_next",
            style = MaterialIcon.Style.Outlined
        )
        val Navigation = MaterialIcon(
            name = "navigation",
            style = MaterialIcon.Style.Outlined
        )
        val NearMe = MaterialIcon(
            name = "near_me",
            style = MaterialIcon.Style.Outlined
        )
        val NearMeDisabled = MaterialIcon(
            name = "near_me_disabled",
            style = MaterialIcon.Style.Outlined
        )
        val NearbyError = MaterialIcon(
            name = "nearby_error",
            style = MaterialIcon.Style.Outlined
        )
        val NearbyOff = MaterialIcon(
            name = "nearby_off",
            style = MaterialIcon.Style.Outlined
        )
        val NestCamWiredStand = MaterialIcon(
            name = "nest_cam_wired_stand",
            style = MaterialIcon.Style.Outlined
        )
        val NetworkCell = MaterialIcon(
            name = "network_cell",
            style = MaterialIcon.Style.Outlined
        )
        val NetworkCheck = MaterialIcon(
            name = "network_check",
            style = MaterialIcon.Style.Outlined
        )
        val NetworkLocked = MaterialIcon(
            name = "network_locked",
            style = MaterialIcon.Style.Outlined
        )
        val NetworkPing = MaterialIcon(
            name = "network_ping",
            style = MaterialIcon.Style.Outlined
        )
        val NetworkWifi = MaterialIcon(
            name = "network_wifi",
            style = MaterialIcon.Style.Outlined
        )
        val NetworkWifi1Bar = MaterialIcon(
            name = "network_wifi_1_bar",
            style = MaterialIcon.Style.Outlined
        )
        val NetworkWifi2Bar = MaterialIcon(
            name = "network_wifi_2_bar",
            style = MaterialIcon.Style.Outlined
        )
        val NetworkWifi3Bar = MaterialIcon(
            name = "network_wifi_3_bar",
            style = MaterialIcon.Style.Outlined
        )
        val NewLabel = MaterialIcon(
            name = "new_label",
            style = MaterialIcon.Style.Outlined
        )
        val NewReleases = MaterialIcon(
            name = "new_releases",
            style = MaterialIcon.Style.Outlined
        )
        val Newspaper = MaterialIcon(
            name = "newspaper",
            style = MaterialIcon.Style.Outlined
        )
        val NextPlan = MaterialIcon(
            name = "next_plan",
            style = MaterialIcon.Style.Outlined
        )
        val NextWeek = MaterialIcon(
            name = "next_week",
            style = MaterialIcon.Style.Outlined
        )
        val Nfc = MaterialIcon(
            name = "nfc",
            style = MaterialIcon.Style.Outlined
        )
        val NightShelter = MaterialIcon(
            name = "night_shelter",
            style = MaterialIcon.Style.Outlined
        )
        val Nightlife = MaterialIcon(
            name = "nightlife",
            style = MaterialIcon.Style.Outlined
        )
        val Nightlight = MaterialIcon(
            name = "nightlight",
            style = MaterialIcon.Style.Outlined
        )
        val NightlightRound = MaterialIcon(
            name = "nightlight_round",
            style = MaterialIcon.Style.Outlined
        )
        val NightsStay = MaterialIcon(
            name = "nights_stay",
            style = MaterialIcon.Style.Outlined
        )
        val NoAccounts = MaterialIcon(
            name = "no_accounts",
            style = MaterialIcon.Style.Outlined
        )
        val NoAdultContent = MaterialIcon(
            name = "no_adult_content",
            style = MaterialIcon.Style.Outlined
        )
        val NoBackpack = MaterialIcon(
            name = "no_backpack",
            style = MaterialIcon.Style.Outlined
        )
        val NoCell = MaterialIcon(
            name = "no_cell",
            style = MaterialIcon.Style.Outlined
        )
        val NoCrash = MaterialIcon(
            name = "no_crash",
            style = MaterialIcon.Style.Outlined
        )
        val NoDrinks = MaterialIcon(
            name = "no_drinks",
            style = MaterialIcon.Style.Outlined
        )
        val NoEncryption = MaterialIcon(
            name = "no_encryption",
            style = MaterialIcon.Style.Outlined
        )
        val NoEncryptionGmailerrorred = MaterialIcon(
            name = "no_encryption_gmailerrorred",
            style = MaterialIcon.Style.Outlined
        )
        val NoFlash = MaterialIcon(
            name = "no_flash",
            style = MaterialIcon.Style.Outlined
        )
        val NoFood = MaterialIcon(
            name = "no_food",
            style = MaterialIcon.Style.Outlined
        )
        val NoLuggage = MaterialIcon(
            name = "no_luggage",
            style = MaterialIcon.Style.Outlined
        )
        val NoMeals = MaterialIcon(
            name = "no_meals",
            style = MaterialIcon.Style.Outlined
        )
        val NoMeetingRoom = MaterialIcon(
            name = "no_meeting_room",
            style = MaterialIcon.Style.Outlined
        )
        val NoPhotography = MaterialIcon(
            name = "no_photography",
            style = MaterialIcon.Style.Outlined
        )
        val NoSim = MaterialIcon(
            name = "no_sim",
            style = MaterialIcon.Style.Outlined
        )
        val NoStroller = MaterialIcon(
            name = "no_stroller",
            style = MaterialIcon.Style.Outlined
        )
        val NoTransfer = MaterialIcon(
            name = "no_transfer",
            style = MaterialIcon.Style.Outlined
        )
        val NoiseAware = MaterialIcon(
            name = "noise_aware",
            style = MaterialIcon.Style.Outlined
        )
        val NoiseControlOff = MaterialIcon(
            name = "noise_control_off",
            style = MaterialIcon.Style.Outlined
        )
        val NordicWalking = MaterialIcon(
            name = "nordic_walking",
            style = MaterialIcon.Style.Outlined
        )
        val North = MaterialIcon(
            name = "north",
            style = MaterialIcon.Style.Outlined
        )
        val NorthEast = MaterialIcon(
            name = "north_east",
            style = MaterialIcon.Style.Outlined
        )
        val NorthWest = MaterialIcon(
            name = "north_west",
            style = MaterialIcon.Style.Outlined
        )
        val NotAccessible = MaterialIcon(
            name = "not_accessible",
            style = MaterialIcon.Style.Outlined
        )
        val NotInterested = MaterialIcon(
            name = "not_interested",
            style = MaterialIcon.Style.Outlined
        )
        val NotListedLocation = MaterialIcon(
            name = "not_listed_location",
            style = MaterialIcon.Style.Outlined
        )
        val NotStarted = MaterialIcon(
            name = "not_started",
            style = MaterialIcon.Style.Outlined
        )
        val Note = MaterialIcon(
            name = "note",
            style = MaterialIcon.Style.Outlined
        )
        val NoteAdd = MaterialIcon(
            name = "note_add",
            style = MaterialIcon.Style.Outlined
        )
        val NoteAlt = MaterialIcon(
            name = "note_alt",
            style = MaterialIcon.Style.Outlined
        )
        val Notes = MaterialIcon(
            name = "notes",
            style = MaterialIcon.Style.Outlined
        )
        val NotificationAdd = MaterialIcon(
            name = "notification_add",
            style = MaterialIcon.Style.Outlined
        )
        val NotificationImportant = MaterialIcon(
            name = "notification_important",
            style = MaterialIcon.Style.Outlined
        )
        val Notifications = MaterialIcon(
            name = "notifications",
            style = MaterialIcon.Style.Outlined
        )
        val NotificationsActive = MaterialIcon(
            name = "notifications_active",
            style = MaterialIcon.Style.Outlined
        )
        val NotificationsNone = MaterialIcon(
            name = "notifications_none",
            style = MaterialIcon.Style.Outlined
        )
        val NotificationsOff = MaterialIcon(
            name = "notifications_off",
            style = MaterialIcon.Style.Outlined
        )
        val NotificationsPaused = MaterialIcon(
            name = "notifications_paused",
            style = MaterialIcon.Style.Outlined
        )
        val Numbers = MaterialIcon(
            name = "numbers",
            style = MaterialIcon.Style.Outlined
        )
        val OfflineBolt = MaterialIcon(
            name = "offline_bolt",
            style = MaterialIcon.Style.Outlined
        )
        val OfflinePin = MaterialIcon(
            name = "offline_pin",
            style = MaterialIcon.Style.Outlined
        )
        val OfflineShare = MaterialIcon(
            name = "offline_share",
            style = MaterialIcon.Style.Outlined
        )
        val OilBarrel = MaterialIcon(
            name = "oil_barrel",
            style = MaterialIcon.Style.Outlined
        )
        val OnDeviceTraining = MaterialIcon(
            name = "on_device_training",
            style = MaterialIcon.Style.Outlined
        )
        val OndemandVideo = MaterialIcon(
            name = "ondemand_video",
            style = MaterialIcon.Style.Outlined
        )
        val OnlinePrediction = MaterialIcon(
            name = "online_prediction",
            style = MaterialIcon.Style.Outlined
        )
        val Opacity = MaterialIcon(
            name = "opacity",
            style = MaterialIcon.Style.Outlined
        )
        val OpenInBrowser = MaterialIcon(
            name = "open_in_browser",
            style = MaterialIcon.Style.Outlined
        )
        val OpenInFull = MaterialIcon(
            name = "open_in_full",
            style = MaterialIcon.Style.Outlined
        )
        val OpenInNew = MaterialIcon(
            name = "open_in_new",
            style = MaterialIcon.Style.Outlined
        )
        val OpenInNewOff = MaterialIcon(
            name = "open_in_new_off",
            style = MaterialIcon.Style.Outlined
        )
        val OpenWith = MaterialIcon(
            name = "open_with",
            style = MaterialIcon.Style.Outlined
        )
        val OtherHouses = MaterialIcon(
            name = "other_houses",
            style = MaterialIcon.Style.Outlined
        )
        val Outbound = MaterialIcon(
            name = "outbound",
            style = MaterialIcon.Style.Outlined
        )
        val Outbox = MaterialIcon(
            name = "outbox",
            style = MaterialIcon.Style.Outlined
        )
        val OutdoorGrill = MaterialIcon(
            name = "outdoor_grill",
            style = MaterialIcon.Style.Outlined
        )
        val Outlet = MaterialIcon(
            name = "outlet",
            style = MaterialIcon.Style.Outlined
        )
        val OutlinedFlag = MaterialIcon(
            name = "outlined_flag",
            style = MaterialIcon.Style.Outlined
        )
        val Output = MaterialIcon(
            name = "output",
            style = MaterialIcon.Style.Outlined
        )
        val Padding = MaterialIcon(
            name = "padding",
            style = MaterialIcon.Style.Outlined
        )
        val Pages = MaterialIcon(
            name = "pages",
            style = MaterialIcon.Style.Outlined
        )
        val Pageview = MaterialIcon(
            name = "pageview",
            style = MaterialIcon.Style.Outlined
        )
        val Paid = MaterialIcon(
            name = "paid",
            style = MaterialIcon.Style.Outlined
        )
        val Palette = MaterialIcon(
            name = "palette",
            style = MaterialIcon.Style.Outlined
        )
        val PanTool = MaterialIcon(
            name = "pan_tool",
            style = MaterialIcon.Style.Outlined
        )
        val PanToolAlt = MaterialIcon(
            name = "pan_tool_alt",
            style = MaterialIcon.Style.Outlined
        )
        val Panorama = MaterialIcon(
            name = "panorama",
            style = MaterialIcon.Style.Outlined
        )
        val PanoramaFishEye = MaterialIcon(
            name = "panorama_fish_eye",
            style = MaterialIcon.Style.Outlined
        )
        val PanoramaHorizontal = MaterialIcon(
            name = "panorama_horizontal",
            style = MaterialIcon.Style.Outlined
        )
        val PanoramaHorizontalSelect = MaterialIcon(
            name = "panorama_horizontal_select",
            style = MaterialIcon.Style.Outlined
        )
        val PanoramaPhotosphere = MaterialIcon(
            name = "panorama_photosphere",
            style = MaterialIcon.Style.Outlined
        )
        val PanoramaPhotosphereSelect = MaterialIcon(
            name = "panorama_photosphere_select",
            style = MaterialIcon.Style.Outlined
        )
        val PanoramaVertical = MaterialIcon(
            name = "panorama_vertical",
            style = MaterialIcon.Style.Outlined
        )
        val PanoramaVerticalSelect = MaterialIcon(
            name = "panorama_vertical_select",
            style = MaterialIcon.Style.Outlined
        )
        val PanoramaWideAngle = MaterialIcon(
            name = "panorama_wide_angle",
            style = MaterialIcon.Style.Outlined
        )
        val PanoramaWideAngleSelect = MaterialIcon(
            name = "panorama_wide_angle_select",
            style = MaterialIcon.Style.Outlined
        )
        val Paragliding = MaterialIcon(
            name = "paragliding",
            style = MaterialIcon.Style.Outlined
        )
        val Park = MaterialIcon(
            name = "park",
            style = MaterialIcon.Style.Outlined
        )
        val PartyMode = MaterialIcon(
            name = "party_mode",
            style = MaterialIcon.Style.Outlined
        )
        val Password = MaterialIcon(
            name = "password",
            style = MaterialIcon.Style.Outlined
        )
        val Pattern = MaterialIcon(
            name = "pattern",
            style = MaterialIcon.Style.Outlined
        )
        val Pause = MaterialIcon(
            name = "pause",
            style = MaterialIcon.Style.Outlined
        )
        val PauseCircle = MaterialIcon(
            name = "pause_circle",
            style = MaterialIcon.Style.Outlined
        )
        val PauseCircleFilled = MaterialIcon(
            name = "pause_circle_filled",
            style = MaterialIcon.Style.Outlined
        )
        val PauseCircleOutline = MaterialIcon(
            name = "pause_circle_outline",
            style = MaterialIcon.Style.Outlined
        )
        val PausePresentation = MaterialIcon(
            name = "pause_presentation",
            style = MaterialIcon.Style.Outlined
        )
        val Payment = MaterialIcon(
            name = "payment",
            style = MaterialIcon.Style.Outlined
        )
        val Payments = MaterialIcon(
            name = "payments",
            style = MaterialIcon.Style.Outlined
        )
        val PedalBike = MaterialIcon(
            name = "pedal_bike",
            style = MaterialIcon.Style.Outlined
        )
        val Pending = MaterialIcon(
            name = "pending",
            style = MaterialIcon.Style.Outlined
        )
        val PendingActions = MaterialIcon(
            name = "pending_actions",
            style = MaterialIcon.Style.Outlined
        )
        val Pentagon = MaterialIcon(
            name = "pentagon",
            style = MaterialIcon.Style.Outlined
        )
        val People = MaterialIcon(
            name = "people",
            style = MaterialIcon.Style.Outlined
        )
        val PeopleAlt = MaterialIcon(
            name = "people_alt",
            style = MaterialIcon.Style.Outlined
        )
        val PeopleOutline = MaterialIcon(
            name = "people_outline",
            style = MaterialIcon.Style.Outlined
        )
        val Percent = MaterialIcon(
            name = "percent",
            style = MaterialIcon.Style.Outlined
        )
        val PermCameraMic = MaterialIcon(
            name = "perm_camera_mic",
            style = MaterialIcon.Style.Outlined
        )
        val PermContactCalendar = MaterialIcon(
            name = "perm_contact_calendar",
            style = MaterialIcon.Style.Outlined
        )
        val PermDataSetting = MaterialIcon(
            name = "perm_data_setting",
            style = MaterialIcon.Style.Outlined
        )
        val PermDeviceInformation = MaterialIcon(
            name = "perm_device_information",
            style = MaterialIcon.Style.Outlined
        )
        val PermIdentity = MaterialIcon(
            name = "perm_identity",
            style = MaterialIcon.Style.Outlined
        )
        val PermMedia = MaterialIcon(
            name = "perm_media",
            style = MaterialIcon.Style.Outlined
        )
        val PermPhoneMsg = MaterialIcon(
            name = "perm_phone_msg",
            style = MaterialIcon.Style.Outlined
        )
        val PermScanWifi = MaterialIcon(
            name = "perm_scan_wifi",
            style = MaterialIcon.Style.Outlined
        )
        val Person = MaterialIcon(
            name = "person",
            style = MaterialIcon.Style.Outlined
        )
        val Person2 = MaterialIcon(
            name = "person_2",
            style = MaterialIcon.Style.Outlined
        )
        val Person3 = MaterialIcon(
            name = "person_3",
            style = MaterialIcon.Style.Outlined
        )
        val Person4 = MaterialIcon(
            name = "person_4",
            style = MaterialIcon.Style.Outlined
        )
        val PersonAdd = MaterialIcon(
            name = "person_add",
            style = MaterialIcon.Style.Outlined
        )
        val PersonAddAlt = MaterialIcon(
            name = "person_add_alt",
            style = MaterialIcon.Style.Outlined
        )
        val PersonAddAlt1 = MaterialIcon(
            name = "person_add_alt_1",
            style = MaterialIcon.Style.Outlined
        )
        val PersonAddDisabled = MaterialIcon(
            name = "person_add_disabled",
            style = MaterialIcon.Style.Outlined
        )
        val PersonOff = MaterialIcon(
            name = "person_off",
            style = MaterialIcon.Style.Outlined
        )
        val PersonOutline = MaterialIcon(
            name = "person_outline",
            style = MaterialIcon.Style.Outlined
        )
        val PersonPin = MaterialIcon(
            name = "person_pin",
            style = MaterialIcon.Style.Outlined
        )
        val PersonPinCircle = MaterialIcon(
            name = "person_pin_circle",
            style = MaterialIcon.Style.Outlined
        )
        val PersonRemove = MaterialIcon(
            name = "person_remove",
            style = MaterialIcon.Style.Outlined
        )
        val PersonRemoveAlt1 = MaterialIcon(
            name = "person_remove_alt_1",
            style = MaterialIcon.Style.Outlined
        )
        val PersonSearch = MaterialIcon(
            name = "person_search",
            style = MaterialIcon.Style.Outlined
        )
        val PersonalInjury = MaterialIcon(
            name = "personal_injury",
            style = MaterialIcon.Style.Outlined
        )
        val PersonalVideo = MaterialIcon(
            name = "personal_video",
            style = MaterialIcon.Style.Outlined
        )
        val PestControl = MaterialIcon(
            name = "pest_control",
            style = MaterialIcon.Style.Outlined
        )
        val PestControlRodent = MaterialIcon(
            name = "pest_control_rodent",
            style = MaterialIcon.Style.Outlined
        )
        val Pets = MaterialIcon(
            name = "pets",
            style = MaterialIcon.Style.Outlined
        )
        val Phishing = MaterialIcon(
            name = "phishing",
            style = MaterialIcon.Style.Outlined
        )
        val Phone = MaterialIcon(
            name = "phone",
            style = MaterialIcon.Style.Outlined
        )
        val PhoneAndroid = MaterialIcon(
            name = "phone_android",
            style = MaterialIcon.Style.Outlined
        )
        val PhoneBluetoothSpeaker = MaterialIcon(
            name = "phone_bluetooth_speaker",
            style = MaterialIcon.Style.Outlined
        )
        val PhoneCallback = MaterialIcon(
            name = "phone_callback",
            style = MaterialIcon.Style.Outlined
        )
        val PhoneDisabled = MaterialIcon(
            name = "phone_disabled",
            style = MaterialIcon.Style.Outlined
        )
        val PhoneEnabled = MaterialIcon(
            name = "phone_enabled",
            style = MaterialIcon.Style.Outlined
        )
        val PhoneForwarded = MaterialIcon(
            name = "phone_forwarded",
            style = MaterialIcon.Style.Outlined
        )
        val PhoneIphone = MaterialIcon(
            name = "phone_iphone",
            style = MaterialIcon.Style.Outlined
        )
        val PhoneLocked = MaterialIcon(
            name = "phone_locked",
            style = MaterialIcon.Style.Outlined
        )
        val PhoneMissed = MaterialIcon(
            name = "phone_missed",
            style = MaterialIcon.Style.Outlined
        )
        val PhonePaused = MaterialIcon(
            name = "phone_paused",
            style = MaterialIcon.Style.Outlined
        )
        val Phonelink = MaterialIcon(
            name = "phonelink",
            style = MaterialIcon.Style.Outlined
        )
        val PhonelinkErase = MaterialIcon(
            name = "phonelink_erase",
            style = MaterialIcon.Style.Outlined
        )
        val PhonelinkLock = MaterialIcon(
            name = "phonelink_lock",
            style = MaterialIcon.Style.Outlined
        )
        val PhonelinkOff = MaterialIcon(
            name = "phonelink_off",
            style = MaterialIcon.Style.Outlined
        )
        val PhonelinkRing = MaterialIcon(
            name = "phonelink_ring",
            style = MaterialIcon.Style.Outlined
        )
        val PhonelinkSetup = MaterialIcon(
            name = "phonelink_setup",
            style = MaterialIcon.Style.Outlined
        )
        val Photo = MaterialIcon(
            name = "photo",
            style = MaterialIcon.Style.Outlined
        )
        val PhotoAlbum = MaterialIcon(
            name = "photo_album",
            style = MaterialIcon.Style.Outlined
        )
        val PhotoCamera = MaterialIcon(
            name = "photo_camera",
            style = MaterialIcon.Style.Outlined
        )
        val PhotoCameraBack = MaterialIcon(
            name = "photo_camera_back",
            style = MaterialIcon.Style.Outlined
        )
        val PhotoCameraFront = MaterialIcon(
            name = "photo_camera_front",
            style = MaterialIcon.Style.Outlined
        )
        val PhotoFilter = MaterialIcon(
            name = "photo_filter",
            style = MaterialIcon.Style.Outlined
        )
        val PhotoLibrary = MaterialIcon(
            name = "photo_library",
            style = MaterialIcon.Style.Outlined
        )
        val PhotoSizeSelectActual = MaterialIcon(
            name = "photo_size_select_actual",
            style = MaterialIcon.Style.Outlined
        )
        val PhotoSizeSelectLarge = MaterialIcon(
            name = "photo_size_select_large",
            style = MaterialIcon.Style.Outlined
        )
        val PhotoSizeSelectSmall = MaterialIcon(
            name = "photo_size_select_small",
            style = MaterialIcon.Style.Outlined
        )
        val Php = MaterialIcon(
            name = "php",
            style = MaterialIcon.Style.Outlined
        )
        val Piano = MaterialIcon(
            name = "piano",
            style = MaterialIcon.Style.Outlined
        )
        val PianoOff = MaterialIcon(
            name = "piano_off",
            style = MaterialIcon.Style.Outlined
        )
        val PictureAsPdf = MaterialIcon(
            name = "picture_as_pdf",
            style = MaterialIcon.Style.Outlined
        )
        val PictureInPicture = MaterialIcon(
            name = "picture_in_picture",
            style = MaterialIcon.Style.Outlined
        )
        val PictureInPictureAlt = MaterialIcon(
            name = "picture_in_picture_alt",
            style = MaterialIcon.Style.Outlined
        )
        val PieChart = MaterialIcon(
            name = "pie_chart",
            style = MaterialIcon.Style.Outlined
        )
        val PieChartOutline = MaterialIcon(
            name = "pie_chart_outline",
            style = MaterialIcon.Style.Outlined
        )
        val Pin = MaterialIcon(
            name = "pin",
            style = MaterialIcon.Style.Outlined
        )
        val PinDrop = MaterialIcon(
            name = "pin_drop",
            style = MaterialIcon.Style.Outlined
        )
        val PinEnd = MaterialIcon(
            name = "pin_end",
            style = MaterialIcon.Style.Outlined
        )
        val PinInvoke = MaterialIcon(
            name = "pin_invoke",
            style = MaterialIcon.Style.Outlined
        )
        val Pinch = MaterialIcon(
            name = "pinch",
            style = MaterialIcon.Style.Outlined
        )
        val PivotTableChart = MaterialIcon(
            name = "pivot_table_chart",
            style = MaterialIcon.Style.Outlined
        )
        val Pix = MaterialIcon(
            name = "pix",
            style = MaterialIcon.Style.Outlined
        )
        val Place = MaterialIcon(
            name = "place",
            style = MaterialIcon.Style.Outlined
        )
        val Plagiarism = MaterialIcon(
            name = "plagiarism",
            style = MaterialIcon.Style.Outlined
        )
        val PlayArrow = MaterialIcon(
            name = "play_arrow",
            style = MaterialIcon.Style.Outlined
        )
        val PlayCircle = MaterialIcon(
            name = "play_circle",
            style = MaterialIcon.Style.Outlined
        )
        val PlayCircleFilled = MaterialIcon(
            name = "play_circle_filled",
            style = MaterialIcon.Style.Outlined
        )
        val PlayCircleOutline = MaterialIcon(
            name = "play_circle_outline",
            style = MaterialIcon.Style.Outlined
        )
        val PlayDisabled = MaterialIcon(
            name = "play_disabled",
            style = MaterialIcon.Style.Outlined
        )
        val PlayForWork = MaterialIcon(
            name = "play_for_work",
            style = MaterialIcon.Style.Outlined
        )
        val PlayLesson = MaterialIcon(
            name = "play_lesson",
            style = MaterialIcon.Style.Outlined
        )
        val PlaylistAdd = MaterialIcon(
            name = "playlist_add",
            style = MaterialIcon.Style.Outlined
        )
        val PlaylistAddCheck = MaterialIcon(
            name = "playlist_add_check",
            style = MaterialIcon.Style.Outlined
        )
        val PlaylistAddCheckCircle = MaterialIcon(
            name = "playlist_add_check_circle",
            style = MaterialIcon.Style.Outlined
        )
        val PlaylistAddCircle = MaterialIcon(
            name = "playlist_add_circle",
            style = MaterialIcon.Style.Outlined
        )
        val PlaylistPlay = MaterialIcon(
            name = "playlist_play",
            style = MaterialIcon.Style.Outlined
        )
        val PlaylistRemove = MaterialIcon(
            name = "playlist_remove",
            style = MaterialIcon.Style.Outlined
        )
        val Plumbing = MaterialIcon(
            name = "plumbing",
            style = MaterialIcon.Style.Outlined
        )
        val PlusOne = MaterialIcon(
            name = "plus_one",
            style = MaterialIcon.Style.Outlined
        )
        val Podcasts = MaterialIcon(
            name = "podcasts",
            style = MaterialIcon.Style.Outlined
        )
        val PointOfSale = MaterialIcon(
            name = "point_of_sale",
            style = MaterialIcon.Style.Outlined
        )
        val Policy = MaterialIcon(
            name = "policy",
            style = MaterialIcon.Style.Outlined
        )
        val Poll = MaterialIcon(
            name = "poll",
            style = MaterialIcon.Style.Outlined
        )
        val Polyline = MaterialIcon(
            name = "polyline",
            style = MaterialIcon.Style.Outlined
        )
        val Polymer = MaterialIcon(
            name = "polymer",
            style = MaterialIcon.Style.Outlined
        )
        val Pool = MaterialIcon(
            name = "pool",
            style = MaterialIcon.Style.Outlined
        )
        val PortableWifiOff = MaterialIcon(
            name = "portable_wifi_off",
            style = MaterialIcon.Style.Outlined
        )
        val Portrait = MaterialIcon(
            name = "portrait",
            style = MaterialIcon.Style.Outlined
        )
        val PostAdd = MaterialIcon(
            name = "post_add",
            style = MaterialIcon.Style.Outlined
        )
        val Power = MaterialIcon(
            name = "power",
            style = MaterialIcon.Style.Outlined
        )
        val PowerInput = MaterialIcon(
            name = "power_input",
            style = MaterialIcon.Style.Outlined
        )
        val PowerOff = MaterialIcon(
            name = "power_off",
            style = MaterialIcon.Style.Outlined
        )
        val PowerSettingsNew = MaterialIcon(
            name = "power_settings_new",
            style = MaterialIcon.Style.Outlined
        )
        val PrecisionManufacturing = MaterialIcon(
            name = "precision_manufacturing",
            style = MaterialIcon.Style.Outlined
        )
        val PregnantWoman = MaterialIcon(
            name = "pregnant_woman",
            style = MaterialIcon.Style.Outlined
        )
        val PresentToAll = MaterialIcon(
            name = "present_to_all",
            style = MaterialIcon.Style.Outlined
        )
        val Preview = MaterialIcon(
            name = "preview",
            style = MaterialIcon.Style.Outlined
        )
        val PriceChange = MaterialIcon(
            name = "price_change",
            style = MaterialIcon.Style.Outlined
        )
        val PriceCheck = MaterialIcon(
            name = "price_check",
            style = MaterialIcon.Style.Outlined
        )
        val Print = MaterialIcon(
            name = "print",
            style = MaterialIcon.Style.Outlined
        )
        val PrintDisabled = MaterialIcon(
            name = "print_disabled",
            style = MaterialIcon.Style.Outlined
        )
        val PriorityHigh = MaterialIcon(
            name = "priority_high",
            style = MaterialIcon.Style.Outlined
        )
        val PrivacyTip = MaterialIcon(
            name = "privacy_tip",
            style = MaterialIcon.Style.Outlined
        )
        val PrivateConnectivity = MaterialIcon(
            name = "private_connectivity",
            style = MaterialIcon.Style.Outlined
        )
        val ProductionQuantityLimits = MaterialIcon(
            name = "production_quantity_limits",
            style = MaterialIcon.Style.Outlined
        )
        val Propane = MaterialIcon(
            name = "propane",
            style = MaterialIcon.Style.Outlined
        )
        val PropaneTank = MaterialIcon(
            name = "propane_tank",
            style = MaterialIcon.Style.Outlined
        )
        val Psychology = MaterialIcon(
            name = "psychology",
            style = MaterialIcon.Style.Outlined
        )
        val PsychologyAlt = MaterialIcon(
            name = "psychology_alt",
            style = MaterialIcon.Style.Outlined
        )
        val Public = MaterialIcon(
            name = "public",
            style = MaterialIcon.Style.Outlined
        )
        val PublicOff = MaterialIcon(
            name = "public_off",
            style = MaterialIcon.Style.Outlined
        )
        val Publish = MaterialIcon(
            name = "publish",
            style = MaterialIcon.Style.Outlined
        )
        val PublishedWithChanges = MaterialIcon(
            name = "published_with_changes",
            style = MaterialIcon.Style.Outlined
        )
        val PunchClock = MaterialIcon(
            name = "punch_clock",
            style = MaterialIcon.Style.Outlined
        )
        val PushPin = MaterialIcon(
            name = "push_pin",
            style = MaterialIcon.Style.Outlined
        )
        val QrCode = MaterialIcon(
            name = "qr_code",
            style = MaterialIcon.Style.Outlined
        )
        val QrCode2 = MaterialIcon(
            name = "qr_code_2",
            style = MaterialIcon.Style.Outlined
        )
        val QrCodeScanner = MaterialIcon(
            name = "qr_code_scanner",
            style = MaterialIcon.Style.Outlined
        )
        val QueryBuilder = MaterialIcon(
            name = "query_builder",
            style = MaterialIcon.Style.Outlined
        )
        val QueryStats = MaterialIcon(
            name = "query_stats",
            style = MaterialIcon.Style.Outlined
        )
        val QuestionAnswer = MaterialIcon(
            name = "question_answer",
            style = MaterialIcon.Style.Outlined
        )
        val QuestionMark = MaterialIcon(
            name = "question_mark",
            style = MaterialIcon.Style.Outlined
        )
        val Queue = MaterialIcon(
            name = "queue",
            style = MaterialIcon.Style.Outlined
        )
        val QueueMusic = MaterialIcon(
            name = "queue_music",
            style = MaterialIcon.Style.Outlined
        )
        val QueuePlayNext = MaterialIcon(
            name = "queue_play_next",
            style = MaterialIcon.Style.Outlined
        )
        val Quickreply = MaterialIcon(
            name = "quickreply",
            style = MaterialIcon.Style.Outlined
        )
        val Quiz = MaterialIcon(
            name = "quiz",
            style = MaterialIcon.Style.Outlined
        )
        val RMobiledata = MaterialIcon(
            name = "r_mobiledata",
            style = MaterialIcon.Style.Outlined
        )
        val Radar = MaterialIcon(
            name = "radar",
            style = MaterialIcon.Style.Outlined
        )
        val Radio = MaterialIcon(
            name = "radio",
            style = MaterialIcon.Style.Outlined
        )
        val RadioButtonChecked = MaterialIcon(
            name = "radio_button_checked",
            style = MaterialIcon.Style.Outlined
        )
        val RadioButtonUnchecked = MaterialIcon(
            name = "radio_button_unchecked",
            style = MaterialIcon.Style.Outlined
        )
        val RailwayAlert = MaterialIcon(
            name = "railway_alert",
            style = MaterialIcon.Style.Outlined
        )
        val RamenDining = MaterialIcon(
            name = "ramen_dining",
            style = MaterialIcon.Style.Outlined
        )
        val RampLeft = MaterialIcon(
            name = "ramp_left",
            style = MaterialIcon.Style.Outlined
        )
        val RampRight = MaterialIcon(
            name = "ramp_right",
            style = MaterialIcon.Style.Outlined
        )
        val RateReview = MaterialIcon(
            name = "rate_review",
            style = MaterialIcon.Style.Outlined
        )
        val RawOff = MaterialIcon(
            name = "raw_off",
            style = MaterialIcon.Style.Outlined
        )
        val RawOn = MaterialIcon(
            name = "raw_on",
            style = MaterialIcon.Style.Outlined
        )
        val ReadMore = MaterialIcon(
            name = "read_more",
            style = MaterialIcon.Style.Outlined
        )
        val RealEstateAgent = MaterialIcon(
            name = "real_estate_agent",
            style = MaterialIcon.Style.Outlined
        )
        val Receipt = MaterialIcon(
            name = "receipt",
            style = MaterialIcon.Style.Outlined
        )
        val ReceiptLong = MaterialIcon(
            name = "receipt_long",
            style = MaterialIcon.Style.Outlined
        )
        val RecentActors = MaterialIcon(
            name = "recent_actors",
            style = MaterialIcon.Style.Outlined
        )
        val Recommend = MaterialIcon(
            name = "recommend",
            style = MaterialIcon.Style.Outlined
        )
        val RecordVoiceOver = MaterialIcon(
            name = "record_voice_over",
            style = MaterialIcon.Style.Outlined
        )
        val Rectangle = MaterialIcon(
            name = "rectangle",
            style = MaterialIcon.Style.Outlined
        )
        val Recycling = MaterialIcon(
            name = "recycling",
            style = MaterialIcon.Style.Outlined
        )
        val Redeem = MaterialIcon(
            name = "redeem",
            style = MaterialIcon.Style.Outlined
        )
        val Redo = MaterialIcon(
            name = "redo",
            style = MaterialIcon.Style.Outlined
        )
        val ReduceCapacity = MaterialIcon(
            name = "reduce_capacity",
            style = MaterialIcon.Style.Outlined
        )
        val Refresh = MaterialIcon(
            name = "refresh",
            style = MaterialIcon.Style.Outlined
        )
        val RememberMe = MaterialIcon(
            name = "remember_me",
            style = MaterialIcon.Style.Outlined
        )
        val Remove = MaterialIcon(
            name = "remove",
            style = MaterialIcon.Style.Outlined
        )
        val RemoveCircle = MaterialIcon(
            name = "remove_circle",
            style = MaterialIcon.Style.Outlined
        )
        val RemoveCircleOutline = MaterialIcon(
            name = "remove_circle_outline",
            style = MaterialIcon.Style.Outlined
        )
        val RemoveDone = MaterialIcon(
            name = "remove_done",
            style = MaterialIcon.Style.Outlined
        )
        val RemoveFromQueue = MaterialIcon(
            name = "remove_from_queue",
            style = MaterialIcon.Style.Outlined
        )
        val RemoveModerator = MaterialIcon(
            name = "remove_moderator",
            style = MaterialIcon.Style.Outlined
        )
        val RemoveRedEye = MaterialIcon(
            name = "remove_red_eye",
            style = MaterialIcon.Style.Outlined
        )
        val RemoveRoad = MaterialIcon(
            name = "remove_road",
            style = MaterialIcon.Style.Outlined
        )
        val RemoveShoppingCart = MaterialIcon(
            name = "remove_shopping_cart",
            style = MaterialIcon.Style.Outlined
        )
        val Reorder = MaterialIcon(
            name = "reorder",
            style = MaterialIcon.Style.Outlined
        )
        val Repartition = MaterialIcon(
            name = "repartition",
            style = MaterialIcon.Style.Outlined
        )
        val Repeat = MaterialIcon(
            name = "repeat",
            style = MaterialIcon.Style.Outlined
        )
        val RepeatOn = MaterialIcon(
            name = "repeat_on",
            style = MaterialIcon.Style.Outlined
        )
        val RepeatOne = MaterialIcon(
            name = "repeat_one",
            style = MaterialIcon.Style.Outlined
        )
        val RepeatOneOn = MaterialIcon(
            name = "repeat_one_on",
            style = MaterialIcon.Style.Outlined
        )
        val Replay = MaterialIcon(
            name = "replay",
            style = MaterialIcon.Style.Outlined
        )
        val Replay10 = MaterialIcon(
            name = "replay_10",
            style = MaterialIcon.Style.Outlined
        )
        val Replay30 = MaterialIcon(
            name = "replay_30",
            style = MaterialIcon.Style.Outlined
        )
        val Replay5 = MaterialIcon(
            name = "replay_5",
            style = MaterialIcon.Style.Outlined
        )
        val ReplayCircleFilled = MaterialIcon(
            name = "replay_circle_filled",
            style = MaterialIcon.Style.Outlined
        )
        val Reply = MaterialIcon(
            name = "reply",
            style = MaterialIcon.Style.Outlined
        )
        val ReplyAll = MaterialIcon(
            name = "reply_all",
            style = MaterialIcon.Style.Outlined
        )
        val Report = MaterialIcon(
            name = "report",
            style = MaterialIcon.Style.Outlined
        )
        val ReportGmailerrorred = MaterialIcon(
            name = "report_gmailerrorred",
            style = MaterialIcon.Style.Outlined
        )
        val ReportOff = MaterialIcon(
            name = "report_off",
            style = MaterialIcon.Style.Outlined
        )
        val ReportProblem = MaterialIcon(
            name = "report_problem",
            style = MaterialIcon.Style.Outlined
        )
        val RequestPage = MaterialIcon(
            name = "request_page",
            style = MaterialIcon.Style.Outlined
        )
        val RequestQuote = MaterialIcon(
            name = "request_quote",
            style = MaterialIcon.Style.Outlined
        )
        val ResetTv = MaterialIcon(
            name = "reset_tv",
            style = MaterialIcon.Style.Outlined
        )
        val RestartAlt = MaterialIcon(
            name = "restart_alt",
            style = MaterialIcon.Style.Outlined
        )
        val Restaurant = MaterialIcon(
            name = "restaurant",
            style = MaterialIcon.Style.Outlined
        )
        val RestaurantMenu = MaterialIcon(
            name = "restaurant_menu",
            style = MaterialIcon.Style.Outlined
        )
        val Restore = MaterialIcon(
            name = "restore",
            style = MaterialIcon.Style.Outlined
        )
        val RestoreFromTrash = MaterialIcon(
            name = "restore_from_trash",
            style = MaterialIcon.Style.Outlined
        )
        val RestorePage = MaterialIcon(
            name = "restore_page",
            style = MaterialIcon.Style.Outlined
        )
        val Reviews = MaterialIcon(
            name = "reviews",
            style = MaterialIcon.Style.Outlined
        )
        val RiceBowl = MaterialIcon(
            name = "rice_bowl",
            style = MaterialIcon.Style.Outlined
        )
        val RingVolume = MaterialIcon(
            name = "ring_volume",
            style = MaterialIcon.Style.Outlined
        )
        val Rocket = MaterialIcon(
            name = "rocket",
            style = MaterialIcon.Style.Outlined
        )
        val RocketLaunch = MaterialIcon(
            name = "rocket_launch",
            style = MaterialIcon.Style.Outlined
        )
        val RollerShades = MaterialIcon(
            name = "roller_shades",
            style = MaterialIcon.Style.Outlined
        )
        val RollerShadesClosed = MaterialIcon(
            name = "roller_shades_closed",
            style = MaterialIcon.Style.Outlined
        )
        val RollerSkating = MaterialIcon(
            name = "roller_skating",
            style = MaterialIcon.Style.Outlined
        )
        val Roofing = MaterialIcon(
            name = "roofing",
            style = MaterialIcon.Style.Outlined
        )
        val Room = MaterialIcon(
            name = "room",
            style = MaterialIcon.Style.Outlined
        )
        val RoomPreferences = MaterialIcon(
            name = "room_preferences",
            style = MaterialIcon.Style.Outlined
        )
        val RoomService = MaterialIcon(
            name = "room_service",
            style = MaterialIcon.Style.Outlined
        )
        val Rotate90DegreesCcw = MaterialIcon(
            name = "rotate_90_degrees_ccw",
            style = MaterialIcon.Style.Outlined
        )
        val Rotate90DegreesCw = MaterialIcon(
            name = "rotate_90_degrees_cw",
            style = MaterialIcon.Style.Outlined
        )
        val RotateLeft = MaterialIcon(
            name = "rotate_left",
            style = MaterialIcon.Style.Outlined
        )
        val RotateRight = MaterialIcon(
            name = "rotate_right",
            style = MaterialIcon.Style.Outlined
        )
        val RoundaboutLeft = MaterialIcon(
            name = "roundabout_left",
            style = MaterialIcon.Style.Outlined
        )
        val RoundaboutRight = MaterialIcon(
            name = "roundabout_right",
            style = MaterialIcon.Style.Outlined
        )
        val RoundedCorner = MaterialIcon(
            name = "rounded_corner",
            style = MaterialIcon.Style.Outlined
        )
        val Route = MaterialIcon(
            name = "route",
            style = MaterialIcon.Style.Outlined
        )
        val Router = MaterialIcon(
            name = "router",
            style = MaterialIcon.Style.Outlined
        )
        val Rowing = MaterialIcon(
            name = "rowing",
            style = MaterialIcon.Style.Outlined
        )
        val RssFeed = MaterialIcon(
            name = "rss_feed",
            style = MaterialIcon.Style.Outlined
        )
        val Rsvp = MaterialIcon(
            name = "rsvp",
            style = MaterialIcon.Style.Outlined
        )
        val Rtt = MaterialIcon(
            name = "rtt",
            style = MaterialIcon.Style.Outlined
        )
        val Rule = MaterialIcon(
            name = "rule",
            style = MaterialIcon.Style.Outlined
        )
        val RuleFolder = MaterialIcon(
            name = "rule_folder",
            style = MaterialIcon.Style.Outlined
        )
        val RunCircle = MaterialIcon(
            name = "run_circle",
            style = MaterialIcon.Style.Outlined
        )
        val RunningWithErrors = MaterialIcon(
            name = "running_with_errors",
            style = MaterialIcon.Style.Outlined
        )
        val RvHookup = MaterialIcon(
            name = "rv_hookup",
            style = MaterialIcon.Style.Outlined
        )
        val SafetyCheck = MaterialIcon(
            name = "safety_check",
            style = MaterialIcon.Style.Outlined
        )
        val SafetyDivider = MaterialIcon(
            name = "safety_divider",
            style = MaterialIcon.Style.Outlined
        )
        val Sailing = MaterialIcon(
            name = "sailing",
            style = MaterialIcon.Style.Outlined
        )
        val Sanitizer = MaterialIcon(
            name = "sanitizer",
            style = MaterialIcon.Style.Outlined
        )
        val Satellite = MaterialIcon(
            name = "satellite",
            style = MaterialIcon.Style.Outlined
        )
        val SatelliteAlt = MaterialIcon(
            name = "satellite_alt",
            style = MaterialIcon.Style.Outlined
        )
        val Save = MaterialIcon(
            name = "save",
            style = MaterialIcon.Style.Outlined
        )
        val SaveAlt = MaterialIcon(
            name = "save_alt",
            style = MaterialIcon.Style.Outlined
        )
        val SaveAs = MaterialIcon(
            name = "save_as",
            style = MaterialIcon.Style.Outlined
        )
        val SavedSearch = MaterialIcon(
            name = "saved_search",
            style = MaterialIcon.Style.Outlined
        )
        val Savings = MaterialIcon(
            name = "savings",
            style = MaterialIcon.Style.Outlined
        )
        val Scale = MaterialIcon(
            name = "scale",
            style = MaterialIcon.Style.Outlined
        )
        val Scanner = MaterialIcon(
            name = "scanner",
            style = MaterialIcon.Style.Outlined
        )
        val ScatterPlot = MaterialIcon(
            name = "scatter_plot",
            style = MaterialIcon.Style.Outlined
        )
        val Schedule = MaterialIcon(
            name = "schedule",
            style = MaterialIcon.Style.Outlined
        )
        val ScheduleSend = MaterialIcon(
            name = "schedule_send",
            style = MaterialIcon.Style.Outlined
        )
        val Schema = MaterialIcon(
            name = "schema",
            style = MaterialIcon.Style.Outlined
        )
        val School = MaterialIcon(
            name = "school",
            style = MaterialIcon.Style.Outlined
        )
        val Science = MaterialIcon(
            name = "science",
            style = MaterialIcon.Style.Outlined
        )
        val Score = MaterialIcon(
            name = "score",
            style = MaterialIcon.Style.Outlined
        )
        val Scoreboard = MaterialIcon(
            name = "scoreboard",
            style = MaterialIcon.Style.Outlined
        )
        val ScreenLockLandscape = MaterialIcon(
            name = "screen_lock_landscape",
            style = MaterialIcon.Style.Outlined
        )
        val ScreenLockPortrait = MaterialIcon(
            name = "screen_lock_portrait",
            style = MaterialIcon.Style.Outlined
        )
        val ScreenLockRotation = MaterialIcon(
            name = "screen_lock_rotation",
            style = MaterialIcon.Style.Outlined
        )
        val ScreenRotation = MaterialIcon(
            name = "screen_rotation",
            style = MaterialIcon.Style.Outlined
        )
        val ScreenRotationAlt = MaterialIcon(
            name = "screen_rotation_alt",
            style = MaterialIcon.Style.Outlined
        )
        val ScreenSearchDesktop = MaterialIcon(
            name = "screen_search_desktop",
            style = MaterialIcon.Style.Outlined
        )
        val ScreenShare = MaterialIcon(
            name = "screen_share",
            style = MaterialIcon.Style.Outlined
        )
        val Screenshot = MaterialIcon(
            name = "screenshot",
            style = MaterialIcon.Style.Outlined
        )
        val ScreenshotMonitor = MaterialIcon(
            name = "screenshot_monitor",
            style = MaterialIcon.Style.Outlined
        )
        val ScubaDiving = MaterialIcon(
            name = "scuba_diving",
            style = MaterialIcon.Style.Outlined
        )
        val Sd = MaterialIcon(
            name = "sd",
            style = MaterialIcon.Style.Outlined
        )
        val SdCard = MaterialIcon(
            name = "sd_card",
            style = MaterialIcon.Style.Outlined
        )
        val SdCardAlert = MaterialIcon(
            name = "sd_card_alert",
            style = MaterialIcon.Style.Outlined
        )
        val SdStorage = MaterialIcon(
            name = "sd_storage",
            style = MaterialIcon.Style.Outlined
        )
        val Search = MaterialIcon(
            name = "search",
            style = MaterialIcon.Style.Outlined
        )
        val SearchOff = MaterialIcon(
            name = "search_off",
            style = MaterialIcon.Style.Outlined
        )
        val Security = MaterialIcon(
            name = "security",
            style = MaterialIcon.Style.Outlined
        )
        val SecurityUpdate = MaterialIcon(
            name = "security_update",
            style = MaterialIcon.Style.Outlined
        )
        val SecurityUpdateGood = MaterialIcon(
            name = "security_update_good",
            style = MaterialIcon.Style.Outlined
        )
        val SecurityUpdateWarning = MaterialIcon(
            name = "security_update_warning",
            style = MaterialIcon.Style.Outlined
        )
        val Segment = MaterialIcon(
            name = "segment",
            style = MaterialIcon.Style.Outlined
        )
        val SelectAll = MaterialIcon(
            name = "select_all",
            style = MaterialIcon.Style.Outlined
        )
        val SelfImprovement = MaterialIcon(
            name = "self_improvement",
            style = MaterialIcon.Style.Outlined
        )
        val Sell = MaterialIcon(
            name = "sell",
            style = MaterialIcon.Style.Outlined
        )
        val Send = MaterialIcon(
            name = "send",
            style = MaterialIcon.Style.Outlined
        )
        val SendAndArchive = MaterialIcon(
            name = "send_and_archive",
            style = MaterialIcon.Style.Outlined
        )
        val SendTimeExtension = MaterialIcon(
            name = "send_time_extension",
            style = MaterialIcon.Style.Outlined
        )
        val SendToMobile = MaterialIcon(
            name = "send_to_mobile",
            style = MaterialIcon.Style.Outlined
        )
        val SensorDoor = MaterialIcon(
            name = "sensor_door",
            style = MaterialIcon.Style.Outlined
        )
        val SensorOccupied = MaterialIcon(
            name = "sensor_occupied",
            style = MaterialIcon.Style.Outlined
        )
        val SensorWindow = MaterialIcon(
            name = "sensor_window",
            style = MaterialIcon.Style.Outlined
        )
        val Sensors = MaterialIcon(
            name = "sensors",
            style = MaterialIcon.Style.Outlined
        )
        val SensorsOff = MaterialIcon(
            name = "sensors_off",
            style = MaterialIcon.Style.Outlined
        )
        val SentimentDissatisfied = MaterialIcon(
            name = "sentiment_dissatisfied",
            style = MaterialIcon.Style.Outlined
        )
        val SentimentNeutral = MaterialIcon(
            name = "sentiment_neutral",
            style = MaterialIcon.Style.Outlined
        )
        val SentimentSatisfied = MaterialIcon(
            name = "sentiment_satisfied",
            style = MaterialIcon.Style.Outlined
        )
        val SentimentSatisfiedAlt = MaterialIcon(
            name = "sentiment_satisfied_alt",
            style = MaterialIcon.Style.Outlined
        )
        val SentimentVeryDissatisfied = MaterialIcon(
            name = "sentiment_very_dissatisfied",
            style = MaterialIcon.Style.Outlined
        )
        val SentimentVerySatisfied = MaterialIcon(
            name = "sentiment_very_satisfied",
            style = MaterialIcon.Style.Outlined
        )
        val SetMeal = MaterialIcon(
            name = "set_meal",
            style = MaterialIcon.Style.Outlined
        )
        val Settings = MaterialIcon(
            name = "settings",
            style = MaterialIcon.Style.Outlined
        )
        val SettingsAccessibility = MaterialIcon(
            name = "settings_accessibility",
            style = MaterialIcon.Style.Outlined
        )
        val SettingsApplications = MaterialIcon(
            name = "settings_applications",
            style = MaterialIcon.Style.Outlined
        )
        val SettingsBackupRestore = MaterialIcon(
            name = "settings_backup_restore",
            style = MaterialIcon.Style.Outlined
        )
        val SettingsBluetooth = MaterialIcon(
            name = "settings_bluetooth",
            style = MaterialIcon.Style.Outlined
        )
        val SettingsBrightness = MaterialIcon(
            name = "settings_brightness",
            style = MaterialIcon.Style.Outlined
        )
        val SettingsCell = MaterialIcon(
            name = "settings_cell",
            style = MaterialIcon.Style.Outlined
        )
        val SettingsEthernet = MaterialIcon(
            name = "settings_ethernet",
            style = MaterialIcon.Style.Outlined
        )
        val SettingsInputAntenna = MaterialIcon(
            name = "settings_input_antenna",
            style = MaterialIcon.Style.Outlined
        )
        val SettingsInputComponent = MaterialIcon(
            name = "settings_input_component",
            style = MaterialIcon.Style.Outlined
        )
        val SettingsInputComposite = MaterialIcon(
            name = "settings_input_composite",
            style = MaterialIcon.Style.Outlined
        )
        val SettingsInputHdmi = MaterialIcon(
            name = "settings_input_hdmi",
            style = MaterialIcon.Style.Outlined
        )
        val SettingsInputSvideo = MaterialIcon(
            name = "settings_input_svideo",
            style = MaterialIcon.Style.Outlined
        )
        val SettingsOverscan = MaterialIcon(
            name = "settings_overscan",
            style = MaterialIcon.Style.Outlined
        )
        val SettingsPhone = MaterialIcon(
            name = "settings_phone",
            style = MaterialIcon.Style.Outlined
        )
        val SettingsPower = MaterialIcon(
            name = "settings_power",
            style = MaterialIcon.Style.Outlined
        )
        val SettingsRemote = MaterialIcon(
            name = "settings_remote",
            style = MaterialIcon.Style.Outlined
        )
        val SettingsSuggest = MaterialIcon(
            name = "settings_suggest",
            style = MaterialIcon.Style.Outlined
        )
        val SettingsSystemDaydream = MaterialIcon(
            name = "settings_system_daydream",
            style = MaterialIcon.Style.Outlined
        )
        val SettingsVoice = MaterialIcon(
            name = "settings_voice",
            style = MaterialIcon.Style.Outlined
        )
        val SevereCold = MaterialIcon(
            name = "severe_cold",
            style = MaterialIcon.Style.Outlined
        )
        val ShapeLine = MaterialIcon(
            name = "shape_line",
            style = MaterialIcon.Style.Outlined
        )
        val Share = MaterialIcon(
            name = "share",
            style = MaterialIcon.Style.Outlined
        )
        val ShareLocation = MaterialIcon(
            name = "share_location",
            style = MaterialIcon.Style.Outlined
        )
        val Shield = MaterialIcon(
            name = "shield",
            style = MaterialIcon.Style.Outlined
        )
        val ShieldMoon = MaterialIcon(
            name = "shield_moon",
            style = MaterialIcon.Style.Outlined
        )
        val Shop = MaterialIcon(
            name = "shop",
            style = MaterialIcon.Style.Outlined
        )
        val Shop2 = MaterialIcon(
            name = "shop_2",
            style = MaterialIcon.Style.Outlined
        )
        val ShopTwo = MaterialIcon(
            name = "shop_two",
            style = MaterialIcon.Style.Outlined
        )
        val ShoppingBag = MaterialIcon(
            name = "shopping_bag",
            style = MaterialIcon.Style.Outlined
        )
        val ShoppingBasket = MaterialIcon(
            name = "shopping_basket",
            style = MaterialIcon.Style.Outlined
        )
        val ShoppingCart = MaterialIcon(
            name = "shopping_cart",
            style = MaterialIcon.Style.Outlined
        )
        val ShoppingCartCheckout = MaterialIcon(
            name = "shopping_cart_checkout",
            style = MaterialIcon.Style.Outlined
        )
        val ShortText = MaterialIcon(
            name = "short_text",
            style = MaterialIcon.Style.Outlined
        )
        val Shortcut = MaterialIcon(
            name = "shortcut",
            style = MaterialIcon.Style.Outlined
        )
        val ShowChart = MaterialIcon(
            name = "show_chart",
            style = MaterialIcon.Style.Outlined
        )
        val Shower = MaterialIcon(
            name = "shower",
            style = MaterialIcon.Style.Outlined
        )
        val Shuffle = MaterialIcon(
            name = "shuffle",
            style = MaterialIcon.Style.Outlined
        )
        val ShuffleOn = MaterialIcon(
            name = "shuffle_on",
            style = MaterialIcon.Style.Outlined
        )
        val ShutterSpeed = MaterialIcon(
            name = "shutter_speed",
            style = MaterialIcon.Style.Outlined
        )
        val Sick = MaterialIcon(
            name = "sick",
            style = MaterialIcon.Style.Outlined
        )
        val SignLanguage = MaterialIcon(
            name = "sign_language",
            style = MaterialIcon.Style.Outlined
        )
        val SignalCellular0Bar = MaterialIcon(
            name = "signal_cellular_0_bar",
            style = MaterialIcon.Style.Outlined
        )
        val SignalCellular4Bar = MaterialIcon(
            name = "signal_cellular_4_bar",
            style = MaterialIcon.Style.Outlined
        )
        val SignalCellularAlt = MaterialIcon(
            name = "signal_cellular_alt",
            style = MaterialIcon.Style.Outlined
        )
        val SignalCellularAlt1Bar = MaterialIcon(
            name = "signal_cellular_alt_1_bar",
            style = MaterialIcon.Style.Outlined
        )
        val SignalCellularAlt2Bar = MaterialIcon(
            name = "signal_cellular_alt_2_bar",
            style = MaterialIcon.Style.Outlined
        )
        val SignalCellularConnectedNoInternet0Bar = MaterialIcon(
            name = "signal_cellular_connected_no_internet_0_bar",
            style = MaterialIcon.Style.Outlined
        )
        val SignalCellularConnectedNoInternet4Bar = MaterialIcon(
            name = "signal_cellular_connected_no_internet_4_bar",
            style = MaterialIcon.Style.Outlined
        )
        val SignalCellularNoSim = MaterialIcon(
            name = "signal_cellular_no_sim",
            style = MaterialIcon.Style.Outlined
        )
        val SignalCellularNodata = MaterialIcon(
            name = "signal_cellular_nodata",
            style = MaterialIcon.Style.Outlined
        )
        val SignalCellularNull = MaterialIcon(
            name = "signal_cellular_null",
            style = MaterialIcon.Style.Outlined
        )
        val SignalCellularOff = MaterialIcon(
            name = "signal_cellular_off",
            style = MaterialIcon.Style.Outlined
        )
        val SignalWifi0Bar = MaterialIcon(
            name = "signal_wifi_0_bar",
            style = MaterialIcon.Style.Outlined
        )
        val SignalWifi4Bar = MaterialIcon(
            name = "signal_wifi_4_bar",
            style = MaterialIcon.Style.Outlined
        )
        val SignalWifi4BarLock = MaterialIcon(
            name = "signal_wifi_4_bar_lock",
            style = MaterialIcon.Style.Outlined
        )
        val SignalWifiBad = MaterialIcon(
            name = "signal_wifi_bad",
            style = MaterialIcon.Style.Outlined
        )
        val SignalWifiConnectedNoInternet4 = MaterialIcon(
            name = "signal_wifi_connected_no_internet_4",
            style = MaterialIcon.Style.Outlined
        )
        val SignalWifiOff = MaterialIcon(
            name = "signal_wifi_off",
            style = MaterialIcon.Style.Outlined
        )
        val SignalWifiStatusbar4Bar = MaterialIcon(
            name = "signal_wifi_statusbar_4_bar",
            style = MaterialIcon.Style.Outlined
        )
        val SignalWifiStatusbarConnectedNoInternet4 = MaterialIcon(
            name = "signal_wifi_statusbar_connected_no_internet_4",
            style = MaterialIcon.Style.Outlined
        )
        val SignalWifiStatusbarNull = MaterialIcon(
            name = "signal_wifi_statusbar_null",
            style = MaterialIcon.Style.Outlined
        )
        val Signpost = MaterialIcon(
            name = "signpost",
            style = MaterialIcon.Style.Outlined
        )
        val SimCard = MaterialIcon(
            name = "sim_card",
            style = MaterialIcon.Style.Outlined
        )
        val SimCardAlert = MaterialIcon(
            name = "sim_card_alert",
            style = MaterialIcon.Style.Outlined
        )
        val SimCardDownload = MaterialIcon(
            name = "sim_card_download",
            style = MaterialIcon.Style.Outlined
        )
        val SingleBed = MaterialIcon(
            name = "single_bed",
            style = MaterialIcon.Style.Outlined
        )
        val Sip = MaterialIcon(
            name = "sip",
            style = MaterialIcon.Style.Outlined
        )
        val Skateboarding = MaterialIcon(
            name = "skateboarding",
            style = MaterialIcon.Style.Outlined
        )
        val SkipNext = MaterialIcon(
            name = "skip_next",
            style = MaterialIcon.Style.Outlined
        )
        val SkipPrevious = MaterialIcon(
            name = "skip_previous",
            style = MaterialIcon.Style.Outlined
        )
        val Sledding = MaterialIcon(
            name = "sledding",
            style = MaterialIcon.Style.Outlined
        )
        val Slideshow = MaterialIcon(
            name = "slideshow",
            style = MaterialIcon.Style.Outlined
        )
        val SlowMotionVideo = MaterialIcon(
            name = "slow_motion_video",
            style = MaterialIcon.Style.Outlined
        )
        val SmartButton = MaterialIcon(
            name = "smart_button",
            style = MaterialIcon.Style.Outlined
        )
        val SmartDisplay = MaterialIcon(
            name = "smart_display",
            style = MaterialIcon.Style.Outlined
        )
        val SmartScreen = MaterialIcon(
            name = "smart_screen",
            style = MaterialIcon.Style.Outlined
        )
        val SmartToy = MaterialIcon(
            name = "smart_toy",
            style = MaterialIcon.Style.Outlined
        )
        val Smartphone = MaterialIcon(
            name = "smartphone",
            style = MaterialIcon.Style.Outlined
        )
        val SmokeFree = MaterialIcon(
            name = "smoke_free",
            style = MaterialIcon.Style.Outlined
        )
        val SmokingRooms = MaterialIcon(
            name = "smoking_rooms",
            style = MaterialIcon.Style.Outlined
        )
        val Sms = MaterialIcon(
            name = "sms",
            style = MaterialIcon.Style.Outlined
        )
        val SmsFailed = MaterialIcon(
            name = "sms_failed",
            style = MaterialIcon.Style.Outlined
        )
        val SnippetFolder = MaterialIcon(
            name = "snippet_folder",
            style = MaterialIcon.Style.Outlined
        )
        val Snooze = MaterialIcon(
            name = "snooze",
            style = MaterialIcon.Style.Outlined
        )
        val Snowboarding = MaterialIcon(
            name = "snowboarding",
            style = MaterialIcon.Style.Outlined
        )
        val Snowmobile = MaterialIcon(
            name = "snowmobile",
            style = MaterialIcon.Style.Outlined
        )
        val Snowshoeing = MaterialIcon(
            name = "snowshoeing",
            style = MaterialIcon.Style.Outlined
        )
        val Soap = MaterialIcon(
            name = "soap",
            style = MaterialIcon.Style.Outlined
        )
        val SocialDistance = MaterialIcon(
            name = "social_distance",
            style = MaterialIcon.Style.Outlined
        )
        val SolarPower = MaterialIcon(
            name = "solar_power",
            style = MaterialIcon.Style.Outlined
        )
        val Sort = MaterialIcon(
            name = "sort",
            style = MaterialIcon.Style.Outlined
        )
        val SortByAlpha = MaterialIcon(
            name = "sort_by_alpha",
            style = MaterialIcon.Style.Outlined
        )
        val Sos = MaterialIcon(
            name = "sos",
            style = MaterialIcon.Style.Outlined
        )
        val SoupKitchen = MaterialIcon(
            name = "soup_kitchen",
            style = MaterialIcon.Style.Outlined
        )
        val Source = MaterialIcon(
            name = "source",
            style = MaterialIcon.Style.Outlined
        )
        val South = MaterialIcon(
            name = "south",
            style = MaterialIcon.Style.Outlined
        )
        val SouthAmerica = MaterialIcon(
            name = "south_america",
            style = MaterialIcon.Style.Outlined
        )
        val SouthEast = MaterialIcon(
            name = "south_east",
            style = MaterialIcon.Style.Outlined
        )
        val SouthWest = MaterialIcon(
            name = "south_west",
            style = MaterialIcon.Style.Outlined
        )
        val Spa = MaterialIcon(
            name = "spa",
            style = MaterialIcon.Style.Outlined
        )
        val SpaceBar = MaterialIcon(
            name = "space_bar",
            style = MaterialIcon.Style.Outlined
        )
        val SpaceDashboard = MaterialIcon(
            name = "space_dashboard",
            style = MaterialIcon.Style.Outlined
        )
        val SpatialAudio = MaterialIcon(
            name = "spatial_audio",
            style = MaterialIcon.Style.Outlined
        )
        val SpatialAudioOff = MaterialIcon(
            name = "spatial_audio_off",
            style = MaterialIcon.Style.Outlined
        )
        val SpatialTracking = MaterialIcon(
            name = "spatial_tracking",
            style = MaterialIcon.Style.Outlined
        )
        val Speaker = MaterialIcon(
            name = "speaker",
            style = MaterialIcon.Style.Outlined
        )
        val SpeakerGroup = MaterialIcon(
            name = "speaker_group",
            style = MaterialIcon.Style.Outlined
        )
        val SpeakerNotes = MaterialIcon(
            name = "speaker_notes",
            style = MaterialIcon.Style.Outlined
        )
        val SpeakerNotesOff = MaterialIcon(
            name = "speaker_notes_off",
            style = MaterialIcon.Style.Outlined
        )
        val SpeakerPhone = MaterialIcon(
            name = "speaker_phone",
            style = MaterialIcon.Style.Outlined
        )
        val Speed = MaterialIcon(
            name = "speed",
            style = MaterialIcon.Style.Outlined
        )
        val Spellcheck = MaterialIcon(
            name = "spellcheck",
            style = MaterialIcon.Style.Outlined
        )
        val Splitscreen = MaterialIcon(
            name = "splitscreen",
            style = MaterialIcon.Style.Outlined
        )
        val Spoke = MaterialIcon(
            name = "spoke",
            style = MaterialIcon.Style.Outlined
        )
        val Sports = MaterialIcon(
            name = "sports",
            style = MaterialIcon.Style.Outlined
        )
        val SportsBar = MaterialIcon(
            name = "sports_bar",
            style = MaterialIcon.Style.Outlined
        )
        val SportsBaseball = MaterialIcon(
            name = "sports_baseball",
            style = MaterialIcon.Style.Outlined
        )
        val SportsBasketball = MaterialIcon(
            name = "sports_basketball",
            style = MaterialIcon.Style.Outlined
        )
        val SportsCricket = MaterialIcon(
            name = "sports_cricket",
            style = MaterialIcon.Style.Outlined
        )
        val SportsEsports = MaterialIcon(
            name = "sports_esports",
            style = MaterialIcon.Style.Outlined
        )
        val SportsFootball = MaterialIcon(
            name = "sports_football",
            style = MaterialIcon.Style.Outlined
        )
        val SportsGolf = MaterialIcon(
            name = "sports_golf",
            style = MaterialIcon.Style.Outlined
        )
        val SportsGymnastics = MaterialIcon(
            name = "sports_gymnastics",
            style = MaterialIcon.Style.Outlined
        )
        val SportsHandball = MaterialIcon(
            name = "sports_handball",
            style = MaterialIcon.Style.Outlined
        )
        val SportsHockey = MaterialIcon(
            name = "sports_hockey",
            style = MaterialIcon.Style.Outlined
        )
        val SportsKabaddi = MaterialIcon(
            name = "sports_kabaddi",
            style = MaterialIcon.Style.Outlined
        )
        val SportsMartialArts = MaterialIcon(
            name = "sports_martial_arts",
            style = MaterialIcon.Style.Outlined
        )
        val SportsMma = MaterialIcon(
            name = "sports_mma",
            style = MaterialIcon.Style.Outlined
        )
        val SportsMotorsports = MaterialIcon(
            name = "sports_motorsports",
            style = MaterialIcon.Style.Outlined
        )
        val SportsRugby = MaterialIcon(
            name = "sports_rugby",
            style = MaterialIcon.Style.Outlined
        )
        val SportsScore = MaterialIcon(
            name = "sports_score",
            style = MaterialIcon.Style.Outlined
        )
        val SportsSoccer = MaterialIcon(
            name = "sports_soccer",
            style = MaterialIcon.Style.Outlined
        )
        val SportsTennis = MaterialIcon(
            name = "sports_tennis",
            style = MaterialIcon.Style.Outlined
        )
        val SportsVolleyball = MaterialIcon(
            name = "sports_volleyball",
            style = MaterialIcon.Style.Outlined
        )
        val Square = MaterialIcon(
            name = "square",
            style = MaterialIcon.Style.Outlined
        )
        val SquareFoot = MaterialIcon(
            name = "square_foot",
            style = MaterialIcon.Style.Outlined
        )
        val SsidChart = MaterialIcon(
            name = "ssid_chart",
            style = MaterialIcon.Style.Outlined
        )
        val StackedBarChart = MaterialIcon(
            name = "stacked_bar_chart",
            style = MaterialIcon.Style.Outlined
        )
        val StackedLineChart = MaterialIcon(
            name = "stacked_line_chart",
            style = MaterialIcon.Style.Outlined
        )
        val Stadium = MaterialIcon(
            name = "stadium",
            style = MaterialIcon.Style.Outlined
        )
        val Stairs = MaterialIcon(
            name = "stairs",
            style = MaterialIcon.Style.Outlined
        )
        val Star = MaterialIcon(
            name = "star",
            style = MaterialIcon.Style.Outlined
        )
        val StarBorder = MaterialIcon(
            name = "star_border",
            style = MaterialIcon.Style.Outlined
        )
        val StarBorderPurple500 = MaterialIcon(
            name = "star_border_purple500",
            style = MaterialIcon.Style.Outlined
        )
        val StarHalf = MaterialIcon(
            name = "star_half",
            style = MaterialIcon.Style.Outlined
        )
        val StarOutline = MaterialIcon(
            name = "star_outline",
            style = MaterialIcon.Style.Outlined
        )
        val StarPurple500 = MaterialIcon(
            name = "star_purple500",
            style = MaterialIcon.Style.Outlined
        )
        val StarRate = MaterialIcon(
            name = "star_rate",
            style = MaterialIcon.Style.Outlined
        )
        val Stars = MaterialIcon(
            name = "stars",
            style = MaterialIcon.Style.Outlined
        )
        val Start = MaterialIcon(
            name = "start",
            style = MaterialIcon.Style.Outlined
        )
        val StayCurrentLandscape = MaterialIcon(
            name = "stay_current_landscape",
            style = MaterialIcon.Style.Outlined
        )
        val StayCurrentPortrait = MaterialIcon(
            name = "stay_current_portrait",
            style = MaterialIcon.Style.Outlined
        )
        val StayPrimaryLandscape = MaterialIcon(
            name = "stay_primary_landscape",
            style = MaterialIcon.Style.Outlined
        )
        val StayPrimaryPortrait = MaterialIcon(
            name = "stay_primary_portrait",
            style = MaterialIcon.Style.Outlined
        )
        val StickyNote2 = MaterialIcon(
            name = "sticky_note_2",
            style = MaterialIcon.Style.Outlined
        )
        val Stop = MaterialIcon(
            name = "stop",
            style = MaterialIcon.Style.Outlined
        )
        val StopCircle = MaterialIcon(
            name = "stop_circle",
            style = MaterialIcon.Style.Outlined
        )
        val StopScreenShare = MaterialIcon(
            name = "stop_screen_share",
            style = MaterialIcon.Style.Outlined
        )
        val Storage = MaterialIcon(
            name = "storage",
            style = MaterialIcon.Style.Outlined
        )
        val Store = MaterialIcon(
            name = "store",
            style = MaterialIcon.Style.Outlined
        )
        val StoreMallDirectory = MaterialIcon(
            name = "store_mall_directory",
            style = MaterialIcon.Style.Outlined
        )
        val Storefront = MaterialIcon(
            name = "storefront",
            style = MaterialIcon.Style.Outlined
        )
        val Storm = MaterialIcon(
            name = "storm",
            style = MaterialIcon.Style.Outlined
        )
        val Straight = MaterialIcon(
            name = "straight",
            style = MaterialIcon.Style.Outlined
        )
        val Straighten = MaterialIcon(
            name = "straighten",
            style = MaterialIcon.Style.Outlined
        )
        val Stream = MaterialIcon(
            name = "stream",
            style = MaterialIcon.Style.Outlined
        )
        val Streetview = MaterialIcon(
            name = "streetview",
            style = MaterialIcon.Style.Outlined
        )
        val StrikethroughS = MaterialIcon(
            name = "strikethrough_s",
            style = MaterialIcon.Style.Outlined
        )
        val Stroller = MaterialIcon(
            name = "stroller",
            style = MaterialIcon.Style.Outlined
        )
        val Style = MaterialIcon(
            name = "style",
            style = MaterialIcon.Style.Outlined
        )
        val SubdirectoryArrowLeft = MaterialIcon(
            name = "subdirectory_arrow_left",
            style = MaterialIcon.Style.Outlined
        )
        val SubdirectoryArrowRight = MaterialIcon(
            name = "subdirectory_arrow_right",
            style = MaterialIcon.Style.Outlined
        )
        val Subject = MaterialIcon(
            name = "subject",
            style = MaterialIcon.Style.Outlined
        )
        val Subscript = MaterialIcon(
            name = "subscript",
            style = MaterialIcon.Style.Outlined
        )
        val Subscriptions = MaterialIcon(
            name = "subscriptions",
            style = MaterialIcon.Style.Outlined
        )
        val Subtitles = MaterialIcon(
            name = "subtitles",
            style = MaterialIcon.Style.Outlined
        )
        val SubtitlesOff = MaterialIcon(
            name = "subtitles_off",
            style = MaterialIcon.Style.Outlined
        )
        val Subway = MaterialIcon(
            name = "subway",
            style = MaterialIcon.Style.Outlined
        )
        val Summarize = MaterialIcon(
            name = "summarize",
            style = MaterialIcon.Style.Outlined
        )
        val Superscript = MaterialIcon(
            name = "superscript",
            style = MaterialIcon.Style.Outlined
        )
        val SupervisedUserCircle = MaterialIcon(
            name = "supervised_user_circle",
            style = MaterialIcon.Style.Outlined
        )
        val SupervisorAccount = MaterialIcon(
            name = "supervisor_account",
            style = MaterialIcon.Style.Outlined
        )
        val Support = MaterialIcon(
            name = "support",
            style = MaterialIcon.Style.Outlined
        )
        val SupportAgent = MaterialIcon(
            name = "support_agent",
            style = MaterialIcon.Style.Outlined
        )
        val Surfing = MaterialIcon(
            name = "surfing",
            style = MaterialIcon.Style.Outlined
        )
        val SurroundSound = MaterialIcon(
            name = "surround_sound",
            style = MaterialIcon.Style.Outlined
        )
        val SwapCalls = MaterialIcon(
            name = "swap_calls",
            style = MaterialIcon.Style.Outlined
        )
        val SwapHoriz = MaterialIcon(
            name = "swap_horiz",
            style = MaterialIcon.Style.Outlined
        )
        val SwapHorizontalCircle = MaterialIcon(
            name = "swap_horizontal_circle",
            style = MaterialIcon.Style.Outlined
        )
        val SwapVert = MaterialIcon(
            name = "swap_vert",
            style = MaterialIcon.Style.Outlined
        )
        val SwapVerticalCircle = MaterialIcon(
            name = "swap_vertical_circle",
            style = MaterialIcon.Style.Outlined
        )
        val Swipe = MaterialIcon(
            name = "swipe",
            style = MaterialIcon.Style.Outlined
        )
        val SwipeDown = MaterialIcon(
            name = "swipe_down",
            style = MaterialIcon.Style.Outlined
        )
        val SwipeDownAlt = MaterialIcon(
            name = "swipe_down_alt",
            style = MaterialIcon.Style.Outlined
        )
        val SwipeLeft = MaterialIcon(
            name = "swipe_left",
            style = MaterialIcon.Style.Outlined
        )
        val SwipeLeftAlt = MaterialIcon(
            name = "swipe_left_alt",
            style = MaterialIcon.Style.Outlined
        )
        val SwipeRight = MaterialIcon(
            name = "swipe_right",
            style = MaterialIcon.Style.Outlined
        )
        val SwipeRightAlt = MaterialIcon(
            name = "swipe_right_alt",
            style = MaterialIcon.Style.Outlined
        )
        val SwipeUp = MaterialIcon(
            name = "swipe_up",
            style = MaterialIcon.Style.Outlined
        )
        val SwipeUpAlt = MaterialIcon(
            name = "swipe_up_alt",
            style = MaterialIcon.Style.Outlined
        )
        val SwipeVertical = MaterialIcon(
            name = "swipe_vertical",
            style = MaterialIcon.Style.Outlined
        )
        val SwitchAccessShortcut = MaterialIcon(
            name = "switch_access_shortcut",
            style = MaterialIcon.Style.Outlined
        )
        val SwitchAccessShortcutAdd = MaterialIcon(
            name = "switch_access_shortcut_add",
            style = MaterialIcon.Style.Outlined
        )
        val SwitchAccount = MaterialIcon(
            name = "switch_account",
            style = MaterialIcon.Style.Outlined
        )
        val SwitchCamera = MaterialIcon(
            name = "switch_camera",
            style = MaterialIcon.Style.Outlined
        )
        val SwitchLeft = MaterialIcon(
            name = "switch_left",
            style = MaterialIcon.Style.Outlined
        )
        val SwitchRight = MaterialIcon(
            name = "switch_right",
            style = MaterialIcon.Style.Outlined
        )
        val SwitchVideo = MaterialIcon(
            name = "switch_video",
            style = MaterialIcon.Style.Outlined
        )
        val Synagogue = MaterialIcon(
            name = "synagogue",
            style = MaterialIcon.Style.Outlined
        )
        val Sync = MaterialIcon(
            name = "sync",
            style = MaterialIcon.Style.Outlined
        )
        val SyncAlt = MaterialIcon(
            name = "sync_alt",
            style = MaterialIcon.Style.Outlined
        )
        val SyncDisabled = MaterialIcon(
            name = "sync_disabled",
            style = MaterialIcon.Style.Outlined
        )
        val SyncLock = MaterialIcon(
            name = "sync_lock",
            style = MaterialIcon.Style.Outlined
        )
        val SyncProblem = MaterialIcon(
            name = "sync_problem",
            style = MaterialIcon.Style.Outlined
        )
        val SystemSecurityUpdate = MaterialIcon(
            name = "system_security_update",
            style = MaterialIcon.Style.Outlined
        )
        val SystemSecurityUpdateGood = MaterialIcon(
            name = "system_security_update_good",
            style = MaterialIcon.Style.Outlined
        )
        val SystemSecurityUpdateWarning = MaterialIcon(
            name = "system_security_update_warning",
            style = MaterialIcon.Style.Outlined
        )
        val SystemUpdate = MaterialIcon(
            name = "system_update",
            style = MaterialIcon.Style.Outlined
        )
        val SystemUpdateAlt = MaterialIcon(
            name = "system_update_alt",
            style = MaterialIcon.Style.Outlined
        )
        val Tab = MaterialIcon(
            name = "tab",
            style = MaterialIcon.Style.Outlined
        )
        val TabUnselected = MaterialIcon(
            name = "tab_unselected",
            style = MaterialIcon.Style.Outlined
        )
        val TableBar = MaterialIcon(
            name = "table_bar",
            style = MaterialIcon.Style.Outlined
        )
        val TableChart = MaterialIcon(
            name = "table_chart",
            style = MaterialIcon.Style.Outlined
        )
        val TableRestaurant = MaterialIcon(
            name = "table_restaurant",
            style = MaterialIcon.Style.Outlined
        )
        val TableRows = MaterialIcon(
            name = "table_rows",
            style = MaterialIcon.Style.Outlined
        )
        val TableView = MaterialIcon(
            name = "table_view",
            style = MaterialIcon.Style.Outlined
        )
        val Tablet = MaterialIcon(
            name = "tablet",
            style = MaterialIcon.Style.Outlined
        )
        val TabletAndroid = MaterialIcon(
            name = "tablet_android",
            style = MaterialIcon.Style.Outlined
        )
        val TabletMac = MaterialIcon(
            name = "tablet_mac",
            style = MaterialIcon.Style.Outlined
        )
        val Tag = MaterialIcon(
            name = "tag",
            style = MaterialIcon.Style.Outlined
        )
        val TagFaces = MaterialIcon(
            name = "tag_faces",
            style = MaterialIcon.Style.Outlined
        )
        val TakeoutDining = MaterialIcon(
            name = "takeout_dining",
            style = MaterialIcon.Style.Outlined
        )
        val TapAndPlay = MaterialIcon(
            name = "tap_and_play",
            style = MaterialIcon.Style.Outlined
        )
        val Tapas = MaterialIcon(
            name = "tapas",
            style = MaterialIcon.Style.Outlined
        )
        val Task = MaterialIcon(
            name = "task",
            style = MaterialIcon.Style.Outlined
        )
        val TaskAlt = MaterialIcon(
            name = "task_alt",
            style = MaterialIcon.Style.Outlined
        )
        val TaxiAlert = MaterialIcon(
            name = "taxi_alert",
            style = MaterialIcon.Style.Outlined
        )
        val TempleBuddhist = MaterialIcon(
            name = "temple_buddhist",
            style = MaterialIcon.Style.Outlined
        )
        val TempleHindu = MaterialIcon(
            name = "temple_hindu",
            style = MaterialIcon.Style.Outlined
        )
        val Terminal = MaterialIcon(
            name = "terminal",
            style = MaterialIcon.Style.Outlined
        )
        val Terrain = MaterialIcon(
            name = "terrain",
            style = MaterialIcon.Style.Outlined
        )
        val TextDecrease = MaterialIcon(
            name = "text_decrease",
            style = MaterialIcon.Style.Outlined
        )
        val TextFields = MaterialIcon(
            name = "text_fields",
            style = MaterialIcon.Style.Outlined
        )
        val TextFormat = MaterialIcon(
            name = "text_format",
            style = MaterialIcon.Style.Outlined
        )
        val TextIncrease = MaterialIcon(
            name = "text_increase",
            style = MaterialIcon.Style.Outlined
        )
        val TextRotateUp = MaterialIcon(
            name = "text_rotate_up",
            style = MaterialIcon.Style.Outlined
        )
        val TextRotateVertical = MaterialIcon(
            name = "text_rotate_vertical",
            style = MaterialIcon.Style.Outlined
        )
        val TextRotationAngledown = MaterialIcon(
            name = "text_rotation_angledown",
            style = MaterialIcon.Style.Outlined
        )
        val TextRotationAngleup = MaterialIcon(
            name = "text_rotation_angleup",
            style = MaterialIcon.Style.Outlined
        )
        val TextRotationDown = MaterialIcon(
            name = "text_rotation_down",
            style = MaterialIcon.Style.Outlined
        )
        val TextRotationNone = MaterialIcon(
            name = "text_rotation_none",
            style = MaterialIcon.Style.Outlined
        )
        val TextSnippet = MaterialIcon(
            name = "text_snippet",
            style = MaterialIcon.Style.Outlined
        )
        val Textsms = MaterialIcon(
            name = "textsms",
            style = MaterialIcon.Style.Outlined
        )
        val Texture = MaterialIcon(
            name = "texture",
            style = MaterialIcon.Style.Outlined
        )
        val TheaterComedy = MaterialIcon(
            name = "theater_comedy",
            style = MaterialIcon.Style.Outlined
        )
        val Theaters = MaterialIcon(
            name = "theaters",
            style = MaterialIcon.Style.Outlined
        )
        val Thermostat = MaterialIcon(
            name = "thermostat",
            style = MaterialIcon.Style.Outlined
        )
        val ThermostatAuto = MaterialIcon(
            name = "thermostat_auto",
            style = MaterialIcon.Style.Outlined
        )
        val ThumbDown = MaterialIcon(
            name = "thumb_down",
            style = MaterialIcon.Style.Outlined
        )
        val ThumbDownAlt = MaterialIcon(
            name = "thumb_down_alt",
            style = MaterialIcon.Style.Outlined
        )
        val ThumbDownOffAlt = MaterialIcon(
            name = "thumb_down_off_alt",
            style = MaterialIcon.Style.Outlined
        )
        val ThumbUp = MaterialIcon(
            name = "thumb_up",
            style = MaterialIcon.Style.Outlined
        )
        val ThumbUpAlt = MaterialIcon(
            name = "thumb_up_alt",
            style = MaterialIcon.Style.Outlined
        )
        val ThumbUpOffAlt = MaterialIcon(
            name = "thumb_up_off_alt",
            style = MaterialIcon.Style.Outlined
        )
        val ThumbsUpDown = MaterialIcon(
            name = "thumbs_up_down",
            style = MaterialIcon.Style.Outlined
        )
        val Thunderstorm = MaterialIcon(
            name = "thunderstorm",
            style = MaterialIcon.Style.Outlined
        )
        val TimeToLeave = MaterialIcon(
            name = "time_to_leave",
            style = MaterialIcon.Style.Outlined
        )
        val Timelapse = MaterialIcon(
            name = "timelapse",
            style = MaterialIcon.Style.Outlined
        )
        val Timeline = MaterialIcon(
            name = "timeline",
            style = MaterialIcon.Style.Outlined
        )
        val Timer = MaterialIcon(
            name = "timer",
            style = MaterialIcon.Style.Outlined
        )
        val Timer10 = MaterialIcon(
            name = "timer_10",
            style = MaterialIcon.Style.Outlined
        )
        val Timer10Select = MaterialIcon(
            name = "timer_10_select",
            style = MaterialIcon.Style.Outlined
        )
        val Timer3 = MaterialIcon(
            name = "timer_3",
            style = MaterialIcon.Style.Outlined
        )
        val Timer3Select = MaterialIcon(
            name = "timer_3_select",
            style = MaterialIcon.Style.Outlined
        )
        val TimerOff = MaterialIcon(
            name = "timer_off",
            style = MaterialIcon.Style.Outlined
        )
        val TipsAndUpdates = MaterialIcon(
            name = "tips_and_updates",
            style = MaterialIcon.Style.Outlined
        )
        val TireRepair = MaterialIcon(
            name = "tire_repair",
            style = MaterialIcon.Style.Outlined
        )
        val Title = MaterialIcon(
            name = "title",
            style = MaterialIcon.Style.Outlined
        )
        val Toc = MaterialIcon(
            name = "toc",
            style = MaterialIcon.Style.Outlined
        )
        val Today = MaterialIcon(
            name = "today",
            style = MaterialIcon.Style.Outlined
        )
        val ToggleOff = MaterialIcon(
            name = "toggle_off",
            style = MaterialIcon.Style.Outlined
        )
        val ToggleOn = MaterialIcon(
            name = "toggle_on",
            style = MaterialIcon.Style.Outlined
        )
        val Token = MaterialIcon(
            name = "token",
            style = MaterialIcon.Style.Outlined
        )
        val Toll = MaterialIcon(
            name = "toll",
            style = MaterialIcon.Style.Outlined
        )
        val Tonality = MaterialIcon(
            name = "tonality",
            style = MaterialIcon.Style.Outlined
        )
        val Topic = MaterialIcon(
            name = "topic",
            style = MaterialIcon.Style.Outlined
        )
        val Tornado = MaterialIcon(
            name = "tornado",
            style = MaterialIcon.Style.Outlined
        )
        val TouchApp = MaterialIcon(
            name = "touch_app",
            style = MaterialIcon.Style.Outlined
        )
        val Tour = MaterialIcon(
            name = "tour",
            style = MaterialIcon.Style.Outlined
        )
        val Toys = MaterialIcon(
            name = "toys",
            style = MaterialIcon.Style.Outlined
        )
        val TrackChanges = MaterialIcon(
            name = "track_changes",
            style = MaterialIcon.Style.Outlined
        )
        val Traffic = MaterialIcon(
            name = "traffic",
            style = MaterialIcon.Style.Outlined
        )
        val Train = MaterialIcon(
            name = "train",
            style = MaterialIcon.Style.Outlined
        )
        val Tram = MaterialIcon(
            name = "tram",
            style = MaterialIcon.Style.Outlined
        )
        val Transcribe = MaterialIcon(
            name = "transcribe",
            style = MaterialIcon.Style.Outlined
        )
        val TransferWithinAStation = MaterialIcon(
            name = "transfer_within_a_station",
            style = MaterialIcon.Style.Outlined
        )
        val Transform = MaterialIcon(
            name = "transform",
            style = MaterialIcon.Style.Outlined
        )
        val Transgender = MaterialIcon(
            name = "transgender",
            style = MaterialIcon.Style.Outlined
        )
        val TransitEnterexit = MaterialIcon(
            name = "transit_enterexit",
            style = MaterialIcon.Style.Outlined
        )
        val Translate = MaterialIcon(
            name = "translate",
            style = MaterialIcon.Style.Outlined
        )
        val TravelExplore = MaterialIcon(
            name = "travel_explore",
            style = MaterialIcon.Style.Outlined
        )
        val TrendingDown = MaterialIcon(
            name = "trending_down",
            style = MaterialIcon.Style.Outlined
        )
        val TrendingFlat = MaterialIcon(
            name = "trending_flat",
            style = MaterialIcon.Style.Outlined
        )
        val TrendingUp = MaterialIcon(
            name = "trending_up",
            style = MaterialIcon.Style.Outlined
        )
        val TripOrigin = MaterialIcon(
            name = "trip_origin",
            style = MaterialIcon.Style.Outlined
        )
        val Troubleshoot = MaterialIcon(
            name = "troubleshoot",
            style = MaterialIcon.Style.Outlined
        )
        val Try = MaterialIcon(
            name = "try",
            style = MaterialIcon.Style.Outlined
        )
        val Tsunami = MaterialIcon(
            name = "tsunami",
            style = MaterialIcon.Style.Outlined
        )
        val Tty = MaterialIcon(
            name = "tty",
            style = MaterialIcon.Style.Outlined
        )
        val Tune = MaterialIcon(
            name = "tune",
            style = MaterialIcon.Style.Outlined
        )
        val Tungsten = MaterialIcon(
            name = "tungsten",
            style = MaterialIcon.Style.Outlined
        )
        val TurnLeft = MaterialIcon(
            name = "turn_left",
            style = MaterialIcon.Style.Outlined
        )
        val TurnRight = MaterialIcon(
            name = "turn_right",
            style = MaterialIcon.Style.Outlined
        )
        val TurnSharpLeft = MaterialIcon(
            name = "turn_sharp_left",
            style = MaterialIcon.Style.Outlined
        )
        val TurnSharpRight = MaterialIcon(
            name = "turn_sharp_right",
            style = MaterialIcon.Style.Outlined
        )
        val TurnSlightLeft = MaterialIcon(
            name = "turn_slight_left",
            style = MaterialIcon.Style.Outlined
        )
        val TurnSlightRight = MaterialIcon(
            name = "turn_slight_right",
            style = MaterialIcon.Style.Outlined
        )
        val TurnedIn = MaterialIcon(
            name = "turned_in",
            style = MaterialIcon.Style.Outlined
        )
        val TurnedInNot = MaterialIcon(
            name = "turned_in_not",
            style = MaterialIcon.Style.Outlined
        )
        val Tv = MaterialIcon(
            name = "tv",
            style = MaterialIcon.Style.Outlined
        )
        val TvOff = MaterialIcon(
            name = "tv_off",
            style = MaterialIcon.Style.Outlined
        )
        val TwoWheeler = MaterialIcon(
            name = "two_wheeler",
            style = MaterialIcon.Style.Outlined
        )
        val TypeSpecimen = MaterialIcon(
            name = "type_specimen",
            style = MaterialIcon.Style.Outlined
        )
        val UTurnLeft = MaterialIcon(
            name = "u_turn_left",
            style = MaterialIcon.Style.Outlined
        )
        val UTurnRight = MaterialIcon(
            name = "u_turn_right",
            style = MaterialIcon.Style.Outlined
        )
        val Umbrella = MaterialIcon(
            name = "umbrella",
            style = MaterialIcon.Style.Outlined
        )
        val Unarchive = MaterialIcon(
            name = "unarchive",
            style = MaterialIcon.Style.Outlined
        )
        val Undo = MaterialIcon(
            name = "undo",
            style = MaterialIcon.Style.Outlined
        )
        val UnfoldLess = MaterialIcon(
            name = "unfold_less",
            style = MaterialIcon.Style.Outlined
        )
        val UnfoldLessDouble = MaterialIcon(
            name = "unfold_less_double",
            style = MaterialIcon.Style.Outlined
        )
        val UnfoldMore = MaterialIcon(
            name = "unfold_more",
            style = MaterialIcon.Style.Outlined
        )
        val UnfoldMoreDouble = MaterialIcon(
            name = "unfold_more_double",
            style = MaterialIcon.Style.Outlined
        )
        val Unpublished = MaterialIcon(
            name = "unpublished",
            style = MaterialIcon.Style.Outlined
        )
        val Unsubscribe = MaterialIcon(
            name = "unsubscribe",
            style = MaterialIcon.Style.Outlined
        )
        val Upcoming = MaterialIcon(
            name = "upcoming",
            style = MaterialIcon.Style.Outlined
        )
        val Update = MaterialIcon(
            name = "update",
            style = MaterialIcon.Style.Outlined
        )
        val UpdateDisabled = MaterialIcon(
            name = "update_disabled",
            style = MaterialIcon.Style.Outlined
        )
        val Upgrade = MaterialIcon(
            name = "upgrade",
            style = MaterialIcon.Style.Outlined
        )
        val Upload = MaterialIcon(
            name = "upload",
            style = MaterialIcon.Style.Outlined
        )
        val UploadFile = MaterialIcon(
            name = "upload_file",
            style = MaterialIcon.Style.Outlined
        )
        val Usb = MaterialIcon(
            name = "usb",
            style = MaterialIcon.Style.Outlined
        )
        val UsbOff = MaterialIcon(
            name = "usb_off",
            style = MaterialIcon.Style.Outlined
        )
        val Vaccines = MaterialIcon(
            name = "vaccines",
            style = MaterialIcon.Style.Outlined
        )
        val VapeFree = MaterialIcon(
            name = "vape_free",
            style = MaterialIcon.Style.Outlined
        )
        val VapingRooms = MaterialIcon(
            name = "vaping_rooms",
            style = MaterialIcon.Style.Outlined
        )
        val Verified = MaterialIcon(
            name = "verified",
            style = MaterialIcon.Style.Outlined
        )
        val VerifiedUser = MaterialIcon(
            name = "verified_user",
            style = MaterialIcon.Style.Outlined
        )
        val VerticalAlignBottom = MaterialIcon(
            name = "vertical_align_bottom",
            style = MaterialIcon.Style.Outlined
        )
        val VerticalAlignCenter = MaterialIcon(
            name = "vertical_align_center",
            style = MaterialIcon.Style.Outlined
        )
        val VerticalAlignTop = MaterialIcon(
            name = "vertical_align_top",
            style = MaterialIcon.Style.Outlined
        )
        val VerticalDistribute = MaterialIcon(
            name = "vertical_distribute",
            style = MaterialIcon.Style.Outlined
        )
        val VerticalShades = MaterialIcon(
            name = "vertical_shades",
            style = MaterialIcon.Style.Outlined
        )
        val VerticalShadesClosed = MaterialIcon(
            name = "vertical_shades_closed",
            style = MaterialIcon.Style.Outlined
        )
        val VerticalSplit = MaterialIcon(
            name = "vertical_split",
            style = MaterialIcon.Style.Outlined
        )
        val Vibration = MaterialIcon(
            name = "vibration",
            style = MaterialIcon.Style.Outlined
        )
        val VideoCall = MaterialIcon(
            name = "video_call",
            style = MaterialIcon.Style.Outlined
        )
        val VideoCameraBack = MaterialIcon(
            name = "video_camera_back",
            style = MaterialIcon.Style.Outlined
        )
        val VideoCameraFront = MaterialIcon(
            name = "video_camera_front",
            style = MaterialIcon.Style.Outlined
        )
        val VideoChat = MaterialIcon(
            name = "video_chat",
            style = MaterialIcon.Style.Outlined
        )
        val VideoFile = MaterialIcon(
            name = "video_file",
            style = MaterialIcon.Style.Outlined
        )
        val VideoLabel = MaterialIcon(
            name = "video_label",
            style = MaterialIcon.Style.Outlined
        )
        val VideoLibrary = MaterialIcon(
            name = "video_library",
            style = MaterialIcon.Style.Outlined
        )
        val VideoSettings = MaterialIcon(
            name = "video_settings",
            style = MaterialIcon.Style.Outlined
        )
        val VideoStable = MaterialIcon(
            name = "video_stable",
            style = MaterialIcon.Style.Outlined
        )
        val Videocam = MaterialIcon(
            name = "videocam",
            style = MaterialIcon.Style.Outlined
        )
        val VideocamOff = MaterialIcon(
            name = "videocam_off",
            style = MaterialIcon.Style.Outlined
        )
        val VideogameAsset = MaterialIcon(
            name = "videogame_asset",
            style = MaterialIcon.Style.Outlined
        )
        val VideogameAssetOff = MaterialIcon(
            name = "videogame_asset_off",
            style = MaterialIcon.Style.Outlined
        )
        val ViewAgenda = MaterialIcon(
            name = "view_agenda",
            style = MaterialIcon.Style.Outlined
        )
        val ViewArray = MaterialIcon(
            name = "view_array",
            style = MaterialIcon.Style.Outlined
        )
        val ViewCarousel = MaterialIcon(
            name = "view_carousel",
            style = MaterialIcon.Style.Outlined
        )
        val ViewColumn = MaterialIcon(
            name = "view_column",
            style = MaterialIcon.Style.Outlined
        )
        val ViewComfy = MaterialIcon(
            name = "view_comfy",
            style = MaterialIcon.Style.Outlined
        )
        val ViewComfyAlt = MaterialIcon(
            name = "view_comfy_alt",
            style = MaterialIcon.Style.Outlined
        )
        val ViewCompact = MaterialIcon(
            name = "view_compact",
            style = MaterialIcon.Style.Outlined
        )
        val ViewCompactAlt = MaterialIcon(
            name = "view_compact_alt",
            style = MaterialIcon.Style.Outlined
        )
        val ViewCozy = MaterialIcon(
            name = "view_cozy",
            style = MaterialIcon.Style.Outlined
        )
        val ViewDay = MaterialIcon(
            name = "view_day",
            style = MaterialIcon.Style.Outlined
        )
        val ViewHeadline = MaterialIcon(
            name = "view_headline",
            style = MaterialIcon.Style.Outlined
        )
        val ViewInAr = MaterialIcon(
            name = "view_in_ar",
            style = MaterialIcon.Style.Outlined
        )
        val ViewKanban = MaterialIcon(
            name = "view_kanban",
            style = MaterialIcon.Style.Outlined
        )
        val ViewList = MaterialIcon(
            name = "view_list",
            style = MaterialIcon.Style.Outlined
        )
        val ViewModule = MaterialIcon(
            name = "view_module",
            style = MaterialIcon.Style.Outlined
        )
        val ViewQuilt = MaterialIcon(
            name = "view_quilt",
            style = MaterialIcon.Style.Outlined
        )
        val ViewSidebar = MaterialIcon(
            name = "view_sidebar",
            style = MaterialIcon.Style.Outlined
        )
        val ViewStream = MaterialIcon(
            name = "view_stream",
            style = MaterialIcon.Style.Outlined
        )
        val ViewTimeline = MaterialIcon(
            name = "view_timeline",
            style = MaterialIcon.Style.Outlined
        )
        val ViewWeek = MaterialIcon(
            name = "view_week",
            style = MaterialIcon.Style.Outlined
        )
        val Vignette = MaterialIcon(
            name = "vignette",
            style = MaterialIcon.Style.Outlined
        )
        val Villa = MaterialIcon(
            name = "villa",
            style = MaterialIcon.Style.Outlined
        )
        val Visibility = MaterialIcon(
            name = "visibility",
            style = MaterialIcon.Style.Outlined
        )
        val VisibilityOff = MaterialIcon(
            name = "visibility_off",
            style = MaterialIcon.Style.Outlined
        )
        val VoiceChat = MaterialIcon(
            name = "voice_chat",
            style = MaterialIcon.Style.Outlined
        )
        val VoiceOverOff = MaterialIcon(
            name = "voice_over_off",
            style = MaterialIcon.Style.Outlined
        )
        val Voicemail = MaterialIcon(
            name = "voicemail",
            style = MaterialIcon.Style.Outlined
        )
        val Volcano = MaterialIcon(
            name = "volcano",
            style = MaterialIcon.Style.Outlined
        )
        val VolumeDown = MaterialIcon(
            name = "volume_down",
            style = MaterialIcon.Style.Outlined
        )
        val VolumeMute = MaterialIcon(
            name = "volume_mute",
            style = MaterialIcon.Style.Outlined
        )
        val VolumeOff = MaterialIcon(
            name = "volume_off",
            style = MaterialIcon.Style.Outlined
        )
        val VolumeUp = MaterialIcon(
            name = "volume_up",
            style = MaterialIcon.Style.Outlined
        )
        val VolunteerActivism = MaterialIcon(
            name = "volunteer_activism",
            style = MaterialIcon.Style.Outlined
        )
        val VpnKey = MaterialIcon(
            name = "vpn_key",
            style = MaterialIcon.Style.Outlined
        )
        val VpnKeyOff = MaterialIcon(
            name = "vpn_key_off",
            style = MaterialIcon.Style.Outlined
        )
        val VpnLock = MaterialIcon(
            name = "vpn_lock",
            style = MaterialIcon.Style.Outlined
        )
        val Vrpano = MaterialIcon(
            name = "vrpano",
            style = MaterialIcon.Style.Outlined
        )
        val Wallet = MaterialIcon(
            name = "wallet",
            style = MaterialIcon.Style.Outlined
        )
        val Wallpaper = MaterialIcon(
            name = "wallpaper",
            style = MaterialIcon.Style.Outlined
        )
        val Warehouse = MaterialIcon(
            name = "warehouse",
            style = MaterialIcon.Style.Outlined
        )
        val Warning = MaterialIcon(
            name = "warning",
            style = MaterialIcon.Style.Outlined
        )
        val WarningAmber = MaterialIcon(
            name = "warning_amber",
            style = MaterialIcon.Style.Outlined
        )
        val Wash = MaterialIcon(
            name = "wash",
            style = MaterialIcon.Style.Outlined
        )
        val Watch = MaterialIcon(
            name = "watch",
            style = MaterialIcon.Style.Outlined
        )
        val WatchLater = MaterialIcon(
            name = "watch_later",
            style = MaterialIcon.Style.Outlined
        )
        val WatchOff = MaterialIcon(
            name = "watch_off",
            style = MaterialIcon.Style.Outlined
        )
        val Water = MaterialIcon(
            name = "water",
            style = MaterialIcon.Style.Outlined
        )
        val WaterDamage = MaterialIcon(
            name = "water_damage",
            style = MaterialIcon.Style.Outlined
        )
        val WaterDrop = MaterialIcon(
            name = "water_drop",
            style = MaterialIcon.Style.Outlined
        )
        val WaterfallChart = MaterialIcon(
            name = "waterfall_chart",
            style = MaterialIcon.Style.Outlined
        )
        val Waves = MaterialIcon(
            name = "waves",
            style = MaterialIcon.Style.Outlined
        )
        val WavingHand = MaterialIcon(
            name = "waving_hand",
            style = MaterialIcon.Style.Outlined
        )
        val WbAuto = MaterialIcon(
            name = "wb_auto",
            style = MaterialIcon.Style.Outlined
        )
        val WbCloudy = MaterialIcon(
            name = "wb_cloudy",
            style = MaterialIcon.Style.Outlined
        )
        val WbIncandescent = MaterialIcon(
            name = "wb_incandescent",
            style = MaterialIcon.Style.Outlined
        )
        val WbIridescent = MaterialIcon(
            name = "wb_iridescent",
            style = MaterialIcon.Style.Outlined
        )
        val WbShade = MaterialIcon(
            name = "wb_shade",
            style = MaterialIcon.Style.Outlined
        )
        val WbSunny = MaterialIcon(
            name = "wb_sunny",
            style = MaterialIcon.Style.Outlined
        )
        val WbTwilight = MaterialIcon(
            name = "wb_twilight",
            style = MaterialIcon.Style.Outlined
        )
        val Wc = MaterialIcon(
            name = "wc",
            style = MaterialIcon.Style.Outlined
        )
        val Web = MaterialIcon(
            name = "web",
            style = MaterialIcon.Style.Outlined
        )
        val WebAsset = MaterialIcon(
            name = "web_asset",
            style = MaterialIcon.Style.Outlined
        )
        val WebAssetOff = MaterialIcon(
            name = "web_asset_off",
            style = MaterialIcon.Style.Outlined
        )
        val WebStories = MaterialIcon(
            name = "web_stories",
            style = MaterialIcon.Style.Outlined
        )
        val Webhook = MaterialIcon(
            name = "webhook",
            style = MaterialIcon.Style.Outlined
        )
        val Weekend = MaterialIcon(
            name = "weekend",
            style = MaterialIcon.Style.Outlined
        )
        val West = MaterialIcon(
            name = "west",
            style = MaterialIcon.Style.Outlined
        )
        val Whatshot = MaterialIcon(
            name = "whatshot",
            style = MaterialIcon.Style.Outlined
        )
        val WheelchairPickup = MaterialIcon(
            name = "wheelchair_pickup",
            style = MaterialIcon.Style.Outlined
        )
        val WhereToVote = MaterialIcon(
            name = "where_to_vote",
            style = MaterialIcon.Style.Outlined
        )
        val Widgets = MaterialIcon(
            name = "widgets",
            style = MaterialIcon.Style.Outlined
        )
        val WidthFull = MaterialIcon(
            name = "width_full",
            style = MaterialIcon.Style.Outlined
        )
        val WidthNormal = MaterialIcon(
            name = "width_normal",
            style = MaterialIcon.Style.Outlined
        )
        val WidthWide = MaterialIcon(
            name = "width_wide",
            style = MaterialIcon.Style.Outlined
        )
        val Wifi = MaterialIcon(
            name = "wifi",
            style = MaterialIcon.Style.Outlined
        )
        val Wifi1Bar = MaterialIcon(
            name = "wifi_1_bar",
            style = MaterialIcon.Style.Outlined
        )
        val Wifi2Bar = MaterialIcon(
            name = "wifi_2_bar",
            style = MaterialIcon.Style.Outlined
        )
        val WifiCalling = MaterialIcon(
            name = "wifi_calling",
            style = MaterialIcon.Style.Outlined
        )
        val WifiCalling3 = MaterialIcon(
            name = "wifi_calling_3",
            style = MaterialIcon.Style.Outlined
        )
        val WifiChannel = MaterialIcon(
            name = "wifi_channel",
            style = MaterialIcon.Style.Outlined
        )
        val WifiFind = MaterialIcon(
            name = "wifi_find",
            style = MaterialIcon.Style.Outlined
        )
        val WifiLock = MaterialIcon(
            name = "wifi_lock",
            style = MaterialIcon.Style.Outlined
        )
        val WifiOff = MaterialIcon(
            name = "wifi_off",
            style = MaterialIcon.Style.Outlined
        )
        val WifiPassword = MaterialIcon(
            name = "wifi_password",
            style = MaterialIcon.Style.Outlined
        )
        val WifiProtectedSetup = MaterialIcon(
            name = "wifi_protected_setup",
            style = MaterialIcon.Style.Outlined
        )
        val WifiTethering = MaterialIcon(
            name = "wifi_tethering",
            style = MaterialIcon.Style.Outlined
        )
        val WifiTetheringError = MaterialIcon(
            name = "wifi_tethering_error",
            style = MaterialIcon.Style.Outlined
        )
        val WifiTetheringOff = MaterialIcon(
            name = "wifi_tethering_off",
            style = MaterialIcon.Style.Outlined
        )
        val WindPower = MaterialIcon(
            name = "wind_power",
            style = MaterialIcon.Style.Outlined
        )
        val Window = MaterialIcon(
            name = "window",
            style = MaterialIcon.Style.Outlined
        )
        val WineBar = MaterialIcon(
            name = "wine_bar",
            style = MaterialIcon.Style.Outlined
        )
        val Woman = MaterialIcon(
            name = "woman",
            style = MaterialIcon.Style.Outlined
        )
        val Woman2 = MaterialIcon(
            name = "woman_2",
            style = MaterialIcon.Style.Outlined
        )
        val Work = MaterialIcon(
            name = "work",
            style = MaterialIcon.Style.Outlined
        )
        val WorkHistory = MaterialIcon(
            name = "work_history",
            style = MaterialIcon.Style.Outlined
        )
        val WorkOff = MaterialIcon(
            name = "work_off",
            style = MaterialIcon.Style.Outlined
        )
        val WorkOutline = MaterialIcon(
            name = "work_outline",
            style = MaterialIcon.Style.Outlined
        )
        val WorkspacePremium = MaterialIcon(
            name = "workspace_premium",
            style = MaterialIcon.Style.Outlined
        )
        val Workspaces = MaterialIcon(
            name = "workspaces",
            style = MaterialIcon.Style.Outlined
        )
        val WrapText = MaterialIcon(
            name = "wrap_text",
            style = MaterialIcon.Style.Outlined
        )
        val WrongLocation = MaterialIcon(
            name = "wrong_location",
            style = MaterialIcon.Style.Outlined
        )
        val Wysiwyg = MaterialIcon(
            name = "wysiwyg",
            style = MaterialIcon.Style.Outlined
        )
        val Yard = MaterialIcon(
            name = "yard",
            style = MaterialIcon.Style.Outlined
        )
        val YoutubeSearchedFor = MaterialIcon(
            name = "youtube_searched_for",
            style = MaterialIcon.Style.Outlined
        )
        val ZoomIn = MaterialIcon(
            name = "zoom_in",
            style = MaterialIcon.Style.Outlined
        )
        val ZoomInMap = MaterialIcon(
            name = "zoom_in_map",
            style = MaterialIcon.Style.Outlined
        )
        val ZoomOut = MaterialIcon(
            name = "zoom_out",
            style = MaterialIcon.Style.Outlined
        )
        val ZoomOutMap = MaterialIcon(
            name = "zoom_out_map",
            style = MaterialIcon.Style.Outlined
        )
    }
    object TwoTone {
        val _10k = MaterialIcon(
            name = "10k",
            style = MaterialIcon.Style.TwoTone
        )
        val _10mp = MaterialIcon(
            name = "10mp",
            style = MaterialIcon.Style.TwoTone
        )
        val _11mp = MaterialIcon(
            name = "11mp",
            style = MaterialIcon.Style.TwoTone
        )
        val _123 = MaterialIcon(
            name = "123",
            style = MaterialIcon.Style.TwoTone
        )
        val _12mp = MaterialIcon(
            name = "12mp",
            style = MaterialIcon.Style.TwoTone
        )
        val _13mp = MaterialIcon(
            name = "13mp",
            style = MaterialIcon.Style.TwoTone
        )
        val _14mp = MaterialIcon(
            name = "14mp",
            style = MaterialIcon.Style.TwoTone
        )
        val _15mp = MaterialIcon(
            name = "15mp",
            style = MaterialIcon.Style.TwoTone
        )
        val _16mp = MaterialIcon(
            name = "16mp",
            style = MaterialIcon.Style.TwoTone
        )
        val _17mp = MaterialIcon(
            name = "17mp",
            style = MaterialIcon.Style.TwoTone
        )
        val _18UpRating = MaterialIcon(
            name = "18_up_rating",
            style = MaterialIcon.Style.TwoTone
        )
        val _18mp = MaterialIcon(
            name = "18mp",
            style = MaterialIcon.Style.TwoTone
        )
        val _19mp = MaterialIcon(
            name = "19mp",
            style = MaterialIcon.Style.TwoTone
        )
        val _1k = MaterialIcon(
            name = "1k",
            style = MaterialIcon.Style.TwoTone
        )
        val _1kPlus = MaterialIcon(
            name = "1k_plus",
            style = MaterialIcon.Style.TwoTone
        )
        val _1xMobiledata = MaterialIcon(
            name = "1x_mobiledata",
            style = MaterialIcon.Style.TwoTone
        )
        val _20mp = MaterialIcon(
            name = "20mp",
            style = MaterialIcon.Style.TwoTone
        )
        val _21mp = MaterialIcon(
            name = "21mp",
            style = MaterialIcon.Style.TwoTone
        )
        val _22mp = MaterialIcon(
            name = "22mp",
            style = MaterialIcon.Style.TwoTone
        )
        val _23mp = MaterialIcon(
            name = "23mp",
            style = MaterialIcon.Style.TwoTone
        )
        val _24mp = MaterialIcon(
            name = "24mp",
            style = MaterialIcon.Style.TwoTone
        )
        val _2k = MaterialIcon(
            name = "2k",
            style = MaterialIcon.Style.TwoTone
        )
        val _2kPlus = MaterialIcon(
            name = "2k_plus",
            style = MaterialIcon.Style.TwoTone
        )
        val _2mp = MaterialIcon(
            name = "2mp",
            style = MaterialIcon.Style.TwoTone
        )
        val _30fps = MaterialIcon(
            name = "30fps",
            style = MaterialIcon.Style.TwoTone
        )
        val _30fpsSelect = MaterialIcon(
            name = "30fps_select",
            style = MaterialIcon.Style.TwoTone
        )
        val _360 = MaterialIcon(
            name = "360",
            style = MaterialIcon.Style.TwoTone
        )
        val _3dRotation = MaterialIcon(
            name = "3d_rotation",
            style = MaterialIcon.Style.TwoTone
        )
        val _3gMobiledata = MaterialIcon(
            name = "3g_mobiledata",
            style = MaterialIcon.Style.TwoTone
        )
        val _3k = MaterialIcon(
            name = "3k",
            style = MaterialIcon.Style.TwoTone
        )
        val _3kPlus = MaterialIcon(
            name = "3k_plus",
            style = MaterialIcon.Style.TwoTone
        )
        val _3mp = MaterialIcon(
            name = "3mp",
            style = MaterialIcon.Style.TwoTone
        )
        val _3p = MaterialIcon(
            name = "3p",
            style = MaterialIcon.Style.TwoTone
        )
        val _4gMobiledata = MaterialIcon(
            name = "4g_mobiledata",
            style = MaterialIcon.Style.TwoTone
        )
        val _4gPlusMobiledata = MaterialIcon(
            name = "4g_plus_mobiledata",
            style = MaterialIcon.Style.TwoTone
        )
        val _4k = MaterialIcon(
            name = "4k",
            style = MaterialIcon.Style.TwoTone
        )
        val _4kPlus = MaterialIcon(
            name = "4k_plus",
            style = MaterialIcon.Style.TwoTone
        )
        val _4mp = MaterialIcon(
            name = "4mp",
            style = MaterialIcon.Style.TwoTone
        )
        val _5g = MaterialIcon(
            name = "5g",
            style = MaterialIcon.Style.TwoTone
        )
        val _5k = MaterialIcon(
            name = "5k",
            style = MaterialIcon.Style.TwoTone
        )
        val _5kPlus = MaterialIcon(
            name = "5k_plus",
            style = MaterialIcon.Style.TwoTone
        )
        val _5mp = MaterialIcon(
            name = "5mp",
            style = MaterialIcon.Style.TwoTone
        )
        val _60fps = MaterialIcon(
            name = "60fps",
            style = MaterialIcon.Style.TwoTone
        )
        val _60fpsSelect = MaterialIcon(
            name = "60fps_select",
            style = MaterialIcon.Style.TwoTone
        )
        val _6FtApart = MaterialIcon(
            name = "6_ft_apart",
            style = MaterialIcon.Style.TwoTone
        )
        val _6k = MaterialIcon(
            name = "6k",
            style = MaterialIcon.Style.TwoTone
        )
        val _6kPlus = MaterialIcon(
            name = "6k_plus",
            style = MaterialIcon.Style.TwoTone
        )
        val _6mp = MaterialIcon(
            name = "6mp",
            style = MaterialIcon.Style.TwoTone
        )
        val _7k = MaterialIcon(
            name = "7k",
            style = MaterialIcon.Style.TwoTone
        )
        val _7kPlus = MaterialIcon(
            name = "7k_plus",
            style = MaterialIcon.Style.TwoTone
        )
        val _7mp = MaterialIcon(
            name = "7mp",
            style = MaterialIcon.Style.TwoTone
        )
        val _8k = MaterialIcon(
            name = "8k",
            style = MaterialIcon.Style.TwoTone
        )
        val _8kPlus = MaterialIcon(
            name = "8k_plus",
            style = MaterialIcon.Style.TwoTone
        )
        val _8mp = MaterialIcon(
            name = "8mp",
            style = MaterialIcon.Style.TwoTone
        )
        val _9k = MaterialIcon(
            name = "9k",
            style = MaterialIcon.Style.TwoTone
        )
        val _9kPlus = MaterialIcon(
            name = "9k_plus",
            style = MaterialIcon.Style.TwoTone
        )
        val _9mp = MaterialIcon(
            name = "9mp",
            style = MaterialIcon.Style.TwoTone
        )
        val Abc = MaterialIcon(
            name = "abc",
            style = MaterialIcon.Style.TwoTone
        )
        val AcUnit = MaterialIcon(
            name = "ac_unit",
            style = MaterialIcon.Style.TwoTone
        )
        val AccessAlarm = MaterialIcon(
            name = "access_alarm",
            style = MaterialIcon.Style.TwoTone
        )
        val AccessAlarms = MaterialIcon(
            name = "access_alarms",
            style = MaterialIcon.Style.TwoTone
        )
        val AccessTime = MaterialIcon(
            name = "access_time",
            style = MaterialIcon.Style.TwoTone
        )
        val AccessTimeFilled = MaterialIcon(
            name = "access_time_filled",
            style = MaterialIcon.Style.TwoTone
        )
        val Accessibility = MaterialIcon(
            name = "accessibility",
            style = MaterialIcon.Style.TwoTone
        )
        val AccessibilityNew = MaterialIcon(
            name = "accessibility_new",
            style = MaterialIcon.Style.TwoTone
        )
        val Accessible = MaterialIcon(
            name = "accessible",
            style = MaterialIcon.Style.TwoTone
        )
        val AccessibleForward = MaterialIcon(
            name = "accessible_forward",
            style = MaterialIcon.Style.TwoTone
        )
        val AccountBalance = MaterialIcon(
            name = "account_balance",
            style = MaterialIcon.Style.TwoTone
        )
        val AccountBalanceWallet = MaterialIcon(
            name = "account_balance_wallet",
            style = MaterialIcon.Style.TwoTone
        )
        val AccountBox = MaterialIcon(
            name = "account_box",
            style = MaterialIcon.Style.TwoTone
        )
        val AccountCircle = MaterialIcon(
            name = "account_circle",
            style = MaterialIcon.Style.TwoTone
        )
        val AccountTree = MaterialIcon(
            name = "account_tree",
            style = MaterialIcon.Style.TwoTone
        )
        val AdUnits = MaterialIcon(
            name = "ad_units",
            style = MaterialIcon.Style.TwoTone
        )
        val Adb = MaterialIcon(
            name = "adb",
            style = MaterialIcon.Style.TwoTone
        )
        val Add = MaterialIcon(
            name = "add",
            style = MaterialIcon.Style.TwoTone
        )
        val AddAPhoto = MaterialIcon(
            name = "add_a_photo",
            style = MaterialIcon.Style.TwoTone
        )
        val AddAlarm = MaterialIcon(
            name = "add_alarm",
            style = MaterialIcon.Style.TwoTone
        )
        val AddAlert = MaterialIcon(
            name = "add_alert",
            style = MaterialIcon.Style.TwoTone
        )
        val AddBox = MaterialIcon(
            name = "add_box",
            style = MaterialIcon.Style.TwoTone
        )
        val AddBusiness = MaterialIcon(
            name = "add_business",
            style = MaterialIcon.Style.TwoTone
        )
        val AddCard = MaterialIcon(
            name = "add_card",
            style = MaterialIcon.Style.TwoTone
        )
        val AddChart = MaterialIcon(
            name = "add_chart",
            style = MaterialIcon.Style.TwoTone
        )
        val AddCircle = MaterialIcon(
            name = "add_circle",
            style = MaterialIcon.Style.TwoTone
        )
        val AddCircleOutline = MaterialIcon(
            name = "add_circle_outline",
            style = MaterialIcon.Style.TwoTone
        )
        val AddComment = MaterialIcon(
            name = "add_comment",
            style = MaterialIcon.Style.TwoTone
        )
        val AddHome = MaterialIcon(
            name = "add_home",
            style = MaterialIcon.Style.TwoTone
        )
        val AddHomeWork = MaterialIcon(
            name = "add_home_work",
            style = MaterialIcon.Style.TwoTone
        )
        val AddIcCall = MaterialIcon(
            name = "add_ic_call",
            style = MaterialIcon.Style.TwoTone
        )
        val AddLink = MaterialIcon(
            name = "add_link",
            style = MaterialIcon.Style.TwoTone
        )
        val AddLocation = MaterialIcon(
            name = "add_location",
            style = MaterialIcon.Style.TwoTone
        )
        val AddLocationAlt = MaterialIcon(
            name = "add_location_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val AddModerator = MaterialIcon(
            name = "add_moderator",
            style = MaterialIcon.Style.TwoTone
        )
        val AddPhotoAlternate = MaterialIcon(
            name = "add_photo_alternate",
            style = MaterialIcon.Style.TwoTone
        )
        val AddReaction = MaterialIcon(
            name = "add_reaction",
            style = MaterialIcon.Style.TwoTone
        )
        val AddRoad = MaterialIcon(
            name = "add_road",
            style = MaterialIcon.Style.TwoTone
        )
        val AddShoppingCart = MaterialIcon(
            name = "add_shopping_cart",
            style = MaterialIcon.Style.TwoTone
        )
        val AddTask = MaterialIcon(
            name = "add_task",
            style = MaterialIcon.Style.TwoTone
        )
        val AddToDrive = MaterialIcon(
            name = "add_to_drive",
            style = MaterialIcon.Style.TwoTone
        )
        val AddToHomeScreen = MaterialIcon(
            name = "add_to_home_screen",
            style = MaterialIcon.Style.TwoTone
        )
        val AddToPhotos = MaterialIcon(
            name = "add_to_photos",
            style = MaterialIcon.Style.TwoTone
        )
        val AddToQueue = MaterialIcon(
            name = "add_to_queue",
            style = MaterialIcon.Style.TwoTone
        )
        val Addchart = MaterialIcon(
            name = "addchart",
            style = MaterialIcon.Style.TwoTone
        )
        val AdfScanner = MaterialIcon(
            name = "adf_scanner",
            style = MaterialIcon.Style.TwoTone
        )
        val Adjust = MaterialIcon(
            name = "adjust",
            style = MaterialIcon.Style.TwoTone
        )
        val AdminPanelSettings = MaterialIcon(
            name = "admin_panel_settings",
            style = MaterialIcon.Style.TwoTone
        )
        val AdsClick = MaterialIcon(
            name = "ads_click",
            style = MaterialIcon.Style.TwoTone
        )
        val Agriculture = MaterialIcon(
            name = "agriculture",
            style = MaterialIcon.Style.TwoTone
        )
        val Air = MaterialIcon(
            name = "air",
            style = MaterialIcon.Style.TwoTone
        )
        val AirlineSeatFlat = MaterialIcon(
            name = "airline_seat_flat",
            style = MaterialIcon.Style.TwoTone
        )
        val AirlineSeatFlatAngled = MaterialIcon(
            name = "airline_seat_flat_angled",
            style = MaterialIcon.Style.TwoTone
        )
        val AirlineSeatIndividualSuite = MaterialIcon(
            name = "airline_seat_individual_suite",
            style = MaterialIcon.Style.TwoTone
        )
        val AirlineSeatLegroomExtra = MaterialIcon(
            name = "airline_seat_legroom_extra",
            style = MaterialIcon.Style.TwoTone
        )
        val AirlineSeatLegroomNormal = MaterialIcon(
            name = "airline_seat_legroom_normal",
            style = MaterialIcon.Style.TwoTone
        )
        val AirlineSeatLegroomReduced = MaterialIcon(
            name = "airline_seat_legroom_reduced",
            style = MaterialIcon.Style.TwoTone
        )
        val AirlineSeatReclineExtra = MaterialIcon(
            name = "airline_seat_recline_extra",
            style = MaterialIcon.Style.TwoTone
        )
        val AirlineSeatReclineNormal = MaterialIcon(
            name = "airline_seat_recline_normal",
            style = MaterialIcon.Style.TwoTone
        )
        val AirlineStops = MaterialIcon(
            name = "airline_stops",
            style = MaterialIcon.Style.TwoTone
        )
        val Airlines = MaterialIcon(
            name = "airlines",
            style = MaterialIcon.Style.TwoTone
        )
        val AirplaneTicket = MaterialIcon(
            name = "airplane_ticket",
            style = MaterialIcon.Style.TwoTone
        )
        val AirplanemodeActive = MaterialIcon(
            name = "airplanemode_active",
            style = MaterialIcon.Style.TwoTone
        )
        val AirplanemodeInactive = MaterialIcon(
            name = "airplanemode_inactive",
            style = MaterialIcon.Style.TwoTone
        )
        val Airplay = MaterialIcon(
            name = "airplay",
            style = MaterialIcon.Style.TwoTone
        )
        val AirportShuttle = MaterialIcon(
            name = "airport_shuttle",
            style = MaterialIcon.Style.TwoTone
        )
        val Alarm = MaterialIcon(
            name = "alarm",
            style = MaterialIcon.Style.TwoTone
        )
        val AlarmAdd = MaterialIcon(
            name = "alarm_add",
            style = MaterialIcon.Style.TwoTone
        )
        val AlarmOff = MaterialIcon(
            name = "alarm_off",
            style = MaterialIcon.Style.TwoTone
        )
        val AlarmOn = MaterialIcon(
            name = "alarm_on",
            style = MaterialIcon.Style.TwoTone
        )
        val Album = MaterialIcon(
            name = "album",
            style = MaterialIcon.Style.TwoTone
        )
        val AlignHorizontalCenter = MaterialIcon(
            name = "align_horizontal_center",
            style = MaterialIcon.Style.TwoTone
        )
        val AlignHorizontalLeft = MaterialIcon(
            name = "align_horizontal_left",
            style = MaterialIcon.Style.TwoTone
        )
        val AlignHorizontalRight = MaterialIcon(
            name = "align_horizontal_right",
            style = MaterialIcon.Style.TwoTone
        )
        val AlignVerticalBottom = MaterialIcon(
            name = "align_vertical_bottom",
            style = MaterialIcon.Style.TwoTone
        )
        val AlignVerticalCenter = MaterialIcon(
            name = "align_vertical_center",
            style = MaterialIcon.Style.TwoTone
        )
        val AlignVerticalTop = MaterialIcon(
            name = "align_vertical_top",
            style = MaterialIcon.Style.TwoTone
        )
        val AllInbox = MaterialIcon(
            name = "all_inbox",
            style = MaterialIcon.Style.TwoTone
        )
        val AllInclusive = MaterialIcon(
            name = "all_inclusive",
            style = MaterialIcon.Style.TwoTone
        )
        val AllOut = MaterialIcon(
            name = "all_out",
            style = MaterialIcon.Style.TwoTone
        )
        val AltRoute = MaterialIcon(
            name = "alt_route",
            style = MaterialIcon.Style.TwoTone
        )
        val AlternateEmail = MaterialIcon(
            name = "alternate_email",
            style = MaterialIcon.Style.TwoTone
        )
        val Analytics = MaterialIcon(
            name = "analytics",
            style = MaterialIcon.Style.TwoTone
        )
        val Anchor = MaterialIcon(
            name = "anchor",
            style = MaterialIcon.Style.TwoTone
        )
        val Android = MaterialIcon(
            name = "android",
            style = MaterialIcon.Style.TwoTone
        )
        val Animation = MaterialIcon(
            name = "animation",
            style = MaterialIcon.Style.TwoTone
        )
        val Announcement = MaterialIcon(
            name = "announcement",
            style = MaterialIcon.Style.TwoTone
        )
        val Aod = MaterialIcon(
            name = "aod",
            style = MaterialIcon.Style.TwoTone
        )
        val Apartment = MaterialIcon(
            name = "apartment",
            style = MaterialIcon.Style.TwoTone
        )
        val Api = MaterialIcon(
            name = "api",
            style = MaterialIcon.Style.TwoTone
        )
        val AppBlocking = MaterialIcon(
            name = "app_blocking",
            style = MaterialIcon.Style.TwoTone
        )
        val AppRegistration = MaterialIcon(
            name = "app_registration",
            style = MaterialIcon.Style.TwoTone
        )
        val AppSettingsAlt = MaterialIcon(
            name = "app_settings_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val AppShortcut = MaterialIcon(
            name = "app_shortcut",
            style = MaterialIcon.Style.TwoTone
        )
        val Approval = MaterialIcon(
            name = "approval",
            style = MaterialIcon.Style.TwoTone
        )
        val Apps = MaterialIcon(
            name = "apps",
            style = MaterialIcon.Style.TwoTone
        )
        val AppsOutage = MaterialIcon(
            name = "apps_outage",
            style = MaterialIcon.Style.TwoTone
        )
        val Architecture = MaterialIcon(
            name = "architecture",
            style = MaterialIcon.Style.TwoTone
        )
        val Archive = MaterialIcon(
            name = "archive",
            style = MaterialIcon.Style.TwoTone
        )
        val AreaChart = MaterialIcon(
            name = "area_chart",
            style = MaterialIcon.Style.TwoTone
        )
        val ArrowBack = MaterialIcon(
            name = "arrow_back",
            style = MaterialIcon.Style.TwoTone
        )
        val ArrowBackIos = MaterialIcon(
            name = "arrow_back_ios",
            style = MaterialIcon.Style.TwoTone
        )
        val ArrowBackIosNew = MaterialIcon(
            name = "arrow_back_ios_new",
            style = MaterialIcon.Style.TwoTone
        )
        val ArrowCircleDown = MaterialIcon(
            name = "arrow_circle_down",
            style = MaterialIcon.Style.TwoTone
        )
        val ArrowCircleLeft = MaterialIcon(
            name = "arrow_circle_left",
            style = MaterialIcon.Style.TwoTone
        )
        val ArrowCircleRight = MaterialIcon(
            name = "arrow_circle_right",
            style = MaterialIcon.Style.TwoTone
        )
        val ArrowCircleUp = MaterialIcon(
            name = "arrow_circle_up",
            style = MaterialIcon.Style.TwoTone
        )
        val ArrowDownward = MaterialIcon(
            name = "arrow_downward",
            style = MaterialIcon.Style.TwoTone
        )
        val ArrowDropDown = MaterialIcon(
            name = "arrow_drop_down",
            style = MaterialIcon.Style.TwoTone
        )
        val ArrowDropDownCircle = MaterialIcon(
            name = "arrow_drop_down_circle",
            style = MaterialIcon.Style.TwoTone
        )
        val ArrowDropUp = MaterialIcon(
            name = "arrow_drop_up",
            style = MaterialIcon.Style.TwoTone
        )
        val ArrowForward = MaterialIcon(
            name = "arrow_forward",
            style = MaterialIcon.Style.TwoTone
        )
        val ArrowForwardIos = MaterialIcon(
            name = "arrow_forward_ios",
            style = MaterialIcon.Style.TwoTone
        )
        val ArrowLeft = MaterialIcon(
            name = "arrow_left",
            style = MaterialIcon.Style.TwoTone
        )
        val ArrowOutward = MaterialIcon(
            name = "arrow_outward",
            style = MaterialIcon.Style.TwoTone
        )
        val ArrowRight = MaterialIcon(
            name = "arrow_right",
            style = MaterialIcon.Style.TwoTone
        )
        val ArrowRightAlt = MaterialIcon(
            name = "arrow_right_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val ArrowUpward = MaterialIcon(
            name = "arrow_upward",
            style = MaterialIcon.Style.TwoTone
        )
        val ArtTrack = MaterialIcon(
            name = "art_track",
            style = MaterialIcon.Style.TwoTone
        )
        val Article = MaterialIcon(
            name = "article",
            style = MaterialIcon.Style.TwoTone
        )
        val AspectRatio = MaterialIcon(
            name = "aspect_ratio",
            style = MaterialIcon.Style.TwoTone
        )
        val Assessment = MaterialIcon(
            name = "assessment",
            style = MaterialIcon.Style.TwoTone
        )
        val Assignment = MaterialIcon(
            name = "assignment",
            style = MaterialIcon.Style.TwoTone
        )
        val AssignmentInd = MaterialIcon(
            name = "assignment_ind",
            style = MaterialIcon.Style.TwoTone
        )
        val AssignmentLate = MaterialIcon(
            name = "assignment_late",
            style = MaterialIcon.Style.TwoTone
        )
        val AssignmentReturn = MaterialIcon(
            name = "assignment_return",
            style = MaterialIcon.Style.TwoTone
        )
        val AssignmentReturned = MaterialIcon(
            name = "assignment_returned",
            style = MaterialIcon.Style.TwoTone
        )
        val AssignmentTurnedIn = MaterialIcon(
            name = "assignment_turned_in",
            style = MaterialIcon.Style.TwoTone
        )
        val AssistWalker = MaterialIcon(
            name = "assist_walker",
            style = MaterialIcon.Style.TwoTone
        )
        val Assistant = MaterialIcon(
            name = "assistant",
            style = MaterialIcon.Style.TwoTone
        )
        val AssistantDirection = MaterialIcon(
            name = "assistant_direction",
            style = MaterialIcon.Style.TwoTone
        )
        val AssistantPhoto = MaterialIcon(
            name = "assistant_photo",
            style = MaterialIcon.Style.TwoTone
        )
        val AssuredWorkload = MaterialIcon(
            name = "assured_workload",
            style = MaterialIcon.Style.TwoTone
        )
        val Atm = MaterialIcon(
            name = "atm",
            style = MaterialIcon.Style.TwoTone
        )
        val AttachEmail = MaterialIcon(
            name = "attach_email",
            style = MaterialIcon.Style.TwoTone
        )
        val AttachFile = MaterialIcon(
            name = "attach_file",
            style = MaterialIcon.Style.TwoTone
        )
        val AttachMoney = MaterialIcon(
            name = "attach_money",
            style = MaterialIcon.Style.TwoTone
        )
        val Attachment = MaterialIcon(
            name = "attachment",
            style = MaterialIcon.Style.TwoTone
        )
        val Attractions = MaterialIcon(
            name = "attractions",
            style = MaterialIcon.Style.TwoTone
        )
        val Attribution = MaterialIcon(
            name = "attribution",
            style = MaterialIcon.Style.TwoTone
        )
        val AudioFile = MaterialIcon(
            name = "audio_file",
            style = MaterialIcon.Style.TwoTone
        )
        val Audiotrack = MaterialIcon(
            name = "audiotrack",
            style = MaterialIcon.Style.TwoTone
        )
        val AutoAwesome = MaterialIcon(
            name = "auto_awesome",
            style = MaterialIcon.Style.TwoTone
        )
        val AutoAwesomeMosaic = MaterialIcon(
            name = "auto_awesome_mosaic",
            style = MaterialIcon.Style.TwoTone
        )
        val AutoAwesomeMotion = MaterialIcon(
            name = "auto_awesome_motion",
            style = MaterialIcon.Style.TwoTone
        )
        val AutoDelete = MaterialIcon(
            name = "auto_delete",
            style = MaterialIcon.Style.TwoTone
        )
        val AutoFixHigh = MaterialIcon(
            name = "auto_fix_high",
            style = MaterialIcon.Style.TwoTone
        )
        val AutoFixNormal = MaterialIcon(
            name = "auto_fix_normal",
            style = MaterialIcon.Style.TwoTone
        )
        val AutoFixOff = MaterialIcon(
            name = "auto_fix_off",
            style = MaterialIcon.Style.TwoTone
        )
        val AutoGraph = MaterialIcon(
            name = "auto_graph",
            style = MaterialIcon.Style.TwoTone
        )
        val AutoMode = MaterialIcon(
            name = "auto_mode",
            style = MaterialIcon.Style.TwoTone
        )
        val AutoStories = MaterialIcon(
            name = "auto_stories",
            style = MaterialIcon.Style.TwoTone
        )
        val AutofpsSelect = MaterialIcon(
            name = "autofps_select",
            style = MaterialIcon.Style.TwoTone
        )
        val Autorenew = MaterialIcon(
            name = "autorenew",
            style = MaterialIcon.Style.TwoTone
        )
        val AvTimer = MaterialIcon(
            name = "av_timer",
            style = MaterialIcon.Style.TwoTone
        )
        val BabyChangingStation = MaterialIcon(
            name = "baby_changing_station",
            style = MaterialIcon.Style.TwoTone
        )
        val BackHand = MaterialIcon(
            name = "back_hand",
            style = MaterialIcon.Style.TwoTone
        )
        val Backpack = MaterialIcon(
            name = "backpack",
            style = MaterialIcon.Style.TwoTone
        )
        val Backspace = MaterialIcon(
            name = "backspace",
            style = MaterialIcon.Style.TwoTone
        )
        val Backup = MaterialIcon(
            name = "backup",
            style = MaterialIcon.Style.TwoTone
        )
        val BackupTable = MaterialIcon(
            name = "backup_table",
            style = MaterialIcon.Style.TwoTone
        )
        val Badge = MaterialIcon(
            name = "badge",
            style = MaterialIcon.Style.TwoTone
        )
        val BakeryDining = MaterialIcon(
            name = "bakery_dining",
            style = MaterialIcon.Style.TwoTone
        )
        val Balance = MaterialIcon(
            name = "balance",
            style = MaterialIcon.Style.TwoTone
        )
        val Balcony = MaterialIcon(
            name = "balcony",
            style = MaterialIcon.Style.TwoTone
        )
        val Ballot = MaterialIcon(
            name = "ballot",
            style = MaterialIcon.Style.TwoTone
        )
        val BarChart = MaterialIcon(
            name = "bar_chart",
            style = MaterialIcon.Style.TwoTone
        )
        val BatchPrediction = MaterialIcon(
            name = "batch_prediction",
            style = MaterialIcon.Style.TwoTone
        )
        val Bathroom = MaterialIcon(
            name = "bathroom",
            style = MaterialIcon.Style.TwoTone
        )
        val Bathtub = MaterialIcon(
            name = "bathtub",
            style = MaterialIcon.Style.TwoTone
        )
        val Battery0Bar = MaterialIcon(
            name = "battery_0_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val Battery1Bar = MaterialIcon(
            name = "battery_1_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val Battery2Bar = MaterialIcon(
            name = "battery_2_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val Battery3Bar = MaterialIcon(
            name = "battery_3_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val Battery4Bar = MaterialIcon(
            name = "battery_4_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val Battery5Bar = MaterialIcon(
            name = "battery_5_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val Battery6Bar = MaterialIcon(
            name = "battery_6_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val BatteryAlert = MaterialIcon(
            name = "battery_alert",
            style = MaterialIcon.Style.TwoTone
        )
        val BatteryChargingFull = MaterialIcon(
            name = "battery_charging_full",
            style = MaterialIcon.Style.TwoTone
        )
        val BatteryFull = MaterialIcon(
            name = "battery_full",
            style = MaterialIcon.Style.TwoTone
        )
        val BatterySaver = MaterialIcon(
            name = "battery_saver",
            style = MaterialIcon.Style.TwoTone
        )
        val BatteryStd = MaterialIcon(
            name = "battery_std",
            style = MaterialIcon.Style.TwoTone
        )
        val BatteryUnknown = MaterialIcon(
            name = "battery_unknown",
            style = MaterialIcon.Style.TwoTone
        )
        val BeachAccess = MaterialIcon(
            name = "beach_access",
            style = MaterialIcon.Style.TwoTone
        )
        val Bed = MaterialIcon(
            name = "bed",
            style = MaterialIcon.Style.TwoTone
        )
        val BedroomBaby = MaterialIcon(
            name = "bedroom_baby",
            style = MaterialIcon.Style.TwoTone
        )
        val BedroomChild = MaterialIcon(
            name = "bedroom_child",
            style = MaterialIcon.Style.TwoTone
        )
        val BedroomParent = MaterialIcon(
            name = "bedroom_parent",
            style = MaterialIcon.Style.TwoTone
        )
        val Bedtime = MaterialIcon(
            name = "bedtime",
            style = MaterialIcon.Style.TwoTone
        )
        val BedtimeOff = MaterialIcon(
            name = "bedtime_off",
            style = MaterialIcon.Style.TwoTone
        )
        val Beenhere = MaterialIcon(
            name = "beenhere",
            style = MaterialIcon.Style.TwoTone
        )
        val Bento = MaterialIcon(
            name = "bento",
            style = MaterialIcon.Style.TwoTone
        )
        val BikeScooter = MaterialIcon(
            name = "bike_scooter",
            style = MaterialIcon.Style.TwoTone
        )
        val Biotech = MaterialIcon(
            name = "biotech",
            style = MaterialIcon.Style.TwoTone
        )
        val Blender = MaterialIcon(
            name = "blender",
            style = MaterialIcon.Style.TwoTone
        )
        val Blind = MaterialIcon(
            name = "blind",
            style = MaterialIcon.Style.TwoTone
        )
        val Blinds = MaterialIcon(
            name = "blinds",
            style = MaterialIcon.Style.TwoTone
        )
        val BlindsClosed = MaterialIcon(
            name = "blinds_closed",
            style = MaterialIcon.Style.TwoTone
        )
        val Block = MaterialIcon(
            name = "block",
            style = MaterialIcon.Style.TwoTone
        )
        val Bloodtype = MaterialIcon(
            name = "bloodtype",
            style = MaterialIcon.Style.TwoTone
        )
        val Bluetooth = MaterialIcon(
            name = "bluetooth",
            style = MaterialIcon.Style.TwoTone
        )
        val BluetoothAudio = MaterialIcon(
            name = "bluetooth_audio",
            style = MaterialIcon.Style.TwoTone
        )
        val BluetoothConnected = MaterialIcon(
            name = "bluetooth_connected",
            style = MaterialIcon.Style.TwoTone
        )
        val BluetoothDisabled = MaterialIcon(
            name = "bluetooth_disabled",
            style = MaterialIcon.Style.TwoTone
        )
        val BluetoothDrive = MaterialIcon(
            name = "bluetooth_drive",
            style = MaterialIcon.Style.TwoTone
        )
        val BluetoothSearching = MaterialIcon(
            name = "bluetooth_searching",
            style = MaterialIcon.Style.TwoTone
        )
        val BlurCircular = MaterialIcon(
            name = "blur_circular",
            style = MaterialIcon.Style.TwoTone
        )
        val BlurLinear = MaterialIcon(
            name = "blur_linear",
            style = MaterialIcon.Style.TwoTone
        )
        val BlurOff = MaterialIcon(
            name = "blur_off",
            style = MaterialIcon.Style.TwoTone
        )
        val BlurOn = MaterialIcon(
            name = "blur_on",
            style = MaterialIcon.Style.TwoTone
        )
        val Bolt = MaterialIcon(
            name = "bolt",
            style = MaterialIcon.Style.TwoTone
        )
        val Book = MaterialIcon(
            name = "book",
            style = MaterialIcon.Style.TwoTone
        )
        val BookOnline = MaterialIcon(
            name = "book_online",
            style = MaterialIcon.Style.TwoTone
        )
        val Bookmark = MaterialIcon(
            name = "bookmark",
            style = MaterialIcon.Style.TwoTone
        )
        val BookmarkAdd = MaterialIcon(
            name = "bookmark_add",
            style = MaterialIcon.Style.TwoTone
        )
        val BookmarkAdded = MaterialIcon(
            name = "bookmark_added",
            style = MaterialIcon.Style.TwoTone
        )
        val BookmarkBorder = MaterialIcon(
            name = "bookmark_border",
            style = MaterialIcon.Style.TwoTone
        )
        val BookmarkRemove = MaterialIcon(
            name = "bookmark_remove",
            style = MaterialIcon.Style.TwoTone
        )
        val Bookmarks = MaterialIcon(
            name = "bookmarks",
            style = MaterialIcon.Style.TwoTone
        )
        val BorderAll = MaterialIcon(
            name = "border_all",
            style = MaterialIcon.Style.TwoTone
        )
        val BorderBottom = MaterialIcon(
            name = "border_bottom",
            style = MaterialIcon.Style.TwoTone
        )
        val BorderClear = MaterialIcon(
            name = "border_clear",
            style = MaterialIcon.Style.TwoTone
        )
        val BorderColor = MaterialIcon(
            name = "border_color",
            style = MaterialIcon.Style.TwoTone
        )
        val BorderHorizontal = MaterialIcon(
            name = "border_horizontal",
            style = MaterialIcon.Style.TwoTone
        )
        val BorderInner = MaterialIcon(
            name = "border_inner",
            style = MaterialIcon.Style.TwoTone
        )
        val BorderLeft = MaterialIcon(
            name = "border_left",
            style = MaterialIcon.Style.TwoTone
        )
        val BorderOuter = MaterialIcon(
            name = "border_outer",
            style = MaterialIcon.Style.TwoTone
        )
        val BorderRight = MaterialIcon(
            name = "border_right",
            style = MaterialIcon.Style.TwoTone
        )
        val BorderStyle = MaterialIcon(
            name = "border_style",
            style = MaterialIcon.Style.TwoTone
        )
        val BorderTop = MaterialIcon(
            name = "border_top",
            style = MaterialIcon.Style.TwoTone
        )
        val BorderVertical = MaterialIcon(
            name = "border_vertical",
            style = MaterialIcon.Style.TwoTone
        )
        val Boy = MaterialIcon(
            name = "boy",
            style = MaterialIcon.Style.TwoTone
        )
        val BrandingWatermark = MaterialIcon(
            name = "branding_watermark",
            style = MaterialIcon.Style.TwoTone
        )
        val BreakfastDining = MaterialIcon(
            name = "breakfast_dining",
            style = MaterialIcon.Style.TwoTone
        )
        val Brightness1 = MaterialIcon(
            name = "brightness_1",
            style = MaterialIcon.Style.TwoTone
        )
        val Brightness2 = MaterialIcon(
            name = "brightness_2",
            style = MaterialIcon.Style.TwoTone
        )
        val Brightness3 = MaterialIcon(
            name = "brightness_3",
            style = MaterialIcon.Style.TwoTone
        )
        val Brightness4 = MaterialIcon(
            name = "brightness_4",
            style = MaterialIcon.Style.TwoTone
        )
        val Brightness5 = MaterialIcon(
            name = "brightness_5",
            style = MaterialIcon.Style.TwoTone
        )
        val Brightness6 = MaterialIcon(
            name = "brightness_6",
            style = MaterialIcon.Style.TwoTone
        )
        val Brightness7 = MaterialIcon(
            name = "brightness_7",
            style = MaterialIcon.Style.TwoTone
        )
        val BrightnessAuto = MaterialIcon(
            name = "brightness_auto",
            style = MaterialIcon.Style.TwoTone
        )
        val BrightnessHigh = MaterialIcon(
            name = "brightness_high",
            style = MaterialIcon.Style.TwoTone
        )
        val BrightnessLow = MaterialIcon(
            name = "brightness_low",
            style = MaterialIcon.Style.TwoTone
        )
        val BrightnessMedium = MaterialIcon(
            name = "brightness_medium",
            style = MaterialIcon.Style.TwoTone
        )
        val BroadcastOnHome = MaterialIcon(
            name = "broadcast_on_home",
            style = MaterialIcon.Style.TwoTone
        )
        val BroadcastOnPersonal = MaterialIcon(
            name = "broadcast_on_personal",
            style = MaterialIcon.Style.TwoTone
        )
        val BrokenImage = MaterialIcon(
            name = "broken_image",
            style = MaterialIcon.Style.TwoTone
        )
        val BrowseGallery = MaterialIcon(
            name = "browse_gallery",
            style = MaterialIcon.Style.TwoTone
        )
        val BrowserNotSupported = MaterialIcon(
            name = "browser_not_supported",
            style = MaterialIcon.Style.TwoTone
        )
        val BrowserUpdated = MaterialIcon(
            name = "browser_updated",
            style = MaterialIcon.Style.TwoTone
        )
        val BrunchDining = MaterialIcon(
            name = "brunch_dining",
            style = MaterialIcon.Style.TwoTone
        )
        val Brush = MaterialIcon(
            name = "brush",
            style = MaterialIcon.Style.TwoTone
        )
        val BubbleChart = MaterialIcon(
            name = "bubble_chart",
            style = MaterialIcon.Style.TwoTone
        )
        val BugReport = MaterialIcon(
            name = "bug_report",
            style = MaterialIcon.Style.TwoTone
        )
        val Build = MaterialIcon(
            name = "build",
            style = MaterialIcon.Style.TwoTone
        )
        val BuildCircle = MaterialIcon(
            name = "build_circle",
            style = MaterialIcon.Style.TwoTone
        )
        val Bungalow = MaterialIcon(
            name = "bungalow",
            style = MaterialIcon.Style.TwoTone
        )
        val BurstMode = MaterialIcon(
            name = "burst_mode",
            style = MaterialIcon.Style.TwoTone
        )
        val BusAlert = MaterialIcon(
            name = "bus_alert",
            style = MaterialIcon.Style.TwoTone
        )
        val Business = MaterialIcon(
            name = "business",
            style = MaterialIcon.Style.TwoTone
        )
        val BusinessCenter = MaterialIcon(
            name = "business_center",
            style = MaterialIcon.Style.TwoTone
        )
        val Cabin = MaterialIcon(
            name = "cabin",
            style = MaterialIcon.Style.TwoTone
        )
        val Cable = MaterialIcon(
            name = "cable",
            style = MaterialIcon.Style.TwoTone
        )
        val Cached = MaterialIcon(
            name = "cached",
            style = MaterialIcon.Style.TwoTone
        )
        val Cake = MaterialIcon(
            name = "cake",
            style = MaterialIcon.Style.TwoTone
        )
        val Calculate = MaterialIcon(
            name = "calculate",
            style = MaterialIcon.Style.TwoTone
        )
        val CalendarMonth = MaterialIcon(
            name = "calendar_month",
            style = MaterialIcon.Style.TwoTone
        )
        val CalendarToday = MaterialIcon(
            name = "calendar_today",
            style = MaterialIcon.Style.TwoTone
        )
        val CalendarViewDay = MaterialIcon(
            name = "calendar_view_day",
            style = MaterialIcon.Style.TwoTone
        )
        val CalendarViewMonth = MaterialIcon(
            name = "calendar_view_month",
            style = MaterialIcon.Style.TwoTone
        )
        val CalendarViewWeek = MaterialIcon(
            name = "calendar_view_week",
            style = MaterialIcon.Style.TwoTone
        )
        val Call = MaterialIcon(
            name = "call",
            style = MaterialIcon.Style.TwoTone
        )
        val CallEnd = MaterialIcon(
            name = "call_end",
            style = MaterialIcon.Style.TwoTone
        )
        val CallMade = MaterialIcon(
            name = "call_made",
            style = MaterialIcon.Style.TwoTone
        )
        val CallMerge = MaterialIcon(
            name = "call_merge",
            style = MaterialIcon.Style.TwoTone
        )
        val CallMissed = MaterialIcon(
            name = "call_missed",
            style = MaterialIcon.Style.TwoTone
        )
        val CallMissedOutgoing = MaterialIcon(
            name = "call_missed_outgoing",
            style = MaterialIcon.Style.TwoTone
        )
        val CallReceived = MaterialIcon(
            name = "call_received",
            style = MaterialIcon.Style.TwoTone
        )
        val CallSplit = MaterialIcon(
            name = "call_split",
            style = MaterialIcon.Style.TwoTone
        )
        val CallToAction = MaterialIcon(
            name = "call_to_action",
            style = MaterialIcon.Style.TwoTone
        )
        val Camera = MaterialIcon(
            name = "camera",
            style = MaterialIcon.Style.TwoTone
        )
        val CameraAlt = MaterialIcon(
            name = "camera_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val CameraEnhance = MaterialIcon(
            name = "camera_enhance",
            style = MaterialIcon.Style.TwoTone
        )
        val CameraFront = MaterialIcon(
            name = "camera_front",
            style = MaterialIcon.Style.TwoTone
        )
        val CameraIndoor = MaterialIcon(
            name = "camera_indoor",
            style = MaterialIcon.Style.TwoTone
        )
        val CameraOutdoor = MaterialIcon(
            name = "camera_outdoor",
            style = MaterialIcon.Style.TwoTone
        )
        val CameraRear = MaterialIcon(
            name = "camera_rear",
            style = MaterialIcon.Style.TwoTone
        )
        val CameraRoll = MaterialIcon(
            name = "camera_roll",
            style = MaterialIcon.Style.TwoTone
        )
        val Cameraswitch = MaterialIcon(
            name = "cameraswitch",
            style = MaterialIcon.Style.TwoTone
        )
        val Campaign = MaterialIcon(
            name = "campaign",
            style = MaterialIcon.Style.TwoTone
        )
        val Cancel = MaterialIcon(
            name = "cancel",
            style = MaterialIcon.Style.TwoTone
        )
        val CancelPresentation = MaterialIcon(
            name = "cancel_presentation",
            style = MaterialIcon.Style.TwoTone
        )
        val CancelScheduleSend = MaterialIcon(
            name = "cancel_schedule_send",
            style = MaterialIcon.Style.TwoTone
        )
        val CandlestickChart = MaterialIcon(
            name = "candlestick_chart",
            style = MaterialIcon.Style.TwoTone
        )
        val CarCrash = MaterialIcon(
            name = "car_crash",
            style = MaterialIcon.Style.TwoTone
        )
        val CarRental = MaterialIcon(
            name = "car_rental",
            style = MaterialIcon.Style.TwoTone
        )
        val CarRepair = MaterialIcon(
            name = "car_repair",
            style = MaterialIcon.Style.TwoTone
        )
        val CardGiftcard = MaterialIcon(
            name = "card_giftcard",
            style = MaterialIcon.Style.TwoTone
        )
        val CardMembership = MaterialIcon(
            name = "card_membership",
            style = MaterialIcon.Style.TwoTone
        )
        val CardTravel = MaterialIcon(
            name = "card_travel",
            style = MaterialIcon.Style.TwoTone
        )
        val Carpenter = MaterialIcon(
            name = "carpenter",
            style = MaterialIcon.Style.TwoTone
        )
        val Cases = MaterialIcon(
            name = "cases",
            style = MaterialIcon.Style.TwoTone
        )
        val Casino = MaterialIcon(
            name = "casino",
            style = MaterialIcon.Style.TwoTone
        )
        val Cast = MaterialIcon(
            name = "cast",
            style = MaterialIcon.Style.TwoTone
        )
        val CastConnected = MaterialIcon(
            name = "cast_connected",
            style = MaterialIcon.Style.TwoTone
        )
        val CastForEducation = MaterialIcon(
            name = "cast_for_education",
            style = MaterialIcon.Style.TwoTone
        )
        val Castle = MaterialIcon(
            name = "castle",
            style = MaterialIcon.Style.TwoTone
        )
        val CatchingPokemon = MaterialIcon(
            name = "catching_pokemon",
            style = MaterialIcon.Style.TwoTone
        )
        val Category = MaterialIcon(
            name = "category",
            style = MaterialIcon.Style.TwoTone
        )
        val Celebration = MaterialIcon(
            name = "celebration",
            style = MaterialIcon.Style.TwoTone
        )
        val CellTower = MaterialIcon(
            name = "cell_tower",
            style = MaterialIcon.Style.TwoTone
        )
        val CellWifi = MaterialIcon(
            name = "cell_wifi",
            style = MaterialIcon.Style.TwoTone
        )
        val CenterFocusStrong = MaterialIcon(
            name = "center_focus_strong",
            style = MaterialIcon.Style.TwoTone
        )
        val CenterFocusWeak = MaterialIcon(
            name = "center_focus_weak",
            style = MaterialIcon.Style.TwoTone
        )
        val Chair = MaterialIcon(
            name = "chair",
            style = MaterialIcon.Style.TwoTone
        )
        val ChairAlt = MaterialIcon(
            name = "chair_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val Chalet = MaterialIcon(
            name = "chalet",
            style = MaterialIcon.Style.TwoTone
        )
        val ChangeCircle = MaterialIcon(
            name = "change_circle",
            style = MaterialIcon.Style.TwoTone
        )
        val ChangeHistory = MaterialIcon(
            name = "change_history",
            style = MaterialIcon.Style.TwoTone
        )
        val ChargingStation = MaterialIcon(
            name = "charging_station",
            style = MaterialIcon.Style.TwoTone
        )
        val Chat = MaterialIcon(
            name = "chat",
            style = MaterialIcon.Style.TwoTone
        )
        val ChatBubble = MaterialIcon(
            name = "chat_bubble",
            style = MaterialIcon.Style.TwoTone
        )
        val ChatBubbleOutline = MaterialIcon(
            name = "chat_bubble_outline",
            style = MaterialIcon.Style.TwoTone
        )
        val Check = MaterialIcon(
            name = "check",
            style = MaterialIcon.Style.TwoTone
        )
        val CheckBox = MaterialIcon(
            name = "check_box",
            style = MaterialIcon.Style.TwoTone
        )
        val CheckBoxOutlineBlank = MaterialIcon(
            name = "check_box_outline_blank",
            style = MaterialIcon.Style.TwoTone
        )
        val CheckCircle = MaterialIcon(
            name = "check_circle",
            style = MaterialIcon.Style.TwoTone
        )
        val CheckCircleOutline = MaterialIcon(
            name = "check_circle_outline",
            style = MaterialIcon.Style.TwoTone
        )
        val Checklist = MaterialIcon(
            name = "checklist",
            style = MaterialIcon.Style.TwoTone
        )
        val ChecklistRtl = MaterialIcon(
            name = "checklist_rtl",
            style = MaterialIcon.Style.TwoTone
        )
        val Checkroom = MaterialIcon(
            name = "checkroom",
            style = MaterialIcon.Style.TwoTone
        )
        val ChevronLeft = MaterialIcon(
            name = "chevron_left",
            style = MaterialIcon.Style.TwoTone
        )
        val ChevronRight = MaterialIcon(
            name = "chevron_right",
            style = MaterialIcon.Style.TwoTone
        )
        val ChildCare = MaterialIcon(
            name = "child_care",
            style = MaterialIcon.Style.TwoTone
        )
        val ChildFriendly = MaterialIcon(
            name = "child_friendly",
            style = MaterialIcon.Style.TwoTone
        )
        val ChromeReaderMode = MaterialIcon(
            name = "chrome_reader_mode",
            style = MaterialIcon.Style.TwoTone
        )
        val Church = MaterialIcon(
            name = "church",
            style = MaterialIcon.Style.TwoTone
        )
        val Circle = MaterialIcon(
            name = "circle",
            style = MaterialIcon.Style.TwoTone
        )
        val CircleNotifications = MaterialIcon(
            name = "circle_notifications",
            style = MaterialIcon.Style.TwoTone
        )
        val Class = MaterialIcon(
            name = "class",
            style = MaterialIcon.Style.TwoTone
        )
        val CleanHands = MaterialIcon(
            name = "clean_hands",
            style = MaterialIcon.Style.TwoTone
        )
        val CleaningServices = MaterialIcon(
            name = "cleaning_services",
            style = MaterialIcon.Style.TwoTone
        )
        val Clear = MaterialIcon(
            name = "clear",
            style = MaterialIcon.Style.TwoTone
        )
        val ClearAll = MaterialIcon(
            name = "clear_all",
            style = MaterialIcon.Style.TwoTone
        )
        val Close = MaterialIcon(
            name = "close",
            style = MaterialIcon.Style.TwoTone
        )
        val CloseFullscreen = MaterialIcon(
            name = "close_fullscreen",
            style = MaterialIcon.Style.TwoTone
        )
        val ClosedCaption = MaterialIcon(
            name = "closed_caption",
            style = MaterialIcon.Style.TwoTone
        )
        val ClosedCaptionDisabled = MaterialIcon(
            name = "closed_caption_disabled",
            style = MaterialIcon.Style.TwoTone
        )
        val ClosedCaptionOff = MaterialIcon(
            name = "closed_caption_off",
            style = MaterialIcon.Style.TwoTone
        )
        val Cloud = MaterialIcon(
            name = "cloud",
            style = MaterialIcon.Style.TwoTone
        )
        val CloudCircle = MaterialIcon(
            name = "cloud_circle",
            style = MaterialIcon.Style.TwoTone
        )
        val CloudDone = MaterialIcon(
            name = "cloud_done",
            style = MaterialIcon.Style.TwoTone
        )
        val CloudDownload = MaterialIcon(
            name = "cloud_download",
            style = MaterialIcon.Style.TwoTone
        )
        val CloudOff = MaterialIcon(
            name = "cloud_off",
            style = MaterialIcon.Style.TwoTone
        )
        val CloudQueue = MaterialIcon(
            name = "cloud_queue",
            style = MaterialIcon.Style.TwoTone
        )
        val CloudSync = MaterialIcon(
            name = "cloud_sync",
            style = MaterialIcon.Style.TwoTone
        )
        val CloudUpload = MaterialIcon(
            name = "cloud_upload",
            style = MaterialIcon.Style.TwoTone
        )
        val Co2 = MaterialIcon(
            name = "co2",
            style = MaterialIcon.Style.TwoTone
        )
        val CoPresent = MaterialIcon(
            name = "co_present",
            style = MaterialIcon.Style.TwoTone
        )
        val Code = MaterialIcon(
            name = "code",
            style = MaterialIcon.Style.TwoTone
        )
        val CodeOff = MaterialIcon(
            name = "code_off",
            style = MaterialIcon.Style.TwoTone
        )
        val Coffee = MaterialIcon(
            name = "coffee",
            style = MaterialIcon.Style.TwoTone
        )
        val CoffeeMaker = MaterialIcon(
            name = "coffee_maker",
            style = MaterialIcon.Style.TwoTone
        )
        val Collections = MaterialIcon(
            name = "collections",
            style = MaterialIcon.Style.TwoTone
        )
        val CollectionsBookmark = MaterialIcon(
            name = "collections_bookmark",
            style = MaterialIcon.Style.TwoTone
        )
        val ColorLens = MaterialIcon(
            name = "color_lens",
            style = MaterialIcon.Style.TwoTone
        )
        val Colorize = MaterialIcon(
            name = "colorize",
            style = MaterialIcon.Style.TwoTone
        )
        val Comment = MaterialIcon(
            name = "comment",
            style = MaterialIcon.Style.TwoTone
        )
        val CommentBank = MaterialIcon(
            name = "comment_bank",
            style = MaterialIcon.Style.TwoTone
        )
        val CommentsDisabled = MaterialIcon(
            name = "comments_disabled",
            style = MaterialIcon.Style.TwoTone
        )
        val Commit = MaterialIcon(
            name = "commit",
            style = MaterialIcon.Style.TwoTone
        )
        val Commute = MaterialIcon(
            name = "commute",
            style = MaterialIcon.Style.TwoTone
        )
        val Compare = MaterialIcon(
            name = "compare",
            style = MaterialIcon.Style.TwoTone
        )
        val CompareArrows = MaterialIcon(
            name = "compare_arrows",
            style = MaterialIcon.Style.TwoTone
        )
        val CompassCalibration = MaterialIcon(
            name = "compass_calibration",
            style = MaterialIcon.Style.TwoTone
        )
        val Compost = MaterialIcon(
            name = "compost",
            style = MaterialIcon.Style.TwoTone
        )
        val Compress = MaterialIcon(
            name = "compress",
            style = MaterialIcon.Style.TwoTone
        )
        val Computer = MaterialIcon(
            name = "computer",
            style = MaterialIcon.Style.TwoTone
        )
        val ConfirmationNumber = MaterialIcon(
            name = "confirmation_number",
            style = MaterialIcon.Style.TwoTone
        )
        val ConnectWithoutContact = MaterialIcon(
            name = "connect_without_contact",
            style = MaterialIcon.Style.TwoTone
        )
        val ConnectedTv = MaterialIcon(
            name = "connected_tv",
            style = MaterialIcon.Style.TwoTone
        )
        val ConnectingAirports = MaterialIcon(
            name = "connecting_airports",
            style = MaterialIcon.Style.TwoTone
        )
        val Construction = MaterialIcon(
            name = "construction",
            style = MaterialIcon.Style.TwoTone
        )
        val ContactEmergency = MaterialIcon(
            name = "contact_emergency",
            style = MaterialIcon.Style.TwoTone
        )
        val ContactMail = MaterialIcon(
            name = "contact_mail",
            style = MaterialIcon.Style.TwoTone
        )
        val ContactPage = MaterialIcon(
            name = "contact_page",
            style = MaterialIcon.Style.TwoTone
        )
        val ContactPhone = MaterialIcon(
            name = "contact_phone",
            style = MaterialIcon.Style.TwoTone
        )
        val ContactSupport = MaterialIcon(
            name = "contact_support",
            style = MaterialIcon.Style.TwoTone
        )
        val Contactless = MaterialIcon(
            name = "contactless",
            style = MaterialIcon.Style.TwoTone
        )
        val Contacts = MaterialIcon(
            name = "contacts",
            style = MaterialIcon.Style.TwoTone
        )
        val ContentCopy = MaterialIcon(
            name = "content_copy",
            style = MaterialIcon.Style.TwoTone
        )
        val ContentCut = MaterialIcon(
            name = "content_cut",
            style = MaterialIcon.Style.TwoTone
        )
        val ContentPaste = MaterialIcon(
            name = "content_paste",
            style = MaterialIcon.Style.TwoTone
        )
        val ContentPasteGo = MaterialIcon(
            name = "content_paste_go",
            style = MaterialIcon.Style.TwoTone
        )
        val ContentPasteOff = MaterialIcon(
            name = "content_paste_off",
            style = MaterialIcon.Style.TwoTone
        )
        val ContentPasteSearch = MaterialIcon(
            name = "content_paste_search",
            style = MaterialIcon.Style.TwoTone
        )
        val Contrast = MaterialIcon(
            name = "contrast",
            style = MaterialIcon.Style.TwoTone
        )
        val ControlCamera = MaterialIcon(
            name = "control_camera",
            style = MaterialIcon.Style.TwoTone
        )
        val ControlPoint = MaterialIcon(
            name = "control_point",
            style = MaterialIcon.Style.TwoTone
        )
        val ControlPointDuplicate = MaterialIcon(
            name = "control_point_duplicate",
            style = MaterialIcon.Style.TwoTone
        )
        val Cookie = MaterialIcon(
            name = "cookie",
            style = MaterialIcon.Style.TwoTone
        )
        val CopyAll = MaterialIcon(
            name = "copy_all",
            style = MaterialIcon.Style.TwoTone
        )
        val Copyright = MaterialIcon(
            name = "copyright",
            style = MaterialIcon.Style.TwoTone
        )
        val Coronavirus = MaterialIcon(
            name = "coronavirus",
            style = MaterialIcon.Style.TwoTone
        )
        val CorporateFare = MaterialIcon(
            name = "corporate_fare",
            style = MaterialIcon.Style.TwoTone
        )
        val Cottage = MaterialIcon(
            name = "cottage",
            style = MaterialIcon.Style.TwoTone
        )
        val Countertops = MaterialIcon(
            name = "countertops",
            style = MaterialIcon.Style.TwoTone
        )
        val Create = MaterialIcon(
            name = "create",
            style = MaterialIcon.Style.TwoTone
        )
        val CreateNewFolder = MaterialIcon(
            name = "create_new_folder",
            style = MaterialIcon.Style.TwoTone
        )
        val CreditCard = MaterialIcon(
            name = "credit_card",
            style = MaterialIcon.Style.TwoTone
        )
        val CreditCardOff = MaterialIcon(
            name = "credit_card_off",
            style = MaterialIcon.Style.TwoTone
        )
        val CreditScore = MaterialIcon(
            name = "credit_score",
            style = MaterialIcon.Style.TwoTone
        )
        val Crib = MaterialIcon(
            name = "crib",
            style = MaterialIcon.Style.TwoTone
        )
        val CrisisAlert = MaterialIcon(
            name = "crisis_alert",
            style = MaterialIcon.Style.TwoTone
        )
        val Crop = MaterialIcon(
            name = "crop",
            style = MaterialIcon.Style.TwoTone
        )
        val Crop169 = MaterialIcon(
            name = "crop_16_9",
            style = MaterialIcon.Style.TwoTone
        )
        val Crop32 = MaterialIcon(
            name = "crop_3_2",
            style = MaterialIcon.Style.TwoTone
        )
        val Crop54 = MaterialIcon(
            name = "crop_5_4",
            style = MaterialIcon.Style.TwoTone
        )
        val Crop75 = MaterialIcon(
            name = "crop_7_5",
            style = MaterialIcon.Style.TwoTone
        )
        val CropDin = MaterialIcon(
            name = "crop_din",
            style = MaterialIcon.Style.TwoTone
        )
        val CropFree = MaterialIcon(
            name = "crop_free",
            style = MaterialIcon.Style.TwoTone
        )
        val CropLandscape = MaterialIcon(
            name = "crop_landscape",
            style = MaterialIcon.Style.TwoTone
        )
        val CropOriginal = MaterialIcon(
            name = "crop_original",
            style = MaterialIcon.Style.TwoTone
        )
        val CropPortrait = MaterialIcon(
            name = "crop_portrait",
            style = MaterialIcon.Style.TwoTone
        )
        val CropRotate = MaterialIcon(
            name = "crop_rotate",
            style = MaterialIcon.Style.TwoTone
        )
        val CropSquare = MaterialIcon(
            name = "crop_square",
            style = MaterialIcon.Style.TwoTone
        )
        val CrueltyFree = MaterialIcon(
            name = "cruelty_free",
            style = MaterialIcon.Style.TwoTone
        )
        val Css = MaterialIcon(
            name = "css",
            style = MaterialIcon.Style.TwoTone
        )
        val CurrencyBitcoin = MaterialIcon(
            name = "currency_bitcoin",
            style = MaterialIcon.Style.TwoTone
        )
        val CurrencyExchange = MaterialIcon(
            name = "currency_exchange",
            style = MaterialIcon.Style.TwoTone
        )
        val CurrencyFranc = MaterialIcon(
            name = "currency_franc",
            style = MaterialIcon.Style.TwoTone
        )
        val CurrencyLira = MaterialIcon(
            name = "currency_lira",
            style = MaterialIcon.Style.TwoTone
        )
        val CurrencyPound = MaterialIcon(
            name = "currency_pound",
            style = MaterialIcon.Style.TwoTone
        )
        val CurrencyRuble = MaterialIcon(
            name = "currency_ruble",
            style = MaterialIcon.Style.TwoTone
        )
        val CurrencyRupee = MaterialIcon(
            name = "currency_rupee",
            style = MaterialIcon.Style.TwoTone
        )
        val CurrencyYen = MaterialIcon(
            name = "currency_yen",
            style = MaterialIcon.Style.TwoTone
        )
        val CurrencyYuan = MaterialIcon(
            name = "currency_yuan",
            style = MaterialIcon.Style.TwoTone
        )
        val Curtains = MaterialIcon(
            name = "curtains",
            style = MaterialIcon.Style.TwoTone
        )
        val CurtainsClosed = MaterialIcon(
            name = "curtains_closed",
            style = MaterialIcon.Style.TwoTone
        )
        val Cyclone = MaterialIcon(
            name = "cyclone",
            style = MaterialIcon.Style.TwoTone
        )
        val Dangerous = MaterialIcon(
            name = "dangerous",
            style = MaterialIcon.Style.TwoTone
        )
        val DarkMode = MaterialIcon(
            name = "dark_mode",
            style = MaterialIcon.Style.TwoTone
        )
        val Dashboard = MaterialIcon(
            name = "dashboard",
            style = MaterialIcon.Style.TwoTone
        )
        val DashboardCustomize = MaterialIcon(
            name = "dashboard_customize",
            style = MaterialIcon.Style.TwoTone
        )
        val DataArray = MaterialIcon(
            name = "data_array",
            style = MaterialIcon.Style.TwoTone
        )
        val DataExploration = MaterialIcon(
            name = "data_exploration",
            style = MaterialIcon.Style.TwoTone
        )
        val DataObject = MaterialIcon(
            name = "data_object",
            style = MaterialIcon.Style.TwoTone
        )
        val DataSaverOff = MaterialIcon(
            name = "data_saver_off",
            style = MaterialIcon.Style.TwoTone
        )
        val DataSaverOn = MaterialIcon(
            name = "data_saver_on",
            style = MaterialIcon.Style.TwoTone
        )
        val DataThresholding = MaterialIcon(
            name = "data_thresholding",
            style = MaterialIcon.Style.TwoTone
        )
        val DataUsage = MaterialIcon(
            name = "data_usage",
            style = MaterialIcon.Style.TwoTone
        )
        val Dataset = MaterialIcon(
            name = "dataset",
            style = MaterialIcon.Style.TwoTone
        )
        val DatasetLinked = MaterialIcon(
            name = "dataset_linked",
            style = MaterialIcon.Style.TwoTone
        )
        val DateRange = MaterialIcon(
            name = "date_range",
            style = MaterialIcon.Style.TwoTone
        )
        val Deblur = MaterialIcon(
            name = "deblur",
            style = MaterialIcon.Style.TwoTone
        )
        val Deck = MaterialIcon(
            name = "deck",
            style = MaterialIcon.Style.TwoTone
        )
        val Dehaze = MaterialIcon(
            name = "dehaze",
            style = MaterialIcon.Style.TwoTone
        )
        val Delete = MaterialIcon(
            name = "delete",
            style = MaterialIcon.Style.TwoTone
        )
        val DeleteForever = MaterialIcon(
            name = "delete_forever",
            style = MaterialIcon.Style.TwoTone
        )
        val DeleteOutline = MaterialIcon(
            name = "delete_outline",
            style = MaterialIcon.Style.TwoTone
        )
        val DeleteSweep = MaterialIcon(
            name = "delete_sweep",
            style = MaterialIcon.Style.TwoTone
        )
        val DeliveryDining = MaterialIcon(
            name = "delivery_dining",
            style = MaterialIcon.Style.TwoTone
        )
        val DensityLarge = MaterialIcon(
            name = "density_large",
            style = MaterialIcon.Style.TwoTone
        )
        val DensityMedium = MaterialIcon(
            name = "density_medium",
            style = MaterialIcon.Style.TwoTone
        )
        val DensitySmall = MaterialIcon(
            name = "density_small",
            style = MaterialIcon.Style.TwoTone
        )
        val DepartureBoard = MaterialIcon(
            name = "departure_board",
            style = MaterialIcon.Style.TwoTone
        )
        val Description = MaterialIcon(
            name = "description",
            style = MaterialIcon.Style.TwoTone
        )
        val Deselect = MaterialIcon(
            name = "deselect",
            style = MaterialIcon.Style.TwoTone
        )
        val DesignServices = MaterialIcon(
            name = "design_services",
            style = MaterialIcon.Style.TwoTone
        )
        val Desk = MaterialIcon(
            name = "desk",
            style = MaterialIcon.Style.TwoTone
        )
        val DesktopAccessDisabled = MaterialIcon(
            name = "desktop_access_disabled",
            style = MaterialIcon.Style.TwoTone
        )
        val DesktopMac = MaterialIcon(
            name = "desktop_mac",
            style = MaterialIcon.Style.TwoTone
        )
        val DesktopWindows = MaterialIcon(
            name = "desktop_windows",
            style = MaterialIcon.Style.TwoTone
        )
        val Details = MaterialIcon(
            name = "details",
            style = MaterialIcon.Style.TwoTone
        )
        val DeveloperBoard = MaterialIcon(
            name = "developer_board",
            style = MaterialIcon.Style.TwoTone
        )
        val DeveloperBoardOff = MaterialIcon(
            name = "developer_board_off",
            style = MaterialIcon.Style.TwoTone
        )
        val DeveloperMode = MaterialIcon(
            name = "developer_mode",
            style = MaterialIcon.Style.TwoTone
        )
        val DeviceHub = MaterialIcon(
            name = "device_hub",
            style = MaterialIcon.Style.TwoTone
        )
        val DeviceThermostat = MaterialIcon(
            name = "device_thermostat",
            style = MaterialIcon.Style.TwoTone
        )
        val DeviceUnknown = MaterialIcon(
            name = "device_unknown",
            style = MaterialIcon.Style.TwoTone
        )
        val Devices = MaterialIcon(
            name = "devices",
            style = MaterialIcon.Style.TwoTone
        )
        val DevicesFold = MaterialIcon(
            name = "devices_fold",
            style = MaterialIcon.Style.TwoTone
        )
        val DevicesOther = MaterialIcon(
            name = "devices_other",
            style = MaterialIcon.Style.TwoTone
        )
        val DialerSip = MaterialIcon(
            name = "dialer_sip",
            style = MaterialIcon.Style.TwoTone
        )
        val Dialpad = MaterialIcon(
            name = "dialpad",
            style = MaterialIcon.Style.TwoTone
        )
        val Diamond = MaterialIcon(
            name = "diamond",
            style = MaterialIcon.Style.TwoTone
        )
        val Difference = MaterialIcon(
            name = "difference",
            style = MaterialIcon.Style.TwoTone
        )
        val Dining = MaterialIcon(
            name = "dining",
            style = MaterialIcon.Style.TwoTone
        )
        val DinnerDining = MaterialIcon(
            name = "dinner_dining",
            style = MaterialIcon.Style.TwoTone
        )
        val Directions = MaterialIcon(
            name = "directions",
            style = MaterialIcon.Style.TwoTone
        )
        val DirectionsBike = MaterialIcon(
            name = "directions_bike",
            style = MaterialIcon.Style.TwoTone
        )
        val DirectionsBoat = MaterialIcon(
            name = "directions_boat",
            style = MaterialIcon.Style.TwoTone
        )
        val DirectionsBoatFilled = MaterialIcon(
            name = "directions_boat_filled",
            style = MaterialIcon.Style.TwoTone
        )
        val DirectionsBus = MaterialIcon(
            name = "directions_bus",
            style = MaterialIcon.Style.TwoTone
        )
        val DirectionsBusFilled = MaterialIcon(
            name = "directions_bus_filled",
            style = MaterialIcon.Style.TwoTone
        )
        val DirectionsCar = MaterialIcon(
            name = "directions_car",
            style = MaterialIcon.Style.TwoTone
        )
        val DirectionsCarFilled = MaterialIcon(
            name = "directions_car_filled",
            style = MaterialIcon.Style.TwoTone
        )
        val DirectionsOff = MaterialIcon(
            name = "directions_off",
            style = MaterialIcon.Style.TwoTone
        )
        val DirectionsRailway = MaterialIcon(
            name = "directions_railway",
            style = MaterialIcon.Style.TwoTone
        )
        val DirectionsRailwayFilled = MaterialIcon(
            name = "directions_railway_filled",
            style = MaterialIcon.Style.TwoTone
        )
        val DirectionsRun = MaterialIcon(
            name = "directions_run",
            style = MaterialIcon.Style.TwoTone
        )
        val DirectionsSubway = MaterialIcon(
            name = "directions_subway",
            style = MaterialIcon.Style.TwoTone
        )
        val DirectionsSubwayFilled = MaterialIcon(
            name = "directions_subway_filled",
            style = MaterialIcon.Style.TwoTone
        )
        val DirectionsTransit = MaterialIcon(
            name = "directions_transit",
            style = MaterialIcon.Style.TwoTone
        )
        val DirectionsTransitFilled = MaterialIcon(
            name = "directions_transit_filled",
            style = MaterialIcon.Style.TwoTone
        )
        val DirectionsWalk = MaterialIcon(
            name = "directions_walk",
            style = MaterialIcon.Style.TwoTone
        )
        val DirtyLens = MaterialIcon(
            name = "dirty_lens",
            style = MaterialIcon.Style.TwoTone
        )
        val DisabledByDefault = MaterialIcon(
            name = "disabled_by_default",
            style = MaterialIcon.Style.TwoTone
        )
        val DisabledVisible = MaterialIcon(
            name = "disabled_visible",
            style = MaterialIcon.Style.TwoTone
        )
        val DiscFull = MaterialIcon(
            name = "disc_full",
            style = MaterialIcon.Style.TwoTone
        )
        val Discount = MaterialIcon(
            name = "discount",
            style = MaterialIcon.Style.TwoTone
        )
        val DisplaySettings = MaterialIcon(
            name = "display_settings",
            style = MaterialIcon.Style.TwoTone
        )
        val Diversity1 = MaterialIcon(
            name = "diversity_1",
            style = MaterialIcon.Style.TwoTone
        )
        val Diversity2 = MaterialIcon(
            name = "diversity_2",
            style = MaterialIcon.Style.TwoTone
        )
        val Diversity3 = MaterialIcon(
            name = "diversity_3",
            style = MaterialIcon.Style.TwoTone
        )
        val Dns = MaterialIcon(
            name = "dns",
            style = MaterialIcon.Style.TwoTone
        )
        val DoDisturb = MaterialIcon(
            name = "do_disturb",
            style = MaterialIcon.Style.TwoTone
        )
        val DoDisturbAlt = MaterialIcon(
            name = "do_disturb_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val DoDisturbOff = MaterialIcon(
            name = "do_disturb_off",
            style = MaterialIcon.Style.TwoTone
        )
        val DoDisturbOn = MaterialIcon(
            name = "do_disturb_on",
            style = MaterialIcon.Style.TwoTone
        )
        val DoNotDisturb = MaterialIcon(
            name = "do_not_disturb",
            style = MaterialIcon.Style.TwoTone
        )
        val DoNotDisturbAlt = MaterialIcon(
            name = "do_not_disturb_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val DoNotDisturbOff = MaterialIcon(
            name = "do_not_disturb_off",
            style = MaterialIcon.Style.TwoTone
        )
        val DoNotDisturbOn = MaterialIcon(
            name = "do_not_disturb_on",
            style = MaterialIcon.Style.TwoTone
        )
        val DoNotDisturbOnTotalSilence = MaterialIcon(
            name = "do_not_disturb_on_total_silence",
            style = MaterialIcon.Style.TwoTone
        )
        val DoNotStep = MaterialIcon(
            name = "do_not_step",
            style = MaterialIcon.Style.TwoTone
        )
        val DoNotTouch = MaterialIcon(
            name = "do_not_touch",
            style = MaterialIcon.Style.TwoTone
        )
        val Dock = MaterialIcon(
            name = "dock",
            style = MaterialIcon.Style.TwoTone
        )
        val DocumentScanner = MaterialIcon(
            name = "document_scanner",
            style = MaterialIcon.Style.TwoTone
        )
        val Domain = MaterialIcon(
            name = "domain",
            style = MaterialIcon.Style.TwoTone
        )
        val DomainAdd = MaterialIcon(
            name = "domain_add",
            style = MaterialIcon.Style.TwoTone
        )
        val DomainDisabled = MaterialIcon(
            name = "domain_disabled",
            style = MaterialIcon.Style.TwoTone
        )
        val DomainVerification = MaterialIcon(
            name = "domain_verification",
            style = MaterialIcon.Style.TwoTone
        )
        val Done = MaterialIcon(
            name = "done",
            style = MaterialIcon.Style.TwoTone
        )
        val DoneAll = MaterialIcon(
            name = "done_all",
            style = MaterialIcon.Style.TwoTone
        )
        val DoneOutline = MaterialIcon(
            name = "done_outline",
            style = MaterialIcon.Style.TwoTone
        )
        val DonutLarge = MaterialIcon(
            name = "donut_large",
            style = MaterialIcon.Style.TwoTone
        )
        val DonutSmall = MaterialIcon(
            name = "donut_small",
            style = MaterialIcon.Style.TwoTone
        )
        val DoorBack = MaterialIcon(
            name = "door_back",
            style = MaterialIcon.Style.TwoTone
        )
        val DoorFront = MaterialIcon(
            name = "door_front",
            style = MaterialIcon.Style.TwoTone
        )
        val DoorSliding = MaterialIcon(
            name = "door_sliding",
            style = MaterialIcon.Style.TwoTone
        )
        val Doorbell = MaterialIcon(
            name = "doorbell",
            style = MaterialIcon.Style.TwoTone
        )
        val DoubleArrow = MaterialIcon(
            name = "double_arrow",
            style = MaterialIcon.Style.TwoTone
        )
        val DownhillSkiing = MaterialIcon(
            name = "downhill_skiing",
            style = MaterialIcon.Style.TwoTone
        )
        val Download = MaterialIcon(
            name = "download",
            style = MaterialIcon.Style.TwoTone
        )
        val DownloadDone = MaterialIcon(
            name = "download_done",
            style = MaterialIcon.Style.TwoTone
        )
        val DownloadForOffline = MaterialIcon(
            name = "download_for_offline",
            style = MaterialIcon.Style.TwoTone
        )
        val Downloading = MaterialIcon(
            name = "downloading",
            style = MaterialIcon.Style.TwoTone
        )
        val Drafts = MaterialIcon(
            name = "drafts",
            style = MaterialIcon.Style.TwoTone
        )
        val DragHandle = MaterialIcon(
            name = "drag_handle",
            style = MaterialIcon.Style.TwoTone
        )
        val DragIndicator = MaterialIcon(
            name = "drag_indicator",
            style = MaterialIcon.Style.TwoTone
        )
        val Draw = MaterialIcon(
            name = "draw",
            style = MaterialIcon.Style.TwoTone
        )
        val DriveEta = MaterialIcon(
            name = "drive_eta",
            style = MaterialIcon.Style.TwoTone
        )
        val DriveFileMove = MaterialIcon(
            name = "drive_file_move",
            style = MaterialIcon.Style.TwoTone
        )
        val DriveFileMoveRtl = MaterialIcon(
            name = "drive_file_move_rtl",
            style = MaterialIcon.Style.TwoTone
        )
        val DriveFileRenameOutline = MaterialIcon(
            name = "drive_file_rename_outline",
            style = MaterialIcon.Style.TwoTone
        )
        val DriveFolderUpload = MaterialIcon(
            name = "drive_folder_upload",
            style = MaterialIcon.Style.TwoTone
        )
        val Dry = MaterialIcon(
            name = "dry",
            style = MaterialIcon.Style.TwoTone
        )
        val DryCleaning = MaterialIcon(
            name = "dry_cleaning",
            style = MaterialIcon.Style.TwoTone
        )
        val Duo = MaterialIcon(
            name = "duo",
            style = MaterialIcon.Style.TwoTone
        )
        val Dvr = MaterialIcon(
            name = "dvr",
            style = MaterialIcon.Style.TwoTone
        )
        val DynamicFeed = MaterialIcon(
            name = "dynamic_feed",
            style = MaterialIcon.Style.TwoTone
        )
        val DynamicForm = MaterialIcon(
            name = "dynamic_form",
            style = MaterialIcon.Style.TwoTone
        )
        val EMobiledata = MaterialIcon(
            name = "e_mobiledata",
            style = MaterialIcon.Style.TwoTone
        )
        val Earbuds = MaterialIcon(
            name = "earbuds",
            style = MaterialIcon.Style.TwoTone
        )
        val EarbudsBattery = MaterialIcon(
            name = "earbuds_battery",
            style = MaterialIcon.Style.TwoTone
        )
        val East = MaterialIcon(
            name = "east",
            style = MaterialIcon.Style.TwoTone
        )
        val EdgesensorHigh = MaterialIcon(
            name = "edgesensor_high",
            style = MaterialIcon.Style.TwoTone
        )
        val EdgesensorLow = MaterialIcon(
            name = "edgesensor_low",
            style = MaterialIcon.Style.TwoTone
        )
        val Edit = MaterialIcon(
            name = "edit",
            style = MaterialIcon.Style.TwoTone
        )
        val EditAttributes = MaterialIcon(
            name = "edit_attributes",
            style = MaterialIcon.Style.TwoTone
        )
        val EditCalendar = MaterialIcon(
            name = "edit_calendar",
            style = MaterialIcon.Style.TwoTone
        )
        val EditLocation = MaterialIcon(
            name = "edit_location",
            style = MaterialIcon.Style.TwoTone
        )
        val EditLocationAlt = MaterialIcon(
            name = "edit_location_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val EditNote = MaterialIcon(
            name = "edit_note",
            style = MaterialIcon.Style.TwoTone
        )
        val EditNotifications = MaterialIcon(
            name = "edit_notifications",
            style = MaterialIcon.Style.TwoTone
        )
        val EditOff = MaterialIcon(
            name = "edit_off",
            style = MaterialIcon.Style.TwoTone
        )
        val EditRoad = MaterialIcon(
            name = "edit_road",
            style = MaterialIcon.Style.TwoTone
        )
        val Egg = MaterialIcon(
            name = "egg",
            style = MaterialIcon.Style.TwoTone
        )
        val EggAlt = MaterialIcon(
            name = "egg_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val Eject = MaterialIcon(
            name = "eject",
            style = MaterialIcon.Style.TwoTone
        )
        val Elderly = MaterialIcon(
            name = "elderly",
            style = MaterialIcon.Style.TwoTone
        )
        val ElderlyWoman = MaterialIcon(
            name = "elderly_woman",
            style = MaterialIcon.Style.TwoTone
        )
        val ElectricBike = MaterialIcon(
            name = "electric_bike",
            style = MaterialIcon.Style.TwoTone
        )
        val ElectricBolt = MaterialIcon(
            name = "electric_bolt",
            style = MaterialIcon.Style.TwoTone
        )
        val ElectricCar = MaterialIcon(
            name = "electric_car",
            style = MaterialIcon.Style.TwoTone
        )
        val ElectricMeter = MaterialIcon(
            name = "electric_meter",
            style = MaterialIcon.Style.TwoTone
        )
        val ElectricMoped = MaterialIcon(
            name = "electric_moped",
            style = MaterialIcon.Style.TwoTone
        )
        val ElectricRickshaw = MaterialIcon(
            name = "electric_rickshaw",
            style = MaterialIcon.Style.TwoTone
        )
        val ElectricScooter = MaterialIcon(
            name = "electric_scooter",
            style = MaterialIcon.Style.TwoTone
        )
        val ElectricalServices = MaterialIcon(
            name = "electrical_services",
            style = MaterialIcon.Style.TwoTone
        )
        val Elevator = MaterialIcon(
            name = "elevator",
            style = MaterialIcon.Style.TwoTone
        )
        val Email = MaterialIcon(
            name = "email",
            style = MaterialIcon.Style.TwoTone
        )
        val Emergency = MaterialIcon(
            name = "emergency",
            style = MaterialIcon.Style.TwoTone
        )
        val EmergencyRecording = MaterialIcon(
            name = "emergency_recording",
            style = MaterialIcon.Style.TwoTone
        )
        val EmergencyShare = MaterialIcon(
            name = "emergency_share",
            style = MaterialIcon.Style.TwoTone
        )
        val EmojiEmotions = MaterialIcon(
            name = "emoji_emotions",
            style = MaterialIcon.Style.TwoTone
        )
        val EmojiEvents = MaterialIcon(
            name = "emoji_events",
            style = MaterialIcon.Style.TwoTone
        )
        val EmojiFoodBeverage = MaterialIcon(
            name = "emoji_food_beverage",
            style = MaterialIcon.Style.TwoTone
        )
        val EmojiNature = MaterialIcon(
            name = "emoji_nature",
            style = MaterialIcon.Style.TwoTone
        )
        val EmojiObjects = MaterialIcon(
            name = "emoji_objects",
            style = MaterialIcon.Style.TwoTone
        )
        val EmojiPeople = MaterialIcon(
            name = "emoji_people",
            style = MaterialIcon.Style.TwoTone
        )
        val EmojiSymbols = MaterialIcon(
            name = "emoji_symbols",
            style = MaterialIcon.Style.TwoTone
        )
        val EmojiTransportation = MaterialIcon(
            name = "emoji_transportation",
            style = MaterialIcon.Style.TwoTone
        )
        val EnergySavingsLeaf = MaterialIcon(
            name = "energy_savings_leaf",
            style = MaterialIcon.Style.TwoTone
        )
        val Engineering = MaterialIcon(
            name = "engineering",
            style = MaterialIcon.Style.TwoTone
        )
        val EnhancedEncryption = MaterialIcon(
            name = "enhanced_encryption",
            style = MaterialIcon.Style.TwoTone
        )
        val Equalizer = MaterialIcon(
            name = "equalizer",
            style = MaterialIcon.Style.TwoTone
        )
        val Error = MaterialIcon(
            name = "error",
            style = MaterialIcon.Style.TwoTone
        )
        val ErrorOutline = MaterialIcon(
            name = "error_outline",
            style = MaterialIcon.Style.TwoTone
        )
        val Escalator = MaterialIcon(
            name = "escalator",
            style = MaterialIcon.Style.TwoTone
        )
        val EscalatorWarning = MaterialIcon(
            name = "escalator_warning",
            style = MaterialIcon.Style.TwoTone
        )
        val Euro = MaterialIcon(
            name = "euro",
            style = MaterialIcon.Style.TwoTone
        )
        val EuroSymbol = MaterialIcon(
            name = "euro_symbol",
            style = MaterialIcon.Style.TwoTone
        )
        val EvStation = MaterialIcon(
            name = "ev_station",
            style = MaterialIcon.Style.TwoTone
        )
        val Event = MaterialIcon(
            name = "event",
            style = MaterialIcon.Style.TwoTone
        )
        val EventAvailable = MaterialIcon(
            name = "event_available",
            style = MaterialIcon.Style.TwoTone
        )
        val EventBusy = MaterialIcon(
            name = "event_busy",
            style = MaterialIcon.Style.TwoTone
        )
        val EventNote = MaterialIcon(
            name = "event_note",
            style = MaterialIcon.Style.TwoTone
        )
        val EventRepeat = MaterialIcon(
            name = "event_repeat",
            style = MaterialIcon.Style.TwoTone
        )
        val EventSeat = MaterialIcon(
            name = "event_seat",
            style = MaterialIcon.Style.TwoTone
        )
        val ExitToApp = MaterialIcon(
            name = "exit_to_app",
            style = MaterialIcon.Style.TwoTone
        )
        val Expand = MaterialIcon(
            name = "expand",
            style = MaterialIcon.Style.TwoTone
        )
        val ExpandCircleDown = MaterialIcon(
            name = "expand_circle_down",
            style = MaterialIcon.Style.TwoTone
        )
        val ExpandLess = MaterialIcon(
            name = "expand_less",
            style = MaterialIcon.Style.TwoTone
        )
        val ExpandMore = MaterialIcon(
            name = "expand_more",
            style = MaterialIcon.Style.TwoTone
        )
        val Explicit = MaterialIcon(
            name = "explicit",
            style = MaterialIcon.Style.TwoTone
        )
        val Explore = MaterialIcon(
            name = "explore",
            style = MaterialIcon.Style.TwoTone
        )
        val ExploreOff = MaterialIcon(
            name = "explore_off",
            style = MaterialIcon.Style.TwoTone
        )
        val Exposure = MaterialIcon(
            name = "exposure",
            style = MaterialIcon.Style.TwoTone
        )
        val ExposureNeg1 = MaterialIcon(
            name = "exposure_neg_1",
            style = MaterialIcon.Style.TwoTone
        )
        val ExposureNeg2 = MaterialIcon(
            name = "exposure_neg_2",
            style = MaterialIcon.Style.TwoTone
        )
        val ExposurePlus1 = MaterialIcon(
            name = "exposure_plus_1",
            style = MaterialIcon.Style.TwoTone
        )
        val ExposurePlus2 = MaterialIcon(
            name = "exposure_plus_2",
            style = MaterialIcon.Style.TwoTone
        )
        val ExposureZero = MaterialIcon(
            name = "exposure_zero",
            style = MaterialIcon.Style.TwoTone
        )
        val Extension = MaterialIcon(
            name = "extension",
            style = MaterialIcon.Style.TwoTone
        )
        val ExtensionOff = MaterialIcon(
            name = "extension_off",
            style = MaterialIcon.Style.TwoTone
        )
        val Face = MaterialIcon(
            name = "face",
            style = MaterialIcon.Style.TwoTone
        )
        val Face2 = MaterialIcon(
            name = "face_2",
            style = MaterialIcon.Style.TwoTone
        )
        val Face3 = MaterialIcon(
            name = "face_3",
            style = MaterialIcon.Style.TwoTone
        )
        val Face4 = MaterialIcon(
            name = "face_4",
            style = MaterialIcon.Style.TwoTone
        )
        val Face5 = MaterialIcon(
            name = "face_5",
            style = MaterialIcon.Style.TwoTone
        )
        val Face6 = MaterialIcon(
            name = "face_6",
            style = MaterialIcon.Style.TwoTone
        )
        val FaceRetouchingNatural = MaterialIcon(
            name = "face_retouching_natural",
            style = MaterialIcon.Style.TwoTone
        )
        val FaceRetouchingOff = MaterialIcon(
            name = "face_retouching_off",
            style = MaterialIcon.Style.TwoTone
        )
        val FaceUnlock = MaterialIcon(
            name = "face_unlock",
            style = MaterialIcon.Style.TwoTone
        )
        val FactCheck = MaterialIcon(
            name = "fact_check",
            style = MaterialIcon.Style.TwoTone
        )
        val Factory = MaterialIcon(
            name = "factory",
            style = MaterialIcon.Style.TwoTone
        )
        val FamilyRestroom = MaterialIcon(
            name = "family_restroom",
            style = MaterialIcon.Style.TwoTone
        )
        val FastForward = MaterialIcon(
            name = "fast_forward",
            style = MaterialIcon.Style.TwoTone
        )
        val FastRewind = MaterialIcon(
            name = "fast_rewind",
            style = MaterialIcon.Style.TwoTone
        )
        val Fastfood = MaterialIcon(
            name = "fastfood",
            style = MaterialIcon.Style.TwoTone
        )
        val Favorite = MaterialIcon(
            name = "favorite",
            style = MaterialIcon.Style.TwoTone
        )
        val FavoriteBorder = MaterialIcon(
            name = "favorite_border",
            style = MaterialIcon.Style.TwoTone
        )
        val Fax = MaterialIcon(
            name = "fax",
            style = MaterialIcon.Style.TwoTone
        )
        val FeaturedPlayList = MaterialIcon(
            name = "featured_play_list",
            style = MaterialIcon.Style.TwoTone
        )
        val FeaturedVideo = MaterialIcon(
            name = "featured_video",
            style = MaterialIcon.Style.TwoTone
        )
        val Feed = MaterialIcon(
            name = "feed",
            style = MaterialIcon.Style.TwoTone
        )
        val Feedback = MaterialIcon(
            name = "feedback",
            style = MaterialIcon.Style.TwoTone
        )
        val Female = MaterialIcon(
            name = "female",
            style = MaterialIcon.Style.TwoTone
        )
        val Fence = MaterialIcon(
            name = "fence",
            style = MaterialIcon.Style.TwoTone
        )
        val Festival = MaterialIcon(
            name = "festival",
            style = MaterialIcon.Style.TwoTone
        )
        val FiberDvr = MaterialIcon(
            name = "fiber_dvr",
            style = MaterialIcon.Style.TwoTone
        )
        val FiberManualRecord = MaterialIcon(
            name = "fiber_manual_record",
            style = MaterialIcon.Style.TwoTone
        )
        val FiberNew = MaterialIcon(
            name = "fiber_new",
            style = MaterialIcon.Style.TwoTone
        )
        val FiberPin = MaterialIcon(
            name = "fiber_pin",
            style = MaterialIcon.Style.TwoTone
        )
        val FiberSmartRecord = MaterialIcon(
            name = "fiber_smart_record",
            style = MaterialIcon.Style.TwoTone
        )
        val FileCopy = MaterialIcon(
            name = "file_copy",
            style = MaterialIcon.Style.TwoTone
        )
        val FileDownload = MaterialIcon(
            name = "file_download",
            style = MaterialIcon.Style.TwoTone
        )
        val FileDownloadDone = MaterialIcon(
            name = "file_download_done",
            style = MaterialIcon.Style.TwoTone
        )
        val FileDownloadOff = MaterialIcon(
            name = "file_download_off",
            style = MaterialIcon.Style.TwoTone
        )
        val FileOpen = MaterialIcon(
            name = "file_open",
            style = MaterialIcon.Style.TwoTone
        )
        val FilePresent = MaterialIcon(
            name = "file_present",
            style = MaterialIcon.Style.TwoTone
        )
        val FileUpload = MaterialIcon(
            name = "file_upload",
            style = MaterialIcon.Style.TwoTone
        )
        val Filter = MaterialIcon(
            name = "filter",
            style = MaterialIcon.Style.TwoTone
        )
        val Filter1 = MaterialIcon(
            name = "filter_1",
            style = MaterialIcon.Style.TwoTone
        )
        val Filter2 = MaterialIcon(
            name = "filter_2",
            style = MaterialIcon.Style.TwoTone
        )
        val Filter3 = MaterialIcon(
            name = "filter_3",
            style = MaterialIcon.Style.TwoTone
        )
        val Filter4 = MaterialIcon(
            name = "filter_4",
            style = MaterialIcon.Style.TwoTone
        )
        val Filter5 = MaterialIcon(
            name = "filter_5",
            style = MaterialIcon.Style.TwoTone
        )
        val Filter6 = MaterialIcon(
            name = "filter_6",
            style = MaterialIcon.Style.TwoTone
        )
        val Filter7 = MaterialIcon(
            name = "filter_7",
            style = MaterialIcon.Style.TwoTone
        )
        val Filter8 = MaterialIcon(
            name = "filter_8",
            style = MaterialIcon.Style.TwoTone
        )
        val Filter9 = MaterialIcon(
            name = "filter_9",
            style = MaterialIcon.Style.TwoTone
        )
        val Filter9Plus = MaterialIcon(
            name = "filter_9_plus",
            style = MaterialIcon.Style.TwoTone
        )
        val FilterAlt = MaterialIcon(
            name = "filter_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val FilterAltOff = MaterialIcon(
            name = "filter_alt_off",
            style = MaterialIcon.Style.TwoTone
        )
        val FilterBAndW = MaterialIcon(
            name = "filter_b_and_w",
            style = MaterialIcon.Style.TwoTone
        )
        val FilterCenterFocus = MaterialIcon(
            name = "filter_center_focus",
            style = MaterialIcon.Style.TwoTone
        )
        val FilterDrama = MaterialIcon(
            name = "filter_drama",
            style = MaterialIcon.Style.TwoTone
        )
        val FilterFrames = MaterialIcon(
            name = "filter_frames",
            style = MaterialIcon.Style.TwoTone
        )
        val FilterHdr = MaterialIcon(
            name = "filter_hdr",
            style = MaterialIcon.Style.TwoTone
        )
        val FilterList = MaterialIcon(
            name = "filter_list",
            style = MaterialIcon.Style.TwoTone
        )
        val FilterListOff = MaterialIcon(
            name = "filter_list_off",
            style = MaterialIcon.Style.TwoTone
        )
        val FilterNone = MaterialIcon(
            name = "filter_none",
            style = MaterialIcon.Style.TwoTone
        )
        val FilterTiltShift = MaterialIcon(
            name = "filter_tilt_shift",
            style = MaterialIcon.Style.TwoTone
        )
        val FilterVintage = MaterialIcon(
            name = "filter_vintage",
            style = MaterialIcon.Style.TwoTone
        )
        val FindInPage = MaterialIcon(
            name = "find_in_page",
            style = MaterialIcon.Style.TwoTone
        )
        val FindReplace = MaterialIcon(
            name = "find_replace",
            style = MaterialIcon.Style.TwoTone
        )
        val Fingerprint = MaterialIcon(
            name = "fingerprint",
            style = MaterialIcon.Style.TwoTone
        )
        val FireExtinguisher = MaterialIcon(
            name = "fire_extinguisher",
            style = MaterialIcon.Style.TwoTone
        )
        val FireHydrantAlt = MaterialIcon(
            name = "fire_hydrant_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val FireTruck = MaterialIcon(
            name = "fire_truck",
            style = MaterialIcon.Style.TwoTone
        )
        val Fireplace = MaterialIcon(
            name = "fireplace",
            style = MaterialIcon.Style.TwoTone
        )
        val FirstPage = MaterialIcon(
            name = "first_page",
            style = MaterialIcon.Style.TwoTone
        )
        val FitScreen = MaterialIcon(
            name = "fit_screen",
            style = MaterialIcon.Style.TwoTone
        )
        val Fitbit = MaterialIcon(
            name = "fitbit",
            style = MaterialIcon.Style.TwoTone
        )
        val FitnessCenter = MaterialIcon(
            name = "fitness_center",
            style = MaterialIcon.Style.TwoTone
        )
        val Flag = MaterialIcon(
            name = "flag",
            style = MaterialIcon.Style.TwoTone
        )
        val FlagCircle = MaterialIcon(
            name = "flag_circle",
            style = MaterialIcon.Style.TwoTone
        )
        val Flaky = MaterialIcon(
            name = "flaky",
            style = MaterialIcon.Style.TwoTone
        )
        val Flare = MaterialIcon(
            name = "flare",
            style = MaterialIcon.Style.TwoTone
        )
        val FlashAuto = MaterialIcon(
            name = "flash_auto",
            style = MaterialIcon.Style.TwoTone
        )
        val FlashOff = MaterialIcon(
            name = "flash_off",
            style = MaterialIcon.Style.TwoTone
        )
        val FlashOn = MaterialIcon(
            name = "flash_on",
            style = MaterialIcon.Style.TwoTone
        )
        val FlashlightOff = MaterialIcon(
            name = "flashlight_off",
            style = MaterialIcon.Style.TwoTone
        )
        val FlashlightOn = MaterialIcon(
            name = "flashlight_on",
            style = MaterialIcon.Style.TwoTone
        )
        val Flatware = MaterialIcon(
            name = "flatware",
            style = MaterialIcon.Style.TwoTone
        )
        val Flight = MaterialIcon(
            name = "flight",
            style = MaterialIcon.Style.TwoTone
        )
        val FlightClass = MaterialIcon(
            name = "flight_class",
            style = MaterialIcon.Style.TwoTone
        )
        val FlightLand = MaterialIcon(
            name = "flight_land",
            style = MaterialIcon.Style.TwoTone
        )
        val FlightTakeoff = MaterialIcon(
            name = "flight_takeoff",
            style = MaterialIcon.Style.TwoTone
        )
        val Flip = MaterialIcon(
            name = "flip",
            style = MaterialIcon.Style.TwoTone
        )
        val FlipCameraAndroid = MaterialIcon(
            name = "flip_camera_android",
            style = MaterialIcon.Style.TwoTone
        )
        val FlipCameraIos = MaterialIcon(
            name = "flip_camera_ios",
            style = MaterialIcon.Style.TwoTone
        )
        val FlipToBack = MaterialIcon(
            name = "flip_to_back",
            style = MaterialIcon.Style.TwoTone
        )
        val FlipToFront = MaterialIcon(
            name = "flip_to_front",
            style = MaterialIcon.Style.TwoTone
        )
        val Flood = MaterialIcon(
            name = "flood",
            style = MaterialIcon.Style.TwoTone
        )
        val Fluorescent = MaterialIcon(
            name = "fluorescent",
            style = MaterialIcon.Style.TwoTone
        )
        val FlutterDash = MaterialIcon(
            name = "flutter_dash",
            style = MaterialIcon.Style.TwoTone
        )
        val FmdBad = MaterialIcon(
            name = "fmd_bad",
            style = MaterialIcon.Style.TwoTone
        )
        val FmdGood = MaterialIcon(
            name = "fmd_good",
            style = MaterialIcon.Style.TwoTone
        )
        val Folder = MaterialIcon(
            name = "folder",
            style = MaterialIcon.Style.TwoTone
        )
        val FolderCopy = MaterialIcon(
            name = "folder_copy",
            style = MaterialIcon.Style.TwoTone
        )
        val FolderDelete = MaterialIcon(
            name = "folder_delete",
            style = MaterialIcon.Style.TwoTone
        )
        val FolderOff = MaterialIcon(
            name = "folder_off",
            style = MaterialIcon.Style.TwoTone
        )
        val FolderOpen = MaterialIcon(
            name = "folder_open",
            style = MaterialIcon.Style.TwoTone
        )
        val FolderShared = MaterialIcon(
            name = "folder_shared",
            style = MaterialIcon.Style.TwoTone
        )
        val FolderSpecial = MaterialIcon(
            name = "folder_special",
            style = MaterialIcon.Style.TwoTone
        )
        val FolderZip = MaterialIcon(
            name = "folder_zip",
            style = MaterialIcon.Style.TwoTone
        )
        val FollowTheSigns = MaterialIcon(
            name = "follow_the_signs",
            style = MaterialIcon.Style.TwoTone
        )
        val FontDownload = MaterialIcon(
            name = "font_download",
            style = MaterialIcon.Style.TwoTone
        )
        val FontDownloadOff = MaterialIcon(
            name = "font_download_off",
            style = MaterialIcon.Style.TwoTone
        )
        val FoodBank = MaterialIcon(
            name = "food_bank",
            style = MaterialIcon.Style.TwoTone
        )
        val Forest = MaterialIcon(
            name = "forest",
            style = MaterialIcon.Style.TwoTone
        )
        val ForkLeft = MaterialIcon(
            name = "fork_left",
            style = MaterialIcon.Style.TwoTone
        )
        val ForkRight = MaterialIcon(
            name = "fork_right",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatAlignCenter = MaterialIcon(
            name = "format_align_center",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatAlignJustify = MaterialIcon(
            name = "format_align_justify",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatAlignLeft = MaterialIcon(
            name = "format_align_left",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatAlignRight = MaterialIcon(
            name = "format_align_right",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatBold = MaterialIcon(
            name = "format_bold",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatClear = MaterialIcon(
            name = "format_clear",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatColorFill = MaterialIcon(
            name = "format_color_fill",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatColorReset = MaterialIcon(
            name = "format_color_reset",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatColorText = MaterialIcon(
            name = "format_color_text",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatIndentDecrease = MaterialIcon(
            name = "format_indent_decrease",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatIndentIncrease = MaterialIcon(
            name = "format_indent_increase",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatItalic = MaterialIcon(
            name = "format_italic",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatLineSpacing = MaterialIcon(
            name = "format_line_spacing",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatListBulleted = MaterialIcon(
            name = "format_list_bulleted",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatListNumbered = MaterialIcon(
            name = "format_list_numbered",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatListNumberedRtl = MaterialIcon(
            name = "format_list_numbered_rtl",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatOverline = MaterialIcon(
            name = "format_overline",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatPaint = MaterialIcon(
            name = "format_paint",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatQuote = MaterialIcon(
            name = "format_quote",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatShapes = MaterialIcon(
            name = "format_shapes",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatSize = MaterialIcon(
            name = "format_size",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatStrikethrough = MaterialIcon(
            name = "format_strikethrough",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatTextdirectionLToR = MaterialIcon(
            name = "format_textdirection_l_to_r",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatTextdirectionRToL = MaterialIcon(
            name = "format_textdirection_r_to_l",
            style = MaterialIcon.Style.TwoTone
        )
        val FormatUnderlined = MaterialIcon(
            name = "format_underlined",
            style = MaterialIcon.Style.TwoTone
        )
        val Fort = MaterialIcon(
            name = "fort",
            style = MaterialIcon.Style.TwoTone
        )
        val Forum = MaterialIcon(
            name = "forum",
            style = MaterialIcon.Style.TwoTone
        )
        val Forward = MaterialIcon(
            name = "forward",
            style = MaterialIcon.Style.TwoTone
        )
        val Forward10 = MaterialIcon(
            name = "forward_10",
            style = MaterialIcon.Style.TwoTone
        )
        val Forward30 = MaterialIcon(
            name = "forward_30",
            style = MaterialIcon.Style.TwoTone
        )
        val Forward5 = MaterialIcon(
            name = "forward_5",
            style = MaterialIcon.Style.TwoTone
        )
        val ForwardToInbox = MaterialIcon(
            name = "forward_to_inbox",
            style = MaterialIcon.Style.TwoTone
        )
        val Foundation = MaterialIcon(
            name = "foundation",
            style = MaterialIcon.Style.TwoTone
        )
        val FreeBreakfast = MaterialIcon(
            name = "free_breakfast",
            style = MaterialIcon.Style.TwoTone
        )
        val FreeCancellation = MaterialIcon(
            name = "free_cancellation",
            style = MaterialIcon.Style.TwoTone
        )
        val FrontHand = MaterialIcon(
            name = "front_hand",
            style = MaterialIcon.Style.TwoTone
        )
        val Fullscreen = MaterialIcon(
            name = "fullscreen",
            style = MaterialIcon.Style.TwoTone
        )
        val FullscreenExit = MaterialIcon(
            name = "fullscreen_exit",
            style = MaterialIcon.Style.TwoTone
        )
        val Functions = MaterialIcon(
            name = "functions",
            style = MaterialIcon.Style.TwoTone
        )
        val GMobiledata = MaterialIcon(
            name = "g_mobiledata",
            style = MaterialIcon.Style.TwoTone
        )
        val GTranslate = MaterialIcon(
            name = "g_translate",
            style = MaterialIcon.Style.TwoTone
        )
        val Gamepad = MaterialIcon(
            name = "gamepad",
            style = MaterialIcon.Style.TwoTone
        )
        val Games = MaterialIcon(
            name = "games",
            style = MaterialIcon.Style.TwoTone
        )
        val Garage = MaterialIcon(
            name = "garage",
            style = MaterialIcon.Style.TwoTone
        )
        val GasMeter = MaterialIcon(
            name = "gas_meter",
            style = MaterialIcon.Style.TwoTone
        )
        val Gavel = MaterialIcon(
            name = "gavel",
            style = MaterialIcon.Style.TwoTone
        )
        val GeneratingTokens = MaterialIcon(
            name = "generating_tokens",
            style = MaterialIcon.Style.TwoTone
        )
        val Gesture = MaterialIcon(
            name = "gesture",
            style = MaterialIcon.Style.TwoTone
        )
        val GetApp = MaterialIcon(
            name = "get_app",
            style = MaterialIcon.Style.TwoTone
        )
        val Gif = MaterialIcon(
            name = "gif",
            style = MaterialIcon.Style.TwoTone
        )
        val GifBox = MaterialIcon(
            name = "gif_box",
            style = MaterialIcon.Style.TwoTone
        )
        val Girl = MaterialIcon(
            name = "girl",
            style = MaterialIcon.Style.TwoTone
        )
        val Gite = MaterialIcon(
            name = "gite",
            style = MaterialIcon.Style.TwoTone
        )
        val GolfCourse = MaterialIcon(
            name = "golf_course",
            style = MaterialIcon.Style.TwoTone
        )
        val GppBad = MaterialIcon(
            name = "gpp_bad",
            style = MaterialIcon.Style.TwoTone
        )
        val GppGood = MaterialIcon(
            name = "gpp_good",
            style = MaterialIcon.Style.TwoTone
        )
        val GppMaybe = MaterialIcon(
            name = "gpp_maybe",
            style = MaterialIcon.Style.TwoTone
        )
        val GpsFixed = MaterialIcon(
            name = "gps_fixed",
            style = MaterialIcon.Style.TwoTone
        )
        val GpsNotFixed = MaterialIcon(
            name = "gps_not_fixed",
            style = MaterialIcon.Style.TwoTone
        )
        val GpsOff = MaterialIcon(
            name = "gps_off",
            style = MaterialIcon.Style.TwoTone
        )
        val Grade = MaterialIcon(
            name = "grade",
            style = MaterialIcon.Style.TwoTone
        )
        val Gradient = MaterialIcon(
            name = "gradient",
            style = MaterialIcon.Style.TwoTone
        )
        val Grading = MaterialIcon(
            name = "grading",
            style = MaterialIcon.Style.TwoTone
        )
        val Grain = MaterialIcon(
            name = "grain",
            style = MaterialIcon.Style.TwoTone
        )
        val GraphicEq = MaterialIcon(
            name = "graphic_eq",
            style = MaterialIcon.Style.TwoTone
        )
        val Grass = MaterialIcon(
            name = "grass",
            style = MaterialIcon.Style.TwoTone
        )
        val Grid3x3 = MaterialIcon(
            name = "grid_3x3",
            style = MaterialIcon.Style.TwoTone
        )
        val Grid4x4 = MaterialIcon(
            name = "grid_4x4",
            style = MaterialIcon.Style.TwoTone
        )
        val GridGoldenratio = MaterialIcon(
            name = "grid_goldenratio",
            style = MaterialIcon.Style.TwoTone
        )
        val GridOff = MaterialIcon(
            name = "grid_off",
            style = MaterialIcon.Style.TwoTone
        )
        val GridOn = MaterialIcon(
            name = "grid_on",
            style = MaterialIcon.Style.TwoTone
        )
        val GridView = MaterialIcon(
            name = "grid_view",
            style = MaterialIcon.Style.TwoTone
        )
        val Group = MaterialIcon(
            name = "group",
            style = MaterialIcon.Style.TwoTone
        )
        val GroupAdd = MaterialIcon(
            name = "group_add",
            style = MaterialIcon.Style.TwoTone
        )
        val GroupOff = MaterialIcon(
            name = "group_off",
            style = MaterialIcon.Style.TwoTone
        )
        val GroupRemove = MaterialIcon(
            name = "group_remove",
            style = MaterialIcon.Style.TwoTone
        )
        val GroupWork = MaterialIcon(
            name = "group_work",
            style = MaterialIcon.Style.TwoTone
        )
        val Groups = MaterialIcon(
            name = "groups",
            style = MaterialIcon.Style.TwoTone
        )
        val Groups2 = MaterialIcon(
            name = "groups_2",
            style = MaterialIcon.Style.TwoTone
        )
        val Groups3 = MaterialIcon(
            name = "groups_3",
            style = MaterialIcon.Style.TwoTone
        )
        val HMobiledata = MaterialIcon(
            name = "h_mobiledata",
            style = MaterialIcon.Style.TwoTone
        )
        val HPlusMobiledata = MaterialIcon(
            name = "h_plus_mobiledata",
            style = MaterialIcon.Style.TwoTone
        )
        val Hail = MaterialIcon(
            name = "hail",
            style = MaterialIcon.Style.TwoTone
        )
        val Handshake = MaterialIcon(
            name = "handshake",
            style = MaterialIcon.Style.TwoTone
        )
        val Handyman = MaterialIcon(
            name = "handyman",
            style = MaterialIcon.Style.TwoTone
        )
        val Hardware = MaterialIcon(
            name = "hardware",
            style = MaterialIcon.Style.TwoTone
        )
        val Hd = MaterialIcon(
            name = "hd",
            style = MaterialIcon.Style.TwoTone
        )
        val HdrAuto = MaterialIcon(
            name = "hdr_auto",
            style = MaterialIcon.Style.TwoTone
        )
        val HdrAutoSelect = MaterialIcon(
            name = "hdr_auto_select",
            style = MaterialIcon.Style.TwoTone
        )
        val HdrEnhancedSelect = MaterialIcon(
            name = "hdr_enhanced_select",
            style = MaterialIcon.Style.TwoTone
        )
        val HdrOff = MaterialIcon(
            name = "hdr_off",
            style = MaterialIcon.Style.TwoTone
        )
        val HdrOffSelect = MaterialIcon(
            name = "hdr_off_select",
            style = MaterialIcon.Style.TwoTone
        )
        val HdrOn = MaterialIcon(
            name = "hdr_on",
            style = MaterialIcon.Style.TwoTone
        )
        val HdrOnSelect = MaterialIcon(
            name = "hdr_on_select",
            style = MaterialIcon.Style.TwoTone
        )
        val HdrPlus = MaterialIcon(
            name = "hdr_plus",
            style = MaterialIcon.Style.TwoTone
        )
        val HdrStrong = MaterialIcon(
            name = "hdr_strong",
            style = MaterialIcon.Style.TwoTone
        )
        val HdrWeak = MaterialIcon(
            name = "hdr_weak",
            style = MaterialIcon.Style.TwoTone
        )
        val Headphones = MaterialIcon(
            name = "headphones",
            style = MaterialIcon.Style.TwoTone
        )
        val HeadphonesBattery = MaterialIcon(
            name = "headphones_battery",
            style = MaterialIcon.Style.TwoTone
        )
        val Headset = MaterialIcon(
            name = "headset",
            style = MaterialIcon.Style.TwoTone
        )
        val HeadsetMic = MaterialIcon(
            name = "headset_mic",
            style = MaterialIcon.Style.TwoTone
        )
        val HeadsetOff = MaterialIcon(
            name = "headset_off",
            style = MaterialIcon.Style.TwoTone
        )
        val Healing = MaterialIcon(
            name = "healing",
            style = MaterialIcon.Style.TwoTone
        )
        val HealthAndSafety = MaterialIcon(
            name = "health_and_safety",
            style = MaterialIcon.Style.TwoTone
        )
        val Hearing = MaterialIcon(
            name = "hearing",
            style = MaterialIcon.Style.TwoTone
        )
        val HearingDisabled = MaterialIcon(
            name = "hearing_disabled",
            style = MaterialIcon.Style.TwoTone
        )
        val HeartBroken = MaterialIcon(
            name = "heart_broken",
            style = MaterialIcon.Style.TwoTone
        )
        val HeatPump = MaterialIcon(
            name = "heat_pump",
            style = MaterialIcon.Style.TwoTone
        )
        val Height = MaterialIcon(
            name = "height",
            style = MaterialIcon.Style.TwoTone
        )
        val Help = MaterialIcon(
            name = "help",
            style = MaterialIcon.Style.TwoTone
        )
        val HelpCenter = MaterialIcon(
            name = "help_center",
            style = MaterialIcon.Style.TwoTone
        )
        val HelpOutline = MaterialIcon(
            name = "help_outline",
            style = MaterialIcon.Style.TwoTone
        )
        val Hevc = MaterialIcon(
            name = "hevc",
            style = MaterialIcon.Style.TwoTone
        )
        val Hexagon = MaterialIcon(
            name = "hexagon",
            style = MaterialIcon.Style.TwoTone
        )
        val HideImage = MaterialIcon(
            name = "hide_image",
            style = MaterialIcon.Style.TwoTone
        )
        val HideSource = MaterialIcon(
            name = "hide_source",
            style = MaterialIcon.Style.TwoTone
        )
        val HighQuality = MaterialIcon(
            name = "high_quality",
            style = MaterialIcon.Style.TwoTone
        )
        val Highlight = MaterialIcon(
            name = "highlight",
            style = MaterialIcon.Style.TwoTone
        )
        val HighlightAlt = MaterialIcon(
            name = "highlight_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val HighlightOff = MaterialIcon(
            name = "highlight_off",
            style = MaterialIcon.Style.TwoTone
        )
        val Hiking = MaterialIcon(
            name = "hiking",
            style = MaterialIcon.Style.TwoTone
        )
        val History = MaterialIcon(
            name = "history",
            style = MaterialIcon.Style.TwoTone
        )
        val HistoryEdu = MaterialIcon(
            name = "history_edu",
            style = MaterialIcon.Style.TwoTone
        )
        val HistoryToggleOff = MaterialIcon(
            name = "history_toggle_off",
            style = MaterialIcon.Style.TwoTone
        )
        val Hive = MaterialIcon(
            name = "hive",
            style = MaterialIcon.Style.TwoTone
        )
        val Hls = MaterialIcon(
            name = "hls",
            style = MaterialIcon.Style.TwoTone
        )
        val HlsOff = MaterialIcon(
            name = "hls_off",
            style = MaterialIcon.Style.TwoTone
        )
        val HolidayVillage = MaterialIcon(
            name = "holiday_village",
            style = MaterialIcon.Style.TwoTone
        )
        val Home = MaterialIcon(
            name = "home",
            style = MaterialIcon.Style.TwoTone
        )
        val HomeMax = MaterialIcon(
            name = "home_max",
            style = MaterialIcon.Style.TwoTone
        )
        val HomeMini = MaterialIcon(
            name = "home_mini",
            style = MaterialIcon.Style.TwoTone
        )
        val HomeRepairService = MaterialIcon(
            name = "home_repair_service",
            style = MaterialIcon.Style.TwoTone
        )
        val HomeWork = MaterialIcon(
            name = "home_work",
            style = MaterialIcon.Style.TwoTone
        )
        val HorizontalDistribute = MaterialIcon(
            name = "horizontal_distribute",
            style = MaterialIcon.Style.TwoTone
        )
        val HorizontalRule = MaterialIcon(
            name = "horizontal_rule",
            style = MaterialIcon.Style.TwoTone
        )
        val HorizontalSplit = MaterialIcon(
            name = "horizontal_split",
            style = MaterialIcon.Style.TwoTone
        )
        val HotTub = MaterialIcon(
            name = "hot_tub",
            style = MaterialIcon.Style.TwoTone
        )
        val Hotel = MaterialIcon(
            name = "hotel",
            style = MaterialIcon.Style.TwoTone
        )
        val HotelClass = MaterialIcon(
            name = "hotel_class",
            style = MaterialIcon.Style.TwoTone
        )
        val HourglassBottom = MaterialIcon(
            name = "hourglass_bottom",
            style = MaterialIcon.Style.TwoTone
        )
        val HourglassDisabled = MaterialIcon(
            name = "hourglass_disabled",
            style = MaterialIcon.Style.TwoTone
        )
        val HourglassEmpty = MaterialIcon(
            name = "hourglass_empty",
            style = MaterialIcon.Style.TwoTone
        )
        val HourglassFull = MaterialIcon(
            name = "hourglass_full",
            style = MaterialIcon.Style.TwoTone
        )
        val HourglassTop = MaterialIcon(
            name = "hourglass_top",
            style = MaterialIcon.Style.TwoTone
        )
        val House = MaterialIcon(
            name = "house",
            style = MaterialIcon.Style.TwoTone
        )
        val HouseSiding = MaterialIcon(
            name = "house_siding",
            style = MaterialIcon.Style.TwoTone
        )
        val Houseboat = MaterialIcon(
            name = "houseboat",
            style = MaterialIcon.Style.TwoTone
        )
        val HowToReg = MaterialIcon(
            name = "how_to_reg",
            style = MaterialIcon.Style.TwoTone
        )
        val HowToVote = MaterialIcon(
            name = "how_to_vote",
            style = MaterialIcon.Style.TwoTone
        )
        val Html = MaterialIcon(
            name = "html",
            style = MaterialIcon.Style.TwoTone
        )
        val Http = MaterialIcon(
            name = "http",
            style = MaterialIcon.Style.TwoTone
        )
        val Https = MaterialIcon(
            name = "https",
            style = MaterialIcon.Style.TwoTone
        )
        val Hub = MaterialIcon(
            name = "hub",
            style = MaterialIcon.Style.TwoTone
        )
        val Hvac = MaterialIcon(
            name = "hvac",
            style = MaterialIcon.Style.TwoTone
        )
        val IceSkating = MaterialIcon(
            name = "ice_skating",
            style = MaterialIcon.Style.TwoTone
        )
        val Icecream = MaterialIcon(
            name = "icecream",
            style = MaterialIcon.Style.TwoTone
        )
        val Image = MaterialIcon(
            name = "image",
            style = MaterialIcon.Style.TwoTone
        )
        val ImageAspectRatio = MaterialIcon(
            name = "image_aspect_ratio",
            style = MaterialIcon.Style.TwoTone
        )
        val ImageNotSupported = MaterialIcon(
            name = "image_not_supported",
            style = MaterialIcon.Style.TwoTone
        )
        val ImageSearch = MaterialIcon(
            name = "image_search",
            style = MaterialIcon.Style.TwoTone
        )
        val ImagesearchRoller = MaterialIcon(
            name = "imagesearch_roller",
            style = MaterialIcon.Style.TwoTone
        )
        val ImportContacts = MaterialIcon(
            name = "import_contacts",
            style = MaterialIcon.Style.TwoTone
        )
        val ImportExport = MaterialIcon(
            name = "import_export",
            style = MaterialIcon.Style.TwoTone
        )
        val ImportantDevices = MaterialIcon(
            name = "important_devices",
            style = MaterialIcon.Style.TwoTone
        )
        val Inbox = MaterialIcon(
            name = "inbox",
            style = MaterialIcon.Style.TwoTone
        )
        val IncompleteCircle = MaterialIcon(
            name = "incomplete_circle",
            style = MaterialIcon.Style.TwoTone
        )
        val IndeterminateCheckBox = MaterialIcon(
            name = "indeterminate_check_box",
            style = MaterialIcon.Style.TwoTone
        )
        val Info = MaterialIcon(
            name = "info",
            style = MaterialIcon.Style.TwoTone
        )
        val Input = MaterialIcon(
            name = "input",
            style = MaterialIcon.Style.TwoTone
        )
        val InsertChart = MaterialIcon(
            name = "insert_chart",
            style = MaterialIcon.Style.TwoTone
        )
        val InsertChartOutlined = MaterialIcon(
            name = "insert_chart_outlined",
            style = MaterialIcon.Style.TwoTone
        )
        val InsertComment = MaterialIcon(
            name = "insert_comment",
            style = MaterialIcon.Style.TwoTone
        )
        val InsertDriveFile = MaterialIcon(
            name = "insert_drive_file",
            style = MaterialIcon.Style.TwoTone
        )
        val InsertEmoticon = MaterialIcon(
            name = "insert_emoticon",
            style = MaterialIcon.Style.TwoTone
        )
        val InsertInvitation = MaterialIcon(
            name = "insert_invitation",
            style = MaterialIcon.Style.TwoTone
        )
        val InsertLink = MaterialIcon(
            name = "insert_link",
            style = MaterialIcon.Style.TwoTone
        )
        val InsertPageBreak = MaterialIcon(
            name = "insert_page_break",
            style = MaterialIcon.Style.TwoTone
        )
        val InsertPhoto = MaterialIcon(
            name = "insert_photo",
            style = MaterialIcon.Style.TwoTone
        )
        val Insights = MaterialIcon(
            name = "insights",
            style = MaterialIcon.Style.TwoTone
        )
        val InstallDesktop = MaterialIcon(
            name = "install_desktop",
            style = MaterialIcon.Style.TwoTone
        )
        val InstallMobile = MaterialIcon(
            name = "install_mobile",
            style = MaterialIcon.Style.TwoTone
        )
        val IntegrationInstructions = MaterialIcon(
            name = "integration_instructions",
            style = MaterialIcon.Style.TwoTone
        )
        val Interests = MaterialIcon(
            name = "interests",
            style = MaterialIcon.Style.TwoTone
        )
        val InterpreterMode = MaterialIcon(
            name = "interpreter_mode",
            style = MaterialIcon.Style.TwoTone
        )
        val Inventory = MaterialIcon(
            name = "inventory",
            style = MaterialIcon.Style.TwoTone
        )
        val Inventory2 = MaterialIcon(
            name = "inventory_2",
            style = MaterialIcon.Style.TwoTone
        )
        val InvertColors = MaterialIcon(
            name = "invert_colors",
            style = MaterialIcon.Style.TwoTone
        )
        val InvertColorsOff = MaterialIcon(
            name = "invert_colors_off",
            style = MaterialIcon.Style.TwoTone
        )
        val IosShare = MaterialIcon(
            name = "ios_share",
            style = MaterialIcon.Style.TwoTone
        )
        val Iron = MaterialIcon(
            name = "iron",
            style = MaterialIcon.Style.TwoTone
        )
        val Iso = MaterialIcon(
            name = "iso",
            style = MaterialIcon.Style.TwoTone
        )
        val Javascript = MaterialIcon(
            name = "javascript",
            style = MaterialIcon.Style.TwoTone
        )
        val JoinFull = MaterialIcon(
            name = "join_full",
            style = MaterialIcon.Style.TwoTone
        )
        val JoinInner = MaterialIcon(
            name = "join_inner",
            style = MaterialIcon.Style.TwoTone
        )
        val JoinLeft = MaterialIcon(
            name = "join_left",
            style = MaterialIcon.Style.TwoTone
        )
        val JoinRight = MaterialIcon(
            name = "join_right",
            style = MaterialIcon.Style.TwoTone
        )
        val Kayaking = MaterialIcon(
            name = "kayaking",
            style = MaterialIcon.Style.TwoTone
        )
        val KebabDining = MaterialIcon(
            name = "kebab_dining",
            style = MaterialIcon.Style.TwoTone
        )
        val Key = MaterialIcon(
            name = "key",
            style = MaterialIcon.Style.TwoTone
        )
        val KeyOff = MaterialIcon(
            name = "key_off",
            style = MaterialIcon.Style.TwoTone
        )
        val Keyboard = MaterialIcon(
            name = "keyboard",
            style = MaterialIcon.Style.TwoTone
        )
        val KeyboardAlt = MaterialIcon(
            name = "keyboard_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val KeyboardArrowDown = MaterialIcon(
            name = "keyboard_arrow_down",
            style = MaterialIcon.Style.TwoTone
        )
        val KeyboardArrowLeft = MaterialIcon(
            name = "keyboard_arrow_left",
            style = MaterialIcon.Style.TwoTone
        )
        val KeyboardArrowRight = MaterialIcon(
            name = "keyboard_arrow_right",
            style = MaterialIcon.Style.TwoTone
        )
        val KeyboardArrowUp = MaterialIcon(
            name = "keyboard_arrow_up",
            style = MaterialIcon.Style.TwoTone
        )
        val KeyboardBackspace = MaterialIcon(
            name = "keyboard_backspace",
            style = MaterialIcon.Style.TwoTone
        )
        val KeyboardCapslock = MaterialIcon(
            name = "keyboard_capslock",
            style = MaterialIcon.Style.TwoTone
        )
        val KeyboardCommandKey = MaterialIcon(
            name = "keyboard_command_key",
            style = MaterialIcon.Style.TwoTone
        )
        val KeyboardControlKey = MaterialIcon(
            name = "keyboard_control_key",
            style = MaterialIcon.Style.TwoTone
        )
        val KeyboardDoubleArrowDown = MaterialIcon(
            name = "keyboard_double_arrow_down",
            style = MaterialIcon.Style.TwoTone
        )
        val KeyboardDoubleArrowLeft = MaterialIcon(
            name = "keyboard_double_arrow_left",
            style = MaterialIcon.Style.TwoTone
        )
        val KeyboardDoubleArrowRight = MaterialIcon(
            name = "keyboard_double_arrow_right",
            style = MaterialIcon.Style.TwoTone
        )
        val KeyboardDoubleArrowUp = MaterialIcon(
            name = "keyboard_double_arrow_up",
            style = MaterialIcon.Style.TwoTone
        )
        val KeyboardHide = MaterialIcon(
            name = "keyboard_hide",
            style = MaterialIcon.Style.TwoTone
        )
        val KeyboardOptionKey = MaterialIcon(
            name = "keyboard_option_key",
            style = MaterialIcon.Style.TwoTone
        )
        val KeyboardReturn = MaterialIcon(
            name = "keyboard_return",
            style = MaterialIcon.Style.TwoTone
        )
        val KeyboardTab = MaterialIcon(
            name = "keyboard_tab",
            style = MaterialIcon.Style.TwoTone
        )
        val KeyboardVoice = MaterialIcon(
            name = "keyboard_voice",
            style = MaterialIcon.Style.TwoTone
        )
        val KingBed = MaterialIcon(
            name = "king_bed",
            style = MaterialIcon.Style.TwoTone
        )
        val Kitchen = MaterialIcon(
            name = "kitchen",
            style = MaterialIcon.Style.TwoTone
        )
        val Kitesurfing = MaterialIcon(
            name = "kitesurfing",
            style = MaterialIcon.Style.TwoTone
        )
        val Label = MaterialIcon(
            name = "label",
            style = MaterialIcon.Style.TwoTone
        )
        val LabelImportant = MaterialIcon(
            name = "label_important",
            style = MaterialIcon.Style.TwoTone
        )
        val LabelOff = MaterialIcon(
            name = "label_off",
            style = MaterialIcon.Style.TwoTone
        )
        val Lan = MaterialIcon(
            name = "lan",
            style = MaterialIcon.Style.TwoTone
        )
        val Landscape = MaterialIcon(
            name = "landscape",
            style = MaterialIcon.Style.TwoTone
        )
        val Landslide = MaterialIcon(
            name = "landslide",
            style = MaterialIcon.Style.TwoTone
        )
        val Language = MaterialIcon(
            name = "language",
            style = MaterialIcon.Style.TwoTone
        )
        val Laptop = MaterialIcon(
            name = "laptop",
            style = MaterialIcon.Style.TwoTone
        )
        val LaptopChromebook = MaterialIcon(
            name = "laptop_chromebook",
            style = MaterialIcon.Style.TwoTone
        )
        val LaptopMac = MaterialIcon(
            name = "laptop_mac",
            style = MaterialIcon.Style.TwoTone
        )
        val LaptopWindows = MaterialIcon(
            name = "laptop_windows",
            style = MaterialIcon.Style.TwoTone
        )
        val LastPage = MaterialIcon(
            name = "last_page",
            style = MaterialIcon.Style.TwoTone
        )
        val Launch = MaterialIcon(
            name = "launch",
            style = MaterialIcon.Style.TwoTone
        )
        val Layers = MaterialIcon(
            name = "layers",
            style = MaterialIcon.Style.TwoTone
        )
        val LayersClear = MaterialIcon(
            name = "layers_clear",
            style = MaterialIcon.Style.TwoTone
        )
        val Leaderboard = MaterialIcon(
            name = "leaderboard",
            style = MaterialIcon.Style.TwoTone
        )
        val LeakAdd = MaterialIcon(
            name = "leak_add",
            style = MaterialIcon.Style.TwoTone
        )
        val LeakRemove = MaterialIcon(
            name = "leak_remove",
            style = MaterialIcon.Style.TwoTone
        )
        val LegendToggle = MaterialIcon(
            name = "legend_toggle",
            style = MaterialIcon.Style.TwoTone
        )
        val Lens = MaterialIcon(
            name = "lens",
            style = MaterialIcon.Style.TwoTone
        )
        val LensBlur = MaterialIcon(
            name = "lens_blur",
            style = MaterialIcon.Style.TwoTone
        )
        val LibraryAdd = MaterialIcon(
            name = "library_add",
            style = MaterialIcon.Style.TwoTone
        )
        val LibraryAddCheck = MaterialIcon(
            name = "library_add_check",
            style = MaterialIcon.Style.TwoTone
        )
        val LibraryBooks = MaterialIcon(
            name = "library_books",
            style = MaterialIcon.Style.TwoTone
        )
        val LibraryMusic = MaterialIcon(
            name = "library_music",
            style = MaterialIcon.Style.TwoTone
        )
        val Light = MaterialIcon(
            name = "light",
            style = MaterialIcon.Style.TwoTone
        )
        val LightMode = MaterialIcon(
            name = "light_mode",
            style = MaterialIcon.Style.TwoTone
        )
        val Lightbulb = MaterialIcon(
            name = "lightbulb",
            style = MaterialIcon.Style.TwoTone
        )
        val LightbulbCircle = MaterialIcon(
            name = "lightbulb_circle",
            style = MaterialIcon.Style.TwoTone
        )
        val LineAxis = MaterialIcon(
            name = "line_axis",
            style = MaterialIcon.Style.TwoTone
        )
        val LineStyle = MaterialIcon(
            name = "line_style",
            style = MaterialIcon.Style.TwoTone
        )
        val LineWeight = MaterialIcon(
            name = "line_weight",
            style = MaterialIcon.Style.TwoTone
        )
        val LinearScale = MaterialIcon(
            name = "linear_scale",
            style = MaterialIcon.Style.TwoTone
        )
        val Link = MaterialIcon(
            name = "link",
            style = MaterialIcon.Style.TwoTone
        )
        val LinkOff = MaterialIcon(
            name = "link_off",
            style = MaterialIcon.Style.TwoTone
        )
        val LinkedCamera = MaterialIcon(
            name = "linked_camera",
            style = MaterialIcon.Style.TwoTone
        )
        val Liquor = MaterialIcon(
            name = "liquor",
            style = MaterialIcon.Style.TwoTone
        )
        val List = MaterialIcon(
            name = "list",
            style = MaterialIcon.Style.TwoTone
        )
        val ListAlt = MaterialIcon(
            name = "list_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val LiveHelp = MaterialIcon(
            name = "live_help",
            style = MaterialIcon.Style.TwoTone
        )
        val LiveTv = MaterialIcon(
            name = "live_tv",
            style = MaterialIcon.Style.TwoTone
        )
        val Living = MaterialIcon(
            name = "living",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalActivity = MaterialIcon(
            name = "local_activity",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalAirport = MaterialIcon(
            name = "local_airport",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalAtm = MaterialIcon(
            name = "local_atm",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalBar = MaterialIcon(
            name = "local_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalCafe = MaterialIcon(
            name = "local_cafe",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalCarWash = MaterialIcon(
            name = "local_car_wash",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalConvenienceStore = MaterialIcon(
            name = "local_convenience_store",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalDining = MaterialIcon(
            name = "local_dining",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalDrink = MaterialIcon(
            name = "local_drink",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalFireDepartment = MaterialIcon(
            name = "local_fire_department",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalFlorist = MaterialIcon(
            name = "local_florist",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalGasStation = MaterialIcon(
            name = "local_gas_station",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalGroceryStore = MaterialIcon(
            name = "local_grocery_store",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalHospital = MaterialIcon(
            name = "local_hospital",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalHotel = MaterialIcon(
            name = "local_hotel",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalLaundryService = MaterialIcon(
            name = "local_laundry_service",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalLibrary = MaterialIcon(
            name = "local_library",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalMall = MaterialIcon(
            name = "local_mall",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalMovies = MaterialIcon(
            name = "local_movies",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalOffer = MaterialIcon(
            name = "local_offer",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalParking = MaterialIcon(
            name = "local_parking",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalPharmacy = MaterialIcon(
            name = "local_pharmacy",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalPhone = MaterialIcon(
            name = "local_phone",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalPizza = MaterialIcon(
            name = "local_pizza",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalPlay = MaterialIcon(
            name = "local_play",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalPolice = MaterialIcon(
            name = "local_police",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalPostOffice = MaterialIcon(
            name = "local_post_office",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalPrintshop = MaterialIcon(
            name = "local_printshop",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalSee = MaterialIcon(
            name = "local_see",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalShipping = MaterialIcon(
            name = "local_shipping",
            style = MaterialIcon.Style.TwoTone
        )
        val LocalTaxi = MaterialIcon(
            name = "local_taxi",
            style = MaterialIcon.Style.TwoTone
        )
        val LocationCity = MaterialIcon(
            name = "location_city",
            style = MaterialIcon.Style.TwoTone
        )
        val LocationDisabled = MaterialIcon(
            name = "location_disabled",
            style = MaterialIcon.Style.TwoTone
        )
        val LocationOff = MaterialIcon(
            name = "location_off",
            style = MaterialIcon.Style.TwoTone
        )
        val LocationOn = MaterialIcon(
            name = "location_on",
            style = MaterialIcon.Style.TwoTone
        )
        val LocationSearching = MaterialIcon(
            name = "location_searching",
            style = MaterialIcon.Style.TwoTone
        )
        val Lock = MaterialIcon(
            name = "lock",
            style = MaterialIcon.Style.TwoTone
        )
        val LockClock = MaterialIcon(
            name = "lock_clock",
            style = MaterialIcon.Style.TwoTone
        )
        val LockOpen = MaterialIcon(
            name = "lock_open",
            style = MaterialIcon.Style.TwoTone
        )
        val LockPerson = MaterialIcon(
            name = "lock_person",
            style = MaterialIcon.Style.TwoTone
        )
        val LockReset = MaterialIcon(
            name = "lock_reset",
            style = MaterialIcon.Style.TwoTone
        )
        val Login = MaterialIcon(
            name = "login",
            style = MaterialIcon.Style.TwoTone
        )
        val LogoDev = MaterialIcon(
            name = "logo_dev",
            style = MaterialIcon.Style.TwoTone
        )
        val Logout = MaterialIcon(
            name = "logout",
            style = MaterialIcon.Style.TwoTone
        )
        val Looks = MaterialIcon(
            name = "looks",
            style = MaterialIcon.Style.TwoTone
        )
        val Looks3 = MaterialIcon(
            name = "looks_3",
            style = MaterialIcon.Style.TwoTone
        )
        val Looks4 = MaterialIcon(
            name = "looks_4",
            style = MaterialIcon.Style.TwoTone
        )
        val Looks5 = MaterialIcon(
            name = "looks_5",
            style = MaterialIcon.Style.TwoTone
        )
        val Looks6 = MaterialIcon(
            name = "looks_6",
            style = MaterialIcon.Style.TwoTone
        )
        val LooksOne = MaterialIcon(
            name = "looks_one",
            style = MaterialIcon.Style.TwoTone
        )
        val LooksTwo = MaterialIcon(
            name = "looks_two",
            style = MaterialIcon.Style.TwoTone
        )
        val Loop = MaterialIcon(
            name = "loop",
            style = MaterialIcon.Style.TwoTone
        )
        val Loupe = MaterialIcon(
            name = "loupe",
            style = MaterialIcon.Style.TwoTone
        )
        val LowPriority = MaterialIcon(
            name = "low_priority",
            style = MaterialIcon.Style.TwoTone
        )
        val Loyalty = MaterialIcon(
            name = "loyalty",
            style = MaterialIcon.Style.TwoTone
        )
        val LteMobiledata = MaterialIcon(
            name = "lte_mobiledata",
            style = MaterialIcon.Style.TwoTone
        )
        val LtePlusMobiledata = MaterialIcon(
            name = "lte_plus_mobiledata",
            style = MaterialIcon.Style.TwoTone
        )
        val Luggage = MaterialIcon(
            name = "luggage",
            style = MaterialIcon.Style.TwoTone
        )
        val LunchDining = MaterialIcon(
            name = "lunch_dining",
            style = MaterialIcon.Style.TwoTone
        )
        val Lyrics = MaterialIcon(
            name = "lyrics",
            style = MaterialIcon.Style.TwoTone
        )
        val MacroOff = MaterialIcon(
            name = "macro_off",
            style = MaterialIcon.Style.TwoTone
        )
        val Mail = MaterialIcon(
            name = "mail",
            style = MaterialIcon.Style.TwoTone
        )
        val MailLock = MaterialIcon(
            name = "mail_lock",
            style = MaterialIcon.Style.TwoTone
        )
        val MailOutline = MaterialIcon(
            name = "mail_outline",
            style = MaterialIcon.Style.TwoTone
        )
        val Male = MaterialIcon(
            name = "male",
            style = MaterialIcon.Style.TwoTone
        )
        val Man = MaterialIcon(
            name = "man",
            style = MaterialIcon.Style.TwoTone
        )
        val Man2 = MaterialIcon(
            name = "man_2",
            style = MaterialIcon.Style.TwoTone
        )
        val Man3 = MaterialIcon(
            name = "man_3",
            style = MaterialIcon.Style.TwoTone
        )
        val Man4 = MaterialIcon(
            name = "man_4",
            style = MaterialIcon.Style.TwoTone
        )
        val ManageAccounts = MaterialIcon(
            name = "manage_accounts",
            style = MaterialIcon.Style.TwoTone
        )
        val ManageHistory = MaterialIcon(
            name = "manage_history",
            style = MaterialIcon.Style.TwoTone
        )
        val ManageSearch = MaterialIcon(
            name = "manage_search",
            style = MaterialIcon.Style.TwoTone
        )
        val Map = MaterialIcon(
            name = "map",
            style = MaterialIcon.Style.TwoTone
        )
        val MapsHomeWork = MaterialIcon(
            name = "maps_home_work",
            style = MaterialIcon.Style.TwoTone
        )
        val MapsUgc = MaterialIcon(
            name = "maps_ugc",
            style = MaterialIcon.Style.TwoTone
        )
        val Margin = MaterialIcon(
            name = "margin",
            style = MaterialIcon.Style.TwoTone
        )
        val MarkAsUnread = MaterialIcon(
            name = "mark_as_unread",
            style = MaterialIcon.Style.TwoTone
        )
        val MarkChatRead = MaterialIcon(
            name = "mark_chat_read",
            style = MaterialIcon.Style.TwoTone
        )
        val MarkChatUnread = MaterialIcon(
            name = "mark_chat_unread",
            style = MaterialIcon.Style.TwoTone
        )
        val MarkEmailRead = MaterialIcon(
            name = "mark_email_read",
            style = MaterialIcon.Style.TwoTone
        )
        val MarkEmailUnread = MaterialIcon(
            name = "mark_email_unread",
            style = MaterialIcon.Style.TwoTone
        )
        val MarkUnreadChatAlt = MaterialIcon(
            name = "mark_unread_chat_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val Markunread = MaterialIcon(
            name = "markunread",
            style = MaterialIcon.Style.TwoTone
        )
        val MarkunreadMailbox = MaterialIcon(
            name = "markunread_mailbox",
            style = MaterialIcon.Style.TwoTone
        )
        val Masks = MaterialIcon(
            name = "masks",
            style = MaterialIcon.Style.TwoTone
        )
        val Maximize = MaterialIcon(
            name = "maximize",
            style = MaterialIcon.Style.TwoTone
        )
        val MediaBluetoothOff = MaterialIcon(
            name = "media_bluetooth_off",
            style = MaterialIcon.Style.TwoTone
        )
        val MediaBluetoothOn = MaterialIcon(
            name = "media_bluetooth_on",
            style = MaterialIcon.Style.TwoTone
        )
        val Mediation = MaterialIcon(
            name = "mediation",
            style = MaterialIcon.Style.TwoTone
        )
        val MedicalInformation = MaterialIcon(
            name = "medical_information",
            style = MaterialIcon.Style.TwoTone
        )
        val MedicalServices = MaterialIcon(
            name = "medical_services",
            style = MaterialIcon.Style.TwoTone
        )
        val Medication = MaterialIcon(
            name = "medication",
            style = MaterialIcon.Style.TwoTone
        )
        val MedicationLiquid = MaterialIcon(
            name = "medication_liquid",
            style = MaterialIcon.Style.TwoTone
        )
        val MeetingRoom = MaterialIcon(
            name = "meeting_room",
            style = MaterialIcon.Style.TwoTone
        )
        val Memory = MaterialIcon(
            name = "memory",
            style = MaterialIcon.Style.TwoTone
        )
        val Menu = MaterialIcon(
            name = "menu",
            style = MaterialIcon.Style.TwoTone
        )
        val MenuBook = MaterialIcon(
            name = "menu_book",
            style = MaterialIcon.Style.TwoTone
        )
        val MenuOpen = MaterialIcon(
            name = "menu_open",
            style = MaterialIcon.Style.TwoTone
        )
        val Merge = MaterialIcon(
            name = "merge",
            style = MaterialIcon.Style.TwoTone
        )
        val MergeType = MaterialIcon(
            name = "merge_type",
            style = MaterialIcon.Style.TwoTone
        )
        val Message = MaterialIcon(
            name = "message",
            style = MaterialIcon.Style.TwoTone
        )
        val Mic = MaterialIcon(
            name = "mic",
            style = MaterialIcon.Style.TwoTone
        )
        val MicExternalOff = MaterialIcon(
            name = "mic_external_off",
            style = MaterialIcon.Style.TwoTone
        )
        val MicExternalOn = MaterialIcon(
            name = "mic_external_on",
            style = MaterialIcon.Style.TwoTone
        )
        val MicNone = MaterialIcon(
            name = "mic_none",
            style = MaterialIcon.Style.TwoTone
        )
        val MicOff = MaterialIcon(
            name = "mic_off",
            style = MaterialIcon.Style.TwoTone
        )
        val Microwave = MaterialIcon(
            name = "microwave",
            style = MaterialIcon.Style.TwoTone
        )
        val MilitaryTech = MaterialIcon(
            name = "military_tech",
            style = MaterialIcon.Style.TwoTone
        )
        val Minimize = MaterialIcon(
            name = "minimize",
            style = MaterialIcon.Style.TwoTone
        )
        val MinorCrash = MaterialIcon(
            name = "minor_crash",
            style = MaterialIcon.Style.TwoTone
        )
        val MiscellaneousServices = MaterialIcon(
            name = "miscellaneous_services",
            style = MaterialIcon.Style.TwoTone
        )
        val MissedVideoCall = MaterialIcon(
            name = "missed_video_call",
            style = MaterialIcon.Style.TwoTone
        )
        val Mms = MaterialIcon(
            name = "mms",
            style = MaterialIcon.Style.TwoTone
        )
        val MobileFriendly = MaterialIcon(
            name = "mobile_friendly",
            style = MaterialIcon.Style.TwoTone
        )
        val MobileOff = MaterialIcon(
            name = "mobile_off",
            style = MaterialIcon.Style.TwoTone
        )
        val MobileScreenShare = MaterialIcon(
            name = "mobile_screen_share",
            style = MaterialIcon.Style.TwoTone
        )
        val MobiledataOff = MaterialIcon(
            name = "mobiledata_off",
            style = MaterialIcon.Style.TwoTone
        )
        val Mode = MaterialIcon(
            name = "mode",
            style = MaterialIcon.Style.TwoTone
        )
        val ModeComment = MaterialIcon(
            name = "mode_comment",
            style = MaterialIcon.Style.TwoTone
        )
        val ModeEdit = MaterialIcon(
            name = "mode_edit",
            style = MaterialIcon.Style.TwoTone
        )
        val ModeEditOutline = MaterialIcon(
            name = "mode_edit_outline",
            style = MaterialIcon.Style.TwoTone
        )
        val ModeFanOff = MaterialIcon(
            name = "mode_fan_off",
            style = MaterialIcon.Style.TwoTone
        )
        val ModeNight = MaterialIcon(
            name = "mode_night",
            style = MaterialIcon.Style.TwoTone
        )
        val ModeOfTravel = MaterialIcon(
            name = "mode_of_travel",
            style = MaterialIcon.Style.TwoTone
        )
        val ModeStandby = MaterialIcon(
            name = "mode_standby",
            style = MaterialIcon.Style.TwoTone
        )
        val ModelTraining = MaterialIcon(
            name = "model_training",
            style = MaterialIcon.Style.TwoTone
        )
        val MonetizationOn = MaterialIcon(
            name = "monetization_on",
            style = MaterialIcon.Style.TwoTone
        )
        val Money = MaterialIcon(
            name = "money",
            style = MaterialIcon.Style.TwoTone
        )
        val MoneyOff = MaterialIcon(
            name = "money_off",
            style = MaterialIcon.Style.TwoTone
        )
        val MoneyOffCsred = MaterialIcon(
            name = "money_off_csred",
            style = MaterialIcon.Style.TwoTone
        )
        val Monitor = MaterialIcon(
            name = "monitor",
            style = MaterialIcon.Style.TwoTone
        )
        val MonitorHeart = MaterialIcon(
            name = "monitor_heart",
            style = MaterialIcon.Style.TwoTone
        )
        val MonitorWeight = MaterialIcon(
            name = "monitor_weight",
            style = MaterialIcon.Style.TwoTone
        )
        val MonochromePhotos = MaterialIcon(
            name = "monochrome_photos",
            style = MaterialIcon.Style.TwoTone
        )
        val Mood = MaterialIcon(
            name = "mood",
            style = MaterialIcon.Style.TwoTone
        )
        val MoodBad = MaterialIcon(
            name = "mood_bad",
            style = MaterialIcon.Style.TwoTone
        )
        val Moped = MaterialIcon(
            name = "moped",
            style = MaterialIcon.Style.TwoTone
        )
        val More = MaterialIcon(
            name = "more",
            style = MaterialIcon.Style.TwoTone
        )
        val MoreHoriz = MaterialIcon(
            name = "more_horiz",
            style = MaterialIcon.Style.TwoTone
        )
        val MoreTime = MaterialIcon(
            name = "more_time",
            style = MaterialIcon.Style.TwoTone
        )
        val MoreVert = MaterialIcon(
            name = "more_vert",
            style = MaterialIcon.Style.TwoTone
        )
        val Mosque = MaterialIcon(
            name = "mosque",
            style = MaterialIcon.Style.TwoTone
        )
        val MotionPhotosAuto = MaterialIcon(
            name = "motion_photos_auto",
            style = MaterialIcon.Style.TwoTone
        )
        val MotionPhotosOff = MaterialIcon(
            name = "motion_photos_off",
            style = MaterialIcon.Style.TwoTone
        )
        val MotionPhotosOn = MaterialIcon(
            name = "motion_photos_on",
            style = MaterialIcon.Style.TwoTone
        )
        val MotionPhotosPause = MaterialIcon(
            name = "motion_photos_pause",
            style = MaterialIcon.Style.TwoTone
        )
        val MotionPhotosPaused = MaterialIcon(
            name = "motion_photos_paused",
            style = MaterialIcon.Style.TwoTone
        )
        val Mouse = MaterialIcon(
            name = "mouse",
            style = MaterialIcon.Style.TwoTone
        )
        val MoveDown = MaterialIcon(
            name = "move_down",
            style = MaterialIcon.Style.TwoTone
        )
        val MoveToInbox = MaterialIcon(
            name = "move_to_inbox",
            style = MaterialIcon.Style.TwoTone
        )
        val MoveUp = MaterialIcon(
            name = "move_up",
            style = MaterialIcon.Style.TwoTone
        )
        val Movie = MaterialIcon(
            name = "movie",
            style = MaterialIcon.Style.TwoTone
        )
        val MovieCreation = MaterialIcon(
            name = "movie_creation",
            style = MaterialIcon.Style.TwoTone
        )
        val MovieFilter = MaterialIcon(
            name = "movie_filter",
            style = MaterialIcon.Style.TwoTone
        )
        val Moving = MaterialIcon(
            name = "moving",
            style = MaterialIcon.Style.TwoTone
        )
        val Mp = MaterialIcon(
            name = "mp",
            style = MaterialIcon.Style.TwoTone
        )
        val MultilineChart = MaterialIcon(
            name = "multiline_chart",
            style = MaterialIcon.Style.TwoTone
        )
        val MultipleStop = MaterialIcon(
            name = "multiple_stop",
            style = MaterialIcon.Style.TwoTone
        )
        val Museum = MaterialIcon(
            name = "museum",
            style = MaterialIcon.Style.TwoTone
        )
        val MusicNote = MaterialIcon(
            name = "music_note",
            style = MaterialIcon.Style.TwoTone
        )
        val MusicOff = MaterialIcon(
            name = "music_off",
            style = MaterialIcon.Style.TwoTone
        )
        val MusicVideo = MaterialIcon(
            name = "music_video",
            style = MaterialIcon.Style.TwoTone
        )
        val MyLocation = MaterialIcon(
            name = "my_location",
            style = MaterialIcon.Style.TwoTone
        )
        val Nat = MaterialIcon(
            name = "nat",
            style = MaterialIcon.Style.TwoTone
        )
        val Nature = MaterialIcon(
            name = "nature",
            style = MaterialIcon.Style.TwoTone
        )
        val NaturePeople = MaterialIcon(
            name = "nature_people",
            style = MaterialIcon.Style.TwoTone
        )
        val NavigateBefore = MaterialIcon(
            name = "navigate_before",
            style = MaterialIcon.Style.TwoTone
        )
        val NavigateNext = MaterialIcon(
            name = "navigate_next",
            style = MaterialIcon.Style.TwoTone
        )
        val Navigation = MaterialIcon(
            name = "navigation",
            style = MaterialIcon.Style.TwoTone
        )
        val NearMe = MaterialIcon(
            name = "near_me",
            style = MaterialIcon.Style.TwoTone
        )
        val NearMeDisabled = MaterialIcon(
            name = "near_me_disabled",
            style = MaterialIcon.Style.TwoTone
        )
        val NearbyError = MaterialIcon(
            name = "nearby_error",
            style = MaterialIcon.Style.TwoTone
        )
        val NearbyOff = MaterialIcon(
            name = "nearby_off",
            style = MaterialIcon.Style.TwoTone
        )
        val NestCamWiredStand = MaterialIcon(
            name = "nest_cam_wired_stand",
            style = MaterialIcon.Style.TwoTone
        )
        val NetworkCell = MaterialIcon(
            name = "network_cell",
            style = MaterialIcon.Style.TwoTone
        )
        val NetworkCheck = MaterialIcon(
            name = "network_check",
            style = MaterialIcon.Style.TwoTone
        )
        val NetworkLocked = MaterialIcon(
            name = "network_locked",
            style = MaterialIcon.Style.TwoTone
        )
        val NetworkPing = MaterialIcon(
            name = "network_ping",
            style = MaterialIcon.Style.TwoTone
        )
        val NetworkWifi = MaterialIcon(
            name = "network_wifi",
            style = MaterialIcon.Style.TwoTone
        )
        val NetworkWifi1Bar = MaterialIcon(
            name = "network_wifi_1_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val NetworkWifi2Bar = MaterialIcon(
            name = "network_wifi_2_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val NetworkWifi3Bar = MaterialIcon(
            name = "network_wifi_3_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val NewLabel = MaterialIcon(
            name = "new_label",
            style = MaterialIcon.Style.TwoTone
        )
        val NewReleases = MaterialIcon(
            name = "new_releases",
            style = MaterialIcon.Style.TwoTone
        )
        val Newspaper = MaterialIcon(
            name = "newspaper",
            style = MaterialIcon.Style.TwoTone
        )
        val NextPlan = MaterialIcon(
            name = "next_plan",
            style = MaterialIcon.Style.TwoTone
        )
        val NextWeek = MaterialIcon(
            name = "next_week",
            style = MaterialIcon.Style.TwoTone
        )
        val Nfc = MaterialIcon(
            name = "nfc",
            style = MaterialIcon.Style.TwoTone
        )
        val NightShelter = MaterialIcon(
            name = "night_shelter",
            style = MaterialIcon.Style.TwoTone
        )
        val Nightlife = MaterialIcon(
            name = "nightlife",
            style = MaterialIcon.Style.TwoTone
        )
        val Nightlight = MaterialIcon(
            name = "nightlight",
            style = MaterialIcon.Style.TwoTone
        )
        val NightlightRound = MaterialIcon(
            name = "nightlight_round",
            style = MaterialIcon.Style.TwoTone
        )
        val NightsStay = MaterialIcon(
            name = "nights_stay",
            style = MaterialIcon.Style.TwoTone
        )
        val NoAccounts = MaterialIcon(
            name = "no_accounts",
            style = MaterialIcon.Style.TwoTone
        )
        val NoAdultContent = MaterialIcon(
            name = "no_adult_content",
            style = MaterialIcon.Style.TwoTone
        )
        val NoBackpack = MaterialIcon(
            name = "no_backpack",
            style = MaterialIcon.Style.TwoTone
        )
        val NoCell = MaterialIcon(
            name = "no_cell",
            style = MaterialIcon.Style.TwoTone
        )
        val NoCrash = MaterialIcon(
            name = "no_crash",
            style = MaterialIcon.Style.TwoTone
        )
        val NoDrinks = MaterialIcon(
            name = "no_drinks",
            style = MaterialIcon.Style.TwoTone
        )
        val NoEncryption = MaterialIcon(
            name = "no_encryption",
            style = MaterialIcon.Style.TwoTone
        )
        val NoEncryptionGmailerrorred = MaterialIcon(
            name = "no_encryption_gmailerrorred",
            style = MaterialIcon.Style.TwoTone
        )
        val NoFlash = MaterialIcon(
            name = "no_flash",
            style = MaterialIcon.Style.TwoTone
        )
        val NoFood = MaterialIcon(
            name = "no_food",
            style = MaterialIcon.Style.TwoTone
        )
        val NoLuggage = MaterialIcon(
            name = "no_luggage",
            style = MaterialIcon.Style.TwoTone
        )
        val NoMeals = MaterialIcon(
            name = "no_meals",
            style = MaterialIcon.Style.TwoTone
        )
        val NoMeetingRoom = MaterialIcon(
            name = "no_meeting_room",
            style = MaterialIcon.Style.TwoTone
        )
        val NoPhotography = MaterialIcon(
            name = "no_photography",
            style = MaterialIcon.Style.TwoTone
        )
        val NoSim = MaterialIcon(
            name = "no_sim",
            style = MaterialIcon.Style.TwoTone
        )
        val NoStroller = MaterialIcon(
            name = "no_stroller",
            style = MaterialIcon.Style.TwoTone
        )
        val NoTransfer = MaterialIcon(
            name = "no_transfer",
            style = MaterialIcon.Style.TwoTone
        )
        val NoiseAware = MaterialIcon(
            name = "noise_aware",
            style = MaterialIcon.Style.TwoTone
        )
        val NoiseControlOff = MaterialIcon(
            name = "noise_control_off",
            style = MaterialIcon.Style.TwoTone
        )
        val NordicWalking = MaterialIcon(
            name = "nordic_walking",
            style = MaterialIcon.Style.TwoTone
        )
        val North = MaterialIcon(
            name = "north",
            style = MaterialIcon.Style.TwoTone
        )
        val NorthEast = MaterialIcon(
            name = "north_east",
            style = MaterialIcon.Style.TwoTone
        )
        val NorthWest = MaterialIcon(
            name = "north_west",
            style = MaterialIcon.Style.TwoTone
        )
        val NotAccessible = MaterialIcon(
            name = "not_accessible",
            style = MaterialIcon.Style.TwoTone
        )
        val NotInterested = MaterialIcon(
            name = "not_interested",
            style = MaterialIcon.Style.TwoTone
        )
        val NotListedLocation = MaterialIcon(
            name = "not_listed_location",
            style = MaterialIcon.Style.TwoTone
        )
        val NotStarted = MaterialIcon(
            name = "not_started",
            style = MaterialIcon.Style.TwoTone
        )
        val Note = MaterialIcon(
            name = "note",
            style = MaterialIcon.Style.TwoTone
        )
        val NoteAdd = MaterialIcon(
            name = "note_add",
            style = MaterialIcon.Style.TwoTone
        )
        val NoteAlt = MaterialIcon(
            name = "note_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val Notes = MaterialIcon(
            name = "notes",
            style = MaterialIcon.Style.TwoTone
        )
        val NotificationAdd = MaterialIcon(
            name = "notification_add",
            style = MaterialIcon.Style.TwoTone
        )
        val NotificationImportant = MaterialIcon(
            name = "notification_important",
            style = MaterialIcon.Style.TwoTone
        )
        val Notifications = MaterialIcon(
            name = "notifications",
            style = MaterialIcon.Style.TwoTone
        )
        val NotificationsActive = MaterialIcon(
            name = "notifications_active",
            style = MaterialIcon.Style.TwoTone
        )
        val NotificationsNone = MaterialIcon(
            name = "notifications_none",
            style = MaterialIcon.Style.TwoTone
        )
        val NotificationsOff = MaterialIcon(
            name = "notifications_off",
            style = MaterialIcon.Style.TwoTone
        )
        val NotificationsPaused = MaterialIcon(
            name = "notifications_paused",
            style = MaterialIcon.Style.TwoTone
        )
        val Numbers = MaterialIcon(
            name = "numbers",
            style = MaterialIcon.Style.TwoTone
        )
        val OfflineBolt = MaterialIcon(
            name = "offline_bolt",
            style = MaterialIcon.Style.TwoTone
        )
        val OfflinePin = MaterialIcon(
            name = "offline_pin",
            style = MaterialIcon.Style.TwoTone
        )
        val OfflineShare = MaterialIcon(
            name = "offline_share",
            style = MaterialIcon.Style.TwoTone
        )
        val OilBarrel = MaterialIcon(
            name = "oil_barrel",
            style = MaterialIcon.Style.TwoTone
        )
        val OnDeviceTraining = MaterialIcon(
            name = "on_device_training",
            style = MaterialIcon.Style.TwoTone
        )
        val OndemandVideo = MaterialIcon(
            name = "ondemand_video",
            style = MaterialIcon.Style.TwoTone
        )
        val OnlinePrediction = MaterialIcon(
            name = "online_prediction",
            style = MaterialIcon.Style.TwoTone
        )
        val Opacity = MaterialIcon(
            name = "opacity",
            style = MaterialIcon.Style.TwoTone
        )
        val OpenInBrowser = MaterialIcon(
            name = "open_in_browser",
            style = MaterialIcon.Style.TwoTone
        )
        val OpenInFull = MaterialIcon(
            name = "open_in_full",
            style = MaterialIcon.Style.TwoTone
        )
        val OpenInNew = MaterialIcon(
            name = "open_in_new",
            style = MaterialIcon.Style.TwoTone
        )
        val OpenInNewOff = MaterialIcon(
            name = "open_in_new_off",
            style = MaterialIcon.Style.TwoTone
        )
        val OpenWith = MaterialIcon(
            name = "open_with",
            style = MaterialIcon.Style.TwoTone
        )
        val OtherHouses = MaterialIcon(
            name = "other_houses",
            style = MaterialIcon.Style.TwoTone
        )
        val Outbound = MaterialIcon(
            name = "outbound",
            style = MaterialIcon.Style.TwoTone
        )
        val Outbox = MaterialIcon(
            name = "outbox",
            style = MaterialIcon.Style.TwoTone
        )
        val OutdoorGrill = MaterialIcon(
            name = "outdoor_grill",
            style = MaterialIcon.Style.TwoTone
        )
        val Outlet = MaterialIcon(
            name = "outlet",
            style = MaterialIcon.Style.TwoTone
        )
        val OutlinedFlag = MaterialIcon(
            name = "outlined_flag",
            style = MaterialIcon.Style.TwoTone
        )
        val Output = MaterialIcon(
            name = "output",
            style = MaterialIcon.Style.TwoTone
        )
        val Padding = MaterialIcon(
            name = "padding",
            style = MaterialIcon.Style.TwoTone
        )
        val Pages = MaterialIcon(
            name = "pages",
            style = MaterialIcon.Style.TwoTone
        )
        val Pageview = MaterialIcon(
            name = "pageview",
            style = MaterialIcon.Style.TwoTone
        )
        val Paid = MaterialIcon(
            name = "paid",
            style = MaterialIcon.Style.TwoTone
        )
        val Palette = MaterialIcon(
            name = "palette",
            style = MaterialIcon.Style.TwoTone
        )
        val PanTool = MaterialIcon(
            name = "pan_tool",
            style = MaterialIcon.Style.TwoTone
        )
        val PanToolAlt = MaterialIcon(
            name = "pan_tool_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val Panorama = MaterialIcon(
            name = "panorama",
            style = MaterialIcon.Style.TwoTone
        )
        val PanoramaFishEye = MaterialIcon(
            name = "panorama_fish_eye",
            style = MaterialIcon.Style.TwoTone
        )
        val PanoramaHorizontal = MaterialIcon(
            name = "panorama_horizontal",
            style = MaterialIcon.Style.TwoTone
        )
        val PanoramaHorizontalSelect = MaterialIcon(
            name = "panorama_horizontal_select",
            style = MaterialIcon.Style.TwoTone
        )
        val PanoramaPhotosphere = MaterialIcon(
            name = "panorama_photosphere",
            style = MaterialIcon.Style.TwoTone
        )
        val PanoramaPhotosphereSelect = MaterialIcon(
            name = "panorama_photosphere_select",
            style = MaterialIcon.Style.TwoTone
        )
        val PanoramaVertical = MaterialIcon(
            name = "panorama_vertical",
            style = MaterialIcon.Style.TwoTone
        )
        val PanoramaVerticalSelect = MaterialIcon(
            name = "panorama_vertical_select",
            style = MaterialIcon.Style.TwoTone
        )
        val PanoramaWideAngle = MaterialIcon(
            name = "panorama_wide_angle",
            style = MaterialIcon.Style.TwoTone
        )
        val PanoramaWideAngleSelect = MaterialIcon(
            name = "panorama_wide_angle_select",
            style = MaterialIcon.Style.TwoTone
        )
        val Paragliding = MaterialIcon(
            name = "paragliding",
            style = MaterialIcon.Style.TwoTone
        )
        val Park = MaterialIcon(
            name = "park",
            style = MaterialIcon.Style.TwoTone
        )
        val PartyMode = MaterialIcon(
            name = "party_mode",
            style = MaterialIcon.Style.TwoTone
        )
        val Password = MaterialIcon(
            name = "password",
            style = MaterialIcon.Style.TwoTone
        )
        val Pattern = MaterialIcon(
            name = "pattern",
            style = MaterialIcon.Style.TwoTone
        )
        val Pause = MaterialIcon(
            name = "pause",
            style = MaterialIcon.Style.TwoTone
        )
        val PauseCircle = MaterialIcon(
            name = "pause_circle",
            style = MaterialIcon.Style.TwoTone
        )
        val PauseCircleFilled = MaterialIcon(
            name = "pause_circle_filled",
            style = MaterialIcon.Style.TwoTone
        )
        val PauseCircleOutline = MaterialIcon(
            name = "pause_circle_outline",
            style = MaterialIcon.Style.TwoTone
        )
        val PausePresentation = MaterialIcon(
            name = "pause_presentation",
            style = MaterialIcon.Style.TwoTone
        )
        val Payment = MaterialIcon(
            name = "payment",
            style = MaterialIcon.Style.TwoTone
        )
        val Payments = MaterialIcon(
            name = "payments",
            style = MaterialIcon.Style.TwoTone
        )
        val PedalBike = MaterialIcon(
            name = "pedal_bike",
            style = MaterialIcon.Style.TwoTone
        )
        val Pending = MaterialIcon(
            name = "pending",
            style = MaterialIcon.Style.TwoTone
        )
        val PendingActions = MaterialIcon(
            name = "pending_actions",
            style = MaterialIcon.Style.TwoTone
        )
        val Pentagon = MaterialIcon(
            name = "pentagon",
            style = MaterialIcon.Style.TwoTone
        )
        val People = MaterialIcon(
            name = "people",
            style = MaterialIcon.Style.TwoTone
        )
        val PeopleAlt = MaterialIcon(
            name = "people_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val PeopleOutline = MaterialIcon(
            name = "people_outline",
            style = MaterialIcon.Style.TwoTone
        )
        val Percent = MaterialIcon(
            name = "percent",
            style = MaterialIcon.Style.TwoTone
        )
        val PermCameraMic = MaterialIcon(
            name = "perm_camera_mic",
            style = MaterialIcon.Style.TwoTone
        )
        val PermContactCalendar = MaterialIcon(
            name = "perm_contact_calendar",
            style = MaterialIcon.Style.TwoTone
        )
        val PermDataSetting = MaterialIcon(
            name = "perm_data_setting",
            style = MaterialIcon.Style.TwoTone
        )
        val PermDeviceInformation = MaterialIcon(
            name = "perm_device_information",
            style = MaterialIcon.Style.TwoTone
        )
        val PermIdentity = MaterialIcon(
            name = "perm_identity",
            style = MaterialIcon.Style.TwoTone
        )
        val PermMedia = MaterialIcon(
            name = "perm_media",
            style = MaterialIcon.Style.TwoTone
        )
        val PermPhoneMsg = MaterialIcon(
            name = "perm_phone_msg",
            style = MaterialIcon.Style.TwoTone
        )
        val PermScanWifi = MaterialIcon(
            name = "perm_scan_wifi",
            style = MaterialIcon.Style.TwoTone
        )
        val Person = MaterialIcon(
            name = "person",
            style = MaterialIcon.Style.TwoTone
        )
        val Person2 = MaterialIcon(
            name = "person_2",
            style = MaterialIcon.Style.TwoTone
        )
        val Person3 = MaterialIcon(
            name = "person_3",
            style = MaterialIcon.Style.TwoTone
        )
        val Person4 = MaterialIcon(
            name = "person_4",
            style = MaterialIcon.Style.TwoTone
        )
        val PersonAdd = MaterialIcon(
            name = "person_add",
            style = MaterialIcon.Style.TwoTone
        )
        val PersonAddAlt = MaterialIcon(
            name = "person_add_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val PersonAddAlt1 = MaterialIcon(
            name = "person_add_alt_1",
            style = MaterialIcon.Style.TwoTone
        )
        val PersonAddDisabled = MaterialIcon(
            name = "person_add_disabled",
            style = MaterialIcon.Style.TwoTone
        )
        val PersonOff = MaterialIcon(
            name = "person_off",
            style = MaterialIcon.Style.TwoTone
        )
        val PersonOutline = MaterialIcon(
            name = "person_outline",
            style = MaterialIcon.Style.TwoTone
        )
        val PersonPin = MaterialIcon(
            name = "person_pin",
            style = MaterialIcon.Style.TwoTone
        )
        val PersonPinCircle = MaterialIcon(
            name = "person_pin_circle",
            style = MaterialIcon.Style.TwoTone
        )
        val PersonRemove = MaterialIcon(
            name = "person_remove",
            style = MaterialIcon.Style.TwoTone
        )
        val PersonRemoveAlt1 = MaterialIcon(
            name = "person_remove_alt_1",
            style = MaterialIcon.Style.TwoTone
        )
        val PersonSearch = MaterialIcon(
            name = "person_search",
            style = MaterialIcon.Style.TwoTone
        )
        val PersonalInjury = MaterialIcon(
            name = "personal_injury",
            style = MaterialIcon.Style.TwoTone
        )
        val PersonalVideo = MaterialIcon(
            name = "personal_video",
            style = MaterialIcon.Style.TwoTone
        )
        val PestControl = MaterialIcon(
            name = "pest_control",
            style = MaterialIcon.Style.TwoTone
        )
        val PestControlRodent = MaterialIcon(
            name = "pest_control_rodent",
            style = MaterialIcon.Style.TwoTone
        )
        val Pets = MaterialIcon(
            name = "pets",
            style = MaterialIcon.Style.TwoTone
        )
        val Phishing = MaterialIcon(
            name = "phishing",
            style = MaterialIcon.Style.TwoTone
        )
        val Phone = MaterialIcon(
            name = "phone",
            style = MaterialIcon.Style.TwoTone
        )
        val PhoneAndroid = MaterialIcon(
            name = "phone_android",
            style = MaterialIcon.Style.TwoTone
        )
        val PhoneBluetoothSpeaker = MaterialIcon(
            name = "phone_bluetooth_speaker",
            style = MaterialIcon.Style.TwoTone
        )
        val PhoneCallback = MaterialIcon(
            name = "phone_callback",
            style = MaterialIcon.Style.TwoTone
        )
        val PhoneDisabled = MaterialIcon(
            name = "phone_disabled",
            style = MaterialIcon.Style.TwoTone
        )
        val PhoneEnabled = MaterialIcon(
            name = "phone_enabled",
            style = MaterialIcon.Style.TwoTone
        )
        val PhoneForwarded = MaterialIcon(
            name = "phone_forwarded",
            style = MaterialIcon.Style.TwoTone
        )
        val PhoneIphone = MaterialIcon(
            name = "phone_iphone",
            style = MaterialIcon.Style.TwoTone
        )
        val PhoneLocked = MaterialIcon(
            name = "phone_locked",
            style = MaterialIcon.Style.TwoTone
        )
        val PhoneMissed = MaterialIcon(
            name = "phone_missed",
            style = MaterialIcon.Style.TwoTone
        )
        val PhonePaused = MaterialIcon(
            name = "phone_paused",
            style = MaterialIcon.Style.TwoTone
        )
        val Phonelink = MaterialIcon(
            name = "phonelink",
            style = MaterialIcon.Style.TwoTone
        )
        val PhonelinkErase = MaterialIcon(
            name = "phonelink_erase",
            style = MaterialIcon.Style.TwoTone
        )
        val PhonelinkLock = MaterialIcon(
            name = "phonelink_lock",
            style = MaterialIcon.Style.TwoTone
        )
        val PhonelinkOff = MaterialIcon(
            name = "phonelink_off",
            style = MaterialIcon.Style.TwoTone
        )
        val PhonelinkRing = MaterialIcon(
            name = "phonelink_ring",
            style = MaterialIcon.Style.TwoTone
        )
        val PhonelinkSetup = MaterialIcon(
            name = "phonelink_setup",
            style = MaterialIcon.Style.TwoTone
        )
        val Photo = MaterialIcon(
            name = "photo",
            style = MaterialIcon.Style.TwoTone
        )
        val PhotoAlbum = MaterialIcon(
            name = "photo_album",
            style = MaterialIcon.Style.TwoTone
        )
        val PhotoCamera = MaterialIcon(
            name = "photo_camera",
            style = MaterialIcon.Style.TwoTone
        )
        val PhotoCameraBack = MaterialIcon(
            name = "photo_camera_back",
            style = MaterialIcon.Style.TwoTone
        )
        val PhotoCameraFront = MaterialIcon(
            name = "photo_camera_front",
            style = MaterialIcon.Style.TwoTone
        )
        val PhotoFilter = MaterialIcon(
            name = "photo_filter",
            style = MaterialIcon.Style.TwoTone
        )
        val PhotoLibrary = MaterialIcon(
            name = "photo_library",
            style = MaterialIcon.Style.TwoTone
        )
        val PhotoSizeSelectActual = MaterialIcon(
            name = "photo_size_select_actual",
            style = MaterialIcon.Style.TwoTone
        )
        val PhotoSizeSelectLarge = MaterialIcon(
            name = "photo_size_select_large",
            style = MaterialIcon.Style.TwoTone
        )
        val PhotoSizeSelectSmall = MaterialIcon(
            name = "photo_size_select_small",
            style = MaterialIcon.Style.TwoTone
        )
        val Php = MaterialIcon(
            name = "php",
            style = MaterialIcon.Style.TwoTone
        )
        val Piano = MaterialIcon(
            name = "piano",
            style = MaterialIcon.Style.TwoTone
        )
        val PianoOff = MaterialIcon(
            name = "piano_off",
            style = MaterialIcon.Style.TwoTone
        )
        val PictureAsPdf = MaterialIcon(
            name = "picture_as_pdf",
            style = MaterialIcon.Style.TwoTone
        )
        val PictureInPicture = MaterialIcon(
            name = "picture_in_picture",
            style = MaterialIcon.Style.TwoTone
        )
        val PictureInPictureAlt = MaterialIcon(
            name = "picture_in_picture_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val PieChart = MaterialIcon(
            name = "pie_chart",
            style = MaterialIcon.Style.TwoTone
        )
        val PieChartOutline = MaterialIcon(
            name = "pie_chart_outline",
            style = MaterialIcon.Style.TwoTone
        )
        val Pin = MaterialIcon(
            name = "pin",
            style = MaterialIcon.Style.TwoTone
        )
        val PinDrop = MaterialIcon(
            name = "pin_drop",
            style = MaterialIcon.Style.TwoTone
        )
        val PinEnd = MaterialIcon(
            name = "pin_end",
            style = MaterialIcon.Style.TwoTone
        )
        val PinInvoke = MaterialIcon(
            name = "pin_invoke",
            style = MaterialIcon.Style.TwoTone
        )
        val Pinch = MaterialIcon(
            name = "pinch",
            style = MaterialIcon.Style.TwoTone
        )
        val PivotTableChart = MaterialIcon(
            name = "pivot_table_chart",
            style = MaterialIcon.Style.TwoTone
        )
        val Pix = MaterialIcon(
            name = "pix",
            style = MaterialIcon.Style.TwoTone
        )
        val Place = MaterialIcon(
            name = "place",
            style = MaterialIcon.Style.TwoTone
        )
        val Plagiarism = MaterialIcon(
            name = "plagiarism",
            style = MaterialIcon.Style.TwoTone
        )
        val PlayArrow = MaterialIcon(
            name = "play_arrow",
            style = MaterialIcon.Style.TwoTone
        )
        val PlayCircle = MaterialIcon(
            name = "play_circle",
            style = MaterialIcon.Style.TwoTone
        )
        val PlayCircleFilled = MaterialIcon(
            name = "play_circle_filled",
            style = MaterialIcon.Style.TwoTone
        )
        val PlayCircleOutline = MaterialIcon(
            name = "play_circle_outline",
            style = MaterialIcon.Style.TwoTone
        )
        val PlayDisabled = MaterialIcon(
            name = "play_disabled",
            style = MaterialIcon.Style.TwoTone
        )
        val PlayForWork = MaterialIcon(
            name = "play_for_work",
            style = MaterialIcon.Style.TwoTone
        )
        val PlayLesson = MaterialIcon(
            name = "play_lesson",
            style = MaterialIcon.Style.TwoTone
        )
        val PlaylistAdd = MaterialIcon(
            name = "playlist_add",
            style = MaterialIcon.Style.TwoTone
        )
        val PlaylistAddCheck = MaterialIcon(
            name = "playlist_add_check",
            style = MaterialIcon.Style.TwoTone
        )
        val PlaylistAddCheckCircle = MaterialIcon(
            name = "playlist_add_check_circle",
            style = MaterialIcon.Style.TwoTone
        )
        val PlaylistAddCircle = MaterialIcon(
            name = "playlist_add_circle",
            style = MaterialIcon.Style.TwoTone
        )
        val PlaylistPlay = MaterialIcon(
            name = "playlist_play",
            style = MaterialIcon.Style.TwoTone
        )
        val PlaylistRemove = MaterialIcon(
            name = "playlist_remove",
            style = MaterialIcon.Style.TwoTone
        )
        val Plumbing = MaterialIcon(
            name = "plumbing",
            style = MaterialIcon.Style.TwoTone
        )
        val PlusOne = MaterialIcon(
            name = "plus_one",
            style = MaterialIcon.Style.TwoTone
        )
        val Podcasts = MaterialIcon(
            name = "podcasts",
            style = MaterialIcon.Style.TwoTone
        )
        val PointOfSale = MaterialIcon(
            name = "point_of_sale",
            style = MaterialIcon.Style.TwoTone
        )
        val Policy = MaterialIcon(
            name = "policy",
            style = MaterialIcon.Style.TwoTone
        )
        val Poll = MaterialIcon(
            name = "poll",
            style = MaterialIcon.Style.TwoTone
        )
        val Polyline = MaterialIcon(
            name = "polyline",
            style = MaterialIcon.Style.TwoTone
        )
        val Polymer = MaterialIcon(
            name = "polymer",
            style = MaterialIcon.Style.TwoTone
        )
        val Pool = MaterialIcon(
            name = "pool",
            style = MaterialIcon.Style.TwoTone
        )
        val PortableWifiOff = MaterialIcon(
            name = "portable_wifi_off",
            style = MaterialIcon.Style.TwoTone
        )
        val Portrait = MaterialIcon(
            name = "portrait",
            style = MaterialIcon.Style.TwoTone
        )
        val PostAdd = MaterialIcon(
            name = "post_add",
            style = MaterialIcon.Style.TwoTone
        )
        val Power = MaterialIcon(
            name = "power",
            style = MaterialIcon.Style.TwoTone
        )
        val PowerInput = MaterialIcon(
            name = "power_input",
            style = MaterialIcon.Style.TwoTone
        )
        val PowerOff = MaterialIcon(
            name = "power_off",
            style = MaterialIcon.Style.TwoTone
        )
        val PowerSettingsNew = MaterialIcon(
            name = "power_settings_new",
            style = MaterialIcon.Style.TwoTone
        )
        val PrecisionManufacturing = MaterialIcon(
            name = "precision_manufacturing",
            style = MaterialIcon.Style.TwoTone
        )
        val PregnantWoman = MaterialIcon(
            name = "pregnant_woman",
            style = MaterialIcon.Style.TwoTone
        )
        val PresentToAll = MaterialIcon(
            name = "present_to_all",
            style = MaterialIcon.Style.TwoTone
        )
        val Preview = MaterialIcon(
            name = "preview",
            style = MaterialIcon.Style.TwoTone
        )
        val PriceChange = MaterialIcon(
            name = "price_change",
            style = MaterialIcon.Style.TwoTone
        )
        val PriceCheck = MaterialIcon(
            name = "price_check",
            style = MaterialIcon.Style.TwoTone
        )
        val Print = MaterialIcon(
            name = "print",
            style = MaterialIcon.Style.TwoTone
        )
        val PrintDisabled = MaterialIcon(
            name = "print_disabled",
            style = MaterialIcon.Style.TwoTone
        )
        val PriorityHigh = MaterialIcon(
            name = "priority_high",
            style = MaterialIcon.Style.TwoTone
        )
        val PrivacyTip = MaterialIcon(
            name = "privacy_tip",
            style = MaterialIcon.Style.TwoTone
        )
        val PrivateConnectivity = MaterialIcon(
            name = "private_connectivity",
            style = MaterialIcon.Style.TwoTone
        )
        val ProductionQuantityLimits = MaterialIcon(
            name = "production_quantity_limits",
            style = MaterialIcon.Style.TwoTone
        )
        val Propane = MaterialIcon(
            name = "propane",
            style = MaterialIcon.Style.TwoTone
        )
        val PropaneTank = MaterialIcon(
            name = "propane_tank",
            style = MaterialIcon.Style.TwoTone
        )
        val Psychology = MaterialIcon(
            name = "psychology",
            style = MaterialIcon.Style.TwoTone
        )
        val PsychologyAlt = MaterialIcon(
            name = "psychology_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val Public = MaterialIcon(
            name = "public",
            style = MaterialIcon.Style.TwoTone
        )
        val PublicOff = MaterialIcon(
            name = "public_off",
            style = MaterialIcon.Style.TwoTone
        )
        val Publish = MaterialIcon(
            name = "publish",
            style = MaterialIcon.Style.TwoTone
        )
        val PublishedWithChanges = MaterialIcon(
            name = "published_with_changes",
            style = MaterialIcon.Style.TwoTone
        )
        val PunchClock = MaterialIcon(
            name = "punch_clock",
            style = MaterialIcon.Style.TwoTone
        )
        val PushPin = MaterialIcon(
            name = "push_pin",
            style = MaterialIcon.Style.TwoTone
        )
        val QrCode = MaterialIcon(
            name = "qr_code",
            style = MaterialIcon.Style.TwoTone
        )
        val QrCode2 = MaterialIcon(
            name = "qr_code_2",
            style = MaterialIcon.Style.TwoTone
        )
        val QrCodeScanner = MaterialIcon(
            name = "qr_code_scanner",
            style = MaterialIcon.Style.TwoTone
        )
        val QueryBuilder = MaterialIcon(
            name = "query_builder",
            style = MaterialIcon.Style.TwoTone
        )
        val QueryStats = MaterialIcon(
            name = "query_stats",
            style = MaterialIcon.Style.TwoTone
        )
        val QuestionAnswer = MaterialIcon(
            name = "question_answer",
            style = MaterialIcon.Style.TwoTone
        )
        val QuestionMark = MaterialIcon(
            name = "question_mark",
            style = MaterialIcon.Style.TwoTone
        )
        val Queue = MaterialIcon(
            name = "queue",
            style = MaterialIcon.Style.TwoTone
        )
        val QueueMusic = MaterialIcon(
            name = "queue_music",
            style = MaterialIcon.Style.TwoTone
        )
        val QueuePlayNext = MaterialIcon(
            name = "queue_play_next",
            style = MaterialIcon.Style.TwoTone
        )
        val Quickreply = MaterialIcon(
            name = "quickreply",
            style = MaterialIcon.Style.TwoTone
        )
        val Quiz = MaterialIcon(
            name = "quiz",
            style = MaterialIcon.Style.TwoTone
        )
        val RMobiledata = MaterialIcon(
            name = "r_mobiledata",
            style = MaterialIcon.Style.TwoTone
        )
        val Radar = MaterialIcon(
            name = "radar",
            style = MaterialIcon.Style.TwoTone
        )
        val Radio = MaterialIcon(
            name = "radio",
            style = MaterialIcon.Style.TwoTone
        )
        val RadioButtonChecked = MaterialIcon(
            name = "radio_button_checked",
            style = MaterialIcon.Style.TwoTone
        )
        val RadioButtonUnchecked = MaterialIcon(
            name = "radio_button_unchecked",
            style = MaterialIcon.Style.TwoTone
        )
        val RailwayAlert = MaterialIcon(
            name = "railway_alert",
            style = MaterialIcon.Style.TwoTone
        )
        val RamenDining = MaterialIcon(
            name = "ramen_dining",
            style = MaterialIcon.Style.TwoTone
        )
        val RampLeft = MaterialIcon(
            name = "ramp_left",
            style = MaterialIcon.Style.TwoTone
        )
        val RampRight = MaterialIcon(
            name = "ramp_right",
            style = MaterialIcon.Style.TwoTone
        )
        val RateReview = MaterialIcon(
            name = "rate_review",
            style = MaterialIcon.Style.TwoTone
        )
        val RawOff = MaterialIcon(
            name = "raw_off",
            style = MaterialIcon.Style.TwoTone
        )
        val RawOn = MaterialIcon(
            name = "raw_on",
            style = MaterialIcon.Style.TwoTone
        )
        val ReadMore = MaterialIcon(
            name = "read_more",
            style = MaterialIcon.Style.TwoTone
        )
        val RealEstateAgent = MaterialIcon(
            name = "real_estate_agent",
            style = MaterialIcon.Style.TwoTone
        )
        val Receipt = MaterialIcon(
            name = "receipt",
            style = MaterialIcon.Style.TwoTone
        )
        val ReceiptLong = MaterialIcon(
            name = "receipt_long",
            style = MaterialIcon.Style.TwoTone
        )
        val RecentActors = MaterialIcon(
            name = "recent_actors",
            style = MaterialIcon.Style.TwoTone
        )
        val Recommend = MaterialIcon(
            name = "recommend",
            style = MaterialIcon.Style.TwoTone
        )
        val RecordVoiceOver = MaterialIcon(
            name = "record_voice_over",
            style = MaterialIcon.Style.TwoTone
        )
        val Rectangle = MaterialIcon(
            name = "rectangle",
            style = MaterialIcon.Style.TwoTone
        )
        val Recycling = MaterialIcon(
            name = "recycling",
            style = MaterialIcon.Style.TwoTone
        )
        val Redeem = MaterialIcon(
            name = "redeem",
            style = MaterialIcon.Style.TwoTone
        )
        val Redo = MaterialIcon(
            name = "redo",
            style = MaterialIcon.Style.TwoTone
        )
        val ReduceCapacity = MaterialIcon(
            name = "reduce_capacity",
            style = MaterialIcon.Style.TwoTone
        )
        val Refresh = MaterialIcon(
            name = "refresh",
            style = MaterialIcon.Style.TwoTone
        )
        val RememberMe = MaterialIcon(
            name = "remember_me",
            style = MaterialIcon.Style.TwoTone
        )
        val Remove = MaterialIcon(
            name = "remove",
            style = MaterialIcon.Style.TwoTone
        )
        val RemoveCircle = MaterialIcon(
            name = "remove_circle",
            style = MaterialIcon.Style.TwoTone
        )
        val RemoveCircleOutline = MaterialIcon(
            name = "remove_circle_outline",
            style = MaterialIcon.Style.TwoTone
        )
        val RemoveDone = MaterialIcon(
            name = "remove_done",
            style = MaterialIcon.Style.TwoTone
        )
        val RemoveFromQueue = MaterialIcon(
            name = "remove_from_queue",
            style = MaterialIcon.Style.TwoTone
        )
        val RemoveModerator = MaterialIcon(
            name = "remove_moderator",
            style = MaterialIcon.Style.TwoTone
        )
        val RemoveRedEye = MaterialIcon(
            name = "remove_red_eye",
            style = MaterialIcon.Style.TwoTone
        )
        val RemoveRoad = MaterialIcon(
            name = "remove_road",
            style = MaterialIcon.Style.TwoTone
        )
        val RemoveShoppingCart = MaterialIcon(
            name = "remove_shopping_cart",
            style = MaterialIcon.Style.TwoTone
        )
        val Reorder = MaterialIcon(
            name = "reorder",
            style = MaterialIcon.Style.TwoTone
        )
        val Repartition = MaterialIcon(
            name = "repartition",
            style = MaterialIcon.Style.TwoTone
        )
        val Repeat = MaterialIcon(
            name = "repeat",
            style = MaterialIcon.Style.TwoTone
        )
        val RepeatOn = MaterialIcon(
            name = "repeat_on",
            style = MaterialIcon.Style.TwoTone
        )
        val RepeatOne = MaterialIcon(
            name = "repeat_one",
            style = MaterialIcon.Style.TwoTone
        )
        val RepeatOneOn = MaterialIcon(
            name = "repeat_one_on",
            style = MaterialIcon.Style.TwoTone
        )
        val Replay = MaterialIcon(
            name = "replay",
            style = MaterialIcon.Style.TwoTone
        )
        val Replay10 = MaterialIcon(
            name = "replay_10",
            style = MaterialIcon.Style.TwoTone
        )
        val Replay30 = MaterialIcon(
            name = "replay_30",
            style = MaterialIcon.Style.TwoTone
        )
        val Replay5 = MaterialIcon(
            name = "replay_5",
            style = MaterialIcon.Style.TwoTone
        )
        val ReplayCircleFilled = MaterialIcon(
            name = "replay_circle_filled",
            style = MaterialIcon.Style.TwoTone
        )
        val Reply = MaterialIcon(
            name = "reply",
            style = MaterialIcon.Style.TwoTone
        )
        val ReplyAll = MaterialIcon(
            name = "reply_all",
            style = MaterialIcon.Style.TwoTone
        )
        val Report = MaterialIcon(
            name = "report",
            style = MaterialIcon.Style.TwoTone
        )
        val ReportGmailerrorred = MaterialIcon(
            name = "report_gmailerrorred",
            style = MaterialIcon.Style.TwoTone
        )
        val ReportOff = MaterialIcon(
            name = "report_off",
            style = MaterialIcon.Style.TwoTone
        )
        val ReportProblem = MaterialIcon(
            name = "report_problem",
            style = MaterialIcon.Style.TwoTone
        )
        val RequestPage = MaterialIcon(
            name = "request_page",
            style = MaterialIcon.Style.TwoTone
        )
        val RequestQuote = MaterialIcon(
            name = "request_quote",
            style = MaterialIcon.Style.TwoTone
        )
        val ResetTv = MaterialIcon(
            name = "reset_tv",
            style = MaterialIcon.Style.TwoTone
        )
        val RestartAlt = MaterialIcon(
            name = "restart_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val Restaurant = MaterialIcon(
            name = "restaurant",
            style = MaterialIcon.Style.TwoTone
        )
        val RestaurantMenu = MaterialIcon(
            name = "restaurant_menu",
            style = MaterialIcon.Style.TwoTone
        )
        val Restore = MaterialIcon(
            name = "restore",
            style = MaterialIcon.Style.TwoTone
        )
        val RestoreFromTrash = MaterialIcon(
            name = "restore_from_trash",
            style = MaterialIcon.Style.TwoTone
        )
        val RestorePage = MaterialIcon(
            name = "restore_page",
            style = MaterialIcon.Style.TwoTone
        )
        val Reviews = MaterialIcon(
            name = "reviews",
            style = MaterialIcon.Style.TwoTone
        )
        val RiceBowl = MaterialIcon(
            name = "rice_bowl",
            style = MaterialIcon.Style.TwoTone
        )
        val RingVolume = MaterialIcon(
            name = "ring_volume",
            style = MaterialIcon.Style.TwoTone
        )
        val Rocket = MaterialIcon(
            name = "rocket",
            style = MaterialIcon.Style.TwoTone
        )
        val RocketLaunch = MaterialIcon(
            name = "rocket_launch",
            style = MaterialIcon.Style.TwoTone
        )
        val RollerShades = MaterialIcon(
            name = "roller_shades",
            style = MaterialIcon.Style.TwoTone
        )
        val RollerShadesClosed = MaterialIcon(
            name = "roller_shades_closed",
            style = MaterialIcon.Style.TwoTone
        )
        val RollerSkating = MaterialIcon(
            name = "roller_skating",
            style = MaterialIcon.Style.TwoTone
        )
        val Roofing = MaterialIcon(
            name = "roofing",
            style = MaterialIcon.Style.TwoTone
        )
        val Room = MaterialIcon(
            name = "room",
            style = MaterialIcon.Style.TwoTone
        )
        val RoomPreferences = MaterialIcon(
            name = "room_preferences",
            style = MaterialIcon.Style.TwoTone
        )
        val RoomService = MaterialIcon(
            name = "room_service",
            style = MaterialIcon.Style.TwoTone
        )
        val Rotate90DegreesCcw = MaterialIcon(
            name = "rotate_90_degrees_ccw",
            style = MaterialIcon.Style.TwoTone
        )
        val Rotate90DegreesCw = MaterialIcon(
            name = "rotate_90_degrees_cw",
            style = MaterialIcon.Style.TwoTone
        )
        val RotateLeft = MaterialIcon(
            name = "rotate_left",
            style = MaterialIcon.Style.TwoTone
        )
        val RotateRight = MaterialIcon(
            name = "rotate_right",
            style = MaterialIcon.Style.TwoTone
        )
        val RoundaboutLeft = MaterialIcon(
            name = "roundabout_left",
            style = MaterialIcon.Style.TwoTone
        )
        val RoundaboutRight = MaterialIcon(
            name = "roundabout_right",
            style = MaterialIcon.Style.TwoTone
        )
        val RoundedCorner = MaterialIcon(
            name = "rounded_corner",
            style = MaterialIcon.Style.TwoTone
        )
        val Route = MaterialIcon(
            name = "route",
            style = MaterialIcon.Style.TwoTone
        )
        val Router = MaterialIcon(
            name = "router",
            style = MaterialIcon.Style.TwoTone
        )
        val Rowing = MaterialIcon(
            name = "rowing",
            style = MaterialIcon.Style.TwoTone
        )
        val RssFeed = MaterialIcon(
            name = "rss_feed",
            style = MaterialIcon.Style.TwoTone
        )
        val Rsvp = MaterialIcon(
            name = "rsvp",
            style = MaterialIcon.Style.TwoTone
        )
        val Rtt = MaterialIcon(
            name = "rtt",
            style = MaterialIcon.Style.TwoTone
        )
        val Rule = MaterialIcon(
            name = "rule",
            style = MaterialIcon.Style.TwoTone
        )
        val RuleFolder = MaterialIcon(
            name = "rule_folder",
            style = MaterialIcon.Style.TwoTone
        )
        val RunCircle = MaterialIcon(
            name = "run_circle",
            style = MaterialIcon.Style.TwoTone
        )
        val RunningWithErrors = MaterialIcon(
            name = "running_with_errors",
            style = MaterialIcon.Style.TwoTone
        )
        val RvHookup = MaterialIcon(
            name = "rv_hookup",
            style = MaterialIcon.Style.TwoTone
        )
        val SafetyCheck = MaterialIcon(
            name = "safety_check",
            style = MaterialIcon.Style.TwoTone
        )
        val SafetyDivider = MaterialIcon(
            name = "safety_divider",
            style = MaterialIcon.Style.TwoTone
        )
        val Sailing = MaterialIcon(
            name = "sailing",
            style = MaterialIcon.Style.TwoTone
        )
        val Sanitizer = MaterialIcon(
            name = "sanitizer",
            style = MaterialIcon.Style.TwoTone
        )
        val Satellite = MaterialIcon(
            name = "satellite",
            style = MaterialIcon.Style.TwoTone
        )
        val SatelliteAlt = MaterialIcon(
            name = "satellite_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val Save = MaterialIcon(
            name = "save",
            style = MaterialIcon.Style.TwoTone
        )
        val SaveAlt = MaterialIcon(
            name = "save_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val SaveAs = MaterialIcon(
            name = "save_as",
            style = MaterialIcon.Style.TwoTone
        )
        val SavedSearch = MaterialIcon(
            name = "saved_search",
            style = MaterialIcon.Style.TwoTone
        )
        val Savings = MaterialIcon(
            name = "savings",
            style = MaterialIcon.Style.TwoTone
        )
        val Scale = MaterialIcon(
            name = "scale",
            style = MaterialIcon.Style.TwoTone
        )
        val Scanner = MaterialIcon(
            name = "scanner",
            style = MaterialIcon.Style.TwoTone
        )
        val ScatterPlot = MaterialIcon(
            name = "scatter_plot",
            style = MaterialIcon.Style.TwoTone
        )
        val Schedule = MaterialIcon(
            name = "schedule",
            style = MaterialIcon.Style.TwoTone
        )
        val ScheduleSend = MaterialIcon(
            name = "schedule_send",
            style = MaterialIcon.Style.TwoTone
        )
        val Schema = MaterialIcon(
            name = "schema",
            style = MaterialIcon.Style.TwoTone
        )
        val School = MaterialIcon(
            name = "school",
            style = MaterialIcon.Style.TwoTone
        )
        val Science = MaterialIcon(
            name = "science",
            style = MaterialIcon.Style.TwoTone
        )
        val Score = MaterialIcon(
            name = "score",
            style = MaterialIcon.Style.TwoTone
        )
        val Scoreboard = MaterialIcon(
            name = "scoreboard",
            style = MaterialIcon.Style.TwoTone
        )
        val ScreenLockLandscape = MaterialIcon(
            name = "screen_lock_landscape",
            style = MaterialIcon.Style.TwoTone
        )
        val ScreenLockPortrait = MaterialIcon(
            name = "screen_lock_portrait",
            style = MaterialIcon.Style.TwoTone
        )
        val ScreenLockRotation = MaterialIcon(
            name = "screen_lock_rotation",
            style = MaterialIcon.Style.TwoTone
        )
        val ScreenRotation = MaterialIcon(
            name = "screen_rotation",
            style = MaterialIcon.Style.TwoTone
        )
        val ScreenRotationAlt = MaterialIcon(
            name = "screen_rotation_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val ScreenSearchDesktop = MaterialIcon(
            name = "screen_search_desktop",
            style = MaterialIcon.Style.TwoTone
        )
        val ScreenShare = MaterialIcon(
            name = "screen_share",
            style = MaterialIcon.Style.TwoTone
        )
        val Screenshot = MaterialIcon(
            name = "screenshot",
            style = MaterialIcon.Style.TwoTone
        )
        val ScreenshotMonitor = MaterialIcon(
            name = "screenshot_monitor",
            style = MaterialIcon.Style.TwoTone
        )
        val ScubaDiving = MaterialIcon(
            name = "scuba_diving",
            style = MaterialIcon.Style.TwoTone
        )
        val Sd = MaterialIcon(
            name = "sd",
            style = MaterialIcon.Style.TwoTone
        )
        val SdCard = MaterialIcon(
            name = "sd_card",
            style = MaterialIcon.Style.TwoTone
        )
        val SdCardAlert = MaterialIcon(
            name = "sd_card_alert",
            style = MaterialIcon.Style.TwoTone
        )
        val SdStorage = MaterialIcon(
            name = "sd_storage",
            style = MaterialIcon.Style.TwoTone
        )
        val Search = MaterialIcon(
            name = "search",
            style = MaterialIcon.Style.TwoTone
        )
        val SearchOff = MaterialIcon(
            name = "search_off",
            style = MaterialIcon.Style.TwoTone
        )
        val Security = MaterialIcon(
            name = "security",
            style = MaterialIcon.Style.TwoTone
        )
        val SecurityUpdate = MaterialIcon(
            name = "security_update",
            style = MaterialIcon.Style.TwoTone
        )
        val SecurityUpdateGood = MaterialIcon(
            name = "security_update_good",
            style = MaterialIcon.Style.TwoTone
        )
        val SecurityUpdateWarning = MaterialIcon(
            name = "security_update_warning",
            style = MaterialIcon.Style.TwoTone
        )
        val Segment = MaterialIcon(
            name = "segment",
            style = MaterialIcon.Style.TwoTone
        )
        val SelectAll = MaterialIcon(
            name = "select_all",
            style = MaterialIcon.Style.TwoTone
        )
        val SelfImprovement = MaterialIcon(
            name = "self_improvement",
            style = MaterialIcon.Style.TwoTone
        )
        val Sell = MaterialIcon(
            name = "sell",
            style = MaterialIcon.Style.TwoTone
        )
        val Send = MaterialIcon(
            name = "send",
            style = MaterialIcon.Style.TwoTone
        )
        val SendAndArchive = MaterialIcon(
            name = "send_and_archive",
            style = MaterialIcon.Style.TwoTone
        )
        val SendTimeExtension = MaterialIcon(
            name = "send_time_extension",
            style = MaterialIcon.Style.TwoTone
        )
        val SendToMobile = MaterialIcon(
            name = "send_to_mobile",
            style = MaterialIcon.Style.TwoTone
        )
        val SensorDoor = MaterialIcon(
            name = "sensor_door",
            style = MaterialIcon.Style.TwoTone
        )
        val SensorOccupied = MaterialIcon(
            name = "sensor_occupied",
            style = MaterialIcon.Style.TwoTone
        )
        val SensorWindow = MaterialIcon(
            name = "sensor_window",
            style = MaterialIcon.Style.TwoTone
        )
        val Sensors = MaterialIcon(
            name = "sensors",
            style = MaterialIcon.Style.TwoTone
        )
        val SensorsOff = MaterialIcon(
            name = "sensors_off",
            style = MaterialIcon.Style.TwoTone
        )
        val SentimentDissatisfied = MaterialIcon(
            name = "sentiment_dissatisfied",
            style = MaterialIcon.Style.TwoTone
        )
        val SentimentNeutral = MaterialIcon(
            name = "sentiment_neutral",
            style = MaterialIcon.Style.TwoTone
        )
        val SentimentSatisfied = MaterialIcon(
            name = "sentiment_satisfied",
            style = MaterialIcon.Style.TwoTone
        )
        val SentimentSatisfiedAlt = MaterialIcon(
            name = "sentiment_satisfied_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val SentimentVeryDissatisfied = MaterialIcon(
            name = "sentiment_very_dissatisfied",
            style = MaterialIcon.Style.TwoTone
        )
        val SentimentVerySatisfied = MaterialIcon(
            name = "sentiment_very_satisfied",
            style = MaterialIcon.Style.TwoTone
        )
        val SetMeal = MaterialIcon(
            name = "set_meal",
            style = MaterialIcon.Style.TwoTone
        )
        val Settings = MaterialIcon(
            name = "settings",
            style = MaterialIcon.Style.TwoTone
        )
        val SettingsAccessibility = MaterialIcon(
            name = "settings_accessibility",
            style = MaterialIcon.Style.TwoTone
        )
        val SettingsApplications = MaterialIcon(
            name = "settings_applications",
            style = MaterialIcon.Style.TwoTone
        )
        val SettingsBackupRestore = MaterialIcon(
            name = "settings_backup_restore",
            style = MaterialIcon.Style.TwoTone
        )
        val SettingsBluetooth = MaterialIcon(
            name = "settings_bluetooth",
            style = MaterialIcon.Style.TwoTone
        )
        val SettingsBrightness = MaterialIcon(
            name = "settings_brightness",
            style = MaterialIcon.Style.TwoTone
        )
        val SettingsCell = MaterialIcon(
            name = "settings_cell",
            style = MaterialIcon.Style.TwoTone
        )
        val SettingsEthernet = MaterialIcon(
            name = "settings_ethernet",
            style = MaterialIcon.Style.TwoTone
        )
        val SettingsInputAntenna = MaterialIcon(
            name = "settings_input_antenna",
            style = MaterialIcon.Style.TwoTone
        )
        val SettingsInputComponent = MaterialIcon(
            name = "settings_input_component",
            style = MaterialIcon.Style.TwoTone
        )
        val SettingsInputComposite = MaterialIcon(
            name = "settings_input_composite",
            style = MaterialIcon.Style.TwoTone
        )
        val SettingsInputHdmi = MaterialIcon(
            name = "settings_input_hdmi",
            style = MaterialIcon.Style.TwoTone
        )
        val SettingsInputSvideo = MaterialIcon(
            name = "settings_input_svideo",
            style = MaterialIcon.Style.TwoTone
        )
        val SettingsOverscan = MaterialIcon(
            name = "settings_overscan",
            style = MaterialIcon.Style.TwoTone
        )
        val SettingsPhone = MaterialIcon(
            name = "settings_phone",
            style = MaterialIcon.Style.TwoTone
        )
        val SettingsPower = MaterialIcon(
            name = "settings_power",
            style = MaterialIcon.Style.TwoTone
        )
        val SettingsRemote = MaterialIcon(
            name = "settings_remote",
            style = MaterialIcon.Style.TwoTone
        )
        val SettingsSuggest = MaterialIcon(
            name = "settings_suggest",
            style = MaterialIcon.Style.TwoTone
        )
        val SettingsSystemDaydream = MaterialIcon(
            name = "settings_system_daydream",
            style = MaterialIcon.Style.TwoTone
        )
        val SettingsVoice = MaterialIcon(
            name = "settings_voice",
            style = MaterialIcon.Style.TwoTone
        )
        val SevereCold = MaterialIcon(
            name = "severe_cold",
            style = MaterialIcon.Style.TwoTone
        )
        val ShapeLine = MaterialIcon(
            name = "shape_line",
            style = MaterialIcon.Style.TwoTone
        )
        val Share = MaterialIcon(
            name = "share",
            style = MaterialIcon.Style.TwoTone
        )
        val ShareLocation = MaterialIcon(
            name = "share_location",
            style = MaterialIcon.Style.TwoTone
        )
        val Shield = MaterialIcon(
            name = "shield",
            style = MaterialIcon.Style.TwoTone
        )
        val ShieldMoon = MaterialIcon(
            name = "shield_moon",
            style = MaterialIcon.Style.TwoTone
        )
        val Shop = MaterialIcon(
            name = "shop",
            style = MaterialIcon.Style.TwoTone
        )
        val Shop2 = MaterialIcon(
            name = "shop_2",
            style = MaterialIcon.Style.TwoTone
        )
        val ShopTwo = MaterialIcon(
            name = "shop_two",
            style = MaterialIcon.Style.TwoTone
        )
        val ShoppingBag = MaterialIcon(
            name = "shopping_bag",
            style = MaterialIcon.Style.TwoTone
        )
        val ShoppingBasket = MaterialIcon(
            name = "shopping_basket",
            style = MaterialIcon.Style.TwoTone
        )
        val ShoppingCart = MaterialIcon(
            name = "shopping_cart",
            style = MaterialIcon.Style.TwoTone
        )
        val ShoppingCartCheckout = MaterialIcon(
            name = "shopping_cart_checkout",
            style = MaterialIcon.Style.TwoTone
        )
        val ShortText = MaterialIcon(
            name = "short_text",
            style = MaterialIcon.Style.TwoTone
        )
        val Shortcut = MaterialIcon(
            name = "shortcut",
            style = MaterialIcon.Style.TwoTone
        )
        val ShowChart = MaterialIcon(
            name = "show_chart",
            style = MaterialIcon.Style.TwoTone
        )
        val Shower = MaterialIcon(
            name = "shower",
            style = MaterialIcon.Style.TwoTone
        )
        val Shuffle = MaterialIcon(
            name = "shuffle",
            style = MaterialIcon.Style.TwoTone
        )
        val ShuffleOn = MaterialIcon(
            name = "shuffle_on",
            style = MaterialIcon.Style.TwoTone
        )
        val ShutterSpeed = MaterialIcon(
            name = "shutter_speed",
            style = MaterialIcon.Style.TwoTone
        )
        val Sick = MaterialIcon(
            name = "sick",
            style = MaterialIcon.Style.TwoTone
        )
        val SignLanguage = MaterialIcon(
            name = "sign_language",
            style = MaterialIcon.Style.TwoTone
        )
        val SignalCellular0Bar = MaterialIcon(
            name = "signal_cellular_0_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val SignalCellular4Bar = MaterialIcon(
            name = "signal_cellular_4_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val SignalCellularAlt = MaterialIcon(
            name = "signal_cellular_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val SignalCellularAlt1Bar = MaterialIcon(
            name = "signal_cellular_alt_1_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val SignalCellularAlt2Bar = MaterialIcon(
            name = "signal_cellular_alt_2_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val SignalCellularConnectedNoInternet0Bar = MaterialIcon(
            name = "signal_cellular_connected_no_internet_0_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val SignalCellularConnectedNoInternet4Bar = MaterialIcon(
            name = "signal_cellular_connected_no_internet_4_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val SignalCellularNoSim = MaterialIcon(
            name = "signal_cellular_no_sim",
            style = MaterialIcon.Style.TwoTone
        )
        val SignalCellularNodata = MaterialIcon(
            name = "signal_cellular_nodata",
            style = MaterialIcon.Style.TwoTone
        )
        val SignalCellularNull = MaterialIcon(
            name = "signal_cellular_null",
            style = MaterialIcon.Style.TwoTone
        )
        val SignalCellularOff = MaterialIcon(
            name = "signal_cellular_off",
            style = MaterialIcon.Style.TwoTone
        )
        val SignalWifi0Bar = MaterialIcon(
            name = "signal_wifi_0_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val SignalWifi4Bar = MaterialIcon(
            name = "signal_wifi_4_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val SignalWifi4BarLock = MaterialIcon(
            name = "signal_wifi_4_bar_lock",
            style = MaterialIcon.Style.TwoTone
        )
        val SignalWifiBad = MaterialIcon(
            name = "signal_wifi_bad",
            style = MaterialIcon.Style.TwoTone
        )
        val SignalWifiConnectedNoInternet4 = MaterialIcon(
            name = "signal_wifi_connected_no_internet_4",
            style = MaterialIcon.Style.TwoTone
        )
        val SignalWifiOff = MaterialIcon(
            name = "signal_wifi_off",
            style = MaterialIcon.Style.TwoTone
        )
        val SignalWifiStatusbar4Bar = MaterialIcon(
            name = "signal_wifi_statusbar_4_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val SignalWifiStatusbarConnectedNoInternet4 = MaterialIcon(
            name = "signal_wifi_statusbar_connected_no_internet_4",
            style = MaterialIcon.Style.TwoTone
        )
        val SignalWifiStatusbarNull = MaterialIcon(
            name = "signal_wifi_statusbar_null",
            style = MaterialIcon.Style.TwoTone
        )
        val Signpost = MaterialIcon(
            name = "signpost",
            style = MaterialIcon.Style.TwoTone
        )
        val SimCard = MaterialIcon(
            name = "sim_card",
            style = MaterialIcon.Style.TwoTone
        )
        val SimCardAlert = MaterialIcon(
            name = "sim_card_alert",
            style = MaterialIcon.Style.TwoTone
        )
        val SimCardDownload = MaterialIcon(
            name = "sim_card_download",
            style = MaterialIcon.Style.TwoTone
        )
        val SingleBed = MaterialIcon(
            name = "single_bed",
            style = MaterialIcon.Style.TwoTone
        )
        val Sip = MaterialIcon(
            name = "sip",
            style = MaterialIcon.Style.TwoTone
        )
        val Skateboarding = MaterialIcon(
            name = "skateboarding",
            style = MaterialIcon.Style.TwoTone
        )
        val SkipNext = MaterialIcon(
            name = "skip_next",
            style = MaterialIcon.Style.TwoTone
        )
        val SkipPrevious = MaterialIcon(
            name = "skip_previous",
            style = MaterialIcon.Style.TwoTone
        )
        val Sledding = MaterialIcon(
            name = "sledding",
            style = MaterialIcon.Style.TwoTone
        )
        val Slideshow = MaterialIcon(
            name = "slideshow",
            style = MaterialIcon.Style.TwoTone
        )
        val SlowMotionVideo = MaterialIcon(
            name = "slow_motion_video",
            style = MaterialIcon.Style.TwoTone
        )
        val SmartButton = MaterialIcon(
            name = "smart_button",
            style = MaterialIcon.Style.TwoTone
        )
        val SmartDisplay = MaterialIcon(
            name = "smart_display",
            style = MaterialIcon.Style.TwoTone
        )
        val SmartScreen = MaterialIcon(
            name = "smart_screen",
            style = MaterialIcon.Style.TwoTone
        )
        val SmartToy = MaterialIcon(
            name = "smart_toy",
            style = MaterialIcon.Style.TwoTone
        )
        val Smartphone = MaterialIcon(
            name = "smartphone",
            style = MaterialIcon.Style.TwoTone
        )
        val SmokeFree = MaterialIcon(
            name = "smoke_free",
            style = MaterialIcon.Style.TwoTone
        )
        val SmokingRooms = MaterialIcon(
            name = "smoking_rooms",
            style = MaterialIcon.Style.TwoTone
        )
        val Sms = MaterialIcon(
            name = "sms",
            style = MaterialIcon.Style.TwoTone
        )
        val SmsFailed = MaterialIcon(
            name = "sms_failed",
            style = MaterialIcon.Style.TwoTone
        )
        val SnippetFolder = MaterialIcon(
            name = "snippet_folder",
            style = MaterialIcon.Style.TwoTone
        )
        val Snooze = MaterialIcon(
            name = "snooze",
            style = MaterialIcon.Style.TwoTone
        )
        val Snowboarding = MaterialIcon(
            name = "snowboarding",
            style = MaterialIcon.Style.TwoTone
        )
        val Snowmobile = MaterialIcon(
            name = "snowmobile",
            style = MaterialIcon.Style.TwoTone
        )
        val Snowshoeing = MaterialIcon(
            name = "snowshoeing",
            style = MaterialIcon.Style.TwoTone
        )
        val Soap = MaterialIcon(
            name = "soap",
            style = MaterialIcon.Style.TwoTone
        )
        val SocialDistance = MaterialIcon(
            name = "social_distance",
            style = MaterialIcon.Style.TwoTone
        )
        val SolarPower = MaterialIcon(
            name = "solar_power",
            style = MaterialIcon.Style.TwoTone
        )
        val Sort = MaterialIcon(
            name = "sort",
            style = MaterialIcon.Style.TwoTone
        )
        val SortByAlpha = MaterialIcon(
            name = "sort_by_alpha",
            style = MaterialIcon.Style.TwoTone
        )
        val Sos = MaterialIcon(
            name = "sos",
            style = MaterialIcon.Style.TwoTone
        )
        val SoupKitchen = MaterialIcon(
            name = "soup_kitchen",
            style = MaterialIcon.Style.TwoTone
        )
        val Source = MaterialIcon(
            name = "source",
            style = MaterialIcon.Style.TwoTone
        )
        val South = MaterialIcon(
            name = "south",
            style = MaterialIcon.Style.TwoTone
        )
        val SouthAmerica = MaterialIcon(
            name = "south_america",
            style = MaterialIcon.Style.TwoTone
        )
        val SouthEast = MaterialIcon(
            name = "south_east",
            style = MaterialIcon.Style.TwoTone
        )
        val SouthWest = MaterialIcon(
            name = "south_west",
            style = MaterialIcon.Style.TwoTone
        )
        val Spa = MaterialIcon(
            name = "spa",
            style = MaterialIcon.Style.TwoTone
        )
        val SpaceBar = MaterialIcon(
            name = "space_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val SpaceDashboard = MaterialIcon(
            name = "space_dashboard",
            style = MaterialIcon.Style.TwoTone
        )
        val SpatialAudio = MaterialIcon(
            name = "spatial_audio",
            style = MaterialIcon.Style.TwoTone
        )
        val SpatialAudioOff = MaterialIcon(
            name = "spatial_audio_off",
            style = MaterialIcon.Style.TwoTone
        )
        val SpatialTracking = MaterialIcon(
            name = "spatial_tracking",
            style = MaterialIcon.Style.TwoTone
        )
        val Speaker = MaterialIcon(
            name = "speaker",
            style = MaterialIcon.Style.TwoTone
        )
        val SpeakerGroup = MaterialIcon(
            name = "speaker_group",
            style = MaterialIcon.Style.TwoTone
        )
        val SpeakerNotes = MaterialIcon(
            name = "speaker_notes",
            style = MaterialIcon.Style.TwoTone
        )
        val SpeakerNotesOff = MaterialIcon(
            name = "speaker_notes_off",
            style = MaterialIcon.Style.TwoTone
        )
        val SpeakerPhone = MaterialIcon(
            name = "speaker_phone",
            style = MaterialIcon.Style.TwoTone
        )
        val Speed = MaterialIcon(
            name = "speed",
            style = MaterialIcon.Style.TwoTone
        )
        val Spellcheck = MaterialIcon(
            name = "spellcheck",
            style = MaterialIcon.Style.TwoTone
        )
        val Splitscreen = MaterialIcon(
            name = "splitscreen",
            style = MaterialIcon.Style.TwoTone
        )
        val Spoke = MaterialIcon(
            name = "spoke",
            style = MaterialIcon.Style.TwoTone
        )
        val Sports = MaterialIcon(
            name = "sports",
            style = MaterialIcon.Style.TwoTone
        )
        val SportsBar = MaterialIcon(
            name = "sports_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val SportsBaseball = MaterialIcon(
            name = "sports_baseball",
            style = MaterialIcon.Style.TwoTone
        )
        val SportsBasketball = MaterialIcon(
            name = "sports_basketball",
            style = MaterialIcon.Style.TwoTone
        )
        val SportsCricket = MaterialIcon(
            name = "sports_cricket",
            style = MaterialIcon.Style.TwoTone
        )
        val SportsEsports = MaterialIcon(
            name = "sports_esports",
            style = MaterialIcon.Style.TwoTone
        )
        val SportsFootball = MaterialIcon(
            name = "sports_football",
            style = MaterialIcon.Style.TwoTone
        )
        val SportsGolf = MaterialIcon(
            name = "sports_golf",
            style = MaterialIcon.Style.TwoTone
        )
        val SportsGymnastics = MaterialIcon(
            name = "sports_gymnastics",
            style = MaterialIcon.Style.TwoTone
        )
        val SportsHandball = MaterialIcon(
            name = "sports_handball",
            style = MaterialIcon.Style.TwoTone
        )
        val SportsHockey = MaterialIcon(
            name = "sports_hockey",
            style = MaterialIcon.Style.TwoTone
        )
        val SportsKabaddi = MaterialIcon(
            name = "sports_kabaddi",
            style = MaterialIcon.Style.TwoTone
        )
        val SportsMartialArts = MaterialIcon(
            name = "sports_martial_arts",
            style = MaterialIcon.Style.TwoTone
        )
        val SportsMma = MaterialIcon(
            name = "sports_mma",
            style = MaterialIcon.Style.TwoTone
        )
        val SportsMotorsports = MaterialIcon(
            name = "sports_motorsports",
            style = MaterialIcon.Style.TwoTone
        )
        val SportsRugby = MaterialIcon(
            name = "sports_rugby",
            style = MaterialIcon.Style.TwoTone
        )
        val SportsScore = MaterialIcon(
            name = "sports_score",
            style = MaterialIcon.Style.TwoTone
        )
        val SportsSoccer = MaterialIcon(
            name = "sports_soccer",
            style = MaterialIcon.Style.TwoTone
        )
        val SportsTennis = MaterialIcon(
            name = "sports_tennis",
            style = MaterialIcon.Style.TwoTone
        )
        val SportsVolleyball = MaterialIcon(
            name = "sports_volleyball",
            style = MaterialIcon.Style.TwoTone
        )
        val Square = MaterialIcon(
            name = "square",
            style = MaterialIcon.Style.TwoTone
        )
        val SquareFoot = MaterialIcon(
            name = "square_foot",
            style = MaterialIcon.Style.TwoTone
        )
        val SsidChart = MaterialIcon(
            name = "ssid_chart",
            style = MaterialIcon.Style.TwoTone
        )
        val StackedBarChart = MaterialIcon(
            name = "stacked_bar_chart",
            style = MaterialIcon.Style.TwoTone
        )
        val StackedLineChart = MaterialIcon(
            name = "stacked_line_chart",
            style = MaterialIcon.Style.TwoTone
        )
        val Stadium = MaterialIcon(
            name = "stadium",
            style = MaterialIcon.Style.TwoTone
        )
        val Stairs = MaterialIcon(
            name = "stairs",
            style = MaterialIcon.Style.TwoTone
        )
        val Star = MaterialIcon(
            name = "star",
            style = MaterialIcon.Style.TwoTone
        )
        val StarBorder = MaterialIcon(
            name = "star_border",
            style = MaterialIcon.Style.TwoTone
        )
        val StarBorderPurple500 = MaterialIcon(
            name = "star_border_purple500",
            style = MaterialIcon.Style.TwoTone
        )
        val StarHalf = MaterialIcon(
            name = "star_half",
            style = MaterialIcon.Style.TwoTone
        )
        val StarOutline = MaterialIcon(
            name = "star_outline",
            style = MaterialIcon.Style.TwoTone
        )
        val StarPurple500 = MaterialIcon(
            name = "star_purple500",
            style = MaterialIcon.Style.TwoTone
        )
        val StarRate = MaterialIcon(
            name = "star_rate",
            style = MaterialIcon.Style.TwoTone
        )
        val Stars = MaterialIcon(
            name = "stars",
            style = MaterialIcon.Style.TwoTone
        )
        val Start = MaterialIcon(
            name = "start",
            style = MaterialIcon.Style.TwoTone
        )
        val StayCurrentLandscape = MaterialIcon(
            name = "stay_current_landscape",
            style = MaterialIcon.Style.TwoTone
        )
        val StayCurrentPortrait = MaterialIcon(
            name = "stay_current_portrait",
            style = MaterialIcon.Style.TwoTone
        )
        val StayPrimaryLandscape = MaterialIcon(
            name = "stay_primary_landscape",
            style = MaterialIcon.Style.TwoTone
        )
        val StayPrimaryPortrait = MaterialIcon(
            name = "stay_primary_portrait",
            style = MaterialIcon.Style.TwoTone
        )
        val StickyNote2 = MaterialIcon(
            name = "sticky_note_2",
            style = MaterialIcon.Style.TwoTone
        )
        val Stop = MaterialIcon(
            name = "stop",
            style = MaterialIcon.Style.TwoTone
        )
        val StopCircle = MaterialIcon(
            name = "stop_circle",
            style = MaterialIcon.Style.TwoTone
        )
        val StopScreenShare = MaterialIcon(
            name = "stop_screen_share",
            style = MaterialIcon.Style.TwoTone
        )
        val Storage = MaterialIcon(
            name = "storage",
            style = MaterialIcon.Style.TwoTone
        )
        val Store = MaterialIcon(
            name = "store",
            style = MaterialIcon.Style.TwoTone
        )
        val StoreMallDirectory = MaterialIcon(
            name = "store_mall_directory",
            style = MaterialIcon.Style.TwoTone
        )
        val Storefront = MaterialIcon(
            name = "storefront",
            style = MaterialIcon.Style.TwoTone
        )
        val Storm = MaterialIcon(
            name = "storm",
            style = MaterialIcon.Style.TwoTone
        )
        val Straight = MaterialIcon(
            name = "straight",
            style = MaterialIcon.Style.TwoTone
        )
        val Straighten = MaterialIcon(
            name = "straighten",
            style = MaterialIcon.Style.TwoTone
        )
        val Stream = MaterialIcon(
            name = "stream",
            style = MaterialIcon.Style.TwoTone
        )
        val Streetview = MaterialIcon(
            name = "streetview",
            style = MaterialIcon.Style.TwoTone
        )
        val StrikethroughS = MaterialIcon(
            name = "strikethrough_s",
            style = MaterialIcon.Style.TwoTone
        )
        val Stroller = MaterialIcon(
            name = "stroller",
            style = MaterialIcon.Style.TwoTone
        )
        val Style = MaterialIcon(
            name = "style",
            style = MaterialIcon.Style.TwoTone
        )
        val SubdirectoryArrowLeft = MaterialIcon(
            name = "subdirectory_arrow_left",
            style = MaterialIcon.Style.TwoTone
        )
        val SubdirectoryArrowRight = MaterialIcon(
            name = "subdirectory_arrow_right",
            style = MaterialIcon.Style.TwoTone
        )
        val Subject = MaterialIcon(
            name = "subject",
            style = MaterialIcon.Style.TwoTone
        )
        val Subscript = MaterialIcon(
            name = "subscript",
            style = MaterialIcon.Style.TwoTone
        )
        val Subscriptions = MaterialIcon(
            name = "subscriptions",
            style = MaterialIcon.Style.TwoTone
        )
        val Subtitles = MaterialIcon(
            name = "subtitles",
            style = MaterialIcon.Style.TwoTone
        )
        val SubtitlesOff = MaterialIcon(
            name = "subtitles_off",
            style = MaterialIcon.Style.TwoTone
        )
        val Subway = MaterialIcon(
            name = "subway",
            style = MaterialIcon.Style.TwoTone
        )
        val Summarize = MaterialIcon(
            name = "summarize",
            style = MaterialIcon.Style.TwoTone
        )
        val Superscript = MaterialIcon(
            name = "superscript",
            style = MaterialIcon.Style.TwoTone
        )
        val SupervisedUserCircle = MaterialIcon(
            name = "supervised_user_circle",
            style = MaterialIcon.Style.TwoTone
        )
        val SupervisorAccount = MaterialIcon(
            name = "supervisor_account",
            style = MaterialIcon.Style.TwoTone
        )
        val Support = MaterialIcon(
            name = "support",
            style = MaterialIcon.Style.TwoTone
        )
        val SupportAgent = MaterialIcon(
            name = "support_agent",
            style = MaterialIcon.Style.TwoTone
        )
        val Surfing = MaterialIcon(
            name = "surfing",
            style = MaterialIcon.Style.TwoTone
        )
        val SurroundSound = MaterialIcon(
            name = "surround_sound",
            style = MaterialIcon.Style.TwoTone
        )
        val SwapCalls = MaterialIcon(
            name = "swap_calls",
            style = MaterialIcon.Style.TwoTone
        )
        val SwapHoriz = MaterialIcon(
            name = "swap_horiz",
            style = MaterialIcon.Style.TwoTone
        )
        val SwapHorizontalCircle = MaterialIcon(
            name = "swap_horizontal_circle",
            style = MaterialIcon.Style.TwoTone
        )
        val SwapVert = MaterialIcon(
            name = "swap_vert",
            style = MaterialIcon.Style.TwoTone
        )
        val SwapVerticalCircle = MaterialIcon(
            name = "swap_vertical_circle",
            style = MaterialIcon.Style.TwoTone
        )
        val Swipe = MaterialIcon(
            name = "swipe",
            style = MaterialIcon.Style.TwoTone
        )
        val SwipeDown = MaterialIcon(
            name = "swipe_down",
            style = MaterialIcon.Style.TwoTone
        )
        val SwipeDownAlt = MaterialIcon(
            name = "swipe_down_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val SwipeLeft = MaterialIcon(
            name = "swipe_left",
            style = MaterialIcon.Style.TwoTone
        )
        val SwipeLeftAlt = MaterialIcon(
            name = "swipe_left_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val SwipeRight = MaterialIcon(
            name = "swipe_right",
            style = MaterialIcon.Style.TwoTone
        )
        val SwipeRightAlt = MaterialIcon(
            name = "swipe_right_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val SwipeUp = MaterialIcon(
            name = "swipe_up",
            style = MaterialIcon.Style.TwoTone
        )
        val SwipeUpAlt = MaterialIcon(
            name = "swipe_up_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val SwipeVertical = MaterialIcon(
            name = "swipe_vertical",
            style = MaterialIcon.Style.TwoTone
        )
        val SwitchAccessShortcut = MaterialIcon(
            name = "switch_access_shortcut",
            style = MaterialIcon.Style.TwoTone
        )
        val SwitchAccessShortcutAdd = MaterialIcon(
            name = "switch_access_shortcut_add",
            style = MaterialIcon.Style.TwoTone
        )
        val SwitchAccount = MaterialIcon(
            name = "switch_account",
            style = MaterialIcon.Style.TwoTone
        )
        val SwitchCamera = MaterialIcon(
            name = "switch_camera",
            style = MaterialIcon.Style.TwoTone
        )
        val SwitchLeft = MaterialIcon(
            name = "switch_left",
            style = MaterialIcon.Style.TwoTone
        )
        val SwitchRight = MaterialIcon(
            name = "switch_right",
            style = MaterialIcon.Style.TwoTone
        )
        val SwitchVideo = MaterialIcon(
            name = "switch_video",
            style = MaterialIcon.Style.TwoTone
        )
        val Synagogue = MaterialIcon(
            name = "synagogue",
            style = MaterialIcon.Style.TwoTone
        )
        val Sync = MaterialIcon(
            name = "sync",
            style = MaterialIcon.Style.TwoTone
        )
        val SyncAlt = MaterialIcon(
            name = "sync_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val SyncDisabled = MaterialIcon(
            name = "sync_disabled",
            style = MaterialIcon.Style.TwoTone
        )
        val SyncLock = MaterialIcon(
            name = "sync_lock",
            style = MaterialIcon.Style.TwoTone
        )
        val SyncProblem = MaterialIcon(
            name = "sync_problem",
            style = MaterialIcon.Style.TwoTone
        )
        val SystemSecurityUpdate = MaterialIcon(
            name = "system_security_update",
            style = MaterialIcon.Style.TwoTone
        )
        val SystemSecurityUpdateGood = MaterialIcon(
            name = "system_security_update_good",
            style = MaterialIcon.Style.TwoTone
        )
        val SystemSecurityUpdateWarning = MaterialIcon(
            name = "system_security_update_warning",
            style = MaterialIcon.Style.TwoTone
        )
        val SystemUpdate = MaterialIcon(
            name = "system_update",
            style = MaterialIcon.Style.TwoTone
        )
        val SystemUpdateAlt = MaterialIcon(
            name = "system_update_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val Tab = MaterialIcon(
            name = "tab",
            style = MaterialIcon.Style.TwoTone
        )
        val TabUnselected = MaterialIcon(
            name = "tab_unselected",
            style = MaterialIcon.Style.TwoTone
        )
        val TableBar = MaterialIcon(
            name = "table_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val TableChart = MaterialIcon(
            name = "table_chart",
            style = MaterialIcon.Style.TwoTone
        )
        val TableRestaurant = MaterialIcon(
            name = "table_restaurant",
            style = MaterialIcon.Style.TwoTone
        )
        val TableRows = MaterialIcon(
            name = "table_rows",
            style = MaterialIcon.Style.TwoTone
        )
        val TableView = MaterialIcon(
            name = "table_view",
            style = MaterialIcon.Style.TwoTone
        )
        val Tablet = MaterialIcon(
            name = "tablet",
            style = MaterialIcon.Style.TwoTone
        )
        val TabletAndroid = MaterialIcon(
            name = "tablet_android",
            style = MaterialIcon.Style.TwoTone
        )
        val TabletMac = MaterialIcon(
            name = "tablet_mac",
            style = MaterialIcon.Style.TwoTone
        )
        val Tag = MaterialIcon(
            name = "tag",
            style = MaterialIcon.Style.TwoTone
        )
        val TagFaces = MaterialIcon(
            name = "tag_faces",
            style = MaterialIcon.Style.TwoTone
        )
        val TakeoutDining = MaterialIcon(
            name = "takeout_dining",
            style = MaterialIcon.Style.TwoTone
        )
        val TapAndPlay = MaterialIcon(
            name = "tap_and_play",
            style = MaterialIcon.Style.TwoTone
        )
        val Tapas = MaterialIcon(
            name = "tapas",
            style = MaterialIcon.Style.TwoTone
        )
        val Task = MaterialIcon(
            name = "task",
            style = MaterialIcon.Style.TwoTone
        )
        val TaskAlt = MaterialIcon(
            name = "task_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val TaxiAlert = MaterialIcon(
            name = "taxi_alert",
            style = MaterialIcon.Style.TwoTone
        )
        val TempleBuddhist = MaterialIcon(
            name = "temple_buddhist",
            style = MaterialIcon.Style.TwoTone
        )
        val TempleHindu = MaterialIcon(
            name = "temple_hindu",
            style = MaterialIcon.Style.TwoTone
        )
        val Terminal = MaterialIcon(
            name = "terminal",
            style = MaterialIcon.Style.TwoTone
        )
        val Terrain = MaterialIcon(
            name = "terrain",
            style = MaterialIcon.Style.TwoTone
        )
        val TextDecrease = MaterialIcon(
            name = "text_decrease",
            style = MaterialIcon.Style.TwoTone
        )
        val TextFields = MaterialIcon(
            name = "text_fields",
            style = MaterialIcon.Style.TwoTone
        )
        val TextFormat = MaterialIcon(
            name = "text_format",
            style = MaterialIcon.Style.TwoTone
        )
        val TextIncrease = MaterialIcon(
            name = "text_increase",
            style = MaterialIcon.Style.TwoTone
        )
        val TextRotateUp = MaterialIcon(
            name = "text_rotate_up",
            style = MaterialIcon.Style.TwoTone
        )
        val TextRotateVertical = MaterialIcon(
            name = "text_rotate_vertical",
            style = MaterialIcon.Style.TwoTone
        )
        val TextRotationAngledown = MaterialIcon(
            name = "text_rotation_angledown",
            style = MaterialIcon.Style.TwoTone
        )
        val TextRotationAngleup = MaterialIcon(
            name = "text_rotation_angleup",
            style = MaterialIcon.Style.TwoTone
        )
        val TextRotationDown = MaterialIcon(
            name = "text_rotation_down",
            style = MaterialIcon.Style.TwoTone
        )
        val TextRotationNone = MaterialIcon(
            name = "text_rotation_none",
            style = MaterialIcon.Style.TwoTone
        )
        val TextSnippet = MaterialIcon(
            name = "text_snippet",
            style = MaterialIcon.Style.TwoTone
        )
        val Textsms = MaterialIcon(
            name = "textsms",
            style = MaterialIcon.Style.TwoTone
        )
        val Texture = MaterialIcon(
            name = "texture",
            style = MaterialIcon.Style.TwoTone
        )
        val TheaterComedy = MaterialIcon(
            name = "theater_comedy",
            style = MaterialIcon.Style.TwoTone
        )
        val Theaters = MaterialIcon(
            name = "theaters",
            style = MaterialIcon.Style.TwoTone
        )
        val Thermostat = MaterialIcon(
            name = "thermostat",
            style = MaterialIcon.Style.TwoTone
        )
        val ThermostatAuto = MaterialIcon(
            name = "thermostat_auto",
            style = MaterialIcon.Style.TwoTone
        )
        val ThumbDown = MaterialIcon(
            name = "thumb_down",
            style = MaterialIcon.Style.TwoTone
        )
        val ThumbDownAlt = MaterialIcon(
            name = "thumb_down_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val ThumbDownOffAlt = MaterialIcon(
            name = "thumb_down_off_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val ThumbUp = MaterialIcon(
            name = "thumb_up",
            style = MaterialIcon.Style.TwoTone
        )
        val ThumbUpAlt = MaterialIcon(
            name = "thumb_up_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val ThumbUpOffAlt = MaterialIcon(
            name = "thumb_up_off_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val ThumbsUpDown = MaterialIcon(
            name = "thumbs_up_down",
            style = MaterialIcon.Style.TwoTone
        )
        val Thunderstorm = MaterialIcon(
            name = "thunderstorm",
            style = MaterialIcon.Style.TwoTone
        )
        val TimeToLeave = MaterialIcon(
            name = "time_to_leave",
            style = MaterialIcon.Style.TwoTone
        )
        val Timelapse = MaterialIcon(
            name = "timelapse",
            style = MaterialIcon.Style.TwoTone
        )
        val Timeline = MaterialIcon(
            name = "timeline",
            style = MaterialIcon.Style.TwoTone
        )
        val Timer = MaterialIcon(
            name = "timer",
            style = MaterialIcon.Style.TwoTone
        )
        val Timer10 = MaterialIcon(
            name = "timer_10",
            style = MaterialIcon.Style.TwoTone
        )
        val Timer10Select = MaterialIcon(
            name = "timer_10_select",
            style = MaterialIcon.Style.TwoTone
        )
        val Timer3 = MaterialIcon(
            name = "timer_3",
            style = MaterialIcon.Style.TwoTone
        )
        val Timer3Select = MaterialIcon(
            name = "timer_3_select",
            style = MaterialIcon.Style.TwoTone
        )
        val TimerOff = MaterialIcon(
            name = "timer_off",
            style = MaterialIcon.Style.TwoTone
        )
        val TipsAndUpdates = MaterialIcon(
            name = "tips_and_updates",
            style = MaterialIcon.Style.TwoTone
        )
        val TireRepair = MaterialIcon(
            name = "tire_repair",
            style = MaterialIcon.Style.TwoTone
        )
        val Title = MaterialIcon(
            name = "title",
            style = MaterialIcon.Style.TwoTone
        )
        val Toc = MaterialIcon(
            name = "toc",
            style = MaterialIcon.Style.TwoTone
        )
        val Today = MaterialIcon(
            name = "today",
            style = MaterialIcon.Style.TwoTone
        )
        val ToggleOff = MaterialIcon(
            name = "toggle_off",
            style = MaterialIcon.Style.TwoTone
        )
        val ToggleOn = MaterialIcon(
            name = "toggle_on",
            style = MaterialIcon.Style.TwoTone
        )
        val Token = MaterialIcon(
            name = "token",
            style = MaterialIcon.Style.TwoTone
        )
        val Toll = MaterialIcon(
            name = "toll",
            style = MaterialIcon.Style.TwoTone
        )
        val Tonality = MaterialIcon(
            name = "tonality",
            style = MaterialIcon.Style.TwoTone
        )
        val Topic = MaterialIcon(
            name = "topic",
            style = MaterialIcon.Style.TwoTone
        )
        val Tornado = MaterialIcon(
            name = "tornado",
            style = MaterialIcon.Style.TwoTone
        )
        val TouchApp = MaterialIcon(
            name = "touch_app",
            style = MaterialIcon.Style.TwoTone
        )
        val Tour = MaterialIcon(
            name = "tour",
            style = MaterialIcon.Style.TwoTone
        )
        val Toys = MaterialIcon(
            name = "toys",
            style = MaterialIcon.Style.TwoTone
        )
        val TrackChanges = MaterialIcon(
            name = "track_changes",
            style = MaterialIcon.Style.TwoTone
        )
        val Traffic = MaterialIcon(
            name = "traffic",
            style = MaterialIcon.Style.TwoTone
        )
        val Train = MaterialIcon(
            name = "train",
            style = MaterialIcon.Style.TwoTone
        )
        val Tram = MaterialIcon(
            name = "tram",
            style = MaterialIcon.Style.TwoTone
        )
        val Transcribe = MaterialIcon(
            name = "transcribe",
            style = MaterialIcon.Style.TwoTone
        )
        val TransferWithinAStation = MaterialIcon(
            name = "transfer_within_a_station",
            style = MaterialIcon.Style.TwoTone
        )
        val Transform = MaterialIcon(
            name = "transform",
            style = MaterialIcon.Style.TwoTone
        )
        val Transgender = MaterialIcon(
            name = "transgender",
            style = MaterialIcon.Style.TwoTone
        )
        val TransitEnterexit = MaterialIcon(
            name = "transit_enterexit",
            style = MaterialIcon.Style.TwoTone
        )
        val Translate = MaterialIcon(
            name = "translate",
            style = MaterialIcon.Style.TwoTone
        )
        val TravelExplore = MaterialIcon(
            name = "travel_explore",
            style = MaterialIcon.Style.TwoTone
        )
        val TrendingDown = MaterialIcon(
            name = "trending_down",
            style = MaterialIcon.Style.TwoTone
        )
        val TrendingFlat = MaterialIcon(
            name = "trending_flat",
            style = MaterialIcon.Style.TwoTone
        )
        val TrendingUp = MaterialIcon(
            name = "trending_up",
            style = MaterialIcon.Style.TwoTone
        )
        val TripOrigin = MaterialIcon(
            name = "trip_origin",
            style = MaterialIcon.Style.TwoTone
        )
        val Troubleshoot = MaterialIcon(
            name = "troubleshoot",
            style = MaterialIcon.Style.TwoTone
        )
        val Try = MaterialIcon(
            name = "try",
            style = MaterialIcon.Style.TwoTone
        )
        val Tsunami = MaterialIcon(
            name = "tsunami",
            style = MaterialIcon.Style.TwoTone
        )
        val Tty = MaterialIcon(
            name = "tty",
            style = MaterialIcon.Style.TwoTone
        )
        val Tune = MaterialIcon(
            name = "tune",
            style = MaterialIcon.Style.TwoTone
        )
        val Tungsten = MaterialIcon(
            name = "tungsten",
            style = MaterialIcon.Style.TwoTone
        )
        val TurnLeft = MaterialIcon(
            name = "turn_left",
            style = MaterialIcon.Style.TwoTone
        )
        val TurnRight = MaterialIcon(
            name = "turn_right",
            style = MaterialIcon.Style.TwoTone
        )
        val TurnSharpLeft = MaterialIcon(
            name = "turn_sharp_left",
            style = MaterialIcon.Style.TwoTone
        )
        val TurnSharpRight = MaterialIcon(
            name = "turn_sharp_right",
            style = MaterialIcon.Style.TwoTone
        )
        val TurnSlightLeft = MaterialIcon(
            name = "turn_slight_left",
            style = MaterialIcon.Style.TwoTone
        )
        val TurnSlightRight = MaterialIcon(
            name = "turn_slight_right",
            style = MaterialIcon.Style.TwoTone
        )
        val TurnedIn = MaterialIcon(
            name = "turned_in",
            style = MaterialIcon.Style.TwoTone
        )
        val TurnedInNot = MaterialIcon(
            name = "turned_in_not",
            style = MaterialIcon.Style.TwoTone
        )
        val Tv = MaterialIcon(
            name = "tv",
            style = MaterialIcon.Style.TwoTone
        )
        val TvOff = MaterialIcon(
            name = "tv_off",
            style = MaterialIcon.Style.TwoTone
        )
        val TwoWheeler = MaterialIcon(
            name = "two_wheeler",
            style = MaterialIcon.Style.TwoTone
        )
        val TypeSpecimen = MaterialIcon(
            name = "type_specimen",
            style = MaterialIcon.Style.TwoTone
        )
        val UTurnLeft = MaterialIcon(
            name = "u_turn_left",
            style = MaterialIcon.Style.TwoTone
        )
        val UTurnRight = MaterialIcon(
            name = "u_turn_right",
            style = MaterialIcon.Style.TwoTone
        )
        val Umbrella = MaterialIcon(
            name = "umbrella",
            style = MaterialIcon.Style.TwoTone
        )
        val Unarchive = MaterialIcon(
            name = "unarchive",
            style = MaterialIcon.Style.TwoTone
        )
        val Undo = MaterialIcon(
            name = "undo",
            style = MaterialIcon.Style.TwoTone
        )
        val UnfoldLess = MaterialIcon(
            name = "unfold_less",
            style = MaterialIcon.Style.TwoTone
        )
        val UnfoldLessDouble = MaterialIcon(
            name = "unfold_less_double",
            style = MaterialIcon.Style.TwoTone
        )
        val UnfoldMore = MaterialIcon(
            name = "unfold_more",
            style = MaterialIcon.Style.TwoTone
        )
        val UnfoldMoreDouble = MaterialIcon(
            name = "unfold_more_double",
            style = MaterialIcon.Style.TwoTone
        )
        val Unpublished = MaterialIcon(
            name = "unpublished",
            style = MaterialIcon.Style.TwoTone
        )
        val Unsubscribe = MaterialIcon(
            name = "unsubscribe",
            style = MaterialIcon.Style.TwoTone
        )
        val Upcoming = MaterialIcon(
            name = "upcoming",
            style = MaterialIcon.Style.TwoTone
        )
        val Update = MaterialIcon(
            name = "update",
            style = MaterialIcon.Style.TwoTone
        )
        val UpdateDisabled = MaterialIcon(
            name = "update_disabled",
            style = MaterialIcon.Style.TwoTone
        )
        val Upgrade = MaterialIcon(
            name = "upgrade",
            style = MaterialIcon.Style.TwoTone
        )
        val Upload = MaterialIcon(
            name = "upload",
            style = MaterialIcon.Style.TwoTone
        )
        val UploadFile = MaterialIcon(
            name = "upload_file",
            style = MaterialIcon.Style.TwoTone
        )
        val Usb = MaterialIcon(
            name = "usb",
            style = MaterialIcon.Style.TwoTone
        )
        val UsbOff = MaterialIcon(
            name = "usb_off",
            style = MaterialIcon.Style.TwoTone
        )
        val Vaccines = MaterialIcon(
            name = "vaccines",
            style = MaterialIcon.Style.TwoTone
        )
        val VapeFree = MaterialIcon(
            name = "vape_free",
            style = MaterialIcon.Style.TwoTone
        )
        val VapingRooms = MaterialIcon(
            name = "vaping_rooms",
            style = MaterialIcon.Style.TwoTone
        )
        val Verified = MaterialIcon(
            name = "verified",
            style = MaterialIcon.Style.TwoTone
        )
        val VerifiedUser = MaterialIcon(
            name = "verified_user",
            style = MaterialIcon.Style.TwoTone
        )
        val VerticalAlignBottom = MaterialIcon(
            name = "vertical_align_bottom",
            style = MaterialIcon.Style.TwoTone
        )
        val VerticalAlignCenter = MaterialIcon(
            name = "vertical_align_center",
            style = MaterialIcon.Style.TwoTone
        )
        val VerticalAlignTop = MaterialIcon(
            name = "vertical_align_top",
            style = MaterialIcon.Style.TwoTone
        )
        val VerticalDistribute = MaterialIcon(
            name = "vertical_distribute",
            style = MaterialIcon.Style.TwoTone
        )
        val VerticalShades = MaterialIcon(
            name = "vertical_shades",
            style = MaterialIcon.Style.TwoTone
        )
        val VerticalShadesClosed = MaterialIcon(
            name = "vertical_shades_closed",
            style = MaterialIcon.Style.TwoTone
        )
        val VerticalSplit = MaterialIcon(
            name = "vertical_split",
            style = MaterialIcon.Style.TwoTone
        )
        val Vibration = MaterialIcon(
            name = "vibration",
            style = MaterialIcon.Style.TwoTone
        )
        val VideoCall = MaterialIcon(
            name = "video_call",
            style = MaterialIcon.Style.TwoTone
        )
        val VideoCameraBack = MaterialIcon(
            name = "video_camera_back",
            style = MaterialIcon.Style.TwoTone
        )
        val VideoCameraFront = MaterialIcon(
            name = "video_camera_front",
            style = MaterialIcon.Style.TwoTone
        )
        val VideoChat = MaterialIcon(
            name = "video_chat",
            style = MaterialIcon.Style.TwoTone
        )
        val VideoFile = MaterialIcon(
            name = "video_file",
            style = MaterialIcon.Style.TwoTone
        )
        val VideoLabel = MaterialIcon(
            name = "video_label",
            style = MaterialIcon.Style.TwoTone
        )
        val VideoLibrary = MaterialIcon(
            name = "video_library",
            style = MaterialIcon.Style.TwoTone
        )
        val VideoSettings = MaterialIcon(
            name = "video_settings",
            style = MaterialIcon.Style.TwoTone
        )
        val VideoStable = MaterialIcon(
            name = "video_stable",
            style = MaterialIcon.Style.TwoTone
        )
        val Videocam = MaterialIcon(
            name = "videocam",
            style = MaterialIcon.Style.TwoTone
        )
        val VideocamOff = MaterialIcon(
            name = "videocam_off",
            style = MaterialIcon.Style.TwoTone
        )
        val VideogameAsset = MaterialIcon(
            name = "videogame_asset",
            style = MaterialIcon.Style.TwoTone
        )
        val VideogameAssetOff = MaterialIcon(
            name = "videogame_asset_off",
            style = MaterialIcon.Style.TwoTone
        )
        val ViewAgenda = MaterialIcon(
            name = "view_agenda",
            style = MaterialIcon.Style.TwoTone
        )
        val ViewArray = MaterialIcon(
            name = "view_array",
            style = MaterialIcon.Style.TwoTone
        )
        val ViewCarousel = MaterialIcon(
            name = "view_carousel",
            style = MaterialIcon.Style.TwoTone
        )
        val ViewColumn = MaterialIcon(
            name = "view_column",
            style = MaterialIcon.Style.TwoTone
        )
        val ViewComfy = MaterialIcon(
            name = "view_comfy",
            style = MaterialIcon.Style.TwoTone
        )
        val ViewComfyAlt = MaterialIcon(
            name = "view_comfy_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val ViewCompact = MaterialIcon(
            name = "view_compact",
            style = MaterialIcon.Style.TwoTone
        )
        val ViewCompactAlt = MaterialIcon(
            name = "view_compact_alt",
            style = MaterialIcon.Style.TwoTone
        )
        val ViewCozy = MaterialIcon(
            name = "view_cozy",
            style = MaterialIcon.Style.TwoTone
        )
        val ViewDay = MaterialIcon(
            name = "view_day",
            style = MaterialIcon.Style.TwoTone
        )
        val ViewHeadline = MaterialIcon(
            name = "view_headline",
            style = MaterialIcon.Style.TwoTone
        )
        val ViewInAr = MaterialIcon(
            name = "view_in_ar",
            style = MaterialIcon.Style.TwoTone
        )
        val ViewKanban = MaterialIcon(
            name = "view_kanban",
            style = MaterialIcon.Style.TwoTone
        )
        val ViewList = MaterialIcon(
            name = "view_list",
            style = MaterialIcon.Style.TwoTone
        )
        val ViewModule = MaterialIcon(
            name = "view_module",
            style = MaterialIcon.Style.TwoTone
        )
        val ViewQuilt = MaterialIcon(
            name = "view_quilt",
            style = MaterialIcon.Style.TwoTone
        )
        val ViewSidebar = MaterialIcon(
            name = "view_sidebar",
            style = MaterialIcon.Style.TwoTone
        )
        val ViewStream = MaterialIcon(
            name = "view_stream",
            style = MaterialIcon.Style.TwoTone
        )
        val ViewTimeline = MaterialIcon(
            name = "view_timeline",
            style = MaterialIcon.Style.TwoTone
        )
        val ViewWeek = MaterialIcon(
            name = "view_week",
            style = MaterialIcon.Style.TwoTone
        )
        val Vignette = MaterialIcon(
            name = "vignette",
            style = MaterialIcon.Style.TwoTone
        )
        val Villa = MaterialIcon(
            name = "villa",
            style = MaterialIcon.Style.TwoTone
        )
        val Visibility = MaterialIcon(
            name = "visibility",
            style = MaterialIcon.Style.TwoTone
        )
        val VisibilityOff = MaterialIcon(
            name = "visibility_off",
            style = MaterialIcon.Style.TwoTone
        )
        val VoiceChat = MaterialIcon(
            name = "voice_chat",
            style = MaterialIcon.Style.TwoTone
        )
        val VoiceOverOff = MaterialIcon(
            name = "voice_over_off",
            style = MaterialIcon.Style.TwoTone
        )
        val Voicemail = MaterialIcon(
            name = "voicemail",
            style = MaterialIcon.Style.TwoTone
        )
        val Volcano = MaterialIcon(
            name = "volcano",
            style = MaterialIcon.Style.TwoTone
        )
        val VolumeDown = MaterialIcon(
            name = "volume_down",
            style = MaterialIcon.Style.TwoTone
        )
        val VolumeMute = MaterialIcon(
            name = "volume_mute",
            style = MaterialIcon.Style.TwoTone
        )
        val VolumeOff = MaterialIcon(
            name = "volume_off",
            style = MaterialIcon.Style.TwoTone
        )
        val VolumeUp = MaterialIcon(
            name = "volume_up",
            style = MaterialIcon.Style.TwoTone
        )
        val VolunteerActivism = MaterialIcon(
            name = "volunteer_activism",
            style = MaterialIcon.Style.TwoTone
        )
        val VpnKey = MaterialIcon(
            name = "vpn_key",
            style = MaterialIcon.Style.TwoTone
        )
        val VpnKeyOff = MaterialIcon(
            name = "vpn_key_off",
            style = MaterialIcon.Style.TwoTone
        )
        val VpnLock = MaterialIcon(
            name = "vpn_lock",
            style = MaterialIcon.Style.TwoTone
        )
        val Vrpano = MaterialIcon(
            name = "vrpano",
            style = MaterialIcon.Style.TwoTone
        )
        val Wallet = MaterialIcon(
            name = "wallet",
            style = MaterialIcon.Style.TwoTone
        )
        val Wallpaper = MaterialIcon(
            name = "wallpaper",
            style = MaterialIcon.Style.TwoTone
        )
        val Warehouse = MaterialIcon(
            name = "warehouse",
            style = MaterialIcon.Style.TwoTone
        )
        val Warning = MaterialIcon(
            name = "warning",
            style = MaterialIcon.Style.TwoTone
        )
        val WarningAmber = MaterialIcon(
            name = "warning_amber",
            style = MaterialIcon.Style.TwoTone
        )
        val Wash = MaterialIcon(
            name = "wash",
            style = MaterialIcon.Style.TwoTone
        )
        val Watch = MaterialIcon(
            name = "watch",
            style = MaterialIcon.Style.TwoTone
        )
        val WatchLater = MaterialIcon(
            name = "watch_later",
            style = MaterialIcon.Style.TwoTone
        )
        val WatchOff = MaterialIcon(
            name = "watch_off",
            style = MaterialIcon.Style.TwoTone
        )
        val Water = MaterialIcon(
            name = "water",
            style = MaterialIcon.Style.TwoTone
        )
        val WaterDamage = MaterialIcon(
            name = "water_damage",
            style = MaterialIcon.Style.TwoTone
        )
        val WaterDrop = MaterialIcon(
            name = "water_drop",
            style = MaterialIcon.Style.TwoTone
        )
        val WaterfallChart = MaterialIcon(
            name = "waterfall_chart",
            style = MaterialIcon.Style.TwoTone
        )
        val Waves = MaterialIcon(
            name = "waves",
            style = MaterialIcon.Style.TwoTone
        )
        val WavingHand = MaterialIcon(
            name = "waving_hand",
            style = MaterialIcon.Style.TwoTone
        )
        val WbAuto = MaterialIcon(
            name = "wb_auto",
            style = MaterialIcon.Style.TwoTone
        )
        val WbCloudy = MaterialIcon(
            name = "wb_cloudy",
            style = MaterialIcon.Style.TwoTone
        )
        val WbIncandescent = MaterialIcon(
            name = "wb_incandescent",
            style = MaterialIcon.Style.TwoTone
        )
        val WbIridescent = MaterialIcon(
            name = "wb_iridescent",
            style = MaterialIcon.Style.TwoTone
        )
        val WbShade = MaterialIcon(
            name = "wb_shade",
            style = MaterialIcon.Style.TwoTone
        )
        val WbSunny = MaterialIcon(
            name = "wb_sunny",
            style = MaterialIcon.Style.TwoTone
        )
        val WbTwilight = MaterialIcon(
            name = "wb_twilight",
            style = MaterialIcon.Style.TwoTone
        )
        val Wc = MaterialIcon(
            name = "wc",
            style = MaterialIcon.Style.TwoTone
        )
        val Web = MaterialIcon(
            name = "web",
            style = MaterialIcon.Style.TwoTone
        )
        val WebAsset = MaterialIcon(
            name = "web_asset",
            style = MaterialIcon.Style.TwoTone
        )
        val WebAssetOff = MaterialIcon(
            name = "web_asset_off",
            style = MaterialIcon.Style.TwoTone
        )
        val WebStories = MaterialIcon(
            name = "web_stories",
            style = MaterialIcon.Style.TwoTone
        )
        val Webhook = MaterialIcon(
            name = "webhook",
            style = MaterialIcon.Style.TwoTone
        )
        val Weekend = MaterialIcon(
            name = "weekend",
            style = MaterialIcon.Style.TwoTone
        )
        val West = MaterialIcon(
            name = "west",
            style = MaterialIcon.Style.TwoTone
        )
        val Whatshot = MaterialIcon(
            name = "whatshot",
            style = MaterialIcon.Style.TwoTone
        )
        val WheelchairPickup = MaterialIcon(
            name = "wheelchair_pickup",
            style = MaterialIcon.Style.TwoTone
        )
        val WhereToVote = MaterialIcon(
            name = "where_to_vote",
            style = MaterialIcon.Style.TwoTone
        )
        val Widgets = MaterialIcon(
            name = "widgets",
            style = MaterialIcon.Style.TwoTone
        )
        val WidthFull = MaterialIcon(
            name = "width_full",
            style = MaterialIcon.Style.TwoTone
        )
        val WidthNormal = MaterialIcon(
            name = "width_normal",
            style = MaterialIcon.Style.TwoTone
        )
        val WidthWide = MaterialIcon(
            name = "width_wide",
            style = MaterialIcon.Style.TwoTone
        )
        val Wifi = MaterialIcon(
            name = "wifi",
            style = MaterialIcon.Style.TwoTone
        )
        val Wifi1Bar = MaterialIcon(
            name = "wifi_1_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val Wifi2Bar = MaterialIcon(
            name = "wifi_2_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val WifiCalling = MaterialIcon(
            name = "wifi_calling",
            style = MaterialIcon.Style.TwoTone
        )
        val WifiCalling3 = MaterialIcon(
            name = "wifi_calling_3",
            style = MaterialIcon.Style.TwoTone
        )
        val WifiChannel = MaterialIcon(
            name = "wifi_channel",
            style = MaterialIcon.Style.TwoTone
        )
        val WifiFind = MaterialIcon(
            name = "wifi_find",
            style = MaterialIcon.Style.TwoTone
        )
        val WifiLock = MaterialIcon(
            name = "wifi_lock",
            style = MaterialIcon.Style.TwoTone
        )
        val WifiOff = MaterialIcon(
            name = "wifi_off",
            style = MaterialIcon.Style.TwoTone
        )
        val WifiPassword = MaterialIcon(
            name = "wifi_password",
            style = MaterialIcon.Style.TwoTone
        )
        val WifiProtectedSetup = MaterialIcon(
            name = "wifi_protected_setup",
            style = MaterialIcon.Style.TwoTone
        )
        val WifiTethering = MaterialIcon(
            name = "wifi_tethering",
            style = MaterialIcon.Style.TwoTone
        )
        val WifiTetheringError = MaterialIcon(
            name = "wifi_tethering_error",
            style = MaterialIcon.Style.TwoTone
        )
        val WifiTetheringOff = MaterialIcon(
            name = "wifi_tethering_off",
            style = MaterialIcon.Style.TwoTone
        )
        val WindPower = MaterialIcon(
            name = "wind_power",
            style = MaterialIcon.Style.TwoTone
        )
        val Window = MaterialIcon(
            name = "window",
            style = MaterialIcon.Style.TwoTone
        )
        val WineBar = MaterialIcon(
            name = "wine_bar",
            style = MaterialIcon.Style.TwoTone
        )
        val Woman = MaterialIcon(
            name = "woman",
            style = MaterialIcon.Style.TwoTone
        )
        val Woman2 = MaterialIcon(
            name = "woman_2",
            style = MaterialIcon.Style.TwoTone
        )
        val Work = MaterialIcon(
            name = "work",
            style = MaterialIcon.Style.TwoTone
        )
        val WorkHistory = MaterialIcon(
            name = "work_history",
            style = MaterialIcon.Style.TwoTone
        )
        val WorkOff = MaterialIcon(
            name = "work_off",
            style = MaterialIcon.Style.TwoTone
        )
        val WorkOutline = MaterialIcon(
            name = "work_outline",
            style = MaterialIcon.Style.TwoTone
        )
        val WorkspacePremium = MaterialIcon(
            name = "workspace_premium",
            style = MaterialIcon.Style.TwoTone
        )
        val Workspaces = MaterialIcon(
            name = "workspaces",
            style = MaterialIcon.Style.TwoTone
        )
        val WrapText = MaterialIcon(
            name = "wrap_text",
            style = MaterialIcon.Style.TwoTone
        )
        val WrongLocation = MaterialIcon(
            name = "wrong_location",
            style = MaterialIcon.Style.TwoTone
        )
        val Wysiwyg = MaterialIcon(
            name = "wysiwyg",
            style = MaterialIcon.Style.TwoTone
        )
        val Yard = MaterialIcon(
            name = "yard",
            style = MaterialIcon.Style.TwoTone
        )
        val YoutubeSearchedFor = MaterialIcon(
            name = "youtube_searched_for",
            style = MaterialIcon.Style.TwoTone
        )
        val ZoomIn = MaterialIcon(
            name = "zoom_in",
            style = MaterialIcon.Style.TwoTone
        )
        val ZoomInMap = MaterialIcon(
            name = "zoom_in_map",
            style = MaterialIcon.Style.TwoTone
        )
        val ZoomOut = MaterialIcon(
            name = "zoom_out",
            style = MaterialIcon.Style.TwoTone
        )
        val ZoomOutMap = MaterialIcon(
            name = "zoom_out_map",
            style = MaterialIcon.Style.TwoTone
        )
    }
    object Round {
        val _10k = MaterialIcon(
            name = "10k",
            style = MaterialIcon.Style.Round
        )
        val _10mp = MaterialIcon(
            name = "10mp",
            style = MaterialIcon.Style.Round
        )
        val _11mp = MaterialIcon(
            name = "11mp",
            style = MaterialIcon.Style.Round
        )
        val _123 = MaterialIcon(
            name = "123",
            style = MaterialIcon.Style.Round
        )
        val _12mp = MaterialIcon(
            name = "12mp",
            style = MaterialIcon.Style.Round
        )
        val _13mp = MaterialIcon(
            name = "13mp",
            style = MaterialIcon.Style.Round
        )
        val _14mp = MaterialIcon(
            name = "14mp",
            style = MaterialIcon.Style.Round
        )
        val _15mp = MaterialIcon(
            name = "15mp",
            style = MaterialIcon.Style.Round
        )
        val _16mp = MaterialIcon(
            name = "16mp",
            style = MaterialIcon.Style.Round
        )
        val _17mp = MaterialIcon(
            name = "17mp",
            style = MaterialIcon.Style.Round
        )
        val _18UpRating = MaterialIcon(
            name = "18_up_rating",
            style = MaterialIcon.Style.Round
        )
        val _18mp = MaterialIcon(
            name = "18mp",
            style = MaterialIcon.Style.Round
        )
        val _19mp = MaterialIcon(
            name = "19mp",
            style = MaterialIcon.Style.Round
        )
        val _1k = MaterialIcon(
            name = "1k",
            style = MaterialIcon.Style.Round
        )
        val _1kPlus = MaterialIcon(
            name = "1k_plus",
            style = MaterialIcon.Style.Round
        )
        val _1xMobiledata = MaterialIcon(
            name = "1x_mobiledata",
            style = MaterialIcon.Style.Round
        )
        val _20mp = MaterialIcon(
            name = "20mp",
            style = MaterialIcon.Style.Round
        )
        val _21mp = MaterialIcon(
            name = "21mp",
            style = MaterialIcon.Style.Round
        )
        val _22mp = MaterialIcon(
            name = "22mp",
            style = MaterialIcon.Style.Round
        )
        val _23mp = MaterialIcon(
            name = "23mp",
            style = MaterialIcon.Style.Round
        )
        val _24mp = MaterialIcon(
            name = "24mp",
            style = MaterialIcon.Style.Round
        )
        val _2k = MaterialIcon(
            name = "2k",
            style = MaterialIcon.Style.Round
        )
        val _2kPlus = MaterialIcon(
            name = "2k_plus",
            style = MaterialIcon.Style.Round
        )
        val _2mp = MaterialIcon(
            name = "2mp",
            style = MaterialIcon.Style.Round
        )
        val _30fps = MaterialIcon(
            name = "30fps",
            style = MaterialIcon.Style.Round
        )
        val _30fpsSelect = MaterialIcon(
            name = "30fps_select",
            style = MaterialIcon.Style.Round
        )
        val _360 = MaterialIcon(
            name = "360",
            style = MaterialIcon.Style.Round
        )
        val _3dRotation = MaterialIcon(
            name = "3d_rotation",
            style = MaterialIcon.Style.Round
        )
        val _3gMobiledata = MaterialIcon(
            name = "3g_mobiledata",
            style = MaterialIcon.Style.Round
        )
        val _3k = MaterialIcon(
            name = "3k",
            style = MaterialIcon.Style.Round
        )
        val _3kPlus = MaterialIcon(
            name = "3k_plus",
            style = MaterialIcon.Style.Round
        )
        val _3mp = MaterialIcon(
            name = "3mp",
            style = MaterialIcon.Style.Round
        )
        val _3p = MaterialIcon(
            name = "3p",
            style = MaterialIcon.Style.Round
        )
        val _4gMobiledata = MaterialIcon(
            name = "4g_mobiledata",
            style = MaterialIcon.Style.Round
        )
        val _4gPlusMobiledata = MaterialIcon(
            name = "4g_plus_mobiledata",
            style = MaterialIcon.Style.Round
        )
        val _4k = MaterialIcon(
            name = "4k",
            style = MaterialIcon.Style.Round
        )
        val _4kPlus = MaterialIcon(
            name = "4k_plus",
            style = MaterialIcon.Style.Round
        )
        val _4mp = MaterialIcon(
            name = "4mp",
            style = MaterialIcon.Style.Round
        )
        val _5g = MaterialIcon(
            name = "5g",
            style = MaterialIcon.Style.Round
        )
        val _5k = MaterialIcon(
            name = "5k",
            style = MaterialIcon.Style.Round
        )
        val _5kPlus = MaterialIcon(
            name = "5k_plus",
            style = MaterialIcon.Style.Round
        )
        val _5mp = MaterialIcon(
            name = "5mp",
            style = MaterialIcon.Style.Round
        )
        val _60fps = MaterialIcon(
            name = "60fps",
            style = MaterialIcon.Style.Round
        )
        val _60fpsSelect = MaterialIcon(
            name = "60fps_select",
            style = MaterialIcon.Style.Round
        )
        val _6FtApart = MaterialIcon(
            name = "6_ft_apart",
            style = MaterialIcon.Style.Round
        )
        val _6k = MaterialIcon(
            name = "6k",
            style = MaterialIcon.Style.Round
        )
        val _6kPlus = MaterialIcon(
            name = "6k_plus",
            style = MaterialIcon.Style.Round
        )
        val _6mp = MaterialIcon(
            name = "6mp",
            style = MaterialIcon.Style.Round
        )
        val _7k = MaterialIcon(
            name = "7k",
            style = MaterialIcon.Style.Round
        )
        val _7kPlus = MaterialIcon(
            name = "7k_plus",
            style = MaterialIcon.Style.Round
        )
        val _7mp = MaterialIcon(
            name = "7mp",
            style = MaterialIcon.Style.Round
        )
        val _8k = MaterialIcon(
            name = "8k",
            style = MaterialIcon.Style.Round
        )
        val _8kPlus = MaterialIcon(
            name = "8k_plus",
            style = MaterialIcon.Style.Round
        )
        val _8mp = MaterialIcon(
            name = "8mp",
            style = MaterialIcon.Style.Round
        )
        val _9k = MaterialIcon(
            name = "9k",
            style = MaterialIcon.Style.Round
        )
        val _9kPlus = MaterialIcon(
            name = "9k_plus",
            style = MaterialIcon.Style.Round
        )
        val _9mp = MaterialIcon(
            name = "9mp",
            style = MaterialIcon.Style.Round
        )
        val Abc = MaterialIcon(
            name = "abc",
            style = MaterialIcon.Style.Round
        )
        val AcUnit = MaterialIcon(
            name = "ac_unit",
            style = MaterialIcon.Style.Round
        )
        val AccessAlarm = MaterialIcon(
            name = "access_alarm",
            style = MaterialIcon.Style.Round
        )
        val AccessAlarms = MaterialIcon(
            name = "access_alarms",
            style = MaterialIcon.Style.Round
        )
        val AccessTime = MaterialIcon(
            name = "access_time",
            style = MaterialIcon.Style.Round
        )
        val AccessTimeFilled = MaterialIcon(
            name = "access_time_filled",
            style = MaterialIcon.Style.Round
        )
        val Accessibility = MaterialIcon(
            name = "accessibility",
            style = MaterialIcon.Style.Round
        )
        val AccessibilityNew = MaterialIcon(
            name = "accessibility_new",
            style = MaterialIcon.Style.Round
        )
        val Accessible = MaterialIcon(
            name = "accessible",
            style = MaterialIcon.Style.Round
        )
        val AccessibleForward = MaterialIcon(
            name = "accessible_forward",
            style = MaterialIcon.Style.Round
        )
        val AccountBalance = MaterialIcon(
            name = "account_balance",
            style = MaterialIcon.Style.Round
        )
        val AccountBalanceWallet = MaterialIcon(
            name = "account_balance_wallet",
            style = MaterialIcon.Style.Round
        )
        val AccountBox = MaterialIcon(
            name = "account_box",
            style = MaterialIcon.Style.Round
        )
        val AccountCircle = MaterialIcon(
            name = "account_circle",
            style = MaterialIcon.Style.Round
        )
        val AccountTree = MaterialIcon(
            name = "account_tree",
            style = MaterialIcon.Style.Round
        )
        val AdUnits = MaterialIcon(
            name = "ad_units",
            style = MaterialIcon.Style.Round
        )
        val Adb = MaterialIcon(
            name = "adb",
            style = MaterialIcon.Style.Round
        )
        val Add = MaterialIcon(
            name = "add",
            style = MaterialIcon.Style.Round
        )
        val AddAPhoto = MaterialIcon(
            name = "add_a_photo",
            style = MaterialIcon.Style.Round
        )
        val AddAlarm = MaterialIcon(
            name = "add_alarm",
            style = MaterialIcon.Style.Round
        )
        val AddAlert = MaterialIcon(
            name = "add_alert",
            style = MaterialIcon.Style.Round
        )
        val AddBox = MaterialIcon(
            name = "add_box",
            style = MaterialIcon.Style.Round
        )
        val AddBusiness = MaterialIcon(
            name = "add_business",
            style = MaterialIcon.Style.Round
        )
        val AddCard = MaterialIcon(
            name = "add_card",
            style = MaterialIcon.Style.Round
        )
        val AddChart = MaterialIcon(
            name = "add_chart",
            style = MaterialIcon.Style.Round
        )
        val AddCircle = MaterialIcon(
            name = "add_circle",
            style = MaterialIcon.Style.Round
        )
        val AddCircleOutline = MaterialIcon(
            name = "add_circle_outline",
            style = MaterialIcon.Style.Round
        )
        val AddComment = MaterialIcon(
            name = "add_comment",
            style = MaterialIcon.Style.Round
        )
        val AddHome = MaterialIcon(
            name = "add_home",
            style = MaterialIcon.Style.Round
        )
        val AddHomeWork = MaterialIcon(
            name = "add_home_work",
            style = MaterialIcon.Style.Round
        )
        val AddIcCall = MaterialIcon(
            name = "add_ic_call",
            style = MaterialIcon.Style.Round
        )
        val AddLink = MaterialIcon(
            name = "add_link",
            style = MaterialIcon.Style.Round
        )
        val AddLocation = MaterialIcon(
            name = "add_location",
            style = MaterialIcon.Style.Round
        )
        val AddLocationAlt = MaterialIcon(
            name = "add_location_alt",
            style = MaterialIcon.Style.Round
        )
        val AddModerator = MaterialIcon(
            name = "add_moderator",
            style = MaterialIcon.Style.Round
        )
        val AddPhotoAlternate = MaterialIcon(
            name = "add_photo_alternate",
            style = MaterialIcon.Style.Round
        )
        val AddReaction = MaterialIcon(
            name = "add_reaction",
            style = MaterialIcon.Style.Round
        )
        val AddRoad = MaterialIcon(
            name = "add_road",
            style = MaterialIcon.Style.Round
        )
        val AddShoppingCart = MaterialIcon(
            name = "add_shopping_cart",
            style = MaterialIcon.Style.Round
        )
        val AddTask = MaterialIcon(
            name = "add_task",
            style = MaterialIcon.Style.Round
        )
        val AddToDrive = MaterialIcon(
            name = "add_to_drive",
            style = MaterialIcon.Style.Round
        )
        val AddToHomeScreen = MaterialIcon(
            name = "add_to_home_screen",
            style = MaterialIcon.Style.Round
        )
        val AddToPhotos = MaterialIcon(
            name = "add_to_photos",
            style = MaterialIcon.Style.Round
        )
        val AddToQueue = MaterialIcon(
            name = "add_to_queue",
            style = MaterialIcon.Style.Round
        )
        val Addchart = MaterialIcon(
            name = "addchart",
            style = MaterialIcon.Style.Round
        )
        val AdfScanner = MaterialIcon(
            name = "adf_scanner",
            style = MaterialIcon.Style.Round
        )
        val Adjust = MaterialIcon(
            name = "adjust",
            style = MaterialIcon.Style.Round
        )
        val AdminPanelSettings = MaterialIcon(
            name = "admin_panel_settings",
            style = MaterialIcon.Style.Round
        )
        val AdsClick = MaterialIcon(
            name = "ads_click",
            style = MaterialIcon.Style.Round
        )
        val Agriculture = MaterialIcon(
            name = "agriculture",
            style = MaterialIcon.Style.Round
        )
        val Air = MaterialIcon(
            name = "air",
            style = MaterialIcon.Style.Round
        )
        val AirlineSeatFlat = MaterialIcon(
            name = "airline_seat_flat",
            style = MaterialIcon.Style.Round
        )
        val AirlineSeatFlatAngled = MaterialIcon(
            name = "airline_seat_flat_angled",
            style = MaterialIcon.Style.Round
        )
        val AirlineSeatIndividualSuite = MaterialIcon(
            name = "airline_seat_individual_suite",
            style = MaterialIcon.Style.Round
        )
        val AirlineSeatLegroomExtra = MaterialIcon(
            name = "airline_seat_legroom_extra",
            style = MaterialIcon.Style.Round
        )
        val AirlineSeatLegroomNormal = MaterialIcon(
            name = "airline_seat_legroom_normal",
            style = MaterialIcon.Style.Round
        )
        val AirlineSeatLegroomReduced = MaterialIcon(
            name = "airline_seat_legroom_reduced",
            style = MaterialIcon.Style.Round
        )
        val AirlineSeatReclineExtra = MaterialIcon(
            name = "airline_seat_recline_extra",
            style = MaterialIcon.Style.Round
        )
        val AirlineSeatReclineNormal = MaterialIcon(
            name = "airline_seat_recline_normal",
            style = MaterialIcon.Style.Round
        )
        val AirlineStops = MaterialIcon(
            name = "airline_stops",
            style = MaterialIcon.Style.Round
        )
        val Airlines = MaterialIcon(
            name = "airlines",
            style = MaterialIcon.Style.Round
        )
        val AirplaneTicket = MaterialIcon(
            name = "airplane_ticket",
            style = MaterialIcon.Style.Round
        )
        val AirplanemodeActive = MaterialIcon(
            name = "airplanemode_active",
            style = MaterialIcon.Style.Round
        )
        val AirplanemodeInactive = MaterialIcon(
            name = "airplanemode_inactive",
            style = MaterialIcon.Style.Round
        )
        val Airplay = MaterialIcon(
            name = "airplay",
            style = MaterialIcon.Style.Round
        )
        val AirportShuttle = MaterialIcon(
            name = "airport_shuttle",
            style = MaterialIcon.Style.Round
        )
        val Alarm = MaterialIcon(
            name = "alarm",
            style = MaterialIcon.Style.Round
        )
        val AlarmAdd = MaterialIcon(
            name = "alarm_add",
            style = MaterialIcon.Style.Round
        )
        val AlarmOff = MaterialIcon(
            name = "alarm_off",
            style = MaterialIcon.Style.Round
        )
        val AlarmOn = MaterialIcon(
            name = "alarm_on",
            style = MaterialIcon.Style.Round
        )
        val Album = MaterialIcon(
            name = "album",
            style = MaterialIcon.Style.Round
        )
        val AlignHorizontalCenter = MaterialIcon(
            name = "align_horizontal_center",
            style = MaterialIcon.Style.Round
        )
        val AlignHorizontalLeft = MaterialIcon(
            name = "align_horizontal_left",
            style = MaterialIcon.Style.Round
        )
        val AlignHorizontalRight = MaterialIcon(
            name = "align_horizontal_right",
            style = MaterialIcon.Style.Round
        )
        val AlignVerticalBottom = MaterialIcon(
            name = "align_vertical_bottom",
            style = MaterialIcon.Style.Round
        )
        val AlignVerticalCenter = MaterialIcon(
            name = "align_vertical_center",
            style = MaterialIcon.Style.Round
        )
        val AlignVerticalTop = MaterialIcon(
            name = "align_vertical_top",
            style = MaterialIcon.Style.Round
        )
        val AllInbox = MaterialIcon(
            name = "all_inbox",
            style = MaterialIcon.Style.Round
        )
        val AllInclusive = MaterialIcon(
            name = "all_inclusive",
            style = MaterialIcon.Style.Round
        )
        val AllOut = MaterialIcon(
            name = "all_out",
            style = MaterialIcon.Style.Round
        )
        val AltRoute = MaterialIcon(
            name = "alt_route",
            style = MaterialIcon.Style.Round
        )
        val AlternateEmail = MaterialIcon(
            name = "alternate_email",
            style = MaterialIcon.Style.Round
        )
        val Analytics = MaterialIcon(
            name = "analytics",
            style = MaterialIcon.Style.Round
        )
        val Anchor = MaterialIcon(
            name = "anchor",
            style = MaterialIcon.Style.Round
        )
        val Android = MaterialIcon(
            name = "android",
            style = MaterialIcon.Style.Round
        )
        val Animation = MaterialIcon(
            name = "animation",
            style = MaterialIcon.Style.Round
        )
        val Announcement = MaterialIcon(
            name = "announcement",
            style = MaterialIcon.Style.Round
        )
        val Aod = MaterialIcon(
            name = "aod",
            style = MaterialIcon.Style.Round
        )
        val Apartment = MaterialIcon(
            name = "apartment",
            style = MaterialIcon.Style.Round
        )
        val Api = MaterialIcon(
            name = "api",
            style = MaterialIcon.Style.Round
        )
        val AppBlocking = MaterialIcon(
            name = "app_blocking",
            style = MaterialIcon.Style.Round
        )
        val AppRegistration = MaterialIcon(
            name = "app_registration",
            style = MaterialIcon.Style.Round
        )
        val AppSettingsAlt = MaterialIcon(
            name = "app_settings_alt",
            style = MaterialIcon.Style.Round
        )
        val AppShortcut = MaterialIcon(
            name = "app_shortcut",
            style = MaterialIcon.Style.Round
        )
        val Approval = MaterialIcon(
            name = "approval",
            style = MaterialIcon.Style.Round
        )
        val Apps = MaterialIcon(
            name = "apps",
            style = MaterialIcon.Style.Round
        )
        val AppsOutage = MaterialIcon(
            name = "apps_outage",
            style = MaterialIcon.Style.Round
        )
        val Architecture = MaterialIcon(
            name = "architecture",
            style = MaterialIcon.Style.Round
        )
        val Archive = MaterialIcon(
            name = "archive",
            style = MaterialIcon.Style.Round
        )
        val AreaChart = MaterialIcon(
            name = "area_chart",
            style = MaterialIcon.Style.Round
        )
        val ArrowBack = MaterialIcon(
            name = "arrow_back",
            style = MaterialIcon.Style.Round
        )
        val ArrowBackIos = MaterialIcon(
            name = "arrow_back_ios",
            style = MaterialIcon.Style.Round
        )
        val ArrowBackIosNew = MaterialIcon(
            name = "arrow_back_ios_new",
            style = MaterialIcon.Style.Round
        )
        val ArrowCircleDown = MaterialIcon(
            name = "arrow_circle_down",
            style = MaterialIcon.Style.Round
        )
        val ArrowCircleLeft = MaterialIcon(
            name = "arrow_circle_left",
            style = MaterialIcon.Style.Round
        )
        val ArrowCircleRight = MaterialIcon(
            name = "arrow_circle_right",
            style = MaterialIcon.Style.Round
        )
        val ArrowCircleUp = MaterialIcon(
            name = "arrow_circle_up",
            style = MaterialIcon.Style.Round
        )
        val ArrowDownward = MaterialIcon(
            name = "arrow_downward",
            style = MaterialIcon.Style.Round
        )
        val ArrowDropDown = MaterialIcon(
            name = "arrow_drop_down",
            style = MaterialIcon.Style.Round
        )
        val ArrowDropDownCircle = MaterialIcon(
            name = "arrow_drop_down_circle",
            style = MaterialIcon.Style.Round
        )
        val ArrowDropUp = MaterialIcon(
            name = "arrow_drop_up",
            style = MaterialIcon.Style.Round
        )
        val ArrowForward = MaterialIcon(
            name = "arrow_forward",
            style = MaterialIcon.Style.Round
        )
        val ArrowForwardIos = MaterialIcon(
            name = "arrow_forward_ios",
            style = MaterialIcon.Style.Round
        )
        val ArrowLeft = MaterialIcon(
            name = "arrow_left",
            style = MaterialIcon.Style.Round
        )
        val ArrowOutward = MaterialIcon(
            name = "arrow_outward",
            style = MaterialIcon.Style.Round
        )
        val ArrowRight = MaterialIcon(
            name = "arrow_right",
            style = MaterialIcon.Style.Round
        )
        val ArrowRightAlt = MaterialIcon(
            name = "arrow_right_alt",
            style = MaterialIcon.Style.Round
        )
        val ArrowUpward = MaterialIcon(
            name = "arrow_upward",
            style = MaterialIcon.Style.Round
        )
        val ArtTrack = MaterialIcon(
            name = "art_track",
            style = MaterialIcon.Style.Round
        )
        val Article = MaterialIcon(
            name = "article",
            style = MaterialIcon.Style.Round
        )
        val AspectRatio = MaterialIcon(
            name = "aspect_ratio",
            style = MaterialIcon.Style.Round
        )
        val Assessment = MaterialIcon(
            name = "assessment",
            style = MaterialIcon.Style.Round
        )
        val Assignment = MaterialIcon(
            name = "assignment",
            style = MaterialIcon.Style.Round
        )
        val AssignmentInd = MaterialIcon(
            name = "assignment_ind",
            style = MaterialIcon.Style.Round
        )
        val AssignmentLate = MaterialIcon(
            name = "assignment_late",
            style = MaterialIcon.Style.Round
        )
        val AssignmentReturn = MaterialIcon(
            name = "assignment_return",
            style = MaterialIcon.Style.Round
        )
        val AssignmentReturned = MaterialIcon(
            name = "assignment_returned",
            style = MaterialIcon.Style.Round
        )
        val AssignmentTurnedIn = MaterialIcon(
            name = "assignment_turned_in",
            style = MaterialIcon.Style.Round
        )
        val AssistWalker = MaterialIcon(
            name = "assist_walker",
            style = MaterialIcon.Style.Round
        )
        val Assistant = MaterialIcon(
            name = "assistant",
            style = MaterialIcon.Style.Round
        )
        val AssistantDirection = MaterialIcon(
            name = "assistant_direction",
            style = MaterialIcon.Style.Round
        )
        val AssistantPhoto = MaterialIcon(
            name = "assistant_photo",
            style = MaterialIcon.Style.Round
        )
        val AssuredWorkload = MaterialIcon(
            name = "assured_workload",
            style = MaterialIcon.Style.Round
        )
        val Atm = MaterialIcon(
            name = "atm",
            style = MaterialIcon.Style.Round
        )
        val AttachEmail = MaterialIcon(
            name = "attach_email",
            style = MaterialIcon.Style.Round
        )
        val AttachFile = MaterialIcon(
            name = "attach_file",
            style = MaterialIcon.Style.Round
        )
        val AttachMoney = MaterialIcon(
            name = "attach_money",
            style = MaterialIcon.Style.Round
        )
        val Attachment = MaterialIcon(
            name = "attachment",
            style = MaterialIcon.Style.Round
        )
        val Attractions = MaterialIcon(
            name = "attractions",
            style = MaterialIcon.Style.Round
        )
        val Attribution = MaterialIcon(
            name = "attribution",
            style = MaterialIcon.Style.Round
        )
        val AudioFile = MaterialIcon(
            name = "audio_file",
            style = MaterialIcon.Style.Round
        )
        val Audiotrack = MaterialIcon(
            name = "audiotrack",
            style = MaterialIcon.Style.Round
        )
        val AutoAwesome = MaterialIcon(
            name = "auto_awesome",
            style = MaterialIcon.Style.Round
        )
        val AutoAwesomeMosaic = MaterialIcon(
            name = "auto_awesome_mosaic",
            style = MaterialIcon.Style.Round
        )
        val AutoAwesomeMotion = MaterialIcon(
            name = "auto_awesome_motion",
            style = MaterialIcon.Style.Round
        )
        val AutoDelete = MaterialIcon(
            name = "auto_delete",
            style = MaterialIcon.Style.Round
        )
        val AutoFixHigh = MaterialIcon(
            name = "auto_fix_high",
            style = MaterialIcon.Style.Round
        )
        val AutoFixNormal = MaterialIcon(
            name = "auto_fix_normal",
            style = MaterialIcon.Style.Round
        )
        val AutoFixOff = MaterialIcon(
            name = "auto_fix_off",
            style = MaterialIcon.Style.Round
        )
        val AutoGraph = MaterialIcon(
            name = "auto_graph",
            style = MaterialIcon.Style.Round
        )
        val AutoMode = MaterialIcon(
            name = "auto_mode",
            style = MaterialIcon.Style.Round
        )
        val AutoStories = MaterialIcon(
            name = "auto_stories",
            style = MaterialIcon.Style.Round
        )
        val AutofpsSelect = MaterialIcon(
            name = "autofps_select",
            style = MaterialIcon.Style.Round
        )
        val Autorenew = MaterialIcon(
            name = "autorenew",
            style = MaterialIcon.Style.Round
        )
        val AvTimer = MaterialIcon(
            name = "av_timer",
            style = MaterialIcon.Style.Round
        )
        val BabyChangingStation = MaterialIcon(
            name = "baby_changing_station",
            style = MaterialIcon.Style.Round
        )
        val BackHand = MaterialIcon(
            name = "back_hand",
            style = MaterialIcon.Style.Round
        )
        val Backpack = MaterialIcon(
            name = "backpack",
            style = MaterialIcon.Style.Round
        )
        val Backspace = MaterialIcon(
            name = "backspace",
            style = MaterialIcon.Style.Round
        )
        val Backup = MaterialIcon(
            name = "backup",
            style = MaterialIcon.Style.Round
        )
        val BackupTable = MaterialIcon(
            name = "backup_table",
            style = MaterialIcon.Style.Round
        )
        val Badge = MaterialIcon(
            name = "badge",
            style = MaterialIcon.Style.Round
        )
        val BakeryDining = MaterialIcon(
            name = "bakery_dining",
            style = MaterialIcon.Style.Round
        )
        val Balance = MaterialIcon(
            name = "balance",
            style = MaterialIcon.Style.Round
        )
        val Balcony = MaterialIcon(
            name = "balcony",
            style = MaterialIcon.Style.Round
        )
        val Ballot = MaterialIcon(
            name = "ballot",
            style = MaterialIcon.Style.Round
        )
        val BarChart = MaterialIcon(
            name = "bar_chart",
            style = MaterialIcon.Style.Round
        )
        val BatchPrediction = MaterialIcon(
            name = "batch_prediction",
            style = MaterialIcon.Style.Round
        )
        val Bathroom = MaterialIcon(
            name = "bathroom",
            style = MaterialIcon.Style.Round
        )
        val Bathtub = MaterialIcon(
            name = "bathtub",
            style = MaterialIcon.Style.Round
        )
        val Battery0Bar = MaterialIcon(
            name = "battery_0_bar",
            style = MaterialIcon.Style.Round
        )
        val Battery1Bar = MaterialIcon(
            name = "battery_1_bar",
            style = MaterialIcon.Style.Round
        )
        val Battery2Bar = MaterialIcon(
            name = "battery_2_bar",
            style = MaterialIcon.Style.Round
        )
        val Battery3Bar = MaterialIcon(
            name = "battery_3_bar",
            style = MaterialIcon.Style.Round
        )
        val Battery4Bar = MaterialIcon(
            name = "battery_4_bar",
            style = MaterialIcon.Style.Round
        )
        val Battery5Bar = MaterialIcon(
            name = "battery_5_bar",
            style = MaterialIcon.Style.Round
        )
        val Battery6Bar = MaterialIcon(
            name = "battery_6_bar",
            style = MaterialIcon.Style.Round
        )
        val BatteryAlert = MaterialIcon(
            name = "battery_alert",
            style = MaterialIcon.Style.Round
        )
        val BatteryChargingFull = MaterialIcon(
            name = "battery_charging_full",
            style = MaterialIcon.Style.Round
        )
        val BatteryFull = MaterialIcon(
            name = "battery_full",
            style = MaterialIcon.Style.Round
        )
        val BatterySaver = MaterialIcon(
            name = "battery_saver",
            style = MaterialIcon.Style.Round
        )
        val BatteryStd = MaterialIcon(
            name = "battery_std",
            style = MaterialIcon.Style.Round
        )
        val BatteryUnknown = MaterialIcon(
            name = "battery_unknown",
            style = MaterialIcon.Style.Round
        )
        val BeachAccess = MaterialIcon(
            name = "beach_access",
            style = MaterialIcon.Style.Round
        )
        val Bed = MaterialIcon(
            name = "bed",
            style = MaterialIcon.Style.Round
        )
        val BedroomBaby = MaterialIcon(
            name = "bedroom_baby",
            style = MaterialIcon.Style.Round
        )
        val BedroomChild = MaterialIcon(
            name = "bedroom_child",
            style = MaterialIcon.Style.Round
        )
        val BedroomParent = MaterialIcon(
            name = "bedroom_parent",
            style = MaterialIcon.Style.Round
        )
        val Bedtime = MaterialIcon(
            name = "bedtime",
            style = MaterialIcon.Style.Round
        )
        val BedtimeOff = MaterialIcon(
            name = "bedtime_off",
            style = MaterialIcon.Style.Round
        )
        val Beenhere = MaterialIcon(
            name = "beenhere",
            style = MaterialIcon.Style.Round
        )
        val Bento = MaterialIcon(
            name = "bento",
            style = MaterialIcon.Style.Round
        )
        val BikeScooter = MaterialIcon(
            name = "bike_scooter",
            style = MaterialIcon.Style.Round
        )
        val Biotech = MaterialIcon(
            name = "biotech",
            style = MaterialIcon.Style.Round
        )
        val Blender = MaterialIcon(
            name = "blender",
            style = MaterialIcon.Style.Round
        )
        val Blind = MaterialIcon(
            name = "blind",
            style = MaterialIcon.Style.Round
        )
        val Blinds = MaterialIcon(
            name = "blinds",
            style = MaterialIcon.Style.Round
        )
        val BlindsClosed = MaterialIcon(
            name = "blinds_closed",
            style = MaterialIcon.Style.Round
        )
        val Block = MaterialIcon(
            name = "block",
            style = MaterialIcon.Style.Round
        )
        val Bloodtype = MaterialIcon(
            name = "bloodtype",
            style = MaterialIcon.Style.Round
        )
        val Bluetooth = MaterialIcon(
            name = "bluetooth",
            style = MaterialIcon.Style.Round
        )
        val BluetoothAudio = MaterialIcon(
            name = "bluetooth_audio",
            style = MaterialIcon.Style.Round
        )
        val BluetoothConnected = MaterialIcon(
            name = "bluetooth_connected",
            style = MaterialIcon.Style.Round
        )
        val BluetoothDisabled = MaterialIcon(
            name = "bluetooth_disabled",
            style = MaterialIcon.Style.Round
        )
        val BluetoothDrive = MaterialIcon(
            name = "bluetooth_drive",
            style = MaterialIcon.Style.Round
        )
        val BluetoothSearching = MaterialIcon(
            name = "bluetooth_searching",
            style = MaterialIcon.Style.Round
        )
        val BlurCircular = MaterialIcon(
            name = "blur_circular",
            style = MaterialIcon.Style.Round
        )
        val BlurLinear = MaterialIcon(
            name = "blur_linear",
            style = MaterialIcon.Style.Round
        )
        val BlurOff = MaterialIcon(
            name = "blur_off",
            style = MaterialIcon.Style.Round
        )
        val BlurOn = MaterialIcon(
            name = "blur_on",
            style = MaterialIcon.Style.Round
        )
        val Bolt = MaterialIcon(
            name = "bolt",
            style = MaterialIcon.Style.Round
        )
        val Book = MaterialIcon(
            name = "book",
            style = MaterialIcon.Style.Round
        )
        val BookOnline = MaterialIcon(
            name = "book_online",
            style = MaterialIcon.Style.Round
        )
        val Bookmark = MaterialIcon(
            name = "bookmark",
            style = MaterialIcon.Style.Round
        )
        val BookmarkAdd = MaterialIcon(
            name = "bookmark_add",
            style = MaterialIcon.Style.Round
        )
        val BookmarkAdded = MaterialIcon(
            name = "bookmark_added",
            style = MaterialIcon.Style.Round
        )
        val BookmarkBorder = MaterialIcon(
            name = "bookmark_border",
            style = MaterialIcon.Style.Round
        )
        val BookmarkRemove = MaterialIcon(
            name = "bookmark_remove",
            style = MaterialIcon.Style.Round
        )
        val Bookmarks = MaterialIcon(
            name = "bookmarks",
            style = MaterialIcon.Style.Round
        )
        val BorderAll = MaterialIcon(
            name = "border_all",
            style = MaterialIcon.Style.Round
        )
        val BorderBottom = MaterialIcon(
            name = "border_bottom",
            style = MaterialIcon.Style.Round
        )
        val BorderClear = MaterialIcon(
            name = "border_clear",
            style = MaterialIcon.Style.Round
        )
        val BorderColor = MaterialIcon(
            name = "border_color",
            style = MaterialIcon.Style.Round
        )
        val BorderHorizontal = MaterialIcon(
            name = "border_horizontal",
            style = MaterialIcon.Style.Round
        )
        val BorderInner = MaterialIcon(
            name = "border_inner",
            style = MaterialIcon.Style.Round
        )
        val BorderLeft = MaterialIcon(
            name = "border_left",
            style = MaterialIcon.Style.Round
        )
        val BorderOuter = MaterialIcon(
            name = "border_outer",
            style = MaterialIcon.Style.Round
        )
        val BorderRight = MaterialIcon(
            name = "border_right",
            style = MaterialIcon.Style.Round
        )
        val BorderStyle = MaterialIcon(
            name = "border_style",
            style = MaterialIcon.Style.Round
        )
        val BorderTop = MaterialIcon(
            name = "border_top",
            style = MaterialIcon.Style.Round
        )
        val BorderVertical = MaterialIcon(
            name = "border_vertical",
            style = MaterialIcon.Style.Round
        )
        val Boy = MaterialIcon(
            name = "boy",
            style = MaterialIcon.Style.Round
        )
        val BrandingWatermark = MaterialIcon(
            name = "branding_watermark",
            style = MaterialIcon.Style.Round
        )
        val BreakfastDining = MaterialIcon(
            name = "breakfast_dining",
            style = MaterialIcon.Style.Round
        )
        val Brightness1 = MaterialIcon(
            name = "brightness_1",
            style = MaterialIcon.Style.Round
        )
        val Brightness2 = MaterialIcon(
            name = "brightness_2",
            style = MaterialIcon.Style.Round
        )
        val Brightness3 = MaterialIcon(
            name = "brightness_3",
            style = MaterialIcon.Style.Round
        )
        val Brightness4 = MaterialIcon(
            name = "brightness_4",
            style = MaterialIcon.Style.Round
        )
        val Brightness5 = MaterialIcon(
            name = "brightness_5",
            style = MaterialIcon.Style.Round
        )
        val Brightness6 = MaterialIcon(
            name = "brightness_6",
            style = MaterialIcon.Style.Round
        )
        val Brightness7 = MaterialIcon(
            name = "brightness_7",
            style = MaterialIcon.Style.Round
        )
        val BrightnessAuto = MaterialIcon(
            name = "brightness_auto",
            style = MaterialIcon.Style.Round
        )
        val BrightnessHigh = MaterialIcon(
            name = "brightness_high",
            style = MaterialIcon.Style.Round
        )
        val BrightnessLow = MaterialIcon(
            name = "brightness_low",
            style = MaterialIcon.Style.Round
        )
        val BrightnessMedium = MaterialIcon(
            name = "brightness_medium",
            style = MaterialIcon.Style.Round
        )
        val BroadcastOnHome = MaterialIcon(
            name = "broadcast_on_home",
            style = MaterialIcon.Style.Round
        )
        val BroadcastOnPersonal = MaterialIcon(
            name = "broadcast_on_personal",
            style = MaterialIcon.Style.Round
        )
        val BrokenImage = MaterialIcon(
            name = "broken_image",
            style = MaterialIcon.Style.Round
        )
        val BrowseGallery = MaterialIcon(
            name = "browse_gallery",
            style = MaterialIcon.Style.Round
        )
        val BrowserNotSupported = MaterialIcon(
            name = "browser_not_supported",
            style = MaterialIcon.Style.Round
        )
        val BrowserUpdated = MaterialIcon(
            name = "browser_updated",
            style = MaterialIcon.Style.Round
        )
        val BrunchDining = MaterialIcon(
            name = "brunch_dining",
            style = MaterialIcon.Style.Round
        )
        val Brush = MaterialIcon(
            name = "brush",
            style = MaterialIcon.Style.Round
        )
        val BubbleChart = MaterialIcon(
            name = "bubble_chart",
            style = MaterialIcon.Style.Round
        )
        val BugReport = MaterialIcon(
            name = "bug_report",
            style = MaterialIcon.Style.Round
        )
        val Build = MaterialIcon(
            name = "build",
            style = MaterialIcon.Style.Round
        )
        val BuildCircle = MaterialIcon(
            name = "build_circle",
            style = MaterialIcon.Style.Round
        )
        val Bungalow = MaterialIcon(
            name = "bungalow",
            style = MaterialIcon.Style.Round
        )
        val BurstMode = MaterialIcon(
            name = "burst_mode",
            style = MaterialIcon.Style.Round
        )
        val BusAlert = MaterialIcon(
            name = "bus_alert",
            style = MaterialIcon.Style.Round
        )
        val Business = MaterialIcon(
            name = "business",
            style = MaterialIcon.Style.Round
        )
        val BusinessCenter = MaterialIcon(
            name = "business_center",
            style = MaterialIcon.Style.Round
        )
        val Cabin = MaterialIcon(
            name = "cabin",
            style = MaterialIcon.Style.Round
        )
        val Cable = MaterialIcon(
            name = "cable",
            style = MaterialIcon.Style.Round
        )
        val Cached = MaterialIcon(
            name = "cached",
            style = MaterialIcon.Style.Round
        )
        val Cake = MaterialIcon(
            name = "cake",
            style = MaterialIcon.Style.Round
        )
        val Calculate = MaterialIcon(
            name = "calculate",
            style = MaterialIcon.Style.Round
        )
        val CalendarMonth = MaterialIcon(
            name = "calendar_month",
            style = MaterialIcon.Style.Round
        )
        val CalendarToday = MaterialIcon(
            name = "calendar_today",
            style = MaterialIcon.Style.Round
        )
        val CalendarViewDay = MaterialIcon(
            name = "calendar_view_day",
            style = MaterialIcon.Style.Round
        )
        val CalendarViewMonth = MaterialIcon(
            name = "calendar_view_month",
            style = MaterialIcon.Style.Round
        )
        val CalendarViewWeek = MaterialIcon(
            name = "calendar_view_week",
            style = MaterialIcon.Style.Round
        )
        val Call = MaterialIcon(
            name = "call",
            style = MaterialIcon.Style.Round
        )
        val CallEnd = MaterialIcon(
            name = "call_end",
            style = MaterialIcon.Style.Round
        )
        val CallMade = MaterialIcon(
            name = "call_made",
            style = MaterialIcon.Style.Round
        )
        val CallMerge = MaterialIcon(
            name = "call_merge",
            style = MaterialIcon.Style.Round
        )
        val CallMissed = MaterialIcon(
            name = "call_missed",
            style = MaterialIcon.Style.Round
        )
        val CallMissedOutgoing = MaterialIcon(
            name = "call_missed_outgoing",
            style = MaterialIcon.Style.Round
        )
        val CallReceived = MaterialIcon(
            name = "call_received",
            style = MaterialIcon.Style.Round
        )
        val CallSplit = MaterialIcon(
            name = "call_split",
            style = MaterialIcon.Style.Round
        )
        val CallToAction = MaterialIcon(
            name = "call_to_action",
            style = MaterialIcon.Style.Round
        )
        val Camera = MaterialIcon(
            name = "camera",
            style = MaterialIcon.Style.Round
        )
        val CameraAlt = MaterialIcon(
            name = "camera_alt",
            style = MaterialIcon.Style.Round
        )
        val CameraEnhance = MaterialIcon(
            name = "camera_enhance",
            style = MaterialIcon.Style.Round
        )
        val CameraFront = MaterialIcon(
            name = "camera_front",
            style = MaterialIcon.Style.Round
        )
        val CameraIndoor = MaterialIcon(
            name = "camera_indoor",
            style = MaterialIcon.Style.Round
        )
        val CameraOutdoor = MaterialIcon(
            name = "camera_outdoor",
            style = MaterialIcon.Style.Round
        )
        val CameraRear = MaterialIcon(
            name = "camera_rear",
            style = MaterialIcon.Style.Round
        )
        val CameraRoll = MaterialIcon(
            name = "camera_roll",
            style = MaterialIcon.Style.Round
        )
        val Cameraswitch = MaterialIcon(
            name = "cameraswitch",
            style = MaterialIcon.Style.Round
        )
        val Campaign = MaterialIcon(
            name = "campaign",
            style = MaterialIcon.Style.Round
        )
        val Cancel = MaterialIcon(
            name = "cancel",
            style = MaterialIcon.Style.Round
        )
        val CancelPresentation = MaterialIcon(
            name = "cancel_presentation",
            style = MaterialIcon.Style.Round
        )
        val CancelScheduleSend = MaterialIcon(
            name = "cancel_schedule_send",
            style = MaterialIcon.Style.Round
        )
        val CandlestickChart = MaterialIcon(
            name = "candlestick_chart",
            style = MaterialIcon.Style.Round
        )
        val CarCrash = MaterialIcon(
            name = "car_crash",
            style = MaterialIcon.Style.Round
        )
        val CarRental = MaterialIcon(
            name = "car_rental",
            style = MaterialIcon.Style.Round
        )
        val CarRepair = MaterialIcon(
            name = "car_repair",
            style = MaterialIcon.Style.Round
        )
        val CardGiftcard = MaterialIcon(
            name = "card_giftcard",
            style = MaterialIcon.Style.Round
        )
        val CardMembership = MaterialIcon(
            name = "card_membership",
            style = MaterialIcon.Style.Round
        )
        val CardTravel = MaterialIcon(
            name = "card_travel",
            style = MaterialIcon.Style.Round
        )
        val Carpenter = MaterialIcon(
            name = "carpenter",
            style = MaterialIcon.Style.Round
        )
        val Cases = MaterialIcon(
            name = "cases",
            style = MaterialIcon.Style.Round
        )
        val Casino = MaterialIcon(
            name = "casino",
            style = MaterialIcon.Style.Round
        )
        val Cast = MaterialIcon(
            name = "cast",
            style = MaterialIcon.Style.Round
        )
        val CastConnected = MaterialIcon(
            name = "cast_connected",
            style = MaterialIcon.Style.Round
        )
        val CastForEducation = MaterialIcon(
            name = "cast_for_education",
            style = MaterialIcon.Style.Round
        )
        val Castle = MaterialIcon(
            name = "castle",
            style = MaterialIcon.Style.Round
        )
        val CatchingPokemon = MaterialIcon(
            name = "catching_pokemon",
            style = MaterialIcon.Style.Round
        )
        val Category = MaterialIcon(
            name = "category",
            style = MaterialIcon.Style.Round
        )
        val Celebration = MaterialIcon(
            name = "celebration",
            style = MaterialIcon.Style.Round
        )
        val CellTower = MaterialIcon(
            name = "cell_tower",
            style = MaterialIcon.Style.Round
        )
        val CellWifi = MaterialIcon(
            name = "cell_wifi",
            style = MaterialIcon.Style.Round
        )
        val CenterFocusStrong = MaterialIcon(
            name = "center_focus_strong",
            style = MaterialIcon.Style.Round
        )
        val CenterFocusWeak = MaterialIcon(
            name = "center_focus_weak",
            style = MaterialIcon.Style.Round
        )
        val Chair = MaterialIcon(
            name = "chair",
            style = MaterialIcon.Style.Round
        )
        val ChairAlt = MaterialIcon(
            name = "chair_alt",
            style = MaterialIcon.Style.Round
        )
        val Chalet = MaterialIcon(
            name = "chalet",
            style = MaterialIcon.Style.Round
        )
        val ChangeCircle = MaterialIcon(
            name = "change_circle",
            style = MaterialIcon.Style.Round
        )
        val ChangeHistory = MaterialIcon(
            name = "change_history",
            style = MaterialIcon.Style.Round
        )
        val ChargingStation = MaterialIcon(
            name = "charging_station",
            style = MaterialIcon.Style.Round
        )
        val Chat = MaterialIcon(
            name = "chat",
            style = MaterialIcon.Style.Round
        )
        val ChatBubble = MaterialIcon(
            name = "chat_bubble",
            style = MaterialIcon.Style.Round
        )
        val ChatBubbleOutline = MaterialIcon(
            name = "chat_bubble_outline",
            style = MaterialIcon.Style.Round
        )
        val Check = MaterialIcon(
            name = "check",
            style = MaterialIcon.Style.Round
        )
        val CheckBox = MaterialIcon(
            name = "check_box",
            style = MaterialIcon.Style.Round
        )
        val CheckBoxOutlineBlank = MaterialIcon(
            name = "check_box_outline_blank",
            style = MaterialIcon.Style.Round
        )
        val CheckCircle = MaterialIcon(
            name = "check_circle",
            style = MaterialIcon.Style.Round
        )
        val CheckCircleOutline = MaterialIcon(
            name = "check_circle_outline",
            style = MaterialIcon.Style.Round
        )
        val Checklist = MaterialIcon(
            name = "checklist",
            style = MaterialIcon.Style.Round
        )
        val ChecklistRtl = MaterialIcon(
            name = "checklist_rtl",
            style = MaterialIcon.Style.Round
        )
        val Checkroom = MaterialIcon(
            name = "checkroom",
            style = MaterialIcon.Style.Round
        )
        val ChevronLeft = MaterialIcon(
            name = "chevron_left",
            style = MaterialIcon.Style.Round
        )
        val ChevronRight = MaterialIcon(
            name = "chevron_right",
            style = MaterialIcon.Style.Round
        )
        val ChildCare = MaterialIcon(
            name = "child_care",
            style = MaterialIcon.Style.Round
        )
        val ChildFriendly = MaterialIcon(
            name = "child_friendly",
            style = MaterialIcon.Style.Round
        )
        val ChromeReaderMode = MaterialIcon(
            name = "chrome_reader_mode",
            style = MaterialIcon.Style.Round
        )
        val Church = MaterialIcon(
            name = "church",
            style = MaterialIcon.Style.Round
        )
        val Circle = MaterialIcon(
            name = "circle",
            style = MaterialIcon.Style.Round
        )
        val CircleNotifications = MaterialIcon(
            name = "circle_notifications",
            style = MaterialIcon.Style.Round
        )
        val Class = MaterialIcon(
            name = "class",
            style = MaterialIcon.Style.Round
        )
        val CleanHands = MaterialIcon(
            name = "clean_hands",
            style = MaterialIcon.Style.Round
        )
        val CleaningServices = MaterialIcon(
            name = "cleaning_services",
            style = MaterialIcon.Style.Round
        )
        val Clear = MaterialIcon(
            name = "clear",
            style = MaterialIcon.Style.Round
        )
        val ClearAll = MaterialIcon(
            name = "clear_all",
            style = MaterialIcon.Style.Round
        )
        val Close = MaterialIcon(
            name = "close",
            style = MaterialIcon.Style.Round
        )
        val CloseFullscreen = MaterialIcon(
            name = "close_fullscreen",
            style = MaterialIcon.Style.Round
        )
        val ClosedCaption = MaterialIcon(
            name = "closed_caption",
            style = MaterialIcon.Style.Round
        )
        val ClosedCaptionDisabled = MaterialIcon(
            name = "closed_caption_disabled",
            style = MaterialIcon.Style.Round
        )
        val ClosedCaptionOff = MaterialIcon(
            name = "closed_caption_off",
            style = MaterialIcon.Style.Round
        )
        val Cloud = MaterialIcon(
            name = "cloud",
            style = MaterialIcon.Style.Round
        )
        val CloudCircle = MaterialIcon(
            name = "cloud_circle",
            style = MaterialIcon.Style.Round
        )
        val CloudDone = MaterialIcon(
            name = "cloud_done",
            style = MaterialIcon.Style.Round
        )
        val CloudDownload = MaterialIcon(
            name = "cloud_download",
            style = MaterialIcon.Style.Round
        )
        val CloudOff = MaterialIcon(
            name = "cloud_off",
            style = MaterialIcon.Style.Round
        )
        val CloudQueue = MaterialIcon(
            name = "cloud_queue",
            style = MaterialIcon.Style.Round
        )
        val CloudSync = MaterialIcon(
            name = "cloud_sync",
            style = MaterialIcon.Style.Round
        )
        val CloudUpload = MaterialIcon(
            name = "cloud_upload",
            style = MaterialIcon.Style.Round
        )
        val Co2 = MaterialIcon(
            name = "co2",
            style = MaterialIcon.Style.Round
        )
        val CoPresent = MaterialIcon(
            name = "co_present",
            style = MaterialIcon.Style.Round
        )
        val Code = MaterialIcon(
            name = "code",
            style = MaterialIcon.Style.Round
        )
        val CodeOff = MaterialIcon(
            name = "code_off",
            style = MaterialIcon.Style.Round
        )
        val Coffee = MaterialIcon(
            name = "coffee",
            style = MaterialIcon.Style.Round
        )
        val CoffeeMaker = MaterialIcon(
            name = "coffee_maker",
            style = MaterialIcon.Style.Round
        )
        val Collections = MaterialIcon(
            name = "collections",
            style = MaterialIcon.Style.Round
        )
        val CollectionsBookmark = MaterialIcon(
            name = "collections_bookmark",
            style = MaterialIcon.Style.Round
        )
        val ColorLens = MaterialIcon(
            name = "color_lens",
            style = MaterialIcon.Style.Round
        )
        val Colorize = MaterialIcon(
            name = "colorize",
            style = MaterialIcon.Style.Round
        )
        val Comment = MaterialIcon(
            name = "comment",
            style = MaterialIcon.Style.Round
        )
        val CommentBank = MaterialIcon(
            name = "comment_bank",
            style = MaterialIcon.Style.Round
        )
        val CommentsDisabled = MaterialIcon(
            name = "comments_disabled",
            style = MaterialIcon.Style.Round
        )
        val Commit = MaterialIcon(
            name = "commit",
            style = MaterialIcon.Style.Round
        )
        val Commute = MaterialIcon(
            name = "commute",
            style = MaterialIcon.Style.Round
        )
        val Compare = MaterialIcon(
            name = "compare",
            style = MaterialIcon.Style.Round
        )
        val CompareArrows = MaterialIcon(
            name = "compare_arrows",
            style = MaterialIcon.Style.Round
        )
        val CompassCalibration = MaterialIcon(
            name = "compass_calibration",
            style = MaterialIcon.Style.Round
        )
        val Compost = MaterialIcon(
            name = "compost",
            style = MaterialIcon.Style.Round
        )
        val Compress = MaterialIcon(
            name = "compress",
            style = MaterialIcon.Style.Round
        )
        val Computer = MaterialIcon(
            name = "computer",
            style = MaterialIcon.Style.Round
        )
        val ConfirmationNumber = MaterialIcon(
            name = "confirmation_number",
            style = MaterialIcon.Style.Round
        )
        val ConnectWithoutContact = MaterialIcon(
            name = "connect_without_contact",
            style = MaterialIcon.Style.Round
        )
        val ConnectedTv = MaterialIcon(
            name = "connected_tv",
            style = MaterialIcon.Style.Round
        )
        val ConnectingAirports = MaterialIcon(
            name = "connecting_airports",
            style = MaterialIcon.Style.Round
        )
        val Construction = MaterialIcon(
            name = "construction",
            style = MaterialIcon.Style.Round
        )
        val ContactEmergency = MaterialIcon(
            name = "contact_emergency",
            style = MaterialIcon.Style.Round
        )
        val ContactMail = MaterialIcon(
            name = "contact_mail",
            style = MaterialIcon.Style.Round
        )
        val ContactPage = MaterialIcon(
            name = "contact_page",
            style = MaterialIcon.Style.Round
        )
        val ContactPhone = MaterialIcon(
            name = "contact_phone",
            style = MaterialIcon.Style.Round
        )
        val ContactSupport = MaterialIcon(
            name = "contact_support",
            style = MaterialIcon.Style.Round
        )
        val Contactless = MaterialIcon(
            name = "contactless",
            style = MaterialIcon.Style.Round
        )
        val Contacts = MaterialIcon(
            name = "contacts",
            style = MaterialIcon.Style.Round
        )
        val ContentCopy = MaterialIcon(
            name = "content_copy",
            style = MaterialIcon.Style.Round
        )
        val ContentCut = MaterialIcon(
            name = "content_cut",
            style = MaterialIcon.Style.Round
        )
        val ContentPaste = MaterialIcon(
            name = "content_paste",
            style = MaterialIcon.Style.Round
        )
        val ContentPasteGo = MaterialIcon(
            name = "content_paste_go",
            style = MaterialIcon.Style.Round
        )
        val ContentPasteOff = MaterialIcon(
            name = "content_paste_off",
            style = MaterialIcon.Style.Round
        )
        val ContentPasteSearch = MaterialIcon(
            name = "content_paste_search",
            style = MaterialIcon.Style.Round
        )
        val Contrast = MaterialIcon(
            name = "contrast",
            style = MaterialIcon.Style.Round
        )
        val ControlCamera = MaterialIcon(
            name = "control_camera",
            style = MaterialIcon.Style.Round
        )
        val ControlPoint = MaterialIcon(
            name = "control_point",
            style = MaterialIcon.Style.Round
        )
        val ControlPointDuplicate = MaterialIcon(
            name = "control_point_duplicate",
            style = MaterialIcon.Style.Round
        )
        val Cookie = MaterialIcon(
            name = "cookie",
            style = MaterialIcon.Style.Round
        )
        val CopyAll = MaterialIcon(
            name = "copy_all",
            style = MaterialIcon.Style.Round
        )
        val Copyright = MaterialIcon(
            name = "copyright",
            style = MaterialIcon.Style.Round
        )
        val Coronavirus = MaterialIcon(
            name = "coronavirus",
            style = MaterialIcon.Style.Round
        )
        val CorporateFare = MaterialIcon(
            name = "corporate_fare",
            style = MaterialIcon.Style.Round
        )
        val Cottage = MaterialIcon(
            name = "cottage",
            style = MaterialIcon.Style.Round
        )
        val Countertops = MaterialIcon(
            name = "countertops",
            style = MaterialIcon.Style.Round
        )
        val Create = MaterialIcon(
            name = "create",
            style = MaterialIcon.Style.Round
        )
        val CreateNewFolder = MaterialIcon(
            name = "create_new_folder",
            style = MaterialIcon.Style.Round
        )
        val CreditCard = MaterialIcon(
            name = "credit_card",
            style = MaterialIcon.Style.Round
        )
        val CreditCardOff = MaterialIcon(
            name = "credit_card_off",
            style = MaterialIcon.Style.Round
        )
        val CreditScore = MaterialIcon(
            name = "credit_score",
            style = MaterialIcon.Style.Round
        )
        val Crib = MaterialIcon(
            name = "crib",
            style = MaterialIcon.Style.Round
        )
        val CrisisAlert = MaterialIcon(
            name = "crisis_alert",
            style = MaterialIcon.Style.Round
        )
        val Crop = MaterialIcon(
            name = "crop",
            style = MaterialIcon.Style.Round
        )
        val Crop169 = MaterialIcon(
            name = "crop_16_9",
            style = MaterialIcon.Style.Round
        )
        val Crop32 = MaterialIcon(
            name = "crop_3_2",
            style = MaterialIcon.Style.Round
        )
        val Crop54 = MaterialIcon(
            name = "crop_5_4",
            style = MaterialIcon.Style.Round
        )
        val Crop75 = MaterialIcon(
            name = "crop_7_5",
            style = MaterialIcon.Style.Round
        )
        val CropDin = MaterialIcon(
            name = "crop_din",
            style = MaterialIcon.Style.Round
        )
        val CropFree = MaterialIcon(
            name = "crop_free",
            style = MaterialIcon.Style.Round
        )
        val CropLandscape = MaterialIcon(
            name = "crop_landscape",
            style = MaterialIcon.Style.Round
        )
        val CropOriginal = MaterialIcon(
            name = "crop_original",
            style = MaterialIcon.Style.Round
        )
        val CropPortrait = MaterialIcon(
            name = "crop_portrait",
            style = MaterialIcon.Style.Round
        )
        val CropRotate = MaterialIcon(
            name = "crop_rotate",
            style = MaterialIcon.Style.Round
        )
        val CropSquare = MaterialIcon(
            name = "crop_square",
            style = MaterialIcon.Style.Round
        )
        val CrueltyFree = MaterialIcon(
            name = "cruelty_free",
            style = MaterialIcon.Style.Round
        )
        val Css = MaterialIcon(
            name = "css",
            style = MaterialIcon.Style.Round
        )
        val CurrencyBitcoin = MaterialIcon(
            name = "currency_bitcoin",
            style = MaterialIcon.Style.Round
        )
        val CurrencyExchange = MaterialIcon(
            name = "currency_exchange",
            style = MaterialIcon.Style.Round
        )
        val CurrencyFranc = MaterialIcon(
            name = "currency_franc",
            style = MaterialIcon.Style.Round
        )
        val CurrencyLira = MaterialIcon(
            name = "currency_lira",
            style = MaterialIcon.Style.Round
        )
        val CurrencyPound = MaterialIcon(
            name = "currency_pound",
            style = MaterialIcon.Style.Round
        )
        val CurrencyRuble = MaterialIcon(
            name = "currency_ruble",
            style = MaterialIcon.Style.Round
        )
        val CurrencyRupee = MaterialIcon(
            name = "currency_rupee",
            style = MaterialIcon.Style.Round
        )
        val CurrencyYen = MaterialIcon(
            name = "currency_yen",
            style = MaterialIcon.Style.Round
        )
        val CurrencyYuan = MaterialIcon(
            name = "currency_yuan",
            style = MaterialIcon.Style.Round
        )
        val Curtains = MaterialIcon(
            name = "curtains",
            style = MaterialIcon.Style.Round
        )
        val CurtainsClosed = MaterialIcon(
            name = "curtains_closed",
            style = MaterialIcon.Style.Round
        )
        val Cyclone = MaterialIcon(
            name = "cyclone",
            style = MaterialIcon.Style.Round
        )
        val Dangerous = MaterialIcon(
            name = "dangerous",
            style = MaterialIcon.Style.Round
        )
        val DarkMode = MaterialIcon(
            name = "dark_mode",
            style = MaterialIcon.Style.Round
        )
        val Dashboard = MaterialIcon(
            name = "dashboard",
            style = MaterialIcon.Style.Round
        )
        val DashboardCustomize = MaterialIcon(
            name = "dashboard_customize",
            style = MaterialIcon.Style.Round
        )
        val DataArray = MaterialIcon(
            name = "data_array",
            style = MaterialIcon.Style.Round
        )
        val DataExploration = MaterialIcon(
            name = "data_exploration",
            style = MaterialIcon.Style.Round
        )
        val DataObject = MaterialIcon(
            name = "data_object",
            style = MaterialIcon.Style.Round
        )
        val DataSaverOff = MaterialIcon(
            name = "data_saver_off",
            style = MaterialIcon.Style.Round
        )
        val DataSaverOn = MaterialIcon(
            name = "data_saver_on",
            style = MaterialIcon.Style.Round
        )
        val DataThresholding = MaterialIcon(
            name = "data_thresholding",
            style = MaterialIcon.Style.Round
        )
        val DataUsage = MaterialIcon(
            name = "data_usage",
            style = MaterialIcon.Style.Round
        )
        val Dataset = MaterialIcon(
            name = "dataset",
            style = MaterialIcon.Style.Round
        )
        val DatasetLinked = MaterialIcon(
            name = "dataset_linked",
            style = MaterialIcon.Style.Round
        )
        val DateRange = MaterialIcon(
            name = "date_range",
            style = MaterialIcon.Style.Round
        )
        val Deblur = MaterialIcon(
            name = "deblur",
            style = MaterialIcon.Style.Round
        )
        val Deck = MaterialIcon(
            name = "deck",
            style = MaterialIcon.Style.Round
        )
        val Dehaze = MaterialIcon(
            name = "dehaze",
            style = MaterialIcon.Style.Round
        )
        val Delete = MaterialIcon(
            name = "delete",
            style = MaterialIcon.Style.Round
        )
        val DeleteForever = MaterialIcon(
            name = "delete_forever",
            style = MaterialIcon.Style.Round
        )
        val DeleteOutline = MaterialIcon(
            name = "delete_outline",
            style = MaterialIcon.Style.Round
        )
        val DeleteSweep = MaterialIcon(
            name = "delete_sweep",
            style = MaterialIcon.Style.Round
        )
        val DeliveryDining = MaterialIcon(
            name = "delivery_dining",
            style = MaterialIcon.Style.Round
        )
        val DensityLarge = MaterialIcon(
            name = "density_large",
            style = MaterialIcon.Style.Round
        )
        val DensityMedium = MaterialIcon(
            name = "density_medium",
            style = MaterialIcon.Style.Round
        )
        val DensitySmall = MaterialIcon(
            name = "density_small",
            style = MaterialIcon.Style.Round
        )
        val DepartureBoard = MaterialIcon(
            name = "departure_board",
            style = MaterialIcon.Style.Round
        )
        val Description = MaterialIcon(
            name = "description",
            style = MaterialIcon.Style.Round
        )
        val Deselect = MaterialIcon(
            name = "deselect",
            style = MaterialIcon.Style.Round
        )
        val DesignServices = MaterialIcon(
            name = "design_services",
            style = MaterialIcon.Style.Round
        )
        val Desk = MaterialIcon(
            name = "desk",
            style = MaterialIcon.Style.Round
        )
        val DesktopAccessDisabled = MaterialIcon(
            name = "desktop_access_disabled",
            style = MaterialIcon.Style.Round
        )
        val DesktopMac = MaterialIcon(
            name = "desktop_mac",
            style = MaterialIcon.Style.Round
        )
        val DesktopWindows = MaterialIcon(
            name = "desktop_windows",
            style = MaterialIcon.Style.Round
        )
        val Details = MaterialIcon(
            name = "details",
            style = MaterialIcon.Style.Round
        )
        val DeveloperBoard = MaterialIcon(
            name = "developer_board",
            style = MaterialIcon.Style.Round
        )
        val DeveloperBoardOff = MaterialIcon(
            name = "developer_board_off",
            style = MaterialIcon.Style.Round
        )
        val DeveloperMode = MaterialIcon(
            name = "developer_mode",
            style = MaterialIcon.Style.Round
        )
        val DeviceHub = MaterialIcon(
            name = "device_hub",
            style = MaterialIcon.Style.Round
        )
        val DeviceThermostat = MaterialIcon(
            name = "device_thermostat",
            style = MaterialIcon.Style.Round
        )
        val DeviceUnknown = MaterialIcon(
            name = "device_unknown",
            style = MaterialIcon.Style.Round
        )
        val Devices = MaterialIcon(
            name = "devices",
            style = MaterialIcon.Style.Round
        )
        val DevicesFold = MaterialIcon(
            name = "devices_fold",
            style = MaterialIcon.Style.Round
        )
        val DevicesOther = MaterialIcon(
            name = "devices_other",
            style = MaterialIcon.Style.Round
        )
        val DialerSip = MaterialIcon(
            name = "dialer_sip",
            style = MaterialIcon.Style.Round
        )
        val Dialpad = MaterialIcon(
            name = "dialpad",
            style = MaterialIcon.Style.Round
        )
        val Diamond = MaterialIcon(
            name = "diamond",
            style = MaterialIcon.Style.Round
        )
        val Difference = MaterialIcon(
            name = "difference",
            style = MaterialIcon.Style.Round
        )
        val Dining = MaterialIcon(
            name = "dining",
            style = MaterialIcon.Style.Round
        )
        val DinnerDining = MaterialIcon(
            name = "dinner_dining",
            style = MaterialIcon.Style.Round
        )
        val Directions = MaterialIcon(
            name = "directions",
            style = MaterialIcon.Style.Round
        )
        val DirectionsBike = MaterialIcon(
            name = "directions_bike",
            style = MaterialIcon.Style.Round
        )
        val DirectionsBoat = MaterialIcon(
            name = "directions_boat",
            style = MaterialIcon.Style.Round
        )
        val DirectionsBoatFilled = MaterialIcon(
            name = "directions_boat_filled",
            style = MaterialIcon.Style.Round
        )
        val DirectionsBus = MaterialIcon(
            name = "directions_bus",
            style = MaterialIcon.Style.Round
        )
        val DirectionsBusFilled = MaterialIcon(
            name = "directions_bus_filled",
            style = MaterialIcon.Style.Round
        )
        val DirectionsCar = MaterialIcon(
            name = "directions_car",
            style = MaterialIcon.Style.Round
        )
        val DirectionsCarFilled = MaterialIcon(
            name = "directions_car_filled",
            style = MaterialIcon.Style.Round
        )
        val DirectionsOff = MaterialIcon(
            name = "directions_off",
            style = MaterialIcon.Style.Round
        )
        val DirectionsRailway = MaterialIcon(
            name = "directions_railway",
            style = MaterialIcon.Style.Round
        )
        val DirectionsRailwayFilled = MaterialIcon(
            name = "directions_railway_filled",
            style = MaterialIcon.Style.Round
        )
        val DirectionsRun = MaterialIcon(
            name = "directions_run",
            style = MaterialIcon.Style.Round
        )
        val DirectionsSubway = MaterialIcon(
            name = "directions_subway",
            style = MaterialIcon.Style.Round
        )
        val DirectionsSubwayFilled = MaterialIcon(
            name = "directions_subway_filled",
            style = MaterialIcon.Style.Round
        )
        val DirectionsTransit = MaterialIcon(
            name = "directions_transit",
            style = MaterialIcon.Style.Round
        )
        val DirectionsTransitFilled = MaterialIcon(
            name = "directions_transit_filled",
            style = MaterialIcon.Style.Round
        )
        val DirectionsWalk = MaterialIcon(
            name = "directions_walk",
            style = MaterialIcon.Style.Round
        )
        val DirtyLens = MaterialIcon(
            name = "dirty_lens",
            style = MaterialIcon.Style.Round
        )
        val DisabledByDefault = MaterialIcon(
            name = "disabled_by_default",
            style = MaterialIcon.Style.Round
        )
        val DisabledVisible = MaterialIcon(
            name = "disabled_visible",
            style = MaterialIcon.Style.Round
        )
        val DiscFull = MaterialIcon(
            name = "disc_full",
            style = MaterialIcon.Style.Round
        )
        val Discount = MaterialIcon(
            name = "discount",
            style = MaterialIcon.Style.Round
        )
        val DisplaySettings = MaterialIcon(
            name = "display_settings",
            style = MaterialIcon.Style.Round
        )
        val Diversity1 = MaterialIcon(
            name = "diversity_1",
            style = MaterialIcon.Style.Round
        )
        val Diversity2 = MaterialIcon(
            name = "diversity_2",
            style = MaterialIcon.Style.Round
        )
        val Diversity3 = MaterialIcon(
            name = "diversity_3",
            style = MaterialIcon.Style.Round
        )
        val Dns = MaterialIcon(
            name = "dns",
            style = MaterialIcon.Style.Round
        )
        val DoDisturb = MaterialIcon(
            name = "do_disturb",
            style = MaterialIcon.Style.Round
        )
        val DoDisturbAlt = MaterialIcon(
            name = "do_disturb_alt",
            style = MaterialIcon.Style.Round
        )
        val DoDisturbOff = MaterialIcon(
            name = "do_disturb_off",
            style = MaterialIcon.Style.Round
        )
        val DoDisturbOn = MaterialIcon(
            name = "do_disturb_on",
            style = MaterialIcon.Style.Round
        )
        val DoNotDisturb = MaterialIcon(
            name = "do_not_disturb",
            style = MaterialIcon.Style.Round
        )
        val DoNotDisturbAlt = MaterialIcon(
            name = "do_not_disturb_alt",
            style = MaterialIcon.Style.Round
        )
        val DoNotDisturbOff = MaterialIcon(
            name = "do_not_disturb_off",
            style = MaterialIcon.Style.Round
        )
        val DoNotDisturbOn = MaterialIcon(
            name = "do_not_disturb_on",
            style = MaterialIcon.Style.Round
        )
        val DoNotDisturbOnTotalSilence = MaterialIcon(
            name = "do_not_disturb_on_total_silence",
            style = MaterialIcon.Style.Round
        )
        val DoNotStep = MaterialIcon(
            name = "do_not_step",
            style = MaterialIcon.Style.Round
        )
        val DoNotTouch = MaterialIcon(
            name = "do_not_touch",
            style = MaterialIcon.Style.Round
        )
        val Dock = MaterialIcon(
            name = "dock",
            style = MaterialIcon.Style.Round
        )
        val DocumentScanner = MaterialIcon(
            name = "document_scanner",
            style = MaterialIcon.Style.Round
        )
        val Domain = MaterialIcon(
            name = "domain",
            style = MaterialIcon.Style.Round
        )
        val DomainAdd = MaterialIcon(
            name = "domain_add",
            style = MaterialIcon.Style.Round
        )
        val DomainDisabled = MaterialIcon(
            name = "domain_disabled",
            style = MaterialIcon.Style.Round
        )
        val DomainVerification = MaterialIcon(
            name = "domain_verification",
            style = MaterialIcon.Style.Round
        )
        val Done = MaterialIcon(
            name = "done",
            style = MaterialIcon.Style.Round
        )
        val DoneAll = MaterialIcon(
            name = "done_all",
            style = MaterialIcon.Style.Round
        )
        val DoneOutline = MaterialIcon(
            name = "done_outline",
            style = MaterialIcon.Style.Round
        )
        val DonutLarge = MaterialIcon(
            name = "donut_large",
            style = MaterialIcon.Style.Round
        )
        val DonutSmall = MaterialIcon(
            name = "donut_small",
            style = MaterialIcon.Style.Round
        )
        val DoorBack = MaterialIcon(
            name = "door_back",
            style = MaterialIcon.Style.Round
        )
        val DoorFront = MaterialIcon(
            name = "door_front",
            style = MaterialIcon.Style.Round
        )
        val DoorSliding = MaterialIcon(
            name = "door_sliding",
            style = MaterialIcon.Style.Round
        )
        val Doorbell = MaterialIcon(
            name = "doorbell",
            style = MaterialIcon.Style.Round
        )
        val DoubleArrow = MaterialIcon(
            name = "double_arrow",
            style = MaterialIcon.Style.Round
        )
        val DownhillSkiing = MaterialIcon(
            name = "downhill_skiing",
            style = MaterialIcon.Style.Round
        )
        val Download = MaterialIcon(
            name = "download",
            style = MaterialIcon.Style.Round
        )
        val DownloadDone = MaterialIcon(
            name = "download_done",
            style = MaterialIcon.Style.Round
        )
        val DownloadForOffline = MaterialIcon(
            name = "download_for_offline",
            style = MaterialIcon.Style.Round
        )
        val Downloading = MaterialIcon(
            name = "downloading",
            style = MaterialIcon.Style.Round
        )
        val Drafts = MaterialIcon(
            name = "drafts",
            style = MaterialIcon.Style.Round
        )
        val DragHandle = MaterialIcon(
            name = "drag_handle",
            style = MaterialIcon.Style.Round
        )
        val DragIndicator = MaterialIcon(
            name = "drag_indicator",
            style = MaterialIcon.Style.Round
        )
        val Draw = MaterialIcon(
            name = "draw",
            style = MaterialIcon.Style.Round
        )
        val DriveEta = MaterialIcon(
            name = "drive_eta",
            style = MaterialIcon.Style.Round
        )
        val DriveFileMove = MaterialIcon(
            name = "drive_file_move",
            style = MaterialIcon.Style.Round
        )
        val DriveFileMoveRtl = MaterialIcon(
            name = "drive_file_move_rtl",
            style = MaterialIcon.Style.Round
        )
        val DriveFileRenameOutline = MaterialIcon(
            name = "drive_file_rename_outline",
            style = MaterialIcon.Style.Round
        )
        val DriveFolderUpload = MaterialIcon(
            name = "drive_folder_upload",
            style = MaterialIcon.Style.Round
        )
        val Dry = MaterialIcon(
            name = "dry",
            style = MaterialIcon.Style.Round
        )
        val DryCleaning = MaterialIcon(
            name = "dry_cleaning",
            style = MaterialIcon.Style.Round
        )
        val Duo = MaterialIcon(
            name = "duo",
            style = MaterialIcon.Style.Round
        )
        val Dvr = MaterialIcon(
            name = "dvr",
            style = MaterialIcon.Style.Round
        )
        val DynamicFeed = MaterialIcon(
            name = "dynamic_feed",
            style = MaterialIcon.Style.Round
        )
        val DynamicForm = MaterialIcon(
            name = "dynamic_form",
            style = MaterialIcon.Style.Round
        )
        val EMobiledata = MaterialIcon(
            name = "e_mobiledata",
            style = MaterialIcon.Style.Round
        )
        val Earbuds = MaterialIcon(
            name = "earbuds",
            style = MaterialIcon.Style.Round
        )
        val EarbudsBattery = MaterialIcon(
            name = "earbuds_battery",
            style = MaterialIcon.Style.Round
        )
        val East = MaterialIcon(
            name = "east",
            style = MaterialIcon.Style.Round
        )
        val EdgesensorHigh = MaterialIcon(
            name = "edgesensor_high",
            style = MaterialIcon.Style.Round
        )
        val EdgesensorLow = MaterialIcon(
            name = "edgesensor_low",
            style = MaterialIcon.Style.Round
        )
        val Edit = MaterialIcon(
            name = "edit",
            style = MaterialIcon.Style.Round
        )
        val EditAttributes = MaterialIcon(
            name = "edit_attributes",
            style = MaterialIcon.Style.Round
        )
        val EditCalendar = MaterialIcon(
            name = "edit_calendar",
            style = MaterialIcon.Style.Round
        )
        val EditLocation = MaterialIcon(
            name = "edit_location",
            style = MaterialIcon.Style.Round
        )
        val EditLocationAlt = MaterialIcon(
            name = "edit_location_alt",
            style = MaterialIcon.Style.Round
        )
        val EditNote = MaterialIcon(
            name = "edit_note",
            style = MaterialIcon.Style.Round
        )
        val EditNotifications = MaterialIcon(
            name = "edit_notifications",
            style = MaterialIcon.Style.Round
        )
        val EditOff = MaterialIcon(
            name = "edit_off",
            style = MaterialIcon.Style.Round
        )
        val EditRoad = MaterialIcon(
            name = "edit_road",
            style = MaterialIcon.Style.Round
        )
        val Egg = MaterialIcon(
            name = "egg",
            style = MaterialIcon.Style.Round
        )
        val EggAlt = MaterialIcon(
            name = "egg_alt",
            style = MaterialIcon.Style.Round
        )
        val Eject = MaterialIcon(
            name = "eject",
            style = MaterialIcon.Style.Round
        )
        val Elderly = MaterialIcon(
            name = "elderly",
            style = MaterialIcon.Style.Round
        )
        val ElderlyWoman = MaterialIcon(
            name = "elderly_woman",
            style = MaterialIcon.Style.Round
        )
        val ElectricBike = MaterialIcon(
            name = "electric_bike",
            style = MaterialIcon.Style.Round
        )
        val ElectricBolt = MaterialIcon(
            name = "electric_bolt",
            style = MaterialIcon.Style.Round
        )
        val ElectricCar = MaterialIcon(
            name = "electric_car",
            style = MaterialIcon.Style.Round
        )
        val ElectricMeter = MaterialIcon(
            name = "electric_meter",
            style = MaterialIcon.Style.Round
        )
        val ElectricMoped = MaterialIcon(
            name = "electric_moped",
            style = MaterialIcon.Style.Round
        )
        val ElectricRickshaw = MaterialIcon(
            name = "electric_rickshaw",
            style = MaterialIcon.Style.Round
        )
        val ElectricScooter = MaterialIcon(
            name = "electric_scooter",
            style = MaterialIcon.Style.Round
        )
        val ElectricalServices = MaterialIcon(
            name = "electrical_services",
            style = MaterialIcon.Style.Round
        )
        val Elevator = MaterialIcon(
            name = "elevator",
            style = MaterialIcon.Style.Round
        )
        val Email = MaterialIcon(
            name = "email",
            style = MaterialIcon.Style.Round
        )
        val Emergency = MaterialIcon(
            name = "emergency",
            style = MaterialIcon.Style.Round
        )
        val EmergencyRecording = MaterialIcon(
            name = "emergency_recording",
            style = MaterialIcon.Style.Round
        )
        val EmergencyShare = MaterialIcon(
            name = "emergency_share",
            style = MaterialIcon.Style.Round
        )
        val EmojiEmotions = MaterialIcon(
            name = "emoji_emotions",
            style = MaterialIcon.Style.Round
        )
        val EmojiEvents = MaterialIcon(
            name = "emoji_events",
            style = MaterialIcon.Style.Round
        )
        val EmojiFoodBeverage = MaterialIcon(
            name = "emoji_food_beverage",
            style = MaterialIcon.Style.Round
        )
        val EmojiNature = MaterialIcon(
            name = "emoji_nature",
            style = MaterialIcon.Style.Round
        )
        val EmojiObjects = MaterialIcon(
            name = "emoji_objects",
            style = MaterialIcon.Style.Round
        )
        val EmojiPeople = MaterialIcon(
            name = "emoji_people",
            style = MaterialIcon.Style.Round
        )
        val EmojiSymbols = MaterialIcon(
            name = "emoji_symbols",
            style = MaterialIcon.Style.Round
        )
        val EmojiTransportation = MaterialIcon(
            name = "emoji_transportation",
            style = MaterialIcon.Style.Round
        )
        val EnergySavingsLeaf = MaterialIcon(
            name = "energy_savings_leaf",
            style = MaterialIcon.Style.Round
        )
        val Engineering = MaterialIcon(
            name = "engineering",
            style = MaterialIcon.Style.Round
        )
        val EnhancedEncryption = MaterialIcon(
            name = "enhanced_encryption",
            style = MaterialIcon.Style.Round
        )
        val Equalizer = MaterialIcon(
            name = "equalizer",
            style = MaterialIcon.Style.Round
        )
        val Error = MaterialIcon(
            name = "error",
            style = MaterialIcon.Style.Round
        )
        val ErrorOutline = MaterialIcon(
            name = "error_outline",
            style = MaterialIcon.Style.Round
        )
        val Escalator = MaterialIcon(
            name = "escalator",
            style = MaterialIcon.Style.Round
        )
        val EscalatorWarning = MaterialIcon(
            name = "escalator_warning",
            style = MaterialIcon.Style.Round
        )
        val Euro = MaterialIcon(
            name = "euro",
            style = MaterialIcon.Style.Round
        )
        val EuroSymbol = MaterialIcon(
            name = "euro_symbol",
            style = MaterialIcon.Style.Round
        )
        val EvStation = MaterialIcon(
            name = "ev_station",
            style = MaterialIcon.Style.Round
        )
        val Event = MaterialIcon(
            name = "event",
            style = MaterialIcon.Style.Round
        )
        val EventAvailable = MaterialIcon(
            name = "event_available",
            style = MaterialIcon.Style.Round
        )
        val EventBusy = MaterialIcon(
            name = "event_busy",
            style = MaterialIcon.Style.Round
        )
        val EventNote = MaterialIcon(
            name = "event_note",
            style = MaterialIcon.Style.Round
        )
        val EventRepeat = MaterialIcon(
            name = "event_repeat",
            style = MaterialIcon.Style.Round
        )
        val EventSeat = MaterialIcon(
            name = "event_seat",
            style = MaterialIcon.Style.Round
        )
        val ExitToApp = MaterialIcon(
            name = "exit_to_app",
            style = MaterialIcon.Style.Round
        )
        val Expand = MaterialIcon(
            name = "expand",
            style = MaterialIcon.Style.Round
        )
        val ExpandCircleDown = MaterialIcon(
            name = "expand_circle_down",
            style = MaterialIcon.Style.Round
        )
        val ExpandLess = MaterialIcon(
            name = "expand_less",
            style = MaterialIcon.Style.Round
        )
        val ExpandMore = MaterialIcon(
            name = "expand_more",
            style = MaterialIcon.Style.Round
        )
        val Explicit = MaterialIcon(
            name = "explicit",
            style = MaterialIcon.Style.Round
        )
        val Explore = MaterialIcon(
            name = "explore",
            style = MaterialIcon.Style.Round
        )
        val ExploreOff = MaterialIcon(
            name = "explore_off",
            style = MaterialIcon.Style.Round
        )
        val Exposure = MaterialIcon(
            name = "exposure",
            style = MaterialIcon.Style.Round
        )
        val ExposureNeg1 = MaterialIcon(
            name = "exposure_neg_1",
            style = MaterialIcon.Style.Round
        )
        val ExposureNeg2 = MaterialIcon(
            name = "exposure_neg_2",
            style = MaterialIcon.Style.Round
        )
        val ExposurePlus1 = MaterialIcon(
            name = "exposure_plus_1",
            style = MaterialIcon.Style.Round
        )
        val ExposurePlus2 = MaterialIcon(
            name = "exposure_plus_2",
            style = MaterialIcon.Style.Round
        )
        val ExposureZero = MaterialIcon(
            name = "exposure_zero",
            style = MaterialIcon.Style.Round
        )
        val Extension = MaterialIcon(
            name = "extension",
            style = MaterialIcon.Style.Round
        )
        val ExtensionOff = MaterialIcon(
            name = "extension_off",
            style = MaterialIcon.Style.Round
        )
        val Face = MaterialIcon(
            name = "face",
            style = MaterialIcon.Style.Round
        )
        val Face2 = MaterialIcon(
            name = "face_2",
            style = MaterialIcon.Style.Round
        )
        val Face3 = MaterialIcon(
            name = "face_3",
            style = MaterialIcon.Style.Round
        )
        val Face4 = MaterialIcon(
            name = "face_4",
            style = MaterialIcon.Style.Round
        )
        val Face5 = MaterialIcon(
            name = "face_5",
            style = MaterialIcon.Style.Round
        )
        val Face6 = MaterialIcon(
            name = "face_6",
            style = MaterialIcon.Style.Round
        )
        val FaceRetouchingNatural = MaterialIcon(
            name = "face_retouching_natural",
            style = MaterialIcon.Style.Round
        )
        val FaceRetouchingOff = MaterialIcon(
            name = "face_retouching_off",
            style = MaterialIcon.Style.Round
        )
        val FaceUnlock = MaterialIcon(
            name = "face_unlock",
            style = MaterialIcon.Style.Round
        )
        val FactCheck = MaterialIcon(
            name = "fact_check",
            style = MaterialIcon.Style.Round
        )
        val Factory = MaterialIcon(
            name = "factory",
            style = MaterialIcon.Style.Round
        )
        val FamilyRestroom = MaterialIcon(
            name = "family_restroom",
            style = MaterialIcon.Style.Round
        )
        val FastForward = MaterialIcon(
            name = "fast_forward",
            style = MaterialIcon.Style.Round
        )
        val FastRewind = MaterialIcon(
            name = "fast_rewind",
            style = MaterialIcon.Style.Round
        )
        val Fastfood = MaterialIcon(
            name = "fastfood",
            style = MaterialIcon.Style.Round
        )
        val Favorite = MaterialIcon(
            name = "favorite",
            style = MaterialIcon.Style.Round
        )
        val FavoriteBorder = MaterialIcon(
            name = "favorite_border",
            style = MaterialIcon.Style.Round
        )
        val Fax = MaterialIcon(
            name = "fax",
            style = MaterialIcon.Style.Round
        )
        val FeaturedPlayList = MaterialIcon(
            name = "featured_play_list",
            style = MaterialIcon.Style.Round
        )
        val FeaturedVideo = MaterialIcon(
            name = "featured_video",
            style = MaterialIcon.Style.Round
        )
        val Feed = MaterialIcon(
            name = "feed",
            style = MaterialIcon.Style.Round
        )
        val Feedback = MaterialIcon(
            name = "feedback",
            style = MaterialIcon.Style.Round
        )
        val Female = MaterialIcon(
            name = "female",
            style = MaterialIcon.Style.Round
        )
        val Fence = MaterialIcon(
            name = "fence",
            style = MaterialIcon.Style.Round
        )
        val Festival = MaterialIcon(
            name = "festival",
            style = MaterialIcon.Style.Round
        )
        val FiberDvr = MaterialIcon(
            name = "fiber_dvr",
            style = MaterialIcon.Style.Round
        )
        val FiberManualRecord = MaterialIcon(
            name = "fiber_manual_record",
            style = MaterialIcon.Style.Round
        )
        val FiberNew = MaterialIcon(
            name = "fiber_new",
            style = MaterialIcon.Style.Round
        )
        val FiberPin = MaterialIcon(
            name = "fiber_pin",
            style = MaterialIcon.Style.Round
        )
        val FiberSmartRecord = MaterialIcon(
            name = "fiber_smart_record",
            style = MaterialIcon.Style.Round
        )
        val FileCopy = MaterialIcon(
            name = "file_copy",
            style = MaterialIcon.Style.Round
        )
        val FileDownload = MaterialIcon(
            name = "file_download",
            style = MaterialIcon.Style.Round
        )
        val FileDownloadDone = MaterialIcon(
            name = "file_download_done",
            style = MaterialIcon.Style.Round
        )
        val FileDownloadOff = MaterialIcon(
            name = "file_download_off",
            style = MaterialIcon.Style.Round
        )
        val FileOpen = MaterialIcon(
            name = "file_open",
            style = MaterialIcon.Style.Round
        )
        val FilePresent = MaterialIcon(
            name = "file_present",
            style = MaterialIcon.Style.Round
        )
        val FileUpload = MaterialIcon(
            name = "file_upload",
            style = MaterialIcon.Style.Round
        )
        val Filter = MaterialIcon(
            name = "filter",
            style = MaterialIcon.Style.Round
        )
        val Filter1 = MaterialIcon(
            name = "filter_1",
            style = MaterialIcon.Style.Round
        )
        val Filter2 = MaterialIcon(
            name = "filter_2",
            style = MaterialIcon.Style.Round
        )
        val Filter3 = MaterialIcon(
            name = "filter_3",
            style = MaterialIcon.Style.Round
        )
        val Filter4 = MaterialIcon(
            name = "filter_4",
            style = MaterialIcon.Style.Round
        )
        val Filter5 = MaterialIcon(
            name = "filter_5",
            style = MaterialIcon.Style.Round
        )
        val Filter6 = MaterialIcon(
            name = "filter_6",
            style = MaterialIcon.Style.Round
        )
        val Filter7 = MaterialIcon(
            name = "filter_7",
            style = MaterialIcon.Style.Round
        )
        val Filter8 = MaterialIcon(
            name = "filter_8",
            style = MaterialIcon.Style.Round
        )
        val Filter9 = MaterialIcon(
            name = "filter_9",
            style = MaterialIcon.Style.Round
        )
        val Filter9Plus = MaterialIcon(
            name = "filter_9_plus",
            style = MaterialIcon.Style.Round
        )
        val FilterAlt = MaterialIcon(
            name = "filter_alt",
            style = MaterialIcon.Style.Round
        )
        val FilterAltOff = MaterialIcon(
            name = "filter_alt_off",
            style = MaterialIcon.Style.Round
        )
        val FilterBAndW = MaterialIcon(
            name = "filter_b_and_w",
            style = MaterialIcon.Style.Round
        )
        val FilterCenterFocus = MaterialIcon(
            name = "filter_center_focus",
            style = MaterialIcon.Style.Round
        )
        val FilterDrama = MaterialIcon(
            name = "filter_drama",
            style = MaterialIcon.Style.Round
        )
        val FilterFrames = MaterialIcon(
            name = "filter_frames",
            style = MaterialIcon.Style.Round
        )
        val FilterHdr = MaterialIcon(
            name = "filter_hdr",
            style = MaterialIcon.Style.Round
        )
        val FilterList = MaterialIcon(
            name = "filter_list",
            style = MaterialIcon.Style.Round
        )
        val FilterListOff = MaterialIcon(
            name = "filter_list_off",
            style = MaterialIcon.Style.Round
        )
        val FilterNone = MaterialIcon(
            name = "filter_none",
            style = MaterialIcon.Style.Round
        )
        val FilterTiltShift = MaterialIcon(
            name = "filter_tilt_shift",
            style = MaterialIcon.Style.Round
        )
        val FilterVintage = MaterialIcon(
            name = "filter_vintage",
            style = MaterialIcon.Style.Round
        )
        val FindInPage = MaterialIcon(
            name = "find_in_page",
            style = MaterialIcon.Style.Round
        )
        val FindReplace = MaterialIcon(
            name = "find_replace",
            style = MaterialIcon.Style.Round
        )
        val Fingerprint = MaterialIcon(
            name = "fingerprint",
            style = MaterialIcon.Style.Round
        )
        val FireExtinguisher = MaterialIcon(
            name = "fire_extinguisher",
            style = MaterialIcon.Style.Round
        )
        val FireHydrantAlt = MaterialIcon(
            name = "fire_hydrant_alt",
            style = MaterialIcon.Style.Round
        )
        val FireTruck = MaterialIcon(
            name = "fire_truck",
            style = MaterialIcon.Style.Round
        )
        val Fireplace = MaterialIcon(
            name = "fireplace",
            style = MaterialIcon.Style.Round
        )
        val FirstPage = MaterialIcon(
            name = "first_page",
            style = MaterialIcon.Style.Round
        )
        val FitScreen = MaterialIcon(
            name = "fit_screen",
            style = MaterialIcon.Style.Round
        )
        val Fitbit = MaterialIcon(
            name = "fitbit",
            style = MaterialIcon.Style.Round
        )
        val FitnessCenter = MaterialIcon(
            name = "fitness_center",
            style = MaterialIcon.Style.Round
        )
        val Flag = MaterialIcon(
            name = "flag",
            style = MaterialIcon.Style.Round
        )
        val FlagCircle = MaterialIcon(
            name = "flag_circle",
            style = MaterialIcon.Style.Round
        )
        val Flaky = MaterialIcon(
            name = "flaky",
            style = MaterialIcon.Style.Round
        )
        val Flare = MaterialIcon(
            name = "flare",
            style = MaterialIcon.Style.Round
        )
        val FlashAuto = MaterialIcon(
            name = "flash_auto",
            style = MaterialIcon.Style.Round
        )
        val FlashOff = MaterialIcon(
            name = "flash_off",
            style = MaterialIcon.Style.Round
        )
        val FlashOn = MaterialIcon(
            name = "flash_on",
            style = MaterialIcon.Style.Round
        )
        val FlashlightOff = MaterialIcon(
            name = "flashlight_off",
            style = MaterialIcon.Style.Round
        )
        val FlashlightOn = MaterialIcon(
            name = "flashlight_on",
            style = MaterialIcon.Style.Round
        )
        val Flatware = MaterialIcon(
            name = "flatware",
            style = MaterialIcon.Style.Round
        )
        val Flight = MaterialIcon(
            name = "flight",
            style = MaterialIcon.Style.Round
        )
        val FlightClass = MaterialIcon(
            name = "flight_class",
            style = MaterialIcon.Style.Round
        )
        val FlightLand = MaterialIcon(
            name = "flight_land",
            style = MaterialIcon.Style.Round
        )
        val FlightTakeoff = MaterialIcon(
            name = "flight_takeoff",
            style = MaterialIcon.Style.Round
        )
        val Flip = MaterialIcon(
            name = "flip",
            style = MaterialIcon.Style.Round
        )
        val FlipCameraAndroid = MaterialIcon(
            name = "flip_camera_android",
            style = MaterialIcon.Style.Round
        )
        val FlipCameraIos = MaterialIcon(
            name = "flip_camera_ios",
            style = MaterialIcon.Style.Round
        )
        val FlipToBack = MaterialIcon(
            name = "flip_to_back",
            style = MaterialIcon.Style.Round
        )
        val FlipToFront = MaterialIcon(
            name = "flip_to_front",
            style = MaterialIcon.Style.Round
        )
        val Flood = MaterialIcon(
            name = "flood",
            style = MaterialIcon.Style.Round
        )
        val Fluorescent = MaterialIcon(
            name = "fluorescent",
            style = MaterialIcon.Style.Round
        )
        val FlutterDash = MaterialIcon(
            name = "flutter_dash",
            style = MaterialIcon.Style.Round
        )
        val FmdBad = MaterialIcon(
            name = "fmd_bad",
            style = MaterialIcon.Style.Round
        )
        val FmdGood = MaterialIcon(
            name = "fmd_good",
            style = MaterialIcon.Style.Round
        )
        val Folder = MaterialIcon(
            name = "folder",
            style = MaterialIcon.Style.Round
        )
        val FolderCopy = MaterialIcon(
            name = "folder_copy",
            style = MaterialIcon.Style.Round
        )
        val FolderDelete = MaterialIcon(
            name = "folder_delete",
            style = MaterialIcon.Style.Round
        )
        val FolderOff = MaterialIcon(
            name = "folder_off",
            style = MaterialIcon.Style.Round
        )
        val FolderOpen = MaterialIcon(
            name = "folder_open",
            style = MaterialIcon.Style.Round
        )
        val FolderShared = MaterialIcon(
            name = "folder_shared",
            style = MaterialIcon.Style.Round
        )
        val FolderSpecial = MaterialIcon(
            name = "folder_special",
            style = MaterialIcon.Style.Round
        )
        val FolderZip = MaterialIcon(
            name = "folder_zip",
            style = MaterialIcon.Style.Round
        )
        val FollowTheSigns = MaterialIcon(
            name = "follow_the_signs",
            style = MaterialIcon.Style.Round
        )
        val FontDownload = MaterialIcon(
            name = "font_download",
            style = MaterialIcon.Style.Round
        )
        val FontDownloadOff = MaterialIcon(
            name = "font_download_off",
            style = MaterialIcon.Style.Round
        )
        val FoodBank = MaterialIcon(
            name = "food_bank",
            style = MaterialIcon.Style.Round
        )
        val Forest = MaterialIcon(
            name = "forest",
            style = MaterialIcon.Style.Round
        )
        val ForkLeft = MaterialIcon(
            name = "fork_left",
            style = MaterialIcon.Style.Round
        )
        val ForkRight = MaterialIcon(
            name = "fork_right",
            style = MaterialIcon.Style.Round
        )
        val FormatAlignCenter = MaterialIcon(
            name = "format_align_center",
            style = MaterialIcon.Style.Round
        )
        val FormatAlignJustify = MaterialIcon(
            name = "format_align_justify",
            style = MaterialIcon.Style.Round
        )
        val FormatAlignLeft = MaterialIcon(
            name = "format_align_left",
            style = MaterialIcon.Style.Round
        )
        val FormatAlignRight = MaterialIcon(
            name = "format_align_right",
            style = MaterialIcon.Style.Round
        )
        val FormatBold = MaterialIcon(
            name = "format_bold",
            style = MaterialIcon.Style.Round
        )
        val FormatClear = MaterialIcon(
            name = "format_clear",
            style = MaterialIcon.Style.Round
        )
        val FormatColorFill = MaterialIcon(
            name = "format_color_fill",
            style = MaterialIcon.Style.Round
        )
        val FormatColorReset = MaterialIcon(
            name = "format_color_reset",
            style = MaterialIcon.Style.Round
        )
        val FormatColorText = MaterialIcon(
            name = "format_color_text",
            style = MaterialIcon.Style.Round
        )
        val FormatIndentDecrease = MaterialIcon(
            name = "format_indent_decrease",
            style = MaterialIcon.Style.Round
        )
        val FormatIndentIncrease = MaterialIcon(
            name = "format_indent_increase",
            style = MaterialIcon.Style.Round
        )
        val FormatItalic = MaterialIcon(
            name = "format_italic",
            style = MaterialIcon.Style.Round
        )
        val FormatLineSpacing = MaterialIcon(
            name = "format_line_spacing",
            style = MaterialIcon.Style.Round
        )
        val FormatListBulleted = MaterialIcon(
            name = "format_list_bulleted",
            style = MaterialIcon.Style.Round
        )
        val FormatListNumbered = MaterialIcon(
            name = "format_list_numbered",
            style = MaterialIcon.Style.Round
        )
        val FormatListNumberedRtl = MaterialIcon(
            name = "format_list_numbered_rtl",
            style = MaterialIcon.Style.Round
        )
        val FormatOverline = MaterialIcon(
            name = "format_overline",
            style = MaterialIcon.Style.Round
        )
        val FormatPaint = MaterialIcon(
            name = "format_paint",
            style = MaterialIcon.Style.Round
        )
        val FormatQuote = MaterialIcon(
            name = "format_quote",
            style = MaterialIcon.Style.Round
        )
        val FormatShapes = MaterialIcon(
            name = "format_shapes",
            style = MaterialIcon.Style.Round
        )
        val FormatSize = MaterialIcon(
            name = "format_size",
            style = MaterialIcon.Style.Round
        )
        val FormatStrikethrough = MaterialIcon(
            name = "format_strikethrough",
            style = MaterialIcon.Style.Round
        )
        val FormatTextdirectionLToR = MaterialIcon(
            name = "format_textdirection_l_to_r",
            style = MaterialIcon.Style.Round
        )
        val FormatTextdirectionRToL = MaterialIcon(
            name = "format_textdirection_r_to_l",
            style = MaterialIcon.Style.Round
        )
        val FormatUnderlined = MaterialIcon(
            name = "format_underlined",
            style = MaterialIcon.Style.Round
        )
        val Fort = MaterialIcon(
            name = "fort",
            style = MaterialIcon.Style.Round
        )
        val Forum = MaterialIcon(
            name = "forum",
            style = MaterialIcon.Style.Round
        )
        val Forward = MaterialIcon(
            name = "forward",
            style = MaterialIcon.Style.Round
        )
        val Forward10 = MaterialIcon(
            name = "forward_10",
            style = MaterialIcon.Style.Round
        )
        val Forward30 = MaterialIcon(
            name = "forward_30",
            style = MaterialIcon.Style.Round
        )
        val Forward5 = MaterialIcon(
            name = "forward_5",
            style = MaterialIcon.Style.Round
        )
        val ForwardToInbox = MaterialIcon(
            name = "forward_to_inbox",
            style = MaterialIcon.Style.Round
        )
        val Foundation = MaterialIcon(
            name = "foundation",
            style = MaterialIcon.Style.Round
        )
        val FreeBreakfast = MaterialIcon(
            name = "free_breakfast",
            style = MaterialIcon.Style.Round
        )
        val FreeCancellation = MaterialIcon(
            name = "free_cancellation",
            style = MaterialIcon.Style.Round
        )
        val FrontHand = MaterialIcon(
            name = "front_hand",
            style = MaterialIcon.Style.Round
        )
        val Fullscreen = MaterialIcon(
            name = "fullscreen",
            style = MaterialIcon.Style.Round
        )
        val FullscreenExit = MaterialIcon(
            name = "fullscreen_exit",
            style = MaterialIcon.Style.Round
        )
        val Functions = MaterialIcon(
            name = "functions",
            style = MaterialIcon.Style.Round
        )
        val GMobiledata = MaterialIcon(
            name = "g_mobiledata",
            style = MaterialIcon.Style.Round
        )
        val GTranslate = MaterialIcon(
            name = "g_translate",
            style = MaterialIcon.Style.Round
        )
        val Gamepad = MaterialIcon(
            name = "gamepad",
            style = MaterialIcon.Style.Round
        )
        val Games = MaterialIcon(
            name = "games",
            style = MaterialIcon.Style.Round
        )
        val Garage = MaterialIcon(
            name = "garage",
            style = MaterialIcon.Style.Round
        )
        val GasMeter = MaterialIcon(
            name = "gas_meter",
            style = MaterialIcon.Style.Round
        )
        val Gavel = MaterialIcon(
            name = "gavel",
            style = MaterialIcon.Style.Round
        )
        val GeneratingTokens = MaterialIcon(
            name = "generating_tokens",
            style = MaterialIcon.Style.Round
        )
        val Gesture = MaterialIcon(
            name = "gesture",
            style = MaterialIcon.Style.Round
        )
        val GetApp = MaterialIcon(
            name = "get_app",
            style = MaterialIcon.Style.Round
        )
        val Gif = MaterialIcon(
            name = "gif",
            style = MaterialIcon.Style.Round
        )
        val GifBox = MaterialIcon(
            name = "gif_box",
            style = MaterialIcon.Style.Round
        )
        val Girl = MaterialIcon(
            name = "girl",
            style = MaterialIcon.Style.Round
        )
        val Gite = MaterialIcon(
            name = "gite",
            style = MaterialIcon.Style.Round
        )
        val GolfCourse = MaterialIcon(
            name = "golf_course",
            style = MaterialIcon.Style.Round
        )
        val GppBad = MaterialIcon(
            name = "gpp_bad",
            style = MaterialIcon.Style.Round
        )
        val GppGood = MaterialIcon(
            name = "gpp_good",
            style = MaterialIcon.Style.Round
        )
        val GppMaybe = MaterialIcon(
            name = "gpp_maybe",
            style = MaterialIcon.Style.Round
        )
        val GpsFixed = MaterialIcon(
            name = "gps_fixed",
            style = MaterialIcon.Style.Round
        )
        val GpsNotFixed = MaterialIcon(
            name = "gps_not_fixed",
            style = MaterialIcon.Style.Round
        )
        val GpsOff = MaterialIcon(
            name = "gps_off",
            style = MaterialIcon.Style.Round
        )
        val Grade = MaterialIcon(
            name = "grade",
            style = MaterialIcon.Style.Round
        )
        val Gradient = MaterialIcon(
            name = "gradient",
            style = MaterialIcon.Style.Round
        )
        val Grading = MaterialIcon(
            name = "grading",
            style = MaterialIcon.Style.Round
        )
        val Grain = MaterialIcon(
            name = "grain",
            style = MaterialIcon.Style.Round
        )
        val GraphicEq = MaterialIcon(
            name = "graphic_eq",
            style = MaterialIcon.Style.Round
        )
        val Grass = MaterialIcon(
            name = "grass",
            style = MaterialIcon.Style.Round
        )
        val Grid3x3 = MaterialIcon(
            name = "grid_3x3",
            style = MaterialIcon.Style.Round
        )
        val Grid4x4 = MaterialIcon(
            name = "grid_4x4",
            style = MaterialIcon.Style.Round
        )
        val GridGoldenratio = MaterialIcon(
            name = "grid_goldenratio",
            style = MaterialIcon.Style.Round
        )
        val GridOff = MaterialIcon(
            name = "grid_off",
            style = MaterialIcon.Style.Round
        )
        val GridOn = MaterialIcon(
            name = "grid_on",
            style = MaterialIcon.Style.Round
        )
        val GridView = MaterialIcon(
            name = "grid_view",
            style = MaterialIcon.Style.Round
        )
        val Group = MaterialIcon(
            name = "group",
            style = MaterialIcon.Style.Round
        )
        val GroupAdd = MaterialIcon(
            name = "group_add",
            style = MaterialIcon.Style.Round
        )
        val GroupOff = MaterialIcon(
            name = "group_off",
            style = MaterialIcon.Style.Round
        )
        val GroupRemove = MaterialIcon(
            name = "group_remove",
            style = MaterialIcon.Style.Round
        )
        val GroupWork = MaterialIcon(
            name = "group_work",
            style = MaterialIcon.Style.Round
        )
        val Groups = MaterialIcon(
            name = "groups",
            style = MaterialIcon.Style.Round
        )
        val Groups2 = MaterialIcon(
            name = "groups_2",
            style = MaterialIcon.Style.Round
        )
        val Groups3 = MaterialIcon(
            name = "groups_3",
            style = MaterialIcon.Style.Round
        )
        val HMobiledata = MaterialIcon(
            name = "h_mobiledata",
            style = MaterialIcon.Style.Round
        )
        val HPlusMobiledata = MaterialIcon(
            name = "h_plus_mobiledata",
            style = MaterialIcon.Style.Round
        )
        val Hail = MaterialIcon(
            name = "hail",
            style = MaterialIcon.Style.Round
        )
        val Handshake = MaterialIcon(
            name = "handshake",
            style = MaterialIcon.Style.Round
        )
        val Handyman = MaterialIcon(
            name = "handyman",
            style = MaterialIcon.Style.Round
        )
        val Hardware = MaterialIcon(
            name = "hardware",
            style = MaterialIcon.Style.Round
        )
        val Hd = MaterialIcon(
            name = "hd",
            style = MaterialIcon.Style.Round
        )
        val HdrAuto = MaterialIcon(
            name = "hdr_auto",
            style = MaterialIcon.Style.Round
        )
        val HdrAutoSelect = MaterialIcon(
            name = "hdr_auto_select",
            style = MaterialIcon.Style.Round
        )
        val HdrEnhancedSelect = MaterialIcon(
            name = "hdr_enhanced_select",
            style = MaterialIcon.Style.Round
        )
        val HdrOff = MaterialIcon(
            name = "hdr_off",
            style = MaterialIcon.Style.Round
        )
        val HdrOffSelect = MaterialIcon(
            name = "hdr_off_select",
            style = MaterialIcon.Style.Round
        )
        val HdrOn = MaterialIcon(
            name = "hdr_on",
            style = MaterialIcon.Style.Round
        )
        val HdrOnSelect = MaterialIcon(
            name = "hdr_on_select",
            style = MaterialIcon.Style.Round
        )
        val HdrPlus = MaterialIcon(
            name = "hdr_plus",
            style = MaterialIcon.Style.Round
        )
        val HdrStrong = MaterialIcon(
            name = "hdr_strong",
            style = MaterialIcon.Style.Round
        )
        val HdrWeak = MaterialIcon(
            name = "hdr_weak",
            style = MaterialIcon.Style.Round
        )
        val Headphones = MaterialIcon(
            name = "headphones",
            style = MaterialIcon.Style.Round
        )
        val HeadphonesBattery = MaterialIcon(
            name = "headphones_battery",
            style = MaterialIcon.Style.Round
        )
        val Headset = MaterialIcon(
            name = "headset",
            style = MaterialIcon.Style.Round
        )
        val HeadsetMic = MaterialIcon(
            name = "headset_mic",
            style = MaterialIcon.Style.Round
        )
        val HeadsetOff = MaterialIcon(
            name = "headset_off",
            style = MaterialIcon.Style.Round
        )
        val Healing = MaterialIcon(
            name = "healing",
            style = MaterialIcon.Style.Round
        )
        val HealthAndSafety = MaterialIcon(
            name = "health_and_safety",
            style = MaterialIcon.Style.Round
        )
        val Hearing = MaterialIcon(
            name = "hearing",
            style = MaterialIcon.Style.Round
        )
        val HearingDisabled = MaterialIcon(
            name = "hearing_disabled",
            style = MaterialIcon.Style.Round
        )
        val HeartBroken = MaterialIcon(
            name = "heart_broken",
            style = MaterialIcon.Style.Round
        )
        val HeatPump = MaterialIcon(
            name = "heat_pump",
            style = MaterialIcon.Style.Round
        )
        val Height = MaterialIcon(
            name = "height",
            style = MaterialIcon.Style.Round
        )
        val Help = MaterialIcon(
            name = "help",
            style = MaterialIcon.Style.Round
        )
        val HelpCenter = MaterialIcon(
            name = "help_center",
            style = MaterialIcon.Style.Round
        )
        val HelpOutline = MaterialIcon(
            name = "help_outline",
            style = MaterialIcon.Style.Round
        )
        val Hevc = MaterialIcon(
            name = "hevc",
            style = MaterialIcon.Style.Round
        )
        val Hexagon = MaterialIcon(
            name = "hexagon",
            style = MaterialIcon.Style.Round
        )
        val HideImage = MaterialIcon(
            name = "hide_image",
            style = MaterialIcon.Style.Round
        )
        val HideSource = MaterialIcon(
            name = "hide_source",
            style = MaterialIcon.Style.Round
        )
        val HighQuality = MaterialIcon(
            name = "high_quality",
            style = MaterialIcon.Style.Round
        )
        val Highlight = MaterialIcon(
            name = "highlight",
            style = MaterialIcon.Style.Round
        )
        val HighlightAlt = MaterialIcon(
            name = "highlight_alt",
            style = MaterialIcon.Style.Round
        )
        val HighlightOff = MaterialIcon(
            name = "highlight_off",
            style = MaterialIcon.Style.Round
        )
        val Hiking = MaterialIcon(
            name = "hiking",
            style = MaterialIcon.Style.Round
        )
        val History = MaterialIcon(
            name = "history",
            style = MaterialIcon.Style.Round
        )
        val HistoryEdu = MaterialIcon(
            name = "history_edu",
            style = MaterialIcon.Style.Round
        )
        val HistoryToggleOff = MaterialIcon(
            name = "history_toggle_off",
            style = MaterialIcon.Style.Round
        )
        val Hive = MaterialIcon(
            name = "hive",
            style = MaterialIcon.Style.Round
        )
        val Hls = MaterialIcon(
            name = "hls",
            style = MaterialIcon.Style.Round
        )
        val HlsOff = MaterialIcon(
            name = "hls_off",
            style = MaterialIcon.Style.Round
        )
        val HolidayVillage = MaterialIcon(
            name = "holiday_village",
            style = MaterialIcon.Style.Round
        )
        val Home = MaterialIcon(
            name = "home",
            style = MaterialIcon.Style.Round
        )
        val HomeMax = MaterialIcon(
            name = "home_max",
            style = MaterialIcon.Style.Round
        )
        val HomeMini = MaterialIcon(
            name = "home_mini",
            style = MaterialIcon.Style.Round
        )
        val HomeRepairService = MaterialIcon(
            name = "home_repair_service",
            style = MaterialIcon.Style.Round
        )
        val HomeWork = MaterialIcon(
            name = "home_work",
            style = MaterialIcon.Style.Round
        )
        val HorizontalDistribute = MaterialIcon(
            name = "horizontal_distribute",
            style = MaterialIcon.Style.Round
        )
        val HorizontalRule = MaterialIcon(
            name = "horizontal_rule",
            style = MaterialIcon.Style.Round
        )
        val HorizontalSplit = MaterialIcon(
            name = "horizontal_split",
            style = MaterialIcon.Style.Round
        )
        val HotTub = MaterialIcon(
            name = "hot_tub",
            style = MaterialIcon.Style.Round
        )
        val Hotel = MaterialIcon(
            name = "hotel",
            style = MaterialIcon.Style.Round
        )
        val HotelClass = MaterialIcon(
            name = "hotel_class",
            style = MaterialIcon.Style.Round
        )
        val HourglassBottom = MaterialIcon(
            name = "hourglass_bottom",
            style = MaterialIcon.Style.Round
        )
        val HourglassDisabled = MaterialIcon(
            name = "hourglass_disabled",
            style = MaterialIcon.Style.Round
        )
        val HourglassEmpty = MaterialIcon(
            name = "hourglass_empty",
            style = MaterialIcon.Style.Round
        )
        val HourglassFull = MaterialIcon(
            name = "hourglass_full",
            style = MaterialIcon.Style.Round
        )
        val HourglassTop = MaterialIcon(
            name = "hourglass_top",
            style = MaterialIcon.Style.Round
        )
        val House = MaterialIcon(
            name = "house",
            style = MaterialIcon.Style.Round
        )
        val HouseSiding = MaterialIcon(
            name = "house_siding",
            style = MaterialIcon.Style.Round
        )
        val Houseboat = MaterialIcon(
            name = "houseboat",
            style = MaterialIcon.Style.Round
        )
        val HowToReg = MaterialIcon(
            name = "how_to_reg",
            style = MaterialIcon.Style.Round
        )
        val HowToVote = MaterialIcon(
            name = "how_to_vote",
            style = MaterialIcon.Style.Round
        )
        val Html = MaterialIcon(
            name = "html",
            style = MaterialIcon.Style.Round
        )
        val Http = MaterialIcon(
            name = "http",
            style = MaterialIcon.Style.Round
        )
        val Https = MaterialIcon(
            name = "https",
            style = MaterialIcon.Style.Round
        )
        val Hub = MaterialIcon(
            name = "hub",
            style = MaterialIcon.Style.Round
        )
        val Hvac = MaterialIcon(
            name = "hvac",
            style = MaterialIcon.Style.Round
        )
        val IceSkating = MaterialIcon(
            name = "ice_skating",
            style = MaterialIcon.Style.Round
        )
        val Icecream = MaterialIcon(
            name = "icecream",
            style = MaterialIcon.Style.Round
        )
        val Image = MaterialIcon(
            name = "image",
            style = MaterialIcon.Style.Round
        )
        val ImageAspectRatio = MaterialIcon(
            name = "image_aspect_ratio",
            style = MaterialIcon.Style.Round
        )
        val ImageNotSupported = MaterialIcon(
            name = "image_not_supported",
            style = MaterialIcon.Style.Round
        )
        val ImageSearch = MaterialIcon(
            name = "image_search",
            style = MaterialIcon.Style.Round
        )
        val ImagesearchRoller = MaterialIcon(
            name = "imagesearch_roller",
            style = MaterialIcon.Style.Round
        )
        val ImportContacts = MaterialIcon(
            name = "import_contacts",
            style = MaterialIcon.Style.Round
        )
        val ImportExport = MaterialIcon(
            name = "import_export",
            style = MaterialIcon.Style.Round
        )
        val ImportantDevices = MaterialIcon(
            name = "important_devices",
            style = MaterialIcon.Style.Round
        )
        val Inbox = MaterialIcon(
            name = "inbox",
            style = MaterialIcon.Style.Round
        )
        val IncompleteCircle = MaterialIcon(
            name = "incomplete_circle",
            style = MaterialIcon.Style.Round
        )
        val IndeterminateCheckBox = MaterialIcon(
            name = "indeterminate_check_box",
            style = MaterialIcon.Style.Round
        )
        val Info = MaterialIcon(
            name = "info",
            style = MaterialIcon.Style.Round
        )
        val Input = MaterialIcon(
            name = "input",
            style = MaterialIcon.Style.Round
        )
        val InsertChart = MaterialIcon(
            name = "insert_chart",
            style = MaterialIcon.Style.Round
        )
        val InsertChartOutlined = MaterialIcon(
            name = "insert_chart_outlined",
            style = MaterialIcon.Style.Round
        )
        val InsertComment = MaterialIcon(
            name = "insert_comment",
            style = MaterialIcon.Style.Round
        )
        val InsertDriveFile = MaterialIcon(
            name = "insert_drive_file",
            style = MaterialIcon.Style.Round
        )
        val InsertEmoticon = MaterialIcon(
            name = "insert_emoticon",
            style = MaterialIcon.Style.Round
        )
        val InsertInvitation = MaterialIcon(
            name = "insert_invitation",
            style = MaterialIcon.Style.Round
        )
        val InsertLink = MaterialIcon(
            name = "insert_link",
            style = MaterialIcon.Style.Round
        )
        val InsertPageBreak = MaterialIcon(
            name = "insert_page_break",
            style = MaterialIcon.Style.Round
        )
        val InsertPhoto = MaterialIcon(
            name = "insert_photo",
            style = MaterialIcon.Style.Round
        )
        val Insights = MaterialIcon(
            name = "insights",
            style = MaterialIcon.Style.Round
        )
        val InstallDesktop = MaterialIcon(
            name = "install_desktop",
            style = MaterialIcon.Style.Round
        )
        val InstallMobile = MaterialIcon(
            name = "install_mobile",
            style = MaterialIcon.Style.Round
        )
        val IntegrationInstructions = MaterialIcon(
            name = "integration_instructions",
            style = MaterialIcon.Style.Round
        )
        val Interests = MaterialIcon(
            name = "interests",
            style = MaterialIcon.Style.Round
        )
        val InterpreterMode = MaterialIcon(
            name = "interpreter_mode",
            style = MaterialIcon.Style.Round
        )
        val Inventory = MaterialIcon(
            name = "inventory",
            style = MaterialIcon.Style.Round
        )
        val Inventory2 = MaterialIcon(
            name = "inventory_2",
            style = MaterialIcon.Style.Round
        )
        val InvertColors = MaterialIcon(
            name = "invert_colors",
            style = MaterialIcon.Style.Round
        )
        val InvertColorsOff = MaterialIcon(
            name = "invert_colors_off",
            style = MaterialIcon.Style.Round
        )
        val IosShare = MaterialIcon(
            name = "ios_share",
            style = MaterialIcon.Style.Round
        )
        val Iron = MaterialIcon(
            name = "iron",
            style = MaterialIcon.Style.Round
        )
        val Iso = MaterialIcon(
            name = "iso",
            style = MaterialIcon.Style.Round
        )
        val Javascript = MaterialIcon(
            name = "javascript",
            style = MaterialIcon.Style.Round
        )
        val JoinFull = MaterialIcon(
            name = "join_full",
            style = MaterialIcon.Style.Round
        )
        val JoinInner = MaterialIcon(
            name = "join_inner",
            style = MaterialIcon.Style.Round
        )
        val JoinLeft = MaterialIcon(
            name = "join_left",
            style = MaterialIcon.Style.Round
        )
        val JoinRight = MaterialIcon(
            name = "join_right",
            style = MaterialIcon.Style.Round
        )
        val Kayaking = MaterialIcon(
            name = "kayaking",
            style = MaterialIcon.Style.Round
        )
        val KebabDining = MaterialIcon(
            name = "kebab_dining",
            style = MaterialIcon.Style.Round
        )
        val Key = MaterialIcon(
            name = "key",
            style = MaterialIcon.Style.Round
        )
        val KeyOff = MaterialIcon(
            name = "key_off",
            style = MaterialIcon.Style.Round
        )
        val Keyboard = MaterialIcon(
            name = "keyboard",
            style = MaterialIcon.Style.Round
        )
        val KeyboardAlt = MaterialIcon(
            name = "keyboard_alt",
            style = MaterialIcon.Style.Round
        )
        val KeyboardArrowDown = MaterialIcon(
            name = "keyboard_arrow_down",
            style = MaterialIcon.Style.Round
        )
        val KeyboardArrowLeft = MaterialIcon(
            name = "keyboard_arrow_left",
            style = MaterialIcon.Style.Round
        )
        val KeyboardArrowRight = MaterialIcon(
            name = "keyboard_arrow_right",
            style = MaterialIcon.Style.Round
        )
        val KeyboardArrowUp = MaterialIcon(
            name = "keyboard_arrow_up",
            style = MaterialIcon.Style.Round
        )
        val KeyboardBackspace = MaterialIcon(
            name = "keyboard_backspace",
            style = MaterialIcon.Style.Round
        )
        val KeyboardCapslock = MaterialIcon(
            name = "keyboard_capslock",
            style = MaterialIcon.Style.Round
        )
        val KeyboardCommandKey = MaterialIcon(
            name = "keyboard_command_key",
            style = MaterialIcon.Style.Round
        )
        val KeyboardControlKey = MaterialIcon(
            name = "keyboard_control_key",
            style = MaterialIcon.Style.Round
        )
        val KeyboardDoubleArrowDown = MaterialIcon(
            name = "keyboard_double_arrow_down",
            style = MaterialIcon.Style.Round
        )
        val KeyboardDoubleArrowLeft = MaterialIcon(
            name = "keyboard_double_arrow_left",
            style = MaterialIcon.Style.Round
        )
        val KeyboardDoubleArrowRight = MaterialIcon(
            name = "keyboard_double_arrow_right",
            style = MaterialIcon.Style.Round
        )
        val KeyboardDoubleArrowUp = MaterialIcon(
            name = "keyboard_double_arrow_up",
            style = MaterialIcon.Style.Round
        )
        val KeyboardHide = MaterialIcon(
            name = "keyboard_hide",
            style = MaterialIcon.Style.Round
        )
        val KeyboardOptionKey = MaterialIcon(
            name = "keyboard_option_key",
            style = MaterialIcon.Style.Round
        )
        val KeyboardReturn = MaterialIcon(
            name = "keyboard_return",
            style = MaterialIcon.Style.Round
        )
        val KeyboardTab = MaterialIcon(
            name = "keyboard_tab",
            style = MaterialIcon.Style.Round
        )
        val KeyboardVoice = MaterialIcon(
            name = "keyboard_voice",
            style = MaterialIcon.Style.Round
        )
        val KingBed = MaterialIcon(
            name = "king_bed",
            style = MaterialIcon.Style.Round
        )
        val Kitchen = MaterialIcon(
            name = "kitchen",
            style = MaterialIcon.Style.Round
        )
        val Kitesurfing = MaterialIcon(
            name = "kitesurfing",
            style = MaterialIcon.Style.Round
        )
        val Label = MaterialIcon(
            name = "label",
            style = MaterialIcon.Style.Round
        )
        val LabelImportant = MaterialIcon(
            name = "label_important",
            style = MaterialIcon.Style.Round
        )
        val LabelOff = MaterialIcon(
            name = "label_off",
            style = MaterialIcon.Style.Round
        )
        val Lan = MaterialIcon(
            name = "lan",
            style = MaterialIcon.Style.Round
        )
        val Landscape = MaterialIcon(
            name = "landscape",
            style = MaterialIcon.Style.Round
        )
        val Landslide = MaterialIcon(
            name = "landslide",
            style = MaterialIcon.Style.Round
        )
        val Language = MaterialIcon(
            name = "language",
            style = MaterialIcon.Style.Round
        )
        val Laptop = MaterialIcon(
            name = "laptop",
            style = MaterialIcon.Style.Round
        )
        val LaptopChromebook = MaterialIcon(
            name = "laptop_chromebook",
            style = MaterialIcon.Style.Round
        )
        val LaptopMac = MaterialIcon(
            name = "laptop_mac",
            style = MaterialIcon.Style.Round
        )
        val LaptopWindows = MaterialIcon(
            name = "laptop_windows",
            style = MaterialIcon.Style.Round
        )
        val LastPage = MaterialIcon(
            name = "last_page",
            style = MaterialIcon.Style.Round
        )
        val Launch = MaterialIcon(
            name = "launch",
            style = MaterialIcon.Style.Round
        )
        val Layers = MaterialIcon(
            name = "layers",
            style = MaterialIcon.Style.Round
        )
        val LayersClear = MaterialIcon(
            name = "layers_clear",
            style = MaterialIcon.Style.Round
        )
        val Leaderboard = MaterialIcon(
            name = "leaderboard",
            style = MaterialIcon.Style.Round
        )
        val LeakAdd = MaterialIcon(
            name = "leak_add",
            style = MaterialIcon.Style.Round
        )
        val LeakRemove = MaterialIcon(
            name = "leak_remove",
            style = MaterialIcon.Style.Round
        )
        val LegendToggle = MaterialIcon(
            name = "legend_toggle",
            style = MaterialIcon.Style.Round
        )
        val Lens = MaterialIcon(
            name = "lens",
            style = MaterialIcon.Style.Round
        )
        val LensBlur = MaterialIcon(
            name = "lens_blur",
            style = MaterialIcon.Style.Round
        )
        val LibraryAdd = MaterialIcon(
            name = "library_add",
            style = MaterialIcon.Style.Round
        )
        val LibraryAddCheck = MaterialIcon(
            name = "library_add_check",
            style = MaterialIcon.Style.Round
        )
        val LibraryBooks = MaterialIcon(
            name = "library_books",
            style = MaterialIcon.Style.Round
        )
        val LibraryMusic = MaterialIcon(
            name = "library_music",
            style = MaterialIcon.Style.Round
        )
        val Light = MaterialIcon(
            name = "light",
            style = MaterialIcon.Style.Round
        )
        val LightMode = MaterialIcon(
            name = "light_mode",
            style = MaterialIcon.Style.Round
        )
        val Lightbulb = MaterialIcon(
            name = "lightbulb",
            style = MaterialIcon.Style.Round
        )
        val LightbulbCircle = MaterialIcon(
            name = "lightbulb_circle",
            style = MaterialIcon.Style.Round
        )
        val LineAxis = MaterialIcon(
            name = "line_axis",
            style = MaterialIcon.Style.Round
        )
        val LineStyle = MaterialIcon(
            name = "line_style",
            style = MaterialIcon.Style.Round
        )
        val LineWeight = MaterialIcon(
            name = "line_weight",
            style = MaterialIcon.Style.Round
        )
        val LinearScale = MaterialIcon(
            name = "linear_scale",
            style = MaterialIcon.Style.Round
        )
        val Link = MaterialIcon(
            name = "link",
            style = MaterialIcon.Style.Round
        )
        val LinkOff = MaterialIcon(
            name = "link_off",
            style = MaterialIcon.Style.Round
        )
        val LinkedCamera = MaterialIcon(
            name = "linked_camera",
            style = MaterialIcon.Style.Round
        )
        val Liquor = MaterialIcon(
            name = "liquor",
            style = MaterialIcon.Style.Round
        )
        val List = MaterialIcon(
            name = "list",
            style = MaterialIcon.Style.Round
        )
        val ListAlt = MaterialIcon(
            name = "list_alt",
            style = MaterialIcon.Style.Round
        )
        val LiveHelp = MaterialIcon(
            name = "live_help",
            style = MaterialIcon.Style.Round
        )
        val LiveTv = MaterialIcon(
            name = "live_tv",
            style = MaterialIcon.Style.Round
        )
        val Living = MaterialIcon(
            name = "living",
            style = MaterialIcon.Style.Round
        )
        val LocalActivity = MaterialIcon(
            name = "local_activity",
            style = MaterialIcon.Style.Round
        )
        val LocalAirport = MaterialIcon(
            name = "local_airport",
            style = MaterialIcon.Style.Round
        )
        val LocalAtm = MaterialIcon(
            name = "local_atm",
            style = MaterialIcon.Style.Round
        )
        val LocalBar = MaterialIcon(
            name = "local_bar",
            style = MaterialIcon.Style.Round
        )
        val LocalCafe = MaterialIcon(
            name = "local_cafe",
            style = MaterialIcon.Style.Round
        )
        val LocalCarWash = MaterialIcon(
            name = "local_car_wash",
            style = MaterialIcon.Style.Round
        )
        val LocalConvenienceStore = MaterialIcon(
            name = "local_convenience_store",
            style = MaterialIcon.Style.Round
        )
        val LocalDining = MaterialIcon(
            name = "local_dining",
            style = MaterialIcon.Style.Round
        )
        val LocalDrink = MaterialIcon(
            name = "local_drink",
            style = MaterialIcon.Style.Round
        )
        val LocalFireDepartment = MaterialIcon(
            name = "local_fire_department",
            style = MaterialIcon.Style.Round
        )
        val LocalFlorist = MaterialIcon(
            name = "local_florist",
            style = MaterialIcon.Style.Round
        )
        val LocalGasStation = MaterialIcon(
            name = "local_gas_station",
            style = MaterialIcon.Style.Round
        )
        val LocalGroceryStore = MaterialIcon(
            name = "local_grocery_store",
            style = MaterialIcon.Style.Round
        )
        val LocalHospital = MaterialIcon(
            name = "local_hospital",
            style = MaterialIcon.Style.Round
        )
        val LocalHotel = MaterialIcon(
            name = "local_hotel",
            style = MaterialIcon.Style.Round
        )
        val LocalLaundryService = MaterialIcon(
            name = "local_laundry_service",
            style = MaterialIcon.Style.Round
        )
        val LocalLibrary = MaterialIcon(
            name = "local_library",
            style = MaterialIcon.Style.Round
        )
        val LocalMall = MaterialIcon(
            name = "local_mall",
            style = MaterialIcon.Style.Round
        )
        val LocalMovies = MaterialIcon(
            name = "local_movies",
            style = MaterialIcon.Style.Round
        )
        val LocalOffer = MaterialIcon(
            name = "local_offer",
            style = MaterialIcon.Style.Round
        )
        val LocalParking = MaterialIcon(
            name = "local_parking",
            style = MaterialIcon.Style.Round
        )
        val LocalPharmacy = MaterialIcon(
            name = "local_pharmacy",
            style = MaterialIcon.Style.Round
        )
        val LocalPhone = MaterialIcon(
            name = "local_phone",
            style = MaterialIcon.Style.Round
        )
        val LocalPizza = MaterialIcon(
            name = "local_pizza",
            style = MaterialIcon.Style.Round
        )
        val LocalPlay = MaterialIcon(
            name = "local_play",
            style = MaterialIcon.Style.Round
        )
        val LocalPolice = MaterialIcon(
            name = "local_police",
            style = MaterialIcon.Style.Round
        )
        val LocalPostOffice = MaterialIcon(
            name = "local_post_office",
            style = MaterialIcon.Style.Round
        )
        val LocalPrintshop = MaterialIcon(
            name = "local_printshop",
            style = MaterialIcon.Style.Round
        )
        val LocalSee = MaterialIcon(
            name = "local_see",
            style = MaterialIcon.Style.Round
        )
        val LocalShipping = MaterialIcon(
            name = "local_shipping",
            style = MaterialIcon.Style.Round
        )
        val LocalTaxi = MaterialIcon(
            name = "local_taxi",
            style = MaterialIcon.Style.Round
        )
        val LocationCity = MaterialIcon(
            name = "location_city",
            style = MaterialIcon.Style.Round
        )
        val LocationDisabled = MaterialIcon(
            name = "location_disabled",
            style = MaterialIcon.Style.Round
        )
        val LocationOff = MaterialIcon(
            name = "location_off",
            style = MaterialIcon.Style.Round
        )
        val LocationOn = MaterialIcon(
            name = "location_on",
            style = MaterialIcon.Style.Round
        )
        val LocationSearching = MaterialIcon(
            name = "location_searching",
            style = MaterialIcon.Style.Round
        )
        val Lock = MaterialIcon(
            name = "lock",
            style = MaterialIcon.Style.Round
        )
        val LockClock = MaterialIcon(
            name = "lock_clock",
            style = MaterialIcon.Style.Round
        )
        val LockOpen = MaterialIcon(
            name = "lock_open",
            style = MaterialIcon.Style.Round
        )
        val LockPerson = MaterialIcon(
            name = "lock_person",
            style = MaterialIcon.Style.Round
        )
        val LockReset = MaterialIcon(
            name = "lock_reset",
            style = MaterialIcon.Style.Round
        )
        val Login = MaterialIcon(
            name = "login",
            style = MaterialIcon.Style.Round
        )
        val LogoDev = MaterialIcon(
            name = "logo_dev",
            style = MaterialIcon.Style.Round
        )
        val Logout = MaterialIcon(
            name = "logout",
            style = MaterialIcon.Style.Round
        )
        val Looks = MaterialIcon(
            name = "looks",
            style = MaterialIcon.Style.Round
        )
        val Looks3 = MaterialIcon(
            name = "looks_3",
            style = MaterialIcon.Style.Round
        )
        val Looks4 = MaterialIcon(
            name = "looks_4",
            style = MaterialIcon.Style.Round
        )
        val Looks5 = MaterialIcon(
            name = "looks_5",
            style = MaterialIcon.Style.Round
        )
        val Looks6 = MaterialIcon(
            name = "looks_6",
            style = MaterialIcon.Style.Round
        )
        val LooksOne = MaterialIcon(
            name = "looks_one",
            style = MaterialIcon.Style.Round
        )
        val LooksTwo = MaterialIcon(
            name = "looks_two",
            style = MaterialIcon.Style.Round
        )
        val Loop = MaterialIcon(
            name = "loop",
            style = MaterialIcon.Style.Round
        )
        val Loupe = MaterialIcon(
            name = "loupe",
            style = MaterialIcon.Style.Round
        )
        val LowPriority = MaterialIcon(
            name = "low_priority",
            style = MaterialIcon.Style.Round
        )
        val Loyalty = MaterialIcon(
            name = "loyalty",
            style = MaterialIcon.Style.Round
        )
        val LteMobiledata = MaterialIcon(
            name = "lte_mobiledata",
            style = MaterialIcon.Style.Round
        )
        val LtePlusMobiledata = MaterialIcon(
            name = "lte_plus_mobiledata",
            style = MaterialIcon.Style.Round
        )
        val Luggage = MaterialIcon(
            name = "luggage",
            style = MaterialIcon.Style.Round
        )
        val LunchDining = MaterialIcon(
            name = "lunch_dining",
            style = MaterialIcon.Style.Round
        )
        val Lyrics = MaterialIcon(
            name = "lyrics",
            style = MaterialIcon.Style.Round
        )
        val MacroOff = MaterialIcon(
            name = "macro_off",
            style = MaterialIcon.Style.Round
        )
        val Mail = MaterialIcon(
            name = "mail",
            style = MaterialIcon.Style.Round
        )
        val MailLock = MaterialIcon(
            name = "mail_lock",
            style = MaterialIcon.Style.Round
        )
        val MailOutline = MaterialIcon(
            name = "mail_outline",
            style = MaterialIcon.Style.Round
        )
        val Male = MaterialIcon(
            name = "male",
            style = MaterialIcon.Style.Round
        )
        val Man = MaterialIcon(
            name = "man",
            style = MaterialIcon.Style.Round
        )
        val Man2 = MaterialIcon(
            name = "man_2",
            style = MaterialIcon.Style.Round
        )
        val Man3 = MaterialIcon(
            name = "man_3",
            style = MaterialIcon.Style.Round
        )
        val Man4 = MaterialIcon(
            name = "man_4",
            style = MaterialIcon.Style.Round
        )
        val ManageAccounts = MaterialIcon(
            name = "manage_accounts",
            style = MaterialIcon.Style.Round
        )
        val ManageHistory = MaterialIcon(
            name = "manage_history",
            style = MaterialIcon.Style.Round
        )
        val ManageSearch = MaterialIcon(
            name = "manage_search",
            style = MaterialIcon.Style.Round
        )
        val Map = MaterialIcon(
            name = "map",
            style = MaterialIcon.Style.Round
        )
        val MapsHomeWork = MaterialIcon(
            name = "maps_home_work",
            style = MaterialIcon.Style.Round
        )
        val MapsUgc = MaterialIcon(
            name = "maps_ugc",
            style = MaterialIcon.Style.Round
        )
        val Margin = MaterialIcon(
            name = "margin",
            style = MaterialIcon.Style.Round
        )
        val MarkAsUnread = MaterialIcon(
            name = "mark_as_unread",
            style = MaterialIcon.Style.Round
        )
        val MarkChatRead = MaterialIcon(
            name = "mark_chat_read",
            style = MaterialIcon.Style.Round
        )
        val MarkChatUnread = MaterialIcon(
            name = "mark_chat_unread",
            style = MaterialIcon.Style.Round
        )
        val MarkEmailRead = MaterialIcon(
            name = "mark_email_read",
            style = MaterialIcon.Style.Round
        )
        val MarkEmailUnread = MaterialIcon(
            name = "mark_email_unread",
            style = MaterialIcon.Style.Round
        )
        val MarkUnreadChatAlt = MaterialIcon(
            name = "mark_unread_chat_alt",
            style = MaterialIcon.Style.Round
        )
        val Markunread = MaterialIcon(
            name = "markunread",
            style = MaterialIcon.Style.Round
        )
        val MarkunreadMailbox = MaterialIcon(
            name = "markunread_mailbox",
            style = MaterialIcon.Style.Round
        )
        val Masks = MaterialIcon(
            name = "masks",
            style = MaterialIcon.Style.Round
        )
        val Maximize = MaterialIcon(
            name = "maximize",
            style = MaterialIcon.Style.Round
        )
        val MediaBluetoothOff = MaterialIcon(
            name = "media_bluetooth_off",
            style = MaterialIcon.Style.Round
        )
        val MediaBluetoothOn = MaterialIcon(
            name = "media_bluetooth_on",
            style = MaterialIcon.Style.Round
        )
        val Mediation = MaterialIcon(
            name = "mediation",
            style = MaterialIcon.Style.Round
        )
        val MedicalInformation = MaterialIcon(
            name = "medical_information",
            style = MaterialIcon.Style.Round
        )
        val MedicalServices = MaterialIcon(
            name = "medical_services",
            style = MaterialIcon.Style.Round
        )
        val Medication = MaterialIcon(
            name = "medication",
            style = MaterialIcon.Style.Round
        )
        val MedicationLiquid = MaterialIcon(
            name = "medication_liquid",
            style = MaterialIcon.Style.Round
        )
        val MeetingRoom = MaterialIcon(
            name = "meeting_room",
            style = MaterialIcon.Style.Round
        )
        val Memory = MaterialIcon(
            name = "memory",
            style = MaterialIcon.Style.Round
        )
        val Menu = MaterialIcon(
            name = "menu",
            style = MaterialIcon.Style.Round
        )
        val MenuBook = MaterialIcon(
            name = "menu_book",
            style = MaterialIcon.Style.Round
        )
        val MenuOpen = MaterialIcon(
            name = "menu_open",
            style = MaterialIcon.Style.Round
        )
        val Merge = MaterialIcon(
            name = "merge",
            style = MaterialIcon.Style.Round
        )
        val MergeType = MaterialIcon(
            name = "merge_type",
            style = MaterialIcon.Style.Round
        )
        val Message = MaterialIcon(
            name = "message",
            style = MaterialIcon.Style.Round
        )
        val Mic = MaterialIcon(
            name = "mic",
            style = MaterialIcon.Style.Round
        )
        val MicExternalOff = MaterialIcon(
            name = "mic_external_off",
            style = MaterialIcon.Style.Round
        )
        val MicExternalOn = MaterialIcon(
            name = "mic_external_on",
            style = MaterialIcon.Style.Round
        )
        val MicNone = MaterialIcon(
            name = "mic_none",
            style = MaterialIcon.Style.Round
        )
        val MicOff = MaterialIcon(
            name = "mic_off",
            style = MaterialIcon.Style.Round
        )
        val Microwave = MaterialIcon(
            name = "microwave",
            style = MaterialIcon.Style.Round
        )
        val MilitaryTech = MaterialIcon(
            name = "military_tech",
            style = MaterialIcon.Style.Round
        )
        val Minimize = MaterialIcon(
            name = "minimize",
            style = MaterialIcon.Style.Round
        )
        val MinorCrash = MaterialIcon(
            name = "minor_crash",
            style = MaterialIcon.Style.Round
        )
        val MiscellaneousServices = MaterialIcon(
            name = "miscellaneous_services",
            style = MaterialIcon.Style.Round
        )
        val MissedVideoCall = MaterialIcon(
            name = "missed_video_call",
            style = MaterialIcon.Style.Round
        )
        val Mms = MaterialIcon(
            name = "mms",
            style = MaterialIcon.Style.Round
        )
        val MobileFriendly = MaterialIcon(
            name = "mobile_friendly",
            style = MaterialIcon.Style.Round
        )
        val MobileOff = MaterialIcon(
            name = "mobile_off",
            style = MaterialIcon.Style.Round
        )
        val MobileScreenShare = MaterialIcon(
            name = "mobile_screen_share",
            style = MaterialIcon.Style.Round
        )
        val MobiledataOff = MaterialIcon(
            name = "mobiledata_off",
            style = MaterialIcon.Style.Round
        )
        val Mode = MaterialIcon(
            name = "mode",
            style = MaterialIcon.Style.Round
        )
        val ModeComment = MaterialIcon(
            name = "mode_comment",
            style = MaterialIcon.Style.Round
        )
        val ModeEdit = MaterialIcon(
            name = "mode_edit",
            style = MaterialIcon.Style.Round
        )
        val ModeEditOutline = MaterialIcon(
            name = "mode_edit_outline",
            style = MaterialIcon.Style.Round
        )
        val ModeFanOff = MaterialIcon(
            name = "mode_fan_off",
            style = MaterialIcon.Style.Round
        )
        val ModeNight = MaterialIcon(
            name = "mode_night",
            style = MaterialIcon.Style.Round
        )
        val ModeOfTravel = MaterialIcon(
            name = "mode_of_travel",
            style = MaterialIcon.Style.Round
        )
        val ModeStandby = MaterialIcon(
            name = "mode_standby",
            style = MaterialIcon.Style.Round
        )
        val ModelTraining = MaterialIcon(
            name = "model_training",
            style = MaterialIcon.Style.Round
        )
        val MonetizationOn = MaterialIcon(
            name = "monetization_on",
            style = MaterialIcon.Style.Round
        )
        val Money = MaterialIcon(
            name = "money",
            style = MaterialIcon.Style.Round
        )
        val MoneyOff = MaterialIcon(
            name = "money_off",
            style = MaterialIcon.Style.Round
        )
        val MoneyOffCsred = MaterialIcon(
            name = "money_off_csred",
            style = MaterialIcon.Style.Round
        )
        val Monitor = MaterialIcon(
            name = "monitor",
            style = MaterialIcon.Style.Round
        )
        val MonitorHeart = MaterialIcon(
            name = "monitor_heart",
            style = MaterialIcon.Style.Round
        )
        val MonitorWeight = MaterialIcon(
            name = "monitor_weight",
            style = MaterialIcon.Style.Round
        )
        val MonochromePhotos = MaterialIcon(
            name = "monochrome_photos",
            style = MaterialIcon.Style.Round
        )
        val Mood = MaterialIcon(
            name = "mood",
            style = MaterialIcon.Style.Round
        )
        val MoodBad = MaterialIcon(
            name = "mood_bad",
            style = MaterialIcon.Style.Round
        )
        val Moped = MaterialIcon(
            name = "moped",
            style = MaterialIcon.Style.Round
        )
        val More = MaterialIcon(
            name = "more",
            style = MaterialIcon.Style.Round
        )
        val MoreHoriz = MaterialIcon(
            name = "more_horiz",
            style = MaterialIcon.Style.Round
        )
        val MoreTime = MaterialIcon(
            name = "more_time",
            style = MaterialIcon.Style.Round
        )
        val MoreVert = MaterialIcon(
            name = "more_vert",
            style = MaterialIcon.Style.Round
        )
        val Mosque = MaterialIcon(
            name = "mosque",
            style = MaterialIcon.Style.Round
        )
        val MotionPhotosAuto = MaterialIcon(
            name = "motion_photos_auto",
            style = MaterialIcon.Style.Round
        )
        val MotionPhotosOff = MaterialIcon(
            name = "motion_photos_off",
            style = MaterialIcon.Style.Round
        )
        val MotionPhotosOn = MaterialIcon(
            name = "motion_photos_on",
            style = MaterialIcon.Style.Round
        )
        val MotionPhotosPause = MaterialIcon(
            name = "motion_photos_pause",
            style = MaterialIcon.Style.Round
        )
        val MotionPhotosPaused = MaterialIcon(
            name = "motion_photos_paused",
            style = MaterialIcon.Style.Round
        )
        val Mouse = MaterialIcon(
            name = "mouse",
            style = MaterialIcon.Style.Round
        )
        val MoveDown = MaterialIcon(
            name = "move_down",
            style = MaterialIcon.Style.Round
        )
        val MoveToInbox = MaterialIcon(
            name = "move_to_inbox",
            style = MaterialIcon.Style.Round
        )
        val MoveUp = MaterialIcon(
            name = "move_up",
            style = MaterialIcon.Style.Round
        )
        val Movie = MaterialIcon(
            name = "movie",
            style = MaterialIcon.Style.Round
        )
        val MovieCreation = MaterialIcon(
            name = "movie_creation",
            style = MaterialIcon.Style.Round
        )
        val MovieFilter = MaterialIcon(
            name = "movie_filter",
            style = MaterialIcon.Style.Round
        )
        val Moving = MaterialIcon(
            name = "moving",
            style = MaterialIcon.Style.Round
        )
        val Mp = MaterialIcon(
            name = "mp",
            style = MaterialIcon.Style.Round
        )
        val MultilineChart = MaterialIcon(
            name = "multiline_chart",
            style = MaterialIcon.Style.Round
        )
        val MultipleStop = MaterialIcon(
            name = "multiple_stop",
            style = MaterialIcon.Style.Round
        )
        val Museum = MaterialIcon(
            name = "museum",
            style = MaterialIcon.Style.Round
        )
        val MusicNote = MaterialIcon(
            name = "music_note",
            style = MaterialIcon.Style.Round
        )
        val MusicOff = MaterialIcon(
            name = "music_off",
            style = MaterialIcon.Style.Round
        )
        val MusicVideo = MaterialIcon(
            name = "music_video",
            style = MaterialIcon.Style.Round
        )
        val MyLocation = MaterialIcon(
            name = "my_location",
            style = MaterialIcon.Style.Round
        )
        val Nat = MaterialIcon(
            name = "nat",
            style = MaterialIcon.Style.Round
        )
        val Nature = MaterialIcon(
            name = "nature",
            style = MaterialIcon.Style.Round
        )
        val NaturePeople = MaterialIcon(
            name = "nature_people",
            style = MaterialIcon.Style.Round
        )
        val NavigateBefore = MaterialIcon(
            name = "navigate_before",
            style = MaterialIcon.Style.Round
        )
        val NavigateNext = MaterialIcon(
            name = "navigate_next",
            style = MaterialIcon.Style.Round
        )
        val Navigation = MaterialIcon(
            name = "navigation",
            style = MaterialIcon.Style.Round
        )
        val NearMe = MaterialIcon(
            name = "near_me",
            style = MaterialIcon.Style.Round
        )
        val NearMeDisabled = MaterialIcon(
            name = "near_me_disabled",
            style = MaterialIcon.Style.Round
        )
        val NearbyError = MaterialIcon(
            name = "nearby_error",
            style = MaterialIcon.Style.Round
        )
        val NearbyOff = MaterialIcon(
            name = "nearby_off",
            style = MaterialIcon.Style.Round
        )
        val NestCamWiredStand = MaterialIcon(
            name = "nest_cam_wired_stand",
            style = MaterialIcon.Style.Round
        )
        val NetworkCell = MaterialIcon(
            name = "network_cell",
            style = MaterialIcon.Style.Round
        )
        val NetworkCheck = MaterialIcon(
            name = "network_check",
            style = MaterialIcon.Style.Round
        )
        val NetworkLocked = MaterialIcon(
            name = "network_locked",
            style = MaterialIcon.Style.Round
        )
        val NetworkPing = MaterialIcon(
            name = "network_ping",
            style = MaterialIcon.Style.Round
        )
        val NetworkWifi = MaterialIcon(
            name = "network_wifi",
            style = MaterialIcon.Style.Round
        )
        val NetworkWifi1Bar = MaterialIcon(
            name = "network_wifi_1_bar",
            style = MaterialIcon.Style.Round
        )
        val NetworkWifi2Bar = MaterialIcon(
            name = "network_wifi_2_bar",
            style = MaterialIcon.Style.Round
        )
        val NetworkWifi3Bar = MaterialIcon(
            name = "network_wifi_3_bar",
            style = MaterialIcon.Style.Round
        )
        val NewLabel = MaterialIcon(
            name = "new_label",
            style = MaterialIcon.Style.Round
        )
        val NewReleases = MaterialIcon(
            name = "new_releases",
            style = MaterialIcon.Style.Round
        )
        val Newspaper = MaterialIcon(
            name = "newspaper",
            style = MaterialIcon.Style.Round
        )
        val NextPlan = MaterialIcon(
            name = "next_plan",
            style = MaterialIcon.Style.Round
        )
        val NextWeek = MaterialIcon(
            name = "next_week",
            style = MaterialIcon.Style.Round
        )
        val Nfc = MaterialIcon(
            name = "nfc",
            style = MaterialIcon.Style.Round
        )
        val NightShelter = MaterialIcon(
            name = "night_shelter",
            style = MaterialIcon.Style.Round
        )
        val Nightlife = MaterialIcon(
            name = "nightlife",
            style = MaterialIcon.Style.Round
        )
        val Nightlight = MaterialIcon(
            name = "nightlight",
            style = MaterialIcon.Style.Round
        )
        val NightlightRound = MaterialIcon(
            name = "nightlight_round",
            style = MaterialIcon.Style.Round
        )
        val NightsStay = MaterialIcon(
            name = "nights_stay",
            style = MaterialIcon.Style.Round
        )
        val NoAccounts = MaterialIcon(
            name = "no_accounts",
            style = MaterialIcon.Style.Round
        )
        val NoAdultContent = MaterialIcon(
            name = "no_adult_content",
            style = MaterialIcon.Style.Round
        )
        val NoBackpack = MaterialIcon(
            name = "no_backpack",
            style = MaterialIcon.Style.Round
        )
        val NoCell = MaterialIcon(
            name = "no_cell",
            style = MaterialIcon.Style.Round
        )
        val NoCrash = MaterialIcon(
            name = "no_crash",
            style = MaterialIcon.Style.Round
        )
        val NoDrinks = MaterialIcon(
            name = "no_drinks",
            style = MaterialIcon.Style.Round
        )
        val NoEncryption = MaterialIcon(
            name = "no_encryption",
            style = MaterialIcon.Style.Round
        )
        val NoEncryptionGmailerrorred = MaterialIcon(
            name = "no_encryption_gmailerrorred",
            style = MaterialIcon.Style.Round
        )
        val NoFlash = MaterialIcon(
            name = "no_flash",
            style = MaterialIcon.Style.Round
        )
        val NoFood = MaterialIcon(
            name = "no_food",
            style = MaterialIcon.Style.Round
        )
        val NoLuggage = MaterialIcon(
            name = "no_luggage",
            style = MaterialIcon.Style.Round
        )
        val NoMeals = MaterialIcon(
            name = "no_meals",
            style = MaterialIcon.Style.Round
        )
        val NoMeetingRoom = MaterialIcon(
            name = "no_meeting_room",
            style = MaterialIcon.Style.Round
        )
        val NoPhotography = MaterialIcon(
            name = "no_photography",
            style = MaterialIcon.Style.Round
        )
        val NoSim = MaterialIcon(
            name = "no_sim",
            style = MaterialIcon.Style.Round
        )
        val NoStroller = MaterialIcon(
            name = "no_stroller",
            style = MaterialIcon.Style.Round
        )
        val NoTransfer = MaterialIcon(
            name = "no_transfer",
            style = MaterialIcon.Style.Round
        )
        val NoiseAware = MaterialIcon(
            name = "noise_aware",
            style = MaterialIcon.Style.Round
        )
        val NoiseControlOff = MaterialIcon(
            name = "noise_control_off",
            style = MaterialIcon.Style.Round
        )
        val NordicWalking = MaterialIcon(
            name = "nordic_walking",
            style = MaterialIcon.Style.Round
        )
        val North = MaterialIcon(
            name = "north",
            style = MaterialIcon.Style.Round
        )
        val NorthEast = MaterialIcon(
            name = "north_east",
            style = MaterialIcon.Style.Round
        )
        val NorthWest = MaterialIcon(
            name = "north_west",
            style = MaterialIcon.Style.Round
        )
        val NotAccessible = MaterialIcon(
            name = "not_accessible",
            style = MaterialIcon.Style.Round
        )
        val NotInterested = MaterialIcon(
            name = "not_interested",
            style = MaterialIcon.Style.Round
        )
        val NotListedLocation = MaterialIcon(
            name = "not_listed_location",
            style = MaterialIcon.Style.Round
        )
        val NotStarted = MaterialIcon(
            name = "not_started",
            style = MaterialIcon.Style.Round
        )
        val Note = MaterialIcon(
            name = "note",
            style = MaterialIcon.Style.Round
        )
        val NoteAdd = MaterialIcon(
            name = "note_add",
            style = MaterialIcon.Style.Round
        )
        val NoteAlt = MaterialIcon(
            name = "note_alt",
            style = MaterialIcon.Style.Round
        )
        val Notes = MaterialIcon(
            name = "notes",
            style = MaterialIcon.Style.Round
        )
        val NotificationAdd = MaterialIcon(
            name = "notification_add",
            style = MaterialIcon.Style.Round
        )
        val NotificationImportant = MaterialIcon(
            name = "notification_important",
            style = MaterialIcon.Style.Round
        )
        val Notifications = MaterialIcon(
            name = "notifications",
            style = MaterialIcon.Style.Round
        )
        val NotificationsActive = MaterialIcon(
            name = "notifications_active",
            style = MaterialIcon.Style.Round
        )
        val NotificationsNone = MaterialIcon(
            name = "notifications_none",
            style = MaterialIcon.Style.Round
        )
        val NotificationsOff = MaterialIcon(
            name = "notifications_off",
            style = MaterialIcon.Style.Round
        )
        val NotificationsPaused = MaterialIcon(
            name = "notifications_paused",
            style = MaterialIcon.Style.Round
        )
        val Numbers = MaterialIcon(
            name = "numbers",
            style = MaterialIcon.Style.Round
        )
        val OfflineBolt = MaterialIcon(
            name = "offline_bolt",
            style = MaterialIcon.Style.Round
        )
        val OfflinePin = MaterialIcon(
            name = "offline_pin",
            style = MaterialIcon.Style.Round
        )
        val OfflineShare = MaterialIcon(
            name = "offline_share",
            style = MaterialIcon.Style.Round
        )
        val OilBarrel = MaterialIcon(
            name = "oil_barrel",
            style = MaterialIcon.Style.Round
        )
        val OnDeviceTraining = MaterialIcon(
            name = "on_device_training",
            style = MaterialIcon.Style.Round
        )
        val OndemandVideo = MaterialIcon(
            name = "ondemand_video",
            style = MaterialIcon.Style.Round
        )
        val OnlinePrediction = MaterialIcon(
            name = "online_prediction",
            style = MaterialIcon.Style.Round
        )
        val Opacity = MaterialIcon(
            name = "opacity",
            style = MaterialIcon.Style.Round
        )
        val OpenInBrowser = MaterialIcon(
            name = "open_in_browser",
            style = MaterialIcon.Style.Round
        )
        val OpenInFull = MaterialIcon(
            name = "open_in_full",
            style = MaterialIcon.Style.Round
        )
        val OpenInNew = MaterialIcon(
            name = "open_in_new",
            style = MaterialIcon.Style.Round
        )
        val OpenInNewOff = MaterialIcon(
            name = "open_in_new_off",
            style = MaterialIcon.Style.Round
        )
        val OpenWith = MaterialIcon(
            name = "open_with",
            style = MaterialIcon.Style.Round
        )
        val OtherHouses = MaterialIcon(
            name = "other_houses",
            style = MaterialIcon.Style.Round
        )
        val Outbound = MaterialIcon(
            name = "outbound",
            style = MaterialIcon.Style.Round
        )
        val Outbox = MaterialIcon(
            name = "outbox",
            style = MaterialIcon.Style.Round
        )
        val OutdoorGrill = MaterialIcon(
            name = "outdoor_grill",
            style = MaterialIcon.Style.Round
        )
        val Outlet = MaterialIcon(
            name = "outlet",
            style = MaterialIcon.Style.Round
        )
        val OutlinedFlag = MaterialIcon(
            name = "outlined_flag",
            style = MaterialIcon.Style.Round
        )
        val Output = MaterialIcon(
            name = "output",
            style = MaterialIcon.Style.Round
        )
        val Padding = MaterialIcon(
            name = "padding",
            style = MaterialIcon.Style.Round
        )
        val Pages = MaterialIcon(
            name = "pages",
            style = MaterialIcon.Style.Round
        )
        val Pageview = MaterialIcon(
            name = "pageview",
            style = MaterialIcon.Style.Round
        )
        val Paid = MaterialIcon(
            name = "paid",
            style = MaterialIcon.Style.Round
        )
        val Palette = MaterialIcon(
            name = "palette",
            style = MaterialIcon.Style.Round
        )
        val PanTool = MaterialIcon(
            name = "pan_tool",
            style = MaterialIcon.Style.Round
        )
        val PanToolAlt = MaterialIcon(
            name = "pan_tool_alt",
            style = MaterialIcon.Style.Round
        )
        val Panorama = MaterialIcon(
            name = "panorama",
            style = MaterialIcon.Style.Round
        )
        val PanoramaFishEye = MaterialIcon(
            name = "panorama_fish_eye",
            style = MaterialIcon.Style.Round
        )
        val PanoramaHorizontal = MaterialIcon(
            name = "panorama_horizontal",
            style = MaterialIcon.Style.Round
        )
        val PanoramaHorizontalSelect = MaterialIcon(
            name = "panorama_horizontal_select",
            style = MaterialIcon.Style.Round
        )
        val PanoramaPhotosphere = MaterialIcon(
            name = "panorama_photosphere",
            style = MaterialIcon.Style.Round
        )
        val PanoramaPhotosphereSelect = MaterialIcon(
            name = "panorama_photosphere_select",
            style = MaterialIcon.Style.Round
        )
        val PanoramaVertical = MaterialIcon(
            name = "panorama_vertical",
            style = MaterialIcon.Style.Round
        )
        val PanoramaVerticalSelect = MaterialIcon(
            name = "panorama_vertical_select",
            style = MaterialIcon.Style.Round
        )
        val PanoramaWideAngle = MaterialIcon(
            name = "panorama_wide_angle",
            style = MaterialIcon.Style.Round
        )
        val PanoramaWideAngleSelect = MaterialIcon(
            name = "panorama_wide_angle_select",
            style = MaterialIcon.Style.Round
        )
        val Paragliding = MaterialIcon(
            name = "paragliding",
            style = MaterialIcon.Style.Round
        )
        val Park = MaterialIcon(
            name = "park",
            style = MaterialIcon.Style.Round
        )
        val PartyMode = MaterialIcon(
            name = "party_mode",
            style = MaterialIcon.Style.Round
        )
        val Password = MaterialIcon(
            name = "password",
            style = MaterialIcon.Style.Round
        )
        val Pattern = MaterialIcon(
            name = "pattern",
            style = MaterialIcon.Style.Round
        )
        val Pause = MaterialIcon(
            name = "pause",
            style = MaterialIcon.Style.Round
        )
        val PauseCircle = MaterialIcon(
            name = "pause_circle",
            style = MaterialIcon.Style.Round
        )
        val PauseCircleFilled = MaterialIcon(
            name = "pause_circle_filled",
            style = MaterialIcon.Style.Round
        )
        val PauseCircleOutline = MaterialIcon(
            name = "pause_circle_outline",
            style = MaterialIcon.Style.Round
        )
        val PausePresentation = MaterialIcon(
            name = "pause_presentation",
            style = MaterialIcon.Style.Round
        )
        val Payment = MaterialIcon(
            name = "payment",
            style = MaterialIcon.Style.Round
        )
        val Payments = MaterialIcon(
            name = "payments",
            style = MaterialIcon.Style.Round
        )
        val PedalBike = MaterialIcon(
            name = "pedal_bike",
            style = MaterialIcon.Style.Round
        )
        val Pending = MaterialIcon(
            name = "pending",
            style = MaterialIcon.Style.Round
        )
        val PendingActions = MaterialIcon(
            name = "pending_actions",
            style = MaterialIcon.Style.Round
        )
        val Pentagon = MaterialIcon(
            name = "pentagon",
            style = MaterialIcon.Style.Round
        )
        val People = MaterialIcon(
            name = "people",
            style = MaterialIcon.Style.Round
        )
        val PeopleAlt = MaterialIcon(
            name = "people_alt",
            style = MaterialIcon.Style.Round
        )
        val PeopleOutline = MaterialIcon(
            name = "people_outline",
            style = MaterialIcon.Style.Round
        )
        val Percent = MaterialIcon(
            name = "percent",
            style = MaterialIcon.Style.Round
        )
        val PermCameraMic = MaterialIcon(
            name = "perm_camera_mic",
            style = MaterialIcon.Style.Round
        )
        val PermContactCalendar = MaterialIcon(
            name = "perm_contact_calendar",
            style = MaterialIcon.Style.Round
        )
        val PermDataSetting = MaterialIcon(
            name = "perm_data_setting",
            style = MaterialIcon.Style.Round
        )
        val PermDeviceInformation = MaterialIcon(
            name = "perm_device_information",
            style = MaterialIcon.Style.Round
        )
        val PermIdentity = MaterialIcon(
            name = "perm_identity",
            style = MaterialIcon.Style.Round
        )
        val PermMedia = MaterialIcon(
            name = "perm_media",
            style = MaterialIcon.Style.Round
        )
        val PermPhoneMsg = MaterialIcon(
            name = "perm_phone_msg",
            style = MaterialIcon.Style.Round
        )
        val PermScanWifi = MaterialIcon(
            name = "perm_scan_wifi",
            style = MaterialIcon.Style.Round
        )
        val Person = MaterialIcon(
            name = "person",
            style = MaterialIcon.Style.Round
        )
        val Person2 = MaterialIcon(
            name = "person_2",
            style = MaterialIcon.Style.Round
        )
        val Person3 = MaterialIcon(
            name = "person_3",
            style = MaterialIcon.Style.Round
        )
        val Person4 = MaterialIcon(
            name = "person_4",
            style = MaterialIcon.Style.Round
        )
        val PersonAdd = MaterialIcon(
            name = "person_add",
            style = MaterialIcon.Style.Round
        )
        val PersonAddAlt = MaterialIcon(
            name = "person_add_alt",
            style = MaterialIcon.Style.Round
        )
        val PersonAddAlt1 = MaterialIcon(
            name = "person_add_alt_1",
            style = MaterialIcon.Style.Round
        )
        val PersonAddDisabled = MaterialIcon(
            name = "person_add_disabled",
            style = MaterialIcon.Style.Round
        )
        val PersonOff = MaterialIcon(
            name = "person_off",
            style = MaterialIcon.Style.Round
        )
        val PersonOutline = MaterialIcon(
            name = "person_outline",
            style = MaterialIcon.Style.Round
        )
        val PersonPin = MaterialIcon(
            name = "person_pin",
            style = MaterialIcon.Style.Round
        )
        val PersonPinCircle = MaterialIcon(
            name = "person_pin_circle",
            style = MaterialIcon.Style.Round
        )
        val PersonRemove = MaterialIcon(
            name = "person_remove",
            style = MaterialIcon.Style.Round
        )
        val PersonRemoveAlt1 = MaterialIcon(
            name = "person_remove_alt_1",
            style = MaterialIcon.Style.Round
        )
        val PersonSearch = MaterialIcon(
            name = "person_search",
            style = MaterialIcon.Style.Round
        )
        val PersonalInjury = MaterialIcon(
            name = "personal_injury",
            style = MaterialIcon.Style.Round
        )
        val PersonalVideo = MaterialIcon(
            name = "personal_video",
            style = MaterialIcon.Style.Round
        )
        val PestControl = MaterialIcon(
            name = "pest_control",
            style = MaterialIcon.Style.Round
        )
        val PestControlRodent = MaterialIcon(
            name = "pest_control_rodent",
            style = MaterialIcon.Style.Round
        )
        val Pets = MaterialIcon(
            name = "pets",
            style = MaterialIcon.Style.Round
        )
        val Phishing = MaterialIcon(
            name = "phishing",
            style = MaterialIcon.Style.Round
        )
        val Phone = MaterialIcon(
            name = "phone",
            style = MaterialIcon.Style.Round
        )
        val PhoneAndroid = MaterialIcon(
            name = "phone_android",
            style = MaterialIcon.Style.Round
        )
        val PhoneBluetoothSpeaker = MaterialIcon(
            name = "phone_bluetooth_speaker",
            style = MaterialIcon.Style.Round
        )
        val PhoneCallback = MaterialIcon(
            name = "phone_callback",
            style = MaterialIcon.Style.Round
        )
        val PhoneDisabled = MaterialIcon(
            name = "phone_disabled",
            style = MaterialIcon.Style.Round
        )
        val PhoneEnabled = MaterialIcon(
            name = "phone_enabled",
            style = MaterialIcon.Style.Round
        )
        val PhoneForwarded = MaterialIcon(
            name = "phone_forwarded",
            style = MaterialIcon.Style.Round
        )
        val PhoneIphone = MaterialIcon(
            name = "phone_iphone",
            style = MaterialIcon.Style.Round
        )
        val PhoneLocked = MaterialIcon(
            name = "phone_locked",
            style = MaterialIcon.Style.Round
        )
        val PhoneMissed = MaterialIcon(
            name = "phone_missed",
            style = MaterialIcon.Style.Round
        )
        val PhonePaused = MaterialIcon(
            name = "phone_paused",
            style = MaterialIcon.Style.Round
        )
        val Phonelink = MaterialIcon(
            name = "phonelink",
            style = MaterialIcon.Style.Round
        )
        val PhonelinkErase = MaterialIcon(
            name = "phonelink_erase",
            style = MaterialIcon.Style.Round
        )
        val PhonelinkLock = MaterialIcon(
            name = "phonelink_lock",
            style = MaterialIcon.Style.Round
        )
        val PhonelinkOff = MaterialIcon(
            name = "phonelink_off",
            style = MaterialIcon.Style.Round
        )
        val PhonelinkRing = MaterialIcon(
            name = "phonelink_ring",
            style = MaterialIcon.Style.Round
        )
        val PhonelinkSetup = MaterialIcon(
            name = "phonelink_setup",
            style = MaterialIcon.Style.Round
        )
        val Photo = MaterialIcon(
            name = "photo",
            style = MaterialIcon.Style.Round
        )
        val PhotoAlbum = MaterialIcon(
            name = "photo_album",
            style = MaterialIcon.Style.Round
        )
        val PhotoCamera = MaterialIcon(
            name = "photo_camera",
            style = MaterialIcon.Style.Round
        )
        val PhotoCameraBack = MaterialIcon(
            name = "photo_camera_back",
            style = MaterialIcon.Style.Round
        )
        val PhotoCameraFront = MaterialIcon(
            name = "photo_camera_front",
            style = MaterialIcon.Style.Round
        )
        val PhotoFilter = MaterialIcon(
            name = "photo_filter",
            style = MaterialIcon.Style.Round
        )
        val PhotoLibrary = MaterialIcon(
            name = "photo_library",
            style = MaterialIcon.Style.Round
        )
        val PhotoSizeSelectActual = MaterialIcon(
            name = "photo_size_select_actual",
            style = MaterialIcon.Style.Round
        )
        val PhotoSizeSelectLarge = MaterialIcon(
            name = "photo_size_select_large",
            style = MaterialIcon.Style.Round
        )
        val PhotoSizeSelectSmall = MaterialIcon(
            name = "photo_size_select_small",
            style = MaterialIcon.Style.Round
        )
        val Php = MaterialIcon(
            name = "php",
            style = MaterialIcon.Style.Round
        )
        val Piano = MaterialIcon(
            name = "piano",
            style = MaterialIcon.Style.Round
        )
        val PianoOff = MaterialIcon(
            name = "piano_off",
            style = MaterialIcon.Style.Round
        )
        val PictureAsPdf = MaterialIcon(
            name = "picture_as_pdf",
            style = MaterialIcon.Style.Round
        )
        val PictureInPicture = MaterialIcon(
            name = "picture_in_picture",
            style = MaterialIcon.Style.Round
        )
        val PictureInPictureAlt = MaterialIcon(
            name = "picture_in_picture_alt",
            style = MaterialIcon.Style.Round
        )
        val PieChart = MaterialIcon(
            name = "pie_chart",
            style = MaterialIcon.Style.Round
        )
        val PieChartOutline = MaterialIcon(
            name = "pie_chart_outline",
            style = MaterialIcon.Style.Round
        )
        val Pin = MaterialIcon(
            name = "pin",
            style = MaterialIcon.Style.Round
        )
        val PinDrop = MaterialIcon(
            name = "pin_drop",
            style = MaterialIcon.Style.Round
        )
        val PinEnd = MaterialIcon(
            name = "pin_end",
            style = MaterialIcon.Style.Round
        )
        val PinInvoke = MaterialIcon(
            name = "pin_invoke",
            style = MaterialIcon.Style.Round
        )
        val Pinch = MaterialIcon(
            name = "pinch",
            style = MaterialIcon.Style.Round
        )
        val PivotTableChart = MaterialIcon(
            name = "pivot_table_chart",
            style = MaterialIcon.Style.Round
        )
        val Pix = MaterialIcon(
            name = "pix",
            style = MaterialIcon.Style.Round
        )
        val Place = MaterialIcon(
            name = "place",
            style = MaterialIcon.Style.Round
        )
        val Plagiarism = MaterialIcon(
            name = "plagiarism",
            style = MaterialIcon.Style.Round
        )
        val PlayArrow = MaterialIcon(
            name = "play_arrow",
            style = MaterialIcon.Style.Round
        )
        val PlayCircle = MaterialIcon(
            name = "play_circle",
            style = MaterialIcon.Style.Round
        )
        val PlayCircleFilled = MaterialIcon(
            name = "play_circle_filled",
            style = MaterialIcon.Style.Round
        )
        val PlayCircleOutline = MaterialIcon(
            name = "play_circle_outline",
            style = MaterialIcon.Style.Round
        )
        val PlayDisabled = MaterialIcon(
            name = "play_disabled",
            style = MaterialIcon.Style.Round
        )
        val PlayForWork = MaterialIcon(
            name = "play_for_work",
            style = MaterialIcon.Style.Round
        )
        val PlayLesson = MaterialIcon(
            name = "play_lesson",
            style = MaterialIcon.Style.Round
        )
        val PlaylistAdd = MaterialIcon(
            name = "playlist_add",
            style = MaterialIcon.Style.Round
        )
        val PlaylistAddCheck = MaterialIcon(
            name = "playlist_add_check",
            style = MaterialIcon.Style.Round
        )
        val PlaylistAddCheckCircle = MaterialIcon(
            name = "playlist_add_check_circle",
            style = MaterialIcon.Style.Round
        )
        val PlaylistAddCircle = MaterialIcon(
            name = "playlist_add_circle",
            style = MaterialIcon.Style.Round
        )
        val PlaylistPlay = MaterialIcon(
            name = "playlist_play",
            style = MaterialIcon.Style.Round
        )
        val PlaylistRemove = MaterialIcon(
            name = "playlist_remove",
            style = MaterialIcon.Style.Round
        )
        val Plumbing = MaterialIcon(
            name = "plumbing",
            style = MaterialIcon.Style.Round
        )
        val PlusOne = MaterialIcon(
            name = "plus_one",
            style = MaterialIcon.Style.Round
        )
        val Podcasts = MaterialIcon(
            name = "podcasts",
            style = MaterialIcon.Style.Round
        )
        val PointOfSale = MaterialIcon(
            name = "point_of_sale",
            style = MaterialIcon.Style.Round
        )
        val Policy = MaterialIcon(
            name = "policy",
            style = MaterialIcon.Style.Round
        )
        val Poll = MaterialIcon(
            name = "poll",
            style = MaterialIcon.Style.Round
        )
        val Polyline = MaterialIcon(
            name = "polyline",
            style = MaterialIcon.Style.Round
        )
        val Polymer = MaterialIcon(
            name = "polymer",
            style = MaterialIcon.Style.Round
        )
        val Pool = MaterialIcon(
            name = "pool",
            style = MaterialIcon.Style.Round
        )
        val PortableWifiOff = MaterialIcon(
            name = "portable_wifi_off",
            style = MaterialIcon.Style.Round
        )
        val Portrait = MaterialIcon(
            name = "portrait",
            style = MaterialIcon.Style.Round
        )
        val PostAdd = MaterialIcon(
            name = "post_add",
            style = MaterialIcon.Style.Round
        )
        val Power = MaterialIcon(
            name = "power",
            style = MaterialIcon.Style.Round
        )
        val PowerInput = MaterialIcon(
            name = "power_input",
            style = MaterialIcon.Style.Round
        )
        val PowerOff = MaterialIcon(
            name = "power_off",
            style = MaterialIcon.Style.Round
        )
        val PowerSettingsNew = MaterialIcon(
            name = "power_settings_new",
            style = MaterialIcon.Style.Round
        )
        val PrecisionManufacturing = MaterialIcon(
            name = "precision_manufacturing",
            style = MaterialIcon.Style.Round
        )
        val PregnantWoman = MaterialIcon(
            name = "pregnant_woman",
            style = MaterialIcon.Style.Round
        )
        val PresentToAll = MaterialIcon(
            name = "present_to_all",
            style = MaterialIcon.Style.Round
        )
        val Preview = MaterialIcon(
            name = "preview",
            style = MaterialIcon.Style.Round
        )
        val PriceChange = MaterialIcon(
            name = "price_change",
            style = MaterialIcon.Style.Round
        )
        val PriceCheck = MaterialIcon(
            name = "price_check",
            style = MaterialIcon.Style.Round
        )
        val Print = MaterialIcon(
            name = "print",
            style = MaterialIcon.Style.Round
        )
        val PrintDisabled = MaterialIcon(
            name = "print_disabled",
            style = MaterialIcon.Style.Round
        )
        val PriorityHigh = MaterialIcon(
            name = "priority_high",
            style = MaterialIcon.Style.Round
        )
        val PrivacyTip = MaterialIcon(
            name = "privacy_tip",
            style = MaterialIcon.Style.Round
        )
        val PrivateConnectivity = MaterialIcon(
            name = "private_connectivity",
            style = MaterialIcon.Style.Round
        )
        val ProductionQuantityLimits = MaterialIcon(
            name = "production_quantity_limits",
            style = MaterialIcon.Style.Round
        )
        val Propane = MaterialIcon(
            name = "propane",
            style = MaterialIcon.Style.Round
        )
        val PropaneTank = MaterialIcon(
            name = "propane_tank",
            style = MaterialIcon.Style.Round
        )
        val Psychology = MaterialIcon(
            name = "psychology",
            style = MaterialIcon.Style.Round
        )
        val PsychologyAlt = MaterialIcon(
            name = "psychology_alt",
            style = MaterialIcon.Style.Round
        )
        val Public = MaterialIcon(
            name = "public",
            style = MaterialIcon.Style.Round
        )
        val PublicOff = MaterialIcon(
            name = "public_off",
            style = MaterialIcon.Style.Round
        )
        val Publish = MaterialIcon(
            name = "publish",
            style = MaterialIcon.Style.Round
        )
        val PublishedWithChanges = MaterialIcon(
            name = "published_with_changes",
            style = MaterialIcon.Style.Round
        )
        val PunchClock = MaterialIcon(
            name = "punch_clock",
            style = MaterialIcon.Style.Round
        )
        val PushPin = MaterialIcon(
            name = "push_pin",
            style = MaterialIcon.Style.Round
        )
        val QrCode = MaterialIcon(
            name = "qr_code",
            style = MaterialIcon.Style.Round
        )
        val QrCode2 = MaterialIcon(
            name = "qr_code_2",
            style = MaterialIcon.Style.Round
        )
        val QrCodeScanner = MaterialIcon(
            name = "qr_code_scanner",
            style = MaterialIcon.Style.Round
        )
        val QueryBuilder = MaterialIcon(
            name = "query_builder",
            style = MaterialIcon.Style.Round
        )
        val QueryStats = MaterialIcon(
            name = "query_stats",
            style = MaterialIcon.Style.Round
        )
        val QuestionAnswer = MaterialIcon(
            name = "question_answer",
            style = MaterialIcon.Style.Round
        )
        val QuestionMark = MaterialIcon(
            name = "question_mark",
            style = MaterialIcon.Style.Round
        )
        val Queue = MaterialIcon(
            name = "queue",
            style = MaterialIcon.Style.Round
        )
        val QueueMusic = MaterialIcon(
            name = "queue_music",
            style = MaterialIcon.Style.Round
        )
        val QueuePlayNext = MaterialIcon(
            name = "queue_play_next",
            style = MaterialIcon.Style.Round
        )
        val Quickreply = MaterialIcon(
            name = "quickreply",
            style = MaterialIcon.Style.Round
        )
        val Quiz = MaterialIcon(
            name = "quiz",
            style = MaterialIcon.Style.Round
        )
        val RMobiledata = MaterialIcon(
            name = "r_mobiledata",
            style = MaterialIcon.Style.Round
        )
        val Radar = MaterialIcon(
            name = "radar",
            style = MaterialIcon.Style.Round
        )
        val Radio = MaterialIcon(
            name = "radio",
            style = MaterialIcon.Style.Round
        )
        val RadioButtonChecked = MaterialIcon(
            name = "radio_button_checked",
            style = MaterialIcon.Style.Round
        )
        val RadioButtonUnchecked = MaterialIcon(
            name = "radio_button_unchecked",
            style = MaterialIcon.Style.Round
        )
        val RailwayAlert = MaterialIcon(
            name = "railway_alert",
            style = MaterialIcon.Style.Round
        )
        val RamenDining = MaterialIcon(
            name = "ramen_dining",
            style = MaterialIcon.Style.Round
        )
        val RampLeft = MaterialIcon(
            name = "ramp_left",
            style = MaterialIcon.Style.Round
        )
        val RampRight = MaterialIcon(
            name = "ramp_right",
            style = MaterialIcon.Style.Round
        )
        val RateReview = MaterialIcon(
            name = "rate_review",
            style = MaterialIcon.Style.Round
        )
        val RawOff = MaterialIcon(
            name = "raw_off",
            style = MaterialIcon.Style.Round
        )
        val RawOn = MaterialIcon(
            name = "raw_on",
            style = MaterialIcon.Style.Round
        )
        val ReadMore = MaterialIcon(
            name = "read_more",
            style = MaterialIcon.Style.Round
        )
        val RealEstateAgent = MaterialIcon(
            name = "real_estate_agent",
            style = MaterialIcon.Style.Round
        )
        val Receipt = MaterialIcon(
            name = "receipt",
            style = MaterialIcon.Style.Round
        )
        val ReceiptLong = MaterialIcon(
            name = "receipt_long",
            style = MaterialIcon.Style.Round
        )
        val RecentActors = MaterialIcon(
            name = "recent_actors",
            style = MaterialIcon.Style.Round
        )
        val Recommend = MaterialIcon(
            name = "recommend",
            style = MaterialIcon.Style.Round
        )
        val RecordVoiceOver = MaterialIcon(
            name = "record_voice_over",
            style = MaterialIcon.Style.Round
        )
        val Rectangle = MaterialIcon(
            name = "rectangle",
            style = MaterialIcon.Style.Round
        )
        val Recycling = MaterialIcon(
            name = "recycling",
            style = MaterialIcon.Style.Round
        )
        val Redeem = MaterialIcon(
            name = "redeem",
            style = MaterialIcon.Style.Round
        )
        val Redo = MaterialIcon(
            name = "redo",
            style = MaterialIcon.Style.Round
        )
        val ReduceCapacity = MaterialIcon(
            name = "reduce_capacity",
            style = MaterialIcon.Style.Round
        )
        val Refresh = MaterialIcon(
            name = "refresh",
            style = MaterialIcon.Style.Round
        )
        val RememberMe = MaterialIcon(
            name = "remember_me",
            style = MaterialIcon.Style.Round
        )
        val Remove = MaterialIcon(
            name = "remove",
            style = MaterialIcon.Style.Round
        )
        val RemoveCircle = MaterialIcon(
            name = "remove_circle",
            style = MaterialIcon.Style.Round
        )
        val RemoveCircleOutline = MaterialIcon(
            name = "remove_circle_outline",
            style = MaterialIcon.Style.Round
        )
        val RemoveDone = MaterialIcon(
            name = "remove_done",
            style = MaterialIcon.Style.Round
        )
        val RemoveFromQueue = MaterialIcon(
            name = "remove_from_queue",
            style = MaterialIcon.Style.Round
        )
        val RemoveModerator = MaterialIcon(
            name = "remove_moderator",
            style = MaterialIcon.Style.Round
        )
        val RemoveRedEye = MaterialIcon(
            name = "remove_red_eye",
            style = MaterialIcon.Style.Round
        )
        val RemoveRoad = MaterialIcon(
            name = "remove_road",
            style = MaterialIcon.Style.Round
        )
        val RemoveShoppingCart = MaterialIcon(
            name = "remove_shopping_cart",
            style = MaterialIcon.Style.Round
        )
        val Reorder = MaterialIcon(
            name = "reorder",
            style = MaterialIcon.Style.Round
        )
        val Repartition = MaterialIcon(
            name = "repartition",
            style = MaterialIcon.Style.Round
        )
        val Repeat = MaterialIcon(
            name = "repeat",
            style = MaterialIcon.Style.Round
        )
        val RepeatOn = MaterialIcon(
            name = "repeat_on",
            style = MaterialIcon.Style.Round
        )
        val RepeatOne = MaterialIcon(
            name = "repeat_one",
            style = MaterialIcon.Style.Round
        )
        val RepeatOneOn = MaterialIcon(
            name = "repeat_one_on",
            style = MaterialIcon.Style.Round
        )
        val Replay = MaterialIcon(
            name = "replay",
            style = MaterialIcon.Style.Round
        )
        val Replay10 = MaterialIcon(
            name = "replay_10",
            style = MaterialIcon.Style.Round
        )
        val Replay30 = MaterialIcon(
            name = "replay_30",
            style = MaterialIcon.Style.Round
        )
        val Replay5 = MaterialIcon(
            name = "replay_5",
            style = MaterialIcon.Style.Round
        )
        val ReplayCircleFilled = MaterialIcon(
            name = "replay_circle_filled",
            style = MaterialIcon.Style.Round
        )
        val Reply = MaterialIcon(
            name = "reply",
            style = MaterialIcon.Style.Round
        )
        val ReplyAll = MaterialIcon(
            name = "reply_all",
            style = MaterialIcon.Style.Round
        )
        val Report = MaterialIcon(
            name = "report",
            style = MaterialIcon.Style.Round
        )
        val ReportGmailerrorred = MaterialIcon(
            name = "report_gmailerrorred",
            style = MaterialIcon.Style.Round
        )
        val ReportOff = MaterialIcon(
            name = "report_off",
            style = MaterialIcon.Style.Round
        )
        val ReportProblem = MaterialIcon(
            name = "report_problem",
            style = MaterialIcon.Style.Round
        )
        val RequestPage = MaterialIcon(
            name = "request_page",
            style = MaterialIcon.Style.Round
        )
        val RequestQuote = MaterialIcon(
            name = "request_quote",
            style = MaterialIcon.Style.Round
        )
        val ResetTv = MaterialIcon(
            name = "reset_tv",
            style = MaterialIcon.Style.Round
        )
        val RestartAlt = MaterialIcon(
            name = "restart_alt",
            style = MaterialIcon.Style.Round
        )
        val Restaurant = MaterialIcon(
            name = "restaurant",
            style = MaterialIcon.Style.Round
        )
        val RestaurantMenu = MaterialIcon(
            name = "restaurant_menu",
            style = MaterialIcon.Style.Round
        )
        val Restore = MaterialIcon(
            name = "restore",
            style = MaterialIcon.Style.Round
        )
        val RestoreFromTrash = MaterialIcon(
            name = "restore_from_trash",
            style = MaterialIcon.Style.Round
        )
        val RestorePage = MaterialIcon(
            name = "restore_page",
            style = MaterialIcon.Style.Round
        )
        val Reviews = MaterialIcon(
            name = "reviews",
            style = MaterialIcon.Style.Round
        )
        val RiceBowl = MaterialIcon(
            name = "rice_bowl",
            style = MaterialIcon.Style.Round
        )
        val RingVolume = MaterialIcon(
            name = "ring_volume",
            style = MaterialIcon.Style.Round
        )
        val Rocket = MaterialIcon(
            name = "rocket",
            style = MaterialIcon.Style.Round
        )
        val RocketLaunch = MaterialIcon(
            name = "rocket_launch",
            style = MaterialIcon.Style.Round
        )
        val RollerShades = MaterialIcon(
            name = "roller_shades",
            style = MaterialIcon.Style.Round
        )
        val RollerShadesClosed = MaterialIcon(
            name = "roller_shades_closed",
            style = MaterialIcon.Style.Round
        )
        val RollerSkating = MaterialIcon(
            name = "roller_skating",
            style = MaterialIcon.Style.Round
        )
        val Roofing = MaterialIcon(
            name = "roofing",
            style = MaterialIcon.Style.Round
        )
        val Room = MaterialIcon(
            name = "room",
            style = MaterialIcon.Style.Round
        )
        val RoomPreferences = MaterialIcon(
            name = "room_preferences",
            style = MaterialIcon.Style.Round
        )
        val RoomService = MaterialIcon(
            name = "room_service",
            style = MaterialIcon.Style.Round
        )
        val Rotate90DegreesCcw = MaterialIcon(
            name = "rotate_90_degrees_ccw",
            style = MaterialIcon.Style.Round
        )
        val Rotate90DegreesCw = MaterialIcon(
            name = "rotate_90_degrees_cw",
            style = MaterialIcon.Style.Round
        )
        val RotateLeft = MaterialIcon(
            name = "rotate_left",
            style = MaterialIcon.Style.Round
        )
        val RotateRight = MaterialIcon(
            name = "rotate_right",
            style = MaterialIcon.Style.Round
        )
        val RoundaboutLeft = MaterialIcon(
            name = "roundabout_left",
            style = MaterialIcon.Style.Round
        )
        val RoundaboutRight = MaterialIcon(
            name = "roundabout_right",
            style = MaterialIcon.Style.Round
        )
        val RoundedCorner = MaterialIcon(
            name = "rounded_corner",
            style = MaterialIcon.Style.Round
        )
        val Route = MaterialIcon(
            name = "route",
            style = MaterialIcon.Style.Round
        )
        val Router = MaterialIcon(
            name = "router",
            style = MaterialIcon.Style.Round
        )
        val Rowing = MaterialIcon(
            name = "rowing",
            style = MaterialIcon.Style.Round
        )
        val RssFeed = MaterialIcon(
            name = "rss_feed",
            style = MaterialIcon.Style.Round
        )
        val Rsvp = MaterialIcon(
            name = "rsvp",
            style = MaterialIcon.Style.Round
        )
        val Rtt = MaterialIcon(
            name = "rtt",
            style = MaterialIcon.Style.Round
        )
        val Rule = MaterialIcon(
            name = "rule",
            style = MaterialIcon.Style.Round
        )
        val RuleFolder = MaterialIcon(
            name = "rule_folder",
            style = MaterialIcon.Style.Round
        )
        val RunCircle = MaterialIcon(
            name = "run_circle",
            style = MaterialIcon.Style.Round
        )
        val RunningWithErrors = MaterialIcon(
            name = "running_with_errors",
            style = MaterialIcon.Style.Round
        )
        val RvHookup = MaterialIcon(
            name = "rv_hookup",
            style = MaterialIcon.Style.Round
        )
        val SafetyCheck = MaterialIcon(
            name = "safety_check",
            style = MaterialIcon.Style.Round
        )
        val SafetyDivider = MaterialIcon(
            name = "safety_divider",
            style = MaterialIcon.Style.Round
        )
        val Sailing = MaterialIcon(
            name = "sailing",
            style = MaterialIcon.Style.Round
        )
        val Sanitizer = MaterialIcon(
            name = "sanitizer",
            style = MaterialIcon.Style.Round
        )
        val Satellite = MaterialIcon(
            name = "satellite",
            style = MaterialIcon.Style.Round
        )
        val SatelliteAlt = MaterialIcon(
            name = "satellite_alt",
            style = MaterialIcon.Style.Round
        )
        val Save = MaterialIcon(
            name = "save",
            style = MaterialIcon.Style.Round
        )
        val SaveAlt = MaterialIcon(
            name = "save_alt",
            style = MaterialIcon.Style.Round
        )
        val SaveAs = MaterialIcon(
            name = "save_as",
            style = MaterialIcon.Style.Round
        )
        val SavedSearch = MaterialIcon(
            name = "saved_search",
            style = MaterialIcon.Style.Round
        )
        val Savings = MaterialIcon(
            name = "savings",
            style = MaterialIcon.Style.Round
        )
        val Scale = MaterialIcon(
            name = "scale",
            style = MaterialIcon.Style.Round
        )
        val Scanner = MaterialIcon(
            name = "scanner",
            style = MaterialIcon.Style.Round
        )
        val ScatterPlot = MaterialIcon(
            name = "scatter_plot",
            style = MaterialIcon.Style.Round
        )
        val Schedule = MaterialIcon(
            name = "schedule",
            style = MaterialIcon.Style.Round
        )
        val ScheduleSend = MaterialIcon(
            name = "schedule_send",
            style = MaterialIcon.Style.Round
        )
        val Schema = MaterialIcon(
            name = "schema",
            style = MaterialIcon.Style.Round
        )
        val School = MaterialIcon(
            name = "school",
            style = MaterialIcon.Style.Round
        )
        val Science = MaterialIcon(
            name = "science",
            style = MaterialIcon.Style.Round
        )
        val Score = MaterialIcon(
            name = "score",
            style = MaterialIcon.Style.Round
        )
        val Scoreboard = MaterialIcon(
            name = "scoreboard",
            style = MaterialIcon.Style.Round
        )
        val ScreenLockLandscape = MaterialIcon(
            name = "screen_lock_landscape",
            style = MaterialIcon.Style.Round
        )
        val ScreenLockPortrait = MaterialIcon(
            name = "screen_lock_portrait",
            style = MaterialIcon.Style.Round
        )
        val ScreenLockRotation = MaterialIcon(
            name = "screen_lock_rotation",
            style = MaterialIcon.Style.Round
        )
        val ScreenRotation = MaterialIcon(
            name = "screen_rotation",
            style = MaterialIcon.Style.Round
        )
        val ScreenRotationAlt = MaterialIcon(
            name = "screen_rotation_alt",
            style = MaterialIcon.Style.Round
        )
        val ScreenSearchDesktop = MaterialIcon(
            name = "screen_search_desktop",
            style = MaterialIcon.Style.Round
        )
        val ScreenShare = MaterialIcon(
            name = "screen_share",
            style = MaterialIcon.Style.Round
        )
        val Screenshot = MaterialIcon(
            name = "screenshot",
            style = MaterialIcon.Style.Round
        )
        val ScreenshotMonitor = MaterialIcon(
            name = "screenshot_monitor",
            style = MaterialIcon.Style.Round
        )
        val ScubaDiving = MaterialIcon(
            name = "scuba_diving",
            style = MaterialIcon.Style.Round
        )
        val Sd = MaterialIcon(
            name = "sd",
            style = MaterialIcon.Style.Round
        )
        val SdCard = MaterialIcon(
            name = "sd_card",
            style = MaterialIcon.Style.Round
        )
        val SdCardAlert = MaterialIcon(
            name = "sd_card_alert",
            style = MaterialIcon.Style.Round
        )
        val SdStorage = MaterialIcon(
            name = "sd_storage",
            style = MaterialIcon.Style.Round
        )
        val Search = MaterialIcon(
            name = "search",
            style = MaterialIcon.Style.Round
        )
        val SearchOff = MaterialIcon(
            name = "search_off",
            style = MaterialIcon.Style.Round
        )
        val Security = MaterialIcon(
            name = "security",
            style = MaterialIcon.Style.Round
        )
        val SecurityUpdate = MaterialIcon(
            name = "security_update",
            style = MaterialIcon.Style.Round
        )
        val SecurityUpdateGood = MaterialIcon(
            name = "security_update_good",
            style = MaterialIcon.Style.Round
        )
        val SecurityUpdateWarning = MaterialIcon(
            name = "security_update_warning",
            style = MaterialIcon.Style.Round
        )
        val Segment = MaterialIcon(
            name = "segment",
            style = MaterialIcon.Style.Round
        )
        val SelectAll = MaterialIcon(
            name = "select_all",
            style = MaterialIcon.Style.Round
        )
        val SelfImprovement = MaterialIcon(
            name = "self_improvement",
            style = MaterialIcon.Style.Round
        )
        val Sell = MaterialIcon(
            name = "sell",
            style = MaterialIcon.Style.Round
        )
        val Send = MaterialIcon(
            name = "send",
            style = MaterialIcon.Style.Round
        )
        val SendAndArchive = MaterialIcon(
            name = "send_and_archive",
            style = MaterialIcon.Style.Round
        )
        val SendTimeExtension = MaterialIcon(
            name = "send_time_extension",
            style = MaterialIcon.Style.Round
        )
        val SendToMobile = MaterialIcon(
            name = "send_to_mobile",
            style = MaterialIcon.Style.Round
        )
        val SensorDoor = MaterialIcon(
            name = "sensor_door",
            style = MaterialIcon.Style.Round
        )
        val SensorOccupied = MaterialIcon(
            name = "sensor_occupied",
            style = MaterialIcon.Style.Round
        )
        val SensorWindow = MaterialIcon(
            name = "sensor_window",
            style = MaterialIcon.Style.Round
        )
        val Sensors = MaterialIcon(
            name = "sensors",
            style = MaterialIcon.Style.Round
        )
        val SensorsOff = MaterialIcon(
            name = "sensors_off",
            style = MaterialIcon.Style.Round
        )
        val SentimentDissatisfied = MaterialIcon(
            name = "sentiment_dissatisfied",
            style = MaterialIcon.Style.Round
        )
        val SentimentNeutral = MaterialIcon(
            name = "sentiment_neutral",
            style = MaterialIcon.Style.Round
        )
        val SentimentSatisfied = MaterialIcon(
            name = "sentiment_satisfied",
            style = MaterialIcon.Style.Round
        )
        val SentimentSatisfiedAlt = MaterialIcon(
            name = "sentiment_satisfied_alt",
            style = MaterialIcon.Style.Round
        )
        val SentimentVeryDissatisfied = MaterialIcon(
            name = "sentiment_very_dissatisfied",
            style = MaterialIcon.Style.Round
        )
        val SentimentVerySatisfied = MaterialIcon(
            name = "sentiment_very_satisfied",
            style = MaterialIcon.Style.Round
        )
        val SetMeal = MaterialIcon(
            name = "set_meal",
            style = MaterialIcon.Style.Round
        )
        val Settings = MaterialIcon(
            name = "settings",
            style = MaterialIcon.Style.Round
        )
        val SettingsAccessibility = MaterialIcon(
            name = "settings_accessibility",
            style = MaterialIcon.Style.Round
        )
        val SettingsApplications = MaterialIcon(
            name = "settings_applications",
            style = MaterialIcon.Style.Round
        )
        val SettingsBackupRestore = MaterialIcon(
            name = "settings_backup_restore",
            style = MaterialIcon.Style.Round
        )
        val SettingsBluetooth = MaterialIcon(
            name = "settings_bluetooth",
            style = MaterialIcon.Style.Round
        )
        val SettingsBrightness = MaterialIcon(
            name = "settings_brightness",
            style = MaterialIcon.Style.Round
        )
        val SettingsCell = MaterialIcon(
            name = "settings_cell",
            style = MaterialIcon.Style.Round
        )
        val SettingsEthernet = MaterialIcon(
            name = "settings_ethernet",
            style = MaterialIcon.Style.Round
        )
        val SettingsInputAntenna = MaterialIcon(
            name = "settings_input_antenna",
            style = MaterialIcon.Style.Round
        )
        val SettingsInputComponent = MaterialIcon(
            name = "settings_input_component",
            style = MaterialIcon.Style.Round
        )
        val SettingsInputComposite = MaterialIcon(
            name = "settings_input_composite",
            style = MaterialIcon.Style.Round
        )
        val SettingsInputHdmi = MaterialIcon(
            name = "settings_input_hdmi",
            style = MaterialIcon.Style.Round
        )
        val SettingsInputSvideo = MaterialIcon(
            name = "settings_input_svideo",
            style = MaterialIcon.Style.Round
        )
        val SettingsOverscan = MaterialIcon(
            name = "settings_overscan",
            style = MaterialIcon.Style.Round
        )
        val SettingsPhone = MaterialIcon(
            name = "settings_phone",
            style = MaterialIcon.Style.Round
        )
        val SettingsPower = MaterialIcon(
            name = "settings_power",
            style = MaterialIcon.Style.Round
        )
        val SettingsRemote = MaterialIcon(
            name = "settings_remote",
            style = MaterialIcon.Style.Round
        )
        val SettingsSuggest = MaterialIcon(
            name = "settings_suggest",
            style = MaterialIcon.Style.Round
        )
        val SettingsSystemDaydream = MaterialIcon(
            name = "settings_system_daydream",
            style = MaterialIcon.Style.Round
        )
        val SettingsVoice = MaterialIcon(
            name = "settings_voice",
            style = MaterialIcon.Style.Round
        )
        val SevereCold = MaterialIcon(
            name = "severe_cold",
            style = MaterialIcon.Style.Round
        )
        val ShapeLine = MaterialIcon(
            name = "shape_line",
            style = MaterialIcon.Style.Round
        )
        val Share = MaterialIcon(
            name = "share",
            style = MaterialIcon.Style.Round
        )
        val ShareLocation = MaterialIcon(
            name = "share_location",
            style = MaterialIcon.Style.Round
        )
        val Shield = MaterialIcon(
            name = "shield",
            style = MaterialIcon.Style.Round
        )
        val ShieldMoon = MaterialIcon(
            name = "shield_moon",
            style = MaterialIcon.Style.Round
        )
        val Shop = MaterialIcon(
            name = "shop",
            style = MaterialIcon.Style.Round
        )
        val Shop2 = MaterialIcon(
            name = "shop_2",
            style = MaterialIcon.Style.Round
        )
        val ShopTwo = MaterialIcon(
            name = "shop_two",
            style = MaterialIcon.Style.Round
        )
        val ShoppingBag = MaterialIcon(
            name = "shopping_bag",
            style = MaterialIcon.Style.Round
        )
        val ShoppingBasket = MaterialIcon(
            name = "shopping_basket",
            style = MaterialIcon.Style.Round
        )
        val ShoppingCart = MaterialIcon(
            name = "shopping_cart",
            style = MaterialIcon.Style.Round
        )
        val ShoppingCartCheckout = MaterialIcon(
            name = "shopping_cart_checkout",
            style = MaterialIcon.Style.Round
        )
        val ShortText = MaterialIcon(
            name = "short_text",
            style = MaterialIcon.Style.Round
        )
        val Shortcut = MaterialIcon(
            name = "shortcut",
            style = MaterialIcon.Style.Round
        )
        val ShowChart = MaterialIcon(
            name = "show_chart",
            style = MaterialIcon.Style.Round
        )
        val Shower = MaterialIcon(
            name = "shower",
            style = MaterialIcon.Style.Round
        )
        val Shuffle = MaterialIcon(
            name = "shuffle",
            style = MaterialIcon.Style.Round
        )
        val ShuffleOn = MaterialIcon(
            name = "shuffle_on",
            style = MaterialIcon.Style.Round
        )
        val ShutterSpeed = MaterialIcon(
            name = "shutter_speed",
            style = MaterialIcon.Style.Round
        )
        val Sick = MaterialIcon(
            name = "sick",
            style = MaterialIcon.Style.Round
        )
        val SignLanguage = MaterialIcon(
            name = "sign_language",
            style = MaterialIcon.Style.Round
        )
        val SignalCellular0Bar = MaterialIcon(
            name = "signal_cellular_0_bar",
            style = MaterialIcon.Style.Round
        )
        val SignalCellular4Bar = MaterialIcon(
            name = "signal_cellular_4_bar",
            style = MaterialIcon.Style.Round
        )
        val SignalCellularAlt = MaterialIcon(
            name = "signal_cellular_alt",
            style = MaterialIcon.Style.Round
        )
        val SignalCellularAlt1Bar = MaterialIcon(
            name = "signal_cellular_alt_1_bar",
            style = MaterialIcon.Style.Round
        )
        val SignalCellularAlt2Bar = MaterialIcon(
            name = "signal_cellular_alt_2_bar",
            style = MaterialIcon.Style.Round
        )
        val SignalCellularConnectedNoInternet0Bar = MaterialIcon(
            name = "signal_cellular_connected_no_internet_0_bar",
            style = MaterialIcon.Style.Round
        )
        val SignalCellularConnectedNoInternet4Bar = MaterialIcon(
            name = "signal_cellular_connected_no_internet_4_bar",
            style = MaterialIcon.Style.Round
        )
        val SignalCellularNoSim = MaterialIcon(
            name = "signal_cellular_no_sim",
            style = MaterialIcon.Style.Round
        )
        val SignalCellularNodata = MaterialIcon(
            name = "signal_cellular_nodata",
            style = MaterialIcon.Style.Round
        )
        val SignalCellularNull = MaterialIcon(
            name = "signal_cellular_null",
            style = MaterialIcon.Style.Round
        )
        val SignalCellularOff = MaterialIcon(
            name = "signal_cellular_off",
            style = MaterialIcon.Style.Round
        )
        val SignalWifi0Bar = MaterialIcon(
            name = "signal_wifi_0_bar",
            style = MaterialIcon.Style.Round
        )
        val SignalWifi4Bar = MaterialIcon(
            name = "signal_wifi_4_bar",
            style = MaterialIcon.Style.Round
        )
        val SignalWifi4BarLock = MaterialIcon(
            name = "signal_wifi_4_bar_lock",
            style = MaterialIcon.Style.Round
        )
        val SignalWifiBad = MaterialIcon(
            name = "signal_wifi_bad",
            style = MaterialIcon.Style.Round
        )
        val SignalWifiConnectedNoInternet4 = MaterialIcon(
            name = "signal_wifi_connected_no_internet_4",
            style = MaterialIcon.Style.Round
        )
        val SignalWifiOff = MaterialIcon(
            name = "signal_wifi_off",
            style = MaterialIcon.Style.Round
        )
        val SignalWifiStatusbar4Bar = MaterialIcon(
            name = "signal_wifi_statusbar_4_bar",
            style = MaterialIcon.Style.Round
        )
        val SignalWifiStatusbarConnectedNoInternet4 = MaterialIcon(
            name = "signal_wifi_statusbar_connected_no_internet_4",
            style = MaterialIcon.Style.Round
        )
        val SignalWifiStatusbarNull = MaterialIcon(
            name = "signal_wifi_statusbar_null",
            style = MaterialIcon.Style.Round
        )
        val Signpost = MaterialIcon(
            name = "signpost",
            style = MaterialIcon.Style.Round
        )
        val SimCard = MaterialIcon(
            name = "sim_card",
            style = MaterialIcon.Style.Round
        )
        val SimCardAlert = MaterialIcon(
            name = "sim_card_alert",
            style = MaterialIcon.Style.Round
        )
        val SimCardDownload = MaterialIcon(
            name = "sim_card_download",
            style = MaterialIcon.Style.Round
        )
        val SingleBed = MaterialIcon(
            name = "single_bed",
            style = MaterialIcon.Style.Round
        )
        val Sip = MaterialIcon(
            name = "sip",
            style = MaterialIcon.Style.Round
        )
        val Skateboarding = MaterialIcon(
            name = "skateboarding",
            style = MaterialIcon.Style.Round
        )
        val SkipNext = MaterialIcon(
            name = "skip_next",
            style = MaterialIcon.Style.Round
        )
        val SkipPrevious = MaterialIcon(
            name = "skip_previous",
            style = MaterialIcon.Style.Round
        )
        val Sledding = MaterialIcon(
            name = "sledding",
            style = MaterialIcon.Style.Round
        )
        val Slideshow = MaterialIcon(
            name = "slideshow",
            style = MaterialIcon.Style.Round
        )
        val SlowMotionVideo = MaterialIcon(
            name = "slow_motion_video",
            style = MaterialIcon.Style.Round
        )
        val SmartButton = MaterialIcon(
            name = "smart_button",
            style = MaterialIcon.Style.Round
        )
        val SmartDisplay = MaterialIcon(
            name = "smart_display",
            style = MaterialIcon.Style.Round
        )
        val SmartScreen = MaterialIcon(
            name = "smart_screen",
            style = MaterialIcon.Style.Round
        )
        val SmartToy = MaterialIcon(
            name = "smart_toy",
            style = MaterialIcon.Style.Round
        )
        val Smartphone = MaterialIcon(
            name = "smartphone",
            style = MaterialIcon.Style.Round
        )
        val SmokeFree = MaterialIcon(
            name = "smoke_free",
            style = MaterialIcon.Style.Round
        )
        val SmokingRooms = MaterialIcon(
            name = "smoking_rooms",
            style = MaterialIcon.Style.Round
        )
        val Sms = MaterialIcon(
            name = "sms",
            style = MaterialIcon.Style.Round
        )
        val SmsFailed = MaterialIcon(
            name = "sms_failed",
            style = MaterialIcon.Style.Round
        )
        val SnippetFolder = MaterialIcon(
            name = "snippet_folder",
            style = MaterialIcon.Style.Round
        )
        val Snooze = MaterialIcon(
            name = "snooze",
            style = MaterialIcon.Style.Round
        )
        val Snowboarding = MaterialIcon(
            name = "snowboarding",
            style = MaterialIcon.Style.Round
        )
        val Snowmobile = MaterialIcon(
            name = "snowmobile",
            style = MaterialIcon.Style.Round
        )
        val Snowshoeing = MaterialIcon(
            name = "snowshoeing",
            style = MaterialIcon.Style.Round
        )
        val Soap = MaterialIcon(
            name = "soap",
            style = MaterialIcon.Style.Round
        )
        val SocialDistance = MaterialIcon(
            name = "social_distance",
            style = MaterialIcon.Style.Round
        )
        val SolarPower = MaterialIcon(
            name = "solar_power",
            style = MaterialIcon.Style.Round
        )
        val Sort = MaterialIcon(
            name = "sort",
            style = MaterialIcon.Style.Round
        )
        val SortByAlpha = MaterialIcon(
            name = "sort_by_alpha",
            style = MaterialIcon.Style.Round
        )
        val Sos = MaterialIcon(
            name = "sos",
            style = MaterialIcon.Style.Round
        )
        val SoupKitchen = MaterialIcon(
            name = "soup_kitchen",
            style = MaterialIcon.Style.Round
        )
        val Source = MaterialIcon(
            name = "source",
            style = MaterialIcon.Style.Round
        )
        val South = MaterialIcon(
            name = "south",
            style = MaterialIcon.Style.Round
        )
        val SouthAmerica = MaterialIcon(
            name = "south_america",
            style = MaterialIcon.Style.Round
        )
        val SouthEast = MaterialIcon(
            name = "south_east",
            style = MaterialIcon.Style.Round
        )
        val SouthWest = MaterialIcon(
            name = "south_west",
            style = MaterialIcon.Style.Round
        )
        val Spa = MaterialIcon(
            name = "spa",
            style = MaterialIcon.Style.Round
        )
        val SpaceBar = MaterialIcon(
            name = "space_bar",
            style = MaterialIcon.Style.Round
        )
        val SpaceDashboard = MaterialIcon(
            name = "space_dashboard",
            style = MaterialIcon.Style.Round
        )
        val SpatialAudio = MaterialIcon(
            name = "spatial_audio",
            style = MaterialIcon.Style.Round
        )
        val SpatialAudioOff = MaterialIcon(
            name = "spatial_audio_off",
            style = MaterialIcon.Style.Round
        )
        val SpatialTracking = MaterialIcon(
            name = "spatial_tracking",
            style = MaterialIcon.Style.Round
        )
        val Speaker = MaterialIcon(
            name = "speaker",
            style = MaterialIcon.Style.Round
        )
        val SpeakerGroup = MaterialIcon(
            name = "speaker_group",
            style = MaterialIcon.Style.Round
        )
        val SpeakerNotes = MaterialIcon(
            name = "speaker_notes",
            style = MaterialIcon.Style.Round
        )
        val SpeakerNotesOff = MaterialIcon(
            name = "speaker_notes_off",
            style = MaterialIcon.Style.Round
        )
        val SpeakerPhone = MaterialIcon(
            name = "speaker_phone",
            style = MaterialIcon.Style.Round
        )
        val Speed = MaterialIcon(
            name = "speed",
            style = MaterialIcon.Style.Round
        )
        val Spellcheck = MaterialIcon(
            name = "spellcheck",
            style = MaterialIcon.Style.Round
        )
        val Splitscreen = MaterialIcon(
            name = "splitscreen",
            style = MaterialIcon.Style.Round
        )
        val Spoke = MaterialIcon(
            name = "spoke",
            style = MaterialIcon.Style.Round
        )
        val Sports = MaterialIcon(
            name = "sports",
            style = MaterialIcon.Style.Round
        )
        val SportsBar = MaterialIcon(
            name = "sports_bar",
            style = MaterialIcon.Style.Round
        )
        val SportsBaseball = MaterialIcon(
            name = "sports_baseball",
            style = MaterialIcon.Style.Round
        )
        val SportsBasketball = MaterialIcon(
            name = "sports_basketball",
            style = MaterialIcon.Style.Round
        )
        val SportsCricket = MaterialIcon(
            name = "sports_cricket",
            style = MaterialIcon.Style.Round
        )
        val SportsEsports = MaterialIcon(
            name = "sports_esports",
            style = MaterialIcon.Style.Round
        )
        val SportsFootball = MaterialIcon(
            name = "sports_football",
            style = MaterialIcon.Style.Round
        )
        val SportsGolf = MaterialIcon(
            name = "sports_golf",
            style = MaterialIcon.Style.Round
        )
        val SportsGymnastics = MaterialIcon(
            name = "sports_gymnastics",
            style = MaterialIcon.Style.Round
        )
        val SportsHandball = MaterialIcon(
            name = "sports_handball",
            style = MaterialIcon.Style.Round
        )
        val SportsHockey = MaterialIcon(
            name = "sports_hockey",
            style = MaterialIcon.Style.Round
        )
        val SportsKabaddi = MaterialIcon(
            name = "sports_kabaddi",
            style = MaterialIcon.Style.Round
        )
        val SportsMartialArts = MaterialIcon(
            name = "sports_martial_arts",
            style = MaterialIcon.Style.Round
        )
        val SportsMma = MaterialIcon(
            name = "sports_mma",
            style = MaterialIcon.Style.Round
        )
        val SportsMotorsports = MaterialIcon(
            name = "sports_motorsports",
            style = MaterialIcon.Style.Round
        )
        val SportsRugby = MaterialIcon(
            name = "sports_rugby",
            style = MaterialIcon.Style.Round
        )
        val SportsScore = MaterialIcon(
            name = "sports_score",
            style = MaterialIcon.Style.Round
        )
        val SportsSoccer = MaterialIcon(
            name = "sports_soccer",
            style = MaterialIcon.Style.Round
        )
        val SportsTennis = MaterialIcon(
            name = "sports_tennis",
            style = MaterialIcon.Style.Round
        )
        val SportsVolleyball = MaterialIcon(
            name = "sports_volleyball",
            style = MaterialIcon.Style.Round
        )
        val Square = MaterialIcon(
            name = "square",
            style = MaterialIcon.Style.Round
        )
        val SquareFoot = MaterialIcon(
            name = "square_foot",
            style = MaterialIcon.Style.Round
        )
        val SsidChart = MaterialIcon(
            name = "ssid_chart",
            style = MaterialIcon.Style.Round
        )
        val StackedBarChart = MaterialIcon(
            name = "stacked_bar_chart",
            style = MaterialIcon.Style.Round
        )
        val StackedLineChart = MaterialIcon(
            name = "stacked_line_chart",
            style = MaterialIcon.Style.Round
        )
        val Stadium = MaterialIcon(
            name = "stadium",
            style = MaterialIcon.Style.Round
        )
        val Stairs = MaterialIcon(
            name = "stairs",
            style = MaterialIcon.Style.Round
        )
        val Star = MaterialIcon(
            name = "star",
            style = MaterialIcon.Style.Round
        )
        val StarBorder = MaterialIcon(
            name = "star_border",
            style = MaterialIcon.Style.Round
        )
        val StarBorderPurple500 = MaterialIcon(
            name = "star_border_purple500",
            style = MaterialIcon.Style.Round
        )
        val StarHalf = MaterialIcon(
            name = "star_half",
            style = MaterialIcon.Style.Round
        )
        val StarOutline = MaterialIcon(
            name = "star_outline",
            style = MaterialIcon.Style.Round
        )
        val StarPurple500 = MaterialIcon(
            name = "star_purple500",
            style = MaterialIcon.Style.Round
        )
        val StarRate = MaterialIcon(
            name = "star_rate",
            style = MaterialIcon.Style.Round
        )
        val Stars = MaterialIcon(
            name = "stars",
            style = MaterialIcon.Style.Round
        )
        val Start = MaterialIcon(
            name = "start",
            style = MaterialIcon.Style.Round
        )
        val StayCurrentLandscape = MaterialIcon(
            name = "stay_current_landscape",
            style = MaterialIcon.Style.Round
        )
        val StayCurrentPortrait = MaterialIcon(
            name = "stay_current_portrait",
            style = MaterialIcon.Style.Round
        )
        val StayPrimaryLandscape = MaterialIcon(
            name = "stay_primary_landscape",
            style = MaterialIcon.Style.Round
        )
        val StayPrimaryPortrait = MaterialIcon(
            name = "stay_primary_portrait",
            style = MaterialIcon.Style.Round
        )
        val StickyNote2 = MaterialIcon(
            name = "sticky_note_2",
            style = MaterialIcon.Style.Round
        )
        val Stop = MaterialIcon(
            name = "stop",
            style = MaterialIcon.Style.Round
        )
        val StopCircle = MaterialIcon(
            name = "stop_circle",
            style = MaterialIcon.Style.Round
        )
        val StopScreenShare = MaterialIcon(
            name = "stop_screen_share",
            style = MaterialIcon.Style.Round
        )
        val Storage = MaterialIcon(
            name = "storage",
            style = MaterialIcon.Style.Round
        )
        val Store = MaterialIcon(
            name = "store",
            style = MaterialIcon.Style.Round
        )
        val StoreMallDirectory = MaterialIcon(
            name = "store_mall_directory",
            style = MaterialIcon.Style.Round
        )
        val Storefront = MaterialIcon(
            name = "storefront",
            style = MaterialIcon.Style.Round
        )
        val Storm = MaterialIcon(
            name = "storm",
            style = MaterialIcon.Style.Round
        )
        val Straight = MaterialIcon(
            name = "straight",
            style = MaterialIcon.Style.Round
        )
        val Straighten = MaterialIcon(
            name = "straighten",
            style = MaterialIcon.Style.Round
        )
        val Stream = MaterialIcon(
            name = "stream",
            style = MaterialIcon.Style.Round
        )
        val Streetview = MaterialIcon(
            name = "streetview",
            style = MaterialIcon.Style.Round
        )
        val StrikethroughS = MaterialIcon(
            name = "strikethrough_s",
            style = MaterialIcon.Style.Round
        )
        val Stroller = MaterialIcon(
            name = "stroller",
            style = MaterialIcon.Style.Round
        )
        val Style = MaterialIcon(
            name = "style",
            style = MaterialIcon.Style.Round
        )
        val SubdirectoryArrowLeft = MaterialIcon(
            name = "subdirectory_arrow_left",
            style = MaterialIcon.Style.Round
        )
        val SubdirectoryArrowRight = MaterialIcon(
            name = "subdirectory_arrow_right",
            style = MaterialIcon.Style.Round
        )
        val Subject = MaterialIcon(
            name = "subject",
            style = MaterialIcon.Style.Round
        )
        val Subscript = MaterialIcon(
            name = "subscript",
            style = MaterialIcon.Style.Round
        )
        val Subscriptions = MaterialIcon(
            name = "subscriptions",
            style = MaterialIcon.Style.Round
        )
        val Subtitles = MaterialIcon(
            name = "subtitles",
            style = MaterialIcon.Style.Round
        )
        val SubtitlesOff = MaterialIcon(
            name = "subtitles_off",
            style = MaterialIcon.Style.Round
        )
        val Subway = MaterialIcon(
            name = "subway",
            style = MaterialIcon.Style.Round
        )
        val Summarize = MaterialIcon(
            name = "summarize",
            style = MaterialIcon.Style.Round
        )
        val Superscript = MaterialIcon(
            name = "superscript",
            style = MaterialIcon.Style.Round
        )
        val SupervisedUserCircle = MaterialIcon(
            name = "supervised_user_circle",
            style = MaterialIcon.Style.Round
        )
        val SupervisorAccount = MaterialIcon(
            name = "supervisor_account",
            style = MaterialIcon.Style.Round
        )
        val Support = MaterialIcon(
            name = "support",
            style = MaterialIcon.Style.Round
        )
        val SupportAgent = MaterialIcon(
            name = "support_agent",
            style = MaterialIcon.Style.Round
        )
        val Surfing = MaterialIcon(
            name = "surfing",
            style = MaterialIcon.Style.Round
        )
        val SurroundSound = MaterialIcon(
            name = "surround_sound",
            style = MaterialIcon.Style.Round
        )
        val SwapCalls = MaterialIcon(
            name = "swap_calls",
            style = MaterialIcon.Style.Round
        )
        val SwapHoriz = MaterialIcon(
            name = "swap_horiz",
            style = MaterialIcon.Style.Round
        )
        val SwapHorizontalCircle = MaterialIcon(
            name = "swap_horizontal_circle",
            style = MaterialIcon.Style.Round
        )
        val SwapVert = MaterialIcon(
            name = "swap_vert",
            style = MaterialIcon.Style.Round
        )
        val SwapVerticalCircle = MaterialIcon(
            name = "swap_vertical_circle",
            style = MaterialIcon.Style.Round
        )
        val Swipe = MaterialIcon(
            name = "swipe",
            style = MaterialIcon.Style.Round
        )
        val SwipeDown = MaterialIcon(
            name = "swipe_down",
            style = MaterialIcon.Style.Round
        )
        val SwipeDownAlt = MaterialIcon(
            name = "swipe_down_alt",
            style = MaterialIcon.Style.Round
        )
        val SwipeLeft = MaterialIcon(
            name = "swipe_left",
            style = MaterialIcon.Style.Round
        )
        val SwipeLeftAlt = MaterialIcon(
            name = "swipe_left_alt",
            style = MaterialIcon.Style.Round
        )
        val SwipeRight = MaterialIcon(
            name = "swipe_right",
            style = MaterialIcon.Style.Round
        )
        val SwipeRightAlt = MaterialIcon(
            name = "swipe_right_alt",
            style = MaterialIcon.Style.Round
        )
        val SwipeUp = MaterialIcon(
            name = "swipe_up",
            style = MaterialIcon.Style.Round
        )
        val SwipeUpAlt = MaterialIcon(
            name = "swipe_up_alt",
            style = MaterialIcon.Style.Round
        )
        val SwipeVertical = MaterialIcon(
            name = "swipe_vertical",
            style = MaterialIcon.Style.Round
        )
        val SwitchAccessShortcut = MaterialIcon(
            name = "switch_access_shortcut",
            style = MaterialIcon.Style.Round
        )
        val SwitchAccessShortcutAdd = MaterialIcon(
            name = "switch_access_shortcut_add",
            style = MaterialIcon.Style.Round
        )
        val SwitchAccount = MaterialIcon(
            name = "switch_account",
            style = MaterialIcon.Style.Round
        )
        val SwitchCamera = MaterialIcon(
            name = "switch_camera",
            style = MaterialIcon.Style.Round
        )
        val SwitchLeft = MaterialIcon(
            name = "switch_left",
            style = MaterialIcon.Style.Round
        )
        val SwitchRight = MaterialIcon(
            name = "switch_right",
            style = MaterialIcon.Style.Round
        )
        val SwitchVideo = MaterialIcon(
            name = "switch_video",
            style = MaterialIcon.Style.Round
        )
        val Synagogue = MaterialIcon(
            name = "synagogue",
            style = MaterialIcon.Style.Round
        )
        val Sync = MaterialIcon(
            name = "sync",
            style = MaterialIcon.Style.Round
        )
        val SyncAlt = MaterialIcon(
            name = "sync_alt",
            style = MaterialIcon.Style.Round
        )
        val SyncDisabled = MaterialIcon(
            name = "sync_disabled",
            style = MaterialIcon.Style.Round
        )
        val SyncLock = MaterialIcon(
            name = "sync_lock",
            style = MaterialIcon.Style.Round
        )
        val SyncProblem = MaterialIcon(
            name = "sync_problem",
            style = MaterialIcon.Style.Round
        )
        val SystemSecurityUpdate = MaterialIcon(
            name = "system_security_update",
            style = MaterialIcon.Style.Round
        )
        val SystemSecurityUpdateGood = MaterialIcon(
            name = "system_security_update_good",
            style = MaterialIcon.Style.Round
        )
        val SystemSecurityUpdateWarning = MaterialIcon(
            name = "system_security_update_warning",
            style = MaterialIcon.Style.Round
        )
        val SystemUpdate = MaterialIcon(
            name = "system_update",
            style = MaterialIcon.Style.Round
        )
        val SystemUpdateAlt = MaterialIcon(
            name = "system_update_alt",
            style = MaterialIcon.Style.Round
        )
        val Tab = MaterialIcon(
            name = "tab",
            style = MaterialIcon.Style.Round
        )
        val TabUnselected = MaterialIcon(
            name = "tab_unselected",
            style = MaterialIcon.Style.Round
        )
        val TableBar = MaterialIcon(
            name = "table_bar",
            style = MaterialIcon.Style.Round
        )
        val TableChart = MaterialIcon(
            name = "table_chart",
            style = MaterialIcon.Style.Round
        )
        val TableRestaurant = MaterialIcon(
            name = "table_restaurant",
            style = MaterialIcon.Style.Round
        )
        val TableRows = MaterialIcon(
            name = "table_rows",
            style = MaterialIcon.Style.Round
        )
        val TableView = MaterialIcon(
            name = "table_view",
            style = MaterialIcon.Style.Round
        )
        val Tablet = MaterialIcon(
            name = "tablet",
            style = MaterialIcon.Style.Round
        )
        val TabletAndroid = MaterialIcon(
            name = "tablet_android",
            style = MaterialIcon.Style.Round
        )
        val TabletMac = MaterialIcon(
            name = "tablet_mac",
            style = MaterialIcon.Style.Round
        )
        val Tag = MaterialIcon(
            name = "tag",
            style = MaterialIcon.Style.Round
        )
        val TagFaces = MaterialIcon(
            name = "tag_faces",
            style = MaterialIcon.Style.Round
        )
        val TakeoutDining = MaterialIcon(
            name = "takeout_dining",
            style = MaterialIcon.Style.Round
        )
        val TapAndPlay = MaterialIcon(
            name = "tap_and_play",
            style = MaterialIcon.Style.Round
        )
        val Tapas = MaterialIcon(
            name = "tapas",
            style = MaterialIcon.Style.Round
        )
        val Task = MaterialIcon(
            name = "task",
            style = MaterialIcon.Style.Round
        )
        val TaskAlt = MaterialIcon(
            name = "task_alt",
            style = MaterialIcon.Style.Round
        )
        val TaxiAlert = MaterialIcon(
            name = "taxi_alert",
            style = MaterialIcon.Style.Round
        )
        val TempleBuddhist = MaterialIcon(
            name = "temple_buddhist",
            style = MaterialIcon.Style.Round
        )
        val TempleHindu = MaterialIcon(
            name = "temple_hindu",
            style = MaterialIcon.Style.Round
        )
        val Terminal = MaterialIcon(
            name = "terminal",
            style = MaterialIcon.Style.Round
        )
        val Terrain = MaterialIcon(
            name = "terrain",
            style = MaterialIcon.Style.Round
        )
        val TextDecrease = MaterialIcon(
            name = "text_decrease",
            style = MaterialIcon.Style.Round
        )
        val TextFields = MaterialIcon(
            name = "text_fields",
            style = MaterialIcon.Style.Round
        )
        val TextFormat = MaterialIcon(
            name = "text_format",
            style = MaterialIcon.Style.Round
        )
        val TextIncrease = MaterialIcon(
            name = "text_increase",
            style = MaterialIcon.Style.Round
        )
        val TextRotateUp = MaterialIcon(
            name = "text_rotate_up",
            style = MaterialIcon.Style.Round
        )
        val TextRotateVertical = MaterialIcon(
            name = "text_rotate_vertical",
            style = MaterialIcon.Style.Round
        )
        val TextRotationAngledown = MaterialIcon(
            name = "text_rotation_angledown",
            style = MaterialIcon.Style.Round
        )
        val TextRotationAngleup = MaterialIcon(
            name = "text_rotation_angleup",
            style = MaterialIcon.Style.Round
        )
        val TextRotationDown = MaterialIcon(
            name = "text_rotation_down",
            style = MaterialIcon.Style.Round
        )
        val TextRotationNone = MaterialIcon(
            name = "text_rotation_none",
            style = MaterialIcon.Style.Round
        )
        val TextSnippet = MaterialIcon(
            name = "text_snippet",
            style = MaterialIcon.Style.Round
        )
        val Textsms = MaterialIcon(
            name = "textsms",
            style = MaterialIcon.Style.Round
        )
        val Texture = MaterialIcon(
            name = "texture",
            style = MaterialIcon.Style.Round
        )
        val TheaterComedy = MaterialIcon(
            name = "theater_comedy",
            style = MaterialIcon.Style.Round
        )
        val Theaters = MaterialIcon(
            name = "theaters",
            style = MaterialIcon.Style.Round
        )
        val Thermostat = MaterialIcon(
            name = "thermostat",
            style = MaterialIcon.Style.Round
        )
        val ThermostatAuto = MaterialIcon(
            name = "thermostat_auto",
            style = MaterialIcon.Style.Round
        )
        val ThumbDown = MaterialIcon(
            name = "thumb_down",
            style = MaterialIcon.Style.Round
        )
        val ThumbDownAlt = MaterialIcon(
            name = "thumb_down_alt",
            style = MaterialIcon.Style.Round
        )
        val ThumbDownOffAlt = MaterialIcon(
            name = "thumb_down_off_alt",
            style = MaterialIcon.Style.Round
        )
        val ThumbUp = MaterialIcon(
            name = "thumb_up",
            style = MaterialIcon.Style.Round
        )
        val ThumbUpAlt = MaterialIcon(
            name = "thumb_up_alt",
            style = MaterialIcon.Style.Round
        )
        val ThumbUpOffAlt = MaterialIcon(
            name = "thumb_up_off_alt",
            style = MaterialIcon.Style.Round
        )
        val ThumbsUpDown = MaterialIcon(
            name = "thumbs_up_down",
            style = MaterialIcon.Style.Round
        )
        val Thunderstorm = MaterialIcon(
            name = "thunderstorm",
            style = MaterialIcon.Style.Round
        )
        val TimeToLeave = MaterialIcon(
            name = "time_to_leave",
            style = MaterialIcon.Style.Round
        )
        val Timelapse = MaterialIcon(
            name = "timelapse",
            style = MaterialIcon.Style.Round
        )
        val Timeline = MaterialIcon(
            name = "timeline",
            style = MaterialIcon.Style.Round
        )
        val Timer = MaterialIcon(
            name = "timer",
            style = MaterialIcon.Style.Round
        )
        val Timer10 = MaterialIcon(
            name = "timer_10",
            style = MaterialIcon.Style.Round
        )
        val Timer10Select = MaterialIcon(
            name = "timer_10_select",
            style = MaterialIcon.Style.Round
        )
        val Timer3 = MaterialIcon(
            name = "timer_3",
            style = MaterialIcon.Style.Round
        )
        val Timer3Select = MaterialIcon(
            name = "timer_3_select",
            style = MaterialIcon.Style.Round
        )
        val TimerOff = MaterialIcon(
            name = "timer_off",
            style = MaterialIcon.Style.Round
        )
        val TipsAndUpdates = MaterialIcon(
            name = "tips_and_updates",
            style = MaterialIcon.Style.Round
        )
        val TireRepair = MaterialIcon(
            name = "tire_repair",
            style = MaterialIcon.Style.Round
        )
        val Title = MaterialIcon(
            name = "title",
            style = MaterialIcon.Style.Round
        )
        val Toc = MaterialIcon(
            name = "toc",
            style = MaterialIcon.Style.Round
        )
        val Today = MaterialIcon(
            name = "today",
            style = MaterialIcon.Style.Round
        )
        val ToggleOff = MaterialIcon(
            name = "toggle_off",
            style = MaterialIcon.Style.Round
        )
        val ToggleOn = MaterialIcon(
            name = "toggle_on",
            style = MaterialIcon.Style.Round
        )
        val Token = MaterialIcon(
            name = "token",
            style = MaterialIcon.Style.Round
        )
        val Toll = MaterialIcon(
            name = "toll",
            style = MaterialIcon.Style.Round
        )
        val Tonality = MaterialIcon(
            name = "tonality",
            style = MaterialIcon.Style.Round
        )
        val Topic = MaterialIcon(
            name = "topic",
            style = MaterialIcon.Style.Round
        )
        val Tornado = MaterialIcon(
            name = "tornado",
            style = MaterialIcon.Style.Round
        )
        val TouchApp = MaterialIcon(
            name = "touch_app",
            style = MaterialIcon.Style.Round
        )
        val Tour = MaterialIcon(
            name = "tour",
            style = MaterialIcon.Style.Round
        )
        val Toys = MaterialIcon(
            name = "toys",
            style = MaterialIcon.Style.Round
        )
        val TrackChanges = MaterialIcon(
            name = "track_changes",
            style = MaterialIcon.Style.Round
        )
        val Traffic = MaterialIcon(
            name = "traffic",
            style = MaterialIcon.Style.Round
        )
        val Train = MaterialIcon(
            name = "train",
            style = MaterialIcon.Style.Round
        )
        val Tram = MaterialIcon(
            name = "tram",
            style = MaterialIcon.Style.Round
        )
        val Transcribe = MaterialIcon(
            name = "transcribe",
            style = MaterialIcon.Style.Round
        )
        val TransferWithinAStation = MaterialIcon(
            name = "transfer_within_a_station",
            style = MaterialIcon.Style.Round
        )
        val Transform = MaterialIcon(
            name = "transform",
            style = MaterialIcon.Style.Round
        )
        val Transgender = MaterialIcon(
            name = "transgender",
            style = MaterialIcon.Style.Round
        )
        val TransitEnterexit = MaterialIcon(
            name = "transit_enterexit",
            style = MaterialIcon.Style.Round
        )
        val Translate = MaterialIcon(
            name = "translate",
            style = MaterialIcon.Style.Round
        )
        val TravelExplore = MaterialIcon(
            name = "travel_explore",
            style = MaterialIcon.Style.Round
        )
        val TrendingDown = MaterialIcon(
            name = "trending_down",
            style = MaterialIcon.Style.Round
        )
        val TrendingFlat = MaterialIcon(
            name = "trending_flat",
            style = MaterialIcon.Style.Round
        )
        val TrendingUp = MaterialIcon(
            name = "trending_up",
            style = MaterialIcon.Style.Round
        )
        val TripOrigin = MaterialIcon(
            name = "trip_origin",
            style = MaterialIcon.Style.Round
        )
        val Troubleshoot = MaterialIcon(
            name = "troubleshoot",
            style = MaterialIcon.Style.Round
        )
        val Try = MaterialIcon(
            name = "try",
            style = MaterialIcon.Style.Round
        )
        val Tsunami = MaterialIcon(
            name = "tsunami",
            style = MaterialIcon.Style.Round
        )
        val Tty = MaterialIcon(
            name = "tty",
            style = MaterialIcon.Style.Round
        )
        val Tune = MaterialIcon(
            name = "tune",
            style = MaterialIcon.Style.Round
        )
        val Tungsten = MaterialIcon(
            name = "tungsten",
            style = MaterialIcon.Style.Round
        )
        val TurnLeft = MaterialIcon(
            name = "turn_left",
            style = MaterialIcon.Style.Round
        )
        val TurnRight = MaterialIcon(
            name = "turn_right",
            style = MaterialIcon.Style.Round
        )
        val TurnSharpLeft = MaterialIcon(
            name = "turn_sharp_left",
            style = MaterialIcon.Style.Round
        )
        val TurnSharpRight = MaterialIcon(
            name = "turn_sharp_right",
            style = MaterialIcon.Style.Round
        )
        val TurnSlightLeft = MaterialIcon(
            name = "turn_slight_left",
            style = MaterialIcon.Style.Round
        )
        val TurnSlightRight = MaterialIcon(
            name = "turn_slight_right",
            style = MaterialIcon.Style.Round
        )
        val TurnedIn = MaterialIcon(
            name = "turned_in",
            style = MaterialIcon.Style.Round
        )
        val TurnedInNot = MaterialIcon(
            name = "turned_in_not",
            style = MaterialIcon.Style.Round
        )
        val Tv = MaterialIcon(
            name = "tv",
            style = MaterialIcon.Style.Round
        )
        val TvOff = MaterialIcon(
            name = "tv_off",
            style = MaterialIcon.Style.Round
        )
        val TwoWheeler = MaterialIcon(
            name = "two_wheeler",
            style = MaterialIcon.Style.Round
        )
        val TypeSpecimen = MaterialIcon(
            name = "type_specimen",
            style = MaterialIcon.Style.Round
        )
        val UTurnLeft = MaterialIcon(
            name = "u_turn_left",
            style = MaterialIcon.Style.Round
        )
        val UTurnRight = MaterialIcon(
            name = "u_turn_right",
            style = MaterialIcon.Style.Round
        )
        val Umbrella = MaterialIcon(
            name = "umbrella",
            style = MaterialIcon.Style.Round
        )
        val Unarchive = MaterialIcon(
            name = "unarchive",
            style = MaterialIcon.Style.Round
        )
        val Undo = MaterialIcon(
            name = "undo",
            style = MaterialIcon.Style.Round
        )
        val UnfoldLess = MaterialIcon(
            name = "unfold_less",
            style = MaterialIcon.Style.Round
        )
        val UnfoldLessDouble = MaterialIcon(
            name = "unfold_less_double",
            style = MaterialIcon.Style.Round
        )
        val UnfoldMore = MaterialIcon(
            name = "unfold_more",
            style = MaterialIcon.Style.Round
        )
        val UnfoldMoreDouble = MaterialIcon(
            name = "unfold_more_double",
            style = MaterialIcon.Style.Round
        )
        val Unpublished = MaterialIcon(
            name = "unpublished",
            style = MaterialIcon.Style.Round
        )
        val Unsubscribe = MaterialIcon(
            name = "unsubscribe",
            style = MaterialIcon.Style.Round
        )
        val Upcoming = MaterialIcon(
            name = "upcoming",
            style = MaterialIcon.Style.Round
        )
        val Update = MaterialIcon(
            name = "update",
            style = MaterialIcon.Style.Round
        )
        val UpdateDisabled = MaterialIcon(
            name = "update_disabled",
            style = MaterialIcon.Style.Round
        )
        val Upgrade = MaterialIcon(
            name = "upgrade",
            style = MaterialIcon.Style.Round
        )
        val Upload = MaterialIcon(
            name = "upload",
            style = MaterialIcon.Style.Round
        )
        val UploadFile = MaterialIcon(
            name = "upload_file",
            style = MaterialIcon.Style.Round
        )
        val Usb = MaterialIcon(
            name = "usb",
            style = MaterialIcon.Style.Round
        )
        val UsbOff = MaterialIcon(
            name = "usb_off",
            style = MaterialIcon.Style.Round
        )
        val Vaccines = MaterialIcon(
            name = "vaccines",
            style = MaterialIcon.Style.Round
        )
        val VapeFree = MaterialIcon(
            name = "vape_free",
            style = MaterialIcon.Style.Round
        )
        val VapingRooms = MaterialIcon(
            name = "vaping_rooms",
            style = MaterialIcon.Style.Round
        )
        val Verified = MaterialIcon(
            name = "verified",
            style = MaterialIcon.Style.Round
        )
        val VerifiedUser = MaterialIcon(
            name = "verified_user",
            style = MaterialIcon.Style.Round
        )
        val VerticalAlignBottom = MaterialIcon(
            name = "vertical_align_bottom",
            style = MaterialIcon.Style.Round
        )
        val VerticalAlignCenter = MaterialIcon(
            name = "vertical_align_center",
            style = MaterialIcon.Style.Round
        )
        val VerticalAlignTop = MaterialIcon(
            name = "vertical_align_top",
            style = MaterialIcon.Style.Round
        )
        val VerticalDistribute = MaterialIcon(
            name = "vertical_distribute",
            style = MaterialIcon.Style.Round
        )
        val VerticalShades = MaterialIcon(
            name = "vertical_shades",
            style = MaterialIcon.Style.Round
        )
        val VerticalShadesClosed = MaterialIcon(
            name = "vertical_shades_closed",
            style = MaterialIcon.Style.Round
        )
        val VerticalSplit = MaterialIcon(
            name = "vertical_split",
            style = MaterialIcon.Style.Round
        )
        val Vibration = MaterialIcon(
            name = "vibration",
            style = MaterialIcon.Style.Round
        )
        val VideoCall = MaterialIcon(
            name = "video_call",
            style = MaterialIcon.Style.Round
        )
        val VideoCameraBack = MaterialIcon(
            name = "video_camera_back",
            style = MaterialIcon.Style.Round
        )
        val VideoCameraFront = MaterialIcon(
            name = "video_camera_front",
            style = MaterialIcon.Style.Round
        )
        val VideoChat = MaterialIcon(
            name = "video_chat",
            style = MaterialIcon.Style.Round
        )
        val VideoFile = MaterialIcon(
            name = "video_file",
            style = MaterialIcon.Style.Round
        )
        val VideoLabel = MaterialIcon(
            name = "video_label",
            style = MaterialIcon.Style.Round
        )
        val VideoLibrary = MaterialIcon(
            name = "video_library",
            style = MaterialIcon.Style.Round
        )
        val VideoSettings = MaterialIcon(
            name = "video_settings",
            style = MaterialIcon.Style.Round
        )
        val VideoStable = MaterialIcon(
            name = "video_stable",
            style = MaterialIcon.Style.Round
        )
        val Videocam = MaterialIcon(
            name = "videocam",
            style = MaterialIcon.Style.Round
        )
        val VideocamOff = MaterialIcon(
            name = "videocam_off",
            style = MaterialIcon.Style.Round
        )
        val VideogameAsset = MaterialIcon(
            name = "videogame_asset",
            style = MaterialIcon.Style.Round
        )
        val VideogameAssetOff = MaterialIcon(
            name = "videogame_asset_off",
            style = MaterialIcon.Style.Round
        )
        val ViewAgenda = MaterialIcon(
            name = "view_agenda",
            style = MaterialIcon.Style.Round
        )
        val ViewArray = MaterialIcon(
            name = "view_array",
            style = MaterialIcon.Style.Round
        )
        val ViewCarousel = MaterialIcon(
            name = "view_carousel",
            style = MaterialIcon.Style.Round
        )
        val ViewColumn = MaterialIcon(
            name = "view_column",
            style = MaterialIcon.Style.Round
        )
        val ViewComfy = MaterialIcon(
            name = "view_comfy",
            style = MaterialIcon.Style.Round
        )
        val ViewComfyAlt = MaterialIcon(
            name = "view_comfy_alt",
            style = MaterialIcon.Style.Round
        )
        val ViewCompact = MaterialIcon(
            name = "view_compact",
            style = MaterialIcon.Style.Round
        )
        val ViewCompactAlt = MaterialIcon(
            name = "view_compact_alt",
            style = MaterialIcon.Style.Round
        )
        val ViewCozy = MaterialIcon(
            name = "view_cozy",
            style = MaterialIcon.Style.Round
        )
        val ViewDay = MaterialIcon(
            name = "view_day",
            style = MaterialIcon.Style.Round
        )
        val ViewHeadline = MaterialIcon(
            name = "view_headline",
            style = MaterialIcon.Style.Round
        )
        val ViewInAr = MaterialIcon(
            name = "view_in_ar",
            style = MaterialIcon.Style.Round
        )
        val ViewKanban = MaterialIcon(
            name = "view_kanban",
            style = MaterialIcon.Style.Round
        )
        val ViewList = MaterialIcon(
            name = "view_list",
            style = MaterialIcon.Style.Round
        )
        val ViewModule = MaterialIcon(
            name = "view_module",
            style = MaterialIcon.Style.Round
        )
        val ViewQuilt = MaterialIcon(
            name = "view_quilt",
            style = MaterialIcon.Style.Round
        )
        val ViewSidebar = MaterialIcon(
            name = "view_sidebar",
            style = MaterialIcon.Style.Round
        )
        val ViewStream = MaterialIcon(
            name = "view_stream",
            style = MaterialIcon.Style.Round
        )
        val ViewTimeline = MaterialIcon(
            name = "view_timeline",
            style = MaterialIcon.Style.Round
        )
        val ViewWeek = MaterialIcon(
            name = "view_week",
            style = MaterialIcon.Style.Round
        )
        val Vignette = MaterialIcon(
            name = "vignette",
            style = MaterialIcon.Style.Round
        )
        val Villa = MaterialIcon(
            name = "villa",
            style = MaterialIcon.Style.Round
        )
        val Visibility = MaterialIcon(
            name = "visibility",
            style = MaterialIcon.Style.Round
        )
        val VisibilityOff = MaterialIcon(
            name = "visibility_off",
            style = MaterialIcon.Style.Round
        )
        val VoiceChat = MaterialIcon(
            name = "voice_chat",
            style = MaterialIcon.Style.Round
        )
        val VoiceOverOff = MaterialIcon(
            name = "voice_over_off",
            style = MaterialIcon.Style.Round
        )
        val Voicemail = MaterialIcon(
            name = "voicemail",
            style = MaterialIcon.Style.Round
        )
        val Volcano = MaterialIcon(
            name = "volcano",
            style = MaterialIcon.Style.Round
        )
        val VolumeDown = MaterialIcon(
            name = "volume_down",
            style = MaterialIcon.Style.Round
        )
        val VolumeMute = MaterialIcon(
            name = "volume_mute",
            style = MaterialIcon.Style.Round
        )
        val VolumeOff = MaterialIcon(
            name = "volume_off",
            style = MaterialIcon.Style.Round
        )
        val VolumeUp = MaterialIcon(
            name = "volume_up",
            style = MaterialIcon.Style.Round
        )
        val VolunteerActivism = MaterialIcon(
            name = "volunteer_activism",
            style = MaterialIcon.Style.Round
        )
        val VpnKey = MaterialIcon(
            name = "vpn_key",
            style = MaterialIcon.Style.Round
        )
        val VpnKeyOff = MaterialIcon(
            name = "vpn_key_off",
            style = MaterialIcon.Style.Round
        )
        val VpnLock = MaterialIcon(
            name = "vpn_lock",
            style = MaterialIcon.Style.Round
        )
        val Vrpano = MaterialIcon(
            name = "vrpano",
            style = MaterialIcon.Style.Round
        )
        val Wallet = MaterialIcon(
            name = "wallet",
            style = MaterialIcon.Style.Round
        )
        val Wallpaper = MaterialIcon(
            name = "wallpaper",
            style = MaterialIcon.Style.Round
        )
        val Warehouse = MaterialIcon(
            name = "warehouse",
            style = MaterialIcon.Style.Round
        )
        val Warning = MaterialIcon(
            name = "warning",
            style = MaterialIcon.Style.Round
        )
        val WarningAmber = MaterialIcon(
            name = "warning_amber",
            style = MaterialIcon.Style.Round
        )
        val Wash = MaterialIcon(
            name = "wash",
            style = MaterialIcon.Style.Round
        )
        val Watch = MaterialIcon(
            name = "watch",
            style = MaterialIcon.Style.Round
        )
        val WatchLater = MaterialIcon(
            name = "watch_later",
            style = MaterialIcon.Style.Round
        )
        val WatchOff = MaterialIcon(
            name = "watch_off",
            style = MaterialIcon.Style.Round
        )
        val Water = MaterialIcon(
            name = "water",
            style = MaterialIcon.Style.Round
        )
        val WaterDamage = MaterialIcon(
            name = "water_damage",
            style = MaterialIcon.Style.Round
        )
        val WaterDrop = MaterialIcon(
            name = "water_drop",
            style = MaterialIcon.Style.Round
        )
        val WaterfallChart = MaterialIcon(
            name = "waterfall_chart",
            style = MaterialIcon.Style.Round
        )
        val Waves = MaterialIcon(
            name = "waves",
            style = MaterialIcon.Style.Round
        )
        val WavingHand = MaterialIcon(
            name = "waving_hand",
            style = MaterialIcon.Style.Round
        )
        val WbAuto = MaterialIcon(
            name = "wb_auto",
            style = MaterialIcon.Style.Round
        )
        val WbCloudy = MaterialIcon(
            name = "wb_cloudy",
            style = MaterialIcon.Style.Round
        )
        val WbIncandescent = MaterialIcon(
            name = "wb_incandescent",
            style = MaterialIcon.Style.Round
        )
        val WbIridescent = MaterialIcon(
            name = "wb_iridescent",
            style = MaterialIcon.Style.Round
        )
        val WbShade = MaterialIcon(
            name = "wb_shade",
            style = MaterialIcon.Style.Round
        )
        val WbSunny = MaterialIcon(
            name = "wb_sunny",
            style = MaterialIcon.Style.Round
        )
        val WbTwilight = MaterialIcon(
            name = "wb_twilight",
            style = MaterialIcon.Style.Round
        )
        val Wc = MaterialIcon(
            name = "wc",
            style = MaterialIcon.Style.Round
        )
        val Web = MaterialIcon(
            name = "web",
            style = MaterialIcon.Style.Round
        )
        val WebAsset = MaterialIcon(
            name = "web_asset",
            style = MaterialIcon.Style.Round
        )
        val WebAssetOff = MaterialIcon(
            name = "web_asset_off",
            style = MaterialIcon.Style.Round
        )
        val WebStories = MaterialIcon(
            name = "web_stories",
            style = MaterialIcon.Style.Round
        )
        val Webhook = MaterialIcon(
            name = "webhook",
            style = MaterialIcon.Style.Round
        )
        val Weekend = MaterialIcon(
            name = "weekend",
            style = MaterialIcon.Style.Round
        )
        val West = MaterialIcon(
            name = "west",
            style = MaterialIcon.Style.Round
        )
        val Whatshot = MaterialIcon(
            name = "whatshot",
            style = MaterialIcon.Style.Round
        )
        val WheelchairPickup = MaterialIcon(
            name = "wheelchair_pickup",
            style = MaterialIcon.Style.Round
        )
        val WhereToVote = MaterialIcon(
            name = "where_to_vote",
            style = MaterialIcon.Style.Round
        )
        val Widgets = MaterialIcon(
            name = "widgets",
            style = MaterialIcon.Style.Round
        )
        val WidthFull = MaterialIcon(
            name = "width_full",
            style = MaterialIcon.Style.Round
        )
        val WidthNormal = MaterialIcon(
            name = "width_normal",
            style = MaterialIcon.Style.Round
        )
        val WidthWide = MaterialIcon(
            name = "width_wide",
            style = MaterialIcon.Style.Round
        )
        val Wifi = MaterialIcon(
            name = "wifi",
            style = MaterialIcon.Style.Round
        )
        val Wifi1Bar = MaterialIcon(
            name = "wifi_1_bar",
            style = MaterialIcon.Style.Round
        )
        val Wifi2Bar = MaterialIcon(
            name = "wifi_2_bar",
            style = MaterialIcon.Style.Round
        )
        val WifiCalling = MaterialIcon(
            name = "wifi_calling",
            style = MaterialIcon.Style.Round
        )
        val WifiCalling3 = MaterialIcon(
            name = "wifi_calling_3",
            style = MaterialIcon.Style.Round
        )
        val WifiChannel = MaterialIcon(
            name = "wifi_channel",
            style = MaterialIcon.Style.Round
        )
        val WifiFind = MaterialIcon(
            name = "wifi_find",
            style = MaterialIcon.Style.Round
        )
        val WifiLock = MaterialIcon(
            name = "wifi_lock",
            style = MaterialIcon.Style.Round
        )
        val WifiOff = MaterialIcon(
            name = "wifi_off",
            style = MaterialIcon.Style.Round
        )
        val WifiPassword = MaterialIcon(
            name = "wifi_password",
            style = MaterialIcon.Style.Round
        )
        val WifiProtectedSetup = MaterialIcon(
            name = "wifi_protected_setup",
            style = MaterialIcon.Style.Round
        )
        val WifiTethering = MaterialIcon(
            name = "wifi_tethering",
            style = MaterialIcon.Style.Round
        )
        val WifiTetheringError = MaterialIcon(
            name = "wifi_tethering_error",
            style = MaterialIcon.Style.Round
        )
        val WifiTetheringOff = MaterialIcon(
            name = "wifi_tethering_off",
            style = MaterialIcon.Style.Round
        )
        val WindPower = MaterialIcon(
            name = "wind_power",
            style = MaterialIcon.Style.Round
        )
        val Window = MaterialIcon(
            name = "window",
            style = MaterialIcon.Style.Round
        )
        val WineBar = MaterialIcon(
            name = "wine_bar",
            style = MaterialIcon.Style.Round
        )
        val Woman = MaterialIcon(
            name = "woman",
            style = MaterialIcon.Style.Round
        )
        val Woman2 = MaterialIcon(
            name = "woman_2",
            style = MaterialIcon.Style.Round
        )
        val Work = MaterialIcon(
            name = "work",
            style = MaterialIcon.Style.Round
        )
        val WorkHistory = MaterialIcon(
            name = "work_history",
            style = MaterialIcon.Style.Round
        )
        val WorkOff = MaterialIcon(
            name = "work_off",
            style = MaterialIcon.Style.Round
        )
        val WorkOutline = MaterialIcon(
            name = "work_outline",
            style = MaterialIcon.Style.Round
        )
        val WorkspacePremium = MaterialIcon(
            name = "workspace_premium",
            style = MaterialIcon.Style.Round
        )
        val Workspaces = MaterialIcon(
            name = "workspaces",
            style = MaterialIcon.Style.Round
        )
        val WrapText = MaterialIcon(
            name = "wrap_text",
            style = MaterialIcon.Style.Round
        )
        val WrongLocation = MaterialIcon(
            name = "wrong_location",
            style = MaterialIcon.Style.Round
        )
        val Wysiwyg = MaterialIcon(
            name = "wysiwyg",
            style = MaterialIcon.Style.Round
        )
        val Yard = MaterialIcon(
            name = "yard",
            style = MaterialIcon.Style.Round
        )
        val YoutubeSearchedFor = MaterialIcon(
            name = "youtube_searched_for",
            style = MaterialIcon.Style.Round
        )
        val ZoomIn = MaterialIcon(
            name = "zoom_in",
            style = MaterialIcon.Style.Round
        )
        val ZoomInMap = MaterialIcon(
            name = "zoom_in_map",
            style = MaterialIcon.Style.Round
        )
        val ZoomOut = MaterialIcon(
            name = "zoom_out",
            style = MaterialIcon.Style.Round
        )
        val ZoomOutMap = MaterialIcon(
            name = "zoom_out_map",
            style = MaterialIcon.Style.Round
        )
    }
    object Sharp {
        val _10k = MaterialIcon(
            name = "10k",
            style = MaterialIcon.Style.Sharp
        )
        val _10mp = MaterialIcon(
            name = "10mp",
            style = MaterialIcon.Style.Sharp
        )
        val _11mp = MaterialIcon(
            name = "11mp",
            style = MaterialIcon.Style.Sharp
        )
        val _123 = MaterialIcon(
            name = "123",
            style = MaterialIcon.Style.Sharp
        )
        val _12mp = MaterialIcon(
            name = "12mp",
            style = MaterialIcon.Style.Sharp
        )
        val _13mp = MaterialIcon(
            name = "13mp",
            style = MaterialIcon.Style.Sharp
        )
        val _14mp = MaterialIcon(
            name = "14mp",
            style = MaterialIcon.Style.Sharp
        )
        val _15mp = MaterialIcon(
            name = "15mp",
            style = MaterialIcon.Style.Sharp
        )
        val _16mp = MaterialIcon(
            name = "16mp",
            style = MaterialIcon.Style.Sharp
        )
        val _17mp = MaterialIcon(
            name = "17mp",
            style = MaterialIcon.Style.Sharp
        )
        val _18UpRating = MaterialIcon(
            name = "18_up_rating",
            style = MaterialIcon.Style.Sharp
        )
        val _18mp = MaterialIcon(
            name = "18mp",
            style = MaterialIcon.Style.Sharp
        )
        val _19mp = MaterialIcon(
            name = "19mp",
            style = MaterialIcon.Style.Sharp
        )
        val _1k = MaterialIcon(
            name = "1k",
            style = MaterialIcon.Style.Sharp
        )
        val _1kPlus = MaterialIcon(
            name = "1k_plus",
            style = MaterialIcon.Style.Sharp
        )
        val _1xMobiledata = MaterialIcon(
            name = "1x_mobiledata",
            style = MaterialIcon.Style.Sharp
        )
        val _20mp = MaterialIcon(
            name = "20mp",
            style = MaterialIcon.Style.Sharp
        )
        val _21mp = MaterialIcon(
            name = "21mp",
            style = MaterialIcon.Style.Sharp
        )
        val _22mp = MaterialIcon(
            name = "22mp",
            style = MaterialIcon.Style.Sharp
        )
        val _23mp = MaterialIcon(
            name = "23mp",
            style = MaterialIcon.Style.Sharp
        )
        val _24mp = MaterialIcon(
            name = "24mp",
            style = MaterialIcon.Style.Sharp
        )
        val _2k = MaterialIcon(
            name = "2k",
            style = MaterialIcon.Style.Sharp
        )
        val _2kPlus = MaterialIcon(
            name = "2k_plus",
            style = MaterialIcon.Style.Sharp
        )
        val _2mp = MaterialIcon(
            name = "2mp",
            style = MaterialIcon.Style.Sharp
        )
        val _30fps = MaterialIcon(
            name = "30fps",
            style = MaterialIcon.Style.Sharp
        )
        val _30fpsSelect = MaterialIcon(
            name = "30fps_select",
            style = MaterialIcon.Style.Sharp
        )
        val _360 = MaterialIcon(
            name = "360",
            style = MaterialIcon.Style.Sharp
        )
        val _3dRotation = MaterialIcon(
            name = "3d_rotation",
            style = MaterialIcon.Style.Sharp
        )
        val _3gMobiledata = MaterialIcon(
            name = "3g_mobiledata",
            style = MaterialIcon.Style.Sharp
        )
        val _3k = MaterialIcon(
            name = "3k",
            style = MaterialIcon.Style.Sharp
        )
        val _3kPlus = MaterialIcon(
            name = "3k_plus",
            style = MaterialIcon.Style.Sharp
        )
        val _3mp = MaterialIcon(
            name = "3mp",
            style = MaterialIcon.Style.Sharp
        )
        val _3p = MaterialIcon(
            name = "3p",
            style = MaterialIcon.Style.Sharp
        )
        val _4gMobiledata = MaterialIcon(
            name = "4g_mobiledata",
            style = MaterialIcon.Style.Sharp
        )
        val _4gPlusMobiledata = MaterialIcon(
            name = "4g_plus_mobiledata",
            style = MaterialIcon.Style.Sharp
        )
        val _4k = MaterialIcon(
            name = "4k",
            style = MaterialIcon.Style.Sharp
        )
        val _4kPlus = MaterialIcon(
            name = "4k_plus",
            style = MaterialIcon.Style.Sharp
        )
        val _4mp = MaterialIcon(
            name = "4mp",
            style = MaterialIcon.Style.Sharp
        )
        val _5g = MaterialIcon(
            name = "5g",
            style = MaterialIcon.Style.Sharp
        )
        val _5k = MaterialIcon(
            name = "5k",
            style = MaterialIcon.Style.Sharp
        )
        val _5kPlus = MaterialIcon(
            name = "5k_plus",
            style = MaterialIcon.Style.Sharp
        )
        val _5mp = MaterialIcon(
            name = "5mp",
            style = MaterialIcon.Style.Sharp
        )
        val _60fps = MaterialIcon(
            name = "60fps",
            style = MaterialIcon.Style.Sharp
        )
        val _60fpsSelect = MaterialIcon(
            name = "60fps_select",
            style = MaterialIcon.Style.Sharp
        )
        val _6FtApart = MaterialIcon(
            name = "6_ft_apart",
            style = MaterialIcon.Style.Sharp
        )
        val _6k = MaterialIcon(
            name = "6k",
            style = MaterialIcon.Style.Sharp
        )
        val _6kPlus = MaterialIcon(
            name = "6k_plus",
            style = MaterialIcon.Style.Sharp
        )
        val _6mp = MaterialIcon(
            name = "6mp",
            style = MaterialIcon.Style.Sharp
        )
        val _7k = MaterialIcon(
            name = "7k",
            style = MaterialIcon.Style.Sharp
        )
        val _7kPlus = MaterialIcon(
            name = "7k_plus",
            style = MaterialIcon.Style.Sharp
        )
        val _7mp = MaterialIcon(
            name = "7mp",
            style = MaterialIcon.Style.Sharp
        )
        val _8k = MaterialIcon(
            name = "8k",
            style = MaterialIcon.Style.Sharp
        )
        val _8kPlus = MaterialIcon(
            name = "8k_plus",
            style = MaterialIcon.Style.Sharp
        )
        val _8mp = MaterialIcon(
            name = "8mp",
            style = MaterialIcon.Style.Sharp
        )
        val _9k = MaterialIcon(
            name = "9k",
            style = MaterialIcon.Style.Sharp
        )
        val _9kPlus = MaterialIcon(
            name = "9k_plus",
            style = MaterialIcon.Style.Sharp
        )
        val _9mp = MaterialIcon(
            name = "9mp",
            style = MaterialIcon.Style.Sharp
        )
        val Abc = MaterialIcon(
            name = "abc",
            style = MaterialIcon.Style.Sharp
        )
        val AcUnit = MaterialIcon(
            name = "ac_unit",
            style = MaterialIcon.Style.Sharp
        )
        val AccessAlarm = MaterialIcon(
            name = "access_alarm",
            style = MaterialIcon.Style.Sharp
        )
        val AccessAlarms = MaterialIcon(
            name = "access_alarms",
            style = MaterialIcon.Style.Sharp
        )
        val AccessTime = MaterialIcon(
            name = "access_time",
            style = MaterialIcon.Style.Sharp
        )
        val AccessTimeFilled = MaterialIcon(
            name = "access_time_filled",
            style = MaterialIcon.Style.Sharp
        )
        val Accessibility = MaterialIcon(
            name = "accessibility",
            style = MaterialIcon.Style.Sharp
        )
        val AccessibilityNew = MaterialIcon(
            name = "accessibility_new",
            style = MaterialIcon.Style.Sharp
        )
        val Accessible = MaterialIcon(
            name = "accessible",
            style = MaterialIcon.Style.Sharp
        )
        val AccessibleForward = MaterialIcon(
            name = "accessible_forward",
            style = MaterialIcon.Style.Sharp
        )
        val AccountBalance = MaterialIcon(
            name = "account_balance",
            style = MaterialIcon.Style.Sharp
        )
        val AccountBalanceWallet = MaterialIcon(
            name = "account_balance_wallet",
            style = MaterialIcon.Style.Sharp
        )
        val AccountBox = MaterialIcon(
            name = "account_box",
            style = MaterialIcon.Style.Sharp
        )
        val AccountCircle = MaterialIcon(
            name = "account_circle",
            style = MaterialIcon.Style.Sharp
        )
        val AccountTree = MaterialIcon(
            name = "account_tree",
            style = MaterialIcon.Style.Sharp
        )
        val AdUnits = MaterialIcon(
            name = "ad_units",
            style = MaterialIcon.Style.Sharp
        )
        val Adb = MaterialIcon(
            name = "adb",
            style = MaterialIcon.Style.Sharp
        )
        val Add = MaterialIcon(
            name = "add",
            style = MaterialIcon.Style.Sharp
        )
        val AddAPhoto = MaterialIcon(
            name = "add_a_photo",
            style = MaterialIcon.Style.Sharp
        )
        val AddAlarm = MaterialIcon(
            name = "add_alarm",
            style = MaterialIcon.Style.Sharp
        )
        val AddAlert = MaterialIcon(
            name = "add_alert",
            style = MaterialIcon.Style.Sharp
        )
        val AddBox = MaterialIcon(
            name = "add_box",
            style = MaterialIcon.Style.Sharp
        )
        val AddBusiness = MaterialIcon(
            name = "add_business",
            style = MaterialIcon.Style.Sharp
        )
        val AddCard = MaterialIcon(
            name = "add_card",
            style = MaterialIcon.Style.Sharp
        )
        val AddChart = MaterialIcon(
            name = "add_chart",
            style = MaterialIcon.Style.Sharp
        )
        val AddCircle = MaterialIcon(
            name = "add_circle",
            style = MaterialIcon.Style.Sharp
        )
        val AddCircleOutline = MaterialIcon(
            name = "add_circle_outline",
            style = MaterialIcon.Style.Sharp
        )
        val AddComment = MaterialIcon(
            name = "add_comment",
            style = MaterialIcon.Style.Sharp
        )
        val AddHome = MaterialIcon(
            name = "add_home",
            style = MaterialIcon.Style.Sharp
        )
        val AddHomeWork = MaterialIcon(
            name = "add_home_work",
            style = MaterialIcon.Style.Sharp
        )
        val AddIcCall = MaterialIcon(
            name = "add_ic_call",
            style = MaterialIcon.Style.Sharp
        )
        val AddLink = MaterialIcon(
            name = "add_link",
            style = MaterialIcon.Style.Sharp
        )
        val AddLocation = MaterialIcon(
            name = "add_location",
            style = MaterialIcon.Style.Sharp
        )
        val AddLocationAlt = MaterialIcon(
            name = "add_location_alt",
            style = MaterialIcon.Style.Sharp
        )
        val AddModerator = MaterialIcon(
            name = "add_moderator",
            style = MaterialIcon.Style.Sharp
        )
        val AddPhotoAlternate = MaterialIcon(
            name = "add_photo_alternate",
            style = MaterialIcon.Style.Sharp
        )
        val AddReaction = MaterialIcon(
            name = "add_reaction",
            style = MaterialIcon.Style.Sharp
        )
        val AddRoad = MaterialIcon(
            name = "add_road",
            style = MaterialIcon.Style.Sharp
        )
        val AddShoppingCart = MaterialIcon(
            name = "add_shopping_cart",
            style = MaterialIcon.Style.Sharp
        )
        val AddTask = MaterialIcon(
            name = "add_task",
            style = MaterialIcon.Style.Sharp
        )
        val AddToDrive = MaterialIcon(
            name = "add_to_drive",
            style = MaterialIcon.Style.Sharp
        )
        val AddToHomeScreen = MaterialIcon(
            name = "add_to_home_screen",
            style = MaterialIcon.Style.Sharp
        )
        val AddToPhotos = MaterialIcon(
            name = "add_to_photos",
            style = MaterialIcon.Style.Sharp
        )
        val AddToQueue = MaterialIcon(
            name = "add_to_queue",
            style = MaterialIcon.Style.Sharp
        )
        val Addchart = MaterialIcon(
            name = "addchart",
            style = MaterialIcon.Style.Sharp
        )
        val AdfScanner = MaterialIcon(
            name = "adf_scanner",
            style = MaterialIcon.Style.Sharp
        )
        val Adjust = MaterialIcon(
            name = "adjust",
            style = MaterialIcon.Style.Sharp
        )
        val AdminPanelSettings = MaterialIcon(
            name = "admin_panel_settings",
            style = MaterialIcon.Style.Sharp
        )
        val AdsClick = MaterialIcon(
            name = "ads_click",
            style = MaterialIcon.Style.Sharp
        )
        val Agriculture = MaterialIcon(
            name = "agriculture",
            style = MaterialIcon.Style.Sharp
        )
        val Air = MaterialIcon(
            name = "air",
            style = MaterialIcon.Style.Sharp
        )
        val AirlineSeatFlat = MaterialIcon(
            name = "airline_seat_flat",
            style = MaterialIcon.Style.Sharp
        )
        val AirlineSeatFlatAngled = MaterialIcon(
            name = "airline_seat_flat_angled",
            style = MaterialIcon.Style.Sharp
        )
        val AirlineSeatIndividualSuite = MaterialIcon(
            name = "airline_seat_individual_suite",
            style = MaterialIcon.Style.Sharp
        )
        val AirlineSeatLegroomExtra = MaterialIcon(
            name = "airline_seat_legroom_extra",
            style = MaterialIcon.Style.Sharp
        )
        val AirlineSeatLegroomNormal = MaterialIcon(
            name = "airline_seat_legroom_normal",
            style = MaterialIcon.Style.Sharp
        )
        val AirlineSeatLegroomReduced = MaterialIcon(
            name = "airline_seat_legroom_reduced",
            style = MaterialIcon.Style.Sharp
        )
        val AirlineSeatReclineExtra = MaterialIcon(
            name = "airline_seat_recline_extra",
            style = MaterialIcon.Style.Sharp
        )
        val AirlineSeatReclineNormal = MaterialIcon(
            name = "airline_seat_recline_normal",
            style = MaterialIcon.Style.Sharp
        )
        val AirlineStops = MaterialIcon(
            name = "airline_stops",
            style = MaterialIcon.Style.Sharp
        )
        val Airlines = MaterialIcon(
            name = "airlines",
            style = MaterialIcon.Style.Sharp
        )
        val AirplaneTicket = MaterialIcon(
            name = "airplane_ticket",
            style = MaterialIcon.Style.Sharp
        )
        val AirplanemodeActive = MaterialIcon(
            name = "airplanemode_active",
            style = MaterialIcon.Style.Sharp
        )
        val AirplanemodeInactive = MaterialIcon(
            name = "airplanemode_inactive",
            style = MaterialIcon.Style.Sharp
        )
        val Airplay = MaterialIcon(
            name = "airplay",
            style = MaterialIcon.Style.Sharp
        )
        val AirportShuttle = MaterialIcon(
            name = "airport_shuttle",
            style = MaterialIcon.Style.Sharp
        )
        val Alarm = MaterialIcon(
            name = "alarm",
            style = MaterialIcon.Style.Sharp
        )
        val AlarmAdd = MaterialIcon(
            name = "alarm_add",
            style = MaterialIcon.Style.Sharp
        )
        val AlarmOff = MaterialIcon(
            name = "alarm_off",
            style = MaterialIcon.Style.Sharp
        )
        val AlarmOn = MaterialIcon(
            name = "alarm_on",
            style = MaterialIcon.Style.Sharp
        )
        val Album = MaterialIcon(
            name = "album",
            style = MaterialIcon.Style.Sharp
        )
        val AlignHorizontalCenter = MaterialIcon(
            name = "align_horizontal_center",
            style = MaterialIcon.Style.Sharp
        )
        val AlignHorizontalLeft = MaterialIcon(
            name = "align_horizontal_left",
            style = MaterialIcon.Style.Sharp
        )
        val AlignHorizontalRight = MaterialIcon(
            name = "align_horizontal_right",
            style = MaterialIcon.Style.Sharp
        )
        val AlignVerticalBottom = MaterialIcon(
            name = "align_vertical_bottom",
            style = MaterialIcon.Style.Sharp
        )
        val AlignVerticalCenter = MaterialIcon(
            name = "align_vertical_center",
            style = MaterialIcon.Style.Sharp
        )
        val AlignVerticalTop = MaterialIcon(
            name = "align_vertical_top",
            style = MaterialIcon.Style.Sharp
        )
        val AllInbox = MaterialIcon(
            name = "all_inbox",
            style = MaterialIcon.Style.Sharp
        )
        val AllInclusive = MaterialIcon(
            name = "all_inclusive",
            style = MaterialIcon.Style.Sharp
        )
        val AllOut = MaterialIcon(
            name = "all_out",
            style = MaterialIcon.Style.Sharp
        )
        val AltRoute = MaterialIcon(
            name = "alt_route",
            style = MaterialIcon.Style.Sharp
        )
        val AlternateEmail = MaterialIcon(
            name = "alternate_email",
            style = MaterialIcon.Style.Sharp
        )
        val Analytics = MaterialIcon(
            name = "analytics",
            style = MaterialIcon.Style.Sharp
        )
        val Anchor = MaterialIcon(
            name = "anchor",
            style = MaterialIcon.Style.Sharp
        )
        val Android = MaterialIcon(
            name = "android",
            style = MaterialIcon.Style.Sharp
        )
        val Animation = MaterialIcon(
            name = "animation",
            style = MaterialIcon.Style.Sharp
        )
        val Announcement = MaterialIcon(
            name = "announcement",
            style = MaterialIcon.Style.Sharp
        )
        val Aod = MaterialIcon(
            name = "aod",
            style = MaterialIcon.Style.Sharp
        )
        val Apartment = MaterialIcon(
            name = "apartment",
            style = MaterialIcon.Style.Sharp
        )
        val Api = MaterialIcon(
            name = "api",
            style = MaterialIcon.Style.Sharp
        )
        val AppBlocking = MaterialIcon(
            name = "app_blocking",
            style = MaterialIcon.Style.Sharp
        )
        val AppRegistration = MaterialIcon(
            name = "app_registration",
            style = MaterialIcon.Style.Sharp
        )
        val AppSettingsAlt = MaterialIcon(
            name = "app_settings_alt",
            style = MaterialIcon.Style.Sharp
        )
        val AppShortcut = MaterialIcon(
            name = "app_shortcut",
            style = MaterialIcon.Style.Sharp
        )
        val Approval = MaterialIcon(
            name = "approval",
            style = MaterialIcon.Style.Sharp
        )
        val Apps = MaterialIcon(
            name = "apps",
            style = MaterialIcon.Style.Sharp
        )
        val AppsOutage = MaterialIcon(
            name = "apps_outage",
            style = MaterialIcon.Style.Sharp
        )
        val Architecture = MaterialIcon(
            name = "architecture",
            style = MaterialIcon.Style.Sharp
        )
        val Archive = MaterialIcon(
            name = "archive",
            style = MaterialIcon.Style.Sharp
        )
        val AreaChart = MaterialIcon(
            name = "area_chart",
            style = MaterialIcon.Style.Sharp
        )
        val ArrowBack = MaterialIcon(
            name = "arrow_back",
            style = MaterialIcon.Style.Sharp
        )
        val ArrowBackIos = MaterialIcon(
            name = "arrow_back_ios",
            style = MaterialIcon.Style.Sharp
        )
        val ArrowBackIosNew = MaterialIcon(
            name = "arrow_back_ios_new",
            style = MaterialIcon.Style.Sharp
        )
        val ArrowCircleDown = MaterialIcon(
            name = "arrow_circle_down",
            style = MaterialIcon.Style.Sharp
        )
        val ArrowCircleLeft = MaterialIcon(
            name = "arrow_circle_left",
            style = MaterialIcon.Style.Sharp
        )
        val ArrowCircleRight = MaterialIcon(
            name = "arrow_circle_right",
            style = MaterialIcon.Style.Sharp
        )
        val ArrowCircleUp = MaterialIcon(
            name = "arrow_circle_up",
            style = MaterialIcon.Style.Sharp
        )
        val ArrowDownward = MaterialIcon(
            name = "arrow_downward",
            style = MaterialIcon.Style.Sharp
        )
        val ArrowDropDown = MaterialIcon(
            name = "arrow_drop_down",
            style = MaterialIcon.Style.Sharp
        )
        val ArrowDropDownCircle = MaterialIcon(
            name = "arrow_drop_down_circle",
            style = MaterialIcon.Style.Sharp
        )
        val ArrowDropUp = MaterialIcon(
            name = "arrow_drop_up",
            style = MaterialIcon.Style.Sharp
        )
        val ArrowForward = MaterialIcon(
            name = "arrow_forward",
            style = MaterialIcon.Style.Sharp
        )
        val ArrowForwardIos = MaterialIcon(
            name = "arrow_forward_ios",
            style = MaterialIcon.Style.Sharp
        )
        val ArrowLeft = MaterialIcon(
            name = "arrow_left",
            style = MaterialIcon.Style.Sharp
        )
        val ArrowOutward = MaterialIcon(
            name = "arrow_outward",
            style = MaterialIcon.Style.Sharp
        )
        val ArrowRight = MaterialIcon(
            name = "arrow_right",
            style = MaterialIcon.Style.Sharp
        )
        val ArrowRightAlt = MaterialIcon(
            name = "arrow_right_alt",
            style = MaterialIcon.Style.Sharp
        )
        val ArrowUpward = MaterialIcon(
            name = "arrow_upward",
            style = MaterialIcon.Style.Sharp
        )
        val ArtTrack = MaterialIcon(
            name = "art_track",
            style = MaterialIcon.Style.Sharp
        )
        val Article = MaterialIcon(
            name = "article",
            style = MaterialIcon.Style.Sharp
        )
        val AspectRatio = MaterialIcon(
            name = "aspect_ratio",
            style = MaterialIcon.Style.Sharp
        )
        val Assessment = MaterialIcon(
            name = "assessment",
            style = MaterialIcon.Style.Sharp
        )
        val Assignment = MaterialIcon(
            name = "assignment",
            style = MaterialIcon.Style.Sharp
        )
        val AssignmentInd = MaterialIcon(
            name = "assignment_ind",
            style = MaterialIcon.Style.Sharp
        )
        val AssignmentLate = MaterialIcon(
            name = "assignment_late",
            style = MaterialIcon.Style.Sharp
        )
        val AssignmentReturn = MaterialIcon(
            name = "assignment_return",
            style = MaterialIcon.Style.Sharp
        )
        val AssignmentReturned = MaterialIcon(
            name = "assignment_returned",
            style = MaterialIcon.Style.Sharp
        )
        val AssignmentTurnedIn = MaterialIcon(
            name = "assignment_turned_in",
            style = MaterialIcon.Style.Sharp
        )
        val AssistWalker = MaterialIcon(
            name = "assist_walker",
            style = MaterialIcon.Style.Sharp
        )
        val Assistant = MaterialIcon(
            name = "assistant",
            style = MaterialIcon.Style.Sharp
        )
        val AssistantDirection = MaterialIcon(
            name = "assistant_direction",
            style = MaterialIcon.Style.Sharp
        )
        val AssistantPhoto = MaterialIcon(
            name = "assistant_photo",
            style = MaterialIcon.Style.Sharp
        )
        val AssuredWorkload = MaterialIcon(
            name = "assured_workload",
            style = MaterialIcon.Style.Sharp
        )
        val Atm = MaterialIcon(
            name = "atm",
            style = MaterialIcon.Style.Sharp
        )
        val AttachEmail = MaterialIcon(
            name = "attach_email",
            style = MaterialIcon.Style.Sharp
        )
        val AttachFile = MaterialIcon(
            name = "attach_file",
            style = MaterialIcon.Style.Sharp
        )
        val AttachMoney = MaterialIcon(
            name = "attach_money",
            style = MaterialIcon.Style.Sharp
        )
        val Attachment = MaterialIcon(
            name = "attachment",
            style = MaterialIcon.Style.Sharp
        )
        val Attractions = MaterialIcon(
            name = "attractions",
            style = MaterialIcon.Style.Sharp
        )
        val Attribution = MaterialIcon(
            name = "attribution",
            style = MaterialIcon.Style.Sharp
        )
        val AudioFile = MaterialIcon(
            name = "audio_file",
            style = MaterialIcon.Style.Sharp
        )
        val Audiotrack = MaterialIcon(
            name = "audiotrack",
            style = MaterialIcon.Style.Sharp
        )
        val AutoAwesome = MaterialIcon(
            name = "auto_awesome",
            style = MaterialIcon.Style.Sharp
        )
        val AutoAwesomeMosaic = MaterialIcon(
            name = "auto_awesome_mosaic",
            style = MaterialIcon.Style.Sharp
        )
        val AutoAwesomeMotion = MaterialIcon(
            name = "auto_awesome_motion",
            style = MaterialIcon.Style.Sharp
        )
        val AutoDelete = MaterialIcon(
            name = "auto_delete",
            style = MaterialIcon.Style.Sharp
        )
        val AutoFixHigh = MaterialIcon(
            name = "auto_fix_high",
            style = MaterialIcon.Style.Sharp
        )
        val AutoFixNormal = MaterialIcon(
            name = "auto_fix_normal",
            style = MaterialIcon.Style.Sharp
        )
        val AutoFixOff = MaterialIcon(
            name = "auto_fix_off",
            style = MaterialIcon.Style.Sharp
        )
        val AutoGraph = MaterialIcon(
            name = "auto_graph",
            style = MaterialIcon.Style.Sharp
        )
        val AutoMode = MaterialIcon(
            name = "auto_mode",
            style = MaterialIcon.Style.Sharp
        )
        val AutoStories = MaterialIcon(
            name = "auto_stories",
            style = MaterialIcon.Style.Sharp
        )
        val AutofpsSelect = MaterialIcon(
            name = "autofps_select",
            style = MaterialIcon.Style.Sharp
        )
        val Autorenew = MaterialIcon(
            name = "autorenew",
            style = MaterialIcon.Style.Sharp
        )
        val AvTimer = MaterialIcon(
            name = "av_timer",
            style = MaterialIcon.Style.Sharp
        )
        val BabyChangingStation = MaterialIcon(
            name = "baby_changing_station",
            style = MaterialIcon.Style.Sharp
        )
        val BackHand = MaterialIcon(
            name = "back_hand",
            style = MaterialIcon.Style.Sharp
        )
        val Backpack = MaterialIcon(
            name = "backpack",
            style = MaterialIcon.Style.Sharp
        )
        val Backspace = MaterialIcon(
            name = "backspace",
            style = MaterialIcon.Style.Sharp
        )
        val Backup = MaterialIcon(
            name = "backup",
            style = MaterialIcon.Style.Sharp
        )
        val BackupTable = MaterialIcon(
            name = "backup_table",
            style = MaterialIcon.Style.Sharp
        )
        val Badge = MaterialIcon(
            name = "badge",
            style = MaterialIcon.Style.Sharp
        )
        val BakeryDining = MaterialIcon(
            name = "bakery_dining",
            style = MaterialIcon.Style.Sharp
        )
        val Balance = MaterialIcon(
            name = "balance",
            style = MaterialIcon.Style.Sharp
        )
        val Balcony = MaterialIcon(
            name = "balcony",
            style = MaterialIcon.Style.Sharp
        )
        val Ballot = MaterialIcon(
            name = "ballot",
            style = MaterialIcon.Style.Sharp
        )
        val BarChart = MaterialIcon(
            name = "bar_chart",
            style = MaterialIcon.Style.Sharp
        )
        val BatchPrediction = MaterialIcon(
            name = "batch_prediction",
            style = MaterialIcon.Style.Sharp
        )
        val Bathroom = MaterialIcon(
            name = "bathroom",
            style = MaterialIcon.Style.Sharp
        )
        val Bathtub = MaterialIcon(
            name = "bathtub",
            style = MaterialIcon.Style.Sharp
        )
        val Battery0Bar = MaterialIcon(
            name = "battery_0_bar",
            style = MaterialIcon.Style.Sharp
        )
        val Battery1Bar = MaterialIcon(
            name = "battery_1_bar",
            style = MaterialIcon.Style.Sharp
        )
        val Battery2Bar = MaterialIcon(
            name = "battery_2_bar",
            style = MaterialIcon.Style.Sharp
        )
        val Battery3Bar = MaterialIcon(
            name = "battery_3_bar",
            style = MaterialIcon.Style.Sharp
        )
        val Battery4Bar = MaterialIcon(
            name = "battery_4_bar",
            style = MaterialIcon.Style.Sharp
        )
        val Battery5Bar = MaterialIcon(
            name = "battery_5_bar",
            style = MaterialIcon.Style.Sharp
        )
        val Battery6Bar = MaterialIcon(
            name = "battery_6_bar",
            style = MaterialIcon.Style.Sharp
        )
        val BatteryAlert = MaterialIcon(
            name = "battery_alert",
            style = MaterialIcon.Style.Sharp
        )
        val BatteryChargingFull = MaterialIcon(
            name = "battery_charging_full",
            style = MaterialIcon.Style.Sharp
        )
        val BatteryFull = MaterialIcon(
            name = "battery_full",
            style = MaterialIcon.Style.Sharp
        )
        val BatterySaver = MaterialIcon(
            name = "battery_saver",
            style = MaterialIcon.Style.Sharp
        )
        val BatteryStd = MaterialIcon(
            name = "battery_std",
            style = MaterialIcon.Style.Sharp
        )
        val BatteryUnknown = MaterialIcon(
            name = "battery_unknown",
            style = MaterialIcon.Style.Sharp
        )
        val BeachAccess = MaterialIcon(
            name = "beach_access",
            style = MaterialIcon.Style.Sharp
        )
        val Bed = MaterialIcon(
            name = "bed",
            style = MaterialIcon.Style.Sharp
        )
        val BedroomBaby = MaterialIcon(
            name = "bedroom_baby",
            style = MaterialIcon.Style.Sharp
        )
        val BedroomChild = MaterialIcon(
            name = "bedroom_child",
            style = MaterialIcon.Style.Sharp
        )
        val BedroomParent = MaterialIcon(
            name = "bedroom_parent",
            style = MaterialIcon.Style.Sharp
        )
        val Bedtime = MaterialIcon(
            name = "bedtime",
            style = MaterialIcon.Style.Sharp
        )
        val BedtimeOff = MaterialIcon(
            name = "bedtime_off",
            style = MaterialIcon.Style.Sharp
        )
        val Beenhere = MaterialIcon(
            name = "beenhere",
            style = MaterialIcon.Style.Sharp
        )
        val Bento = MaterialIcon(
            name = "bento",
            style = MaterialIcon.Style.Sharp
        )
        val BikeScooter = MaterialIcon(
            name = "bike_scooter",
            style = MaterialIcon.Style.Sharp
        )
        val Biotech = MaterialIcon(
            name = "biotech",
            style = MaterialIcon.Style.Sharp
        )
        val Blender = MaterialIcon(
            name = "blender",
            style = MaterialIcon.Style.Sharp
        )
        val Blind = MaterialIcon(
            name = "blind",
            style = MaterialIcon.Style.Sharp
        )
        val Blinds = MaterialIcon(
            name = "blinds",
            style = MaterialIcon.Style.Sharp
        )
        val BlindsClosed = MaterialIcon(
            name = "blinds_closed",
            style = MaterialIcon.Style.Sharp
        )
        val Block = MaterialIcon(
            name = "block",
            style = MaterialIcon.Style.Sharp
        )
        val Bloodtype = MaterialIcon(
            name = "bloodtype",
            style = MaterialIcon.Style.Sharp
        )
        val Bluetooth = MaterialIcon(
            name = "bluetooth",
            style = MaterialIcon.Style.Sharp
        )
        val BluetoothAudio = MaterialIcon(
            name = "bluetooth_audio",
            style = MaterialIcon.Style.Sharp
        )
        val BluetoothConnected = MaterialIcon(
            name = "bluetooth_connected",
            style = MaterialIcon.Style.Sharp
        )
        val BluetoothDisabled = MaterialIcon(
            name = "bluetooth_disabled",
            style = MaterialIcon.Style.Sharp
        )
        val BluetoothDrive = MaterialIcon(
            name = "bluetooth_drive",
            style = MaterialIcon.Style.Sharp
        )
        val BluetoothSearching = MaterialIcon(
            name = "bluetooth_searching",
            style = MaterialIcon.Style.Sharp
        )
        val BlurCircular = MaterialIcon(
            name = "blur_circular",
            style = MaterialIcon.Style.Sharp
        )
        val BlurLinear = MaterialIcon(
            name = "blur_linear",
            style = MaterialIcon.Style.Sharp
        )
        val BlurOff = MaterialIcon(
            name = "blur_off",
            style = MaterialIcon.Style.Sharp
        )
        val BlurOn = MaterialIcon(
            name = "blur_on",
            style = MaterialIcon.Style.Sharp
        )
        val Bolt = MaterialIcon(
            name = "bolt",
            style = MaterialIcon.Style.Sharp
        )
        val Book = MaterialIcon(
            name = "book",
            style = MaterialIcon.Style.Sharp
        )
        val BookOnline = MaterialIcon(
            name = "book_online",
            style = MaterialIcon.Style.Sharp
        )
        val Bookmark = MaterialIcon(
            name = "bookmark",
            style = MaterialIcon.Style.Sharp
        )
        val BookmarkAdd = MaterialIcon(
            name = "bookmark_add",
            style = MaterialIcon.Style.Sharp
        )
        val BookmarkAdded = MaterialIcon(
            name = "bookmark_added",
            style = MaterialIcon.Style.Sharp
        )
        val BookmarkBorder = MaterialIcon(
            name = "bookmark_border",
            style = MaterialIcon.Style.Sharp
        )
        val BookmarkRemove = MaterialIcon(
            name = "bookmark_remove",
            style = MaterialIcon.Style.Sharp
        )
        val Bookmarks = MaterialIcon(
            name = "bookmarks",
            style = MaterialIcon.Style.Sharp
        )
        val BorderAll = MaterialIcon(
            name = "border_all",
            style = MaterialIcon.Style.Sharp
        )
        val BorderBottom = MaterialIcon(
            name = "border_bottom",
            style = MaterialIcon.Style.Sharp
        )
        val BorderClear = MaterialIcon(
            name = "border_clear",
            style = MaterialIcon.Style.Sharp
        )
        val BorderColor = MaterialIcon(
            name = "border_color",
            style = MaterialIcon.Style.Sharp
        )
        val BorderHorizontal = MaterialIcon(
            name = "border_horizontal",
            style = MaterialIcon.Style.Sharp
        )
        val BorderInner = MaterialIcon(
            name = "border_inner",
            style = MaterialIcon.Style.Sharp
        )
        val BorderLeft = MaterialIcon(
            name = "border_left",
            style = MaterialIcon.Style.Sharp
        )
        val BorderOuter = MaterialIcon(
            name = "border_outer",
            style = MaterialIcon.Style.Sharp
        )
        val BorderRight = MaterialIcon(
            name = "border_right",
            style = MaterialIcon.Style.Sharp
        )
        val BorderStyle = MaterialIcon(
            name = "border_style",
            style = MaterialIcon.Style.Sharp
        )
        val BorderTop = MaterialIcon(
            name = "border_top",
            style = MaterialIcon.Style.Sharp
        )
        val BorderVertical = MaterialIcon(
            name = "border_vertical",
            style = MaterialIcon.Style.Sharp
        )
        val Boy = MaterialIcon(
            name = "boy",
            style = MaterialIcon.Style.Sharp
        )
        val BrandingWatermark = MaterialIcon(
            name = "branding_watermark",
            style = MaterialIcon.Style.Sharp
        )
        val BreakfastDining = MaterialIcon(
            name = "breakfast_dining",
            style = MaterialIcon.Style.Sharp
        )
        val Brightness1 = MaterialIcon(
            name = "brightness_1",
            style = MaterialIcon.Style.Sharp
        )
        val Brightness2 = MaterialIcon(
            name = "brightness_2",
            style = MaterialIcon.Style.Sharp
        )
        val Brightness3 = MaterialIcon(
            name = "brightness_3",
            style = MaterialIcon.Style.Sharp
        )
        val Brightness4 = MaterialIcon(
            name = "brightness_4",
            style = MaterialIcon.Style.Sharp
        )
        val Brightness5 = MaterialIcon(
            name = "brightness_5",
            style = MaterialIcon.Style.Sharp
        )
        val Brightness6 = MaterialIcon(
            name = "brightness_6",
            style = MaterialIcon.Style.Sharp
        )
        val Brightness7 = MaterialIcon(
            name = "brightness_7",
            style = MaterialIcon.Style.Sharp
        )
        val BrightnessAuto = MaterialIcon(
            name = "brightness_auto",
            style = MaterialIcon.Style.Sharp
        )
        val BrightnessHigh = MaterialIcon(
            name = "brightness_high",
            style = MaterialIcon.Style.Sharp
        )
        val BrightnessLow = MaterialIcon(
            name = "brightness_low",
            style = MaterialIcon.Style.Sharp
        )
        val BrightnessMedium = MaterialIcon(
            name = "brightness_medium",
            style = MaterialIcon.Style.Sharp
        )
        val BroadcastOnHome = MaterialIcon(
            name = "broadcast_on_home",
            style = MaterialIcon.Style.Sharp
        )
        val BroadcastOnPersonal = MaterialIcon(
            name = "broadcast_on_personal",
            style = MaterialIcon.Style.Sharp
        )
        val BrokenImage = MaterialIcon(
            name = "broken_image",
            style = MaterialIcon.Style.Sharp
        )
        val BrowseGallery = MaterialIcon(
            name = "browse_gallery",
            style = MaterialIcon.Style.Sharp
        )
        val BrowserNotSupported = MaterialIcon(
            name = "browser_not_supported",
            style = MaterialIcon.Style.Sharp
        )
        val BrowserUpdated = MaterialIcon(
            name = "browser_updated",
            style = MaterialIcon.Style.Sharp
        )
        val BrunchDining = MaterialIcon(
            name = "brunch_dining",
            style = MaterialIcon.Style.Sharp
        )
        val Brush = MaterialIcon(
            name = "brush",
            style = MaterialIcon.Style.Sharp
        )
        val BubbleChart = MaterialIcon(
            name = "bubble_chart",
            style = MaterialIcon.Style.Sharp
        )
        val BugReport = MaterialIcon(
            name = "bug_report",
            style = MaterialIcon.Style.Sharp
        )
        val Build = MaterialIcon(
            name = "build",
            style = MaterialIcon.Style.Sharp
        )
        val BuildCircle = MaterialIcon(
            name = "build_circle",
            style = MaterialIcon.Style.Sharp
        )
        val Bungalow = MaterialIcon(
            name = "bungalow",
            style = MaterialIcon.Style.Sharp
        )
        val BurstMode = MaterialIcon(
            name = "burst_mode",
            style = MaterialIcon.Style.Sharp
        )
        val BusAlert = MaterialIcon(
            name = "bus_alert",
            style = MaterialIcon.Style.Sharp
        )
        val Business = MaterialIcon(
            name = "business",
            style = MaterialIcon.Style.Sharp
        )
        val BusinessCenter = MaterialIcon(
            name = "business_center",
            style = MaterialIcon.Style.Sharp
        )
        val Cabin = MaterialIcon(
            name = "cabin",
            style = MaterialIcon.Style.Sharp
        )
        val Cable = MaterialIcon(
            name = "cable",
            style = MaterialIcon.Style.Sharp
        )
        val Cached = MaterialIcon(
            name = "cached",
            style = MaterialIcon.Style.Sharp
        )
        val Cake = MaterialIcon(
            name = "cake",
            style = MaterialIcon.Style.Sharp
        )
        val Calculate = MaterialIcon(
            name = "calculate",
            style = MaterialIcon.Style.Sharp
        )
        val CalendarMonth = MaterialIcon(
            name = "calendar_month",
            style = MaterialIcon.Style.Sharp
        )
        val CalendarToday = MaterialIcon(
            name = "calendar_today",
            style = MaterialIcon.Style.Sharp
        )
        val CalendarViewDay = MaterialIcon(
            name = "calendar_view_day",
            style = MaterialIcon.Style.Sharp
        )
        val CalendarViewMonth = MaterialIcon(
            name = "calendar_view_month",
            style = MaterialIcon.Style.Sharp
        )
        val CalendarViewWeek = MaterialIcon(
            name = "calendar_view_week",
            style = MaterialIcon.Style.Sharp
        )
        val Call = MaterialIcon(
            name = "call",
            style = MaterialIcon.Style.Sharp
        )
        val CallEnd = MaterialIcon(
            name = "call_end",
            style = MaterialIcon.Style.Sharp
        )
        val CallMade = MaterialIcon(
            name = "call_made",
            style = MaterialIcon.Style.Sharp
        )
        val CallMerge = MaterialIcon(
            name = "call_merge",
            style = MaterialIcon.Style.Sharp
        )
        val CallMissed = MaterialIcon(
            name = "call_missed",
            style = MaterialIcon.Style.Sharp
        )
        val CallMissedOutgoing = MaterialIcon(
            name = "call_missed_outgoing",
            style = MaterialIcon.Style.Sharp
        )
        val CallReceived = MaterialIcon(
            name = "call_received",
            style = MaterialIcon.Style.Sharp
        )
        val CallSplit = MaterialIcon(
            name = "call_split",
            style = MaterialIcon.Style.Sharp
        )
        val CallToAction = MaterialIcon(
            name = "call_to_action",
            style = MaterialIcon.Style.Sharp
        )
        val Camera = MaterialIcon(
            name = "camera",
            style = MaterialIcon.Style.Sharp
        )
        val CameraAlt = MaterialIcon(
            name = "camera_alt",
            style = MaterialIcon.Style.Sharp
        )
        val CameraEnhance = MaterialIcon(
            name = "camera_enhance",
            style = MaterialIcon.Style.Sharp
        )
        val CameraFront = MaterialIcon(
            name = "camera_front",
            style = MaterialIcon.Style.Sharp
        )
        val CameraIndoor = MaterialIcon(
            name = "camera_indoor",
            style = MaterialIcon.Style.Sharp
        )
        val CameraOutdoor = MaterialIcon(
            name = "camera_outdoor",
            style = MaterialIcon.Style.Sharp
        )
        val CameraRear = MaterialIcon(
            name = "camera_rear",
            style = MaterialIcon.Style.Sharp
        )
        val CameraRoll = MaterialIcon(
            name = "camera_roll",
            style = MaterialIcon.Style.Sharp
        )
        val Cameraswitch = MaterialIcon(
            name = "cameraswitch",
            style = MaterialIcon.Style.Sharp
        )
        val Campaign = MaterialIcon(
            name = "campaign",
            style = MaterialIcon.Style.Sharp
        )
        val Cancel = MaterialIcon(
            name = "cancel",
            style = MaterialIcon.Style.Sharp
        )
        val CancelPresentation = MaterialIcon(
            name = "cancel_presentation",
            style = MaterialIcon.Style.Sharp
        )
        val CancelScheduleSend = MaterialIcon(
            name = "cancel_schedule_send",
            style = MaterialIcon.Style.Sharp
        )
        val CandlestickChart = MaterialIcon(
            name = "candlestick_chart",
            style = MaterialIcon.Style.Sharp
        )
        val CarCrash = MaterialIcon(
            name = "car_crash",
            style = MaterialIcon.Style.Sharp
        )
        val CarRental = MaterialIcon(
            name = "car_rental",
            style = MaterialIcon.Style.Sharp
        )
        val CarRepair = MaterialIcon(
            name = "car_repair",
            style = MaterialIcon.Style.Sharp
        )
        val CardGiftcard = MaterialIcon(
            name = "card_giftcard",
            style = MaterialIcon.Style.Sharp
        )
        val CardMembership = MaterialIcon(
            name = "card_membership",
            style = MaterialIcon.Style.Sharp
        )
        val CardTravel = MaterialIcon(
            name = "card_travel",
            style = MaterialIcon.Style.Sharp
        )
        val Carpenter = MaterialIcon(
            name = "carpenter",
            style = MaterialIcon.Style.Sharp
        )
        val Cases = MaterialIcon(
            name = "cases",
            style = MaterialIcon.Style.Sharp
        )
        val Casino = MaterialIcon(
            name = "casino",
            style = MaterialIcon.Style.Sharp
        )
        val Cast = MaterialIcon(
            name = "cast",
            style = MaterialIcon.Style.Sharp
        )
        val CastConnected = MaterialIcon(
            name = "cast_connected",
            style = MaterialIcon.Style.Sharp
        )
        val CastForEducation = MaterialIcon(
            name = "cast_for_education",
            style = MaterialIcon.Style.Sharp
        )
        val Castle = MaterialIcon(
            name = "castle",
            style = MaterialIcon.Style.Sharp
        )
        val CatchingPokemon = MaterialIcon(
            name = "catching_pokemon",
            style = MaterialIcon.Style.Sharp
        )
        val Category = MaterialIcon(
            name = "category",
            style = MaterialIcon.Style.Sharp
        )
        val Celebration = MaterialIcon(
            name = "celebration",
            style = MaterialIcon.Style.Sharp
        )
        val CellTower = MaterialIcon(
            name = "cell_tower",
            style = MaterialIcon.Style.Sharp
        )
        val CellWifi = MaterialIcon(
            name = "cell_wifi",
            style = MaterialIcon.Style.Sharp
        )
        val CenterFocusStrong = MaterialIcon(
            name = "center_focus_strong",
            style = MaterialIcon.Style.Sharp
        )
        val CenterFocusWeak = MaterialIcon(
            name = "center_focus_weak",
            style = MaterialIcon.Style.Sharp
        )
        val Chair = MaterialIcon(
            name = "chair",
            style = MaterialIcon.Style.Sharp
        )
        val ChairAlt = MaterialIcon(
            name = "chair_alt",
            style = MaterialIcon.Style.Sharp
        )
        val Chalet = MaterialIcon(
            name = "chalet",
            style = MaterialIcon.Style.Sharp
        )
        val ChangeCircle = MaterialIcon(
            name = "change_circle",
            style = MaterialIcon.Style.Sharp
        )
        val ChangeHistory = MaterialIcon(
            name = "change_history",
            style = MaterialIcon.Style.Sharp
        )
        val ChargingStation = MaterialIcon(
            name = "charging_station",
            style = MaterialIcon.Style.Sharp
        )
        val Chat = MaterialIcon(
            name = "chat",
            style = MaterialIcon.Style.Sharp
        )
        val ChatBubble = MaterialIcon(
            name = "chat_bubble",
            style = MaterialIcon.Style.Sharp
        )
        val ChatBubbleOutline = MaterialIcon(
            name = "chat_bubble_outline",
            style = MaterialIcon.Style.Sharp
        )
        val Check = MaterialIcon(
            name = "check",
            style = MaterialIcon.Style.Sharp
        )
        val CheckBox = MaterialIcon(
            name = "check_box",
            style = MaterialIcon.Style.Sharp
        )
        val CheckBoxOutlineBlank = MaterialIcon(
            name = "check_box_outline_blank",
            style = MaterialIcon.Style.Sharp
        )
        val CheckCircle = MaterialIcon(
            name = "check_circle",
            style = MaterialIcon.Style.Sharp
        )
        val CheckCircleOutline = MaterialIcon(
            name = "check_circle_outline",
            style = MaterialIcon.Style.Sharp
        )
        val Checklist = MaterialIcon(
            name = "checklist",
            style = MaterialIcon.Style.Sharp
        )
        val ChecklistRtl = MaterialIcon(
            name = "checklist_rtl",
            style = MaterialIcon.Style.Sharp
        )
        val Checkroom = MaterialIcon(
            name = "checkroom",
            style = MaterialIcon.Style.Sharp
        )
        val ChevronLeft = MaterialIcon(
            name = "chevron_left",
            style = MaterialIcon.Style.Sharp
        )
        val ChevronRight = MaterialIcon(
            name = "chevron_right",
            style = MaterialIcon.Style.Sharp
        )
        val ChildCare = MaterialIcon(
            name = "child_care",
            style = MaterialIcon.Style.Sharp
        )
        val ChildFriendly = MaterialIcon(
            name = "child_friendly",
            style = MaterialIcon.Style.Sharp
        )
        val ChromeReaderMode = MaterialIcon(
            name = "chrome_reader_mode",
            style = MaterialIcon.Style.Sharp
        )
        val Church = MaterialIcon(
            name = "church",
            style = MaterialIcon.Style.Sharp
        )
        val Circle = MaterialIcon(
            name = "circle",
            style = MaterialIcon.Style.Sharp
        )
        val CircleNotifications = MaterialIcon(
            name = "circle_notifications",
            style = MaterialIcon.Style.Sharp
        )
        val Class = MaterialIcon(
            name = "class",
            style = MaterialIcon.Style.Sharp
        )
        val CleanHands = MaterialIcon(
            name = "clean_hands",
            style = MaterialIcon.Style.Sharp
        )
        val CleaningServices = MaterialIcon(
            name = "cleaning_services",
            style = MaterialIcon.Style.Sharp
        )
        val Clear = MaterialIcon(
            name = "clear",
            style = MaterialIcon.Style.Sharp
        )
        val ClearAll = MaterialIcon(
            name = "clear_all",
            style = MaterialIcon.Style.Sharp
        )
        val Close = MaterialIcon(
            name = "close",
            style = MaterialIcon.Style.Sharp
        )
        val CloseFullscreen = MaterialIcon(
            name = "close_fullscreen",
            style = MaterialIcon.Style.Sharp
        )
        val ClosedCaption = MaterialIcon(
            name = "closed_caption",
            style = MaterialIcon.Style.Sharp
        )
        val ClosedCaptionDisabled = MaterialIcon(
            name = "closed_caption_disabled",
            style = MaterialIcon.Style.Sharp
        )
        val ClosedCaptionOff = MaterialIcon(
            name = "closed_caption_off",
            style = MaterialIcon.Style.Sharp
        )
        val Cloud = MaterialIcon(
            name = "cloud",
            style = MaterialIcon.Style.Sharp
        )
        val CloudCircle = MaterialIcon(
            name = "cloud_circle",
            style = MaterialIcon.Style.Sharp
        )
        val CloudDone = MaterialIcon(
            name = "cloud_done",
            style = MaterialIcon.Style.Sharp
        )
        val CloudDownload = MaterialIcon(
            name = "cloud_download",
            style = MaterialIcon.Style.Sharp
        )
        val CloudOff = MaterialIcon(
            name = "cloud_off",
            style = MaterialIcon.Style.Sharp
        )
        val CloudQueue = MaterialIcon(
            name = "cloud_queue",
            style = MaterialIcon.Style.Sharp
        )
        val CloudSync = MaterialIcon(
            name = "cloud_sync",
            style = MaterialIcon.Style.Sharp
        )
        val CloudUpload = MaterialIcon(
            name = "cloud_upload",
            style = MaterialIcon.Style.Sharp
        )
        val Co2 = MaterialIcon(
            name = "co2",
            style = MaterialIcon.Style.Sharp
        )
        val CoPresent = MaterialIcon(
            name = "co_present",
            style = MaterialIcon.Style.Sharp
        )
        val Code = MaterialIcon(
            name = "code",
            style = MaterialIcon.Style.Sharp
        )
        val CodeOff = MaterialIcon(
            name = "code_off",
            style = MaterialIcon.Style.Sharp
        )
        val Coffee = MaterialIcon(
            name = "coffee",
            style = MaterialIcon.Style.Sharp
        )
        val CoffeeMaker = MaterialIcon(
            name = "coffee_maker",
            style = MaterialIcon.Style.Sharp
        )
        val Collections = MaterialIcon(
            name = "collections",
            style = MaterialIcon.Style.Sharp
        )
        val CollectionsBookmark = MaterialIcon(
            name = "collections_bookmark",
            style = MaterialIcon.Style.Sharp
        )
        val ColorLens = MaterialIcon(
            name = "color_lens",
            style = MaterialIcon.Style.Sharp
        )
        val Colorize = MaterialIcon(
            name = "colorize",
            style = MaterialIcon.Style.Sharp
        )
        val Comment = MaterialIcon(
            name = "comment",
            style = MaterialIcon.Style.Sharp
        )
        val CommentBank = MaterialIcon(
            name = "comment_bank",
            style = MaterialIcon.Style.Sharp
        )
        val CommentsDisabled = MaterialIcon(
            name = "comments_disabled",
            style = MaterialIcon.Style.Sharp
        )
        val Commit = MaterialIcon(
            name = "commit",
            style = MaterialIcon.Style.Sharp
        )
        val Commute = MaterialIcon(
            name = "commute",
            style = MaterialIcon.Style.Sharp
        )
        val Compare = MaterialIcon(
            name = "compare",
            style = MaterialIcon.Style.Sharp
        )
        val CompareArrows = MaterialIcon(
            name = "compare_arrows",
            style = MaterialIcon.Style.Sharp
        )
        val CompassCalibration = MaterialIcon(
            name = "compass_calibration",
            style = MaterialIcon.Style.Sharp
        )
        val Compost = MaterialIcon(
            name = "compost",
            style = MaterialIcon.Style.Sharp
        )
        val Compress = MaterialIcon(
            name = "compress",
            style = MaterialIcon.Style.Sharp
        )
        val Computer = MaterialIcon(
            name = "computer",
            style = MaterialIcon.Style.Sharp
        )
        val ConfirmationNumber = MaterialIcon(
            name = "confirmation_number",
            style = MaterialIcon.Style.Sharp
        )
        val ConnectWithoutContact = MaterialIcon(
            name = "connect_without_contact",
            style = MaterialIcon.Style.Sharp
        )
        val ConnectedTv = MaterialIcon(
            name = "connected_tv",
            style = MaterialIcon.Style.Sharp
        )
        val ConnectingAirports = MaterialIcon(
            name = "connecting_airports",
            style = MaterialIcon.Style.Sharp
        )
        val Construction = MaterialIcon(
            name = "construction",
            style = MaterialIcon.Style.Sharp
        )
        val ContactEmergency = MaterialIcon(
            name = "contact_emergency",
            style = MaterialIcon.Style.Sharp
        )
        val ContactMail = MaterialIcon(
            name = "contact_mail",
            style = MaterialIcon.Style.Sharp
        )
        val ContactPage = MaterialIcon(
            name = "contact_page",
            style = MaterialIcon.Style.Sharp
        )
        val ContactPhone = MaterialIcon(
            name = "contact_phone",
            style = MaterialIcon.Style.Sharp
        )
        val ContactSupport = MaterialIcon(
            name = "contact_support",
            style = MaterialIcon.Style.Sharp
        )
        val Contactless = MaterialIcon(
            name = "contactless",
            style = MaterialIcon.Style.Sharp
        )
        val Contacts = MaterialIcon(
            name = "contacts",
            style = MaterialIcon.Style.Sharp
        )
        val ContentCopy = MaterialIcon(
            name = "content_copy",
            style = MaterialIcon.Style.Sharp
        )
        val ContentCut = MaterialIcon(
            name = "content_cut",
            style = MaterialIcon.Style.Sharp
        )
        val ContentPaste = MaterialIcon(
            name = "content_paste",
            style = MaterialIcon.Style.Sharp
        )
        val ContentPasteGo = MaterialIcon(
            name = "content_paste_go",
            style = MaterialIcon.Style.Sharp
        )
        val ContentPasteOff = MaterialIcon(
            name = "content_paste_off",
            style = MaterialIcon.Style.Sharp
        )
        val ContentPasteSearch = MaterialIcon(
            name = "content_paste_search",
            style = MaterialIcon.Style.Sharp
        )
        val Contrast = MaterialIcon(
            name = "contrast",
            style = MaterialIcon.Style.Sharp
        )
        val ControlCamera = MaterialIcon(
            name = "control_camera",
            style = MaterialIcon.Style.Sharp
        )
        val ControlPoint = MaterialIcon(
            name = "control_point",
            style = MaterialIcon.Style.Sharp
        )
        val ControlPointDuplicate = MaterialIcon(
            name = "control_point_duplicate",
            style = MaterialIcon.Style.Sharp
        )
        val Cookie = MaterialIcon(
            name = "cookie",
            style = MaterialIcon.Style.Sharp
        )
        val CopyAll = MaterialIcon(
            name = "copy_all",
            style = MaterialIcon.Style.Sharp
        )
        val Copyright = MaterialIcon(
            name = "copyright",
            style = MaterialIcon.Style.Sharp
        )
        val Coronavirus = MaterialIcon(
            name = "coronavirus",
            style = MaterialIcon.Style.Sharp
        )
        val CorporateFare = MaterialIcon(
            name = "corporate_fare",
            style = MaterialIcon.Style.Sharp
        )
        val Cottage = MaterialIcon(
            name = "cottage",
            style = MaterialIcon.Style.Sharp
        )
        val Countertops = MaterialIcon(
            name = "countertops",
            style = MaterialIcon.Style.Sharp
        )
        val Create = MaterialIcon(
            name = "create",
            style = MaterialIcon.Style.Sharp
        )
        val CreateNewFolder = MaterialIcon(
            name = "create_new_folder",
            style = MaterialIcon.Style.Sharp
        )
        val CreditCard = MaterialIcon(
            name = "credit_card",
            style = MaterialIcon.Style.Sharp
        )
        val CreditCardOff = MaterialIcon(
            name = "credit_card_off",
            style = MaterialIcon.Style.Sharp
        )
        val CreditScore = MaterialIcon(
            name = "credit_score",
            style = MaterialIcon.Style.Sharp
        )
        val Crib = MaterialIcon(
            name = "crib",
            style = MaterialIcon.Style.Sharp
        )
        val CrisisAlert = MaterialIcon(
            name = "crisis_alert",
            style = MaterialIcon.Style.Sharp
        )
        val Crop = MaterialIcon(
            name = "crop",
            style = MaterialIcon.Style.Sharp
        )
        val Crop169 = MaterialIcon(
            name = "crop_16_9",
            style = MaterialIcon.Style.Sharp
        )
        val Crop32 = MaterialIcon(
            name = "crop_3_2",
            style = MaterialIcon.Style.Sharp
        )
        val Crop54 = MaterialIcon(
            name = "crop_5_4",
            style = MaterialIcon.Style.Sharp
        )
        val Crop75 = MaterialIcon(
            name = "crop_7_5",
            style = MaterialIcon.Style.Sharp
        )
        val CropDin = MaterialIcon(
            name = "crop_din",
            style = MaterialIcon.Style.Sharp
        )
        val CropFree = MaterialIcon(
            name = "crop_free",
            style = MaterialIcon.Style.Sharp
        )
        val CropLandscape = MaterialIcon(
            name = "crop_landscape",
            style = MaterialIcon.Style.Sharp
        )
        val CropOriginal = MaterialIcon(
            name = "crop_original",
            style = MaterialIcon.Style.Sharp
        )
        val CropPortrait = MaterialIcon(
            name = "crop_portrait",
            style = MaterialIcon.Style.Sharp
        )
        val CropRotate = MaterialIcon(
            name = "crop_rotate",
            style = MaterialIcon.Style.Sharp
        )
        val CropSquare = MaterialIcon(
            name = "crop_square",
            style = MaterialIcon.Style.Sharp
        )
        val CrueltyFree = MaterialIcon(
            name = "cruelty_free",
            style = MaterialIcon.Style.Sharp
        )
        val Css = MaterialIcon(
            name = "css",
            style = MaterialIcon.Style.Sharp
        )
        val CurrencyBitcoin = MaterialIcon(
            name = "currency_bitcoin",
            style = MaterialIcon.Style.Sharp
        )
        val CurrencyExchange = MaterialIcon(
            name = "currency_exchange",
            style = MaterialIcon.Style.Sharp
        )
        val CurrencyFranc = MaterialIcon(
            name = "currency_franc",
            style = MaterialIcon.Style.Sharp
        )
        val CurrencyLira = MaterialIcon(
            name = "currency_lira",
            style = MaterialIcon.Style.Sharp
        )
        val CurrencyPound = MaterialIcon(
            name = "currency_pound",
            style = MaterialIcon.Style.Sharp
        )
        val CurrencyRuble = MaterialIcon(
            name = "currency_ruble",
            style = MaterialIcon.Style.Sharp
        )
        val CurrencyRupee = MaterialIcon(
            name = "currency_rupee",
            style = MaterialIcon.Style.Sharp
        )
        val CurrencyYen = MaterialIcon(
            name = "currency_yen",
            style = MaterialIcon.Style.Sharp
        )
        val CurrencyYuan = MaterialIcon(
            name = "currency_yuan",
            style = MaterialIcon.Style.Sharp
        )
        val Curtains = MaterialIcon(
            name = "curtains",
            style = MaterialIcon.Style.Sharp
        )
        val CurtainsClosed = MaterialIcon(
            name = "curtains_closed",
            style = MaterialIcon.Style.Sharp
        )
        val Cyclone = MaterialIcon(
            name = "cyclone",
            style = MaterialIcon.Style.Sharp
        )
        val Dangerous = MaterialIcon(
            name = "dangerous",
            style = MaterialIcon.Style.Sharp
        )
        val DarkMode = MaterialIcon(
            name = "dark_mode",
            style = MaterialIcon.Style.Sharp
        )
        val Dashboard = MaterialIcon(
            name = "dashboard",
            style = MaterialIcon.Style.Sharp
        )
        val DashboardCustomize = MaterialIcon(
            name = "dashboard_customize",
            style = MaterialIcon.Style.Sharp
        )
        val DataArray = MaterialIcon(
            name = "data_array",
            style = MaterialIcon.Style.Sharp
        )
        val DataExploration = MaterialIcon(
            name = "data_exploration",
            style = MaterialIcon.Style.Sharp
        )
        val DataObject = MaterialIcon(
            name = "data_object",
            style = MaterialIcon.Style.Sharp
        )
        val DataSaverOff = MaterialIcon(
            name = "data_saver_off",
            style = MaterialIcon.Style.Sharp
        )
        val DataSaverOn = MaterialIcon(
            name = "data_saver_on",
            style = MaterialIcon.Style.Sharp
        )
        val DataThresholding = MaterialIcon(
            name = "data_thresholding",
            style = MaterialIcon.Style.Sharp
        )
        val DataUsage = MaterialIcon(
            name = "data_usage",
            style = MaterialIcon.Style.Sharp
        )
        val Dataset = MaterialIcon(
            name = "dataset",
            style = MaterialIcon.Style.Sharp
        )
        val DatasetLinked = MaterialIcon(
            name = "dataset_linked",
            style = MaterialIcon.Style.Sharp
        )
        val DateRange = MaterialIcon(
            name = "date_range",
            style = MaterialIcon.Style.Sharp
        )
        val Deblur = MaterialIcon(
            name = "deblur",
            style = MaterialIcon.Style.Sharp
        )
        val Deck = MaterialIcon(
            name = "deck",
            style = MaterialIcon.Style.Sharp
        )
        val Dehaze = MaterialIcon(
            name = "dehaze",
            style = MaterialIcon.Style.Sharp
        )
        val Delete = MaterialIcon(
            name = "delete",
            style = MaterialIcon.Style.Sharp
        )
        val DeleteForever = MaterialIcon(
            name = "delete_forever",
            style = MaterialIcon.Style.Sharp
        )
        val DeleteOutline = MaterialIcon(
            name = "delete_outline",
            style = MaterialIcon.Style.Sharp
        )
        val DeleteSweep = MaterialIcon(
            name = "delete_sweep",
            style = MaterialIcon.Style.Sharp
        )
        val DeliveryDining = MaterialIcon(
            name = "delivery_dining",
            style = MaterialIcon.Style.Sharp
        )
        val DensityLarge = MaterialIcon(
            name = "density_large",
            style = MaterialIcon.Style.Sharp
        )
        val DensityMedium = MaterialIcon(
            name = "density_medium",
            style = MaterialIcon.Style.Sharp
        )
        val DensitySmall = MaterialIcon(
            name = "density_small",
            style = MaterialIcon.Style.Sharp
        )
        val DepartureBoard = MaterialIcon(
            name = "departure_board",
            style = MaterialIcon.Style.Sharp
        )
        val Description = MaterialIcon(
            name = "description",
            style = MaterialIcon.Style.Sharp
        )
        val Deselect = MaterialIcon(
            name = "deselect",
            style = MaterialIcon.Style.Sharp
        )
        val DesignServices = MaterialIcon(
            name = "design_services",
            style = MaterialIcon.Style.Sharp
        )
        val Desk = MaterialIcon(
            name = "desk",
            style = MaterialIcon.Style.Sharp
        )
        val DesktopAccessDisabled = MaterialIcon(
            name = "desktop_access_disabled",
            style = MaterialIcon.Style.Sharp
        )
        val DesktopMac = MaterialIcon(
            name = "desktop_mac",
            style = MaterialIcon.Style.Sharp
        )
        val DesktopWindows = MaterialIcon(
            name = "desktop_windows",
            style = MaterialIcon.Style.Sharp
        )
        val Details = MaterialIcon(
            name = "details",
            style = MaterialIcon.Style.Sharp
        )
        val DeveloperBoard = MaterialIcon(
            name = "developer_board",
            style = MaterialIcon.Style.Sharp
        )
        val DeveloperBoardOff = MaterialIcon(
            name = "developer_board_off",
            style = MaterialIcon.Style.Sharp
        )
        val DeveloperMode = MaterialIcon(
            name = "developer_mode",
            style = MaterialIcon.Style.Sharp
        )
        val DeviceHub = MaterialIcon(
            name = "device_hub",
            style = MaterialIcon.Style.Sharp
        )
        val DeviceThermostat = MaterialIcon(
            name = "device_thermostat",
            style = MaterialIcon.Style.Sharp
        )
        val DeviceUnknown = MaterialIcon(
            name = "device_unknown",
            style = MaterialIcon.Style.Sharp
        )
        val Devices = MaterialIcon(
            name = "devices",
            style = MaterialIcon.Style.Sharp
        )
        val DevicesFold = MaterialIcon(
            name = "devices_fold",
            style = MaterialIcon.Style.Sharp
        )
        val DevicesOther = MaterialIcon(
            name = "devices_other",
            style = MaterialIcon.Style.Sharp
        )
        val DialerSip = MaterialIcon(
            name = "dialer_sip",
            style = MaterialIcon.Style.Sharp
        )
        val Dialpad = MaterialIcon(
            name = "dialpad",
            style = MaterialIcon.Style.Sharp
        )
        val Diamond = MaterialIcon(
            name = "diamond",
            style = MaterialIcon.Style.Sharp
        )
        val Difference = MaterialIcon(
            name = "difference",
            style = MaterialIcon.Style.Sharp
        )
        val Dining = MaterialIcon(
            name = "dining",
            style = MaterialIcon.Style.Sharp
        )
        val DinnerDining = MaterialIcon(
            name = "dinner_dining",
            style = MaterialIcon.Style.Sharp
        )
        val Directions = MaterialIcon(
            name = "directions",
            style = MaterialIcon.Style.Sharp
        )
        val DirectionsBike = MaterialIcon(
            name = "directions_bike",
            style = MaterialIcon.Style.Sharp
        )
        val DirectionsBoat = MaterialIcon(
            name = "directions_boat",
            style = MaterialIcon.Style.Sharp
        )
        val DirectionsBoatFilled = MaterialIcon(
            name = "directions_boat_filled",
            style = MaterialIcon.Style.Sharp
        )
        val DirectionsBus = MaterialIcon(
            name = "directions_bus",
            style = MaterialIcon.Style.Sharp
        )
        val DirectionsBusFilled = MaterialIcon(
            name = "directions_bus_filled",
            style = MaterialIcon.Style.Sharp
        )
        val DirectionsCar = MaterialIcon(
            name = "directions_car",
            style = MaterialIcon.Style.Sharp
        )
        val DirectionsCarFilled = MaterialIcon(
            name = "directions_car_filled",
            style = MaterialIcon.Style.Sharp
        )
        val DirectionsOff = MaterialIcon(
            name = "directions_off",
            style = MaterialIcon.Style.Sharp
        )
        val DirectionsRailway = MaterialIcon(
            name = "directions_railway",
            style = MaterialIcon.Style.Sharp
        )
        val DirectionsRailwayFilled = MaterialIcon(
            name = "directions_railway_filled",
            style = MaterialIcon.Style.Sharp
        )
        val DirectionsRun = MaterialIcon(
            name = "directions_run",
            style = MaterialIcon.Style.Sharp
        )
        val DirectionsSubway = MaterialIcon(
            name = "directions_subway",
            style = MaterialIcon.Style.Sharp
        )
        val DirectionsSubwayFilled = MaterialIcon(
            name = "directions_subway_filled",
            style = MaterialIcon.Style.Sharp
        )
        val DirectionsTransit = MaterialIcon(
            name = "directions_transit",
            style = MaterialIcon.Style.Sharp
        )
        val DirectionsTransitFilled = MaterialIcon(
            name = "directions_transit_filled",
            style = MaterialIcon.Style.Sharp
        )
        val DirectionsWalk = MaterialIcon(
            name = "directions_walk",
            style = MaterialIcon.Style.Sharp
        )
        val DirtyLens = MaterialIcon(
            name = "dirty_lens",
            style = MaterialIcon.Style.Sharp
        )
        val DisabledByDefault = MaterialIcon(
            name = "disabled_by_default",
            style = MaterialIcon.Style.Sharp
        )
        val DisabledVisible = MaterialIcon(
            name = "disabled_visible",
            style = MaterialIcon.Style.Sharp
        )
        val DiscFull = MaterialIcon(
            name = "disc_full",
            style = MaterialIcon.Style.Sharp
        )
        val Discount = MaterialIcon(
            name = "discount",
            style = MaterialIcon.Style.Sharp
        )
        val DisplaySettings = MaterialIcon(
            name = "display_settings",
            style = MaterialIcon.Style.Sharp
        )
        val Diversity1 = MaterialIcon(
            name = "diversity_1",
            style = MaterialIcon.Style.Sharp
        )
        val Diversity2 = MaterialIcon(
            name = "diversity_2",
            style = MaterialIcon.Style.Sharp
        )
        val Diversity3 = MaterialIcon(
            name = "diversity_3",
            style = MaterialIcon.Style.Sharp
        )
        val Dns = MaterialIcon(
            name = "dns",
            style = MaterialIcon.Style.Sharp
        )
        val DoDisturb = MaterialIcon(
            name = "do_disturb",
            style = MaterialIcon.Style.Sharp
        )
        val DoDisturbAlt = MaterialIcon(
            name = "do_disturb_alt",
            style = MaterialIcon.Style.Sharp
        )
        val DoDisturbOff = MaterialIcon(
            name = "do_disturb_off",
            style = MaterialIcon.Style.Sharp
        )
        val DoDisturbOn = MaterialIcon(
            name = "do_disturb_on",
            style = MaterialIcon.Style.Sharp
        )
        val DoNotDisturb = MaterialIcon(
            name = "do_not_disturb",
            style = MaterialIcon.Style.Sharp
        )
        val DoNotDisturbAlt = MaterialIcon(
            name = "do_not_disturb_alt",
            style = MaterialIcon.Style.Sharp
        )
        val DoNotDisturbOff = MaterialIcon(
            name = "do_not_disturb_off",
            style = MaterialIcon.Style.Sharp
        )
        val DoNotDisturbOn = MaterialIcon(
            name = "do_not_disturb_on",
            style = MaterialIcon.Style.Sharp
        )
        val DoNotDisturbOnTotalSilence = MaterialIcon(
            name = "do_not_disturb_on_total_silence",
            style = MaterialIcon.Style.Sharp
        )
        val DoNotStep = MaterialIcon(
            name = "do_not_step",
            style = MaterialIcon.Style.Sharp
        )
        val DoNotTouch = MaterialIcon(
            name = "do_not_touch",
            style = MaterialIcon.Style.Sharp
        )
        val Dock = MaterialIcon(
            name = "dock",
            style = MaterialIcon.Style.Sharp
        )
        val DocumentScanner = MaterialIcon(
            name = "document_scanner",
            style = MaterialIcon.Style.Sharp
        )
        val Domain = MaterialIcon(
            name = "domain",
            style = MaterialIcon.Style.Sharp
        )
        val DomainAdd = MaterialIcon(
            name = "domain_add",
            style = MaterialIcon.Style.Sharp
        )
        val DomainDisabled = MaterialIcon(
            name = "domain_disabled",
            style = MaterialIcon.Style.Sharp
        )
        val DomainVerification = MaterialIcon(
            name = "domain_verification",
            style = MaterialIcon.Style.Sharp
        )
        val Done = MaterialIcon(
            name = "done",
            style = MaterialIcon.Style.Sharp
        )
        val DoneAll = MaterialIcon(
            name = "done_all",
            style = MaterialIcon.Style.Sharp
        )
        val DoneOutline = MaterialIcon(
            name = "done_outline",
            style = MaterialIcon.Style.Sharp
        )
        val DonutLarge = MaterialIcon(
            name = "donut_large",
            style = MaterialIcon.Style.Sharp
        )
        val DonutSmall = MaterialIcon(
            name = "donut_small",
            style = MaterialIcon.Style.Sharp
        )
        val DoorBack = MaterialIcon(
            name = "door_back",
            style = MaterialIcon.Style.Sharp
        )
        val DoorFront = MaterialIcon(
            name = "door_front",
            style = MaterialIcon.Style.Sharp
        )
        val DoorSliding = MaterialIcon(
            name = "door_sliding",
            style = MaterialIcon.Style.Sharp
        )
        val Doorbell = MaterialIcon(
            name = "doorbell",
            style = MaterialIcon.Style.Sharp
        )
        val DoubleArrow = MaterialIcon(
            name = "double_arrow",
            style = MaterialIcon.Style.Sharp
        )
        val DownhillSkiing = MaterialIcon(
            name = "downhill_skiing",
            style = MaterialIcon.Style.Sharp
        )
        val Download = MaterialIcon(
            name = "download",
            style = MaterialIcon.Style.Sharp
        )
        val DownloadDone = MaterialIcon(
            name = "download_done",
            style = MaterialIcon.Style.Sharp
        )
        val DownloadForOffline = MaterialIcon(
            name = "download_for_offline",
            style = MaterialIcon.Style.Sharp
        )
        val Downloading = MaterialIcon(
            name = "downloading",
            style = MaterialIcon.Style.Sharp
        )
        val Drafts = MaterialIcon(
            name = "drafts",
            style = MaterialIcon.Style.Sharp
        )
        val DragHandle = MaterialIcon(
            name = "drag_handle",
            style = MaterialIcon.Style.Sharp
        )
        val DragIndicator = MaterialIcon(
            name = "drag_indicator",
            style = MaterialIcon.Style.Sharp
        )
        val Draw = MaterialIcon(
            name = "draw",
            style = MaterialIcon.Style.Sharp
        )
        val DriveEta = MaterialIcon(
            name = "drive_eta",
            style = MaterialIcon.Style.Sharp
        )
        val DriveFileMove = MaterialIcon(
            name = "drive_file_move",
            style = MaterialIcon.Style.Sharp
        )
        val DriveFileMoveRtl = MaterialIcon(
            name = "drive_file_move_rtl",
            style = MaterialIcon.Style.Sharp
        )
        val DriveFileRenameOutline = MaterialIcon(
            name = "drive_file_rename_outline",
            style = MaterialIcon.Style.Sharp
        )
        val DriveFolderUpload = MaterialIcon(
            name = "drive_folder_upload",
            style = MaterialIcon.Style.Sharp
        )
        val Dry = MaterialIcon(
            name = "dry",
            style = MaterialIcon.Style.Sharp
        )
        val DryCleaning = MaterialIcon(
            name = "dry_cleaning",
            style = MaterialIcon.Style.Sharp
        )
        val Duo = MaterialIcon(
            name = "duo",
            style = MaterialIcon.Style.Sharp
        )
        val Dvr = MaterialIcon(
            name = "dvr",
            style = MaterialIcon.Style.Sharp
        )
        val DynamicFeed = MaterialIcon(
            name = "dynamic_feed",
            style = MaterialIcon.Style.Sharp
        )
        val DynamicForm = MaterialIcon(
            name = "dynamic_form",
            style = MaterialIcon.Style.Sharp
        )
        val EMobiledata = MaterialIcon(
            name = "e_mobiledata",
            style = MaterialIcon.Style.Sharp
        )
        val Earbuds = MaterialIcon(
            name = "earbuds",
            style = MaterialIcon.Style.Sharp
        )
        val EarbudsBattery = MaterialIcon(
            name = "earbuds_battery",
            style = MaterialIcon.Style.Sharp
        )
        val East = MaterialIcon(
            name = "east",
            style = MaterialIcon.Style.Sharp
        )
        val EdgesensorHigh = MaterialIcon(
            name = "edgesensor_high",
            style = MaterialIcon.Style.Sharp
        )
        val EdgesensorLow = MaterialIcon(
            name = "edgesensor_low",
            style = MaterialIcon.Style.Sharp
        )
        val Edit = MaterialIcon(
            name = "edit",
            style = MaterialIcon.Style.Sharp
        )
        val EditAttributes = MaterialIcon(
            name = "edit_attributes",
            style = MaterialIcon.Style.Sharp
        )
        val EditCalendar = MaterialIcon(
            name = "edit_calendar",
            style = MaterialIcon.Style.Sharp
        )
        val EditLocation = MaterialIcon(
            name = "edit_location",
            style = MaterialIcon.Style.Sharp
        )
        val EditLocationAlt = MaterialIcon(
            name = "edit_location_alt",
            style = MaterialIcon.Style.Sharp
        )
        val EditNote = MaterialIcon(
            name = "edit_note",
            style = MaterialIcon.Style.Sharp
        )
        val EditNotifications = MaterialIcon(
            name = "edit_notifications",
            style = MaterialIcon.Style.Sharp
        )
        val EditOff = MaterialIcon(
            name = "edit_off",
            style = MaterialIcon.Style.Sharp
        )
        val EditRoad = MaterialIcon(
            name = "edit_road",
            style = MaterialIcon.Style.Sharp
        )
        val Egg = MaterialIcon(
            name = "egg",
            style = MaterialIcon.Style.Sharp
        )
        val EggAlt = MaterialIcon(
            name = "egg_alt",
            style = MaterialIcon.Style.Sharp
        )
        val Eject = MaterialIcon(
            name = "eject",
            style = MaterialIcon.Style.Sharp
        )
        val Elderly = MaterialIcon(
            name = "elderly",
            style = MaterialIcon.Style.Sharp
        )
        val ElderlyWoman = MaterialIcon(
            name = "elderly_woman",
            style = MaterialIcon.Style.Sharp
        )
        val ElectricBike = MaterialIcon(
            name = "electric_bike",
            style = MaterialIcon.Style.Sharp
        )
        val ElectricBolt = MaterialIcon(
            name = "electric_bolt",
            style = MaterialIcon.Style.Sharp
        )
        val ElectricCar = MaterialIcon(
            name = "electric_car",
            style = MaterialIcon.Style.Sharp
        )
        val ElectricMeter = MaterialIcon(
            name = "electric_meter",
            style = MaterialIcon.Style.Sharp
        )
        val ElectricMoped = MaterialIcon(
            name = "electric_moped",
            style = MaterialIcon.Style.Sharp
        )
        val ElectricRickshaw = MaterialIcon(
            name = "electric_rickshaw",
            style = MaterialIcon.Style.Sharp
        )
        val ElectricScooter = MaterialIcon(
            name = "electric_scooter",
            style = MaterialIcon.Style.Sharp
        )
        val ElectricalServices = MaterialIcon(
            name = "electrical_services",
            style = MaterialIcon.Style.Sharp
        )
        val Elevator = MaterialIcon(
            name = "elevator",
            style = MaterialIcon.Style.Sharp
        )
        val Email = MaterialIcon(
            name = "email",
            style = MaterialIcon.Style.Sharp
        )
        val Emergency = MaterialIcon(
            name = "emergency",
            style = MaterialIcon.Style.Sharp
        )
        val EmergencyRecording = MaterialIcon(
            name = "emergency_recording",
            style = MaterialIcon.Style.Sharp
        )
        val EmergencyShare = MaterialIcon(
            name = "emergency_share",
            style = MaterialIcon.Style.Sharp
        )
        val EmojiEmotions = MaterialIcon(
            name = "emoji_emotions",
            style = MaterialIcon.Style.Sharp
        )
        val EmojiEvents = MaterialIcon(
            name = "emoji_events",
            style = MaterialIcon.Style.Sharp
        )
        val EmojiFoodBeverage = MaterialIcon(
            name = "emoji_food_beverage",
            style = MaterialIcon.Style.Sharp
        )
        val EmojiNature = MaterialIcon(
            name = "emoji_nature",
            style = MaterialIcon.Style.Sharp
        )
        val EmojiObjects = MaterialIcon(
            name = "emoji_objects",
            style = MaterialIcon.Style.Sharp
        )
        val EmojiPeople = MaterialIcon(
            name = "emoji_people",
            style = MaterialIcon.Style.Sharp
        )
        val EmojiSymbols = MaterialIcon(
            name = "emoji_symbols",
            style = MaterialIcon.Style.Sharp
        )
        val EmojiTransportation = MaterialIcon(
            name = "emoji_transportation",
            style = MaterialIcon.Style.Sharp
        )
        val EnergySavingsLeaf = MaterialIcon(
            name = "energy_savings_leaf",
            style = MaterialIcon.Style.Sharp
        )
        val Engineering = MaterialIcon(
            name = "engineering",
            style = MaterialIcon.Style.Sharp
        )
        val EnhancedEncryption = MaterialIcon(
            name = "enhanced_encryption",
            style = MaterialIcon.Style.Sharp
        )
        val Equalizer = MaterialIcon(
            name = "equalizer",
            style = MaterialIcon.Style.Sharp
        )
        val Error = MaterialIcon(
            name = "error",
            style = MaterialIcon.Style.Sharp
        )
        val ErrorOutline = MaterialIcon(
            name = "error_outline",
            style = MaterialIcon.Style.Sharp
        )
        val Escalator = MaterialIcon(
            name = "escalator",
            style = MaterialIcon.Style.Sharp
        )
        val EscalatorWarning = MaterialIcon(
            name = "escalator_warning",
            style = MaterialIcon.Style.Sharp
        )
        val Euro = MaterialIcon(
            name = "euro",
            style = MaterialIcon.Style.Sharp
        )
        val EuroSymbol = MaterialIcon(
            name = "euro_symbol",
            style = MaterialIcon.Style.Sharp
        )
        val EvStation = MaterialIcon(
            name = "ev_station",
            style = MaterialIcon.Style.Sharp
        )
        val Event = MaterialIcon(
            name = "event",
            style = MaterialIcon.Style.Sharp
        )
        val EventAvailable = MaterialIcon(
            name = "event_available",
            style = MaterialIcon.Style.Sharp
        )
        val EventBusy = MaterialIcon(
            name = "event_busy",
            style = MaterialIcon.Style.Sharp
        )
        val EventNote = MaterialIcon(
            name = "event_note",
            style = MaterialIcon.Style.Sharp
        )
        val EventRepeat = MaterialIcon(
            name = "event_repeat",
            style = MaterialIcon.Style.Sharp
        )
        val EventSeat = MaterialIcon(
            name = "event_seat",
            style = MaterialIcon.Style.Sharp
        )
        val ExitToApp = MaterialIcon(
            name = "exit_to_app",
            style = MaterialIcon.Style.Sharp
        )
        val Expand = MaterialIcon(
            name = "expand",
            style = MaterialIcon.Style.Sharp
        )
        val ExpandCircleDown = MaterialIcon(
            name = "expand_circle_down",
            style = MaterialIcon.Style.Sharp
        )
        val ExpandLess = MaterialIcon(
            name = "expand_less",
            style = MaterialIcon.Style.Sharp
        )
        val ExpandMore = MaterialIcon(
            name = "expand_more",
            style = MaterialIcon.Style.Sharp
        )
        val Explicit = MaterialIcon(
            name = "explicit",
            style = MaterialIcon.Style.Sharp
        )
        val Explore = MaterialIcon(
            name = "explore",
            style = MaterialIcon.Style.Sharp
        )
        val ExploreOff = MaterialIcon(
            name = "explore_off",
            style = MaterialIcon.Style.Sharp
        )
        val Exposure = MaterialIcon(
            name = "exposure",
            style = MaterialIcon.Style.Sharp
        )
        val ExposureNeg1 = MaterialIcon(
            name = "exposure_neg_1",
            style = MaterialIcon.Style.Sharp
        )
        val ExposureNeg2 = MaterialIcon(
            name = "exposure_neg_2",
            style = MaterialIcon.Style.Sharp
        )
        val ExposurePlus1 = MaterialIcon(
            name = "exposure_plus_1",
            style = MaterialIcon.Style.Sharp
        )
        val ExposurePlus2 = MaterialIcon(
            name = "exposure_plus_2",
            style = MaterialIcon.Style.Sharp
        )
        val ExposureZero = MaterialIcon(
            name = "exposure_zero",
            style = MaterialIcon.Style.Sharp
        )
        val Extension = MaterialIcon(
            name = "extension",
            style = MaterialIcon.Style.Sharp
        )
        val ExtensionOff = MaterialIcon(
            name = "extension_off",
            style = MaterialIcon.Style.Sharp
        )
        val Face = MaterialIcon(
            name = "face",
            style = MaterialIcon.Style.Sharp
        )
        val Face2 = MaterialIcon(
            name = "face_2",
            style = MaterialIcon.Style.Sharp
        )
        val Face3 = MaterialIcon(
            name = "face_3",
            style = MaterialIcon.Style.Sharp
        )
        val Face4 = MaterialIcon(
            name = "face_4",
            style = MaterialIcon.Style.Sharp
        )
        val Face5 = MaterialIcon(
            name = "face_5",
            style = MaterialIcon.Style.Sharp
        )
        val Face6 = MaterialIcon(
            name = "face_6",
            style = MaterialIcon.Style.Sharp
        )
        val FaceRetouchingNatural = MaterialIcon(
            name = "face_retouching_natural",
            style = MaterialIcon.Style.Sharp
        )
        val FaceRetouchingOff = MaterialIcon(
            name = "face_retouching_off",
            style = MaterialIcon.Style.Sharp
        )
        val FaceUnlock = MaterialIcon(
            name = "face_unlock",
            style = MaterialIcon.Style.Sharp
        )
        val FactCheck = MaterialIcon(
            name = "fact_check",
            style = MaterialIcon.Style.Sharp
        )
        val Factory = MaterialIcon(
            name = "factory",
            style = MaterialIcon.Style.Sharp
        )
        val FamilyRestroom = MaterialIcon(
            name = "family_restroom",
            style = MaterialIcon.Style.Sharp
        )
        val FastForward = MaterialIcon(
            name = "fast_forward",
            style = MaterialIcon.Style.Sharp
        )
        val FastRewind = MaterialIcon(
            name = "fast_rewind",
            style = MaterialIcon.Style.Sharp
        )
        val Fastfood = MaterialIcon(
            name = "fastfood",
            style = MaterialIcon.Style.Sharp
        )
        val Favorite = MaterialIcon(
            name = "favorite",
            style = MaterialIcon.Style.Sharp
        )
        val FavoriteBorder = MaterialIcon(
            name = "favorite_border",
            style = MaterialIcon.Style.Sharp
        )
        val Fax = MaterialIcon(
            name = "fax",
            style = MaterialIcon.Style.Sharp
        )
        val FeaturedPlayList = MaterialIcon(
            name = "featured_play_list",
            style = MaterialIcon.Style.Sharp
        )
        val FeaturedVideo = MaterialIcon(
            name = "featured_video",
            style = MaterialIcon.Style.Sharp
        )
        val Feed = MaterialIcon(
            name = "feed",
            style = MaterialIcon.Style.Sharp
        )
        val Feedback = MaterialIcon(
            name = "feedback",
            style = MaterialIcon.Style.Sharp
        )
        val Female = MaterialIcon(
            name = "female",
            style = MaterialIcon.Style.Sharp
        )
        val Fence = MaterialIcon(
            name = "fence",
            style = MaterialIcon.Style.Sharp
        )
        val Festival = MaterialIcon(
            name = "festival",
            style = MaterialIcon.Style.Sharp
        )
        val FiberDvr = MaterialIcon(
            name = "fiber_dvr",
            style = MaterialIcon.Style.Sharp
        )
        val FiberManualRecord = MaterialIcon(
            name = "fiber_manual_record",
            style = MaterialIcon.Style.Sharp
        )
        val FiberNew = MaterialIcon(
            name = "fiber_new",
            style = MaterialIcon.Style.Sharp
        )
        val FiberPin = MaterialIcon(
            name = "fiber_pin",
            style = MaterialIcon.Style.Sharp
        )
        val FiberSmartRecord = MaterialIcon(
            name = "fiber_smart_record",
            style = MaterialIcon.Style.Sharp
        )
        val FileCopy = MaterialIcon(
            name = "file_copy",
            style = MaterialIcon.Style.Sharp
        )
        val FileDownload = MaterialIcon(
            name = "file_download",
            style = MaterialIcon.Style.Sharp
        )
        val FileDownloadDone = MaterialIcon(
            name = "file_download_done",
            style = MaterialIcon.Style.Sharp
        )
        val FileDownloadOff = MaterialIcon(
            name = "file_download_off",
            style = MaterialIcon.Style.Sharp
        )
        val FileOpen = MaterialIcon(
            name = "file_open",
            style = MaterialIcon.Style.Sharp
        )
        val FilePresent = MaterialIcon(
            name = "file_present",
            style = MaterialIcon.Style.Sharp
        )
        val FileUpload = MaterialIcon(
            name = "file_upload",
            style = MaterialIcon.Style.Sharp
        )
        val Filter = MaterialIcon(
            name = "filter",
            style = MaterialIcon.Style.Sharp
        )
        val Filter1 = MaterialIcon(
            name = "filter_1",
            style = MaterialIcon.Style.Sharp
        )
        val Filter2 = MaterialIcon(
            name = "filter_2",
            style = MaterialIcon.Style.Sharp
        )
        val Filter3 = MaterialIcon(
            name = "filter_3",
            style = MaterialIcon.Style.Sharp
        )
        val Filter4 = MaterialIcon(
            name = "filter_4",
            style = MaterialIcon.Style.Sharp
        )
        val Filter5 = MaterialIcon(
            name = "filter_5",
            style = MaterialIcon.Style.Sharp
        )
        val Filter6 = MaterialIcon(
            name = "filter_6",
            style = MaterialIcon.Style.Sharp
        )
        val Filter7 = MaterialIcon(
            name = "filter_7",
            style = MaterialIcon.Style.Sharp
        )
        val Filter8 = MaterialIcon(
            name = "filter_8",
            style = MaterialIcon.Style.Sharp
        )
        val Filter9 = MaterialIcon(
            name = "filter_9",
            style = MaterialIcon.Style.Sharp
        )
        val Filter9Plus = MaterialIcon(
            name = "filter_9_plus",
            style = MaterialIcon.Style.Sharp
        )
        val FilterAlt = MaterialIcon(
            name = "filter_alt",
            style = MaterialIcon.Style.Sharp
        )
        val FilterAltOff = MaterialIcon(
            name = "filter_alt_off",
            style = MaterialIcon.Style.Sharp
        )
        val FilterBAndW = MaterialIcon(
            name = "filter_b_and_w",
            style = MaterialIcon.Style.Sharp
        )
        val FilterCenterFocus = MaterialIcon(
            name = "filter_center_focus",
            style = MaterialIcon.Style.Sharp
        )
        val FilterDrama = MaterialIcon(
            name = "filter_drama",
            style = MaterialIcon.Style.Sharp
        )
        val FilterFrames = MaterialIcon(
            name = "filter_frames",
            style = MaterialIcon.Style.Sharp
        )
        val FilterHdr = MaterialIcon(
            name = "filter_hdr",
            style = MaterialIcon.Style.Sharp
        )
        val FilterList = MaterialIcon(
            name = "filter_list",
            style = MaterialIcon.Style.Sharp
        )
        val FilterListOff = MaterialIcon(
            name = "filter_list_off",
            style = MaterialIcon.Style.Sharp
        )
        val FilterNone = MaterialIcon(
            name = "filter_none",
            style = MaterialIcon.Style.Sharp
        )
        val FilterTiltShift = MaterialIcon(
            name = "filter_tilt_shift",
            style = MaterialIcon.Style.Sharp
        )
        val FilterVintage = MaterialIcon(
            name = "filter_vintage",
            style = MaterialIcon.Style.Sharp
        )
        val FindInPage = MaterialIcon(
            name = "find_in_page",
            style = MaterialIcon.Style.Sharp
        )
        val FindReplace = MaterialIcon(
            name = "find_replace",
            style = MaterialIcon.Style.Sharp
        )
        val Fingerprint = MaterialIcon(
            name = "fingerprint",
            style = MaterialIcon.Style.Sharp
        )
        val FireExtinguisher = MaterialIcon(
            name = "fire_extinguisher",
            style = MaterialIcon.Style.Sharp
        )
        val FireHydrantAlt = MaterialIcon(
            name = "fire_hydrant_alt",
            style = MaterialIcon.Style.Sharp
        )
        val FireTruck = MaterialIcon(
            name = "fire_truck",
            style = MaterialIcon.Style.Sharp
        )
        val Fireplace = MaterialIcon(
            name = "fireplace",
            style = MaterialIcon.Style.Sharp
        )
        val FirstPage = MaterialIcon(
            name = "first_page",
            style = MaterialIcon.Style.Sharp
        )
        val FitScreen = MaterialIcon(
            name = "fit_screen",
            style = MaterialIcon.Style.Sharp
        )
        val Fitbit = MaterialIcon(
            name = "fitbit",
            style = MaterialIcon.Style.Sharp
        )
        val FitnessCenter = MaterialIcon(
            name = "fitness_center",
            style = MaterialIcon.Style.Sharp
        )
        val Flag = MaterialIcon(
            name = "flag",
            style = MaterialIcon.Style.Sharp
        )
        val FlagCircle = MaterialIcon(
            name = "flag_circle",
            style = MaterialIcon.Style.Sharp
        )
        val Flaky = MaterialIcon(
            name = "flaky",
            style = MaterialIcon.Style.Sharp
        )
        val Flare = MaterialIcon(
            name = "flare",
            style = MaterialIcon.Style.Sharp
        )
        val FlashAuto = MaterialIcon(
            name = "flash_auto",
            style = MaterialIcon.Style.Sharp
        )
        val FlashOff = MaterialIcon(
            name = "flash_off",
            style = MaterialIcon.Style.Sharp
        )
        val FlashOn = MaterialIcon(
            name = "flash_on",
            style = MaterialIcon.Style.Sharp
        )
        val FlashlightOff = MaterialIcon(
            name = "flashlight_off",
            style = MaterialIcon.Style.Sharp
        )
        val FlashlightOn = MaterialIcon(
            name = "flashlight_on",
            style = MaterialIcon.Style.Sharp
        )
        val Flatware = MaterialIcon(
            name = "flatware",
            style = MaterialIcon.Style.Sharp
        )
        val Flight = MaterialIcon(
            name = "flight",
            style = MaterialIcon.Style.Sharp
        )
        val FlightClass = MaterialIcon(
            name = "flight_class",
            style = MaterialIcon.Style.Sharp
        )
        val FlightLand = MaterialIcon(
            name = "flight_land",
            style = MaterialIcon.Style.Sharp
        )
        val FlightTakeoff = MaterialIcon(
            name = "flight_takeoff",
            style = MaterialIcon.Style.Sharp
        )
        val Flip = MaterialIcon(
            name = "flip",
            style = MaterialIcon.Style.Sharp
        )
        val FlipCameraAndroid = MaterialIcon(
            name = "flip_camera_android",
            style = MaterialIcon.Style.Sharp
        )
        val FlipCameraIos = MaterialIcon(
            name = "flip_camera_ios",
            style = MaterialIcon.Style.Sharp
        )
        val FlipToBack = MaterialIcon(
            name = "flip_to_back",
            style = MaterialIcon.Style.Sharp
        )
        val FlipToFront = MaterialIcon(
            name = "flip_to_front",
            style = MaterialIcon.Style.Sharp
        )
        val Flood = MaterialIcon(
            name = "flood",
            style = MaterialIcon.Style.Sharp
        )
        val Fluorescent = MaterialIcon(
            name = "fluorescent",
            style = MaterialIcon.Style.Sharp
        )
        val FlutterDash = MaterialIcon(
            name = "flutter_dash",
            style = MaterialIcon.Style.Sharp
        )
        val FmdBad = MaterialIcon(
            name = "fmd_bad",
            style = MaterialIcon.Style.Sharp
        )
        val FmdGood = MaterialIcon(
            name = "fmd_good",
            style = MaterialIcon.Style.Sharp
        )
        val Folder = MaterialIcon(
            name = "folder",
            style = MaterialIcon.Style.Sharp
        )
        val FolderCopy = MaterialIcon(
            name = "folder_copy",
            style = MaterialIcon.Style.Sharp
        )
        val FolderDelete = MaterialIcon(
            name = "folder_delete",
            style = MaterialIcon.Style.Sharp
        )
        val FolderOff = MaterialIcon(
            name = "folder_off",
            style = MaterialIcon.Style.Sharp
        )
        val FolderOpen = MaterialIcon(
            name = "folder_open",
            style = MaterialIcon.Style.Sharp
        )
        val FolderShared = MaterialIcon(
            name = "folder_shared",
            style = MaterialIcon.Style.Sharp
        )
        val FolderSpecial = MaterialIcon(
            name = "folder_special",
            style = MaterialIcon.Style.Sharp
        )
        val FolderZip = MaterialIcon(
            name = "folder_zip",
            style = MaterialIcon.Style.Sharp
        )
        val FollowTheSigns = MaterialIcon(
            name = "follow_the_signs",
            style = MaterialIcon.Style.Sharp
        )
        val FontDownload = MaterialIcon(
            name = "font_download",
            style = MaterialIcon.Style.Sharp
        )
        val FontDownloadOff = MaterialIcon(
            name = "font_download_off",
            style = MaterialIcon.Style.Sharp
        )
        val FoodBank = MaterialIcon(
            name = "food_bank",
            style = MaterialIcon.Style.Sharp
        )
        val Forest = MaterialIcon(
            name = "forest",
            style = MaterialIcon.Style.Sharp
        )
        val ForkLeft = MaterialIcon(
            name = "fork_left",
            style = MaterialIcon.Style.Sharp
        )
        val ForkRight = MaterialIcon(
            name = "fork_right",
            style = MaterialIcon.Style.Sharp
        )
        val FormatAlignCenter = MaterialIcon(
            name = "format_align_center",
            style = MaterialIcon.Style.Sharp
        )
        val FormatAlignJustify = MaterialIcon(
            name = "format_align_justify",
            style = MaterialIcon.Style.Sharp
        )
        val FormatAlignLeft = MaterialIcon(
            name = "format_align_left",
            style = MaterialIcon.Style.Sharp
        )
        val FormatAlignRight = MaterialIcon(
            name = "format_align_right",
            style = MaterialIcon.Style.Sharp
        )
        val FormatBold = MaterialIcon(
            name = "format_bold",
            style = MaterialIcon.Style.Sharp
        )
        val FormatClear = MaterialIcon(
            name = "format_clear",
            style = MaterialIcon.Style.Sharp
        )
        val FormatColorFill = MaterialIcon(
            name = "format_color_fill",
            style = MaterialIcon.Style.Sharp
        )
        val FormatColorReset = MaterialIcon(
            name = "format_color_reset",
            style = MaterialIcon.Style.Sharp
        )
        val FormatColorText = MaterialIcon(
            name = "format_color_text",
            style = MaterialIcon.Style.Sharp
        )
        val FormatIndentDecrease = MaterialIcon(
            name = "format_indent_decrease",
            style = MaterialIcon.Style.Sharp
        )
        val FormatIndentIncrease = MaterialIcon(
            name = "format_indent_increase",
            style = MaterialIcon.Style.Sharp
        )
        val FormatItalic = MaterialIcon(
            name = "format_italic",
            style = MaterialIcon.Style.Sharp
        )
        val FormatLineSpacing = MaterialIcon(
            name = "format_line_spacing",
            style = MaterialIcon.Style.Sharp
        )
        val FormatListBulleted = MaterialIcon(
            name = "format_list_bulleted",
            style = MaterialIcon.Style.Sharp
        )
        val FormatListNumbered = MaterialIcon(
            name = "format_list_numbered",
            style = MaterialIcon.Style.Sharp
        )
        val FormatListNumberedRtl = MaterialIcon(
            name = "format_list_numbered_rtl",
            style = MaterialIcon.Style.Sharp
        )
        val FormatOverline = MaterialIcon(
            name = "format_overline",
            style = MaterialIcon.Style.Sharp
        )
        val FormatPaint = MaterialIcon(
            name = "format_paint",
            style = MaterialIcon.Style.Sharp
        )
        val FormatQuote = MaterialIcon(
            name = "format_quote",
            style = MaterialIcon.Style.Sharp
        )
        val FormatShapes = MaterialIcon(
            name = "format_shapes",
            style = MaterialIcon.Style.Sharp
        )
        val FormatSize = MaterialIcon(
            name = "format_size",
            style = MaterialIcon.Style.Sharp
        )
        val FormatStrikethrough = MaterialIcon(
            name = "format_strikethrough",
            style = MaterialIcon.Style.Sharp
        )
        val FormatTextdirectionLToR = MaterialIcon(
            name = "format_textdirection_l_to_r",
            style = MaterialIcon.Style.Sharp
        )
        val FormatTextdirectionRToL = MaterialIcon(
            name = "format_textdirection_r_to_l",
            style = MaterialIcon.Style.Sharp
        )
        val FormatUnderlined = MaterialIcon(
            name = "format_underlined",
            style = MaterialIcon.Style.Sharp
        )
        val Fort = MaterialIcon(
            name = "fort",
            style = MaterialIcon.Style.Sharp
        )
        val Forum = MaterialIcon(
            name = "forum",
            style = MaterialIcon.Style.Sharp
        )
        val Forward = MaterialIcon(
            name = "forward",
            style = MaterialIcon.Style.Sharp
        )
        val Forward10 = MaterialIcon(
            name = "forward_10",
            style = MaterialIcon.Style.Sharp
        )
        val Forward30 = MaterialIcon(
            name = "forward_30",
            style = MaterialIcon.Style.Sharp
        )
        val Forward5 = MaterialIcon(
            name = "forward_5",
            style = MaterialIcon.Style.Sharp
        )
        val ForwardToInbox = MaterialIcon(
            name = "forward_to_inbox",
            style = MaterialIcon.Style.Sharp
        )
        val Foundation = MaterialIcon(
            name = "foundation",
            style = MaterialIcon.Style.Sharp
        )
        val FreeBreakfast = MaterialIcon(
            name = "free_breakfast",
            style = MaterialIcon.Style.Sharp
        )
        val FreeCancellation = MaterialIcon(
            name = "free_cancellation",
            style = MaterialIcon.Style.Sharp
        )
        val FrontHand = MaterialIcon(
            name = "front_hand",
            style = MaterialIcon.Style.Sharp
        )
        val Fullscreen = MaterialIcon(
            name = "fullscreen",
            style = MaterialIcon.Style.Sharp
        )
        val FullscreenExit = MaterialIcon(
            name = "fullscreen_exit",
            style = MaterialIcon.Style.Sharp
        )
        val Functions = MaterialIcon(
            name = "functions",
            style = MaterialIcon.Style.Sharp
        )
        val GMobiledata = MaterialIcon(
            name = "g_mobiledata",
            style = MaterialIcon.Style.Sharp
        )
        val GTranslate = MaterialIcon(
            name = "g_translate",
            style = MaterialIcon.Style.Sharp
        )
        val Gamepad = MaterialIcon(
            name = "gamepad",
            style = MaterialIcon.Style.Sharp
        )
        val Games = MaterialIcon(
            name = "games",
            style = MaterialIcon.Style.Sharp
        )
        val Garage = MaterialIcon(
            name = "garage",
            style = MaterialIcon.Style.Sharp
        )
        val GasMeter = MaterialIcon(
            name = "gas_meter",
            style = MaterialIcon.Style.Sharp
        )
        val Gavel = MaterialIcon(
            name = "gavel",
            style = MaterialIcon.Style.Sharp
        )
        val GeneratingTokens = MaterialIcon(
            name = "generating_tokens",
            style = MaterialIcon.Style.Sharp
        )
        val Gesture = MaterialIcon(
            name = "gesture",
            style = MaterialIcon.Style.Sharp
        )
        val GetApp = MaterialIcon(
            name = "get_app",
            style = MaterialIcon.Style.Sharp
        )
        val Gif = MaterialIcon(
            name = "gif",
            style = MaterialIcon.Style.Sharp
        )
        val GifBox = MaterialIcon(
            name = "gif_box",
            style = MaterialIcon.Style.Sharp
        )
        val Girl = MaterialIcon(
            name = "girl",
            style = MaterialIcon.Style.Sharp
        )
        val Gite = MaterialIcon(
            name = "gite",
            style = MaterialIcon.Style.Sharp
        )
        val GolfCourse = MaterialIcon(
            name = "golf_course",
            style = MaterialIcon.Style.Sharp
        )
        val GppBad = MaterialIcon(
            name = "gpp_bad",
            style = MaterialIcon.Style.Sharp
        )
        val GppGood = MaterialIcon(
            name = "gpp_good",
            style = MaterialIcon.Style.Sharp
        )
        val GppMaybe = MaterialIcon(
            name = "gpp_maybe",
            style = MaterialIcon.Style.Sharp
        )
        val GpsFixed = MaterialIcon(
            name = "gps_fixed",
            style = MaterialIcon.Style.Sharp
        )
        val GpsNotFixed = MaterialIcon(
            name = "gps_not_fixed",
            style = MaterialIcon.Style.Sharp
        )
        val GpsOff = MaterialIcon(
            name = "gps_off",
            style = MaterialIcon.Style.Sharp
        )
        val Grade = MaterialIcon(
            name = "grade",
            style = MaterialIcon.Style.Sharp
        )
        val Gradient = MaterialIcon(
            name = "gradient",
            style = MaterialIcon.Style.Sharp
        )
        val Grading = MaterialIcon(
            name = "grading",
            style = MaterialIcon.Style.Sharp
        )
        val Grain = MaterialIcon(
            name = "grain",
            style = MaterialIcon.Style.Sharp
        )
        val GraphicEq = MaterialIcon(
            name = "graphic_eq",
            style = MaterialIcon.Style.Sharp
        )
        val Grass = MaterialIcon(
            name = "grass",
            style = MaterialIcon.Style.Sharp
        )
        val Grid3x3 = MaterialIcon(
            name = "grid_3x3",
            style = MaterialIcon.Style.Sharp
        )
        val Grid4x4 = MaterialIcon(
            name = "grid_4x4",
            style = MaterialIcon.Style.Sharp
        )
        val GridGoldenratio = MaterialIcon(
            name = "grid_goldenratio",
            style = MaterialIcon.Style.Sharp
        )
        val GridOff = MaterialIcon(
            name = "grid_off",
            style = MaterialIcon.Style.Sharp
        )
        val GridOn = MaterialIcon(
            name = "grid_on",
            style = MaterialIcon.Style.Sharp
        )
        val GridView = MaterialIcon(
            name = "grid_view",
            style = MaterialIcon.Style.Sharp
        )
        val Group = MaterialIcon(
            name = "group",
            style = MaterialIcon.Style.Sharp
        )
        val GroupAdd = MaterialIcon(
            name = "group_add",
            style = MaterialIcon.Style.Sharp
        )
        val GroupOff = MaterialIcon(
            name = "group_off",
            style = MaterialIcon.Style.Sharp
        )
        val GroupRemove = MaterialIcon(
            name = "group_remove",
            style = MaterialIcon.Style.Sharp
        )
        val GroupWork = MaterialIcon(
            name = "group_work",
            style = MaterialIcon.Style.Sharp
        )
        val Groups = MaterialIcon(
            name = "groups",
            style = MaterialIcon.Style.Sharp
        )
        val Groups2 = MaterialIcon(
            name = "groups_2",
            style = MaterialIcon.Style.Sharp
        )
        val Groups3 = MaterialIcon(
            name = "groups_3",
            style = MaterialIcon.Style.Sharp
        )
        val HMobiledata = MaterialIcon(
            name = "h_mobiledata",
            style = MaterialIcon.Style.Sharp
        )
        val HPlusMobiledata = MaterialIcon(
            name = "h_plus_mobiledata",
            style = MaterialIcon.Style.Sharp
        )
        val Hail = MaterialIcon(
            name = "hail",
            style = MaterialIcon.Style.Sharp
        )
        val Handshake = MaterialIcon(
            name = "handshake",
            style = MaterialIcon.Style.Sharp
        )
        val Handyman = MaterialIcon(
            name = "handyman",
            style = MaterialIcon.Style.Sharp
        )
        val Hardware = MaterialIcon(
            name = "hardware",
            style = MaterialIcon.Style.Sharp
        )
        val Hd = MaterialIcon(
            name = "hd",
            style = MaterialIcon.Style.Sharp
        )
        val HdrAuto = MaterialIcon(
            name = "hdr_auto",
            style = MaterialIcon.Style.Sharp
        )
        val HdrAutoSelect = MaterialIcon(
            name = "hdr_auto_select",
            style = MaterialIcon.Style.Sharp
        )
        val HdrEnhancedSelect = MaterialIcon(
            name = "hdr_enhanced_select",
            style = MaterialIcon.Style.Sharp
        )
        val HdrOff = MaterialIcon(
            name = "hdr_off",
            style = MaterialIcon.Style.Sharp
        )
        val HdrOffSelect = MaterialIcon(
            name = "hdr_off_select",
            style = MaterialIcon.Style.Sharp
        )
        val HdrOn = MaterialIcon(
            name = "hdr_on",
            style = MaterialIcon.Style.Sharp
        )
        val HdrOnSelect = MaterialIcon(
            name = "hdr_on_select",
            style = MaterialIcon.Style.Sharp
        )
        val HdrPlus = MaterialIcon(
            name = "hdr_plus",
            style = MaterialIcon.Style.Sharp
        )
        val HdrStrong = MaterialIcon(
            name = "hdr_strong",
            style = MaterialIcon.Style.Sharp
        )
        val HdrWeak = MaterialIcon(
            name = "hdr_weak",
            style = MaterialIcon.Style.Sharp
        )
        val Headphones = MaterialIcon(
            name = "headphones",
            style = MaterialIcon.Style.Sharp
        )
        val HeadphonesBattery = MaterialIcon(
            name = "headphones_battery",
            style = MaterialIcon.Style.Sharp
        )
        val Headset = MaterialIcon(
            name = "headset",
            style = MaterialIcon.Style.Sharp
        )
        val HeadsetMic = MaterialIcon(
            name = "headset_mic",
            style = MaterialIcon.Style.Sharp
        )
        val HeadsetOff = MaterialIcon(
            name = "headset_off",
            style = MaterialIcon.Style.Sharp
        )
        val Healing = MaterialIcon(
            name = "healing",
            style = MaterialIcon.Style.Sharp
        )
        val HealthAndSafety = MaterialIcon(
            name = "health_and_safety",
            style = MaterialIcon.Style.Sharp
        )
        val Hearing = MaterialIcon(
            name = "hearing",
            style = MaterialIcon.Style.Sharp
        )
        val HearingDisabled = MaterialIcon(
            name = "hearing_disabled",
            style = MaterialIcon.Style.Sharp
        )
        val HeartBroken = MaterialIcon(
            name = "heart_broken",
            style = MaterialIcon.Style.Sharp
        )
        val HeatPump = MaterialIcon(
            name = "heat_pump",
            style = MaterialIcon.Style.Sharp
        )
        val Height = MaterialIcon(
            name = "height",
            style = MaterialIcon.Style.Sharp
        )
        val Help = MaterialIcon(
            name = "help",
            style = MaterialIcon.Style.Sharp
        )
        val HelpCenter = MaterialIcon(
            name = "help_center",
            style = MaterialIcon.Style.Sharp
        )
        val HelpOutline = MaterialIcon(
            name = "help_outline",
            style = MaterialIcon.Style.Sharp
        )
        val Hevc = MaterialIcon(
            name = "hevc",
            style = MaterialIcon.Style.Sharp
        )
        val Hexagon = MaterialIcon(
            name = "hexagon",
            style = MaterialIcon.Style.Sharp
        )
        val HideImage = MaterialIcon(
            name = "hide_image",
            style = MaterialIcon.Style.Sharp
        )
        val HideSource = MaterialIcon(
            name = "hide_source",
            style = MaterialIcon.Style.Sharp
        )
        val HighQuality = MaterialIcon(
            name = "high_quality",
            style = MaterialIcon.Style.Sharp
        )
        val Highlight = MaterialIcon(
            name = "highlight",
            style = MaterialIcon.Style.Sharp
        )
        val HighlightAlt = MaterialIcon(
            name = "highlight_alt",
            style = MaterialIcon.Style.Sharp
        )
        val HighlightOff = MaterialIcon(
            name = "highlight_off",
            style = MaterialIcon.Style.Sharp
        )
        val Hiking = MaterialIcon(
            name = "hiking",
            style = MaterialIcon.Style.Sharp
        )
        val History = MaterialIcon(
            name = "history",
            style = MaterialIcon.Style.Sharp
        )
        val HistoryEdu = MaterialIcon(
            name = "history_edu",
            style = MaterialIcon.Style.Sharp
        )
        val HistoryToggleOff = MaterialIcon(
            name = "history_toggle_off",
            style = MaterialIcon.Style.Sharp
        )
        val Hive = MaterialIcon(
            name = "hive",
            style = MaterialIcon.Style.Sharp
        )
        val Hls = MaterialIcon(
            name = "hls",
            style = MaterialIcon.Style.Sharp
        )
        val HlsOff = MaterialIcon(
            name = "hls_off",
            style = MaterialIcon.Style.Sharp
        )
        val HolidayVillage = MaterialIcon(
            name = "holiday_village",
            style = MaterialIcon.Style.Sharp
        )
        val Home = MaterialIcon(
            name = "home",
            style = MaterialIcon.Style.Sharp
        )
        val HomeMax = MaterialIcon(
            name = "home_max",
            style = MaterialIcon.Style.Sharp
        )
        val HomeMini = MaterialIcon(
            name = "home_mini",
            style = MaterialIcon.Style.Sharp
        )
        val HomeRepairService = MaterialIcon(
            name = "home_repair_service",
            style = MaterialIcon.Style.Sharp
        )
        val HomeWork = MaterialIcon(
            name = "home_work",
            style = MaterialIcon.Style.Sharp
        )
        val HorizontalDistribute = MaterialIcon(
            name = "horizontal_distribute",
            style = MaterialIcon.Style.Sharp
        )
        val HorizontalRule = MaterialIcon(
            name = "horizontal_rule",
            style = MaterialIcon.Style.Sharp
        )
        val HorizontalSplit = MaterialIcon(
            name = "horizontal_split",
            style = MaterialIcon.Style.Sharp
        )
        val HotTub = MaterialIcon(
            name = "hot_tub",
            style = MaterialIcon.Style.Sharp
        )
        val Hotel = MaterialIcon(
            name = "hotel",
            style = MaterialIcon.Style.Sharp
        )
        val HotelClass = MaterialIcon(
            name = "hotel_class",
            style = MaterialIcon.Style.Sharp
        )
        val HourglassBottom = MaterialIcon(
            name = "hourglass_bottom",
            style = MaterialIcon.Style.Sharp
        )
        val HourglassDisabled = MaterialIcon(
            name = "hourglass_disabled",
            style = MaterialIcon.Style.Sharp
        )
        val HourglassEmpty = MaterialIcon(
            name = "hourglass_empty",
            style = MaterialIcon.Style.Sharp
        )
        val HourglassFull = MaterialIcon(
            name = "hourglass_full",
            style = MaterialIcon.Style.Sharp
        )
        val HourglassTop = MaterialIcon(
            name = "hourglass_top",
            style = MaterialIcon.Style.Sharp
        )
        val House = MaterialIcon(
            name = "house",
            style = MaterialIcon.Style.Sharp
        )
        val HouseSiding = MaterialIcon(
            name = "house_siding",
            style = MaterialIcon.Style.Sharp
        )
        val Houseboat = MaterialIcon(
            name = "houseboat",
            style = MaterialIcon.Style.Sharp
        )
        val HowToReg = MaterialIcon(
            name = "how_to_reg",
            style = MaterialIcon.Style.Sharp
        )
        val HowToVote = MaterialIcon(
            name = "how_to_vote",
            style = MaterialIcon.Style.Sharp
        )
        val Html = MaterialIcon(
            name = "html",
            style = MaterialIcon.Style.Sharp
        )
        val Http = MaterialIcon(
            name = "http",
            style = MaterialIcon.Style.Sharp
        )
        val Https = MaterialIcon(
            name = "https",
            style = MaterialIcon.Style.Sharp
        )
        val Hub = MaterialIcon(
            name = "hub",
            style = MaterialIcon.Style.Sharp
        )
        val Hvac = MaterialIcon(
            name = "hvac",
            style = MaterialIcon.Style.Sharp
        )
        val IceSkating = MaterialIcon(
            name = "ice_skating",
            style = MaterialIcon.Style.Sharp
        )
        val Icecream = MaterialIcon(
            name = "icecream",
            style = MaterialIcon.Style.Sharp
        )
        val Image = MaterialIcon(
            name = "image",
            style = MaterialIcon.Style.Sharp
        )
        val ImageAspectRatio = MaterialIcon(
            name = "image_aspect_ratio",
            style = MaterialIcon.Style.Sharp
        )
        val ImageNotSupported = MaterialIcon(
            name = "image_not_supported",
            style = MaterialIcon.Style.Sharp
        )
        val ImageSearch = MaterialIcon(
            name = "image_search",
            style = MaterialIcon.Style.Sharp
        )
        val ImagesearchRoller = MaterialIcon(
            name = "imagesearch_roller",
            style = MaterialIcon.Style.Sharp
        )
        val ImportContacts = MaterialIcon(
            name = "import_contacts",
            style = MaterialIcon.Style.Sharp
        )
        val ImportExport = MaterialIcon(
            name = "import_export",
            style = MaterialIcon.Style.Sharp
        )
        val ImportantDevices = MaterialIcon(
            name = "important_devices",
            style = MaterialIcon.Style.Sharp
        )
        val Inbox = MaterialIcon(
            name = "inbox",
            style = MaterialIcon.Style.Sharp
        )
        val IncompleteCircle = MaterialIcon(
            name = "incomplete_circle",
            style = MaterialIcon.Style.Sharp
        )
        val IndeterminateCheckBox = MaterialIcon(
            name = "indeterminate_check_box",
            style = MaterialIcon.Style.Sharp
        )
        val Info = MaterialIcon(
            name = "info",
            style = MaterialIcon.Style.Sharp
        )
        val Input = MaterialIcon(
            name = "input",
            style = MaterialIcon.Style.Sharp
        )
        val InsertChart = MaterialIcon(
            name = "insert_chart",
            style = MaterialIcon.Style.Sharp
        )
        val InsertChartOutlined = MaterialIcon(
            name = "insert_chart_outlined",
            style = MaterialIcon.Style.Sharp
        )
        val InsertComment = MaterialIcon(
            name = "insert_comment",
            style = MaterialIcon.Style.Sharp
        )
        val InsertDriveFile = MaterialIcon(
            name = "insert_drive_file",
            style = MaterialIcon.Style.Sharp
        )
        val InsertEmoticon = MaterialIcon(
            name = "insert_emoticon",
            style = MaterialIcon.Style.Sharp
        )
        val InsertInvitation = MaterialIcon(
            name = "insert_invitation",
            style = MaterialIcon.Style.Sharp
        )
        val InsertLink = MaterialIcon(
            name = "insert_link",
            style = MaterialIcon.Style.Sharp
        )
        val InsertPageBreak = MaterialIcon(
            name = "insert_page_break",
            style = MaterialIcon.Style.Sharp
        )
        val InsertPhoto = MaterialIcon(
            name = "insert_photo",
            style = MaterialIcon.Style.Sharp
        )
        val Insights = MaterialIcon(
            name = "insights",
            style = MaterialIcon.Style.Sharp
        )
        val InstallDesktop = MaterialIcon(
            name = "install_desktop",
            style = MaterialIcon.Style.Sharp
        )
        val InstallMobile = MaterialIcon(
            name = "install_mobile",
            style = MaterialIcon.Style.Sharp
        )
        val IntegrationInstructions = MaterialIcon(
            name = "integration_instructions",
            style = MaterialIcon.Style.Sharp
        )
        val Interests = MaterialIcon(
            name = "interests",
            style = MaterialIcon.Style.Sharp
        )
        val InterpreterMode = MaterialIcon(
            name = "interpreter_mode",
            style = MaterialIcon.Style.Sharp
        )
        val Inventory = MaterialIcon(
            name = "inventory",
            style = MaterialIcon.Style.Sharp
        )
        val Inventory2 = MaterialIcon(
            name = "inventory_2",
            style = MaterialIcon.Style.Sharp
        )
        val InvertColors = MaterialIcon(
            name = "invert_colors",
            style = MaterialIcon.Style.Sharp
        )
        val InvertColorsOff = MaterialIcon(
            name = "invert_colors_off",
            style = MaterialIcon.Style.Sharp
        )
        val IosShare = MaterialIcon(
            name = "ios_share",
            style = MaterialIcon.Style.Sharp
        )
        val Iron = MaterialIcon(
            name = "iron",
            style = MaterialIcon.Style.Sharp
        )
        val Iso = MaterialIcon(
            name = "iso",
            style = MaterialIcon.Style.Sharp
        )
        val Javascript = MaterialIcon(
            name = "javascript",
            style = MaterialIcon.Style.Sharp
        )
        val JoinFull = MaterialIcon(
            name = "join_full",
            style = MaterialIcon.Style.Sharp
        )
        val JoinInner = MaterialIcon(
            name = "join_inner",
            style = MaterialIcon.Style.Sharp
        )
        val JoinLeft = MaterialIcon(
            name = "join_left",
            style = MaterialIcon.Style.Sharp
        )
        val JoinRight = MaterialIcon(
            name = "join_right",
            style = MaterialIcon.Style.Sharp
        )
        val Kayaking = MaterialIcon(
            name = "kayaking",
            style = MaterialIcon.Style.Sharp
        )
        val KebabDining = MaterialIcon(
            name = "kebab_dining",
            style = MaterialIcon.Style.Sharp
        )
        val Key = MaterialIcon(
            name = "key",
            style = MaterialIcon.Style.Sharp
        )
        val KeyOff = MaterialIcon(
            name = "key_off",
            style = MaterialIcon.Style.Sharp
        )
        val Keyboard = MaterialIcon(
            name = "keyboard",
            style = MaterialIcon.Style.Sharp
        )
        val KeyboardAlt = MaterialIcon(
            name = "keyboard_alt",
            style = MaterialIcon.Style.Sharp
        )
        val KeyboardArrowDown = MaterialIcon(
            name = "keyboard_arrow_down",
            style = MaterialIcon.Style.Sharp
        )
        val KeyboardArrowLeft = MaterialIcon(
            name = "keyboard_arrow_left",
            style = MaterialIcon.Style.Sharp
        )
        val KeyboardArrowRight = MaterialIcon(
            name = "keyboard_arrow_right",
            style = MaterialIcon.Style.Sharp
        )
        val KeyboardArrowUp = MaterialIcon(
            name = "keyboard_arrow_up",
            style = MaterialIcon.Style.Sharp
        )
        val KeyboardBackspace = MaterialIcon(
            name = "keyboard_backspace",
            style = MaterialIcon.Style.Sharp
        )
        val KeyboardCapslock = MaterialIcon(
            name = "keyboard_capslock",
            style = MaterialIcon.Style.Sharp
        )
        val KeyboardCommandKey = MaterialIcon(
            name = "keyboard_command_key",
            style = MaterialIcon.Style.Sharp
        )
        val KeyboardControlKey = MaterialIcon(
            name = "keyboard_control_key",
            style = MaterialIcon.Style.Sharp
        )
        val KeyboardDoubleArrowDown = MaterialIcon(
            name = "keyboard_double_arrow_down",
            style = MaterialIcon.Style.Sharp
        )
        val KeyboardDoubleArrowLeft = MaterialIcon(
            name = "keyboard_double_arrow_left",
            style = MaterialIcon.Style.Sharp
        )
        val KeyboardDoubleArrowRight = MaterialIcon(
            name = "keyboard_double_arrow_right",
            style = MaterialIcon.Style.Sharp
        )
        val KeyboardDoubleArrowUp = MaterialIcon(
            name = "keyboard_double_arrow_up",
            style = MaterialIcon.Style.Sharp
        )
        val KeyboardHide = MaterialIcon(
            name = "keyboard_hide",
            style = MaterialIcon.Style.Sharp
        )
        val KeyboardOptionKey = MaterialIcon(
            name = "keyboard_option_key",
            style = MaterialIcon.Style.Sharp
        )
        val KeyboardReturn = MaterialIcon(
            name = "keyboard_return",
            style = MaterialIcon.Style.Sharp
        )
        val KeyboardTab = MaterialIcon(
            name = "keyboard_tab",
            style = MaterialIcon.Style.Sharp
        )
        val KeyboardVoice = MaterialIcon(
            name = "keyboard_voice",
            style = MaterialIcon.Style.Sharp
        )
        val KingBed = MaterialIcon(
            name = "king_bed",
            style = MaterialIcon.Style.Sharp
        )
        val Kitchen = MaterialIcon(
            name = "kitchen",
            style = MaterialIcon.Style.Sharp
        )
        val Kitesurfing = MaterialIcon(
            name = "kitesurfing",
            style = MaterialIcon.Style.Sharp
        )
        val Label = MaterialIcon(
            name = "label",
            style = MaterialIcon.Style.Sharp
        )
        val LabelImportant = MaterialIcon(
            name = "label_important",
            style = MaterialIcon.Style.Sharp
        )
        val LabelOff = MaterialIcon(
            name = "label_off",
            style = MaterialIcon.Style.Sharp
        )
        val Lan = MaterialIcon(
            name = "lan",
            style = MaterialIcon.Style.Sharp
        )
        val Landscape = MaterialIcon(
            name = "landscape",
            style = MaterialIcon.Style.Sharp
        )
        val Landslide = MaterialIcon(
            name = "landslide",
            style = MaterialIcon.Style.Sharp
        )
        val Language = MaterialIcon(
            name = "language",
            style = MaterialIcon.Style.Sharp
        )
        val Laptop = MaterialIcon(
            name = "laptop",
            style = MaterialIcon.Style.Sharp
        )
        val LaptopChromebook = MaterialIcon(
            name = "laptop_chromebook",
            style = MaterialIcon.Style.Sharp
        )
        val LaptopMac = MaterialIcon(
            name = "laptop_mac",
            style = MaterialIcon.Style.Sharp
        )
        val LaptopWindows = MaterialIcon(
            name = "laptop_windows",
            style = MaterialIcon.Style.Sharp
        )
        val LastPage = MaterialIcon(
            name = "last_page",
            style = MaterialIcon.Style.Sharp
        )
        val Launch = MaterialIcon(
            name = "launch",
            style = MaterialIcon.Style.Sharp
        )
        val Layers = MaterialIcon(
            name = "layers",
            style = MaterialIcon.Style.Sharp
        )
        val LayersClear = MaterialIcon(
            name = "layers_clear",
            style = MaterialIcon.Style.Sharp
        )
        val Leaderboard = MaterialIcon(
            name = "leaderboard",
            style = MaterialIcon.Style.Sharp
        )
        val LeakAdd = MaterialIcon(
            name = "leak_add",
            style = MaterialIcon.Style.Sharp
        )
        val LeakRemove = MaterialIcon(
            name = "leak_remove",
            style = MaterialIcon.Style.Sharp
        )
        val LegendToggle = MaterialIcon(
            name = "legend_toggle",
            style = MaterialIcon.Style.Sharp
        )
        val Lens = MaterialIcon(
            name = "lens",
            style = MaterialIcon.Style.Sharp
        )
        val LensBlur = MaterialIcon(
            name = "lens_blur",
            style = MaterialIcon.Style.Sharp
        )
        val LibraryAdd = MaterialIcon(
            name = "library_add",
            style = MaterialIcon.Style.Sharp
        )
        val LibraryAddCheck = MaterialIcon(
            name = "library_add_check",
            style = MaterialIcon.Style.Sharp
        )
        val LibraryBooks = MaterialIcon(
            name = "library_books",
            style = MaterialIcon.Style.Sharp
        )
        val LibraryMusic = MaterialIcon(
            name = "library_music",
            style = MaterialIcon.Style.Sharp
        )
        val Light = MaterialIcon(
            name = "light",
            style = MaterialIcon.Style.Sharp
        )
        val LightMode = MaterialIcon(
            name = "light_mode",
            style = MaterialIcon.Style.Sharp
        )
        val Lightbulb = MaterialIcon(
            name = "lightbulb",
            style = MaterialIcon.Style.Sharp
        )
        val LightbulbCircle = MaterialIcon(
            name = "lightbulb_circle",
            style = MaterialIcon.Style.Sharp
        )
        val LineAxis = MaterialIcon(
            name = "line_axis",
            style = MaterialIcon.Style.Sharp
        )
        val LineStyle = MaterialIcon(
            name = "line_style",
            style = MaterialIcon.Style.Sharp
        )
        val LineWeight = MaterialIcon(
            name = "line_weight",
            style = MaterialIcon.Style.Sharp
        )
        val LinearScale = MaterialIcon(
            name = "linear_scale",
            style = MaterialIcon.Style.Sharp
        )
        val Link = MaterialIcon(
            name = "link",
            style = MaterialIcon.Style.Sharp
        )
        val LinkOff = MaterialIcon(
            name = "link_off",
            style = MaterialIcon.Style.Sharp
        )
        val LinkedCamera = MaterialIcon(
            name = "linked_camera",
            style = MaterialIcon.Style.Sharp
        )
        val Liquor = MaterialIcon(
            name = "liquor",
            style = MaterialIcon.Style.Sharp
        )
        val List = MaterialIcon(
            name = "list",
            style = MaterialIcon.Style.Sharp
        )
        val ListAlt = MaterialIcon(
            name = "list_alt",
            style = MaterialIcon.Style.Sharp
        )
        val LiveHelp = MaterialIcon(
            name = "live_help",
            style = MaterialIcon.Style.Sharp
        )
        val LiveTv = MaterialIcon(
            name = "live_tv",
            style = MaterialIcon.Style.Sharp
        )
        val Living = MaterialIcon(
            name = "living",
            style = MaterialIcon.Style.Sharp
        )
        val LocalActivity = MaterialIcon(
            name = "local_activity",
            style = MaterialIcon.Style.Sharp
        )
        val LocalAirport = MaterialIcon(
            name = "local_airport",
            style = MaterialIcon.Style.Sharp
        )
        val LocalAtm = MaterialIcon(
            name = "local_atm",
            style = MaterialIcon.Style.Sharp
        )
        val LocalBar = MaterialIcon(
            name = "local_bar",
            style = MaterialIcon.Style.Sharp
        )
        val LocalCafe = MaterialIcon(
            name = "local_cafe",
            style = MaterialIcon.Style.Sharp
        )
        val LocalCarWash = MaterialIcon(
            name = "local_car_wash",
            style = MaterialIcon.Style.Sharp
        )
        val LocalConvenienceStore = MaterialIcon(
            name = "local_convenience_store",
            style = MaterialIcon.Style.Sharp
        )
        val LocalDining = MaterialIcon(
            name = "local_dining",
            style = MaterialIcon.Style.Sharp
        )
        val LocalDrink = MaterialIcon(
            name = "local_drink",
            style = MaterialIcon.Style.Sharp
        )
        val LocalFireDepartment = MaterialIcon(
            name = "local_fire_department",
            style = MaterialIcon.Style.Sharp
        )
        val LocalFlorist = MaterialIcon(
            name = "local_florist",
            style = MaterialIcon.Style.Sharp
        )
        val LocalGasStation = MaterialIcon(
            name = "local_gas_station",
            style = MaterialIcon.Style.Sharp
        )
        val LocalGroceryStore = MaterialIcon(
            name = "local_grocery_store",
            style = MaterialIcon.Style.Sharp
        )
        val LocalHospital = MaterialIcon(
            name = "local_hospital",
            style = MaterialIcon.Style.Sharp
        )
        val LocalHotel = MaterialIcon(
            name = "local_hotel",
            style = MaterialIcon.Style.Sharp
        )
        val LocalLaundryService = MaterialIcon(
            name = "local_laundry_service",
            style = MaterialIcon.Style.Sharp
        )
        val LocalLibrary = MaterialIcon(
            name = "local_library",
            style = MaterialIcon.Style.Sharp
        )
        val LocalMall = MaterialIcon(
            name = "local_mall",
            style = MaterialIcon.Style.Sharp
        )
        val LocalMovies = MaterialIcon(
            name = "local_movies",
            style = MaterialIcon.Style.Sharp
        )
        val LocalOffer = MaterialIcon(
            name = "local_offer",
            style = MaterialIcon.Style.Sharp
        )
        val LocalParking = MaterialIcon(
            name = "local_parking",
            style = MaterialIcon.Style.Sharp
        )
        val LocalPharmacy = MaterialIcon(
            name = "local_pharmacy",
            style = MaterialIcon.Style.Sharp
        )
        val LocalPhone = MaterialIcon(
            name = "local_phone",
            style = MaterialIcon.Style.Sharp
        )
        val LocalPizza = MaterialIcon(
            name = "local_pizza",
            style = MaterialIcon.Style.Sharp
        )
        val LocalPlay = MaterialIcon(
            name = "local_play",
            style = MaterialIcon.Style.Sharp
        )
        val LocalPolice = MaterialIcon(
            name = "local_police",
            style = MaterialIcon.Style.Sharp
        )
        val LocalPostOffice = MaterialIcon(
            name = "local_post_office",
            style = MaterialIcon.Style.Sharp
        )
        val LocalPrintshop = MaterialIcon(
            name = "local_printshop",
            style = MaterialIcon.Style.Sharp
        )
        val LocalSee = MaterialIcon(
            name = "local_see",
            style = MaterialIcon.Style.Sharp
        )
        val LocalShipping = MaterialIcon(
            name = "local_shipping",
            style = MaterialIcon.Style.Sharp
        )
        val LocalTaxi = MaterialIcon(
            name = "local_taxi",
            style = MaterialIcon.Style.Sharp
        )
        val LocationCity = MaterialIcon(
            name = "location_city",
            style = MaterialIcon.Style.Sharp
        )
        val LocationDisabled = MaterialIcon(
            name = "location_disabled",
            style = MaterialIcon.Style.Sharp
        )
        val LocationOff = MaterialIcon(
            name = "location_off",
            style = MaterialIcon.Style.Sharp
        )
        val LocationOn = MaterialIcon(
            name = "location_on",
            style = MaterialIcon.Style.Sharp
        )
        val LocationSearching = MaterialIcon(
            name = "location_searching",
            style = MaterialIcon.Style.Sharp
        )
        val Lock = MaterialIcon(
            name = "lock",
            style = MaterialIcon.Style.Sharp
        )
        val LockClock = MaterialIcon(
            name = "lock_clock",
            style = MaterialIcon.Style.Sharp
        )
        val LockOpen = MaterialIcon(
            name = "lock_open",
            style = MaterialIcon.Style.Sharp
        )
        val LockPerson = MaterialIcon(
            name = "lock_person",
            style = MaterialIcon.Style.Sharp
        )
        val LockReset = MaterialIcon(
            name = "lock_reset",
            style = MaterialIcon.Style.Sharp
        )
        val Login = MaterialIcon(
            name = "login",
            style = MaterialIcon.Style.Sharp
        )
        val LogoDev = MaterialIcon(
            name = "logo_dev",
            style = MaterialIcon.Style.Sharp
        )
        val Logout = MaterialIcon(
            name = "logout",
            style = MaterialIcon.Style.Sharp
        )
        val Looks = MaterialIcon(
            name = "looks",
            style = MaterialIcon.Style.Sharp
        )
        val Looks3 = MaterialIcon(
            name = "looks_3",
            style = MaterialIcon.Style.Sharp
        )
        val Looks4 = MaterialIcon(
            name = "looks_4",
            style = MaterialIcon.Style.Sharp
        )
        val Looks5 = MaterialIcon(
            name = "looks_5",
            style = MaterialIcon.Style.Sharp
        )
        val Looks6 = MaterialIcon(
            name = "looks_6",
            style = MaterialIcon.Style.Sharp
        )
        val LooksOne = MaterialIcon(
            name = "looks_one",
            style = MaterialIcon.Style.Sharp
        )
        val LooksTwo = MaterialIcon(
            name = "looks_two",
            style = MaterialIcon.Style.Sharp
        )
        val Loop = MaterialIcon(
            name = "loop",
            style = MaterialIcon.Style.Sharp
        )
        val Loupe = MaterialIcon(
            name = "loupe",
            style = MaterialIcon.Style.Sharp
        )
        val LowPriority = MaterialIcon(
            name = "low_priority",
            style = MaterialIcon.Style.Sharp
        )
        val Loyalty = MaterialIcon(
            name = "loyalty",
            style = MaterialIcon.Style.Sharp
        )
        val LteMobiledata = MaterialIcon(
            name = "lte_mobiledata",
            style = MaterialIcon.Style.Sharp
        )
        val LtePlusMobiledata = MaterialIcon(
            name = "lte_plus_mobiledata",
            style = MaterialIcon.Style.Sharp
        )
        val Luggage = MaterialIcon(
            name = "luggage",
            style = MaterialIcon.Style.Sharp
        )
        val LunchDining = MaterialIcon(
            name = "lunch_dining",
            style = MaterialIcon.Style.Sharp
        )
        val Lyrics = MaterialIcon(
            name = "lyrics",
            style = MaterialIcon.Style.Sharp
        )
        val MacroOff = MaterialIcon(
            name = "macro_off",
            style = MaterialIcon.Style.Sharp
        )
        val Mail = MaterialIcon(
            name = "mail",
            style = MaterialIcon.Style.Sharp
        )
        val MailLock = MaterialIcon(
            name = "mail_lock",
            style = MaterialIcon.Style.Sharp
        )
        val MailOutline = MaterialIcon(
            name = "mail_outline",
            style = MaterialIcon.Style.Sharp
        )
        val Male = MaterialIcon(
            name = "male",
            style = MaterialIcon.Style.Sharp
        )
        val Man = MaterialIcon(
            name = "man",
            style = MaterialIcon.Style.Sharp
        )
        val Man2 = MaterialIcon(
            name = "man_2",
            style = MaterialIcon.Style.Sharp
        )
        val Man3 = MaterialIcon(
            name = "man_3",
            style = MaterialIcon.Style.Sharp
        )
        val Man4 = MaterialIcon(
            name = "man_4",
            style = MaterialIcon.Style.Sharp
        )
        val ManageAccounts = MaterialIcon(
            name = "manage_accounts",
            style = MaterialIcon.Style.Sharp
        )
        val ManageHistory = MaterialIcon(
            name = "manage_history",
            style = MaterialIcon.Style.Sharp
        )
        val ManageSearch = MaterialIcon(
            name = "manage_search",
            style = MaterialIcon.Style.Sharp
        )
        val Map = MaterialIcon(
            name = "map",
            style = MaterialIcon.Style.Sharp
        )
        val MapsHomeWork = MaterialIcon(
            name = "maps_home_work",
            style = MaterialIcon.Style.Sharp
        )
        val MapsUgc = MaterialIcon(
            name = "maps_ugc",
            style = MaterialIcon.Style.Sharp
        )
        val Margin = MaterialIcon(
            name = "margin",
            style = MaterialIcon.Style.Sharp
        )
        val MarkAsUnread = MaterialIcon(
            name = "mark_as_unread",
            style = MaterialIcon.Style.Sharp
        )
        val MarkChatRead = MaterialIcon(
            name = "mark_chat_read",
            style = MaterialIcon.Style.Sharp
        )
        val MarkChatUnread = MaterialIcon(
            name = "mark_chat_unread",
            style = MaterialIcon.Style.Sharp
        )
        val MarkEmailRead = MaterialIcon(
            name = "mark_email_read",
            style = MaterialIcon.Style.Sharp
        )
        val MarkEmailUnread = MaterialIcon(
            name = "mark_email_unread",
            style = MaterialIcon.Style.Sharp
        )
        val MarkUnreadChatAlt = MaterialIcon(
            name = "mark_unread_chat_alt",
            style = MaterialIcon.Style.Sharp
        )
        val Markunread = MaterialIcon(
            name = "markunread",
            style = MaterialIcon.Style.Sharp
        )
        val MarkunreadMailbox = MaterialIcon(
            name = "markunread_mailbox",
            style = MaterialIcon.Style.Sharp
        )
        val Masks = MaterialIcon(
            name = "masks",
            style = MaterialIcon.Style.Sharp
        )
        val Maximize = MaterialIcon(
            name = "maximize",
            style = MaterialIcon.Style.Sharp
        )
        val MediaBluetoothOff = MaterialIcon(
            name = "media_bluetooth_off",
            style = MaterialIcon.Style.Sharp
        )
        val MediaBluetoothOn = MaterialIcon(
            name = "media_bluetooth_on",
            style = MaterialIcon.Style.Sharp
        )
        val Mediation = MaterialIcon(
            name = "mediation",
            style = MaterialIcon.Style.Sharp
        )
        val MedicalInformation = MaterialIcon(
            name = "medical_information",
            style = MaterialIcon.Style.Sharp
        )
        val MedicalServices = MaterialIcon(
            name = "medical_services",
            style = MaterialIcon.Style.Sharp
        )
        val Medication = MaterialIcon(
            name = "medication",
            style = MaterialIcon.Style.Sharp
        )
        val MedicationLiquid = MaterialIcon(
            name = "medication_liquid",
            style = MaterialIcon.Style.Sharp
        )
        val MeetingRoom = MaterialIcon(
            name = "meeting_room",
            style = MaterialIcon.Style.Sharp
        )
        val Memory = MaterialIcon(
            name = "memory",
            style = MaterialIcon.Style.Sharp
        )
        val Menu = MaterialIcon(
            name = "menu",
            style = MaterialIcon.Style.Sharp
        )
        val MenuBook = MaterialIcon(
            name = "menu_book",
            style = MaterialIcon.Style.Sharp
        )
        val MenuOpen = MaterialIcon(
            name = "menu_open",
            style = MaterialIcon.Style.Sharp
        )
        val Merge = MaterialIcon(
            name = "merge",
            style = MaterialIcon.Style.Sharp
        )
        val MergeType = MaterialIcon(
            name = "merge_type",
            style = MaterialIcon.Style.Sharp
        )
        val Message = MaterialIcon(
            name = "message",
            style = MaterialIcon.Style.Sharp
        )
        val Mic = MaterialIcon(
            name = "mic",
            style = MaterialIcon.Style.Sharp
        )
        val MicExternalOff = MaterialIcon(
            name = "mic_external_off",
            style = MaterialIcon.Style.Sharp
        )
        val MicExternalOn = MaterialIcon(
            name = "mic_external_on",
            style = MaterialIcon.Style.Sharp
        )
        val MicNone = MaterialIcon(
            name = "mic_none",
            style = MaterialIcon.Style.Sharp
        )
        val MicOff = MaterialIcon(
            name = "mic_off",
            style = MaterialIcon.Style.Sharp
        )
        val Microwave = MaterialIcon(
            name = "microwave",
            style = MaterialIcon.Style.Sharp
        )
        val MilitaryTech = MaterialIcon(
            name = "military_tech",
            style = MaterialIcon.Style.Sharp
        )
        val Minimize = MaterialIcon(
            name = "minimize",
            style = MaterialIcon.Style.Sharp
        )
        val MinorCrash = MaterialIcon(
            name = "minor_crash",
            style = MaterialIcon.Style.Sharp
        )
        val MiscellaneousServices = MaterialIcon(
            name = "miscellaneous_services",
            style = MaterialIcon.Style.Sharp
        )
        val MissedVideoCall = MaterialIcon(
            name = "missed_video_call",
            style = MaterialIcon.Style.Sharp
        )
        val Mms = MaterialIcon(
            name = "mms",
            style = MaterialIcon.Style.Sharp
        )
        val MobileFriendly = MaterialIcon(
            name = "mobile_friendly",
            style = MaterialIcon.Style.Sharp
        )
        val MobileOff = MaterialIcon(
            name = "mobile_off",
            style = MaterialIcon.Style.Sharp
        )
        val MobileScreenShare = MaterialIcon(
            name = "mobile_screen_share",
            style = MaterialIcon.Style.Sharp
        )
        val MobiledataOff = MaterialIcon(
            name = "mobiledata_off",
            style = MaterialIcon.Style.Sharp
        )
        val Mode = MaterialIcon(
            name = "mode",
            style = MaterialIcon.Style.Sharp
        )
        val ModeComment = MaterialIcon(
            name = "mode_comment",
            style = MaterialIcon.Style.Sharp
        )
        val ModeEdit = MaterialIcon(
            name = "mode_edit",
            style = MaterialIcon.Style.Sharp
        )
        val ModeEditOutline = MaterialIcon(
            name = "mode_edit_outline",
            style = MaterialIcon.Style.Sharp
        )
        val ModeFanOff = MaterialIcon(
            name = "mode_fan_off",
            style = MaterialIcon.Style.Sharp
        )
        val ModeNight = MaterialIcon(
            name = "mode_night",
            style = MaterialIcon.Style.Sharp
        )
        val ModeOfTravel = MaterialIcon(
            name = "mode_of_travel",
            style = MaterialIcon.Style.Sharp
        )
        val ModeStandby = MaterialIcon(
            name = "mode_standby",
            style = MaterialIcon.Style.Sharp
        )
        val ModelTraining = MaterialIcon(
            name = "model_training",
            style = MaterialIcon.Style.Sharp
        )
        val MonetizationOn = MaterialIcon(
            name = "monetization_on",
            style = MaterialIcon.Style.Sharp
        )
        val Money = MaterialIcon(
            name = "money",
            style = MaterialIcon.Style.Sharp
        )
        val MoneyOff = MaterialIcon(
            name = "money_off",
            style = MaterialIcon.Style.Sharp
        )
        val MoneyOffCsred = MaterialIcon(
            name = "money_off_csred",
            style = MaterialIcon.Style.Sharp
        )
        val Monitor = MaterialIcon(
            name = "monitor",
            style = MaterialIcon.Style.Sharp
        )
        val MonitorHeart = MaterialIcon(
            name = "monitor_heart",
            style = MaterialIcon.Style.Sharp
        )
        val MonitorWeight = MaterialIcon(
            name = "monitor_weight",
            style = MaterialIcon.Style.Sharp
        )
        val MonochromePhotos = MaterialIcon(
            name = "monochrome_photos",
            style = MaterialIcon.Style.Sharp
        )
        val Mood = MaterialIcon(
            name = "mood",
            style = MaterialIcon.Style.Sharp
        )
        val MoodBad = MaterialIcon(
            name = "mood_bad",
            style = MaterialIcon.Style.Sharp
        )
        val Moped = MaterialIcon(
            name = "moped",
            style = MaterialIcon.Style.Sharp
        )
        val More = MaterialIcon(
            name = "more",
            style = MaterialIcon.Style.Sharp
        )
        val MoreHoriz = MaterialIcon(
            name = "more_horiz",
            style = MaterialIcon.Style.Sharp
        )
        val MoreTime = MaterialIcon(
            name = "more_time",
            style = MaterialIcon.Style.Sharp
        )
        val MoreVert = MaterialIcon(
            name = "more_vert",
            style = MaterialIcon.Style.Sharp
        )
        val Mosque = MaterialIcon(
            name = "mosque",
            style = MaterialIcon.Style.Sharp
        )
        val MotionPhotosAuto = MaterialIcon(
            name = "motion_photos_auto",
            style = MaterialIcon.Style.Sharp
        )
        val MotionPhotosOff = MaterialIcon(
            name = "motion_photos_off",
            style = MaterialIcon.Style.Sharp
        )
        val MotionPhotosOn = MaterialIcon(
            name = "motion_photos_on",
            style = MaterialIcon.Style.Sharp
        )
        val MotionPhotosPause = MaterialIcon(
            name = "motion_photos_pause",
            style = MaterialIcon.Style.Sharp
        )
        val MotionPhotosPaused = MaterialIcon(
            name = "motion_photos_paused",
            style = MaterialIcon.Style.Sharp
        )
        val Mouse = MaterialIcon(
            name = "mouse",
            style = MaterialIcon.Style.Sharp
        )
        val MoveDown = MaterialIcon(
            name = "move_down",
            style = MaterialIcon.Style.Sharp
        )
        val MoveToInbox = MaterialIcon(
            name = "move_to_inbox",
            style = MaterialIcon.Style.Sharp
        )
        val MoveUp = MaterialIcon(
            name = "move_up",
            style = MaterialIcon.Style.Sharp
        )
        val Movie = MaterialIcon(
            name = "movie",
            style = MaterialIcon.Style.Sharp
        )
        val MovieCreation = MaterialIcon(
            name = "movie_creation",
            style = MaterialIcon.Style.Sharp
        )
        val MovieFilter = MaterialIcon(
            name = "movie_filter",
            style = MaterialIcon.Style.Sharp
        )
        val Moving = MaterialIcon(
            name = "moving",
            style = MaterialIcon.Style.Sharp
        )
        val Mp = MaterialIcon(
            name = "mp",
            style = MaterialIcon.Style.Sharp
        )
        val MultilineChart = MaterialIcon(
            name = "multiline_chart",
            style = MaterialIcon.Style.Sharp
        )
        val MultipleStop = MaterialIcon(
            name = "multiple_stop",
            style = MaterialIcon.Style.Sharp
        )
        val Museum = MaterialIcon(
            name = "museum",
            style = MaterialIcon.Style.Sharp
        )
        val MusicNote = MaterialIcon(
            name = "music_note",
            style = MaterialIcon.Style.Sharp
        )
        val MusicOff = MaterialIcon(
            name = "music_off",
            style = MaterialIcon.Style.Sharp
        )
        val MusicVideo = MaterialIcon(
            name = "music_video",
            style = MaterialIcon.Style.Sharp
        )
        val MyLocation = MaterialIcon(
            name = "my_location",
            style = MaterialIcon.Style.Sharp
        )
        val Nat = MaterialIcon(
            name = "nat",
            style = MaterialIcon.Style.Sharp
        )
        val Nature = MaterialIcon(
            name = "nature",
            style = MaterialIcon.Style.Sharp
        )
        val NaturePeople = MaterialIcon(
            name = "nature_people",
            style = MaterialIcon.Style.Sharp
        )
        val NavigateBefore = MaterialIcon(
            name = "navigate_before",
            style = MaterialIcon.Style.Sharp
        )
        val NavigateNext = MaterialIcon(
            name = "navigate_next",
            style = MaterialIcon.Style.Sharp
        )
        val Navigation = MaterialIcon(
            name = "navigation",
            style = MaterialIcon.Style.Sharp
        )
        val NearMe = MaterialIcon(
            name = "near_me",
            style = MaterialIcon.Style.Sharp
        )
        val NearMeDisabled = MaterialIcon(
            name = "near_me_disabled",
            style = MaterialIcon.Style.Sharp
        )
        val NearbyError = MaterialIcon(
            name = "nearby_error",
            style = MaterialIcon.Style.Sharp
        )
        val NearbyOff = MaterialIcon(
            name = "nearby_off",
            style = MaterialIcon.Style.Sharp
        )
        val NestCamWiredStand = MaterialIcon(
            name = "nest_cam_wired_stand",
            style = MaterialIcon.Style.Sharp
        )
        val NetworkCell = MaterialIcon(
            name = "network_cell",
            style = MaterialIcon.Style.Sharp
        )
        val NetworkCheck = MaterialIcon(
            name = "network_check",
            style = MaterialIcon.Style.Sharp
        )
        val NetworkLocked = MaterialIcon(
            name = "network_locked",
            style = MaterialIcon.Style.Sharp
        )
        val NetworkPing = MaterialIcon(
            name = "network_ping",
            style = MaterialIcon.Style.Sharp
        )
        val NetworkWifi = MaterialIcon(
            name = "network_wifi",
            style = MaterialIcon.Style.Sharp
        )
        val NetworkWifi1Bar = MaterialIcon(
            name = "network_wifi_1_bar",
            style = MaterialIcon.Style.Sharp
        )
        val NetworkWifi2Bar = MaterialIcon(
            name = "network_wifi_2_bar",
            style = MaterialIcon.Style.Sharp
        )
        val NetworkWifi3Bar = MaterialIcon(
            name = "network_wifi_3_bar",
            style = MaterialIcon.Style.Sharp
        )
        val NewLabel = MaterialIcon(
            name = "new_label",
            style = MaterialIcon.Style.Sharp
        )
        val NewReleases = MaterialIcon(
            name = "new_releases",
            style = MaterialIcon.Style.Sharp
        )
        val Newspaper = MaterialIcon(
            name = "newspaper",
            style = MaterialIcon.Style.Sharp
        )
        val NextPlan = MaterialIcon(
            name = "next_plan",
            style = MaterialIcon.Style.Sharp
        )
        val NextWeek = MaterialIcon(
            name = "next_week",
            style = MaterialIcon.Style.Sharp
        )
        val Nfc = MaterialIcon(
            name = "nfc",
            style = MaterialIcon.Style.Sharp
        )
        val NightShelter = MaterialIcon(
            name = "night_shelter",
            style = MaterialIcon.Style.Sharp
        )
        val Nightlife = MaterialIcon(
            name = "nightlife",
            style = MaterialIcon.Style.Sharp
        )
        val Nightlight = MaterialIcon(
            name = "nightlight",
            style = MaterialIcon.Style.Sharp
        )
        val NightlightRound = MaterialIcon(
            name = "nightlight_round",
            style = MaterialIcon.Style.Sharp
        )
        val NightsStay = MaterialIcon(
            name = "nights_stay",
            style = MaterialIcon.Style.Sharp
        )
        val NoAccounts = MaterialIcon(
            name = "no_accounts",
            style = MaterialIcon.Style.Sharp
        )
        val NoAdultContent = MaterialIcon(
            name = "no_adult_content",
            style = MaterialIcon.Style.Sharp
        )
        val NoBackpack = MaterialIcon(
            name = "no_backpack",
            style = MaterialIcon.Style.Sharp
        )
        val NoCell = MaterialIcon(
            name = "no_cell",
            style = MaterialIcon.Style.Sharp
        )
        val NoCrash = MaterialIcon(
            name = "no_crash",
            style = MaterialIcon.Style.Sharp
        )
        val NoDrinks = MaterialIcon(
            name = "no_drinks",
            style = MaterialIcon.Style.Sharp
        )
        val NoEncryption = MaterialIcon(
            name = "no_encryption",
            style = MaterialIcon.Style.Sharp
        )
        val NoEncryptionGmailerrorred = MaterialIcon(
            name = "no_encryption_gmailerrorred",
            style = MaterialIcon.Style.Sharp
        )
        val NoFlash = MaterialIcon(
            name = "no_flash",
            style = MaterialIcon.Style.Sharp
        )
        val NoFood = MaterialIcon(
            name = "no_food",
            style = MaterialIcon.Style.Sharp
        )
        val NoLuggage = MaterialIcon(
            name = "no_luggage",
            style = MaterialIcon.Style.Sharp
        )
        val NoMeals = MaterialIcon(
            name = "no_meals",
            style = MaterialIcon.Style.Sharp
        )
        val NoMeetingRoom = MaterialIcon(
            name = "no_meeting_room",
            style = MaterialIcon.Style.Sharp
        )
        val NoPhotography = MaterialIcon(
            name = "no_photography",
            style = MaterialIcon.Style.Sharp
        )
        val NoSim = MaterialIcon(
            name = "no_sim",
            style = MaterialIcon.Style.Sharp
        )
        val NoStroller = MaterialIcon(
            name = "no_stroller",
            style = MaterialIcon.Style.Sharp
        )
        val NoTransfer = MaterialIcon(
            name = "no_transfer",
            style = MaterialIcon.Style.Sharp
        )
        val NoiseAware = MaterialIcon(
            name = "noise_aware",
            style = MaterialIcon.Style.Sharp
        )
        val NoiseControlOff = MaterialIcon(
            name = "noise_control_off",
            style = MaterialIcon.Style.Sharp
        )
        val NordicWalking = MaterialIcon(
            name = "nordic_walking",
            style = MaterialIcon.Style.Sharp
        )
        val North = MaterialIcon(
            name = "north",
            style = MaterialIcon.Style.Sharp
        )
        val NorthEast = MaterialIcon(
            name = "north_east",
            style = MaterialIcon.Style.Sharp
        )
        val NorthWest = MaterialIcon(
            name = "north_west",
            style = MaterialIcon.Style.Sharp
        )
        val NotAccessible = MaterialIcon(
            name = "not_accessible",
            style = MaterialIcon.Style.Sharp
        )
        val NotInterested = MaterialIcon(
            name = "not_interested",
            style = MaterialIcon.Style.Sharp
        )
        val NotListedLocation = MaterialIcon(
            name = "not_listed_location",
            style = MaterialIcon.Style.Sharp
        )
        val NotStarted = MaterialIcon(
            name = "not_started",
            style = MaterialIcon.Style.Sharp
        )
        val Note = MaterialIcon(
            name = "note",
            style = MaterialIcon.Style.Sharp
        )
        val NoteAdd = MaterialIcon(
            name = "note_add",
            style = MaterialIcon.Style.Sharp
        )
        val NoteAlt = MaterialIcon(
            name = "note_alt",
            style = MaterialIcon.Style.Sharp
        )
        val Notes = MaterialIcon(
            name = "notes",
            style = MaterialIcon.Style.Sharp
        )
        val NotificationAdd = MaterialIcon(
            name = "notification_add",
            style = MaterialIcon.Style.Sharp
        )
        val NotificationImportant = MaterialIcon(
            name = "notification_important",
            style = MaterialIcon.Style.Sharp
        )
        val Notifications = MaterialIcon(
            name = "notifications",
            style = MaterialIcon.Style.Sharp
        )
        val NotificationsActive = MaterialIcon(
            name = "notifications_active",
            style = MaterialIcon.Style.Sharp
        )
        val NotificationsNone = MaterialIcon(
            name = "notifications_none",
            style = MaterialIcon.Style.Sharp
        )
        val NotificationsOff = MaterialIcon(
            name = "notifications_off",
            style = MaterialIcon.Style.Sharp
        )
        val NotificationsPaused = MaterialIcon(
            name = "notifications_paused",
            style = MaterialIcon.Style.Sharp
        )
        val Numbers = MaterialIcon(
            name = "numbers",
            style = MaterialIcon.Style.Sharp
        )
        val OfflineBolt = MaterialIcon(
            name = "offline_bolt",
            style = MaterialIcon.Style.Sharp
        )
        val OfflinePin = MaterialIcon(
            name = "offline_pin",
            style = MaterialIcon.Style.Sharp
        )
        val OfflineShare = MaterialIcon(
            name = "offline_share",
            style = MaterialIcon.Style.Sharp
        )
        val OilBarrel = MaterialIcon(
            name = "oil_barrel",
            style = MaterialIcon.Style.Sharp
        )
        val OnDeviceTraining = MaterialIcon(
            name = "on_device_training",
            style = MaterialIcon.Style.Sharp
        )
        val OndemandVideo = MaterialIcon(
            name = "ondemand_video",
            style = MaterialIcon.Style.Sharp
        )
        val OnlinePrediction = MaterialIcon(
            name = "online_prediction",
            style = MaterialIcon.Style.Sharp
        )
        val Opacity = MaterialIcon(
            name = "opacity",
            style = MaterialIcon.Style.Sharp
        )
        val OpenInBrowser = MaterialIcon(
            name = "open_in_browser",
            style = MaterialIcon.Style.Sharp
        )
        val OpenInFull = MaterialIcon(
            name = "open_in_full",
            style = MaterialIcon.Style.Sharp
        )
        val OpenInNew = MaterialIcon(
            name = "open_in_new",
            style = MaterialIcon.Style.Sharp
        )
        val OpenInNewOff = MaterialIcon(
            name = "open_in_new_off",
            style = MaterialIcon.Style.Sharp
        )
        val OpenWith = MaterialIcon(
            name = "open_with",
            style = MaterialIcon.Style.Sharp
        )
        val OtherHouses = MaterialIcon(
            name = "other_houses",
            style = MaterialIcon.Style.Sharp
        )
        val Outbound = MaterialIcon(
            name = "outbound",
            style = MaterialIcon.Style.Sharp
        )
        val Outbox = MaterialIcon(
            name = "outbox",
            style = MaterialIcon.Style.Sharp
        )
        val OutdoorGrill = MaterialIcon(
            name = "outdoor_grill",
            style = MaterialIcon.Style.Sharp
        )
        val Outlet = MaterialIcon(
            name = "outlet",
            style = MaterialIcon.Style.Sharp
        )
        val OutlinedFlag = MaterialIcon(
            name = "outlined_flag",
            style = MaterialIcon.Style.Sharp
        )
        val Output = MaterialIcon(
            name = "output",
            style = MaterialIcon.Style.Sharp
        )
        val Padding = MaterialIcon(
            name = "padding",
            style = MaterialIcon.Style.Sharp
        )
        val Pages = MaterialIcon(
            name = "pages",
            style = MaterialIcon.Style.Sharp
        )
        val Pageview = MaterialIcon(
            name = "pageview",
            style = MaterialIcon.Style.Sharp
        )
        val Paid = MaterialIcon(
            name = "paid",
            style = MaterialIcon.Style.Sharp
        )
        val Palette = MaterialIcon(
            name = "palette",
            style = MaterialIcon.Style.Sharp
        )
        val PanTool = MaterialIcon(
            name = "pan_tool",
            style = MaterialIcon.Style.Sharp
        )
        val PanToolAlt = MaterialIcon(
            name = "pan_tool_alt",
            style = MaterialIcon.Style.Sharp
        )
        val Panorama = MaterialIcon(
            name = "panorama",
            style = MaterialIcon.Style.Sharp
        )
        val PanoramaFishEye = MaterialIcon(
            name = "panorama_fish_eye",
            style = MaterialIcon.Style.Sharp
        )
        val PanoramaHorizontal = MaterialIcon(
            name = "panorama_horizontal",
            style = MaterialIcon.Style.Sharp
        )
        val PanoramaHorizontalSelect = MaterialIcon(
            name = "panorama_horizontal_select",
            style = MaterialIcon.Style.Sharp
        )
        val PanoramaPhotosphere = MaterialIcon(
            name = "panorama_photosphere",
            style = MaterialIcon.Style.Sharp
        )
        val PanoramaPhotosphereSelect = MaterialIcon(
            name = "panorama_photosphere_select",
            style = MaterialIcon.Style.Sharp
        )
        val PanoramaVertical = MaterialIcon(
            name = "panorama_vertical",
            style = MaterialIcon.Style.Sharp
        )
        val PanoramaVerticalSelect = MaterialIcon(
            name = "panorama_vertical_select",
            style = MaterialIcon.Style.Sharp
        )
        val PanoramaWideAngle = MaterialIcon(
            name = "panorama_wide_angle",
            style = MaterialIcon.Style.Sharp
        )
        val PanoramaWideAngleSelect = MaterialIcon(
            name = "panorama_wide_angle_select",
            style = MaterialIcon.Style.Sharp
        )
        val Paragliding = MaterialIcon(
            name = "paragliding",
            style = MaterialIcon.Style.Sharp
        )
        val Park = MaterialIcon(
            name = "park",
            style = MaterialIcon.Style.Sharp
        )
        val PartyMode = MaterialIcon(
            name = "party_mode",
            style = MaterialIcon.Style.Sharp
        )
        val Password = MaterialIcon(
            name = "password",
            style = MaterialIcon.Style.Sharp
        )
        val Pattern = MaterialIcon(
            name = "pattern",
            style = MaterialIcon.Style.Sharp
        )
        val Pause = MaterialIcon(
            name = "pause",
            style = MaterialIcon.Style.Sharp
        )
        val PauseCircle = MaterialIcon(
            name = "pause_circle",
            style = MaterialIcon.Style.Sharp
        )
        val PauseCircleFilled = MaterialIcon(
            name = "pause_circle_filled",
            style = MaterialIcon.Style.Sharp
        )
        val PauseCircleOutline = MaterialIcon(
            name = "pause_circle_outline",
            style = MaterialIcon.Style.Sharp
        )
        val PausePresentation = MaterialIcon(
            name = "pause_presentation",
            style = MaterialIcon.Style.Sharp
        )
        val Payment = MaterialIcon(
            name = "payment",
            style = MaterialIcon.Style.Sharp
        )
        val Payments = MaterialIcon(
            name = "payments",
            style = MaterialIcon.Style.Sharp
        )
        val PedalBike = MaterialIcon(
            name = "pedal_bike",
            style = MaterialIcon.Style.Sharp
        )
        val Pending = MaterialIcon(
            name = "pending",
            style = MaterialIcon.Style.Sharp
        )
        val PendingActions = MaterialIcon(
            name = "pending_actions",
            style = MaterialIcon.Style.Sharp
        )
        val Pentagon = MaterialIcon(
            name = "pentagon",
            style = MaterialIcon.Style.Sharp
        )
        val People = MaterialIcon(
            name = "people",
            style = MaterialIcon.Style.Sharp
        )
        val PeopleAlt = MaterialIcon(
            name = "people_alt",
            style = MaterialIcon.Style.Sharp
        )
        val PeopleOutline = MaterialIcon(
            name = "people_outline",
            style = MaterialIcon.Style.Sharp
        )
        val Percent = MaterialIcon(
            name = "percent",
            style = MaterialIcon.Style.Sharp
        )
        val PermCameraMic = MaterialIcon(
            name = "perm_camera_mic",
            style = MaterialIcon.Style.Sharp
        )
        val PermContactCalendar = MaterialIcon(
            name = "perm_contact_calendar",
            style = MaterialIcon.Style.Sharp
        )
        val PermDataSetting = MaterialIcon(
            name = "perm_data_setting",
            style = MaterialIcon.Style.Sharp
        )
        val PermDeviceInformation = MaterialIcon(
            name = "perm_device_information",
            style = MaterialIcon.Style.Sharp
        )
        val PermIdentity = MaterialIcon(
            name = "perm_identity",
            style = MaterialIcon.Style.Sharp
        )
        val PermMedia = MaterialIcon(
            name = "perm_media",
            style = MaterialIcon.Style.Sharp
        )
        val PermPhoneMsg = MaterialIcon(
            name = "perm_phone_msg",
            style = MaterialIcon.Style.Sharp
        )
        val PermScanWifi = MaterialIcon(
            name = "perm_scan_wifi",
            style = MaterialIcon.Style.Sharp
        )
        val Person = MaterialIcon(
            name = "person",
            style = MaterialIcon.Style.Sharp
        )
        val Person2 = MaterialIcon(
            name = "person_2",
            style = MaterialIcon.Style.Sharp
        )
        val Person3 = MaterialIcon(
            name = "person_3",
            style = MaterialIcon.Style.Sharp
        )
        val Person4 = MaterialIcon(
            name = "person_4",
            style = MaterialIcon.Style.Sharp
        )
        val PersonAdd = MaterialIcon(
            name = "person_add",
            style = MaterialIcon.Style.Sharp
        )
        val PersonAddAlt = MaterialIcon(
            name = "person_add_alt",
            style = MaterialIcon.Style.Sharp
        )
        val PersonAddAlt1 = MaterialIcon(
            name = "person_add_alt_1",
            style = MaterialIcon.Style.Sharp
        )
        val PersonAddDisabled = MaterialIcon(
            name = "person_add_disabled",
            style = MaterialIcon.Style.Sharp
        )
        val PersonOff = MaterialIcon(
            name = "person_off",
            style = MaterialIcon.Style.Sharp
        )
        val PersonOutline = MaterialIcon(
            name = "person_outline",
            style = MaterialIcon.Style.Sharp
        )
        val PersonPin = MaterialIcon(
            name = "person_pin",
            style = MaterialIcon.Style.Sharp
        )
        val PersonPinCircle = MaterialIcon(
            name = "person_pin_circle",
            style = MaterialIcon.Style.Sharp
        )
        val PersonRemove = MaterialIcon(
            name = "person_remove",
            style = MaterialIcon.Style.Sharp
        )
        val PersonRemoveAlt1 = MaterialIcon(
            name = "person_remove_alt_1",
            style = MaterialIcon.Style.Sharp
        )
        val PersonSearch = MaterialIcon(
            name = "person_search",
            style = MaterialIcon.Style.Sharp
        )
        val PersonalInjury = MaterialIcon(
            name = "personal_injury",
            style = MaterialIcon.Style.Sharp
        )
        val PersonalVideo = MaterialIcon(
            name = "personal_video",
            style = MaterialIcon.Style.Sharp
        )
        val PestControl = MaterialIcon(
            name = "pest_control",
            style = MaterialIcon.Style.Sharp
        )
        val PestControlRodent = MaterialIcon(
            name = "pest_control_rodent",
            style = MaterialIcon.Style.Sharp
        )
        val Pets = MaterialIcon(
            name = "pets",
            style = MaterialIcon.Style.Sharp
        )
        val Phishing = MaterialIcon(
            name = "phishing",
            style = MaterialIcon.Style.Sharp
        )
        val Phone = MaterialIcon(
            name = "phone",
            style = MaterialIcon.Style.Sharp
        )
        val PhoneAndroid = MaterialIcon(
            name = "phone_android",
            style = MaterialIcon.Style.Sharp
        )
        val PhoneBluetoothSpeaker = MaterialIcon(
            name = "phone_bluetooth_speaker",
            style = MaterialIcon.Style.Sharp
        )
        val PhoneCallback = MaterialIcon(
            name = "phone_callback",
            style = MaterialIcon.Style.Sharp
        )
        val PhoneDisabled = MaterialIcon(
            name = "phone_disabled",
            style = MaterialIcon.Style.Sharp
        )
        val PhoneEnabled = MaterialIcon(
            name = "phone_enabled",
            style = MaterialIcon.Style.Sharp
        )
        val PhoneForwarded = MaterialIcon(
            name = "phone_forwarded",
            style = MaterialIcon.Style.Sharp
        )
        val PhoneIphone = MaterialIcon(
            name = "phone_iphone",
            style = MaterialIcon.Style.Sharp
        )
        val PhoneLocked = MaterialIcon(
            name = "phone_locked",
            style = MaterialIcon.Style.Sharp
        )
        val PhoneMissed = MaterialIcon(
            name = "phone_missed",
            style = MaterialIcon.Style.Sharp
        )
        val PhonePaused = MaterialIcon(
            name = "phone_paused",
            style = MaterialIcon.Style.Sharp
        )
        val Phonelink = MaterialIcon(
            name = "phonelink",
            style = MaterialIcon.Style.Sharp
        )
        val PhonelinkErase = MaterialIcon(
            name = "phonelink_erase",
            style = MaterialIcon.Style.Sharp
        )
        val PhonelinkLock = MaterialIcon(
            name = "phonelink_lock",
            style = MaterialIcon.Style.Sharp
        )
        val PhonelinkOff = MaterialIcon(
            name = "phonelink_off",
            style = MaterialIcon.Style.Sharp
        )
        val PhonelinkRing = MaterialIcon(
            name = "phonelink_ring",
            style = MaterialIcon.Style.Sharp
        )
        val PhonelinkSetup = MaterialIcon(
            name = "phonelink_setup",
            style = MaterialIcon.Style.Sharp
        )
        val Photo = MaterialIcon(
            name = "photo",
            style = MaterialIcon.Style.Sharp
        )
        val PhotoAlbum = MaterialIcon(
            name = "photo_album",
            style = MaterialIcon.Style.Sharp
        )
        val PhotoCamera = MaterialIcon(
            name = "photo_camera",
            style = MaterialIcon.Style.Sharp
        )
        val PhotoCameraBack = MaterialIcon(
            name = "photo_camera_back",
            style = MaterialIcon.Style.Sharp
        )
        val PhotoCameraFront = MaterialIcon(
            name = "photo_camera_front",
            style = MaterialIcon.Style.Sharp
        )
        val PhotoFilter = MaterialIcon(
            name = "photo_filter",
            style = MaterialIcon.Style.Sharp
        )
        val PhotoLibrary = MaterialIcon(
            name = "photo_library",
            style = MaterialIcon.Style.Sharp
        )
        val PhotoSizeSelectActual = MaterialIcon(
            name = "photo_size_select_actual",
            style = MaterialIcon.Style.Sharp
        )
        val PhotoSizeSelectLarge = MaterialIcon(
            name = "photo_size_select_large",
            style = MaterialIcon.Style.Sharp
        )
        val PhotoSizeSelectSmall = MaterialIcon(
            name = "photo_size_select_small",
            style = MaterialIcon.Style.Sharp
        )
        val Php = MaterialIcon(
            name = "php",
            style = MaterialIcon.Style.Sharp
        )
        val Piano = MaterialIcon(
            name = "piano",
            style = MaterialIcon.Style.Sharp
        )
        val PianoOff = MaterialIcon(
            name = "piano_off",
            style = MaterialIcon.Style.Sharp
        )
        val PictureAsPdf = MaterialIcon(
            name = "picture_as_pdf",
            style = MaterialIcon.Style.Sharp
        )
        val PictureInPicture = MaterialIcon(
            name = "picture_in_picture",
            style = MaterialIcon.Style.Sharp
        )
        val PictureInPictureAlt = MaterialIcon(
            name = "picture_in_picture_alt",
            style = MaterialIcon.Style.Sharp
        )
        val PieChart = MaterialIcon(
            name = "pie_chart",
            style = MaterialIcon.Style.Sharp
        )
        val PieChartOutline = MaterialIcon(
            name = "pie_chart_outline",
            style = MaterialIcon.Style.Sharp
        )
        val Pin = MaterialIcon(
            name = "pin",
            style = MaterialIcon.Style.Sharp
        )
        val PinDrop = MaterialIcon(
            name = "pin_drop",
            style = MaterialIcon.Style.Sharp
        )
        val PinEnd = MaterialIcon(
            name = "pin_end",
            style = MaterialIcon.Style.Sharp
        )
        val PinInvoke = MaterialIcon(
            name = "pin_invoke",
            style = MaterialIcon.Style.Sharp
        )
        val Pinch = MaterialIcon(
            name = "pinch",
            style = MaterialIcon.Style.Sharp
        )
        val PivotTableChart = MaterialIcon(
            name = "pivot_table_chart",
            style = MaterialIcon.Style.Sharp
        )
        val Pix = MaterialIcon(
            name = "pix",
            style = MaterialIcon.Style.Sharp
        )
        val Place = MaterialIcon(
            name = "place",
            style = MaterialIcon.Style.Sharp
        )
        val Plagiarism = MaterialIcon(
            name = "plagiarism",
            style = MaterialIcon.Style.Sharp
        )
        val PlayArrow = MaterialIcon(
            name = "play_arrow",
            style = MaterialIcon.Style.Sharp
        )
        val PlayCircle = MaterialIcon(
            name = "play_circle",
            style = MaterialIcon.Style.Sharp
        )
        val PlayCircleFilled = MaterialIcon(
            name = "play_circle_filled",
            style = MaterialIcon.Style.Sharp
        )
        val PlayCircleOutline = MaterialIcon(
            name = "play_circle_outline",
            style = MaterialIcon.Style.Sharp
        )
        val PlayDisabled = MaterialIcon(
            name = "play_disabled",
            style = MaterialIcon.Style.Sharp
        )
        val PlayForWork = MaterialIcon(
            name = "play_for_work",
            style = MaterialIcon.Style.Sharp
        )
        val PlayLesson = MaterialIcon(
            name = "play_lesson",
            style = MaterialIcon.Style.Sharp
        )
        val PlaylistAdd = MaterialIcon(
            name = "playlist_add",
            style = MaterialIcon.Style.Sharp
        )
        val PlaylistAddCheck = MaterialIcon(
            name = "playlist_add_check",
            style = MaterialIcon.Style.Sharp
        )
        val PlaylistAddCheckCircle = MaterialIcon(
            name = "playlist_add_check_circle",
            style = MaterialIcon.Style.Sharp
        )
        val PlaylistAddCircle = MaterialIcon(
            name = "playlist_add_circle",
            style = MaterialIcon.Style.Sharp
        )
        val PlaylistPlay = MaterialIcon(
            name = "playlist_play",
            style = MaterialIcon.Style.Sharp
        )
        val PlaylistRemove = MaterialIcon(
            name = "playlist_remove",
            style = MaterialIcon.Style.Sharp
        )
        val Plumbing = MaterialIcon(
            name = "plumbing",
            style = MaterialIcon.Style.Sharp
        )
        val PlusOne = MaterialIcon(
            name = "plus_one",
            style = MaterialIcon.Style.Sharp
        )
        val Podcasts = MaterialIcon(
            name = "podcasts",
            style = MaterialIcon.Style.Sharp
        )
        val PointOfSale = MaterialIcon(
            name = "point_of_sale",
            style = MaterialIcon.Style.Sharp
        )
        val Policy = MaterialIcon(
            name = "policy",
            style = MaterialIcon.Style.Sharp
        )
        val Poll = MaterialIcon(
            name = "poll",
            style = MaterialIcon.Style.Sharp
        )
        val Polyline = MaterialIcon(
            name = "polyline",
            style = MaterialIcon.Style.Sharp
        )
        val Polymer = MaterialIcon(
            name = "polymer",
            style = MaterialIcon.Style.Sharp
        )
        val Pool = MaterialIcon(
            name = "pool",
            style = MaterialIcon.Style.Sharp
        )
        val PortableWifiOff = MaterialIcon(
            name = "portable_wifi_off",
            style = MaterialIcon.Style.Sharp
        )
        val Portrait = MaterialIcon(
            name = "portrait",
            style = MaterialIcon.Style.Sharp
        )
        val PostAdd = MaterialIcon(
            name = "post_add",
            style = MaterialIcon.Style.Sharp
        )
        val Power = MaterialIcon(
            name = "power",
            style = MaterialIcon.Style.Sharp
        )
        val PowerInput = MaterialIcon(
            name = "power_input",
            style = MaterialIcon.Style.Sharp
        )
        val PowerOff = MaterialIcon(
            name = "power_off",
            style = MaterialIcon.Style.Sharp
        )
        val PowerSettingsNew = MaterialIcon(
            name = "power_settings_new",
            style = MaterialIcon.Style.Sharp
        )
        val PrecisionManufacturing = MaterialIcon(
            name = "precision_manufacturing",
            style = MaterialIcon.Style.Sharp
        )
        val PregnantWoman = MaterialIcon(
            name = "pregnant_woman",
            style = MaterialIcon.Style.Sharp
        )
        val PresentToAll = MaterialIcon(
            name = "present_to_all",
            style = MaterialIcon.Style.Sharp
        )
        val Preview = MaterialIcon(
            name = "preview",
            style = MaterialIcon.Style.Sharp
        )
        val PriceChange = MaterialIcon(
            name = "price_change",
            style = MaterialIcon.Style.Sharp
        )
        val PriceCheck = MaterialIcon(
            name = "price_check",
            style = MaterialIcon.Style.Sharp
        )
        val Print = MaterialIcon(
            name = "print",
            style = MaterialIcon.Style.Sharp
        )
        val PrintDisabled = MaterialIcon(
            name = "print_disabled",
            style = MaterialIcon.Style.Sharp
        )
        val PriorityHigh = MaterialIcon(
            name = "priority_high",
            style = MaterialIcon.Style.Sharp
        )
        val PrivacyTip = MaterialIcon(
            name = "privacy_tip",
            style = MaterialIcon.Style.Sharp
        )
        val PrivateConnectivity = MaterialIcon(
            name = "private_connectivity",
            style = MaterialIcon.Style.Sharp
        )
        val ProductionQuantityLimits = MaterialIcon(
            name = "production_quantity_limits",
            style = MaterialIcon.Style.Sharp
        )
        val Propane = MaterialIcon(
            name = "propane",
            style = MaterialIcon.Style.Sharp
        )
        val PropaneTank = MaterialIcon(
            name = "propane_tank",
            style = MaterialIcon.Style.Sharp
        )
        val Psychology = MaterialIcon(
            name = "psychology",
            style = MaterialIcon.Style.Sharp
        )
        val PsychologyAlt = MaterialIcon(
            name = "psychology_alt",
            style = MaterialIcon.Style.Sharp
        )
        val Public = MaterialIcon(
            name = "public",
            style = MaterialIcon.Style.Sharp
        )
        val PublicOff = MaterialIcon(
            name = "public_off",
            style = MaterialIcon.Style.Sharp
        )
        val Publish = MaterialIcon(
            name = "publish",
            style = MaterialIcon.Style.Sharp
        )
        val PublishedWithChanges = MaterialIcon(
            name = "published_with_changes",
            style = MaterialIcon.Style.Sharp
        )
        val PunchClock = MaterialIcon(
            name = "punch_clock",
            style = MaterialIcon.Style.Sharp
        )
        val PushPin = MaterialIcon(
            name = "push_pin",
            style = MaterialIcon.Style.Sharp
        )
        val QrCode = MaterialIcon(
            name = "qr_code",
            style = MaterialIcon.Style.Sharp
        )
        val QrCode2 = MaterialIcon(
            name = "qr_code_2",
            style = MaterialIcon.Style.Sharp
        )
        val QrCodeScanner = MaterialIcon(
            name = "qr_code_scanner",
            style = MaterialIcon.Style.Sharp
        )
        val QueryBuilder = MaterialIcon(
            name = "query_builder",
            style = MaterialIcon.Style.Sharp
        )
        val QueryStats = MaterialIcon(
            name = "query_stats",
            style = MaterialIcon.Style.Sharp
        )
        val QuestionAnswer = MaterialIcon(
            name = "question_answer",
            style = MaterialIcon.Style.Sharp
        )
        val QuestionMark = MaterialIcon(
            name = "question_mark",
            style = MaterialIcon.Style.Sharp
        )
        val Queue = MaterialIcon(
            name = "queue",
            style = MaterialIcon.Style.Sharp
        )
        val QueueMusic = MaterialIcon(
            name = "queue_music",
            style = MaterialIcon.Style.Sharp
        )
        val QueuePlayNext = MaterialIcon(
            name = "queue_play_next",
            style = MaterialIcon.Style.Sharp
        )
        val Quickreply = MaterialIcon(
            name = "quickreply",
            style = MaterialIcon.Style.Sharp
        )
        val Quiz = MaterialIcon(
            name = "quiz",
            style = MaterialIcon.Style.Sharp
        )
        val RMobiledata = MaterialIcon(
            name = "r_mobiledata",
            style = MaterialIcon.Style.Sharp
        )
        val Radar = MaterialIcon(
            name = "radar",
            style = MaterialIcon.Style.Sharp
        )
        val Radio = MaterialIcon(
            name = "radio",
            style = MaterialIcon.Style.Sharp
        )
        val RadioButtonChecked = MaterialIcon(
            name = "radio_button_checked",
            style = MaterialIcon.Style.Sharp
        )
        val RadioButtonUnchecked = MaterialIcon(
            name = "radio_button_unchecked",
            style = MaterialIcon.Style.Sharp
        )
        val RailwayAlert = MaterialIcon(
            name = "railway_alert",
            style = MaterialIcon.Style.Sharp
        )
        val RamenDining = MaterialIcon(
            name = "ramen_dining",
            style = MaterialIcon.Style.Sharp
        )
        val RampLeft = MaterialIcon(
            name = "ramp_left",
            style = MaterialIcon.Style.Sharp
        )
        val RampRight = MaterialIcon(
            name = "ramp_right",
            style = MaterialIcon.Style.Sharp
        )
        val RateReview = MaterialIcon(
            name = "rate_review",
            style = MaterialIcon.Style.Sharp
        )
        val RawOff = MaterialIcon(
            name = "raw_off",
            style = MaterialIcon.Style.Sharp
        )
        val RawOn = MaterialIcon(
            name = "raw_on",
            style = MaterialIcon.Style.Sharp
        )
        val ReadMore = MaterialIcon(
            name = "read_more",
            style = MaterialIcon.Style.Sharp
        )
        val RealEstateAgent = MaterialIcon(
            name = "real_estate_agent",
            style = MaterialIcon.Style.Sharp
        )
        val Receipt = MaterialIcon(
            name = "receipt",
            style = MaterialIcon.Style.Sharp
        )
        val ReceiptLong = MaterialIcon(
            name = "receipt_long",
            style = MaterialIcon.Style.Sharp
        )
        val RecentActors = MaterialIcon(
            name = "recent_actors",
            style = MaterialIcon.Style.Sharp
        )
        val Recommend = MaterialIcon(
            name = "recommend",
            style = MaterialIcon.Style.Sharp
        )
        val RecordVoiceOver = MaterialIcon(
            name = "record_voice_over",
            style = MaterialIcon.Style.Sharp
        )
        val Rectangle = MaterialIcon(
            name = "rectangle",
            style = MaterialIcon.Style.Sharp
        )
        val Recycling = MaterialIcon(
            name = "recycling",
            style = MaterialIcon.Style.Sharp
        )
        val Redeem = MaterialIcon(
            name = "redeem",
            style = MaterialIcon.Style.Sharp
        )
        val Redo = MaterialIcon(
            name = "redo",
            style = MaterialIcon.Style.Sharp
        )
        val ReduceCapacity = MaterialIcon(
            name = "reduce_capacity",
            style = MaterialIcon.Style.Sharp
        )
        val Refresh = MaterialIcon(
            name = "refresh",
            style = MaterialIcon.Style.Sharp
        )
        val RememberMe = MaterialIcon(
            name = "remember_me",
            style = MaterialIcon.Style.Sharp
        )
        val Remove = MaterialIcon(
            name = "remove",
            style = MaterialIcon.Style.Sharp
        )
        val RemoveCircle = MaterialIcon(
            name = "remove_circle",
            style = MaterialIcon.Style.Sharp
        )
        val RemoveCircleOutline = MaterialIcon(
            name = "remove_circle_outline",
            style = MaterialIcon.Style.Sharp
        )
        val RemoveDone = MaterialIcon(
            name = "remove_done",
            style = MaterialIcon.Style.Sharp
        )
        val RemoveFromQueue = MaterialIcon(
            name = "remove_from_queue",
            style = MaterialIcon.Style.Sharp
        )
        val RemoveModerator = MaterialIcon(
            name = "remove_moderator",
            style = MaterialIcon.Style.Sharp
        )
        val RemoveRedEye = MaterialIcon(
            name = "remove_red_eye",
            style = MaterialIcon.Style.Sharp
        )
        val RemoveRoad = MaterialIcon(
            name = "remove_road",
            style = MaterialIcon.Style.Sharp
        )
        val RemoveShoppingCart = MaterialIcon(
            name = "remove_shopping_cart",
            style = MaterialIcon.Style.Sharp
        )
        val Reorder = MaterialIcon(
            name = "reorder",
            style = MaterialIcon.Style.Sharp
        )
        val Repartition = MaterialIcon(
            name = "repartition",
            style = MaterialIcon.Style.Sharp
        )
        val Repeat = MaterialIcon(
            name = "repeat",
            style = MaterialIcon.Style.Sharp
        )
        val RepeatOn = MaterialIcon(
            name = "repeat_on",
            style = MaterialIcon.Style.Sharp
        )
        val RepeatOne = MaterialIcon(
            name = "repeat_one",
            style = MaterialIcon.Style.Sharp
        )
        val RepeatOneOn = MaterialIcon(
            name = "repeat_one_on",
            style = MaterialIcon.Style.Sharp
        )
        val Replay = MaterialIcon(
            name = "replay",
            style = MaterialIcon.Style.Sharp
        )
        val Replay10 = MaterialIcon(
            name = "replay_10",
            style = MaterialIcon.Style.Sharp
        )
        val Replay30 = MaterialIcon(
            name = "replay_30",
            style = MaterialIcon.Style.Sharp
        )
        val Replay5 = MaterialIcon(
            name = "replay_5",
            style = MaterialIcon.Style.Sharp
        )
        val ReplayCircleFilled = MaterialIcon(
            name = "replay_circle_filled",
            style = MaterialIcon.Style.Sharp
        )
        val Reply = MaterialIcon(
            name = "reply",
            style = MaterialIcon.Style.Sharp
        )
        val ReplyAll = MaterialIcon(
            name = "reply_all",
            style = MaterialIcon.Style.Sharp
        )
        val Report = MaterialIcon(
            name = "report",
            style = MaterialIcon.Style.Sharp
        )
        val ReportGmailerrorred = MaterialIcon(
            name = "report_gmailerrorred",
            style = MaterialIcon.Style.Sharp
        )
        val ReportOff = MaterialIcon(
            name = "report_off",
            style = MaterialIcon.Style.Sharp
        )
        val ReportProblem = MaterialIcon(
            name = "report_problem",
            style = MaterialIcon.Style.Sharp
        )
        val RequestPage = MaterialIcon(
            name = "request_page",
            style = MaterialIcon.Style.Sharp
        )
        val RequestQuote = MaterialIcon(
            name = "request_quote",
            style = MaterialIcon.Style.Sharp
        )
        val ResetTv = MaterialIcon(
            name = "reset_tv",
            style = MaterialIcon.Style.Sharp
        )
        val RestartAlt = MaterialIcon(
            name = "restart_alt",
            style = MaterialIcon.Style.Sharp
        )
        val Restaurant = MaterialIcon(
            name = "restaurant",
            style = MaterialIcon.Style.Sharp
        )
        val RestaurantMenu = MaterialIcon(
            name = "restaurant_menu",
            style = MaterialIcon.Style.Sharp
        )
        val Restore = MaterialIcon(
            name = "restore",
            style = MaterialIcon.Style.Sharp
        )
        val RestoreFromTrash = MaterialIcon(
            name = "restore_from_trash",
            style = MaterialIcon.Style.Sharp
        )
        val RestorePage = MaterialIcon(
            name = "restore_page",
            style = MaterialIcon.Style.Sharp
        )
        val Reviews = MaterialIcon(
            name = "reviews",
            style = MaterialIcon.Style.Sharp
        )
        val RiceBowl = MaterialIcon(
            name = "rice_bowl",
            style = MaterialIcon.Style.Sharp
        )
        val RingVolume = MaterialIcon(
            name = "ring_volume",
            style = MaterialIcon.Style.Sharp
        )
        val Rocket = MaterialIcon(
            name = "rocket",
            style = MaterialIcon.Style.Sharp
        )
        val RocketLaunch = MaterialIcon(
            name = "rocket_launch",
            style = MaterialIcon.Style.Sharp
        )
        val RollerShades = MaterialIcon(
            name = "roller_shades",
            style = MaterialIcon.Style.Sharp
        )
        val RollerShadesClosed = MaterialIcon(
            name = "roller_shades_closed",
            style = MaterialIcon.Style.Sharp
        )
        val RollerSkating = MaterialIcon(
            name = "roller_skating",
            style = MaterialIcon.Style.Sharp
        )
        val Roofing = MaterialIcon(
            name = "roofing",
            style = MaterialIcon.Style.Sharp
        )
        val Room = MaterialIcon(
            name = "room",
            style = MaterialIcon.Style.Sharp
        )
        val RoomPreferences = MaterialIcon(
            name = "room_preferences",
            style = MaterialIcon.Style.Sharp
        )
        val RoomService = MaterialIcon(
            name = "room_service",
            style = MaterialIcon.Style.Sharp
        )
        val Rotate90DegreesCcw = MaterialIcon(
            name = "rotate_90_degrees_ccw",
            style = MaterialIcon.Style.Sharp
        )
        val Rotate90DegreesCw = MaterialIcon(
            name = "rotate_90_degrees_cw",
            style = MaterialIcon.Style.Sharp
        )
        val RotateLeft = MaterialIcon(
            name = "rotate_left",
            style = MaterialIcon.Style.Sharp
        )
        val RotateRight = MaterialIcon(
            name = "rotate_right",
            style = MaterialIcon.Style.Sharp
        )
        val RoundaboutLeft = MaterialIcon(
            name = "roundabout_left",
            style = MaterialIcon.Style.Sharp
        )
        val RoundaboutRight = MaterialIcon(
            name = "roundabout_right",
            style = MaterialIcon.Style.Sharp
        )
        val RoundedCorner = MaterialIcon(
            name = "rounded_corner",
            style = MaterialIcon.Style.Sharp
        )
        val Route = MaterialIcon(
            name = "route",
            style = MaterialIcon.Style.Sharp
        )
        val Router = MaterialIcon(
            name = "router",
            style = MaterialIcon.Style.Sharp
        )
        val Rowing = MaterialIcon(
            name = "rowing",
            style = MaterialIcon.Style.Sharp
        )
        val RssFeed = MaterialIcon(
            name = "rss_feed",
            style = MaterialIcon.Style.Sharp
        )
        val Rsvp = MaterialIcon(
            name = "rsvp",
            style = MaterialIcon.Style.Sharp
        )
        val Rtt = MaterialIcon(
            name = "rtt",
            style = MaterialIcon.Style.Sharp
        )
        val Rule = MaterialIcon(
            name = "rule",
            style = MaterialIcon.Style.Sharp
        )
        val RuleFolder = MaterialIcon(
            name = "rule_folder",
            style = MaterialIcon.Style.Sharp
        )
        val RunCircle = MaterialIcon(
            name = "run_circle",
            style = MaterialIcon.Style.Sharp
        )
        val RunningWithErrors = MaterialIcon(
            name = "running_with_errors",
            style = MaterialIcon.Style.Sharp
        )
        val RvHookup = MaterialIcon(
            name = "rv_hookup",
            style = MaterialIcon.Style.Sharp
        )
        val SafetyCheck = MaterialIcon(
            name = "safety_check",
            style = MaterialIcon.Style.Sharp
        )
        val SafetyDivider = MaterialIcon(
            name = "safety_divider",
            style = MaterialIcon.Style.Sharp
        )
        val Sailing = MaterialIcon(
            name = "sailing",
            style = MaterialIcon.Style.Sharp
        )
        val Sanitizer = MaterialIcon(
            name = "sanitizer",
            style = MaterialIcon.Style.Sharp
        )
        val Satellite = MaterialIcon(
            name = "satellite",
            style = MaterialIcon.Style.Sharp
        )
        val SatelliteAlt = MaterialIcon(
            name = "satellite_alt",
            style = MaterialIcon.Style.Sharp
        )
        val Save = MaterialIcon(
            name = "save",
            style = MaterialIcon.Style.Sharp
        )
        val SaveAlt = MaterialIcon(
            name = "save_alt",
            style = MaterialIcon.Style.Sharp
        )
        val SaveAs = MaterialIcon(
            name = "save_as",
            style = MaterialIcon.Style.Sharp
        )
        val SavedSearch = MaterialIcon(
            name = "saved_search",
            style = MaterialIcon.Style.Sharp
        )
        val Savings = MaterialIcon(
            name = "savings",
            style = MaterialIcon.Style.Sharp
        )
        val Scale = MaterialIcon(
            name = "scale",
            style = MaterialIcon.Style.Sharp
        )
        val Scanner = MaterialIcon(
            name = "scanner",
            style = MaterialIcon.Style.Sharp
        )
        val ScatterPlot = MaterialIcon(
            name = "scatter_plot",
            style = MaterialIcon.Style.Sharp
        )
        val Schedule = MaterialIcon(
            name = "schedule",
            style = MaterialIcon.Style.Sharp
        )
        val ScheduleSend = MaterialIcon(
            name = "schedule_send",
            style = MaterialIcon.Style.Sharp
        )
        val Schema = MaterialIcon(
            name = "schema",
            style = MaterialIcon.Style.Sharp
        )
        val School = MaterialIcon(
            name = "school",
            style = MaterialIcon.Style.Sharp
        )
        val Science = MaterialIcon(
            name = "science",
            style = MaterialIcon.Style.Sharp
        )
        val Score = MaterialIcon(
            name = "score",
            style = MaterialIcon.Style.Sharp
        )
        val Scoreboard = MaterialIcon(
            name = "scoreboard",
            style = MaterialIcon.Style.Sharp
        )
        val ScreenLockLandscape = MaterialIcon(
            name = "screen_lock_landscape",
            style = MaterialIcon.Style.Sharp
        )
        val ScreenLockPortrait = MaterialIcon(
            name = "screen_lock_portrait",
            style = MaterialIcon.Style.Sharp
        )
        val ScreenLockRotation = MaterialIcon(
            name = "screen_lock_rotation",
            style = MaterialIcon.Style.Sharp
        )
        val ScreenRotation = MaterialIcon(
            name = "screen_rotation",
            style = MaterialIcon.Style.Sharp
        )
        val ScreenRotationAlt = MaterialIcon(
            name = "screen_rotation_alt",
            style = MaterialIcon.Style.Sharp
        )
        val ScreenSearchDesktop = MaterialIcon(
            name = "screen_search_desktop",
            style = MaterialIcon.Style.Sharp
        )
        val ScreenShare = MaterialIcon(
            name = "screen_share",
            style = MaterialIcon.Style.Sharp
        )
        val Screenshot = MaterialIcon(
            name = "screenshot",
            style = MaterialIcon.Style.Sharp
        )
        val ScreenshotMonitor = MaterialIcon(
            name = "screenshot_monitor",
            style = MaterialIcon.Style.Sharp
        )
        val ScubaDiving = MaterialIcon(
            name = "scuba_diving",
            style = MaterialIcon.Style.Sharp
        )
        val Sd = MaterialIcon(
            name = "sd",
            style = MaterialIcon.Style.Sharp
        )
        val SdCard = MaterialIcon(
            name = "sd_card",
            style = MaterialIcon.Style.Sharp
        )
        val SdCardAlert = MaterialIcon(
            name = "sd_card_alert",
            style = MaterialIcon.Style.Sharp
        )
        val SdStorage = MaterialIcon(
            name = "sd_storage",
            style = MaterialIcon.Style.Sharp
        )
        val Search = MaterialIcon(
            name = "search",
            style = MaterialIcon.Style.Sharp
        )
        val SearchOff = MaterialIcon(
            name = "search_off",
            style = MaterialIcon.Style.Sharp
        )
        val Security = MaterialIcon(
            name = "security",
            style = MaterialIcon.Style.Sharp
        )
        val SecurityUpdate = MaterialIcon(
            name = "security_update",
            style = MaterialIcon.Style.Sharp
        )
        val SecurityUpdateGood = MaterialIcon(
            name = "security_update_good",
            style = MaterialIcon.Style.Sharp
        )
        val SecurityUpdateWarning = MaterialIcon(
            name = "security_update_warning",
            style = MaterialIcon.Style.Sharp
        )
        val Segment = MaterialIcon(
            name = "segment",
            style = MaterialIcon.Style.Sharp
        )
        val SelectAll = MaterialIcon(
            name = "select_all",
            style = MaterialIcon.Style.Sharp
        )
        val SelfImprovement = MaterialIcon(
            name = "self_improvement",
            style = MaterialIcon.Style.Sharp
        )
        val Sell = MaterialIcon(
            name = "sell",
            style = MaterialIcon.Style.Sharp
        )
        val Send = MaterialIcon(
            name = "send",
            style = MaterialIcon.Style.Sharp
        )
        val SendAndArchive = MaterialIcon(
            name = "send_and_archive",
            style = MaterialIcon.Style.Sharp
        )
        val SendTimeExtension = MaterialIcon(
            name = "send_time_extension",
            style = MaterialIcon.Style.Sharp
        )
        val SendToMobile = MaterialIcon(
            name = "send_to_mobile",
            style = MaterialIcon.Style.Sharp
        )
        val SensorDoor = MaterialIcon(
            name = "sensor_door",
            style = MaterialIcon.Style.Sharp
        )
        val SensorOccupied = MaterialIcon(
            name = "sensor_occupied",
            style = MaterialIcon.Style.Sharp
        )
        val SensorWindow = MaterialIcon(
            name = "sensor_window",
            style = MaterialIcon.Style.Sharp
        )
        val Sensors = MaterialIcon(
            name = "sensors",
            style = MaterialIcon.Style.Sharp
        )
        val SensorsOff = MaterialIcon(
            name = "sensors_off",
            style = MaterialIcon.Style.Sharp
        )
        val SentimentDissatisfied = MaterialIcon(
            name = "sentiment_dissatisfied",
            style = MaterialIcon.Style.Sharp
        )
        val SentimentNeutral = MaterialIcon(
            name = "sentiment_neutral",
            style = MaterialIcon.Style.Sharp
        )
        val SentimentSatisfied = MaterialIcon(
            name = "sentiment_satisfied",
            style = MaterialIcon.Style.Sharp
        )
        val SentimentSatisfiedAlt = MaterialIcon(
            name = "sentiment_satisfied_alt",
            style = MaterialIcon.Style.Sharp
        )
        val SentimentVeryDissatisfied = MaterialIcon(
            name = "sentiment_very_dissatisfied",
            style = MaterialIcon.Style.Sharp
        )
        val SentimentVerySatisfied = MaterialIcon(
            name = "sentiment_very_satisfied",
            style = MaterialIcon.Style.Sharp
        )
        val SetMeal = MaterialIcon(
            name = "set_meal",
            style = MaterialIcon.Style.Sharp
        )
        val Settings = MaterialIcon(
            name = "settings",
            style = MaterialIcon.Style.Sharp
        )
        val SettingsAccessibility = MaterialIcon(
            name = "settings_accessibility",
            style = MaterialIcon.Style.Sharp
        )
        val SettingsApplications = MaterialIcon(
            name = "settings_applications",
            style = MaterialIcon.Style.Sharp
        )
        val SettingsBackupRestore = MaterialIcon(
            name = "settings_backup_restore",
            style = MaterialIcon.Style.Sharp
        )
        val SettingsBluetooth = MaterialIcon(
            name = "settings_bluetooth",
            style = MaterialIcon.Style.Sharp
        )
        val SettingsBrightness = MaterialIcon(
            name = "settings_brightness",
            style = MaterialIcon.Style.Sharp
        )
        val SettingsCell = MaterialIcon(
            name = "settings_cell",
            style = MaterialIcon.Style.Sharp
        )
        val SettingsEthernet = MaterialIcon(
            name = "settings_ethernet",
            style = MaterialIcon.Style.Sharp
        )
        val SettingsInputAntenna = MaterialIcon(
            name = "settings_input_antenna",
            style = MaterialIcon.Style.Sharp
        )
        val SettingsInputComponent = MaterialIcon(
            name = "settings_input_component",
            style = MaterialIcon.Style.Sharp
        )
        val SettingsInputComposite = MaterialIcon(
            name = "settings_input_composite",
            style = MaterialIcon.Style.Sharp
        )
        val SettingsInputHdmi = MaterialIcon(
            name = "settings_input_hdmi",
            style = MaterialIcon.Style.Sharp
        )
        val SettingsInputSvideo = MaterialIcon(
            name = "settings_input_svideo",
            style = MaterialIcon.Style.Sharp
        )
        val SettingsOverscan = MaterialIcon(
            name = "settings_overscan",
            style = MaterialIcon.Style.Sharp
        )
        val SettingsPhone = MaterialIcon(
            name = "settings_phone",
            style = MaterialIcon.Style.Sharp
        )
        val SettingsPower = MaterialIcon(
            name = "settings_power",
            style = MaterialIcon.Style.Sharp
        )
        val SettingsRemote = MaterialIcon(
            name = "settings_remote",
            style = MaterialIcon.Style.Sharp
        )
        val SettingsSuggest = MaterialIcon(
            name = "settings_suggest",
            style = MaterialIcon.Style.Sharp
        )
        val SettingsSystemDaydream = MaterialIcon(
            name = "settings_system_daydream",
            style = MaterialIcon.Style.Sharp
        )
        val SettingsVoice = MaterialIcon(
            name = "settings_voice",
            style = MaterialIcon.Style.Sharp
        )
        val SevereCold = MaterialIcon(
            name = "severe_cold",
            style = MaterialIcon.Style.Sharp
        )
        val ShapeLine = MaterialIcon(
            name = "shape_line",
            style = MaterialIcon.Style.Sharp
        )
        val Share = MaterialIcon(
            name = "share",
            style = MaterialIcon.Style.Sharp
        )
        val ShareLocation = MaterialIcon(
            name = "share_location",
            style = MaterialIcon.Style.Sharp
        )
        val Shield = MaterialIcon(
            name = "shield",
            style = MaterialIcon.Style.Sharp
        )
        val ShieldMoon = MaterialIcon(
            name = "shield_moon",
            style = MaterialIcon.Style.Sharp
        )
        val Shop = MaterialIcon(
            name = "shop",
            style = MaterialIcon.Style.Sharp
        )
        val Shop2 = MaterialIcon(
            name = "shop_2",
            style = MaterialIcon.Style.Sharp
        )
        val ShopTwo = MaterialIcon(
            name = "shop_two",
            style = MaterialIcon.Style.Sharp
        )
        val ShoppingBag = MaterialIcon(
            name = "shopping_bag",
            style = MaterialIcon.Style.Sharp
        )
        val ShoppingBasket = MaterialIcon(
            name = "shopping_basket",
            style = MaterialIcon.Style.Sharp
        )
        val ShoppingCart = MaterialIcon(
            name = "shopping_cart",
            style = MaterialIcon.Style.Sharp
        )
        val ShoppingCartCheckout = MaterialIcon(
            name = "shopping_cart_checkout",
            style = MaterialIcon.Style.Sharp
        )
        val ShortText = MaterialIcon(
            name = "short_text",
            style = MaterialIcon.Style.Sharp
        )
        val Shortcut = MaterialIcon(
            name = "shortcut",
            style = MaterialIcon.Style.Sharp
        )
        val ShowChart = MaterialIcon(
            name = "show_chart",
            style = MaterialIcon.Style.Sharp
        )
        val Shower = MaterialIcon(
            name = "shower",
            style = MaterialIcon.Style.Sharp
        )
        val Shuffle = MaterialIcon(
            name = "shuffle",
            style = MaterialIcon.Style.Sharp
        )
        val ShuffleOn = MaterialIcon(
            name = "shuffle_on",
            style = MaterialIcon.Style.Sharp
        )
        val ShutterSpeed = MaterialIcon(
            name = "shutter_speed",
            style = MaterialIcon.Style.Sharp
        )
        val Sick = MaterialIcon(
            name = "sick",
            style = MaterialIcon.Style.Sharp
        )
        val SignLanguage = MaterialIcon(
            name = "sign_language",
            style = MaterialIcon.Style.Sharp
        )
        val SignalCellular0Bar = MaterialIcon(
            name = "signal_cellular_0_bar",
            style = MaterialIcon.Style.Sharp
        )
        val SignalCellular4Bar = MaterialIcon(
            name = "signal_cellular_4_bar",
            style = MaterialIcon.Style.Sharp
        )
        val SignalCellularAlt = MaterialIcon(
            name = "signal_cellular_alt",
            style = MaterialIcon.Style.Sharp
        )
        val SignalCellularAlt1Bar = MaterialIcon(
            name = "signal_cellular_alt_1_bar",
            style = MaterialIcon.Style.Sharp
        )
        val SignalCellularAlt2Bar = MaterialIcon(
            name = "signal_cellular_alt_2_bar",
            style = MaterialIcon.Style.Sharp
        )
        val SignalCellularConnectedNoInternet0Bar = MaterialIcon(
            name = "signal_cellular_connected_no_internet_0_bar",
            style = MaterialIcon.Style.Sharp
        )
        val SignalCellularConnectedNoInternet4Bar = MaterialIcon(
            name = "signal_cellular_connected_no_internet_4_bar",
            style = MaterialIcon.Style.Sharp
        )
        val SignalCellularNoSim = MaterialIcon(
            name = "signal_cellular_no_sim",
            style = MaterialIcon.Style.Sharp
        )
        val SignalCellularNodata = MaterialIcon(
            name = "signal_cellular_nodata",
            style = MaterialIcon.Style.Sharp
        )
        val SignalCellularNull = MaterialIcon(
            name = "signal_cellular_null",
            style = MaterialIcon.Style.Sharp
        )
        val SignalCellularOff = MaterialIcon(
            name = "signal_cellular_off",
            style = MaterialIcon.Style.Sharp
        )
        val SignalWifi0Bar = MaterialIcon(
            name = "signal_wifi_0_bar",
            style = MaterialIcon.Style.Sharp
        )
        val SignalWifi4Bar = MaterialIcon(
            name = "signal_wifi_4_bar",
            style = MaterialIcon.Style.Sharp
        )
        val SignalWifi4BarLock = MaterialIcon(
            name = "signal_wifi_4_bar_lock",
            style = MaterialIcon.Style.Sharp
        )
        val SignalWifiBad = MaterialIcon(
            name = "signal_wifi_bad",
            style = MaterialIcon.Style.Sharp
        )
        val SignalWifiConnectedNoInternet4 = MaterialIcon(
            name = "signal_wifi_connected_no_internet_4",
            style = MaterialIcon.Style.Sharp
        )
        val SignalWifiOff = MaterialIcon(
            name = "signal_wifi_off",
            style = MaterialIcon.Style.Sharp
        )
        val SignalWifiStatusbar4Bar = MaterialIcon(
            name = "signal_wifi_statusbar_4_bar",
            style = MaterialIcon.Style.Sharp
        )
        val SignalWifiStatusbarConnectedNoInternet4 = MaterialIcon(
            name = "signal_wifi_statusbar_connected_no_internet_4",
            style = MaterialIcon.Style.Sharp
        )
        val SignalWifiStatusbarNull = MaterialIcon(
            name = "signal_wifi_statusbar_null",
            style = MaterialIcon.Style.Sharp
        )
        val Signpost = MaterialIcon(
            name = "signpost",
            style = MaterialIcon.Style.Sharp
        )
        val SimCard = MaterialIcon(
            name = "sim_card",
            style = MaterialIcon.Style.Sharp
        )
        val SimCardAlert = MaterialIcon(
            name = "sim_card_alert",
            style = MaterialIcon.Style.Sharp
        )
        val SimCardDownload = MaterialIcon(
            name = "sim_card_download",
            style = MaterialIcon.Style.Sharp
        )
        val SingleBed = MaterialIcon(
            name = "single_bed",
            style = MaterialIcon.Style.Sharp
        )
        val Sip = MaterialIcon(
            name = "sip",
            style = MaterialIcon.Style.Sharp
        )
        val Skateboarding = MaterialIcon(
            name = "skateboarding",
            style = MaterialIcon.Style.Sharp
        )
        val SkipNext = MaterialIcon(
            name = "skip_next",
            style = MaterialIcon.Style.Sharp
        )
        val SkipPrevious = MaterialIcon(
            name = "skip_previous",
            style = MaterialIcon.Style.Sharp
        )
        val Sledding = MaterialIcon(
            name = "sledding",
            style = MaterialIcon.Style.Sharp
        )
        val Slideshow = MaterialIcon(
            name = "slideshow",
            style = MaterialIcon.Style.Sharp
        )
        val SlowMotionVideo = MaterialIcon(
            name = "slow_motion_video",
            style = MaterialIcon.Style.Sharp
        )
        val SmartButton = MaterialIcon(
            name = "smart_button",
            style = MaterialIcon.Style.Sharp
        )
        val SmartDisplay = MaterialIcon(
            name = "smart_display",
            style = MaterialIcon.Style.Sharp
        )
        val SmartScreen = MaterialIcon(
            name = "smart_screen",
            style = MaterialIcon.Style.Sharp
        )
        val SmartToy = MaterialIcon(
            name = "smart_toy",
            style = MaterialIcon.Style.Sharp
        )
        val Smartphone = MaterialIcon(
            name = "smartphone",
            style = MaterialIcon.Style.Sharp
        )
        val SmokeFree = MaterialIcon(
            name = "smoke_free",
            style = MaterialIcon.Style.Sharp
        )
        val SmokingRooms = MaterialIcon(
            name = "smoking_rooms",
            style = MaterialIcon.Style.Sharp
        )
        val Sms = MaterialIcon(
            name = "sms",
            style = MaterialIcon.Style.Sharp
        )
        val SmsFailed = MaterialIcon(
            name = "sms_failed",
            style = MaterialIcon.Style.Sharp
        )
        val SnippetFolder = MaterialIcon(
            name = "snippet_folder",
            style = MaterialIcon.Style.Sharp
        )
        val Snooze = MaterialIcon(
            name = "snooze",
            style = MaterialIcon.Style.Sharp
        )
        val Snowboarding = MaterialIcon(
            name = "snowboarding",
            style = MaterialIcon.Style.Sharp
        )
        val Snowmobile = MaterialIcon(
            name = "snowmobile",
            style = MaterialIcon.Style.Sharp
        )
        val Snowshoeing = MaterialIcon(
            name = "snowshoeing",
            style = MaterialIcon.Style.Sharp
        )
        val Soap = MaterialIcon(
            name = "soap",
            style = MaterialIcon.Style.Sharp
        )
        val SocialDistance = MaterialIcon(
            name = "social_distance",
            style = MaterialIcon.Style.Sharp
        )
        val SolarPower = MaterialIcon(
            name = "solar_power",
            style = MaterialIcon.Style.Sharp
        )
        val Sort = MaterialIcon(
            name = "sort",
            style = MaterialIcon.Style.Sharp
        )
        val SortByAlpha = MaterialIcon(
            name = "sort_by_alpha",
            style = MaterialIcon.Style.Sharp
        )
        val Sos = MaterialIcon(
            name = "sos",
            style = MaterialIcon.Style.Sharp
        )
        val SoupKitchen = MaterialIcon(
            name = "soup_kitchen",
            style = MaterialIcon.Style.Sharp
        )
        val Source = MaterialIcon(
            name = "source",
            style = MaterialIcon.Style.Sharp
        )
        val South = MaterialIcon(
            name = "south",
            style = MaterialIcon.Style.Sharp
        )
        val SouthAmerica = MaterialIcon(
            name = "south_america",
            style = MaterialIcon.Style.Sharp
        )
        val SouthEast = MaterialIcon(
            name = "south_east",
            style = MaterialIcon.Style.Sharp
        )
        val SouthWest = MaterialIcon(
            name = "south_west",
            style = MaterialIcon.Style.Sharp
        )
        val Spa = MaterialIcon(
            name = "spa",
            style = MaterialIcon.Style.Sharp
        )
        val SpaceBar = MaterialIcon(
            name = "space_bar",
            style = MaterialIcon.Style.Sharp
        )
        val SpaceDashboard = MaterialIcon(
            name = "space_dashboard",
            style = MaterialIcon.Style.Sharp
        )
        val SpatialAudio = MaterialIcon(
            name = "spatial_audio",
            style = MaterialIcon.Style.Sharp
        )
        val SpatialAudioOff = MaterialIcon(
            name = "spatial_audio_off",
            style = MaterialIcon.Style.Sharp
        )
        val SpatialTracking = MaterialIcon(
            name = "spatial_tracking",
            style = MaterialIcon.Style.Sharp
        )
        val Speaker = MaterialIcon(
            name = "speaker",
            style = MaterialIcon.Style.Sharp
        )
        val SpeakerGroup = MaterialIcon(
            name = "speaker_group",
            style = MaterialIcon.Style.Sharp
        )
        val SpeakerNotes = MaterialIcon(
            name = "speaker_notes",
            style = MaterialIcon.Style.Sharp
        )
        val SpeakerNotesOff = MaterialIcon(
            name = "speaker_notes_off",
            style = MaterialIcon.Style.Sharp
        )
        val SpeakerPhone = MaterialIcon(
            name = "speaker_phone",
            style = MaterialIcon.Style.Sharp
        )
        val Speed = MaterialIcon(
            name = "speed",
            style = MaterialIcon.Style.Sharp
        )
        val Spellcheck = MaterialIcon(
            name = "spellcheck",
            style = MaterialIcon.Style.Sharp
        )
        val Splitscreen = MaterialIcon(
            name = "splitscreen",
            style = MaterialIcon.Style.Sharp
        )
        val Spoke = MaterialIcon(
            name = "spoke",
            style = MaterialIcon.Style.Sharp
        )
        val Sports = MaterialIcon(
            name = "sports",
            style = MaterialIcon.Style.Sharp
        )
        val SportsBar = MaterialIcon(
            name = "sports_bar",
            style = MaterialIcon.Style.Sharp
        )
        val SportsBaseball = MaterialIcon(
            name = "sports_baseball",
            style = MaterialIcon.Style.Sharp
        )
        val SportsBasketball = MaterialIcon(
            name = "sports_basketball",
            style = MaterialIcon.Style.Sharp
        )
        val SportsCricket = MaterialIcon(
            name = "sports_cricket",
            style = MaterialIcon.Style.Sharp
        )
        val SportsEsports = MaterialIcon(
            name = "sports_esports",
            style = MaterialIcon.Style.Sharp
        )
        val SportsFootball = MaterialIcon(
            name = "sports_football",
            style = MaterialIcon.Style.Sharp
        )
        val SportsGolf = MaterialIcon(
            name = "sports_golf",
            style = MaterialIcon.Style.Sharp
        )
        val SportsGymnastics = MaterialIcon(
            name = "sports_gymnastics",
            style = MaterialIcon.Style.Sharp
        )
        val SportsHandball = MaterialIcon(
            name = "sports_handball",
            style = MaterialIcon.Style.Sharp
        )
        val SportsHockey = MaterialIcon(
            name = "sports_hockey",
            style = MaterialIcon.Style.Sharp
        )
        val SportsKabaddi = MaterialIcon(
            name = "sports_kabaddi",
            style = MaterialIcon.Style.Sharp
        )
        val SportsMartialArts = MaterialIcon(
            name = "sports_martial_arts",
            style = MaterialIcon.Style.Sharp
        )
        val SportsMma = MaterialIcon(
            name = "sports_mma",
            style = MaterialIcon.Style.Sharp
        )
        val SportsMotorsports = MaterialIcon(
            name = "sports_motorsports",
            style = MaterialIcon.Style.Sharp
        )
        val SportsRugby = MaterialIcon(
            name = "sports_rugby",
            style = MaterialIcon.Style.Sharp
        )
        val SportsScore = MaterialIcon(
            name = "sports_score",
            style = MaterialIcon.Style.Sharp
        )
        val SportsSoccer = MaterialIcon(
            name = "sports_soccer",
            style = MaterialIcon.Style.Sharp
        )
        val SportsTennis = MaterialIcon(
            name = "sports_tennis",
            style = MaterialIcon.Style.Sharp
        )
        val SportsVolleyball = MaterialIcon(
            name = "sports_volleyball",
            style = MaterialIcon.Style.Sharp
        )
        val Square = MaterialIcon(
            name = "square",
            style = MaterialIcon.Style.Sharp
        )
        val SquareFoot = MaterialIcon(
            name = "square_foot",
            style = MaterialIcon.Style.Sharp
        )
        val SsidChart = MaterialIcon(
            name = "ssid_chart",
            style = MaterialIcon.Style.Sharp
        )
        val StackedBarChart = MaterialIcon(
            name = "stacked_bar_chart",
            style = MaterialIcon.Style.Sharp
        )
        val StackedLineChart = MaterialIcon(
            name = "stacked_line_chart",
            style = MaterialIcon.Style.Sharp
        )
        val Stadium = MaterialIcon(
            name = "stadium",
            style = MaterialIcon.Style.Sharp
        )
        val Stairs = MaterialIcon(
            name = "stairs",
            style = MaterialIcon.Style.Sharp
        )
        val Star = MaterialIcon(
            name = "star",
            style = MaterialIcon.Style.Sharp
        )
        val StarBorder = MaterialIcon(
            name = "star_border",
            style = MaterialIcon.Style.Sharp
        )
        val StarBorderPurple500 = MaterialIcon(
            name = "star_border_purple500",
            style = MaterialIcon.Style.Sharp
        )
        val StarHalf = MaterialIcon(
            name = "star_half",
            style = MaterialIcon.Style.Sharp
        )
        val StarOutline = MaterialIcon(
            name = "star_outline",
            style = MaterialIcon.Style.Sharp
        )
        val StarPurple500 = MaterialIcon(
            name = "star_purple500",
            style = MaterialIcon.Style.Sharp
        )
        val StarRate = MaterialIcon(
            name = "star_rate",
            style = MaterialIcon.Style.Sharp
        )
        val Stars = MaterialIcon(
            name = "stars",
            style = MaterialIcon.Style.Sharp
        )
        val Start = MaterialIcon(
            name = "start",
            style = MaterialIcon.Style.Sharp
        )
        val StayCurrentLandscape = MaterialIcon(
            name = "stay_current_landscape",
            style = MaterialIcon.Style.Sharp
        )
        val StayCurrentPortrait = MaterialIcon(
            name = "stay_current_portrait",
            style = MaterialIcon.Style.Sharp
        )
        val StayPrimaryLandscape = MaterialIcon(
            name = "stay_primary_landscape",
            style = MaterialIcon.Style.Sharp
        )
        val StayPrimaryPortrait = MaterialIcon(
            name = "stay_primary_portrait",
            style = MaterialIcon.Style.Sharp
        )
        val StickyNote2 = MaterialIcon(
            name = "sticky_note_2",
            style = MaterialIcon.Style.Sharp
        )
        val Stop = MaterialIcon(
            name = "stop",
            style = MaterialIcon.Style.Sharp
        )
        val StopCircle = MaterialIcon(
            name = "stop_circle",
            style = MaterialIcon.Style.Sharp
        )
        val StopScreenShare = MaterialIcon(
            name = "stop_screen_share",
            style = MaterialIcon.Style.Sharp
        )
        val Storage = MaterialIcon(
            name = "storage",
            style = MaterialIcon.Style.Sharp
        )
        val Store = MaterialIcon(
            name = "store",
            style = MaterialIcon.Style.Sharp
        )
        val StoreMallDirectory = MaterialIcon(
            name = "store_mall_directory",
            style = MaterialIcon.Style.Sharp
        )
        val Storefront = MaterialIcon(
            name = "storefront",
            style = MaterialIcon.Style.Sharp
        )
        val Storm = MaterialIcon(
            name = "storm",
            style = MaterialIcon.Style.Sharp
        )
        val Straight = MaterialIcon(
            name = "straight",
            style = MaterialIcon.Style.Sharp
        )
        val Straighten = MaterialIcon(
            name = "straighten",
            style = MaterialIcon.Style.Sharp
        )
        val Stream = MaterialIcon(
            name = "stream",
            style = MaterialIcon.Style.Sharp
        )
        val Streetview = MaterialIcon(
            name = "streetview",
            style = MaterialIcon.Style.Sharp
        )
        val StrikethroughS = MaterialIcon(
            name = "strikethrough_s",
            style = MaterialIcon.Style.Sharp
        )
        val Stroller = MaterialIcon(
            name = "stroller",
            style = MaterialIcon.Style.Sharp
        )
        val Style = MaterialIcon(
            name = "style",
            style = MaterialIcon.Style.Sharp
        )
        val SubdirectoryArrowLeft = MaterialIcon(
            name = "subdirectory_arrow_left",
            style = MaterialIcon.Style.Sharp
        )
        val SubdirectoryArrowRight = MaterialIcon(
            name = "subdirectory_arrow_right",
            style = MaterialIcon.Style.Sharp
        )
        val Subject = MaterialIcon(
            name = "subject",
            style = MaterialIcon.Style.Sharp
        )
        val Subscript = MaterialIcon(
            name = "subscript",
            style = MaterialIcon.Style.Sharp
        )
        val Subscriptions = MaterialIcon(
            name = "subscriptions",
            style = MaterialIcon.Style.Sharp
        )
        val Subtitles = MaterialIcon(
            name = "subtitles",
            style = MaterialIcon.Style.Sharp
        )
        val SubtitlesOff = MaterialIcon(
            name = "subtitles_off",
            style = MaterialIcon.Style.Sharp
        )
        val Subway = MaterialIcon(
            name = "subway",
            style = MaterialIcon.Style.Sharp
        )
        val Summarize = MaterialIcon(
            name = "summarize",
            style = MaterialIcon.Style.Sharp
        )
        val Superscript = MaterialIcon(
            name = "superscript",
            style = MaterialIcon.Style.Sharp
        )
        val SupervisedUserCircle = MaterialIcon(
            name = "supervised_user_circle",
            style = MaterialIcon.Style.Sharp
        )
        val SupervisorAccount = MaterialIcon(
            name = "supervisor_account",
            style = MaterialIcon.Style.Sharp
        )
        val Support = MaterialIcon(
            name = "support",
            style = MaterialIcon.Style.Sharp
        )
        val SupportAgent = MaterialIcon(
            name = "support_agent",
            style = MaterialIcon.Style.Sharp
        )
        val Surfing = MaterialIcon(
            name = "surfing",
            style = MaterialIcon.Style.Sharp
        )
        val SurroundSound = MaterialIcon(
            name = "surround_sound",
            style = MaterialIcon.Style.Sharp
        )
        val SwapCalls = MaterialIcon(
            name = "swap_calls",
            style = MaterialIcon.Style.Sharp
        )
        val SwapHoriz = MaterialIcon(
            name = "swap_horiz",
            style = MaterialIcon.Style.Sharp
        )
        val SwapHorizontalCircle = MaterialIcon(
            name = "swap_horizontal_circle",
            style = MaterialIcon.Style.Sharp
        )
        val SwapVert = MaterialIcon(
            name = "swap_vert",
            style = MaterialIcon.Style.Sharp
        )
        val SwapVerticalCircle = MaterialIcon(
            name = "swap_vertical_circle",
            style = MaterialIcon.Style.Sharp
        )
        val Swipe = MaterialIcon(
            name = "swipe",
            style = MaterialIcon.Style.Sharp
        )
        val SwipeDown = MaterialIcon(
            name = "swipe_down",
            style = MaterialIcon.Style.Sharp
        )
        val SwipeDownAlt = MaterialIcon(
            name = "swipe_down_alt",
            style = MaterialIcon.Style.Sharp
        )
        val SwipeLeft = MaterialIcon(
            name = "swipe_left",
            style = MaterialIcon.Style.Sharp
        )
        val SwipeLeftAlt = MaterialIcon(
            name = "swipe_left_alt",
            style = MaterialIcon.Style.Sharp
        )
        val SwipeRight = MaterialIcon(
            name = "swipe_right",
            style = MaterialIcon.Style.Sharp
        )
        val SwipeRightAlt = MaterialIcon(
            name = "swipe_right_alt",
            style = MaterialIcon.Style.Sharp
        )
        val SwipeUp = MaterialIcon(
            name = "swipe_up",
            style = MaterialIcon.Style.Sharp
        )
        val SwipeUpAlt = MaterialIcon(
            name = "swipe_up_alt",
            style = MaterialIcon.Style.Sharp
        )
        val SwipeVertical = MaterialIcon(
            name = "swipe_vertical",
            style = MaterialIcon.Style.Sharp
        )
        val SwitchAccessShortcut = MaterialIcon(
            name = "switch_access_shortcut",
            style = MaterialIcon.Style.Sharp
        )
        val SwitchAccessShortcutAdd = MaterialIcon(
            name = "switch_access_shortcut_add",
            style = MaterialIcon.Style.Sharp
        )
        val SwitchAccount = MaterialIcon(
            name = "switch_account",
            style = MaterialIcon.Style.Sharp
        )
        val SwitchCamera = MaterialIcon(
            name = "switch_camera",
            style = MaterialIcon.Style.Sharp
        )
        val SwitchLeft = MaterialIcon(
            name = "switch_left",
            style = MaterialIcon.Style.Sharp
        )
        val SwitchRight = MaterialIcon(
            name = "switch_right",
            style = MaterialIcon.Style.Sharp
        )
        val SwitchVideo = MaterialIcon(
            name = "switch_video",
            style = MaterialIcon.Style.Sharp
        )
        val Synagogue = MaterialIcon(
            name = "synagogue",
            style = MaterialIcon.Style.Sharp
        )
        val Sync = MaterialIcon(
            name = "sync",
            style = MaterialIcon.Style.Sharp
        )
        val SyncAlt = MaterialIcon(
            name = "sync_alt",
            style = MaterialIcon.Style.Sharp
        )
        val SyncDisabled = MaterialIcon(
            name = "sync_disabled",
            style = MaterialIcon.Style.Sharp
        )
        val SyncLock = MaterialIcon(
            name = "sync_lock",
            style = MaterialIcon.Style.Sharp
        )
        val SyncProblem = MaterialIcon(
            name = "sync_problem",
            style = MaterialIcon.Style.Sharp
        )
        val SystemSecurityUpdate = MaterialIcon(
            name = "system_security_update",
            style = MaterialIcon.Style.Sharp
        )
        val SystemSecurityUpdateGood = MaterialIcon(
            name = "system_security_update_good",
            style = MaterialIcon.Style.Sharp
        )
        val SystemSecurityUpdateWarning = MaterialIcon(
            name = "system_security_update_warning",
            style = MaterialIcon.Style.Sharp
        )
        val SystemUpdate = MaterialIcon(
            name = "system_update",
            style = MaterialIcon.Style.Sharp
        )
        val SystemUpdateAlt = MaterialIcon(
            name = "system_update_alt",
            style = MaterialIcon.Style.Sharp
        )
        val Tab = MaterialIcon(
            name = "tab",
            style = MaterialIcon.Style.Sharp
        )
        val TabUnselected = MaterialIcon(
            name = "tab_unselected",
            style = MaterialIcon.Style.Sharp
        )
        val TableBar = MaterialIcon(
            name = "table_bar",
            style = MaterialIcon.Style.Sharp
        )
        val TableChart = MaterialIcon(
            name = "table_chart",
            style = MaterialIcon.Style.Sharp
        )
        val TableRestaurant = MaterialIcon(
            name = "table_restaurant",
            style = MaterialIcon.Style.Sharp
        )
        val TableRows = MaterialIcon(
            name = "table_rows",
            style = MaterialIcon.Style.Sharp
        )
        val TableView = MaterialIcon(
            name = "table_view",
            style = MaterialIcon.Style.Sharp
        )
        val Tablet = MaterialIcon(
            name = "tablet",
            style = MaterialIcon.Style.Sharp
        )
        val TabletAndroid = MaterialIcon(
            name = "tablet_android",
            style = MaterialIcon.Style.Sharp
        )
        val TabletMac = MaterialIcon(
            name = "tablet_mac",
            style = MaterialIcon.Style.Sharp
        )
        val Tag = MaterialIcon(
            name = "tag",
            style = MaterialIcon.Style.Sharp
        )
        val TagFaces = MaterialIcon(
            name = "tag_faces",
            style = MaterialIcon.Style.Sharp
        )
        val TakeoutDining = MaterialIcon(
            name = "takeout_dining",
            style = MaterialIcon.Style.Sharp
        )
        val TapAndPlay = MaterialIcon(
            name = "tap_and_play",
            style = MaterialIcon.Style.Sharp
        )
        val Tapas = MaterialIcon(
            name = "tapas",
            style = MaterialIcon.Style.Sharp
        )
        val Task = MaterialIcon(
            name = "task",
            style = MaterialIcon.Style.Sharp
        )
        val TaskAlt = MaterialIcon(
            name = "task_alt",
            style = MaterialIcon.Style.Sharp
        )
        val TaxiAlert = MaterialIcon(
            name = "taxi_alert",
            style = MaterialIcon.Style.Sharp
        )
        val TempleBuddhist = MaterialIcon(
            name = "temple_buddhist",
            style = MaterialIcon.Style.Sharp
        )
        val TempleHindu = MaterialIcon(
            name = "temple_hindu",
            style = MaterialIcon.Style.Sharp
        )
        val Terminal = MaterialIcon(
            name = "terminal",
            style = MaterialIcon.Style.Sharp
        )
        val Terrain = MaterialIcon(
            name = "terrain",
            style = MaterialIcon.Style.Sharp
        )
        val TextDecrease = MaterialIcon(
            name = "text_decrease",
            style = MaterialIcon.Style.Sharp
        )
        val TextFields = MaterialIcon(
            name = "text_fields",
            style = MaterialIcon.Style.Sharp
        )
        val TextFormat = MaterialIcon(
            name = "text_format",
            style = MaterialIcon.Style.Sharp
        )
        val TextIncrease = MaterialIcon(
            name = "text_increase",
            style = MaterialIcon.Style.Sharp
        )
        val TextRotateUp = MaterialIcon(
            name = "text_rotate_up",
            style = MaterialIcon.Style.Sharp
        )
        val TextRotateVertical = MaterialIcon(
            name = "text_rotate_vertical",
            style = MaterialIcon.Style.Sharp
        )
        val TextRotationAngledown = MaterialIcon(
            name = "text_rotation_angledown",
            style = MaterialIcon.Style.Sharp
        )
        val TextRotationAngleup = MaterialIcon(
            name = "text_rotation_angleup",
            style = MaterialIcon.Style.Sharp
        )
        val TextRotationDown = MaterialIcon(
            name = "text_rotation_down",
            style = MaterialIcon.Style.Sharp
        )
        val TextRotationNone = MaterialIcon(
            name = "text_rotation_none",
            style = MaterialIcon.Style.Sharp
        )
        val TextSnippet = MaterialIcon(
            name = "text_snippet",
            style = MaterialIcon.Style.Sharp
        )
        val Textsms = MaterialIcon(
            name = "textsms",
            style = MaterialIcon.Style.Sharp
        )
        val Texture = MaterialIcon(
            name = "texture",
            style = MaterialIcon.Style.Sharp
        )
        val TheaterComedy = MaterialIcon(
            name = "theater_comedy",
            style = MaterialIcon.Style.Sharp
        )
        val Theaters = MaterialIcon(
            name = "theaters",
            style = MaterialIcon.Style.Sharp
        )
        val Thermostat = MaterialIcon(
            name = "thermostat",
            style = MaterialIcon.Style.Sharp
        )
        val ThermostatAuto = MaterialIcon(
            name = "thermostat_auto",
            style = MaterialIcon.Style.Sharp
        )
        val ThumbDown = MaterialIcon(
            name = "thumb_down",
            style = MaterialIcon.Style.Sharp
        )
        val ThumbDownAlt = MaterialIcon(
            name = "thumb_down_alt",
            style = MaterialIcon.Style.Sharp
        )
        val ThumbDownOffAlt = MaterialIcon(
            name = "thumb_down_off_alt",
            style = MaterialIcon.Style.Sharp
        )
        val ThumbUp = MaterialIcon(
            name = "thumb_up",
            style = MaterialIcon.Style.Sharp
        )
        val ThumbUpAlt = MaterialIcon(
            name = "thumb_up_alt",
            style = MaterialIcon.Style.Sharp
        )
        val ThumbUpOffAlt = MaterialIcon(
            name = "thumb_up_off_alt",
            style = MaterialIcon.Style.Sharp
        )
        val ThumbsUpDown = MaterialIcon(
            name = "thumbs_up_down",
            style = MaterialIcon.Style.Sharp
        )
        val Thunderstorm = MaterialIcon(
            name = "thunderstorm",
            style = MaterialIcon.Style.Sharp
        )
        val TimeToLeave = MaterialIcon(
            name = "time_to_leave",
            style = MaterialIcon.Style.Sharp
        )
        val Timelapse = MaterialIcon(
            name = "timelapse",
            style = MaterialIcon.Style.Sharp
        )
        val Timeline = MaterialIcon(
            name = "timeline",
            style = MaterialIcon.Style.Sharp
        )
        val Timer = MaterialIcon(
            name = "timer",
            style = MaterialIcon.Style.Sharp
        )
        val Timer10 = MaterialIcon(
            name = "timer_10",
            style = MaterialIcon.Style.Sharp
        )
        val Timer10Select = MaterialIcon(
            name = "timer_10_select",
            style = MaterialIcon.Style.Sharp
        )
        val Timer3 = MaterialIcon(
            name = "timer_3",
            style = MaterialIcon.Style.Sharp
        )
        val Timer3Select = MaterialIcon(
            name = "timer_3_select",
            style = MaterialIcon.Style.Sharp
        )
        val TimerOff = MaterialIcon(
            name = "timer_off",
            style = MaterialIcon.Style.Sharp
        )
        val TipsAndUpdates = MaterialIcon(
            name = "tips_and_updates",
            style = MaterialIcon.Style.Sharp
        )
        val TireRepair = MaterialIcon(
            name = "tire_repair",
            style = MaterialIcon.Style.Sharp
        )
        val Title = MaterialIcon(
            name = "title",
            style = MaterialIcon.Style.Sharp
        )
        val Toc = MaterialIcon(
            name = "toc",
            style = MaterialIcon.Style.Sharp
        )
        val Today = MaterialIcon(
            name = "today",
            style = MaterialIcon.Style.Sharp
        )
        val ToggleOff = MaterialIcon(
            name = "toggle_off",
            style = MaterialIcon.Style.Sharp
        )
        val ToggleOn = MaterialIcon(
            name = "toggle_on",
            style = MaterialIcon.Style.Sharp
        )
        val Token = MaterialIcon(
            name = "token",
            style = MaterialIcon.Style.Sharp
        )
        val Toll = MaterialIcon(
            name = "toll",
            style = MaterialIcon.Style.Sharp
        )
        val Tonality = MaterialIcon(
            name = "tonality",
            style = MaterialIcon.Style.Sharp
        )
        val Topic = MaterialIcon(
            name = "topic",
            style = MaterialIcon.Style.Sharp
        )
        val Tornado = MaterialIcon(
            name = "tornado",
            style = MaterialIcon.Style.Sharp
        )
        val TouchApp = MaterialIcon(
            name = "touch_app",
            style = MaterialIcon.Style.Sharp
        )
        val Tour = MaterialIcon(
            name = "tour",
            style = MaterialIcon.Style.Sharp
        )
        val Toys = MaterialIcon(
            name = "toys",
            style = MaterialIcon.Style.Sharp
        )
        val TrackChanges = MaterialIcon(
            name = "track_changes",
            style = MaterialIcon.Style.Sharp
        )
        val Traffic = MaterialIcon(
            name = "traffic",
            style = MaterialIcon.Style.Sharp
        )
        val Train = MaterialIcon(
            name = "train",
            style = MaterialIcon.Style.Sharp
        )
        val Tram = MaterialIcon(
            name = "tram",
            style = MaterialIcon.Style.Sharp
        )
        val Transcribe = MaterialIcon(
            name = "transcribe",
            style = MaterialIcon.Style.Sharp
        )
        val TransferWithinAStation = MaterialIcon(
            name = "transfer_within_a_station",
            style = MaterialIcon.Style.Sharp
        )
        val Transform = MaterialIcon(
            name = "transform",
            style = MaterialIcon.Style.Sharp
        )
        val Transgender = MaterialIcon(
            name = "transgender",
            style = MaterialIcon.Style.Sharp
        )
        val TransitEnterexit = MaterialIcon(
            name = "transit_enterexit",
            style = MaterialIcon.Style.Sharp
        )
        val Translate = MaterialIcon(
            name = "translate",
            style = MaterialIcon.Style.Sharp
        )
        val TravelExplore = MaterialIcon(
            name = "travel_explore",
            style = MaterialIcon.Style.Sharp
        )
        val TrendingDown = MaterialIcon(
            name = "trending_down",
            style = MaterialIcon.Style.Sharp
        )
        val TrendingFlat = MaterialIcon(
            name = "trending_flat",
            style = MaterialIcon.Style.Sharp
        )
        val TrendingUp = MaterialIcon(
            name = "trending_up",
            style = MaterialIcon.Style.Sharp
        )
        val TripOrigin = MaterialIcon(
            name = "trip_origin",
            style = MaterialIcon.Style.Sharp
        )
        val Troubleshoot = MaterialIcon(
            name = "troubleshoot",
            style = MaterialIcon.Style.Sharp
        )
        val Try = MaterialIcon(
            name = "try",
            style = MaterialIcon.Style.Sharp
        )
        val Tsunami = MaterialIcon(
            name = "tsunami",
            style = MaterialIcon.Style.Sharp
        )
        val Tty = MaterialIcon(
            name = "tty",
            style = MaterialIcon.Style.Sharp
        )
        val Tune = MaterialIcon(
            name = "tune",
            style = MaterialIcon.Style.Sharp
        )
        val Tungsten = MaterialIcon(
            name = "tungsten",
            style = MaterialIcon.Style.Sharp
        )
        val TurnLeft = MaterialIcon(
            name = "turn_left",
            style = MaterialIcon.Style.Sharp
        )
        val TurnRight = MaterialIcon(
            name = "turn_right",
            style = MaterialIcon.Style.Sharp
        )
        val TurnSharpLeft = MaterialIcon(
            name = "turn_sharp_left",
            style = MaterialIcon.Style.Sharp
        )
        val TurnSharpRight = MaterialIcon(
            name = "turn_sharp_right",
            style = MaterialIcon.Style.Sharp
        )
        val TurnSlightLeft = MaterialIcon(
            name = "turn_slight_left",
            style = MaterialIcon.Style.Sharp
        )
        val TurnSlightRight = MaterialIcon(
            name = "turn_slight_right",
            style = MaterialIcon.Style.Sharp
        )
        val TurnedIn = MaterialIcon(
            name = "turned_in",
            style = MaterialIcon.Style.Sharp
        )
        val TurnedInNot = MaterialIcon(
            name = "turned_in_not",
            style = MaterialIcon.Style.Sharp
        )
        val Tv = MaterialIcon(
            name = "tv",
            style = MaterialIcon.Style.Sharp
        )
        val TvOff = MaterialIcon(
            name = "tv_off",
            style = MaterialIcon.Style.Sharp
        )
        val TwoWheeler = MaterialIcon(
            name = "two_wheeler",
            style = MaterialIcon.Style.Sharp
        )
        val TypeSpecimen = MaterialIcon(
            name = "type_specimen",
            style = MaterialIcon.Style.Sharp
        )
        val UTurnLeft = MaterialIcon(
            name = "u_turn_left",
            style = MaterialIcon.Style.Sharp
        )
        val UTurnRight = MaterialIcon(
            name = "u_turn_right",
            style = MaterialIcon.Style.Sharp
        )
        val Umbrella = MaterialIcon(
            name = "umbrella",
            style = MaterialIcon.Style.Sharp
        )
        val Unarchive = MaterialIcon(
            name = "unarchive",
            style = MaterialIcon.Style.Sharp
        )
        val Undo = MaterialIcon(
            name = "undo",
            style = MaterialIcon.Style.Sharp
        )
        val UnfoldLess = MaterialIcon(
            name = "unfold_less",
            style = MaterialIcon.Style.Sharp
        )
        val UnfoldLessDouble = MaterialIcon(
            name = "unfold_less_double",
            style = MaterialIcon.Style.Sharp
        )
        val UnfoldMore = MaterialIcon(
            name = "unfold_more",
            style = MaterialIcon.Style.Sharp
        )
        val UnfoldMoreDouble = MaterialIcon(
            name = "unfold_more_double",
            style = MaterialIcon.Style.Sharp
        )
        val Unpublished = MaterialIcon(
            name = "unpublished",
            style = MaterialIcon.Style.Sharp
        )
        val Unsubscribe = MaterialIcon(
            name = "unsubscribe",
            style = MaterialIcon.Style.Sharp
        )
        val Upcoming = MaterialIcon(
            name = "upcoming",
            style = MaterialIcon.Style.Sharp
        )
        val Update = MaterialIcon(
            name = "update",
            style = MaterialIcon.Style.Sharp
        )
        val UpdateDisabled = MaterialIcon(
            name = "update_disabled",
            style = MaterialIcon.Style.Sharp
        )
        val Upgrade = MaterialIcon(
            name = "upgrade",
            style = MaterialIcon.Style.Sharp
        )
        val Upload = MaterialIcon(
            name = "upload",
            style = MaterialIcon.Style.Sharp
        )
        val UploadFile = MaterialIcon(
            name = "upload_file",
            style = MaterialIcon.Style.Sharp
        )
        val Usb = MaterialIcon(
            name = "usb",
            style = MaterialIcon.Style.Sharp
        )
        val UsbOff = MaterialIcon(
            name = "usb_off",
            style = MaterialIcon.Style.Sharp
        )
        val Vaccines = MaterialIcon(
            name = "vaccines",
            style = MaterialIcon.Style.Sharp
        )
        val VapeFree = MaterialIcon(
            name = "vape_free",
            style = MaterialIcon.Style.Sharp
        )
        val VapingRooms = MaterialIcon(
            name = "vaping_rooms",
            style = MaterialIcon.Style.Sharp
        )
        val Verified = MaterialIcon(
            name = "verified",
            style = MaterialIcon.Style.Sharp
        )
        val VerifiedUser = MaterialIcon(
            name = "verified_user",
            style = MaterialIcon.Style.Sharp
        )
        val VerticalAlignBottom = MaterialIcon(
            name = "vertical_align_bottom",
            style = MaterialIcon.Style.Sharp
        )
        val VerticalAlignCenter = MaterialIcon(
            name = "vertical_align_center",
            style = MaterialIcon.Style.Sharp
        )
        val VerticalAlignTop = MaterialIcon(
            name = "vertical_align_top",
            style = MaterialIcon.Style.Sharp
        )
        val VerticalDistribute = MaterialIcon(
            name = "vertical_distribute",
            style = MaterialIcon.Style.Sharp
        )
        val VerticalShades = MaterialIcon(
            name = "vertical_shades",
            style = MaterialIcon.Style.Sharp
        )
        val VerticalShadesClosed = MaterialIcon(
            name = "vertical_shades_closed",
            style = MaterialIcon.Style.Sharp
        )
        val VerticalSplit = MaterialIcon(
            name = "vertical_split",
            style = MaterialIcon.Style.Sharp
        )
        val Vibration = MaterialIcon(
            name = "vibration",
            style = MaterialIcon.Style.Sharp
        )
        val VideoCall = MaterialIcon(
            name = "video_call",
            style = MaterialIcon.Style.Sharp
        )
        val VideoCameraBack = MaterialIcon(
            name = "video_camera_back",
            style = MaterialIcon.Style.Sharp
        )
        val VideoCameraFront = MaterialIcon(
            name = "video_camera_front",
            style = MaterialIcon.Style.Sharp
        )
        val VideoChat = MaterialIcon(
            name = "video_chat",
            style = MaterialIcon.Style.Sharp
        )
        val VideoFile = MaterialIcon(
            name = "video_file",
            style = MaterialIcon.Style.Sharp
        )
        val VideoLabel = MaterialIcon(
            name = "video_label",
            style = MaterialIcon.Style.Sharp
        )
        val VideoLibrary = MaterialIcon(
            name = "video_library",
            style = MaterialIcon.Style.Sharp
        )
        val VideoSettings = MaterialIcon(
            name = "video_settings",
            style = MaterialIcon.Style.Sharp
        )
        val VideoStable = MaterialIcon(
            name = "video_stable",
            style = MaterialIcon.Style.Sharp
        )
        val Videocam = MaterialIcon(
            name = "videocam",
            style = MaterialIcon.Style.Sharp
        )
        val VideocamOff = MaterialIcon(
            name = "videocam_off",
            style = MaterialIcon.Style.Sharp
        )
        val VideogameAsset = MaterialIcon(
            name = "videogame_asset",
            style = MaterialIcon.Style.Sharp
        )
        val VideogameAssetOff = MaterialIcon(
            name = "videogame_asset_off",
            style = MaterialIcon.Style.Sharp
        )
        val ViewAgenda = MaterialIcon(
            name = "view_agenda",
            style = MaterialIcon.Style.Sharp
        )
        val ViewArray = MaterialIcon(
            name = "view_array",
            style = MaterialIcon.Style.Sharp
        )
        val ViewCarousel = MaterialIcon(
            name = "view_carousel",
            style = MaterialIcon.Style.Sharp
        )
        val ViewColumn = MaterialIcon(
            name = "view_column",
            style = MaterialIcon.Style.Sharp
        )
        val ViewComfy = MaterialIcon(
            name = "view_comfy",
            style = MaterialIcon.Style.Sharp
        )
        val ViewComfyAlt = MaterialIcon(
            name = "view_comfy_alt",
            style = MaterialIcon.Style.Sharp
        )
        val ViewCompact = MaterialIcon(
            name = "view_compact",
            style = MaterialIcon.Style.Sharp
        )
        val ViewCompactAlt = MaterialIcon(
            name = "view_compact_alt",
            style = MaterialIcon.Style.Sharp
        )
        val ViewCozy = MaterialIcon(
            name = "view_cozy",
            style = MaterialIcon.Style.Sharp
        )
        val ViewDay = MaterialIcon(
            name = "view_day",
            style = MaterialIcon.Style.Sharp
        )
        val ViewHeadline = MaterialIcon(
            name = "view_headline",
            style = MaterialIcon.Style.Sharp
        )
        val ViewInAr = MaterialIcon(
            name = "view_in_ar",
            style = MaterialIcon.Style.Sharp
        )
        val ViewKanban = MaterialIcon(
            name = "view_kanban",
            style = MaterialIcon.Style.Sharp
        )
        val ViewList = MaterialIcon(
            name = "view_list",
            style = MaterialIcon.Style.Sharp
        )
        val ViewModule = MaterialIcon(
            name = "view_module",
            style = MaterialIcon.Style.Sharp
        )
        val ViewQuilt = MaterialIcon(
            name = "view_quilt",
            style = MaterialIcon.Style.Sharp
        )
        val ViewSidebar = MaterialIcon(
            name = "view_sidebar",
            style = MaterialIcon.Style.Sharp
        )
        val ViewStream = MaterialIcon(
            name = "view_stream",
            style = MaterialIcon.Style.Sharp
        )
        val ViewTimeline = MaterialIcon(
            name = "view_timeline",
            style = MaterialIcon.Style.Sharp
        )
        val ViewWeek = MaterialIcon(
            name = "view_week",
            style = MaterialIcon.Style.Sharp
        )
        val Vignette = MaterialIcon(
            name = "vignette",
            style = MaterialIcon.Style.Sharp
        )
        val Villa = MaterialIcon(
            name = "villa",
            style = MaterialIcon.Style.Sharp
        )
        val Visibility = MaterialIcon(
            name = "visibility",
            style = MaterialIcon.Style.Sharp
        )
        val VisibilityOff = MaterialIcon(
            name = "visibility_off",
            style = MaterialIcon.Style.Sharp
        )
        val VoiceChat = MaterialIcon(
            name = "voice_chat",
            style = MaterialIcon.Style.Sharp
        )
        val VoiceOverOff = MaterialIcon(
            name = "voice_over_off",
            style = MaterialIcon.Style.Sharp
        )
        val Voicemail = MaterialIcon(
            name = "voicemail",
            style = MaterialIcon.Style.Sharp
        )
        val Volcano = MaterialIcon(
            name = "volcano",
            style = MaterialIcon.Style.Sharp
        )
        val VolumeDown = MaterialIcon(
            name = "volume_down",
            style = MaterialIcon.Style.Sharp
        )
        val VolumeMute = MaterialIcon(
            name = "volume_mute",
            style = MaterialIcon.Style.Sharp
        )
        val VolumeOff = MaterialIcon(
            name = "volume_off",
            style = MaterialIcon.Style.Sharp
        )
        val VolumeUp = MaterialIcon(
            name = "volume_up",
            style = MaterialIcon.Style.Sharp
        )
        val VolunteerActivism = MaterialIcon(
            name = "volunteer_activism",
            style = MaterialIcon.Style.Sharp
        )
        val VpnKey = MaterialIcon(
            name = "vpn_key",
            style = MaterialIcon.Style.Sharp
        )
        val VpnKeyOff = MaterialIcon(
            name = "vpn_key_off",
            style = MaterialIcon.Style.Sharp
        )
        val VpnLock = MaterialIcon(
            name = "vpn_lock",
            style = MaterialIcon.Style.Sharp
        )
        val Vrpano = MaterialIcon(
            name = "vrpano",
            style = MaterialIcon.Style.Sharp
        )
        val Wallet = MaterialIcon(
            name = "wallet",
            style = MaterialIcon.Style.Sharp
        )
        val Wallpaper = MaterialIcon(
            name = "wallpaper",
            style = MaterialIcon.Style.Sharp
        )
        val Warehouse = MaterialIcon(
            name = "warehouse",
            style = MaterialIcon.Style.Sharp
        )
        val Warning = MaterialIcon(
            name = "warning",
            style = MaterialIcon.Style.Sharp
        )
        val WarningAmber = MaterialIcon(
            name = "warning_amber",
            style = MaterialIcon.Style.Sharp
        )
        val Wash = MaterialIcon(
            name = "wash",
            style = MaterialIcon.Style.Sharp
        )
        val Watch = MaterialIcon(
            name = "watch",
            style = MaterialIcon.Style.Sharp
        )
        val WatchLater = MaterialIcon(
            name = "watch_later",
            style = MaterialIcon.Style.Sharp
        )
        val WatchOff = MaterialIcon(
            name = "watch_off",
            style = MaterialIcon.Style.Sharp
        )
        val Water = MaterialIcon(
            name = "water",
            style = MaterialIcon.Style.Sharp
        )
        val WaterDamage = MaterialIcon(
            name = "water_damage",
            style = MaterialIcon.Style.Sharp
        )
        val WaterDrop = MaterialIcon(
            name = "water_drop",
            style = MaterialIcon.Style.Sharp
        )
        val WaterfallChart = MaterialIcon(
            name = "waterfall_chart",
            style = MaterialIcon.Style.Sharp
        )
        val Waves = MaterialIcon(
            name = "waves",
            style = MaterialIcon.Style.Sharp
        )
        val WavingHand = MaterialIcon(
            name = "waving_hand",
            style = MaterialIcon.Style.Sharp
        )
        val WbAuto = MaterialIcon(
            name = "wb_auto",
            style = MaterialIcon.Style.Sharp
        )
        val WbCloudy = MaterialIcon(
            name = "wb_cloudy",
            style = MaterialIcon.Style.Sharp
        )
        val WbIncandescent = MaterialIcon(
            name = "wb_incandescent",
            style = MaterialIcon.Style.Sharp
        )
        val WbIridescent = MaterialIcon(
            name = "wb_iridescent",
            style = MaterialIcon.Style.Sharp
        )
        val WbShade = MaterialIcon(
            name = "wb_shade",
            style = MaterialIcon.Style.Sharp
        )
        val WbSunny = MaterialIcon(
            name = "wb_sunny",
            style = MaterialIcon.Style.Sharp
        )
        val WbTwilight = MaterialIcon(
            name = "wb_twilight",
            style = MaterialIcon.Style.Sharp
        )
        val Wc = MaterialIcon(
            name = "wc",
            style = MaterialIcon.Style.Sharp
        )
        val Web = MaterialIcon(
            name = "web",
            style = MaterialIcon.Style.Sharp
        )
        val WebAsset = MaterialIcon(
            name = "web_asset",
            style = MaterialIcon.Style.Sharp
        )
        val WebAssetOff = MaterialIcon(
            name = "web_asset_off",
            style = MaterialIcon.Style.Sharp
        )
        val WebStories = MaterialIcon(
            name = "web_stories",
            style = MaterialIcon.Style.Sharp
        )
        val Webhook = MaterialIcon(
            name = "webhook",
            style = MaterialIcon.Style.Sharp
        )
        val Weekend = MaterialIcon(
            name = "weekend",
            style = MaterialIcon.Style.Sharp
        )
        val West = MaterialIcon(
            name = "west",
            style = MaterialIcon.Style.Sharp
        )
        val Whatshot = MaterialIcon(
            name = "whatshot",
            style = MaterialIcon.Style.Sharp
        )
        val WheelchairPickup = MaterialIcon(
            name = "wheelchair_pickup",
            style = MaterialIcon.Style.Sharp
        )
        val WhereToVote = MaterialIcon(
            name = "where_to_vote",
            style = MaterialIcon.Style.Sharp
        )
        val Widgets = MaterialIcon(
            name = "widgets",
            style = MaterialIcon.Style.Sharp
        )
        val WidthFull = MaterialIcon(
            name = "width_full",
            style = MaterialIcon.Style.Sharp
        )
        val WidthNormal = MaterialIcon(
            name = "width_normal",
            style = MaterialIcon.Style.Sharp
        )
        val WidthWide = MaterialIcon(
            name = "width_wide",
            style = MaterialIcon.Style.Sharp
        )
        val Wifi = MaterialIcon(
            name = "wifi",
            style = MaterialIcon.Style.Sharp
        )
        val Wifi1Bar = MaterialIcon(
            name = "wifi_1_bar",
            style = MaterialIcon.Style.Sharp
        )
        val Wifi2Bar = MaterialIcon(
            name = "wifi_2_bar",
            style = MaterialIcon.Style.Sharp
        )
        val WifiCalling = MaterialIcon(
            name = "wifi_calling",
            style = MaterialIcon.Style.Sharp
        )
        val WifiCalling3 = MaterialIcon(
            name = "wifi_calling_3",
            style = MaterialIcon.Style.Sharp
        )
        val WifiChannel = MaterialIcon(
            name = "wifi_channel",
            style = MaterialIcon.Style.Sharp
        )
        val WifiFind = MaterialIcon(
            name = "wifi_find",
            style = MaterialIcon.Style.Sharp
        )
        val WifiLock = MaterialIcon(
            name = "wifi_lock",
            style = MaterialIcon.Style.Sharp
        )
        val WifiOff = MaterialIcon(
            name = "wifi_off",
            style = MaterialIcon.Style.Sharp
        )
        val WifiPassword = MaterialIcon(
            name = "wifi_password",
            style = MaterialIcon.Style.Sharp
        )
        val WifiProtectedSetup = MaterialIcon(
            name = "wifi_protected_setup",
            style = MaterialIcon.Style.Sharp
        )
        val WifiTethering = MaterialIcon(
            name = "wifi_tethering",
            style = MaterialIcon.Style.Sharp
        )
        val WifiTetheringError = MaterialIcon(
            name = "wifi_tethering_error",
            style = MaterialIcon.Style.Sharp
        )
        val WifiTetheringOff = MaterialIcon(
            name = "wifi_tethering_off",
            style = MaterialIcon.Style.Sharp
        )
        val WindPower = MaterialIcon(
            name = "wind_power",
            style = MaterialIcon.Style.Sharp
        )
        val Window = MaterialIcon(
            name = "window",
            style = MaterialIcon.Style.Sharp
        )
        val WineBar = MaterialIcon(
            name = "wine_bar",
            style = MaterialIcon.Style.Sharp
        )
        val Woman = MaterialIcon(
            name = "woman",
            style = MaterialIcon.Style.Sharp
        )
        val Woman2 = MaterialIcon(
            name = "woman_2",
            style = MaterialIcon.Style.Sharp
        )
        val Work = MaterialIcon(
            name = "work",
            style = MaterialIcon.Style.Sharp
        )
        val WorkHistory = MaterialIcon(
            name = "work_history",
            style = MaterialIcon.Style.Sharp
        )
        val WorkOff = MaterialIcon(
            name = "work_off",
            style = MaterialIcon.Style.Sharp
        )
        val WorkOutline = MaterialIcon(
            name = "work_outline",
            style = MaterialIcon.Style.Sharp
        )
        val WorkspacePremium = MaterialIcon(
            name = "workspace_premium",
            style = MaterialIcon.Style.Sharp
        )
        val Workspaces = MaterialIcon(
            name = "workspaces",
            style = MaterialIcon.Style.Sharp
        )
        val WrapText = MaterialIcon(
            name = "wrap_text",
            style = MaterialIcon.Style.Sharp
        )
        val WrongLocation = MaterialIcon(
            name = "wrong_location",
            style = MaterialIcon.Style.Sharp
        )
        val Wysiwyg = MaterialIcon(
            name = "wysiwyg",
            style = MaterialIcon.Style.Sharp
        )
        val Yard = MaterialIcon(
            name = "yard",
            style = MaterialIcon.Style.Sharp
        )
        val YoutubeSearchedFor = MaterialIcon(
            name = "youtube_searched_for",
            style = MaterialIcon.Style.Sharp
        )
        val ZoomIn = MaterialIcon(
            name = "zoom_in",
            style = MaterialIcon.Style.Sharp
        )
        val ZoomInMap = MaterialIcon(
            name = "zoom_in_map",
            style = MaterialIcon.Style.Sharp
        )
        val ZoomOut = MaterialIcon(
            name = "zoom_out",
            style = MaterialIcon.Style.Sharp
        )
        val ZoomOutMap = MaterialIcon(
            name = "zoom_out_map",
            style = MaterialIcon.Style.Sharp
        )
    }
}