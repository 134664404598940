package com.catbit.opinionpoll.core.mapper

import kotlin.reflect.KClass

class Mapper<TSource : Any, TTarget : Any>(
    private val sourceClass: KClass<TSource>, private val map: TSource.(Mappers) -> TTarget
) {

    fun match(sourceClass: KClass<*>) = sourceClass == this.sourceClass

    fun mapTo(origin: Any, mappers: Mappers): TTarget = (origin as TSource).map(mappers)
}

inline fun <reified TSource : Any, reified TTarget : Any> mapper(
    noinline map: TSource.(Mappers) -> TTarget
) = Mapper(TSource::class, map)

class Mappers(val mappers: List<Mapper<*, *>>) {

    inline fun <reified TSource : Any, reified TTarget : Any> mapTo(source: TSource): TTarget {

        return mappers.filter { it.match(source::class) }.firstNotNullOfOrNull { mapper ->
            try {
                mapper.mapTo(source, this) as TTarget
            } catch (e: Throwable) {
                null
            }
        } ?: throw RuntimeException(
            """
                Mapper not found
                source: ${TSource::class.js.name}
                target: ${TTarget::class.js.name}
                sourceObj: $source
            """.trimIndent()
        )
    }
}

inline fun <reified TSource : Any, reified TTarget : Any> TSource.mapTo(mappers: Mappers): TTarget =
    mappers.mapTo(this)

inline fun <reified TSource : Any, reified TTarget : Any> List<TSource>.mapTo(mappers: Mappers): List<TTarget> =
    map { obj -> obj.mapTo(mappers) }