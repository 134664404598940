package com.catbit.opinionpoll.inputs.state

import com.catbit.opinionpoll.core.ui.icons.MaterialIcon
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons

data class InputGraphUIState(
    val identifier: String,
    val title: String,
    val chartType: CharType,
    val data: List<ChartData>
) {
    data class ChartData(
        val label: String,
        val value: Int
    )

    enum class CharType(
        val translatedName: String,
        val icon: MaterialIcon
    ) {
        Pizza(
            translatedName = "Pizza",
            icon = MaterialIcons.Round.PieChart
        ),
        Bar(
            translatedName = "Barras",
            icon = MaterialIcons.Round.BarChart
        )
    }
}