package com.catbit.opinionpoll.inputs.requests.producers

import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.inputs.SingleChoiceInputUIState
import com.catbit.opinionpoll.inputs.requests.requests.OptionRequest
import com.catbit.opinionpoll.inputs.requests.requests.SingleChoiceOptionsInputRequest

class SingleChoiceInputRequestProducer : InputRequestProducer<SingleChoiceInputUIState> {
    override fun match(inputUIState: InputUIState) = inputUIState is SingleChoiceInputUIState

    override fun produce(inputUIState: SingleChoiceInputUIState) = SingleChoiceOptionsInputRequest(
        identifier = inputUIState.identifier,
        index = inputUIState.index,
        title = inputUIState.title,
        subtitle = inputUIState.subtitle,
        isObligatory = inputUIState.isObligatory,
        options = inputUIState.options.map { option ->
            OptionRequest(
                title = option.title,
                identifier = option.actualIdentifier
            )
        },
        otherOption = if (inputUIState.hasOtherOption)
            OptionRequest(
                title = inputUIState.otherOptionTitle,
                identifier = UUID.stringUUID()
            )
        else null,
        otherOptionPlaceholderText = inputUIState.otherOptionPlaceholderText,
        maxOtherOptionTextLength = inputUIState.maxOtherOptionTextLength,
        style = inputUIState.style.name.lowercase(),
        showSearchBar = inputUIState.showSearchBar
    )
}