package com.catbit.opinionpoll.domain.environment

import com.catbit.opinionpoll.core.domain.UseCase
import com.catbit.opinionpoll.data.models.EnvironmentInfoModel
import com.catbit.opinionpoll.data.repositories.OpinionPollRepository

class GetEnvironmentInfoUseCase(
    private val repository: OpinionPollRepository
) : UseCase<EnvironmentInfoModel, Unit>() {
    override suspend fun execute(params: Unit) = repository.getEnvironmentInfo()
}