package com.catbit.opinionpoll.data.data_sources.local_storage

import org.w3c.dom.Storage
import org.w3c.dom.get

class OpinionPollLocalStorageImpl(
    private val localStorage: Storage
) : OpinionPollLocalStorage {
    override suspend fun saveLoginCredentials(
        kind: String,
        localId: String,
        email: String,
        role: String,
        displayName: String,
        idToken: String,
        registered: Boolean,
        environment: String,
        environmentImage: String?,
        environmentName: String,
        environmentPlanCreatedIn: String,
        environmentPlanExpiresIn: String,
        environmentPlan: String,
        refreshToken: String,
        expiresIn: String
    ) {
        with(localStorage) {
            setItem("OPKind", kind)
            setItem("OPLocalId", localId)
            setItem("OPEmail", email)
            setItem("OPRole", role)
            setItem("OPDisplayName", displayName)
            setItem("OPIdToken", idToken)
            setItem("OPRegistered", registered.toString())
            setItem("OPEnvironment", environment)
            environmentImage?.let { setItem("OPEnvironmentImage", it) }
            setItem("OPEnvironmentName", environmentName)
            setItem("OPEnvironmentPlanCreatedIn", environmentPlanCreatedIn)
            setItem("OPEnvironmentPlanExpiresIn", environmentPlanExpiresIn)
            setItem("OPEnvironmentPlan", environmentPlan)
            setItem("OPRefreshToken", refreshToken)
            setItem("OPExpiresIn", expiresIn)
        }
    }

    override suspend fun getCredentialKind(): String? = localStorage["OPKind"]
    override suspend fun deleteCredentialKind(): Unit = localStorage.removeItem("OPKind")
    override suspend fun updateCredentialKind(kind: String): Unit = localStorage.setItem("OPKind", kind)

    override suspend fun getCredentialLocalId(): String? = localStorage["OPLocalId"]
    override suspend fun deleteCredentialLocalId(): Unit = localStorage.removeItem("OPLocalId")
    override suspend fun updateCredentialLocalId(localId: String): Unit = localStorage.setItem("OPLocalId", localId)

    override suspend fun getCredentialEmail(): String? = localStorage["OPEmail"]
    override suspend fun deleteCredentialEmail(): Unit = localStorage.removeItem("OPEmail")
    override suspend fun updateCredentialEmail(email: String): Unit = localStorage.setItem("OPEmail", email)

    override suspend fun getCredentialRole(): String? = localStorage["OPRole"]
    override suspend fun deleteCredentialRole(): Unit = localStorage.removeItem("OPRole")
    override suspend fun updateCredentialRole(role: String): Unit = localStorage.setItem("OPRole", role)

    override suspend fun getCredentialDisplayName(): String? = localStorage["OPDisplayName"]
    override suspend fun deleteCredentialDisplayName(): Unit = localStorage.removeItem("OPDisplayName")
    override suspend fun updateCredentialDisplayName(displayName: String): Unit =
        localStorage.setItem("OPDisplayName", displayName)

    override suspend fun getCredentialIdToken(): String? = localStorage["OPIdToken"]
    override suspend fun deleteCredentialIdToken(): Unit = localStorage.removeItem("OPIdToken")
    override suspend fun updateCredentialIdToken(idToken: String): Unit = localStorage.setItem("OPIdToken", idToken)

    override suspend fun getCredentialRegistered(): Boolean? = localStorage["OPRegistered"]?.toBoolean()
    override suspend fun deleteCredentialRegistered(): Unit = localStorage.removeItem("OPRegistered")
    override suspend fun updateCredentialRegistered(registered: Boolean): Unit =
        localStorage.setItem("OPRegistered", registered.toString())

    override suspend fun getCredentialEnvironment(): String? = localStorage["OPEnvironment"]
    override suspend fun deleteCredentialEnvironment(): Unit = localStorage.removeItem("OPEnvironment")
    override suspend fun updateCredentialEnvironment(environment: String): Unit =
        localStorage.setItem("OPEnvironment", environment)

    override suspend fun getCredentialEnvironmentImage(): String? = localStorage["OPEnvironmentImage"]
    override suspend fun deleteCredentialEnvironmentImage(): Unit = localStorage.removeItem("OPEnvironmentImage")
    override suspend fun updateCredentialEnvironmentImage(environmentImage: String): Unit =
        localStorage.setItem("OPEnvironmentImage", environmentImage)

    override suspend fun getCredentialEnvironmentName(): String? = localStorage["OPEnvironmentName"]
    override suspend fun deleteCredentialEnvironmentName(): Unit = localStorage.removeItem("OPEnvironmentName")
    override suspend fun updateCredentialEnvironmentName(environmentName: String): Unit =
        localStorage.setItem("OPEnvironmentName", environmentName)

    override suspend fun getCredentialEnvironmentPlanCreatedIn(): String? = localStorage["OPEnvironmentPlanCreatedIn"]
    override suspend fun deleteCredentialEnvironmentPlanCreatedIn() =
        localStorage.removeItem("OPEnvironmentPlanCreatedIn")

    override suspend fun updateCredentialEnvironmentPlanCreatedIn(environmentPlanCreatedIn: String) =
        localStorage.setItem("OPEnvironmentPlanCreatedIn", environmentPlanCreatedIn)

    override suspend fun getCredentialEnvironmentPlanExpiresIn(): String? = localStorage["OPEnvironmentPlanExpiresIn"]
    override suspend fun deleteCredentialEnvironmentPlanExpiresIn() =
        localStorage.removeItem("OPEnvironmentPlanExpiresIn")

    override suspend fun updateCredentialEnvironmentPlanExpiresIn(environmentPlanExpiresIn: String) =
        localStorage.setItem("OPEnvironmentPlanExpiresIn", environmentPlanExpiresIn)

    override suspend fun getCredentialEnvironmentPlan(): String? = localStorage["OPEnvironmentPlan"]
    override suspend fun deleteCredentialEnvironmentPlan() = localStorage.removeItem("OPEnvironmentPlan")
    override suspend fun updateCredentialEnvironmentPlan(environmentPlan: String) =
        localStorage.setItem("OPEnvironmentPlan", environmentPlan)

    override suspend fun getCredentialRefreshToken(): String? = localStorage["OPRefreshToken"]
    override suspend fun deleteCredentialRefreshToken(): Unit = localStorage.removeItem("OPRefreshToken")
    override suspend fun updateCredentialRefreshToken(refreshToken: String): Unit =
        localStorage.setItem("OPRefreshToken", refreshToken)

    override suspend fun getCredentialExpiresIn(): String? = localStorage["OPExpiresIn"]
    override suspend fun deleteCredentialExpiresIn(): Unit = localStorage.removeItem("OPExpiresIn")
    override suspend fun updateCredentialExpiresIn(expiresIn: String): Unit =
        localStorage.setItem("OPExpiresIn", expiresIn)

    override suspend fun cleanLoginCredentials() {
        with(localStorage) {
            removeItem("OPKind")
            removeItem("OPLocalId")
            removeItem("OPEmail")
            removeItem("OPRole")
            removeItem("OPDisplayName")
            removeItem("OPIdToken")
            removeItem("OPRegistered")
            removeItem("OPEnvironment")
            removeItem("OPRefreshToken")
            removeItem("OPExpiresIn")
        }
    }
}