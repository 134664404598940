package com.catbit.opinionpoll.data.models

import com.catbit.opinionpoll.core.data.models.LatLong
import kotlinx.datetime.LocalDateTime

data class FormAnswerModel(
    val identifier: String,
    val submittedBy: String,
    val submittedAt: LocalDateTime,
    val geoCoordinates: LatLong?,
    val answer: Map<String, String>
)