package com.catbit.opinionpoll.data.data_sources.networking.responses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SimplifiedFormResponse(
    @SerialName("identifier")
    val identifier: String,
    @SerialName("version")
    val version: Long,
    @SerialName("title")
    val title: String,
    @SerialName("creationDate")
    val creationDate: String,
    @SerialName("createdBy")
    val createdBy: String,
    @SerialName("expirationDate")
    val expirationDate: String
)