package com.catbit.opinionpoll.inputs.requests.producers

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.inputs.EvaluationInputUIState
import com.catbit.opinionpoll.inputs.requests.requests.EvaluationInputRequest

class EvaluationInputRequestProducer : InputRequestProducer<EvaluationInputUIState> {
    override fun match(inputUIState: InputUIState) = inputUIState is EvaluationInputUIState

    override fun produce(inputUIState: EvaluationInputUIState) = EvaluationInputRequest(
        identifier = inputUIState.identifier,
        index = inputUIState.index,
        title = inputUIState.title,
        subtitle = inputUIState.subtitle,
        isObligatory = inputUIState.isObligatory,
        minValue = inputUIState.minValue,
        maxValue = inputUIState.maxValue
    )
}