package com.catbit.opinionpoll.inputs.events_handler

import com.catbit.opinionpoll.core.extensions.swap
import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.events.CompactSingleChoiceInputEventData
import com.catbit.opinionpoll.inputs.events.InputUIEventDefaultData
import com.catbit.opinionpoll.inputs.inputs.CompactSingleChoiceInputUIState
import com.catbit.opinionpoll.inputs.inputs.OptionInput
import com.catbit.opinionpoll.inputs.inputs.QuestionInput

class CompactSingleChoiceInputUIEventHandler : InputUIEventHandler<CompactSingleChoiceInputUIState> {

    override fun match(formIdentifier: String, uiState: InputUIState) =
        uiState is CompactSingleChoiceInputUIState && formIdentifier == uiState.actualIdentifier

    override fun handle(uiState: CompactSingleChoiceInputUIState, eventData: Any) = when (eventData) {
        is CompactSingleChoiceInputEventData.OnAddOption -> uiState.copy(
            options = uiState.options.toMutableList().apply {
                add(
                    OptionInput(
                        actualIdentifier = UUID.stringUUID(),
                        title = ""
                    )
                )
            }
        )

        is CompactSingleChoiceInputEventData.OnOptionTitleChange -> {
            uiState.copy(
                options = uiState.options.map { option ->
                    if (option.actualIdentifier == eventData.actualIdentifier) {
                        option.copy(title = eventData.newTitle)
                    } else option
                }
            )
        }

        is CompactSingleChoiceInputEventData.OnOptionRemove -> {
            uiState.copy(
                options = uiState.options.filter { option ->
                    option.actualIdentifier != eventData.actualIdentifier
                }
            )
        }

        is CompactSingleChoiceInputEventData.OnAddQuestion -> uiState.copy(
            questions = uiState.questions.toMutableList().apply {
                add(
                    QuestionInput(
                        actualIdentifier = UUID.stringUUID(),
                        identifier = "",
                        title = ""
                    )
                )
            }
        )

        is CompactSingleChoiceInputEventData.OnQuestionActualIdentifierChange -> {
            uiState.copy(
                questions = uiState.questions.map { question ->
                    if (question.actualIdentifier == eventData.actualIdentifier) {
                        question.copy(identifier = eventData.newIdentifier)
                    } else question
                }
            )
        }

        is CompactSingleChoiceInputEventData.OnQuestionTitleChange -> {
            uiState.copy(
                questions = uiState.questions.map { question ->
                    if (question.actualIdentifier == eventData.actualIdentifier) {
                        question.copy(title = eventData.newTitle)
                    } else question
                }
            )
        }

        is CompactSingleChoiceInputEventData.OnQuestionRemove -> {
            uiState.copy(
                questions = uiState.questions.filter { question ->
                    question.actualIdentifier != eventData.actualIdentifier
                }
            )
        }

        is CompactSingleChoiceInputEventData.OnShowSearchBarToggle -> {
            uiState.copy(showSearchBar = eventData.showSearchBar)
        }

        is CompactSingleChoiceInputEventData.OnAddBatchQuestions -> {
            uiState.copy(
                questions = uiState.questions + eventData.rawText
                    .trim()
                    .replace(";", "\n")
                    .split("\n")
                    .map {
                        QuestionInput(
                            identifier = "",
                            actualIdentifier = UUID.stringUUID(),
                            title = it
                        )
                    }
            )
        }

        is CompactSingleChoiceInputEventData.OnAddBatchOptions -> {
            uiState.copy(
                options = uiState.options + eventData.rawText
                    .trim()
                    .replace(";", "\n")
                    .split("\n")
                    .map {
                        OptionInput(
                            actualIdentifier = UUID.stringUUID(),
                            title = it
                        )
                    }
            )
        }

        is CompactSingleChoiceInputEventData.OnMoveOptionUp -> {
            with(uiState) {
                if (options.size == 1) return uiState
                val currentIndex = options.indexOfFirst { it.actualIdentifier == eventData.actualIdentifier }
                if ((currentIndex - 1) == -1) return uiState

                val newOptions = options.toMutableList().apply {
                    swap(currentIndex, currentIndex - 1)
                }

                copy(options = newOptions)
            }
        }

        is CompactSingleChoiceInputEventData.OnMoveOptionDown -> {
            with(uiState) {
                if (options.size == 1) return uiState
                val currentIndex = options.indexOfFirst { it.actualIdentifier == eventData.actualIdentifier }
                if (currentIndex == options.lastIndex) return uiState

                val newOptions = options.toMutableList().apply {
                    swap(currentIndex, currentIndex + 1)
                }

                copy(options = newOptions)
            }
        }

        is CompactSingleChoiceInputEventData.OnMoveQuestionUp -> {
            with(uiState) {
                if (questions.size == 1) return uiState
                val currentIndex = questions.indexOfFirst { it.actualIdentifier == eventData.actualIdentifier }
                if ((currentIndex - 1) == -1) return uiState

                val newQuestions = questions.toMutableList().apply {
                    swap(currentIndex, currentIndex - 1)
                }

                copy(questions = newQuestions)
            }
        }

        is CompactSingleChoiceInputEventData.OnMoveQuestionDown -> {
            with(uiState) {
                if (questions.size == 1) return uiState
                val currentIndex = questions.indexOfFirst { it.actualIdentifier == eventData.actualIdentifier }
                if (currentIndex == questions.lastIndex) return uiState

                val newQuestions = questions.toMutableList().apply {
                    swap(currentIndex, currentIndex + 1)
                }

                copy(questions = newQuestions)
            }
        }

        is InputUIEventDefaultData.IdentifierChange -> uiState.copy(identifier = eventData.newIdentifier)
        is InputUIEventDefaultData.TitleChange -> uiState.copy(title = eventData.newTitle)
        is InputUIEventDefaultData.SubtitleChange -> uiState.copy(subtitle = eventData.newSubtitle)
        is InputUIEventDefaultData.ObligatoryToggle -> uiState.copy(isObligatory = eventData.isObligatory)
        else -> uiState
    }
}