package com.catbit.opinionpoll.inputs.models

import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.inputs.InputType
import com.catbit.opinionpoll.inputs.inputs.DateInputUIState
import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

@Serializable
data class DateInputModel(
    override val identifier: String,
    override val title: String,
    override val subtitle: String?,
    override val isObligatory: Boolean,
    override val index: Int,
    val minDate: LocalDate?,
    val maxDate: LocalDate?
) : InputModel {

    override fun toUIState() = DateInputUIState(
        actualIdentifier = UUID.stringUUID(),
        type = InputType.Date,
        identifier = identifier,
        title = title,
        subtitle = subtitle.orEmpty(),
        isObligatory = isObligatory,
        index = index,
        isEnabled = true,
        errorMessage = null,
        minDate = minDate,
        maxDate = maxDate
    )

    override fun knowsIdentifier(identifier: String) = identifier == this.identifier
    override val identifiers = listOf(identifier)
    override val titleToIdentifierMapping = listOf(title to identifier)
}