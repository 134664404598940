package com.catbit.opinionpoll.data.data_sources.networking.responses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FormFilterResponse(
    @SerialName("identifier")
    val identifier: String,
    @SerialName("title")
    val title: String,
    @SerialName("groupingField")
    val groupingField: String,
    @SerialName("subgroups")
    val subgroups: List<FormFilterSubgroupFieldResponse>,
    @SerialName("fieldsToDisplay")
    val fieldsToDisplay: List<String>
) {
    @Serializable
    data class FormFilterSubgroupFieldResponse(
        @SerialName("identifier")
        val identifier: String,
        @SerialName("title")
        val title: String,
        @SerialName("values")
        val values: List<String>
    )
}