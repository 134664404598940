package com.catbit.opinionpoll.data.models

import kotlinx.serialization.Serializable

@Serializable
data class StatsModel(
    val totalForms: Int,
    val totalUsers: Int,
    val formsTotalAnswers: Map<String, Int>
)
