package com.catbit.opinionpoll.inputs.events_handler

import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.events.InputUIEventDefaultData
import com.catbit.opinionpoll.inputs.events.NumericAreaInputEventData
import com.catbit.opinionpoll.inputs.inputs.NumericAreaInputUIState

class NumericAreaInputUIEventHandler : InputUIEventHandler<NumericAreaInputUIState> {

    override fun match(formIdentifier: String, uiState: InputUIState) =
        uiState is NumericAreaInputUIState && formIdentifier == uiState.actualIdentifier

    override fun handle(uiState: NumericAreaInputUIState, eventData: Any) = when (eventData) {
        is NumericAreaInputEventData.OnMinValueChange -> uiState.copy(minValue = eventData.newMinValue)
        is NumericAreaInputEventData.OnMaxValueChange -> uiState.copy(maxValue = eventData.newMaxValue)
        is NumericAreaInputEventData.OnSymbolChange -> uiState.copy(symbol = eventData.newSymbol)
        is NumericAreaInputEventData.OnSymbolPositionChange -> uiState.copy(symbolPosition = eventData.newSymbolPosition)
        is NumericAreaInputEventData.OnDecimalPointsChange -> uiState.copy(decimalPoints = eventData.newDecimalPoints)
        is InputUIEventDefaultData.IdentifierChange -> uiState.copy(identifier = eventData.newIdentifier)
        is InputUIEventDefaultData.TitleChange -> uiState.copy(title = eventData.newTitle)
        is InputUIEventDefaultData.SubtitleChange -> uiState.copy(subtitle = eventData.newSubtitle)
        is InputUIEventDefaultData.ObligatoryToggle -> uiState.copy(isObligatory = eventData.isObligatory)
        else -> uiState
    }
}