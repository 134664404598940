package com.catbit.opinionpoll.core.charts.ui

interface ChartConfiguration {
    val height: Int
    val width: Int
    val labelsPosition: LabelPosition

    enum class LabelPosition {
        Right, Bottom
    }
}
