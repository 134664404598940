package com.catbit.opinionpoll.pages.app

import kotlinx.datetime.LocalDateTime

sealed interface AppUIContract {
    sealed interface State {
        data object Loading : State
        data class Displaying(
            val environmentId: String,
            val environmentName: String,
            val environmentPlan: String,
            val planExpirationDate: LocalDateTime?,
            val environmentImage: String?
        ) : State
    }

    sealed interface Effect {
        data object OnLogout : Effect
    }

    sealed interface Event {
        data object OnLogout : Event
    }
}