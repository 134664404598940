package com.catbit.opinionpoll.inputs.inputs

import androidx.compose.runtime.*
import com.catbit.opinionpoll.core.extensions.withNotNull
import com.catbit.opinionpoll.core.sailor.providers.LocalDialogStateProvider
import com.catbit.opinionpoll.core.ui.composables.HoverableContent
import com.catbit.opinionpoll.core.ui.composables.MaterialIcon
import com.catbit.opinionpoll.core.ui.composables.base_components.*
import com.catbit.opinionpoll.core.ui.icons.MaterialIcons
import com.catbit.opinionpoll.core.ui.modifiers.*
import com.catbit.opinionpoll.core.uuid.UUID
import com.catbit.opinionpoll.inputs.InputType
import com.catbit.opinionpoll.inputs.InputUI
import com.catbit.opinionpoll.inputs.InputUIState
import com.catbit.opinionpoll.inputs.composables.DefaultFormContainer
import com.catbit.opinionpoll.inputs.composables.DefaultFormPreview
import com.catbit.opinionpoll.inputs.events.InputUIEvent
import com.catbit.opinionpoll.inputs.events.MultipleChoicesInputEventData
import com.catbit.opinionpoll.res.Strings
import com.catbit.opinionpoll.core.extensions.sitePalette
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.document
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.px
import org.w3c.files.File
import org.w3c.files.FileReader

class MultipleChoicesInput : InputUI<MultipleChoicesInputUIState> {
    override fun match(uiState: InputUIState) = uiState is MultipleChoicesInputUIState

    @Composable
    override fun Compose(uiState: MultipleChoicesInputUIState, onEvent: (InputUIEvent) -> Unit) {

        // TODO aumentar max options quando o usuário adicionar uma opção

        val dialogState = LocalDialogStateProvider.current
        var textAreaValue by remember { mutableStateOf("") }
        var hadUserInteraction by remember { mutableStateOf(false) }

        LaunchedEffect(uiState.options.lastOrNull()?.actualIdentifier) {
            withNotNull(uiState.options.lastOrNull()?.actualIdentifier) {
                if (hadUserInteraction) {
                    document.getElementById(this)?.focus()
                }
            }
        }

        DefaultFormContainer(
            modifier = Modifier.fillMaxWidth(),
            inputUIState = uiState,
            onEvent = onEvent,
            preview = { MultipleChoicesInputPreview(uiState) }
        ) {
            Column(
                modifier = Modifier.fillMaxWidth()
            ) {

                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(top = 16.px)
                ) {
                    Column(
                        modifier = Modifier
                            .fillMaxWidth()
                            .margin(right = 16.px)
                    ) {
                        Text(
                            modifier = Modifier
                                .titleMedium()
                                .fontWeight(FontWeight.Bold),
                            text = Strings.minSelection
                        )
                        TextField(
                            modifier = Modifier
                                .margin(top = 8.px)
                                .fillMaxWidth(),
                            text = uiState.minSelection.toString(),
                            enabled = uiState.isEnabled,
                            onTextChanged = {
                                val newValue = it.takeIf { it.isNotBlank() } ?: "0"
                                onEvent(
                                    InputUIEvent(
                                        formIdentifier = uiState.actualIdentifier,
                                        data = MultipleChoicesInputEventData.OnMinSelectionChange(newValue.toInt())
                                    )
                                )
                            }
                        )
                    }
                    Column(
                        modifier = Modifier.fillMaxWidth()
                    ) {
                        Text(
                            modifier = Modifier
                                .titleMedium()
                                .fontWeight(FontWeight.Bold),
                            text = Strings.maxSelection
                        )
                        TextField(
                            modifier = Modifier
                                .margin(top = 8.px)
                                .fillMaxWidth(),
                            text = uiState.maxSelection.toString(),
                            enabled = uiState.isEnabled,
                            onTextChanged = {
                                val newValue = it.takeIf { it.isNotBlank() } ?: "0"
                                onEvent(
                                    InputUIEvent(
                                        formIdentifier = uiState.actualIdentifier,
                                        data = MultipleChoicesInputEventData.OnMaxSelectionChange(newValue.toInt())
                                    )
                                )
                            }
                        )
                    }
                }
                Text(
                    modifier = Modifier
                        .titleMedium()
                        .fontWeight(FontWeight.Bold)
                        .margin(top = 16.px),
                    text = Strings.options
                )
                Column(
                    modifier = Modifier
                        .margin(top = 8.px)
                        .fillMaxWidth()
                        .display(DisplayStyle.Flex)
                        .gap(8.px)
                ) {
                    uiState.options.forEach { option ->
                        Row(
                            modifier = Modifier
                                .fillMaxWidth(),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            TextField(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .margin(right = 8.px),
                                text = option.title,
                                enabled = uiState.isEnabled,
                                inputIdentifier = option.actualIdentifier,
                                hint = Strings.option,
                                onTextChanged = {
                                    onEvent(
                                        InputUIEvent(
                                            formIdentifier = uiState.actualIdentifier,
                                            data = MultipleChoicesInputEventData.OnOptionTitleChange(
                                                actualIdentifier = option.actualIdentifier,
                                                newTitle = it
                                            )
                                        )
                                    )
                                },
                                onEnterPress = {
                                    hadUserInteraction = true
                                    onEvent(
                                        InputUIEvent(
                                            formIdentifier = uiState.actualIdentifier,
                                            data = MultipleChoicesInputEventData.OnAddOption
                                        )
                                    )
                                }
                            )

                            IconButton(
                                onClick = {
                                    onEvent(
                                        InputUIEvent(
                                            formIdentifier = uiState.actualIdentifier,
                                            data = MultipleChoicesInputEventData.OnMoveOptionUp(
                                                actualIdentifier = option.actualIdentifier
                                            )
                                        )
                                    )
                                }
                            ) {
                                MaterialIcon(MaterialIcons.Round.ArrowCircleUp)
                            }
                            Tooltip(
                                target = ElementTarget.PreviousSibling,
                                text = Strings.moveOptionUp
                            )
                            IconButton(
                                onClick = {
                                    onEvent(
                                        InputUIEvent(
                                            formIdentifier = uiState.actualIdentifier,
                                            data = MultipleChoicesInputEventData.OnMoveOptionDown(
                                                actualIdentifier = option.actualIdentifier
                                            )
                                        )
                                    )
                                }
                            ) {
                                MaterialIcon(MaterialIcons.Round.ArrowCircleDown)
                            }
                            Tooltip(
                                target = ElementTarget.PreviousSibling,
                                text = Strings.moveOptionDown
                            )

                            IconButton(
                                onClick = {
                                    onEvent(
                                        InputUIEvent(
                                            formIdentifier = uiState.actualIdentifier,
                                            data = MultipleChoicesInputEventData.OnOptionRemove(
                                                actualIdentifier = option.actualIdentifier
                                            )
                                        )
                                    )
                                }
                            ) {
                                MaterialIcon(MaterialIcons.Round.DeleteOutline)
                            }
                            Tooltip(
                                target = ElementTarget.PreviousSibling,
                                text = Strings.removeOption
                            )
                        }
                    }
                }
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(top = 8.px),
                    horizontalArrangement = Arrangement.Center
                ) {
                    IconButton(
                        onClick = {
                            hadUserInteraction = true
                            onEvent(
                                InputUIEvent(
                                    formIdentifier = uiState.actualIdentifier,
                                    data = MultipleChoicesInputEventData.OnAddOption
                                )
                            )
                        }
                    ) {
                        MaterialIcon(MaterialIcons.Round.AddCircleOutline)
                    }
                    Tooltip(
                        target = ElementTarget.PreviousSibling,
                        text = Strings.addOption
                    )
                    IconButton(
                        onClick = {
                            hadUserInteraction = true
                            dialogState.show {
                                Column(
                                    modifier = Modifier.fillMaxSize()
                                ) {
                                    Text(
                                        text = "Adicionar lista de opções",
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .headlineSmall()
                                    )
                                    Text(
                                        text = "Adicione valores separados por quebra de linha ou por \";\"",
                                        modifier = Modifier
                                            .margin(top = 16.px)
                                            .fillMaxWidth()
                                            .bodyMedium()
                                    )
                                    TextArea(
                                        modifier = Modifier
                                            .fillMaxSize()
                                            .margin(top = 16.px),
                                        text = textAreaValue,
                                        lines = 10,
                                        onTextChanged = { textAreaValue = it }
                                    )
                                    Row(
                                        modifier = Modifier
                                            .margin(top = 24.px)
                                            .fillMaxWidth(),
                                        horizontalArrangement = Arrangement.End
                                    ) {
                                        HoverableContent(
                                            modifier = Modifier.padding(horizontal = 16.px, vertical = 8.px),
                                            onHoverEndColor = sitePalette().surface,
                                            onClick = {
                                                dialogState.dismiss()
                                                textAreaValue = ""
                                            }
                                        ) {
                                            Text(
                                                text = Strings.cancel,
                                                modifier = Modifier.bodyLarge()
                                            )
                                        }
                                        HoverableContent(
                                            modifier = Modifier.padding(horizontal = 16.px, vertical = 8.px),
                                            onHoverEndColor = sitePalette().surface,
                                            onClick = {
                                                onEvent(
                                                    InputUIEvent(
                                                        formIdentifier = uiState.actualIdentifier,
                                                        data = MultipleChoicesInputEventData.OnAddBatchOptions(rawText = textAreaValue)
                                                    )
                                                )
                                                dialogState.dismiss()
                                                textAreaValue = ""
                                            }
                                        ) {
                                            Text(
                                                text = Strings.add,
                                                modifier = Modifier.bodyLarge()
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    ) {
                        MaterialIcon(MaterialIcons.Round.PostAdd)
                    }

                    Tooltip(
                        target = ElementTarget.PreviousSibling,
                        text = Strings.addOptionsInBatch
                    )
                }
                Row(
                    modifier = Modifier.margin(top = 16.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Checkbox(
                        modifier = Modifier.margin(right = 8.px),
                        checked = uiState.hasOtherOption,
                        onCheck = {
                            onEvent(
                                InputUIEvent(
                                    formIdentifier = uiState.actualIdentifier,
                                    data = MultipleChoicesInputEventData.OnToggleHasOtherOption(it)
                                )
                            )
                        }
                    )
                    Text(
                        modifier = Modifier
                            .titleMedium()
                            .fontWeight(FontWeight.Bold),
                        text = Strings.displayOthersOption
                    )
                }

                if (uiState.hasOtherOption) {
                    Column(
                        modifier = Modifier
                            .fillMaxWidth()
                            .margin(top = 16.px)
                    ) {
                        Row(
                            modifier = Modifier.fillMaxWidth()
                        ) {
                            Column(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .margin(right = 16.px)
                            ) {
                                Text(
                                    modifier = Modifier
                                        .titleMedium()
                                        .fontWeight(FontWeight.Bold),
                                    text = Strings.maxOptions
                                )
                                TextField(
                                    modifier = Modifier
                                        .margin(top = 8.px)
                                        .fillMaxWidth(),
                                    text = uiState.maxOtherOptions.toString(),
                                    enabled = uiState.isEnabled,
                                    onTextChanged = {

                                        val newValue = it.takeIf { it.isNotBlank() } ?: "0"

                                        onEvent(
                                            InputUIEvent(
                                                formIdentifier = uiState.actualIdentifier,
                                                data = MultipleChoicesInputEventData.OnMaxOtherOptionsChange(newValue.toInt())
                                            )
                                        )
                                    }
                                )
                            }
                            Column(
                                modifier = Modifier
                                    .fillMaxWidth()
                            ) {
                                Text(
                                    modifier = Modifier
                                        .fontWeight(FontWeight.Bold)
                                        .titleMedium(),
                                    text = Strings.maxOptionTextLength
                                )
                                TextField(
                                    modifier = Modifier
                                        .margin(top = 8.px)
                                        .fillMaxWidth(),
                                    text = uiState.maxOtherOptionTextLength.toString(),
                                    enabled = uiState.isEnabled,
                                    onTextChanged = {

                                        val newValue = it.takeIf { it.isNotBlank() } ?: "0"

                                        onEvent(
                                            InputUIEvent(
                                                formIdentifier = uiState.actualIdentifier,
                                                data = MultipleChoicesInputEventData.OnOtherOptionTextLengthChange(
                                                    newValue.toInt()
                                                )
                                            )
                                        )
                                    }
                                )
                            }
                        }

                        Text(
                            modifier = Modifier
                                .titleMedium()
                                .fontWeight(FontWeight.Bold)
                                .margin(top = 16.px),
                            text = Strings.tip
                        )
                        TextField(
                            modifier = Modifier
                                .fillMaxWidth()
                                .margin(top = 8.px),
                            text = uiState.otherOptionPlaceholderText,
                            enabled = uiState.isEnabled,
                            onTextChanged = {
                                onEvent(
                                    InputUIEvent(
                                        formIdentifier = uiState.actualIdentifier,
                                        data = MultipleChoicesInputEventData.OnOtherPlaceholderTextTitleChange(it)
                                    )
                                )
                            }
                        )
                    }
                }
            }
        }
    }
}

@Composable
private fun MultipleChoicesInputPreview(
    uiState: MultipleChoicesInputUIState
) {
    Column(
        modifier = Modifier.fillMaxWidth()
    ) {
        val checkedOptions = remember { mutableStateListOf<String>() }
        val otherOptions = remember { mutableStateMapOf<String, String>() }

        SideEffect {
            if (!uiState.hasOtherOption) {
                otherOptions.clear()
            }
        }

        with(uiState) {
            DefaultFormPreview(
                index = index,
                title = title,
                subtitle = subtitle
            ) {
                Column(
                    modifier = Modifier
                        .margin(top = 8.px)
                        .fillMaxWidth()
                        .display(DisplayStyle.Flex)
                        .gap(8.px)
                ) {
                    uiState.options.forEach { option ->
                        Row(
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Checkbox(
                                modifier = Modifier.margin(right = 8.px),
                                checked = option.title in checkedOptions,
                                enabled = option.title in checkedOptions
                                        || checkedOptions.size + otherOptions.size < maxSelection
                            ) {
                                if (it) {
                                    checkedOptions.add(option.title)
                                } else {
                                    checkedOptions.remove(option.title)
                                }
                            }
                            Text(
                                modifier = Modifier.bodyLarge(),
                                text = option.title
                            )
                        }
                    }

                    if (hasOtherOption) {
                        otherOptions.forEach { (key, value) ->
                            Row(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .margin(top = 8.px),
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                TextField(
                                    modifier = Modifier
                                        .fillMaxWidth(),
                                    text = value,
                                    hint = uiState.otherOptionPlaceholderText,
                                    onTextChanged = { otherOptions[key] = it }
                                )
                                IconButton(
                                    modifier = Modifier
                                        .margin(left = 8.px),
                                    onClick = { otherOptions.remove(key) }
                                ) {
                                    MaterialIcon(MaterialIcons.Round.DeleteOutline)
                                }
                            }
                        }

                        Row(
                            modifier = Modifier
                                .fillMaxWidth()
                                .margin(top = 8.px),
                            horizontalArrangement = Arrangement.Center
                        ) {
                            IconButton(
                                onClick = {
                                    otherOptions[UUID.stringUUID()] = ""
                                },
                                enabled = otherOptions.size < maxOtherOptions
                                        && checkedOptions.size + otherOptions.size < maxSelection
                            ) {
                                MaterialIcon(MaterialIcons.Round.AddCircleOutline)
                            }
                        }
                    }
                }
            }
        }
    }
}

data class MultipleChoicesInputUIState(
    override val index: Int,
    override val isEnabled: Boolean,
    override val actualIdentifier: String,
    override val identifier: String,
    override val title: String,
    override val subtitle: String,
    override val isObligatory: Boolean,
    override val type: InputType,
    override val errorMessage: String?,
    val options: List<OptionInput>,
    val style: MultipleChoicesStyle,
    val minSelection: Int,
    val maxSelection: Int,
    val hasOtherOption: Boolean,
    val otherOptionPlaceholderText: String,
    val maxOtherOptions: Int,
    val maxOtherOptionTextLength: Int
) : InputUIState {

    companion object {
        fun default(
            index: Int
        ) = MultipleChoicesInputUIState(
            index = index,
            isEnabled = true,
            actualIdentifier = UUID.stringUUID(),
            identifier = "",
            title = "",
            subtitle = "",
            isObligatory = false,
            type = InputType.MultipleChoices,
            errorMessage = null,
            options = listOf(),
            minSelection = 0,
            maxSelection = 0,
            hasOtherOption = false,
            otherOptionPlaceholderText = "",
            maxOtherOptionTextLength = 50,
            maxOtherOptions = 1,
            style = MultipleChoicesStyle.Checkbox,
        )
    }

    enum class MultipleChoicesStyle {
        Checkbox
    }

    override val internalIdentifiers = listOf(identifier)
    override fun knowsIdentifier(identifier: String) = identifier == this.identifier
    override fun updateIndex(newIndex: Int) = copy(index = newIndex)
    override fun copy(
        index: Int?,
        isEnabled: Boolean?,
        actualIdentifier: String?,
        identifier: String?,
        title: String?,
        subtitle: String?,
        isObligatory: Boolean?,
        type: InputType?,
        error: String?
    ) = copy(
        index = index ?: this.index,
        isEnabled = isEnabled ?: this.isEnabled,
        actualIdentifier = actualIdentifier ?: this.actualIdentifier,
        identifier = identifier ?: this.identifier,
        title = title ?: this.title,
        subtitle = subtitle ?: this.subtitle,
        isObligatory = isObligatory ?: this.isObligatory,
        type = type ?: this.type,
        errorMessage = error ?: this.errorMessage,
    )
}