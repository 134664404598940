package com.catbit.opinionpoll.core.events

import kotlinx.browser.window

object WindowOnUnloadEventHandler {

    private var eventObservers = mutableListOf<() -> Unit>()
    fun addOnWindowUnloadListener(observer: () -> Unit) {
        eventObservers.add(observer)
    }

    fun removeOnWindowUnloadListener(observer: () -> Unit) {
        eventObservers.remove(observer)
    }

    init {
        window.onunload = {
            eventObservers.forEach { it() }
        }
    }
}